import { observable, action } from "mobx";
import { ContractSubTabs } from "./enum/AgentDetails";

class ContractTabContext {
  @observable selectedTab: string | undefined = "";

  @action getSelectedTab = () => {
    return this.selectedTab;
  };

  @action setSelectedTab = (value: number) => {
      if(value === 0)this.selectedTab = ContractSubTabs.Contracts;
    else this.selectedTab = ContractSubTabs.PaymentPlans
  };
}

export default new ContractTabContext();

