import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { BackoutRenewalImportListModel } from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";
import { action, observable } from "mobx";
import ContractsStore from "../../../../store/ContractsStore";
import Utils from "../../../../infrastructure/Utils";
import PageContext from "../../../../infrastructure/PageContext";

export class BackoutRenewalImportListViewModel extends BaseListViewModel<BackoutRenewalImportListModel> {

  @observable divisionList: any[] = [];
  @observable isErrorVisible: boolean = false;
  @observable selectedBackoutImport: any;
  @observable responseMessage: any;
  @observable isSuccess: boolean = false;
  @observable selectedCarrierName: string = "";
  @observable selectedYear: string = "";
  @observable backoutAlertDialogTitle: string = 'Are you sure you want to backout?';
  
  constructor()
  {
    super("Backout Revenue Import", "Backout Revenue Import", routes.renewalImportUndo, routes.renewalImportUndo, 'id', 'carrierName', true);
  }

  protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
      return await CommissionStore.getBackoutRenewalImports(pageIndex, pageSize, sortColumn, sortOrder);
  }

  protected async loadLookups(): Promise<void> { 
    var items = await ContractsStore.getCarrierDivisionLookup();
    this.divisionList = Utils.mapListItemsAsList(items, "");
  }

  @action setErrorVisible = (value: boolean) => {
    if(value)
    {
      this.backoutAlertDialogTitle = this.getDialogTitle();
    }
    this.isErrorVisible = value;
  }
  
  @action getDialogTitle()
  {
    let title = 'Are you sure you want to backout?';
      var curYear = new Date().getFullYear().toString()
    if(this.selectedYear !== curYear) {
        title = "Mismatch in the Carrier name entered. Please check and try again";
    }

    if(!this.selectedBackoutImport || this.selectedBackoutImport.length <= 0) {
        title ="Mismatch in the Carrier name entered. Please check and try again";
    }

    if(this.selectedBackoutImport.carrierName !== this.selectedCarrierName) {
        title = "Mismatch in the Carrier name entered. Please check and try again";
    }
    return title;
  }

  @action onDeleteSelectedBackoutImport = async () => {
    var curYear = new Date().getFullYear().toString()
    if(this.selectedYear !== curYear) {
        this.backoutAlertDialogTitle = "Mismatch in the Carrier name entered. Please check and try again";
        this.setErrorVisible(false);
        return;
    }

    if(!this.selectedBackoutImport || this.selectedBackoutImport.length <= 0) {
        this.backoutAlertDialogTitle ="Mismatch in the Carrier name entered. Please check and try again";
        this.setErrorVisible(false);
        return;
    }

    if(this.selectedBackoutImport.carrierName !== this.selectedCarrierName) {
        this.backoutAlertDialogTitle = "Mismatch in the Carrier name entered. Please check and try again";
      this.setErrorVisible(false);
      return
    }
    
    var carrierId = this.selectedBackoutImport.carrierId | 0;
    var importDate = this.selectedBackoutImport.dateCreated;
    await CommissionStore.backoutRenewalImport(carrierId, importDate);
             
  this.setErrorVisible(false);

  PageContext.setIsMessageVisible(true)
          let msg = {
            status: 200,
      title: 'Import Backed Out Successfully',
      errors: { "": [] },
  };          
          PageContext.setResponseMessage(JSON.stringify(msg));
          setTimeout(async () => {
           PageContext.setIsMessageVisible(false);
            this.selectedYear = "";
            this.selectedCarrierName = "";
            this.currentPage = 0;
            await this.loadItems();  
          }, 1500);
     
  }

  resetFiltersToDefault() {   
    this.isSuccess = false;
    this.pageSize = 10;
  }

}
