/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import IMASLog from "../../infrastructure/IMASLog";
import DocumentStore from "../../store/DocumentStore";
import { Constants } from "../../infrastructure/enum/Constants";
import { CustomerDocumentSearchListModel } from "../../services/DocumentService";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import PageContext from "../../infrastructure/PageContext";
import Utils from "../../infrastructure/Utils";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { Console } from "console";

export class CustomerDocsViewModel implements ViewModel {
  @action Load = async (id: number) => {
    this.customerId = id;
    this.isLoading = false;
    this.isResubmitConfirm = false;
    this.resetPagingValues();
    this.customerName = RouteParamDetails.customerName;
    this.resetOnload();
    this.loadDocs();
  };

  @action resetOnload = () => {
    this.isException = false;
    this.isMessgeVisible = false;
    this.exceptionMessage = "";
    this.isSuccess = false;
    this.response = "";
  };
  @observable customerName: string | undefined = "";
  @observable customerId: number = 0;
  @observable docId: number = 0;
  @observable docType: number = 0;
  // paging
  @observable rows: number = 25; // rows is pagesize
  @observable totalRecords: number = 0;
  @observable first: number = 0; // first is pageindex
  @observable startIndex: number = 0;

  // loader
  @observable isLoading: boolean = true;
  @observable isDeleteConfirm: boolean = false;
  @observable isRefaxConfirm: boolean = false;
  @observable isReftpConfirm: boolean = false;
  @observable isResubmitConfirm: boolean = false;
  // page input variables
  @observable searchInputValue: string = "";
  @observable searchValue: string | undefined = undefined;
  @observable selectedType: any;
  @observable selectedCompany: any;
  @observable sortColumn: string = "id";
  @observable sortOrder: boolean = false;

  // others
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable exceptionMessage: string = Constants.Error;
  @observable response: any;
  // lists variables
  @observable documentList = new Collection<CustomerDocumentSearchListModel>();
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isMessgeVisible: boolean = false;

  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedDocRow: any = {};
  @observable selectedsubmissionTypeIds: string = "0";
  @observable isResubmit: boolean = false;
  @observable selectedPolicyId: string = "0";
  @observable selectedsubmissionTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable public ErrorModel = new ErrorModel(
    new CustomerDocumentValidator()
  );
  @observable viewFiles: string[] = ["pdf", "png", "jpg", "jpeg", "txt"];
  @observable fileDocumentType: any;
  @observable isAcceptFileSizeReSubmit: boolean = false;
  @observable uploadedFileSize: number = 0;
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action checkUploadedFileSizeAccepted = async () => {
    if (
      this.uploadedFileSize &&
      this.uploadedFileSize >= 31 &&
      this.selectedsubmissionTypeIds &&
      this.selectedsubmissionTypeIds != "0" &&
      this.selectedsubmissionTypeIds.split("|").shift() == "0"
    )
      this.isAcceptFileSizeReSubmit = true;
    else this.isAcceptFileSizeReSubmit = false;      
  };

  @action onShow = () => {
    let okButton = document.getElementById("okButton");
    okButton && okButton.focus();
  };

  // function for all documents binding
  @action resetPagingValues() {
    this.rows = 25;
    this.totalRecords = 0;
    this.sortOrder = false;
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "id";
  }

  @action downloadDocument = (result: any) => {
    setTimeout(() => {
      var link = document.createElement("a");
      let filename = result.filename ? result.filename.split(".")[0] : "";
      link.href = "data:application/pdf;base64," + result.pdfData;
      link.download = filename !== "" ? filename : "document.pdf";
      link.textContent = "Download PDF";
      link.click();
      document.body.appendChild(link);
    }, 0);
  };

  @action setSelectedPolicyId = (value: string) => {
    this.selectedPolicyId = value;
  };

  @action deleteCustomerDoc = async (docId: number) => {
    this.isMessgeVisible = false;
    this.response = {};
    try {
      this.setDeleteConfirmation(false);
      await DocumentStore.deleteDocument(docId);
      this.isMessgeVisible = true;
      setTimeout(async () => {
        await this.loadDocs();
        this.isMessgeVisible = false;
      }, Utils.timeDelay_Delete());
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
      this.setDeleteConfirmation(false);
    }
    this.isLoading = false;
  };

  @action setDeleteConfirmation(value: boolean) {
    this.isDeleteConfirm = value;
  }

  @action setRefaxConfirmation(value: boolean) {
    this.isRefaxConfirm = value;
  }

  @action setReftpConfirmation(value: boolean) {
    this.isReftpConfirm = value;
  }

  @action setResubmitConfirmation(value: boolean) {
    this.isResubmitConfirm = value;
  }

  @action refaxOrReftpDoc = async (
    docId: number,
    app_type: string,
    companyId: string
  ) => {
    this.isMessgeVisible = false;
    this.response = {};
    var dto: any = {};
    if (app_type === "refax") {
      dto = {
        documentId: docId,
        isSendDocViaReFaxChecked: true,
        customerId: this.customerId,
        companyId: +companyId,
      };
    } else if (app_type === "reftp") {
      dto = {
        documentId: docId,
        isSendDocViaReFTPChecked: true,
        customerId: this.customerId,
        companyId: +companyId,
      };
    }
    try {
      this.setRefaxConfirmation(false);
      this.setReftpConfirmation(false);
      var result = await DocumentStore.CreateCustomerDocument(dto);
      if (result != null) {
        if (result.message && result.message !== null) {
          var responseModel = {
            status: 200,
            title: result.message,
            errors: { "": [] },
          };
          this.response = JSON.stringify(responseModel);
          this.isMessgeVisible = true;
          PageContext.setResponseMessage(this.response);
          PageContext.setIsMessageVisible(true);
        }
        setTimeout(async () => {
          await this.loadDocs();
          this.isMessgeVisible = false;
        }, Utils.timeDelay_Success());
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
      this.setRefaxConfirmation(false);
      this.setReftpConfirmation(false);
    }
    this.isLoading = false;
  };

  @action downloadDocumentLink = async (
    docId: number,
    docType: number,
    filename: string
  ) => {
    this.isMessgeVisible = false;
    this.response = {};
    try {
      var result = await DocumentStore.downloadFiles(docId, docType, false);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : filename,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action loadDocs = async () => {
    this.isException = false;
    try {
      var result = await DocumentStore.getCustomerDocuments(
        this.customerId,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (result != null) {
        if (result.data) {
          this.totalRecords = result.recordCount ? result.recordCount : 0;
          this.documentList.values = result.data;
        } else {
          this.documentList.values = [];
        }
      }
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
      this.documentList.values = [];
      this.isException = true;
    }
    this.isLoading = false;
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadDocs();
  }
  @action setSelectedCompany = (value: string) => {
    this.selectedCompany = value;
  };
  @action setSelectedType = (value: string) => {
    this.selectedType = value;
  };
  @action setSelectedDoc = (docId: number) => {
    this.docId = docId;
  };
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action setIsException(value: boolean) {
    this.isException = value;
  }

  // event for reset
  @action resetFilters = () => {
    this.isResubmitConfirm = false;
    this.selectedType = "0";
    this.selectedCompany = "0";
    this.searchValue = "";
    this.searchInputValue = "";
    this.resetPagingValues();
    this.loadDocs();
  };

  @action openDocumentLink = async (docId: number, docType: number) => {
    this.isException = false;
    try {
      // var result = await DocumentStore.downloadCustomerDoc(docId, docType)
      // if (result != null) {
      //     if (result.pdfData) {
      //         this.loadDocument(result.pdfData);
      //     }
      //     else {
      //         this.loadDocument("");
      //     }
      // }
      this.isMessgeVisible = false;
      //window.open(window.location.origin + "/customers/" + this.customerId + "/customerDocsViewer/" + docId + "/" + docType, '_new');
      window.open(
        window.location.origin +
          "/customers/" +
          this.customerId +
          "/customerDocsViewer/" +
          docId +
          "/" +
          docType,
        "_new"
      );
    } catch (e) {}
    this.isLoading = false;
  };

  @action loadDocument = (base64: string) => {
    let win = window.open("DocumentViewer", "_blank");
    let html = "";
    html += "<html>";
    html += '<body style="margin:0!important">';
    html +=
      '<embed width="100%" height="100%" src="data:application/pdf;base64,' +
      base64 +
      '" type="application/pdf" />';
    html += "</body>";
    html += "</html>";

    setTimeout(() => {
      if (win !== null) win.document.write(html);
    }, 0);
  };

  @action setIsResubmit = (value: boolean) => {
    this.isResubmit = value;
  };

    @action resubmitVisible = async() => {
        await this.ResetValidate();
        //await this.setResubmitConfirmation(false)
        await this.setIsResubmit(true)
        var result = await DocumentStore.getSubmissionTypesByDocumentId(+this.docId, +this.customerId)        
        if(result){
            this.selectedsubmissionTypeList.values =  this.mapListItemAndSort(result)
            this.selectedsubmissionTypeIds = this.selectedsubmissionTypeList.values[1].value;
            var documentResult = await DocumentStore.getFilesSizeById(+this.docId, 0);
            if (documentResult && documentResult.documentFileSize) {
                this.uploadedFileSize = documentResult.documentFileSize;
            } else { this.uploadedFileSize = 0; }
            this.checkUploadedFileSizeAccepted();
        }
    }

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      listObjects.splice(0, 0, { text: "Select", value: "0", selected: true });
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action CreateUpdateDocumentModel() {
    var selectedSubmissionObj = this.selectedsubmissionTypeList.values.filter(
      (item: any) => {
        return item.value === this.selectedsubmissionTypeIds;
      }
    );
    var dto: any = {
      customerId: this.customerId,
      isSendDocViaReSubmitChecked: true,
      documentId: +this.docId,
      submissionTypeIds: this.selectedsubmissionTypeIds,
      isSendSubmission: true,
      submissionTypeText:
        selectedSubmissionObj &&
        selectedSubmissionObj.length > 0 &&
        selectedSubmissionObj[0].label,
      policyId: +this.selectedPolicyId,
    };
    return dto;
  }

  @action resubmit = async () => {
    await this.ResetValidate();
    if (!(await this.Validate())) {
      var dto = this.CreateUpdateDocumentModel();
      await DocumentStore.CreateCustomerDocument(dto);
      await this.cancel();
    }
  };

  @action cancel = () => {
    this.setIsResubmit(false);
    this.isAcceptFileSizeReSubmit = false;
  };
}

class CustomerDocumentValidator extends AbstractValidator<CustomerDocsViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedsubmissionTypeIds)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Submit To is required");
  }
}
