import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../../store/ContractsStore";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import Utils from "../../../../infrastructure/Utils";
import { ReportFile } from "../../../../services/SupplyOrderService";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { routes } from "../../../../router";

export class ImportLicensePersistencyTypesViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { }

    @observable typeList = new Collection<any>();
    @observable typeId: string = "0";
    @observable isLoading: boolean = false;
    @observable fileData: any;
    @observable fileName: string = "";
    @observable ErrorModel = new ErrorModel(new ImportAgentCertificatesValidator());

    @action Load = async () => {
        await this.ResetValidate();
        this.typeId ="0";
        this.fileData="";
        await this.getCompanies();
    }

    @action async getCompanies() {
        this.isLoading = true;
        try {
            var result = await ContractsStore.getAllImportPersistencyLookup();
            Utils.mapListItemsToDropdown(result, this.typeList);
        } catch (e) {
            this.typeList.values = [];
            this.typeList.values.push({ label: "Select", value: "0" });
        }
        this.isLoading = false;
    }
    @action backToAdmin = async () => {
        routes.administration.push();
    }
    @action onDrop = (value: any, fileName: string) => {
        this.fileData = value;
        this.fileName = fileName;
    }
    @action Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action async process() {
        await this.ResetValidate();
        if (!await this.Validate()) {
            this.isLoading = true;

            var blob = null;
            if (this.fileData != null && this.fileData !== "" && this.fileData !== undefined) {
                // Split the base64 string in data and contentType
                var block = this.fileData ? this.fileData.split(";") : "";
                // Get the content type of the image
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                // Convert it to a blob to upload
                blob = Utils.b64toBlob(realData, contentType, 512);
            }
            try {
                var file = blob ? { data: blob, fileName: this.fileName } : null;
                var result = await ContractsStore.importPersistencyTypes(parseInt(this.typeId), file);

                if (result) {
                    this.downloadReport(result, result.fileName || "ContractPersistencyTypes");
                }
                
            } catch (e) {

            }
            this.isLoading = false;
        }
    }
    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };
}

class ImportAgentCertificatesValidator extends AbstractValidator<ImportLicensePersistencyTypesViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.typeId)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Import for is required");
        this.validateIf(input => input.fileData)
            .isNotEmpty()
            .withFailureMessage("File is required");
    }
}