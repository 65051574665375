import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./emailSearch.css";

interface Props {
  onReset: Function;
  onSearch: Function;
  onChange: Function;
  onKeyUp: Function;
  searchValue: string;
}

const EmailSearch = ({
  onReset,
  onSearch,
  onChange,
  onKeyUp,
  searchValue,
}: Props) => {
  return (
    <div className="email_search_cotainer">
      <div className="p-col-12 p-md-4 sm-order-4">
        <label htmlFor="txtSearchFor">Search For Email</label>
        <InputText
          id="txtSearchFor"
          type="search"
          placeholder="Search"
          icon="pi pi-search"
          value={searchValue}
          onChange={(e) => onChange(e.currentTarget.value)}
          onKeyUp={(e) => onKeyUp(e.key, e.currentTarget.value)}
        />
      </div>
      <div className="email_search_buttons">
        <Button
          icon="fa fa-search"
          label="Search"
          className="primary-button margin-right-10"
          onClick={() => onSearch()}
        />
        <Button
          id="btnReset"
          label="Reset"
          icon="fa fa-redo"
          className="secondary-button"
          onClick={() => onReset()}
        />
      </div>
    </div>
  );
};

export default EmailSearch;
