import { getToken } from "../infrastructure/Auth0Authenticator";
import { action, observable } from "mobx";
import UserContext from "../infrastructure/UserContext";
import {
  CreateAnnouncementModel,
  FileType,
  UpdateAnnouncementModel,
  WebLinkModel,
  Client as BUConfigServiceClient,
  UpdateAgentGroupModel,
  CreateAgentGroupModel,
  SubmissionSenderEmailTypeModel,
  SubmissionTypeEmailModel,
  SubmissionTypeFtpModel,
  RequirementSubmissionMethodRequest,
  CreateUpdateSubmissionMethodRequest,
  SubmissionAutoUncheckRulesetRequest,
  SubmissionTypesByCarrierIdRequest,
  ImportColumnDefinitionModel,
  ImportDefinitionModel,
  MarketingEventsReportRequest,
} from "../services/BusinessUnitConfigService";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import Collection from "../infrastructure/CollectionHelper";

class BusinessUnitConfigStore {
  @observable AnnouncementList = new Collection<any>();
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): BUConfigServiceClient => {
    return ServiceClient.getClient(ServiceType.BusinessUnitConfig);
  };
  //AgentGroup
  @action
  public getAgentGroupDetail = async (ID: number) => {
    try {
      var result = this.getClient().getAgentGroupById(
        ID,
        this.getApiVersion(),
        UserContext.userId
      );

      return result;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  public loadAgentGroupList = async (
    groups?: number[],
    users?: number[],
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = this.getClient().getAllAgentGroup(
      this.getApiVersion(),
      groups,
      users,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.userId
    );
    return result;
  };
  @action
  public addAgentGroup = async (dto: CreateAgentGroupModel) => {
    var result = this.getClient().createAgentGroup(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };
  @action
  public updateAgentGroup = async (dto: UpdateAgentGroupModel) => {
    var result = this.getClient().updateAgentGroup(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };

  @action
  public deleteAgentGroup = async (id: number) => {
    var result = this.getClient().deleteAgentGroup(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getAllUserLookup = async () => {
    var result = await this.getClient().usersLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllAgentGroupNameLookup = async () => {
    var result = await this.getClient().getAgentGroupNameLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllContractAgentGroupNameLookup = async () => {
    var result = await this.getClient().getContractAgentGroupNameLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  //Announcement
  @action
  public getAnnouncementDetail = async (ID: number) => {
    try {
      var result = this.getClient().getAnnouncementById(
        ID,
        this.getApiVersion(),
        UserContext.userId
      );

      return result;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  public loadAnnouncementList = async (
    securityLevel?:boolean|null,
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = this.getClient().getAllAnnouncement(
      this.getApiVersion(),
      securityLevel,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.userId
    );
    return result;
  };
  @action
  public addAnnouncement = async (dto: CreateAnnouncementModel) => {
    var result = this.getClient().createAnnouncement(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };
  @action
  public updateAnnouncement = async (dto: UpdateAnnouncementModel) => {
    var result = this.getClient().updateAnnouncement(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };

  @action
  public deleteAnnouncement = async (id: number) => {
    var result = this.getClient().deleteAnnouncement(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  // Web Links
  @action
  getWebLinkList = async (
    linkTypeId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string = "Title",
    isSortAscending: boolean = true
  ) => {
    var result = await this.getClient().getAllWeblinks(
      this.getApiVersion(),
      linkTypeId,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      null,
      UserContext.getUserId()
    );

    return result;
  };

  @action
  getWebLinkById = async (id: number): Promise<WebLinkModel> => {
    var result = await this.getClient().getWeblinkById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  updateWebLink = async (item: WebLinkModel) => {
    await this.getClient().updateWeblink(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  addWebLink = async (item: WebLinkModel) => {
    await this.getClient().addWeblink(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  deleteWebLink = async (id: number) => {
    await this.getClient().deleteWeblink(
      this.getApiVersion(),
      "" + id,
      UserContext.getUserId(),
      undefined,
      id
    );
  };

  @action
  getMarketingEventsReport = async (
    startDate?: Date,
    endDate?: Date,
    fileType?: FileType | undefined
  ) => {
    var request: MarketingEventsReportRequest = {
      startDate: startDate,
      stopDate: endDate,
      fileType: fileType,
    };
    var result = await this.getClient().getMarketingEventsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action public getCertificationsList = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string = "id",
    isSortAscending: boolean = true
  ) => {
    let result = {
      recordCount: 3,
      data: [
        {
          id: 1,
          company: "Accendo Insurance Company",
          product: "Final Expense (Immediate)",
          fullName: "LePage, Travis",
          certDate: "07/21/2019",
          year: "2020",
          user: "Nathan",
        },
        {
          id: 2,
          company: "Accendo Insurance Company",
          product: "Final Expense (Immediate)",
          fullName: "LePage, Travis",
          certDate: "07/21/2019",
          year: "2020",
          user: "Nathan",
        },
        {
          id: 3,
          company: "Accendo Insurance Company",
          product: "Final Expense (Immediate)",
          fullName: "LePage, Travis",
          certDate: "07/21/2019",
          year: "2020",
          user: "Nathan",
        },
      ],
    };
    return result;
  };

  @action public getSuppliesList = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string = "id",
    isSortAscending: boolean = true
  ) => {
    let result = {
      recordCount: 3,
      data: [
        {
          id: 1,
          company: "Accendo Insurance Company",
          description: "Final Expense (Immediate)",
          item: "LePage, Travis",
          status: "Nathan",
        },
        {
          id: 2,
          company: "Accendo Insurance Company",
          description: "Final Expense (Immediate)",
          item: "LePage, Travis",
          status: "Nathan",
        },
        {
          id: 3,
          company: "Accendo Insurance Company",
          description: "Final Expense (Immediate)",
          item: "LePage, Travis",
          status: "Nathan",
        },
      ],
    };
    return result;
  };

  @action getTodayAnnouncement = async (
    securityLevel?: boolean | null,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined
  ) => {
    let result = await this.getClient().getTodayAnnouncement(
      this.getApiVersion(),
      securityLevel,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllSubmissionSenderEmailTypes = async (
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    searchValue?: string | undefined
  ) => {
    let result = await this.getClient().getAllSubmissionSenderEmailTypes(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchValue,
      UserContext.getUserId()
    );
    return result;
  };

  @action getSubmissionSenderEmailTypeById = async (id: number) => {
    let result = await this.getClient().getSubmissionSenderEmailTypeById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteSubmissionSenderEmailType = async (id: number) => {
    let result = await this.getClient().deleteSubmissionSenderEmailType(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action addSubmissionSenderEmailType = async (
    body: SubmissionSenderEmailTypeModel
  ) => {
    let result = await this.getClient().addSubmissionSenderEmailType(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action updateSubmissionSenderEmailType = async (
    body: SubmissionSenderEmailTypeModel
  ) => {
    let result = await this.getClient().updateSubmissionSenderEmailType(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action getAllSubmissionTypes = async (
    typeId?: number | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    searchValue?: string | undefined
  ) => {
    return this.getClient().getAllSubmissionTypes(
      this.getApiVersion(),
      typeId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchValue,
      UserContext.getUserId()
    );
  };

  @action getSubmissionTypeById = async (
    id: number,
    submissionType: number
  ) => {
    return this.getClient().getSubmissionTypeById(
      id,
      submissionType,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action deleteSubmissionType = async (id: number, submissionType: number) => {
    return this.getClient().deleteSubmissionType(
      id,
      submissionType,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action addEmailSubmissionType = async (dto?: SubmissionTypeEmailModel) => {
    return this.getClient().addEmailSubmissionType(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action updateEmailSubmissionType = async (
    dto?: SubmissionTypeEmailModel
  ) => {
    return this.getClient().updateEmailSubmissionType(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action addFTPSubmissionType = async (dto?: SubmissionTypeFtpModel) => {
    return this.getClient().addFTPSubmissionType(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action updateFTPSubmissionType = async (dto?: SubmissionTypeFtpModel) => {
    return this.getClient().updateFTPSubmissionType(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action exportEmailSubmissionType = async () => {
    return this.getClient().exportEmailSubmissionType(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action exportFtpSubmissionType = async () => {
    return this.getClient().exportFtpSubmissionType(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action exportRequirementSubmissionRuleset = async (
    submissionRuleSetType: number,
    carrierId: number,
    documentTypeId: number
  ) => {
    return this.getClient().exportRequirementSubmissionRuleset(
      this.getApiVersion(),
      submissionRuleSetType || undefined,
      carrierId,
      documentTypeId,
      UserContext.getUserId()
    );
  };

  @action viewRequirementSubmissionMethods = async (
    submissionRulesetType?: number | undefined,
    documentTypeId?: number | undefined,
    companyId?: number | undefined,
    stateId?: number | undefined,
    productId?: number | undefined,
    productSubcategoryId?: number | undefined,
    policyEffectiveDateYear?: number | undefined
  ) => {
    return this.getClient().viewRequirementSubmissionMethods(
      this.getApiVersion(),
      submissionRulesetType,
      documentTypeId,
      companyId,
      stateId,
      productId,
      productSubcategoryId,
      policyEffectiveDateYear,
      UserContext.getUserId()
    );
  };

  @action getAllRequirementSubmissionMethods = async (
    submissionRulesetType?: number | undefined,
    documentTypeId?: number | undefined,
    carrierId?: number | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined
  ) => {
    return this.getClient().getAllRequirementSubmissionMethods(
      this.getApiVersion(),
      submissionRulesetType,
      documentTypeId,
      carrierId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
  };
  @action addRequirementSubmissionMethod = async (
    dto: RequirementSubmissionMethodRequest
  ) => {
    return this.getClient().addRequirementSubmissionMethod(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action updateRequirementSubmissionMethod = async (
    dto: RequirementSubmissionMethodRequest
  ) => {
    return this.getClient().updateRequirementSubmissionMethod(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action deleteRequirementSubmissionMethod = async (id: number) => {
    return this.getClient().deleteRequirementSubmissionMethod(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action getRequirementSubmissionMethodById = async (id: number) => {
    return this.getClient().getRequirementSubmissionMethodById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllSubmissionAutoUncheckRulesets = async (
    submissionRulesetType?: number | undefined,
    documentTypeId?: number | undefined,
    carrierId?: number | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined
  ) => {
    return this.getClient().getAllSubmissionAutoUncheckRulesets(
      this.getApiVersion(),
      submissionRulesetType,
      documentTypeId,
      carrierId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
  };

  @action addSubmissionAutoUncheckRuleset = async (
    dto: SubmissionAutoUncheckRulesetRequest
  ) => {
    return this.getClient().addSubmissionAutoUncheckRuleset(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action updateSubmissionAutoUncheckRuleset = async (
    dto: SubmissionAutoUncheckRulesetRequest
  ) => {
    return this.getClient().updateSubmissionAutoUncheckRuleset(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action deleteSubmissionAutoUncheckRuleset = async (id: number) => {
    return this.getClient().deleteSubmissionAutoUncheckRuleset(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action getSubmissionAutoUncheckRulesetById = async (id: number) => {
    return this.getClient().getSubmissionAutoUncheckRulesetById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action lookup = async () => {
    let result = await this.getClient().lookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action deleteSubmissionMethod = async (id: number) => {
    let result = await this.getClient().deleteSubmissionMethod(
      this.getApiVersion(),
      id,
      UserContext.getUserId()
    );
    return result;
  };

  @action getSubmissionMethodById = async (
    id: number,
    viewSubmission: boolean
  ) => {
    let result = await this.getClient().getSubmissionMethodById(
      id,
      viewSubmission,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getProductsByCarrierId = async (
    carrierIds: any,
    productSubcategoryIds: any
  ) => {
    let result = await this.getClient().getProductsByCarrierId(
      this.getApiVersion(),
      carrierIds,
      productSubcategoryIds,
      UserContext.getUserId()
    );
    return result;
  };

  @action getTranslatedFullFilePath = async (
    submissionRuleSetType: number,
    fileName: string,
    filePath: string
  ) => {
    let result = await this.getClient().getTranslatedFullFilePath(
      this.getApiVersion(),
      submissionRuleSetType,
      fileName,
      filePath,
      UserContext.getUserId()
    );
    return result;
  };

  @action getSubmissionTypeSearch = async (
    submissionRulesetType: number,
    documentTypeId: number,
    carrierId: number,
    stateId: number,
    productId: number,
    productSubCategoryId: number,
    policyEffectiveDateYear: number
  ) => {
    let result = await this.getClient().getSubmissionTypeSearch(
      this.getApiVersion(),
      submissionRulesetType,
      documentTypeId,
      carrierId,
      stateId,
      productId,
      productSubCategoryId,
      policyEffectiveDateYear,
      UserContext.getUserId()
    );
    return result;
  };

  @action exportSubmissionRuleset = async (
    submissionRuleSetType: number,
    carrierId: number,
    documentTypeId: number
  ) => {
    let result = await this.getClient().exportSubmissionRuleset(
      this.getApiVersion(),
      submissionRuleSetType || undefined,
      carrierId,
      documentTypeId,
      UserContext.getUserId()
    );
    return result;
  };

  @action addSubmissionMethod = async (
    body: CreateUpdateSubmissionMethodRequest
  ) => {
    let result = await this.getClient().addSubmissionMethod(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action updateSubmissionMethod = async (
    body: CreateUpdateSubmissionMethodRequest
  ) => {
    let result = await this.getClient().updateSubmissionMethod(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action getAllSubmissionMethods = async (
    submisisonMethodTypeId: number,
    documentTypeId: number,
    carrierId: number,
    startIndex: number,
    rows: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    let result = await this.getClient().getAllSubmissionMethods(
      this.getApiVersion(),
      submisisonMethodTypeId,
      documentTypeId,
      carrierId,
      startIndex,
      rows,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getSubmissionTypesByCarrierId = async (
    agentId: number,
    documentTypeId: number,
    companyId: number
  ) => {
    var model = {
      carrierId: companyId,
      documentTypeId: documentTypeId,
    } as SubmissionTypesByCarrierIdRequest;
    var result = await this.getClient().getSubmissionTypes(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };
  @action
  getAllAgentsbyGroupId = async (
    id: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    var result = await this.getClient().getAllAgentsByGroup(
      this.getApiVersion(),
      id,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      null,
      UserContext.getUserId()
    );

    return result;
  };

  @action
  downloadAllAgentByGroupReport = async (id: number, fileType: number) => {
    var result = await this.getClient().downloadAllAgentByGroupReport(
      this.getApiVersion(),
      id,
      fileType,
      UserContext.getUserId()
    );

    return result;
  };

  @action getImportColumnDefinitionByType = async (importType: number) => {
    var result = await this.getClient().getImportColumnDefinitionByType(
      importType,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action createOrUpdateImportColumnDefinition = async (
    dto: ImportColumnDefinitionModel
  ) => {
    var result = this.getClient().createOrUpdateImportColumnDefinition(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };

  @action public getAllImportDefinition = async (
    importType: number,
    carrierId: number,
    importName: string,
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = this.getClient().getAllImportDefinition(
      this.getApiVersion(),
      importType,
      carrierId,
      importName,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.userId
    );
    return result;
  };

  @action deleteImportDefinition = async (id: number) => {
    return await this.getClient().deleteImportDefinition(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
  };

  @action compileBusinessRule = async (rule: string) => {
    return await this.getClient().compileBusinessRule(
      this.getApiVersion(),
      rule,
      UserContext.getUserId(),
      undefined
    );
  };

  @action getImportDefinitionByType = async (
    importType: number | undefined,
    importId: number
  ) => {
    return await this.getClient().getImportDefinitionByType(
      this.getApiVersion(),
      importType,
      importId,
      UserContext.getUserId(),
      undefined
    );
  };

  @action copyImportDefinition = async (dto: ImportDefinitionModel) => {
    return await this.getClient().copyImportDefinition(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action createOrUpdateImportDefinition = async (
    dto: ImportDefinitionModel
  ) => {
    return await this.getClient().createOrUpdateImportDefinition(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action getCarrierBydefinition = async () => {
    var result = await this.getClient().getCarrierBydefinition(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
}

export default new BusinessUnitConfigStore();
