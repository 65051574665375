import { action, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import {
  CreateUpdateNoteModel,
  NoteModel,
} from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";

export class PagedList<T> {
  public recordCount: number = 0;
  public data: T[] = [];
}
export class CarrierCMNotesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable itemList = new Collection<NoteModel>();
  @observable cmSearchInputValue: string = "";
  @observable selectedStatus: string | null = "";
  @observable selectedCarrierGroup: any;
  @observable isCarrierToast: boolean = false;
  @observable isCmNotesPreview: boolean = true;
  @observable isCmNotesPreviewTemp: boolean = true;
  @observable isCmNotesAdd: boolean = false;
  @observable toasterIcon: string | null = "";
  @observable toasterMessage: string | null = "";
  @observable message: string = "";
  @observable cmNoteTitle: string = "";
  @observable cmNotesSearch: string = "";
  @observable cmNotesSaveBtnTxt: string =
    "This button is disabled because a title for your note must be entered.";
  @observable CarrierID: number = 0;
  @observable rowId: number = 0;
  @observable isEditNoteId: number = 0;
  @observable timer: any;
  @observable isInvalidMessage: boolean = false;
  @observable isCmNoteSave: boolean = false;
  @observable showToolTip: boolean = false;
  @observable isMaxLenghtExceed: boolean = false;
  @observable notesCount: number = 0;

  @observable
  public ErrorModel = new ErrorModel(new AddNoteValidator());
  @observable selectedRowData: any;
  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 50;
  @observable pageIndex: number = 0;

  @observable sortColumn: string = "createdDate";
  @observable isSortAscending: boolean = true;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable isPagination: string = "";
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 50;

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;

  @observable isShowCreateToastNotes: boolean = false;
  @observable isShowDeleteToastNotes: boolean = false;
  @observable isShowUpdatedToastNotes: boolean = false;
  @observable isShowDiscardedToastNotes: boolean = false;

  @observable isShowEditToastNotes: string = "";
  @observable NotesTitleToaster: string = "";

  @observable isNotesDeleteDialogVisible: boolean = false;
  @observable isNotesDiscardDialogVisible: boolean = false;
  @observable sort: boolean = false;
  @action Load = async (id: number) => {
    this.startIndex = 0;
    this.totalRecords = 0;
    this.first = 0;
    this.rows = 50;
    this.firstItem = 0;
    this.currentPage = 0;
    this.pageSize = 50;
    this.pageIndex = 0;
    this.sortColumn = "createdDate";
    this.isSortAscending = true;
    this.defaultSortColumn = undefined;
    this.defaultIsSortAscending = true;
    this.showToolTip = false;
    this.CarrierID = id;
    this.isCmNotesAdd = false;
    this.isCmNoteSave = false;
    this.cmNoteTitle = "";
    this.message = "";
    this.resetToasters();
    this.cmSearchInputValue = "";
    this.loadAllNotes(false);
  };
  @action resetToasters = () => {
    this.isShowCreateToastNotes = false;
    this.isShowUpdatedToastNotes = false;
    this.isShowDiscardedToastNotes = false;
    this.isShowDeleteToastNotes = false;
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;
    this.loadAllNotes(false);
  }

  @action noRecordsFound() {
    this.itemList.values = [];
  }

  @action handleToggle = (e: any) => {
    e.persist();
    this.isCmNotesPreview = e.target.checked;
    this.isCmNotesPreviewTemp = e.target.checked;
    this.isCmNotesAdd = false;
  };

  @action setMessage(value: string) {
    if (value && value.length > 0) {
      this.notesCount = value.replace(/<[^>]+>/g, "").length;
      this.isMaxLenghtExceed = this.notesCount > 5000;
      this.message = value;
    } else {
         this.message = "";
      this.cmNotesSaveBtnTxt =
        "This button is disabled because a title for your note must be entered.";
      this.isMaxLenghtExceed = false;
      this.notesCount = 0;
    }
  }
  @action setNoteTitle(value: string) {
    if (value.length == 1 && value == " ") {
      this.showToolTip = true;
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        this.showToolTip = true;
        return false;
      } else {
        this.showToolTip = false;
        this.cmNoteTitle = value;
        if (
          this.cmNoteTitle &&
          this.cmNoteTitle.length > 0 &&
          this.cmNoteTitle.trim().split(/ +/).join(" ").length
        ) {
          this.isCmNoteSave = true;
        } else {
          this.isCmNoteSave = false;
        }
        return true;
      }
    }
  }
  @action onFieldChange = (e: any) => {
    e.persist();
    this.cmSearchInputValue = e.currentTarget.value;
    if (
      this.cmSearchInputValue.length >= 3 ||
      this.cmSearchInputValue.length === 0
    ) {
      this.loadAllNotes(true);
    }
  };

  readonly currentPageReportTemplate: string =
    "Showing {first} - {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }
  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [50, 100, 150, 200] : [];
  }

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false; 
  @action handleClick = (id: string) => { 
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
    };

  @action loadAllNotes = async (isSearchCase: boolean) => {
    try {
      this.isLoading = true;
      this.itemList.values = [];

      const response = await ContractsStore.getAllNotes(
        this.CarrierID,
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sort,
        "" + this.cmSearchInputValue
      );
      if (response && response.data && response.data.length > 0) {
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.isPagination =
          this.totalRecords > 50 ? "" : "disable-cm-pagination";
        this.itemList.values = response.data;
        this.selectedRowData = this.itemList.values[0];
        if (isSearchCase) {
          this.isCmNotesPreview = this.isCmNotesPreviewTemp;
        }
        this.resetToasters();
      } else {
        this.resetToasters();
        // if (!isSearchCase) {
        this.isCmNotesPreview = false;
        // }
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.isSortAscending = this.defaultIsSortAscending;
  };

  @action handleAddNotes = () => {
    this.cmNoteTitle = "";
    this.message = "";
    this.isCmNotesPreview = true;
    this.isCmNotesPreviewTemp = true;
    this.isCmNotesAdd = !this.isCmNotesAdd;
  };
  @action handleSaveNotes = async () => {
    this.resetToasters();
    this.isCmNotesAdd = !this.isCmNotesAdd;
    if (this.isEditNoteId != 0) {
      this.updateNote(this.isEditNoteId);
    } else {
      localStorage.setItem("CMNotesEdit", "edit");
      this.resetToasters();
      let reqData: CreateUpdateNoteModel = {
        companyId: this.CarrierID,
        title: this.cmNoteTitle,
        note: this.message,
      };
      try {
        this.isLoading = true;
        const response = await ContractsStore.addNote(reqData);
        if (response && response) {
          this.loadAllNotes(false);
          this.isCmNotesAdd = false;
          this.isCmNoteSave = false;
          this.cmNoteTitle = "";
          this.message = "";
          this.selectedRowData = undefined;
          clearTimeout(this.timer);
          this.timer = setTimeout(async () => {
            // this.isShowCreateToastNotes = true;
            Utils.showSuccessToaster(`Note created.`, 7000, 500);
            localStorage.removeItem("CMNotesEdit");
          }, 300);
        }
        this.isLoading = false;
      } catch (e: any) {
        this.isLoading = false;
      }
    }
  };

  @action deleteNote = async () => {
    this.isCmNotesAdd = false;
    localStorage.setItem("CMNotesDelete", "delete");
    this.resetToasters();
    try {
      this.isLoading = true;
      const response = await ContractsStore.deleteNote(this.selectedRowData.id);
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        // this.isShowDeleteToastNotes = true;
        Utils.showSuccessToaster(`Note deleted.`, 7000, 500);
        localStorage.removeItem("CMNotesDelete");
      }, 300);

      this.isCmNotesAdd = false;
      this.isCmNoteSave = false;
      this.isNotesDeleteDialogVisible = false;
      this.rowId = 0;
      this.cmNoteTitle = "";
      this.message = "";
      this.selectedRowData = undefined;
      this.loadAllNotes(false);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action setNotesDeleteDialogVisible = async (value: boolean) => {
    this.resetToasters();
    this.isNotesDeleteDialogVisible = value;
  };
  @action handleDiscardNote = (value: boolean) => {
    this.isNotesDiscardDialogVisible = value;
  };
  @action DiscardNote = (value: boolean) => {
    localStorage.setItem("CMNotesDiscard", "discard");
    this.isNotesDiscardDialogVisible = value;
    this.isCmNotesAdd = false;
    this.isEditNoteId = 0;
    this.loadAllNotes(false);
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      // this.isShowDiscardedToastNotes = true;
      Utils.showSuccessToaster(`Note discarded.`, 7000, 500);
      localStorage.removeItem("CMNotesDiscard");
    }, 300);
  };
  @action cancelNotesDelete = async (value: boolean) => {
    this.isNotesDeleteDialogVisible = value;
  };
  @action editNote = async (data: any) => {
    this.isCmNoteSave = true;
    this.isEditNoteId = data.id;
    this.selectedRowData = undefined;
    this.isCmNotesAdd = true;
    this.isCmNotesPreview = true;
    this.isCmNotesPreviewTemp = true;
    this.cmNoteTitle = data.title;
    this.message = data.note;
  };

  @action updateNote = async (id: number) => {
    this.isCmNotesAdd = !this.isCmNotesAdd;
    localStorage.setItem("CMNotesEdit", "edit");
    this.resetToasters();
    let reqData: CreateUpdateNoteModel = {
      id: id,
      companyId: this.CarrierID,
      title: this.cmNoteTitle,
      note: this.message,
    };
    try {
      this.isLoading = true;
      const response = await ContractsStore.updateNote(reqData);
      this.loadAllNotes(false);
      this.isCmNotesAdd = false;
      this.isCmNoteSave = false;
      this.cmNoteTitle = "";
      this.message = "";
      this.selectedRowData = undefined;
      this.isLoading = false;
      this.isEditNoteId = 0;
      this.selectedRowData = this.itemList.values[0];
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        // this.isShowUpdatedToastNotes = true;
        Utils.showSuccessToaster(`Note updated.`, 7000, 500);
        localStorage.removeItem("CMNotesEdit");
      }, 300);
      this.isCmNoteSave = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };
}
class AddNoteValidator extends AbstractValidator<CarrierCMNotesViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.message)
      .isNotEmpty()
      .withFailureMessage("Message is required.");
  }
}
