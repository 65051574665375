import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList"; 
import Utils from "../../../infrastructure/Utils"; 
import { Constants } from "../../../infrastructure/enum/Constants";
import PageContext from "../../../infrastructure/PageContext"; 
import ContractsStore from "../../../store/ContractsStore";
import { routes } from "../../../router";
import Collection from "../../../infrastructure/CollectionHelper";
import { FileParameter } from "../../../services/ContractService";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../components/controls/IMASTemplates";
import { Permission } from "../../../infrastructure/enum/Permission";


export class ContractBulkUploadViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    } 
    Route = async (currentRoute: RouteList): Promise<void> => { }    
    
    @observable isLoading: boolean = false; 
    @observable isFileuploadReset: boolean = false; 
    @observable response: any;
    @observable fileData: any = null;
    @observable fileName: string = "";
    @observable isMessgeVisible: boolean = false;
    @observable isAcceptFileSizeLimit: boolean = true;
    @observable isAcceptFileSize: boolean = false;
    @observable isSubmissionChecked: boolean = false;
    @observable isSubmissionEnabled: boolean = true;
    @observable selectedSubmission: string = '';
    @observable disableUpload: boolean = true;
    @observable selectedTabIndex?: number = 0;
    @observable selectedPanel: string = 'Contracts';
    @observable firstItem: number = 0;
    @observable currentPage: number = 0;
    
    @observable pageSize: number = 10;
    @observable sortColumn: string = 'createdOn';
    @observable isSortAscending: boolean = true;
    @observable defaultSortColumn: string = 'createdOn';
    @observable defaultIsSortAscending: boolean = true;
    @observable totalRecords: number = 10; 

    @observable firstBatch: number = 0;
    @observable pageSizeBatch: number = 10;
    @observable sortColumnBatch: string = 'status';
    @observable isSortAscendingBatch: boolean = true;
    @observable defaultSortColumnBatch: string = 'status';
    @observable defaultIsSortAscendingBatch: boolean = true;
    @observable totalRecordsBatch: number = 10; 
    @observable startIndexBatch: number = 0; 
    @observable rowsBatch: number = 10; 
    @observable batchId: number = 0; 
    
    @observable itemList = new Collection<T>();
    @observable fileParameter = new Collection<FileParameter>();
    @observable sort: boolean = true; 
    @observable rows: number = 10; 
    @observable first: number = 0;
    @observable startIndex: number = 0;
    @observable hideFileName: boolean = false;
    @observable isFileUploadReset: boolean = false;
    @observable fileUploadKey:string = 'file-upload-key';
      
    @action Load = async () => {
      this.selectedPanel = 'Contracts';
      this.handleTabClick(0, 'Contracts');
      this.resetGrid();
      this.handleHistoryView(0, false);
      this.disableUpload = true;
    };
    @action downloadReport = async (result: any) => {
        if (result != null) {
            if (result.data) {
                Utils.downloadDocument(
                    result.data,
                    result.fileName !== null &&
                        result.fileName !== undefined
                        ? result.fileName
                        : "BulkContracts_Upload.xlsx",
                    result.fileName !== null &&
                        result.fileName !== undefined
                        ? result.fileName
                        : "BulkContracts_Upload.xlsx"
                );
            }
        }
    };

    @action handledisableUpload = (value:boolean) => {
      this.disableUpload = value;
    }
    @action onDrop = (value: any, fileName: string) => {
        this.fileData = value;
        this.fileName = fileName;
        // this.disableUpload = false;        
    }
    showLongFileNameError() {
        this.isMessgeVisible = false;
        this.response = {};
        var responseModel = {
            status: 400,
            title: Constants.LongFileName,
            errors: { "": [] },
        };
        this.isMessgeVisible = true;
        PageContext.setIsMessageVisible(false);
        this.response = JSON.stringify(responseModel);
    }
    @action checkUploadedFileSizeAccepted = async () => {
        if (this.isSubmissionChecked && !this.isAcceptFileSizeLimit && this.selectedSubmission && this.selectedSubmission != "0" && this.selectedSubmission.split('|').shift() == "0"){
        }else{
            this.isAcceptFileSize = false;
        }
    }


    

  @action hideValidationToaster = () => { 
    toast.dismiss("validation-toaster"); 
  };


  @action readURL(acceptedFiles: any) {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = (_event) => {
        this.fileData = reader.result;
      };
    }
  }


  @observable timer: any;
    @action uploadContract = async () => {
      var fileNameExtension = this.fileName
        .substring(this.fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      var fileNamewithoutExtension = this.fileName.substring(
        0,
        this.fileName.lastIndexOf(".")
      );
      var formattedFileName = fileNamewithoutExtension + "." + fileNameExtension;
      var blob = null;
      if (
        this.fileData != null &&
        this.fileData !== "" &&
        this.fileData !== undefined
      ) {
        // Split the base64 string in data and contentType
        var block = this.fileData ? this.fileData.split(";") : "";
        // Get the content type of the image
        var contentType = block[0].split(":")[1]; // In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
  
        // Convert it to a blob to upload
        blob = Utils.b64toBlob(realData, contentType, 512);
      }
        
        this.fileParameter.data = blob;
        this.fileParameter.fileName = formattedFileName;
        try { 
            var apiRes = await ContractsStore.bulkUpdateContracts(this.fileParameter);
            this.disableUpload = true; 
            this.fileUploadKey = "file-upload-key"+Math.random();
            Utils.showSuccessToaster(`${this.fileParameter.fileName} uploaded successfully. `, 7000, 500);
        }
        catch (e: any) {  
          e.text().then((_responseText:any) => {
            let _title = JSON.parse(_responseText).title; 
            toast.warning(_title, {
                position: toast.POSITION.TOP_RIGHT,
                closeOnClick: false,
                closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
                className: "validation-toaster contract",
                draggable: false,
                toastId: "validation-toaster",
                autoClose: 7000
            });
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
              this.hideValidationToaster();
            },  7000); 
          });
            return;
        }
    };
  


    @action goToAdmin = () => { 
      routes.administration.push();
    };

    @action resetGrid = async () => {
      this.first = 0;
      this.startIndex = 0;
      this.pageSize = 10;
      this.rows = 10;
      this.sortColumn = 'createdOn';
      this.isSortAscending = true;
    }
    @action bulkUpdateContractsHistory = async (isRefresh:boolean) => {
      isRefresh && await this.resetGrid();
      var result = await ContractsStore.bulkUpdateContractsQueue(this.startIndex, this.pageSize, this.sortColumn || this.defaultSortColumn , !this.isSortAscending, "");
          if (result !== null && result !== undefined && result.data !== null) {
              this.itemList.values = result.data ? result.data : [];
              this.totalRecords = result.recordCount
                  ? result.recordCount
                  : 0;
          } else {
              this.itemList.values = [];
          }
    }
  
    @action setSelectedPanel = async (value: string) => {
      this.selectedPanel = value;
      if (value == 'Contracts') { 
      } else {
          await this.bulkUpdateContractsHistory(false);
      }
    };
    @action handleTabClick = (index: number | undefined, key: string): void => {
      this.handleHistoryView(0, false);
      this.selectedTabIndex = index;
      this.setSelectedPanel(key);
    };

    @action bulkUpdateContractsTemplates = async () => {
        var result = ContractsStore.bulkUpdateContractsTemplate();
        return result;
    };

    @action setSortOrder() {
      this.isSortAscending = !this.isSortAscending; 
    }
    @action setSortOrderBatch() {
      this.isSortAscendingBatch = !this.isSortAscendingBatch;
      
    }

    @action setSortColumnBatch(column: string) {
      this.sortColumnBatch = column;
    }
    @action setSortColumn(column: string) {
      this.sortColumn = column;
    }

    @action onPage = async(firstIndex: number, rows: number) => {
      this.rows = rows;
      this.pageSize = rows;
      this.first = firstIndex;
      this.startIndex = firstIndex / this.rows;
      await this.bulkUpdateContractsHistory(false);
    }  
    
    @action resetBatchGrid = async () => {
      this.firstBatch = 0;
      this.pageSizeBatch = 10 ;
      this.sortColumnBatch = 'status';
      this.isSortAscendingBatch = true;
      this.startIndexBatch = 0;
    }
    
    @observable isHistoryModal:boolean = false;
    @observable historyData:any;

    @action onPageBatch = async(firstIndex: number, rows: number) => {
      this.rowsBatch = rows;
      this.pageSizeBatch = rows;
      this.firstBatch = firstIndex;
      this.startIndexBatch = firstIndex / this.rowsBatch;
      await this.handleHistoryView(this.batchId, true);
    }  

    @action handleHistoryView = async (batchId:number, historyModal:boolean) => {
      this.batchId = batchId || 0;
      // await this.resetBatchGrid();
        if(batchId > 0){
          let res =  await ContractsStore.bulkUpdateContractsHistory(batchId, this.startIndexBatch, this.pageSizeBatch, this.sortColumnBatch || this.defaultSortColumnBatch , this.isSortAscendingBatch, "");
          if(res){
            this.historyData = res.data;
            this.isHistoryModal =  historyModal;
            this.totalRecordsBatch = res.recordCount
            ? res.recordCount
            : 0;
          }
        }else{
          this.historyData = [];
          this.isHistoryModal =  false;
        }
        
    }
}