import { observer } from "mobx-react";
import React from "react";
import { Repeater } from "../Repeater";
import classNames from "classnames";
import NumberFormat from "react-number-format";

export const IMASFormattedInput: React.FC<{
  errors: string[] | undefined;
  onKeyDown?: (event: Event) => void;
  handleEnterKey: Function;
  autoFocus?: boolean;
  label: string;
  value: string | undefined;
  id: string;
  format?: string;
  readonly?: boolean;
  allowNegative?: boolean;
  mask?: string;
  placeHolder?: string;
  className?: string;
  isrequired: boolean;
  isNumericString?: boolean;
  thousandsGroupStyle?: "thousand" | "lakh" | "wan";
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  isAllowed?: (value: any) => boolean;
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
}> = observer((props) => {
  const getClassNameError = () => {
    if (props.errors) {
      return classNames("p-inputtext p-component", {
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    } else {
      return classNames("p-inputtext p-component");
    }
  };
  return (
    <>
      <label
        className={props.isrequired ? "asterik-required" : ""}
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <div onKeyDown={(e) => props.handleEnterKey(e)}>
        <NumberFormat
          autoFocus={props.autoFocus}
          format={props.format}
          mask={props.mask}
          className={props.className ? props.className : getClassNameError()}
          placeholder={props.placeHolder}
          readOnly={props.readonly ? props.readonly : false}
          id={props.id}
          allowNegative={props.allowNegative}
          value={props.value}
          isNumericString={props.isNumericString}
          decimalScale={props.decimalScale}
          fixedDecimalScale={props.fixedDecimalScale}
          thousandSeparator={props.thousandSeparator}
          isAllowed={props.isAllowed}
          onBlur={(event) => props.onBlur(event.currentTarget.value)}
          onChange={(event) => props.onChange(event.currentTarget.value)}
          onFocus={(e) => {
            let div = document.getElementById(props.id) as HTMLInputElement;
            if (div) {
              div.select();
            }
          }}
        />
      </div>

      <Repeater
        errors={props.errors}
        viewId={props.id}
        child={(error) => <label className="validation-error">{error}</label>}
      />
    </>
  );
});
