import React from "react";
import "./banner.css";
import exclamation from "../../assets/square-exclamation.svg"

interface Props {
  children: string | (string | JSX.Element)[];
  type: "warning" | "success" | "primary" | "danger";
}

const Banner = ({ children, type }: Props) => {
  return (
    <div className="banner_container">
      <div data-testid="state" className={["banner_icon_container", `banner_${type}`].join(" ")}>
        <img alt="!" src={exclamation} className="banner_banner_icon" />
      </div>
      <div className="banner_body">{children}</div>
    </div>
  );
};
export default Banner;
