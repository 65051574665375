import { AbstractValidator} from 'fluent-ts-validator';
import { observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from '../../../components/BaseAddEditViewModel';
import Utils from '../../../../infrastructure/Utils';
import Collection from '../../../../infrastructure/CollectionHelper';
import ProductStore from '../../../../store/ProductStore';


export class ProductSubCategoryAddEditViewModel extends BaseAddEditViewModel {

    @observable productCategoryId: number = 0;
    @observable productLineId: number = 0;
    @observable subCategoryName: string = "";
    @observable productClass: string = "";
    @observable productTypeCode: string = "";
    @observable marginFactor: string = "" ;
    @observable productCategories = new Collection<{ label: string; value: string; }>();
    @observable productLines = new Collection<{ label: string; value: string; }>();

    constructor()
    {
        super("Product SubCategory", routes.listProductSubCategory, new ProductSubCategoryValidator());
    }

    protected toServiceModel() {
        return {
            id: this.selectedId,
            categoryId: this.productCategoryId,
            productLineId: this.productLineId,
            subCategoryName: this.subCategoryName,
            productClass: this.productClass,
            productTypeCode: this.productTypeCode,
            marginFactor: this.marginFactor ? parseFloat(this.marginFactor): 0
        };
    }

    protected resetModel() {
        this.productCategoryId = 0;
        this.productLineId = 0;
        this.subCategoryName = "";
        this.productClass = "";
        this.productTypeCode = "";
        this.marginFactor = "";
    }

    protected async loadLookups(): Promise<void> {
        if(this.selectedId === 0){
            this.loadItem(0)
        }
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await ProductStore.getProductSubCategoryById(id);
        if (item) {
            this.selectedId = item.id || 0;
            this.productCategoryId = item.categoryId || 0;
            this.productLineId = item.productLineId || 0;
            this.subCategoryName = item.subCategoryName || "";
            this.productClass = item.productClass || "";
            this.productTypeCode = item.productTypeCode || "";
            this.marginFactor = (item.marginFactor != undefined && item.marginFactor >0) ?''+item.marginFactor:  "";
            Utils.mapListItemsToDropdown(item.productCategories ? item.productCategories : [], this.productCategories, "Select a Category");
            Utils.mapListItemsToDropdown(item.productLines ? item.productLines : [], this.productLines, "Select a Product Line");
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await ProductStore.addProductSubCategory(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await ProductStore.updateProductSubCategory(item);
    }
}

class ProductSubCategoryValidator extends AbstractValidator<ProductSubCategoryAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfNumber((input) => input.productCategoryId)
            .isGreaterThan(0)
            .withFailureMessage("Please select a Product Category");

        this.validateIfNumber((input) => input.productLineId)
            .isGreaterThan(0)
            .withFailureMessage("Please select a Product Line");

        this.validateIfString(input => input.subCategoryName)
            .hasMaxLength(100)
            .matches(/\S+/,"i")
            .isNotEmpty()
            .withFailureMessage("Name is required and should be less than 50 characters");

            // this.validateIfString(input => input.marginFactor)
            // .isNotEmpty()
            // .withFailureMessage("Margin Factor is required");

    }
}
