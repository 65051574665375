import React, { FunctionComponentElement, useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { AppCoordinator } from './coordinators/AppCoordinator';
import loggedInUser from './Auth0Authenticator';
import axios from 'axios';
import Utils from './Utils';
import { registerFetchInterceptor } from './fetchInterceptor';
import Bootstrapper from './Bootstrapper';
import { AppViewModel } from '../viewModels/AppViewModel';
import { AddEditMarketingEventViewModel } from '../viewModels/home/AddEditMarketingEventViewModel';
import { MarketingEventDetailViewModel } from '../viewModels/home/MarketingEventDetailViewModel';
import { MarketingEventsViewModel } from '../viewModels/home/MarketingEventsViewModel';
import { HomeViewModel } from '../viewModels/home/HomeViewModel';
import { DashboardViewModel } from '../viewModels/DashboardViewModel';
import { ErrorViewModel } from '../viewModels/ErrorViewModel';
import { RenewalHistoryViewModel } from '../viewModels/agents/policies/RenewalHistoryViewModel';
import { CreditCheckHistoryViewModel } from '../viewModels/agents/CreditCheckHistoryViewModel';
import { EmailViewModel } from '../viewModels/emails/EmailViewModel';
import { AutoDialerViewModel } from '../viewModels/autoDialers/AutoDialerViewModel';
import { ExistingCallListViewModel } from '../viewModels/autoDialers/ExistingCallListViewModel';
import { StartCallingViewModel } from '../viewModels/autoDialers/StartCallingViewModel';
import { AppView } from '../views/AppView';
import { AddEditMarketingEventView } from '../views/home/AddEditMarketingEventView';
import { MarketingEventDetailView } from '../views/home/MarketingEventDetailView';
import { MarketingEventsView } from '../views/home/MarketingEventsView';
import { HomeView } from '../views/home/HomeView';
import DashboardView from '../views/DashboardView';
import { ErrorView } from '../views/ErrorView';
import { RenewalHistoryView } from '../views/agents/policies/RenewalHistoryView';
import { CreditCheckHistoryView } from '../views/agents/CreditCheckHistoryView';
import { EmailView } from '../views/emails/EmailView';
import AutoDialerView from '../views/autoDialers/AutoDialerView';
import { ExistingCallListView } from '../views/autoDialers/ExistingCallListView';
import { StartCallingView } from '../views/autoDialers/StartCallingView';
import * as serviceWorker from "../serviceWorker";
import * as dateHelper from "../infrastructure/DateHelper";

function App() {
  const {
    user: auth0User,
    isAuthenticated,
    logout,
    getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsloading] = useState(true);
  const [display, setDisplay] = useState<FunctionComponentElement<any> | null>(null);

  const bindBootstrapper = () => {
    Bootstrapper.bind(AppViewModel, AppView);
    Bootstrapper.bind(AddEditMarketingEventViewModel, AddEditMarketingEventView);
    Bootstrapper.bind(MarketingEventDetailViewModel, MarketingEventDetailView);
    Bootstrapper.bind(MarketingEventsViewModel, MarketingEventsView);
    Bootstrapper.bind(HomeViewModel, HomeView);
    Bootstrapper.bind(DashboardViewModel, DashboardView);
    Bootstrapper.bind(ErrorViewModel, ErrorView);
    Bootstrapper.bind(RenewalHistoryViewModel, RenewalHistoryView);
    Bootstrapper.bind(CreditCheckHistoryViewModel, CreditCheckHistoryView);
    Bootstrapper.bind(EmailViewModel, EmailView);
    Bootstrapper.bind(AutoDialerViewModel, AutoDialerView);
    Bootstrapper.bind(ExistingCallListViewModel, ExistingCallListView);
    Bootstrapper.bind(StartCallingViewModel, StartCallingView);
  }

  const createProviderUser = (accessToken: string) => {
    let authKey;
    if (auth0User) {
      localStorage.setItem('auth0UserItem', JSON.stringify(auth0User));
      localStorage.setItem("userLogin", "userLogin");
      loggedInUser.displayName = auth0User && auth0User.name || '';
      loggedInUser.initialsName = auth0User && auth0User.nickname || '';
      loggedInUser.userName = auth0User && auth0User.email || '';
      loggedInUser.adUserId = auth0User && auth0User.sub || '';
    }
    if (accessToken) {
      localStorage.setItem('auth0offline_access', accessToken);
      authKey = `Bearer ${accessToken}`;
    }
    const url = Utils.getServiceUrlByEnvironment();
    var data = {
      Email: loggedInUser.userName,
      providerName: loggedInUser.displayName,
      providerID: auth0User && auth0User.sub,
      codeInvite: localStorage.getItem('invite_code') || '',
    };
    let host = Utils.getHost();
    const headers = {
      'Content-Type': 'application/json',
      hostname: host,
      Authorization: authKey,
      code_invite: localStorage.getItem('invite_code') || '',
    };
    axios
      .post(url + '/api/identity/v1/users/createProviderUser', data, {
        headers: headers,
      })
      .then(() => {
        setIsloading(false);
      })
      .catch((error) => {
        if (
          error.response == undefined ||
          error.response.data.title === '-1'
        ) {
          localStorage.clear();
          setIsloading(false);
          return logout({
            logoutParams: { returnTo: window.location.origin },
          });
        } else {
          setIsloading(false);
        }
      });
  }

  useEffect(() => {
    bindBootstrapper();
    if (isAuthenticated) {
      setIsloading(true);
      getAccessTokenSilently(
        {
          authorizationParams: {
            audience: Utils.getAuth0ClientIdByEnvironment("audience")
          },
        }
      ).then((accessToken) => {
        createProviderUser(accessToken);
        registerFetchInterceptor();
        serviceWorker.unregister();
        dateHelper.initialize();
      })
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (!isLoading && isAuthenticated && !display) {
    const app = new AppCoordinator();
    app.start().then(() => {
      setDisplay(app.display());
    });
  }

  if (display) return display;

  return <div className="Splash-Screen"></div>;
}

export default withAuthenticationRequired(App);
