import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../../infrastructure/IMASLog";
import AccountStore from "../../../store/AccountingStore";
import Utils from "../../../infrastructure/Utils";

import { routes } from "../../../router";
import {
  CreateUpdatePaymentPlanModel,
  AgentProfileRequest,
  Profile,
} from "../../../services/AccountingService";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import Collection from "../../../infrastructure/CollectionHelper";

export class AddEditPaymentPlanViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable paymentPlanId: number = 0;
  @observable specialInstruction: string = "";
  @observable contractId: number = 0;
  listener: AddEditPaymentPlanListener | undefined;
  @observable paymentPlan: AgentProfileRequest | undefined;
  @observable profile: Profile | undefined;
  @observable isFromLevel: boolean = false;
  @observable isInitial: boolean = true;
  @action Load = async (
    agentId: number,
    paymentPlanId: number | undefined,
    isFromLevel?: boolean | undefined
  ) => {
    this.isAdd = false;
    this.isInitial = true;
    this.agentId = agentId;
    this.contractId = RouteParamDetails.contractId;

    if (paymentPlanId) {
      this.isEdit = true;
      this.isInitial && this.loadPaymentSource(true);
      this.title = "Edit Payment Plan";
    } else {
      this.isAdd = true;
      this.loadPaymentSource(true);
      this.isEdit = false;
      this.title = "Add Payment Plan";
    }
    if (isFromLevel) {
      IMASLog.log("its from levels...");
      this.isFromLevel = isFromLevel;
    }
    this.setIsSuccess(false);
    this.setIsException(false);
    this.ResetValidate();
    this.resetAllFields();
    await this.loadCompanies();
    if (paymentPlanId) {
      this.paymentPlanId = paymentPlanId;
      await this.loadPaymentPlanDetails();
    } else {
      this.paymentPlanId = 0;

      await this.loadProducts();
      if (this.product !== "0") {
        await this.loadLevels();
      }
      await this.loadCommPlan(this.isShowAll);
      if (this.productRef !== "0") {
        await this.loadMarketers();
        await this.loadPay1stYearTo();
      }

      if (this.isFromLevel) {
        // get level id from route and get data
        var levelId = RouteParamDetails.levelId;
        if (levelId != null && levelId > 0) {
          // get data product data by level id and compare with product list
          await this.loadProductDetailByLevelId(levelId);
        }
      }
    }
    if (this.profile && this.profile !== undefined) {
      await this.loadPay1stYearTo();
    }
  };
  @observable
  public ErrorModel = new ErrorModel(new PaymentPlanValidator());
  setListener(listener: AddEditPaymentPlanListener) {
    this.listener = listener;
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable isLoading: boolean = false;
  @observable agentId: number = 0;
  @observable isEdit: boolean = false;
  @observable isAdd: boolean = false;
  @observable isCarrierDisabled: boolean = false;
  @observable isShowAll: boolean = false;
  @observable title: string = "Add Payment Plan";
  @observable commPlan: string = "0";
  @observable paymentSource: string = "1";
  @observable product: string = "0";
  @observable productRef: string = "0";
  @observable paymentPlanStatus: string = "A";
  @observable commPlanStatus: string = "1";
  @observable companyName: string = "0";
  @observable marketer: string = "0";
  @observable paymentTo: string = "0";
  @observable comment: string = "";
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable exceptionMsg: string = "Something went wrong";
  @observable commPlanChange: boolean = false;
  @observable isShowallChange: boolean = false;
  @observable isCalledFromEditContract: boolean = false;
  @observable paymentSourceList = [
    { label: "Premium", value: "1" },
    { label: "Commission", value: "2" },
  ];

  @observable paymentToList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable paymentPlanStatusList = [
    { label: "Active", value: "A" },
    { label: "History", value: "H" },
  ];
  @observable commPlanStatusList = [
    { label: "Active", value: "1" },
    { label: "History", value: "2" },
  ];
  @observable marketerList = [{ label: "Lisa Cooke", value: "0" }];
  @observable commPlanList = [{ label: "Direct Pay", value: "0" }];
  @observable companyNameList = [
    { label: "Great Western - PPL", value: "Great Western - PPL" },
  ];
  @observable productList = [
    { label: "Assurence Plus", value: "Assurency Plus" },
  ];
  @observable productRefList = [
    { label: "LOA Lisa Cooke 10% / Steven 5% ", value: "0" },
  ];

  @action setShowAll = async (value: boolean) => {
    this.isShowAll = value;
  };

  @action setCompanyName = async (value: string) => {
    this.companyName = value;
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setIsLoading(value: boolean) {
    this.isLoading = value;
  }
  @action setProduct = async (value: string) => {
    this.product = value;
  };
  @action setProductRef = async (value: string) => {
    this.productRef = value;
    await this.loadSpecialInstructions();
  };
  @action setCommisionPlan = async (value: string) => {
    this.commPlanChange = true;
    this.commPlan = value;
    if (value && value !== "0") {
      ((this.isEdit && !this.isInitial) || this.isAdd) &&
        this.loadPaymentSource(true);
    }
  };
  @action setPaymentPlanStatus(value: string) {
    this.paymentPlanStatus = value;
  }
  @action setCommPlanStatus = async (value: string) => {
    this.commPlanStatus = value;
  };
  @action setPaymentSource = async (value: string) => {
    this.paymentSource = value;
  };
  @action setPaymentTo(value: string) {
    this.paymentTo = value;
  }
  @action setMarketer = async (value: string) => {
    this.marketer = value;
  };
  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action setComment(value: string) {
    this.comment = value;
  }
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
  @action goBack(delay: number) {
    if (this.isCalledFromEditContract) {
      routes.editContractPaymentPlans.replace({
        agentId: "" + this.agentId,
        contractId: RouteParamDetails.contractId,
      });
    } else {
      localStorage.setItem("loadpaymentplans", "true");
      setTimeout(() => {
        routes.contractPaymentPlans.replace({
          agentId: "" + this.agentId,
          contractId: RouteParamDetails.contractId,
        });
      }, delay);
    }
  }

  @action backToMainPaymentPlanScreen(delay: number) {
    setTimeout(() => {
      routes.agentContracts.replace({
        agentId: "" + RouteParamDetails.id,
      });
    }, delay);
  }
  @action
  public Submit = async () => {
    if (!(await this.Validate())) {
      this.setIsSuccess(false);
      this.setIsLoading(true);
      this.setIsException(false);
      var dto: CreateUpdatePaymentPlanModel = {
        commPlanId: +this.commPlan,
        paymentSourceId: +this.paymentSource,
        companyId: +this.companyName,
        levelId: +this.productRef,
        payToId: +this.paymentTo,
        productId: +this.product,
        status: this.paymentPlanStatus,
        mgrId: +this.marketer,
        isActive: false,
        dateCreated: new Date(),
      };

      try {
        if (this.isEdit && this.profile) {
          dto.id = this.profile.id;
          await AccountStore.editPaymentPlan(dto);
        } else {
          await AccountStore.addPaymentPlan(dto);
        }

        this.setIsLoading(false);
        var editMainScreenPaymentPlan = localStorage.getItem(
          "editMainScreenPaymentPlan"
        );
        if (editMainScreenPaymentPlan == "true") {
          this.backToMainPaymentPlanScreen(0);
        } else if (this.isCalledFromEditContract) {
          routes.editContractPaymentPlans.replace({
            agentId: "" + this.agentId,
            contractId: RouteParamDetails.contractId,
          });
        } else {
          this.goBack(0);
        }
      } catch (e) {
        this.setIsLoading(false);
        this.setIsException(true);
      }
    }
  };

  @action resetAllFields() {
    this.marketerList = [];
    this.productRefList = [];
    this.productList = [];
    this.marketer = "0";
    this.setCommPlanStatus("1");
    this.setPaymentSource("1");
    this.isShowAll = false;
    this.commPlanStatus = "1";
    this.product = "0";
    this.productRef = "0";
    this.isShowAll = false;
    this.profile = undefined;
    this.specialInstruction = "";
  }
  @action loadProductDetailByLevelId = async (levelId: number) => {
    var res = await AccountStore.getProductDetailByLevelId(levelId);
    if (res && res.productId != null && res.productId > 0) {
      var productId = res.productId;

      var isProductExist = this.productList.find(
        (x) => parseInt(x.value) == productId
      );
      if (!isProductExist) {
        this.productList.push({
          label: "" + res.productName,
          value: "" + res.productId,
        });
      }
    }
  };

  @action loadCompanies = async () => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      this.companyNameList = [];

      if (this.isEdit && this.paymentPlan) {
        await this.setCompanyName(
          this.paymentPlan.companyId ? "" + this.paymentPlan.companyId : "0"
        );
      } else {
        var carrierId =
          RouteParamDetails.carrierId ||
          parseInt(localStorage.getItem("contractCarrierId") || "0");
        await this.setCompanyName("" + carrierId);
        //var pymntSourceId= parseInt(localStorage.getItem("contractPaymentSourceId") || "1");
        //this.setPaymentSource(""+ pymntSourceId)
      }
      if (this.isFromLevel) {
        var carrierId =
          RouteParamDetails.carrierId ||
          parseInt(localStorage.getItem("contractCarrierId") || "0");
        await this.setCompanyName("" + carrierId);
      }
      this.isCarrierDisabled = true;
      this.setIsLoading(false);
    } catch (e) {
      this.setIsLoading(false);
    }
  };
  @action loadProducts = async () => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      this.productList = [];
      var result = await AccountStore.getProductLookup(
        this.agentId,
        this.contractId
      );
      if (result) {
        var sortList = [...result].sort(Utils.compareListItem);
        var sortListUnique = sortList.filter(
          (v, i, a) => a.findIndex((t) => t.value === v.value) === i
        );
        for (let item of sortListUnique) {
          this.productList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        if (this.isEdit && this.paymentPlan) {
          this.setProduct(
            this.paymentPlan.productId ? "" + this.paymentPlan.productId : "0"
          );
        } else if (this.isFromLevel) {
          this.setProduct("" + RouteParamDetails.productId);
        } else {
          this.setProduct(this.productList[0].value);
        }

        this.setIsLoading(false);
      }
    } catch (e) {
      this.setIsLoading(false);
    }
  };
  @action loadLevels = async () => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      this.productRefList = [];
      var result = await AccountStore.getLevelLookupByContract(
        this.contractId,
        +this.product
      );
      if (result) {
        var sortList = [...result].sort(Utils.compareListItem);
        for (let item of sortList) {
          this.productRefList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        if (this.isEdit && this.profile) {
          this.setProductRef(
            this.profile.levelId ? "" + this.profile.levelId : "0"
          );
        } else if (this.isFromLevel) {
          this.setProductRef("" + RouteParamDetails.payoutLevelId);
        } else {
          this.setProductRef(this.productRefList[0].value);
        }
        this.setIsLoading(false);
      }
    } catch (e) {
      this.setIsLoading(false);
    }
  };
  @action loadMarketers = async () => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      this.marketerList = [];
      if (this.productRef && this.productRef !== "0") {
        var result = await AccountStore.getMarketerLookup(+this.productRef);
        this.marketerList = [];
        if (result) {
          var sortList = [...result].sort(Utils.compareListItem);
          var sortListUnique = sortList.filter(
            (v, i, a) => a.findIndex((t) => t.value === v.value) === i
          );
          for (let item of sortListUnique) {
            this.marketerList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
          }
          if (this.isEdit && this.profile) {
            this.setMarketer(
              this.profile.mgrId ? "" + this.profile.mgrId : "0"
            );
          } else if (this.isFromLevel) {
            this.setMarketer(this.marketerList[0].value);
          } else this.setMarketer(this.marketerList[0].value);

          this.setIsLoading(false);
        }
      }
    } catch (e) {
      this.setIsLoading(false);
    }
  };
  @action loadCommPlan = async (showAll: boolean) => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      var result = null;
      var commPlanIdP = "0";
      if (this.profile) {
        commPlanIdP = this.profile.commPlanId
          ? "" + this.profile.commPlanId
          : "0";
      }
      if (
        this.companyName &&
        this.companyName !== "0" &&
        !isNaN(Number(this.companyName))
      ) {
        result = await AccountStore.getCommPlan(
          +commPlanIdP,
          +this.companyName,
          +this.productRef,
          +this.commPlanStatus,
          showAll
        );
        if (result) {
          this.commPlanList = [];
          var sortList = [...result].sort(Utils.compareListItem);
          var sortListUnique = sortList.filter(
            (v, i, a) => a.findIndex((t) => t.value === v.value) === i
          );
          let defaultValue = "0";
          for (let item of sortListUnique) {
            this.commPlanList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
            if (item.text === "Direct Pay") {
              defaultValue = "" + item.value;
            }
          }
          if (this.isEdit && this.profile) {
            this.setCommisionPlan(
              this.profile.commPlanId ? "" + this.profile.commPlanId : "0"
            );
          } else {
            if (this.commPlanList.length > 1) {
              defaultValue = "0";
            }
            ((this.isEdit && !this.isInitial) || this.isAdd) &&
              this.setCommisionPlan(defaultValue);
          }
          this.setIsLoading(false);
        }
      }
    } catch (e) {
      this.setIsLoading(false);
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action loadPaymentSource = async (check: boolean | undefined) => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      var result = await AccountStore.getPaymentSource(+this.commPlan);
      this.paymentSourceList = [];
      if (result) {
        this.paymentSourceList = this.mapListItemAndSort(result);
        var selectedPaymentSource = result.filter((item: any) => {
          return item.selected == true;
        });
        var flag = true;
        if (
          (!this.commPlanChange && this.isShowallChange) ||
          (this.isEdit && this.isShowallChange)
        ) {
          for (let item of this.paymentSourceList) {
            if (this.paymentSource == item.value) flag = false;
          }
        }

        if (
          flag &&
          selectedPaymentSource != null &&
          ((this.isEdit && !this.isInitial) || this.isAdd)
        ) {
          this.paymentSource = "" + selectedPaymentSource[0].value;
        }

        
        this.setIsLoading(false);
      }
    } catch (e) {
      this.setIsLoading(false);
    }
    this.commPlanChange = false;
    this.isShowallChange = false;
  };
  @action loadPay1stYearTo = async () => {
    try {
      this.setIsLoading(true);
      this.setIsException(false);
      this.paymentToList.values = [];
      var result = await AccountStore.getPayFirstYearTo(
        this.agentId,
        +this.productRef,
        +this.productRef,
        +this.paymentTo,
        +this.marketer
      );

      if (result.firstToYearList) {
        Utils.mapListItemsToDropdown(
          result.firstToYearList || [],
          this.paymentToList,
          ""
        );
        if (this.isEdit && this.profile) {
          this.setPaymentTo("" + this.profile.payToId || "0");
        } else {
          this.setPaymentTo("0");
        }

        this.setIsLoading(false);
      }
    } catch (e) {
      this.setIsLoading(false);
    }
  };

  @action loadPaymentPlanDetails = async () => {
    this.setIsLoading(true);
    this.setIsException(false);
    // paymentSource
    this.paymentPlan = await AccountStore.getPaymentPlanDetail(
      this.paymentPlanId
    );
    if (this.paymentPlan && this.paymentPlan.agentProfileModel) {
      this.profile = this.paymentPlan.agentProfileModel;
      this.setProduct(
        this.paymentPlan.productId ? "" + this.paymentPlan.productId : "0"
      );
      this.setProductRef(
        this.profile.levelId ? "" + this.profile.levelId : "0"
      );
      ((this.isEdit && !this.isInitial) || this.isAdd) &&
        this.setCommisionPlan(
          this.profile.commPlanId ? "" + this.profile.commPlanId : "0"
        );
      this.setPaymentPlanStatus(
        this.profile.status ? this.profile.status : "A"
      );
      // this.setPaymentSource(
      //   this.profile.paymentSourceId ? "" + this.profile.paymentSourceId : "1"
      // );
      
      this.setCommPlanStatus("1");
      await this.loadProducts();
      await this.loadLevels();
      await this.loadMarketers();
      await this.loadCompanies();
      await this.loadCommPlan(this.isShowAll);
      this.setMarketer(this.profile.mgrId ? "" + this.profile.mgrId : "0");
      this.setPaymentTo(this.profile.payToId ? "" + this.profile.payToId : "0");
      this.setIsLoading(false);
      this.paymentSource =
        this.paymentPlan.agentProfileModel.paymentSourceId + "";

      if (
        this.profile.levelId !== null &&
        this.profile.levelId !== undefined &&
        this.profile.levelId > 0
      ) {
        // get data product data by level id and compare with product list
        await this.loadProductDetailByLevelId(this.profile.levelId);
      }
    }
    this.setIsLoading(false);
    this.isInitial = false;
  };
  @action loadSpecialInstructions = async () => {
    try {
      const result = await AccountStore.getSpecialInstructionsByLevelId(
        +this.productRef || 0
      );
      this.specialInstruction =
        result && result.specialInstruction ? result.specialInstruction : "";
    } catch {}
  };
}
export interface AddEditPaymentPlanListener {
  paymentPlanListener(): void;
  cancelAddEditPaymentPlan(): void;
}
class PaymentPlanValidator extends AbstractValidator<AddEditPaymentPlanViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.companyName)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Company is required");
    this.validateIfString((input) => input.commPlan)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Commission Plan is required");
    this.validateIfString((input) => input.paymentTo)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("1st year Commission is required");
    this.validateIfString((input) => input.product)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Product is required");
    this.validateIfString((input) => input.marketer)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Marketer is required");
    this.validateIfString((input) => input.productRef)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Level is required");
  }
}
