import { observer } from "mobx-react";
import React from "react";
import NumberFormat from "react-number-format";
import { Repeater } from "../Repeater";
import classNames from "classnames";

export const IMASInputNum: React.FC<{
  errors: string[] | undefined;
  label: string;
  value: string | number | undefined;
  id: string;
  isrequired: boolean;
  placeHolder?: string;
  decimalScale?: number;
  prefix?: boolean;
  suffix?: boolean;
  thousandSeparator?: string;
  maxLength?: number;
  autoFocus?: boolean;
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
  allowNegative?: boolean;
}> = observer((props) => {
  const getClassNameError = () => {
    if (props.errors)
      return classNames("p-inputtext p-component", {
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    else return classNames("p-inputtext p-component");
  };

  return (
    <div className="flex-column">
      <label htmlFor={props.id}>
        {props.label} {props.isrequired && <span className="required">*</span>}{" "}
      </label>
      <NumberFormat
        className={getClassNameError()}
        placeholder={props.placeHolder}
        id={props.id}
        value={props.value}
        decimalScale={props.decimalScale ? props.decimalScale : 0}
        allowNegative={props.allowNegative && props.allowNegative == true ? true : false}
        thousandSeparator={props.thousandSeparator === "false" ? false : true}
        prefix={props.prefix ? `$` : undefined}
        suffix={props.suffix ? `%` : undefined}
        autoFocus={props.autoFocus}
        onBlur={(event) => props.onBlur(event.currentTarget.value)}
        onChange={(event) => props.onChange(event.currentTarget.value)}
        maxLength={props.maxLength ? props.maxLength : undefined}
        onFocus={(e) => {
          const div = document.getElementById(props.id) as HTMLElement;
          if (div) {
            let autocompleteDiv = div.querySelector(
              ".p-inputnumber-input"
            ) as HTMLInputElement;
            if (autocompleteDiv) {
              autocompleteDiv.select();
            }
          }
        }}
      />
      <Repeater
        errors={props.errors}
        viewId={props.id}
        child={(error) => <div className="invalid-feedback">{error}</div>}
      />
    </div>
  );
});
