import React from "react";
import { observer } from "mobx-react";
import { InputTextarea } from "primereact/inputtextarea";
import { Repeater } from "../Repeater";
import classNames from "classnames";

export const CustomInputTextArea: React.FC<{
  errors: string[] | undefined;
  label: string;
  value: string | undefined;
  id: string;
  rows: number | undefined;
  maxLength: number | undefined;
  placeHolder: string | undefined;
  isrequired?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  className?: string;
  autoResize?: boolean;
  onBlur?: (value: string) => void;
  onChange: (value: string) => void;
  onKeyDown?: (event: any, value: string, shiftKey: any) => void;
}> = observer((props) => {
  const getClassNameError = () => {
    if (props.errors)
      return classNames({
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    else return classNames("");
  };
  return (
    <>
      <label htmlFor={props.id}>
        {props.label}
        {props.isrequired && <span className="required">*</span>}{" "}
      </label>
      <InputTextarea
        readOnly={props.readonly ? props.readonly : false}
        placeholder={props.placeHolder}
        id={props.id}
        rows={props.rows}
        maxLength={props.maxLength}
        disabled={props.disabled}
        value={props.value}
        autoFocus={props.autoFocus}
        className={props.className ? props.className : getClassNameError()}
        onBlur={(event) =>
          props.onBlur ? props.onBlur(event.currentTarget.value) : {}
        }
        onChange={(event) => props.onChange(event.currentTarget.value)}
        autoResize={props.autoResize || false}
        onKeyDown={(event) =>
          props.onKeyDown
            ? props.onKeyDown(event, event.currentTarget.value, event.shiftKey)
            : {}
        }
        onFocus={(e) => {
          let div = document.getElementById(props.id) as HTMLInputElement;
          if (div) {
            div.select();
          }
        }}
      />
      <Repeater
        errors={props.errors}
        viewId={props.id}
        child={(error) => <div className="invalid-feedback">{error}</div>}
      />
    </>
  );
});
