import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import history from "../infrastructure/History";
import { history as routerHistory } from "../router";
import Utils from './Utils';

export interface Props {
  children: JSX.Element;
}

const Auth0ProviderWithNavigate = ({ children }: Props) => {

  const onRedirectCallback = (appState: AppState | undefined) => {
    history.replace(
      appState && appState.returnTo ? appState.returnTo : window.location.href
    )
    routerHistory.configure({type: 'browser'});
  };

  const providerConfig = {
    domain: Utils.getAuth0ClientIdByEnvironment("domain"),
    clientId: Utils.getAuth0ClientIdByEnvironment("clientid"),
    useRefreshTokens: true,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      ...(Utils.getAuth0ClientIdByEnvironment("audience")
        ? { audience: Utils.getAuth0ClientIdByEnvironment("audience") }
        : null),
      acr_values: window.location.host,
    },
  };

  return (
    <Auth0Provider
      {...providerConfig}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
