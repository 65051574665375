import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import BusinessUnitConfigStore from "../../store/BusinessUnitConfigStore";
import Collection from "../../infrastructure/CollectionHelper";
import { AnnouncementListModel } from "../../services/BusinessUnitConfigService";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";

export class AnnouncementsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @action Load = async () => {
    this.Loaded();
    await this.getAllAnnouncements()
  };
  @action Loaded = () => {};

  @observable pageIndex: number = 0;
  @observable pageSize: number = 5;
  @observable sortColumn: string = "id";
  @observable sortAscending: boolean = false;
  @observable Announcements = new Collection<AnnouncementListModel>();
  @observable totalRecords: number = 0; 

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.Announcements.values = [];
  }

  @action getAllAnnouncements = async () => {
    let securityLevel;
    if(Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) && Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)){
      securityLevel = null;
    }  else if(Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements)){
      securityLevel = true;
    }else{
      securityLevel = false;
    } 

    if(!Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) && !Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)){
      this.noRecordsFound()
    }else{
      try {
        let result = await BusinessUnitConfigStore.getTodayAnnouncement(
          securityLevel,
          this.pageIndex,
          this.pageSize,
          this.sortColumn,
          this.sortAscending
        );
        if (result && result.recordCount) {
          this.totalRecords = result.recordCount
          this.Announcements.values = result.data ? result.data : []
        }
        else{
          this.noRecordsFound()
        }
      } catch (e) {}
    }
  };
}
