import { action, computed, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { CarrierCM, CarrierCMDocuments, CarrierCMDocumentsTabIndex, CarrierCMTabIndex } from "../../../../infrastructure/enum/Common";
import { CarrierCMDocumentPacketViewModel } from "./CarrierCMDocumentPacketViewModel";
import { CarrierCMDocumentViewModel } from "./CarrierCMDocumentViewModel";

export class CarrierCMDocumentMainViewModel implements ViewModel {



    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => {};

    @observable CarrierCMDocumentPacketViewModel: CarrierCMDocumentPacketViewModel =new CarrierCMDocumentPacketViewModel();
    @observable CarrierCMDocumentViewModel: CarrierCMDocumentViewModel = new CarrierCMDocumentViewModel();

    @observable carrierId: number = 0;
    @observable selectedKey: string | undefined = "Documents";
    @observable isLoading: boolean = false;
    @observable isException: boolean = false;
    @observable isDocumentsTab: boolean = false;
    @observable isDocumentPacketsTab: boolean = false;


    @action Load = async (id: number) => {
        this.carrierId = id;
        if (this.getTabIndex == 0) {
            this.isDocumentsTab = true;
        }
        else {
            this.isDocumentPacketsTab = true;
        }
        this.CarrierCMDocumentViewModel.Load(this.carrierId);
        this.CarrierCMDocumentPacketViewModel.Load(this.carrierId);
    };

    @computed get getTabIndex(): number {
        let tabIndex = 0;
        switch (this.selectedKey) {
            case CarrierCMDocuments.Documents:
                tabIndex = 0;
                break;
            case CarrierCMDocuments.DocumentPackets:
                tabIndex = 1;
                break;

            default:
                tabIndex = 0;
                break;
        }

        return tabIndex;
    }

    @action handleLinkClick = (item: string | undefined): void => {
        if (item) {
            this.selectedKey = item;
            this.isDocumentPacketsTab = false;
            this.isDocumentsTab = false;
            switch (item) {
                case CarrierCMDocuments.Documents:
                    this.isDocumentsTab = true;
                    this.CarrierCMDocumentViewModel.Load(this.carrierId);
                    return;
                case CarrierCMDocuments.DocumentPackets:
                    this.CarrierCMDocumentPacketViewModel.Load(this.carrierId);
                    this.isDocumentPacketsTab = true;
                    return;

                default:
                    return;
            }
        }
    };

    @action handleTabClick = (index: number | undefined, key: string): void => {
        switch (index) {
            case CarrierCMDocumentsTabIndex.Documents:
                this.handleLinkClick(CarrierCMDocuments.Documents);
                break;
            case CarrierCMDocumentsTabIndex.DocumentPackets:
                this.handleLinkClick(CarrierCMDocuments.DocumentPackets);
                break;

        }
    };



}
