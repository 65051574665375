import { action, observable } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import { PaymentPlanListModel } from "../../../services/AccountingService";
import Collection from "../../../infrastructure/CollectionHelper";
import IMASLog from "../../../infrastructure/IMASLog";
import Utils from "../../../infrastructure/Utils";
import AccountingStore from "../../../store/AccountingStore";
import PageContext from "../../../infrastructure/PageContext";
import { ContractAddEditPaymentPlanViewModel } from "./ContractAddEditPaymentPlanViewModel";

export class ContractPaymentplansViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (contractId: number, agentId: number) => {
    this.contractId = contractId;
    this.agentId = agentId;
    this.loadPaymentPlans();
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable contractId: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable isException: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @observable startIndex: number = 0;
  @observable first: number = 0;
  @observable sortOrderPaymentPlan: boolean = false;
  @observable sortColumnPryamentPlan: string = "Id";
  @observable rows = 10;
  @observable totalRecords: number = 0;
  @observable selectedCompany: string = "0";
  @observable isDeletePaymentPlan: boolean = false;
  @observable selectedPaymentPlanId: number = 0;
  @observable selectedPaymentPlan: PaymentPlanListModel = {};
  @observable status: string = "A";
  @observable selectedStatus: string | undefined = "";
  @observable statusList = [
    { label: "All", value: "All" },
    { label: "Active", value: "A" },
    { label: "History", value: "H" },
  ];
  @observable PaymentPlansList = new Collection<PaymentPlanListModel>();
  @observable paymentPlanTitle: string = "Add Payment plan";
  @observable isPaymentPlanVisible: boolean = false;
  @observable ContractAddEditPaymentPlanViewModel =
    new ContractAddEditPaymentPlanViewModel();
  @action setPaymentPlan = (value: boolean) => {
    this.isPaymentPlanVisible = value;
  };

  @action loadPaymentPlans = async () => {
    this.isMessgeVisible = false;
    try {
      if (this.agentId) {
        this.isLoading = true;
        if (RouteParamDetails.carrierId)
          this.selectedCompany = "" + RouteParamDetails.carrierId;
        else this.selectedCompany = "0";
        var result = await AccountingStore.getPaymentPlans(
          this.agentId,
          this.status,
          +this.selectedCompany,
          this.contractId,
          this.startIndex,
          this.rows,
          this.sortColumnPryamentPlan,
          this.sortOrderPaymentPlan
        );
        if (result != null) {
          if (result.data) {
           // this.totalRecords = 0;
            this.PaymentPlansList.values = [];
            this.totalRecords = result.recordCount ? result.recordCount : 0;
            this.PaymentPlansList.values = result.data;
          } else {
            this.noPaymentPlansFound();
          }
        }
        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
      IMASLog.log("exception from store: " + e.value);
      this.noPaymentPlansFound();
    }
    this.isLoading = false;
  };
  @action noPaymentPlansFound() {
    this.totalRecords = 0;
    this.PaymentPlansList.values = [];
  }

  @action onPagePaymentPlans(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / rows;
    this.loadPaymentPlans();
  }
  @action setStatus(value: string) {
    this.status = value;
  }
  @action downloadPaymentPlan = async (id: number | undefined) => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      if (id) {
        var result = await AccountingStore.downloadPaymentPlan(id);
        await Utils.downloadDocument(
          result.data || "",
          result.fileName !== null && result.fileName !== undefined
            ? result.fileName
            : "paymentplan.pdf",
          result.fileName ? result.fileName : ""
        );
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };

  @action setSortOrder() {
    this.sortOrderPaymentPlan = !this.sortOrderPaymentPlan;
  }
  @action setSortColumn(column: string) {
    this.sortColumnPryamentPlan = column;
  }
  @action resetPaymentPlanFilters() {
    this.status = "A";
    this.loadPaymentPlans();
  }
  @action setSelectedPaymentPlanId(id: number) {
    this.selectedPaymentPlanId = id;
  }
  @action setSelectedPaymentPlan(obj: any) {
    this.selectedPaymentPlan = obj;
  }
  @action setDeletPaymentPlanConfirm(value: boolean) {
    this.isDeletePaymentPlan = value;
  }
  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }
  @action deletePaymentPlan = async () => {
    try {
      this.isMessgeVisible = false;
      this.isLoading = true;
      var result = await AccountingStore.deletePaymentPlan(
        this.selectedPaymentPlanId
      );
      this.startIndex = 0;
      this.setDeletPaymentPlanConfirm(false);
      this.isLoading = false;
      if (result && result.message) {
        // var responseModel = {
        //   status: 400,
        //   title: result.message,
        //   errors: { "": [] },
        // };
        // this.response = JSON.stringify(responseModel);
        // PageContext.setResponseMessage(this.response);
        // this.isMessgeVisible = true;
        if (result.isDeleted) {
          Utils.showSuccessToaster("Payment Plan deleted.", 4000);
          setTimeout(async () => {
            await this.loadPaymentPlans();
            this.isLoading = false;
            window.scrollTo(0, 0);
          }, Utils.timeDelay_Success());
        } else {
          // PageContext.setIsMessageVisible(true);
          Utils.showWarningToaster(result.message || "", 4000);
          window.scrollTo(0, 0);
        }
      }
    } catch (e) {
      this.setDeletPaymentPlanConfirm(false);
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
}
