import React from "react";
import { observer } from "mobx-react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Constants } from "../../../infrastructure/enum/Constants";
import IMASLog from "../../../infrastructure/IMASLog";
import { RenewalHistoryViewModel } from "../../../viewModels/agents/policies/RenewalHistoryViewModel";
import Utils from "../../../infrastructure/Utils";

export const RenewalHistoryView: React.FC<{ vm: RenewalHistoryViewModel }> = observer(
  (props) => {

    const tripSort = (column: any) => {
      IMASLog.log("sort function " + JSON.stringify(column));
      var obj = JSON.parse(JSON.stringify(column));
      props.vm.setSortOrder();
      props.vm.setSortColumn(obj.sortField);
      props.vm.loadRenewalHistory();
    };

    const onPageHandle = (e: { first: number; rows: number }) => {
      props.vm.onPage(e.first);
    };

    const flatRateAmountTemplate=  (column: any) => {
      return ''+parseFloat((column.flatRateAmount * 100).toFixed(2))+'%';
    };

    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer={"Total Commissions paid on this Policy: $" + props.vm.totalPoints}
            style={{ textAlign: "end" }}
            colSpan={10}
          />
        </Row>
      </ColumnGroup>
    );
    return (
      <div className="p-fluid">
        <div className="p-fluid margin-top-10">
          <div className="p-grid">
            <div className="p-col-12">
              <DataTable
                className="header-align-left datatable-width-auto app-table"
                onSort={
                  props.vm.renewalHistoryList.values.length > 1
                    ? tripSort
                    : () => {}
                }
                autoLayout={true}
                resizableColumns={true}
                columnResizeMode="expand"
                responsive={true}
                value={props.vm.renewalHistoryList.values}
                paginator={false}
                //  rows={props.vm.rows}
                totalRecords={props.vm.totalRecords}
                paginatorTemplate={
                  props.vm.renewalHistoryList.values.length > 10
                    ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    : ""
                }
                rowsPerPageOptions={
                  props.vm.renewalHistoryList.values.length > 10
                    ? [5, 10, 25]
                    : []
                }
                selectionMode="single"
                currentPageReportTemplate={
                  props.vm.renewalHistoryList.values.length > 10
                    ? "Showing {first} to {last} of {totalRecords} records"
                    : ""
                }
                footerColumnGroup={
                  props.vm.renewalHistoryList.values.length > 0
                    ? footerGroup
                    : null
                }
                lazy={true}
                first={props.vm.first}
                onPage={onPageHandle}
                loading={false}
                emptyMessage={Constants.NoRecordFounds}
              >
                <Column
                  field="statementDate"
                  header="Statement Date"
                  className={"text-center"}
                />
                <Column
                  field="datePaid"
                  header="Date Paid"
                  className={"text-center"}
                />
                <Column
                  field="renewalMonth"
                  className={"text-center"}
                  header="Renewal Month"
                />
                <Column
                  field="premium"
                  body={(rowData: any) => {
                    return Utils.getCurrencyFormat(rowData.premium);
                  }}
                  className={"text-center"}
                  header="Premium"
                />
                <Column
                  field="commissionPlanName"
                  className={"text-center"}
                  header="Commission Plan Name"
                />

                <Column
                  field="rate"
                  body={(rowData: any) => {
                    return Utils.getFlatRate(rowData.rate);
                  }}
                  className={"text-center"}
                  header="Rate"
                />
                <Column
                  body={(rowData: any) => {
                    return Utils.getCurrencyFormat(rowData.commissionReceived);
                  }}
                  field="commissionReceived"
                  className={"text-center"}
                  header="Commission Received"
                />
                <Column
                  body={(rowData: any) => {
                    return Utils.getCurrencyFormat(rowData.commission);
                  }}
                  field="commission"
                  className={"text-center"}
                  header="Commission Paid"
                />

                <Column field="paid" className={"text-center"} header="Paid" />

                <Column
                  field="paidTo"
                  className={"text-center"}
                  header="Paid To"
                />

                {/* <Column
                  field="advance"
                  className={"text-center"}
                  header="Advance"
                />
               
                <Column
                  field="paymentSourceName"
                  className={"text-center"}
                  header="Source"
                />*/}
                <Column
                  body={flatRateAmountTemplate}
                  className={"text-center"}
                  header="Prorate Factor"
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

//export default withAITracking(reactPlugin, RenewalHistoryView, "RenewalHistory");
