import React, { useEffect, useMemo } from 'react';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';

import '../css/WebChat.css';
import botIcon from '../../assets/images/ic-big.png';
// import axios from "axios";

const WebChat = ({ className, onFetchToken, token, store, styleSet, styleOptions }) => {
  const directLine = useMemo(() => createDirectLine({ token }), [token]);
  directLine.connectionStatus$
  .subscribe(connectionStatus => {
     console.log("connectionStatus",connectionStatus)
      switch(connectionStatus) {
          case 0: //ConnectionStatus.Uninitialized:    // the status when the DirectLine object is first created/constructed
          break;
          case 1: //ConnectionStatus.Connecting:       // currently trying to connect to the conversation
          break;
          case 2: //ConnectionStatus.Online:           // successfully connected to the converstaion. Connection is healthy so far as we know.
          break;
          case 3: //ConnectionStatus.ExpiredToken:     // last operation errored out with an expired token. Your app should supply a new one.
          case 4: //ConnectionStatus.FailedToConnect:  // the initial attempt to connect to the conversation failed. No recovery possible.
          //AI-307
          setTimeout(() => {
              let errDiv = document.getElementsByClassName("webchat__connectivityStatus");
              console.log("connectivity Status error div",  errDiv[0])
              if(errDiv != null && errDiv[0] != null){
                  errDiv[0].innerHTML = '<svg alt="" class="" height="16" viewBox="0 0 13.1 13.1" width="16"><path d="M6.5,13C2.9,13,0,10.1,0,6.5S2.9,0,6.5,0S13,2.9,13,6.5S10.1,13,6.5,13z M6.1,3.5v4.3h0.9V3.5H6.1z M6.1,8.7v0.9h0.9V8.7H6.1z" fill-rule="evenodd"></path></svg> Refresh'
              }
          }, 10);
          break;
          case 5: //ConnectionStatus.Ended: // the bot ended the conversation
          break;
      }
  });  

  // Hook for setting the state
  // const [botPonyfill, setBotPonyfill] = useState();

  const overrideLocalizedStrings = {
    TEXT_INPUT_PLACEHOLDER: "Ask Integrity",
  };

  // Speech API //
  // const speechAPI = axios.create({
  //   baseURL: "https://eastus.api.cognitive.microsoft.com/sts/v1.0/issuetoken",
  //   timeout: 5000,
  // });

  // function for creating ponyfillfactory for speech
  // async function createPonyFill(webchat) {
  //   const res = await speechAPI.post("/", null, {
  //     headers: {
  //       "Ocp-Apim-Subscription-Key": `fab9cee52145472da8de29787627c5bd`, //hidden for security
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     },
  //   });

  //   console.log(res.statusText);

  //   if (res.status !== 200) {
  //     throw new Error("Failed to fetch authorization token and region.");
  //   }

  //   const adapters = webchat.createCognitiveServicesSpeechServicesPonyfillFactory(
  //     {
  //       credentials: {
  //         authorizationToken: res.data,
  //         region: "eastus",
  //       }
  //     }
  //   );

  //   return adapters;
  // }

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  
  const input =  document.getElementsByClassName("webchat__send-box-text-box__input");
  const chatDiv = document.getElementsByClassName("react-web-chat");
  if(input && chatDiv && input != null && input[0]){
    input[0].addEventListener('keyup', () => {
      if(input[0].value == ''){
        chatDiv[0].classList.remove("bot-arrow-icon");
        chatDiv[0].classList.add("bot-search-icon");
      } else {
        chatDiv[0].classList.remove("bot-search-icon");
        chatDiv[0].classList.add("bot-arrow-icon");
      }
    })
  }
 

  return (token) ? (
    <ReactWebChat className={`${className || ''} web-chat`}
      directLine={directLine}
      store={store}
      styleSet={styleSet}
      styleOptions={styleOptions}
      sendTypingIndicator={true}
      // webSpeechPonyfillFactory={botPonyfill}
      locale='en-US'
      // voices={true}
      // selectVoice={selectVoice}
      overrideLocalizedStrings={overrideLocalizedStrings}
    />
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <img src={botIcon} alt="bot Icon" className="initIcon initbotIcon" />
        </div>
        <p class="initP">Please wait while we are connecting.</p>
      </div>
    </div>
  );
};

export default WebChat;

