import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import WorkflowStore from "../../store/WorkflowStore";
import { Constants } from "../../infrastructure/enum/Constants";
import {
  AddDocumentNoteModel,
  DeleteWorkflowDocumentModel,
} from "../../services/WorkflowService";
import { routes } from "../../router";

export class AddDocumentCommentViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable isWorkflowApiCall: boolean = true;

  @action Load = async () => {
    await this.ResetValidate();
    this.reset();
  };

  @action setComments(value: string) {
    this.comments = value;
  }

  @action cancel = async () => {
    if (this.listener) {
      this.listener.cancelNoteDeleteDocument();
      this.reset();
    }
  };

  @action public save = async (docId: number, isDelete: boolean) => {
    this.isMessageVisible = false;
    this.response = "";

    if (isDelete) {
      try {
        await this.ResetValidate();

        if (!(await this.Validate())) {
          if (this.isWorkflowApiCall) {
            let dto: DeleteWorkflowDocumentModel = {
              notes: this.comments.trim(),
            };

            this.isWorkflowApiCall = false;

            await WorkflowStore.deleteWorkflowDocument(docId, dto);
            let successResponse = {
              status: 200,
              title: Constants.WorkflowDocumentDelete,
              errors: { "": [] },
            };
            this.response = JSON.stringify(successResponse);
            this.isMessageVisible = true;
            setTimeout(() => {
              routes.docWorkflow.push();
              this.reset();
            }, 1300);
          }

          this.isWorkflowApiCall = true;
        }
      } catch (e: any) {
        this.isMessageVisible = true;
        e && e.text
          ? e.text().then((res: any) => {
              this.response = res;
            })
          : (this.response = e.response);
        this.isWorkflowApiCall = true;
      }
    } else {
      try {
        await this.ResetValidate();
        if (!(await this.Validate())) {
          if (this.isWorkflowApiCall) {
            let dto: AddDocumentNoteModel = {
              //id: docId,
              //userId: 0,
              notes: this.comments.trim(),
              //dateCreated: new Date()
            };

            this.isWorkflowApiCall = false;

            await WorkflowStore.addWorkflowDocumentNotes(
              docId ? docId : 0,
              dto
            );

            let successResponse = {
              status: 200,
              title: Constants.AddWorkflowDocumentComment,
              errors: { "": [] },
            };
            this.response = JSON.stringify(successResponse);
            this.isMessageVisible = true;
            setTimeout(() => {
              if (this.listener) {
                this.listener.noteDeleteDocumentListener();
                this.listener.cancelNoteDeleteDocument();
              }
              this.reset();
            }, 1300);
          }

          this.isWorkflowApiCall = true;
        }
      } catch (e: any) {
        this.isMessageVisible = true;
        e && e.text
          ? e.text().then((res: any) => {
              this.response = res;
            })
          : (this.response = e.response);
        this.isWorkflowApiCall = true;
      }
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: NoteDeleteListener | undefined;

  @observable locationList = [
    { label: "At the beginning", value: "1" },
    { label: "In the end", value: "2" },
  ];
  @observable noteTitle: string = "Add your comments to this document:";
  @observable deleteTitle: string = "Why are you deleting this document?";
  @observable comments: string = "";
  @observable isDialogVisible: boolean = false;
  @observable isUpload: boolean = false;
  @observable fileName: string = "";
  @observable response: any = null;
  @observable isMessageVisible: boolean = false;

  @action reset = () => {
    this.isUpload = false;
    this.comments = "";

    this.fileName = "";
    this.isMessageVisible = false;
    this.response = "";
    this.ResetValidate();
  };

  setDocumentListener(listener: NoteDeleteListener) {
    this.listener = listener;
  }

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @observable
  public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<AddDocumentCommentViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.comments)
      .hasMaxLength(750)
      .whenNotEmpty()
      .withFailureMessage("Comment should not be more than 750 characters");
    this.validateIfString((input) => input.comments)
      .isNotEmpty()
      .withFailureMessage("Comment is required");
  }
}

export interface NoteDeleteListener {
  noteDeleteDocumentListener(): void;
  cancelNoteDeleteDocument(): void;
}
