import React from "react";
import { observer } from "mobx-react";
import { MarketingEventDetailViewModel } from "./../../viewModels/home/MarketingEventDetailViewModel";
import { routes } from "../../router";
import IMASTableRow from "../components/controls/IMASTableRow";
import { Button } from "primereact/button";
import { CustomInputText } from "../components/controls/IMASInputText";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";

export const MarketingEventDetailView: React.FC<{
  vm: MarketingEventDetailViewModel;
}> = observer((props) => {
  const changeSendTest = (value: string) => {
    props.vm.setSendTest(value);
  };

  return (
    <>
      <>
        <div className="p-grid">
          <div className="p-col-12 p-md-4 p-lg-4">
            <h2 style={{ marginBottom: "10px" }}>{props.vm.title}</h2>
          </div>

          <div className="p-col-12 p-md-8 p-lg-8 text-right">
            {Utils.hasUserPermission(
              Permission.DownloadMarketingEventStatisticsReport
            ) && (
              <Button
                id="btnDownload"
                label="Download Statistics Report"
                icon="fa fa-download"
                className="secondary-button download-mktg"
                onClick={props.vm.downloadStatisticReport}
              />
            )}
            &nbsp;&nbsp;&nbsp;
            {Utils.hasUserPermission(
              Permission.DownloadMarketingEventResendableEmailList
            ) && (
              <Button
                id="btnResendableEmailList"
                label="Download Resendable Email List"
                icon="fa fa-download"
                className="secondary-button download-mktg"
                onClick={props.vm.getResendListReport}
              />
            )}
            &nbsp;&nbsp;&nbsp;
            {Utils.hasUserPermission(Permission.EditMarketingEvent) && (
              <Button
                id="btnEdit"
                label="Edit"
                icon="fa fa-edit"
                className="primary-button edit-mktg"
                disabled={props.vm.enableEditButton ? false : true}
                onClick={() =>
                  routes.editMarketingEvent.push({
                    marketingEventId: props.vm.eventDetail.id
                      ? props.vm.eventDetail.id
                      : 0,
                  })
                }
              />
            )}
          </div>
          <div className="p-col-12 p-md-6 p-lg-6">
            <table>
              <IMASTableRow
                styleName="row-value"
                header="Event ID:"
                value={props.vm.eventDetail.id ? props.vm.eventDetail.id : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value custom-title"
                header="Title:"
                value={
                  props.vm.eventDetail.title ? props.vm.eventDetail.title : ""
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Subject Line:"
                value={
                  props.vm.eventDetail.emailSubject
                    ? props.vm.eventDetail.emailSubject
                    : ""
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Description:"
                value={
                  props.vm.eventDetail.description
                    ? props.vm.eventDetail.description
                    : ""
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Type:"
                value={props.vm.type ? props.vm.type : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Format:"
                value={props.vm.format ? props.vm.format : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Owner:"
                value={props.vm.owner ? props.vm.owner : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Requestor:"
                value={props.vm.requestor ? props.vm.requestor : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Carrier:"
                value={props.vm.company ? props.vm.company : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Product Line:"
                value={props.vm.productLine ? props.vm.productLine : ""}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Start Time:"
                value={`${
                  props.vm.eventDetail.dateStart
                    ? props.vm.eventDetail.dateStart
                    : ""
                } ${
                  props.vm.eventDetail.timeStart
                    ? props.vm.eventDetail.timeStart
                    : ""
                }`}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Stop time:"
                value={`${
                  props.vm.eventDetail.dateStop
                    ? props.vm.eventDetail.dateStop
                    : ""
                } ${
                  props.vm.eventDetail.timeStop
                    ? props.vm.eventDetail.timeStop
                    : ""
                }`}
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value custom-url"
                header="URL:"
                value={
                  props.vm.eventDetail.url ? (
                    props.vm.checkIfUrlHasHTTP(props.vm.eventDetail.url) ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-underline"
                        href={`${props.vm.eventDetail.url}`}
                      >
                        {props.vm.eventDetail.url}
                      </a>
                    ) : (
                      props.vm.eventDetail.url
                    )
                  ) : (
                    ""
                  )
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Audience:"
                value={
                  props.vm.eventDetail.audience
                    ? props.vm.eventDetail.audience
                    : ""
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Audience Size:"
                value={
                  props.vm.eventDetail.audienceSize
                    ? props.vm.eventDetail.audienceSize.toLocaleString("en")
                    : ""
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Leads Generated:"
                value={props.vm.eventDetail.purchasedLead ? "1" : "0"}
                comment=""
                headerClass={true}
              />
            </table>
          </div>
          <div className="p-col-12 p-md-6 p-lg-6">
            <table className="mktg-event-detail-table">
              <IMASTableRow
                styleName="row-value"
                header="Requests:"
                value={
                  props.vm.eventDetail.requestCount
                    ? props.vm.eventDetail.requestCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Delivered:"
                value={
                  props.vm.eventDetail.deliveredCount
                    ? props.vm.eventDetail.deliveredCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Opened:"
                value={
                  props.vm.eventDetail.openedCount
                    ? props.vm.eventDetail.openedCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Unique Opens:"
                value={
                  props.vm.eventDetail.uniqueOpensCount
                    ? props.vm.eventDetail.uniqueOpensCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Clicks:"
                value={
                  props.vm.eventDetail.clickedCount
                    ? props.vm.eventDetail.clickedCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Unique Clicks:"
                value={
                  props.vm.eventDetail.uniqueClicksCount
                    ? props.vm.eventDetail.uniqueClicksCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Bounces:"
                value={
                  props.vm.eventDetail.bouncesCount
                    ? props.vm.eventDetail.bouncesCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Spam Reports:"
                value={
                  props.vm.eventDetail.spamReportsCount
                    ? props.vm.eventDetail.spamReportsCount
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Open Leads:"
                value={
                  props.vm.eventDetail.openLeads
                    ? props.vm.eventDetail.openLeads
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Open Leads Phone Calls:"
                value={
                  props.vm.eventDetail.openLeadsPhoneCalls
                    ? props.vm.eventDetail.openLeadsPhoneCalls
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Contracted Leads:"
                value={
                  props.vm.eventDetail.contractedLeads
                    ? props.vm.eventDetail.contractedLeads
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Contracted Leads Phone Calls:"
                value={
                  props.vm.eventDetail.contractedLeadsPhoneCalls
                    ? props.vm.eventDetail.contractedLeadsPhoneCalls
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Non-Contracted Leads:"
                value={
                  props.vm.eventDetail.nonContractedLeads
                    ? props.vm.eventDetail.nonContractedLeads
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Non-Contracted Leads Phone Calls:"
                value={
                  props.vm.eventDetail.nonContractedLeadsPhoneCalls
                    ? props.vm.eventDetail.nonContractedLeadsPhoneCalls
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Production:"
                value={
                  props.vm.eventDetail.production
                    ? props.vm.eventDetail.production
                    : "0"
                }
                comment=""
                headerClass={true}
              />
              <IMASTableRow
                styleName="row-value"
                header="Unique Email Unsubscribes:"
                value={
                  props.vm.eventDetail.unsubscribes
                    ? props.vm.eventDetail.unsubscribes
                    : "0"
                }
                comment=""
                headerClass={true}
              />
            </table>
          </div>
          
              <div className={`"p-col-12 p-md-6 p-lg-4 ${!props.vm.eventDetail.allowedSendMarketingEmail && 
                  !Utils.hasUserPermission(Permission.EditMarketingEvent) && 'hidden-custom'}`}>
                  <div className="form-group">
                    <CustomInputText
                      errors={undefined}
                      id={"email"}
                      isrequired={false}
                      maxLength={undefined}
                      label={""}
                      value={props.vm.sendTest}
                      placeHolder="Enter email /emails (comma separated)"
                      onBlur={() => {}}
                      onChange={changeSendTest}
                    />
                    {props.vm.areEmailsValid && (
                      <div className="invalid-feedback">{`Please enter valid email/emails`}</div>
                    )}
                  </div>
                </div>
                <div className={`"p-col-12 p-md-6 p-lg-4 ${!props.vm.eventDetail.allowedSendMarketingEmail && 
                  !Utils.hasUserPermission(Permission.EditMarketingEvent) && 'hidden-custom'}`}>
                  <Button
                    id="btnSubmit"
                    label="Send Test"
                    icon="fa fa-envelope"
                    className="primary-button margin-bottom-10"
                    onClick={props.vm.onSubmit}
                  />
                  {props.vm.isEmailSent && (
                    <span style={{ color: "green" }}>
                      &nbsp;&nbsp;<i className="fa fa-check"></i>&nbsp;Sent
                    </span>
                  )}
                </div>
          <div className="p-col-12 p-md-6 p-lg-4 text-right">
            <Button
              id="btnBack"
              label="Back to Calendar"
              icon="fa fa-arrow-left"
              className="secondary-button margin-bottom-10"
              onClick={() => routes.listMarketingEvent.push({})}
            />
          </div>
        </div>
        {props.vm.checkIfUrlHasHTTP(
          props.vm.eventDetail.url ? props.vm.eventDetail.url : ""
        ) && (
          <div className="p-grid">
            <div className="p-col-12">
              <iframe
                id="showEmailerFromUrl"
                src={""}
                title={"Emailer template"}
                width="100%"
                height="800px"
              ></iframe>
            </div>
          </div>
        )}
      </>
    </>
  );
});
