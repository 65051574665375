import { action, observable, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import CustomAttributeStore from "../../store/CustomAttributeStore";
import Collection from "../../infrastructure/CollectionHelper";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import { routes } from "../../router";
import { ManageAttributeScreens } from "../../infrastructure/enum/Common";
import { CustomAttributesComponentViewModel } from "./CustomAttributesComponentViewModel";
import { CustomAttributeModel } from "../../services/CustomAttributeService";
import { reset } from "mixpanel-browser";
export class ManageAttributesViewModel implements ViewModel {

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented."); 220
  }

  Route = async (currentRoute: RouteList): Promise<void> => undefined;



  public ErrorModel = new ErrorModel(new AddressValidator());
  @observable isLoading: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable isException: boolean = false;
  @observable exceptionMsg: any = "";


  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 10;
  @observable pageIndex: number = 0;
  @observable isSortAscending: boolean = false;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable isPagination: string = "";


  @observable searchFilterType = [
    { label: "All", value: "0" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "2" },
  ];
  @observable searchFilterSelected: any = "0";
  @observable refreshVisible: boolean = false;
  @observable isDetailsAttribute: boolean = false;
  @observable isEditAttribute: boolean = false;
  @observable searchValue: string = '';
  @observable customAttributeList = new Collection<any>();
  @observable customAttributeDetail: any;
  @observable sort: boolean = false;
  @observable sortColumn: string = "created";
  @observable rows: number = 10;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @observable selectedAttributeDetails: any;
  @observable screensList: any;
  @observable gridData: any;
  @observable selectedRowDetailsForDelete: any;
  @observable CustomAttributesComponentViewModel: CustomAttributesComponentViewModel = new CustomAttributesComponentViewModel();
  @observable title: string = "Manage Custom Attributes";
  @observable DefaultValueToaster: boolean = true;
  @observable isShowWarning: boolean = false;
  @observable showUpdateToast: boolean = false;
  @observable isCancelChangesConfirmation: boolean = false;
  @observable tempAttrName: string = '';
  @observable editSource: string = '';
  @observable timer: any;
  @observable rowData: any;
  @observable rowDataHolding: any;
  @observable isEmptyMessageVisible: boolean = false;
  @observable isDefaultValueError: boolean = false;
  @observable deletedAttrId: number = 0;
  @observable deletedAttrName: number = 0;

  @action Load = async () => {
    this.sortColumn = "created";
    this.rows = 10;
    this.startIndex = 0;
    this.first = 0;
    this.showUpdateToast = false;
    this.searchFilterSelected = '0';
    this.searchValue = '';
    this.isEmptyMessageVisible = false;
    this.resetRefesh();
    this.resetToasters();
    this.handleDetailsView(false, undefined, false, '');
    this.resetViewEdit();
    this.loadItems(true);
    // this.isEmptyMessageVisible = true ;
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadItems(false);
  }


  @action goToAdmin = () => {
    this.handleDetailsView(false, undefined, false, '');
    routes.administration.push();
  };

  @action setSearchFilterDropdown = (value: string) => {
    this.searchFilterSelected = value;
    this.resetRefesh();
    this.loadItems(false);
  };

  @action resetRefesh() {
    if (
      this.searchFilterSelected == "0" &&
      (this.searchValue == "" || !this.searchValue)
    )
      this.refreshVisible = false;
    else this.refreshVisible = true;
  }

  @action reset = async () => {
    this.searchFilterSelected = "0";
    this.searchValue = "";
    this.resetRefesh();
    this.loadItems(false);
  };


  @action onFieldChange = (value: any) => {
    this.searchValue = value;
    if (
      this.searchValue.length == 0 ||
      this.searchValue.length > 2
    ) {
      this.loadItems(false);
    }
    this.resetRefesh();
  };
  @action handleClick = (id: string) => {
    this.resetToasters();
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  @action loadItems = async (isLoad: boolean) => {
    this.customAttributeList.values = []; let privacyStatus = undefined;
    this.showUpdateToast = isLoad && false;
    if (Utils.hasUserPermission(Permission.PublicCustomAttribute) && Utils.hasUserPermission(Permission.PrivateCustomAttribute)) {
      privacyStatus = "";
    } else if (Utils.hasUserPermission(Permission.PublicCustomAttribute) && !Utils.hasUserPermission(Permission.PrivateCustomAttribute)) {
      privacyStatus = false;
    } else if (!Utils.hasUserPermission(Permission.PublicCustomAttribute) && Utils.hasUserPermission(Permission.PrivateCustomAttribute)) {
      privacyStatus = true;
    }

    let res = await CustomAttributeStore.getAllManageCustomAttributes(
      this.searchFilterSelected,
      privacyStatus,
      this.startIndex,
      this.rows,
      this.sortColumn,
      this.sort,
      this.searchValue);
    if (res) {
      this.customAttributeList.values = res.data || [];
      this.totalRecords = res.recordCount || 0;
    }
    this.gridData = res.data;
    if (isLoad && this.customAttributeList.values.length <= 0) {
      this.isEmptyMessageVisible = true;
    }
    else {
      this.isEmptyMessageVisible = false;
    }
  }

  @action handleLinkedScreens = (parentData: any) => {
    let tempVar: any = [];
    let res = (Object.keys(ManageAttributeScreens) || []).map((x: any, idx: any) => {
      if ((parentData || []).includes(ManageAttributeScreens.Statelicense)) {
        tempVar.push("State License")
      }
      if ((parentData || []).includes(ManageAttributeScreens.Policy)) {
        tempVar.push("Policy")
      }
      if ((parentData || []).includes(ManageAttributeScreens.Insured)) {
        tempVar.push("Insured")
      }
      if ((parentData || []).includes(ManageAttributeScreens.Contract)) {
        tempVar.push("Contract")
      }
      if ((parentData || []).includes(ManageAttributeScreens.AddAgent)) {
        tempVar.push("Agent (Add)")
      }
      if ((parentData || []).includes(ManageAttributeScreens.EditAgent)) {
        tempVar.push("Agent (Edit)")
      }
      if ((parentData || []).includes(ManageAttributeScreens.Tasks)) {
        tempVar.push("Tasks")
      }
    });
    this.screensList = [... new Set(tempVar)];
  }

  @action loadDetailById = async (id: number, isEdit:boolean) => {
    let res = await CustomAttributeStore.getAttributeById(id, isEdit);
    if (res) {

      this.customAttributeDetail = res;

      this.tempAttrName = res.name || "";
      this.rowData = res || null;
      this.rowDataHolding = res || null;
      this.isEditAttribute = false;
      this.setEditConfirmation(false);
      this.getAttributeAssocaitionsById(false, id, true, res.name);
      let TempRowData = res && res.screens || [];
      this.handleLinkedScreens(TempRowData);
      this.selectedAttributeDetails = JSON.parse(JSON.stringify(res));
    }

  }
  @action handleDetailsView = async (value: boolean, rowData: any, forEdit: boolean, editFrom: string, isEdit:boolean) => {

    if (rowData && value) {
      // call new API by Id

      this.editSource = editFrom;
      await this.loadDetailById(rowData.id, isEdit);
      
    } else {
      this.isEditAttribute = false;
      this.resetViewEdit();
    }
    this.isDetailsAttribute = value;
    forEdit && this.handleEdit(true);
    this.title = value ? (forEdit ? "Edit Attribute" : "View Attribute") : "Manage Custom Attributes";
  }

  // region for filters/ delete events 
  @observable isDeleteConfirm: boolean = false;
  @observable isEditConfirm: boolean = false;
  @observable deleteText: any = 'Are you sure you want to delete this custom attribute?';
  @observable attributeId: number = 0;
  @observable attributeName: string = "text 1";
  @observable attributeAssocaitionCount: number = 0;

  @observable deleteToastMessage: string = "";
  @observable isShowDeleteToast: boolean = false;
  @action goSearch = () => {
    // Datatable load function called once created
    this.loadItems(false);

    this.resetRefesh();
  };

  @action setDeleteConfirmation(value: boolean) {
    this.isDeleteConfirm = value;
  }
  @action setEditConfirmation(value: boolean) {
    this.isEditConfirm = value;
  }

  @action setAttributeId = (value: number) => {
    this.attributeId = value;
  };

  @action setAttributeName = (value: string) => {
    this.attributeName = value;
  };

  @action attributeAssocaitionCounts = (count: number) => {
    this.attributeAssocaitionCount = count;
  };


  @action getAttributeAssocaitionsById = async (value: boolean, id: any, fromView: boolean, attrName: any) => {

    this.isShowDeleteToast = false;
    this.deleteToastMessage = "";
    this.attributeName = "";
    this.deletedAttrId = id;
    this.deletedAttrName = attrName;

    var count = 0;
    if (value == true) {
      let res1 = await CustomAttributeStore.getRecordCountById(id);
      if (res1) {
        count = res1;
      }
    }
   
    this.deleteText = count > 0  ? `${"By deleting this custom attribute, you will permanently remove its data from " + count + " records. \n Are you sure you want to delete?"}` : 'Are you sure you want to delete this custom attribute?';
    !fromView && this.setDeleteConfirmation(true);
  }


  @action onDeleteClick = async () => {
    this.isLoading = true;
    try {
      var result = await CustomAttributeStore.deleteCustomAttribute(this.deletedAttrId) ;
      this.setDeleteConfirmation(false);
      this.isLoading = false;
      this.handleDetailsView(false, undefined, false, '',false);
      this.loadItems(true);
    } catch (e: any) {
      this.setDeleteConfirmation(false);
      this.isLoading = false;
    }

    this.setDeleteConfirmation(false);
    this.deleteToastMessage = `Custom Attribute '${this.deletedAttrName}' is Deleted.`;
    this.isShowDeleteToast = true;
    Utils.showSuccessToaster(`Custom Attribute '${this.deletedAttrName}' is Deleted.`, 7000, 500);
  };


  @action handleEdit = (value: boolean) => {
    value && this.resetToasters();
    this.isEditAttribute = value;
    !value && this.handleRouteBackOnEdit(undefined);
    this.title = this.isEditAttribute ? "Edit Attribute" : this.isDetailsAttribute ? "View Attribute" : "Manage Custom Attributes";
    this.CustomAttributesComponentViewModel.loadLookUps(true, this.selectedAttributeDetails);
    this.CustomAttributesComponentViewModel.isSave = value;

  }


  @action restdefValueError = () => {
    this.DefaultValueToaster = false;
    this.isDefaultValueError = false;
    this.isShowWarning = false;
  }

  @action updateAttrHandle = async () => {
    this.setEditConfirmation(false);
    this.DefaultValueToaster = false;
    this.tempAttrName = this.CustomAttributesComponentViewModel.newAttributeName;
    let tempData = (this.CustomAttributesComponentViewModel.defaultValue.values || []).map((Items: any) => {
      if (isNaN(Items.value)) {
        Items.value = 0;
      }
      delete Items.duplicateValue;
      delete Items.isDuplicate;
      return Items;
    });
    let data = {
      id: this.selectedAttributeDetails.id,
      name: this.CustomAttributesComponentViewModel.newAttributeName,
      dataTypeId: this.CustomAttributesComponentViewModel.attributeDataType,
      active: this.CustomAttributesComponentViewModel.selectedStatus == 1,
      values: tempData || '',
      description: this.CustomAttributesComponentViewModel.newAttributeDescription,
      isPrivate: this.CustomAttributesComponentViewModel.selectedAttributeSecurityType != 1,
      isMandatory: this.CustomAttributesComponentViewModel.selectedAttributeMandatoryType == 1,
      screens: this.selectedAttributeDetails.screens
    };
    let dataTmp = {
      id: this.selectedAttributeDetails.id,
      name: this.CustomAttributesComponentViewModel.newAttributeName,
      dataTypeId: this.CustomAttributesComponentViewModel.attributeDataType,
      active: this.CustomAttributesComponentViewModel.selectedStatus == 1,
      values: tempData || '',
      description: this.CustomAttributesComponentViewModel.newAttributeDescription,
      isPrivate: this.CustomAttributesComponentViewModel.selectedAttributeSecurityType != 1,
      isMandatory: this.CustomAttributesComponentViewModel.selectedAttributeMandatoryType == 1,
      screens: this.selectedAttributeDetails.screens,
      valuesInUse: this.selectedAttributeDetails.valuesInUse,
      dataTypeName: this.selectedAttributeDetails.dataTypeName
    };
    let res = await CustomAttributeStore.updateCustomAttribute(data);
    this.showUpdateToast = true;
    this.handleLinkedScreens(this.selectedAttributeDetails.screens);
    Utils.showSuccessToaster(`Custom Attribute '${this.tempAttrName}' changes has been made.`, 7000, 500);
    this.rowDataHolding = dataTmp;
    this.handleRouteBackOnEdit(data);
  }

  @action handleRouteBackOnEdit = (data: any) => {
    if (this.editSource == 'grid') {
      setTimeout(async () => {
        this.isEditAttribute = false;
        this.isDetailsAttribute = false;
        this.title = "Manage Custom Attributes";
        this.resetViewEdit();
        this.loadItems(true);
      }, 500);
    } else if (this.editSource == 'details') {
      this.title = "View Attribute";
      this.resetViewEdit();
      this.selectedAttributeDetails = JSON.parse(JSON.stringify(this.rowDataHolding));
      this.rowData = this.rowDataHolding;
      this.isEditAttribute = false;
      this.isDetailsAttribute = true;
      this.loadItems(true);
    }
  }
  @action resetToasters = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.showUpdateToast = false;
      localStorage.removeItem("CMNotesEdit");
    }, 8000);
  }
  @action resetViewEdit = () => {
    this.selectedAttributeDetails = undefined;
    this.DefaultValueToaster = false;
    this.isCancelChangesConfirmation = false;
    this.isShowWarning = false;
    this.editSource = '';
  }
  @action cancelChanges = () => {
    this.handleRouteBackOnEdit(undefined);
  }

  @action setCancelChangesConfirmation(value: boolean) {
    this.isCancelChangesConfirmation = value;
  }

}

class AddressValidator extends AbstractValidator<ManageAttributesViewModel> {
  public constructor() {
    super();
  }
}
