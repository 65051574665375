import { action, observable } from "mobx";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { CarrierListModel } from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";

export class CarrierListViewModel extends BaseListViewModel<CarrierListModel> {
  @observable searchInputValue: string = "";
  @observable rows: number = 50;
  @observable totalRecords: number = 0;
  @observable searchValue: string = '';
  @observable isHidden: boolean|undefined = false;
  @observable isNotHiddenChecked: boolean|undefined = true;
  @observable isHiddenChecked: boolean|undefined = undefined;
    @observable isHiddenAllChecked: boolean | undefined = undefined;

    constructor()
    {
      super("Carriers", "Carrier", routes.addCarrier, routes.editCarrier, "carrierId", "carrierName");
      this.pageSize = 200;
    }

    @action async goSearch() {
        await this.loadCarriers();
    }
  @action resetFilters = () => {

    this.searchValue = "";
    this.searchInputValue = "";
    this.isHidden=false;
    this.isNotHiddenChecked  = true;
    this.isHiddenChecked =false;
    this.isHiddenAllChecked  = false;
    this.sortColumn="carrierName";
    this.isSortAscending=true;
     this.resetPagingValues();
    
    this.loadCarriers();
  }

  @action resetPagingValues() {
    this.rows = 10;
    this.totalRecords = 0;
     
  }
   loadCarriers=async ()=>{
    let  res = await ContractsStore.getAllCarriers(
      this.isHidden,
      0,
      this.pageSize,
      this.sortColumn,
      true,
      this.searchInputValue
    ); 
    if(res)
    {
      if(res.data)
        this.itemList.values = res.data;
        this.totalRecords=res.recordCount||0;
        this.totalRowsCount=res.recordCount||0;
    }

  }
  
  protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
    return await ContractsStore.getAllCarriers(this.isHidden,pageIndex, pageSize, sortColumn, sortOrder,this.searchInputValue);
  }

  protected async deleteItem(id: number) {
    // delete is not available for carrier
  }

  @action downloadCarrierListAsExcel = async() => {
    let res = await ContractsStore.getAllCarriersAsExcel();
    Utils.saveDownloadedReportFile(res, "Carriers");
  }

  resetFiltersToDefault() {   
      this.pageSize = 200;
      this.resetFilters();
  }
  
}
