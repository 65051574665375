import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import Utils from "../../infrastructure/Utils";
import ProducerStore from "../../store/ProducerStore";
import { MarketingEventEmailListDetailModel } from "../../services/ProducerService";

export class AddEditMarketingEventListViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }

  get CanRoute(): boolean {
    return true;
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: ListListener | undefined;

  @observable id: number | undefined = undefined;
  @observable title: string = "Add Marketing Event List";
  @observable addListTitle: string = "";
  @observable date: Date = new Date()
  @observable a : Date | any = new Date().setDate(new Date().getDate())
  @observable startTime: Date | undefined = new Date(this.a);
  @observable endTime: Date | undefined = new Date(this.a);
  @observable validationErrorDateTime: boolean = false;
  @observable eventId: number | undefined = undefined;
  @observable isLoading: boolean = false;
  @observable isdisableSave:boolean=false;

  @action reset = () => {
    this.addListTitle = "";
    this.a = new Date().setDate(new Date().getDate())
    this.startTime = new Date(this.a);
    this.endTime = new Date(this.a);
    this.id = undefined;
    this.validationErrorDateTime = false;
    this.isdisableSave=false;
  };

  @action getValidationForDateTime = () => {
    let end = this.endTime ? this.endTime : 0;
    let start = this.startTime ? this.startTime : 0;
    let dateTimeChck = start > end;
    if (dateTimeChck) this.validationErrorDateTime = true;
    else this.validationErrorDateTime = false;
    return dateTimeChck;
  };

  @action setAddListTitle = (value: string) => {
    this.addListTitle = value;
  };

  @action setStartTime = (value: Date | undefined) => {
    this.startTime = value;
  };
  @action setEndTime = (value: Date | undefined) => {
    this.endTime = value;
  };

  setListListener(listener: ListListener) {
    this.listener = listener;
  }

  @action chkTime = (time: any) => {
    let chk = time ? time.split(":")[0].length : undefined;
    if (chk === 1) {
      let temp = time.split(":");
      temp[0] = "0".concat(temp[0]);
      temp = temp[0].concat(":", temp[1]);
      return temp;
    }
    return time;
  };

  @action Load = async (id?: number) => {
    // let tempDate = this.date.setDate(this.date.getDate() + 1);
    // this.startTime = new Date(tempDate)
    // this.endTime = new Date(tempDate)
    this.isdisableSave=false;
    if (id) {
      this.isLoading = true
      this.id = id;
      await this.getMarketingEventListById();
      this.isLoading = false
      this.title = "Edit Marketing Event List";
    } else {
      this.title = "Add Marketing Event List";
    }
  };
  @action getMarketingEventListById = async () => {
    let id = this.id ? this.id : 0;
    try {
      let result = await ProducerStore.getMarketingEventListById(id);
      if (result) {
        let response: MarketingEventEmailListDetailModel = result;
        this.addListTitle = response.listName ? response.listName : "";
        this.startTime = response.dateStart
          ? new Date(`${response.dateStart}`)
          : new Date();
        this.endTime = response.dateEnd
          ? new Date(`${response.dateEnd}`)
          : new Date();
      }
    } catch (e) {}
  };

  @action cancel = async () => {
    if (this.listener) {
      this.listener.cancelAddEditListListener();
      this.reset();
    }
  };

  @action save = async (eventId?: number) => {    
    this.eventId = eventId;
    await this.submit();
  };

  @action createDto = () => {
    let startDate = this.startTime
      ? Utils.getDateInFormat(this.startTime).toString()
      : undefined;
    let endDate = this.endTime
      ? Utils.getDateInFormat(this.endTime).toString()
      : undefined;
    let startTime = this.startTime
      ? Utils.getTimeInFormat(this.startTime).toString()
      : undefined;
    let endTime = this.endTime
      ? Utils.getTimeInFormat(this.endTime).toString()
      : undefined;
    if (this.id) {
      let dto = {
        marketingEventListId: this.id,
        marketingEventId: this.eventId,
        listName: this.addListTitle,
        dateStart: startDate,
        dateStop: endDate,
        timeStart: this.chkTime(startTime),
        timeStop: this.chkTime(endTime),
      };
      return dto;
    } else {
      let dto = {
        marketingEventListId: 0,
        marketingEventId: this.eventId,
        listName: this.addListTitle,
        dateStart: startDate,
        dateStop: endDate,
        timeStart: this.chkTime(startTime),
        timeStop: this.chkTime(endTime),
      };
      return dto;
    }
  };

  @action submit = async () => {
    await this.ResetValidate();
    try {
      this.isLoading = true
      if (!(await this.Validate()) && !this.getValidationForDateTime()) {
        this.isdisableSave=true;
        let dto = this.createDto();
        await ProducerStore.addMarketingEventList(dto);
        setTimeout(() => {
          if (this.listener) {
            this.listener.addEditListListener();
            this.listener.cancelAddEditListListener();
          }
          this.reset();
        }, 1000);
        this.isLoading = false
      }
    } catch (e) {
      this.isLoading = false
    }
  };


  public ErrorModel = new ErrorModel(new AddEditMarketingEventListValidator());
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
}

export interface ListListener {
  addEditListListener(): void;
  cancelAddEditListListener(): void;
}

class AddEditMarketingEventListValidator extends AbstractValidator<AddEditMarketingEventListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.addListTitle)
      .isNotEmpty()
      .hasMinLength(5)
      .withFailureMessage("Name should be 5 characters or more");
    this.validateIfDate((input) => input.startTime)
      .isNotEmpty()
      // .isSameOrBefore(new Date())
      .withFailureMessage("Start date time is required");
    this.validateIfDate((input) => input.endTime)
      .isNotEmpty()
      // .isSameOrAfter(new Date())
      .withFailureMessage("End date time is required");
  }
}
