import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../../store/ContractsStore";
import Utils from "../../../../infrastructure/Utils";
import DocumentStore from "../../../../store/DocumentStore";
import ProductStore from "../../../../store/ProductStore";

export class EmailAndFaxDetailsAddEditViewModel extends BaseAddEditViewModel {

    @observable docTypeList = new Collection<any>();
    @observable companyList = new Collection<any>();
    @observable productList = new Collection<any>();
    @observable stateList = new Collection<any>();
    @observable docTypeId: number = 0;
    @observable companyId: number = 0;
    @observable productId: number = 0;
    @observable stateId: number = 0;
    @observable selectedStates:any;
    @observable isInsertIntoUserSentItems: boolean = false;
    @observable description: string = '';
    @observable securityDelivery: boolean = false;
    @observable isDefault: boolean = false;
    @observable senderName: string = '';
    @observable senderEmail: string = '';
    @observable faxDomian: string = '';
    @observable emailFaxType: number = 0; // 0 - Email, 1 - Fax
    @observable email: string = '';
    @observable emailDisplay: string = '';
    @observable faxNumber: string = '';
    @observable faxNumberDisplay: string = '';
    @observable allStatesSelected: boolean = false;

    constructor() {
        super("Carrier Email and Fax details", routes.listEmailFaxDetails, new EmailAndFaxDetailValidator());
        if (this.selectedId === 0) {
            this.loadItem(0);
        }
    }

    protected async loadLookups() {
        this.productList.values = [{ label: "Select", value: '-1' }, { label: "No Specific Product", value: '0' }];

        var items = await ContractsStore.getAllCompanyLookup();
        Utils.mapListItemsToDropdown(items, this.companyList);

        var fileTypeItems = await DocumentStore.getDocumentFileTypesLookup();
        Utils.mapListItemsToDropdown(fileTypeItems, this.docTypeList);
    }

    @action async setSelectedCompany(companyId: number) {
        this.companyId = companyId;

        if(companyId < 1) {
            this.productList.values = [{ label: "Select", value: '-1' }, { label: "No Specific Product", value: '0' }];
        } 
        else {
            var items = await ProductStore.getReportProductList(companyId);
            items.unshift({ text: "No Specific Product", value: '0' });
            Utils.mapListItemsToDropdown(items, this.productList, "Select", "-1");
           
        }
        this.productId = -1;
    }


    protected toServiceModel() {
        var states:any;
        if (this.selectedStates && this.selectedStates.length > 0) {
            states = [];
            this.selectedStates.forEach((element: any) => {
                var selectedState = this.stateList.values.find((item:any) => { return item.value === element});
                states.push(selectedState ? selectedState.label : "");
            });
        }
        return {
            id: this.selectedId || 0,
            companyId: this.companyId || 0,
            productId: this.productId || 0,
            docTypeId: this.docTypeId || 0,
            faxNumber: this.faxNumber || undefined,
            faxNumberDisplay: this.faxNumberDisplay || undefined,
            secureDelivery: this.securityDelivery || false,
            faxDomain: this.faxDomian || undefined,
            description: this.description || undefined,
            senderEmail: this.senderEmail || undefined,
            senderName: this.senderName || undefined,
            insertIntoUserSentItems: this.isInsertIntoUserSentItems || false,
            //delayTell: TimeSpan;
            isDefault: this.isDefault || false,
            emailAndFaxDetails: {
                email: this.email,
                emailDisplay: this.emailDisplay,
                faxNumber: this.faxNumber,
                faxNumberDisplay: this.faxNumberDisplay
            },
            type: this.emailFaxType,
            selectedStates: states,
            allStatesSelected: this.allStatesSelected
        } as any;
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await ContractsStore.getEmailAndFaxDetailsById(id);
        if (item) {
            this.selectedId = item.id || 0;
            this.docTypeId = item.docTypeId || 0;
            this.companyId = item.companyId || 0;
            this.productId = (item.productId === 0 ? 0 : item.productId || -1);                       
            this.isInsertIntoUserSentItems = item.insertIntoUserSentItems || false;
            this.description = item.description || "";
            this.securityDelivery = item.secureDelivery || false;
            this.isDefault = item.isDefault || false;
            this.senderName = item.senderName || "";
            this.senderEmail = item.senderEmail || "";
            this.faxDomian = item.faxDomain || "";
            this.emailFaxType = item.type || 0;
            this.email = item.faxDomain || "";
            this.faxDomian = item.faxDomain || "";
            this.email = (item.emailAndFaxDetails ? item.emailAndFaxDetails.email || "" : "");
            this.emailDisplay = (item.emailAndFaxDetails ? item.emailAndFaxDetails.emailDisplay || "" : "");
            this.faxNumber = (item.emailAndFaxDetails ? item.emailAndFaxDetails.faxNumber || "" : "");
            this.faxNumberDisplay = (item.emailAndFaxDetails ? item.emailAndFaxDetails.faxNumberDisplay || "" : "");
             this.stateList.values = Utils.mapListItem(item.states);
            if (item.state && item.state.trim() === "*") {
                this.allStatesSelected = true;
            }
            else if (item.state){
                var state = this.stateList.values.find((element:any) => { return element.label === item.state});
                this.stateId = state.value;
            }
            else{
                this.selectedStates = undefined;
            }
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        console.log(item);
        await ContractsStore.addEmailAndFaxDetails(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        console.log(item);
        await ContractsStore.updateEmailAndFaxDetails(item);
    }
    /*
    @action async setLookUpByCompanyId(companyId: number) {
        this.isLoading = true;
        try {
            var result = await DocumentStore.getLookUpByCompanyId(companyId, 0, 0);
            if (result != null) {
                // Product
                if (result.products != null) {
                    this.productList.values = [{ label: "Select", value: '-1' }, { label: "No Specific Product", value: '0' }];
                    result.products.forEach((obj: any) => {
                        var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.productList.values.push(data);
                    });
                    this.productId = -1;
                }
                else {
                    this.productList.values = [{ label: "Select", value: '-1' }, { label: "No Specific Product", value: '0' }];
                    this.productId = -1;
                }
            }
        } catch (e) {
            this.productList.values = [{ label: "Select", value: '-1' }, { label: "No Specific Product", value: '0' }];
            this.productId = -1;
        }
        this.isLoading = false;
    }
    */
}

class EmailAndFaxDetailValidator extends AbstractValidator<EmailAndFaxDetailsAddEditViewModel> {
    public constructor() {
        super();
        
        this.validateIfNumber(input => input.docTypeId)
            .isNotEqualTo(0)
            .withFailureMessage("File Type is required.");

        this.validateIfNumber(input => input.companyId)
            .isNotEqualTo(0)
            .withFailureMessage("Carrier is required.");

        this.validateIfIterable(input => input.selectedStates)
            .isNotEmpty()
            .withFailureMessage("State is required.")
            .when(input => !input.allStatesSelected);

        this.validateIfString(input => input.email)
            .isNotEmpty()
            .matches(/\S+/,"i")
            .withFailureMessage("Email is required.")
            .when(input => input.emailFaxType === 0);

        this.validateIfString(input => input.email)
            .isEmail()
            .whenNotEmpty()
            .withFailureMessage("Email is invalid.")
            .when(input => input.emailFaxType === 0);

        this.validateIfString(input => input.emailDisplay)
            .matches(/\S+/,"i")
            .isNotEmpty()
            .withFailureMessage("Email Display is required.")
            .when(x => x.emailFaxType === 0);

        this.validateIfString(input => input.faxNumber)
            .matches(/\S+/,"i")
            .isNotEmpty()
            .withFailureMessage("Fax Number is required.")
            .when(x => x.emailFaxType === 1);

        this.validateIfString(input => input.faxNumberDisplay)
            .matches(/\S+/,"i")
            .isNotEmpty()
            .withFailureMessage("Fax Number Display is required.")
            .when(x => x.emailFaxType === 1);
    }
}