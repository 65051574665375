import { observable, action } from "mobx";
import AutoDialerStore from "../../store/AutoDialerStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import IMASLog from "../../infrastructure/IMASLog";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import {
    AgentNotesModel, AgentSummaryMarketer, AgentSummaryHierarchy,
    CreateNoteAndTaskModel, GetRecentCalledAgentsResponseModel, UpdateBadPhoneNumberModel, MarkAgentAsCalledModel
} from "../../services/AutoDialerService";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { routes } from "../../router";


export class StartCallingViewModel implements ViewModel {

    @observable
    private loading = false;
    private onClose?: () => void;

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        return true;
    }
    get IsLoading(): boolean {
        return this.loading;
    }
    Close(): void {
        if (this.onClose) this.onClose();
    }
    @action Load = async (callListId: number) => {
        this.ResetValidate();
        this.ResetFields();
        await this.getLast5CalledAgents(callListId);
        await this.getExistingCallListInfo(callListId);
        await this.getRandomAgentInfo(callListId);

    };

    // declaration for route
    Route = async (currentRoute: RouteList): Promise<void> => { }

    @observable response: any;
    @observable callListId: number | undefined;

    @observable callListName: any;
    @observable createdDate: Date | undefined;
    @observable called: number | undefined;
    @observable remaining: number | undefined;
    @observable startingTotal: number | undefined;

    @observable agentId: number | undefined;
    @observable agentName: string | undefined;
    @observable lastCalledAgentId: number | undefined;
    @observable lastCalledAgentName: string | undefined;
    @observable email: string | undefined;
    @observable phonenumber: string | undefined;
    @observable city: string | undefined;
    @observable state: string | undefined;
    @observable statustext: string | undefined;
    @observable currenttime: string | undefined;
    @observable timezone: string | undefined;
    @observable zip: string | undefined;
    @observable contactId: number | undefined;
    @observable maxDate: Date = new Date();
    @observable taskDate: Date = new Date();
    @observable noteAndTaskMessage: string | undefined;
    @observable updatedPhoneNumberMessage: string | undefined;

    @observable addComments: string = "";
    private ResetFields() {
        this.lastSkippedAgentsList = [];
        this.last5SkippedAgentsList = [];
        this.lastCalledAgentsList.values = [];
        this.callListId = 0;
        this.callListName = "";
        this.createdDate = undefined;
        this.called = 0;
        this.remaining = 0;
        this.startingTotal = 0;
        this.agentId = 0;
        this.agentName = undefined;
        this.email = undefined;
        this.phonenumber = undefined;
        this.contactId = undefined;
        this.city = undefined;
        this.state = undefined;
        this.statustext = undefined;
        this.currenttime = undefined;
        this.timezone = undefined;
        this.zip = undefined;
        this.agentNotesModel.values = [];
        this.hierarchies.values = [];
        this.marketers.values = [];
    }

    @action setAddComments(value: string) {
        this.addComments = value;
    }
    @action setTaskDate(value: Date | Date[]) {
        if (value instanceof Date) {
            this.taskDate = value;
        }

    }
    @observable followupTask: boolean = false;
    @observable makeNotePrivate: boolean = false;
    @observable currentDate: Date = new Date();
    @observable agentNotesModel = new Collection<AgentNotesModel>()
    @observable hierarchies = new Collection<AgentSummaryHierarchy>()
    @observable marketers = new Collection<AgentSummaryMarketer>()
    @observable lastCalledAgentsList = new Collection<GetRecentCalledAgentsResponseModel>()
    @observable lastSkippedAgentsList: any = [];
    @observable last5SkippedAgentsList: any = [];

    @action getExistingCallListInfo = async (callListId: number) => {
        try {
            if (callListId > 0) {
                var result = await AutoDialerStore.loadExistingCallingList(0, 1, 'id', false, callListId);
                if (result.data) {
                    this.callListId = result.data[0].callListId;
                    this.callListName = result.data[0].callListName;
                    this.createdDate = result.data[0].createdDate;
                    this.called = result.data[0].called;
                    this.remaining = result.data[0].remaning;
                    this.startingTotal = result.data[0].startingTotal;
                }
            }
        } catch (e) {
            this.response = e.response;
        }
    };

    @action getRandomAgentInfo = async (callListId: any) => {
        try {
            if (callListId > 0) {
                var result = await AutoDialerStore.getRandomAgentInfo(callListId);
                if (result) {
                    var output: any = [];
                    output = result;
                    this.displayAgentInfo(output);
                }
            }
        } catch (e) {
            this.response = e.response;
            this.agentNotesModel.values = [];
            this.hierarchies.values = [];
            this.marketers.values = [];
        }
    };

    @action findAnotherAgent = async (callListId: any, agentId: any, agentName: any) => {
        try {
            var filteredAgent = this.lastSkippedAgentsList.filter((item: any) => { return item.agentId === agentId })
            var calledAgent = this.lastCalledAgentsList.values.filter((item: any) => { return item.agentId === agentId })
            if ((filteredAgent && filteredAgent.length === 0) && (calledAgent && calledAgent.length === 0)) {
                this.lastSkippedAgentsList.push({
                    agentId: agentId ? agentId : "",
                    agentName: agentName ? agentName : "",
                    callListId: callListId ? callListId : "",
                });
            }
            //  this.lastSkippedAgentsList.slice(Math.max(this.lastSkippedAgentsList.length - 5, 0))
            this.last5SkippedAgentsList = this.lastSkippedAgentsList.slice(-5).reverse();

            if (callListId > 0) {
                var result = await AutoDialerStore.getRandomAgentInfo(callListId);
                if (result) {
                    var output: any = [];
                    output = result;
                    this.displayAgentInfo(output);
                }
            }
        } catch (e) {
            this.response = e.response;
            this.agentNotesModel.values = [];
            this.hierarchies.values = [];
            this.marketers.values = [];
        }
    };

    @action getAgentInfo = async (agentdata: any) => {
        try {
            if (agentdata.callListId > 0) {
                var result = await AutoDialerStore.getAgentInfo(agentdata.callListId, agentdata.agentId);
                if (result) {
                    var output: any = [];
                    output = result;
                    this.displayAgentInfo(output);
                }
            }
        } catch (e) {
            this.response = e.response;
            this.agentNotesModel.values = [];
            this.hierarchies.values = [];
            this.marketers.values = [];
        }
    };

    @action displayAgentInfo(output: any) {
        this.agentId = output.agentId;
        this.agentName = output.name;
        this.email = output.email;
        this.phonenumber = output.phoneNumber;
        this.contactId = output.contactId;
        this.city = output.city;
        this.state = output.state;
        this.statustext = output.statusText;
        this.currenttime = output.currentTime;
        this.timezone = output.timeZone;
        this.zip = output.zip;
        this.agentNotesModel.values = output.notes;
        this.hierarchies.values = output.hierarchies;
        this.marketers.values = output.marketers;
    }
    @action getLast5CalledAgents = async (callListId: number) => {
        try {
            if (callListId > 0) {
                var result = await AutoDialerStore.getLast5CalledAgents(callListId, 5)
                if (result) {
                    var output: any = [];
                    output = result.data;
                    this.lastCalledAgentsList.values = output;
                }
            }
        }
        catch (e) {
            this.response = e.response;
            this.lastCalledAgentsList.values = [];
        }
    };

    @action markAgentAsCalled = async (agentId: any, callListId: any) => {
        try {
            var markAgentAsCalledModel: MarkAgentAsCalledModel = {};
            markAgentAsCalledModel.agentId = agentId ? agentId : 0;
            markAgentAsCalledModel.callListId = callListId ? callListId : 0;
            await AutoDialerStore.markAgentAsCalled(markAgentAsCalledModel).then(async (response) => {
                let result = response;
                this.callListName = result.callListName;
                this.called = result.called;
                this.remaining = result.remaning;
                this.startingTotal = result.startingTotal;
                // fetch the updated results and display
                await this.getRandomAgentInfo(callListId);
                this.lastSkippedAgentsList = this.lastSkippedAgentsList.filter((item: any) => { return item.agentId !== agentId });
                await this.getLast5CalledAgents(callListId);
                this.last5SkippedAgentsList = this.lastSkippedAgentsList.slice(-5).reverse();
            }).catch((error) => { IMASLog.log("exception: " + error); });

        } catch (error) {
            IMASLog.log("exception: " + error);
        }
    }

    @action UpdatePhoneNumber = async (agentId: any, contactId: any) => {
        try {
            var badPhoneModel: UpdateBadPhoneNumberModel = {};
            badPhoneModel.agentId = agentId ? agentId : 0;
            badPhoneModel.contactId = contactId ? contactId : 0;
            var result = await AutoDialerStore.updateBadPhoneNumber(badPhoneModel);
            if (result) {
                if (result.newContactInfo != null) {
                    var output: any = [];
                    output = result.newContactInfo;
                    this.contactId = output.contactId;
                    this.phonenumber = output.phoneNumber;
                } else {
                    this.phonenumber = "";
                }
                this.updatedPhoneNumberMessage = result.message;
                setTimeout(() => {
                    this.updatedPhoneNumberMessage = '';
                }, 5000)
            }
        } catch (error) {
            IMASLog.log("exception: " + error);
        }
    }

    @action saveNoteAndTask = async (callListId: any) => {
        if (!(await this.Validate())) {
            try {
                var noteAndTaskdto: CreateNoteAndTaskModel = {};
                noteAndTaskdto.agentId = this.agentId ? this.agentId : 0;
                noteAndTaskdto.includeTasks = this.followupTask;
                noteAndTaskdto.includeNotes = this.makeNotePrivate;
                noteAndTaskdto.comments = this.addComments;
                noteAndTaskdto.date = this.taskDate;
                await AutoDialerStore.createNoteAndTask(noteAndTaskdto).then(resp => {
                    let message = this.followupTask ? 'Note/Task' : 'Note';
                    this.noteAndTaskMessage = message + " saved successfully!";
                    let agentId = this.agentId;
                    this.getAgentInfo({ callListId, agentId })
                    this.followupTask = false;
                    this.makeNotePrivate = false;
                    this.addComments = "";
                    setTimeout(() => {
                        this.noteAndTaskMessage = '';
                    }, 5000)

                }).catch(error => {
                    IMASLog.log("exception: " + error);
                });

            } catch (error) {
                IMASLog.log("exception: " + error);
            }
        }
        else {
            setTimeout(() => {
                return this.ErrorModel.ResetValidation(this);
            }, 2500)
        }
    }
    public callPhoneNumber = async (phoneNumber: string, sourceName: string) => {
        try {
            await AutoDialerStore.callLog(phoneNumber, sourceName, this.agentId);
            window.open(`rcapp://r/call?number=${this.phonenumber}`, '_parent');
        } catch (error) {
            IMASLog.log("exception: " + error);
        }
    };
    @action goToNotesTab = (id: string, param: string, divisionId: number | undefined) => {
        IMASLog.log('values id: ' + id + ' param = ' + param);

        switch (param) {
            case 'hierarchies':
                RouteParamDetails.notesUserId = 0;
                window.open(window.location.origin + "/agents/"+id, '_blank');
                break;
            case 'marketers':
                RouteParamDetails.notesUserId = 0;
                if (this.agentId && divisionId) {
                    window.open(window.location.origin + "/agents/" + this.agentId +"/contracts/"+ id +"/" + divisionId, '_blank');
                }
                break;
        }
    };
    @action goBack = () => {
        routes.backToAutoDialer.push();
    }
    @action addNewAgentLead = async () => {
        localStorage.setItem("agentId", this.agentId ? this.agentId.toString() : "0")
        localStorage.setItem("agentName", this.agentName || '')
        window.open(window.location.origin + "/marketerleads/add", '_blank');
        //routes.addAgentLead.push();
    };
    public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    };

    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    };

    @observable
    public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<StartCallingViewModel>{
    public constructor() {
        super();
        this.validateIfDate((input) => input.taskDate)
            .isNotEmpty()
            .withFailureMessage("Task Date is required");
        this.validateIfString((input) => input.addComments)
            .isNotEmpty()
            .isNotEqualTo("NONE")
            .withFailureMessage("Notes is required.");
    }
}