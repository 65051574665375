import { User } from '@auth0/auth0-react';
import { userInfo } from 'os';
import { AuthenticationContext, AdalConfig, adalGetToken } from 'react-adal';
import Utils from './Utils';


export const getToken = async () => {
    try {
        return localStorage.getItem("auth0offline_access") || "";
    } catch (e) {
        return ''; 
    }
}


export const getUser = () => {
    return '';
}

class LoggedInUser {
    displayName: string = '';
    userName: string = '';
    initialsName: string = '';
    adUserId: string = '';
}
export default new LoggedInUser();