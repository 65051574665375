import { Util } from "@microsoft/applicationinsights-web";
import { observable, action } from "mobx";
import Collection from "../../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../../infrastructure/RouteList";
import Utils from "../../../../../infrastructure/Utils";
import ViewModel from "../../../../../infrastructure/ViewModel";
import { RequirementSubmissionMethodModel } from "../../../../../services/BusinessUnitConfigService";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";

export class RequirementsViewFileViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = "View Requirements";
  @observable submisisonMethodType = new Collection<{
    label: string;
    value: string;
  }>();
  @observable ItemList: any;
  @observable isLoading: boolean = false;
  @observable isResult: boolean = false;
  @observable selectedId: number = 0;
  @observable selectedDocTypes: string = "0";
  @observable selectedCarrierIds: string = "0";
  @observable selectedPrdtSubCat: string = "0";
  @observable selectedPrdts: string = "0";
  @observable selectedStates: string = "0";
  @observable selectedPolicyEffectiveDateYear: string = "0";
  @observable selectedRequirementType: string = "-1";

  @observable requirementTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable docTypesList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable carrierList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productSubCatList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statesList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable policyEffectiveDateYearList = new Collection<{
    label: string;
    value: string;
  }>();

  @action Load = async () => {
    await this.getRequirementById();
    await this.getRequirementResults();
  };

  @action getRequirementResults = async () => {
    try {
      this.isLoading = true;
      this.isResult = false;
      let result =
        await BusinessUnitConfigStore.viewRequirementSubmissionMethods(
          +this.selectedRequirementType,
          +this.selectedDocTypes,
          +this.selectedCarrierIds,
          +this.selectedStates,
          +this.selectedPrdts,
          +this.selectedPrdtSubCat,
          +this.selectedPolicyEffectiveDateYear
        );
      if (result) {
        this.ItemList = result.isSubmissionRequired
          ? result.isSubmissionRequired
          : false;
        this.isResult = true;
        this.isLoading = false;
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action getRequirementById = async () => {
    try {
      this.isLoading = true;
      let result =
        await BusinessUnitConfigStore.getRequirementSubmissionMethodById(
          this.selectedId
        );

      if (result) {
        let details: RequirementSubmissionMethodModel = result;
        this.loadDetails(details);
        this.getRequirementTypeList();
      }
    } catch (e) {
      this.isLoading = false;

      this.selectedDocTypes = "";
      this.selectedCarrierIds = "";
      this.selectedPrdtSubCat = "";
      this.selectedPrdts = "";
      this.selectedStates = "";
      this.selectedPolicyEffectiveDateYear = "";

      this.docTypesList.values = [];
      this.carrierList.values = [];
      this.productSubCatList.values = [];
      this.productList.values = [];
      this.statesList.values = [];
      this.policyEffectiveDateYearList.values = [];
    }
  };

  @action loadDetails = (dto: RequirementSubmissionMethodModel) => {
    this.docTypesList.values = [];
    this.carrierList.values = [];
    this.productSubCatList.values = [];
    this.productList.values = [];
    this.statesList.values = [];
    this.policyEffectiveDateYearList.values = [];
    this.docTypesList.values = dto.documentTypes
      ? Utils.mapListItem(dto.documentTypes)
      : [];
    this.docTypesList.values.splice(0, 0, { label: "All", value: "0" });

    this.carrierList.values = dto.companies
      ? Utils.mapListItem(dto.companies)
      : [];
    this.carrierList.values.splice(0, 0, { label: "All", value: "0" });

    this.productSubCatList.values = dto.productSubcategories
      ? Utils.mapListItem(dto.productSubcategories)
      : [];
    this.productSubCatList.values.splice(0, 0, { label: "All", value: "0" });

    this.productList.values = dto.products
      ? Utils.mapListItem(dto.products)
      : [];
    this.productList.values.splice(0, 0, { label: "All", value: "0" });

   // this.statesList.values = dto.states ? Utils.mapListItem(dto.states) : [];
      this.statesList.values = dto.states ? this.mapStateListItemAndSort(dto.states) : [];
    this.statesList.values.splice(0, 0, { label: "All", value: "0" });

    this.policyEffectiveDateYearList.values = dto.policyEffectiveDateYearsList
      ? Utils.mapListItem(dto.policyEffectiveDateYearsList)
      : [];
    this.policyEffectiveDateYearList.values.splice(0, 0, {
      label: "All",
      value: "0",
    });
    };

    @action mapStateListItemAndSort(listObjects: any) {
        if (listObjects) {
            var sortList = listObjects.sort(Utils.compareListItem);
            sortList.forEach((element: any) => {
                element.label = element.stateName;
                element.value = element.stateId;
            });
            return sortList;
        }
        else {
            return [];
        }
    }

  @action getRequirementTypeList = async () => {
    let list = [
      { label: "All", value: "-1" },
      { label: "Agent", value: "0" },
      { label: "Insured", value: "1" },
    ];
    this.requirementTypeList.values = [];
    this.requirementTypeList.values = list;
    this.selectedRequirementType = this.requirementTypeList.values[0].value;
  };

  @action setSelectedRequirementType(value: string) {
    this.selectedRequirementType = value;
  }

  @action setSelectedDocTypes = (value: any) => {
    this.selectedDocTypes = value;
  };

  @action setSelectedCarrierIds = (value: any) => {
    this.selectedCarrierIds = value;
  };
  @action setSelectedPrdtSubCat = (value: any) => {
    this.selectedPrdtSubCat = value;
  };

  @action setSelectedPrdts = (value: any) => {
    this.selectedPrdts = value;
  };

  @action setSelectedStates = (value: any) => {
    this.selectedStates = value;
  };

  @action setSelectedPolicyEffectiveDateYear = (value: any) => {
    this.selectedPolicyEffectiveDateYear = value;
  };

  @action goSearch = async () => {
    await this.getRequirementResults();
  };

  @action resetFilters = async () => {
    this.selectedDocTypes = "0";
    this.selectedCarrierIds = "0";
    this.selectedPrdtSubCat = "0";
    this.selectedPrdts = "0";
    this.selectedStates = "0";
    this.selectedPolicyEffectiveDateYear = "0";
    this.selectedRequirementType = "-1";
    this.isResult = false;
    await this.getRequirementResults();
  };
}
