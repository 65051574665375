import { action } from "mobx";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
// import { Client as ContractServiceClient, CustAttrToEntityModel, DocumentAttachment, StateLicenseModel } from "../services/ContractService";
import {
  Client as StateLicenseServiceClient,
  CustAttrToEntityModel,
  DocumentAttachment,
  StateLicenseModel,
} from "../services/StateLicenseService";
class AgentStateLicensesStore {
  private loaded = false;

  getApiVersion = () => {
    return "1";
  };
  getClient = (): StateLicenseServiceClient => {
    return ServiceClient.getClient(ServiceType.StateLicense);
  };

  @action
  public getStateLicenseDetail = async (ID: number) => {
    return this.getClient().getStateLicenseById(
      ID,
      this.getApiVersion(),
      UserContext.userId
    );
  };

  @action
  public loadStateLicenseList = async (
    agentId?: number,
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = this.getClient().getAllStateLicenses(
      this.getApiVersion(),
      agentId,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.userId,
      undefined
    );

    return result;
  };

  @action
  public addStateLicense = async (dto: StateLicenseModel) => {
    var result = await this.getClient().addStateLicense(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );

    return result;
  };

  @action
  public editStateLicense = async (dto: StateLicenseModel) => {
    var result = await this.getClient().updateStateLicense(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };

  @action
  public deleteStateLicense = async (id: number) => {
    var result = this.getClient().deleteStateLicense(
      id,
      this.getApiVersion(),
      UserContext.userId,
      undefined
    );
    return result;
  };

  @action
  public getProductLinesLookup = async () => {
    var result = this.getClient().getProductLinesLookup(
      this.getApiVersion(),
      UserContext.userId,
      undefined
    );
    return result;
  };
  @action
  public getAllStatesLookup = async () => {
    var result = this.getClient().getAllStatesLookup(
      this.getApiVersion(),
      UserContext.userId,
      undefined
    );
    return result;
  };

  @action
  public checkStateLicenseDuplicateByAgent = async (
    id?: number | undefined,
    agentId?: number | undefined,
    stateId?: number | undefined,
    issueDate?: Date | null | undefined,
    expirationDate?: Date | undefined,
    stateLicense?: string | null | undefined,
    residencyStatusId?: number | undefined,
    renewalDate?: Date | null | undefined,
    stateLicenseStatusId?: number | undefined,
    stateLicenseActionStatusId?: number | undefined,
    notes?: string | null | undefined,
    licenseLineOfAuthorityIds?: number[] | null | undefined,
    documentAttachmentList?: DocumentAttachment[] | null | undefined,
    custAttrPathId?: number | undefined,
    entityId?: number | null | undefined,
    custAttrToEntityList?: CustAttrToEntityModel[] | null | undefined
  ) => {
    var result = await this.getClient().checkStateLicenseDuplicateByAgent(
      this.getApiVersion(),
      id,
      agentId,
      stateId,
      issueDate,
      stateLicense,
      stateLicenseStatusId,
      licenseLineOfAuthorityIds,
      UserContext.userId,
      undefined
    );

    return result;
  };
}

export default new AgentStateLicensesStore();
