import { observable, action } from "mobx";
import Utils from "../../../infrastructure/Utils";
import { routes } from "../../../router";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import ContractsStore from "../../../store/ContractsStore";
import { BaseListViewModel } from "../../components/BaseListViewModel";

export class ImportListViewModel extends BaseListViewModel<any> {
    constructor()
    {
        super("Manage Imports", "New Import", routes.addImports, routes.editImports, "importId");
    }
  
    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
      return  await BusinessUnitConfigStore.getAllImportDefinition(+this.selectedImportType, this.selectedCarrierId, this.importName, pageIndex, pageSize, sortColumn, sortOrder)
    }

    @observable importTypes: any  = [
      { label: "Agent Debt", value: "3" },
      { label: "Agent RTS Info", value: "4" },
      { label: "Contract", value: "2" },
      { label: "Lead", value: "6" },
      { label: "Persistancy", value: "5" },
      { label: "Policy Dashboard", value: "0" },
      { label: "Revenue Dashboard", value: "1" },
    ];
    @observable selectedImportType: string = "0"
    @observable importName: string = ""
    @observable selectedCarrierId: number = 0;
    @observable carrierList: any;

    @action goToAdmin = () => {
      routes.administration.push()
    }  

    @action reset = async () => {
        this.resetPaging();
        this.resetFiltersToDefault();
        this.loadItems();
    }

    @action search = async () => {
      this.resetPaging();
      this.loadItems();
    };
  
    resetFiltersToDefault() {
      this.pageSize = 50;
    }

    @action resetAll = async() => {
      this.importName = "";
      this.selectedImportType = "0";
      this.selectedCarrierId = this.carrierList.length > 0 ? this.carrierList[0].value : "";
      this.loadItems();
    }
  
    protected async loadLookups(): Promise<void> {
      await this.loadCarriers();  
    }

    @action onRowSelect = async(data: any, e: any) => {
      localStorage.setItem("selectedImportType", ''+data.importTypeId);
    }

    @action loadCarriers = async () => {
      var items = await ContractsStore.getAllCompanyLookup();
      this.carrierList = Utils.mapListItemsAsList(items, "");
      this.selectedCarrierId = this.carrierList.length > 0 ? this.carrierList[0].value : ""
    }

    protected async deleteItem(id: number) {
      await BusinessUnitConfigStore.deleteImportDefinition(id);
    }

}