import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { AddSendToCommentsModel } from "../../services/WorkflowService";
import { WorkflowStatusType } from "../../infrastructure/enum/WorkflowDetails";
import WorkflowStore from "../../store/WorkflowStore";
import Utils from "../../infrastructure/Utils";
import { Constants } from "../../infrastructure/enum/Constants";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import UserContext from "../../infrastructure/UserContext";
import { googleTagManager } from "../../infrastructure/tracking";

export class SendToViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable exceptionMsg: string = "";
  @observable isException: boolean = false;
  @observable workFlowStatusType: any;
  @observable assignedToUsers: any;
  @observable assignedToUserTeam: any;
  @observable selectedAssignedToUser: any;
  @observable selectedAssignedToUserTeam: any;
  @observable selectedSendtoEmailUser: string = "1";
  @observable title: string = "Select a user to send this document to:";
  @observable deleteTitle: string = "Why are you deleting this document?";
  @observable comments: string = "";
  @observable isDialogVisible: boolean = false;
  @observable isUpload: boolean = false;
  @observable fileName: string = "";
  @observable response: any = null;
  @observable isMessageVisible: boolean = false;
  @observable isWorkflowApiCall: boolean = true;
  @observable moveToMissingInfo: boolean = true;
  @observable sendEmailTo: boolean = false;
  @observable documentId: number = 0;
  @observable emailList: any = [];

  @action Load = async () => {
    await this.ResetValidate();
    this.reset();
    this.selectedAssignedToUser = "0";
    this.selectedAssignedToUserTeam = "0";
    this.moveToMissingInfo = true;
  };

  @action setComments(value: string) {
    this.comments = value;
  }

  @action cancel = async () => {
    if (this.listener) {
      this.listener.cancelSendToUser();
      this.reset();
    }
  };

  @action public save = async (docId: number, workflowStatus: any) => {
    this.isMessageVisible = false;
    this.response = "";
    this.documentId = docId;
    this.workFlowStatusType = workflowStatus;
    try {
      await this.ResetValidate();
      if (!(await this.Validate())) {
        if (this.isWorkflowApiCall) {
          googleTagManager.trackAction("workflow_doc_moved", {
            feature: "Workflow",
            user_id: UserContext.userId,
            old_queue: this.workFlowStatusType,
            new_queue: WorkflowStatusType.MissingInfoQueue,
          });
          var addCommentsdto: AddSendToCommentsModel = {};
          addCommentsdto.workflowStatusId =
            this.workFlowStatusType === WorkflowStatusType.ScrubQueue
              ? 2
              : this.workFlowStatusType === WorkflowStatusType.MissingInfoQueue
              ? 3
              : 100;
          addCommentsdto.assignedToUserId =
            this.selectedAssignedToUser && this.selectedAssignedToUser > 0
              ? Number(this.selectedAssignedToUser)
              : 0;
          addCommentsdto.assignedToTeamId =
            this.selectedAssignedToUserTeam &&
            this.selectedAssignedToUserTeam > 0
              ? Number(this.selectedAssignedToUserTeam)
              : 0;
          addCommentsdto.notes = this.comments ? this.comments : "";
          if (this.workFlowStatusType === WorkflowStatusType.MissingInfoQueue) {
            addCommentsdto.chkSendEmail = this.sendEmailTo
              ? this.sendEmailTo
              : false;
            addCommentsdto.chkMoveToMissingInfo = true;
          } else {
            addCommentsdto.chkSendEmail = this.sendEmailTo
              ? this.sendEmailTo
              : false;
            addCommentsdto.chkMoveToMissingInfo = this.moveToMissingInfo
              ? this.moveToMissingInfo
              : false;
          }
          if (
            addCommentsdto.assignedToUserId === 0 &&
            addCommentsdto.assignedToTeamId === 0
          ) {
            setTimeout(() => {
              this.exceptionMsg = "Select an individual or user team to assign";
            }, 250);

            this.isException = true;
            return 0;
          } else {
            this.exceptionMsg = "";
            this.isException = false;
            try {
              this.isWorkflowApiCall = false;
              await WorkflowStore.sendTo(this.documentId, addCommentsdto);
              this.cancel();
              routes.docWorkflow.push();
              //window.location.pathname = Constants.docWorkflow
            } catch (e: any) {
              this.isMessageVisible = true;
              e && e.text
                ? e &&
                  e.text().then((res: any) => {
                    this.response = res;
                  })
                : (this.response = e.response);
            }
          }
        }
        this.isWorkflowApiCall = true;
      }
    } catch (e: any) {
      this.isWorkflowApiCall = true;
      this.isMessageVisible = true;
      e && e.text
        ? e &&
          e.text().then((res: any) => {
            this.response = res;
          })
        : (this.response = e.response);
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: SendToListener | undefined;

  @observable locationList = [
    { label: "At the beginning", value: "1" },
    { label: "In the end", value: "2" },
  ];
  @observable sendtoEmailUserList = [{ label: "Me", value: "1" }];

  @action setSendToEmailUser = (value: string) => {
    this.selectedSendtoEmailUser = value;
  };
  @action setSelectedAssignedToUser = (value: string) => {
    this.selectedAssignedToUser = value;
    let loggedInUser = Utils.getUserId();
    if (Number(this.selectedAssignedToUser) !== loggedInUser) {
      if (this.assignedToUsers) {
        let a = this.assignedToUsers.filter(
          (ind: any) => ind.value === this.selectedAssignedToUser
        );
        let b = {
          label: a ? a[0].label : "None",
          value: this.selectedAssignedToUser,
        };
        this.sendtoEmailUserList[1] = b;
      }
    }
    if (
      this.selectedAssignedToUser !== "ALL" &&
      this.selectedAssignedToUser !== ""
    ) {
      this.selectedAssignedToUserTeam = "0";
    } else {
      this.selectedAssignedToUser = "0";
    }
  };
  @action setSelectedAssignedToUserTeam = (value: string) => {
    this.sendtoEmailUserList = [];
    this.sendtoEmailUserList = [{ label: "Me", value: "1" }];
    this.selectedSendtoEmailUser = "1";
    this.selectedAssignedToUserTeam = value;
    if (
      this.selectedAssignedToUserTeam !== "ALL" &&
      this.selectedAssignedToUserTeam !== ""
    ) {
      this.selectedAssignedToUser = "0";
    } else {
      this.selectedAssignedToUserTeam = "0";
    }
  };

  @action setMoveToMissingInfo(value: boolean) {
    this.moveToMissingInfo = value;
  }

  @action setSendEmailTo(value: boolean) {
    this.sendEmailTo = value;
  }

  @action setIsException(value: boolean) {
    this.isException = value;
  }

  @action reset = () => {
    this.comments = "";
    this.selectedAssignedToUserTeam = "0";
    this.selectedAssignedToUser = "0";
    this.moveToMissingInfo = true;
    this.sendEmailTo = false;
    this.isMessageVisible = false;
    this.response = "";
    this.selectedSendtoEmailUser = "1";
    this.ResetValidate();
  };

  setDocumentListener(listener: SendToListener) {
    this.listener = listener;
  }

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @observable
  public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<SendToViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.comments)
      .hasMaxLength(200)
      .whenNotEmpty()
      .withFailureMessage("Comment should not be more than 200 characters");
    this.validateIfString((input) => input.comments)
      .isNotEmpty()
      .withFailureMessage("Comment is required");
  }
}

export interface SendToListener {
  sendToUserListener(): void;
  cancelSendToUser(): void;
}
