import { observable, action } from 'mobx';
import { Permission } from './enum/Permission';
type NewType = undefined;
class UserContext {
  @observable userId: number | undefined = 0;
  @observable agencyId: string = "0";
  @observable marketerId: number | undefined = 0;
  @observable teamId: number = 0;
  @observable downlineAgencyId: number = 0;
  @observable displayName: string | undefined = '';
  @observable roles?: any | NewType = [];
  @observable permissions?: any | NewType = [];
  @observable isDownlineAgency: boolean = false;
  @observable userProperties: string | undefined = '';


  @action hasPermmission = (permission: Permission, id?: number) => {
    if (id) {
      return this.permissions && this.permissions.filter((item: any) => { return item.name === permission && item.id === id }).length > 0;
    } else {
      return this.permissions && this.permissions.filter((item: any) => { return item.name === permission; }).length > 0;
    }
  }
  @action hasDeleteDoc = () => {
    let arr = []
    if (this.permissions) {
      arr = this.permissions.filter((item: any) => { return item.name === Permission.DeleteDocument })
    }
    return arr
  }

  @action getUserId = () => {
    return this.userId
  }
  @action getUserName = () => {
    return this.displayName
  }
  @action userHasDownlineAgency = () => {
    return this.isDownlineAgency === true ? true : false;
  }
  @action userHasDownlineAgencyId = () => {
    return this.downlineAgencyId;
  }
  @action getUserProperties = () => {
    return this.userProperties;
  }
}

export default new UserContext();

export interface UserProperties {
  isShowProductNames: boolean;
  includeTerminatedDownlines: boolean;
}
export interface AgentSearchUserProperties {
  buName: string | undefined;
  userId: number | undefined;
  searchType?: string | undefined;
  searchHeaderType?: string | undefined;
}