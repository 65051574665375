import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import AccountingAdjustmentStore from "../../../../store/AccountingAdjustmentStore";
import CommissionStore from "../../../../store/CommissionStore";
import { FileType,RenewalRecordCountListModel, RenewalReviewLogSpModel, ReportFile } from "../../../../services/AccountingAdjustmentService";
import { PolicyInfoListModel } from "../../../../services/CommissionService";
import InsuredStore from "../../../../store/InsuredStore"; 
import Collection from "../../../../infrastructure/CollectionHelper";
import { routes } from "../../../../router";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from 'fluent-ts-validator';
import Utils from "../../../../infrastructure/Utils"; 
import moment from 'moment';

 export class PolicyAdjustmentsViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => { 
        await this.ResetValidateDuration();
        this.policySearchResult = '';
        this.policyEffectiveDate = '';
        this.isValidPolicy = false;
        this.showPolicyDetails = false;
        this.searchValue = '';
        this.searchedPolicyID = '';
        
        this.amountValue = '';
        this.selectedTtransactiontype = undefined;
        this.selectedAgent = undefined;
        this.selectedReason = undefined;
        this.noteValue = '';
        this.transactionDate = new Date();
        this.PoliciesList = [];
        this.searchedPolicyID = '';
        this.SelecetedPolicy = '';
        this.selectedPolicyId = undefined;
        this.policyDialog = false;
    };

    Route = async (currentRoute: RouteList): Promise<void> => { }
    static Symbol: symbol = Symbol("PolicyAdjustmentsViewModel");

    @observable first: number = 0;
    @observable name: string = ''
    @observable totalRecords: number = 0;
    @observable isLoading: boolean = true;
    @observable isAmountError: boolean = false;
    @observable showPolicyDetails: boolean = false;
    @observable isValidPolicy: boolean = false;
    @observable isRowColor: boolean = false;
    @observable policyDialog: boolean = false;
    @observable isMultilePolicy: boolean = true;
    @observable id: string = ''
    @observable startIndex: number = 0;
    @observable startSearchIndex: number = 0;
    @observable serachInputValue: string = ''; 
    @observable searchValue: string = "";
    @observable searchedPolicyID: string = "";
    @observable noteValue: string = "";
    @observable policyNumber: number = 0;
    @observable amountValue: string = "";
    @observable policySearchResult : any = '';
    @observable policyEffectiveDate : any = '';
    @observable adjustmentHistoryResult : any = '';
    @observable response: any;
    @observable transactionDate: Date = new Date();
    @observable selectedAgent: number | undefined;
    @observable selectedTtransactiontype: number | undefined;  
    @observable selectedReason: number | undefined; 
    @observable rows: number = 50;
    @observable  tableData : any;
    @observable  reversDataArray : any;
    @observable selectedDocumentsFiltered : any;
    @observable impersonatedUserId: number | undefined = 0; 
    @observable transactiontypeValues: { label: string; value: string; }[] = []; 
    @observable reasonValues: { label: string; value: string; }[] = []; 
    @observable agentValues: { label: string; value: string; }[] = []; 
    @observable selectedDocuments: any = [];
    @observable importVisible: boolean = false
    @observable isMultiple: boolean = false
    @observable isSelected: boolean = false
    @observable PoliciesList : any = '';
    @observable SelecetedPolicy : any = '';
    @observable selectedPolicyId: any;
    
    @observable DurationErrorModel = new ErrorModel(new CommissionPlanDurationValidator());
    @observable ErrorModel: ErrorModel<any>;
    constructor(itemType: string, listRoute: any, validator: AbstractValidator<any>)
    {
        this.ErrorModel = new ErrorModel(validator);
    }
  
    @action ValidateDuration = async () => {
        return await this.DurationErrorModel.Validate(this);
      }
    
      @action ResetValidateDuration = async () => {
          return await this.DurationErrorModel.ResetValidation(this);
      }
    @action
    public ResetValidation = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
    @action checkIsModelValid = async () => {
      var hasErrors = await this.ErrorModel.Validate(this)
      return !hasErrors;
    }

    @action resetAllFieldsStates =() =>{
        this.policySearchResult = '';
        this.policyEffectiveDate = '';
        this.showPolicyDetails = false;
        !this.isValidPolicy && (this.searchValue = '');
        this.searchedPolicyID = '';
        this.amountValue = '';
        this.selectedTtransactiontype = undefined;
        this.selectedAgent = undefined;
        this.selectedReason = undefined;
        this.noteValue = '';
        this.transactionDate = new Date();

    }
    @action goToAdmin = () => {
        routes.administration.push();
        this.resetAllFieldsStates()
    }
    
    @action setImportVisible = (value: boolean) => {
        this.importVisible = value;
        if(value == true){
            let assign =[];
            if(this.selectedDocuments.length > 0){
                assign = this.selectedDocuments.filter(function( element:any ) {
                    return element !== undefined;
                });
                this.selectedDocumentsFiltered = assign
                if(this.selectedDocumentsFiltered.length == 1){
                    this.isMultiple = false
                }else{
                    this.isMultiple = true
                }
                let arr = assign.map((a: { id: any; }) => a.id)
                this.reversDataArray = arr;
            }
        }
    }
    @action cancelReverse = () =>{
      this.setImportVisible(false)
    }
    
    @action processReversal = async () =>{
        this.impersonatedUserId = Utils.getUserId();
        let assign =[];
        let reversedId =[];

        if(this.selectedDocuments.length > 0){
            assign = this.selectedDocuments.filter(function( element:any ) {
                return element !== undefined;
            });
            
            let arr = assign.map((a: { id: any; }) => a.id)
            let result = await AccountingAdjustmentStore.reverseAccountingAdjustment( JSON.stringify(arr), this.impersonatedUserId);
        }
        this.getAdjustmentHistory()
        this.setImportVisible(false)
    }

    @action onPage(firstIndex: number, rows: number) {
        this.first = firstIndex;
        this.startIndex = firstIndex;
        this.rows = rows;
        this.startIndex = firstIndex / this.rows;
        this.getAdjustmentHistory()
    }
    @action setTransactionDate(value: Date) {
        this.transactionDate = value;
    }
    @action setDocumentSelection(selectedObj: any) {
        if(selectedObj.length >0 ){
            this.isSelected = true
        }else{
            this.isSelected = false
        }
        this.selectedDocuments = selectedObj;
    }
     
    
    @action addNewAdjustments= async (data: any)  =>  {
        if (!await this.ValidateDuration()) {
            this.impersonatedUserId = Utils.getUserId();
            let result = await AccountingAdjustmentStore.createAccountingAdjustment(data.agent,this.isMultilePolicy ? this.selectedPolicyId : +this.searchValue, data.amount, data.transactionDate, data.transactionType, data.reason, this.noteValue, this.impersonatedUserId);
            this.getAdjustmentHistory();
        }

    }

    @action setpolicyDialog = (value: boolean) => {
        this.policyDialog = value
    };
    @action getPolicyInformation = async() => {
        let result = await CommissionStore.getPoliciesByPolicyNumber(this.searchValue);
         this.isLoading = false
        if(result.recordCount == 1){
            this.selectedPolicyId = result && result.data && result.data[0].policyID;
            let resultOfPolicyId = await InsuredStore.getPolicySummaryById(+this.selectedPolicyId);
            this.getAgents();
            this.getAdjustmentHistory();
            this.isLoading = false;
            this.policySearchResult = resultOfPolicyId &&  resultOfPolicyId;
            
            let date = resultOfPolicyId &&  resultOfPolicyId.policy &&  resultOfPolicyId.policy.effectiveDate || new Date();
            const d = new Date();
            let hour = d.getHours();
            let UTCEndDate = new Date(date).setHours(hour)
            let UTCEndisoDateString = new Date(UTCEndDate).toISOString();
            var UTCEndDateFormatted = new Date(UTCEndisoDateString); 
            let policyEffectiveDate1 =  moment.utc(UTCEndDateFormatted).format('l LT');
            this.policyEffectiveDate =  moment(policyEffectiveDate1).format('MMM DD, YYYY');
            
            
            this.showPolicyDetails = true;
            this.policyDialog = false; 
            this.isMultilePolicy = true;
            this.isValidPolicy = false;
        }else if(result.recordCount == 0){
            this.isValidPolicy = true;
            this.showPolicyDetails = false;
            await this.ResetValidateDuration();
            this.resetAllFieldsStates()
        }else{
            this.isValidPolicy = false;
            this.showPolicyDetails = false;
            this.policyDialog = true;
            this.isMultilePolicy = true;
            this.policySearchResult = result && result.data && result.data[0];
            this.PoliciesList = result.data;
        }
        
         this.policyNumber = parseInt(result && result.data && result.data[0] && result.data[0].policyNumber || '0');
         return result;
     }
     @action updateSelectedPolicy(value: PolicyInfoListModel | undefined) {
       this.SelecetedPolicy = value;
       if (value) {
         this.selectedPolicyId = value.policyID;
       } else {
         this.selectedPolicyId = undefined;
       }
     }
     @action removePolicies() {
       this.PoliciesList = [];
       this.searchedPolicyID = '';
       this.SelecetedPolicy = '';
       this.selectedPolicyId = undefined;
       this.policyDialog = false;
     }
     
    @action getPolicyInfoForSelectedRecord = async() => {
        let result = await InsuredStore.getPolicySummaryById(+this.selectedPolicyId);
        this.getAgents();
        this.getAdjustmentHistory();
        this.isLoading = false;
        this.showPolicyDetails = true;
        this.policySearchResult = result;
            
        let date = result &&  result.policy &&  result.policy.effectiveDate || new Date();
        const d = new Date();
        let hour = d.getHours();
        let UTCEndDate = new Date(date).setHours(hour)
        let UTCEndisoDateString = new Date(UTCEndDate).toISOString();
        var UTCEndDateFormatted = new Date(UTCEndisoDateString); 
        let policyEffectiveDate1 =  moment.utc(UTCEndDateFormatted).format('l LT');
        this.policyEffectiveDate =  moment(policyEffectiveDate1).format('MMM DD, YYYY');
        this.policyDialog = false;
        return result;
     }
     
    @action getAdjustmentHistory = async() => { 
        this.amountValue = '';
        this.selectedTtransactiontype = undefined;
        this.selectedAgent = undefined;
        this.selectedReason = undefined;
        this.noteValue = '';
        this.selectedDocuments = '';
        this.transactionDate = new Date();

        let result = await AccountingAdjustmentStore.searchAccountingAdjustmentsOfPolicy(this.isMultilePolicy ? this.selectedPolicyId : +this.searchValue, this.startIndex, this.rows);
         this.isLoading = false; 
         let assign: { transactionDate: string; id?: number | undefined; agentId?: number | undefined; agentName?: string | undefined; policyId?: number | undefined; transactionAmount?: number | undefined; accountingAdjustmentReason?: string | undefined; accountingAdjustmentType?: string | undefined; transactionNote?: string | undefined; reversed?: boolean | undefined; createByName?: string | undefined; lastModifyByName?: string | undefined; }[] =[];
         (result && result.data && result.data || []).forEach((item) => {
            assign.push({ ...item, transactionDate: moment(item.transactionDate).format("MM/DD/yyyy") })
        });  
        this.totalRecords = result.recordCount || 0;
        this.adjustmentHistoryResult = assign
     }
     
    @action mapListItemAndSort(listObjects: any) {
        if (listObjects) { 
          var sortList = listObjects.sort(Utils.compareListItem);
          sortList.forEach((element: any) => {
            element.label = element.text;
          });          
          return sortList;
        }
        else {
          return [];
        }
      }
    @action getAccountingAdjustmentTypeLookup = async() => {
        this.impersonatedUserId = Utils.getUserId();
        let result = await AccountingAdjustmentStore.getAccountingAdjustmentTypeLookup(this.impersonatedUserId);
        this.isLoading = false;
        var assign: { label: string; value: string; }[] = []; 

        if (result != null) {
            result.forEach((item) => {
                var data = {
                    label: item.text ? item.text : "",
                    value: item.value ? item.value : "",
                };
                assign.push(data);
            });
        }
        
        this.transactiontypeValues = assign;
        return result;
    }
    
    @action getReasonTypeLookup = async(type: number) => {
        this.impersonatedUserId = Utils.getUserId();
        let result = await AccountingAdjustmentStore.getAccountingAdjustmentReasons(this.impersonatedUserId, type);
        this.isLoading = false;
        var assign: { label: string; value: string; }[] = []; 
        var data = {label:'',value:''};

        if (result != null) {
            let filterdValuesAdvance = (result.data || []).filter(function( element:any ) {
                return element.accountingAdjustmentTypeId == 1;
            });
            let filterdValuesChargeback = (result.data || []).filter(function( element:any ) {
                return element.accountingAdjustmentTypeId == 2;
            });
            
            if(type == 1 ){
                (filterdValuesAdvance || []).forEach((item: any) => {
                    data = {label: item.name ? item.name : "",value: item.id ? `${item.id}` : ""};
                    assign.push(data);
                });
            }else{
                (filterdValuesChargeback || []).forEach((item: any) => {
                    data = {label: item.name ? item.name : "",value: item.id ? `${item.id}` : ""};
                    assign.push(data);
                });
            }
        }
        this.reasonValues = assign;
        return result;
    }
     
    @action getAgents = async() => {
        let result = await AccountingAdjustmentStore.getAgentsByPolicyIDLookup(+this.selectedPolicyId);        
        this.isLoading = false;
        var assign: { label: string; value: string; }[] = []; 

        if (result != null) {
            result.forEach((item) => {
                var data = {
                    label: item.text ? item.text : "",
                    value: item.value ? item.value : "",
                };
                assign.push(data);
            });
        }
        this.agentValues = assign;
        return result;
    } 
}
class LeadListValidator extends AbstractValidator<PolicyAdjustmentsViewModel>{
    public constructor() {
        super();
    }
}
class CommissionPlanDurationValidator extends AbstractValidator<PolicyAdjustmentsViewModel> {
  public constructor() {
    super();
    this.validateIfString(input => input.amountValue)
      .isNotEmpty()
      .withFailureMessage("Amount is required");
    this.validateIfNumber(input => input.selectedTtransactiontype)
        .isGreaterThan(0)
        .withFailureMessage("Transaction Type is required");
    this.validateIfNumber(input => input.selectedAgent)
        .isGreaterThan(0)
        .withFailureMessage("Agent is required");
    this.validateIfNumber(input => input.selectedReason)
        .isGreaterThan(0)
        .withFailureMessage("Reason is required");
  }
} 