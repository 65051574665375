import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import utils from "../../infrastructure/Utils";
import InsuredStore from "../../store/InsuredStore";
import Utils from "../../infrastructure/Utils";

export class TripPointsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (id: number) => {
    this.agentId = id;
    this.types = [];
    this.datesArr = [];
    this.selectedConfirmDate = "";
    this.selectedDate = "";
    this.selectedFooterOption = "";
    this.setThisYear();
    this.dropdownValues();
    this.rows = 10;
    this.loadTripPoints();
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable rows: number = 10;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable startDate: Date = new Date("2020-01-01");
  @observable endDate: Date = new Date("2020-12-31");

  @observable startIndex: number = 0;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 10;
  @observable excludedFromTrip: boolean | undefined = false;
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @observable tripPointsList = new Collection<{
    agentId?: number;
    tripCategoryId?: number;
    categoryName?: string;
    appCount?: number;
    annualPremium?: number;
    targetPremium?: number;
    points?: number;
    singleLife?: number;
  }>();

  @observable types: any = [];
  @observable datesArr: any = [];

  @observable selectedType: string = "0";
  @observable selectedCategory: string = "-1";
  @observable lengthOfYears: number = 0;
  @observable companies = [{ label: "All", value: "0" }];
  @observable pointsCategoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedDate: any;
  @observable selectedConfirmDate: any;
  @observable selectedFooterOption: any;
  @observable selectedConfirmedFooterOption: any;

  @action
  dropdownValues = async () => {
    var result = await InsuredStore.getTripCategory();
    Utils.mapListItemsToDropdown(result, this.pointsCategoryList, "All", "-1");
  };

  @observable selectedCompany = "0";
  @computed
  get totalPoints(): number {
    let total = 0;

    for (let obj of this.tripPointsList.values) {
      total = obj && obj.points ? total + obj.points : total;
    }
    return Math.round(total);
  }

  @observable sortTrips: boolean = false;
  @observable sortColumnTrips: string = "tripCategoryId";
  @action onColumnSort = (column: any) => {
    var obj = JSON.parse(JSON.stringify(column));
    this.setSortOrder();
    this.setSortColumn(obj.sortField);
    this.loadTripPoints();
  };
  @action setSortOrder() {
    this.sortTrips = !this.sortTrips;
  }
  @action setSortColumn(column: string) {
    this.sortColumnTrips = column;
  }
  @action setPointsCategory(value: string) {
    this.selectedCategory = value;
  }

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.tripPointsList.values = [];
  }
  @action resetFilters() {
    this.selectedType = "0";
    this.selectedCategory = "-1";
    this.lengthOfYears = 0;
    this.rows = 25;
    this.totalRecords = 0;
    this.sortTrips = false;
    this.startIndex = 0;
    this.first = 0;
    this.sortColumnTrips = "tripCategoryId";
    this.selectedDate = "";
    this.selectedConfirmDate = "";
    this.setThisYear();
    this.loadTripPoints();
  }
  @action setThisYear = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), 0, 1);
    var lastDay = new Date(date.getFullYear() + 1, 0, 0);
    this.setSelectedDate([firstDay, lastDay]);
    this.setSelectedConfirmDate([firstDay, lastDay]);
    this.selectedFooterOption = "This Year";
    this.selectedConfirmedFooterOption = "This Year";
  };

  @action loadTripPoints = async () => {
    this.isLoading = true;
    this.excludedFromTrip = false;
    this.isMessgeVisible = false;
    this.response = "";
    this.startDate =
      this.selectedDate && this.selectedDate.length > 0
        ? this.selectedDate[0]
        : new Date();
    this.endDate =
      this.selectedDate && this.selectedDate.length > 1 && this.selectedDate[1]
        ? this.selectedDate[1]
        : this.startDate;
    try {
      var tripPoints = await InsuredStore.getTripPoints(
        this.agentId,
        this.startDate,
        this.endDate,
        parseInt(this.selectedCategory),
        this.pageIndex,
        this.pageSize,
        this.sortColumnTrips,
        this.sortTrips
      );
      if (tripPoints) {
        this.excludedFromTrip = tripPoints.excludeFromTrip;
        if (tripPoints.data) {
          this.tripPointsList.values = tripPoints.data;
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.response = error.response;
      this.isMessgeVisible = true;
    }

    this.isLoading = false;
  };

  @action getFormatValue = (rowData: any, header: string) => {
    switch (header) {
      case "Annual Premium":
        return utils.getCurrencyFormat(rowData.annualPremium);
      case "Target Premium":
        return utils.getCurrencyFormat(rowData.targetPremium);
      case "Single Life Premium":
        return utils.getCurrencyFormat(rowData.singleLife);
      case "Annuity Premium":
        return utils.getCurrencyFormat(rowData.annuities);
      case "Points":
        return "" + rowData.points.toFixed(2);
      default:
        return "";
    }
  };
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadTripPoints();
  }
  @action setSelectedCompany = (value: string) => {
    this.selectedCompany = value;
  };
  @action setSelectedType = (value: string) => {
    this.selectedType = value;
  };
  @action setSelectedDate = (value: any) => {
    this.selectedDate = value;
    this.viewDate = value && value.length > 0 ? new Date(value[0]) : new Date();
  };
  @action setSelectedConfirmDate = (value: any) => {
    this.selectedConfirmDate = value;
    if (value && value.length > 1) {
      if (!value[1]) {
        this.setSelectedDate([value[0], value[0]]);
      }
    }
  };
  @action setSelectedFooterOption = (value: any) => {
    this.selectedFooterOption = value;
  };
  @action setSelectedConfirmedFooterOption = (value: any) => {
    this.selectedConfirmedFooterOption = value;
  };
  @observable viewDate: Date = new Date();
  @action setViewDate = (value: any) => {
    this.viewDate = value;
  };
}
