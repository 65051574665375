import { observable, action, computed, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import Collection from "../../infrastructure/CollectionHelper";
import axios from "axios";
import ContractsStore from "../../store/ContractsStore";
import { AutoRtsByAgentResponse } from "../../services/ProducerSearchService";
import "../../modules/administration/carrierManagement/carrier/CMAddEdit.css";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { CertificationsDropDownValuesResponse } from "../../services/ContractService";

export class AgentAutoAppointmentsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable year: string = "";
  @observable cmSearchInputValue: string = "";
  @observable rows: number = 50;
  @observable totalRecords: number = 0;
  @observable selectedCarrier: any;
  @observable isCarrierToast: boolean = false;
  @observable toasterIcon: string | null = "";
  @observable toasterMessage: string | null = "";
  @observable timer: any;
  @observable downline: any;
  @observable isRefreshEnable: boolean = false;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 50;
  @observable firstItem: number = 0;
  @observable sortColumn: string = "npn";
  @observable isSortAscending: boolean = false;
  @observable totalRowsCount: number = 0;
  @observable autoRTSList = new Collection<any>();
  @observable isLoading: boolean = false;
  @observable isException: boolean = false;
  @observable isDeleteConfirmationVisible: boolean = false;
  @observable selectedId: number = 0;
  @observable currentPage: number = 0;
  @observable defaultIsSortAscending: boolean = true;
  @observable defaultSortColumn: string = "npn";
  @observable defaultSelectedCompany: string = "0";
  @observable defaultSelectedYear: string = "";
  @observable defaultSelectedCurrentYear: string = "";
  @observable agentid: number = 0;
  @observable yearList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @action Load = async () => {};

  @action load = async (agentId: number) => {
    this.defaultSelectedYear = new Date().getFullYear().toString();
    this.firstItem = 0;
    this.currentPage = 0;
    this.pageSize = 50;
    this.pageIndex = 0;
    this.cmSearchInputValue = "";
    this.isRefreshEnable = false;
    this.sortColumn = "npn";
    this.isSortAscending = true;
    this.defaultIsSortAscending = true;
    this.autoRTSList.values = [];
    this.selectedCarrier = "";
    if (agentId > 0) {
      this.agentid = agentId;
      this.loadCarriers();
      this.certificationsDropDownValues();
      await this.loadAutoRTS();
    }
  };

  @action getAutoRTSData = async (): Promise<any> => {};

  @action async goSearch() {
    await this.loadAutoRTS();
  }

  @action async loadAutoRTS() {
    let result = await ProducerSearchStore.getAutoRtsByNpn(
      this.defaultSelectedYear == "All"
        ? 0
        : parseInt(this.defaultSelectedYear),
      this.selectedCarrier,
      this.agentid,
      this.pageIndex,
      this.pageSize,
      this.sortColumn,
      this.isSortAscending,
      ""
    );
    if (result && result.data) {
      this.autoRTSList.values = result.data;
      this.totalRecords = result.recordCount;
    } else {
      this.autoRTSList.values = [];
      this.totalRecords = 0;
    }
  }

  @action resetFilters = () => {
    this.resetPagingValues();
  };

  @action resetPagingValues() {
    this.totalRecords = 0;
    this.pageSize = 50;
    this.firstItem = 0;
  }

  @action setYear(value: string) {
    this.isRefreshEnable = true;
    this.defaultSelectedYear = value;
    this.loadAutoRTS();
  }

  @action setCarrier(value: string) {
    this.selectedCarrier = value;
    this.isRefreshEnable = true;
    this.loadAutoRTS();
  }

  protected loadCarriers = async () => {
    let result = await ContractsStore.getAllMasterCarrierLookup();
    if (result) {
      this.companyList.values = [];
      result.forEach((obj: any) => {
        var data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value.toString() : "",
        };
        this.companyList.values.push(data);
      });
    } else {
      this.companyList.values = [];
    }
  };

  protected certificationsDropDownValues = async () => {
    this.yearList.values = [];
    let currentYear = new Date().getFullYear();
    this.defaultSelectedYear = currentYear.toString();
    this.defaultSelectedCurrentYear = currentYear.toString();
    let nextYear = currentYear + 1;
    var data = {
      label: "All",
      value: "0",
    };
    this.yearList.values.push(data);
    var data = {
      label: nextYear.toString(),
      value: nextYear.toString(),
    };
    this.yearList.values.push(data);
    for (let index = 0; index < 4; index++) {
      const element = currentYear--;
      var data = {
        label: element.toString(),
        value: element.toString(),
      };
      this.yearList.values.push(data);
    }
  };

  @action downloadAutoRTS = async () => {
    var result = await ProducerSearchStore.downloadAutoRtsByNpn(
      this.defaultSelectedYear == "All"
        ? 0
        : parseInt(this.defaultSelectedYear),
      this.selectedCarrier,
      this.agentid,
      this.pageIndex,
      this.pageSize,
      this.sortColumn,
      this.isSortAscending,
      ""
    );
    if (result && result.data)
      Utils.downloadDocument(result.data, "Auto_RTS.xlsx", "Auto_RTS.xlsx");
  };

  resetFiltersToDefault() {
    this.resetFilters();
  }

  @action refresh = () => {
    this.certificationsDropDownValues();
    this.loadCarriers();
    this.isRefreshEnable = false;
    this.cmSearchInputValue = "";
    this.sortColumn = "npn";
    this.isSortAscending = false;
    this.selectedCarrier = "";
    this.resetFiltersToDefault();
    this.defaultSelectedYear = this.defaultSelectedCurrentYear;
    this.loadAutoRTS();
  };

  @action onSort = (sortColumn: any) => {
    this.sortColumn = sortColumn.sortField;
    if (sortColumn.sortField === this.sortColumn)
      this.isSortAscending = !this.isSortAscending;
    else this.isSortAscending = true;

    this.loadAutoRTS();
  };

  @action onPage = (pageInfo: any) => {
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.rows = pageSize;
    this.firstItem = pageIndex * pageSize;
    this.loadAutoRTS();
  }

  @observable currentPageReportTemplate: string =
    "Showing {first} to {last} of {totalRecords} records";
  @action paginatorTemplate = () => {
    return this.autoRTSList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  };
  @action rowsPerPageOptions = () => {
    return this.autoRTSList.values.length > 0 ? [50, 100, 150, 200] : [];
  };

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.sortColumn = this.defaultSortColumn;
    this.isSortAscending = this.defaultIsSortAscending;
  };
  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };
}
