import React, { Fragment, Component } from 'react';
import ReactDOM from 'react-dom';

import './assets/css/AgentCard.css';
import menuIcon from './assets/images/table-cells-large.svg';
import agentIcon from './assets/images/agent.png';
import menuCloseIcon from './assets/images/DownArrow.svg';
import shuffleIcon from './assets/images/changeAgentArrow.svg';
import arrowSrc from './assets/images/ArrowPoint.svg'
class AgentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateMenuComponent: false
        };
        this.updateMenuComponent = this.updateMenuComponent.bind(this);
    }    

    updateMenuComponent = () => {
        this.setState(prev => ({
            updateMenuComponent: !prev.updateMenuComponent
        }));
    };
  
    render(){
        let menuIconImg = (<img src={menuCloseIcon} alt="menuClicked" onClick={this.updateMenuComponent} />);
        if (!this.state.updateMenuComponent){
            menuIconImg = (<div className="menu-table-cell"><img src={menuIcon} alt="menu" onClick={this.updateMenuComponent} className="menu-table-icon" /></div>);
        }
        return (
            <Fragment>   
                <div className={ this.state.updateMenuComponent ? "menuBox" : "menuCollapse"}>
                  <div className="menuAgentBox">
                   
                    <div className='menuImage'>
                        {menuIconImg}
                    </div>
                    <div className='agent'>
                        {/* <img className="image" src={agentIcon} alt="user"/> */}
                       Selected Agent:
                        <p>{this.props.agent.agentName} | {this.props.agent.agentId}</p>
                    </div>
                    <div className='changeAgent' onClick={(e) => this.props.handleSendMessageToBot('Change Agent')}>
                        <a className='changeNameText'>Change Agent</a>
                        <img src={shuffleIcon} alt="changeAgent"/>
                    </div>
                    </div>
                    {this.state.updateMenuComponent && 
                        <div className="agentMenu">
                            <div className="agentMenuOptions">
                                <button onClick={(e) => this.props.handleSendMessageToBot('Contract Info')}><div className='agentMenuText'>
                                    Contract Info&nbsp;&nbsp;<img className="agentMenuBtn" src={arrowSrc}/></div>
                                </button>
                                <button onClick={(e) => this.props.handleSendMessageToBot('Commissions Info')} >
                                    <div className='agentMenuText'>Commissions Info&nbsp;&nbsp;<img className="agentMenuBtn" src={arrowSrc}/></div></button>
                                <button onClick={(e) => this.props.handleSendMessageToBot('Hierarchy')}>
                                    <div className='agentMenuText'>Hierarchy&nbsp;&nbsp;
                                    <img className="agentMenuBtn" src={arrowSrc}/>
                                    </div></button>
                                <button onClick={(e) => this.props.handleSendMessageToBot('Certifications')}>
                                    <div className='agentMenuText'>Certifications&nbsp;&nbsp;
                                    <img className="agentMenuBtn" src={arrowSrc}/>
                                    </div>
                                </button>
                                {/* <button disabled onClick={(e) => this.props.handleSendMessageToBot('Appointments')}>Appointments</button> */}
                                {/* <button disabled onClick={(e) => this.props.handleSendMessageToBot('Application status')}>Application status</button> */}
                            </div>
                            {/* <div className="smallText">Choosing a menu will close the current conversation.</div> */}
                        </div>
                    }
                </div>   
            </Fragment>
        )
    }
}

export default AgentCard;