import { AbstractValidator } from 'fluent-ts-validator';
import { observable, action, computed } from "mobx";
import ViewModel from '../../../../infrastructure/ViewModel';
import RouteList from '../../../../infrastructure/RouteList';
import { ErrorModel } from '../../../../infrastructure/ErrorModel';
import { routes } from "../../../../router";
import Utils from '../../../../infrastructure/Utils';
import AccountingStore from '../../../../store/AccountingStore';
import UserContext from '../../../../infrastructure/UserContext';
import ContractsStore from '../../../../store/ContractsStore';
import Collection from '../../../../infrastructure/CollectionHelper';
export class CarrierBankDepositAddViewModel implements ViewModel {

    constructor(private OnSave?: (BankAccountID: number) => void) { }
   
    @observable companyList = new Collection<{ label: string; value: string; }>();
    @observable bankAccountList = new Collection<{ label: string; value: string; }>();
    @observable CarrierBankDepositID: number = 0;
    @observable selectedCompany: string = "0";
    @observable selectedBankAccount: string = "0";
    @observable isException: boolean = false;
    @observable isSuccess: boolean = false;
    @observable isWarningDisplayed: boolean = false;
    @observable isLoading: boolean = true;
    @computed
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return window.confirm("Are you sure?");
    }
    @computed
    get IsLoaded(): boolean {
        return !this.loading;
    }
    @computed
    get IsLoading(): boolean {
        return this.loading;
    }

    @action loadDropdowns = async () => {       
       var items = await ContractsStore.getAllCompanyLookup();
       Utils.mapListItemsToDropdown(items, this.companyList, "Select Carrier");       
       items = await AccountingStore.getBankAccountLookup();
       items = items.filter(function (e) { return (e.text !== "ARC" && e.text !== "IMDirect"); });
       Utils.mapListItemsToDropdown(items, this.bankAccountList, "Select Paid Entity");
    }

    Close = (): void => {
        routes.listCarrierBankDeposit.push();
    }
    @action setIsException(value: boolean) {
        this.isException = value;
    }


    @action setisWarningDisplayed(value: boolean) {
        this.isWarningDisplayed = value;
    }
    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }
    public Cancel = async () => {
        this.isSuccess = false;
        routes.listCarrierBankDeposit.push();
    };

    public Load = async (): Promise<void> => {
        await this.ResetValidation();
        this.loading = true;
        this.InputModel.DepositDate = new Date();
        this.InputModel = new InputModel();
        console.log('on loading....')
        this.loadDropdowns();
        this.selectedBankAccount = "0";
        this.selectedCompany = "0";
    };

     


    @action
    private loaded = () => {
        this.loading = false;
    };

    Route = async (currentRoute: RouteList): Promise<void> => { }

     
    private paramCarrierID: number | undefined;
    private paramPaidDivisionId: number | undefined;
    @action
    public Submit = async () => {

        this.isException = false;
        this.isSuccess = false;
        try {

            

            if (this.selectedCompany !== "0")
                this.paramCarrierID = parseInt(this.selectedCompany);
            else
                this.paramCarrierID = 0;

            if (this.selectedBankAccount !== "0")
                this.paramPaidDivisionId = parseInt(this.selectedBankAccount);
            else
                this.paramPaidDivisionId = 0;

            this.InputModel.BankAccountID = this.paramCarrierID;
            this.InputModel.CarrierID = this.paramPaidDivisionId;

            await this.ResetValidation();
            if (!await this.checkIsModelValid()) {
                return false;
            }
                        
            this.isLoading = true;

            if (UserContext.userId && UserContext.userId > 0) {
                await AccountingStore.addBankDeposit({

                    companyId: this.paramCarrierID,
                    amount: parseFloat(this.InputModel.DepositAmount),
                    comment: this.InputModel.Comment,
                    paidDivisionId: this.paramPaidDivisionId,
                    statementDate: this.InputModel.DepositDate,
                    createdByUserId: UserContext.getUserId()
                });
            }

            this.setIsSuccess(true);
            this.isLoading = false;
            this.Close();
        } catch (error) {
            this.isLoading = false;
            this.isException = true;
        }
    }

    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this.InputModel);
    }

    @action
    public ResetValidation = async () => {
        return await this.ErrorModel.ResetValidation(this.InputModel);
    }
    @action checkIsModelValid = async () => {
      var hasErrors = await this.ErrorModel.Validate(this.InputModel)
      return !hasErrors;
    }

    @observable
    public InputModel = new InputModel();
    @observable
    public ErrorModel = new ErrorModel(new Validator());
     public onSave?: (CarrierBankDepositID: number) => void;
    private onClose?: () => void;
    @observable
    private loading = true;
}

export class InputModel {
    //#region Properities
    @observable
    public BankAccountID: number | undefined;
    @observable
    public DepositDate: Date | undefined;
    @observable
    public CarrierID: number | undefined;
    @observable
    public DepositAmount: string = "";
    @observable
    public Comment: string = "";
    @observable
    public PaidDivisionID: number | undefined;
    @observable
    public CompanyID: number | undefined;
    //#endregion

    //#region State Management
    @action
    public SetBankAccountID = (value: number) => {
        this.BankAccountID = value;
    };

    @action
    public SetDepositDate = (value: Date | undefined) => {
        this.DepositDate = value;
    };

    @action
    public SetCarrierID = (value: number) => {
        this.CarrierID = value;
    };

    @action
    public SetDepositAmount = (value: string) => {
        this.DepositAmount = value;
    };

    @action
    public SetComment = (value: string) => {
        this.Comment = value;
    };
    //#endregion
}


class Validator extends AbstractValidator<InputModel>{
    public constructor() {
        super();

        this.validateIfNumber(input => input.BankAccountID)
            .isGreaterThan(0)
            .withFailureMessage("Bank Account is required");

        this.validateIfDate(input => input.DepositDate)
            .isNotEmpty()
            .withFailureMessage("Deposit Date is required");

        this.validateIfNumber(input => input.CarrierID)
            .isGreaterThan(0)
            .withFailureMessage("Carrier is required");

        this.validateIfString(input => input.DepositAmount)
            .isNotEmpty()
            .withFailureMessage("Amount is required");

        this.validateIfString(input => input.DepositAmount)
            .isCurrency()
            .whenNotEmpty()
            .withFailureMessage("Amount must be a currency");

        this.validateIfString(input => input.Comment)
            .hasMaxLength(200)
            .whenNotEmpty()
            .withFailureMessage("Comment is more than 200 characters");
    }
}