import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import InsuredStore from "../../store/InsuredStore";
import IMASLog from "../../infrastructure/IMASLog";
import { PoliciesByCustomerListModel, GetAllPoliciesByCustomerResponse, } from "../../services/InsuredService";
import Utils from "../../infrastructure/Utils";
import UserContext from "../../infrastructure/UserContext";

export class CustomerPoliciesViewModel implements ViewModel {

    @action Load = async () => {
        this.isLoading = false;
        this.customerName = RouteParamDetails.customerName;
    }

    Route = async (currentRoute: RouteList): Promise<void> => {
    }

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @action load = async (id: number, name: string | undefined) => {
        this.customerId = id
        this.customerName = "" + name;
        this.isdeletePolicyConfirm = false;
        await this.loadPolicies();
    }

    @action addPolicy = () => {
        routes.addPolicy.push({ customerId: "" + this.customerId });
        RouteParamDetails.customerName = this.customerName;
    }

    @action deletePolicy = async (PolicyId: number) => {
        try {
            //this.isLoading = true;
            this.isMessgeVisible = false;
            this.response = {};
            this.isdeletePolicyConfirm = false;
            await InsuredStore.deletePolicy(PolicyId)
            this.isMessgeVisible = true;
            this.isLoading = false;
            setTimeout(async () => {
               await this.loadPolicies();
                this.response = "";
                this.isMessgeVisible = false;
            }, Utils.timeDelay_Delete());
        }
        catch (e) {
            this.isLoading = false;
            this.isMessgeVisible = true;
            this.response = e.response;
            IMASLog.log("exception from store: " + e.value);
        }
    }

    @action loadPolicies = async () => {
        try {
            this.isMessgeVisible = false;
            this.response = {};
            this.PoliciesList.values = [];

            this.policiesListModel = await InsuredStore.getCustomerPolicies(this.customerId, this.startIndex, this.rows, this.sortColumnOrder, this.sortOrder);
            if (this.policiesListModel && this.policiesListModel.data) {
                this.PoliciesList.values = this.policiesListModel.data;
            }
            else {
                this.PoliciesList.values = [];
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            this.isMessgeVisible = true;
            this.response = e.response;
            IMASLog.log("exception from store: " + e.value);
        }
    }

    @action setPolicyDelateConfirmation(value: boolean) {
        this.isdeletePolicyConfirm = value;
    }

    @observable isMessgeVisible: boolean = false;
    @observable response: any;
    @observable customerId: number = 0;
    @observable customerName: string = '';
    @observable startIndex: number = 0;
    @observable policyId: number = 0;
    @observable PoliciesList = new Collection<PoliciesByCustomerListModel>();
    @observable policiesListModel: GetAllPoliciesByCustomerResponse | null = null;
    @observable isdeletePolicyConfirm: boolean = false;

    @computed
    get totalRecords(): number {
        if (this.policiesListModel && this.policiesListModel.recordCount) {
            return this.policiesListModel.recordCount;
        }
        else {
            return 0;
        }
    }

    @observable selectedDataType: string = '';

    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadPolicies();
    }

    @action async setSortColumn(sortField: any) {
        this.sortColumnOrder = sortField;
        await this.loadPolicies();
    }

    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }

    @action setSelectedPolicy = (value: number) => {
        this.policyId = value
    }

    @observable rows: number = 25
    @observable first: number = 0;
    @observable isLoading: boolean = false;
    @observable sortOrder: boolean = false;
    @observable sortColumnOrder: string = "policyId";

    getSymbol(): symbol {
        return CustomerPoliciesViewModel.Symbol;
    }

    static Symbol: symbol = Symbol("CustomerPoliciesViewModel");


}

export interface CustomerPolicyListModel {
    policyId?: number;
    policyNumbers?: string ;
    companyName?: string ;
    policyEffectiveDate?: string ;
    productName?: string ;
    agentName?: string ;
    policyStatus?: string ;
}
