import { action, observable, toJS } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import {
  CustomReportFilterType,
  CustomReportModalType,
} from "../../../infrastructure/enum/Common";
import PageContext from "../../../infrastructure/PageContext";
import RouteList from "../../../infrastructure/RouteList";
import UserContext from "../../../infrastructure/UserContext";
import Utils from "../../../infrastructure/Utils";
import ViewModel from "../../../infrastructure/ViewModel";
import { routes } from "../../../router";
import {
  ReportCloneRequest,
  ReportModel,
  ReportPreviewRequest,
} from "../../../services/ReportService";
import ReportStore from "../../../store/ReportStore";
import {
  createCustomReportListener,
  CustomReportDetailsAddEditViewModel,
} from "./CustomReportDetailsAddEditViewModel";
import ContractsStore from "../../../store/ContractsStore";
import { Permission } from "../../../infrastructure/enum/Permission";
import { UserLookupListModel } from "../../../services/IdentityService";
import { googleTagManager } from "../../../infrastructure/tracking";
import IMASLog from "../../../infrastructure/IMASLog";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../views/components/controls/IMASTemplates";
export class CustomReportAddEditViewModel
  implements ViewModel, createCustomReportListener {
  createCustomReport(
    reportName: string,
    reportPrivacy: string,
    reportDescription: string
  ): void {
    if (this.customReportTitle === CustomReportModalType.Create) {
      const dto: ReportModel = {
        name: reportName,
        description: reportDescription,
        reportStatusId: +reportPrivacy,
      };
      this.save(dto);
    } else {
      const dto: ReportCloneRequest = {
        reportName: reportName,
        description: reportDescription,
        reportStatusId: +reportPrivacy,
      };
      this.cloneCustomReport(dto);
    }
  }
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => undefined;

  @action Load = async (id: number, mode: boolean) => {
    window.scrollTo(0,0);
    this.canChangeToPrivateReport = true;
    PageContext.setIsMessageVisible(false);
    await this.reset();
    await this.CustomReportDetailsAddEditViewModel.loadReportStatusDropdowns();
    this.selectedReportType = "";
    await this.loadReportTypes();
    this.validatePermissions();
    await this.CustomReportDetailsAddEditViewModel.setListener(this);
    this.selectedId = id;
    if (this.selectedId !== 0 && mode === false) {
      this.isEdit = true;
      await this.getReportById();
      this.ReportScreenHeader = "Report Designer";
    } else if (this.selectedId !== 0 && mode === true) {
      this.isPreview = true;
      this.ReportScreenHeader = "Report Viewer";
      await this.getReportById();
      this.showGenerateToast = false;
      if (localStorage.getItem("ssrCreateToast")) {
        setTimeout(() => {
          this.isShowCreateToast = true;
        }, 300);
        this.title = this.customReportName;
      }
    } else {
      this.ReportScreenHeader = "Report Designer";
      this.title = "New";
    }
    await this.loadReportStatusDropdowns();
    if (localStorage.getItem("showCloneToast") !== null) {
      this.titleClone = localStorage.getItem("showCloneToast") || "";
      this.showCloneToast = true;
      localStorage.removeItem("showCloneToast");
    }
    };
  @observable isInsuredIDFeild: boolean = false;
  @observable isPredefinedFilter: boolean = false;
  @observable isPredefinedFilterWithTextBox: boolean = false;
  @observable createdBy: string = "";
  @observable createdByUserId: number = 0;
  @observable access: boolean = false;
  @observable customReportDescription: string = "";
  @observable customReportName: string = "";
  @observable title: string = "";
  @observable titleClone: string = "";
  @observable ReportScreenHeader: string = "";
  @observable isEdit: boolean = false;
  @observable isShowCreateToast: boolean = false;
  @observable showGenerateToast: boolean = false;
  @observable showGenerateToastFileName: any = "New report";
  @observable showCloneToast: boolean = false;
  @observable isPreview: boolean = false;
  @observable selectedId: number = 0;
  @observable customReportFiltersList: any = [];
  @observable columnReorderPositions: any = [];
  @observable reportPreview: boolean = true;
  @observable isClearAll: boolean = false;
  @observable reportTreeData: any;
  @observable parentDataTemp: any;
  @observable dataTableHeaders: any;
  @observable dataTableData: any;
  @observable dataTableHeaderList: any;
  @observable parentNodeFilterCount: any;
  @observable parentNodeFilterCountPrev: any;
  @observable isDisabledCol: boolean = false;
  @observable mainGroups: any = {};
  @observable expandedNodes: any;
  @observable isSetColumn: boolean = true;
  @observable isAgentChangeAttribute: boolean = false;
  @observable isContractChangeAttribute: boolean = false;
  @observable isInsuredChangeAttribute: boolean = false;
  @observable isCarrierChangeAttribute: boolean = false;
  @observable isWorkflowChangeAttribute: boolean = false;
  @observable isTasksReport: boolean = false;
  @observable isAppsAndCerts: boolean = false;
  @observable isWorkflow: boolean = false;
  @observable isPolicyReport: boolean = false;
  @observable isNotesReport: boolean = false;
  @observable customReportId: number = 0;
  @observable customReportTitle: string = "";
  @observable
  CustomReportDetailsAddEditViewModel: CustomReportDetailsAddEditViewModel =
    new CustomReportDetailsAddEditViewModel();
  @observable showFiltersDialog: boolean = false;
  @observable filterTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedFilterType: string = "";
  @observable selectedFilterCondition: string = "";
  @observable filterConditionList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable filterDropdownList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable stateDropdownList: any = [
    { label: "No Records", value: "UHC", abbreviation: "" },
  ];
  @observable filterValue: any;
  @observable filterValue2: any;
  @observable selectedStateValue: any = "None";
  @observable selectedState: any = "None";
  @observable filterValueToDate: any;
  @observable filterType: number = 0;
  @observable selectedEntityColumnId: any;
  @observable selectedEntityLabel: boolean = false;
  @observable isLesserBetweenValue: boolean = false;
  @observable isAttributeLabel: number | undefined = 0;
  @observable selectedEntityKey: any;
  @observable isClearAllConfirm: boolean = false;
  @observable isMessageVisible: boolean = false;
  @observable response: any;
  @observable isLoading: boolean = true;
  @observable reportId: number = 0;
  @observable isDeleteConfirm: boolean = false;
  @observable generateConfirm: boolean = false;
  @observable isIDField: boolean = false;
  @observable isConditionField: boolean = false;
  @observable isIDFieldLabel: string = "";
  @observable selectedPrivacyStatus: string = "";
  @observable custAttEntityDefinitionId: any;
  @observable privacyStatusList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isDiscardConfirm: boolean = false;
  @observable isDeselect: boolean = false;
  @observable canChangeToPrivateReport: boolean = true;
  @observable parentNodeList: any;
  @observable reportIsPrivate: boolean = false;
  @observable isSSRAutoComplete: boolean = false;
  @observable isSSRState: boolean = false;
  @observable isSSRGender: boolean = false;
  @observable isSSRAutoCompleteCondition: boolean = false;
  @observable isSSREmptyNotEmptyCondition: boolean = false;
  @observable isSSRBetweenNotBetweenCondition: boolean = false;
  @observable autoCompleteValue: string = "";
  @observable FilteredAutoCompleteList = new Collection<any>();
  @observable timer: any;
  @observable selectedSsrId: string = ""; 
  @observable ssrIdList = new Collection<any>();

  @action hasChild = (obj: any) => {
    if (obj && !!Object.keys(obj).length) {
      this.infiniteCheck(obj, false, false, false, false);
    } else {
      return false;
    }
  };

  @action deleteSelectedTreeNode = async (
    id: any,
    data: any,
    entityColumnId: any
  ) => {
    let tempFilterList = this.filterTypeList.values;
    setTimeout(() => {
      const removeIndex = this.customReportFiltersList
        .map((item: any) => item.key)
        .indexOf(id);
      ~removeIndex && this.customReportFiltersList.splice(removeIndex, 1);
      const idFormatted = { [id]: "a" };
      this.infiniteCheck(idFormatted, true, false, false, false);
      tempFilterList = tempFilterList.filter(
        (o: any) => o.value !== "" + entityColumnId
      );
      this.filterTypeList.values = [];
      this.filterTypeList.values = tempFilterList;
      if (this.customReportFiltersList.length < 1) {
        this.dataTableData = [];
        this.dataTableHeaders = [];
      }
    }, 10);
  };

  @action findParentObject = (data: any, id: any) => {
    let mainPath = "";
    let stack = data.map((item: any) => ({
      path: [item.label],
      currObj: item,
    }));
    while (stack.length) {
      const { path, currObj } = stack.pop();
      if (currObj.entityColumnId !== null && currObj.entityColumnId == id) {
        mainPath = path;
      } else if (currObj.children && currObj.children.length) {
        stack = stack.concat(
          currObj.children.map((item: any) => ({
            path: path.concat(item.label),
            currObj: item,
          }))
        );
      }
    }
    this.parentDataTemp = mainPath;
    this.findNodeById(this.parentDataTemp, id);
    return mainPath;
  };

  @action findNodeById = (nodes: any, id: any) => {
    let res;
      let findNode = (nodes: any, id: any) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].entityColumnId == id) {
          res = nodes[i];
        }
        if (nodes[i].children) {
          findNode(nodes[i].children, id);
        }
      }
    };
    nodes.length > 0 && findNode(nodes, id);
    return res;
  };
  @action checkHasProperty = (currObj: any) => {
    var newArr: any[] = [];
    newArr.push(currObj);
    var array1 = ["Agent/Agency ID", "Contract ID", "Insured ID"];
    let finRes = this.reportTreeData.map((item: any) => {
      if (
        this.parentDataTemp.length > 0 &&
        this.parentDataTemp.includes(item.key) &&
        array1.includes(newArr[0].label) &&
        newArr[0].label == "Agent/Agency ID" &&
        newArr[0].label !== "Contract ID" &&
        newArr[0].label !== "Insured ID"
      ) {
        var exist2 = array1.includes(newArr[0].label); 
        return exist2;
      } else if (
        this.parentDataTemp.length > 0 &&
        this.parentDataTemp.includes(item.key) &&
        array1.includes(newArr[0].label) &&
        newArr[0].label !== "Agent/Agency ID" &&
        newArr[0].label == "Contract ID" &&
        newArr[0].label !== "Insured ID"
      ) {
        var exist2 = array1.includes(newArr[0].label);
        return exist2;
      } else if (
        this.parentDataTemp.length > 0 &&
        this.parentDataTemp.includes(item.key) &&
        array1.includes(newArr[0].label) &&
        newArr[0].label !== "Agent/Agency ID" &&
        newArr[0].label !== "Contract ID" &&
        newArr[0].label == "Insured ID"
      ) {
        var exist2 = array1.includes(newArr[0].label);
        return exist2;
      }
    });
    return finRes.filter((item: any) => item);
    };
  @action infiniteCheck = async (
    value: any,
    isDeselect: boolean,
    isFilter: boolean,
    isFilterRemove: boolean,
    isInitialLoad: boolean
  ) => {
    if (this.isSetColumn) {
      const objMain = this.reportTreeData;
      const key = value && Object.keys(value)[0];
      this.findParentObject(this.reportTreeData, key);
      this.parentNodeList = [];      
      (objMain || []).map(async (mapItem: any) => {

        if (
          mapItem &&
          Object.keys(mapItem).length > 1 &&
          mapItem.children.length > 0
        ) {
          this.parentNodeList.push(mapItem.key);
          mapItem.children.map(async (item2: any) => {
            
            if (
              (isInitialLoad &&
                !this.isPolicyReport &&
                !this.isTasksReport &&
                !this.isAppsAndCerts &&
                !this.isWorkflow &&
                item2.label == "Agent/Agency ID" &&
                !this.isAgentChangeAttribute) ||
              (!this.isPolicyReport &&
                !this.isWorkflow &&
                item2.label == "Insured ID" &&
                !this.isInsuredChangeAttribute) ||
              (!this.isPolicyReport &&
                !this.isTasksReport &&
                !this.isNotesReport &&
                !this.isWorkflow &&
                item2.label == "Carrier" &&
                !this.isCarrierChangeAttribute)
            ) {
              if (item2.data.status == 0) {
                mapItem.data.filtersApplied++;
                item2.data.status = 2;
                await this.setFilter(item2);
              }
            }

            if (this.parentDataTemp.includes(mapItem.key)) {
              let cppp = this.checkHasProperty(item2);
              if (
                (cppp.length > 0 &&
                  !this.isPolicyReport &&
                  !this.isTasksReport &&
                  !this.isAppsAndCerts &&
                  !this.isWorkflow &&
                  item2.label == "Agent/Agency ID" &&
                  !this.isAgentChangeAttribute) ||
                (!this.isPolicyReport &&
                  item2.label == "Contract ID" &&
                  !this.isContractChangeAttribute) ||
                (!this.isPolicyReport &&
                  !this.isWorkflow &&
                  item2.label == "Insured ID" &&
                  !this.isInsuredChangeAttribute &&
                  !isInitialLoad) ||
                (!this.isPolicyReport &&
                  !this.isTasksReport &&
                  !this.isNotesReport &&
                  !this.isWorkflow &&
                  item2.label == "Carrier" &&
                  !this.isCarrierChangeAttribute &&
                  !isInitialLoad)
              ) {
                if (item2.data.status == 0) {
                  mapItem.data.filtersApplied++;
                  item2.data.status = 2;
                  await this.setFilter(item2);
                }
              }
            }

            if (
              item2.children.length === 0 ||
              (item2.entityColumnId > 0 && item2.children.length > 0)
            ) {
              if (
                item2.key == key &&
                !isDeselect &&
                !isFilter &&
                !isFilterRemove &&
                !this.isDeselect
              ) {
                if (item2.data.status === 0) {
                  item2.data.status = 1;
                  mapItem.data.filtersApplied++;
                } else if (item2.data.status === 1) {
                  item2.data.status = 2;
                } else {
                  item2.data.status = 1;
                  const checkForDup = this.customReportFiltersList.map(
                    (i: any) => i.key === item2.key
                  );
                  if (checkForDup.count === 0) {
                    mapItem.data.filtersApplied++;
                  }
                }
                await this.setFilter(item2);
              } else if (
                this.isClearAll &&
                !isDeselect &&
                !isFilter &&
                !isFilterRemove
              ) {
                item2.data.status = 0;
                item2.data.filtersApplied = 0;
                mapItem.data.filtersApplied = 0;
              } else if (
                item2.key == key &&
                isDeselect &&
                !isFilter &&
                !isFilterRemove
              ) {
                item2.data.status = 0;
                item2.children.length < 1 && item2.data.filtersApplied--;
                mapItem.children.length < 1 && mapItem.data.filtersApplied--;
                this.isDeselect = false;
              } else if (
                item2.key == key &&
                isFilter &&
                !isDeselect &&
                !isFilterRemove
              ) {
                if (
                  (this.isSSRAutoComplete &&
                    this.isSSRAutoCompleteCondition &&
                    this.autoCompleteValue !== "" &&
                    this.autoCompleteValue.length > 0 &&
                    this.autoCompleteValue !== null &&
                    this.autoCompleteValue !== undefined) ||
                  (this.isSSRState &&
                    this.selectedState !== "" &&
                    this.selectedState !== undefined) ||
                    (
                      this.isIDField && this.isConditionField
                    )||
                  this.isSSREmptyNotEmptyCondition ||
                  this.isSSRBetweenNotBetweenCondition
                ) {
                  item2.data.filtersApplied = item2.data.filtersApplied + 1;
                } else if (
                  (!this.isSSRAutoComplete &&
                    this.isSSRState &&
                    this.filterValue !== undefined &&
                    this.filterValue !== "") ||
                  (!this.isSSRState &&
                    this.filterValue !== undefined &&
                    this.filterValue !== "" &&
                    this.filterValue.length) ||
                  this.isSSREmptyNotEmptyCondition ||
                  this.isSSRBetweenNotBetweenCondition
                ) {
                  item2.data.filtersApplied = item2.data.filtersApplied + 1;
                } else {
                  if (this.filterValue !== undefined && this.filterValue !== "")
                    item2.data.filtersApplied = item2.data.filtersApplied + 1;
                }
                await this.addFilter();
              } else if (
                item2.key == key &&
                !isFilter &&
                !isDeselect &&
                isFilterRemove
              ) {
                item2.data.filtersApplied = item2.data.filtersApplied - 1;
              }
            }
            if (item2.children.length > 0) {
              // this.hasChild(item2.children);
              //// temporarily commented above hasChild code
              item2.children.map(async (item3: any) => {
                if (
                  (isInitialLoad &&
                    !this.isPolicyReport &&
                    !this.isTasksReport &&
                    !this.isAppsAndCerts &&
                    !this.isWorkflow &&
                    item3.label == "Agent/Agency ID" &&
                    !this.isAgentChangeAttribute) ||
                  (!this.isPolicyReport &&
                    !this.isWorkflow &&
                    item3.label == "Insured ID" &&
                    !this.isInsuredChangeAttribute) ||
                  (!this.isPolicyReport &&
                    !this.isTasksReport &&
                    !this.isNotesReport &&
                    !this.isWorkflow &&
                    item2.label == "Carrier" &&
                    !this.isCarrierChangeAttribute)
                ) {
                  if (item3.data.status == 0) {
                    item2.data.filtersApplied++;
                    item3.data.status = 2;
                    await this.setFilter(item3);
                  }
                }
                if (this.parentDataTemp.includes(mapItem.key)) {
                  let cppp1 = this.checkHasProperty(item3);

                  if (
                    (!isInitialLoad &&
                      !this.isPolicyReport &&
                      !this.isTasksReport &&
                      !this.isAppsAndCerts &&
                      !this.isWorkflow &&
                      cppp1.length > 0 &&
                      item3.label == "Agent/Agency ID" &&
                      !this.isAgentChangeAttribute) ||
                    (!this.isPolicyReport &&
                      !this.isWorkflow &&
                      item3.label == "Contract ID" &&
                      !this.isContractChangeAttribute) ||
                    (!this.isPolicyReport &&
                      !this.isWorkflow &&
                      item3.label == "Insured ID" &&
                      !this.isInsuredChangeAttribute) ||
                    (!this.isPolicyReport &&
                      !this.isTasksReport &&
                      !this.isNotesReport &&
                      !this.isWorkflow &&
                      item2.label == "Carrier" &&
                      !this.isCarrierChangeAttribute)
                  ) {
                    if (item3.data.status == 0) {
                      item2.data.filtersApplied++;
                      item3.data.status = 2;
                      await this.setFilter(item3);
                    }
                  }
                }
                if (
                  item3.children.length === 0 ||
                  (item3.entityColumnId > 0 && item3.children.length > 0)
                ) {
                  if (
                    item3.apiParam &&
                    item3.key == key &&
                    !isDeselect &&
                    !isFilter &&
                    !isFilterRemove &&
                    !this.isDeselect
                  ) {
                    if (item3.data.status === 0) {
                      item3.data.status = 1;
                      item2.data.filtersApplied++;
                    } else if (item3.data.status === 1) {
                      item3.data.status = 2;
                    } else {
                      item3.data.status = 1;
                      const checkForDup = this.customReportFiltersList.map(
                        (i: any) => i.key === item3.key
                      );
                      if (checkForDup === 0) {
                        item2.data.filtersApplied++;
                      }
                    }
                    await this.setFilter(item3);
                  } else if (
                    this.isClearAll &&
                    !isDeselect &&
                    !isFilter &&
                    !isFilterRemove
                  ) {
                    item3.data.status = 0;
                    item3.data.filtersApplied = 0;
                    item2.data.filtersApplied = 0;
                  } else if (
                    item3.key == key &&
                    isDeselect &&
                    !isFilter &&
                    !isFilterRemove
                  ) {
                    item3.data.status = 0;
                    item3.children.length < 1 && item3.data.filtersApplied--;
                    item2.children.length < 1 && item2.data.filtersApplied--;
                    this.isDeselect = false;
                  } else if (
                    item3.key == key &&
                    isFilter &&
                    !isDeselect &&
                    !isFilterRemove
                  ) {
                    // for 3rd step , appliedfilter count
                    
                    if (
                      (this.isSSRAutoComplete &&
                        this.isSSRAutoCompleteCondition &&
                        this.autoCompleteValue !== "" &&
                        this.autoCompleteValue.length > 0 &&
                        this.autoCompleteValue !== null &&
                        this.autoCompleteValue !== undefined) ||
                      (this.isSSRState &&
                        this.selectedState !== "" &&
                        this.selectedState !== undefined) ||
                        (
                          this.isIDField && this.isConditionField
                        )||
                      this.isSSREmptyNotEmptyCondition ||
                      this.isSSRBetweenNotBetweenCondition
                    ) {
                      item3.data.filtersApplied = item3.data.filtersApplied + 1;
                    } else if (
                      (!this.isSSRAutoComplete &&
                        this.isSSRState &&
                        this.filterValue !== undefined &&
                        this.filterValue !== "") ||
                      (!this.isSSRState &&
                        this.filterValue !== undefined &&
                        this.filterValue !== "" &&
                        this.filterValue.length) ||
                      this.isSSREmptyNotEmptyCondition ||
                      this.isSSRBetweenNotBetweenCondition
                    ) {
                      item3.data.filtersApplied = item3.data.filtersApplied + 1;
                    } else {
                      if (
                        this.filterValue !== undefined &&
                        this.filterValue !== ""
                      )
                        item3.data.filtersApplied =
                          item3.data.filtersApplied + 1;
                    }

                    await this.addFilter();
                  } else if (
                    item3.key == key &&
                    !isFilter &&
                    !isDeselect &&
                    isFilterRemove
                  ) {
                    item3.data.filtersApplied = item3.data.filtersApplied - 1;
                  }
                }
                /// 3rd child start
                if (item3.children.length > 0) {
                  item3.children.map(async (item4: any) => {
                    if (
                      (isInitialLoad &&
                        !this.isPolicyReport &&
                        !this.isTasksReport &&
                        !this.isAppsAndCerts &&
                        !this.isWorkflow &&
                        item4.label == "Agent/Agency ID" &&
                        !this.isAgentChangeAttribute) ||
                      (!this.isPolicyReport &&
                        !this.isWorkflow &&
                        item4.label == "Insured ID" &&
                        !this.isInsuredChangeAttribute) ||
                      (!this.isPolicyReport &&
                        !this.isTasksReport &&
                        !this.isNotesReport &&
                        !this.isWorkflow &&
                        item2.label == "Carrier" &&
                        !this.isCarrierChangeAttribute)
                    ) {
                      if (item4.data.status == 0) {
                        item3.data.filtersApplied++;
                        item4.data.status = 2;
                        await this.setFilter(item4);
                      }
                    }
                    if (this.parentDataTemp.includes(mapItem.key)) {
                      let cppp3 = this.checkHasProperty(item4);

                      if (
                        (!isInitialLoad &&
                          !this.isPolicyReport &&
                          !this.isTasksReport &&
                          !this.isWorkflow &&
                          cppp3.length > 0 &&
                          item4.label == "Agent/Agency ID" &&
                          !this.isAgentChangeAttribute) ||
                        (!this.isPolicyReport &&
                          !this.isWorkflow &&
                          item4.label == "Contract ID" &&
                          !this.isContractChangeAttribute) ||
                        (!this.isPolicyReport &&
                          !this.isWorkflow &&
                          item4.label == "Insured ID" &&
                          !this.isInsuredChangeAttribute) ||
                        (!this.isPolicyReport &&
                          !this.isTasksReport &&
                          !this.isNotesReport &&
                          !this.isWorkflow &&
                          item2.label == "Carrier" &&
                          !this.isCarrierChangeAttribute)
                      ) {
                        if (item4.data.status == 0) {
                          item3.data.filtersApplied++;
                          item4.data.status = 2;
                          await this.setFilter(item4);
                        }
                      }
                    }
                    if (
                      item4.children.length === 0 ||
                      (item4.entityColumnId > 0 && item4.children.length > 0)
                    ) {
                      if (
                        item4.apiParam &&
                        item4.key == key &&
                        !isDeselect &&
                        !isFilter &&
                        !isFilterRemove &&
                        !this.isDeselect
                      ) {
                        if (item4.data.status === 0) {
                          item4.data.status = 1;
                          item3.data.filtersApplied++;
                        } else if (item4.data.status === 1) {
                          item4.data.status = 2;
                        } else {
                          item4.data.status = 1;
                          const checkForDup = this.customReportFiltersList.map(
                            (i: any) => i.key === item4.key
                          );
                          if (checkForDup === 0) {
                            item3.data.filtersApplied++;
                          }
                        }
                        await this.setFilter(item4);
                      } else if (
                        this.isClearAll &&
                        !isDeselect &&
                        !isFilter &&
                        !isFilterRemove
                      ) {
                        item4.data.status = 0;
                        item4.data.filtersApplied = 0;
                        item3.data.filtersApplied = 0;
                      } else if (
                        item4.key == key &&
                        isDeselect &&
                        !isFilter &&
                        !isFilterRemove
                      ) {
                        item4.data.status = 0;
                        item4.children.length < 1 && item4.data.filtersApplied--;
                        item3.data.filtersApplied--;
                        this.isDeselect = false;
                      } else if (
                        item4.key == key &&
                        isFilter &&
                        !isDeselect &&
                        !isFilterRemove
                      ) {
                        //

                        if (
                          (this.isSSRAutoComplete &&
                            this.isSSRAutoCompleteCondition &&
                            this.autoCompleteValue !== "" &&
                            this.autoCompleteValue.length > 0 &&
                            this.autoCompleteValue !== null &&
                            this.autoCompleteValue !== undefined) ||
                          (this.isSSRState &&
                            this.selectedState !== "" &&
                            this.selectedState !== undefined) ||
                            (
                              this.isIDField && this.isConditionField
                            )||
                          this.isSSREmptyNotEmptyCondition ||
                          this.isSSRBetweenNotBetweenCondition
                        ) {
                          item4.data.filtersApplied =
                            item4.data.filtersApplied + 1;
                        } else if (
                          (!this.isSSRAutoComplete &&
                            this.isSSRState &&
                            this.filterValue !== undefined &&
                            this.filterValue !== "") ||
                          (!this.isSSRState &&
                            this.filterValue !== undefined &&
                            this.filterValue !== "" &&
                            this.filterValue.length) ||
                          this.isSSREmptyNotEmptyCondition ||
                          this.isSSRBetweenNotBetweenCondition
                        ) {
                          item4.data.filtersApplied =
                            item4.data.filtersApplied + 1;
                        } else {
                          if (
                            this.filterValue !== undefined &&
                            this.filterValue !== ""
                          )
                            item4.data.filtersApplied =
                              item4.data.filtersApplied + 1;
                        }
                        await this.addFilter();
                      } else if (
                        item4.key == key &&
                        !isFilter &&
                        !isDeselect &&
                        isFilterRemove
                      ) {
                        item4.data.filtersApplied =
                          item4.data.filtersApplied - 1;
                      }

                      
                    }
                    /// 4th child start
                    if (item4.children.length > 0) {
                      item4.children.map(async (item5: any) => {
                        if (
                          (isInitialLoad &&
                            !this.isPolicyReport &&
                            !this.isTasksReport &&
                            !this.isWorkflow &&
                            item5.label == "Agent/Agency ID" &&
                            !this.isAgentChangeAttribute) ||
                          (!this.isPolicyReport &&
                            !this.isWorkflow &&
                            item5.label == "Insured ID" &&
                            !this.isInsuredChangeAttribute) ||
                          (!this.isPolicyReport &&
                            !this.isTasksReport &&
                            !this.isNotesReport &&
                            !this.isWorkflow &&
                            item2.label == "Carrier" &&
                            !this.isCarrierChangeAttribute)
                        ) {
                          if (item5.data.status == 0) {
                            item4.data.filtersApplied++;
                            item5.data.status = 2;
                            await this.setFilter(item5);
                          }
                        }
                        if (this.parentDataTemp.includes(mapItem.key)) {
                          let cppp5 = this.checkHasProperty(item5);

                          if (
                            (!isInitialLoad &&
                              !this.isPolicyReport &&
                              !this.isTasksReport &&
                              !this.isWorkflow &&
                              cppp5.length > 0 &&
                              item5.label == "Agent/Agency ID" &&
                              !this.isAgentChangeAttribute) ||
                            (!this.isPolicyReport &&
                              !this.isWorkflow &&
                              item5.label == "Contract ID" &&
                              !this.isContractChangeAttribute) ||
                            (!this.isPolicyReport &&
                              !this.isWorkflow &&
                              item5.label == "Insured ID" &&
                              !this.isInsuredChangeAttribute) ||
                            (!this.isPolicyReport &&
                              !this.isTasksReport &&
                              !this.isNotesReport &&
                              !this.isWorkflow &&
                              item2.label == "Carrier" &&
                              !this.isCarrierChangeAttribute)
                          ) {
                            if (item5.data.status == 0) {
                              item4.data.filtersApplied++;
                              item5.data.status = 2;
                              await this.setFilter(item5);
                            }
                          }
                        }

                        if (
                          item5.children.length === 0 ||
                          (item5.entityColumnId > 0 &&
                            item5.children.length > 0)
                        ) {
                          if (
                            item5.apiParam &&
                            item5.key == key &&
                            !isDeselect &&
                            !isFilter &&
                            !isFilterRemove &&
                            !this.isDeselect
                          ) {
                            if (item5.data.status === 0) {
                              item5.data.status = 1;
                              item4.data.filtersApplied++;
                            } else if (item5.data.status === 1) {
                              item5.data.status = 2;
                            } else {
                              item5.data.status = 1;
                              const checkForDup =
                                this.customReportFiltersList.map(
                                  (i: any) => i.key === item5.key
                                );
                              if (checkForDup === 0) {
                                item4.data.filtersApplied++;
                              }
                            }
                            await this.setFilter(item5);
                          } else if (
                            this.isClearAll &&
                            !isDeselect &&
                            !isFilter &&
                            !isFilterRemove
                          ) {
                            item5.data.status = 0;
                            item5.data.filtersApplied = 0;
                            item4.data.filtersApplied = 0;
                          } else if (
                            item5.key == key &&
                            isDeselect &&
                            !isFilter &&
                            !isFilterRemove
                          ) {
                            item5.data.status = 0;
                            item5.children.length < 1 && item5.data.filtersApplied--;
                            item4.children.length < 1 && item4.data.filtersApplied--;
                            this.isDeselect = false;
                          } else if (
                            item5.key == key &&
                            isFilter &&
                            !isDeselect &&
                            !isFilterRemove
                          ) {
                            if (
                              this.filterValue !== undefined &&
                              this.filterValue !== ""
                            ) {
                              item5.data.filtersApplied =
                                item5.data.filtersApplied + 1;
                            }
                            await this.addFilter();
                          } else if (
                            item5.key == key &&
                            !isFilter &&
                            !isDeselect &&
                            isFilterRemove
                          ) {
                            item5.data.filtersApplied =
                              item5.data.filtersApplied - 1;
                          }
                        }
                      });
                    }
                    /// 4th child end
                  });
                }
                /// 3rd child end
              });
              //// 2nd child end
            }
          });
        }

        if (
          mapItem &&
          Object.keys(mapItem).length > 1 &&
          mapItem.children.length < 1
        ) {
          
          if (
            mapItem.key == key &&
            !isDeselect &&
            !isFilter &&
            !this.isDeselect
          ) {
            if (mapItem.data.status === 0) {
              mapItem.data.status = 1;
              mapItem.data.filtersApplied++;
            } else if (mapItem.data.status === 1) {
              mapItem.data.status = 2;
            } else {
              mapItem.data.status = 1;
              mapItem.data.filtersApplied++;
            }
            await this.setFilter(mapItem);
          } else if (
            this.isClearAll &&
            !isDeselect &&
            !isFilter &&
            !isFilterRemove
          ) {
            mapItem.data.status = 0;
            mapItem.data.filtersApplied = 0;
          } else if (mapItem.key == value && isDeselect && !isFilter) {
            mapItem.data.status = 0;
            mapItem.children.length < 1 && mapItem.data.filtersApplied--;
            this.isDeselect = false;
          } else if (mapItem.key == key && isFilter && !isDeselect) {
            await this.addFilter();
          }
        }
      });

      this.isClearAll = false;
      this.reportTreeData = "";
      this.autoCompleteValue = "";
      this.filterValue ="";
      this.reportTreeData = objMain;
    }
  };

  @action expandTreeNode = async (nodes: any) => {
    const _expandedKeys = {};
    for (const node of nodes) {
      await this.expandNode(node, _expandedKeys);
    }
    this.expandedNodes = "";
    this.expandedNodes = _expandedKeys;
    this.parentNodeFilterCount = "";
    this.parentNodeFilterCountPrev = "";
    this.parentNodeFilterCount = Object.keys(_expandedKeys);
    this.parentNodeFilterCountPrev = Object.keys(_expandedKeys);
  };

  @action expandNode = async (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (const child of node.children) {
        this.expandNode(child, _expandedKeys);
      }
    }
  };

  @action onExpand(node: any) {
    this.parentNodeFilterCount.push(node.key);
  }

  @action onCollapse(node: any) {
    this.parentNodeFilterCount = this.parentNodeFilterCount.filter(
      (i: any) => i !== node.key
    );
  }

  @action goToReports = () => {
    this.selectedReportType = "";
    this.reset();
    routes.customReports.replace();
  };

  @observable reportTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedReportType: string = "";
  @observable activeIndex: number = 1;

  @action setActiveIndex = (activeIndex: number) => {
    if (activeIndex == 1) {
      this.parentNodeFilterCount = this.parentNodeFilterCountPrev;
    }
    this.activeIndex = activeIndex;
  };

  @action loadReportTypes = async () => {
    const result = await ReportStore.entityTypeDropdownValues();
    if (result) {
      Utils.mapListItemsToDropdown(result, this.reportTypeList, "", "");
    }
  };
  @action validatePermissions = () => {
    if (!Utils.hasUserPermission(Permission.ManageAgentAgencyReportCustom)) {
      this.removeTypesFromList("Agent/Agency");
    }

    if (!Utils.hasUserPermission(Permission.ManageInsuredReportsCustom)) {
      this.removeTypesFromList("Insured");
    }
    if (!Utils.hasUserPermission(Permission.ViewAgentNotes)) {
        this.removeTypesFromList("Agent Notes");
    }
    if (!Utils.hasUserPermission(Permission.ViewInsuredNotes)) {
      this.removeTypesFromList("Insured Notes");
    }

    if (!Utils.hasUserPermission(Permission.ManageTasksReportCustom)) {
      this.removeTypesFromList("Tasks");
    }
    if (!Utils.hasUserPermission(Permission.ManageStateLicensesReportCustom)) {
      this.removeTypesFromList("State Licenses");
    }

    if ((!Utils.hasUserPermission(Permission.ManagePolicyReportCustom))) {
      this.removeTypesFromList("Policy");
    }
    if ((!Utils.hasUserPermission(Permission.ManageAppointmentsAndCertificationsReportCustom))) {
      this.removeTypesFromList("Appointments and Certifications");
    }
    if ((!Utils.hasUserPermission(Permission.ManagePointsReportCustom))) {
      this.removeTypesFromList("Points");
    }
    if ((!Utils.hasUserPermission(Permission.ManageWorkflowReportCustom))) {
      this.removeTypesFromList("Workflow");
    }
  }
  @action removeTypesFromList = (labelValue: string) => {
    var res = this.reportTypeList.values.find(x => x.label.toLowerCase() === labelValue.toLowerCase());
    if (res) {
      this.reportTypeList.values.forEach((item, index) => {
        if (item === res) this.reportTypeList.values.splice(index, 1);
      });
    }
  }

  @action loadReportAttributes = async () => {
    const result = await ReportStore.getAllEntityDefinition(
      +this.selectedReportType
    );
    let checkReportLabel = this.reportTypeList.values.filter(
      (itm: any) => itm.value == this.selectedReportType
    );
    if (result) {
      this.isPolicyReport = checkReportLabel[0].label == "Policy";
      this.isTasksReport = checkReportLabel[0].label == "Tasks";
      this.isAppsAndCerts = checkReportLabel[0].label == "Appointment and Certifications";
      this.isNotesReport = checkReportLabel[0].label == "Notes";
      this.isWorkflow = checkReportLabel[0].label == "Workflow";
      this.reportTreeData = "";
      this.isAgentChangeAttribute = false;
      this.isContractChangeAttribute = false;
      this.isCarrierChangeAttribute = false;
      this.isInsuredChangeAttribute = false;
      this.isWorkflowChangeAttribute = false;
      this.reportTreeData = result;
      this.isSetColumn = true;
      this.infiniteCheck({ "000000000": "a" }, false, false, false, true);

      this.hasChild(this.reportTreeData);
      await this.expandTreeNode(this.reportTreeData);
    }
  };

  @action setReportPreview = async (value: boolean) => {
    this.previewReport(value);
  };

  @action setFilter = async (i: any) => {
    var reportCustom = this.reportTreeData;
      if (reportCustom) {
          this.custAttEntityDefinitionId = reportCustom.find((x: any) => x.key.toLowerCase() === "custom attributes") || 0;

          if (this.custAttEntityDefinitionId == undefined || this.custAttEntityDefinitionId == 0) {
              this.custAttEntityDefinitionId = reportCustom.find((x: any) => x.key.toLowerCase() === "agent custom attributes") || (reportCustom.find((x: any) => x.key.toLowerCase() === "contracts custom attributes") || 0);
          }
      }
    const temp = this.customReportFiltersList;
    if (i) {
      const node = temp.filter((o: any) => o.key == i.key);
      if (node.length > 0) {
        node[0].status = i.data.status;
        node[0].filtersApplied = i.data.filtersApplied;
      } else {
        if (i.apiParam) {
          temp.push({
            key: i.key,
            label: i.label,
            apiParam: i.apiParam.trim(),
            status: i.data.status,
            entityColumnId: i.entityColumnId,
            filterType: i.data.filterType,
            columnFilters: [],
            filtersApplied: i.data.filtersApplied,
            filterValues: i.data.filterValues,
            displayOrder: this.customReportFiltersList.length + 1,
            isCustomAttribute: i.isCustomAttribute,
            custAttEntityColumnId: i.isCustomAttribute ? (i.custAttEntityColumnId ? i.custAttEntityColumnId : i.entityColumnId) : 0,
            custAttEntityDefinitionId: i.isCustomAttribute && this.custAttEntityDefinitionId && this.custAttEntityDefinitionId.entityDefinitionId ? this.custAttEntityDefinitionId.entityDefinitionId : (i.custAttEntityDefinitionId ? i.custAttEntityDefinitionId : 0),
          });
          const checkDup = this.filterTypeList.values.filter(
            (o: any) => o.value == "" + i.entityColumnId
          );
          if (
            i.data.filterType !== CustomReportFilterType.None &&
            checkDup.length === 0
          ) {
            this.filterTypeList.values.push({
              label: i.label,
              value: "" + i.entityColumnId,
            });
          }
          this.selectedFilterType =
            this.filterTypeList.values.length > 0
              ? this.filterTypeList.values[0].value
              : "";
              
        }
        
      }
    }
    temp.map((i: any, idx: any) => (i.displayOrder = idx + 1));
    this.customReportFiltersList = [];
    this.customReportFiltersList = temp;
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.previewReport(this.reportPreview);
    }, 800);
  };

  @action clearAll = () => {
    this.isClearAll = true;
    this.isIDField = false;
    this.isConditionField = false;
    this.isIDFieldLabel = "";
    this.customReportFiltersList = [];
    this.filterTypeList.values = [];
    this.dataTableData = [];
    this.isInsuredChangeAttribute = true;
    this.dataTableHeaders = [];
    this.selectedFilterType = "";
    this.infiniteCheck("", false, false, false, false);
    this.isAgentChangeAttribute = false;
    this.isContractChangeAttribute = false;
    this.isCarrierChangeAttribute = false;
    this.isWorkflowChangeAttribute = false;
    this.isInsuredChangeAttribute = false;
    this.setClearAllConfirm(false);

  };

  @action hideValidationToaster = () => {
    toast.dismiss("validation-toaster");
};

  showErrorMessageinToaster(message: string) {
    toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: false,
        closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
        className: "validation-toaster website",
        draggable: false,
        toastId: "validation-toaster",
        autoClose: 7000,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
        this.hideValidationToaster();
    }, 7000);
}
  @action save = async (dto: ReportModel) => {
    try {
      this.CustomReportDetailsAddEditViewModel.isException = false;
      dto.id = this.selectedId;
      dto.headerText = dto.name;
      dto.footerText = undefined;
      dto.logoName = undefined;
      dto.isCustom = true;
      dto.createdByUserId = UserContext.getUserId();
      dto.reportColumns = await this.getReportColumn();
      if (this.selectedId === 0) {
        localStorage.setItem("ssrCreateToast", "created");
        const id = await ReportStore.createReport(dto);
        routes.previewCustomReport.push({ id: id });
      } else {
        localStorage.setItem("ssrCreateToast", "updated");
        await ReportStore.updateReport(dto);
        routes.previewCustomReport.replace({ id: this.selectedId });
      }
      this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
        false
      );
    } catch (e: any) { 
      e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          this.showErrorMessageinToaster(`${_title}`);
      });
      this.isLoading = false;
      PageContext.setIsMessageVisible(false);
      IMASLog.log("exception: " + e);
    }
  };

  @action getReportColumn = async () => {
    var reportCustom = this.reportTreeData;
    if (reportCustom) {
        this.custAttEntityDefinitionId = reportCustom.find((x: any) => x.key.toLowerCase() === "custom attributes") || 0;

        if (this.custAttEntityDefinitionId == undefined || this.custAttEntityDefinitionId == 0) {
            this.custAttEntityDefinitionId = reportCustom.find((x: any) => x.key.toLowerCase() === "agent custom attributes") || (reportCustom.find((x: any) => x.key.toLowerCase() === "contracts custom attributes") || 0);
        }
    }

    const reportDto: any = [];
    let count = 1;
    this.customReportFiltersList.map(async (item: any, i: any) => {
      if (item.status !== 2) {
        reportDto.push({
          id: item.id > 0 ? item.id : 0,
          reportId: this.selectedId,
          entityColumnId: item.entityColumnId,
          value: item.value == "None" ? "" : item.value,
          columnFilters:
            item.columnFilters.length > 0
              ? await this.getFilters(item.columnFilters)
              : [],
          isDisabled: item.status === 2 ? true : false,
          displayOrder:
            this.columnReorderPositions.length > 0
              ? this.columnReorderPositions.indexOf(item.apiParam) == -1
                ? this.columnReorderPositions.length + 1
                : this.columnReorderPositions.indexOf(item.apiParam) + 1
                    : item.displayOrder,
          isCustomAttribute: item.isCustomAttribute,
          custAttEntityColumnId: item.isCustomAttribute ? item.entityColumnId : 0,
          custAttEntityDefinitionId: item.isCustomAttribute && this.custAttEntityDefinitionId ? this.custAttEntityDefinitionId.entityDefinitionId : 0
        });
        count++;
      }
    });

    return reportDto;
  };

  @action saveConfirmDialog = async (value: boolean) => {
    if (this.selectedId === 0) {
      this.CustomReportDetailsAddEditViewModel.reportName = "";
      this.customReportTitle = CustomReportModalType.Create;
    }
    this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
      value
    );
  };

  @action reset = async () => {
    this.parentNodeList = [];
    this.isDeselect = false;
    this.title = "New";
    this.isShowCreateToast = false;
    this.showGenerateToast = false;
    this.generateConfirm = false;
    this.showCloneToast = false;
    this.reportPreview = true;
    this.isDiscardConfirm = false;
    this.selectedPrivacyStatus = "";
    this.ReportScreenHeader = "";
    this.activeIndex = 1;
    this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
      false
    );
    this.customReportFiltersList = [];
    this.dataTableData = [];
    this.dataTableHeaders = [];
    this.reportTreeData = "";
    this.expandedNodes = "";
    this.parentNodeFilterCount = "";
    this.parentNodeFilterCountPrev = "";
    this.showFiltersDialog = false;
    this.isEdit = false;
    this.isPreview = false;
    this.customReportDescription = "";
    this.isClearAllConfirm = false;
    this.customReportName = "";
    this.selectedId = 0;
    this.createdBy = "";
    this.createdByUserId = 0;
    this.access = false;
    this.isDeleteConfirm = false;
    this.filterTypeList.values = [];
    this.selectedFilterType = "";
  };

  @action setFiltersDialog = async (value: boolean, node: any) => {
    this.showFiltersDialog = value;
    if (value) {
      this.isSSRAutoComplete =
        node && node.filterType == CustomReportFilterType.AutoComplete;

      node &&
        (node.label == "State" || node.label == "License State") &&
        node &&
        node.filterType == CustomReportFilterType.Dropdown &&
        (await this.loadAppointmentStates());
      this.isSSRState =
        node &&
        (node.label == "State" || node.label == "License State") &&
        node &&
        node.filterType == CustomReportFilterType.Dropdown;
      this.selectedEntityLabel = node && node.label == "Insured ID";

      // this.isSSRGender = node && node.label == "Gender";
      this.isSetColumn = true;
      if (!node) {
        const firstNode = this.customReportFiltersList.filter(
          (i: any) => i.filterType !== CustomReportFilterType.None
        );

        node = firstNode.length > 0 ? firstNode[0] : [];
      }
      this.filterValue2 = "";
      await this.setFilterDropdowns(node);
      this.filterValue = "";
    } else {
      this.selectedStateValue = "";
      this.autoCompleteValue = "";
      this.isIDField = false;
      this.isConditionField = false;
      this.selectedSsrId = '';
      this.filterValue = ""; 
      await this.previewReport(this.reportPreview);
    }
  };

  @action callpreviewReport = async (e: any) => {
    this.showFiltersDialog = false;
    this.isConditionField = false;
    this.selectedSsrId = ''; 
    this.isIDField = false;
    e.preventDefault(); 
    e.stopPropagation(); 
     await this.previewReport(this.reportPreview);
     this.selectedStateValue = "";
     this.autoCompleteValue = "";
     this.filterValue = "";  
     return false;
  }


    @action filterTypeChange = async () => {
      this.isIDFieldLabel = '';
      this.selectedSsrId='';
      this.isConditionField = false;
    const node = this.customReportFiltersList.filter(
      (i: any) => "" + i.entityColumnId === this.selectedFilterType
    );

    if (node && node.length > 0) {
      if (node[0].label  == "Agent/Agency ID" ||
      node[0].label  == "Contract ID" ){ 
        this.isIDField = true;
        this.isSSRAutoComplete = false;
        this.selectedEntityLabel = false;
        this.isSSRBetweenNotBetweenCondition = false;
        this.isSSREmptyNotEmptyCondition = true;
        this.isIDFieldLabel = node[0].label;
      }else{ 
        this.isIDField = false;
        this.isIDFieldLabel = '';
        this.selectedSsrId='';
      }
      this.autoCompleteValue = "";
      this.filterValue ="";
      this.isSSRAutoComplete =
        node && node[0].filterType == CustomReportFilterType.AutoComplete;
      node &&
        (node[0].label == "State" || node[0].label == "License State") &&
        node &&
        node[0].filterType == CustomReportFilterType.Dropdown &&
        (await this.loadAppointmentStates());
      this.isSSRState =
        node &&
        (node[0].label == "State" || node[0].label == "License State") &&
        node &&
        node.filterType == CustomReportFilterType.Dropdown;
      this.selectedEntityLabel = node && node[0].label == "Insured ID";
      // this.isSSRGender = node && node[0].label == "Gender";
      await this.setFilterDropdowns(node[0]);
    }
  };
  
  @action filterConditionChange = async (value: string) => {
    this.selectedSsrId='';
    this.isConditionField = false;
    this.filterValueToDate = undefined;
    this.selectedFilterCondition = value;
    this.autoCompleteValue = "";
    this.filterValue = "";
    this.filterValue2 = "";
    this.isLesserBetweenValue = false;
    this.isSSRAutoCompleteCondition =
      this.isSSRAutoComplete && (value == "Equals" || value == "Not Equals");
    this.isSSREmptyNotEmptyCondition =
     ( value == "Is Empty" || 
      value == "Is Not Empty" ||
      value == "Today" ||
      value == "Yesterday" ||
      value == "Last Week (calendar week)" ||
      value == "Last Month" ||
      value == "This Month" ||
      value == "Year to Date (YTD)") ? true : false;
      
    this.isSSRBetweenNotBetweenCondition =
      value == "Between" || value == "Not Between";

    if ((value == "Equals" ||
    value == "Not Equals") && this.filterType == CustomReportFilterType.TextBoxWithDropdown) 
    { this.filterValue = this.filterDropdownList.values[0].value; }
    else {
      this.filterValue = "";
    }

    if (this.isIDField && value == "Equals" || value == "Not Equals"){
      this.isConditionField = true; 
    }else if( value == "Contains" || value == "Not Contains"){ 
      this.isSSRAutoComplete = false;
      this.selectedEntityLabel = false;
      this.isSSRBetweenNotBetweenCondition = false;
      this.isSSREmptyNotEmptyCondition = false;
      this.isConditionField = false; 
    }else if( this.filterType == CustomReportFilterType.AutoComplete && value == "Equals" || value == "Not Equals"){ 
      this.isSSRAutoComplete = true;
      this.selectedEntityLabel = false;
      this.isSSRBetweenNotBetweenCondition = false;
      this.isSSREmptyNotEmptyCondition = false;
      this.isConditionField = false; 
    }  else{
      this.isConditionField = false; 
    }
  };

  @action setFilterDropdowns = async (node: any) => {
    this.filterType = node.filterType;
    this.selectedEntityColumnId = node.entityColumnId || node.custAttEntityColumnId;
    this.selectedEntityKey = node.key;
    this.selectedFilterType = "" + node.entityColumnId || node.custAttEntityColumnId;
    this.isPredefinedFilter = false;
    this.isPredefinedFilterWithTextBox = false;
    this.isSSREmptyNotEmptyCondition = false;
    this.isSSRBetweenNotBetweenCondition = false;
    this.filterConditionList.values = [];
    
    
    if (node.label  == "Agent/Agency ID" ||
      node.label  == "Contract ID"){ 
        this.isIDField = true;
        this.isSSRAutoComplete = false;
        this.selectedEntityLabel = false;
        this.isSSRBetweenNotBetweenCondition = false;
        this.isSSREmptyNotEmptyCondition = true;
        this.isIDFieldLabel = node.label;
      }else{ 
        this.isIDField = false;
        this.isConditionField = false;
        this.isIDFieldLabel ='';
        this.selectedSsrId='';
      }
       
    if (node) {
      switch (node.filterType) {
        case CustomReportFilterType.TextBox:
              this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Contains", value: "Contains" },
            { label: "Not Contains", value: "Not Contains" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" }
          );
          break;
        case CustomReportFilterType.TextBoxWithIsEmpty:
          this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Contains", value: "Contains" },
            { label: "Not Contains", value: "Not Contains" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" }
          );
          break;
        case CustomReportFilterType.TextBoxWithCondition:
          this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" },
            { label: "Less Than", value: "Less Than" },
            { label: "Less Than or Equal", value: "Less Than or Equal" },
            { label: "Greater Than", value: "Greater Than" },
            { label: "Greater than or Equal", value: "Greater than or Equal" },
            { label: "Between", value: "Between" },
            { label: "Not Between", value: "Not Between" }
          );
          break;
        case CustomReportFilterType.TextBoxWithRanges:
              this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" },
            { label: "Less Than", value: "Less Than" },
            { label: "Less Than or Equal", value: "Less Than or Equal" },
            { label: "Greater Than", value: "Greater Than" },
            { label: "Greater than or Equal", value: "Greater than or Equal" },
            { label: "Between", value: "Between" },
            { label: "Not Between", value: "Not Between" }
          );
          break;
        case CustomReportFilterType.AutoComplete:
            if (node.label == "Insured ID") {
            this.isInsuredIDFeild = true;
            this.isSSRAutoComplete = true;
            this.isSSRAutoCompleteCondition = true;
            this.filterValue = CustomReportFilterType.AutoComplete;
              this.filterConditionList.values.push(
              { label: "Is Empty", value: "Is Empty" },
              { label: "Is Not Empty", value: "Is Not Empty" },
              { label: "Equals", value: "Equals" },
              { label: "Not Equals", value: "Not Equals" }
            );
            this.selectedEntityLabel = true;
            } else {
            this.isInsuredIDFeild = false;
            this.selectedEntityLabel = false;
            this.isSSRAutoComplete = true;
            this.filterValue ="";
              this.filterConditionList.values.push(
              { label: "Is Empty", value: "Is Empty" },
              { label: "Is Not Empty", value: "Is Not Empty" },
              { label: "Contains", value: "Contains" },
              { label: "Not Contains", value: "Not Contains" },
              { label: "Equals", value: "Equals" },
              { label: "Not Equals", value: "Not Equals" }
            );
          }
          break;

        case CustomReportFilterType.TextBoxWithDropdown:
              this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Contains", value: "Contains" },
            { label: "Not Contains", value: "Not Contains" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" }
          );
          //dropdown
          this.filterValue ="";
          this.isPredefinedFilterWithTextBox = true;
          this.filterDropdownList.values = [];
          if (node.filterValues) {
            const filters = node.filterValues.split("|");
            if (filters.length > 0) {
              filters.map((i: any) => {
                this.filterDropdownList.values.push({ label: i, value: i });
                if ((this.selectedFilterCondition == "Equals" ||
                this.selectedFilterCondition == "Not Equals") &&
                 this.filterType == CustomReportFilterType.TextBoxWithDropdown && this.isPredefinedFilterWithTextBox) {
                   this.filterValue = this.filterDropdownList.values[0].value; }
              else {
                this.filterValue = "";
              }
              });

              

            }
          }
          else if (node.label !== "State" && node.label !== "License State") {
            // code when filterValues are not available
            let result = await ReportStore.getFilterValues(
              node.entityColumnId,
              node.label
            );
            if (result) {
              result.map((i: any) => {
                this.filterDropdownList.values.push({
                  label: i.value,
                  value: i.value,
                });
                if ((this.selectedFilterCondition == "Equals" ||
                this.selectedFilterCondition == "Not Equals") && this.filterType == CustomReportFilterType.TextBoxWithDropdown && this.isPredefinedFilterWithTextBox) {
                this.filterValue = this.filterDropdownList.values[0].value;
              }
              else {
                this.filterValue = "";
              }
              });
              
             
            }
          }

          break;
        case CustomReportFilterType.Dropdown:
          this.isPredefinedFilter = true;
          this.filterConditionList.values = [];
          this.filterDropdownList.values = [];
          if (node.filterValues) {
            const filters = node.filterValues.split("|");
            if (filters.length > 0) {
              filters.map((i: any) => {
                this.filterDropdownList.values.push({ label: i, value: i });
              });
              this.filterValue = this.filterDropdownList.values[0].value;
            }
          } else if (node.label !== "State" && node.label !== "License State") {
            // code when filterValues are not available
            let result = await ReportStore.getFilterValues(
              node.entityColumnId,
              node.label
            );
            if (result) {
              result.map((i: any) => {
                this.filterDropdownList.values.push({
                  label: i.value,
                  value: i.value,
                });
              });
              this.filterValue = this.filterDropdownList.values[0].value;
            }
          }
          // code for only state
          if (
            (node.label == "State" || node.label == "License State") &&
            node &&
            node.filterType == CustomReportFilterType.Dropdown
          ) {
            await this.loadAppointmentStates();
            this.isSSRState = true;
          } else {
            this.isSSRState = false;
          }
          this.filterConditionList.values.push({
            label: "Equals",
            value: "Equals",
          });
          break;
        case CustomReportFilterType.MultiSelectDropdown:
          this.filterConditionList.values = [];
          this.filterDropdownList.values = [];
          if (node.filterValues) {
            const filters = node.filterValues.split("|");
            if (filters.length > 0) {
              filters.map((i: any) => {
                this.filterDropdownList.values.push({ label: i, value: i });
              });
              this.filterValue = this.filterDropdownList.values[0].value;
            }
          }
          this.filterConditionList.values.push({
            label: "Equals",
            value: "Equals",
          });
          break;
        case CustomReportFilterType.NumericEntry:
          this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" },
            { label: "Greater", value: "Greater" },
            { label: "Greater Than Equals", value: "Greater Than Equals" },
            { label: "Lesser Than", value: "Lesser Than" },
            { label: "Lesser Than Equals", value: "Lesser Than Equals" }
          );
          break;
        case CustomReportFilterType.DatePicker:
          this.filterConditionList.values.push(
            { label: "Is Empty", value: "Is Empty" },
            { label: "Is Not Empty", value: "Is Not Empty" },
            { label: "Equals", value: "Equals" },
            { label: "Not Equals", value: "Not Equals" },
            { label: "Start", value: "Start" },
            { label: "End", value: "End" },
            { label: "Between", value: "Between" },
            { label: "Today", value: "Today" },
            { label: "Yesterday", value: "Yesterday" },
            { label: "Last Week (calendar week)", value: "Last Week (calendar week)" },
            { label: "Last Month", value: "Last Month" },
            { label: "This Month", value: "This Month" },
            { label: "Year to Date (YTD)", value: "Year to Date (YTD)" }
          );
          break;
        case CustomReportFilterType.CheckBox:
          this.filterConditionList.values.push(
            { label: "True", value: "True" },
            { label: "False", value: "False" }
          );
          break;
      }

      if (this.filterConditionList.values.length > 0) {
        this.isSSRAutoCompleteCondition =
          this.isSSRAutoComplete &&
          (this.filterConditionList.values[0].value == "Equals" ||
            this.filterConditionList.values[0].value == "Not Equals");
        this.selectedFilterCondition = this.filterConditionList.values[0].value;

        this.isSSREmptyNotEmptyCondition =
          this.filterConditionList.values[0].value == "Is Empty" ||
          this.filterConditionList.values[0].value == "Is Not Empty";
        this.selectedFilterCondition = this.filterConditionList.values[0].value;

        this.isSSRBetweenNotBetweenCondition =
          this.filterConditionList.values[0].value == "Between" ||
          this.filterConditionList.values[0].value == "Not Between";
        this.selectedFilterCondition = this.filterConditionList.values[0].value;
      }
    }
  };
  @action setInitialState = (label: any) => {
    if (label == "Agent/Agency ID") {
      this.isAgentChangeAttribute = true;
    }
    if (label == "Insured ID") {
      this.isInsuredChangeAttribute = true;
    }
    if (label == "Contract ID") {
      this.isContractChangeAttribute = true;
    }
    if (label == "Carrier") {
      this.isCarrierChangeAttribute = true;
    }
    if (label == "Document ID") {
      this.isWorkflowChangeAttribute = true;
    }
  };
  @action removeFilter = async (node: any, filterId: any) => {
    const tempCustomReportList = this.customReportFiltersList;
    let tempFilterList = this.filterTypeList.values;
    if (tempCustomReportList.length > 0) {
      tempCustomReportList.map((i: any) => {
        i.columnFilters = i.columnFilters.filter((j: any) => j.id !== filterId);
        if (node.key == i.key) {
          i.filtersApplied--;
        }
      });
      tempFilterList = tempFilterList.filter(
        (o: any) => o.value !== "" + filterId
      );
    }
    this.customReportFiltersList = "";
    this.filterTypeList.values = [];
    this.customReportFiltersList = tempCustomReportList;
    this.filterTypeList.values = tempFilterList;
  };

  @action addFilter = async () => {
    if (
      this.selectedFilterCondition === "Between" &&
      this.filterType === CustomReportFilterType.DatePicker &&
      (!this.filterValue || !this.filterValueToDate)
    ) {
      return true;
    }
    if (
      (this.selectedFilterCondition === "Between" ||
        this.selectedFilterCondition === "Not Between") &&
      this.filterType === CustomReportFilterType.TextBoxWithCondition &&
      (!this.filterValue || !this.filterValue2)
    ) {
      return true;
    }
   
    if (( (this.isIDField && this.isConditionField) ||
      (this.filterType === CustomReportFilterType.AutoComplete &&
        this.isSSRAutoCompleteCondition &&
        this.autoCompleteValue.length > 0) ||
      (this.selectedEntityLabel &&
        this.isSSRAutoComplete &&
        this.isSSRAutoCompleteCondition &&
        this.autoCompleteValue.length > 0 &&
        this.autoCompleteValue !== "" &&
        this.autoCompleteValue !== null &&
        this.autoCompleteValue !== undefined)) 
        || (this.isInsuredIDFeild && this.filterType === CustomReportFilterType.AutoComplete &&
                (this.selectedFilterCondition === "Is Empty" || 
                this.selectedFilterCondition === "Is Not Empty" 
                
                )) 
    ) {
      const tempCustomReportList = this.customReportFiltersList;

      if (tempCustomReportList.length > 0) {
        tempCustomReportList.map((item: any) => {
          if (
              (item.entityColumnId || item.custAttEntityColumnId) === this.selectedEntityColumnId &&
            item.filterType === this.filterType
          ) {
            item.filtersApplied++;
            item.columnFilters.push({
              id: "cv" + new Date().getMilliseconds(),
              reportColumnId: 0,
              entityColumnId: this.selectedEntityColumnId,
              operator: this.selectedFilterCondition,
              value: (this.isIDField && this.isConditionField) ? ""+this.selectedSsrId : (this.isInsuredIDFeild && this.filterType === CustomReportFilterType.AutoComplete &&
                    (this.selectedFilterCondition === "Is Empty" ||  this.selectedFilterCondition === "Is Not Empty")) ? "" : this.isSSRAutoCompleteCondition && this.autoCompleteValue,
            });
          }
        });
      }
      this.customReportFiltersList = "";
        this.customReportFiltersList = tempCustomReportList;        
      this.filterValue =
        this.filterType === CustomReportFilterType.AutoComplete
          ? this.filterDropdownList.values.length > 0
            ? this.filterDropdownList.values[0].value
            : ""
          : "";
      this.autoCompleteValue = "";
      this.filterValueToDate = undefined;
      this.isSSRAutoComplete = true;
    } //autoComplete

    if (
      this.isSSRState &&
      this.filterType === CustomReportFilterType.Dropdown &&
      this.selectedState !== "" &&
      this.selectedState !== undefined
    ) {
      const tempCustomReportList = this.customReportFiltersList;
      if (tempCustomReportList.length > 0) {
        tempCustomReportList.map((item: any) => {
          if (
              (item.entityColumnId || item.custAttEntityColumnId) === this.selectedEntityColumnId &&
            item.filterType === this.filterType
          ) {
            item.filtersApplied++;
            item.columnFilters.push({
              id: "cv" + new Date().getMilliseconds(),
              reportColumnId: 0,
              entityColumnId: this.selectedEntityColumnId,
              operator: this.selectedFilterCondition,
              value:
                this.selectedState == "None" ? "None" : this.selectedStateValue,
            });
          }
        });
        this.selectedStateValue = "None";
        this.selectedState = "None";
      }
      this.customReportFiltersList = "";
      this.customReportFiltersList = tempCustomReportList;
      this.filterValue =
        this.filterType === CustomReportFilterType.Dropdown
          ? this.filterDropdownList.values.length > 0
            ? this.filterDropdownList.values[0].value
            : ""
          : "";
      this.filterValueToDate = undefined;
    } //state

    if (
      (!this.isSSRState &&
        !this.isSSRAutoCompleteCondition &&
        !this.selectedEntityLabel &&
        ((this.filterValue !== undefined && this.filterValue !== "") ||
          this.isSSREmptyNotEmptyCondition ||
          this.isSSRBetweenNotBetweenCondition)) ||
      this.isAttributeLabel
    ) {
      
      const tempCustomReportList = this.customReportFiltersList;
      if (tempCustomReportList.length > 0) {
        tempCustomReportList.map((item: any) => {
          
          if (
            item.entityColumnId === this.selectedEntityColumnId &&
            item.filterType === this.filterType
          ) {
            
            item.filtersApplied++;
            item.columnFilters.push({
              id: "cv" + new Date().getMilliseconds(),
              reportColumnId: 0,
              operator: this.selectedFilterCondition,
              value: this.getFilterValue(),
            });
          }
        });
      }
      this.customReportFiltersList = "";
      this.customReportFiltersList = tempCustomReportList;
      this.filterValue = "";
      this.filterValue2 = "";
      this.isAttributeLabel = undefined;
      this.filterValueToDate = undefined;
    } //normal flow for agent type



    if (
      (!this.isSSRAutoComplete &&
        !this.selectedEntityLabel &&
        this.filterValue !== undefined &&
        this.filterValue !== "") ||
      (this.filterType === CustomReportFilterType.CheckBox && !this.isSSRState)
    ) {
      const tempCustomReportList = this.customReportFiltersList;
      if (tempCustomReportList.length > 0) {
        tempCustomReportList.map((item: any) => {
          if (
              (item.entityColumnId || item.custAttEntityColumnId) === this.selectedEntityColumnId &&
            item.filterType === this.filterType
          ) {
            item.filtersApplied++;
            item.columnFilters.push({
              id: "cv" + new Date().getMilliseconds(),
              reportColumnId: 0,
              entityColumnId: this.selectedEntityColumnId,
              operator: this.selectedFilterCondition,
              value: this.getFilterValue(),
            });
          }
        });
      }
      this.customReportFiltersList = "";
      this.customReportFiltersList = tempCustomReportList;
      this.filterValue =
        this.filterType === CustomReportFilterType.Dropdown ||
          this.filterType === CustomReportFilterType.MultiSelectDropdown
          ? this.filterDropdownList.values.length > 0
            ? this.filterDropdownList.values[0].value
            : ""
          : "";
      this.filterValueToDate = undefined;
    }


    if (this.isSSRState) {
    } else {
      this.filterValue =
        this.filterType === CustomReportFilterType.Dropdown
          ? (this.filterValue = this.filterDropdownList.values[0].value)
          : "";
      }
  };

  @action previewReport = async (value: boolean) => {
    const data = {
      reportId: 0,
      createdByUserId: UserContext.getUserId(),
      reportColumnModel:
        (await this.getGenerateFilters(this.customReportFiltersList)) ||
        undefined,
      };
    if (data.reportColumnModel.length > 0) {
      const res = await ReportStore.previewReport(data);

      // below code custom filter reformat
      setTimeout(async () => {
        await this.reformatCustomFilters();
      }, 300);

      this.customReportFiltersList.forEach((item: any) => {
        item.displayOrder =
          this.columnReorderPositions.length > 0
            ? this.columnReorderPositions.indexOf(item.apiParam) == -1
              ? this.columnReorderPositions.length + 1
              : this.columnReorderPositions.indexOf(item.apiParam) + 1
            : item.displayOrder;
      });

      const temp = this.customReportFiltersList;
      this.customReportFiltersList = [];
      this.customReportFiltersList = temp.sort((a: any, b: any) =>
        a.displayOrder > b.displayOrder ? 1 : -1
      );

      const dataTableHeadersTemp: any[] = [];
      this.customReportFiltersList.map((itm: any, idx: any) => {
        dataTableHeadersTemp.push({
          field: itm.apiParam.trim(),
          header: itm.label,
          status: itm.status,
        });
      });

      this.dataTableHeaders = dataTableHeadersTemp;
      this.dataTableData = [];
      this.dataTableData = res && res;
      this.reportPreview = value;
    }
  };

  @action getReportById = async () => {
    const result = await ReportStore.getReportById(this.selectedId);
    if (result) {
      this.customReportDescription = result.description || "";
      this.customReportName = result.name;
      this.title = result.name;
      this.createdBy = result.createdBy || "";
      this.createdByUserId = result.createdByUserId || 0;
      this.reportIsPrivate = result.reportStatusId === 1;
      this.selectedPrivacyStatus = "" + result.reportStatusId || "1";
      this.reportTreeData = result.entityDefinitionTreeModel;
      if (
        result.entityDefinitionTreeModel &&
        result.entityDefinitionTreeModel.length > 0
      ) {
        this.reportTreeData = "";
        this.reportTreeData = result.entityDefinitionTreeModel;
        await this.expandTreeNode(this.reportTreeData);
      }
      this.filterTypeList.values = [];
      if (result.reportColumns && result.reportColumns.length > 0) {
        const temp: any = [];
        result.reportColumns.map(async (i: any) => {
          temp.push({
            key: i.key,
            id: i.id,
            label: i.label,
            apiParam: i.apiParam.trim(),
            status: i.isDisabled ? 2 : 1,
            entityColumnId: i.entityColumnId,
            filterType: i.filterType,
            columnFilters: i.columnFilters,
            filtersApplied: i.filtersApplied,
            filterValues: i.filterValues,
            displayOrder: i.displayOrder,
            isCustomAttribute: i.isCustomAttribute,
            custAttEntityColumnId: i.isCustomAttribute ? (i.custAttEntityColumnId ? i.custAttEntityColumnId : i.entityColumnId) : 0,
            custAttEntityDefinitionId: i.isCustomAttribute && i.custAttEntityDefinitionId ? i.custAttEntityDefinitionId : 0,
          });
          if (i.filterType !== CustomReportFilterType.None) {
            this.filterTypeList.values.push({
              label: i.label,
              value: "" + i.entityColumnId || "" + i.custAttEntityColumnId,
            });
          }
        });
        if (temp.length > 0) {
          await this.setFilterDropdowns(temp[0]);
        }
        this.customReportFiltersList = "";
        this.customReportFiltersList = temp.sort((a: any, b: any) =>
          a.displayOrder > b.displayOrder ? 1 : -1
        );
        await this.previewReport(this.reportPreview);
        if (this.isPreview) {
          this.activeIndex = 0;
        } else {
          this.activeIndex = 1;
        }
      }
    }
  };

  @action getFilters = async (data: any) => {
    const filters = data;
    if (filters) {
      filters.forEach((i: any) => {
        i.id = i.id.toString().includes("cv") ? 0 : +i.id;
      });
    }
    return filters;
  };

  @action reformatCustomFilters = async () => {
    let count = 0;
    this.customReportFiltersList.map(async (i: any) => {
      if (i.columnFilters.length > 0) {
        i.columnFilters.forEach((i: any, inx: any) => {
          i.id =
            i.id === 0
              ? count + "cv" + new Date().getMilliseconds()
              : "" + i.id;
          count = count + 1;
        });
      }
    });
  };

  @action setClearAllConfirm = (value: boolean) => {
    this.isClearAllConfirm = value;
    this.isClearAll = false;
  };

  @action cloneCustomReport = async (dto: ReportCloneRequest) => {
    try {
      const result = await ReportStore.cloneReport(this.selectedId, dto);
      this.CustomReportDetailsAddEditViewModel.isException = false;
      localStorage.setItem("showCloneToast", this.customReportName);
      await this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
        false
      );
      routes.previewCustomReport.push({ id: result });
    } catch (e: any) { }
  };
  @action cloneCustomReportDialog = async (value: boolean) => {
    this.CustomReportDetailsAddEditViewModel.isException = false;
    this.CustomReportDetailsAddEditViewModel.reportName =
      this.customReportName + " (Clone)";
    this.customReportTitle = CustomReportModalType.Clone;
    await this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
      true
    );
  };

  @action setDeleteConfirm = (value: boolean) => {
    this.isDeleteConfirm = value;
  };

  @action deleteCustomReport = async () => {
    if (this.selectedId !== 0) {
      await ReportStore.deleteReport(this.selectedId);
    }
    this.setDeleteConfirm(false);
    setTimeout(async () => {
      localStorage.setItem("showDeleteToast", this.title);
      this.goToReports();
    }, Utils.timeDelay_Delete());
  };

  @action setDiscardConfirm = (value: boolean) => {
    this.isDiscardConfirm = value;
  };

  @action discardReport = async () => {
    await this.reset();
    await this.setDiscardConfirm(false);
    await this.goToReports();
  };

  @action generateReport = async () => {
    const enabledFiltersCount = this.customReportFiltersList.filter(
      (item: any) => item.status === 1
    );
    if (enabledFiltersCount.length > 0) {
      await this.generateCustomReport(this.customReportFiltersList);
    } else {
      await this.setGenerateConfirmDialog(true);
    }
  };

  @action setGenerateConfirmDialog = async (value: boolean) => {
    this.generateConfirm = value;
  };

  @action generateCustomReport = async (filters: any) => {
    googleTagManager.trackAction("custom_report_created", { feature:routes.addCustomReport.name, user_id: UserContext.userId, agent_id:0 });
   this.isShowCreateToast = false;
    this.showGenerateToast = false;
    this.showCloneToast = false;
    await this.setGenerateConfirmDialog(false);
    const entityName = this.reportTypeList.values.filter(
      (i: any) => i.value === this.selectedReportType
    );
    const dto: ReportPreviewRequest = {
      reportId: this.selectedId || 0,
      reportName:
        this.selectedId === 0 ? "Untitled Report" : this.customReportName,
      entityName:
        this.selectedId === 0 && entityName.length > 0
          ? entityName[0].label
          : undefined,
      createdByUserId: UserContext.getUserId(),
      reportColumnModel: !this.isPreview
        ? await this.getGenerateFilters(filters)
        : undefined,
    };
    await ReportStore.downloadCustomReport(dto.reportId || 0, dto);
    setTimeout(async () => {
      this.showGenerateToast = true;
      await this.reformatCustomFilters();
    }, 300);
  };

    @action getGenerateFilters = async (data: any) => {
        var reportCustom = this.reportTreeData;
        if (reportCustom) {
            this.custAttEntityDefinitionId = reportCustom.find((x: any) => x.key.toLowerCase() === "custom attributes") || 0;

            if (this.custAttEntityDefinitionId == undefined || this.custAttEntityDefinitionId == 0) {
                this.custAttEntityDefinitionId = reportCustom.find((x: any) => x.key.toLowerCase() === "agent custom attributes") || (reportCustom.find((x: any) => x.key.toLowerCase() === "contracts custom attributes") || 0);
            }
        }
    const dto: any = [];
    let count: number = 1;
    const tempFilterList = data;
    if (tempFilterList.length > 0) {
      tempFilterList.map(async (i: any) => {
        dto.push({
          entityColumnId: i.entityColumnId,
          displayOrder:
            this.columnReorderPositions.length > 0
              ? this.columnReorderPositions.indexOf(i.apiParam) == -1
                ? this.columnReorderPositions.length + 1
                : this.columnReorderPositions.indexOf(i.apiParam) + 1
              : i.displayOrder,
          disabled: i.status === 2,
          columnFilters:
            i.columnFilters && i.columnFilters.length > 0
              ? await this.getFilters(i.columnFilters)
              : [],
            isCustomAttribute: i.isCustomAttribute,
            custAttEntityColumnId: i.isCustomAttribute ? (i.custAttEntityColumnId ? i.custAttEntityColumnId : i.entityColumnId) : 0,
            custAttEntityDefinitionId: i.isCustomAttribute && this.custAttEntityDefinitionId && this.custAttEntityDefinitionId.entityDefinitionId ? this.custAttEntityDefinitionId.entityDefinitionId : (i.custAttEntityDefinitionId ? i.custAttEntityDefinitionId : 0),
        });
        count = count + 1;
      });
    }

    return dto;
  };

  @action loadReportStatusDropdowns = async () => {
    const result = await ReportStore.getAllReportStatus();
    if (result) {
      Utils.mapListItemsToDropdown(result, this.privacyStatusList, "", "");
    }
  };

  @action setExceptionMessage = async () => {
    const inValidResponse = {
      status: 400,
      title: "You can not make public report as private",
      errors: { "": [] },
    };
    PageContext.setIsMessageVisible(true);
    PageContext.setResponseMessage(
      JSON.stringify(inValidResponse),
      false,
      true
    );
  };

  @action onFieldChange = (e: any) => {
    if (e.target) {
      //Calendar Control
      (<any>this)[e.target.id] = e.target.value;
    } else {
      //All Input Fields
      (<any>this)[e.currentTarget.id] = e.currentTarget.value;
    }
  };

  @action onChangePrivacyStatus = async (value: string) => {
    this.selectedPrivacyStatus = value;
    if (value === "1") {
      if (!this.reportIsPrivate) {
        this.canChangeToPrivateReport = false;
        this.CustomReportDetailsAddEditViewModel.isException = false;
        await this.setExceptionMessage();
      }
    } else {
      this.canChangeToPrivateReport = true;
      PageContext.setIsMessageVisible(false);
    }
  };

  private getFilterValue() {
    if (
      this.selectedFilterCondition === "Is Empty" ||
      this.selectedFilterCondition === "Is Not Empty" ||
      this.selectedFilterCondition === "Less Than" ||
      this.selectedFilterCondition === "Less Than or Equal" ||
      this.selectedFilterCondition === "Greater Than" ||
      this.selectedFilterCondition === "Greater than or Equal" ||
      (this.selectedFilterCondition === "Equals" && this.filterType !== CustomReportFilterType.DatePicker) ||
      (this.selectedFilterCondition === "Not Equals" && this.filterType !== CustomReportFilterType.DatePicker)
    ) {
      return this.filterValue + "" + this.filterValue2;
    }

    if (
      this.selectedFilterCondition === "Between" &&
      this.filterType === CustomReportFilterType.DatePicker
    ) {
      return (
        Utils.getDateInFormat(this.filterValue) +
        " AND " +
        Utils.getDateInFormat(this.filterValueToDate)
      );
    } else if (this.filterType === CustomReportFilterType.DatePicker) {
      return Utils.getDateInFormat(this.filterValue);
    } else if (
      this.filterType === CustomReportFilterType.TextBoxWithCondition ||
      (this.filterType === CustomReportFilterType.TextBoxWithRanges &&
        (this.selectedFilterCondition === "Between" ||
          this.selectedFilterCondition === "Not Between"))
    ) {
      return this.filterValue + " AND " + this.filterValue2;
    } else {
      return this.filterValue;
    }
  }

  @action stateChange(value: any) {
    let stateObj = this.stateDropdownList.filter(
      (item: any) => item.value == value
    );
    this.selectedState = stateObj[0].label;
    this.selectedStateValue = value;
  }

  @action loadAppointmentStates = async () => {
    var result = await ContractsStore.appointmentStates();
    if (result != null) {
      if (result) {
        this.stateDropdownList = [];
        this.stateDropdownList.push({
          label: "None",
          value: "",
          abbreviation: "",
        });
        var sortStateList = [...result].sort(Utils.compareListItem);
        for (let item of sortStateList) {
          this.stateDropdownList.push({
            label: "" + item.stateName,
            value: "" + item.abbreviation,
            abbreviation: item.stateName,
            isHeader: item.isHeader,
          });
        }
      }
    }
  };

  @action autoCompleteChange = (value: string) => {
    this.autoCompleteValue = value;
  };

  @action onSelectAutoComplete = (value: any) => {
    this.autoCompleteValue = value.text;
  };
 
  @action searchAutoComplete = async (value: string) => {
    setTimeout(async () => {
      if (value != null && value.length >= 3) {
        let noAttribute: any = [];
        
        let result = await ReportStore.getFilterValues(
          this.selectedEntityColumnId,
          this.autoCompleteValue
        );
        if (result !== null) {
          if (result) {
            this.FilteredAutoCompleteList.values = result;
          }
        } else {
          this.FilteredAutoCompleteList.values = [noAttribute];
          this.autoCompleteValue = "";
        }
        this.isLoading = false;
      }
    }, 250);
  };
  
  @action loadUsers = async () => {
    let result:any; 
      result = await ReportStore.getFilterGridValues(
        this.selectedEntityColumnId,
        ""+this.selectedSsrId);
     
      
      if (result !== undefined && result !== null && result != null && result != undefined && result.length > 0) {
          result.splice(0, 0, { id: -1 });
          this.ssrIdList.values = result;

      } else {
          var noUser: UserLookupListModel = { id: 0 };
          this.ssrIdList.values = [noUser];
      }
  }
  
}
