import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { agentGroup, getCurrentRoute, routes } from "../../router";
import { Route } from "type-route";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";

import { AgentsViewModel } from "./AgentsViewModel";
import { AgentDetailsTabViewModel } from "./AgentDetailsTabViewModel";
import { AddAgentViewModel } from "./AddAgentViewModel";
import { EditAgentViewModel } from "./EditAgentViewModel";
import { MergeAgentsViewModel } from "../customers/MergeAgentsViewModel";
import { AgentSearchByMapViewModel } from "../maps/AgentSearchByMapViewModel";
import {EmailViewModel } from "../emails/EmailViewModel";
import { getToken } from "../../infrastructure/Auth0Authenticator";
import { TopHeaderSearchViewModel } from "../TopHeaderSearchViewModel";
import PageContext from "../../infrastructure/PageContext";
import { AgentsGroupSummaryViewModel } from "./AgentsGroupSummaryViewModel";
import { AppInlineProfileViewModel } from "./../AppInlineProfileViewModel";
import Utils from "../../infrastructure/Utils";

export class AgentsDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable AgentsViewModel: AgentsViewModel = new AgentsViewModel();
  @observable AgentSearchByMapViewModel: AgentSearchByMapViewModel = new AgentSearchByMapViewModel();
  @observable EmailViewModel: EmailViewModel = new EmailViewModel();
  @observable AddAgentViewModel: AddAgentViewModel = new AddAgentViewModel();
  @observable AgentDetailsTabViewModel: AgentDetailsTabViewModel = new AgentDetailsTabViewModel();
  @observable EditAgentViewModel: EditAgentViewModel = new EditAgentViewModel();
  @observable TopHeaderSearchViewModel: TopHeaderSearchViewModel | undefined;
  @observable
  MergeAgentsViewModel: MergeAgentsViewModel = new MergeAgentsViewModel();
  @observable AgentsGroupSummaryViewModel:AgentsGroupSummaryViewModel=new AgentsGroupSummaryViewModel();
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action setTopHeaderViewModel(viewModel: TopHeaderSearchViewModel)
  {
    this.TopHeaderSearchViewModel = viewModel;
    this.TopHeaderSearchViewModel.Load();
  } 

  @observable AppInlineProfileViewModel: AppInlineProfileViewModel | undefined;

  @action setAppInlineProfileViewModel(viewModel: AppInlineProfileViewModel | undefined)
  {
    this.AppInlineProfileViewModel = viewModel;
  }
  @action Load = async () => {
    PageContext.setIsLoadingVisible(false);
    this.route = getCurrentRoute();
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    var routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    var agentParamId: any | undefined;
    if (
      currentRoute.name === routes.agentSearch.name 
      // || 
      // currentRoute.name === routes.home.name
    ) {
      if (!(this.SelectedViewModel instanceof AgentsViewModel)) {
        this.SelectedViewModel = this.AgentsViewModel;
      }
      localStorage.setItem('createlog', 'createLog');
      this.AgentsViewModel.setHeaderSearchViewModel(this.TopHeaderSearchViewModel);
      await this.SelectedViewModel.Load(currentRoute.params);
    }
    if(currentRoute.name === routes.mapsView.name)
    {
      if (!(this.SelectedViewModel instanceof AgentSearchByMapViewModel)) {
        this.SelectedViewModel = this.AgentSearchByMapViewModel;
      }
      await getToken();
      await this.SelectedViewModel.Load();
      return;
    }
    if(currentRoute.name === routes.emailsView.name)
    {
      if (!(this.SelectedViewModel instanceof EmailViewModel)) {
        this.SelectedViewModel = this.EmailViewModel;
      }
      await getToken();
      await this.SelectedViewModel.Load();
      return;
    }

     if (currentRoute.name === routes.addAgent.name) {
      if (!(this.SelectedViewModel instanceof AddAgentViewModel)) {
        this.SelectedViewModel = this.AddAgentViewModel;
      }
      await this.SelectedViewModel.Load(0);
      await this.SelectedViewModel.Route(currentRoute);
    }else if (
      currentRoute.name === routes.mergeAgents.name 
    ) {
      if (!(this.SelectedViewModel instanceof MergeAgentsViewModel)) {
        this.SelectedViewModel = this.MergeAgentsViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }
    else if (agentGroup.has(currentRoute)) {
      agentParamId = routeParamObj.agentId;
      RouteParamDetails.id = +agentParamId;
      RouteParamDetails.policyId = routeParamObj.policyId;
      RouteParamDetails.contractId = routeParamObj.contractId;
      RouteParamDetails.noteId = routeParamObj.noteId;
      RouteParamDetails.taskId = routeParamObj.taskId;
      RouteParamDetails.paymentPlanId = routeParamObj.paymentPlanId;
      RouteParamDetails.debtId = routeParamObj.debtId;
      RouteParamDetails.levelId = routeParamObj.levelId;
      if (!(this.SelectedViewModel instanceof AgentDetailsTabViewModel)) {
        this.SelectedViewModel = this.AgentDetailsTabViewModel;
      }
      this.AgentDetailsTabViewModel.resetView();
      this.AgentDetailsTabViewModel.setAppInlineProfileViewModel(this.AppInlineProfileViewModel);
      await this.SelectedViewModel.Load('' + agentParamId);
      await this.SelectedViewModel.Route(currentRoute);
    }
    else if (currentRoute.name === routes.editAgent.name) {
      var id = routeParamObj.agentId;
      if (!(this.SelectedViewModel instanceof EditAgentViewModel)) {
        this.SelectedViewModel = this.EditAgentViewModel;
      }
      await this.SelectedViewModel.Load(id);
      await this.SelectedViewModel.Route(currentRoute);

    }
    else if (currentRoute.name === routes.agentGroupDetails.name) {
      var agentid = routeParamObj.agentId;
      var id=routeParamObj.groupId;
      if (!(this.SelectedViewModel instanceof AgentsGroupSummaryViewModel)) {
        this.SelectedViewModel = this.AgentsGroupSummaryViewModel;
      }
      await this.SelectedViewModel.Load(agentid,id);
      await this.SelectedViewModel.Route(currentRoute);
      

    }
  }
}
