import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import {
  Client as AccountingAdjustmentServiceClient,
  FileType,
} from "../services/AccountingAdjustmentService";

import { number } from "prop-types";
// import { request } from "http";-

class AccountingAdjustmentStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
    getClient = (): AccountingAdjustmentServiceClient => {
    return ServiceClient.getClient(ServiceType.AccountingAdjustment);
    };

  getHostname = () => {
    return null;
  };

    @action turnInReport = async (
        agentId?: number | undefined,
        startDate?: Date,
        endDate?: Date,
        fileType?: StatementFileType
    ) => {

        var result = await this.getClient().getTurnInReport(
            this.getApiVersion(),
            agentId,
            startDate,
            endDate,
            fileType,
            UserContext.getUserId()
        );

        return result;
    };


    @action getMonthlyReport = async (
        agentId?: number | undefined,
        startDate?: any,
        endDate?: any,
        fileType?: any
    ) => {

        var result = await this.getClient().getMonthlyReport(
            this.getApiVersion(),
            agentId,
            startDate,
            endDate,
            fileType,
            UserContext.getUserId()
        );

        return result;
    };

  @action searchAccountingAdjustmentsOfPolicy = async (policyNumber: number | undefined, pageIndex: number,pageSize: number) => {
    return await this.getClient().searchAccountingAdjustmentsOfPolicy(this.getApiVersion(), policyNumber, UserContext.getUserId());
     
   }
   
  @action getAccountingAdjustmentTypeLookup = async (impersonatedUid: number | undefined) => {
    return await this.getClient().getAccountingAdjustmentTypeLookup(this.getApiVersion(), impersonatedUid);
  }

  @action getAccountingAdjustmentReasons = async (impersonatedUid: number | undefined, type: number) => {
    return await this.getClient().getAccountingAdjustmentReasons(this.getApiVersion(), impersonatedUid, type);
  }
  
  @action getAgentsByPolicyIDLookup = async (policyNumber: number | undefined) => {
    return await this.getClient().getAgentsByPolicyIDLookup(this.getApiVersion(), policyNumber);
  }
  
  @action createAccountingAdjustment = async ( agentId?: number | undefined, policyId?: number | undefined, transactionAmount?: number | undefined, transactionDate?: Date | undefined, accountingAdjustmentTypeId?: number | undefined, accountingAdjustmentReasonId?: number | undefined, transactionNote?: string | null | undefined, impersonated_User?: any | undefined) => {
    return await this.getClient().createAccountingAdjustment(this.getApiVersion(), agentId, policyId, transactionAmount, transactionDate, accountingAdjustmentTypeId, accountingAdjustmentReasonId, transactionNote, impersonated_User);
  }
  @action reverseAccountingAdjustment = async (type : any, impersonatedUid: number | undefined) => {
    return await this.getClient().reverseAccountingAdjustment(this.getApiVersion(), type, impersonatedUid);
  }

}

export enum StatementFileType {
    _0 = 0,
    _1 = 1,
}

export default new AccountingAdjustmentStore();
