import { action, observable } from "mobx";
import { Route } from "type-route";
import { PageMode, CarrierManagementTabs,CarrierManagement } from "../../../infrastructure/enum/Common";
import RouteList from "../../../infrastructure/RouteList";
import ViewModel from "../../../infrastructure/ViewModel";
import { CarrierManagementViewModel } from "./CarrierManagementViewModel";
import { CarrierCMAddEditViewModel } from "../carrierManagement/carrier/CarrierCMAddEditViewModel";
import { CarrierGroupCMListViewModel } from "../carrierManagement/carriergroup/CarrierGroupCMListViewModel";
import {
  getCurrentRoute,
  routes,
  carrierManagementGroup,
} from "../../../router"; 
import { CarrierAddEditViewModel } from "../../../viewModels/administration/contract/carriers/CarrierAddEditViewModel";
import {CarrierCMListViewModel} from "../carrierManagement/carrier/CarrierCMListViewModel"
export class CarrierCMDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined; 
  @observable CarrierManagementViewModel: CarrierManagementViewModel =
    new CarrierManagementViewModel(); 
  @observable CarrierCMListViewModel: CarrierCMListViewModel =
    new CarrierCMListViewModel();
  @observable CarrierCMAddEditViewModel: CarrierCMAddEditViewModel =
    new CarrierCMAddEditViewModel(); 
    
  @observable CarrierGroupCMListViewModel: CarrierGroupCMListViewModel =
  new CarrierGroupCMListViewModel(); 
    
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    var routeParams = JSON.parse(JSON.stringify(currentRoute.params));
    //   currentRoute.name === routes.addCarrierManagement.name ||
    //   currentRoute.name === routes.editCarrierManagement.name ||
    //   currentRoute.name === routes.previewCarrierManagement.name
    // if (carrierManagementGroup.has(currentRoute)) {
    //   if (!(this.SelectedViewModel instanceof CarrierManagementViewModel)) {
    //     this.SelectedViewModel = this.CarrierManagementViewModel;
    //   }
    //   await this.SelectedViewModel.Load();
    //   await this.SelectedViewModel.Route(currentRoute);
    // } else 
    if (currentRoute.name === routes.addCarrierManagement.name || 
      currentRoute.name === routes.editCarrierManagement.name ||  
      currentRoute.name === routes.previewCarrierManagement.name) {
      
        if (!(this.SelectedViewModel instanceof CarrierCMAddEditViewModel)) {
        this.SelectedViewModel = this.CarrierCMAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } 
    if(currentRoute.name === routes.carrierManagement.name || 
      currentRoute.name === routes.carrierCMList.name ||
      currentRoute.name === routes.carrierGroupCMList.name  ){
          if (!(this.SelectedViewModel instanceof CarrierManagementViewModel)) {
            this.CarrierManagementViewModel.SelectedViewModel =  CarrierManagementTabs.Carrier;
        this.SelectedViewModel = this.CarrierManagementViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }  
  };
}
