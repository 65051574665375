import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import ContractsStore from "../../../../store/ContractsStore";
import Collection from "../../../../infrastructure/CollectionHelper";

export class WritingNumberListViewModel implements ViewModel  {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable wrnList = new Collection<any>();
  @observable wrnrows: number = 10;
  @observable wrntotalRecords: number | undefined = 0;
  @observable wrnfirst: number = 0;
  @observable wrnstartIndex: number = 0;
  @observable pageTitle: string = "Writing Number Defaults";
  @observable sortColumn: string = 'licenseNumberType';
  @observable sortOrder: boolean = true;

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.wrnstartIndex = 0;
    this.wrnfirst = 0;
  }

  @action Load = async (companyId: any) => {
    var result = await ContractsStore.getAllCompanyLicenseNumberDefaults(companyId, 0, 1000, this.sortColumn, this.sortOrder)
    if(result && result.data){
      this.wrntotalRecords = result.recordCount;
      this.wrnList.values = result.data
    }
    };

    @action reset() {
        this.wrntotalRecords = 0;
        this.wrnList = new Collection<any>();
    }
  Route = async (currentRoute: RouteList): Promise<void> => { };
}