import { action } from "mobx";
// import apiClient from "../infrastructure/client/ReportClient";
import { getToken } from "./Auth0Authenticator";

const reportTypes = [
  { label: "Agent / Agency Contact and Demographics", value: "27" },
  { label: "Agent Debt", value: "0" },
  { label: "Agent / Agency Details", value: "22" },
  { label: "Agents Without Notes (30, 60, 90 Days)", value: "2" },
  { label: "Carrier Updates", value: "3" },
  { label: "Commission", value: "4" },
  { label: "Commission Break-Down", value: "26" },
  { label: "Contracted Agent List", value: "1" },
  { label: "Contract Details", value: "24" },
  { label: "Contract Invite", value: "5" },
  { label: "Contracting Sent", value: "6" },
  { label: "Contracting Sent - No FUP in 30 days", value: "7" },
  { label: "Contracts by Marketer", value: "8" },
  { label: "Contracts without Business", value: "9" },
  { label: "Downline Agents", value: "10" },
  { label: "Fax Confirmation", value: "11" },
  { label: "Contract Count by Carrier", value: "12" },
  { label: "Marketing Event", value: "13" },
  { label: "Marketer Production", value: "14" },
  { label: "On Hold Agent Commissions", value: "15" },
  { label: "Orders by Division (Shipping Expenses)", value: "16" },
  { label: "Policies Info", value: "17" },
  { label: "Policy Summary", value: "18" },
  { label: "Production", value: "19" },
  { label: "Production Summary", value: "20" },
  { label: "Incentive Program", value: "21" },  
    { label: "State Licenses", value: "25" }
    //  { label: "Turn-in Report", value: "Turninreport" },
    //{ label: "Monthly Commission Statement", value: "Mcsreport" }
];


const monthList = [
    { label: "Janaury", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" }
];
const reportAddresses = [
  { label: "All", value: "1" },
  { label: "Default", value: "0" },
]

const AgentAddressTypes = [
    { label: "Home / Residence", value: "Home" },
    { label: "Business Office", value: "Business Office" },
    { label: "Post Office", value: "Post Office" },
    { label: "Custom", value: "Custom" }
]

const AgentContactTypes = [
  { label: "Email", value: "1" },
  { label: "Phone Number", value: "3" },
  { label: "Alternative Point of Contact", value: "4" }
  
  // { label: "Text Message", value: "2" }
]

const SMSEnabledTypes = [
  { label: "Yes", value: "1" },
  { label: "No", value: "2" },
  { label: "N/A", value: "0" }
]

const reportContactTypes = [
  { label: "All", value: "1" },
  { label: "Default", value: "0" },
  { label: "Commission", value: "2" },
]
const reportEOTypes = [
  { label: "All", value: "1" },
  { label: "Yes", value: "0" },
  { label: "No", value: "2" },
]
 
const reportPrincipalAgentStatusTypes = [
  { label: "All", value: "1" },
  { label: "Yes", value: "0" },
  { label: "No", value: "2" },
  { label: "N/A", value: "3" },
]


const whichDateList = [
  { label: "Date Effective", value: "DateEffective" },
  { label: "Original Effective Date", value: "OriginalDateEffective" },
  { label: "Date Posted", value: "DatePosted" },
  { label: "Date Entered", value: "DateEntered" },
  { label: "Date Sold", value: "DateSold" },
];

const commissionDateList = [
    { label: "Pay Date", value: "PayDate" }
];


const companyList = [
  { label: "Accendo Insurance Company", value: "1" },
  { label: "Aetna", value: "2" },
  { label: "Allianz", value: "3" },
];

const notesDaysList = [
  { label: "30", value: "30" },
  { label: "60", value: "60" },
  { label: "90", value: "90" },
];

class ReportStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    let token = await getToken();
    return token ? `Bearer ${token}` : "";
  };

  @action getReportTypes = async () => {
    let result = reportTypes;
    return result;
    };

    @action getMonthList = async () => {
        let result = monthList;
        return result;
    };

  @action getWhichDateEntered = async () => {
    let result = whichDateList;
    return result;
    };

    @action getCommissionDate = async () => {
        let result = commissionDateList;
        return result;
    };

  @action getAddressTypes = async () => {
    let result = reportAddresses;
    return result;
  }
  @action getAgentAddressTypes = async () => {
    let result = AgentAddressTypes;
    return result;
  }
  @action getAgentContactTypes = async () => {
    let result = AgentContactTypes;
    return result;
  }

  @action getContactTypes = async () => {
    let result = reportContactTypes;
    return result;
  }
  @action getSMSEnabled = async () => {
    let result = SMSEnabledTypes;
    return result;
  }
  @action getEOTypes = async () => {
    let result = reportEOTypes;
    return result;
  }

  @action getPrincipalAgentStatusTypes = async () => {
    let result = reportPrincipalAgentStatusTypes;
    return result;
  }

  @action getProductList = async () => {
    let result = companyList;
    return result;
  };

  @action getNotesInXDaysList = async () => {
    let result = notesDaysList;
    return result;
  };

}

export enum Reports {
  StandardReports = "StandardReports",
  CustomReports = "CustomReports",
  Queue = "Queue"
}

export enum ReportTabs {
  StandardReports = "StandardReports",
  CustomReports = "CustomReports",
  Queue = "Queue"
}

export default new ReportStore();
