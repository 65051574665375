import React from "react";
import { CustomDialog } from "../../../components/CustomDialog/CustomDialog";
import "./emailBodyModal.css";
import Banner from "../Banner";

interface Props {
  onClose: Function;
  isOpen: boolean;
  body?: string;
  subject?: string;
}

const hasLinks = (htmlString: string) => {
  const doc = document.createElement("html");
  doc.innerHTML = htmlString;
  const links = doc.getElementsByTagName("a");
  return links && links.length > 0;
};

const removeLinks = (htmlString: string) => {
  const doc = document.createElement("html");
  doc.innerHTML = htmlString;
  const links = doc.getElementsByTagName("a");
  for (const link of links) {
    link.removeAttribute("href");
  }
  return doc.innerHTML;
};

const EmailBodyModal = ({
  onClose,
  isOpen,
  body = "",
  subject = " ",
}: Props) => {
  return (
    <CustomDialog
      customClassName="template-test-modal"
      isDialogShown={isOpen}
      title={subject}
      onClose={() => onClose()}
    >
      <div className="email_body_modal_container">
        {hasLinks(body) && (
          <Banner type="primary">Preview only. Links are not clickable.</Banner>
        )}
        <div className="email_body_modal_content email-logs-content">
          <div dangerouslySetInnerHTML={{ __html: removeLinks(body) }} />
        </div>
      </div>
    </CustomDialog>
  );
};

export default EmailBodyModal;
