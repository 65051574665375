import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { routes } from "../../router";
import LeadStore from "../../store/LeadStore";
import { Constants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Collection from "../../infrastructure/CollectionHelper";
import { AgentListModel } from "../../services/ProducerSearchService";
import ContractsStore from "../../store/ContractsStore";
import Utils from "../../infrastructure/Utils";
import {
  ApproveEmailPhoneModel,
  CreateMarketerLeadNotesModel,
  MarketerLeadDetailsResponse,
  MarketerLeadNotesResponse,
} from "../../services/LeadService";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import UserContext from "../../infrastructure/UserContext";
import { Permission } from "../../infrastructure/enum/Permission";

export class NonContractedAgentLeadDetailViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (id: number) => {
    let delay = 0;
    if(UserContext.permissions.length <= 0)
        {
            delay = 2000;
        }
        setTimeout (async () => {
          this.leadId = id;
          this.getLeadDetail(this.leadId);
          this.getContractingSentForList();
          if(UserContext.permissions.length > 0 && !Utils.hasUserPermission(Permission.ViewLeads)) {
            this.hasPermission = false;
        }
        else{
          this.hasPermission = true;
        }
        }, delay);
 
  };

  Route = async (): Promise<void> => { };
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable isNotesVisible: boolean = false;
  @observable notesVisibleResponse: any;
  @observable leadId: number = 0;
  @observable leadDetail: MarketerLeadDetailsResponse = {};
  @observable leadLogs: MarketerLeadNotesResponse[] = [];
  @observable isAddNoteDialogVisible: boolean = false;
  @observable notes: string = "";
  @observable isPrivateNote: boolean = false;
  @observable isSendNoteAsEmail: boolean = false;
  @observable contractingSentFor: string = "0";
  @observable level: string = "0";
  @observable upline: string = "";
  @observable uplineId: number = 0;
  @observable isLevelRequired: boolean = false;
  @observable hasPermission: boolean = true;
  @observable contractingSentForList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable levelList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable FilteredUplineList = new Collection<AgentListModel>();
  public ErrorModel = new ErrorModel(new NoteValidator());
  @observable actionItems: MenuItem[] = [
    {
      label: "Call",
      icon: "fa fa-phone icon-fill-color",
      command: () => {
        this.callOnAgentPhoneNumber(
          this.leadDetail.agentPhone ? Number(this.leadDetail.agentPhone) : 0
        );
      },
    },
    {
      label: "Add Note",
      icon: "fa fa-plus icon-fill-color",
      command: () => {
        this.setAddNoteDialogVisible(true);
      },
    },
    {
      label: "Edit Lead",
      icon: "fa fa fa-edit icon-fill-color",
      command: () => {
        setTimeout(() => {
          this.editLead(this.leadId);
        }, 300);
      },
    },
  ];
  @action getContractingSentForList = async () => {
    this.contractingSentForList.values = [];
    var defaultItem = { label: "--Select--", value: "0" };
    let res = await ContractsStore.getAllCompanyLookup();
    if (res) {
      res.forEach((obj: any) => {
        var data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value.toString() : "",
        };

        this.contractingSentForList.values.push(data);
      });
      this.contractingSentForList.values.splice(0, 0, defaultItem);
      this.contractingSentFor = "0";
    } else {
      this.contractingSentForList.values = [];
      this.contractingSentForList.values.splice(0, 0, defaultItem);
      this.contractingSentFor = "0";
    }
  };

  @action getLevelsList = async (carrierId: number) => {
    this.levelList.values = [];
    var defaultItem = { label: "None", value: "0" };
    let res = await LeadStore.marketerLeadPayoutLevel(carrierId);
    if (res) {
      var output: any = [];
      output = res;
      for (let item of output) {
        this.levelList.values.push({
          label: "" + item.payoutLevelName,
          value: "" + item.payoutLevelId,
        });
      }
      this.levelList.values.splice(0, 0, defaultItem);
      this.level = "0";
    } else {
      this.levelList.values = [];
      this.levelList.values.splice(0, 0, defaultItem);
      this.level = "0";
    }
  };
  @action setAddNoteDialogVisible = async (value: boolean) => {
    if (value === false) {
      this.resetFormFields();
    } else {
        this.upline = this.leadDetail.agentName != null ? this.leadDetail.agentName.toString() : "";
        this.uplineId = this.leadDetail.agentId ? this.leadDetail.agentId : 0;
    }

    this.isAddNoteDialogVisible = value;
  };
  @action setNotes(value: string) {
    this.notes = value;
  }
  @action onBackClick() {
    Utils.setLeadsTab(0); // 0 for NonContracted
    routes.leads.push();
  }
  @action setIsPrivateNote(value: boolean) {
    this.isPrivateNote = value;
  }
  @action setIsSendNoteAsEmail(value: boolean) {
    this.isSendNoteAsEmail = value;
  }
  @action setContractingSentFor(value: string) {
    this.contractingSentFor = value;
    this.getLevelsList(parseInt(value));
  }
  @action setLevel(value: string) {
    this.level = value;
  }
  @action setUpline = (value: string) => {
    this.upline = value;
  };
  @action setUplineId = (value: number) => {
    this.uplineId = value;
  };
  @action fillterUsers = async (value: string) => {
    try {
      let result = await ProducerSearchStore.getAgentsLookup(
        1,
        value,
        0,
        100,
        undefined,
        undefined
      );
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            this.FilteredUplineList.values = result.data;
          }
        }
      }
    } catch (e) {
      var noAgent: AgentListModel = { id: 0 };
      this.FilteredUplineList.values = [noAgent];
    }
  };

  @action customValidation() {
    let isValid: boolean = true;
    if (this.level === "0" && this.contractingSentFor !== "0") {
      isValid = false;
      this.isLevelRequired = true;
    } else {
      this.isLevelRequired = false;
    }
    return isValid;
  }
  @action saveNotes = async () => {
    await this.ResetValidate();
    var isValid = this.customValidation();
    if (!(await this.Validate())) {
      var dto = this.createModel();
      if (isValid) {
        this.addNote(dto);
      }
    }
  };
  @action createModel() {
    var dto: CreateMarketerLeadNotesModel = {
      note: this.notes,
      contractingCompanyId: this.contractingSentFor ? parseInt(this.contractingSentFor) : 0,
      payoutLevelId: this.level ? parseInt(this.level) : 0,
      private: this.isPrivateNote,
      sendNoteEmail: this.isSendNoteAsEmail,
      agentLeadId: this.leadId,
      agentId: this.leadDetail.agentId 
    };
    return dto;
  }

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action addNote = async (dto: CreateMarketerLeadNotesModel) => {

    this.isMessgeVisible = false;
    this.response = {};
    await LeadStore.addNote(dto);
    this.resetFormFields();
    this.isAddNoteDialogVisible = false;
    if (this.leadDetail.agentId) {
      this.getLeadLogs(this.leadDetail.agentId);
    }

    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = {};
    }, Utils.timeDelay_Success());

  };
  @action resetFormFields() {
    this.contractingSentFor = "0";
    this.level = "0";
    this.uplineId = 0;
    this.upline = "";
    this.isPrivateNote = false;
    this.isSendNoteAsEmail = false;
    this.isLevelRequired = false;
    this.notes = "";
    this.levelList.values = [];
    this.ResetValidate();
  }

  @action callOnAgentPhoneNumber = (phoneNumber: number) => {
    setTimeout(() => {
      var link = document.createElement("a");
      link.href = "tel:+" + phoneNumber;
      link.click();
    }, 0);
  };
  @action editLead = async (id: number) => {
    routes.editAgentLead.push({ AgentLeadId: id });
  };

  @action getLeadDetail = async (leadId: number) => {
    try {
      var result = await LeadStore.getLeadDetail(leadId);
      if (result != null) {
        if (result) {
          this.leadDetail = result;
          if (this.leadDetail.agentId) {
            this.getLeadLogs(this.leadDetail.agentId);
          }

        } else {
          this.leadDetail = {};
        }
      }
    } catch (e) {
      this.leadDetail = {};

    }
  };

  @action getLeadLogs = async (agentId: number) => {
    try {
      this.notesVisibleResponse = {};
      this.isNotesVisible = false;
      var result = await LeadStore.getLeadNotes(agentId);
      if (result != null) {
        var output: any = [];
        output = result;
        this.leadLogs = output;
      } else {
        this.leadLogs = [];
      }

      if (this.leadLogs.length <= 0) {
        var responseModel = {
          status: 400,
          title: Constants.NoLogsAvailable,
          errors: { "": [] },
        };
        this.notesVisibleResponse = JSON.stringify(responseModel);
        this.isNotesVisible = true;
      }

    } catch (e) {
      this.leadLogs = [];
      this.isNotesVisible = false;
      this.notesVisibleResponse = {};
    }
  };

  @action approvePhone = async () => {
    this.isMessgeVisible = false;
    this.response = {};
    let body: ApproveEmailPhoneModel = {
      contactTypeId: 3, 
      contactValue: this.leadDetail.leadPhone,
      agentId: this.leadDetail.agentId ? this.leadDetail.agentId : 0,
    };
    await LeadStore.approvePhoneEmail(body);
    var successResponse = {
      status: 200,
      title: Constants.ContactAdded,
      errors: { "": [] },
    };
    this.isMessgeVisible = true;
    this.leadDetail.canApprovePhone = false;
    this.response = JSON.stringify(successResponse);
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, Utils.timeDelay_Success());

  };

  @action approveEmail = async () => {
    this.isMessgeVisible = false;
    this.response = {};
    let body: ApproveEmailPhoneModel = {
      contactTypeId: 1, 
      contactValue: this.leadDetail.leadEmail,
      agentId: this.leadDetail.agentId ? this.leadDetail.agentId : 0,
    };
    
    await LeadStore.approvePhoneEmail(body);
    var successResponse = {
      status: 200,
      title: Constants.EmailAdded,
      errors: { "": [] },
    };
    this.isMessgeVisible = true;
    this.leadDetail.canApproveEmail = false;
    this.response = JSON.stringify(successResponse);
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, Utils.timeDelay_Success());
  };
}
class NoteValidator extends AbstractValidator<
  NonContractedAgentLeadDetailViewModel
  > {
  public constructor() {
    super();
    this.validateIfString((input) => input.notes)
      .isNotEmpty()
      .isNotEqualTo("")
      .withFailureMessage("Note is required");
  }
}
