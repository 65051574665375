import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { getCurrentRoute, routes } from "../../router";
import { Route } from "type-route";
import { LeadListViewModel } from "./LeadListViewModel";
import { AgentLeadAddViewModel } from "./AgentLeadAddViewModel";
import { AgentLeadEditViewModel } from "./AgentLeadEditViewModel";
import { NonContractedAgentLeadDetailViewModel } from "./NonContractedAgentLeadDetailViewModel";
import { ContractedAgentLeadDetailViewModel } from "./ContractedAgentLeadDetailViewModel";
import { SalesOpsAgentLeadsDetailViewModel } from "./SalesOpsAgentLeadsDetailViewModel";
import { UnmatchedLeadsDetailViewModel } from "./UnmatchedLeadsDetailViewModel";
import { SaleOpsAgentLeadAddViewModel } from "./SaleOpsAgentLeadAddViewModel";
import { SaleOpsAgentLeadEditViewModel } from "./SaleOpsAgentLeadEditViewModel";
import { UnmatchedLeadListViewModel } from "./UnmatchedLeadListViewModel";
import { SalesLeadListViewModel } from "./SalesLeadListViewModel";
import { LeadTabs } from "../../infrastructure/enum/Leads";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";

export class LeadsDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable LeadListViewModel: LeadListViewModel = new LeadListViewModel();
  @observable
  SalesLeadListViewModel: SalesLeadListViewModel = new SalesLeadListViewModel();
  @observable
  UnmatchedLeadListViewModel: UnmatchedLeadListViewModel =
    new UnmatchedLeadListViewModel();
  @observable
  AgentLeadAddViewModel: AgentLeadAddViewModel = new AgentLeadAddViewModel();
  @observable
  SaleOpsAgentLeadAddViewModel: SaleOpsAgentLeadAddViewModel =
    new SaleOpsAgentLeadAddViewModel();
  @observable
  SaleOpsAgentLeadEditViewModel: SaleOpsAgentLeadEditViewModel =
    new SaleOpsAgentLeadEditViewModel();
  @observable
  AgentLeadEditViewModel: AgentLeadEditViewModel = new AgentLeadEditViewModel();
  @observable
  NonContractedAgentLeadDetailViewModel: NonContractedAgentLeadDetailViewModel =
    new NonContractedAgentLeadDetailViewModel();
  @observable
  ContractedAgentLeadDetailViewModel: ContractedAgentLeadDetailViewModel =
    new ContractedAgentLeadDetailViewModel();

  @observable
  SalesOpsAgentLeadsDetailViewModel: SalesOpsAgentLeadsDetailViewModel =
    new SalesOpsAgentLeadsDetailViewModel();
  @observable
  UnmatchedLeadsDetailViewModel: UnmatchedLeadsDetailViewModel =
    new UnmatchedLeadsDetailViewModel();

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
  };

  @observable isMarketerLeadLoaded: boolean = false;
  @observable isSalesLeadLoaded: boolean = false;

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    var routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    if (currentRoute.name === routes.leads.name) {
      if (!(this.SelectedViewModel instanceof LeadListViewModel)) {
        this.SelectedViewModel = this.LeadListViewModel;
      }
      if (!this.isMarketerLeadLoaded) {
        this.LeadListViewModel.selectedTabIndex = 0;
        this.LeadListViewModel.setFilterForm(LeadTabs.NonContracted, false);
        this.isMarketerLeadLoaded = true;
        await this.SelectedViewModel.Load();
        await this.SelectedViewModel.Route(currentRoute);
      } else {
        await this.LeadListViewModel.goSearch();
      }
      setTimeout(async () => {
        if (
          UserContext.permissions.length > 0 &&
          !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
        ) {
          this.LeadListViewModel.hasSalesOpsPermission = false;
        } else {
          this.LeadListViewModel.hasSalesOpsPermission = true;
        }
      }, 500);
    } else if (currentRoute.name === routes.addAgentLead.name) {
      if (!(this.SelectedViewModel instanceof AgentLeadAddViewModel)) {
        this.SelectedViewModel = this.AgentLeadAddViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.addSaleOpsLead.name) {
      if (!(this.SelectedViewModel instanceof SaleOpsAgentLeadAddViewModel)) {
        this.SelectedViewModel = this.SaleOpsAgentLeadAddViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.editSaleOpsLead.name) {
      if (!(this.SelectedViewModel instanceof SaleOpsAgentLeadEditViewModel)) {
        this.SelectedViewModel = this.SaleOpsAgentLeadEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.AgentLeadId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.editAgentLead.name) {
      if (!(this.SelectedViewModel instanceof AgentLeadEditViewModel)) {
        this.SelectedViewModel = this.AgentLeadEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.AgentLeadId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.detailNonContarctedLeads.name) {
      if (
        !(
          this.SelectedViewModel instanceof
          NonContractedAgentLeadDetailViewModel
        )
      ) {
        this.SelectedViewModel = this.NonContractedAgentLeadDetailViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.AgentLeadId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.detailContarctedLeads.name) {
      if (
        !(this.SelectedViewModel instanceof ContractedAgentLeadDetailViewModel)
      ) {
        this.SelectedViewModel = this.ContractedAgentLeadDetailViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.AgentLeadId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.detailSaleOpsLeads.name) {
      if (
        !(this.SelectedViewModel instanceof SalesOpsAgentLeadsDetailViewModel)
      ) {
        this.SelectedViewModel = this.SalesOpsAgentLeadsDetailViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.AgentLeadId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.detailUnmatchedLeads.name) {
      if (!(this.SelectedViewModel instanceof UnmatchedLeadsDetailViewModel)) {
        this.SelectedViewModel = this.UnmatchedLeadsDetailViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.AgentLeadId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.salesLeads.name) {
      this.SalesLeadListViewModel.selectedTabIndex = 0;
      if (!(this.SelectedViewModel instanceof SalesLeadListViewModel)) {
        this.SelectedViewModel = this.SalesLeadListViewModel;
      }
      if (!this.isSalesLeadLoaded) {
        this.isSalesLeadLoaded = true;
        await this.SelectedViewModel.Load();
        await this.SelectedViewModel.Route(currentRoute);
      } else {
        await this.SalesLeadListViewModel.goSearch();
      }
      setTimeout(async () => {
        if (
          UserContext.permissions.length > 0 &&
          !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
        ) {
          this.SalesLeadListViewModel.hasPermission = false;
        } else {
          this.SalesLeadListViewModel.hasPermission = true;
        }
      }, 500);
    } else if (currentRoute.name === routes.unmatchedLeads.name) {
      if (!(this.SelectedViewModel instanceof UnmatchedLeadListViewModel)) {
        this.SelectedViewModel = this.UnmatchedLeadListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }
  };
}
