import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { routes } from "../../../../router";
import IdentityStore from "../../../../store/IdentityStore";
import { action, observable, toJS } from "mobx";
import { UserSearch, UserStatus } from "../../../../infrastructure/enum/Common";
import Utils from "../../../../infrastructure/Utils";
import axios from "axios";
import Collection from "../../../../infrastructure/CollectionHelper";
import { UserInvitationViewModel } from "./UserInvitationViewModel";

export class UserListViewModel extends BaseListViewModel<any> {

  constructor() {
    super("Users", "User", routes.addUser, routes.editUser, "userId", "firstName");
    this.pageSize = 50
  }

  @observable name: string = "";
  @observable searchType: string = "1";
  @observable searchTypeList = [
    { label: "Last Name", value: UserSearch.LastName },
    { label: "First Name", value: UserSearch.FirstName },
    { label: "Team", value: UserSearch.Team },
  ];
  @observable statusType: string = "2";
    @observable statusTypeList = [
    { label: "All", value: UserStatus.All },
    { label: "Active", value: UserStatus.Active },
    { label: "Inactive", value: UserStatus.Inactive },    
  ];
  @observable invitationStatusTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable searchValue: string = '';
  @observable refreshVisible: boolean = false;
  @observable isDownload: boolean = false;
  @observable timer: any;

  protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {

    let tabValue = localStorage.getItem("selectedUserTab");
    if (tabValue !== "") {
      if (tabValue === "1") {
        this.selectedTabIndex = 1;
        this.UserInvitationViewModel.loadInvitations();
        this.UserInvitationViewModel.resetInvitePagination();
        this.selectedStatus = 0;
        this.UserInvitationViewModel.searchInvitationValue = ''
      } else {
        this.selectedTabIndex = 0;
        this.refreshVisible = false;
        return await IdentityStore.getAllUser(pageIndex, pageSize, sortColumn, sortOrder, +this.searchType, this.searchValue, +this.statusType);
      }
    }
    else {
      this.selectedTabIndex = 0;
      this.refreshVisible = false;
      return await IdentityStore.getAllUser(pageIndex, pageSize, sortColumn, sortOrder, +this.searchType, this.searchValue, +this.statusType);
    }

  }

  @action goToAdmin = () => {
    routes.administration.push()
  }
    
  @observable selectedTabIndex?: number = 0;
  @observable userInvitationsTableData?: any = [];
  @observable selectedStatus: any = "0";
  @observable searchInvitationValue: any;
  @observable serchTextQuery: any = '';
  @observable isNoRecords: boolean = false;
  @observable UserInvitationViewModel: UserInvitationViewModel =
    new UserInvitationViewModel();


  @action resetInvitePagination = () => {
    this.UserInvitationViewModel.resetInvitePagination();
    this.UserInvitationViewModel.searchInvitationValue = '';
  }
  @action setStatusDropdown = (value: string) => {
    this.selectedStatus = value;
    this.loadInvitations();
  };

  @action onFieldChange = (value: any) => {
    this.searchInvitationValue = value;
    if (this.searchInvitationValue.length == 0 || this.searchInvitationValue.length > 2) {
      this.serchTextQuery = `&FullTextFilter=${this.searchInvitationValue}`
      this.loadInvitations();
    } else {
      this.serchTextQuery = '';
    }
  };

  @action loadInvitationsStatusTypes = async () => {
    const url = Utils.getMicroServiceUrl("inviteService");
    const authKey = await Utils.getApiKey();

    return axios
      .get(`${url}/api/invite/v1/invite/invitestatuslookup`, {
        headers: { authorization: authKey },
      })
      .then((response: any) => {
        if (response.data) {
          Utils.mapListItemsToDropdown(response.data, this.invitationStatusTypeList, "All", "0");
        }
      })
      .catch(console.error);
  };


  @action loadInvitations = async () => {
    const url = Utils.getMicroServiceUrl("inviteService");
    const authKey = await Utils.getApiKey();

    return axios
      .get(`${url}/api/invite/v1/invite/allinvites?Status=${this.selectedStatus}&SortAscending=true${this.serchTextQuery}`, {
        headers: { authorization: authKey },
      })
      .then((response: any) => {
        this.userInvitationsTableData = response.data.data;
      })
      .catch(console.error);
  };

  @action handleTabClick = async (index: number | undefined, key: string): Promise<void> => {
    this.selectedTabIndex = index;
    this.resetPaging();
    this.resetFiltersToDefault();
    if (index !== undefined) {
      localStorage.setItem("selectedUserTab", "" + index);
    }
    if (this.selectedTabIndex == 1) {
      this.UserInvitationViewModel.loadInvitations();
      this.UserInvitationViewModel.resetInvitePagination();
      this.selectedStatus = 0;
      this.UserInvitationViewModel.searchInvitationValue = ''
    }
    else {
        await this.loadItems();
        if (this.itemList.values.length <= 0)
            this.isNoRecords = true;
    }
  };
  @action rebindInvitations = async () => {
    let tabValue = localStorage.getItem("selectedUserTab");
    if (tabValue !== "") {
      if (tabValue === "1") {
        this.UserInvitationViewModel.resetInvitePagination();
        this.selectedStatus = 0;
        this.UserInvitationViewModel.searchInvitationValue = ''
        this.UserInvitationViewModel.loadInvitations();
      }
    }
  }
  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  @action search = async () => {
    this.resetPaging();
    await this.loadItems();
      console.log(this.itemList.values.length);
    if (this.itemList.values && this.itemList.values.length <= 0)
        this.isNoRecords = true;
    else
        this.isNoRecords = false;
  }

  @action reset = async () => {
    this.resetPaging();
    this.resetFiltersToDefault();
    await this.loadItems();
    this.refreshVisible = false;
  }

  resetFiltersToDefault() {
    this.searchValue = ''
    this.searchType = "1"
    this.statusType = "1"
    this.pageSize = 50;
  }

  @action setSearchType(value: string) {
    this.searchType = value;
    this.refreshVisible = true;
    this.resetRefesh();
  }

  @action resetRefesh() {
    if (this.searchType == "1" && this.searchValue == "" && this.statusType == "1")
          this.refreshVisible = false;
    else
        this.refreshVisible = true;
  }

  @action setStatusType(value: string) {
    this.statusType = value;
    this.refreshVisible = true;
    this.search();
    this.resetRefesh();
  }

  @action downloadUsersDashboard = async () => {
    let result = await IdentityStore.getUsersAsExcel(Number(this.statusType), Number(this.searchType), this.firstItem, this.pageSize, this.sortColumn, this.isSortAscending, this.searchValue);
    await this.downloadReport(
      result,
      result.fileName ? result.fileName : "Users_Report.xlsx"
    );   

    setTimeout(() => {
        this.isDownload = true;
    }, 300);

    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
        this.isDownload = false;
    }, 9000);
  }

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
}