import { observable, action, computed } from "mobx";
import InsuredStore from "../../store/InsuredStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { AgentsSearchList } from "../../services/InsuredService";
import IMASLog from "../../infrastructure/IMASLog";
import { AgentListModel } from "../../services/ProducerSearchService";

export class AgentLookupViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {
  };

  Route = async (currentRoute: RouteList): Promise<void> => { }
  @observable isLoading: boolean = false;
  @observable agentId: number = 0
  @observable companyId: number = 0
  @observable agentName: string = ''
  @observable isDialogVisible: boolean = false;
  @observable AgentsList = new Collection<AgentsSearchList>();
  @observable SelecetedAgent: AgentsSearchList | null = null
  @observable serachInputValue: string = ''
  @observable first: number = 0
  @observable totalRecords: number = 0;
  @observable rows: number = 8;
  @observable sortColumn: string = 'AgentId';
  @observable sortOrder: boolean = false;
  @observable pageIndex: number = 0
  @observable status: string = 'A'
  @observable searchType: number = 1
  @observable AgentsSearchList = new Collection<AgentsSearchList>();
  @observable firstAgentsSearch: number = 0
  @observable totalRecordsAgentSearch: number = 0;
  @observable rowsAgentsSearch: number = 8;
  @observable sortColumnAgentsSearch: string = 'AgentId';
  @observable sortOrderAgentsSearch: boolean = false;
  @observable pageIndexAgentsSearch: number = 0
  @observable searchTypeAgensSearch: number = 1
  @computed
  get isAnyAgentSelected(): boolean {
    if (this.SelecetedAgent)
      return false
    return true
  }

  @action searchIntervals = (text: string) => {
    if (text) {
      this.serachInputValue = text;
    }

  }

  @action loadAgents = async () => {
    try {
      this.isLoading = true
      this.AgentsList.values = []
      var result = await InsuredStore.getAgentsLookup(this.serachInputValue, this.status, this.searchType, this.pageIndex, this.rows,
        this.sortColumn, this.sortOrder)
      if (result) {
        this.totalRecords = result.recordCount ? result.recordCount : 0
        this.AgentsList.values = result.data ? result.data : []
      }
      this.isLoading = false;
    } catch (e) {
      this.AgentsList.values = []
    }
  }
  @observable paymentPlanStatus: string = 'A'

  @observable noteTypeList = [
    { label: 'General Comment', value: '1' },
    { label: 'Underwriting', value: '2' }
  ]
  @observable paymentPlanStatusList = [
    { label: 'Active', value: 'A' },
    { label: 'Histore', value: 'H' }
  ]
  @action setPaymentPlanStatus(value: string) {
    this.paymentPlanStatus = value
  }
  @action onPage = async (firstIndex: number, rows: number) => {
    this.first = firstIndex;
    this.pageIndex = firstIndex / rows;
    await this.loadAgents();

  }
  @action updateSelectedAgent = (value: AgentsSearchList) => {
    this.SelecetedAgent = value
    IMASLog.log('value: ' + JSON.stringify(value))
    let taskObj = JSON.parse(JSON.stringify(value))
    taskObj.isSelected = !taskObj.isSelected
  }


  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state)
      return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state)
      return agent.city;
    else if (!agent.city && agent.state)
      return agent.state;
    else
      return "";
  }

  @action setDialogVisibility(value: boolean, isSave: boolean) {
    if (isSave) {
      if (this.SelecetedAgent) {
        this.agentId = this.SelecetedAgent.agentId ? this.SelecetedAgent.agentId : 0
        this.companyId = this.SelecetedAgent.companyId ? this.SelecetedAgent.companyId : 0
        this.agentName = this.SelecetedAgent.agent ? this.SelecetedAgent.agent : ''
      }
    }
    this.isDialogVisible = value;

  }

}