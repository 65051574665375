import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { UserLookupListModel} from "../../services/IdentityService";

export class UserLookupViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => {
    };

    Route = async (currentRoute: RouteList): Promise<void> => { }

    @observable isLoading: boolean = false;
    @observable isDialogVisible: boolean = false;
    @observable UsersList = new Collection<UserLookupListModel>();
    @observable SelectedUser: UserLookupListModel | null = null
    @observable searchInputValue: string = ''
    @observable first: number = 0

    @computed
    get isAnyUserSelected(): boolean {
        if (this.SelectedUser)
            return false
        return true
    }

    @action searchIntervals = (text: string) => {
        if (text)
            this.searchInputValue = text;
    }

    @action loadUsers = async () => {
        this.isLoading = true
        //this.AgentsList = await AgentsStore.getAgentList(this.serachInputValue)
        this.isLoading = false;
    }

    @action updateSelectedUser = (value: UserLookupListModel) => {
        this.SelectedUser = value
    }

    @action onPage(firstIndex: number) {
        this.first = firstIndex;
    }
      
    @action setDialogVisibility(value: boolean) {
        this.isDialogVisible = value;
    }

}