import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import AccountingStore from "../../../../store/AccountingStore";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import Utils from "../../../../infrastructure/Utils";
import { ReportFile } from "../../../../services/SupplyOrderService";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { routes } from "../../../../router";

export class ImportAgentDeptsViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { }

    @observable companyList = new Collection<any>();
    @observable companyId: string = "0";
    @observable reportDate?: Date = new Date();
    @observable isLoading: boolean = false;
    @observable fileData: any;
    @observable fileName: string = "";
    @observable ErrorModel = new ErrorModel(new ImportAgentCertificatesValidator());

    @action Load = async () => {
        await this.getCompanies();
    }

    @action async getCompanies() {
        this.isLoading = true;
        try {
            var result = await AccountingStore.agentDebtImportFor();
            if (result && result.importFor && result.importFor.length > 0) {
                this.companyList.values = [];
                this.companyList.values.push({ label: "Select", value: "0" });
                result.importFor.forEach((element: any) => {
                    this.companyList.values.push({ label: element.text, value: element.value.toString() });
                });
                this.companyId = "0";
            }
        } catch (e) {
            this.companyList.values = [];
            this.companyList.values.push({ label: "Select", value: "0" });
            this.companyId = "0";
        }

        this.isLoading = false;

    }
    @action backToAdmin = async () => {
        routes.administration.push();
    }
    @action onDrop = (value: any, fileName: string) => {
        this.fileData = value;
        this.fileName = fileName;
    }
    @action Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action async process() {
        await this.ResetValidate();
        if (!await this.Validate()) {
            this.isLoading = true;

            var blob = null;
            if (this.fileData != null && this.fileData !== "" && this.fileData !== undefined) {
                // Split the base64 string in data and contentType
                var block = this.fileData ? this.fileData.split(";") : "";
                // Get the content type of the image
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                // Convert it to a blob to upload
                blob = Utils.b64toBlob(realData, contentType, 512);
            }
            try {
                var file = blob ? { data: blob, fileName: this.fileName } : null;
                var result = await AccountingStore.agentDebtImportUpload(this.reportDate, parseInt(this.companyId), file);

                if (result) {
                    this.downloadReport(result, "AgentDepts");
                }
               
            } catch (e) {

            }
            this.isLoading = false;
        }
    }
    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };
}

class ImportAgentCertificatesValidator extends AbstractValidator<ImportAgentDeptsViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.companyId)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Import for is required");
        this.validateIf(input => input.reportDate)
            .isNotEmpty()
            .withFailureMessage("Report Date is required");
        this.validateIf(input => input.fileData)
            .isNotEmpty()
            .withFailureMessage("File is required");
    }
}