import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { action, observable } from "mobx";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import ContractsStore from "../../../../store/ContractsStore";
import {
  LicenseStatusReasonModel,
  LicenseStatusReasonsListModel,
} from "../../../../services/ContractService";
import Collection from "../../../../infrastructure/CollectionHelper";

export class LicenseStatusReasonsListViewModel extends BaseListViewModel<LicenseStatusReasonsListModel> {
  @observable actionTitle: string = "";
  @observable isEditorVisible: boolean = false;
  @observable public ErrorModel = new ErrorModel(
    new LicenseStatusReasonValidator()
  );

  @observable reason: string = "";
  @observable statusId: number = 0;
  @observable licenseCount: number = 0;
  @observable licenseStatus: string = "";
  @observable licenseStatusList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable showSaveError: boolean = false;
  @observable saveErrorMessage: string = "";

  @observable isUpdateConfirmationVisible: boolean = false;
  @observable updateConfirmMessage: string = "";

  @observable deleteConfirmMessage: string = "";
  @observable deleteConfirmTitle: string = "";
  @observable canDeleteSelectedReason: boolean = true;
  @observable isStatusReasonException: boolean = false;
  @observable statusReasonResponse: any;

  @action setEditorVisibility(isVisible: boolean) {
    this.isStatusReasonException = false;
    this.isEditorVisible = isVisible;
  }
  @action onEditorHide() {
    this.setEditorVisibility(false);
  }
  @action hideUpdateConfirmation() {
    this.isUpdateConfirmationVisible = false;
  }
  @action async showUpdateConfirmation() {
    if (!(await this.Validate())) {
      if (this.selectedId === 0) {
        this.saveSelectedItem();
      } else {
        if (this.licenseCount > 0) {
          this.updateConfirmMessage =
            "There are " +
            this.licenseCount +
            " Contracts associated with this reason. Do you still want to update?";
          this.isUpdateConfirmationVisible = true;
        } else {
          this.saveSelectedItem();
        }
      }
    }
  }
  @action setSaveErrorVisibility(value: boolean) {
    if (value) {
      var status = this.licenseStatusList.values.filter(
        (lsr) => lsr.value === this.statusId.toString()
      );
      var statusName = status && status[0] ? status[0].label : "";
      this.saveErrorMessage =
        "Contract Status Reason '" +
        this.reason +
        "' with Status '" +
        statusName +
        "' already exists";
    }
    this.showSaveError = value;
  }

  @action showDeleteConfirmation = () => {
    this.canDeleteSelectedReason = this.licenseCount <= 0;
    if (this.canDeleteSelectedReason) {
      this.deleteConfirmTitle = "Confirm Delete";
      this.deleteConfirmMessage = "Are you sure, you want to delete this item?";
    } else {
      this.deleteConfirmTitle = "Delete Error";
      this.deleteConfirmMessage =
        "Status Reason can't be deleted. There are " +
        this.licenseCount +
        " Contracts associated with this reason.";
    }

    this.isDeleteConfirmationVisible = true;
  };

  protected async loadLookups() {
    this.licenseStatusList.values = [
      { label: "Active", value: "1" },
      { label: "Pending", value: "3" },
      { label: "Terminated", value: "2" },
      { label: "Pre-Contracting", value: "4" },
    ];
  }

  @action editItem = async (id: number) => {
    if (id === 0) {
      this.actionTitle = "Add Status Reason";
      this.selectedId = 0;
      this.reason = "";
      this.statusId = 1;
      this.licenseStatus = "";
      this.licenseCount = 0;
    } else {
      this.actionTitle = "Edit Status Reason";
      var item = await ContractsStore.getLicenseStatusReasonById(id);
      this.selectedId = item.id || 0;
      this.reason = item.reason || "";
      this.statusId = item.statusId || 0;
      this.licenseStatus = item.licenseStatus || "";
      this.licenseCount = item.licenseCount || 0;
    }

    this.ResetValidate();
    this.setEditorVisibility(true);
  };
  @action addItem = async () => {
    this.editItem(0);
  };

  protected toServiceModel() {
    var dto: LicenseStatusReasonModel = {
      id: this.selectedId,
      reason: this.reason,
      licenseStatus: this.licenseStatus,
      statusId: this.statusId,
    };
    return dto;
  }
  @action public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action checkIfDuplicate = async () => {
    return await ContractsStore.isLicenseStatusReasonExists(
      this.selectedId,
      this.reason,
      this.statusId
    );
  };

  @action saveSelectedItem = async () => {
    try {
      this.isStatusReasonException = false;
      this.hideUpdateConfirmation();
      if (!(await this.Validate())) {
        if (await this.checkIfDuplicate()) {
          this.setSaveErrorVisibility(true);
          return;
        }

        var item = this.toServiceModel();
        if (this.selectedId === 0) {
          await ContractsStore.addLicenseStatusReason(item);
        } else await ContractsStore.updateLicenseStatusReason(item);

        this.setEditorVisibility(false);
        setTimeout(() => {
          this.Load();
        }, 10);
      }
    } catch (e: any) {
      e &&
        e.text().then((_response: any) => {
          this.statusReasonResponse = _response;
          this.isStatusReasonException = true;
        });
    }
  };

  constructor() {
    super(
      "Status Reasons",
      "Status Reason",
      routes.addLicenseStatusReasons,
      routes.editLicenseStatusReasons,
      "id",
      "licenseStatus"
    );
    this.pageSize = 50;
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    return await ContractsStore.getLicenseStatusReasonList(
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }

  protected async deleteItem(id: number) {
    await ContractsStore.deleteLicenseStatusReason(id);
  }

  resetFiltersToDefault() {
    this.pageSize = 50;
  }
}

class LicenseStatusReasonValidator extends AbstractValidator<LicenseStatusReasonsListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.reason)
      .isNotEmpty()
      .isNotEqualTo("0")
      .matches(/\S+/, "i")
      .withFailureMessage("Name for Status Reason is required");

    this.validateIfNumber((input) => input.statusId)
      .isGreaterThanOrEqual(1)
      .withFailureMessage("Please choose a status");
  }
}
