import TagManager from "react-gtm-module";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import LoggedInUser from "../../infrastructure/Auth0Authenticator";

// Declare gTM dataLayer array.
declare global {
    interface Window { dataLayer: any[]; }
}
class GTM {
    initialized: boolean = false;
    tagManagerArgs = {
        gtmId: "GTM-P28CH9G",
    };
    constructor() {}

    init() {
        TagManager.initialize(this.tagManagerArgs);
        this.initialized = true;   
    }
    addLoginUser() {
        var userLogin = localStorage.getItem("userLogin");
        var userLoginAdded = localStorage.getItem("userLoginAdded");
        if (userLogin == "userLogin" && userLoginAdded != "true") {
            this.trackAction("login_success", { user_id: UserContext.userId });
            localStorage.removeItem("userLogin");
            localStorage.setItem("userLoginAdded", "true");
        }
    }

    trackAction = (event: string, props: any): void => {
        setTimeout(async () => {
            try {
                if (!this.initialized) {
                    await this.init();
                }

                this.addLoginUser();

                props = props || {};
                props.event = event;
                props.BU = Utils.getBUName().toUpperCase();
                props.user_name = LoggedInUser.displayName;
                props.user_type = UserContext.isDownlineAgency ? "DownlineAgency" : "Normal";
                props.date_time = Utils.getDateWithTimeZone();
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(props);

                console.log(props);
            }
            catch (e) {
                console.error("Error in GTM TrackAction");
                console.error(props);
            }
        }, 0);

    }
}
export const googleTagManager = new GTM();
