import { observer } from "mobx-react";
import React from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";

export const IMASConfirmationAlert:
    React.FC<
        {
            title?: string,
            okTitle?: string,
            cancelTitle?: string,
            value: string,
            isVisible: boolean,
            onHide: () => void,
            ok: (e: any) => void,
            cancel: (e: any) => void,
            disabled?: boolean
        }> = observer(props => {
            const onShow = () => {
                let okButton = document.getElementById("okButton")
                okButton && okButton.focus()
              }
            return (
                <>
                    <Dialog header={props.title || 'Alert'}
                        visible={props.isVisible} onHide={props.onHide}
                        onShow={onShow}
        focusOnShow = {false} >
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <label id='alertLable' htmlFor='alertLable'>{props.value}</label>

                            </div>
                            <div className='p-col-12'>                               
                                <div className="button-group text-right">
                                    <Button label={props.cancelTitle ? props.cancelTitle : "Cancel"}  id='cancelButton' icon='fa fa-times' onClick={props.cancel} className="secondary-button margin-right-10" ></Button>
                                    <Button disabled={props.disabled} label={props.okTitle ? props.okTitle : "OK"} id='okButton' onClick={props.ok} icon='fa fa-check' className='primary-button' ></Button>
                                </div>                               
                            </div>
                        </div>
                    </Dialog>

                </>
            )
        });
