import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";
import { AgentListModel } from "../../services/ProducerSearchService";
import ProducerStore from "../../store/ProducerStore";
import DocumentStore from "../../store/DocumentStore";
import loggedInUser from "../../infrastructure/Auth0Authenticator";
import UserContext from "../../infrastructure/UserContext";
import { Permission } from "../../infrastructure/enum/Permission";

import { DownloadPdfModel } from "../../services/DocumentService";
import PageContext from "../../infrastructure/PageContext";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { Constants } from "../../infrastructure/enum/Constants";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { routes } from "../../router";
import { googleTagManager } from "../../infrastructure/tracking";

export class DocumentManagementViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (id: number) => {
    this.agentId = id;
    this.resetFilters();
    this.title = "Document Search";
    this.cc = loggedInUser.userName;
    this.ddlPayoutLevelEnabled = false;
    this.ddlStateEnabled = false;
    if (RouteParamDetails.id && RouteParamDetails.id > 0) {
      this.selectedAgent = RouteParamDetails.agentName;
      this.selectedAgentId = RouteParamDetails.id;
      if (this.selectedAgentId > 0) {
        try {
          var agentProfile = await ProducerStore.getAgentProfileInfoById(
            this.selectedAgentId
          );
          this.selectedAgent =
            agentProfile && agentProfile.name
              ? (agentProfile.dba && agentProfile.dba.split(",").length > 1
                  ? agentProfile.dba.split(",")[1].trim() +
                    ", " +
                    agentProfile.dba.split(",")[0]
                  : agentProfile.name) +
                (agentProfile.nickName
                  ? " (" + agentProfile.nickName + ")"
                  : "")
              : "";
          // if(agentProfile.defaultEmail)
          // {
          this.email = agentProfile.defaultEmail || "";
          // } else {
          //     this.email = "";
          // }
        } catch (error) {
          IMASLog.log("exception");
        }
      }
    }
  };
  @action handleInactiveCarriers = async (e: any) => {
        this.showInactiveCarriers = e.checked;
        this.companyList.values = []; 
        this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
      };
      
  @action async loadLookup() {
    try {
      //this.isLoading = true;
      var result = await DocumentStore.getAllDMSLookupList();
      if (result) {
        if (
          UserContext.agencyId === "0" ||
          Utils.hasUserPermission(Permission.SendLicenseKitFromDocumentSystem)
        ) {
          this.categoryList.values = [{ label: "All", value: "0" }].concat(
            this.mapListItemAndSort(result.categories)
          );
        } else {
          this.categoryList.values = this.mapListItemAndSort(result.categories);
        }

        this.companyList.values = [{ label: "All", value: "0" }].concat(
          this.mapListItemAndSort(result.carriers)
        );
        
        this.carriersInitial.values = this.companyList.values;
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
        this.companyGroupList.values = [{ label: "All", value: "0" }].concat(
          this.mapListItemAndSort(result.carrierGroups)
        );
        this.stateList.values = [{ label: "All", value: "0" }];
        if (result.states) {
          for (let item of result.states) {
            this.stateList.values.push({
              label: "" + item.stateName,
              value: "" + item.stateId,
              abbreviation: "" + item.abbreviation,
              isHeader: item.isHeader,
            });
          }
        }
        this.payoutLevelList.values = [{ label: "All", value: "0" }]; //.concat(this.mapListItemAndSort(result.payoutLayouts));
        this.productList.values = [{ label: "All", value: "0" }].concat(
          this.mapListItemAndSort(result.products)
        );
        this.productSubCategoryList.values = [
          { label: "All", value: "0" },
        ].concat(this.mapListItemAndSort(result.productSubCategories));
        var documentTypeListdata = this.mapListItemAndSort(
          result.documentTypes
        );
        let documentTypeids: any = [];
        ////Remove the "Corporate Website" option
        if (
          documentTypeListdata.filter((item: any) => {
            return item.value === "19";
          }).length > 0
        ) {
          documentTypeListdata = documentTypeListdata.filter((item: any) => {
            return item.value !== "19";
          });
        }

        ////Remove the "Internal" option
        if (
          !UserContext.hasPermmission(Permission.ViewInternalDocs) &&
          documentTypeListdata.filter((item: any) => {
            return item.value === "18";
          }).length > 0
        ) {
          documentTypeListdata = documentTypeListdata.filter((item: any) => {
            return item.value !== "18";
          });
        }
        documentTypeListdata.forEach((item: any) => {
          documentTypeids.push(item.value);
        });
        this.selectedDocumentTypeId = documentTypeids;
        this.documentTypeList.values = documentTypeListdata;
      }
      this.isLoading = false;
    } catch (e) {
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
    }
  }
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
        element.key = element.value;
      });
      return sortList;
    } else {
      return [];
    }
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;
  @observable agentId: number = 0;
  @observable title: string = "";

  @observable categoryList = new Collection<any>();
  @observable companyList = new Collection<any>();
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyGroupList = new Collection<any>();
  @observable stateList = new Collection<any>();
  @observable documentTypeList = new Collection<any>();
  @observable productSubCategoryList = new Collection<any>();
  @observable productList = new Collection<any>();
  @observable payoutLevelList = new Collection<any>();
  @observable documentTitle: string = "";

  @observable selectedCategoryId = 0;
  @observable selectedCompanyId = 0;
  @observable selectedCompanyGroupId = 0;
  @observable selectedProductSubCategoryId = 0;
  @observable selectedStateId = 0;
  @observable selectedDocumentTypeId: any = [];
  @observable selectedProductId = 0;
  @observable selectedPayoutLevelId = 0;

  @observable ddlStateEnabled: boolean = true;
  @observable txtTitleEnabled: boolean = true;
  @observable docTypesVisible: boolean = true;
  @observable ddlProductEnabled: boolean = true;
  @observable ddlPayoutLevelEnabled: boolean = true;
  @observable pnlDocumentsVisible: boolean = true;
  @observable pnlPacketVisible: boolean = true;
  @observable pnlContractingSentVisible: boolean = true;
  @observable DefaultDownlineOptionsVisible: boolean = false;
  @observable
  public ErrorModel = new ErrorModel(new AddDocumentManagementValidator());
  @observable
  public ErrorModelDocument = new ErrorModel(
    new AddDocumentManagementValidator()
  );

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  /*set dropdown start*/
  @action async setSelectedCategory(categoryId: number) {
    this.selectedCategoryId = categoryId;
    //Supplies and App Packets
    if (
      categoryId === 4 ||
      categoryId === 5 ||
      categoryId === 6 ||
      categoryId === 7
    ) {
      this.ddlStateEnabled = true;
      this.docTypesVisible = false;
      this.txtTitleEnabled = false;
      this.setspecialInstructionsVisible(true);

      if (categoryId === 6 || categoryId === 7) {
        this.ddlProductEnabled = false;
      } else {
        this.ddlProductEnabled = true;
      }

      if (categoryId === 5) {
        this.ddlPayoutLevelEnabled = true;
      } else {
        this.selectedPayoutLevelId = 0;
        this.ddlPayoutLevelEnabled = false;
      }
    } else {
      this.ddlStateEnabled = false;
      this.selectedStateId = 0;
      this.docTypesVisible = true;
      this.txtTitleEnabled = true;
      this.setspecialInstructionsVisible(false);
      this.selectedPayoutLevelId = 0;
      this.ddlPayoutLevelEnabled = false;
      this.ddlProductEnabled = true;
    }

    // Load Document Types
    var result = await DocumentStore.getDocumentTypesByGroupId(
      this.selectedCategoryId
    );
    if (result) {
      this.documentTypeList.values = this.mapListItemAndSort(
        result.documentTypes
      );
      ////Remove the "Corporate Website" option
      if (
        this.documentTypeList.values.filter((item: any) => {
          return item.value === "19";
        }).length > 0
      ) {
        this.documentTypeList.values = this.documentTypeList.values.filter(
          (item: any) => {
            return item.value !== "19";
          }
        );
      }

      ////Remove the "Internal" option
      if (
        !UserContext.hasPermmission(Permission.ViewInternalDocs) &&
        this.documentTypeList.values.filter((item: any) => {
          return item.value === "18";
        }).length > 0
      ) {
        this.documentTypeList.values = this.documentTypeList.values.filter(
          (item: any) => {
            return item.value !== "18";
          }
        );
      }
      let documentTypeids: any = [];
      this.documentTypeList.values.forEach((item: any) => {
        documentTypeids.push(item.value);
      });
      this.selectedDocumentTypeId = documentTypeids;
    }

    //Hide search results
    this.pnlDocumentsVisible = false;
    this.pnlPacketVisible = false;
    this.canShowQueuedDocuments = false;
  }
  @action async setSelectedCompany(companyId: number) {
    this.selectedCompanyId = companyId;
    var result = await DocumentStore.getLookUpByCompanyId(
      this.selectedCompanyId,
      this.selectedCompanyGroupId,
      this.selectedProductSubCategoryId,
      true
    );
    if (result) {
      //this.carrierGroups.values = this.mapListItemAndSort(result.carrierGroups);
      this.payoutLevelList.values = [{ label: "All", value: "0" }].concat(
        this.mapListItemAndSort(result.payoutLayouts)
      );
      this.productList.values = [{ label: "All", value: "0" }].concat(
        this.mapListItemAndSort(result.products)
      );
    }
    this.selectedPayoutLevelId = 0;
    this.selectedProductId = 0;
  }

  @action async setSelectedCompanyGroup(companyGroupId: number) {
    this.selectedCompanyGroupId = companyGroupId;
    var result = await DocumentStore.getComapniesByCompanyGroupId(
      this.selectedCompanyGroupId
    );
    if (result) {
      this.companyList.values = [{ label: "All", value: "0" }].concat(
        this.mapListItemAndSort(result.carriers)
      );
    }
    if (this.selectedCompanyId !== 0) {
      this.selectedCompanyId = 0;
      this.setSelectedCompany(0);
    }
  }
  @action async setSelectedProductGroup(productGroupId: number) {
    this.selectedProductSubCategoryId = productGroupId;
    var result = await DocumentStore.getProductByProductGroupId(
      this.selectedCompanyId,
      this.selectedCompanyGroupId,
      this.selectedProductSubCategoryId,
      true
    );
    if (result) {
      this.productList.values = [{ label: "All", value: "0" }].concat(
        this.mapListItemAndSort(result.products)
      );
    }
    if (this.selectedProductId !== 0) {
      this.setSelectedProduct(0);
    }
  }
  @action setSelectedState(stateId: number) {
    this.selectedStateId = stateId;
  }
  @action setSelectedDocumentType(documentId: any) {
    this.selectedDocumentTypeId = documentId; // ? documentId.split(',') : [];
  }
  @action setSelectedProduct(productId: number) {
    this.selectedProductId = productId;
  }
  @action setSelectedPayoutLevel(payoutLevelId: number) {
    this.selectedPayoutLevelId = payoutLevelId;
    console.log(payoutLevelId);
  }
  @action setDocumentTitle(title: string) {
    this.documentTitle = title;
  }
  /*set dropdown end*/
  @observable selectedDocuments: any = [];
  @observable selectionEventPropogation: boolean = true;
  @action setDocumentSelection(selectedObj: any) {
    this.selectedDocuments = selectedObj;
  }
  @action setPacketSelection(id: number) {
    this.packetId = id;
  }
  @observable queuedDocuments = new Collection<any>();
  @observable canShowQueuedDocuments: boolean = false;
  @action addtoQueue = async () => {
    await this.ResetValidate();
    PageContext.setIsMessageVisible(false);
    this.setremoveQueueConfirmation(false);
    this.isMessgeVisible = false;
    if (this.selectedDocuments.length === 0) {
      var responseModel = {
        status: 400,
        title: "",
        errors: { ValidationError: [Constants.SelectDocument] },
      };
      this.response = JSON.stringify(responseModel);
      this.isMessgeVisible = true;
      window.scrollTo(0, 0);
      return false;
    }

    // adding the documents to queue.
    var queuedDocumentsList = this.queuedDocuments.values.map(
      (result: any) => result.id
    );
    var selectedDocumentsList =
      this.selectedDocuments &&
      this.selectedDocuments.filter(function (result: any) {
        return queuedDocumentsList.indexOf(result.id) === -1;
      });
    this.queuedDocuments.values = this.queuedDocuments.values.concat(
      selectedDocumentsList
    );

    //this.selectedDocuments = [];
    this.canShowQueuedDocuments = true;
    if (this.selectedCategoryId === 5) {
      //Contracting Being Sent
      this.pnlContractingSentVisible = true;
    } else {
      this.pnlContractingSentVisible = false;
    }
    this.bundleName = "bundle.pdf";
    this.isMessgeVisible = false;
    this.isSuccess = false;
    this.title = "Document Search - Send Mail";
  };
  @action removeItemFromQueue = (id: any): void => {
    this.queuedDocuments.values = this.queuedDocuments.values.filter(
      (item: any) => {
        return item.id !== id;
      }
    );
    if (this.selectedDocuments && this.selectedDocuments.length > 0) {
      this.selectedDocuments = this.queuedDocuments.values.filter(
        (item: any) => {
          return item.id !== id;
        }
      );
    }
    if (this.queuedDocuments.values.length < 2) {
      this.bundlePdfs = false;
    }
  };
  @action removeQueue() {
    this.queuedDocuments.values = [];
    this.selectedDocuments = [];
    this.isMessgeVisible = false;
    this.isSuccess = false;
    this.canShowQueuedDocuments = false;
    this.bundlePdfs = false;
    this.title = "Document Search";
    PageContext.setIsMessageVisible(false);
  }

  @action backtoDocumentList() {
    this.isMessgeVisible = false;
    this.isSuccess = false;
    this.canShowQueuedDocuments = false;
    this.title = "Document Search";
    PageContext.setIsMessageVisible(false);
  }

  @action downloadReport = async (result: DownloadPdfModel, name: string) => {
    this.isMessgeVisible = false;
    if (result && result.pdfData) {
      await fetch(`data:text/pdf;base64,${result.pdfData}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          link.href = url;
          link.download = result.fileName ? result.fileName : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    } else if (result && result.message) {
      var responseModel = {
        status: 400,
        title: "",
        errors: { ValidationError: [result.message] },
      };
      this.response = JSON.stringify(responseModel);
      this.isMessgeVisible = true;
      window.scrollTo(0, 0);
    }
  };

  @action async downloadBundlePdf() {
    this.isLoading = true;
    try {
      let docIds: any = [];
      this.queuedDocuments.values.forEach((item) => {
        docIds.push(item.id);
      });
      let result = await DocumentStore.downloadBudlePdf(
        this.packetId,
        docIds.join(",")
      );
      this.downloadReport(result, "document");
    } catch (error) {
      IMASLog.log("exception while dowloading documents.");
    }
    this.isLoading = false;
  }

  @action async downloadSignature() {
    this.isLoading = true;
    try {
      let docIds: any = [];
      this.queuedDocuments.values.forEach((item) => {
        docIds.push(item.id);
      });
      let result = await DocumentStore.downloadSignaturePages(
        this.packetId,
        docIds.join(",")
      );
      this.downloadReport(result, "document");
    } catch (error) {
      IMASLog.log("exception while dowloading documents");
    }
    this.isLoading = false;
  }
  /* grid functions start*/
  @observable rows: number = 200;
  @observable totalRecords: number = 100;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable sortOrder: boolean = false;
  @observable sortColumnOrder: string = "id";
  async setSortColumn(sortField: any) {
    this.sortOrder = !this.sortOrder;
    this.sortColumnOrder = sortField;
    await this.loadDocuments();
  }
  @action async onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    await this.loadDocuments();
  }
  /* grid functions end*/

  /* Agent search funcationality starts */
  @observable filteredAgentsList = new Collection<AgentListModel>();
  @observable filteredUplineAgentsList = new Collection<AgentListModel>();
  @observable selectedAgent: any = "";
  @observable selectedAgentId = 0;
  @observable selectedUplineAgent: any = "";
  @observable selectedUplineAgentId = 0;
  @action async setSelectedAgent(
    agentName: any,
    agentId: number,
    email: string
  ) {
    if (agentId) {
      this.selectedAgent = agentName;
      this.selectedAgentId = agentId;
      this.selfAsUpline = false;
      this.uplineDNCall = false;
      this.uplineDNEmail = false;
      this.uplineDNMarket = false;
      this.downlineDNCall = false;
      this.downlineDNEmail = false;
      this.downlineDNMarket = false;
      this.selectedUplineAgent = undefined;
      this.selectedUplineAgentId = 0;
      if (agentId > 0) {
        try {
          let result = await ProducerStore.getContacts(agentId);
          if (result && result.data) {
            var contact = result.data.find((item: any) => {
              return item.contactType === "Email" && item.default === true;
            });
            this.email =
              contact && contact.contactValue ? contact.contactValue : "";
          } else {
            this.email = "";
          }
        } catch (error) {
          IMASLog.log("exception");
        }
      }
    } else {
      this.selectedAgent = undefined;
      this.selectedAgentId = 0;
    }
  }
  @action setSelectedUser(value: string) {
    this.selectedAgent = value;
    if (!value) {
        this.selectedAgentId = 0;
        this.selfAsUpline = false;
        this.selectedUplineAgent = undefined;
        this.selectedUplineAgentId = 0;
        this.DefaultDownlineOptionsVisible = false;
        this.uplineDNCall = false;
        this.uplineDNEmail = false;
        this.uplineDNMarket = false;
        this.downlineDNCall = false;
        this.downlineDNEmail = false;
        this.downlineDNMarket = false;
    }
  }
  @action setSelectedUplineAgent(
    agentName: string,
    agentId: number,
    email: string
  ) {
    if (agentId) {
      this.selectedUplineAgent = agentId + " - " + agentName;
      this.selectedUplineAgentId = agentId;
      this.DefaultDownlineOptionsVisible = true;
    } else {
      this.selectedUplineAgent = undefined;
      this.selectedUplineAgentId = 0;
      this.DefaultDownlineOptionsVisible = false;
      }
      if (agentId != this.selectedAgentId) {
          this.selfAsUpline = false;
      }
      else {
          this.selfAsUpline = true;
      }
      this.uplineDNCall = false;
      this.uplineDNEmail = false;
      this.uplineDNMarket = false;
      this.downlineDNCall = false;
      this.downlineDNEmail = false;
      this.downlineDNMarket = false;
  }
  @action setSelectedUplineAgentId(value: string) {
    this.selectedUplineAgent = value;
    if (!value) {
      this.selfAsUpline = false;
      this.selectedUplineAgentId = 0;
      this.DefaultDownlineOptionsVisible = false;
      this.uplineDNCall = false;
      this.uplineDNEmail = false;
      this.uplineDNMarket = false;
      this.downlineDNCall = false;
      this.downlineDNEmail = false;
      this.downlineDNMarket = false;
    }
  }

  @observable isremoveQueueConfirm: boolean = false;

  @action setremoveQueueConfirmation(value: boolean) {
    this.isremoveQueueConfirm = value;
  }

  @action fillterUsers = async (value: string) => {
    var results = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      10,
      undefined,
      false
    );
    if (results !== null) {
      if (results.recordCount) {
        if (results.data) {
          this.filteredAgentsList.values = results.data;
        } else {
          this.filteredAgentsList.values = [{ name: "" }];
        }
      } else {
        this.filteredAgentsList.values = [{ name: "" }];
      }
    } else {
      this.filteredAgentsList.values = [{ name: "" }];
    }
  };
  @action fillterUplineUsers = async (value: string) => {
    var results = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      10,
      undefined,
      false
    );
    if (results !== null) {
      if (results.recordCount) {
        if (results.data) {
          this.filteredUplineAgentsList.values = results.data;
        } else {
          this.filteredUplineAgentsList.values = [{ name: "" }];
        }
      } else {
        this.filteredUplineAgentsList.values = [{ name: "" }];
      }
    } else {
      this.filteredUplineAgentsList.values = [{ name: "" }];
    }
  };
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @observable isMessgeVisible: boolean = false;
  @observable response: any = {};
  @action openDocumentLink = async (docId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      this.isMessgeVisible = false;
      window.open(
        window.location.origin + "/documentviewer/" + docId + "/" + 0,
        "_blank"
      );
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
  /* Agent search funcationality end*/

  @observable documentList = new Collection<any>();
  @observable documentPacketsList = new Collection<any>();
  @action loadDocuments = async () => {
    PageContext.isLoading = true;
    if (this.selectedCategoryId === 5 || this.selectedCategoryId === 0) {
      //lbError.Text = "Please select a COMPANY when searching for Licensing Kits";
      // return;
    }

    if (
      this.selectedCategoryId === 4 ||
      this.selectedCategoryId === 5 ||
      this.selectedCategoryId === 6 ||
      this.selectedCategoryId === 7
    ) {
      try {
        this.isLoading = true;
        var packetsResponse = await DocumentStore.searchDocumentPackets(
          this.selectedCategoryId,
          this.selectedCompanyId,
          this.selectedCompanyGroupId,
          this.selectedStateId,
          this.selectedProductId,
          this.selectedProductSubCategoryId,
          this.selectedPayoutLevelId,
          UserContext.userId ? UserContext.userId : 0,
          this.selectedDocumentTypeId,
          this.documentTitle,
          this.startIndex,
          this.rows,
          this.sortColumnOrder,
          this.sortOrder
        );

        if (packetsResponse && packetsResponse.data) {
          this.totalRecords = packetsResponse.recordCount
            ? packetsResponse.recordCount
            : 0;
          this.documentPacketsList.values = packetsResponse.data;
        }
        this.isLoading = false;
      } catch (e) {
        IMASLog.log("exception: " + e.message);
        this.isLoading = false;
      }
      this.pnlPacketVisible = true;
      this.pnlDocumentsVisible = false;
    } else {
      try {
        this.isLoading = true;
        var documentResponse = await DocumentStore.searchDocuments(
          this.selectedCategoryId,
          this.selectedCompanyId,
          this.selectedCompanyGroupId,
          this.selectedStateId,
          this.selectedProductId,
          this.selectedProductSubCategoryId,
          this.selectedPayoutLevelId,
          UserContext.userId ? UserContext.userId : 0,
          this.selectedDocumentTypeId,
          this.documentTitle,
          this.startIndex,
          this.rows,
          this.sortColumnOrder,
          this.sortOrder
        );

        if (documentResponse && documentResponse.data) {
          this.totalRecords = documentResponse.recordCount
            ? documentResponse.recordCount
            : 0;
          this.documentList.values = documentResponse.data;
        }
        this.isLoading = false;
        PageContext.isLoading = false;
      } catch (e) {
        IMASLog.log("exception: " + e.message);
        this.isLoading = false;
        PageContext.isLoading = false;
      }
      this.pnlDocumentsVisible = true;
      this.pnlPacketVisible = false;
    }

    try {
      this.isLoading = true;
      // if (ImpersonatedUser.User.AgencyID == "0")
      // {
      //     ListItem allItem = new ListItem("[ALL]", "%");
      //     ddlGroupID.Items.Insert(0, allItem);
      //     ddlGroupID.SelectedIndex = 0;
      // }
      // else if (!ImpersonatedUser.Documents.CanSendContractKitFromDocumentSystem)
      // {
      //     ddlGroupID.Items.RemoveAt(0);
      //     ddlGroupID.Items.RemoveAt(0);
      // }
      this.isLoading = false;
      PageContext.isLoading = false;
    } catch (e) {
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
      PageContext.isLoading = false;
    }
    PageContext.isLoading = false;
  };
  @observable packetId: number = 0;
  @action onAddPacketClick = async (packetId: number) => {
    try {
      this.isLoading = true;
      // this.resetEmailFilters();
      var result = await DocumentStore.getDocumentDetails(packetId);
      if (result && result.data) {
        this.selectedDocuments = result.data;
      }
      this.canShowQueuedDocuments = true;
      if (result.packetSignatureAvailable) {
        this.chkIncludeSignature = true;
        this.chkIncludeSignatureVisible = true;
      } else {
        this.chkIncludeSignature = false;
        this.chkIncludeSignatureVisible = false;
      }
      this.addtoQueue();
      this.isLoading = false;
    } catch (e) {
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
    }
  };
  @action goSearch = async () => {
    this.isMessgeVisible = false;
    if (
      this.selectedCategoryId === 5 &&
      this.selectedCompanyId === 0 &&
      this.selectedCompanyGroupId === 0
    ) {
      let response = {
        status: 0,
        title: "",
        errors: {
          ValidationException: [
            "Please select a specific Carrier Group or Carrier when searching for Licensing Kits",
          ],
        },
      };
      this.response = JSON.stringify(response);
      this.isMessgeVisible = true;
      return;
    }
    this.startIndex = 0;
    this.first = 0;
    this.rows = 200;
    this.sortColumnOrder = "id";
    this.sortOrder = false;
    this.queuedDocuments.values = [];
    this.canShowQueuedDocuments = false;
    await this.loadDocuments();
  };
  @action resetFilters = async () => {
    
    this.showInactiveCarriers = false;
    this.selectedCategoryId = 0;
    this.selectedCompanyId = 0;
    this.selectedCompanyGroupId = 0;
    this.selectedStateId = 0;
    this.selectedDocumentTypeId = [];
    this.selectedProductId = 0;
    this.selectedProductSubCategoryId = 0;
    this.selectedPayoutLevelId = 0;
    this.documentTitle = "";
    this.sortColumnOrder = "id";
    this.sortOrder = false;
    this.first = 0;
    this.startIndex = 0;
    this.rows = 200;
    this.setIsException(false);
    this.documentList.values = [];
    this.documentPacketsList.values = [];
    this.ddlStateEnabled = true;
    this.docTypesVisible = true;
    this.ddlPayoutLevelEnabled = true;
    this.ddlProductEnabled = true;
    this.canShowQueuedDocuments = false;
    this.pnlDocumentsVisible = false;
    this.pnlPacketVisible = false;
    this.selectedDocuments = [];
    this.isMessgeVisible = false;
    this.setremoveQueueConfirmation(false);
    await this.loadLookup();
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  /*Send mail */
  @observable email: string = "";
  @observable cc: string = "";
  @observable subject: string = "";
  @observable message: string = "";
  @observable saveToAgents: boolean = false;
  @observable bundlePdfs: boolean = false;
  @observable bundleName: string = "";
  @observable chkIncludeSignature: boolean = false;
  @observable uplineDNCall: boolean = false;
  @observable uplineDNEmail: boolean = false;
  @observable uplineDNMarket: boolean = false;
  @observable downlineDNCall: boolean = false;
  @observable downlineDNEmail: boolean = false;
  @observable downlineDNMarket: boolean = false;
  @observable chkIncludeSignatureEnabled: boolean = false;
  @observable chkIncludeSignatureVisible: boolean = false;
  @observable specialInstructionsVisible: boolean = false;
  @observable specialInstructions: string = "";
  @observable selfAsUpline: boolean = false;
  @action setspecialInstructionsVisible(value: boolean) {
    this.specialInstructionsVisible = value;
  }

  @action setEmail(email: string) {
    this.email = email;
  }
  @action setCc(cc: string) {
    this.cc = cc;
  }
  @action setSubject(subject: string) {
    this.subject = subject;
  }
  @action setMessage(message: string) {
    this.message = message;
  }
  @action setSpecialInstructions(instruction: string) {
    this.specialInstructions = instruction;
  }
  @action setSaveToAgents(saveToAgents: boolean) {
    this.saveToAgents = saveToAgents;
  }
  @action setbundlePdfs(bundlePdfs: boolean) {
    this.bundlePdfs = bundlePdfs;
  }
  @action setBundleName(bundleName: string) {
    this.bundleName = bundleName;
  }
  @action setUplineDNCall(dncall: boolean) {
    this.uplineDNCall = dncall;
  }
  @action setUplineDNEmail(dnEmail: boolean) {
    this.uplineDNEmail = dnEmail;
  }
  @action setUplineMarket(dnMarket: boolean) {
    this.uplineDNMarket = dnMarket;
  }
  @action setDownlineDNCall(dncall: boolean) {
    this.downlineDNCall = dncall;
  }
  @action setDownlineDNEmail(dnEmail: boolean) {
    this.downlineDNEmail = dnEmail;
  }
  @action setDownlineMarket(dnMarket: boolean) {
    this.downlineDNMarket = dnMarket;
  }
  @observable isSuccess: boolean = false;
  @action async sendEmail() {
    if (!(await this.Validate())) {
      let docIds: any = [];
      let docNames: any=[];
      this.queuedDocuments.values.forEach((item) => {
        docIds.push(item.id);
        docNames.push(item.fileName);
      });

        googleTagManager.trackAction("doc_emailed_to_agent", {
            feature: routes.documents.name,
            user_id: UserContext.userId,
            agent_id: this.agentId,
            document_names: docNames.join(",")
        });
    
      var result = await DocumentStore.sendEmail(
        this.packetId,
        docIds.join(","),
        this.selectedCategoryId,
        this.selectedAgentId,
        this.selectedCompanyId,
        this.email,
        this.cc,
        this.subject,
        this.message,
        this.specialInstructions,
        this.saveToAgents,
        this.selectedUplineAgentId,
        this.uplineDNCall,
        this.uplineDNEmail,
        this.uplineDNMarket,
        this.downlineDNCall,
        this.downlineDNEmail,
        this.downlineDNMarket,
        this.bundlePdfs,
        this.bundleName,
        this.chkIncludeSignature
      );

      if (result) {
        this.isSuccess = true;
        this.email = "";
        //this.cc = '';
        this.subject = "";
        this.message = "";
        this.saveToAgents = false;
        this.bundlePdfs = false;
        this.bundleName = "";
        this.chkIncludeSignature = false;
        this.uplineDNCall = false;
        this.uplineDNEmail = false;
        this.uplineDNMarket = false;
        this.downlineDNCall = false;
        this.downlineDNEmail = false;
        this.downlineDNMarket = false;
        this.chkIncludeSignatureEnabled = false;
        this.chkIncludeSignatureVisible = false;
        this.specialInstructionsVisible = false;
        this.specialInstructions = "";
        this.selectedAgent = "";
        this.selectedAgentId = 0;
        this.selectedUplineAgent = "";
        this.selectedUplineAgentId = 0;
        this.queuedDocuments.values = [];
        this.isMessgeVisible = false;
        var successResponse = {
          status: 200,
          title: Constants.EmailSuccess,
          errors: { "": [] },
        };
        this.response = JSON.stringify(successResponse);
        PageContext.setResponseMessage(this.response);
        PageContext.setIsMessageVisible(true);
        setTimeout(() => {
          PageContext.setIsMessageVisible(false);
          this.removeQueue();
        }, Utils.timeDelay_Success());
      }
    }
    }
  @action resetEmailFilters = async () => {
      this.selectedAgent = "";
      this.selectedAgentId = 0;
      this.email = "";
      this.subject = "";
      this.message = "";
      this.specialInstructions = "";
      this.bundlePdfs = false;
      this.saveToAgents = false;
      this.selfAsUpline = false;
      this.selectedUplineAgent = "";
      this.selectedUplineAgentId = 0;
      this.uplineDNCall = false;
      this.uplineDNEmail = false;
      this.uplineDNMarket = false;
      this.downlineDNCall = false;
      this.downlineDNEmail = false;
      this.downlineDNMarket = false;
      this.queuedDocuments.values = [];
      this.bundleName = "";
    };
}

class AddDocumentManagementValidator extends AbstractValidator<DocumentManagementViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedAgent)
      .isNotEmpty()
      .withFailureMessage("Agent is required");
    this.validateIfString((input) => input.email)
      .isNotEmpty()
      .withFailureMessage("Email is required");
    this.validateIfString((input) => input.email)
      .isEmail()
      .whenNotEmpty()
      .withFailureMessage("Please enter valid email");
    this.validateIfString((input) => input.subject)
      .isNotEmpty()
      .withFailureMessage("Subject is required");
  }
}
