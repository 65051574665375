import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import ProducerStore from "../../store/ProducerStore";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import {
  MarketingEventDetailModel,
  MarketingEventLookupRepsonse,
  ReportFile,
} from "../../services/ProducerService";

export class MarketingEventDetailViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @action Load = async (id: number) => {
    this.id = id;
    this.isLoading = true;
    this.enableEditButton = false
    await this.getAllLookups();
    await this.getMarketingEventDetail(id);
    this.isLoading = false;
    this.enableEditButton = true
  };
  @observable id: number = 0;
  @observable title: string = "Marketing Event";
  @observable sendTest: string = "";
  @observable eventDetail: MarketingEventDetailModel = {};
  @observable isLoading: boolean = false;
  @observable lookups: MarketingEventLookupRepsonse = {};
  @observable type: string | undefined = "";
  @observable format: string | undefined = "";
  @observable owner: string | undefined = "";
  @observable requestor: string | undefined = "";
  @observable company: string | undefined = "";
  @observable productLine: string | undefined = "";
  @observable areEmailsValid: boolean = false;
  @observable isEmailSent: boolean = false;
  @observable iframeSrc: string = "";
  @observable enableEditButton: boolean = false;

  @action Loaded = () => {};

  @action getAllLookups = async () => {
    this.isLoading = true;
    try {
      let result = await ProducerStore.getmarketingeventlookup();
      if (result) this.lookups = result;
    } catch (e) {}
  };

  @action reset = () => {
    this.isLoading = false;
    this.eventDetail = {};
    this.areEmailsValid = false;
    this.sendTest = "";
  };
  @action setSendTest = (value: string) => {
    this.sendTest = value;
  };
  @action validate(email: string) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).trim().toLowerCase());
  }

  @action checkIfUrlHasHTTP = (param: string) => {
    let link = param.split("/");
    if (link.length > 0) {
      let url = link[0];
      if (url.includes("http") || url.includes("https")) return true;
      else return false;
    } else {
      return false;
    }
  };

  @action emailValidator = () => {
    let emails = this.sendTest.trim().split(",");
    if (emails.length > 0) {
      let list = emails.map((email) => this.validate(email));
      let checker = (arr: any) => arr.every((v: any) => v === true);
      this.areEmailsValid = checker(list) ? false : true;
    } else {
      this.areEmailsValid = true;
    }
  };
  @action downloadStatisticReport = async () => {
    try {
      this.isLoading = true;
      let result = await ProducerStore.getStatisticsReport(this.id);
      await this.downloadReport(result, "Statistics Report");
    } catch (e) {}
    this.isLoading = false;
  };

  @action getResendListReport = async () => {
    try {
      this.isLoading = true;
      let result = await ProducerStore.getResendListReport(this.id);
      await this.downloadReport(result, "Statistics Report");
    } catch (e) {}
    this.isLoading = false;
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
  @action getMarketingEventWebViewerUrl = async (param: any) => {
    let url = (param && param.url) ? param.url  : "";
    let urlCheck = this.checkIfUrlHasHTTP(url);
    if (urlCheck) {
      let result = await ProducerStore.getMarketingEventWebViewerUrl(url);
      if (result && result.data) {
        this.iframeSrc = result.data;
        let a = document.getElementById("showEmailerFromUrl");
        a && a.setAttribute("src","data:text/html;base64,"+this.iframeSrc)
      }
    }
  };
  @action getMarketingEventDetail = async (id: number) => {
    try {
      this.isLoading = true;
      let result = await ProducerStore.getMarketingEventById(id);
      this.eventDetail = result;
      this.getMarketingEventWebViewerUrl(this.eventDetail);
      this.type =
        this.lookups.types &&
        this.lookups.types.filter(
          (i) => Number(i.value) === this.eventDetail.marketingEventTypeId
        )[0].text;
      this.format =
        this.lookups.formats &&
        this.lookups.formats.filter(
          (i) => Number(i.value) === this.eventDetail.marketingFormatId
        )[0].text;
      this.owner =
        this.lookups.owners &&
        this.lookups.owners.filter(
          (i) => Number(i.value) === this.eventDetail.ownerId
        )[0].text;
      this.requestor =
        this.lookups.requestors &&
        this.lookups.requestors.filter(
          (i) => Number(i.value) === this.eventDetail.requestorId
        )[0].text;
      this.company =
        this.eventDetail.companyId === 0
          ? "All"
          : this.lookups.companies &&
            this.lookups.companies.filter(
              (i) => Number(i.value) === this.eventDetail.companyId
            )[0].text;
      this.productLine =
        this.eventDetail.productLineId === 0
          ? "All"
          : this.lookups.productLines &&
            this.lookups.productLines.filter(
              (i) => Number(i.value) === this.eventDetail.productLineId
            )[0].text;
      this.isLoading = false;
    } catch (e) {}
    this.isLoading = false;
  };

  public ErrorModel = new ErrorModel(new addSentTestValidator());
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action onSubmit = async () => {
    await this.ResetValidate();
    try {
      this.emailValidator();
      if (!this.areEmailsValid) {
        this.isLoading = true;
        //call api
        let temp = this.sendTest.trim().split(",");
        let emails = temp.map((i) => i.trim().toLowerCase()).join(" ");
        let body = {
          id: 0,
          testEmailAddress: emails,
        };
        await ProducerStore.sendMarketingEventEmail(body);
        this.isEmailSent = true;
        setTimeout(() => {
          this.isEmailSent = false;
          this.sendTest = "";
          this.areEmailsValid = false;
        }, 1000);
      }
    } catch (e) {}
    this.isLoading = false;
  };
}

class addSentTestValidator extends AbstractValidator<MarketingEventDetailViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.sendTest)
      .isNotEmpty()
      .isEmail()
      .withFailureMessage("Valid 'From' Email is required");
  }
}
