import React from "react";
import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Alert, MessageType } from "../../../infrastructure/enum/Common";
import { ProgressInfo } from "../../../infrastructure/ProgressInfoModel";
import { ProgressBar } from "primereact/progressbar";
import Utils from "../../../infrastructure/Utils";
export const IMASProgressBarHandler: React.FC<{
  response: ProgressInfo;
}> = observer((props) => {
  var divToaster = document.getElementById("divToaster");
  if (divToaster !== null) {
    if (divToaster.style.display === "none") {
      divToaster.style.display = "block";
    }
  }
  var divNotificationToaster = document.getElementById(
    "divNotificationToaster"
  );
  if (divNotificationToaster !== null) {
    if (divNotificationToaster.style.display === "none") {
      divNotificationToaster.style.display = "block";
    }
  }
  var progressbarcontainer = document.getElementById("progressbarcontainer");
  if (progressbarcontainer !== null) {
    if (progressbarcontainer.style.display === "none") {
      progressbarcontainer.style.display = "block";
    }
  }

  var notificationCard = document.getElementById("notificationCard");
  if (notificationCard !== null) {
    notificationCard.className = "card";
  }

  const onClose = (divId: string) => {
    var alertDiv = document.getElementById(divId);
    var y = document.getElementById("notificationCard");
    if (alertDiv !== null) {
      if (alertDiv.style.display === "none") {
        alertDiv.style.display = "block";
      } else {
        alertDiv.style.display = "none";
      }
    }
    if (y) {
      y.className = "";
    }
  };
  const handleToasterClick = (message: string, id: number) => {
    let toaterInfo = JSON.parse(
      localStorage.getItem("broker-notification") || "[]"
    );
    toaterInfo = toaterInfo.filter((item: any) => {
      return item.Id !== id;
    });
    props.response.ToaterInfo = toaterInfo;
    props.response.Total = toaterInfo.length;
    if (message.search("Broken Order") !== -1) {
      localStorage.setItem("broker-notification", JSON.stringify(toaterInfo));
    }

    if (toaterInfo && toaterInfo.length === 0) {
      onClose("progressbarcontainer");
    }
  };

  const handleProgressBarClick = () => {
    onClose("divNotificationToaster");
    onClose("progressbarcontainer");
  };
  var percentage: number = 0;
  var progress: number = 0;
  progress = (props.response.Progress / props.response.Total) * 100;
  percentage = Math.round(
    (props.response.Progress / props.response.Total) * 100
  );

  const displayValueTemplate = () => {
    return (
      <>{percentage + "% completed for " + props.response.Total + " records"}</>
    );
  };
  if (progress >= 100) {
    setTimeout(() => {
      handleProgressBarClick();
    }, 3000);
  }

  return (
    <>
      {/* Toaster */}
      {
        //props.response.MessageType === MessageType.Toaster &&
        //     <div id="notificationCard" className="card">
        //         <div id="divToaster" className={"alert alert-" + String(Alert.success)} role="alert">
        //             <div className='display-flex-column'>
        //                 <div >{Utils.getSafeHtml(props.response.Message)}</div>
        //             </div>
        //             <Button icon="fa fa-times" onClick={handleToasterClick} />
        //         </div>
        //     </div>
      }
      {
        /* Toaster - multiple notification  */
        props.response.MessageType === MessageType.Toaster && (
          <div id="notificationCard" className="card">
            {props.response.ToaterInfo.map((element) => {
              return (
                <div
                  id={"divToaster" + element.Id.toString()}
                  className={"alert alert-" + String(Alert.success)}
                  role="alert"
                >
                  <div className="display-flex-column">
                    <div>{Utils.getSafeHtml(element.Message)}</div>
                  </div>
                  <Button
                    icon="fa fa-times"
                    onClick={() =>
                      handleToasterClick(element.Message, element.Id)
                    }
                  />
                </div>
              );
            })}
          </div>
        )
      }
      {/* ProgressBar */}
      {props.response.MessageType === MessageType.ProgressBar &&
        props.response.Total > 0 && (
          <>
            <div id="notificationCard" className="card">
              {progress < 100 && (
                <ProgressBar
                  value={percentage}
                  displayValueTemplate={displayValueTemplate}
                ></ProgressBar>
              )}
              {progress >= 100 && (
                <div
                  id="divNotificationToaster"
                  className={"alert alert-" + String(Alert.success)}
                  role="alert"
                >
                  <div className="display-flex-column">
                    <div>Process Completed</div>
                  </div>
                  <Button icon="fa fa-times" onClick={handleProgressBarClick} />
                </div>
              )}
            </div>
          </>
        )}
    </>
  );
});
