
import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { control, AuthenticationType, CameraOptions, ControlPosition, data, ControlStyle } from 'azure-maps-control';
import { routes } from "../../router";
import Collection from "../../infrastructure/CollectionHelper";
import ContractsStore from "../../store/ContractsStore";
import Utils from "../../infrastructure/Utils";
import { AgentSearch } from "../../infrastructure/enum/Common";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { AgentListModel, AgentMapViewSPModel } from "../../services/ProducerSearchService";
import { ControlOptions, IAzureCustomControls, IAzureMapControls, IAzureMapOptions } from "react-azure-maps";
import { FullViewControl } from './FullViewControl';
import { Permission } from "../../infrastructure/enum/Permission";
import AutoDialerStore from "../../store/AutoDialerStore";
import UserContext from "../../infrastructure/UserContext";
import { googleTagManager } from "../../infrastructure/tracking";
export class AgentSearchByMapViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true
  }
  @observable customControls: IAzureCustomControls[] = [
    {
      // @ts-ignore
      control: new FullViewControl({ style: ControlStyle.auto }),
      controlOptions: {
        position: ControlPosition.TopLeft,
      },
    },
    {
      // @ts-ignore
      control: new control.StyleControl({
        mapStyles: ["road", "grayscale_light", "grayscale_dark", "night", "road_shaded_relief"], 
        //Optionally pass in an HTML element or its ID to have the fullscreen control toggle it in and out of fullscreen mode instead of the map.
        //container: 'myHtmlElement'
      }),
      controlOptions: {
        position: ControlPosition.TopLeft,
      },
    },
  ];
  @observable controls: IAzureMapControls[] = [
    {
      controlName: 'ZoomControl',
      //controlOptions: { mapStyles: 'all' },
      options: { position: 'top-right' } as ControlOptions,
    }
  ];
  @observable option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: 'u8FQAqzsuH-5SQYlFLoad8ojdBlqrrXrRhaX158wTqE'
    },
    center: [-96.8, 32.7],
    zoom: 4,
    showLogo: false,
    showFeedbackLink: false
  }
  @observable cameraOptions: any = [];
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable agentId: number = 0;
  @observable resultVisible: boolean = false;

  @action Load = async () => {
    this.AgentsSearchList.values = []
    this.resultVisible = false
    this.loadCarrierList();
    this.loadMarketers();
    this.loadStates();
  };
  @action Loaded = () => {

  };
  @observable isValidationFailed: boolean = false;
  @action setIsValidation(value: boolean) {
    this.isValidationFailed = value;
  }
  @observable point4x = -96.8;
  @observable point4y = 32.7;
  @observable slelectedAgentId: number = 0
  @observable pointNFx = -80.38;
  @observable pointNFy = 42.83;
  // @observable optionHTML: HtmlMarkerOptions = { position: [126, 55],
  //   text: 'My text',
  //   title: 'Title',}
  Route = async (currentRoute: RouteList): Promise<void> => { }
  @observable dnc: string = "0";
  @observable dncList = [
    { label: "All", value: "0" },
    { label: "Exclude", value: "1" }
  ];
  @action setDnc(value: string) {
    this.dnc = value;
  }
  @observable marketer: string = "All";
  @observable marketerList = new Collection<{ label: string; value: string; }>();
  @action setMarketer(value: string) {
    this.marketer = value;
  }
  @observable carrier: string = "All";
  @observable carrierList = new Collection<{ label: string; value: string; }>();
  @action setCarrier(value: string) {
    this.carrier = value;
  }
  @observable isAgentSearchDialogVisible: boolean = false;
  @action setIsAgentSearchDialogVisible(value: boolean) {
    this.isAgentSearchDialogVisible = value;
  }
  @observable selectedAgent: string = "";
  @action setSelectedAgent(value: string) {
    this.selectedAgent = value;
  }
  @observable county: string = "";
  @observable setCounty(value: string) {
    this.county = value;
  }
  @observable state: string = "0";
  @observable stateList:any = [{ label: "None", value: "0", abbreviation: "" }];
  @action setState(value: string) {
    this.state = value;
  }
  @observable zip: string = "";

  @action setZip(value: string) {
    this.zip = value;
  }

  @observable htmlMarkers: data.Position[] = [];

  @action reset = () => {
    this.setSelectedAgent('');
    this.slelectedAgentId = 0;
    this.county = '';
    this.zip = '';
    this.state = '0';
    this.marketer = '0';
    this.carrier = '0';
    this.searchInputValue = '';
    this.dnc = '0';
    this.AgentsSearchList.values = [];
    this.AgentsList.values = []
    this.resultVisible = false;
    this.cameraOptions = {zoom: 4, center: new data.Position(-96.8, 32.7) } as CameraOptions;
  }

  @action searchAgents = async () => {
    
      googleTagManager.trackAction("agent_searched_by_map", {
          feature: "MapsView",
          user_id: UserContext.userId,
          zip_code: this.zip,
          state: this.getStateAbrrevationById(this.state),
          county: this.county
      });

    if (this.county && this.county.length > 0 && this.state === "0") {
      this.setIsValidation(true);
      return;
    }
    else {
      this.setIsValidation(false);
    }
    await this.searchAgentsForMap();
  }

  @action searchAgentsForMap = async () => {
    try {
      let stateAbbreviation = this.getStateAbrrevationById(this.state);
      let zipCode = this.zip;
      if (this.county && this.state !== "0") {
          var zipFromApi = await ProducerSearchStore.getZipByCountyState(stateAbbreviation, this.county);
        if (zipFromApi) {
          zipCode = "" + zipFromApi;
          this.zip = "" + zipFromApi;
        }
      }
      var result = await ProducerSearchStore.getAgentsSearchByMap(+this.marketer, +this.carrier, this.slelectedAgentId, stateAbbreviation, zipCode, (this.dnc === "1"));
        if (result && result.length > 0) {
        this.AgentsList.values = [];
        let long = result[0].longitude ? result[0].longitude : 0;
        let lat = result[0].latitude ? result[0].latitude : 0;
        this.cameraOptions = { zoom: 6, center: new data.Position(long, lat)} as CameraOptions;
        this.AgentsList.values = result;
      }
      else {
        this.AgentsList.values = [];
      }
      this.resultVisible = true
    } catch (e) { }
  }

  @action setMapOptions = async (longitude?: number, latitude?: number, zoom?: number) => {
    this.option = {};
    this.option = {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: 'u8FQAqzsuH-5SQYlFLoad8ojdBlqrrXrRhaX158wTqE'
      },
      center: [longitude, latitude],
      zoom: zoom
    }
  }
  @action goToAgencySystem() {
    routes.agentSearch.replace();
  }

  @action saveSelectedAgent() {
    this.setIsAgentSearchDialogVisible(false);

  }
  @observable AgentsSearchList = new Collection<AgentListModel>();
  @observable AgentsList = new Collection<AgentMapViewSPModel>();
  @observable totalRecords: number = 0;
  @observable searchInputValue: string = '';
  @action searchIntervalsAgentsSearch = (text: string) => {
    this.searchInputValue = text;
  }
  formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return match[0];
    }
    return phoneNumberString;
  }
  @action loadAgents = async () => {
    try {
      if (this.searchTypeAgentSearch === AgentSearch.Phone) {
        this.searchInputValue = this.formatPhoneNumber(this.searchInputValue);
      }
      if (this.searchTypeAgentSearch === AgentSearch.Ssn || this.searchTypeAgentSearch === AgentSearch.Tin) {
        this.searchInputValue = this.searchInputValue.replace(/-/g, '').trim()
      }
      let result = await ProducerSearchStore.getAgentList(
        +this.searchTypeAgentSearch,
        this.searchInputValue,
        0,
        100,
        "lastName",
        true
      );
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            this.AgentsList.values = []
            this.AgentsSearchList.values = result.data; // filterList;
            this.totalRecords = result.recordCount;   //filterList.length;

          }
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
    } catch (error) {

      this.noRecordsFound();
    }

  }
  @action noRecordsFound() {
    this.totalRecords = 0;
    this.AgentsSearchList.values = [];
  }
  @observable searchTypeAgentSearch: string = "6";
  @observable searchTypeList = [
    { label: "NPN", value: AgentSearch.Npn },
    { label: "Last Name", value: AgentSearch.LastName },
    { label: "Agent #", value: AgentSearch.Agent },
    { label: "Agent ID", value: AgentSearch.AgentId },
    { label: "Contact Options", value: AgentSearch.ContactOptions },
    { label: "DBA/Agency Name", value: AgentSearch.Dba },
    { label: "Email", value: AgentSearch.Email },
    { label: "Phone", value: AgentSearch.Phone },
    { label: "SSN", value: AgentSearch.Ssn },
    { label: "TIN", value: AgentSearch.Tin },

  ];
  @action setSearchType(value: string) {
    this.searchTypeAgentSearch = value;
  }

  private async loadCarrierList() {
    var items = await ContractsStore.getAllCompanyLookup();
    Utils.mapListItemsToDropdown(items, this.carrierList, "All", "0");
    this.carrier = "0";
  }
  @action loadMarketers = async () => {
    var items = null;
    if (UserContext.marketerId && !Utils.hasUserPermission(Permission.ChangeMarketerParameter, 220)) {
      items = await AutoDialerStore.getMarketers();
    }
    else {
     items = await ProducerSearchStore.getMarketersForMap();
    }
    Utils.mapListItemsToDropdown(items, this.marketerList, "All", "0");
    this.marketer = "0";

  };
  @action getStateAbrrevationById(id: string) {
    let stateObj = this.stateList.find((i:any) => i.value.trim() === id.trim());
    return stateObj ? stateObj.abbreviation : '';
  }
  @action loadStates = async () => {
    var result = await ContractsStore.appointmentStates();
    if (result != null) {
      this.stateList = [];
      if (result) {
        this.stateList.push({ label: "None", value: "0", abbreviation: "" });
        for (let item of result) {
          this.stateList.push({
            label: "" + item.stateName,
            value: "" + item.stateId,
            abbreviation: '' + item.abbreviation,
            isHeader: item.isHeader
          });
        }
      }
    }
  };

  @action goToAgentDetails(agentId: number) {
    window.open(window.location.origin + "/agents/" + agentId, '_new');
  }

  @action updateSelectedAgent = (value: any) => {
    console.log('selected value: ' + JSON.stringify(value));
    let obj = JSON.parse(JSON.stringify(value));
    this.slelectedAgentId = obj.id ? obj.id : 0;
    this.setSelectedAgent(obj.name ? obj.name : "");
    console.log('selected value: ' + obj.id + " name:" + obj.name);
    // value.foreach((item: any) => {
    //   console.log("name is: "+item.name);
    // });
    this.selectedAgentsFromSearch = value;
  }

  @observable selectedAgentsFromSearch: any | undefined = undefined;

  @action searchAgentAutocomplete = async (value: string, actionV: string) => {
    if (this.searchTypeAgentSearch === AgentSearch.Phone) {
      value = this.formatPhoneNumber(value);
    }
    if (this.searchTypeAgentSearch === AgentSearch.Ssn || this.searchTypeAgentSearch === AgentSearch.Tin) {
      value = value.replace(/-/g, '').trim()
    }
    let result = await ProducerSearchStore.getAgentList(
      +this.searchTypeAgentSearch,
      value,
      0,
      100,
      "lastname",
      true
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          this.FilteredAgentsList.values = result.data;
        }

      } else {
        this.FilteredAgentsList.values = [noAgent];
        this.selectedAgent = "";
      }
    } else {
      this.FilteredAgentsList.values = [noAgent];
      this.selectedAgent = "";
    }
  };

}




