import { AbstractValidator } from 'fluent-ts-validator';
import { observable, action, computed } from "mobx";
import ViewModel from '../../../../infrastructure/ViewModel';
import RouteList from '../../../../infrastructure/RouteList';
import { ErrorModel } from '../../../../infrastructure/ErrorModel';
import { routes } from '../../../../router';
import IMASLog from '../../../../infrastructure/IMASLog';
import Utils from '../../../../infrastructure/Utils';
import AccountingStore from '../../../../store/AccountingStore';
import UserContext from '../../../../infrastructure/UserContext';
import ContractsStore from '../../../../store/ContractsStore';
import Collection from '../../../../infrastructure/CollectionHelper';


export class CarrierBankDepositEditViewModel implements ViewModel {
  
    @observable companyList = new Collection<{ label: string; value: string; }>();
    @observable bankAccountList = new Collection<{ label: string; value: string; }>();
    @observable selectedCompany: string = "";
    @observable selectedBankAccount: string = "";
    @observable CarrierBankDepositID: number = 0;
    @observable isException: boolean = false;
    @observable isSuccess: boolean = false;
    @observable isWarningDisplayed: boolean = false;
     
    @observable isLoading: boolean = true;
    constructor(private OnSave?: (BankAccountID: number) => void) { }
    @computed
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }

    get CanRoute(): boolean {
        return window.confirm("Are you sure?");
    }
    @computed
    get IsLoaded(): boolean {
        return !this.isLoading;
    }
    @computed
    get IsLoading(): boolean {
        return this.isLoading;
    }

    Close = (): void => {
        routes.listCarrierBankDeposit.push();
    }
    @action loadDropdowns = async () => {
       
       var items = await ContractsStore.getAllCompanyLookup();
       Utils.mapListItemsToDropdown(items, this.companyList, "Select Carrier");
       
       items = await AccountingStore.getBankAccountLookup();
       items = items.filter(function (e) { return (e.text !== "ARC" && e.text !== "IMDirect"); });
       Utils.mapListItemsToDropdown(items, this.bankAccountList, "Select Paid Entity");
    }
    @observable tempCarrierId: number = 0;
    @observable tempPaidDivisionId: number = 0;
    public Load = async (CarrierBankDepositID: number): Promise<void> => {  
        await this.ResetValidation();    
        this.CarrierBankDepositID = CarrierBankDepositID;
        this.isLoading = true;
        console.log('on close....' + CarrierBankDepositID)
        await this.loadDropdowns();
       
        var depositModel = await AccountingStore.getCarrierBankDepositDetail(this.CarrierBankDepositID);
        this.InputModel = new InputModel();
        this.setSelectBankAccount((depositModel.paidDivisionId!).toString());

        this.setSelectCompany((depositModel.companyId!).toString());
        this.InputModel.Comment = depositModel.comment || '';
        this.InputModel.DepositAmount = (depositModel.amount || 0).toString();
        if (depositModel.paidDivisionId) {
            this.tempPaidDivisionId = depositModel.paidDivisionId;
            this.InputModel.SetBankAccountID(depositModel.paidDivisionId)
        }
        if (depositModel.companyId) {
            this.tempCarrierId = depositModel.companyId;
            this.InputModel.SetCarrierID(depositModel.companyId)
        }

        if (depositModel.statementDate)
            this.InputModel.DepositDate = new Date(depositModel.statementDate.toString());
        this.InputModel.PaidDivisionID = depositModel.paidDivisionId;
        
    };
    @action loadCompanies = async () => {
        
    }

    @action loadBankAccounts = async () => {
       
    }

    @action setSelectBankAccount(value: string) {
        this.selectedBankAccount = value;
    }
    @action setSelectCompany(value: string) {
        this.selectedCompany = value;
    }

    @action
    private loaded = () => {
        this.isLoading = false;
    };
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    

    @action setisWarningDisplayed(value: boolean) {
        this.isWarningDisplayed = value;
    }
    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }
    public Cancel = async () => {
        this.isSuccess = false;
        routes.listCarrierBankDeposit.push();
    };

    Route = async (currentRoute: RouteList): Promise<void> => { }

    @action
    public Submit = async () => {
        if (UserContext.userId && UserContext.userId > 0) {
        this.isException = false;
        this.isSuccess = false;
        this.isLoading = true;
        try {
            var carrierId = 0;
            if (this.selectedCompany !== "0")
                carrierId = parseInt(this.selectedCompany);

            var bankAccountId = 0;
            if (this.selectedBankAccount !== "0")
                bankAccountId = parseInt(this.selectedBankAccount);

            this.InputModel.BankAccountID = bankAccountId;
            this.InputModel.CarrierID = carrierId;

            await this.ResetValidation();
            if (!await this.checkIsModelValid()) {
                return false;
            }
           
            await AccountingStore.editBankDeposit({
                id: this.CarrierBankDepositID,
                companyId: parseInt(this.selectedCompany),
                amount: parseFloat(this.InputModel.DepositAmount),
                comment: this.InputModel.Comment,
                paidDivisionId: parseInt(this.selectedBankAccount),
                statementDate: this.InputModel.DepositDate,
                createdByUserId: UserContext.getUserId()
            });
        
    
            this.setIsSuccess(true);
            this.isLoading = false;
            this.Close();
        } catch (error) {
            this.isLoading = false;
            this.isException = true;
        }
       }
    }
   


    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this.InputModel);
    }
    @action
    public ResetValidation = async () => {
        return await this.ErrorModel.ResetValidation(this.InputModel);
    }
    @action checkIsModelValid = async () => {
      var hasErrors = await this.ErrorModel.Validate(this.InputModel)
      return !hasErrors;
    }

    @observable
    public InputModel = new InputModel();
    @observable
    public ErrorModel = new ErrorModel(new Validator());
    public onSave?: (CarrierBankDepositID: number) => void;
    private onClose?: () => void;
    @observable
    private loading = true;
}

export class InputModel {
    //#region Properities
    @observable
    public BankAccountID: number | undefined;
    @observable
    public DepositDate: Date | undefined;
    @observable
    public CarrierID: number | undefined;
    @observable
    public DepositAmount: string = "";
    @observable
    public Comment: string = "";
    //#endregion
    @observable
    public PaidDivisionID: number | undefined;

    @observable
    public CompanyID: number | undefined;
    //#region State Management
    @action
    public SetBankAccountID = (value: number) => {
        IMASLog.log('bank account id is : '+value)
        this.BankAccountID = value;
    };

    @action
    public SetDepositDate = (value: Date | undefined) => {
        this.DepositDate = value;
    };

    @action
    public SetCarrierID = (value: number) => {
        this.CarrierID = value;
    };

    @action
    public SetDepositAmount = (value: string) => {
        this.DepositAmount = value;
    };

    @action
    public SetComment = (value: string) => {
        this.Comment = value;
    };
    //#endregion
}


class Validator extends AbstractValidator<InputModel>{
    public constructor() {
        super();
      
        this.validateIfNumber(input => input.BankAccountID)
            .isGreaterThan(0)
            .withFailureMessage("Bank Account is test required");

        this.validateIfDate(input => input.DepositDate)
            .isNotEmpty()
            .withFailureMessage("Deposit Date is required");

        this.validateIfNumber(input => input.CarrierID)
            .isGreaterThan(0)
            .withFailureMessage("Carrier is required");

        this.validateIfString(input => input.DepositAmount)
            .isNotEmpty()
            .withFailureMessage("Amount is required");

        this.validateIfString(input => input.DepositAmount)
            .isCurrency()
            .whenNotEmpty()
            .withFailureMessage("Amount must be a currency");

        this.validateIfString(input => input.Comment)
            .hasMaxLength(200)
            .whenNotEmpty()
            .withFailureMessage("Comment is more than 200 characters");
    }
}