import { observable, action } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import { Permission } from "../../../../infrastructure/enum/Permission";
import PageContext from "../../../../infrastructure/PageContext";
import RouteParamDetails from "../../../../infrastructure/RouteParamDetails";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import {
	FileType,
	PolicyInfoListModel,
	RenewalRecordCountListModel,
	RenewalReviewLogSpModel,
	ReportFile,
} from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import UserContext from "../../../../infrastructure/UserContext";
import { googleTagManager } from "../../../../infrastructure/tracking";

export class RenewalDashboardViewModel extends BaseListViewModel<any> {
	constructor() {
		super(
			"Revenue Dashboard",
			"Revenue",
			routes.addRenewal,
			routes.editRenewal,
			"renewalId",
			"policyNumber"
		);
		this.pageSize = 500;
	}
	@observable sortRenewalColumn: string = "";
	@observable policyNumber: string = "";
	@observable policyDialog: boolean = false;
	@observable commissionRecordsDialog: boolean = false;
	@observable reviewLogsDialog: boolean = false;
	@observable companyList = new Collection<{
		label: string;
		value: string;
	}>();
	@observable sortColumnForReviewLogs: string = "createdDate";
	@observable isSortAscendingForReviewLogs: boolean = false;
	@observable sortColumnForCommissionRecord: string = "carrier";
	@observable isSortAscendingForCommissionRecord: boolean = true;

	@observable selectedCompany: string = "0";
	@observable defaultCarrier: string = "";
	@observable selectedAgent: string = "";
	@observable selectedAgentId: string = "0";
	@observable selectedPolicy: string = "";
	@observable isDurationSort: boolean = false;
	@observable FilteredAgentsList = new Collection<{
		label: string;
		value: string;
	}>();
	@observable
	RenewalRecordCountList = new Collection<RenewalRecordCountListModel>();
	@observable RenewalReviewLogList = new Collection<RenewalReviewLogSpModel>();
	@observable
	RenewalReviewLogListSource = new Collection<RenewalReviewLogSpModel>();
	@observable FilteredPolicyNumbersList = new Collection<string>();
	@observable PoliciesList = new Collection<PolicyInfoListModel>();
	@observable SelecetedPolicy: PolicyInfoListModel | undefined;
	@observable selectedPolicyId: number | undefined;
	@observable isNoPoliciesFound: boolean = false;
	@observable isInitialLoad: boolean = true;
	@observable fromCustomLoad: boolean = false;
	@observable importsList = new Collection<{
		label: string;
		value: string;
	}>();
	@observable selectedImport: string = "0";
	@observable userSearch: string = "";
	@observable exportAsList = [
		{ label: "Excel", value: "Excel" },
		{ label: "PDF", value: "PDF" },
	];
	@observable exportAs: string = "Excel";
	@observable fileData: any;
	@observable fileName: string = "";
	@observable fileFormatExport: FileType = FileType._1;

	@action customLoad = async () => {
		localStorage.removeItem("changeCompany");
		localStorage.removeItem("loadCarrierAfterDelete");
		localStorage.removeItem("fromImport");
		this.fromCustomLoad = true;
		// await this.searchAgents();
		//await this.search();
		if (
			PageContext.isAddRenewal ||
			localStorage.getItem("addRenewal") === "true"
		) {
			this.policyDialog = true;
			PageContext.isAddRenewal = false;
			localStorage.setItem("addRenewal", "");
		}
	};

	@action loadGetItems = async () => { };

	protected async getItems(pageIndex: number, pageSize: number) {
		if (this.fromCustomLoad) {
			await this.loadCarriers();
			this.fromCustomLoad = false;
		}
		this.fileData = null;
		if (
			PageContext.isAddRenewal ||
			localStorage.getItem("addRenewal") === "true"
		) {
			this.policyDialog = true;
			PageContext.isAddRenewal = false;
			localStorage.setItem("addRenewal", "");
		} else {
			this.removePolicies();
			this.policyNumber = "";
		}

		if (Utils.hasUserPermission(Permission.ViewRevenueDashboard)) {
			let agentID = this.selectedAgentId ? Number(this.selectedAgentId) : "0";
			let carrierID = this.selectedCompany ? Number(this.selectedCompany) : 0;
			let policyNumber = this.selectedPolicy ? this.selectedPolicy : "";
			this.sortRenewalColumn = this.sortColumn;
			return await CommissionStore.getRenewalDashboardSearch(
				false,
				agentID ? +agentID : undefined,
				carrierID,
				policyNumber,
				pageIndex,
				pageSize,
				this.sortColumn,
				this.isSortAscending
			);
		}
	}

	protected async deleteItem(id: number) {
		await CommissionStore.deleteRenewal(id);
		if (this.itemList.values.length <= 1) {
			localStorage.setItem("loadCarrierAfterDelete", "true");
			localStorage.removeItem("changeCompany");
		} else {
			localStorage.removeItem("loadCarrierAfterDelete");
		}
	}

	@action goToAdmin = () => {
		routes.administration.replace();
	};

	@action setpolicyDialog = (value: boolean) => {
		this.policyNumber = "";
		this.policyDialog = value;
		this.isNoPoliciesFound = false;
	};

	@action setCommissionRecordsDialog = async (value: boolean) => {
		this.commissionRecordsDialog = value;
		if (value) {
			await this.loadRenewalRecords();
		}
	};

	@action setReviewLogsDialog = async (value: boolean) => {
		this.reviewLogsDialog = value;
		if (value) {
			await this.loadRenewalReviewLogs();
		}
	};

	protected async loadLookups(): Promise<void> {
		this.lodImportLookup();
	}

	@action lodImportLookup = async () => {
		var result = await CommissionStore.getRenewalImportsLookup();
		if (result) {
			Utils.mapListItemsToDropdown(result, this.importsList, "", "");
			if (this.importsList.values.length > 0) {
				this.selectedImport = this.importsList.values[0].value;
			}
		}
	};

	@action loadRenewalRecords = async () => {
		var result = await CommissionStore.getRenewalRecordsCount(
			0,
			1000,
			this.sortColumnForCommissionRecord,
			this.isSortAscendingForCommissionRecord
		);
		if (result && result.data && result.data.length > 0) {
			this.RenewalRecordCountList.values = result.data;
		} else {
			this.RenewalRecordCountList.values = [];
		}
	};

	@action setSortOrder() {
		this.isSortAscendingForReviewLogs = !this.isSortAscendingForReviewLogs;
	}
	@action setSortColumn(column: string) {
		this.sortColumnForReviewLogs = column;
	}
	@action setCommissionRecordSortOrder() {
		this.isSortAscendingForCommissionRecord =
			!this.isSortAscendingForCommissionRecord;
	}
	@action setCommissionRecordSortColumn(column: string) {
		this.sortColumnForCommissionRecord = column;
	}

	@action loadRenewalReviewLogs = async () => {
		var result = await CommissionStore.getRenewalReviewLog(
			0,
			1000,
			this.sortColumnForReviewLogs,
			this.isSortAscendingForReviewLogs
		);
		if (result && result.data && result.data.length > 0) {
			this.RenewalReviewLogList.values = result.data;
			this.RenewalReviewLogListSource.values = {
				...this.RenewalReviewLogList.values,
			};
		} else {
			this.RenewalReviewLogList.values = [];
			this.RenewalReviewLogListSource.values = [];
		}
	};
	@action loadCarriers = async () => {
		let result = await CommissionStore.getRenewalPendingCarrierLookup(); //getPolicyImportCompaniesLookup()
		if (result !== null && result.length > 0) {
			this.companyList.values = [];

			result &&
				result.forEach((obj: any) => {
					var data = {
						label: obj.text ? obj.text : "",
						value: obj.value ? obj.value.toString() : "",
					};
					this.companyList.values.push(data);
				});
			//if (this.selectedCompany == "0") {
			this.selectedCompany = this.selectedCompany && this.selectedCompany != "0" ? this.selectedCompany : this.companyList.values[0].value;
			//}

			// code for not changing selected carrier after carrier imported
			if (localStorage.getItem("carrierIdFromAddRenewal")) {
				this.selectedCompany =
					localStorage.getItem("carrierIdFromAddRenewal") || "";

				setTimeout(() => {
					localStorage.removeItem("carrierIdFromAddRenewal");
					localStorage.removeItem("changeCompany");
				}, 100);
			} else if (
				localStorage.getItem("changeCarrierId") ||
				localStorage.getItem("loadCarrierAfterDelete")
			) {
				this.selectedCompany = this.companyList.values[0].value;
				this.defaultCarrier = this.companyList.values[0].value;
				localStorage.removeItem("changeCarrierId");
				localStorage.removeItem("loadCarrierAfterDelete");
			} else {
				localStorage.removeItem("changeCarrierId");
				localStorage.removeItem("carrierIdFromAddRenewal");
				localStorage.removeItem("fromImport");
			}

			this.searchAgents();
		} else {
			this.companyList.values = [];
			this.selectedCompany = "0";
			this.FilteredAgentsList.values = [];
			this.selectedAgent = "0";
		}
	};

	@action search = async () => {
		this.itemList.values = [];
		this.loadItems();
	};

	@action reset = async () => {
		this.selectedCompany = "0";
		this.selectedAgent = "";
		this.selectedAgentId = "0";
		this.selectedPolicy = "";
		if (this.companyList.values && this.companyList.values.length > 0) {
			this.selectedCompany = this.selectedCompany && this.selectedCompany != "0" ? this.selectedCompany : this.companyList.values[0].value;
			this.searchAgents();
		}
		this.loadItems();
	};

	@action setAgentId(value: string) {
		this.selectedAgentId = value;
	}

	@action setPolicy(value: string) {
		this.selectedPolicy = value;
		if (!value) {
			this.search();
		}
	}
	@action setImport(value: string) {
		this.selectedImport = value;
	}
	@action setExportAs(value: string) {
		this.exportAs = value;
		switch (value) {
			case "Excel":
				this.fileFormatExport = FileType._1;
				break;
			case "PDF":
				this.fileFormatExport = FileType._0;
				break;
		}
	}

	@action searchAgents = async () => {
		try {
			let data = await CommissionStore.getRenewalAgentsOfCarrierLookup(
				+this.selectedCompany
			);
			Utils.mapListItemsToDropdown(data, this.FilteredAgentsList, "All", "0");
		} catch (e) { }
	};
	@action reviewLogSearch = async (value: string) => {
		this.userSearch = value;
		if (value) {
			setTimeout(() => {
				let source = this.RenewalReviewLogListSource.values;
				var results = source.filter((model) => {
					let user = model.user ? model.user.toLowerCase() : "";
					return user.startsWith(this.userSearch.toLowerCase()) ? model : [];
				});
				this.RenewalReviewLogList.values = { ...results };
			}, 100);
		} else {
			this.RenewalReviewLogList.values = {
				...this.RenewalReviewLogListSource.values,
			};
		}
	};
	@action noPrincipalAgents() {
		this.FilteredAgentsList.values = [];
	}

	@action searchPolicies = async (value: string) => {
		try {
			if (value && value.length >= 3) {
				let result = await CommissionStore.getPoliciesBySearchTerm(
					value,
					true,
					this.selectedAgentId === "0" ? undefined : +this.selectedAgentId,
					+this.selectedCompany
				);
				if (result && result.policyNumbers && result.policyNumbers.length > 0) {
					this.FilteredPolicyNumbersList.values = result.policyNumbers;
				} else {
					this.noPolicies();
				}
			}
		} catch (e) {
			this.noPolicies();
		}
	};
	@action noPolicies() {
		var noPolicy: string = "No Records Found.";
		this.FilteredPolicyNumbersList.values = [noPolicy];
	}

	@action downloadRenewalDashboard = async () => {
		let result = await CommissionStore.getRenewalDashboardExcel(
			this.selectedAgentId && this.selectedAgentId !== "0"
				? +this.selectedAgentId
				: undefined,
			+this.selectedCompany,
			this.selectedPolicy,
			this.currentPage,
			this.pageSize,
			this.sortColumn,
			this.isSortAscending,
			undefined
		);
		await this.downloadReport(
			result,
			result.fileName ? result.fileName : "Renewal_Report.pdf"
		);
	};

	@action downloadReport = async (result: ReportFile, name: string) => {
		if (result) {
			await fetch(`data:${result.contentType};base64,${result.data}`)
				.then((response) => response.blob())
				.then(function (myBlob) {
					var link = document.createElement("a");
					var url = window.URL.createObjectURL(myBlob);
					let n = result.fileName ? result.fileName.split(".")[0] : "";
					link.href = url;
					link.download = n !== "" ? n : name;
					link.click();
					window.URL.revokeObjectURL(url);
					link.remove();
				});
		}
	};

	@action setPolicyNumber(value: string) {
		this.policyNumber = value;
	}

	@action validatePolicy = async () => {
		var result = await CommissionStore.getPoliciesByPolicyNumber(
			this.policyNumber
		);
		if (result && result.data && result.data.length > 0) {
			this.isNoPoliciesFound = false;
			if (result.data.length === 1) {
				this.selectedPolicyId = result.data[0].policyID;
				this.addRenewal();
			} else {
				this.updateSelectedPolicy(undefined);
				this.PoliciesList.values = result.data;
			}
		} else {
			this.isNoPoliciesFound = true;
		}
	};

	@action updateSelectedPolicy(value: PolicyInfoListModel | undefined) {
		this.SelecetedPolicy = value;
		if (value) {
			this.selectedPolicyId = value.policyID;
		} else {
			this.selectedPolicyId = undefined;
		}
	}

	@action removePolicies() {
		this.PoliciesList.values = [];
	}

	@action addRenewal() {
		localStorage.setItem("carrierIdFromAddRenewal", "" + this.selectedCompany);
		RouteParamDetails.policyIdForAddRenewal = this.selectedPolicyId
			? this.selectedPolicyId
			: 0;
		localStorage.setItem("renewalPolicyId", "" + this.selectedPolicyId);
		this.addItem();
	}

	@action onDrop = (value: any, fileName: string) => {
		this.fileData = value;
		this.fileName = fileName;
	};

	@action async process() {
		this.isLoading = true;

		googleTagManager.trackAction("commission_file_imported", { feature: routes.renewalDashboard.name, user_id: UserContext.userId, carrier_id: this.selectedImport || 0 });
		var blob = null;
		if (
			this.fileData != null &&
			this.fileData !== "" &&
			this.fileData !== undefined
		) {
			// Split the base64 string in data and contentType
			var block = this.fileData ? this.fileData.split(";") : "";
			// Get the content type of the image
			var contentType = block[0].split(":")[1]; // In this case "image/gif"
			// get the real base64 content of the file
			var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
			// Convert it to a blob to upload
			blob = Utils.b64toBlob(realData, contentType, 512);
		}
		try {
			this.fileData = null;
			var file = blob ? { data: blob, fileName: this.fileName } : null;
			let importName: any[] =
				this.importsList.values.length > 0
					? this.importsList.values.filter(
						(obj) => obj.value === this.selectedImport
					)
					: [];
			var result = await CommissionStore.importRenewal(
				file,
				importName && importName.length > 0 ? importName[0].label : "",
				this.fileFormatExport,
				this.selectedImport
			);
			if (result) {
				this.downloadReport(result, "Revenue");
				localStorage.setItem("fromImport", "true");
				if (this.companyList.values.length > 0) {
					this.selectedCompany = this.companyList.values[0].value;
				}
				this.loadItems();
			} else {
				// if (result.message && result.message !== null) {
				//     var responseModel = {
				//         status: 400,
				//         title: "",
				//         errors: { "ValidationError": [result.message] },
				//     };
				// }
			}
		} catch (e) { }
		this.isLoading = false;
	}

	resetFiltersToDefault() {
		this.sortColumn = this.sortRenewalColumn ? this.sortRenewalColumn : "renewalId",
			this.pageSize = 500;
	}
}
