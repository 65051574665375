import { observable, action, computed, toJS } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import RouteList from "../../../infrastructure/RouteList";
import Utils from "../../../infrastructure/Utils";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import IMASLog from "../../../infrastructure/IMASLog";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import InsuredStore from "../../../store/InsuredStore";
import ProductStore from "../../../store/ProductStore";
import {
  AgentsSearchList,
  CreateUpdatePolicyNoteModel,
  PolicyNumberList,
  CreateUpdatePolicyNumberModel,
  PolicyByIdResponseModel,
  CreateUpdatePolicyModel,
  PaymentPlanModel,
  StatusModel,
  PolicyNoteModel,
  CreateUpdateAnnuityPremiumModel,
  CreateUpdateExcessPremiumModel,
  CreateUpdateSingleLifePremiumModel,
  CommissionReceivedByPolicyListModel,
  CustAttrToEntityModel,
} from "../../../services/InsuredService";
import { ProductContractModel } from "../../../services/ProductService";
import { routes } from "../../../router";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import { AgentLookupViewModel } from "../../components/AgentLookupViewModel";
import { Permission } from "../../../infrastructure/enum/Permission";
import PageContext from "../../../infrastructure/PageContext";
import { AgentListModel } from "../../../services/ProducerSearchService";
import PolicyNotesValidator from "./PolicyNotesValidator";
import UserContext from "../../../infrastructure/UserContext";
import { Constants } from "../../../infrastructure/enum/Constants";
import { CustomAttributesComponentViewModel } from "../../../views/customers/CustomAttributesComponentViewModel";
import CustomAttributeStore from "../../../store/CustomAttributeStore";
import { googleTagManager } from "../../../infrastructure/tracking";

export class AddEditPolicyViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable endDateEnable: boolean = true;
  @observable isAgentSearchDialogVisible: boolean = false;
  @observable AgentLookupViewModel: AgentLookupViewModel =
    new AgentLookupViewModel();
  @observable isEdit: boolean = false;
  @observable isValid = true;
  @observable isAddPrimary: boolean = false;
  @observable isStatusSaved: boolean = false;
  @observable policyId: number = 0;
  @observable premiumDate: Date | undefined = new Date();
  @observable statementDate: Date | undefined = new Date();
  @observable premiumAmount: number = 0;
  @observable commissionReceivedAmount: number = 0;
  @observable prefix: string = "$";
  @observable premiumOverride: string = "";
  @observable customer: string = "";
  @observable customerDob: string = "";
  @observable product: string = "";
  @observable company: string = "";
  @observable companyId: number = 0;
  @observable plan: string = "";
  @observable license: string = "";
  @observable licenseNumbersList = [{ label: "None", value: "0" }];
  @observable isSoldDateValid: boolean = false;
  @observable isEffectiveDateValid: boolean = false;
  @observable marketer: string = "";
  @observable payTo: string = "";
  @observable productCategoryName: string = "";
  @observable location: string = "";
  @observable selectedLocation: string = "0";
  @observable LocationList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable writingNumberList = new Collection<{
    label: string;
    value: string;
    selected: boolean;
  }>();

  @observable licenseNumberID: number = 0;
  @observable selectedAgent: string = "";
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable policyPremiumDataList = new Collection<PolicyPremium>();
  @observable commissionReceivedDataList =
    new Collection<CommissionReceivedByPolicyListModel>();

  @observable policyNumersHistory = new Collection<PolicyNumberList>();
  @observable division = "";
  @observable pendingInfo = "";
  @observable memberCount = "";
  @observable groupName = "";
  @observable policy = "";
  @observable isException: boolean = false;
  @observable exceptionMsg: string = "";
  @observable isSuccess: boolean = false;
  @observable issueAge = "";
  @observable pcp: string = "";
  @observable isSaveAge: boolean = false;
  @observable ssboCreditCard: boolean = false;
  @observable thisIsCopy: boolean = false;
  @observable initialDeposit: boolean = false;
  @observable targetPremium: number = 0;
  @observable sellingAgent: number = 0;
  @observable premiumId: number = 0;
  @observable commissionReceivedId: number = 0;
  @observable policyNumberObj: PolicyNumberList | null = null;
  @observable enrollment: string = "";
  @observable agencyPercentage: number = 0;
  @observable deathBenfit: number = 0;
  @observable selectedPanel: string = "Summary";
  @observable policyDetails: PolicyByIdResponseModel | null = null;
  //@observable productDetails: ProductByProfileModel | null = null
  @observable policyPaymentPlan: PaymentPlanModel | null = null;
  @observable policyStatusModel: StatusModel | null = null;
  @observable policyAnnualPremiumValue: string = "";
  @observable commissionReceived: string = "";
  @observable policySPLifePremiumValue: string = "";
  @observable policyExcessPremiumValue: string = "";
  @observable commissionReceivedValue: string = "";
  @observable NotesList = new Collection<PolicyNoteModel>();
  @observable ProductContractList = new Collection<ProductContractModel>();
  @observable sortColumn: string | undefined = "Id";
  @observable sortOrder: boolean = false;
  @observable pageIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable rows: number = 25;
  @observable sortColumnContract: string = "id";
  @observable sortOrderContract: boolean = false;
  @observable pageIndexContract: number = 0;
  @observable totalRecordsContract: number = 0;
  @observable rowsContract: number = 100;
  @observable selectedTabIndex: number | undefined = 0;
  @observable tempAnnualPremiumList: any;
  @observable tempSPLifePremiumList: any;
  @observable tempExcessPremiumList: any;
  @observable tempCommissionReceivedList: any;
  @observable isReplaceNoteConfirm: boolean = false;
  @observable selectedNoteId: number | undefined;
  @observable selectedPolicyId: number | undefined;
  @observable selectedmarkAsRevenueNote: boolean = false;
  @observable noteConfirmationMeassage: string =
    "You can view only one note on the Revenue Dashboard. Do you wish to replace the previous enabled note?";
  @observable fetchrowIndex: number = 0;
  @observable
  public ErrorModel = new ErrorModel(new PolicySummaryValidator());
  @observable
  public ErrorModelNote = new ErrorModel(new PolicyAddNoteValidator());
  @observable
  public PremiumErrorModel = new ErrorModel(new PolicyPremiumValidator());

  @observable
  public CommissionReceivedErrorModel = new ErrorModel(
    new CommissionReceivedValidator()
  );

  @observable
  public PolicyNotesErrorModel = new ErrorModel(PolicyNotesValidator);
  @observable premiumActionItems: MenuItem[] = [
    {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeletePremiumConfirm = true;
      },
    },
  ];
  @observable commissionReceivedActionItems: MenuItem[] = [
    {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeleteCommissionReceivedConfirm = true;
      },
    },
  ];

  //custom attribute states
  @observable addAttributesModal: boolean = false;
  @observable
  CustomAttributesComponentViewModel: CustomAttributesComponentViewModel =
    new CustomAttributesComponentViewModel();
  @observable attributesScreenPath: number = 0;
  @observable isCustomAttrActive: boolean = false;
  @observable customAttributeList: any = [];
  @observable attributesScreenPathId: number = 0;
  @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();
  @action Load = async (
    id: number,
    policyId: number | undefined,
    customerName: string | undefined
  ) => {        
    PageContext.isCustomAttribute = false;
    this.productException = false;
    this.isSoldDateValid = false;
    this.isEffectiveDateValid = false;

    if (
      UserContext.permissions.length > 0 &&
      !Utils.hasUserPermission(Permission.AddPolicy) &&
      !Utils.hasUserPermission(Permission.EditPolicy)
    ) {
      this.isAddEditPermission = true;
    } else {
      this.isAddEditPermission = false;
    }
    let delay = 0;
    if (UserContext.permissions.length <= 0) {
      delay = 2000;
    }
    this.title = policyId ? "Edit Policy" : "Add Policy";
    PageContext.setIsLoadingVisible(true);
    this.customerDob = RouteParamDetails.customerDob;
    setTimeout(async () => {
      if (policyId) {
        this.agentId = id;
        this.title = "Edit Policy";
        this.policyId = policyId;
        this.isEdit = true;
        this.isStatusSaved = true;
        this.isAddPrimary = false;
        this.customerDob = RouteParamDetails.customerDob;
        if (Utils.hasUserPermission(Permission.EditPolicy)) {
          await this.loadPolicyDetails();
        }
        PageContext.setIsLoadingVisible(false);
      } else {
        if (Utils.hasUserPermission(Permission.AddPolicy)) {
          this.policyId = 0;
          await this.loadPolicyDetails();
          this.title = "Add New Policy";
          this.resetPolicySummary();
          this.isStatusSaved = false;
          this.isAddPrimary = true;
          this.isEdit = false;
          this.customerId = +id;
          this.customer = RouteParamDetails.customerName;
          this.policyExcessPremiumValue = Utils.getCurrencyFormat(undefined);
          this.policySPLifePremiumValue = Utils.getCurrencyFormat(undefined);
          this.policyAnnualPremiumValue = Utils.getCurrencyFormat(undefined);
          this.setIsAgentSearchDialogVisible(true);
          this.setIssueAge(
            RouteParamDetails.customerAge
              ? RouteParamDetails.customerAge.replace("(", "").replace(")", "")
              : ""
          );
        }
        PageContext.setIsLoadingVisible(false);
      }
    }, delay);
    this.getAgentSearchList();
    // get Custom attribute screen permission
    PageContext.isCustomAttribute = false;
    this.response = "";
    localStorage.removeItem("CustomAttribute");
    var res = await this.getCustomAttributePermissionByScreen();
    if (res) {
      let data = res.filter(
        (x) => x.path === Permission.AddEditPolicyScreen && x.active === true
      );
      this.isCustomAttrActive = data.length > 0 ? true : false;
      this.attributesScreenPath = data[0].id || 0;

      var attributes = await this.getCustomAttributesByScreen(data[0].id || 2);
      if (attributes) {
        this.customAttributeList = attributes;
      }

      this.attributesScreenPathId = data[0].id || 2;
    }
  };

  @action async getCustomAttributePermissionByScreen() {
    try {
      var res = await CustomAttributeStore.getAllScreens();
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(
        screenId,
        this.policyId
      );
      return res;
    } catch (e) {
      throw e;
    }
  }
  @action loadLocationLookupByContractId = async (contractId: number) => {
    let res = await InsuredStore.getPolicyLocations(contractId);
    res && Utils.mapListItemsToDropdown(res, this.LocationList, "None", "0");
    this.selectedLocation = "0";
  };
  @action getAgentSearchList = () => {
    this.searchTypeAgensSearchList = [
      {
        label: "Last Name",
        value: "1",
        disabled: !Utils.hasUserPermission(Permission.LookupAgentByLastName),
      },
      {
        label: "Writing Number #",
        value: "2",
        disabled: !Utils.hasUserPermission(
          Permission.LookupAgentByWritingNumber
        ),
      },
    ];
  };
  @action AddPolicy = async (customerId: any, customerName: string) => {
    this.title = "Add New Policy";
    this.isEdit = false;
    this.policyId = 0;
    await this.loadPolicyDetails();
  };

  @action loadPolicyDetails = async () => {
    try {
      this.setIsException(false);
      this.setIsSuccess(false);
      this.resetPolicySummary();
      this.ResetValidatePolicySummary();
      this.ResetValidatePolicyAddNote();
      this.isLoading = true;
      if (this.policyId === 0) {
        this.policyDetails = await InsuredStore.getPolicySummary(
          this.policyId,
          0
        );
      } else {
        this.policyDetails = await InsuredStore.getPolicyDetails(this.policyId);
        this.loadPremiumValuesOnlyOnce();
        this.loadCommissionReceivedOnlyOnce();
      }
      if (this.policyDetails) {
        this.agentId = this.policyDetails.agentId;
        this.productId = this.policyDetails.productId;
        this.licenseId = this.policyDetails.licenseId;
        this.profileId = this.policyDetails.profileId;
        this.contractId = this.policyDetails.licenseId
          ? this.policyDetails.licenseId
          : 0;
        this.customer = this.policyDetails.customer
          ? this.policyDetails.customer +
            " (#" +
            this.policyDetails.customerId +
            ")"
          : "";
        this.license = this.policyDetails.licenseId
          ? "" + this.policyDetails.licenseId
          : "";
        this.licenseNumber = this.policyDetails.licenseNumber
          ? "" + this.policyDetails.licenseNumber
          : "";

        if (this.policyDetails.licenseNumbers) {
          let number = this.policyDetails.licenseNumbers.filter((i) => {
            return i.number == this.licenseNumber;
          });
          this.licenseNumberID =
            number && number.length > 0 ? number[0].id || 0 : 0;
          this.writingNumberList.values = [];
          var assign = [];
          if (this.policyDetails.licenseNumbers == null) {
            var defaultItem = { label: "None", value: "0", selected: true };
            assign.push(defaultItem);
          }

          if (this.policyDetails.licenseNumbers != null) {
            this.policyDetails.licenseNumbers.forEach((item) => {
              var data = {
                label: item.number ? item.number : "",
                value: item.id ? item.id + "" : "",
              };
              assign.push(data);
            });
          }
          this.writingNumberList.values = assign;
        }

        if (this.policyDetails.licenseNumber) {
          this.licenseNumbersList = [];
          this.licenseNumbersList.push({
            label: "" + this.policyDetails.licenseNumber,
            value: "" + this.policyDetails.licenseId,
          });
          this.setLicense(
            this.policyDetails.licenseId
              ? "" + this.policyDetails.licenseId
              : "0"
          );
        }
        this.company = this.policyDetails.company
          ? this.policyDetails.company
          : "";
        this.plan = this.policyDetails.plan ? this.policyDetails.plan : "";
        this.marketer = this.policyDetails.marketer
          ? this.policyDetails.marketer
          : "";
        this.payTo = this.policyDetails.payTo ? this.policyDetails.payTo : "";
        this.division = this.policyDetails.division
          ? this.policyDetails.division
          : "";

        this.pcp = this.policyDetails.pcp ? this.policyDetails.pcp : "";

        this.cashWApp = this.policyDetails.cashWithApp
          ? this.policyDetails.cashWithApp.toString()
          : "0";
        var soldDate = Utils.getDateInFormat(
          this.policyDetails.dateSold && this.policyId !== 0
            ? this.policyDetails.dateSold
            : new Date()
        );
        this.soldDate = new Date(soldDate);
        var orginalEffectDate = Utils.getDateInFormat(
          this.policyDetails.originalDateEffective && this.policyId !== 0
            ? this.policyDetails.originalDateEffective
            : this.DefaultDate()
        );
        this.orginalEffectDate = new Date(orginalEffectDate);
        var effeciveDate = Utils.getDateInFormat(
          this.policyDetails.dateEffective && this.policyId !== 0
            ? this.policyDetails.dateEffective
            : this.DefaultDate()
        );
        this.effectiveDate = new Date(effeciveDate);
        this.premium = this.policyDetails.premium
          ? this.policyDetails.premium.toString()
          : "0";
        this.annualPremium = this.policyDetails.annualPremium
          ? this.policyDetails.annualPremium.toString()
          : "0";
        this.selectedAgent = this.policyDetails.agent
          ? this.policyDetails.agent
          : "";
        this.product = this.policyDetails.product
          ? this.policyDetails.product
          : "";
        this.selectedMode = this.policyDetails.modeId
          ? "" + this.policyDetails.modeId
          : "8";
        this.customerId = this.policyDetails.customerId;
        // this.mgrId = this.policyDetails.mgrId
        this.locationPremierId = this.policyDetails.locationPremierId;
        this.loadModes();
        this.loadLicenseNumbers();
        this.loadApprovedRatings();
        this.loadPaymentTypes();
        this.loadCommissionTypes();
        this.loadCommissionTypes();
        this.loadAppliedRatings();
        this.loadStatus();
        this.loadPremiumAmountValues();
        await this.loadLocationLookupByContractId(this.contractId || 0);
        this.company = this.policyDetails.company
          ? this.policyDetails.company
          : "";
        this.companyId = this.policyDetails.companyId
          ? this.policyDetails.companyId
          : 0;
        //Load payment plan status
        if (this.policyId !== 0) {
          this.issueAge = this.policyDetails.issueAge
            ? "" + this.policyDetails.issueAge
            : "";
        } else {
          this.issueAge = this.customerDob
            ? this.getDateDiff(
                this.effectiveDate ? this.effectiveDate : new Date(),
                this.customerDob
              )
            : this.issueAge;
        }
        this.isSaveAge = this.policyDetails.saveAge
          ? this.policyDetails.saveAge
          : false;
        // this.sellingAgent = this.policyDetails.agentPercent
        //   ? this.policyDetails.agentPercent
        //   : 0;
        this.enrollment = this.policyDetails.enrollmentNumber
          ? "" + this.policyDetails.enrollmentNumber
          : "";
        this.targetPremium = this.policyDetails.targetPremium
          ? this.policyDetails.targetPremium
          : 0;
        // this.selectedPaymentType = this.policyDetails.paymentTypeId
        //   ? "" + this.policyDetails.paymentTypeId
        //   : "1";
        this.approvedRating = this.policyDetails.approvedRatingId
          ? "" + this.policyDetails.approvedRatingId
          : "0";
        this.ssboCreditCard = this.policyDetails.ssboCreditCard
          ? this.policyDetails.ssboCreditCard
          : false;
        this.policy = this.policyDetails.policy
          ? "" + this.policyDetails.policy
          : "";
        this.deathBenfit = this.policyDetails.deathBenefit
          ? this.policyDetails.deathBenefit
          : 0;
        // this.agencyPercentage = this.policyDetails.agencyPercent
        //   ? this.policyDetails.agencyPercent
        //   : 0;
        this.commission = this.policyDetails.commission
          ? this.policyDetails.commission
          : 0;
        this.selectedAdvance = this.policyDetails.advanceMonth
          ? "" + this.policyDetails.advanceMonth
          : "0";
        this.selectedCommissionType = this.policyDetails.commissionTypeId
          ? "" + this.policyDetails.commissionTypeId
          : "1";
        this.appliedRating = this.policyDetails.appliedRatingId
          ? "" + this.policyDetails.appliedRatingId
          : "0";
        //Load policy status details
        this.setStatus(
          this.policyDetails.statusId ? "" + this.policyDetails.statusId : "2"
        );
        this.pendingInfo = this.policyDetails.pendingInfo
          ? this.policyDetails.pendingInfo
          : "";
        var datePosted = Utils.getDateInFormat(
          this.policyDetails.datePosted
            ? this.policyDetails.datePosted
            : new Date()
        );
        this.postedDate = new Date(datePosted);
        var dateEntered = Utils.getDateInFormat(
          this.policyDetails.dateEntered
            ? this.policyDetails.dateEntered
            : new Date()
        );
        this.enteredDate = new Date(dateEntered);

        if (this.policyDetails.dateSurrender) {
          var dateEnd = Utils.getDateInFormat(this.policyDetails.dateSurrender);
          this.endDate = dateEnd ? new Date(dateEnd) : undefined;
        } else {
          this.endDate = undefined;
        }
        this.endDateEnable = !(
          this.status === "4" ||
          this.status === "9" ||
          (this.isEdit &&
            (this.status === "3" || this.status === "6" || this.status === "7"))
        );

        if (this.policyDetails.replacedDate) {
          var replacedDate = Utils.getDateInFormat(
            this.policyDetails.replacedDate
          );
          this.replacedDate = replacedDate ? new Date(replacedDate) : undefined;
        } else {
          this.replacedDate = undefined;
        }

        if (this.policyId && this.policyId !== 0) {
          this.loadPolicyNotes(this.policyId);
        }
      }
      await this.loadProductInfo();
      //
      this.selectedLocation = this.policyDetails.locationPremierId
        ? "" + this.policyDetails.locationPremierId
        : "0";
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  };
  @action getDateDiff(effdt: any, dob: any) {
    var today = new Date(effdt);
    var b = new Date(dob);
    var age_now = today.getFullYear() - b.getFullYear();
    var m = today.getMonth() - b.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < b.getDate())) {
      age_now--;
    }
    return age_now.toString();
  }
  @action resetPolicySummary() {
    this.endDateEnable = true;
    this.isSoldDateValid = false;
    this.isEffectiveDateValid = false;
    this.searchTypeAgensSearch = "2";
    this.agentId = 0;
    this.productId = 0;
    this.licenseId = 0;
    this.contractId = 0;
    this.profileId = 0;
    this.customer = "";
    this.license = "";
    this.company = "";
    this.plan = "";
    this.marketer = "";
    this.payTo = "";
    this.productCategoryName = "";
    this.cashWApp = "0";
    this.soldDate = new Date();
    this.postedDate = new Date();
    this.enteredDate = new Date();
    this.policy = "";
    this.selectedPaymentType = "1";
    this.enrollment = "";
    this.targetPremium = 0;
    this.deathBenfit = 0;
    this.selectedCommissionType = "1";
    this.approvedRating = "0";
    this.appliedRating = "0";
    this.sellingAgent = 0;
    this.commission = 0;
    this.selectedAdvance = "0";
    this.agencyPercentage = 0;
    this.premium = "0";
    this.annualPremium = "0";
    this.selectedLocation = "0";
    this.selectedAgent = "";
    this.product = "";
    this.selectedMode = "0";
    this.customerId = 0;
    this.mgrId = 0;
    this.locationPremierId = 0;
    this.policy = "";
    this.status = "2";
    this.endDate = undefined;
    this.replacedDate = undefined;
    this.pendingInfo = "";
    this.effectiveDate = this.DefaultDate();
    this.orginalEffectDate = this.DefaultDate();
    this.issueAge = "";
    this.notes = "";
    this.copyMarketer = false;
    this.createFollowup = false;
    this.selectePolicyNoteDate = new Date();
    this.noteType = "1";
    this.division = "";
    this.licenseNumber = "";
    this.policyAnnualPremiumValue = "";
    this.commissionReceived = "";
    this.policySPLifePremiumValue = "";
    this.policyExcessPremiumValue = "";
    this.tempAnnualPremiumList = [];
    this.tempExcessPremiumList = [];
    this.tempSPLifePremiumList = [];
    this.thisIsCopy = false;
    this.ssboCreditCard = false;
    this.excessCount = 0;
    this.annuityCount = 0;
    this.spLifeCount = 0;
  }
  loadPremiumAmountValues() {
    if (this.policyDetails) {
      this.policyExcessPremiumValue = Utils.getCurrencyFormat(
        this.policyDetails.excessAmount
      );
      this.policySPLifePremiumValue = Utils.getCurrencyFormat(
        this.policyDetails.spLife
      );
      this.policyAnnualPremiumValue = Utils.getCurrencyFormat(
        this.policyDetails.annuityAmount
      );
      this.commissionReceivedValue = Utils.getCurrencyFormat(
        this.policyDetails.commissionReceivedAmount
      );
    }
  }
  loadStatus() {
    if (this.policyDetails && this.policyDetails.statuses) {
      this.statusList = [];
      var sortList = [...this.policyDetails.statuses].sort(
        Utils.compareListItem
      );
      for (let item of sortList) {
        this.statusList.push({ label: "" + item.text, value: "" + item.value });
      }
      this.status = "2";
    }
  }
  loadModes() {
    if (this.policyDetails && this.policyDetails.modes) {
      this.modeList = [];
      var sortList = [...this.policyDetails.modes].sort(Utils.compareListItem);
      for (let item of sortList) {
        this.modeList.push({ label: "" + item.text, value: "" + item.value });
      }
      if (this.isEdit)
        this.selectedMode = this.policyDetails.modeId
          ? "" + this.policyDetails.modeId
          : "8";
      else this.selectedMode = "8";
    }
  }
  loadLicenseNumbers() {
    if (
      this.policyDetails &&
      this.policyDetails.licenseNumbers &&
      this.policyDetails.licenseNumbers.length > 0
    ) {
      this.licenseNumbersList = [];
      for (let item of this.policyDetails.licenseNumbers) {
        this.licenseNumbersList.push({
          label: "" + item.number,
          value: "" + item.id,
        });
      }
      this.setLicense(this.policyDetails.licenseNumbers[0].id + "");
    }
  }
  loadApprovedRatings() {
    if (this.policyDetails && this.policyDetails.approvedRatings) {
      this.approvedRatingList = [{ label: "None", value: "0" }];
      var sortList = [...this.policyDetails.approvedRatings].sort(
        Utils.compareListItem
      );
      for (let item of sortList) {
        this.approvedRatingList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.approvedRating = "0";
    }
  }
  loadPaymentTypes() {
    if (this.policyDetails && this.policyDetails.paymentTypes) {
      this.paymentTypeList = [];
      var sortList = [...this.policyDetails.paymentTypes].sort(
        Utils.compareListItem
      );
      for (let item of sortList) {
        this.paymentTypeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.selectedPaymentType = "1";
    }
  }
  loadCommissionTypes() {
    if (this.policyDetails && this.policyDetails.commissionTypes) {
      this.commissionTypeList = [];
      var sortList = [...this.policyDetails.commissionTypes].sort(
        Utils.compareListItem
      );
      for (let item of sortList) {
        this.commissionTypeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.selectedCommissionType = "1";
    }
  }
  loadAppliedRatings() {
    if (this.policyDetails && this.policyDetails.appliedRatings) {
      this.appliedRatingList = [{ label: "None", value: "0" }];
      var sortList = [...this.policyDetails.appliedRatings].sort(
        Utils.compareListItem
      );
      for (let item of sortList) {
        this.appliedRatingList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.appliedRating = "0";
    }
  }
  private async loadPolicyNotes(policyId: number) {
    try {
      this.isLoading = true;
      this.setIsException(false);
      this.noPolicyNotes();
      var notesResult = await InsuredStore.getPoliciyNotes(
        policyId,
        +this.selectedType,
        this.pageIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (notesResult && notesResult.data) {
        this.totalRecords = notesResult.recordCount
          ? notesResult.recordCount
          : 0;
        this.NotesList.values = notesResult.data;
      }
      this.isLoading = false;
    } catch (e) {
      this.noPolicyNotes();
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  }
  private async loadPolicyNumberHistory() {
    try {
      this.isLoading = true;
      this.setIsException(false);
      this.policyNumersHistory.values = [];
      var result = await InsuredStore.getPolicyNumbers(this.policyId);
      if (result) {
        this.policyNumersHistory.values = result;
      }
      this.isLoading = false;
    } catch (e) {
      this.policyNumersHistory.values = [];
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  }
  @action async loadProductInfo() {
    try {
      this.isLoading = true;
      this.setIsException(false);
      if (this.agentId) {
        this.ProductContractList.values = [];
        let status =
          this.productContractStaus === "All"
            ? undefined
            : this.productContractStaus;
        var result = await ProductStore.getProductInfo(
          this.agentId,
          this.companyId,
          status,
          this.license == "null" ? 0 : Number(this.license),
          this.licenseNumber ? this.licenseNumber : "",
          this.pageIndexContract / this.rowsContract,
          this.rowsContract,
          this.sortColumnContract,
          this.sortOrderContract
        );
        if (result && result.data) {
          this.totalRecordsContract = result.recordCount
            ? result.recordCount
            : 0;
          this.ProductContractList.values = result.data;
        } else {
          this.ProductContractList.values = [];
        }
      }
      this.isLoading = false;
    } catch (e) {
      this.exceptionResponse = e.response;
      this.isLoading = false;
      this.setIsException(true);
    }
  }
  @action goToLicenseTab() {
    if (this.licenseId && this.selectedAgent && this.agentId) {
      routes.contractDetails.push({
        agentId: "" + this.agentId,
        contractId: this.licenseId,
      });
    }
  }
  @action noPolicyNotes() {
    this.totalRecords = 0;
    this.NotesList.values = [];
  }
  @action setReplaceNoteConfirmation(value: boolean) {
    this.isReplaceNoteConfirm = value;
  }
  @action pinUnpinNote = async () => {
    await InsuredStore.pinUnpinNote(
      this.selectedNoteId != undefined ? this.selectedNoteId : 0,
      this.selectedmarkAsRevenueNote,
      this.selectedPolicyId != undefined ? this.selectedPolicyId : 0
    );
    this.sortColumn = "noteDate";
    this.sortOrder = false;
    this.pageIndex = 0;
    this.rows = 10;
    setTimeout(() => {
      this.loadPolicyNotes(
        this.selectedPolicyId != undefined ? this.selectedPolicyId : 0
      );
    }, 300);
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setProductException(value: boolean) {
    this.productException = value;
  }
  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action setTargetPremium(value: string) {
    this.targetPremium = Utils.formatNum(value);
  }
  @action setSellingAgent(value: string) {
    this.sellingAgent = Utils.formatNum(value);
  }
  @action setSelectedPremiumId(value: number) {
    this.premiumId = value;
  }
  @action setSelectedCommissionReceivedId(value: number) {
    this.commissionReceivedId = value;
  }
  @action setSelectedPolicyNumber(value: PolicyNumberList) {
    this.policyNumberObj = value;
  }
  @action setEnrollment(value: string) {
    this.enrollment = value;
  }

  @action setAgencyPercentage(value: string) {
    this.agencyPercentage = Utils.formatNum(value);
  }
  @action setDeathBenefit(value: string) {
    this.deathBenfit = Utils.formatNum(value);
  }
  @action setPendingInfo(value: string) {
    this.pendingInfo = value;
  }
  @action setMemberCount(value: string) {
    this.memberCount = value;
  }
  @action setGroupName(value: string) {
    this.groupName = value;
  }
  @action setPolicy(value: string) {
    this.policy = value;
  }
  @action setPrmiumDate(date: Date | undefined) {
    this.premiumDate = date;
  }
  @action setStatementDate(date: Date | undefined) {
    this.statementDate = date;
  }
  @action setPremiumAmount(value: string) {
    //if (this.premiumDialogTitle == "Annuity Premium" && value && value.length > 10 && !value.includes(".")) {
    //    value = value.substring(0, 10);
    //}
    this.premiumAmount = Utils.formatNum(value);
  }
  @action setCommissionReceivedAmount(value: string) {
    this.commissionReceivedAmount = Utils.formatNum(value);
  }

  @action setPremiumOverride(value: string) {
    this.premiumOverride = value;
  }

  private async loadPremiumValuesFromApi() {
    var excessPremiums = await InsuredStore.getPolicyExcessPremium(
      this.policyId
    );
    var excessPremiumValue = 0;

    excessPremiums.forEach((obj: any) => {
      if (obj && obj.amount) excessPremiumValue += obj.amount ? obj.amount : 0;
    });
    this.policyExcessPremiumValue = Utils.getCurrencyFormat(excessPremiumValue);
    var annualPremiumValue = 0;
    var annualPremiums = await InsuredStore.getPolicyAnnuityPremiums(
      this.policyId
    );
    annualPremiums.forEach((obj: any) => {
      if (obj && obj.amount) annualPremiumValue += obj.amount ? obj.amount : 0;
    });
    this.policyAnnualPremiumValue = Utils.getCurrencyFormat(annualPremiumValue);
    var spLifePremiumValue = 0;
    var splifePremiums = await InsuredStore.getPolicySingleLifePremiums(
      this.policyId
    );
    splifePremiums.forEach((obj: any) => {
      if (obj && obj.amount) spLifePremiumValue += obj.amount ? obj.amount : 0;
    });
    this.policySPLifePremiumValue = Utils.getCurrencyFormat(spLifePremiumValue);
  }

  @action cancel() {
    localStorage.removeItem("CustomAttribute");
    this.isSaveButtonEnabled = true;
    var local_url = window.location;
    if (local_url.href.includes("/agents/")) {
      routes.agentPolicies.replace({ agentId: "" + this.agentId });
    } else if (local_url.href.includes("/customers/")) {
      routes.customerPolicies.push({ customerId: "" + this.customerId });
    }
  }

  @action gotoSummary() {
    if (this.isEdit && !this.isAddPrimary) {
      routes.customerDetails.push({ customerId: "" + this.customerId });
    } else {
      routes.customerPolicies.push({ customerId: "" + this.customerId });
    }
  }

  @action goToRevenueHistory() {
    routes.customerpolicyRevenueHistoryDetails.push({
      customerId: "" + this.customerId,
      policyId: +this.policyId,
      tabIndex: 1,
    });
  }

  @action gotoPolicies() {
    let url = window.location.pathname.split("/");
    if (url && url[1] === "customers") {
      routes.customerPolicies.push({ customerId: "" + this.customerId });
    } else if (url[1] === "agents") {
      routes.agentPolicies.push({ agentId: "" + this.agentId });
    }
  }

  @action save = async () => {
    try {
      //this.isLoading = true         
      this.setIsException(false);
      this.setProductException(false);
      var result = null;
      if (this.product) {
        if (this.soldDate && this.soldDate > new Date()) {
          this.isSoldDateValid = true;
          return false;
        } else {
          this.isSoldDateValid = false;
        }
        this.productException = false;
        this.isSaveButtonEnabled = false;
        await this.addEditPolicySummary(result);       
      } else {
        //this.isSaveButtonEnabled = true;
        this.productException = true;
        var x = document.getElementById("divAlert");
        if (x !== null) {
          if (x.style.display === "none") {
            x.style.display = "block";
          }
        }
        PageContext.setIsMessageVisible(false);
      }
      if (result) 
      {
        this.setIsSuccess(true); 
        this.isSaveButtonEnabled = false;
      }
      else{        
        this.isSaveButtonEnabled = true;        
      }
      this.isLoading = false;
    } catch (e) {      
      this.isSaveButtonEnabled = true;
      this.isLoading = false;
    }
  };
  @action savePolicyNumberHistory = async () => {
    try {
      let dto: CreateUpdatePolicyNumberModel[] = [];
      this.policyNumersHistory.values.forEach((obj) => {
        var policyNumber: CreateUpdatePolicyNumberModel = {
          policyId: this.policyId,
          policyNumber: obj.policyNumber,
          policyNumberId: obj.policyNumberId,
          dateCreated: obj.dateEntered,
          isValid: obj.isValid,
        };
        dto.push(policyNumber);
      });

      await InsuredStore.postPolicyNumber(dto);
      this.isLoading = false;
      this.setPolicyNumberSearchDialogVisible(false);
    } catch (e) {
      this.isLoading = false;
    }
  };
  @action setIssueAge(value: string) {
    this.issueAge = value;
  }
  @action setPCP(value: string) {
    this.pcp = value;
  }
  @action setMarketingEvent(value: string) {
    this.selectedMarketingEvent = value;
  }
  @action setMode(value: string) {
    this.selectedMode = value;
    this.calculateAnnualPremium();
  }

  @action setAdvance(value: string) {
    this.selectedAdvance = value;
  }

  @action setProductContractStats(value: string) {
    this.productContractStaus = value;
    this.loadProductInfo();
  }
  @action setProductContractCompany(value: string) {
    this.productContractCompany = value;
  }
  @action setStatus(value: string) {
    this.status = value;
    if (
      value === "4" ||
      value === "9" ||
      (this.isEdit && (value === "3" || value === "6" || value === "7"))
    ) {
      this.endDateEnable = false;
      this.endDate = new Date();
    } else {
      this.endDateEnable = true;
      this.endDate = undefined;
    }
  }
  @action setCommissionType(value: string) {
    this.selectedCommissionType = value;
  }
  @action setPaymentType(value: string) {
    this.selectedPaymentType = value;
  }
  @action setAppliedRating(value: string) {
    this.appliedRating = value;
  }
  @action setApprovedRating(value: string) {
    this.approvedRating = value;
  }
  @action setCashWApp(value: string) {
    this.cashWApp = value;
  }
  @action setPremium(value: string) {
    if (value == "-") value = "0";
    this.premium = Utils.getValueWithNegativeAndPrefix(value, this.prefix);
  }
  @action unfocusCashWApp() {
    //this.premium = Utils.formatNum(this.cashWApp).toString();
    this.getCalculatedCommission();
    this.calculateAnnualPremium();
  }
  @action unfocusPremium() {
    this.calculateAnnualPremium();
  }
  @action calculateAnnualPremium() {
    IMASLog.log("annual premium mode " + this.selectedMode);
    let times = 6;
    switch (this.selectedMode) {
      case "1":
        times = 1;
        break;
      case "2":
        times = 2;
        break;
      case "3":
        times = 4;
        break;
      // case "4":
      //     times = 6;
      //     break
      // case "5":
      //     times = 6;
      //     break
      case "8":
        times = 12;
        break;
      // case "9":
      //     times = 6;
      //     break;
    }
    this.annualPremium = (times * Utils.formatNum(this.premium)).toString();
  }
  @action setAnnualPremium(value: string) {
    this.annualPremium = value;
  }

  @action setCommissionValue(value: string) {
    this.commission = Utils.formatNum(value);
  }
  @action setSoldDate(date: Date | undefined) {
    this.soldDate = date;
    if (date instanceof Date) {
      if (date > new Date()) {
        this.isSoldDateValid = true;
      } else {
        this.isSoldDateValid = false;
      }
    }
  }
  @action setEffectiveDate(date: Date | undefined) {
    this.effectiveDate = date;
    const tempFromDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 25)
    );
    const fromDate = new Date(
      tempFromDate.getUTCFullYear(),
      tempFromDate.getUTCMonth(),
      tempFromDate.getUTCDate()
    );
    const toDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );

    if (date instanceof Date && (date < fromDate || date >= toDate)) {
      this.isEffectiveDateValid = true;
    } else {
      this.isEffectiveDateValid = false;
    }
    if (!this.isEdit) {
      this.orginalEffectDate = this.effectiveDate;
      this.issueAge = this.customerDob
        ? this.getDateDiff(
            this.effectiveDate ? this.effectiveDate : new Date(),
            this.customerDob
          )
        : this.issueAge;
    }
  }
  @action setOrginalEffectiveDate(date: Date | undefined) {
    this.orginalEffectDate = date;
  }
  @action setEnteredDate(date: Date | undefined) {
    this.enteredDate = date;
  }
  @action setPostedDate(date: Date | undefined) {
    this.postedDate = date;
  }

  @action setselectePolicyNoteDate(date: Date | undefined) {
    this.selectePolicyNoteDate = date;
  }

  @action setEndDate(date: Date | undefined) {
    this.endDate = date;
  }
  @action setIsSaveAge(value: boolean) {
    this.isSaveAge = value;
  }
  @action setSSBOCreditCard(value: boolean) {
    this.ssboCreditCard = value;
  }
  @action setthisIsCopy(value: boolean) {
    this.thisIsCopy = value;
  }
  @action setInitialDeposit(value: boolean) {
    this.initialDeposit = value;
  }
  @action setReplacedDate(date: Date | undefined) {
    this.replacedDate = date;
  }
  @action searchIntervals = (text: string) => {
    this.selectedAgent = text;
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @action onHide = async (value: string) => {
    switch (value) {
      case "selectCotract":
        this.setSelectContractDialogVisible(false);
        break;
      case "addPolicyNote":
        this.setPolicyNoteDialogVisible(false);
        break;
      case "addPremium":
        this.setAddPremiumDialogVisible(false);
        break;
      case "agentSearch":
        this.setIsAgentSearchDialogVisible(false);
        break;
    }
  };
  @action onCommisionReceivedHide = async () => {
    this.setEditCommissionReceivedDialogVisible(false);
  };

  @observable replacedDate: Date | undefined;
  @observable enteredDate: Date | undefined = new Date();
  @observable endDate: Date | undefined;
  @observable postedDate: Date | undefined = new Date();
  @observable soldDate: Date | undefined = new Date();
  @observable orginalEffectDate: Date | undefined = new Date();
  @observable effectiveDate: Date | undefined = new Date();
  @observable premium: string = "0";
  @observable annualPremium: string = "0";
  @observable commission: number = 0;
  @observable isLoading: boolean = true;
  @observable productException: boolean = false;
  @observable isContractingSent: boolean = true;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable selectedUser: string = "";
  @observable isAddInputAvaliable: boolean = false;
  @observable isCommissionReceivedAddInputAvaliable: boolean = false;
  @observable isAddPremiumDialogVisible: boolean = false;
  @observable isEditCommissionReceivedDialogVisible: boolean = false;
  @observable isEditPremium: boolean = false;
  @observable isEditCommissionReceived: boolean = false;
  @observable isDeletePremiumConfirm: boolean = false;
  @observable isDeleteCommissionReceivedConfirm: boolean = false;

  @observable first: number = 0;
  @observable excessCount: number = 0;
  @observable annuityCount: number = 0;
  @observable spLifeCount: number = 0;

  @action DefaultDate = () => {
    var d: Date = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    return new Date(year, month, day + 7);
  };

  @computed
  get premiumEmptyMsg(): string {
    switch (this.premiumType) {
      case "excess":
        return "There are no Excess Premium deposits for this policy.";
      case "annuity":
        return "There are no Annuity Premium deposits for this policy.";
      case "singlePremium":
        return "There are no Single Premium deposits for this policy.";
      default:
        return "";
    }
  }
  @computed
  get premiumDialogTitle(): string {
    switch (this.premiumType) {
      case "excess":
        return "Excess Premium";
      case "annuity":
        return "Annuity Premium";
      case "singlePremium":
        return "Single Premium";
      default:
        return "";
    }
  }
  @observable premiumType: string = "";

  @observable productContractStaus: string = "A";
  @observable productContractStausList = [
    { label: "All", value: "All" },
    { label: "Active", value: "A" },
    { label: "Hold", value: "H" },
  ];
  @observable productContractCompany: string = "All";
  @observable productContractCompanyList = [{ label: "All", value: "All" }];
  @observable selectedMode: string = "8";
  @observable selectedAdvance: string = "0";
  @observable cashWApp: string = "0";
  @observable AdvanceList = [
    { label: "None", value: "0" },
    { label: "3", value: "3" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "12", value: "12" },
  ];
  @observable modeList = [{ label: "None", value: "0" }];
  @observable status: string = "";
  @observable statusList = [{ label: "None", value: "0" }];
  @observable selectedPaymentType: string = "";
  @observable paymentTypeList = [{ label: "None", value: "0" }];
  @observable selectedCommissionType: string = "";
  @observable commissionTypeList = [{ label: "None", value: "0" }];
  @observable appliedRating: string = "";
  @observable appliedRatingList = [{ label: "None", value: "0" }];
  @observable approvedRating: string = "";
  @observable approvedRatingList = [{ label: "None", value: "0" }];

  @observable selectedMarketingEvent: string = "";

  @observable agentId: number | undefined;
  @observable profileId: number | undefined;
  @observable productId: number | undefined;
  @observable licenseId: number | undefined;
  @observable licenseNumber: string = "";
  @observable modeId: number | undefined;
  @observable customerId: number | undefined;
  @observable mgrId: number | undefined;
  @observable locationPremierId: number | undefined;
  @observable excessPreium: number | undefined;
  @observable annuityPremium: number | undefined;
  @observable spLifePremium: number | undefined;
  @observable contractId: number = 0;
  @observable companies = [{ label: "None", value: "0" }];

  @observable IsValidOptions = [
    { label: "Yes", value: "1" },
    { label: "No", value: "2" },
  ];
  @observable isLead: boolean = false;
  @observable selectedCompany: string = "0";
  @observable selectedLevel: string = "0";
  @observable selectedType: string = "0";
  @observable securityLevel: string = "Private";
  @observable message: string = "";
  @observable selecteDate: Date = new Date();
  @observable doNotCall: boolean = false;
  @observable doNotMarket: boolean = false;
  @observable doNotEmail: boolean = false;
  @observable fmoTranser: boolean = false;
  @observable title: string = "Add New Policy";
  @observable exceptionResponse: any = "";
  @observable isAddEditPermission: boolean = false;
  @observable response: any;
  @observable isNotesException: boolean = false;
  @observable notesResponse: any;

  @observable type = [
    { label: "All", value: "0" },
    { label: "General Comment", value: "1" },
    { label: "Underwriting", value: "2" },
  ];
  @action setAddPremiumDialogVisible = async (value: boolean) => {
    // await this.loadPremiumValuesFromApi()
    this.isAddPremiumDialogVisible = value;
  };

  @action setEditCommissionReceivedDialogVisible = async (value: boolean) => {
    // await this.loadPremiumValuesFromApi()
    this.isEditCommissionReceivedDialogVisible = value;
    this.isCommissionReceivedAddInputAvaliable = false;
  };

  @action setIsAgentSearchDialogVisible = async (value: boolean) => {
    if (value) {
      this.serachInputValue = "";
      this.paymentPlanStatus = "A";
      this.searchTypeAgensSearch = "2";
      this.AgentsSearchList.values = [];
      this.SelecetedAgent = null;
    }
    this.isAgentSearchDialogVisible = value;
  };
  @action setDeletePremiumConfirm = async (value: boolean) => {
    this.isDeletePremiumConfirm = value;
  };
  @action setDeleteCommissionReceivedConfirm = async (value: boolean) => {
    this.isDeleteCommissionReceivedConfirm = value;
  };

  @action resetAddPremiumDailogValues() {
    this.ResetValidatePolicyPremium();
    this.setPrmiumDate(new Date());
    this.setPremiumAmount("0");
    this.setPremiumOverride("");
  }
  @action resetCommisionReceivedDailogValues() {
    this.ResetValidateCommissionReceived();
    this.setStatementDate(new Date());
    this.setCommissionReceivedAmount("0");
  }
  @action setAddPremInputAvailable(value: boolean) {
    this.isEditPremium = value;
    this.isAddInputAvaliable = !this.isAddInputAvaliable;
  }
  @action setCommissionReceivedAddInputAvailable(value: boolean) {
    this.isEditCommissionReceived = value;
    this.isCommissionReceivedAddInputAvaliable =
      !this.isCommissionReceivedAddInputAvaliable;
  }
  @action setPolicyNumberIsValid(value: string, rowData: PolicyNumberList) {
    let newValues: PolicyNumberList[] = [];
    this.policyNumersHistory.values.forEach((obj) => {
      if (obj.policyNumberId === rowData.policyNumberId) {
        obj.isValid = value === "1";
      }
      newValues.push(obj);
    });
    this.policyNumersHistory.values = newValues;
  }
  @action setDoNotCall(value: boolean) {
    this.doNotCall = value;
  }
  @action setPremiumType(value: string) {
    this.premiumType = value;
  }
  @action setDoNotEmail(value: boolean) {
    this.doNotEmail = value;
  }
  @action setDoNotMarket(value: boolean) {
    this.doNotMarket = value;
  }
  @action setFmoTranser(value: boolean) {
    this.fmoTranser = value;
  }
  @action setSelectDate(value: Date) {
    this.selecteDate = value;
  }
  @action setMessage(value: string) {
    this.message = value;
  }
  @action setLead(value: boolean) {
    this.isLead = value;
  }
  @action setLicense(value: string) {
    this.license = value;
  }
  @action setSelectedAgent(value: string) {
    this.selectedAgent = value;
  }
  @observable SelectedProduct: any;
  @observable isSelectContractDialogVisible: boolean = false;
  @observable isAddPolicyNoteDialogVisible: boolean = false;
  @observable isPolicyNumberSearchDialogVisible: boolean = false;

  @action setSelectContractDialogVisible(value: boolean) {
    if (value) {
      this.loadProductInfo();
    }
    this.isSelectContractDialogVisible = value;
    this.SelectedProduct = null;
  }
  @action setPolicyNumberSearchDialogVisible = async (value: boolean) => {
    if (value) await this.loadPolicyNumberHistory();
    this.isPolicyNumberSearchDialogVisible = value;
  };
  @action refreshPolicyNotes = async () => {
    if (this.policyId) await this.loadPolicyNotes(this.policyId);
  };
  @action setPolicyNoteDialogVisible(value: boolean) {
    this.isNotesException = false;
    this.notes = "";
    this.isPrivate = Utils.hasUserPermission(Permission.NotesPrivateByDefault);
    this.noteType = "1";
    this.copyLicenseCommunication = false;
    this.copyMarketer = false;
    this.createFollowup = false;
    this.selecteDate = new Date();
    this.isAddPolicyNoteDialogVisible = value;
    this.selectePolicyNoteDate = new Date();
  }

  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }

  @action onPagePolicyNotes = async (firstIndex: number, rows: number) => {
    this.first = firstIndex;
    this.rows = rows;
    this.pageIndex = firstIndex / this.rows;
    if (this.policyId) await this.loadPolicyNotes(this.policyId);
  };
  @action onPageContract = async (firstIndex: number, rows: number) => {
    this.pageIndex = firstIndex;
    if (this.policyId) await this.loadProductInfo();
  };
  @action updateProductInfo = () => {
    try {
      let obj = JSON.parse(JSON.stringify(this.SelectedProduct));
      this.productId = obj.productId;
      this.profileId = obj.id;
      this.product = obj.productName;
      this.plan = obj.commissionPlanName;
      this.marketer = obj.marketer;
      this.payTo = obj.payTo ? obj.payTo : "";
      this.productCategoryName = obj.productCategoryName
        ? obj.productCategoryName
        : "";
      this.cashWApp = obj.defaultPremium ? "" + obj.defaultPremium : "0";
      this.premium = Utils.formatNum(this.cashWApp).toString();
      this.selectedMode = obj.defaultMode ? "" + obj.defaultMode : "8";
      this.calculateAnnualPremium();
    } catch (error) {}
  };
  @action updateSelectedProduct = (value: any) => {
    try {
      this.SelectedProduct = value;
      IMASLog.log("value: " + JSON.stringify(value));
      let taskObj = JSON.parse(JSON.stringify(value));
      taskObj.isSelected = !taskObj.isSelected;
    } catch (error) {}
  };
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
  }
  @action loadPremiumValuesOnlyOnce = async () => {
    try {
      this.isLoading = true;
      let result = await InsuredStore.getPolicyExcessPremium(this.policyId);
      let result1 = await InsuredStore.getPolicyAnnuityPremiums(this.policyId);
      let result2 = await InsuredStore.getPolicySingleLifePremiums(
        this.policyId
      );
      this.tempExcessPremiumList = result ? result : [];
      this.tempAnnualPremiumList = result1 ? result1 : [];
      this.tempSPLifePremiumList = result2 ? result2 : [];
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action loadCommissionReceivedOnlyOnce = async () => {
    try {
      this.isLoading = true;
      let result = await InsuredStore.getCommissionReceived(this.policyId);
      this.tempCommissionReceivedList = result ? result : [];
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action loadCommissionReceivedValues = async () => {
    try {
      this.isLoading = true;
      this.setIsException(false);
      this.commissionReceivedDataList.values = [];
      var result = null;
      this.tempCommissionReceivedList = this.tempCommissionReceivedList
        ? [...this.tempCommissionReceivedList]
        : [];
      result = this.tempCommissionReceivedList.filter((item: any) => {
        return !item.isDelete;
      });
      var commissionReceivedValue = 0;
      if (result && result.length > 0) {
        result.forEach((obj: any) => {
          if (obj && obj.amount)
            commissionReceivedValue += obj.amount ? obj.amount : 0;
        });
      }

      this.commissionReceivedValue = Utils.getCurrencyFormat(
        commissionReceivedValue
      );
      result = this.tempCommissionReceivedList;
      if (result) {
        var res = [...result].reverse();
        this.commissionReceivedDataList.values = res;
      }
      this.isLoading = false;
    } catch (e) {
      this.commissionReceivedDataList.values = [];
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  };
  @action loadPremiumValues = async () => {
    try {
      this.isLoading = true;
      this.setIsException(false);
      this.policyPremiumDataList.values = [];
      var result = null;
      switch (this.premiumType) {
        case "excess":
          this.tempExcessPremiumList = this.tempExcessPremiumList
            ? [...this.tempExcessPremiumList]
            : [];
          result = this.tempExcessPremiumList;
          var excessPremiumValue = 0;
          result.forEach((obj: any) => {
            if (obj && obj.amount)
              excessPremiumValue += obj.amount ? obj.amount : 0;
          });
          this.policyExcessPremiumValue =
            Utils.getCurrencyFormat(excessPremiumValue);
          break;
        case "annuity":
          this.tempAnnualPremiumList = this.tempAnnualPremiumList
            ? [...this.tempAnnualPremiumList]
            : [];
          result = this.tempAnnualPremiumList;
          var annualPremiumValue = 0;
          result.forEach((obj: any) => {
            if (obj && obj.amount)
              annualPremiumValue += obj.amount ? obj.amount : 0;
          });
          this.policyAnnualPremiumValue =
            Utils.getCurrencyFormat(annualPremiumValue);
          break;
        case "singlePremium":
          this.tempSPLifePremiumList = this.tempSPLifePremiumList
            ? [...this.tempSPLifePremiumList]
            : [];
          result = this.tempSPLifePremiumList;
          var spLifePremiumValue = 0;
          result.forEach((obj: any) => {
            if (obj && obj.amount)
              spLifePremiumValue += obj.amount ? obj.amount : 0;
          });
          this.policySPLifePremiumValue =
            Utils.getCurrencyFormat(spLifePremiumValue);
          break;
      }
      if (result) {
        var res = [...result].reverse();
        this.policyPremiumDataList.values = res;
      }
      this.isLoading = false;
    } catch (e) {
      this.policyPremiumDataList.values = [];
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  };

  @action getCommissionReceivedById = async (id: number) => {
    try {
      this.commissionReceivedId = id;
      this.isLoading = true;
      this.setIsException(false);
      var index = this.tempCommissionReceivedList.findIndex(
        (obj: any) => obj.id == id
      );

      var result = this.tempCommissionReceivedList[index];
      if (result) {
        this.setStatementDate(new Date(Utils.getDateFormat(result.date)));
        this.setCommissionReceivedAmount(
          result.amount ? "" + result.amount : "0"
        );
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  };

  @action getPremiumValuesById = async () => {
    try {
      this.isLoading = true;
      this.setIsException(false);
      switch (this.premiumType) {
        case "excess":
          var result = this.policyPremiumDataList.values[this.fetchrowIndex];
          if (result) {
            this.setPrmiumDate(new Date(Utils.getDateFormat(result.date)));
            this.setPremiumAmount(result.amount ? "" + result.amount : "0");
            this.setPremiumOverride(
              result.override ? "" + result.override : ""
            );
          }
          break;
        case "annuity":
          var result2 = this.policyPremiumDataList.values[this.fetchrowIndex];
          if (result2) {
            this.setPrmiumDate(new Date(Utils.getDateFormat(result2.date)));
            this.setPremiumAmount(result2.amount ? "" + result2.amount : "0");
            this.setInitialDeposit(
              result2.initialDeposit ? result2.initialDeposit : false
            );
          }
          break;
        case "singlePremium":
          var result3 = this.policyPremiumDataList.values[this.fetchrowIndex];
          if (result3) {
            this.setPrmiumDate(new Date(Utils.getDateFormat(result3.date)));
            this.setPremiumAmount(result3.amount ? "" + result3.amount : "0");
          }
          break;
      }
      // }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  };

  @action savePremium = async () => {
    try {
      if (!(await this.ValidatePolicyPremium())) {
        this.isLoading = true;
        this.setIsException(false);
        var result = null;
        switch (this.premiumType) {
          case "excess":
            await this.addEditExcessPremium(result);
            break;
          case "annuity":
            await this.addEditAnnuityPremium(result);
            break;
          case "singlePremium":
            await this.addEditSingleLifePremium(result);
            break;
        }
        setTimeout(async () => {
          this.setAddPremInputAvailable(false);
          await this.loadPremiumValues();
        }, Utils.timeDelay_Success());
      }
      this.isLoading = false;
    } catch (e) {
      this.setAddPremiumDialogVisible(false);
      this.exceptionResponse = e.response;
      this.isLoading = false;
      this.setIsException(true);
    }
  };

  @action saveCommissionReceived = async () => {
    try {
      if (!(await this.ValidateCommissionReceived())) {
        this.isLoading = true;
        this.setIsException(false);
        var result = null;
        await this.addEditCommissionReceived(result, true, false);
        setTimeout(async () => {
          this.setCommissionReceivedAddInputAvailable(false);
          await this.loadCommissionReceivedValues();
        }, Utils.timeDelay_Success());
      }
      this.isLoading = false;
    } catch (e) {
      this.setEditCommissionReceivedDialogVisible(false);
      this.exceptionResponse = e.response;
      this.isLoading = false;
      this.setIsException(true);
    }
  };

  @action deletePremium = async () => {
    try {
      this.isLoading = true;
      this.setIsException(false);

      switch (this.premiumType) {
        case "excess":
          let tempExcessPremiumList = [];
          tempExcessPremiumList = this.tempExcessPremiumList.reverse();
          tempExcessPremiumList.splice(this.fetchrowIndex, 1);
          this.tempExcessPremiumList = [...tempExcessPremiumList].reverse();

          break;
        case "annuity":
          let tempAnnualPremiumList = [];
          tempAnnualPremiumList = this.tempAnnualPremiumList.reverse();
          tempAnnualPremiumList.splice(this.fetchrowIndex, 1);
          this.tempAnnualPremiumList = [...tempAnnualPremiumList].reverse();

          break;
        case "singlePremium":
          let tempSPLifePremiumList = [];
          tempSPLifePremiumList = this.tempSPLifePremiumList.reverse();
          tempSPLifePremiumList.splice(this.fetchrowIndex, 1);
          this.tempSPLifePremiumList = [...tempSPLifePremiumList].reverse();

          break;
      }
      this.setDeletePremiumConfirm(false);
      setTimeout(async () => {
        await this.loadPremiumValues();
      }, Utils.timeDelay_Delete());

      this.isLoading = false;
    } catch (e) {
      this.setDeletePremiumConfirm(false);
      this.isLoading = false;
      this.exceptionResponse = e.response;
      this.setIsException(true);
    }
  };
  @action deleteCommissionReceived = async () => {
    this.isLoading = true;
    this.setIsException(false);
    var result = null;
    this.isEditCommissionReceived = true;
    await this.addEditCommissionReceived(result, false, true);
    this.isEditCommissionReceived = false;
    this.setDeleteCommissionReceivedConfirm(false);
    setTimeout(async () => {
      await this.loadCommissionReceivedValues();
    }, Utils.timeDelay_Success());
  };

  private async addEditAnnuityPremium(result: any) {
    var dtoAnnuity: CreateUpdateAnnuityPremiumModel = {
      policyId: this.policyId,
      date: this.premiumDate,
      amount: this.premiumAmount,
      initialDeposit: this.initialDeposit,
    };
    if (this.isEditPremium) {
      dtoAnnuity.id = this.premiumId;

      let tempAnnualPremiumList = [];
      tempAnnualPremiumList = this.tempAnnualPremiumList.reverse();
      tempAnnualPremiumList[this.fetchrowIndex] = dtoAnnuity;
      this.tempAnnualPremiumList = [...tempAnnualPremiumList].reverse();
    } else {
      this.tempAnnualPremiumList = [...this.tempAnnualPremiumList, dtoAnnuity];
      result = "";
    }
    return result;
  }

  private async addEditSingleLifePremium(result: any) {
    var dtoSingleLife: CreateUpdateSingleLifePremiumModel = {
      policyId: this.policyId,
      date: this.premiumDate,
      amount: this.premiumAmount,
      override: +this.premiumOverride,
    };
    if (this.isEditPremium) {
      dtoSingleLife.id = this.premiumId;

      let tempSPLifePremiumList = [];
      tempSPLifePremiumList = this.tempSPLifePremiumList.reverse();
      tempSPLifePremiumList[this.fetchrowIndex] = dtoSingleLife;
      this.tempSPLifePremiumList = [...tempSPLifePremiumList].reverse();
    } else {
      this.tempSPLifePremiumList = [
        ...this.tempSPLifePremiumList,
        dtoSingleLife,
      ];
      result = "";
    }
    return result;
  }

  private async addEditExcessPremium(result: any) {
    var dto: CreateUpdateExcessPremiumModel = {
      policyId: this.policyId,
      date: this.premiumDate,
      amount: this.premiumAmount,
      override: +this.premiumOverride,
    };
    if (this.isEditPremium) {
      dto.id = this.premiumId;

      let tempExcessPremiumList = [];
      tempExcessPremiumList = this.tempExcessPremiumList.reverse();
      tempExcessPremiumList[this.fetchrowIndex] = dto;
      this.tempExcessPremiumList = [...tempExcessPremiumList].reverse();
    } else {
      this.tempExcessPremiumList = [...this.tempExcessPremiumList, dto];
    }
    return result;
  }
  private async addEditCommissionReceived(
    result: any,
    isEdit: boolean,
    isDelete: boolean
  ) {
    var dto: CommissionReceivedByPolicyListModel = {
      policyId: this.policyId,
      date: isEdit ? this.statementDate : undefined,
      amount: isEdit ? this.commissionReceivedAmount : undefined,
    };
    if (this.isEditCommissionReceived) {
      dto.id = this.commissionReceivedId;
      dto.isEdit = isEdit;
      dto.isDelete = isDelete;
      var index = this.tempCommissionReceivedList.findIndex(
        (obj: any) => obj.id == this.commissionReceivedId
      );
      this.tempCommissionReceivedList[index] = dto;
    } else {
      this.tempCommissionReceivedList = [
        ...this.tempCommissionReceivedList,
        dto,
      ];
    }
    return result;
  }
  private async addEditPolicySummary(result: any) {
    
    PageContext.isMessageVisible = false;
    this.setIsException(false);
    this.isValid = true;
    await this.ValidatePolicySummary();
    await this.ValidatePolicyAddNote();
    if (this.isEdit === true) {
      this.isValid = !(await this.ValidatePolicySummary());
    } else {
      this.isValid =
        !(await this.ValidatePolicySummary()) &&
        !(await this.ValidatePolicyAddNote());
    }
    this.isValid && this.validateCustomAttributes();

    console.log("Valid For Save ->", this.isValid);

    if (this.isValid === true) {
      try {
        var commissionReceivedList: any;
        if (this.commissionReceivedDataList.values.length > 0) {
          commissionReceivedList =
            this.commissionReceivedDataList.values.filter(
              (x) => x.isEdit == true || x.isDelete
            );
        }

        var dto: CreateUpdatePolicyModel = {
          id: this.policyId,
          policyNoteTypeId: +this.noteType,
          customerId: this.customerId ? +this.customerId : 0,
          profileId: this.profileId,
          company: this.company,
          mgrId: this.mgrId,
          locationPremierId: parseInt(this.selectedLocation),
          cashWithApp: Utils.formatNum(this.cashWApp),
          premium: Utils.formatNum(this.premium),
          mode: +this.selectedMode,
          annualPremium: Utils.formatNum(this.annualPremium),
          dateSold: Utils.getDateinyymmdd(this.soldDate),
          dateEffective: Utils.getDateinyymmdd(this.effectiveDate),
          originalDateEffective: Utils.getDateinyymmdd(this.orginalEffectDate),
          licenseNumberId: this.licenseNumberID,
          replacedDate: this.replacedDate,
          statusId: +this.status,
          datePosted: Utils.getDateinyymmdd(this.postedDate),
          dateEntered: Utils.getDateinyymmdd(this.enteredDate),
          dateSurrender: Utils.getDateinyymmdd(this.endDate),
          pendingInfo: this.pendingInfo,
          policyNumber: this.policy,
          group: "", // need to fetch
          saveAge: this.isSaveAge,
          issueAge: +this.issueAge,
          targetPremium: this.targetPremium,
          // agencyPercent: +this.agencyPercentage,
          // agentPercent: +this.sellingAgent,
          deathBenefit: this.deathBenfit,
          spLife: this.policySPLifePremiumValue
            ? Utils.formatNum(this.policySPLifePremiumValue)
            : 0,
          commissionTypeId: +this.selectedCommissionType,
          enrollmentNumber: this.enrollment,
          appliedRatingId: +this.appliedRating,
          approvedRatingId: +this.approvedRating,
          // paymentTypeId: +this.selectedPaymentType,
          ssboCreditCard: this.ssboCreditCard,
          excessAmount: this.policyExcessPremiumValue
            ? Utils.formatNum(this.policyExcessPremiumValue)
            : 0,
          annuityAmount: this.policyAnnualPremiumValue
            ? Utils.formatNum(this.policyAnnualPremiumValue)
            : 0,
          isCopy: this.thisIsCopy,
          commission: +this.commission,
          advanceMonths: +this.selectedAdvance,
          copyMarketer: this.copyMarketer,
          createFollowUpTask: this.createFollowup,
          taskDate: this.selectePolicyNoteDate,
          note: this.notes,
          annuityPremiumModelList: this.tempAnnualPremiumList
            ? this.tempAnnualPremiumList
            : [],
          singlePremiumModelList: this.tempSPLifePremiumList
            ? this.tempSPLifePremiumList
            : [],
          excessPremiumModelList: this.tempExcessPremiumList
            ? this.tempExcessPremiumList
            : [],
          canBypassAppointment: Utils.hasUserPermission(
            Permission.BypassAppointmentCheck
          ),
          canBypassCertification: Utils.hasUserPermission(
            Permission.BypassCertificationCheck
          ),
          pcp: this.pcp,
          commissionReceivedByPolicyModel: commissionReceivedList
            ? commissionReceivedList
            : [],
        };

        if (
          Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
          Utils.hasUserPermission(Permission.PublicCustomAttribute)
        ) {
          dto.customAttributes = await this.createUpdateAttributes();
        }

        if (this.isEdit) {
          dto.id = this.policyId;
          await InsuredStore.editPolicySummary(dto);

          this.gotoPolicies();
        } else {         
          var response = await InsuredStore.addPolicySummary(dto);
          googleTagManager.trackAction("policy_created", {
            feature: routes.addPolicy.name,
            user_id: UserContext.userId,
            policy_id: response && response.isSuccess ? response.policyId : 0,
          });
          if (response && !response.isSuccess) {           
            var successResponse = {
              status: 500,
              title: response.validationMessage,
              errors: { "": [] },
            };
            setTimeout(() => {
              PageContext.setIsMessageVisible(true);
            }, 1100);
            PageContext.setResponseMessage(JSON.stringify(successResponse));
            window.scrollTo(0, 0);
            this.canBypassAppointment = response.canBypassAppointment || false;
            this.canBypassCertification =
              response.canBypassCertification || false;
            this.isSaveButtonEnabled = response.isSaveButtonEnabled
              ? true
              : false;
          } else { 
            this.isSaveButtonEnabled = false;           
            this.policyId =
              response.policyId != undefined ? response.policyId : 0;
            this.gotoSummary();
          }
        }

        return true;
      } catch (e) {
        this.exceptionResponse = e.response;
      }
    } else {
      return false;
    }
  }

  @observable canBypassAppointment: boolean = false;
  @observable canBypassCertification: boolean = false;
  @observable isSaveButtonEnabled: boolean = true;
  //Agent Lookup Related
  @observable AgentsSearchList = new Collection<AgentsSearchList>();
  @observable SelecetedAgent: AgentsSearchList | null = null;
  @observable serachInputValue: string = "";
  @observable firstAgentsSearch: number = 0;
  @observable totalRecordsAgentSearch: number = 0;
  @observable rowsAgentsSearch: number = 100;
  @observable sortColumnAgentsSearch: string = "lastName";
  @observable sortOrderAgentsSearch: boolean = false;
  @observable pageIndexAgentsSearch: number = 0;
  @observable searchTypeAgensSearch: string = "2";
  @observable searchTypeAgensSearchList: any;
  @computed
  get isAnyAgentSelected(): boolean {
    if (this.SelecetedAgent) return false;
    return true;
  }

  @action setsearchTypeAgensSearch = (value: string) => {
    this.searchTypeAgensSearch = value;
  };

  @action searchIntervalsAgentsSearch = (text: string) => {
    this.serachInputValue = text;
  };

  @action loadAgents = async () => {
    try {
      this.AgentsList.values = [];
      if (this.serachInputValue) {
        var result = await InsuredStore.getAgentsLookup(
          this.serachInputValue,
          this.paymentPlanStatus,
          +this.searchTypeAgensSearch,
          this.pageIndexAgentsSearch,
          this.rowsAgentsSearch,
          this.sortColumnAgentsSearch,
          this.sortOrderAgentsSearch
        );
        if (result) {
          this.totalRecordsAgentSearch = result.recordCount
            ? result.recordCount
            : 0;
          this.AgentsSearchList.values = result.data ? result.data : [];
        }
      }
      this.isLoading = false;
    } catch (e) {
      this.AgentsSearchList.values = [];
    }
  };

  @action onPageAgentSearch = async (firstIndex: number, rows: number) => {
    this.firstAgentsSearch = firstIndex;
    this.pageIndex = firstIndex / rows;
    await this.loadAgents();
  };

  @action updateSelectedAgent = (value: AgentsSearchList) => {
    this.SelecetedAgent = value;
    IMASLog.log("value: " + JSON.stringify(value));
    let taskObj = JSON.parse(JSON.stringify(value));

    taskObj.isSelected = !taskObj.isSelected;
  };
  @action setSelectedType(value: string) {
    this.selectedType = value;
    this.loadPolicyNotes(this.policyId);
  }

  @action saveSelectedAgent = async () => {
    this.setIsAgentSearchDialogVisible(false);
    if (this.SelecetedAgent) {
      this.agentId = this.SelecetedAgent.agentId
        ? this.SelecetedAgent.agentId
        : 0;
      this.contractId = this.SelecetedAgent.contractId
        ? this.SelecetedAgent.contractId
        : 0;
      this.companyId = this.SelecetedAgent.companyId
        ? this.SelecetedAgent.companyId
        : 0;
      this.selectedAgent = this.SelecetedAgent.agent
        ? this.SelecetedAgent.agent
        : "";
      this.company = this.SelecetedAgent.company
        ? this.SelecetedAgent.company
        : "";
      this.locationPremierId = this.SelecetedAgent.agentId
        ? this.SelecetedAgent.agentId
        : 0;
      this.licenseNumbersList = [];
      this.licenseNumbersList.push({
        label: "" + this.SelecetedAgent.agentNumber,
        value: "" + this.SelecetedAgent.licenseNumberId,
      });
      this.licenseId = this.SelecetedAgent.licenseNumberId;
      this.licenseNumber = this.SelecetedAgent.agentNumber
        ? "" + this.SelecetedAgent.agentNumber
        : "";
      this.licenseNumberID = this.SelecetedAgent.licenseNumberId || 0;
      this.setLicense("" + this.SelecetedAgent.licenseNumberId);
      await this.loadProductInfo();
      await this.loadLocationLookupByContractId(this.contractId);
    }
    this.setSelectContractDialogVisible(true);
  };

  //Add Edit Policy Notes Related
  @observable notes: string = "";
  @observable isDefaultPolicyNote: boolean = false;
  @observable isCommission: boolean = false;
  @observable isResidential: boolean = false;
  @observable isVisibleInDownline: boolean = false;
  @observable paymentPlanStatus: string = "A";

  @observable noteTypeList = [
    { label: "General Comment", value: "1" },
    { label: "Underwriting", value: "2" },
  ];
  @observable noteType: string = "1";
  @observable paymentPlanStatusList = [
    { label: "Active", value: "A" },
    { label: "History", value: "H" },
  ];
  @observable isPrivate: boolean = false;
  @observable copyLicenseCommunication: boolean = false;
  @observable copyMarketer: boolean = false;
  @observable selectePolicyNoteDate: Date | undefined = new Date();

  @observable createFollowup: boolean = false;

  @action setSelectedPolicyDate(date: Date | undefined) {
    this.selectePolicyNoteDate = date;
  }
  @action setIsPrivate(value: boolean) {
    this.isPrivate = value;
  }
  @action setCopyLicenseCommunication(value: boolean) {
    this.copyLicenseCommunication = value;
  }
  @action setCopyMarketer(value: boolean) {
    this.copyMarketer = value;
  }
  @action setCreateFollowup(value: boolean) {
    this.createFollowup = value;
  }
  @action setNotes(value: string) {
    this.notes = value;
  }
  @action setPaymentPlanStatus(value: string) {
    this.paymentPlanStatus = value;
    this.loadAgents();
  }
  @action setNoteType(value: string) {
    this.noteType = value;
  }
  @action
  public ValidatePolicyNote = async (dto: CreateUpdatePolicyNoteModel) => {
    return this.PolicyNotesErrorModel.Validate(dto);
  };
  @action
  public ValidatePolicyPremium = async () => {
    return this.PremiumErrorModel.Validate(this);
  };
  @action
  public ValidateCommissionReceived = async () => {
    return this.CommissionReceivedErrorModel.Validate(this);
  };
  @action
  public ResetValidatePolicyPremium = async () => {
    return this.PremiumErrorModel.ResetValidation(this);
  };
  @action
  public ResetValidateCommissionReceived = async () => {
    return this.CommissionReceivedErrorModel.ResetValidation(this);
  };

  @action
  public ValidatePolicySummary = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ValidatePolicyAddNote = async () => {
    return this.ErrorModelNote.Validate(this);
  };

  @action
  public ResetValidatePolicyAddNote = async () => {
    return this.ErrorModelNote.ResetValidation(this);
  };

  @action
  public ResetValidatePolicySummary = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
  @action setDefaultPolicyNote(value: boolean) {
    this.isDefaultPolicyNote = value;
  }
  @action setCommission(value: boolean) {
    this.isCommission = value;
  }
  @action setResidential(value: boolean) {
    this.isResidential = value;
  }
  @action setVisibleInDownline(value: boolean) {
    this.isVisibleInDownline = value;
  }
  @action
  public Submit = async () => {
    
    try {
      var dto: CreateUpdatePolicyNoteModel = {
        policyId: this.policyId,
        note: this.notes,
        private: this.isPrivate,
        policyNoteTypeId: +this.noteType,
        agentId: this.agentId,
        noteDate: new Date(),
        copyDesignatedLicenseCommunication: this.copyLicenseCommunication,
        copyMarketer: this.copyMarketer,
        createFollowUpTask: this.createFollowup,
        taskDate: this.selectePolicyNoteDate,
        licenseId: this.licenseId ? +this.licenseId : 0,
      };

      if (!(await this.ValidatePolicyNote(dto))) {
        this.isLoading = true;
        this.isNotesException = false;
        try
        {
          await InsuredStore.addPolicyNotes(dto);
          this.isLoading = false;
          this.setPolicyNoteDialogVisible(false);
          this.sortColumn = "Id";
          this.sortOrder = false;
          await this.loadPolicyNotes(this.policyId);
        }
        catch(e: any)
        {
          this.isLoading = false;
          e.text().then((_responseText: any) => {
            this.notesResponse = _responseText;
            this.isNotesException = true;
          });
        }
       
       
      }
    } catch (e: any) {
      
      this.isLoading = false;
      e &&
        e.text().then((_responseText: any) => {
          this.notesResponse = _responseText;
          this.isNotesException = true;
        });
    }
  };
  @action policyNotes = async (column: string) => {
    this.sortColumn = column;
    this.sortOrder = !this.sortOrder;
    if (this.policyId) {
      this.loadPolicyNotes(this.policyId);
    }
  };

  @action getCalculatedCommission = async () => {
    if (this.productId && this.cashWApp) {
      var result = await InsuredStore.getCalculatedCommission(
        this.productId ? +this.productId : 0,
        Utils.formatNum(this.cashWApp)
      );
      if (result) {
        this.premium = result.premium ? "" + result.premium : "0";
      }
    }
  };

  // custom attribute methods
  @action handleAttributesModal = (value: boolean) => {
    this.CustomAttributesComponentViewModel.disableRadioInitial = true;
    this.addAttributesModal = value;
    setTimeout(async () => {
      value &&
        this.CustomAttributesComponentViewModel.loadExistingAttributes(
          this.attributesScreenPathId
        );
      !value && this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  };

  @action AttributeSave = (type: number) => {
    let data = this.CustomAttributesComponentViewModel.saveAttribute(type);
    this.addAttributesModal = false;

    setTimeout(async () => {
      var attributes = await this.getCustomAttributesByScreen(
        this.attributesScreenPathId || 2
      );
      if (attributes) {
        this.customAttributeList = attributes;
      }
      this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  };

  @action public validateCustomAttributes = async () => {
    PageContext.isCustomAttribute = false;
    this.response = "";
    var attrData = localStorage.getItem("CustomAttribute");
    var result = [];
    this.isValid = true;
    if (attrData) {
      result = JSON.parse(attrData);
      var mandatoryCustomAttributeData = result.filter(
        (x: any) =>
          x.isMandatory == true &&
          (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined)
      );
      if (
        mandatoryCustomAttributeData != null &&
        mandatoryCustomAttributeData.length > 0 &&
        (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
          Utils.hasUserPermission(Permission.PublicCustomAttribute))
      ) {
        PageContext.isCustomAttribute = true;
        this.isValid = false;
        window.scrollTo(0, 0);
        var responseModel = {
          status: 400,
          title: Constants.CustomAttributeValidationMessage,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
      }
    }
  };

  @action createUpdateAttributes = async () => {
    let data: any = [];
    (this.customAttributeList || []).map((item: any) => {
      item.attrValue =
        item.dataTypeId == 6
          ? item.attrValue
            ? Utils.getDateInFormat(new Date(item.attrValue))
            : ""
          : item.attrValue;
      data.push({
        customAttributeScreenId: item.scrnCustAttrId,
        entityId: this.policyId,
        value: item.attrValue + "",
      });
    });

    return data;
  };
}
class PolicyAddNoteValidator extends AbstractValidator<AddEditPolicyViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.notes)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage("Note is required");
  }
}
class PolicySummaryValidator extends AbstractValidator<AddEditPolicyViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedAgent)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage("Agent is required");
    this.validateIfDate((input) => input.soldDate)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage("Sold date is required");
    this.validateIfDate((input) => input.effectiveDate)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage("Effective date is required");
    this.validateIfString((input) => input.pcp)
      .matches(/^[a-z\-\s.]*$/i)
      .hasMaxLength(40)
      .withFailureMessage(
        "Please enter max 40 letters including only the special characters . (period) or - (hyphen)."
      );
  }
}
class PolicyPremiumValidator extends AbstractValidator<AddEditPolicyViewModel> {
  public constructor() {
    super();
    //this.validateIfNumber((input) => input.premiumAmount)
    //  .isGreaterThan(0)
    //  .withFailureMessage("Amount is required");
  }
}

class CommissionReceivedValidator extends AbstractValidator<AddEditPolicyViewModel> {
  public constructor() {
    super();
    this.validateIfNumber((input) => input.commissionReceivedAmount)
      .isGreaterThan(0)
      .withFailureMessage("Commission received is required");
  }
}

export interface PolicyPremium {
  id?: number;
  policyId?: number;
  amount?: number;
  date?: Date;
  override?: number;
  initialDeposit?: boolean;
}
