import { action, observable } from "mobx";
import ViewModel from "./../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { DateSelectArg, EventChangeArg, EventInput } from "@fullcalendar/react";
import { MarketingEventsViewModel } from "./MarketingEventsViewModel";
import { AgentSearchByMapViewModel } from "../maps/AgentSearchByMapViewModel";
import { TodaysTaskViewModel } from "./TodaysTaskViewModel";
import { AnnouncementsViewModel } from "./AnnouncementsViewModel";
import ProducerStore from "../../store/ProducerStore";
import {
  AgentTripPointModel,
  HomeScreenKPIResponse,
  MarketingEventSearchModel,
} from "../../services/ProducerService";

import loggedInUser from "../../infrastructure/Auth0Authenticator";
import { months } from "../../infrastructure/enum/Constants";

import {
  ReloadHomeListener,
  AppInlineProfileViewModel,
} from "../AppInlineProfileViewModel";
import PageContext from "../../infrastructure/PageContext";
import Utils from "../../infrastructure/Utils";

export class HomeViewModel implements ViewModel, ReloadHomeListener {
  reloadHome(): void {
    this.Load();
  }
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @observable AppInlineProfileViewModel: AppInlineProfileViewModel | undefined;
  @action setAppInlineViewModel(
    viewModel: AppInlineProfileViewModel | undefined
  ) {
    this.AppInlineProfileViewModel = viewModel;
  }
  @action loadTasks = async () => {
    this.TodaysTaskViewModel.Load();
  };
  @action Load = async () => {
    if (this.AppInlineProfileViewModel) {
      this.AppInlineProfileViewModel.setListener(this);
    }
    this.loggedInUser = loggedInUser.displayName.split(" ")[0];
    setTimeout(() => {
      PageContext.setIsLoadingVisible(false); 
    }, 3000); 
    await this.getKpis();
    setTimeout(() => {
      this.AnnouncementsViewModel.Load();
      this.TodaysTaskViewModel.Load();
      PageContext.setIsLoadingVisible(false); 
      this.getValuesForMarketingEvent();
    }, 500);
  };
  @action Loaded = () => {};

  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable
  weekendsVisible = true;
  @observable
  MarketingEventsViewModel: MarketingEventsViewModel = new MarketingEventsViewModel();
  @observable
  AgentSearchByMapViewModel: AgentSearchByMapViewModel = new AgentSearchByMapViewModel();
  @observable
  TodaysTaskViewModel: TodaysTaskViewModel = new TodaysTaskViewModel();
  @observable
  AnnouncementsViewModel: AnnouncementsViewModel = new AnnouncementsViewModel();
  private eventGuid = 0;
  @observable barChartValues: string = "";
  @observable carrierChartValues: string = "";
  @observable productTypesChartValues: string = "";
  @observable mrktgEvents: MarketingEventSearchModel[] = [];
  @observable loggedInUser: string = "";
  @observable totalContractsInWorkflow: string = "";
  @observable totalAppsInWorkflow: string = "";
  @observable livesImpacted: string = "";
  @observable contractedAgents: string = "";
  @observable agentLists: AgentTripPointModel[] = [];
  @observable prevYear: number = 0;
  @observable currYear: number = 0;
  @observable totalContractsInWorkflowOrder: number = 1;
  @observable totalAppsInWorkflowOrder: number = 2;
  @observable livesImpactedOrder: number = 3;
  @observable contractedAgentsOrder: number = 4;

  @action reOrder = () => {
    this.totalContractsInWorkflowOrder = 4;
    this.totalAppsInWorkflowOrder = 3;
    this.livesImpactedOrder = 2;
    this.contractedAgentsOrder = 1;
  };

  @action getValuesForMarketingEvent = async () => {
    try {
      let result = await ProducerStore.getTop5MarketingEvents();
      if (result.length > 5) {
        result.length = 5;
        this.mrktgEvents = result;
      } else {
        this.mrktgEvents = result;
      }
    } catch (e) {
      this.mrktgEvents = [];
    }
  };

  @action formatDate = (param: Date) => {
    let year = param.getFullYear();
    let month = param.toLocaleString("default", { month: "long" });
    let date = param.getDate();
    return `${month} ${date}, ${year}`;
  };

  @action getKpis = async () => {
    let prevYear = new Date().getFullYear() - 1;
    let s = new Date(`${prevYear}-01-01`);
    let e = new Date();
    let eDate = new Date(e.getFullYear(), e.getMonth(), e.getDate());
    let sDate = new Date(s.getFullYear(), s.getMonth(), s.getDate());
    try {
      let result = await ProducerStore.homeScreenKPILookup(sDate, eDate);
      if (result) {
        let response: HomeScreenKPIResponse = result;
        this.totalContractsInWorkflow = response.totalContractsInWorkflow
          ? response.totalContractsInWorkflow.toLocaleString("en")
          : "-";
        this.totalAppsInWorkflow = response.totalAppsInWorkflow
          ? response.totalAppsInWorkflow.toLocaleString("en")
          : "-";
        this.contractedAgents = response.contractedAgents
          ? response.contractedAgents.toLocaleString("en")
          : "-";
        this.livesImpacted = response.livesImpacted
          ? response.livesImpacted.toLocaleString("en")
          : "-";
        this.getCarrierLists(response);
        this.getProductTypesLists(response);
        this.getAgentLists(response);
        this.getAppCountsByMonthYear(response);
        // agentTripPointModels
      }
    } catch (e) {}
  };
  @action getAppCountsByMonthYear = (response: any) => {
    let appCountsByMonthYearList = response.appCountMonthYearModel
      ? response.appCountMonthYearModel
      : [];
    let prevYear = new Date().getFullYear() - 1;
    let currYear = new Date().getFullYear();
    this.prevYear = prevYear;
    this.currYear = currYear;
    if (appCountsByMonthYearList.length > 0) {
      let tempList = [["", `${currYear}`, `${prevYear}`]];
      let list = appCountsByMonthYearList.map((i: any) => {
        return [
          i.month ? months[i.month - 1] : "",
          i.currentYear ? i.currentYear : 0,
          i.previousYear ? i.previousYear : 0,
        ];
      });
      let a = [...tempList, ...list];
      this.barChartValues = JSON.stringify(a);
    } else {
      // let tempList = [["", `${currYear}`, `${prevYear}`]];
      let tempList = [[]];
      this.barChartValues = JSON.stringify(tempList);
    }
  };

  @action getCarrierLists = (response: any) => {
    let carrierList = response.carrierCountModels
      ? response.carrierCountModels
      : [];
    if (carrierList.length > 0) {
      let tempList = [["Carrier", "App Count"]];
      let carriers = carrierList.map((i: any) => {
        return [
          `${
            i.carrierName
              ? 
              // i.carrierName.length > 12
              //   ? i.carrierName.slice(0, 9)
              //   : 
                i.carrierName
              : ""
          }`,
          i.appCount ? i.appCount : 0,
          // `${i.carrierName ? i.carrierName : ""}`
        ];
      });
      let a = [...tempList, ...carriers];
      this.carrierChartValues = JSON.stringify(a);
    } else {
      // let tempList = [["Carrier", "App Count"]];
      let tempList = [[]];
      this.carrierChartValues = JSON.stringify(tempList);
    }
  };

  @action getProductTypesLists = (response: any) => {
    let ProductTypesLists = response.productCountModels
      ? response.productCountModels
      : [];
    if (ProductTypesLists.length > 0) {
      let tempList = [["Product Types", "App Count"]];
      let ProductTypes = ProductTypesLists.map((i: any) => {
        return [
          `${
            i.subCategoryName
              ? 
              // i.subCategoryName.length > 10
              //   ? i.subCategoryName.slice(0, 9)
              //   : 
                i.subCategoryName
              : ""
          }`,
          i.appCount ? i.appCount : 0,
        ];
      });
      let a = [...tempList, ...ProductTypes];
      this.productTypesChartValues = JSON.stringify(a);
    } else {
      // let tempList = [["Product Types", "App Count"]];
      let tempList = [[]];
      this.productTypesChartValues = JSON.stringify(tempList);
    }
  };

  @action getAgentLists = (response: any) => {
    let agentLists = response.agentTripPointModels
      ? response.agentTripPointModels
      : [];
    if (agentLists) {
      this.agentLists = agentLists;
      // let a = agentLists.map((i:any) => {
      //   return({
      //   i.agentName ? i.agentName : ''
      //   )
      // })
      // this.agentLists = a
    } else {
      this.agentLists = [];
    }
  };

  @action getValues = async () => {
    let a = [
      ["", "2021", "2020"],
      ["Jan", 1000, 400],
      ["Feb", 1170, 460],
      ["Mar", 660, 1120],
      ["Apr", 1030, 540],
      ["May", 1030, 540],
      ["June", 930, 540],
      ["July", 1030, 540],
      ["Aug", 1030, 540],
      ["Sep", 630, 540],
      ["Oct", 830, 540],
      ["Nov", 1030, 540],
      ["Dec", 1030, 540],
    ];
    this.barChartValues = JSON.stringify(a);
  };

  @observable
  events: EventInput[] = [
    {
      id: this.createEventId(),
      title: "All-day event",
      start: new Date(),
      allDay: true,
    },
    {
      id: this.createEventId(),
      title: "Timed event",
      start: new Date(),
      allDay: false,
    },
  ];

  getEvents(): EventInput[] {
    return this.events;
  }

  private createEventId() {
    return String(this.eventGuid++);
  }

  @action
  addEvent(selectInfo: DateSelectArg, title: string | null) {
    this.events.push({
      id: this.createEventId(),
      title: title || "New Event",
      start: selectInfo.start,
      end: selectInfo.end,
      allDay: selectInfo.allDay,
    });
  }

  @action
  deleteEvent(id: string) {
    this.events.splice(
      this.events.findIndex((e) => e.id === id),
      1
    );
  }

  @action
  changeEvent(changeInfo: EventChangeArg) {
    const newEvent = changeInfo.event;
    const storedEvent = this.events.find((e) => e.id === changeInfo.event.id);
    if (storedEvent) {
      storedEvent.title = newEvent.title;
      storedEvent.allDay = newEvent.allDay;
      storedEvent.start = newEvent.start || storedEvent.start;
      storedEvent.end = newEvent.end || storedEvent.end;
    }
  }

  @action
  toggleWeekends() {
    this.weekendsVisible = !this.weekendsVisible;
  }
}
