import { BaseListViewModel } from "../../../components/BaseListViewModel";
import {
  RoleListModel,
  RoleModel,
  UserLookupListModel,
} from "../../../../services/IdentityService";
import { routes } from "../../../../router";
import IdentityStore from "../../../../store/IdentityStore";
import ProducerSearchStore from "../../../../store/ProducerSearchStore";
import { action, observable, toJS } from "mobx";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import PageContext from "../../../../infrastructure/PageContext";
import { Role } from "../../../../infrastructure/enum/Common";
import Collection from "../../../../infrastructure/CollectionHelper";
import UserContext from "../../../../infrastructure/UserContext";
import { Constants } from "../../../../infrastructure/enum/Constants";
import IMASLog from "../../../../infrastructure/IMASLog";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../../views/components/controls/IMASTemplates";

export class RoleListViewModel extends BaseListViewModel<RoleListModel> {
  constructor() {
    super("Roles", "Role", null, null, "roleId", "id",false);
    this.pageSize = 50;
  }

  @observable timer: any;
  @observable viewRolePermissions: boolean = false;
  @observable addEditRole: boolean = false;
  @observable actionTitle: string = "";
  @observable userRoleName: string = "";
  @observable selectedPermission = {
    "0-2-2": { checked: true, partialChecked: false },
    "0-2-1": { checked: true, partialChecked: false },
    "0-2": { checked: false, partialChecked: true },
  };
  @observable expandedNodes: any;
  @observable searchValue: string = "";
  @observable public ErrorModel = new ErrorModel(new RoleValidator());
  @observable isDeleteConfirm: boolean = false;
  @observable userList: any = [];
  @observable selectedAgencyList: any = [];
  @observable selectedrow: any;
  @observable selectedStatus: any = null;
  @observable statusTypeList = [
      { label: "All", value: "null" },
      { label: "Downlines", value: "1" },
      { label: "Non-Downlines", value: "0" },
  ];
  @observable selectedNotExistingUsers: any = null;
  @observable notExistingUsersList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable existingUserList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isConfirmationAlert: boolean = false;
  @observable confirmationTitle: string = "";
  @observable confirmationValue: string = "";
  @observable isConfirmationForDelete: boolean = false;
  @observable showUserError: boolean = false;
  @observable userIdToDelete: number = 0;
  @observable deleteRoles: string = "";
  @observable deleteRoleName: string = "";
  @observable deleteRoleToasterConfirm: boolean = false;
  @observable addRoleToasterConfirm: boolean = false;
  @observable updateRoleToasterConfirm: boolean = false;
  @observable cloneRoleToasterConfirm: boolean = false;
  @observable selectedRowData: any;
  @observable selectedAgencyDeleteIDFromAddGrid: any;
  @observable selectedAgencyDeleteNameFromAddGrid: any;
  @observable deleteTitle = Role.DeleteTitle;
  @observable isCloneConfirm: boolean = false;
  @observable isSaveEnable: boolean = true;
  @observable disablecheckDownline: boolean = false;
  @observable checkDownline: boolean = true;
  @observable isRoleEdit: boolean = true;
  @observable isRoleEditName: string = '';
  @observable checkDownlineClone: boolean = true;
  @observable isRoleNameExists: boolean = false;
  @observable showAgencyDeleteConfirm: boolean = false;
  @observable roleModalIsOverflow: boolean = false;
  @observable selectedUser: string = ""; 
  @observable selectedAgency: string = ""; 
  @observable selectedUserArray = new Collection<any>();
  @observable selectedAgencyArray = new Collection<any>();
  @observable selectedAgencyArrayListIds:number[] = [];
  @observable userListRole = new Collection<UserLookupListModel>();
  @observable agencyListRole = new Collection<UserLookupListModel>();
  @observable roleMandatoryErrorMsg: string = "Role name already exists. Please enter a different name.";
  @observable roleMandatoryErrorMsgClone: string = "";
  @observable roleClonePlaceHolder: string = "Enter the Role Name";
  @observable selectId: number = 0;
    
@action setRoleName = async (value: string) => {
    this.userRoleName = value;
    this.roleMandatoryErrorMsgClone = "";
    if (this.userRoleName && this.userRoleName.length > 0)
        this.isSaveEnable = false;
    else
        this.isSaveEnable = true;
}

@action checkRoleName  = async () => {  
  
  if(this.isRoleEdit && this.isRoleEditName == this.userRoleName){
    this.isRoleNameExists = false;
    this.isSaveEnable = false;
  }else{
    let result = await IdentityStore.checkRoleExists(0,this.userRoleName,true);
    this.isSaveEnable = result;
    this.isRoleNameExists = result;
  }
}
  @action setStatusDropdown = (value: string) => { 
        this.selectedStatus = value; 
        this.loadItems(); 
  };

  @action goSearch = (value:any) => {  
    this.currentPage = 0;
    this.searchValue = value;
  };

  @action loadAgency = async () => {
    let result:any; 
      result = await ProducerSearchStore.getAgencyLookup(1, this.selectedAgency, undefined, undefined, undefined, undefined);
      
      if (result !== undefined &&  result.recordCount >0 &&  result !== null && result.data != null && result.data != undefined && result.data.length > 0) {
          result.data.splice(0, 0, { id: -1 });
          this.agencyListRole.values = result.data;
      } else {
          var noUser: UserLookupListModel = { id: 0 };
          this.agencyListRole.values = [noUser];
      }
  }
  
  @action loadUsers = async () => {
    let result:any; 
      result = await IdentityStore.getDownlineAgencyUserLookup([...new Set(this.selectedAgencyArrayListIds)], this.selectedUser,this.checkDownline);
     
      
      if (result !== undefined && result !== null && result.data != null && result.data != undefined && result.data.length > 0) {
          result.data.splice(0, 0, { id: -1 });
          this.userListRole.values = result.data;

      } else {
          var noUser: UserLookupListModel = { id: 0 };
          this.userListRole.values = [noUser];
      }
  }
  
  @action onFieldChange = (value: any) => {  
        this.searchValue = value;  
        if (this.searchValue.length == 0 || this.searchValue.length > 2) {
            this.loadItems();
        } 
  };
  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => { 
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
    };

    @action checkRoleExists = async (id: number, roleName: string, isCreateMode: boolean) => {
        let res = await IdentityStore.checkRoleExists(id, roleName, isCreateMode);
        this.isRoleNameExists = res;
    }
  
    @action handleCloneDownlineChange = (e: any) => {
        this.checkDownlineClone = !e.target.value;
        this.showAgencyDeleteConfirm = false;

    };

  @action setSelectedNotExistingUsers = (value: any) => {
    this.selectedNotExistingUsers = value;
  };

  @action showConfirmationAlert = (bool: boolean, id?: string) => {
    this.isConfirmationAlert = true;
    if (bool) {
      this.confirmationTitle = "Confirm Delete";
      this.confirmationValue = "Are you sure you want to delete this user";
      this.userIdToDelete = id ? Number(id) : 0;
      this.isConfirmationForDelete = true;
    } else {
      this.confirmationTitle = "Confirm Add";
      this.confirmationValue = "Are you sure you want to add user/users";
      this.isConfirmationForDelete = false;
    }
  };

  @action hideConfirmationAlert = () => {
    this.isConfirmationAlert = false;
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(function (a: any, b: any) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      sortList.forEach((element: any) => {
        element.label = element.text;
      });

      return sortList;
    } else {
      return [];
    }
  }

  @action getNotExistingUserList = async () => {
    try {
      let res = await IdentityStore.getUserLookupByRoleId(this.selectedId);
      if (res.length) {
        this.notExistingUsersList.values = [];
        this.notExistingUsersList.values = this.mapListItemAndSort(res);
      } else this.notExistingUsersList.values = [];
    } catch (e) {
      this.notExistingUsersList.values = [];
    }
  };

  @action goToAdministration = () => {
    routes.administration.replace();
  };
 

  @action addBtnAction = async () => {
    let users = this.selectedNotExistingUsers;
    if (users && users.length > 0) {
      this.showUserError = false;
      this.addUsers();
    } else {
      //show error
      this.showUserError = true;
    }
  };

  @action clearBtnAction = () => {
    this.showUserError = false;
    this.selectedNotExistingUsers = null;
  };

  @action addUsers = async () => {
    let users = this.selectedNotExistingUsers.map((i: any) => Number(i));
    if (users.length > 0) {
        if ((this.actionTitle === Role.AddTitle) || (this.actionTitle === Role.EditTitle) || (this.actionTitle === Role.CloneTitle)){
        this.tempAssignedUsers(users);
      }
    }
  };

  @action deleteUser = async (userId: number) => {
    this.userIdToDelete = userId ? Number(userId) : 0;
      if (this.userIdToDelete !== 0) {
        let id = this.userIdToDelete;

        //remove this id from existinglist
        let addBackToNonExistingList = this.existingUserList.values.filter(
          (i) => i.value === id.toString()
        );
        this.existingUserList.values = this.existingUserList.values.filter(
          (i) => i.value !== id.toString()
        );

        //add this id to nonexistnig list
        let a = [
          ...this.notExistingUsersList.values,
          ...addBackToNonExistingList,
        ];
        this.notExistingUsersList.values = this.mapListItemAndSort(a);
     
      }
  };

  @action tempAssignedUsers = (arr: any) => {
    const existingList = arr.map((i: any) => {
      return this.notExistingUsersList.values.filter(
        (j: any) => j.value === i.toString()
      );
    });
    let tempExistingList: any = [];
      
    existingList.map((i: any) => tempExistingList.push(i[0]));

    let temp = this.existingUserList.values.map((i:any) => {
      return({
        label: i.label,
        value: i.value,
        text: i.label
      })
    })
    let list = [...temp, ...tempExistingList];
    this.existingUserList.values = this.mapListItemAndSort(list);


    function comparer(otherArray: any) {
      return function (current: any) {
        return (
          otherArray.filter(function (other: any) {
            return (
              other.value === current.value && other.display === current.display
            );
          }).length === 0
        );
      };
    }

    let onlyInA = this.notExistingUsersList.values.filter(
      comparer(tempExistingList)
    );
    let onlyInB = tempExistingList.filter(
      comparer(this.notExistingUsersList.values)
    );

    const result = onlyInA.concat(onlyInB);
    this.notExistingUsersList.values = this.mapListItemAndSort(result);

    this.selectedNotExistingUsers = null;
  };

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) { 
    let tempStatus =this.selectedStatus; 
    return await IdentityStore.getAllRole(
      (tempStatus == null ||tempStatus == 'null')   ? null : Number(tempStatus),
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      this.searchValue
    );
  }

  @action public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  protected toServiceModel() {
    let users:any =[]; 
        (this.selectedUserArray.values || []).map((item:any)=>{
          users.push({
            userId:item.id,
            agencyId:item.downlineAgencyId || 0 
          }) 
        }) 
    var dto: RoleModel = {
      id: this.selectedId,
        userRoleName: this.userRoleName,
        isDownlineRole: this.actionTitle === Role.CloneTitle ? this.checkDownlineClone : this.checkDownline, 
        userInfo: users,
      agencyIds: [...new Set(this.selectedAgencyArrayListIds)]
    };
    return dto;
  }

  @action getUserPermission = async () => {
    await IdentityStore.getUserPermissions("" + UserContext.getUserId()).then(
      (data: any) => {
        if (data) {
          UserContext.permissions = data.permissions;
          UserContext.roles = data.roles;
        }
      }
    );
    };

    @action cloneRole = async (item: RoleModel) => {
        try {            
            localStorage.setItem("roleCloned", "cloned");
            item.id = item && item.id == null ? this.selectId : item.id;
            await IdentityStore.cloneRole(item);
            this.cloneRoleToasterConfirm = true;
            this.isCloneConfirm = false;
            setTimeout(async () => {
                this.cloneRoleToasterConfirm = false;
                localStorage.removeItem("roleCloned");
            }, 3000);
            this.checkDownlineClone = true;
        } catch (e: any) {
            localStorage.removeItem("roleCloned");
            this.cloneRoleToasterConfirm = false;
            this.isCloneConfirm = true;
            e.text().then((_responseText: any) => {
                let _title = JSON.parse(_responseText).title;
                this.showErrorMessageinToaster(`${_title}`);
            });
            this.isLoading = false;
            PageContext.setIsMessageVisible(false);
            IMASLog.log("exception: " + e);
        }
        
    }

    @action SaveClone = async () => {
        await this.checkRoleName();

        if (!this.isRoleNameExists) {
            this.AddEditRoleItem();
            this.roleMandatoryErrorMsgClone = "";
        }
        else {
            this.roleMandatoryErrorMsgClone = this.roleMandatoryErrorMsg;
        }
    }

  @action AddEditRoleItem = async () => {
    try {  
      var item = this.toServiceModel(); 
      if(this.actionTitle === Role.AddTitle){
        localStorage.setItem("roleCloned", "cloned");
      }else if(this.actionTitle === Role.EditTitle){
        localStorage.setItem("roleCloned", "cloned");
        const elem = document.querySelector(".p-dialog-content");
        if(elem) {
          const rect = elem.getBoundingClientRect(); 
          if (rect.height >480){
            this.roleModalIsOverflow = true;
          }else{
            this.roleModalIsOverflow = false
          }
        }
      }
        var result;
        if (this.actionTitle === Role.AddTitle) {
            result = await IdentityStore.addRole(item);
            this.addRoleToasterConfirm = true;
            this.updateRoleToasterConfirm = false;
            this.setCloneConfirm(false, false);
        }
        else if (this.actionTitle === Role.EditTitle) {
            result = await IdentityStore.updateRole(item);
            this.addRoleToasterConfirm = false;
            this.updateRoleToasterConfirm = true;
            this.setCloneConfirm(false, false);
        }
        else {
            localStorage.setItem("roleCloned", "cloned");
            result = await IdentityStore.cloneRole(item);
            this.cloneRoleToasterConfirm = true;
            this.isCloneConfirm = false;
            this.checkDownlineClone = true;
            setTimeout(async () => {
                this.cloneRoleToasterConfirm = false;
                localStorage.removeItem("roleCloned");
            }, 3000);
        }    
           
        this.setAddEditRoleDialogVisible(false);
        
        this.selectedId = +result;         
      await this.getUserPermission()
      // setTimeout(() => {
      //   if (this.selectedId !== 0 && this.actionTitle === Role.AddTitle) {
      //     localStorage.setItem("roleName", this.userRoleName);
      //     routes.rolePermissions.replace({ Id: this.selectedId });
      //   } else {
      //     this.Load();
      //   }
      // }, 800);
      this.Load();
      // this.addRoleToasterConfirm = false;
      // this.updateRoleToasterConfirm = false;
            
        setTimeout(async () => {
          this.addRoleToasterConfirm = false;
          this.updateRoleToasterConfirm = false;
          localStorage.removeItem("roleDeleted");
        }, 10000);
    } catch (e: any) {
        localStorage.removeItem("roleDeleted");
        localStorage.removeItem("roleCloned");
        if (this.actionTitle && this.actionTitle != Role.AddTitle && this.actionTitle != Role.EditTitle) {
            this.isCloneConfirm = true;
            this.cloneRoleToasterConfirm = false;
            this.setAddEditRoleDialogVisible(false);
        }
        else {
            this.isCloneConfirm = false;
            this.setAddEditRoleDialogVisible(true);
        }             

        e.text().then((_responseText: any) => {
            let _title = JSON.parse(_responseText).title;
            this.showErrorMessageinToaster(`${_title}`);
        });
        this.isLoading = false;
        PageContext.setIsMessageVisible(false);
        IMASLog.log("exception: " + e);
    }
  };

    @action hideValidationToaster = () => {
        toast.dismiss("validation-toaster");
    };

    @action showErrorMessageinToaster(message: string) {
        toast.warning(message, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: false,
            closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
            className: "validation-toaster website",
            draggable: false,
            toastId: "validation-toaster",
            autoClose: 7000,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            this.hideValidationToaster();
        }, 7000);
    }

  @action setAddEditRoleDialogVisible(value: boolean) {
    this.addEditRole = value;
  }

  @action onHide = async (value: string) => {
    this.setAddEditRoleDialogVisible(false);
  };

  @action handleDownlineCheckBoxChange = (e: any) => {
    this.checkDownline = !e.target.value;
    this.selectedUserArray.values = [];
    this.selectedAgencyArray.values = [];
    this.selectedUser = "";
    this.selectedAgency = "";
    this.selectedAgencyArrayListIds=[];
    this.roleModalIsOverflow = false;
  };
  
  @action AddEditRole = async (id: number, isDownlineRole: boolean, action_item: string) => {
    this.cloneRoleToasterConfirm = false;
    this.addRoleToasterConfirm = false;
    this.updateRoleToasterConfirm = false;
    this.roleMandatoryErrorMsgClone = "";
    this.isRoleNameExists = false;
    this.userRoleName = "";
    this.selectedUser = "";
    this.selectedAgency = "";
    this.checkDownline = false;
    this.selectedUserArray.values = [];
    this.selectedNotExistingUsers = null
    this.selectedAgencyArrayListIds=[];
    this.showAgencyDeleteConfirm = false;
    if (action_item === Role.Add) {
      this.isRoleEdit = false;
      this.isSaveEnable = true;
      this.isRoleEditName = '';
      this.disablecheckDownline= false; 
      this.actionTitle = Role.AddTitle;
      this.selectedId = 0;  
        this.showUserError = false;
        this.setAddEditRoleDialogVisible(true);
    } else if (action_item === Role.Edit) {
      this.isRoleEdit = true;
      this.isSaveEnable = false;
      this.disablecheckDownline= true;
      this.actionTitle = Role.EditTitle;
      var result = await IdentityStore.getRoleById(id);
      if (result) { 
        this.selectedAgencyArrayListIds=[];
        this.checkDownline =  result.isDownlineRole && result.isDownlineRole || false;
        this.userRoleName = result.userRoleName ? result.userRoleName : "";
        this.isRoleEditName = result.userRoleName ? result.userRoleName : "";
        this.selectedUserArray.values = result.userInfo ? result.userInfo : [];
        this.selectedAgencyArray.values = result.downlineAgencys ? result.downlineAgencys : [];
        this.selectedId = id;
        this.showUserError = false;
        (this.selectedAgencyArray.values || []).map((item:any)=>{
          this.selectedAgencyArrayListIds.push(item.id)
        });
        [...new Set(this.selectedAgencyArrayListIds)] 
          this.setAddEditRoleDialogVisible(true);
      }
    } else {
      this.selectedId = id;
      this.isRoleEdit = false;
      this.isRoleEditName = '';
      this.disablecheckDownline= false;
      this.actionTitle = Role.CloneTitle;
      this.setCloneConfirm(true, isDownlineRole);
    }
    this.ResetValidate();
    PageContext.setIsMessageVisible(false);    
  };

  @action search = async () => {
    this.resetPaging();
    this.loadItems();
  };
  @action reset = async () => {
    this.resetPaging();
    this.resetFiltersToDefault();
    this.loadItems();
  };

  resetFiltersToDefault() {
    this.searchValue = "";
    this.pageSize = 50;
  }

  @action deleteRole = async () => {
    this.setDeleteConfirm(false);
    this.userList = [];
    this.deleteRoleName = this.selectedrow.name;
    this.deleteRoleToasterConfirm = true;
    localStorage.setItem("roleDeleted", "deleted");
    await IdentityStore.deleteRole(this.selectedrow.id);   
    var items = this.itemList.values;    
    var index = items.indexOf(this.selectedrow);
    items.splice(index, 1);
    this.itemList.values = items;
    this.loadItems();
    this.selectedrow = "";      
    setTimeout(async () => {
        this.deleteRoleToasterConfirm = false;
        localStorage.removeItem("roleDeleted");
    }, 3000);
  };

  @action deleteConfirm = async (model: any) => {
    // var result = await IdentityStore.getUsersByRoleId(model.id);
      this.selectedrow = model;
      this.deleteRoles = Constants.DeleteRole;
        if ((this.selectedrow.usersAssignedToRole && this.selectedrow.usersAssignedToRole.length > 0) || (this.selectedrow.downlineAgencyAssignedToRole && this.selectedrow.downlineAgencyAssignedToRole.length > 0))
            this.deleteRoles = Constants.DeleteRoles;
    // if (result) {
      this.setDeleteConfirm(true);
    //   this.userList =
    //     result.length !== 0 ? "The role is assigned to " + result + ". " : "";
    // }
  };

  @action setDeleteConfirm = (value: boolean) => {
    this.isDeleteConfirm = value;
  };

  @action setCloneConfirm = (value: boolean, isDownlineRole: boolean) => {
      this.isCloneConfirm = value;
        if (value) {
            this.isSaveEnable = true;            
            if (isDownlineRole)
                this.checkDownlineClone = true;
            else
                this.checkDownlineClone = false;
        }
    };
     
  @action goToAdmin = () => {
    routes.administration.replace();
  };

  @action goToRoles = () => {
    routes.listRole.replace();
  };
}

class RoleValidator extends AbstractValidator<RoleListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.userRoleName)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
  }
}
