import { action, computed, observable, toJS } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { PasswordListResponse } from "../../../../services/IdentityService";
import IdentityStore from "../../../../store/IdentityStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import UserContext from "../../../../infrastructure/UserContext";
import IMASLog from "../../../../infrastructure/IMASLog";
import PageContext from "../../../../infrastructure/PageContext";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../../views/components/controls/IMASTemplates";

export class PagedList<T> {
  public recordCount: number = 0;
  public data: T[] = [];
}
export class CarrierCMWebsitesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};
  public ErrorModel = new ErrorModel(new WebsitesValidator());
  @observable itemList = new Collection<any>();
  @observable cmSearchInputValue: string = "";
  @observable rowId: number = 0;
  @observable response: any = "";
  @observable isMessgeVisible: boolean = false;

  @observable selectedRowData: any;
  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 50;
  @observable pageIndex: number = 0;

  @observable sortColumn: string = "websiteURL";
  @observable isSortAscending: boolean = true;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 50;
  @observable sort: boolean = false;

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;
  @observable isWebsitesAddEditDialogVisible: boolean = false;
  @observable disableSave: boolean = true;
  @observable isNotValidWebsites: boolean = false;
  @observable isWebsitesDeleteDialogVisible: boolean = false;
  @observable isWebsitesDiscardDialogVisible: boolean = false;
  @observable selectedRowId: number = 0;

  @observable expandableRows: any = [];

  @observable isShowCreateToastWebsites: boolean = false;
  @observable isShowDeleteToastWebsites: boolean = false;
  @observable isShowUpdatedToastWebsites: boolean = false;
  @observable isShowDiscardedToastWebsites: boolean = false;
  @observable isShowEditToastWebsites: string = "";
  @observable WebsitesTitleToaster: string = "";
  @observable timer: any;

  @observable title: string = "";
  @observable carrierID: number = 0;
  @observable Url: string = "";
  @observable userName: string | undefined;
  @observable urlName: string | undefined;
  @observable siteAdministrator: string | undefined;
  @observable agentNumber: string | undefined;
  @observable hints: string | undefined;
  @observable isRefreshEnable: boolean = false;
  @observable urlPlaceHolder: string = "Enter URL";
  @observable optional: string = "(optional)";
  @observable userNamePlaceHolder: string = "Enter Username";
  @observable userNameErrorMsg: string = "Username.";
  @observable urlNamePlaceHolder: string = "Administration, Commission, etc.";
  @observable urlNameErrorMsg: string = "URL Name.";
  @observable siteAdministratorPlaceHolder: string = "Enter Site Administrator";
  @observable siteAdministratorErrorMsg: string = "Site Administrator.";
  @observable agentNumberPlaceHolder: string = "XXXXXXXXXX";
  @observable agentNumberErrorMsg: string = "Agent Number.";
  @observable public ErrorModelWebsites = new ErrorModel(
    new WebsitesValidator()
  );

  @action resetToasters = () => {
    this.isShowCreateToastWebsites = false;
    this.isShowUpdatedToastWebsites = false;
    this.isShowDiscardedToastWebsites = false;
    this.isShowDeleteToastWebsites = false;
  };

  @action public ValidateWebsites = async () => {
    return this.ErrorModelWebsites.Validate(this);
  };
  @action enableDisableSaveButton = async () => {
    if (!(await this.ValidateWebsites())) {
      if (!this.isNotValidWebsites && this.Url.trim().length > 0)
        this.disableSave = false;
      else this.disableSave = true;
    } else {
      this.disableSave = true;
    }
  };

  @action cancel = () => {
    this.setWebsitesAddEditDialogVisibility(false);
    this.resetWebsitesForm();
  };

  @action resetWebsitesForm() {
    this.Url = "";
    this.userName = "";
    this.urlName = "";
    this.siteAdministrator = "";
    this.agentNumber = "";
    this.hints = "";
    this.isMessgeVisible = false;
    this.disableSave = true;
    this.isNotValidWebsites = false;
  }

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false; 
  @action handleClick = (id: string) => { 
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
    };

  @action addWebsites = async () => {
    this.isNotValidWebsites = false;
    if (!(await this.ValidateWebsites())) {
      if (this.isNotValidWebsites) return true;
      try {
        this.resetToasters();
        var dto: any = {
          Id: this.selectedRowId,
          companyId: this.carrierID,
          websiteURL: this.Url,
          urlName: this.urlName,
          comment: "",
          userName: this.userName,
          password: "",
          hint: this.hints,
          lastUpdated: new Date(),
          updatedByUserID: UserContext.getUserId(),
          hidden: false,
          agentNumber: this.agentNumber,
          adminPhoneNumber: "",
          marketingPhoneNumber: "",
          siteAdministrator: this.siteAdministrator,
        };

        if (this.selectedRowId == 0) {
          localStorage.setItem("CMWebsitesAdd", "add");
          await IdentityStore.addPasswordList(dto);
          clearTimeout(this.timer);
          this.timer = setTimeout(async () => {
            this.isShowCreateToastWebsites = true;
            localStorage.removeItem("CMWebsitesAdd");
          }, 300);
        } else {
          localStorage.setItem("CMWebsitesEdit", "edit");
          await IdentityStore.updatePasswordList(dto);
          clearTimeout(this.timer);
          this.timer = setTimeout(async () => {
            this.isShowUpdatedToastWebsites = true;
            localStorage.removeItem("CMWebsitesEdit");
          }, 300);
        }
        await this.setWebsitesAddEditDialogVisibility(false);
        await this.loadAllWebsites();
        PageContext.setIsMessageVisible(false);
        this.resetWebsitesForm();
        this.resetToasters();
        this.selectedRowId = 0;
        this.isLoading = false;
      } catch (e: any) {
          e.text().then((_responseText: any) => {
              let _title = JSON.parse(_responseText).title;
              this.showErrorMessageinToaster(`${_title}`);
          });
          this.isLoading = false;
          PageContext.setIsMessageVisible(false);
          IMASLog.log("exception: " + e);
      }
    }
  };

    @action hideValidationToaster = () => {
        toast.dismiss("validation-toaster");
    };

    showErrorMessageinToaster(message: string) {
        toast.warning(message, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: false,
            closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
            className: "validation-toaster website",
            draggable: false,
            toastId: "validation-toaster",
            autoClose: 7000,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            this.hideValidationToaster();
        }, 7000);
    }

  @action refresh = () => {
    this.cmSearchInputValue = "";
    this.isRefreshEnable = false;
    this.loadAllWebsites();
  };

  @action setUrl = async (value: string) => {
    this.Url = value;

    this.enableDisableSaveButton();
  };
  @action setUserName = async (value: string) => {
    this.userName = value;
  };
  @action setUrlName = async (value: string) => {
    this.urlName = value;
  };
  @action setSiteAdministrator = async (value: string) => {
    this.siteAdministrator = value;
  };
  @action setAgentNumber = async (value: string) => {
    this.agentNumber = value.replace(/[^a-z0-9]/gi, "");
  };
  @action setHints = async (value: string) => {
    this.hints = value;
  };

  @action Load = async (id: number) => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.pageSize = 50;
    this.pageIndex = 0;
    this.carrierID = id;
    this.cmSearchInputValue = "";
    this.isRefreshEnable = false;
    this.defaultSortColumn = undefined;
    this.sortColumn = "Id";
    this.isSortAscending = true;
    this.defaultIsSortAscending = true;
    this.resetToasters();
    this.expandableRows = [];
    this.loadAllWebsites();
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;
    this.loadAllWebsites();
  }

  @action loadAllWebsites = async () => {
    try {
      this.isLoading = true;
      this.itemList.values = [];

      const response = await IdentityStore.getPasswordList(
        this.carrierID,
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sort,
        "" + this.cmSearchInputValue
      );
      if (response && response.data && response.data.length > 0) {
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.itemList.values = response.data;
        this.selectedRowData = this.itemList.values[0];
      } else {
        this.totalRecords = 0;
        this.itemList.values = [];
        this.selectedRowData = [];
      }
      this.resetToasters();
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action setWebsitesAddEditDialogVisibility = async (value: boolean) => {
    this.isWebsitesAddEditDialogVisible = value;
  };

  @action selectedRow = async (data: any) => {
    this.selectedRowId = data.id;
  };

  @action setWebsitesDeleteDialogVisible = async (value: boolean) => {
    this.resetToasters();
    this.isWebsitesDeleteDialogVisible = value;
  };

  @action cancelNotesDelete = async (value: boolean) => {
    this.isWebsitesDeleteDialogVisible = value;
  };

  @action handleWebsite = (value: boolean) => {
    this.isWebsitesDiscardDialogVisible = value;
  };

  @action deleteWebsites = async () => {
    localStorage.setItem("CMWebsitesDelete", "delete");
    this.resetToasters();
    try {
      const response = await IdentityStore.deletePasswordList(
        this.selectedRowId
      );
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.isShowDeleteToastWebsites = true;
        localStorage.removeItem("CMWebsitesDelete");
      }, 300);
      this.isWebsitesDeleteDialogVisible = false;
      this.selectedRowId = 0;
      this.loadAllWebsites();
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }

  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action formatDate(date: Date | undefined) {
    return Utils.getDateTimeInFormat(date);
  }

  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.isSortAscending = this.defaultIsSortAscending;
  };

  @action onFieldChange = (e: any) => {
    e.persist();
    this.cmSearchInputValue = e.currentTarget.value;

    this.isRefreshEnable = true;
    if (this.cmSearchInputValue.length === 0) this.isRefreshEnable = false;

    if (
      this.cmSearchInputValue.length >= 3 ||
      this.cmSearchInputValue.length === 0
    ) {
      this.loadAllWebsites();
    }
  };

  readonly currentPageReportTemplate: string =
    "Showing {first} - {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }

  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [50, 100, 150, 200, 250] : [];
  }
}

class WebsitesValidator extends AbstractValidator<CarrierCMWebsitesViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.Url)
      .isNotEmpty()
      .withFailureMessage("");
  }
}
