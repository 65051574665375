import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import Utils from "../infrastructure/Utils";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import { PageMode } from "../infrastructure/enum/Common";
import {
  Client as DocumentServiceClient,
  DocumentFileRequest,
  DocumentFileTypeModel,
  SubmissionTypesRequest,
} from "../services/DocumentService";
import { Client as WorkflowServiceClient } from "../services/WorkflowService";
import { AgentDocumentOptionValues } from "../infrastructure/enum/AgentDetails";
class DocumentStore {
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;
  // adal methods
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): DocumentServiceClient => {
    return ServiceClient.getClient(ServiceType.Document);
  };
  getWorkFlowClient = (): WorkflowServiceClient => {
    return ServiceClient.getClient(ServiceType.Workflow);
  };

  // Agent document
  @action getAllLookupList = async (
    agentId: number,
    documentTypeId: number
  ) => {
    var result = await this.getClient().getDocumentById(
      documentTypeId,
      this.getApiVersion(),
      agentId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getFaxAndEmailList = async (companyId: number) => {
    // var result = await this.getClient().getCompanyFaxMarketerEmail(
    //   this.getApiVersion(),
    //   companyId,
    //   UserContext.getUserId()
    // );
    // return result;
    // the end point is removed
    return undefined;
  };

  @action getWorkFlowList = async (
    agentId: number,
    documentTypeId: number,
    companyId: number
  ) => {
    var result = await this.getClient().getWorkflowData(
      this.getApiVersion(),
      companyId,
      documentTypeId,
      agentId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgentDocuments = async (
    agentId: number,
    documentTypeId: number,
    companyId: number,
    searchKey: string,    
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined,
    status: boolean | null | undefined = null,
  ) => {
    var result = await this.getClient().getAllDocument(
      agentId,
      this.getApiVersion(),
      documentTypeId,
      companyId,
      searchKey,
      (status != null && status !=undefined) ? status : undefined ,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.getUserId()
    );
    return result;
  };
  @action getDocumentLink = async (documentId: number, typeId: number) => {
    var result = await this.getClient().downloadPdf(
      this.getApiVersion(),
      documentId,
      typeId,
      UserContext.getUserId()
    );
    return result;
  };
  @action downloadFiles = async (
    documentId: number,
    typeId: number,
    isAgent: boolean
  ) => {
    var result = await this.getClient().downloadFiles(
      this.getApiVersion(),
      documentId,
      typeId,
      isAgent,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgentDocWorkFlowHistory = async (documentId: number) => {
    var result = await this.getWorkFlowClient().getAllWorkflowNotes(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteDocument = async (documentId: number) => {
    var result = await this.getClient().deleteDocument(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action CreateUpdateDocument = async (docModel: any) => {
    var blob = null;
    if (
      docModel.fileData != null &&
      docModel.fileData !== "" &&
      docModel.fileData !== undefined
    ) {
      // Split the base64 string in data and contentType
      var block = docModel.fileData ? docModel.fileData.split(";") : "";
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      // Convert it to a blob to upload
      blob = Utils.b64toBlob(realData, contentType, 512);
    }
    docModel.fileData = "";

    var result = await this.getClient().createDocument(
      this.getApiVersion(),
      UserContext.userId, 
      undefined,
      docModel.id,
      docModel.fName,
      docModel.lName,
      docModel.agentId,
      docModel.typeId,
      docModel.companyId,
      0,
      docModel.notes,
      docModel.isPrivate,
      docModel.createdDate,
      docModel.fileData,
      0,
      docModel.licenseStatusId,
      docModel.isWorkflowChecked,
      docModel.isDontSend,
      docModel.ignoreForAgeCalculation,
      blob ? { data: blob, fileName: docModel.fileName } : null,
      docModel.isSendSubmission,
      docModel.submissionTypeIds
    );
    return result;
  };
  @action UpdateDocument = async (docModel: any) => {
    var blob = null;
    if (
      docModel.fileData != null &&
      docModel.fileData !== "" &&
      docModel.fileData !== undefined
    ) {
      // Split the base64 string in data and contentType
      var block = docModel.fileData ? docModel.fileData.split(";") : "";
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      // Convert it to a blob to upload
      blob = Utils.b64toBlob(realData, contentType, 512);
    }
    docModel.fileData = "";

    var result = await this.getClient().updateDocument(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      docModel.id,
      docModel.typeId,
      docModel.companyId,
      docModel.notes,
      docModel.isPrivate,
      docModel.isSendSubmission ? AgentDocumentOptionValues.SubmitTo : docModel.isDontSend ? AgentDocumentOptionValues.DontSendJustAttach : docModel.isWorkflowChecked ? AgentDocumentOptionValues.EnterIntoWorkflowScrub : 0,
      docModel.isSendSubmission ? docModel.submissionTypeIds : "",
      1,     
      { data: blob, fileName: docModel.fileName },
      docModel.licenseStatusId,
      docModel.isWorkflowChecked,
      docModel.ignoreForAgeCalculation,
      docModel.agentId,
      docModel.isSendSubmission,
      docModel.submissionTypeIds
 
    );
    return result;
  };
  @action editDocument = async (documentId: number) => {
    var result = await this.getClient().editDocument(
      this.getApiVersion(),
      documentId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getCustomerDocuments = async (
    customerId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllCustomerDocument(
      customerId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };

  @action getCustomerDocumentsOnSearch = async (
    customerId: number,
    fullTextFilter:string,
    startIndex: number | undefined,
    pageSize: number | undefined,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllCustomerDocument(
      customerId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter ,
      UserContext.userId
    );
    return result;
  };

  @action downloadCustomerDoc = async (
    documentId: number,
    documentType: number
  ) => {
    var result = await this.getClient().downloadCustomerPdf(
      this.getApiVersion(),
      documentId,
      documentType,
      UserContext.userId
    );
    return result;
  };

  @action getDocumentAssocaitionsByDocumentId = async (documentId: number) => {
    var result = await this.getClient().getDocumentAssocaitionsByDocumentId(
      documentId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action loadAllDocList = async (
    customerId: number,
    policyId: number,
    docTypeId: number
  ) => {
    var result = await this.getClient().getCustomerAddDocumentsDetails(
      customerId,
      policyId,
      docTypeId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action CreateCustomerDocument = async (docModel: any) => {
    var blob = null;
    if (
      docModel.fileData != null &&
      docModel.fileData !== "" &&
      docModel.fileData !== undefined
    ) {
      // Split the base64 string in data and contentType
      var block = docModel.fileData ? docModel.fileData.split(";") : "";
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      // Convert it to a blob to upload
      blob = Utils.b64toBlob(realData, contentType, 512);
    }
    docModel.fileData = "";
    var result = await this.getClient().createCustomerDocument(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      docModel.customerId,
      undefined,
      docModel.typeId || 0,
      0,
      docModel.notes || "",
      docModel.policyId || 0,
      docModel.licenseStatusId || 0,
      docModel.isSendAgentEmailChecked || false,
      docModel.isSendDocViaReSubmitChecked || false,
      docModel.documentId || 0,
      blob ? { data: blob, fileName: docModel.fileName } : undefined,
      docModel.isSendSubmission || false,
      docModel.submissionTypeIds,
      docModel.submissionTypeText || ""
    );
    return result;
  };

  // DMS functions
  @action getAllDMSLookupList = async () => {
    var result = await this.getClient().getAllManageDocumentLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getDocumentTypesByGroupId = async (categoryId: number) => {
    var result = await this.getClient().getDocumentTypeByGroupId(
      categoryId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getLookUpByCompanyId = async (
    companyId: number,
    companyGroupId: number,
    productGroupId: number,
    fromSearch?: boolean | undefined
  ) => {
    var result = await this.getClient().getAllLookupbyCompany(
      this.getApiVersion(),
      companyId,
      companyGroupId,
      productGroupId,
      fromSearch ? fromSearch : false,
      UserContext.getUserId()
    );
    return result;
  };

  @action getComapniesByCompanyGroupId = async (companyGroupId: number) => {
    var result = await this.getClient().getAllCompanybyCompanyGroupId(
      companyGroupId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getProductByProductGroupId = async (
    companyId: number,
    companyGroupId: number,
    productGroupId: number,
    fromSearch?: boolean | undefined
  ) => {
    var result = await this.getClient().getAllProductsbyProductSubCategoryId(
      this.getApiVersion(),
      companyId,
      companyGroupId,
      productGroupId,
      fromSearch ? fromSearch : false,
      UserContext.getUserId()
    );
    return result;
  };

  @action searchDocuments = async (
    categoryId: number,
    companyId: number,
    companyGroupId: number,
    stateId: number,
    productId: number,
    productSubCategoryId: number,
    payLayoutId: number,
    agencyId: number,
    documentTypeId: [],
    title: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllManageDocument(
      this.getApiVersion(),
      categoryId,
      companyId,
      companyGroupId,
      stateId,
      productId,
      productSubCategoryId,
      payLayoutId,
      agencyId,
      documentTypeId.join(","),
      title,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined
    );
    return result;
  };
  @action searchDocumentPackets = async (
    categoryId: number,
    companyId: number,
    companyGroupId: number,
    stateId: number,
    productId: number,
    productSubCategoryId: number,
    payLayoutId: number,
    AgencyId: number,
    documentTypeId: [],
    title: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllDocumentPackets(
      this.getApiVersion(),
      categoryId,
      companyId,
      companyGroupId,
      stateId,
      productId,
      productSubCategoryId,
      payLayoutId,
      AgencyId,
      documentTypeId,
      title,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getDocumentDetails = async (packetId: number) => {
    var result = await this.getClient().getDocumentByPacketId(
      packetId,
      this.getApiVersion()
    );
    return result;
  };
  @action downloadBudlePdf = async (packetId: number, documentIds: string) => {
    var result = await this.getClient().downloadBundlePdf(
      this.getApiVersion(),
      packetId,
      documentIds,
      UserContext.getUserId()
    );
    return result;
  };
  @action downloadSignaturePages = async (
    packetId: number,
    documentIds: string
  ) => {
    var result = await this.getClient().downloadSignaturePages(
      this.getApiVersion(),
      packetId,
      documentIds,
      UserContext.getUserId()
    );
    return result;
  };
  @action viewDocument = async (documentId: number) => {
    var result = await this.getClient().downloadManagePdf(
      documentId,
      this.getApiVersion()
    );
    return result;
  };

  @action sendEmail = async (
    packetId: number,
    documentIds: string,
    groupId: number,
    agentId: number,
    companyId: number,
    toEmails: string,
    ccEmails: string,
    subject: string,
    message: string,
    specialInstructions: string,
    saveToNotes: boolean,
    uplineAgentId: number,
    uplineDnCall: boolean,
    uplineDnEmail: boolean,
    uplineDnMarket: boolean,
    downlineDnCall: boolean,
    downlineDnEmail: boolean,
    downlineDnMarket: boolean,
    isBundlePdfs: boolean,
    bundlePdfsName: string,
    includeSignaure: boolean
  ) => {
    var request = {
      packetId: packetId,
      documentIds: documentIds,
      agentId: agentId,
      companyId: companyId,
      toEmails: toEmails,
      ccEmails: ccEmails,
      subject: subject,
      message: message,
      specialInstructions: specialInstructions,
      isSavetoAgentNote: saveToNotes,
      bundlePdfs: isBundlePdfs,
      bundleName: bundlePdfsName,
      includeSignaturePages: includeSignaure,
      uplineAgentId: uplineAgentId,
      groupId: groupId,
      dnCall: uplineDnCall,
      dnEmail: uplineDnEmail,
      dnMarket: uplineDnMarket,
      downlineDNCall: downlineDnCall,
      downlineDNEmail: downlineDnEmail,
      downlineDNMarket: downlineDnMarket,
    };
    var result = await this.getClient().sendDocumentEmail(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  /* Admin apis */
  // documents
  @action searchAdminDocuments = async (
    documentTypeId: number,
    categoryId: number,
    companyId: number,
    productId: number,
    title: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllAdminDocument(
      this.getApiVersion(),
      documentTypeId,
      categoryId,
      companyId,
      productId,
      title,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined
    );
    return result;
  };
  @action getAllDocumentLookup = async () => {
    var result = await this.getClient().getAllAdminLookup(this.getApiVersion());
    return result;
  };
  @action viewArchivedDocuments = async (documentId: number) => {
    var result = await this.getClient().getAllArchivedDocuments(
      this.getApiVersion(),
      documentId,
      undefined,
      0,
      undefined,
      "id",
      false,
      undefined,
      undefined
    );
    return result;
  };
  @action documentDetails = async (documentId: number) => {
    var result = await this.getClient().getAdminDocumentById(
      documentId,
      this.getApiVersion()
    );
    return result;
  };
  @action withdrawdocument = async (documentId: number) => {
    var result = await this.getClient().withDrawDocumentById(
      documentId,
      this.getApiVersion()
    );
    return result;
  };
  @action CreateUpdateDMSDocument = async (
    id: number,
    title: string,
    description: string,
    documentTypeId: number,
    companyId: number,
    productId: number,
    fileName: string,
    fileData: any,
    mode: PageMode,
    dateCreated?: number,
    createUserId?: number,
    masterDocId?: number,
    showOnAgentPortal?: boolean,
    agentPortalPageID?: number,
    agentPortalYear?: number
  ) => {
    var blob = new Blob();
    if (fileData != null && fileData !== "" && fileData !== undefined) {
      // Split the base64 string in data and contentType
      var block = fileData ? fileData.split(";") : "";
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      blob = Utils.b64toBlob(realData, contentType, 512);
    }
    var result = null;
    if (mode === PageMode.Update) {
      result = await this.getClient().uploadAdminDocument(
        this.getApiVersion(),
        UserContext.getUserId(),
        undefined,
        id,
        title,
        description,
        documentTypeId,
        companyId,
        productId,
        fileName,
        dateCreated,
        createUserId,
        masterDocId,
        { data: blob, fileName: fileName },
        showOnAgentPortal,
        agentPortalPageID,
        agentPortalYear
      );
    } else {
      if (id > 0) {
        result = await this.getClient().updateAdminDocument(
          this.getApiVersion(),
          UserContext.getUserId(),
          undefined,
          id,
          title,
          description,
          documentTypeId,
          companyId,
          productId,
          fileName,
          dateCreated,
          createUserId,
          masterDocId,
          { data: blob, fileName: fileName },
          showOnAgentPortal,
          agentPortalPageID,
          agentPortalYear
        );
      } else {
        result = await this.getClient().createAdminDocument(
          this.getApiVersion(),
          UserContext.getUserId(),
          undefined,
          id,
          title,
          description,
          documentTypeId,
          companyId,
          productId,
          fileName,
          dateCreated,
          createUserId,
          masterDocId,
          { data: blob, fileName: fileName },
          showOnAgentPortal,
          agentPortalPageID,
          agentPortalYear
        );
      }
    }

    return result;
  };
  //Packets
  @action searchAdminPackets = async (
    documentTypeId: number,
    companyId: number,
    description: string,
    title: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllPackets(
      this.getApiVersion(),
      documentTypeId,
      companyId,
      description,
      title,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined
    );
    return result;
  };
  @action getAllPacketLookup = async () => {
    var result = await this.getClient().getAllPacketsLookup(
      this.getApiVersion()
    );
    return result;
  };
  @action viewPacketDocuments = async (packetId: number) => {
    var result = await this.getClient().getPacketDocumentById(
      packetId,
      this.getApiVersion()
    );
    return result;
  };
  @action packetDetails = async (packetId: number) => {
    var result = await this.getClient().getPacketById(
      packetId,
      this.getApiVersion()
    );
    return result;
  };
  @action duplicatePacket = async (packetId: number, packetName: string) => {
    var result = await this.getClient().duplicatePacket(
      packetId,
      packetName,
      this.getApiVersion()
    );
    return result;
  };
  @action getLookUpByCompany = async (packetId: number) => {
    var result = await this.getClient().getLookUpByCompany(
      packetId,
      this.getApiVersion()
    );
    return result;
  };
  @action getProductByCompany = async (packetId: number) => {
    var result = await this.getClient().getProductsByCompany(
      packetId,
      this.getApiVersion()
    );
    return result;
  };

  @action deletePacket = async (packetId: number) => {
    var result = await this.getClient().deletePacket(
      packetId,
      this.getApiVersion()
    );
    return result;
  };

  @action savePacket = async (
    packetId: number,
    typeGroupId: number,
    packetName: string,
    description: string,
    companyId: number,
    payoutLevelId: number,
    signaturePageDocumentId: number,
    selectedStates: any,
    selectedProducts: any,
    packetDocuments: any
  ) => {
    var createUpdatePacketRequest = {
      documentPacketId: packetId,
      documentPacketGroupId: typeGroupId,
      packetName: packetName,
      description: description,
      companyId: companyId,
      payoutLevelId: payoutLevelId,
      signaturePageDocumentId: signaturePageDocumentId,
      selectedStates: selectedStates,
      selectedProducts: selectedProducts,
      packetDocuments: packetDocuments,
    };
    var result = null;
    if (packetId > 0) {
      result = await this.getClient().updatePacket(
        this.getApiVersion(),
        undefined,
        undefined,
        createUpdatePacketRequest
      );
    } else {
      result = await this.getClient().createPacket(
        this.getApiVersion(),
        undefined,
        undefined,
        createUpdatePacketRequest
      );
    }

    return result;
  };

  @action getCustomerWorkflowList = async (policyId: number) => {
    var result = await this.getClient().getCustomerWorkflowDocument(
      this.getApiVersion(),
      policyId,
      UserContext.userId
    );
    return result;
  };

  @action downloadactionlogfile = async (fileGuid: string) => {
    var result = await this.getClient().downloadActionLogFile(
      this.getApiVersion(),
      fileGuid,
      UserContext.userId
    );
    return result;
  };

  @action getAgentDocScreenLookups = async () => {
    var result = await this.getClient().getAgentDocScreenLookups(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getAllDocumentFileTypes = async (
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined
  ) => {
    let result = await this.getClient().getAllDocumentFileTypes(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };
  @action addDocumentFileType = async (body: DocumentFileTypeModel) => {
    let result = await this.getClient().addDocumentFileType(
      this.getApiVersion(),
      UserContext.userId,
      body
    );
    return result;
  };
  @action updateDocumentFileType = async (body: DocumentFileTypeModel) => {
    let result = await this.getClient().updateDocumentFileType(
      this.getApiVersion(),
      UserContext.userId,
      body
    );
    return result;
  };
  @action getDocumentFileTypeById = async (id: number) => {
    let result = this.getClient().getDocumentFileTypeById(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action deleteDocumentFileType = async (id: number) => {
    let result = this.getClient().deleteDocumentFileType(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getDocumentFileTypesLookup = async () => {
    var result = await this.getClient().getDocumentFileTypesLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getDocumentAllFileTypesLookup = async () => {
    return await this.getClient().getDocumentAllFileTypesLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getSubmissionTypesByCarrierId = async (
    policyId: number,
    typeId: number,
    customerId: number
  ) => {
    var model = {
      policyId: policyId,
      typeId: typeId,
      customerId: customerId,
    } as SubmissionTypesRequest;

    var result = await this.getClient().getSubmissionTypesByCarrierId(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };

  @action getSubmissionTypesByDocumentId = async (
    documentId: number,
    customerId: number
  ) => {
    var result = await this.getClient().getSubmissionTypesByDocumentId(
      documentId,
      customerId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

    @action getFilesSizeById = async (
        documentId: number,
        documentType: number
    ) => {
        var model = {
            documentId: documentId,
            documentType: documentType
        } as DocumentFileRequest;
  

        var result = await this.getClient().getFilesSizeById(
            this.getApiVersion(),
            UserContext.getUserId(),
            undefined,
            model
        );
        return result;

    };  
    @action getDocumentByCompanyPacketIds = async (packetid: number) => {
        var result = await this.getClient().getDocumentsByCompanyPacketId(
            packetid,
            this.getApiVersion()
        );
        return result;
    };
    @action getAllPacketSearchByCompany = async (
        categoryId: number,
        carrierId: number,
        pageIndex: number,
        pageSize: number,
        sortColumn: string | undefined,
        sortAscending: boolean | undefined,
        searchParam: string | undefined,
    ) => {
        var result = await this.getClient().getAllPacketsSearchByCompany(
            this.getApiVersion(),
            categoryId,
            carrierId,
            pageIndex,
            pageSize,
            sortColumn,
            sortAscending,
            searchParam
        );
        return result;
    };
    @action getAllDocumentSearchByTypes = async (
        companyId: number,
        documentTypeId: number,
        pageIndex: number,
        pageSize: number,
        sortColumn: string | undefined,
        sortAscending: boolean | undefined,
        searchParam: string | undefined,
    ) => {
        var result = await this.getClient().getAllDocumentSearchByType(
            this.getApiVersion(),
            companyId,
            documentTypeId,
            pageIndex,
            pageSize,
            sortColumn,
            sortAscending,
            searchParam
        );
        return result;
    };
    @action downloadDocumentByPacketAsZip = async (
        packetId?: number | undefined,
        documentId?: number[] | null | undefined
    ) => {
        var result = await this.getClient().downloadDocumentByPacketAsZip(
            this.getApiVersion(),
            packetId,
            documentId
        );
        return result;
    };
    
    @action getUploadedDocumentFilesizeByDocumentId = async (
        documentId: number,
        documentType: number
    ) => {
        var model = {
            documentId: documentId,
            documentType: documentType,
        } as DocumentFileRequest;
 
    var result = await this.getClient().getUploadedDocumentFilesizeByDocumentId(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };
}
export default new DocumentStore();
