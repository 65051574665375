import { observer } from "mobx-react";
import React, { createRef } from "react";
import { Repeater } from "../Repeater";
import { AutoComplete } from "primereact/autocomplete";
import Utils from "../../../infrastructure/Utils";

export const IMASAutoComplete: React.FC<{
  errors: string[] | undefined;
  label: string;
  value: string;
  id: string;
  isrequired: boolean;
  suggestions: any[];
  placeHolder: string | undefined;
  itemTemplate: (data: any) => JSX.Element | undefined;
  onChange: (value: string) => void;
  onComplete: (value: string) => void;
  onSelect: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  isUpline?: boolean;
  inputDisabled?: boolean;
}> = observer((props) => {
  let className =
    props.suggestions && props.suggestions.length > 0
      ? (props.isUpline && props.suggestions[0].agentId === -1) ||
        (!props.isUpline && props.suggestions[0].id === -1)
        ? "imas-agent-auto-complete"
        : "agent-auto-complete"
      : "agent-auto-complete";
  let agentref = createRef<any>();
  const onSelectOption = (value: any) => {
    agentref &&
      agentref.current &&
      agentref.current.panel.element.classList.remove(
        "p-autocomplete-header-Panel"
      );
    let obj = Utils.getObject(value);
    if (obj.id === -1 || obj.agentId === -1) {
      if (agentref && agentref.current) {
        props.onChange("");
        props.onChange(props.value ? props.value : "");
        agentref.current.panel.element.classList.add(
          "p-autocomplete-header-Panel"
        );
        agentref.current.focus = true;
        var agentElementref = agentref.current;
        setTimeout(() => {
          agentElementref.panel.element.classList.add(
            "p-input-overlay-visible"
          );
          agentElementref.panel.element.style.display = "block";
          agentElementref.panel.element.classList.remove(
            "p-autocomplete-header-Panel"
          );
          agentElementref.bindDocumentClickListener();
        }, 400);
      }
    } else {
      props.onSelect(value);
    }
  };

  return (
    <>
      <label htmlFor={props.id}>
        {props.label} {props.isrequired && <span className="required">*</span>}{" "}
      </label>
      {props.disabled === undefined ||
      props.disabled === null ||
      !props.disabled ? (
        <>
          <AutoComplete
            value={props.value}
            id={props.id}
            ref={agentref}
            delay={350}
            style={{ Top: "0" }}
            className={className}
            disabled={props.inputDisabled}
            suggestions={props.suggestions}
            completeMethod={(e) => {
              props.onComplete(e.query);
            }}
            itemTemplate={props.itemTemplate}
            onSelect={(e) => {
              onSelectOption(e.value);
            }}
            size={30}
            placeholder={props.placeHolder}
            minLength={3}
            onChange={(e) => {
              props.onChange(e.value);
            }}
            onBlur={(e) => (props.onBlur ? props.onBlur() : {})}
            autoFocus={false}
            onFocus={(e) => {
              const div = document.getElementById(props.id) as HTMLElement;
              if (div) {
                let autocompleteDiv = div.querySelector(
                  ".p-autocomplete-input"
                ) as HTMLInputElement;
                if (autocompleteDiv) {
                  autocompleteDiv.select();
                }
              }
            }}
          />
        </>
      ) : (
        <>
          <div
            className="p-inputtext p-component p-autocomplete-input p-disabled p-filled"
            style={{ minHeight: "36.6px" }}
          >
            {props.value}
          </div>
        </>
      )}
      <Repeater
        errors={props.errors}
        child={(error) => <div className="invalid-feedback">{error}</div>}
        viewId={props.id}
      />
    </>
  );
});
