import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import RouteList from "../../../infrastructure/RouteList";
import IdentityStore from "../../../store/IdentityStore";
import Utils from "../../../infrastructure/Utils";
import { AuditTrailModel } from "../../../services/IdentityService";
import DocumentStore from "../../../store/DocumentStore";
import PageContext from "../../../infrastructure/PageContext";
import { routes } from "../../../router";



export class UserAuditTrailViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => {
       this.reset()
     //  this.loadActions();
    };

    Route = async (currentRoute: RouteList): Promise<void> => { }
    static Symbol: symbol = Symbol("UserAuditTrailViewModel");

    @observable expanded: string | boolean = false;

    @observable rows: number = 50;
    @observable totalRecords: number = 0;
    @observable first: number = 0;
    @observable name: string = ''
    @observable isLoading: boolean = true;
    @observable id: string = ''
    @observable startIndex: number = 0;
    @observable startSearchIndex: number = 0;
    @observable serachInputValue: string = '';
    @observable userAuditList = new Collection<AuditTrailModel>();
    private onClose?: () => void;
    @observable actionLogs = new Collection<any>();
    @observable sortColumn: string = 'createdDate'
    @observable sortOrder: boolean = false;
    @observable userList = new Collection<{
        label: string;
        value: string;
      }>();
    @observable actionList = new Collection<{
        label: string;
        value: string;
      }>();
    @observable selectedAction: string = '0';
    @observable selectedUser: string = '';
    @observable selectedUserId: string = '';
    @observable response: any;
    @observable isMessgeVisible: boolean = false
    @observable searchValue: string = ""
    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }

    @action goToAdmin = () => {
        routes.administration.push()
    }

    @action mapListItemAndSort(listObjects: any, actionItem: string) {
        if (listObjects) {
         listObjects.splice(0, 0, { text: "All "+actionItem, value: "0" , selected: false});
          var sortList = listObjects.sort(Utils.compareListItem);
          sortList.forEach((element: any) => {
            element.label = element.text;
          });          
          return sortList;
        }
        else {
          return [];
        }
      }

    @action loadUsers = async (value: string) => {
        if(value && value.length >= 3){
            var result = await IdentityStore.getAuditTrailUserLookup(value);
            var values = null
            if (result !== null) {
                values = result   
            }
            if (values !== undefined && values !== null) {
                this.userList.values = this.mapListItemAndSort(values, 'Users');;
            } else {
                this.userList.values = [];
            }
        }
        this.isLoading = false
    }

    @action loadActions = async () => {
        var result = await IdentityStore.getActionList();
        if (result) {
            this.actionList.values = [];
            this.actionList.values = this.mapListItemAndSort(result, "Actions");
            this.selectedAction = this.actionList.values[0].value
        }
        this.isLoading = false
    }

    @action LoadUserAuditTrail = async() => {
       this.noRecordsFound();
        let result = await IdentityStore.auditTrail(this.selectedUserId, this.searchValue, this.startIndex, this.rows, this.sortColumn , this.sortOrder);
        if (result.data && result.data.length > 0) {
            this.totalRecords = result.recordCount || 0;
            if (result.data) this.userAuditList.values = result.data;
        }
        this.isLoading = false
    }

    @action resetPagination = () => {
        this.first = 0
        this.startIndex = 0
        this.rows = 50;
    }

    @action reset = () => {
        this.rows = 50;
        this.noRecordsFound();
        this.selectedUser = ''
        this.selectedUserId = ''
        this.searchValue = ""
        this.resetPagination();
        PageContext.setIsMessageVisible(false);  
    }

    @action noRecordsFound() {
        this.totalRecords = 0;
        this.userAuditList.values = [];
    }

    @action onPage(firstIndex: number, rows: number) {
        this.first = firstIndex;
        this.startIndex = firstIndex;
        this.rows = rows;
        this.startIndex = firstIndex / this.rows;
        this.LoadUserAuditTrail()
    }

    @action downloadactionlogfile = async(fileName: string, fileGuid: string) => {
        var result = await DocumentStore.downloadactionlogfile(fileGuid)
        if (result.message && result.message !== null) {
            var responseModel = {
                status: 400,
                title: result.message,
                errors: { "": [] },
            };
            PageContext.setResponseMessage(JSON.stringify(responseModel));
            PageContext.setIsMessageVisible(true); 
        } else if(result.pdfData &&  result.pdfData !== null){
            this.downloadDocument(result.pdfData, fileName);
        }
    }

    @action downloadDocument = (base64: string, fileName: string) => {
        setTimeout(() => {
            var link = document.createElement('a');
            link.download = fileName+'.csv';
            link.href = "data:application/csv;base64," + base64;
            link.textContent = fileName+' CSV';
            link.click();
            document.body.appendChild(link);
        }, 0);
    }

}