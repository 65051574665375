import React from "react";
import ReactTooltip from "react-tooltip";

interface Props {
  parent: undefined | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
  id: string;
  className: string;
  childrenClassName: string;
}

const IMASCustomTooltip = ({
  children,
  id,
  className,
  parent,
  childrenClassName,
}: Props) => {
  return (
    <>
      {parent ? (
        parent
      ) : (
        <span data-tip data-for={id} className={"info-circle " + className} />
      )}
      <ReactTooltip
        className={
          childrenClassName
            ? "imas-tooltip " + childrenClassName
            : "imas-tooltip"
        }
        effect="solid"
        id={id}
        // TODO: import colors as constants
        textColor="#FFFFFF"
        backgroundColor="#434A51"
        arrowColor="#434A51"
        place="bottom"
      >
        {children}
      </ReactTooltip>
    </>
  );
};

export default IMASCustomTooltip;
