import { AbstractValidator } from "fluent-ts-validator";
import { observable, action } from "mobx";
import { basename } from "path";
import TreeNode from "primereact/components/treenode/TreeNode";
import Collection from "../../infrastructure/CollectionHelper";
import { AutoDialerActionTypes, AutoDialerConstants, ResidentStateTypeContants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import ViewModel from "../../infrastructure/ViewModel";
import AutoDialerStore from "../../store/AutoDialerStore";
import IdentityStore from "../../store/IdentityStore";
import { AutoDialerViewModel } from "./AutoDialerViewModel";

export class CreateCustomCallListViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented."); 
  }
  @action Load = async () => {
    //this.isException = false;
    // this.validAddressModel = undefined;
    this.resetFields();
   await this.getMarketerEvents();
  };
  Route = async (currentRoute: RouteList): Promise<void> => { }


  @observable callerViewModel: AutoDialerViewModel|undefined=undefined;
  @observable residentState: any;
   
   @observable isInvalidAgentIds: boolean = false;
  @observable invalidAgentIDsMsg:string='';
  
  @observable agentIds: string = '';
  @observable residentCounty: { [key: string]: { checked?: boolean, partialChecked?: boolean }; } = {};
  @observable leadSource: any;
  @observable addZipCode: string = '';
  @observable addZipCodeDistance: number = 100;
  @observable addZipCodeKeepZipCodes: boolean = false;
  @observable residentStateList = new Collection<any>();
 
  @observable leadSourceList = new Collection<any>();
   
  @observable createdFor: number = 0;
   
  @observable createdForList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable marketingEvent: string= "0";
  @observable marketingEventList = new Collection<any>();
  @observable callListName: string = '';

  @observable isLeadSourceLoaded: boolean = false;
  @observable isStatesLoaded: boolean = false;
  @observable isMarketerEventsLoaded: boolean = false;
  @observable selectedMarketingEvent: string = '0';
  @observable selectedCreatedFor: string = '0';
  @observable selectedUserId: string = '';
  @observable userList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable agentList:number[] | undefined = [];

  @observable selectedUser: string = '';
  @observable isLoading: boolean = true;
  @observable ErrorModel = new ErrorModel(new CreateCustomCallListValidator());
  @observable validationType: string = AutoDialerActionTypes.PriviewResult;
  @observable fileData: any;
  @observable fileName: string = "";
  @observable isManual: boolean = false;
  @observable isUpload: boolean = true;
  @action Validate = async () => {
    return await this.ErrorModel.Validate(this);
  }

  @action ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  }

  @action onSubmit = async () => {
    await this.ResetValidate();
    if (!(await this.Validate())) {

      var blob = null;
      if (this.fileData != null && this.fileData !== "" && this.fileData !== undefined) {
          // Split the base64 string in data and contentType
          var block = this.fileData ? this.fileData.split(";") : "";
          // Get the content type of the image
          var contentType = block[0].split(":")[1];// In this case "image/gif"
          // get the real base64 content of the file
          var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
          // Convert it to a blob to upload
          blob = Utils.b64toBlob(realData, contentType, 512);
      }

      var fileInfo = blob ? { data: blob, fileName: this.fileName } : null;
       
      if(this.isUpload)
      { 
        this.agentList  = [];
      }

      if(this.isManual)
      { 
        fileInfo=null;
      }
      try {         
         let result= await AutoDialerStore.createCustomCallList(this.callListName,
          this.selectedUserId== "" ? 0 :  +this.selectedUserId,
            
             this.marketingEvent== "" ? 0 :  +this.marketingEvent,
              this.agentList,
            false,
            false,
           fileInfo,
          this.isUpload
          );  
         if(result && result.id==0)
         {
            this.isInvalidAgentIds=true;
            this.invalidAgentIDsMsg=result.message ||'';
         }
         else
         {  this.isInvalidAgentIds=false;
          this.invalidAgentIDsMsg=result.message ||'';
         
          if(this.callerViewModel)   {  
            this.callerViewModel.selectedCallingType=AutoDialerConstants.Existing;
            this.callerViewModel.Load(false);
          }
        }
      } catch (e) {
        
      }
    } 
  };
  @action onDrop = (value: any, fileName: string) => {
    this.fileData = value;
    this.fileName = fileName;
}
  @action goBack = () => {     
    if(this.callerViewModel)   {  
    this.callerViewModel.selectedCallingType="0";
    }
  };
  @action loadUsers = async (value: string) => {
    if(value && value.length >= 3){
        var result = await IdentityStore.getAllUsers(value);
        var values = null
        if (result !== null) {
            values = result   
        }
        if (values !== undefined && values !== null) {
            this.userList.values = this.mapListItemAndSort(values, 'Users');;
        } else {
            this.userList.values = [];
        }
    }
    this.isLoading = false
}
@action mapListItemAndSort(listObjects: any, actionItem: string) {
  if (listObjects) {
   listObjects.splice(0, 0, { text: "All "+actionItem, value: "0" , selected: false});
    var sortList = listObjects.sort(Utils.compareListItem);
    sortList.forEach((element: any) => {
      element.label = element.text;
    });          
    return sortList;
  }
  else {
    return [];
  }
}
  @action getMarketerEvents = async () => {
    // this.isLoading = true;
    if (!this.isMarketerEventsLoaded) {
      try {
        var items = await AutoDialerStore.getMarketerEvents();
        this.marketingEventList.values = []
        Utils.mapListItemsToDropdown(items, this.marketingEventList, "None", "0");
  
        this.isMarketerEventsLoaded = true;
      } catch (e) {
      }
    }
    // this.isLoading = false;
  }

  @action resetFields() {
    this.residentState = 0;
     
    this.leadSource = 0;
    this.residentStateList.values = [];
    this.leadSourceList.values = [];
    this.isLeadSourceLoaded = false;
    this.isStatesLoaded = false;
    this.marketingEvent = "0";
    this.callListName = "";
    this.isInvalidAgentIds = false;
    this.invalidAgentIDsMsg='';
    this.agentIds="";
    this.agentList=[];
    this.fileName="";
    this.fileData=null;
    this.isUpload=true;
    this.isManual=false;
      this.ResetValidate();
  }

}

class CreateCustomCallListValidator extends AbstractValidator<CreateCustomCallListViewModel> {
  public constructor() {
    super();

    this.validateIfString(input => input.callListName)
      .isNotEmpty()
      .withFailureMessage("Call List Name required.");

      
        this.validateIfString(input => input.agentIds)
        .isNotEmpty()
        .withFailureMessage("Agent Ids required.")
        .when(input => input.isManual );     
   
    
        this.validateIf(input => input.fileData)      
        .isNotNull()
        .withFailureMessage("File is required")
        .when(input => input.isUpload );     
      
 
  }
}