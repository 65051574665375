import * as React from "react";
import ReactDOM from "react-dom";
import App from "./infrastructure/App";
import "./layout/layout.css";
import "./index.css";
import "./css/all.min.css";
import "./css/fontawesome-4.7.0.min.css";
import "primeflex/primeflex.css";
import "./css/fontawesome.min.css";
import "./App.css";
import "./Connect.css";
import DevTools from "mobx-react-devtools";
import Utils from "./infrastructure/Utils";
import { agGridLicense } from "./infrastructure/enum/Constants";
import { LicenseManager } from "ag-grid-enterprise";
import Auth0ProviderWithNavigate from "./infrastructure/Auth0ProviderWithNavigate";
import { googleTagManager } from "./infrastructure/tracking/googleTagManager";

LicenseManager.setLicenseKey(agGridLicense);

googleTagManager.init();

const queryParameters = new URLSearchParams(window.location.search);
const invite_code = queryParameters.get("invite_code") || "";
if (invite_code != "") {
  localStorage.setItem("invite_code", invite_code);
}

//For access deneied users redirect to logout�
const accessUser = queryParameters.get("error") || "";
const errorDescription = queryParameters.get("error_description") || "";

function accessDenied() {
  if (
    accessUser != null &&
    accessUser == "access_denied" &&
    errorDescription != "User is not valid user and not allowed to register"
  ) {
    sessionStorage.clear();
    localStorage.clear();
    var domain = Utils.getAuth0ClientIdByEnvironment("domain");
    var clientId = Utils.getAuth0ClientIdByEnvironment("clientid");
    return location.replace(
      `https://${domain}/v2/logout?returnTo=${window.location.origin}&client_id=${clientId}`
    );
  }
}
accessDenied();

function AppRun() {
  const rootElement = document.getElementById("root");
  localStorage.removeItem("auth0UserItem");
  localStorage.removeItem("auth0offline_access");
  ReactDOM.render(
    <Auth0ProviderWithNavigate>
      <>
        {process.env.NODE_ENV === "development" ? <DevTools /> : <></>}
        <App />
      </>
    </Auth0ProviderWithNavigate>,
    rootElement
  );
}
AppRun();
