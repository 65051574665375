import { BaseCoordinator } from "./BaseCoordinator";
import { routes, getCurrentRoute, listen } from "../../router";
import { Route } from "type-route";
import { AppViewModel } from "../../viewModels/AppViewModel";
import Bootstrapper from "../Bootstrapper";
/*import { authContext } from "../Auth0Authenticator";*/
import IMASLog from "../IMASLog";
export class AppCoordinator extends BaseCoordinator {
    private CurrentRoute: Route<typeof routes> = getCurrentRoute();
    private AppViewModel: AppViewModel = new AppViewModel();

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super();
    }

    private listenToken = listen((nextRoute) => this.TryRoute(nextRoute));

    start = async (): Promise<void> => {
        IMASLog.log("current route" + this.CurrentRoute.name);
        this.CurrentRoute = getCurrentRoute();
        if (this.CurrentRoute.name == false) {
            this.CurrentRoute.name = 'home';
        }
        await this.AppViewModel.Load();
        await this.AppViewModel.Route(this.CurrentRoute);
    };

    TryRoute = (nextRoute: Route<typeof routes>): boolean => {
        if (!this.AppViewModel.CanRoute) {
            return false;
        }
        this.CurrentRoute = nextRoute;
        if (nextRoute.name === routes.logout.name) {
            localStorage.removeItem("isCMNotesPreview");
            // authContext.logOut();
        }
        this.AppViewModel.Route(this.CurrentRoute);
        return true;
    };

    async stop(): Promise<void> {
        this.listenToken();
    }

    display() {
        return Bootstrapper.resolve(this.AppViewModel);
    }
}
