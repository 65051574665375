import React from "react";
import { observer } from "mobx-react";
import { ErrorViewModel } from "../viewModels/ErrorViewModel";

export const ErrorView : React.FC<{vm: ErrorViewModel}> = observer(props => {
    return (
        <div>
            {props.vm.errorMessage}
        </div>
    );
});