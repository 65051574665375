import React from "react";
import { observer } from "mobx-react";

import { Dropdown } from "primereact/dropdown";
import { Repeater } from "../Repeater";
import classNames from "classnames";

export const CustomDropdown: React.FC<{
  errors: string[] | undefined;
  label: string;
  value?: string;
  id: string;
  disabled: boolean;
  placeHolder: string;
  isrequired: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  options: Array<{ label: string; value: string; isHeader?: boolean }>;
  filter?: boolean;
  showClear?: boolean;
  filterBy?: string;
  dataKey?: string;
  className?: string;
  style?: boolean;
  autoFocus?: boolean;
  ref?: any;
  filterMatchMode?: string;
  appendTo?: any;
  itemTemplate?: any;
  onFocus?(e: Event): void;
  isGroupedList?: boolean;
  filterPlaceholder?: string;
}> = observer((props) => {
  var createRef = props.ref ? props.ref : React.createRef<any>();

  setTimeout(() => {
    if (
      createRef.current &&
      createRef.current.panel &&
      createRef.current.panel.element
    ) {
      createRef.current.panel.element.style.visibility = "hidden";
    }
  });
  const getClassNameError = () => {
    if (props.errors)
      return classNames({
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    else return props.className ? classNames(props.className) : classNames("");
  };
  const onClick = (e: any) => {
    var dropdownDiv = document.getElementById(props.id) as HTMLElement;
    if (dropdownDiv) {
      var filterdropdown = dropdownDiv.querySelector(
        ".p-dropdown-filter"
      ) as HTMLInputElement;
      if (filterdropdown) {
        createRef.current.resetFilter();
      }
    }
  };
  const onKeyDown = (e: any) => {
    var multiSelectDiv = document.getElementById(props.id);
    if (e.altKey && e.keyCode === 40) {
      // alt + Arrow Down
      // open the dropdown
      if (multiSelectDiv !== null) {
        multiSelectDiv.className =
          "p-dropdown p-component p-inputwrapper-filled p-inputwrapper-focus";
        var targetDiv = Array.from(
          multiSelectDiv.getElementsByClassName(
            "p-dropdown-panel"
          ) as HTMLCollectionOf<HTMLElement>
        );
        if (targetDiv !== null) {
          targetDiv[0].className =
            "p-dropdown-panel p-hidden p-input-overlay p-input-overlay-visible";
          targetDiv[0].style.display = "block";
          targetDiv[0].style.zIndex = "1009";
        }
      }
    }
    if (e.keyCode === 27) {
      // Esc
      // close the dropdown
      if (multiSelectDiv !== null) {
        multiSelectDiv.className =
          "p-dropdown p-component p-inputwrapper-filled p-inputwrapper-focus p-focus";
        var targetDiv1 = Array.from(
          multiSelectDiv.getElementsByClassName(
            "p-dropdown-panel"
          ) as HTMLCollectionOf<HTMLElement>
        );
        if (targetDiv1 !== null) {
          targetDiv1[0].className = "p-dropdown-panel p-hidden p-input-overlay";
          targetDiv1[0].style.display = "none";
          targetDiv1[0].style.zIndex = "1002";
        }
      }
    }
  };
  const onChange = (e: any) => {
    if (props.isGroupedList) {
      let selectedoption = props.options.filter((i: any) => {
        return i.value == e.value;
      });
      if (
        selectedoption &&
        selectedoption.length > 0 &&
        selectedoption[0].isHeader
      ) {
        return false;
      } else {
        props.onChange(e.value);
      }
    } else {
      props.onChange(e.value);
    }
  };

  if (props.isGroupedList) {
    setTimeout(() => {
      let container = document.getElementById(props.id);
      if (container) {
        container
          .querySelectorAll(".list-item-header")
          .forEach((element: any) => {
            let liItem = element.closest("li");
            if (liItem) {
              liItem.classList.add("list-header-disabled");
            }
          });
      }
    }, 100);
  }

  const getDropdown = () => {
    return (
      <>
        <label htmlFor={props.id}>
          {props.label}{" "}
          {props.isrequired && <span className="required">*</span>}{" "}
        </label>
        <Dropdown
          placeholder={props.placeHolder}
          value={props.value}
          id={props.id}
          dataKey={"_" + Math.random().toString(36).substr(2, 9)}
          className={getClassNameError()}
          disabled={props.disabled}
          onChange={(event) => onChange(event)}
          onBlur={(event) => props.onBlur()}
          options={props.options}
          filter={props.filter}
          filterBy={props.filterBy}
          showClear={props.showClear}
          autoFocus={props.autoFocus}
          appendTo={props.appendTo}
          style={props.style ? { minWidth: 150 } : undefined}
          ref={createRef}
          onFocus={props.onFocus}
          filterMatchMode={
            props.filterMatchMode ? props.filterMatchMode : "contains"
          }
          itemTemplate={props.itemTemplate}
          filterPlaceholder={props.filterPlaceholder}
        />
        <Repeater
          errors={props.errors}
          viewId={props.id}
          child={(error) => <div className="invalid-feedback">{error}</div>}
        />
      </>
    );
  };
  return (
    <>
      {["searchTypeTopHeaderDropdown", "searchTypemapDropdown"].includes(
        props.id
      ) ? (
        <>{getDropdown()}</>
      ) : (
        <>
          <div id={"div" + props.id} onKeyDown={onKeyDown} onClick={onClick}>
            {getDropdown()}
          </div>
        </>
      )}
    </>
  );
});
