import { observable, action, computed, toJS } from "mobx";
import ProducerStore from "../../store/ProducerStore";

import AccountStore from "../../store/AccountingStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { AgentDetails } from "../../infrastructure/enum/AgentDetails";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import IMASLog from "../../infrastructure/IMASLog";
import { AddEditCreditCheckViewModel } from "../components/AddEditCreditCheckViewModel";
import {
  AddEditAddressViewModel,
  AddressListener,
} from "../components/AddEditAddressViewModel";
import {
  AgentSummaryModel,
  AddressListModel,
  UpdateAgentModel,
  ContactModel,
  PayeeListModel,
  AgencyListModel,
  CreateUpdateContactModel,
  PrincipalResponseModel,
  CreatePrincipalModel,
  CreateUpdateAddressModel,
  BankAccountDetailModel,
  UploadProfilePicModel,
  PrincipalModelList,
  UpdateAgentW9InformationRequest,
} from "../../services/ProducerService";
import BusinessUnitConfigStore from "../../store/BusinessUnitConfigStore";
import { routes } from "../../router";

import Utils from "../../infrastructure/Utils";
import {
  CreditCheckListModel,
  CreateUpdateCreditCheckModel,
  BankModelReponse,
} from "../../services/AccountingService";
import ProfilePicData from "../../infrastructure/ProfilePicData";
import { Permission } from "../../infrastructure/enum/Permission";
import { Constants } from "../../infrastructure/enum/Constants";
import { AgentListModel } from "../../services/ProducerSearchService";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import PageContext from "../../infrastructure/PageContext";
import IdentityStore from "../../store/IdentityStore";
import UserContext from "../../infrastructure/UserContext";
import { Util } from "@microsoft/applicationinsights-web";
import {
  ContactType,
  PhoneType,
  EmailType,
  AlternativePointOfContactType,
  PhoneTypeValue,
  EmailTypeValue,
  AlternativePointOfContactTypeValue,
} from "../../infrastructure/enum/Common";
import { ManageSubscriptionViewModel } from "./contacts/ManageSubscriptionViewModel";
import ContractsStore from "../../store/ContractsStore";
import DocumentStore from "../../store/DocumentStore";
import { AxiosResponse } from "axios";

// Custom Attributes
import CustomAttributeStore from "../../store/CustomAttributeStore";
import { CustomAttributesComponentViewModel } from "../../views/customers/CustomAttributesComponentViewModel";
import { CustAttrToEntityModel } from "../../services/ContractService";

export class EditAgentViewModel implements ViewModel, AddressListener {
  async updateAddress() {
    await this.addEditAddress();
  }
  @observable profilePic: string = "";
  @observable profilePicDelete: boolean = false;
  @observable ManageSubscriptionViewModel: ManageSubscriptionViewModel =
    new ManageSubscriptionViewModel();
  @observable manageSubscriptionDialog: boolean = false;
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable sortColumn: string | undefined = "Id";
  @observable sortOrder: boolean = false;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 100;
  @observable enableSMS: boolean = false;
  @observable isCancelConfirm: boolean = false;
  @observable agentSummary: AgentSummaryModel | null = null;
  @observable FilteredAgentsList = new Collection<PrincipalModelList>();
  @observable FilteredPayeeList = new Collection<AgentListModel>();
  @observable addressPageNo: number = 0;
  @observable addressTotalRecords: number = 0;
  @observable principalAgentsPageNo: number = 0;
  @observable principalAgentsTotalRecords: number = 0;
  @observable crditCheckPageNo: number = 0;
  @observable creditChecksTotalRecoreds: number = 0;
  @observable contactOptionsPageNO: number = 0;
  @observable contactOptionsTotalRecords: number = 0;
  @observable creditChecksPageNo: number = 0;
  @observable creditChecksTotalRecords: number = 0;
  @observable agenciesPageNo: number = 0;
  @observable agenciesTotalRecords: number = 0;
  @observable payeesPageNo: number = 0;
  @observable payeesTotalRecords: number = 0;
  @observable createdBy: number = 341;
  @observable contactValue: string = "";
  @observable contactValueW9: any;
  @observable w9einTemp: any = "";
  @observable w9ssnTemp: any = "";
  @observable isNotValidContact: boolean = false;
  @observable contactDescription: string = "";
  @observable rows: number = 25;
  @observable AddressList = new Collection<AddressListModel>();
  @observable ContactsData = new Collection<ContactModel>();
  @observable showPhoneManageSubscriptions: boolean = false;
  @observable showEmailManageSubscriptions: boolean = false;
  @observable PayeesData = new Collection<PayeeListModel>();
  @observable AgenciesData = new Collection<AgencyListModel>();
  @observable PrincipalAgentsData = new Collection<PrincipalResponseModel>();
  @observable CreditChecksData = new Collection<CreditCheckListModel>();
  @observable ffmId: string | undefined = "";
  @observable isExcludeFromTrip: boolean | undefined = false;
  @observable isLocation: boolean | undefined = false;
  @observable onlyPoBox: boolean | undefined = false;
  @observable sureLcAgent: boolean | undefined = false;
  @observable isHiddenAgentProfile: boolean | undefined = false;
  @observable isIntegrityPlatformPartner: boolean | undefined = false;
  @observable isEAndOPariticipant: boolean | undefined = false;
  @observable vested: boolean | undefined = false;
  @observable vestedPercentage: string | undefined = "100";

  @observable isMarketer: boolean | undefined = false;
  @observable existingIsMarketer: boolean | undefined = false;

  @observable isException: boolean = false;
  @observable isDuplicate: boolean = false;
  @observable duplicateError: string = "";
  @observable isBankValid: boolean = false;
  @observable isEOExpirationDateValid: boolean = false;
  @observable validBankError: string = "";
  @observable isSuccess: boolean = false;
  @observable isAddAddressSuccess: boolean = false;
  @observable isAddContactSuccess: boolean = false;
  @observable isAddCreditCheckSuccess: boolean = false;
  @observable exceptionMessage: any;
  @observable maxDate: Date = new Date();
  @observable federalTaxClassifications: any;

  // Custom Attribtues
  @observable isCustomAttrActive: boolean = false;
  @observable isValid = true;
  //custom attribute states
  @observable addAttributesModal: boolean = false;
  @observable
  CustomAttributesComponentViewModel: CustomAttributesComponentViewModel =
    new CustomAttributesComponentViewModel();
  @observable attributesScreenPath: number = 6;
  @observable attributesScreenPathId: number = 6;
  @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();
  @observable customAttributeList: any = [];

  @observable isBankMandatory: boolean = false;
  @observable isAccountNumMandatory: boolean = false;
  @observable isAccountTypeMandatory: boolean = false;

  @action Load = async (agentId: any) => {
    PageContext.isCustomAttribute = false;
    var d: Date = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var startDate = new Date(year - 2, month, day);
    var endDate = new Date(year + 5, month, day);
    this.yearRange = startDate.getFullYear() + ":" + endDate.getFullYear();
    this.isCancelConfirm = false;
    this.isLoading = true;
    this.isSuccess = false;
    this.isException = false;
    PageContext.setIsOverlay(true);
    this.setIsBankValid(false);
    this.setIsEOExpirationDateValid(false);
    this.setIsDuplicate(false);
    PageContext.setIsFromEdit(true);
    this.AddEditAddressViewModel.setAddressListener(this);
    this.selectedTabIndex = 0;
    this.selectedKey = AgentDetails.Summary;
    this.maxDate = this.getmaxDate(new Date(), 18);
    this.isAgencyFromApi =
      localStorage.getItem("isAgency") === "true" ? true : false;
    if (agentId) {
      this.isEdit = true;
      this.title = "";
      this.agentId = +agentId;

      this.AddEditAddressViewModel.setAgentId(this.agentId);
    } else {
      this.isEdit = false;
      this.agentId = 0;
      this.title = "Add Agent";
    }
    this.selectedKey = AgentDetails.Summary;
    this.profilePic = "";
    this.vested = false;
    ProfilePicData.data = "";

    this.duplicateAgentId = 0;
    await this.ResetValidate();
    await this.ResetValidateAgency();
    this.setIslastNameValid(false);
    this.setIsfirstNameValid(false);
    this.setIsDOBValid(false);
    this.setIsDbaValid(false);
    this.setIsmiddleNameValid(false);
    this.setIsnickNameValid(false);
    this.setIsagencyNameValid(false);
    this.setIsSsnValid(false);
    this.setIsNpnValid(false);
    await this.getUserPermission();
    await this.loadProfilePic();
    await this.loadAgentDetails();
    this.profilePicDelete = false;
    this.isDuplicate = false;
    this.checkBankDetails(this.paymentMethod);
    PageContext.setIsOverlay(false);
    this.isLoading = false;
    this.setEmailType("Others");
    this.isDuplicate = false;

    if (
      Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
      Utils.hasUserPermission(Permission.PublicCustomAttribute)
    ) {
      await this.checkPermissions();
    }

    this.isLoadAddressCall = true;
  };

  @action showManageSubscription = (value: boolean) => {
    this.manageSubscriptionDialog = value;
    this.ManageSubscriptionViewModel.Load(
      this.agentId,
      this.showPhoneManageSubscriptions,
      this.showEmailManageSubscriptions
    );
  };

  @action hideManageSubscription = () => {
    this.manageSubscriptionDialog = false;
    this.getContacts(0);
  };

  @action getUserPermission = async () => {
    await IdentityStore.getUserPermissions("" + UserContext.getUserId()).then(
      (data: any) => {
        if (data) {
          UserContext.permissions = data.permissions;
          UserContext.roles = data.roles;
        }
      }
    );
  };
  @action contractsSort = async (column: string) => {
    this.sortColumn = column;
    this.sortOrder = !this.sortOrder;
    this.setAddContactDialogVisibility(false, false);
    await this.getContacts(0);
    this.isLoading = false;
  };

  @action checkBankDetails = (value: string) => {
    if (value == "2") {
      this.isBankMandatory = true;
      this.isAccountNumMandatory = true;
      this.isAccountTypeMandatory = true;
    } else {
      this.isBankMandatory = false;
      this.isAccountNumMandatory = false;
      this.isAccountTypeMandatory = false;
    }
  };

  @action getCategoryType = (contact: ContactModel) => {
    return "" + contact.contactType === "Email"
      ? contact.emailType
      : contact.contactType === "Phone Number" && contact.phoneType
        ? contact.phoneType.replace("Others", "Other")
        : contact.alternativePointOfContactType;
  };

  private loadSuffixList(result: any) {
    if (result.suffixes) {
      this.suffixList.values = [];
      this.suffixList.values.push({ label: "Select", value: "0" });
      //var sortLeadSourceList = [...result.leadSources].sort(Utils.compareListItem);
      for (let item of result.suffixes) {
        this.suffixList.values.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.suffix = result.suffix ? "" + result.suffix : "0";
    }
  }
  @action loadProfilePic = async () => {
    try {
      if (!this.agentId) return;
      setTimeout(() => {
        PageContext.setIsLoadingVisible(false);
      }, 3000);
      var result = await ProducerStore.getAgentProfilePic(this.agentId);
      if (result && result.imageData) {
        this.profilePic = "data:image/jpeg;base64," + result.imageData;
      } else {
        this.profilePic = "assets/layout/images/profile-default.png";
      }
    } catch (e) {
      this.profilePic = "assets/layout/images/profile-default.png";
      this.isException = true;
      this.exceptionMessage = e.response;
      IMASLog.log("exception - download profile");
    }
  };

  @action loadAgentDetails = async () => {
    try {
      this.resetAll();
      this.isLoading = true;
      this.isException = false;
      await this.ResetValidate();
      await this.ResetValidateAgency();
      var result = await ProducerStore.getAgentDetails(this.agentId);
      if (result) {
        if (!result.isUserCanSeeThisAgent) {
          routes.agentSearch.replace();
          return;
        }

        // this.w9Name = result.taxpayerName;
        // this.w9BusinessName = result.taxpayerBusinessName;
        // this.federalTaxType = result.federalTaxClassificationId;
        // this.w9addressLine = result.address &&result.address .addressLine1;
        // this.w9county = result.address && result.address.addressLine2;
        // this.w9state = result.address && result.address.stateAbbreviation;
        // this.w9city = result.address && result.address.cityName;
        // this.w9zip = result.address && result.address.zipCode;
        // this.documentList.values = result.documentAttachmentList || [];
        // this.contactValueW9 = result.ein || result.ssn ;
        // this.setW9TableRadio(result.w9AddressID || 0);
        // if(result.federalTaxClassifications){
        //   this.federalTaxClassifications = result.federalTaxClassifications;
        //   this.federalTaxType = result.federalTaxClassificationId || undefined;
        // }

        this.isAgency = result.isAgency ? result.isAgency : false;
        this.existingIsAgency = result.isAgency ? result.isAgency : false;

        this.isAgencyFromApi = result.isAgency ? result.isAgency : false;
        this.firstName = result.firstName ? result.firstName : "";
        this.agencyName = result.lastName ? result.lastName : "";
        this.oldagencyName = result.lastName ? result.lastName : "";
        this.lastName = result.lastName ? result.lastName : "";
        this.middleName = result.middle ? result.middle : "";
        this.suffix = result.suffix ? result.suffix : "0";
        this.alternativeLastName = result.alternativeLastName
          ? result.alternativeLastName
          : "";
        var date = Utils.getDateInFormat(result.dob ? result.dob : undefined);
        if (date) {
          this.setDOB(new Date(date));
        }

        if (this.isEdit) {
          this.title = this.isAgency
            ? this.lastName + " (" + this.agentId + ")"
            : this.firstName +
            " " +
            this.middleName +
            " " +
            this.lastName +
            (this.suffix && this.suffix !== "0" ? ", " + this.suffix : "") +
            " (" +
            this.agentId +
            ")";
        }
        this.oldfirstname = result.firstName;
        this.oldlastname = result.lastName;
        this.dba = result.dba ? result.dba : "";
        this.nickName = result.nickName ? result.nickName : "";
        this.website = result.webSite ? result.webSite : "";
        this.npn = result.npn ? result.npn : "";
        this.oldnpn = result.npn ? result.npn : "";
        this.ffmId = result.federallyFacilitatedMarketplaceUserId
          ? result.federallyFacilitatedMarketplaceUserId
          : "";
        this.w9 = result.w9 || undefined;
        this.isMarketer = result.marketer;
        this.existingIsMarketer = result.marketer;

        this.ssn = result.ssn ? result.ssn : "";
        this.oldssn = result.ssn ? result.ssn : "";
        this.validateSSN = result.ssn ? result.ssn : "";
        this.tin = result.tin ? result.tin : "";
        this.oldtin = result.tin ? result.tin : "";
        this.setRenewalOption(
          result.terminatedOption ? "" + result.terminatedOption : "0"
        );
        this.gender = result.gender ? result.gender : "";
        this.vested = result.vested ? true : false;
        this.vestedPercentage = (result.vestedPercentage !== null && result.vestedPercentage !== undefined) ? '' + result.vestedPercentage : "100";
        this.loadRatings(result);
        this.loadStatusses(result);
        this.loaduplines(result);
        this.loadSmrs(result);
        this.loadLhrs(result);
        this.loadSuffixList(result);
        this.loadDebtLevels(result);
        this.loadBanks(result);
        this.loadLeadSourceList(result);
        this.loadBankAccountPaymentMethods(result);
        await this.loadBankAccountTypes(result);
        this.loadBankAccountDetails(result);
        await this.loadAgentGroupNameList(result);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception.." + error);
    }
  };
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      listObjects.splice(0, 0, { text: "Select", value: "0", selected: false });
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });

      return sortList;
    } else {
      return [];
    }
  }

  @action loadAddresses = async (startIndex: number) => {
    try {
      this.isLoading = true;
      this.noRecordsFound();
      if (this.agentId) {
        let result = await ProducerStore.getAddresses(+this.agentId);
        if (result && result !== null) {
          if (result.recordCount) {
            this.addressTotalRecords = result.recordCount;
            if (result.data) this.AddressList.values = result.data;
          } else {
            this.noRecordsFound();
          }
        } else {
          this.noRecordsFound();
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  private loadBankAccountDetails(result: UpdateAgentModel) {
    if (result.bankAccountModel) {
      var accTypeId = result.bankAccountModel.bankAccountTypeId;
      this.bankAccountType = accTypeId ? accTypeId.toString() : "0";
      var id = result.bankAccountModel.bankAccountPaymentMethodId;
      this.paymentMethod = id ? "" + id : "";
      var accNo = result.bankAccountModel.accountNumber;
      this.accountNumber = accNo ? "" + accNo : "";
      var bankName = result.bankAccountModel.bankName;
      var bankId = result.bankAccountModel.bankId;
      var bankAccountId = result.bankAccountModel.bankAccountId;
      this.bank = bankName ? bankName : "";
      this.bankId = bankId ? bankId : 0;
      this.bankAccountId = bankAccountId ? bankAccountId : 0;
    }
  }

  @action loadBankAccountTypes = async (result: UpdateAgentModel) => {
    if (result.bankAccountTypes) {
      this.bankAccountTypeList = [];
      this.bankAccountTypeList.push({ label: "None", value: "0" });
      var sortBankAccTypeList = [...result.bankAccountTypes].sort(
        Utils.compareListItem
      );
      for (let item of sortBankAccTypeList) {
        this.bankAccountTypeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }
  };

  private loadBankAccountPaymentMethods(result: UpdateAgentModel) {
    if (result.bankAccountPaymentMethods) {
      this.paymentMethodList = [];
      var sortPaymentMethodList = [...result.bankAccountPaymentMethods].sort(
        Utils.compareListItem
      );
      for (let item of sortPaymentMethodList) {
        this.paymentMethodList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }
  }

  private loadLeadSourceList(result: UpdateAgentModel) {
    if (result.leadSources) {
      this.leadSourceList = [];
      this.leadSourceList.push({ label: "None", value: "0" });
      var sortLeadSourceList = [...result.leadSources].sort(
        Utils.compareListItem
      );
      for (let item of sortLeadSourceList) {
        this.leadSourceList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.leadSource = result.leadSource ? "" + result.leadSource : "0";
    }
  }

  @action loadAgentGroupNameList = async (result: UpdateAgentModel) => {
    var items = await BusinessUnitConfigStore.getAllAgentGroupNameLookup();
    Utils.mapListItemsToDropdown(items, this.groups, "");
    this.selectedGroups =
      result.agentGroup && result.agentGroup.length > 0
        ? result.agentGroup.map(String)
        : [];
  };

  private loadStatusses(result: UpdateAgentModel) {
    if (result.statuses) {
      this.statusList = [];
      var sortStatusList = [...result.statuses].sort(Utils.compareListItem);
      for (let item of sortStatusList) {
        this.statusList.push({ label: "" + item.text, value: "" + item.value });
      }
      this.status = result.status ? "" + result.status : "6";
    }
  }

  private loaduplines(result: UpdateAgentModel) {
    if (result.uplines) {
      this.uplineList = [];
      this.uplineList.push({ label: "Select Upline", value: "0" });
      var sortUplineList = [...result.uplines].sort(Utils.compareListItem);
      for (let item of sortUplineList) {
        this.uplineList.push({ label: "" + item.text, value: "" + item.value });
      }
      this.selectedUpline = result.uplineId ? "" + result.uplineId : "0";

    }
  }
  private loadSmrs(result: UpdateAgentModel) {
    if (result.smrs) {
      this.smrList = [];
      var sortSmrList = [...result.smrs].sort(Utils.compareListItem);
      for (let item of sortSmrList) {
        this.smrList.push({ label: "" + item.text, value: "" + item.value });
      }
      this.smr = result.smr ? "" + result.smr : "1";
    }
  }

  private loadLhrs(result: UpdateAgentModel) {
    if (result.lhrs) {
      this.lhrList = [];
      var sortLhrList = [...result.lhrs].sort(Utils.compareListItem);
      for (let item of sortLhrList) {
        this.lhrList.push({ label: "" + item.text, value: "" + item.value });
      }
      this.lhr = result.lhr ? "" + result.lhr : "1";
    }
  }
  private loadRatings(result: UpdateAgentModel) {
    this.isHiddenAgentProfile = result.isHiddenAgentProfile;
    this.isExcludeFromTrip = result.excludeFromTrip;
    this.isIntegrityPlatformPartner = result.isIntegrityPlatformPartner;
    this.isEAndOPariticipant = result.eoParticipant;
    var eoDate = Utils.getDateInFormat(
      result.eoParticipantExpirationDate
        ? result.eoParticipantExpirationDate
        : undefined
    );
    if (eoDate) {
      this.setEOParticipationExpDate(new Date(eoDate));
    }

    this.onlyPoBox = result.onlyPobox;
    this.sureLcAgent = result.sureLC;
    this.isLocation = result.isLocation;
    this.isMarketer = result.marketer;
    this.renewalOption = result.terminatedOption
      ? "" + result.terminatedOption
      : "0";
    this.selectedHealthRating = result.healthRating ? result.healthRating : "";
    this.isHealthRatingLock = result.healthRatingLocked
      ? result.healthRatingLocked
      : false;
    this.ltcRating = result.ltcrating ? result.ltcrating : "";
    this.lifeRating = result.lifeRating ? result.lifeRating : "";
    this.annuityRating = result.annuityRating ? result.annuityRating : "";
    this.feRating = result.finalExpenseRating ? result.finalExpenseRating : "";
  }
  private loadDebtLevels(result: UpdateAgentModel) {
    if (result.debtLevels) {
      this.delbtLevelList = [];
      var sortDebtLevelList = [...result.debtLevels].sort(
        Utils.compareListItem
      );
      for (let item of sortDebtLevelList) {
        this.delbtLevelList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.debtLevel = result.debtLevelId ? "" + result.debtLevelId : "0";
    }
  }
  private loadBanks(result: UpdateAgentModel) { }
  @action noRecordsFound() {
    this.addressTotalRecords = 0;
    this.AddressList.values = [];
  }

  @action getContactType = (contact: ContactModel) => {
    return "" + contact.contactType;
  };
  @action getContactValue = (contact: ContactModel) => {
    if (contact.contactType === "Phone Number") {
      //3
      return this.getPhoneNumberUSformat("" + contact.contactValue);
    } else return "" + contact.contactValue;
  };
  @action getPhoneNumberUSformat = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumber;
  };
  @action validateContactValue = async () => {
    this.isNotValidContact = false;
    this.contactValue = this.contactValue.trim();
    if (this.contactValue === "") {
      this.isNotValidContact = true;
    } else {
      this.isNotValidContact = false;
    }

    if (this.contactType === "1") {
      var result = this.contactValue.match(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      );
      if (result) {
        if (!Utils.hasUserPermission(Permission.OverrideEmailValidation)) {
          let emailValidationResponse: AxiosResponse =
            await ProducerStore.validateEmail(this.contactValue);
          if (
            emailValidationResponse &&
            emailValidationResponse.data &&
            emailValidationResponse.data.isValid === true
          ) {
            this.isNotValidContact = false;
          } else {
            this.isNotValidContact = true;
          }
        } else {
          this.isNotValidContact = false;
        }
      } else {
        this.isNotValidContact = true;
      }
    }
    if (this.contactType === "3") {
      var phoneResult = this.contactValue.match(
        /^(((\d{3})(\d{3})(\d{4}))|((\(\d{3}\))[\s]?\d{3}[\-]\d{4})|(\d{3}[\s]\d{3}[\s]\d{4}))$/
      );
      if (phoneResult) {
        this.isNotValidContact = false;
      } else {
        this.isNotValidContact = true;
      }
    }
  };

  @action getContactHref = (contact: ContactModel) => {
    if (contact.contactType === "Phone Nuber") {
      //3
      return "tel:" + contact.contactValue;
    } else if (contact.contactType === "Email") {
      return "mailto:" + contact.contactValue + "?Subject=Integrity%20Connect";
    } else return "" + contact.contactType;
  };
  // @computed
  // get title(): string {
  //     return (this.isEdit) ? 'Edit Agent' : 'Add Agent'
  // }
  @observable title: string = "";

  @computed
  get isPrincipalAgentAvailable(): boolean {
    if (this.principalAgent.trim().length > 0 && this.principalAgentId > 0)
      return true;
    return false;
  }
  @computed
  get isPayeeAvailable(): boolean {
    if (this.payee.trim().length > 0 && this.payeeAgentId > 0) return true;
    return false;
  }
  @observable
  public ErrorModel = new ErrorModel(new AddAgentValidator());
  @observable
  public ErrorModelContact = new ErrorModel(new ContactValidatory());
  @observable
  public ErrorModelAgency = new ErrorModel(new AddAgencyValidator());

  @observable public ErrorModelForAgentOrAgency = new ErrorModel(
    new AddAgentOrAgencyValidator()
  );

  Route = async (currentRoute: RouteList): Promise<void> => { };
  static Symbol: symbol = Symbol("AddNoteViewModel");
  @observable BankListSuggestions = new Collection<BankModelReponse>();
  @observable isLoading: boolean = true;
  @observable contactValueLabel: string = ContactType.Email;
  @observable response: any = "";
  @observable isEdit: boolean = false;
  @observable isEditAccess: boolean = false;
  @observable agentId: number = 0;
  @observable duplicateAgentId: number = 0;
  @observable selectContactId: number = 0;
  @observable selectContactTypeId: number = 0;
  @observable selectedCreditCheckId: number = 0;
  @observable selectedAddressId: number = 0;
  @observable selectedW9AddressId: any = {};
  @observable selectedW9TableRecordID: any = {};
  @observable selectedPrincipalAgentId: number = 0;
  @observable selectedPayee: number = 0;

  @observable isAgency: boolean = false;
  @observable existingIsAgency: boolean = false;

  @observable isAgencyFromApi: boolean = false;
  @observable bank: string = "";
  @observable bankId: number = 0;
  @observable bankAccountId: number = 0;
  @observable isDeleteContactConfirm: boolean = false;
  @observable isDeleteAddressConfirm: boolean = false;
  @observable isDeleteCreditCheckConfirm: boolean = false;
  @observable isDeletePrincipalAgentConfirm: boolean = false;
  @observable isDeletePayeeConfirm: boolean = false;
  @observable npn: string | undefined = "";
  @observable agencyName: string = "";
  @observable oldagencyName: string = "";
  @observable dba: string | undefined = "";
  @observable nickName: string | undefined = "";
  @observable website: string | undefined = "";
  @observable ssn: string | undefined = "";
  @observable oldssn: string | undefined = "";
  @observable oldfirstname: string | undefined = "";
  @observable oldlastname: string | undefined = "";
  @observable oldnpn: string | undefined = "";
  @observable validateSSN: string = "";
  @observable alternativeLastName: string | undefined = "";
  @observable tin: string = "";
  @observable oldtin: string = "";
  @observable agency: string | undefined = "";
  @observable selectedKey: string = AgentDetails.Summary;
  @observable dateOfBirth: Date | undefined = undefined;
  @observable eoParticipantExpirationDate: Date | undefined = undefined;
  @observable status: string = "6";
  @observable selectedUpline: string = "0";

  @observable lastName: string = "";
  @observable firstName: string | undefined = "";
  @observable middleName: string | undefined = "";
  @observable leadSource: string = "0";
  @observable selectedHealthRating = "";
  @observable feRating = "";
  @observable annuityRating = "";
  @observable smr: string = "1";
  @observable lhr: string = "1";
  @observable principalAgentTitle: string = "Add Principal Agent";
  @observable isAddPrincipalAgentVisible: boolean = false;
  @observable existingMarketerClassification: string = "0";

  @observable isfirstNameValid: boolean = false;
  @observable validfirstNameError: string = "";
  @action setIsfirstNameValid(value: boolean) {
    this.isfirstNameValid = value;
  }

  @action setValidfirstNameError(value: string) {
    this.validfirstNameError = value;
  }

  @observable islastNameValid: boolean = false;
  @observable validlastNameError: string = "";
  @action setIslastNameValid(value: boolean) {
    this.islastNameValid = value;
  }
  @observable groups = new Collection<{ label: string; value: string }>();
  @observable selectedGroups: any = [];
  @action setValidlastNameError(value: string) {
    this.validlastNameError = value;
  }

  @observable ismiddleNameValid: boolean = false;
  @observable validmiddleNameError: string = "";
  @observable valiEOExpirationDateError: string = "";
  @action setIsmiddleNameValid(value: boolean) {
    this.ismiddleNameValid = value;
  }

  @action setValidmiddleNameError(value: string) {
    this.validmiddleNameError = value;
  }

  @action setValidEOExpirationDateError(value: string) {
    this.valiEOExpirationDateError = value;
  }

  @observable isnickNameValid: boolean = false;
  @observable validnickNameError: string = "";
  @action setIsnickNameValid(value: boolean) {
    this.isnickNameValid = value;
  }

  @action setValidnickNameError(value: string) {
    this.validnickNameError = value;
  }

  @observable isagencyNameValid: boolean = false;
  @observable validagencyNameError: string = "";
  @action setIsagencyNameValid(value: boolean) {
    this.isagencyNameValid = value;
  }

  @action setValidagencyNameError(value: string) {
    this.validagencyNameError = value;
  }

  @observable isNpnValid: boolean = false;
  @observable validNpnError: string = "";
  @action setIsNpnValid(value: boolean) {
    this.isNpnValid = value;
  }

  @action setValidNpnError(value: string) {
    this.validNpnError = value;
  }

  @observable isFfmValid: boolean = false;
  @observable validFfmError: string = "";
  @action setIsFfmValid(value: boolean) {
    this.isFfmValid = value;
  }

  @action setValidFfmError(value: string) {
    this.validFfmError = value;
  }
  @observable w9: Date | undefined;
  @observable yearRange: string = "";
  @observable isSsnValid: boolean = false;
  @observable validSsnError: string = "";
  @action setIsSsnValid(value: boolean) {
    this.isSsnValid = value;
  }

  @action setValidSsnError(value: string) {
    this.validSsnError = value;
  }

  @observable isDbaValid: boolean = false;
  @observable validDbaError: string = "";
  @action setIsDbaValid(value: boolean) {
    this.isDbaValid = value;
  }

  @action setValidDbaError(value: string) {
    this.validDbaError = value;
  }

  @observable isDOBValid: boolean = false;
  @observable validDOBError: string = "";
  @action setIsDOBValid(value: boolean) {
    this.isDOBValid = value;
  }

  @action setValidDOBError(value: string) {
    this.validDOBError = value;
  }

  @computed
  get isEditNPN(): boolean {
    if (this.isEdit) {
      return !Utils.hasUserPermission(Permission.EditAgentNPN);
    } else return false;
  }
  @computed
  get isEditSSN(): boolean {
    if (this.isEdit) {
      return !Utils.hasUserPermission(Permission.EditAgentSSN);
    } else return false;
  }
  @computed
  get isEditTIN(): boolean {
    if (this.isEdit) {
      return !Utils.hasUserPermission(Permission.EditAgentTIN);
    } else return false;
  }
  @computed
  get isEditDBA(): boolean {
    if (this.isEdit) {
      return !Utils.hasUserPermission(Permission.EditAgentDBA);
    } else return false;
  }
  @computed
  get isViewDBA(): boolean {
    if (this.isEdit) {
      if (
        Utils.hasUserPermission(Permission.ViewAgentDBA) &&
        !Utils.hasUserPermission(Permission.EditAgentDBA)
      ) {
        return true;
      } else if (
        !Utils.hasUserPermission(Permission.ViewAgentDBA) &&
        Utils.hasUserPermission(Permission.EditAgentDBA)
      ) {
        return true;
      } else if (
        Utils.hasUserPermission(Permission.ViewAgentDBA) &&
        Utils.hasUserPermission(Permission.EditAgentDBA)
      ) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }
  @computed
  get isViewSSN(): boolean {
    if (this.isEdit) {
      if (
        Utils.hasUserPermission(Permission.ViewAgentSSN) &&
        !Utils.hasUserPermission(Permission.EditAgentSSN)
      ) {
        return true;
      } else if (
        !Utils.hasUserPermission(Permission.ViewAgentSSN) &&
        Utils.hasUserPermission(Permission.EditAgentSSN)
      ) {
        return true;
      } else if (
        Utils.hasUserPermission(Permission.ViewAgentSSN) &&
        Utils.hasUserPermission(Permission.EditAgentSSN)
      ) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }

  @computed
  get isViewTIN(): boolean {
    if (this.isEdit) {
      if (
        Utils.hasUserPermission(Permission.ViewAgentTIN) &&
        !Utils.hasUserPermission(Permission.EditAgentTIN)
      ) {
        return true;
      } else if (
        !Utils.hasUserPermission(Permission.ViewAgentTIN) &&
        Utils.hasUserPermission(Permission.EditAgentTIN)
      ) {
        return true;
      } else if (
        Utils.hasUserPermission(Permission.ViewAgentTIN) &&
        Utils.hasUserPermission(Permission.EditAgentTIN)
      ) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }
  //Id - 0  value - none, Id - 1 Value - Reduce 2%, Id - 2 Value - No Renewals, Id - 3 - Leave Renewals
  @observable renewalOptionList = [
    { label: "None", value: "0" },
    { label: "Reduce 2%", value: "1" },
    { label: "No Renewals", value: "2" },
    { label: "Leave Renewals", value: "3" },
  ];
  @observable renewalOption = "0";
  @observable ltcRating = "";
  @observable lifeRating = "";
  @observable isDefaultContact: boolean = false;
  @observable isHealthRatingLock: boolean = false;
  @observable contactDialogTitle: string = "";
  @observable addrDialogTitle: string = "";
  @observable creditCheckDialogTitle: string = "";
  @observable isCommissionContact: boolean = false;

  @observable bankAccountType: string = "";
  @observable isAddContacDialogtVisible: boolean = false;
  @observable AddEditCreditCheckViewModle: AddEditCreditCheckViewModel =
    new AddEditCreditCheckViewModel();
  @observable AddEditAddressViewModel: AddEditAddressViewModel =
    new AddEditAddressViewModel();

  @observable accountNumber: string = "";
  @observable principalAgent: string = "";
  @observable payee: string = "";
  @observable principalAgentId: number = 0;
  @observable payeeAgentId: number = 0;
  @observable paymentMethod: string = "";
  @observable prinicpalAgentsList = [];
  @observable bankAccountTypeList = [{ label: "None", value: "0" }];
  @observable paymentMethodList: any = [];
  @observable agentRatingList = [
    { label: "None", value: "" },
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
    { label: "F", value: "F" },
  ];

  @action getAddrActionItems = (rowData: AddressListModel) => {
    let detletMenuItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeleteAddressConfirm = true;
      },
    };
    var addressActionItems: MenuItem[] = [
      {
        label: rowData.isDefault ? "Set as NOT Default" : "Set as Default",
        icon: "fa fa-star",
        command: () => {
          if (rowData.isDefault) {
            this.unSetAddressAS(2);
          } else {
            this.setAddressAS(2);
          }
        },
      },
      {
        label: rowData.isHome ? "Set as NOT Residential" : "Set as Residential",
        icon: "fa fa-home",
        command: () => {
          if (rowData.isHome) {
            this.unSetAddressAS(0);
          } else {
            this.setAddressAS(0);
          }
        },
      },
      {
        label: rowData.isCommission
          ? "Set as NOT Commission Statement"
          : "Set as Commission Statement",
        icon: "fa fa-usd",
        command: () => {
          if (rowData.isCommission) {
            this.unSetAddressAS(1);
          } else {
            this.setAddressAS(1);
          }
        },
      },
      // detletMenuItem
    ];
    if (Utils.hasUserPermission(Permission.DeleteAddress)) {
      addressActionItems.push(detletMenuItem);
    }
    return addressActionItems;
  };

  @action getContactActionItems = (rowData: ContactModel) => {
    let deleteMenuItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeleteContactConfirm = true;
      },
    };
    var contactActionItems: MenuItem[] = [
      {
        label: rowData.commission
          ? "Set as Not Commission"
          : "Set as Commission",
        icon: "fa fa-usd",
        command: () => {
          this.setContactAsCommission();
        },
      },
    ];
    let setDefault = {
      label: rowData.default ? "Set as Not Default" : "Set as Default",
      icon: "fa fa-star",
      command: () => {
        if (rowData.default) {
          this.unSetContactAsDefault("default");
        } else {
          this.setContactAsDefault();
        }
      },
    };
    let setSMS = {
      label: rowData.enableSMS ? "Disable SMS" : "Enable SMS",
      icon: "fa fa-comment",
      command: () => {
        if (rowData.enableSMS !== undefined) {
          this.contactEnableSMS(rowData.enableSMS);
        }
      },
    };
    // Utils.hasUserPermission(Permission.SetDefaultContactTypeViaAgentProfile) &&
    contactActionItems.unshift(setDefault);
    if (
      rowData.contactType === ContactType.PhoneNumber &&
      rowData.phoneType === PhoneType.Mobile
    ) {
      contactActionItems.push(setSMS);
    }
    Utils.hasUserPermission(Permission.DeleteContactOption) &&
      contactActionItems.push(deleteMenuItem);
    return contactActionItems;
  };

  @action contactEnableSMS = async (enableSMS: boolean) => {
    try {
      await ProducerStore.setEnableSMS(this.selectContactId, !enableSMS);
      await this.getContacts(this.agentId);
    } catch (error) {
      this.isLoading = false;
    }
  };

  @action getCreditCheckActionItems = () => {
    var deleteMenuItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeleteCreditCheckConfirm = true;
      },
    };
    var creditCheckActionItems: MenuItem[] = [];
    if (Utils.hasUserPermission(Permission.DeleteCreditCheck))
      creditCheckActionItems.push(deleteMenuItem);

    return creditCheckActionItems;
  };

  @observable gender: string = "";
  @observable genderList = [
    { label: "Not Selected", value: "" },
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];
  @observable suffix: string = "0";
  @observable suffixList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable contactType: string = "Email";
  @observable phoneType: number = PhoneTypeValue.Other;
  @observable emailType: number = EmailTypeValue.Work;
  @observable alternativePOCType: number =
    AlternativePointOfContactTypeValue.Primary;
  @observable selectedRow: any | undefined = undefined;
  @observable contactDefault: any | undefined = undefined;
  @observable contactCommission: any | undefined = undefined;
  @observable contactTypeList = new Collection<any>();
  @observable phoneTypeList = new Collection<any>();
  @observable emailTypeList = new Collection<any>();
  @observable alternativePOCTypeList = new Collection<any>();

  @observable banksList = [{ label: "None", value: "0" }];

  @observable debtLevel: string = "0";
  @observable delbtLevelList = [{ label: "None", value: "0" }];
  @observable leadSourceList = [{ label: "None", value: "0" }];
  @observable selectedTabIndex?: number = 0;
  @observable agencyTabItems = [
    { label: AgentDetails.Summary, value: 0 },
    { label: AgentDetails.Addresses, value: 1 },
    { label: AgentDetails.ContactOptions, value: 2 },
    { label: AgentDetails.CreditChecks, value: 3 },
    { label: AgentDetails.PrinicipaAgents, value: 4 },
    { label: AgentDetails.Payees, value: 5 },
  ];
  @observable nonAgencyTabItems = [
    { label: AgentDetails.Summary, value: 0 },
    { label: AgentDetails.Addresses, value: 1 },
    { label: AgentDetails.ContactOptions, value: 2 },
    { label: AgentDetails.CreditChecks, value: 3 },
    { label: AgentDetails.Agencies, value: 4 },
    { label: AgentDetails.Payees, value: 5 },
  ];

  @computed
  get isCreditCheckDialogVisible(): boolean {
    return this.AddEditCreditCheckViewModle.isDialogVisible;
  }
  @computed
  get isAddressDialogVisible(): boolean {
    return this.AddEditAddressViewModel.isDialogVisible;
  }
  @action onRowClick = async (data: any) => {
    this.selectedRow = data;
    this.contactDefault = data.default;
    this.contactCommission = data.commission;
    this.selectContactId = data.id;
    this.contactType = data.contactType;
  };
  @action onRowUnselect = async (data: any, e: any) => {
    this.selectedRow = null;
  };
  @action setContactVale(value: string) {
    this.isNotValidContact = false;
    this.contactValue = value;
  }
  @action setDebtLevel(value: string) {
    this.debtLevel = value;
  }
  @action setContactDescription(value: string) {
    this.contactDescription = value;
  }

  @observable commissionSelectedPreferenceDefaultInitial: any = [];
  @observable commissionSelectedPreferenceDefault: any = [];
  @observable commissionSelectedPreferenceEdit: boolean = false;
  @observable showDefaultOverrideMessage: boolean = false;

  @action setCommissionPreferenceType(e: any) {
    let tempArray = [...this.commissionSelectedPreferenceDefault];
    if (e.checked) tempArray.push(e.value);
    else tempArray.splice(tempArray.indexOf(e.value), 1);
    this.commissionSelectedPreferenceDefault = [];
    this.commissionSelectedPreferenceDefault = tempArray;
  }

  @action checkIsAnyDefault = () => {
    let defaultRow = this.ContactsData.values.filter((i) => {
      return (
        i.default &&
        i.contactTypeId + "" == this.contactType &&
        i.id != this.selectContactId
      );
    });
    let anyDefault = defaultRow.length > 0;
    this.showDefaultOverrideMessage = anyDefault;
  };

  @action setAddContactDialogVisibility = async (
    value: boolean,
    isEdit: boolean | undefined
  ) => {
    if (value) {
      await this.loadLookups();
    }
    this.setIsException(false);
    this.isContactException = false;
    this.showDefaultOverrideMessage = false;
    this.contactResponse = "";
    this.isNotValidContact = false;
    this.commissionSelectedPreferenceDefaultInitial = [];
    this.commissionSelectedPreferenceEdit = false;
    this.commissionSelectedPreferenceDefault = [];
    await this.ResetValidateContact();
    if (isEdit) {
      this.contactDialogTitle = "Edit Contact";
      var result = await this.getContactDetails();
      if (result) {
        this.setContactDescription(
          result.description ? result.description : ""
        );
        this.setContactType(
          result.contactTypeId ? result.contactTypeId.toString() : "0"
        );
        this.setContactVale(
          result.contactValue
            ? this.getPhoneNumberUSformat("" + result.contactValue)
            : ""
        );
        if (result.enableSMS !== null) {
          this.setphoneType(
            result.phoneTypeId ? result.phoneTypeId.toString() : "0"
          );
          this.setSMS(result.enableSMS || false);
        } else {
          this.setSMS(false);
          this.setphoneType(PhoneTypeValue.Other.toString());
        }
        this.setEmailType(
          result.emailTypeId ? result.emailTypeId.toString() : "0"
        );
        this.setAlternativePOCType(
          result.alternativePointOfContactTypeId
            ? result.alternativePointOfContactTypeId.toString()
            : "0"
        );
        result.default && this.commissionSelectedPreferenceDefault.push("0");
        result.commission && this.commissionSelectedPreferenceDefault.push("1");
        this.commissionSelectedPreferenceDefaultInitial =
          this.commissionSelectedPreferenceDefault;
        this.commissionSelectedPreferenceEdit = (
          this.commissionSelectedPreferenceDefault || []
        ).includes("0");
      }
    } else {
      this.selectContactId = 0;
      this.contactDialogTitle = "Add Contact";
      this.setContactDescription("");
      this.setContactType("1");
      this.setContactVale("");
      this.setDefaultContact(false);
      this.setCommisionContact(false);
      this.setSMS(false);
      this.setphoneType(PhoneTypeValue.Mobile.toString());
      this.setEmailType(EmailTypeValue.Work.toString());
      this.setAlternativePOCType(
        AlternativePointOfContactTypeValue.Primary.toString()
      );
      this.commissionSelectedPreferenceDefault = [];
      this.commissionSelectedPreferenceDefaultInitial = [];
      this.commissionSelectedPreferenceEdit = false;
    }
    this.checkIsAnyDefault();
    this.isAddContacDialogtVisible = value;
  };
  @observable isLookupsLoaded = false;
  @action async loadLookups() {
    if (!this.isLookupsLoaded) {
      let items = await ContractsStore.getContactLookups();
      Utils.mapListItemsToDropdown(
        items.emailType || [],
        this.emailTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.phoneType || [],
        this.phoneTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.contactType || [],
        this.contactTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.alternativePointOfContactType || [],
        this.alternativePOCTypeList,
        "",
        ""
      );
      this.isLookupsLoaded = true;
    }
  }

  @action setCancelConfirmation(value: boolean) {
    this.isCancelConfirm = value;
  }
  @action setAddressDialogVisibility = async (
    value: boolean,
    isEdit: boolean
  ) => {
    await this.AddEditAddressViewModel.ResetValidate();
    this.AddEditAddressViewModel.isCustomValidation = false;
    this.AddEditAddressViewModel.isException = false;

    this.setIsException(false);
    if (isEdit) {
      this.addrDialogTitle = "Edit Address";
      var result = await this.getAddressDetails();
      this.AddEditAddressViewModel.showOverrideAddress = false;
      if (result) {
        this.AddEditAddressViewModel.setAddrssId(result.id ? result.id : 0);
        this.AddEditAddressViewModel.setAddress(
          result.addressLine1
            ? result.addressLine1 +
            ", " +
            result.city +
            " " +
            result.stateAbbreviation
            : ""
        );
        this.AddEditAddressViewModel.setAddressType(
          result.addressType ? result.addressType : 1
        );
        if (result.addressType == 4)
          this.AddEditAddressViewModel.setAddressName(
            result.addressType ? "" + result.addressType : "",
            true,
            result.addressName ? result.addressName : ""
          );
        else
          this.AddEditAddressViewModel.setAddressName(
            result.addressType ? "" + result.addressType : "",
            true,
            ""
          );
        this.AddEditAddressViewModel.setBusinessName(
          result.bussinessName ? result.bussinessName : ""
        );
        this.AddEditAddressViewModel.setAttn(result.attn ? result.attn : "");
        this.AddEditAddressViewModel.setVisibleInDownline(
          result.visibleInDownline ? result.visibleInDownline : false
        );
        this.AddEditAddressViewModel.setDefault(
          result.isDefault ? result.isDefault : false
        );
        this.AddEditAddressViewModel.setCommission(
          result.isCommission ? result.isCommission : false
        );
        this.AddEditAddressViewModel.setResidential(
          result.isHome ? result.isHome : false
        );
        await this.AddEditAddressViewModel.checkAddressValidation();
        if (
          !result.isValid &&
          Utils.hasUserPermission(Permission.OverrideAddressValidation)
        ) {
          this.AddEditAddressViewModel.showOverrideAddress = true;
          this.AddEditAddressViewModel.setCity(result.city ? result.city : "");
          this.AddEditAddressViewModel.setCounty(
            result.countyName ? result.countyName : ""
          );
          this.AddEditAddressViewModel.setState(
            result.stateAbbreviation ? result.stateAbbreviation : ""
          );
          this.AddEditAddressViewModel.setAddressLine(
            result.addressLine1 ? result.addressLine1 : ""
          );
          this.AddEditAddressViewModel.setZip(
            result.zipCode ? "" + result.zipCode : "0"
          );
        } else {
          this.AddEditAddressViewModel.showOverrideAddress = false;
          await this.AddEditAddressViewModel.resetOverrideFields();
        }
      }
    } else {
      this.addrDialogTitle = "Add Address";
      this.AddEditAddressViewModel.validAddressModel = undefined;
      this.AddEditAddressViewModel.isValidAddress = false;
      this.AddEditAddressViewModel.setAddress("");
      this.AddEditAddressViewModel.setAddressName("1", false, "");
      this.AddEditAddressViewModel.setAddressType(1);
      this.AddEditAddressViewModel.setCustomAddrName("");
      this.AddEditAddressViewModel.setBusinessName("");
      this.AddEditAddressViewModel.setAttn("");
      this.AddEditAddressViewModel.setVisibleInDownline(false);
      this.AddEditAddressViewModel.setDefault(false);
      this.AddEditAddressViewModel.setCommission(false);
      this.AddEditAddressViewModel.setResidential(false);
      this.AddEditAddressViewModel.isValidAddress = false;
      this.AddEditAddressViewModel.showOverrideAddress = false;
    }
    this.AddEditAddressViewModel.setDialogVisibility(value);
  };

  @action setCreditCheckDialogVisibility = async (
    value: boolean,
    isEdit: boolean
  ) => {
    this.setIsException(false);
    this.AddEditCreditCheckViewModle.ResetValidation();
    if (isEdit) {
      this.creditCheckDialogTitle = "Edit Credit Check";
      var result = await AccountStore.getCreditCheckDetails(
        this.selectedCreditCheckId
      );
      if (result) {
        this.AddEditCreditCheckViewModle.result = result.result ? "2" : "1";
        var date = Utils.getDateInFormat(
          result.inquiryDate ? result.inquiryDate : new Date()
        );
        this.AddEditCreditCheckViewModle.setInquiryDate(new Date(date));
        this.AddEditCreditCheckViewModle.vendor = "" + result.vendorId;
      }
    } else {
      this.creditCheckDialogTitle = "Add Credit Check";
      await this.AddEditCreditCheckViewModle.loadVendors();
      this.AddEditCreditCheckViewModle.result = "1";
      this.AddEditCreditCheckViewModle.inquiryDate = new Date();
      this.AddEditCreditCheckViewModle.vendor = "2";
    }
    this.AddEditCreditCheckViewModle.setDialogVisibility(value);
  };
  @action setDeleteContactConfirmation(value: boolean) {
    this.isDeleteContactConfirm = value;
  }
  @action setDeleteAddressConfirmation(value: boolean) {
    this.isDeleteAddressConfirm = value;
  }
  @action setDeleteCreditCheckConfirmation(value: boolean) {
    this.isDeleteCreditCheckConfirm = value;
  }
  @action setDeletePrincipalAgentConfirmation(value: boolean) {
    this.isDeletePrincipalAgentConfirm = value;
  }
  @action setDeletePayeeConfirmation(value: boolean) {
    this.isDeletePayeeConfirm = value;
  }
  @action setSelectedGroups = (value: any) => {
    this.selectedGroups = value;
  };
  @action deleteAddress = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      await ProducerStore.deleteAddress(this.selectedAddressId, this.agentId);
      this.isDeleteAddressConfirm = false;
      setTimeout(async () => {
        await this.loadAddresses(0);
        this.isLoading = false;
      }, Utils.timeDelay_Delete());
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };
  @action deletePrincipalAgent = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      await ProducerStore.deletePrincipalAgent(
        this.selectedPrincipalAgentId,
        this.agentId
      );
      this.isDeletePrincipalAgentConfirm = false;
      setTimeout(async () => {
        await this.getPrincipalAgents(0);
        this.isLoading = false;
      }, Utils.timeDelay_Delete());
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };
  @action deleteContact = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      await ProducerStore.deleteContact(this.selectContactId, this.agentId);
      this.isDeleteContactConfirm = false;
      setTimeout(async () => {
        await this.getContacts(0);
        this.isLoading = false;
      }, Utils.timeDelay_Delete());
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };
  @action deleteCreditCheck = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      await AccountStore.deleteCreditCheck(this.selectedCreditCheckId);
      this.setDeleteCreditCheckConfirmation(false);
      await this.getCreditChecks(0);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };
  @action setLeadSource(value: string) {
    this.leadSource = value;
  }
  @action setDefaultContact(value: boolean) {
    this.isDefaultContact = value;
  }
  @action setCommisionContact(value: boolean) {
    this.isCommissionContact = value;
  }
  @action setHealthRating(value: string) {
    this.selectedHealthRating = value;
  }
  @action setHealthRatingLock(value: boolean) {
    this.isHealthRatingLock = value;
  }
  @action setLifeRating(value: string) {
    this.lifeRating = value;
  }
  @action setFERating(value: string) {
    this.feRating = value;
  }
  @action setRenewalOption(value: string) {
    this.renewalOption = value;
  }
  @action setLTCRating(value: string) {
    this.ltcRating = value;
  }
  @action setAnnuityRating(value: string) {
    this.annuityRating = value;
  }
  @action setBankAccountType(value: string) {
    this.bankAccountType = value;
  }
  @action setSmr(value: string) {
    this.smr = value;
  }
  @action setLhr(value: string) {
    this.lhr = value;
  }
  @action searchPrincipalAgents = async (value: string) => {
    try {
      var element = document.querySelector(".p-autocomplete-panel");
      let result = await ProducerStore.getPriniciplaAgentsLookup(value);

      if (result) {
        if (
          result.principalModelLists &&
          result.principalModelLists.length > 0
        ) {
          result.principalModelLists.splice(0, 0, { id: -1 });
          this.FilteredAgentsList.values = result.principalModelLists;
          setTimeout(() => {
            if (element) {
              element.scrollTo(0, 0);
            }
          }, 10);
        } else {
          this.noPrincipalAgents();
        }
      } else {
        this.noPrincipalAgents();
      }
    } catch (e) {
      this.noPrincipalAgents();
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = e.response;
    }
  };
  @action searchPayee = async (value: string) => {
    IMASLog.log("search agents ..");
    try {
      let result = await ProducerSearchStore.getAgentsLookup(
        1,
        value,
        0,
        20,
        undefined,
        undefined
      );
      if (result !== null) {
        if (result.data) {
          this.FilteredPayeeList.values = result.data;
        } else {
          this.noPayeeFound();
        }
      } else {
        this.noPayeeFound();
      }
    } catch (e) {
      this.noPayeeFound();
    }
  };
  @action noPrincipalAgents() {
    var noAgent: AgentListModel = { id: 0 };
    this.FilteredAgentsList.values = [noAgent];
  }
  @action noPayeeFound() {
    var noAgent: AgentListModel = { id: 0 };
    this.FilteredPayeeList.values = [noAgent];
  }
  @observable statusList = [{ label: "Prospective", value: "6" }];
  @observable uplineList = [{ label: "Select Upline", value: "0" }];
  @observable smrList = [{ label: "N/A", value: "1" }];
  @observable lhrList = [{ label: "N/A", value: "1" }];
  @action setFirstName(value: string) {
    this.firstName = value;
  }
  @action setMiddleName(value: string) {
    this.middleName = value;
  }
  @action setLastName(value: string) {
    this.lastName = value;
  }
  @action setStatus(value: string) {
    this.status = value;
  }
  @action setUpline(value: string) {
    this.selectedUpline = value;
  }

  @action setBank(value: string) {
    this.bank = value;
  }
  @action setBankId(value: number) {
    this.bankId = value;
  }
  @action setAccountNumber(value: string) {
    this.accountNumber = value;
  }
  @action setPrincipalAgent(value: string) {
    this.principalAgent = value;
  }
  @action setPayee(value: string) {
    this.payee = value;
  }
  @action setProfilPic(value: string) {
    this.profilePicDelete = false;
    this.profilePic = value;
    if (value === "1,1") {
      this.profilePic = "assets/layout/images/profile-default.png";
      this.profilePicDelete = true;
      ProfilePicData.data = undefined;
    }
  }
  @action setPrincipalAgentId(value: number) {
    this.principalAgentId = value;
  }
  @action setPayeeId(value: number) {
    this.payeeAgentId = value;
  }
  @action setPaymentMethod(value: string) {
    this.paymentMethod = value;
  }

  @action getPhoneTypeId = (phoneType: string) => {
    var pt = 0;
    if (this.phoneTypeList.values.length > 0) {
      var phone_type = this.phoneTypeList.values.filter(
        (i) => i.label === (phoneType === "Other" ? "Others" : phoneType)
      );
      if (phone_type && phone_type.length > 0) {
        pt = +phone_type[0].value;
      }
    }
    return pt;
  };
  @action getEmailTypeId = (emailType: string) => {
    var et = 0;
    if (this.emailTypeList.values.length > 0) {
      var email_type = this.emailTypeList.values.filter(
        (i) => i.label === emailType
      );
      if (email_type && email_type.length > 0) {
        et = +email_type[0].value;
      }
    }
    return et;
  };
  @action getAlternativePOCTypeId = (alternativePOCType: string) => {
    var at = 0;
    if (this.alternativePOCTypeList.values.length > 0) {
      var alternativePOC_type = this.alternativePOCTypeList.values.filter(
        (i) => i.label === alternativePOCType
      );
      if (alternativePOC_type && alternativePOC_type.length > 0) {
        at = +alternativePOC_type[0].value;
      }
    }
    return at;
  };

  @action setContactType(value: string) {
    this.isNotValidContact = false;
    this.contactType = value;
    this.contactValue = "";
    this.commissionSelectedPreferenceDefault = [];
    this.commissionSelectedPreferenceDefault =
      this.commissionSelectedPreferenceDefaultInitial;
    this.commissionSelectedPreferenceEdit = (
      this.commissionSelectedPreferenceDefaultInitial || []
    ).includes("0");

    this.phoneType = PhoneTypeValue.Other;
    switch (value) {
      case "1":
        this.contactValueLabel = ContactType.Email;
        break;
      case "2":
        this.contactValueLabel = ContactType.TextMessage;
        break;
      case "3":
        this.contactValueLabel = ContactType.PhoneNumber;
        break;
      case "4":
        this.contactValueLabel = ContactType.Name;
        break;
    }
  }
  @action setphoneType(value: string) {
    this.phoneType = parseInt(value);
    if (value !== "2") {
      this.setSMS(false);
    }
  }
  @action setEmailType = async (value: string) => {
    this.emailType = parseInt(value);
  };
  @action setAlternativePOCType = async (value: string) => {
    this.alternativePOCType = parseInt(value);
  };
  @action setGender(value: string) {
    this.gender = value;
  }
  @action setSuffix(value: string) {
    this.suffix = value;
  }
  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.setIsEditAccess(false);
    this.updateSelectedKey(key);
  };
  @action updateSelectedKey = (value: string) => {
    this.selectedKey = value;
    switch (value) {
      case AgentDetails.Summary:
        this.setIsException(false);
        break;
      case AgentDetails.Addresses:
        this.loadAddresses(0);
        break;
      case AgentDetails.ContactOptions:
        this.getContacts(0);
        break;
      case AgentDetails.CreditChecks:
        this.getCreditChecks(0);
        break;
      case AgentDetails.Payees:
        this.getPayees(0);
        break;
      case AgentDetails.Agencies:
        this.getAgencies(0);
        break;
      case AgentDetails.PrinicipaAgents:
        this.getPrincipalAgents(0);
        break;
      case AgentDetails.W9:
        this.resetW9Form();
        this.isEditW9 = true;
        this.getW9AddressStates();
        break;
    }
  };
  @action updateIsAgencySelected = (value: boolean) => {
    this.isAgency = value;
    if (this.isAgency === this.existingIsAgency) {
      this.isMarketer = this.existingIsMarketer;
    } else {
      this.isMarketer = false;
    }
  };
  @action setNPN(value: string) {
    this.npn = value;
    //if (value === "") {
    this.checkDuplicateAgent("npn");
    // }
  }
  @action setAgencyName(value: string) {
    this.agencyName = value;
  }
  @action setTin(value: string) {
    this.tin = value;
    this.isDuplicate = false;
  }
  @action setAgency(value: string) {
    this.agency = value;
  }
  @action setSelectedContactId(
    value: number | undefined,
    contactType: string | undefined
  ) {
    this.selectContactId = value ? value : 0;
    this.selectContactTypeId = this.getContactTypeId("" + contactType);
  }
  @action setSelectedCreditCheck(value: number | undefined) {
    this.selectedCreditCheckId = value ? value : 0;
  }
  @action setSelectedAddressId(value: number | undefined) {
    this.selectedAddressId = value ? value : 0;
  }
  @action setSelectedPrincipalAgent(value: number | undefined) {
    this.selectedPrincipalAgentId = value ? value : 0;
  }
  @action setSelectedPayee(value: number | undefined) {
    this.selectedPayee = value ? value : 0;
  }
  @action setIsExportFromTrip(value: boolean) {
    this.isExcludeFromTrip = value;
  }
  @action setIsLocation(value: boolean) {
    this.isLocation = value;
  }
  @action setIsEditAccess(value: boolean) {
    this.isEditAccess = value;
  }
  @action setisOnlyPoBox(value: boolean) {
    this.onlyPoBox = value;
  }
  @action setisSureLcAgent(value: boolean) {
    this.sureLcAgent = value;
  }
  @action setIsHiddenAgentProfile(value: boolean) {
    this.isHiddenAgentProfile = value;
  }
  @action setIsIntegrityPartner(value: boolean) {
    this.isIntegrityPlatformPartner = value;
  }
  @action setVested(value: boolean) {
    this.vested = value;
  }
  @action setEOParticipant(value: boolean) {
    this.isEAndOPariticipant = value;
    if (!value) {
      this.eoParticipantExpirationDate = undefined;
    }
  }
  @action setIsMarketer(value: boolean) {
    this.isMarketer = value;
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setIsDuplicate(value: boolean) {
    this.isDuplicate = value;
  }
  @action setDuplicateError(value: string) {
    this.duplicateError = value;
  }
  @action setIsBankValid(value: boolean) {
    this.isBankValid = value;
    if (value) {
      window.scrollTo(0, 1000);
    }
  }
  @action setIsEOExpirationDateValid(value: boolean) {
    this.isEOExpirationDateValid = value;
  }

  @action setValidBankError(value: string) {
    this.validBankError = value;
  }
  @action scrollToTop() {
    window.scrollTo(0, 0);
  }
  @action setIsSuccess(value: boolean) {
    if (value) {
      this.scrollToTop();
    }
    this.isSuccess = value;
  }
  @action setIsAddAddressSuccess(value: boolean) {
    if (value) {
      this.scrollToTop();
    }
    this.isAddAddressSuccess = value;
  }
  @action setIsContactSuccess(value: boolean) {
    if (value) {
      this.scrollToTop();
    }
    this.isAddContactSuccess = value;
  }
  @action setIsCreditCheckSuccess(value: boolean) {
    if (value) {
      this.scrollToTop();
    }
    this.isAddCreditCheckSuccess = value;
  }
  @action setExceptionMessage(value: string) {
    this.exceptionMessage = value;
  }
  @action setDba(value: string) {
    this.dba = value;
  }
  @action setFFMId(value: string) {
    this.ffmId = value;
  }
  @action onVestedPercentage(value: string) {
    ;
    this.vestedPercentage = value;

  }

  @action setWebsite(value: string) {
    this.website = value;
  }
  @action async setSSN(value: string) {
    this.ssn = value;
    this.validateSSN = value.replaceAll("-", "").replaceAll("_", "");
    await this.Validate();
    if (value === "" || this.validateSSN.length == 9) {
      this.checkDuplicateAgent("ssn");
    }
  }
  @action setAlternativeLastName(value: string) {
    this.alternativeLastName = value;
  }
  @action setNickName(value: string) {
    this.nickName = value;
  }
  @action setDOB(value: Date | undefined) {
    this.dateOfBirth = value;
  }
  @action setEOParticipationExpDate(value: Date | undefined) {
    this.eoParticipantExpirationDate = value;
  }
  @action
  public Validate = async () => {
    var isInValid = await this.ErrorModel.Validate(this);
    var isAgentOrAgencyInValid = await this.ErrorModelForAgentOrAgency.Validate(
      this
    );
    return isInValid || isAgentOrAgencyInValid;
  };
  @action
  public ResetValidate = async () => {
    await this.ErrorModel.ResetValidation(this);
    await this.ErrorModelForAgentOrAgency.ResetValidation(this);
  };
  @action
  public ValidateContact = async () => {
    return this.ErrorModelContact.Validate(this);
  };
  @action
  public ResetValidateContact = async () => {
    return this.ErrorModelContact.ResetValidation(this);
  };
  @action
  public ValidateAgency = async () => {
    var isInValid = await this.ErrorModelAgency.Validate(this);
    var isAgentOrAgencyInValid = await this.ErrorModelForAgentOrAgency.Validate(
      this
    );
    return isInValid || isAgentOrAgencyInValid;
  };
  @action
  public ResetValidateAgency = async () => {
    await this.ErrorModelAgency.ResetValidation(this);
    await this.ErrorModelForAgentOrAgency.ResetValidation(this);
  };

  @action
  public Submit = async () => {
    this.isEdit = true;
  };

  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }

  @action onPageAddress(firstIndex: number, rows: number) {
    this.rows = rows;
    this.addressPageNo = firstIndex;
    var startIndex = firstIndex / this.rows;
    this.loadAddresses(startIndex);
  }
  @action onPagePrincipalAgents(firstIndex: number) {
    this.principalAgentsPageNo = firstIndex;
    this.getPrincipalAgents(firstIndex / this.rows);
  }
  @action onPageContacts(firstIndex: number) {
    this.contactOptionsPageNO = firstIndex;
    var startIndex = firstIndex / this.rows;
    this.getContacts(startIndex);
  }
  @action onPagePayees(firstIndex: number) {
    this.payeesPageNo = firstIndex;
    var startIndex = firstIndex / this.rows;
    this.getPayees(startIndex);
  }
  @action onPageAgencies(firstIndex: number) {
    this.payeesPageNo = firstIndex;
    var startIndex = firstIndex / this.rows;
    this.getAgencies(startIndex);
  }
  @action onPageCreditChecks(firstIndex: number) {
    this.creditChecksPageNo = firstIndex;
    var startIndex = firstIndex / this.rows;
    this.getCreditChecks(startIndex);
  }
  @action
  public Cancel = async () => {
    if (this.isEdit) {
      routes.agentDetails.replace({ agentId: "" + this.agentId });
    } else {
      routes.agentSearch.replace();
    }
  };
  @action searchBank = async (value: string) => {
    var noRecords: BankModelReponse = {
      bankId: 0,
      bankName: "No Records Found",
    };
    try {
      var result = await AccountStore.banksLookup(value);
      if (result && result.length > 0) {
        this.BankListSuggestions.values = result;
      } else {
        this.BankListSuggestions.values = [noRecords];
      }
    } catch (e) {
      this.BankListSuggestions.values = [noRecords];
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = e.response;
    }
  };
  @action validateMiddleName() {
    if (!this.isAgency) {
      //validation for middleName
      var middleNameValue = "";
      var MName: any[] = [];
      if (this.middleName) {
        middleNameValue = this.middleName.toLowerCase().trim();
        MName = middleNameValue.split("");

        if (
          middleNameValue &&
          MName &&
          (["dba", "llc", "agency", "jr", "sr", "||", "inc"].indexOf(
            middleNameValue
          ) !== -1 ||
            middleNameValue.length < 2 ||
            ["'", "-"].includes(MName[0]) ||
            ["'", "-"].includes(MName[MName.length - 1]) ||
            middleNameValue.split(" ").length - 1 > 1)
        ) {
          this.setIsmiddleNameValid(true);
          this.setValidmiddleNameError("Middle Name is Invalid");
        } else {
          this.setIsmiddleNameValid(false);
          this.setValidmiddleNameError("");
        }
      } else {
        this.setIsmiddleNameValid(false);
        this.setValidmiddleNameError("");
      }
    }
  }

  @action getMapHref = (address: AddressListModel) => {
    return (
      "https://maps.google.com/maps?q=" +
      address.latitude +
      "," +
      address.longitude +
      "&ie=UTF-8"
    );
  };
  @action editAgent = async () => {
    this.isBankValid = false;
    await this.ResetValidateAgency();
    await this.ResetValidate();

    PageContext.isMessageVisible = false;

    //await this.checkDuplicateAgent();
    try {
      if (!this.isEdit) {
        if (this.isDuplicate) {
          if (this.isAgency) {
            if (this.tin || this.npn) return 0;
          } else {
            if (this.ssn || this.npn) return 0;
          }
        }
      } else {
        if (this.accountNumber && this.accountNumber.length > 0) {
          this.validBankError = "";
          if (this.accountNumber.length < 4) {
            this.setIsBankValid(true);
            this.setValidBankError(
              "Account Number must be between 4 and 17 digits."
            );
          }
          if (
            this.isEAndOPariticipant &&
            !Utils.isDateValid(this.eoParticipantExpirationDate)
          ) {
            this.setIsEOExpirationDateValid(true);
            this.setValidEOExpirationDateError(
              "E & O Expiration Date is mandatory"
            );
          } else {
            this.setIsEOExpirationDateValid(false);
            this.setValidEOExpirationDateError("");
          }
        }
        if (this.isBankValid) {
          return 0;
        }
      }
      this.isLoading = true;
      this.isException = false;
      this.setIsSuccess(false);
      if (!this.isAgency) {
        //validation for firstName
        var firstNameValue = "";
        var FName: any[] = [];
        if (this.firstName) {
          firstNameValue = this.firstName.toLowerCase().trim();
          FName = firstNameValue.split("");

          if (
            firstNameValue &&
            FName &&
            (firstNameValue.length < 2 ||
              ["'", "-"].includes(FName[0]) ||
              ["'", "-"].includes(FName[FName.length - 1]) ||
              firstNameValue.split(" ").length - 1 > 1)
          ) {
            this.setIsfirstNameValid(true);
            this.setValidfirstNameError("First Name is Invalid");
          } else {
            this.setIsfirstNameValid(false);
            this.setValidfirstNameError("");
          }
        } else {
          this.setIsfirstNameValid(false);
          this.setValidfirstNameError("");
        }
      }

      if (!this.isAgency) {
        //validation for lastName
        var lastNameValue = "";
        var LName: any[] = [];
        if (this.lastName) {
          lastNameValue = this.lastName.toLowerCase().trim();
          LName = lastNameValue.split("");

          if (
            lastNameValue &&
            LName &&
            (["dba", "llc", "agency", "jr", "sr", "||"].indexOf(
              lastNameValue
            ) !== -1 ||
              ["'", "-"].includes(LName[0]) ||
              ["'", "-"].includes(LName[LName.length - 1]))
          ) {
            this.setIslastNameValid(true);
            this.setValidlastNameError("Last Name is Invalid");
          } else {
            this.setIslastNameValid(false);
            this.setValidlastNameError("");
          }
        } else {
          this.setIslastNameValid(false);
          this.setValidlastNameError("");
        }
      }

      //  this.validateMiddleName();
      //validation for nickName
      var nickNameValue = "";
      var NName: any[] = [];
      if (this.nickName) {
        nickNameValue = this.nickName.toLowerCase().trim();
        NName = nickNameValue.split("");

        if (
          nickNameValue &&
          NName &&
          (nickNameValue.length < 2 ||
            ["'", "-"].includes(NName[0]) ||
            ["'", "-"].includes(NName[NName.length - 1]))
        ) {
          this.setIsnickNameValid(true);
          this.setValidnickNameError("Nick Name is Invalid");
        } else {
          this.setIsnickNameValid(false);
          this.setValidnickNameError("");
        }
      } else {
        this.setIsnickNameValid(false);
        this.setValidnickNameError("");
      }
      if (this.isAgency) {
        //validation for agency
        var agencyNameValue = "";
        var AName: any[] = [];
        if (this.agencyName) {
          agencyNameValue = this.agencyName.toLowerCase().trim();
          AName = agencyNameValue.split("");

          if (
            agencyNameValue &&
            AName &&
            (["dba"].filter((s) => s.includes(agencyNameValue)).length !== 0 ||
              agencyNameValue.length < 2 ||
              ["'", "-", "&", ","].includes(AName[0]) ||
              ["'", "-", "&", ","].includes(AName[AName.length - 1]))
          ) {
            this.setIsagencyNameValid(true);
            this.setValidagencyNameError("Agency Name is Invalid");
          } else {
            this.setIsagencyNameValid(false);
            this.setValidagencyNameError("");
          }
        } else {
          this.setIsagencyNameValid(false);
          this.setValidagencyNameError("");
        }
      }

      //validation for Npn
      var NpnValue = "";
      var NpnName: any[] = [];
      if (this.npn) {
        NpnValue = this.npn;
        NpnName = NpnValue.split("");
        if (NpnValue && NpnName && NpnName[0] === "0") {
          this.setIsNpnValid(true);
          this.setValidNpnError("NPN is Invalid");
        } else {
          this.setIsNpnValid(false);
          this.setValidNpnError("");
        }
      } else {
        this.setIsNpnValid(false);
        this.setValidNpnError("");
      }

      //validation for SSN
      if (!this.isAgency) {
        var SSNValue = "";
        var SSNName: any[] = [];
        if (this.ssn) {
          SSNValue = this.ssn.includes("-")
            ? this.ssn
            : this.ssn.substring(0, 3) +
            "-" +
            this.ssn.substring(3, 5) +
            "-" +
            this.ssn.substring(5, 9);
          SSNName = SSNValue.split("-");
          if (
            SSNValue &&
            SSNName &&
            (SSNName[0] === "000" ||
              SSNName[1] === "00" ||
              SSNName[2] === "0000")
          ) {
            this.setIsSsnValid(true);
            this.setValidSsnError("SSN is Invalid");
          } else {
            this.setIsSsnValid(false);
            this.setValidSsnError("");
          }
        } else {
          this.setIsSsnValid(false);
          this.setValidSsnError("");
        }
        // if(this.selectedKey === AgentDetails.W9){
        //   SSNValue = this.contactValueW9.includes("-")
        //     ? this.contactValueW9
        //     : this.contactValueW9.substring(0, 3) +
        //       "-" +
        //       this.contactValueW9.substring(3, 5) +
        //       "-" +
        //       this.contactValueW9.substring(5, 9);
        //   SSNName = SSNValue.split("-");
        // }
        // SSNValue = this.ssn ? this.ssn.replace(/-/g, '').trim() : ''
      }
      //validation for DBA
      var DbaValue = "";
      var DName: any[] = [];
      if (this.dba) {
        DbaValue = this.dba.toLowerCase().trim();
        DName = DbaValue.split("");

        if (
          DbaValue &&
          DName &&
          ([
            "dba",
            this.agency,
            this.firstName ? this.firstName.toLowerCase() : "",
            this.lastName ? this.lastName.toLowerCase() : "",
            this.firstName && this.lastName
              ? (this.lastName + ", " + this.firstName).toLowerCase()
              : "",
            this.firstName && this.lastName
              ? (this.lastName + "," + this.firstName).toLowerCase()
              : "",
            this.firstName && this.lastName
              ? (this.firstName + " " + this.lastName).toLowerCase()
              : "",
          ].filter((s) => s && s.includes(DbaValue)).length !== 0 ||
            DbaValue.length < 2 ||
            ["'", "-", "&", ","].includes(DName[0]) ||
            ["'", "-", "&", ","].includes(DName[DName.length - 1]))
        ) {
          this.setIsDbaValid(true);
          this.setValidDbaError("* Invalid DBA"); //DBA is Invalid
        } else {
          this.setIsDbaValid(false);
          this.setValidDbaError("");
        }
      } else {
        this.setIsDbaValid(false);
        this.setValidDbaError("");
      }

      //validation for calender date for age 18
      if (this.dateOfBirth && !this.isAgency) {
        if (this.dateOfBirth && Utils.validateDOB(this.dateOfBirth)) {
          this.setIsDOBValid(true);
          this.setValidDOBError(Constants.dobValidation);
        } else {
          this.setIsDOBValid(false);
          this.setValidDOBError("");
        }
      } else {
        this.setIsDOBValid(false);
        this.setValidDOBError("");
      }

      if (
        this.isfirstNameValid ||
        this.islastNameValid ||
        this.isnickNameValid ||
        this.isDOBValid ||
        this.isagencyNameValid ||
        this.isSsnValid ||
        this.isNpnValid
      ) {
        return 0;
      }
      if (this.leadSource === "0") {
        window.scrollTo(0, 300);
      }

      this.validateCustomAttributes();

      let customAttributes: any = [];

      if (
        Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
        Utils.hasUserPermission(Permission.PublicCustomAttribute)
      ) {
        customAttributes = await this.createUpdateAttributes();
      }

      if (this.isValid) {
        if (!(await this.ValidateAgency()) && this.isAgency) {
          var agencyDto: UpdateAgentModel = { lastName: "" };
          var tinValue = "";
          if (this.tin) {
            tinValue = this.tin.replace(/-/g, "").trim();
          }
          let agencyName = this.agencyName ? this.agencyName.trim() : "";
          let firstName = this.firstName ? this.firstName.trim() : "";
          agencyDto.lastName = Utils.capitalize(agencyName);
          agencyDto.firstName = Utils.capitalize(firstName);
          agencyDto.isAgency = this.isAgency;
          agencyDto.npn = this.npn;
          agencyDto.status = +this.status;
          agencyDto.dba = this.dba ? this.dba.trim() : "";
          agencyDto.nickName = this.nickName ? this.nickName.trim() : "";
          agencyDto.leadSource = +this.leadSource;
          agencyDto.tin = tinValue;
          agencyDto.webSite = this.website;
          agencyDto.isIntegrityPlatformPartner =
            this.isIntegrityPlatformPartner;
          agencyDto.federallyFacilitatedMarketplaceUserId = this.ffmId;
          agencyDto.agentGroup = this.selectedGroups.map(Number);
          agencyDto.w9 = this.w9 || undefined;
          agencyDto.eoParticipantExpirationDate =
            this.eoParticipantExpirationDate;
          agencyDto.eoParticipant = this.isEAndOPariticipant;
          agencyDto.vested = this.vested;
          agencyDto.smr = +this.smr;
          agencyDto.lhr = +this.lhr;
          agencyDto.vestedPercentage = this.vestedPercentage ? +this.vestedPercentage : undefined;
          agencyDto.uplineId = +this.selectedUpline;
          agencyDto.customAttributes = customAttributes;
          if (this.isEdit) {
            this.createAgencyDto(agencyDto);
            // try {
            var agentResponse = await ProducerStore.editAgent(agencyDto);
            if (agentResponse.error && agentResponse.error.length > 0) {
              this.setIsDuplicate(true);
              this.duplicateAgentId = agentResponse.existingAgentId
                ? agentResponse.existingAgentId
                : 0;
              this.duplicateError = "" + agentResponse.error;
            } else {
              this.agentId = agentResponse.agentId ? agentResponse.agentId : 0;
              if (
                Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
                Utils.hasUserPermission(Permission.PublicCustomAttribute)
              ) {
                await this.createUpdateAttributes();
              }
              IMASLog.log("agency updated successfully :");
              this.isLoading = false;
              await this.ResetValidateAgency();
              //this.setIsSuccess(true)
              this.gotoDetails();
            }
            // } catch (error) {
            //   this.isLoading = false;
            //   this.isException = true;
            //   this.exceptionMessage = error.response;
            // }
          }
        }

        if (
          (!this.isAgency && !(await this.Validate())) ||
          this.selectedKey === AgentDetails.W9
        ) {
          var ssnValue = "";
          if (this.ssn) {
            ssnValue = this.ssn.replace(/-/g, "").trim();
          }

          let firstName = this.firstName ? this.firstName.trim() : "";
          let lastName = this.lastName ? this.lastName.trim() : "";
          var updateDto: UpdateAgentModel = {
            firstName: Utils.capitalize(firstName),
            lastName: Utils.capitalize(lastName),
            isAgency: this.isAgency,
            middle: this.middleName ? this.middleName : "",
            status: +this.status,
            gender: this.gender,
            suffix: this.suffix !== "0" ? this.suffix : undefined,
            tin: this.tin,
            dba: this.dba ? this.dba.trim() : "",
            dob: this.dateOfBirth,
            npn: this.npn,
            nickName: this.nickName ? this.nickName.trim() : "",
            webSite: this.website,
            leadSource: +this.leadSource,
            agentGroup:
              this.selectedGroups && this.selectedGroups.length > 0
                ? this.selectedGroups.map(Number)
                : [],
            ssn: ssnValue,
            federallyFacilitatedMarketplaceUserId: this.ffmId,
            w9: this.w9,
            smr: +this.smr,
            lhr: +this.lhr,
            uplineId: +this.selectedUpline,
            vestedPercentage: this.vestedPercentage ? +this.vestedPercentage : undefined,
            customAttributes: customAttributes,
          };
          // try {
          if (this.isEdit || this.selectedKey === AgentDetails.W9) {
            this.createAgentDto(updateDto);
            var agentResponse = await ProducerStore.editAgent(updateDto);
            if (agentResponse.error && agentResponse.error.length > 0) {
              this.setIsDuplicate(true);
              this.duplicateAgentId = agentResponse.existingAgentId
                ? agentResponse.existingAgentId
                : 0;
              this.duplicateError = "" + agentResponse.error;
            } else {
              if (
                Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
                Utils.hasUserPermission(Permission.PublicCustomAttribute)
              ) {
                await this.createUpdateAttributes();
              }
              this.isLoading = false;
              await this.ResetValidate();
              this.setIsSuccess(true);
              this.gotoDetails();
            }
          }
          // } catch (error) {
          //   this.isLoading = false;
          //   this.isException = true;
          //   this.exceptionMessage = error.response;
          //   return 0;
          // }
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      return 0;
    }
  };

  @action gotoDetails = async () => {
    try {
      this.isException = false;
      this.isLoading = true;
      let profilePicDTO: UploadProfilePicModel = {};
      if (ProfilePicData.data) {
        var imageData = ProfilePicData.data.split(",");
        profilePicDTO.agentId = this.agentId;
        profilePicDTO.image = imageData[1];
        await ProducerStore.updateProfilePic(profilePicDTO);
      } else {
        profilePicDTO.agentId = this.agentId;
        profilePicDTO.image = undefined;
        if (this.profilePicDelete) {
          await ProducerStore.updateProfilePic(profilePicDTO);
        }
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }

    setTimeout(() => {
      routes.agentDetails.replace({ agentId: "" + this.agentId });
    }, 100);
  };
  @action goToDuplicateAgent() {
    routes.agentDetails.replace({ agentId: "" + this.duplicateAgentId });
  }
  @action getCreditChecks = async (startIndex: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await AccountStore.getCreditChecks(
        this.agentId,
        startIndex,
        this.rows
      );
      if (result && result !== null) {
        if (result.recordCount) {
          this.creditChecksTotalRecords = result.recordCount;
          if (result.data) this.CreditChecksData.values = result.data;
        } else {
          this.noCreditChecksFound();
        }
      } else {
        this.noCreditChecksFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noCreditChecksFound();
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  private createAgentDto(updateDto: UpdateAgentModel) {
    updateDto.debtLevelId = +this.debtLevel;
    updateDto.id = this.agentId;
    updateDto.isHiddenAgentProfile = this.isHiddenAgentProfile;
    updateDto.eoParticipant = this.isEAndOPariticipant;
    updateDto.vested = this.vested;
    updateDto.eoParticipantExpirationDate = this.eoParticipantExpirationDate;
    updateDto.isIntegrityPlatformPartner = this.isIntegrityPlatformPartner;
    updateDto.excludeFromTrip = this.isExcludeFromTrip;
    updateDto.onlyPobox = this.onlyPoBox;
    updateDto.sureLC = this.sureLcAgent;
    updateDto.isLocation = this.isLocation;
    updateDto.marketer = this.isMarketer;
    updateDto.gender = this.gender;
    updateDto.terminatedOption = +this.renewalOption;
    updateDto.healthRating = this.selectedHealthRating;
    updateDto.healthRatingLocked = this.isHealthRatingLock;
    updateDto.ltcrating = this.ltcRating;
    updateDto.lifeRating = this.lifeRating;
    updateDto.annuityRating = this.annuityRating;
    updateDto.finalExpenseRating = this.feRating;
    this.setBankAccountInfo(updateDto);
  }

  private createAgencyDto(agencyDto: UpdateAgentModel) {
    agencyDto.debtLevelId = +this.debtLevel;
    agencyDto.id = this.agentId;
    agencyDto.isHiddenAgentProfile = this.isHiddenAgentProfile;
    agencyDto.excludeFromTrip = this.isExcludeFromTrip;
    agencyDto.onlyPobox = this.onlyPoBox;
    agencyDto.sureLC = this.sureLcAgent;
    agencyDto.isLocation = this.isLocation;
    agencyDto.marketer = this.isMarketer;
    agencyDto.terminatedOption = +this.renewalOption;
    agencyDto.healthRating = this.selectedHealthRating;
    agencyDto.healthRatingLocked = this.isHealthRatingLock;
    agencyDto.ltcrating = this.ltcRating;
    agencyDto.lifeRating = this.lifeRating;
    agencyDto.annuityRating = this.annuityRating;
    agencyDto.finalExpenseRating = this.feRating;
    this.setBankAccountInfo(agencyDto);
  }
  setBankAccountInfo(dto: UpdateAgentModel) {
    var bankAccountModel: BankAccountDetailModel = {
      accountNumber: this.accountNumber,
      bankAccountPaymentMethodId: this.paymentMethod ? +this.paymentMethod : 3,
      bankAccountTypeId:
        this.bankAccountType && this.bankAccountType != "0"
          ? +this.bankAccountType
          : undefined,
      bankId: this.bankId ? +this.bankId : undefined,
      bankAccountId: this.bankAccountId,
    };
    dto.bankAccountModel = bankAccountModel;
  }
  @action resetAll() {
    this.enableSMS = false;
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.nickName = "";
    this.dba = "";
    this.agencyName = "";
    this.website = "";
    this.ffmId = "";
    this.ssn = "";
    this.validateSSN = "";
    this.npn = "";
    this.leadSource = "0";
    this.gender = "";
    this.dateOfBirth = undefined;
    this.ltcRating = "";
    this.isHiddenAgentProfile = false;
    this.isIntegrityPlatformPartner = false;
    this.isEAndOPariticipant = false;
    this.vested = false;
    this.isMarketer = false;
    this.onlyPoBox = false;
    this.sureLcAgent = false;
    this.isLocation = false;
    this.isExcludeFromTrip = false;
    this.annuityRating = "";
    this.feRating = "";
    this.isHealthRatingLock = false;
    this.setHealthRating("");
    this.lifeRating = "";
    this.leadSourceList = [];
    this.isCustomAttrActive = false;
    PageContext.isCustomAttribute = false;
    this.isLoadAddressCall = true;
  }

  noCreditChecksFound() {
    this.creditChecksTotalRecords = 0;
    this.CreditChecksData.values = [];
  }

  @action getPrincipalAgents = async (startIndex: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await ProducerStore.getPrincipalAgents(
        this.agentId,
        startIndex,
        this.rows
      );
      if (result && result !== null) {
        if (result.recordCount) {
          this.principalAgentsTotalRecords = result.recordCount;
          if (result.data) this.PrincipalAgentsData.values = result.data;
        } else {
          this.noPrincipalAgentsFound();
        }
      } else {
        this.noPrincipalAgentsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noPrincipalAgentsFound();
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  noPrincipalAgentsFound() {
    this.principalAgentsTotalRecords = 0;
    this.PrincipalAgentsData.values = [];
  }
  @action getContacts = async (startIndex: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      this.noContactsFound();
      var result = await ProducerStore.getContacts(
        this.agentId,
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sortOrder,
        ""
      );
      if (result && result !== null) {
        if (result.recordCount) {
          this.contactOptionsTotalRecords = result.recordCount;
          if (result.data) this.ContactsData.values = result.data;
          this.showPhoneManageSubscriptions = result.showPhoneTab || false;
          this.showEmailManageSubscriptions = result.showEmailTab || false;
        } else {
          this.noContactsFound();
        }
      } else {
        this.noContactsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noContactsFound();
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  noContactsFound() {
    this.contactOptionsTotalRecords = 0;
    this.ContactsData.values = [];
    this.showPhoneManageSubscriptions = false;
    this.showEmailManageSubscriptions = false;
  }

  @action getAgencies = async (startIndex: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await ProducerStore.getAgencies(
        this.agentId,
        startIndex,
        this.rows
      );
      if (result && result !== null) {
        if (result.recordCount) {
          this.agenciesTotalRecords = result.recordCount;
          if (result.data) this.AgenciesData.values = result.data;
        } else {
          this.noAgenciesFound();
        }
      } else {
        this.noAgenciesFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noAgenciesFound();
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  noAgenciesFound() {
    this.agenciesTotalRecords = 0;
    this.AgenciesData.values = [];
  }
  @action getPayees = async (startIndex: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await ProducerStore.getPayees(
        this.agentId,
        startIndex,
        this.rows
      );
      if (result && result !== null) {
        if (result.recordCount) {
          this.payeesTotalRecords = result.recordCount;
          if (result.data) this.PayeesData.values = result.data;
        } else {
          this.noPayeesFound();
        }
      } else {
        this.noPayeesFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noPayeesFound();
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  noPayeesFound() {
    this.payeesTotalRecords = 0;
    this.PayeesData.values = [];
  }
  @action addPrincipalAgent = async () => {
    this.isLoading = true;
    try {
      this.isException = false;
      var dto: CreatePrincipalModel = {
        agentId: this.agentId,
        principalAgentId: this.principalAgentId,
      };
      var result = await ProducerStore.addPrincipalAgent(this.agentId, dto);
      await this.getPrincipalAgents(0);
      this.setPrincipalAgent("");
      this.setPrincipalAgentId(0);
      this.getPrincipalAgents(0);
      this.setAddPrincipalAgentVisibility(false);
      this.isLoading = false;
      return result;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      return 0;
    }
  };

  @observable selectedPayeeAgentId: string = "";

  @action onRowSelect = async (data: any) => {
    this.selectedPayeeAgentId = data.payeeAgentId;
  };

  @action deletePayee = async () => {
    try {
      await ProducerStore.deletePayee(this.agentId, +this.selectedPayeeAgentId);
      setTimeout(async () => {
        await this.getPayees(0);
        this.setDeletePayeeConfirmation(false);
      }, Utils.timeDelay_Delete());
      this.payee = "";
    } catch (e) {
      this.setDeletePayeeConfirmation(false);
    }
  };
  @action addPayee = async () => {
    try {
      await ProducerStore.addPayee(this.agentId, this.payeeAgentId);
      setTimeout(async () => {
        this.payeeAgentId = 0;
        this.payee = "";
        await this.getPayees(0);
      }, Utils.timeDelay_Success());
    } catch (e) { }
  };

  @action setSMS = async (value: boolean) => {
    this.enableSMS = value;
  };

  @action addContact = async () => {
    this.isContactException = false;
    // var valid = await this.ValidateContact()
    this.setIsContactSuccess(false);
    this.isNotValidContact = false;
    if (!(await this.ValidateContact())) {
      await this.validateContactValue();
      if (this.isNotValidContact) return true;
      this.isLoading = true;
      this.isException = false;
      try {
        var dto: CreateUpdateContactModel = {
          contactTypeId: +this.contactType,
          agentId: this.agentId,
          contactValue:
            this.contactType === "3"
              ? this.contactValue.replace(/[^\d]/g, "")
              : this.contactValue,
          description: this.contactDescription,
          isDefault: (this.commissionSelectedPreferenceDefault || []).includes(
            "0"
          ),
          isCommission: (
            this.commissionSelectedPreferenceDefault || []
          ).includes("1"),
          dateCreated: new Date(),
          enableSMS:
            this.contactType === "3" && this.phoneType === PhoneTypeValue.Mobile
              ? this.enableSMS
              : undefined,
          phoneTypeId: this.phoneType,
          emailTypeID: this.emailType,
          alternativePointOfContactTypeID: this.alternativePOCType,
          //createdByUserId: 15
        };

        if (this.contactDialogTitle === "Add Contact")
          await ProducerStore.addContact(this.agentId, dto);
        else {
          dto.id = this.selectContactId;
          await ProducerStore.editContact(this.agentId, dto);
        }

        this.setAddContactDialogVisibility(false, false);
        await this.getContacts(0);
        this.commissionSelectedPreferenceDefault = [];
        this.isLoading = false;
      } catch (error: any) {
        this.isLoading = false;

        error.text().then((_responseText: any) => {
          this.contactResponse = _responseText;
          this.isContactException = true;
        });
        IMASLog.log("exception: " + error);
      }
    }
  };
  @action addCreditCheck = async () => {
    this.setIsCreditCheckSuccess(false);
    if (!(await this.AddEditCreditCheckViewModle.Validate())) {
      this.isLoading = true;
      this.isException = false;
      try {
        var dto: CreateUpdateCreditCheckModel = {
          inquiryDate: this.AddEditCreditCheckViewModle.inquiryDate,
          vendor: this.AddEditCreditCheckViewModle.vendor,
          result: this.AddEditCreditCheckViewModle.result === "2",
          negativeRating: this.AddEditCreditCheckViewModle.result === "2",
          agentId: this.agentId,
          creditCheckVendorId: +this.AddEditCreditCheckViewModle.vendor,
        };
        if (this.creditCheckDialogTitle === "Add Credit Check")
          await AccountStore.addCreditCheck(dto);
        else {
          dto.id = this.selectedCreditCheckId;
          await AccountStore.editCreditCheck(dto);
        }

        this.setCreditCheckDialogVisibility(false, false);

        await this.getCreditChecks(0);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isException = true;
        this.exceptionMessage = error.response;
      }
    }
  };

  @action checkDuplicateAgent = async (caller: string) => {
    if (this.isEdit) {
      if (caller == "firstname" && this.firstName == this.oldfirstname) return;
      if (caller == "lastname" && this.lastName == this.oldlastname) return;
      if (caller == "ssn" && this.ssn == this.oldssn) return;
      if (caller == "npn" && this.npn == this.oldnpn) return;
    }
    // if(this.isEdit) // commented as per PII SSN task
    //  return
    if (this.isAgency) {
      this.checkDuplicateAgency(caller);
      return;
    }
    this.firstName = this.firstName ? this.firstName.trim() : this.firstName;
    this.lastName = this.lastName.trim();
    if (this.firstName && this.lastName) {
      try {
        var ssn = "";
        if (this.ssn) {
          ssn = this.ssn.replace(/-/g, "").trim();
        }
        this.isLoading = true;
        this.isException = false;
        this.setIsDuplicate(false);
        this.firstName = this.firstName.trim();
        this.lastName = this.lastName.trim();
        var result = await ProducerStore.checkAgentDuplicate(
          "" + this.firstName,
          "" + this.lastName,
          ssn,
          !this.isEditNPN ? this.npn : "",
          this.isAgency,
          this.tin,
          this.agentId
        );
        this.isLoading = false;
        if (result.isDuplicate) {
          this.setIsDuplicate(true);
          this.duplicateAgentId = result.existingAgentId
            ? result.existingAgentId
            : 0;
          this.duplicateError = "" + result.error;
        } else {
          this.setIsDuplicate(false);
        }
      } catch (e) {
        this.isLoading = false;
        this.isException = true;
        this.exceptionMessage = e.response;
      }
    }
  };
  @action checkDuplicateAgency = async (caller: string) => {
    this.agencyName = this.agencyName.trim();
    //if (this.isEdit) { //Commented this due to check the duplication entry
    //    return
    //}
    if (this.isEdit) {
      if (caller == "agencyname" && this.agencyName == this.oldagencyName)
        return;
      if (caller == "tin" && this.tin == this.oldtin) return;
    }
    if (this.agencyName) {
      try {
        this.isLoading = true;
        var tin = "";
        if (this.tin) {
          tin = this.tin.replace(/-/g, "").trim();
        }
        this.isException = false;
        this.setIsDuplicate(false);
        var result = await ProducerStore.checkAgentDuplicate(
          "firsname",
          "" + this.agencyName,
          this.ssn,
          !this.isEditNPN ? this.npn : "",
          this.isAgency,
          tin,
          this.agentId
        );
        this.isLoading = false;
        if (result.isDuplicate) {
          this.setIsDuplicate(true);
          this.duplicateAgentId = result.existingAgentId
            ? result.existingAgentId
            : 0;
          this.duplicateError = "" + result.error;
        } else {
          this.setIsDuplicate(false);
        }
      } catch (e) {
        this.isLoading = false;
        this.isException = true;
        this.exceptionMessage = e.response;
      }
    }
  };

  @action getContactDetails = async () => {
    try {
      return await ProducerStore.getContactDetails(
        this.selectContactId,
        this.agentId
      );
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  @action getCreditCheckDetails = async () => {
    try {
      return await AccountStore.getCreditCheckDetails(
        this.selectedCreditCheckId
      );
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      return undefined;
    }
  };
  @action getAddressDetails = async () => {
    try {
      this.isException = false;
      return await ProducerStore.getAddressDetails(
        this.selectedAddressId,
        this.agentId
      );
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @observable isContactException: boolean = false;
  @observable contactResponse: any = "";

  @action setAddressAS = async (addressType: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await ProducerStore.default(
        this.selectedAddressId,
        addressType,
        this.agentId
      );
      setTimeout(async () => {
        await this.loadAddresses(0);
        this.isLoading = false;
        return result;
      }, 700);
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action setContactDefault = async () => {
    if (this.contactDefault && this.contactDefault === true) {
      await this.unSetContactAsDefault("default");
    } else {
      await this.setContactAsDefault();
    }
    this.selectedRow = undefined;
  };

  @action setContactCommission = async () => {
    await this.setContactAsCommission();
    this.selectedRow = undefined;
  };
  @action setContactAsDefault = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      await ProducerStore.defaultContact(
        this.selectContactId,
        this.agentId,
        this.selectContactTypeId
      );
      setTimeout(async () => {
        await this.getContacts(0);
        this.isLoading = false;
      }, 700);
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  @action setContactAsCommission = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      await ProducerStore.commissionContact(
        this.selectContactId,
        this.agentId,
        this.selectContactTypeId
      );
      setTimeout(async () => {
        await this.getContacts(0);
        this.isLoading = false;
      }, 700);
      // return result
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
  @action unSetContactAsDefault = async (type: string) => {
    if (type === "default") {
      try {
        await ProducerStore.undefaultContact(
          this.selectContactId,
          this.agentId,
          this.getContactTypeId(this.contactType ? this.contactType : "")
        );
        setTimeout(async () => {
          await this.getContacts(0);
          this.isLoading = false;
        }, 700);
      } catch (error) {
        this.isLoading = false;
      }
    } else {
      try {
        this.isLoading = true;
        this.isException = false;
        var details = await this.getContactDetails();
        if (details) {
          var contact: CreateUpdateContactModel = {
            id: this.selectContactId,
            description: details.description,
            contactValue: details.contactValue,
            contactTypeId: details.contactTypeId || 0,
            agentId: this.agentId,

            //createdByUserId: 15
          };

          await ProducerStore.editContact(this.agentId, contact);
          setTimeout(async () => {
            await this.getContacts(0);
            this.isLoading = false;
          }, 700);
        }
      } catch (error) {
        this.isLoading = false;
        this.isException = true;
        this.exceptionMessage = error.response;
      }
    }
  };
  @action unSetAddressAS = async (addressType: number) => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await this.getAddressDetails();
      if (result) {
        switch (addressType) {
          case 0:
            result.isHome = false;
            break;
          case 1:
            result.isCommission = false;
            break;
          case 2:
            result.isDefault = false;
            break;
        }
        await ProducerStore.editAddresses(this.agentId, result);
        setTimeout(async () => {
          await this.loadAddresses(0);
          this.isLoading = false;
        }, 700);
      }
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  getContactTypeId = (value: string) => {
    switch (value) {
      case "Email":
        return 1;
      case "Text Message":
        return 2;
      case "Phone Number":
        return 3;
      case "Alternative Point of Contact":
        return 4;
      default:
        return 3;
    }
  };
  @action addEditAddress = async () => {
    this.setIsAddAddressSuccess(false);

    this.isLoading = true;
    var agentaddress = this.AddEditAddressViewModel.addressName;

    if (!this.AddEditAddressViewModel.addressName) {
      let addrType = this.AddEditAddressViewModel.addressNameList.values.find(
        (p) => p.value == this.AddEditAddressViewModel.addressType
      );
      if (addrType != null) {
        agentaddress = addrType.label;
      }
    }

    var validAddressModel = this.AddEditAddressViewModel.validAddressModel;

    this.isException = false;
    var adrName =
      this.AddEditAddressViewModel.addressName === "Custom"
        ? this.AddEditAddressViewModel.customAddrName
        : agentaddress;
    var model: CreateUpdateAddressModel = {
      address: this.AddEditAddressViewModel.address,
      addressName: adrName,
      bussinessName: this.AddEditAddressViewModel.businessName,
      attn: this.AddEditAddressViewModel.attn,
      addressNote: "",
      isVisibleInDownline: this.AddEditAddressViewModel.isVisibleInDownline,
      agentId: this.agentId,
      isHome: this.AddEditAddressViewModel.isResidential,
      isDefault: this.AddEditAddressViewModel.isDefault,
      isCommission: this.AddEditAddressViewModel.isCommission,
      addressType: +this.AddEditAddressViewModel.addressType,
      plusFourCode: validAddressModel && validAddressModel.plusFourCode,
    };
    if (
      this.AddEditAddressViewModel.showOverrideAddress &&
      Utils.hasUserPermission(Permission.OverrideAddressValidation)
    ) {
      model.stateAbbreviation = this.AddEditAddressViewModel.state;
      model.zipCode = this.AddEditAddressViewModel.zip;
      model.city = this.AddEditAddressViewModel.city;
      model.countyName = this.AddEditAddressViewModel.county;
      model.addressLine1 = this.AddEditAddressViewModel.addressLine;
      model.isValid = false;
    } else if (validAddressModel) {
      model.countyName = validAddressModel.countyName;
      model.stateAbbreviation = validAddressModel.stateAbbreviation;
      model.addressLine1 = validAddressModel.street;
      model.zipCode = validAddressModel.zipCode;
      model.lattitude = validAddressModel.latitude;
      model.longitude = validAddressModel.longitude;
      model.city = validAddressModel.city;
      model.addressComments = validAddressModel.addressComments;
      model.dpvFootnotes = validAddressModel.dpvFootnotes;
      model.dpvMatchCode = validAddressModel.dpvMatchCode;
      model.suitelinkMatch = validAddressModel.suitelinkMatch;
      model.lacslinkCode = validAddressModel.lacslinkCode;
      model.lacslinkIndicator = validAddressModel.lacslinkIndicator;
      model.footnotes = validAddressModel.footnotes;
      model.ewsMatch = validAddressModel.ewsMatch;
      model.active = validAddressModel.active;
      model.dpvVacant = validAddressModel.dpvVacant;
      model.dpvCmra = validAddressModel.dpvCmra;
      model.location = validAddressModel.location;
      model.precision = validAddressModel.precision;
      model.elotSort = validAddressModel.elotSort;
      model.elotSequence = validAddressModel.elotSequence;
      model.rdi = validAddressModel.rdi;
      model.buildingDefaultIndicator =
        validAddressModel.buildingDefaultIndicator;
      model.congressionalDistrict = validAddressModel.congressionalDistrict;
      model.carrierRoute = validAddressModel.carrierRoute;
      model.countyFips = validAddressModel.countyFips;
      model.zipType = validAddressModel.zipType;
      model.recordType = validAddressModel.recordType;
      model.deliveryPointBarcode = validAddressModel.deliveryPointBarcode;
      model.deliveryPoint = validAddressModel.deliveryPoint;
      model.deliveryPointCheckDigit =
        validAddressModel.deliveryPointCheckDigit;
      model.plusFourCode = validAddressModel.plusFourCode;
      model.defaultCityName = validAddressModel.defaultCityName;
      model.cityName = validAddressModel.cityName;
      model.secondaryDesignator = validAddressModel.secondaryDesignator;
      model.secondaryNumber = validAddressModel.secondaryNumber;
      model.streetSuffix = validAddressModel.streetSuffix;
      model.streetPostdirection = validAddressModel.streetPostdirection;
      model.streetPredirection = validAddressModel.streetPredirection;
      model.streetName = validAddressModel.streetName;
      model.primaryNumber = validAddressModel.primaryNumber;
      model.firmName = validAddressModel.firmName;
      model.isValid = true;
    }

    if (this.addrDialogTitle === "Add Address")
      await ProducerStore.addAddresses(this.agentId, model);
    else {
      model.id = this.AddEditAddressViewModel.id;
      await ProducerStore.editAddresses(this.agentId, model);
    }

    await this.loadAddresses(0);

  };

  @action getmaxDate = (dt: Date, n: number) => {
    return new Date();
  };
  @action setAddPrincipalAgentVisibility = async (value: boolean) => {
    if (!value) {
      this.setPrincipalAgent("");
      this.setPrincipalAgentId(0);
    }
    this.isAddPrincipalAgentVisible = value;
  };

  // w9

  @observable taxPayerNumberList = [
    { label: "Social Security Number", value: "199" },
    { label: "Employer Identification Number", value: "299" },
  ];

  @observable federalTaxType: number | undefined = 1;
  @observable federalTaxNumber: number | undefined = 199;
  @observable W9TableRadio: any | undefined;
  @observable exceptionMsg: any = "";
  @observable addressType: string = "2";
  @observable w9Name: any;
  @observable enableW9Save: boolean = false;
  @observable w9BusinessName: any;
  @observable addressTypeList = new Collection<any>();
  @observable stateList = new Collection<any>();
  @observable w9addressName: any;
  @observable customAddrName: string = "";
  @observable isCustomValidation: boolean = false;
  @observable w9addressLine: any;
  @observable w9city: any;
  @observable w9county: any;
  @observable w9state: any = "";
  @observable w9zip: any;
  @observable addressId: number = 0;
  @observable disableSave: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable addAddressDto: any | undefined = [];
  @observable handleExistingAddressView: boolean = false;
  @observable isLoadAddressCall: boolean = true;
  @observable validZipError: string = "";
  @observable FilteredDocumentList = new Collection<any>();
  @observable documentList = new Collection<any>();
  @observable selectedDocument: string = "";
  @observable w9ModifiedBy: string = "";
  @observable w9ModifiedtimeStamp: string = "";
  @observable selectedDocumentId: number = 0;
  @observable w9taxpayerId: any;
  @observable w9DocId: any;
  @observable isW9DiscardModal: boolean | undefined = false;
  @observable isSaveW9: Boolean = false;
  @observable isEditW9: Boolean = true;
  @observable isW9AttachList: Boolean = true;
  @observable w9WholeData: any;

  @action w9DiscardModal = (value: boolean) => {
    this.isW9DiscardModal = value;
  };

  @action w9ExitEdit = () => {
    this.resetW9Form();
    this.getAgentW9Information();
  };
  @action getAgentW9Information = async () => {
    var result = await ProducerStore.getAgentW9Information(this.agentId);
    if (result) {
      (this.w9ModifiedtimeStamp =
        result.lastModifiedDate &&
        Utils.getDateInFormat(result.lastModifiedDate)),
        (this.w9ModifiedBy = result.lastModifiedBy),
        (this.w9taxpayerId = result.taxpayerId || 0);
      this.federalTaxClassifications = result.federalTaxClassifications;
      this.selectedW9TableRecordID = result.w9AddressID || 0;
      this.w9Name = result.taxpayerName || "";
      this.w9BusinessName = result.taxpayerBusinessName;
      this.federalTaxType = result.federalTaxClassificationId || 1;
      this.w9addressLine = result.address && result.address.addressLine1;
      this.w9county = result.address && result.address.addressLine2;
      this.w9state = result.address && result.address.stateAbbreviation;
      this.w9city = result.address && result.address.cityName;
      this.w9zip = result.address && result.address.zipCode;
      this.documentList.values = result.documentAttachmentList || [];
      this.isW9AttachList =
        ((result.documentAttachmentList && result.documentAttachmentList) || [])
          .length > 0
          ? true
          : false;
      this.setW9TableRadio(result.w9AddressID || 0);
      if (result.federalTaxClassifications) {
        this.federalTaxClassifications = result.federalTaxClassifications;
        this.federalTaxType = result.federalTaxClassificationId || undefined;
      }
      if (
        result &&
        result.federalTaxClassifications &&
        result.federalTaxClassificationId == null
      ) {
        this.federalTaxType = result.federalTaxClassifications[0].value;
      }

      if (result.ein !== null) {
        this.w9einTemp = result.ein;
        this.federalTaxNumber = 299;
        this.contactValueW9 = result.ein;
      } else if (result.w9SSN !== null) {
        this.w9ssnTemp = result.w9SSN;
        this.federalTaxNumber = 199;
        this.contactValueW9 = result.w9SSN;
      }
      if (this.isW9DiscardModal) {
        setTimeout(() => {
          this.isEditW9 = false;
          this.isW9DiscardModal = false;
        }, 300);
      }
    }
  };

  @action filterDocuments = async (value: string) => {
    let result = await DocumentStore.getAgentDocuments(
      this.agentId || 0,
      0,
      0,
      this.selectedDocument,
      0,
      100,
      "id",
      false,
      null
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result && result.recordCount && result.data) {
      let self = this;
      result.data =
        self.documentList.values &&
        result.data.filter((i) => {
          return (
            self.documentList.values.find(
              (j) =>
                j.documentName == i.documentName &&
                j.fileType == i.fileType &&
                !j.isDeleted
            ) == null
          );
        });
      result.recordCount = (result.data && result.data.length) || 0;
    }

    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          result.data.splice(0, 0, { id: -1 });
          this.FilteredDocumentList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              //element.scrollTo(0, 0);
            }
          }, 10);
        }
      } else {
        this.FilteredDocumentList.values = [noAgent];
        this.selectedDocument = "";
      }
    }
  };

  @action setSelectedDocument(value: any) {
    this.selectedDocument = value;
  }

  @action setW9Name = (value: string) => {
    if (value.length == 1 && value == " ") {
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        return false;
      } else {
        this.w9Name = value;
        return true;
      }
    }
  };
  @action setW9BusinessName = (value: string) => {
    this.w9BusinessName = value;
  };

  @action setW9AddressLine = (value: string) => {
    if (value.length == 1 && value == " ") {
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        return false;
      } else {
        this.w9addressLine = value;
        return true;
      }
    }
  };

  @action setW9City = (value: string) => {
    if (value.length == 1 && value == " ") {
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        return false;
      } else {
        this.w9city = value.trim();
        return true;
      }
    }
  };

  @action setW9County = (value: string) => {
    this.w9county = value;
  };

  @action setW9State = (value: string) => {
    this.w9state = value.trim();
  };

  @action checkZip = () => {
    if (this.w9zip.length < 100) {
    } else {
      this.validZipError = "";
    }
  };

  @action setW9Zip = (value: string, number: string) => {
    this.w9zip = value.replace(/\D/g, "");
  };

  @action loadStates(data: any) {
    data.forEach((obj: any) => {
      if (obj.stateId !== -1) {
        var data = {
          label: obj.stateName ? obj.stateName : "",
          value: obj.abbreviation ? obj.abbreviation : "",
        };
        this.stateList.values.push(data);
      }
    });
  }

  @action AddExistingAddress = () => {
    if (this.selectedW9AddressId) {
      this.w9addressLine = this.selectedW9AddressId.line1;
      this.w9city = this.selectedW9AddressId.city;
      this.w9county = this.selectedW9AddressId.county;
      this.w9state = this.selectedW9AddressId.state;
      this.w9zip = this.selectedW9AddressId.zip;
      this.handleExistingAddressView = false;
    }
  };

  @action getW9AddressStates = async () => {
    this.stateList.values = [];
    try {
      let response = await ContractsStore.appointmentStates();
      if (response) {
        this.loadStates(response);
      }
      this.getAgentW9Information();
    } catch (e) {
      this.stateList.values = [];
    }
  };

  @action setContactValueBlur = async (value: string) => {
    this.isNotValidContact = false;
    if (!(await this.ValidateContact()) && value !== null && value.length > 0) {
      await this.validateContactValue();
      if (this.isNotValidContact) {
        return true;
      }
      try {
      } catch (error) {
        PageContext.setIsMessageVisible(false);
        this.isLoading = false;
        setTimeout(() => {
          PageContext.setIsMessageVisible(false);
          let message = JSON.parse(PageContext.responseMessage);
          if (message && message.status === 400) {
            this.response = PageContext.responseMessage;
            this.isMessgeVisible = true;
            this.disableSave = true;
          }
        }, 100);
        IMASLog.log("exception: " + error);
      }
    }
  };

  @action getPhoneNumber = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "", match[2], " ", match[3], " ", match[4]].join("");
    }
    return phoneNumber;
  };

  @action setW9ContactValue(value: string) {
    this.contactValueW9 = this.getPhoneNumber("" + value);
  }

  @action downloadDocumentLink = async (docId: number) => {
    this.isLoading = true;
    this.isException = false;
    this.exceptionMessage = {};
    try {
      var result = await DocumentStore.getDocumentLink(docId, 0);
      if (result != null) {
        if (result.pdfData) {
          Utils.downloadDocument(
            result.pdfData,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : "document.pdf",
            result.actualFileName ? result.actualFileName : ""
          );
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.exceptionMessage = JSON.stringify(responseModel);
            this.isException = true;
          }
        }
      }
    } catch (e) {
      this.isException = true;
      this.exceptionMessage = e.response;
    }
    this.isLoading = false;
  };

  @action deleteDocument(rowId: any, mappingId: any) {
    let document: any;

    if (rowId && rowId > 0 && mappingId == 0) {
      this.documentList.values = this.documentList.values.filter((item) => {
        return item.rowId != rowId;
      });
    } else if (mappingId && mappingId > 0) {
      document = this.documentList.values.filter((item) => {
        return item.mappingId == mappingId;
      });

      if (document && document.length > 0) {
        document[0].isDeleted = true;
      }
    }
    if (this.documentList.values) {
      let temp = this.documentList.values.map((itm, idx) => {
        return itm.isDeleted == true;
      });
      this.isW9AttachList = !temp.every(Boolean);
    }
  }

  @action HandleAddExistingVisibility = (value: boolean) => {
    this.handleExistingAddressView = value;
    if (value && this.isLoadAddressCall) {
      this.loadAddresses(0);
      this.isLoadAddressCall = false;
    }
  };
  @action setW9federalTax = async (value: string) => {
    this.federalTaxType = parseInt(value);
  };
  @action setW9TaxNumber = async (value: string) => {
    this.federalTaxNumber = parseInt(value);
    this.contactValueW9 = "";
    this.contactValueW9 =
      this.federalTaxNumber == 299 ? this.w9einTemp : this.w9ssnTemp || "";
  };
  @action setW9TableRadio = async (value: number) => {
    this.W9TableRadio = value;
    this.selectedW9TableRecordID = value;
  };
  @action setSelectedW9AddressId(value: number | undefined) {
    this.selectedW9AddressId = value ? value : {};
  }

  @action validateW9SaveForm = () => {
    if (
      this.w9Name &&
      this.federalTaxType &&
      this.w9addressLine &&
      this.w9city &&
      this.w9state &&
      this.w9zip &&
      this.contactValueW9
    ) {
      this.enableW9Save = true;
    } else {
      this.enableW9Save = false;
    }
  };

  @action resetW9Form = () => {
    if (!this.isW9DiscardModal) {
      this.federalTaxType = 1;
      this.federalTaxNumber = 199;
      this.W9TableRadio = 1;
      this.exceptionMsg = "";
      this.addressType = "2";
      this.w9Name = "";
      this.w9BusinessName = "";
      this.w9addressName = "";
      this.customAddrName = "";
      this.w9addressLine = "";
      this.w9city = "";
      this.w9county = "";
      this.w9state = "";
      this.w9zip = "";
      this.addressId = 0;
      this.documentList.values = [];
      this.selectedDocument = "";
      this.selectedDocumentId = 0;
      this.w9taxpayerId = 0;
      this.contactValueW9 = "";
      this.w9ssnTemp = "";
      this.w9einTemp = "";
      this.isW9DiscardModal = false;
      this.isSaveW9 = false;
      // this.isEditW9 = false;
    }
  };
  @action updateW9Form = async () => {
    let ssnValue;
    if (this.selectedKey === AgentDetails.W9) {
      ssnValue = this.contactValueW9.replace(/-/g, "").trim();
    }
    let reqData: UpdateAgentW9InformationRequest = {
      taxPayerId: this.w9taxpayerId || 0,
      taxpayerName: this.w9Name,
      taxpayerBusinessName: this.w9BusinessName,
      federalTaxClassificationId: this.federalTaxType,
      address: {
        id: this.selectedW9TableRecordID || 0,
        addressLine1: this.w9addressLine,
        addressLine2: this.w9county,
        zipCode: this.w9zip,
        cityName: this.w9city,
        stateAbbreviation: this.w9state,
      },
      w9AddressID: this.selectedW9TableRecordID || 0,
      ein: this.federalTaxNumber == 299 ? this.contactValueW9 : null,
      documentAttachmentList: this.documentList.values || [],
      w9SSN:
        this.federalTaxNumber == 199
          ? (ssnValue || "").replace(/ /g, "")
          : null,
      agentId: this.agentId,
    };
    var result = await ProducerStore.updateAgentW9Information(reqData);
    this.isEditW9 = false;
    // this.resetW9Form();
    // this.gotoDetails();
  };

  // custom attribute methods
  @action handleAttributesModal = (value: boolean) => {
    this.CustomAttributesComponentViewModel.disableRadioInitial = true;
    this.addAttributesModal = value;
    setTimeout(async () => {
      value &&
        this.CustomAttributesComponentViewModel.loadExistingAttributes(6);
      !value && this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  };

  @action AttributeSave = (type: number) => {
    let data = this.CustomAttributesComponentViewModel.saveAttribute(type);
    this.addAttributesModal = false;

    setTimeout(async () => {
      var attributes = await this.getCustomAttributesByScreen(6);
      if (attributes) {
        this.customAttributeList = attributes;
      }
    }, 900);
    this.CustomAttributesComponentViewModel.resetAttributes();
  };

  protected async checkPermissions(): Promise<void> {
    localStorage.removeItem("CustomAttribute");
    // get Custom attribute screen permission
    var res = await this.getCustomAttributePermissionByScreen();
    if (res) {
      let data = res.filter(
        (x) =>
          x.path === Permission.EditAgentCustomAttribute && x.active === true
      );
      this.isCustomAttrActive = data.length > 0 ? true : false;

      var attributes = await this.getCustomAttributesByScreen(data[0].id || 6);
      if (attributes) {
        this.customAttributeList = attributes;
      }
      this.attributesScreenPathId = data[0].id || 6;
    }
  }

  @action async getCustomAttributePermissionByScreen() {
    try {
      var res = await CustomAttributeStore.getAllScreens();
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(
        screenId,
        this.agentId
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action public validateCustomAttributes = async () => {
    PageContext.isCustomAttribute = false;
    this.response = "";
    var attrData = localStorage.getItem("CustomAttribute");
    var result = [];
    this.isValid = true;
    if (attrData) {
      result = JSON.parse(attrData);
      var mandatoryCustomAttributeData = result.filter(
        (x: any) =>
          x.isMandatory == true &&
          (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined)
      );
      if (
        mandatoryCustomAttributeData != null &&
        mandatoryCustomAttributeData.length > 0 &&
        (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
          Utils.hasUserPermission(Permission.PublicCustomAttribute))
      ) {
        PageContext.isCustomAttribute = true;
        this.isValid = false;
        window.scrollTo(0, 0);
        var responseModel = {
          status: 400,
          title: Constants.CustomAttributeValidationMessage,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
      }
    }
  };

  @action createUpdateAttributes = async () => {
    let data: any = [];
    (this.customAttributeList || []).map((item: any) => {
      item.attrValue =
        item.dataTypeId == 6
          ? item.attrValue
            ? Utils.getDateInFormat(new Date(item.attrValue))
            : ""
          : item.attrValue;
      data.push({
        customAttributeScreenId: item.scrnCustAttrId,
        entityId: this.agentId,
        value: item.attrValue + "",
      });
    });

    return data;
  };
}

class AddAgentValidator extends AbstractValidator<EditAgentViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.firstName)
      .isNotEmpty()
      .withFailureMessage("First Name is required");
    this.validateIfString((input) => input.lastName)
      .isNotEmpty()
      .withFailureMessage("Last Name is required");
    this.validateIfString((input) => input.leadSource)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Lead Source is required");

    this.validateIfString((input) => input.validateSSN)
      .hasMinLength(9)
      .whenNotEmpty()
      .when((input) => input.isViewSSN)
      .withFailureMessage("Please enter 9 numerals for SSN");


    this.validateIfString((input) => input.vestedPercentage)
      .isNotNull()
      .isNotEmpty()
      .when(
        (input) =>
          input.vested == true
      )
      .withFailureMessage("Percentage field must be entered");

    this.validateIfString((input) => input.selectedUpline)
      .isNotEmpty()
      .isNotEqualTo("0")
      .when(
        (input) =>
          input.vested == true && input.vestedPercentage != "100"
      )
      .withFailureMessage("Contract upline must be selected");
  }

}
class ContactValidatory extends AbstractValidator<EditAgentViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.contactType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .isNotEqualTo("None")
      .isNotEqualTo("NONE")
      .withFailureMessage("Type is required");
    this.validateIfString((input) => input.contactValue)
      .isNotEmpty()
      .withFailureMessage("Value is required");
  }
}
class AddAgencyValidator extends AbstractValidator<EditAgentViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.agencyName)
      .isNotEmpty()
      .withFailureMessage("Agency Name is required");
  }
}
class AddAgentOrAgencyValidator extends AbstractValidator<EditAgentViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.paymentMethod)
      .isNotEmpty()
      .when(
        (input) =>
          Utils.hasUserPermission(Permission.ViewBankAccount) &&
          Utils.hasUserPermission(Permission.EditBankAccount)
      )
      .withFailureMessage("Payment Method is required");

    this.validateIfNumber((input) => input.bankId)
      .isNotEqualTo(0)
      .when(
        (input) =>
          input.paymentMethod == "2" &&
          Utils.hasUserPermission(Permission.ViewBankAccount) &&
          Utils.hasUserPermission(Permission.EditBankAccount)
      )
      .withFailureMessage("Bank is required");

    this.validateIfString((input) => input.accountNumber)
      .isNotEmpty()
      .when(
        (input) =>
          input.paymentMethod == "2" &&
          Utils.hasUserPermission(Permission.ViewBankAccount) &&
          Utils.hasUserPermission(Permission.EditBankAccount)
      )
      .withFailureMessage("Account Number is required");


    this.validateIfString((input) => input.bankAccountType)
      .isNotEmpty()
      .when(
        (input) =>
          input.paymentMethod == "2" &&
          Utils.hasUserPermission(Permission.ViewBankAccount) &&
          Utils.hasUserPermission(Permission.EditBankAccount)
      )
      .withFailureMessage("Bank Account Type is required");

    this.validateIfString((input) => input.bankAccountType)
      .isNotEqualTo("0")
      .when(
        (input) =>
          input.paymentMethod == "2" &&
          Utils.hasUserPermission(Permission.ViewBankAccount) &&
          Utils.hasUserPermission(Permission.EditBankAccount)
      )
      .withFailureMessage("Select a Bank Account Type.");

      this.validateIfString((input) => input.vestedPercentage)
      .isNotNull()
      .isNotEmpty()
      .when(
        (input) =>
          input.vested == true
      )
      .withFailureMessage("Percentage field must be entered");

    this.validateIfString((input) => input.selectedUpline)
      .isNotEmpty()
      .isNotEqualTo("0")
      .when(
        (input) =>
          input.vested == true && input.vestedPercentage != "100"
      )
      .withFailureMessage("Contract upline must be selected");
  }
}
