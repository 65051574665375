import { observable, action } from "mobx";
import Collection from "../../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../../infrastructure/RouteList";
import Utils from "../../../../../infrastructure/Utils";
import ViewModel from "../../../../../infrastructure/ViewModel";
import { ReportFile, SubmissionMethodList } from "../../../../../services/BusinessUnitConfigService";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";

interface itemListModel {
  type?: string | undefined;
  name?: string | undefined;
  priority?: number | undefined;
  id?: number | undefined;
}

export class SubmissionMethodViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  
  @action Load = async () => {
    await this.resetFilters()
    await this.lookup()
    await this.loadSubmissionMethods()
  };
  
  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable sortColumn: string = "id";
  @observable sortOrder: boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable selectedId: number = 0
  @observable ItemsList =  new Collection<SubmissionMethodList>()
  @observable submisisonMethodTypes = [
  {label: "All", value: "-1"},
  {label: "Agent", value: "0"},
  {label: "Insured", value: "1"}]
  @observable documentTypeId: string ="0";
  @observable documentTypes = new Collection<{
    label: string;
    value: string;
  }>();
  @observable carrierId: string ="0";
  @observable carriers = new Collection<{
    label: string;
    value: string;
  }>();
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();
  @observable submisisonMethodTypeId: string = '-1';
  @observable isDeleteConfirm: boolean = false

  @action goSearch = async() => {
    await this.loadSubmissionMethods()
  };

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.carriers.values = []; 
    this.showInactiveCarriers ? (this.carriers.values = this.carriersInitial.values) :
    this.carriers.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action lookup = async () => {
    var result = await BusinessUnitConfigStore.lookup()
    if(result) {
      if(result.documentTypes){
        this.documentTypes.values = this.mapListItemAndSort(result.documentTypes, '');
      }
      if(result.carriers){
        this.carriers.values = this.mapListItemAndSort(result.carriers, '');
        this.carriersInitial.values = this.carriers.values;
        this.carriers.values = Utils.carriersFilter(this.carriersInitial.values);
      }
    }
  }

  @action loadSubmissionMethods = async() => {
    var result = await BusinessUnitConfigStore.getAllSubmissionMethods(+this.submisisonMethodTypeId,
      +this.documentTypeId,
      +this.carrierId,
      this.startIndex,
      this.rows,
      this.sortColumn,
      this.sortOrder)
      if(result.data){
        this.totalRecords = result.recordCount || 0
        this.ItemsList.values = result.data || []
      }
  }

  @action resetFilters = async() => {
    this.submisisonMethodTypeId = "-1"
    this.documentTypeId = "0"
    this.carrierId = "0"
    this.startIndex = 0
    this.rows = 25
    this.sortColumn = 'id'
    this.sortOrder = false
    this.isDeleteConfirm = false
    this.showInactiveCarriers = false;
    this.carriers.values = Utils.carriersFilter(this.carriersInitial.values);
    await this.loadSubmissionMethods();
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadSubmissionMethods()
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action setSelectedId(id: number){
    this.selectedId = id
  }

  @action mapListItemAndSort(listObjects: any, actionItem: string) {
    if (listObjects) {
     listObjects.splice(0, 0, { text: "All "+actionItem, value: "0" , selected: false});
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });          
      return sortList;
    }
    else {
      return [];
    }
  }

  @action setDeleteSubmissionMethodConfirmation = (value: boolean) => {
    this.isDeleteConfirm = value;
  }

  @action deleteSubmissionMethod = async() => {
    this.setDeleteSubmissionMethodConfirmation(false)
    await BusinessUnitConfigStore.deleteSubmissionMethod(this.selectedId)
    await this.loadSubmissionMethods()
  }

  @action export = async() => {
    let result = await BusinessUnitConfigStore.exportSubmissionRuleset(+this.submisisonMethodTypeId, +this.carrierId, +this.documentTypeId)
    if(result){
      this.downloadReport(result, "export")
    }
  }

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
        await fetch(`data:${result.contentType};base64,${result.data}`)
            .then((response) => response.blob())
            .then(function (myBlob) {
                var link = document.createElement("a");
                var url = window.URL.createObjectURL(myBlob);
                let n = result.fileName ? result.fileName.split(".")[0] : "";
                link.href = url;
                link.download = n !== "" ? n : name;
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            });
    }
};
}
