import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { Menu } from "primereact/menu";
import React from "react";

export const agentItemTemplate = (agent: any) => {
  let cityState: string = "";

  if (agent.id) {
    if (agent.city && agent.state) cityState = agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) cityState = agent.city;
    else if (!agent.city && agent.state) cityState = agent.state;
    else cityState = "";
  }
  return (
    <>
      {(agent && agent.id && agent.id > 0) || agent.id === -1 ? (
        <>
          {agent.id === -1 ? (
            <div className="p-grid header">
              <div className="p-col-3 text-left p-col-align-center">
                <b>Agent Id</b>
              </div>
              <div className="p-col-4 p-col-align-center">
                <b>Name</b>
              </div>
              <div className="p-col-5 p-col-align-center">
                <b>Location</b>
              </div>
            </div>
          ) : (
            <div className="p-grid ag-main-content">
              <div className="p-col-3 text-left p-col-align-center">
                <span>{agent.id}</span>
              </div>
              <div
                className={
                  cityState
                    ? "p-col-4 p-col-align-center"
                    : "p-col-4 p-col-align-center"
                }
              >
                <span>{agent.name ? agent.name : ""} </span>
                {/* <span>{agent.firstName ? agent.lastName + ', ' + agent.firstName : agent.lastName} </span> */}
              </div>
              <div
                className={cityState ? "p-col-5 p-col-align-center" : "hide"}
              >
                <span className="margin-left-10"> {cityState}</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <span>No Records Found.</span>{" "}
        </>
      )}
    </>
  );
};

export const groupItemTemplate = (option: any) => {
  return (
    <>
      {option.isHeader ? (
        <div className="list-item-header">{option.label}</div>
      ) : (
        <span style={{ paddingLeft: 10 }}>{option.label}</span>
      )}
    </>
  );
};

export const groupMultiselectItemsTemplate = (option: any) => {
  if (option) {
    if (option.isHeader) {
      return <div className="list-item-header">{option.label}</div>;
    } else {
      return <div className="list-item">{option.label}</div>;
    }
  }
  return <div className="list-item">No Records.</div>;
};

export const agentImmediateUplineItemTemplate = (agent: any) => {
  return (
    <>
      {(agent && agent.agentId && agent.agentId > 0) || agent.agentId === -1 ? (
        <>
          {agent.agentId === -1 ? (
            <div className="p-grid header">
              <div className="p-col-8 text-left p-col-align-center">
                <b>Agent/Agency ID & Name</b>
              </div>
              <div
                className="p-col-4 p-col-align-center"
                style={{ marginLeft: "-4px" }}
              >
                <b>Agent Status</b>
              </div>
            </div>
          ) : (
            <div className="p-grid">
              <div className="p-col-8 text-left p-col-align-center">
                <span>{agent.agentName}</span>
              </div>
              <div className="p-col-4 text-left p-col-align-center">
                <span>{agent.status} </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <span>No Records Found.</span>{" "}
        </>
      )}
    </>
  );
};

export const documentItemTemplate = (document: any) => {
  return (
    <>
      {(document && document.id && document.id > 0) || document.id === -1 ? (
        <>
          {document.id === -1 ? (
            <div className="p-grid document-attach-autosuggestion-header">
              <div className="p-col-4 text-left p-col-align-center">
                <b>Doc Type</b>
              </div>
              <div className="p-col-4 p-col-align-center">
                <b>Document Name</b>
              </div>
              <div className="p-col-4 p-col-align-center">
                <b>Carrier</b>
              </div>
            </div>
          ) : (
            <div className="p-grid">
              <div className="p-col-4 text-left p-col-align-center">
                <span>{document.fileType}</span>
              </div>
              <div className="p-col-4 text-left p-col-align-center">
                <span>{document.documentName}</span>
              </div>
              <div className="p-col-4 text-left p-col-align-center">
                <span>{document.companyName} </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <span>No Records Found.</span>{" "}
        </>
      )}
    </>
  );
};

export const dotActionTemplate = (
  rowId: number,
  actionItems: any,
  disabled: boolean = false,
  className?: string
) => {
  let isActionShown: boolean = false;
  let handleClick = (id: string) => {
    let showState = isActionShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    isActionShown = showState;
  };
  return (
    <>
      <div
        className={"LatoFont text-center " + className ? className : ""}
        //style={{
        //  position: "absolute",
        //  right: "-2px",
        //  top: "-12px",
        //}}
      >
        <span id={"custom-temp-" + rowId} className="custom-report-menuspan">
          <span
            id={"custom-menu-li" + rowId}
            className={"ellipse-customReport" + (disabled ? " disabled" : "")}
            onClick={() => {
              if (!disabled) {
                handleClick("" + rowId);
              }
            }}
          >
            <span></span>
          </span>
        </span>

        <div
          tabIndex={-1}
          className="custom-report-menu-list custom-report-menu-list-last"
          id={"" + rowId}
          style={{ display: "none", position: "relative" }}
        >
          <Menu
            model={actionItems}
            style={{ display: disabled ? "none" : "" }}
          />
        </div>
      </div>
    </>
  );
};

export const closeToasterButtonTemplate = (closeOnClick: any) => {
  return (
    <span
      className="fa fa-close"
      onClick={() => {
        closeOnClick();
      }}
    ></span>
  );
};

export const getDangerouslyHtml = (htmlString: any) => {
  const theObj = { __html: htmlString };
  return <span dangerouslySetInnerHTML={theObj}></span>;
};
