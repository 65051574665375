import ViewModel from "../../../../infrastructure/ViewModel";
import { action, observable } from "mobx";
import RouteList from "../../../../infrastructure/RouteList";
import { routes } from "../../../../router";
import Collection from "../../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../../store/ContractsStore";
import ProductStore from "../../../../store/ProductStore";
import { Constants } from "../../../../infrastructure/enum/Constants";
import Utils from "../../../../infrastructure/Utils";

export class LicenseDashboardViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => { };

  @action Load = async () => {
    this.showInactiveCarriers = false;
    await this.getAllCompanies()
    await this.getAllPrdtSubCat()
  };

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
            
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productSubCatList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable pageTitle: string = "Contract Dashboard";
  @observable defaultCompany: string = "0";
  @observable defaultPrdtSubCategory: string = "0";

  @action backToAdmin = async () => {
    routes.administration.push();
  };


  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList.values = []; 
    this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
    this.defaultCompany = this.companyList.values[0].value
  };  
     
  @action getAllCompanies = async () => {
    let result = await ContractsStore.GetActiveInactiveAllCarrierLookup()
    if (result) {
      result.forEach((obj: any) => {
        var data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value.toString() : "",
        };
        this.companyList.values.push(data);
      });
      this.carriersInitial.values = this.companyList.values;
      this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
    
      this.defaultCompany = this.companyList.values[0].value
    }
  }

  @action getAllPrdtSubCat = async () => {
    let result = await ProductStore.getProductSubCategoriesLookup()
    if (result) {
      let subCat = result.subCategories ? result.subCategories : []
      subCat.forEach((obj: any) => {
        var data = {
          label: obj.subCategoryText ? obj.subCategoryText : "",
          value: obj.subCategoryValue ? obj.subCategoryValue.toString() : "",
        };
        this.productSubCatList.values.push(data);
      });
      this.defaultPrdtSubCategory = this.productSubCatList.values[0].value
    }
  }

  @action buildAll = async () => {
    let body = {
      companyId: this.defaultCompany ? Number(this.defaultCompany) : 0,
      productSubCategoryId: this.defaultPrdtSubCategory ? Number(this.defaultPrdtSubCategory) : 0
    }
    this.isMessgeVisible = false;
    this.response = {};
    await ContractsStore.buildAllLevel(body);
    var successResponse = {
      status: 200,
      title: Constants.ScheduledSuccess,
      errors: { "": [] },
    };
    this.response = JSON.stringify(successResponse);

    this.isMessgeVisible = true;
    this.defaultCompany = this.companyList.values[1].value;
    this.defaultPrdtSubCategory = this.productSubCatList.values[1].value;

  }
}
