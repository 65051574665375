import { getToken } from "../infrastructure/Auth0Authenticator";
import { action } from "mobx";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import Utils from "../infrastructure/Utils";
import {
  Client as ProducerSearchClient,
  DownloadProductionRequest,
  ProductionReconcileReportRequest,
  PolicySnapShotRequest,
  AutoRTSRequest,
} from "../services/ProducerSearchService";
import { FileType } from "../services/BusinessUnitConfigService";
import { googleTagManager } from '../infrastructure/tracking/';

class ProducerSearchStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): ProducerSearchClient => {
    return ServiceClient.getClient(ServiceType.ProducerSearch);
  };
  @action getAgentList = async (
    searchType: number,
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    
    googleTagManager.trackAction("agent_search_conducted", {
      feature: "Agents",
      user_id: UserContext.userId,
      search_text:searchKey,
    });

    var result = await this.getClient().getAllAgent(
      this.getApiVersion(),
      searchType,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );
    return result;
  };

  @action getAgencyLookup = async (
    searchType: number,
    searchKey: string,
    startIndex: number | undefined,
    pageSize: number | undefined,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllAgentsLookup(
      this.getApiVersion(),
      Utils.getAgencySearchType(searchKey),
      startIndex,
      200,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );
    if (result.data) {
      result.data.splice(0, 0, { id: -1 });
    }
    return result;
  };

  @action getAgentsLookup = async (
    searchType: number,
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    googleTagManager.trackAction("agent_search_conducted", {
      feature: "Agents",
      user_id: UserContext.userId,
      search_text: searchKey,
    });
    var result = await this.getClient().getAllAgentsLookup(
      this.getApiVersion(),
      Utils.getAgentSearchType(searchKey),
      startIndex,
      200,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );
    if (result.data) {
      result.data.splice(0, 0, { id: -1 });
    }
    return result;
  };
  
  @action getAgentsLookupForRTSReview = async (
    searchType: number,
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    googleTagManager.trackAction("agent_search_conducted", {
      feature: "Agents",
      user_id: UserContext.userId,
      search_text: searchKey,
    });
    var result = await this.getClient().getAllAgentsLookup(
      this.getApiVersion(),
      Utils.getAgentSearchTypeForRTSReview(searchKey),
      startIndex,
      200,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );
    if (result.data) {
      result.data.splice(0, 0, { id: -1 });
    }
    return result;
  };

  @action getLocationEnabledAgentsLookup = async (
    searchType: number,
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllAgentsLookup(
      this.getApiVersion(),
      searchType,
      startIndex,
      200,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );
    if (result.data) {
      result.data.splice(0, 0, { id: -1 });
    }
    return result;
  };

  @action getAllMarketersLookup = async (
    searchType: number,
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllMarketersLookup(
      this.getApiVersion(),
      Utils.getAgentSearchType(searchKey),
      startIndex,
      200,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );
    if (result.data) {
      result.data.splice(0, 0, { id: -1 });
    }
    return result;
  };

  @action getAgentsSearchByMap = async (
    marketerId: number,
    carrierId: number,
    agentId: number,
    stateAbbreviation: string,
    zip: string | undefined,
    excludeDNC: boolean | undefined
  ) => {
    var result = await this.getClient().getAllAgentsByMap(
      this.getApiVersion(),
      marketerId,
      carrierId,
      agentId,
      stateAbbreviation,
      zip,
      excludeDNC,
      UserContext.userId
    );
    return result;
  };

  @action getZipByCountyState = async (state?: string, county?: string) => {
    var result = await this.getClient().getZipCodeByCountyState(
      this.getApiVersion(),
      county,
      state,
      UserContext.getUserId()
    );
    return result;
  };

    @action getAutoRtsByNpn = async (year?: number | undefined, 
        carrierIds?: number[] | null | undefined,
        agentId?: number | undefined,
        pageIndex?: number | undefined,
        pageSize?: number | undefined,
        sortColumn?: string | null | undefined,
        sortOrder?: boolean | undefined,
        searchText?: string | undefined) => {
        var result = await this.getClient().getAutoRtsByNpn(
        this.getApiVersion(),
        year,
        carrierIds,
        agentId,
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        searchText,
        UserContext.getUserId()
    );
    return result;
    };

    @action downloadAutoRtsByNpn = async (year?: number | undefined,
        carrierIds?: number[] | null | undefined,
        agentId?: number | undefined,
        pageIndex?: number | undefined,
        pageSize?: number | undefined,
        sortColumn?: string | null | undefined,
        sortOrder?: boolean | undefined,
        searchText?: string | undefined) => {
        var result = await this.getClient().downloadAutoRtsByNpn(
            this.getApiVersion(),
            year,
            carrierIds,
            agentId,
            pageIndex,
            pageSize,
            sortColumn,
            sortOrder,
            searchText,
            UserContext.getUserId()
        );
        return result;
    };


  @action getMarketersForMap = async () => {
    var result = await this.getClient().marketersForMaps(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getProductionReportData = async (
    startDate: Date,
    endDate: Date,
    agentOrAgencyAWNs: string[] | undefined,
    carrier: string | undefined,
    products: string[] | null | undefined,
    productCategories: string[] | null | undefined,
    productSubCategories: string[] | null | undefined,
    fileType: FileType
  ) => {
    var request: ProductionReconcileReportRequest = {
      fileType: fileType,
      startDate: startDate,
      endDate: endDate,
      agentOrAgencyAWNs: agentOrAgencyAWNs ? agentOrAgencyAWNs : [],
      carrierNames: [],
      carrierName: carrier,
      productNames: products ? products : [],
      productCategories: productCategories ? productCategories : [],
      productTypes: productSubCategories ? productSubCategories : [],
    };

    var result = await this.getClient().getProductionReconcileReport(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      request
    );
    return result;
  };

  @action downloadProductionData = async (
    request: DownloadProductionRequest
  ) => {
    var result = await this.getClient().downloadProduction(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      request
    );
    return result;
    };

    @action getPolicySnapShotData = async (
        startDate: Date | undefined,
        endDate: Date | undefined,
        nPN: string | null | undefined,
        downline: string | null | undefined,
        carrier: string | null | undefined,
        searchText: string | null | undefined,
        statusType: number | undefined,
        pageIndex?: number | undefined,
        pageSize?: number | undefined,
        sortColumn?: string | null | undefined,
        sortAscending?: boolean | undefined,
        fullTextFilter?: string | null | undefined
    ) => {
        var result = await this.getClient().getPolicySnapShotData(
            this.getApiVersion(),
            startDate, endDate, nPN, downline, carrier, searchText, statusType, pageIndex, pageSize, sortColumn, sortAscending, fullTextFilter,
            UserContext.userId,
            undefined
        );
        return result;
    };

    @action getTopFiveCarriers = async (
        startDate: Date | undefined,
        endDate: Date | undefined,
        nPN: string | null | undefined,
        downline: string | null | undefined,
        carrier: string | null | undefined,
        searchText: string | null | undefined,
        statusType: number | undefined,
        pageIndex?: number | undefined,
        pageSize?: number | undefined,
        sortColumn?: string | null | undefined,
        sortAscending?: boolean | undefined,
        fullTextFilter?: string | null | undefined
    ) => {
        var result = await this.getClient().getTopFiveCarriers(
            this.getApiVersion(),
            startDate, endDate, nPN, downline, carrier, searchText, statusType, pageIndex, pageSize, sortColumn, sortAscending, fullTextFilter,
            UserContext.userId,
            undefined
        );
        return result;
    };

    @action getGetTopFiveProductType = async (
      startDate: Date | undefined,
      endDate: Date | undefined,
      nPN: string | null | undefined,
      downline: string | null | undefined,
      carrier: string | null | undefined,
      searchText: string | null | undefined,
      statusType: number | undefined,
      pageIndex?: number | undefined,
      pageSize?: number | undefined,
      sortColumn?: string | null | undefined,
      sortAscending?: boolean | undefined,
      fullTextFilter?: string | null | undefined
  ) => {
        var result = await this.getClient().getGetTopFiveProductType(
        this.getApiVersion(),
        startDate, endDate, nPN, downline, carrier, searchText, statusType, pageIndex, pageSize, sortColumn, sortAscending, fullTextFilter,
      UserContext.userId,
      undefined
    );
    return result;
  };

    @action getPolicyData = async (
        startDate: Date | undefined,
        endDate: Date | undefined,
        nPN: string | null | undefined,
        downline: string | null | undefined,
        carrier: string | null | undefined,
        searchText: string | null | undefined,
        statusType: number | undefined,
        pageIndex?: number | undefined,
        pageSize?: number | undefined,
        sortColumn?: string | null | undefined,
        sortAscending?: boolean | undefined,
        fullTextFilter?: string | null | undefined
    ) => {
        var result = await this.getClient().getPolicyData(
            this.getApiVersion(),
            startDate, endDate, nPN, downline, carrier, searchText, statusType, pageIndex, pageSize, sortColumn, sortAscending, fullTextFilter,
            UserContext.userId,
            undefined
        );
        return result;
    };

  @action downloadPolicySnapShotReport = async (
      request: PolicySnapShotRequest
  ) => {
    var result = await this.getClient().downloadPolicySnapShotReport(
        this.getApiVersion(),
        UserContext.userId,     
        undefined,
        request
    );
    return result;
  };

    @action getPolicyManagementData = async (
        request: PolicySnapShotRequest
    ) => {
        var result = await this.getClient().getPolicyManagementData(
            this.getApiVersion(),
            UserContext.userId,
            undefined,
            request
        );
        return result;
    };
    @action getAutoRTSData = async (
        request: AutoRTSRequest
    ) => {
        var result = await this.getClient().getAutoRTSData(
            this.getApiVersion(),
            UserContext.userId,
            undefined,
            request
        );
        return result;
    };
}
export default new ProducerSearchStore();
