import { action, observable } from "mobx";
import RouteParamDetails from "../../../../infrastructure/RouteParamDetails";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { ProductSubCategoryListModel } from "../../../../services/ProductService";
import ProductStore from "../../../../store/ProductStore";
import { BaseListViewModel } from "../../../../viewModels/components/BaseListViewModel";

export class ProductSubCategoryListViewModel extends BaseListViewModel<ProductSubCategoryListModel> {
    // Add any filter related @observable variables here, like Filter Drop-Downs
    constructor()
    {
        super("Product SubCategories", "Product SubCategory", routes.addProductSubCategory, routes.editProductSubCategory, "id", "productSubCategory");
    }

    protected async loadLookups(): Promise<void> {
        this.pageSize = 50
        
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        this.isMessgeVisible = false;
        this.response = {};
       return await ProductStore.getProductSubCategoryList(pageIndex, pageSize, sortColumn, sortOrder);
    }
    @observable isMessgeVisible: boolean = false;
    @observable response: any;
    protected async deleteItem(id: number) {
        this.onDeleteClick(id);
    }
    @action onDeleteClick = async (id: number) => {
        this.isMessgeVisible = false;
        let result = await ProductStore.deleteProductSubCategory(id);
        if(result && result.message)
        {
            var responseModel = {
                status: 400,
                title:  result.message,
                errors: { "": [] },
              };
              this.response = JSON.stringify(responseModel);
              this.isMessgeVisible = true;
        }
       
    }

    @action downloadProductSubcategoryListAsExcel = async() => {
        let res = await ProductStore.DownloadAllProductSubCategoryReport();
        Utils.saveDownloadedReportFile(res, "ProductSubCategory");
    }

    @action ProductDetails = (id: number, name: string) => {
        RouteParamDetails.productSubCategoryName = name;
        routes.productDetails.push({Id: +id})
    }

    resetFiltersToDefault() {
        this.pageSize = 50;
    }
}
