import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../infrastructure/IMASLog";
import AccountingStore from "../../store/AccountingStore";
import Utils from "../../infrastructure/Utils";

export class AddEditCreditCheckViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => {
       
    };
    @observable
    public ErrorModel = new ErrorModel(new CreditCheckValidator());
    Route = async (currentRoute: RouteList): Promise<void> => { } 
    @observable isLoading: boolean = false;
    @observable isDialogVisible: boolean = false;
    @observable vendor: string = '2'
     @observable vendorList: any;
    @observable result: string = ''
    @observable resultList = [
        { label: 'Ok', value: '1' },
        { label: 'Bad Rating', value: '2' }        
    ]
    @observable inquiryDate: Date | undefined = new Date();

    @action loadVendors = async() => {
        var result = await AccountingStore.getCreditCheckVendor()
        this.vendorList = []
        if(result){
            this.vendorList =  this.mapListItemAndSort(result);
        } 
    }

    @action mapListItemAndSort(listObjects: any) {
        if (listObjects) {
          var sortList = listObjects.sort(Utils.compareListItem);
          sortList.forEach((element: any) => {
            element.label = element.text;
          });          
          return sortList;
        }
        else {
          return [];
        }
      }
    @computed
    get isAnyAgentSelected(): boolean {        
        return true
    }
    @action setResult(value: string){
      this.result = value
    }
    @action setVendor(value: string)
    {
      this.vendor = value
    }
    @action setInquiryDate(value: Date | undefined) {
      this.inquiryDate = value;
  }   

      @action setDialogVisibility(value: boolean) {
        this.isDialogVisible = value;        
    }
    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action
    public ResetValidation = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
    @action
    public Submit = async () => {
      var isValid = await this.Validate()
      IMASLog.log('is valie: '+isValid)
        if (await this.Validate()) {
          this.setDialogVisibility(false)
        }
    }

}

class CreditCheckValidator extends AbstractValidator<AddEditCreditCheckViewModel>{
  public constructor() {
      super();
      this.validateIfString(input => input.vendor)
      .isNotEmpty()
      .withFailureMessage("Vendor is required");
      this.validateIfString(input => input.result)
      .isNotEmpty()       
      .withFailureMessage("Result is required");
      this.validateIfDate(input => input.inquiryDate)
      .isNotEmpty()       
      .withFailureMessage("Inquiry date is required");
  }
}