import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { routes } from "../../../../router";
import IdentityStore from "../../../../store/IdentityStore";
import { action, observable, toJS } from "mobx";
import { UserSearch, UserStatus } from "../../../../infrastructure/enum/Common";
import Utils from "../../../../infrastructure/Utils";
import axios from "axios";
import Collection from "../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../infrastructure/RouteList";
import ViewModel from "../../../../infrastructure/ViewModel";
import UserContext from "../../../../infrastructure/UserContext";
import { v1 as uuidv1 } from "uuid";

export class UserInvitationViewModel implements ViewModel {


    @observable selectedRowData: any;
    @observable firstItem: number = 0;
    @observable currentPage: number = 0;
    @observable pageSize: number = 50;
    @observable pageIndex: number = 0;

    @observable sortColumn: string = "dateSent";
    @observable isSortAscending: boolean = true;
    @observable defaultSortColumn: string | undefined;
    @observable defaultIsSortAscending: boolean = true;
    @observable isPagination: string = "";
    @observable startIndex: number = 0;
    @observable totalRecords: number = 0;
    @observable first: number = 0;
    @observable rows: number = 50;


    readonly currentPageReportTemplate: string =
        "Showing {first} - {last} of {totalRecords} records";
    paginatorTemplate(): string {
        return this.userInvitationsTableData.length > 0
            ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            : "";
    }
    rowsPerPageOptions(): number[] {
        return this.userInvitationsTableData.length > 0 ? [50, 100, 150, 200] : [];
    }

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => undefined;
    @observable name: string = "";
    @observable searchType: string = "1";
    @observable searchTypeList = [
        { label: "Last Name", value: UserSearch.LastName },
        { label: "First Name", value: UserSearch.FirstName },
        { label: "Team", value: UserSearch.Team },
    ];
    @observable statusType: string = "1";
    @observable statusTypeList = [
        { label: "Active", value: UserStatus.Active },
        { label: "Inactive", value: UserStatus.Inactive },
        { label: "All", value: UserStatus.All },
    ];
    @observable invitationStatusTypeList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable searchValue: string = '';

    @action Load = async () => { };
    @action goToAdmin = () => {
        routes.administration.push()
    }


    @observable selectedTabIndex?: number = 0;
    @observable userInvitationsTableData?: any = [];
    @observable selectedStatus: any = "0";
    @observable searchInvitationValue: any;
    @observable serchTextQuery: any = '';
    @observable totalInvitationsRowsCount: any;

    @action setStatusDropdown = (value: string) => {
        this.selectedStatus = value;
        this.loadInvitations();
    };

    @action onFieldChange = (value: any) => {
        this.searchInvitationValue = value;
        if (this.searchInvitationValue.length == 0 || this.searchInvitationValue.length > 2) {
            this.serchTextQuery = `&FullTextFilter=${this.searchInvitationValue}`
            this.loadInvitations();
        } else {
            this.serchTextQuery = '';
        }
    };

    @action resetInvitePagination = ()=>{
        this.sortColumn = "dateSent";
        this.isSortAscending = true;
        this.defaultSortColumn= undefined;
        this.defaultIsSortAscending = true;
        this.isPagination = "";
        this.startIndex = 0;
        this.totalRecords = 0;
        this.first = 0;
        this.rows = 50;
        this.sort = false;
    }
    @action loadInvitationsStatusTypes = async () => {
        const url = Utils.getMicroServiceUrl("inviteService");
        const authKey = await Utils.getApiKey();
        let host = Utils.getHost();
        return axios
            .get(`${url}/api/invite/v1/invite/invitestatuslookup`, {
                headers: {
                    HOSTNAME: host,
                    authorization: authKey
                },
            })
            .then((response: any) => {
                if (response.data) {
                    Utils.mapListItemsToDropdown(response.data, this.invitationStatusTypeList, "All", "0");
                }
            })
            .catch(console.error);
    };

    @observable sort: boolean = false;
    @observable revokeInviteModalVis: boolean = false; 
    
    @observable revokeInviteToaster: boolean = false;
    @observable revokeInviteMail: any = '';
    @observable revokeInviteMailID: any = '';
    @action setrevokeInviteModalVis = () => {        
        this.revokeInviteModalVis = false;
        this.revokeInviteToaster = false; 
        this.revokeInviteMailID = '';
        setTimeout(async () => {
            localStorage.removeItem("roleCloned");
        }, 5000);
    }

    @action revokeInvite = async () => { 
        let result = await IdentityStore.revokeInvite(this.revokeInviteMailID);
        localStorage.setItem("roleCloned", "cloned");
        this.revokeInviteModalVis = false;
        this.revokeInviteToaster = true;
        this.loadInvitations();
        this.setrevokeInviteModalVis();
    }

    @action setSortOrder() {
        this.sort = !this.sort;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }

    @action loadInvitations = async () => {
        this.loadInvitationsStatusTypes();
        const url = Utils.getMicroServiceUrl("inviteService");
        const authKey = await Utils.getApiKey();
        let host = Utils.getHost();
        return axios
            .get(`${url}/api/invite/v1/invite/allinvites?Status=${this.selectedStatus}&PageIndex=${this.pageIndex}&PageSize=${this.pageSize}&SortColumn=${this.sortColumn}&SortAscending=${this.sort}${this.serchTextQuery}`, {
                headers: {
                    HOSTNAME: host,
                    authorization: authKey
                },
            })
            .then((response: any) => {
                this.userInvitationsTableData = response.data.data;
                this.totalInvitationsRowsCount = response.data.recordCount;
            })
            .catch(console.error);
    };

    onPage = (pageInfo: any) => {
        this.firstItem = pageInfo.first;
        this.pageSize = pageInfo.rows;
        this.pageIndex = pageInfo.first / pageInfo.rows;
        this.loadInvitations();
    };


    @action handleTabClick = async (index: number | undefined, key: string): Promise<void> => {
        this.selectedTabIndex = index;
        // this.resetPaging();
        this.resetFiltersToDefault();
        if (this.selectedTabIndex == 1) {
            this.selectedStatus = 0;
            this.loadInvitationsStatusTypes();
            this.loadInvitations();
            
        }
    };

    @observable isShown: boolean = false;
    @observable listViewActions: boolean = false;
    @action handleClick = (id: string) => {
        let showState = this.isShown;
        const menulistDiv = document.getElementById(id);
        // code to hide all menu lists
        const menuListAllDivs = Array.from(
            document.getElementsByClassName(
                "custom-report-menu-list"
            ) as HTMLCollectionOf<HTMLElement>
        );
        if (menulistDiv !== null && menuListAllDivs !== null) {
            for (let i = 0; i < menuListAllDivs.length; i++) {
                if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
                    menuListAllDivs[i].style.display = "none";
                }
            }

            // code to open menu list
            menulistDiv.style.display =
                menulistDiv.style.display === "block" ? "none" : "block";

            // code to remove backgroud of svg
            for (let i = 0; i < menuListAllDivs.length; i++) {
                const svgSpan = document.getElementById(
                    "custom-temp-" + menuListAllDivs[i].id
                ) as HTMLElement;
                if (svgSpan !== null && menuListAllDivs[i].id !== id) {
                    svgSpan.className = "custom-report-menuspan";
                }
            }
            const svgDiv = document.getElementById(
                "custom-temp-" + id
            ) as HTMLElement;
            if (svgDiv !== null) {
                svgDiv.className =
                    menulistDiv.style.display === "block"
                        ? "custom-report-menu-active"
                        : "custom-report-menuspan";
                menulistDiv.style.display === "block"
                    ? (this.listViewActions = true)
                    : (this.listViewActions = false);
            }
        }

        window.onclick = function (event) {
            const menuListAllDivs = Array.from(
                document.getElementsByClassName(
                    "custom-report-menu-list"
                ) as HTMLCollectionOf<HTMLElement>
            );
            const svgDiv = document.getElementById(
                "custom-temp-" + id
            ) as HTMLElement;
            const menulistDiv = document.getElementById(id);
            if (showState && menulistDiv) {
                for (let i = 0; i < menuListAllDivs.length; i++) {
                    menuListAllDivs[i].style.display = "none";
                    menulistDiv.style.display =
                        menulistDiv.style.display === "block" ? "none" : "none";
                    svgDiv.className =
                        menulistDiv.style.display === "block"
                            ? "custom-report-menuspan"
                            : "custom-report-menuspan";
                    showState = false;
                }

                const activeClassName = document.querySelector(
                    ".custom-report-menu-active"
                );
            } else {
                showState = true;
            }
        };
        this.isShown = showState;
    };


    resetFiltersToDefault() {
        this.searchValue = ''
        this.searchType = "1"
        this.statusType = "1"
    }

    @action setSearchType(value: string) {
        this.searchType = value;
    }

    @action setStatusType(value: string) {
        this.statusType = value;
    }

    // resend BL

// 
@observable resendmailToaster:any='';
@observable revokeResendModalVis: boolean = false;
@observable selectedTeam:any='';
@observable listofTeams:any='';
@observable selectedRoles:any=[];
@observable listofRoles = new Collection<{
    label: string;
    value: string;
}>();
@observable rowDataRoles:any='';
@observable selectedValue:any;
@observable items:any=[];
@observable email: any = '';
@observable firstname: any = '';
@observable lastname: any = '';
@observable message:any='';
@observable recordID:any='';
@observable errorMessage:boolean=false;
@observable emailExisted:boolean=false;
@observable selectedRolesRef:any; 
@observable inviteId:any = UserContext.userId; 
@observable resendInviteMail: any = '';
@observable searchValueAgency: any = '';



// 

    @action sendInvite = async (): Promise<any> => { 
        const url = Utils.getMicroServiceUrl("inviteService");
        const baseUrl = Utils.getServiceUrlByEnvironment();
        const authKey = await Utils.getApiKey();
        const uniCode = uuidv1();
        return axios
          .post(`${url}/kafka-producer/publish`, {
            topic: "Connect.Invite.Requested",
            message: {
              url: baseUrl,
              bu: Utils.getBUName() =='DEMO'? 'IntegrityDemo' :Utils.getBUName() ,
              email: this.email,
              code: uniCode,
              downlineAgencyId: this.selectedValue &&  this.selectedValue.id,
              roles: this.selectedRoles.map(( code:any) => {
                return parseInt(code);
              }),
              teamId: Number(this.selectedTeam),
              userId: Utils.getUserId(),
              reInvite: true,
              inviteId: this.revokeInviteMailID,
              firstName: this.firstname,
              lastName: this.lastname,
            },
            headers: { authorization: authKey },
          })
          .then((response) => {
            this.loadInvitations(); 
            this.resendmailToaster = this.email
            this.revokeResendModalVis = false; 
          })
          .catch(console.error);
      };
    
    @action getDownLineAgency = async (searchValue: any): Promise<any> => {
        const url = Utils.getServiceUrlByEnvironment();
        const authKey = await Utils.getApiKey();
        let host = Utils.getHost();
        return axios
          .get(
            `${url}/api/producersearch/v1/agents/lookup?SearchType=12&PageIndex=0&PageSize=200&FullTextFilter=${searchValue}`,
            {
              headers: {"HOSTNAME": host, authorization: authKey },
            }
          )
          .then((response) => {
            
            const agentList = response.data.data;
            this.items =response.data.data; 
            this.errorMessage= !this.isUserInputValidAgency(searchValue, agentList);
          })
          .catch(console.error);
      };
    
  @action isUserInputValidAgency = (
    agentName: string,
    agentList: any
  ) => {
    if (agentName.length >= 3) {
      return this.isValidAgency(agentName, agentList);
    }
    return true;
  };

  @action isValidAgency = (agentName: string, agentList: any) => {
    const agentLC = agentName.toLowerCase();
    const listOfAgents = agentList.filter(({ name }) =>
      name.toLowerCase().match(agentLC)
    );
    const itemFound = listOfAgents.length > 0;
    return itemFound;
  };

  @action getUserRoles = async (
    rowId: any ,
    isDownlineAgencyUser: boolean,
    roleName:any
  ): Promise<any> => { 
    const url = Utils.getServiceUrlByEnvironment();
    const authKey = await Utils.getApiKey(); 
    let host = Utils.getHost();
    return axios
      .get(
        `${url}/api/identity/v1/users/` +
        0 +
          `/` +
          isDownlineAgencyUser +
          `/getUsersScreenRoleLookup`,
        {
          headers: { HOSTNAME: host, authorization: authKey },
        }
      )
      .then((response) => {
        this.selectedRoles=[];
        
        // rowId > 0 && this.getRowData(rowId); 
        response.data && Utils.mapListItemsToDropdown(response.data, this.listofRoles, "", "");
        this.selectedRoles= roleName;
        this.loadTeams();
        })
      .catch(console.error); 
  };
  @action loadTeams =async ()=>{
    let result  = await IdentityStore.getUsersScreenLookup(0); 
    this.listofTeams = result.teamLookup;

  }
  @action getEmailVerified = async (email: any): Promise<any> => {
    const url = Utils.getServiceUrlByEnvironment();
    const authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    return axios
      .get(`${url}/api/identity/v1/users/checkuseremailexist/` + email, {
        headers: { HOSTNAME: host, authorization: authKey },
      })
      .then((response) => {
        response.data;
        if (response.data != '') {
          this.message=response.data;
          this.emailExisted = true;
        } else {
            this.emailExisted = false;
        }
      })
      .catch(console.error);
  };

  @action getUserProfile = async (): Promise<any> => {
    let response  = await IdentityStore.getUsersScreenLookup(0);
    if(response && response.roleLookup){   
        if(response && response.teamLookup){
            Utils.mapListItemsToDropdown(response.teamLookup, this.listofTeams, "", "");
        }
    }
    // return axios
    //   .get(`${url}/api/identity/v1/users/0/getUsersScreenLookup`, {
    //     headers: { HOSTNAME: host, authorization: authKey },
    //   })
    //   .then((response) => {
    //     this.getRowData(rowId);
    //     this.listofTeams = response.data.teamLookup;
    //     this.listofRoles = formatRole(response.data);
    //   })
    //   .catch(console.error);
  };

  @action getRowData = async (rowId:any)=>{
    const url = Utils.getMicroServiceUrl("inviteService");
    const authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    return axios
        .get(`${url}/api/invite/v1/invite/${rowId}`, {
            headers: {
                HOSTNAME: host,
                authorization: authKey
            },
        })
        .then((response: any) => {  
            this.getUserRoles(rowId, response.data.downlineAgencyId > 0, response.data && response.data.roles.length > 0 ? response.data.roles.map(String) : [])
            this.firstname = response.data.firstName;
            this.lastname = response.data.lastName;
            this.email =response.data.email;
            // this.selectedRoles= (response.data && response.data.roles.length > 0? response.data.roles.map(String): []);
            this.selectedTeam = "" + response.data.teamId;
            if(response.data.downlineAgencyId){
              this.getDownLineAgency(response.data.agencyName);
              this.selectedValue={ value:response.data.downlineAgencyId, id:response.data.downlineAgencyId, name: response.data.agencyName };
            } 
        })
        .catch(console.error);
  }

    // 

}
