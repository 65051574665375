import { action, observable } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import { ContractDocumentDetailModel } from "../../../services/DocumentService";
import Collection from "../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../store/ContractsStore";
import DocumentStore from "../../../store/DocumentStore";
import PageContext from "../../../infrastructure/PageContext";

export class ContractDocumentsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (contractId: number, agentId: number) => {
    this.contractId = contractId;
    this.agentId = agentId;
    this.getContractDocuments();
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable DocumentsList = new Collection<ContractDocumentDetailModel>();

  @observable contractId: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable isException: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  // Documents
  @observable documentId: number = 0;
  @observable sortColumnDocument: string = "date"; // default date as per column sorting AMS-1281
  @observable sortOrderDocument: boolean = false;
  @observable pageIndexDocument: number = 0;
  @observable totalRecordsDocument: number = 0;
  @observable rowsDocument: number = 10;
  @observable firstDocument: number = 0;
  @observable sortContractDocument: boolean = false;
  @observable sortColumnPryamentPlan: string = "Id";

  @action getContractDocuments = async () => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    try {
      if (this.contractId) {
        var result = await ContractsStore.getContractDocuments(
          this.contractId,
          this.pageIndexDocument,
          this.rowsDocument,
          this.sortColumnDocument,
          this.sortOrderDocument,
          ""
        );
        if (result !== null && result !== undefined && result.data !== null) {
          this.DocumentsList.values = result.data ? result.data : [];
          this.totalRecordsDocument = result.recordCount
            ? result.recordCount
            : 0;
        } else {
          this.DocumentsList.values = [];
        }
        this.isMessgeVisible = false;
      }
    } catch (e) {
      this.DocumentsList.values = [];
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  @action downloadDocument = (base64: string) => {
    setTimeout(() => {
      var link = document.createElement("a");
      link.download = "document.pdf";
      link.href = "data:application/pdf;base64," + base64;
      link.textContent = "Download PDF";
      link.click();
      document.body.appendChild(link);
    }, 0);
  };
  @action downloadDocumentLink = async (docId: number, typeId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    try {
      var result = await DocumentStore.getDocumentLink(docId, typeId);
      if (result != null) {
        if (result.pdfData) {
          this.downloadDocument(result.pdfData);
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.response = JSON.stringify(responseModel);
            this.isMessgeVisible = true;
            PageContext.setResponseMessage(this.response);
            PageContext.setIsMessageVisible(true);
          }
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action openDocumentLink = async (docId: number, typeId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    if (typeId === 2) {
      window.open(
        window.location.origin +
          "/agents/" +
          this.agentId +
          "/customerDocsViewer/" +
          docId +
          "/" +
          typeId,
        "_new"
      );
    } else {
      window.open(
        window.location.origin +
          "/agents/" +
          this.agentId +
          "/pdfViewer/" +
          docId +
          "/" +
          typeId,
        "_new"
      );
    }
    this.isLoading = false;
  };
  @action contractDocumentSort = (column: any) => {
    var obj = JSON.parse(JSON.stringify(column));
    this.setContractDocumentSortOrder();
    this.setSortColumn(obj.sortField);
    this.getContractDocuments();
  };
  @action setSortColumn(column: string) {
    this.sortColumnPryamentPlan = column;
  }

  @action setContractDocumentSortOrder() {
    this.sortContractDocument = !this.sortContractDocument;
  }
  @action setSortOrderDocument() {
    this.sortOrderDocument = !this.sortOrderDocument;
    this.pageIndexDocument = 0;
    this.firstDocument = 0;
  }
  @action onPageDocument(firstIndex: number, rows: number) {
    this.rowsDocument = rows;
    this.firstDocument = firstIndex;
    this.pageIndexDocument = firstIndex / this.rowsDocument;
    this.getContractDocuments();
  }
  @action async setSortColumnDocument(sortField: any) {
    this.sortColumnDocument = sortField;
    await this.getContractDocuments();
  }
  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }
}
