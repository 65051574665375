import React from "react";
import { observer } from "mobx-react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Utils from "../../../infrastructure/Utils";

export const IMASConfirmationAlert: React.FC<{
  value: string;
  isVisible: boolean;
  onHide: () => void;
  ok: (e: any) => void;
  cancel: (e: any) => void;
  displayContent?: any;
  title?: string;
  isCancelHidden?: boolean;
  isOk?: boolean;
}> = observer((props) => {
  const onShow = () => {
    let okButton = document.getElementById("okButton");
    okButton && okButton.focus();
  };
  return (
    <>
      <Dialog
        header={props.title || "Alert"}
        visible={props.isVisible}
        onHide={props.onHide}
        onShow={onShow}
        focusOnShow={false}
      >
        <div className="p-grid">
          <div className="p-col-12">
            <label id="alertLable" htmlFor="alertLable">
              {props.displayContent ? (
                <strong>{props.value}</strong>
              ) : (
                <span>{Utils.getSafeHtml(props.value)}</span>
              )}
            </label>
          </div>
          {props.displayContent && (
            <div className="p-col-12">{props.displayContent}</div>
          )}
          <div className="p-col-12">
            <div className="text-right">
              {!props.isCancelHidden && (
                <Button
                  label="Cancel"
                  icon="fa fa-times"
                  id="cancelButton"
                  onClick={props.cancel}
                  className="secondary-button margin-right-10"
                ></Button>
              )}
              {!props.isOk && (
                <Button
                  label="OK"
                  id="okButton"
                  icon="fa fa-check"
                  onClick={props.ok}
                  className="primary-button"
                ></Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
});
