import React from 'react'
import { observer } from 'mobx-react';
import { IMASMessagesHandler } from '../components/controls/IMASMessagesHandler';
import { CreditCheckHistoryViewModel } from '../../viewModels/agents/CreditCheckHistoryViewModel';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CreditCheckHistoryListModel } from '../../services/AccountingService';
import Utils from '../../infrastructure/Utils';
import { Button } from 'primereact/button';

 export const CreditCheckHistoryView: React.FC<{ vm: CreditCheckHistoryViewModel }> = observer(props => {

    const dateTemplate = (rowData: CreditCheckHistoryListModel) => {
        return (          <>
            {Utils.getDateInFormat(rowData.inquiryDate)}
          </>
        );
      };
      const resultTemplate = (rowData: CreditCheckHistoryListModel) => {
        return (          <>
          <div className='text-center' >
            {rowData.negativeRating ? <> 
                <i className="fa fa-2x fa-exclamation-circle icon-fill-color-red" />
            </> : 
            <> 
            <i className="fa fa-2x fa-plus-circle icon-fill-color-green" />
            </>}
            </div>
          </>
        );
      };
    return (
        <>
          <Card>
        <div className="p-grid">
        {props.vm.isMessageVisible && (
                      <div className="p-col-12">
                        <IMASMessagesHandler
                          response={props.vm.response}
                        ></IMASMessagesHandler>
                      </div>
                    )}
            <div className="p-col-12">
              <Button
                id='returnToCustomer'
                className="secondary-button  margin-right-10"
                label={props.vm.agentName}
                icon="fa fa-chevron-left"
                onClick={() => props.vm.returnToAgent()}
              />
            </div>
          <div className="p-col-12">
            <DataTable
              className="header-align-left datatable-width-auto app-table"
              id="agentsTable"           
             
              resizableColumns={true}
              columnResizeMode="expand"
              responsive={true}
              value={props.vm.creditCheckHistoryList.values}
              paginator={false}             
              lazy={true}             
             
            >
              <Column
                header="Agent"
                field="agentName"               
                sortField="agentName"
              />
              <Column
                header="User"
                field="userName"              
                sortField="userName"                
              />
              <Column
                header="Vendor"
                field="vendorName"              
                sortField="vendorName"
                
              />
              <Column
                header="Inquiry Date"
                field="inquiryDate"
               body={dateTemplate}
                sortField="awn"
                
              />
              <Column
                header="Result"     
                className="column-center"                    
                body={resultTemplate}                
              />
              
            </DataTable>
          </div>
        </div>
      </Card>          
          
           
        </>
    );
});

//export default withAITracking(reactPlugin, CreditCheckHistoryView, "CreditCheckHistory");