import React from 'react';
import { observer } from 'mobx-react';
import { PropectiveCallListViewModel } from '../../viewModels/autoDialers/ProspectiveCallListViewModel';
import { CustomDropdown } from '../components/controls/IMASDropdown';
import { IMASMultiSelect } from '../components/controls/IMASMultiSelect';
import { IMASTreeNode } from '../components/controls/IMASTreeNode';
import { ResidentStateTypeContants } from '../../infrastructure/enum/Constants';
import { CustomInputTextArea } from '../components/controls/IMASInputTextArea';
import { IMASFormattedInput } from '../components/controls/IMASFormattedInput';
import { InputNumber } from 'primereact/inputnumber';
import { Repeater } from '../components/Repeater';
import { IMASCheckbox } from '../components/controls/IMASCheckbox';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { groupMultiselectItemsTemplate } from '../components/controls/IMASTemplates';

export const ProspectiveCallListView: React.FC<{ vm: PropectiveCallListViewModel }> = observer(props => {
    const onResidentStateTypeChange = async (value: string) => {
        if (value === ResidentStateTypeContants.County) {
            await props.vm.getAllStatesAndCountys(undefined);
            props.vm.residentStateType = value;
        } else {
            props.vm.residentStateType = value;
        }

        if ( props.vm.residentStateType !== ResidentStateTypeContants.State) {
            props.vm.residentState = [];
        } 
        if (props.vm.residentStateType !== ResidentStateTypeContants.County) {
            props.vm.residentCounty = {};
        } 
        if (props.vm.residentStateType !== ResidentStateTypeContants.ZipCode) {
            props.vm.residentZipCode = undefined;
            props.vm.addZipCode = "";            
        }
    }
    const onResidentStateChange = (value: any) => {
        props.vm.residentState = value;
    }
    const onResidentCountyChange = (value: any) => {
        props.vm.residentCounty = value;
    }
    const onResidentZipCodeChange = (value: any) => {
        props.vm.residentZipCode = value;
    }
    const onLeadSourceChange = (value: any) => {
        props.vm.leadSource = value;
    }
    const onAddZipCodeChange = (value: string) => {
        props.vm.addZipCode = value;
    }
    const onAddZipCodeDistanceChange = (value: number) => {
        props.vm.addZipCodeDistance = value;
    }
    const onAddZipCodeKeepZipCodeChange = (value: boolean) => {
        props.vm.addZipCodeKeepZipCodes = value;
    }
    const itemsTemplate = (option: any) => {
        if (option) {
            return <div className="list-item">{option.label}</div>;
        }
        return <div className="list-item">No Records.</div>;
    };

    // refs all multi-select
    let leadSourceTarget = React.createRef<any>();
    let residenseStateTarget = React.createRef<any>();
    
    return (
        <div>
            <div className="p-grid">
                <div className="p-col-12 p-md-12 p-lg-12">
                    <label>Resident Location</label>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4 p-lg-4">
                                <div className="form-group">
                                    <CustomDropdown
                                        errors={undefined}
                                        label="Filter By"
                                        placeHolder=""
                                        disabled={false}
                                        id="residentTypeDropdown"
                                        isrequired={false}
                                        onBlur={() => { }}
                                        value={props.vm.residentStateType.toString()}
                                        options={props.vm.residentStateTypeList}
                                        onChange={(value) => {
                                            onResidentStateTypeChange(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={props.vm.residentStateType === ResidentStateTypeContants.County ? "p-col-12 p-md-8 p-lg-8" : "p-col-12 p-md-4 p-lg-4"} >
                                <div className="form-group">
                                    {props.vm.residentStateType === ResidentStateTypeContants.State &&
                                        <IMASMultiSelect
                                            errors={undefined}
                                            label="State"
                                            placeHolder="All"
                                            id="residentStateDropdown"
                                            isrequired={false}
                                            value={props.vm.residentState}
                                            options={props.vm.residentStateList.values}
                                            itemTemplate={groupMultiselectItemsTemplate}
                                            onChange={(value) => {
                                                onResidentStateChange(value);
                                            }}
                                            ref={residenseStateTarget}
                                            onFocus={(e) => { props.vm.getAllStatesAndCountys(residenseStateTarget.current) }}
                                            optionLabel={"label"}
                                            filter={true}
                                            isGroupedList={true}
                                        />}

                                    {props.vm.residentStateType === ResidentStateTypeContants.County &&
                                        <div style={{ maxHeight: "270px", overflow: "overlay", paddingRight: "20px" }}>
                                            <IMASTreeNode
                                                value={props.vm.residentCountyList}
                                                selectionMode="checkbox"
                                                selectionKeys={props.vm.residentCounty}
                                                onSelectionChange={e => onResidentCountyChange(e)}
                                                filter={true}
                                                expandedKeys={[]}
                                                id="residentCountyDropdown"
                                                filterPlaceholder={"Search"}
                                                className="appsetting-tree"
                                                contentClassName="county-tree"
                                                onExpand={e => { }}
                                                onCollapse={e => { }}
                                            />
                                        </div>}

                                    {props.vm.residentStateType === ResidentStateTypeContants.ZipCode &&
                                        <CustomInputTextArea
                                            errors={undefined}
                                            label="Search Via ZipCodes. Ex: 68710,68700-68709,68800-68899"
                                            placeHolder=""
                                            id="ZipCodeDropdown"
                                            isrequired={false}
                                            rows={10}
                                            maxLength={undefined}
                                            onBlur={() => { }}
                                            value={props.vm.residentZipCode}
                                            onChange={(value) => {
                                                onResidentZipCodeChange(value)
                                            }}
                                        />}
                                </div>
                            </div>
                            <div className={props.vm.residentStateType === ResidentStateTypeContants.County ? "hide" : "p-col-12 p-md-4 p-lg-4"}>
                                {props.vm.residentStateType === ResidentStateTypeContants.ZipCode &&
                                    <div>
                                        <div className="form-group">
                                            <label>Add Zip codes that are within</label>
                                            <InputNumber value={props.vm.addZipCodeDistance} style={{ width: '100%' }} id="distanceTextbox"
                                                required={true} onChange={(e) => onAddZipCodeDistanceChange(e.value)} />
                                            <Repeater viewId='addZipCodeDistance' errors={props.vm.ErrorModel.getError("addZipCodeDistance")} child={(error) => <div className="invalid-feedback">{error}</div>} />
                                        </div>
                                        <div className="form-group">
                                            <IMASFormattedInput
                                                errors={props.vm.ErrorModel.getError("addZipCode")}
                                                format="#####-#####"
                                                mask="_"
                                                label="Miles of"
                                                isrequired={false}
                                                id='zipCodeTextbox'
                                                onBlur={() => ""}
                                                placeHolder=""
                                                value={props.vm.addZipCode}
                                                onChange={(value) => { onAddZipCodeChange(value); }}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <IMASCheckbox
                                                label="Limit Zip Codes to State Boundaries"
                                                id="keepZipCodes"
                                                onChange={(e) => {
                                                    onAddZipCodeKeepZipCodeChange(e.checked)
                                                }}
                                                value={props.vm.addZipCodeKeepZipCodes}
                                                disabled={false}
                                            />
                                        </div>
                                        <Button id="btnZipCode" className='primary-button margin-left-10' icon="fa fa-plus" label="Add Zip Codes" onClick={() => props.vm.getZipcodesByRange()} />
                                    </div>}
                            </div>
                        </div>
                    </Card>
                </div>

            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <IMASMultiSelect
                            errors={undefined}
                            label="Lead Source"
                            placeHolder="All"
                            id="leadSourceDropdown"
                            isrequired={false}
                            value={props.vm.leadSource}
                            options={props.vm.leadSourceList.values}
                            itemTemplate={itemsTemplate}
                            onChange={(value) => {
                                onLeadSourceChange(value);
                            }}
                            ref={leadSourceTarget}
                            onFocus={(e) => { props.vm.getLeadSource(leadSourceTarget.current) }}
                            optionLabel={"label"}
                            filter={true}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
});

export default ProspectiveCallListView;