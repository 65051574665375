import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../../store/ContractsStore";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import Utils from "../../../../infrastructure/Utils";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { routes } from "../../../../router";
import { ReportFile } from "../../../../services/ContractService";

export class ImportAgentCertificatesViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { }

    @observable companyListData: any = [];
    @observable companyList = new Collection<any>();
    @observable companyId: string = "0";
    @observable productYear: number = 2024; //new Date().getFullYear();
    @observable isLoading: boolean = false;
    @observable fileData: any;
    @observable fileName: string = "";
    @observable ErrorModel = new ErrorModel(new ImportAgentCertificatesValidator());
    @observable response:any;
    @observable isMessgeVisible : boolean = false;

    @action Load = async () => {
        this.response = ''
        this.isMessgeVisible = false;
        await this.ResetValidate();
        await this.getCompanies();
    }

    @action async getCompanies() {
        this.isLoading = true;
        try {
            var result = await ContractsStore.getImportForValues();
            if (result && result.length > 0) {
                this.companyListData = result;
                this.companyList.values = [];
                this.companyId = "0";
                Utils.mapListItemsToDropdown(result, this.companyList, "Select", "0");
            }
        } catch (e) {
            this.companyList.values = [];
            this.companyList.values.push({ label: "Select", value: "0" });
        }
        this.isLoading = false;
    }
    @action backToAdmin = async () => {
        routes.administration.push();
    }
    @action onDrop = (value: any, fileName: string) => {
        this.fileData = value;
        this.fileName = fileName;
    }
    @action Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action async process() {
        await this.ResetValidate();
        if (!await this.Validate()) {
            this.isLoading = true;
            var importForName = "";
            var selectedCompany = this.companyList.values.find((item: any) => { return item.value.toString() === this.companyId });
            //let carrierName: any[] = this.carrierImportList.values.length > 0 ? this.carrierImportList.values.filter(obj => obj.value === this.selectedCarrierImport) : []
            importForName = (selectedCompany ? selectedCompany.label : "");
            var blob = null;
            if (this.fileData != null && this.fileData !== "" && this.fileData !== undefined) {
                // Split the base64 string in data and contentType
                var block = this.fileData ? this.fileData.split(";") : "";
                // Get the content type of the image
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                // Convert it to a blob to upload
                blob = Utils.b64toBlob(realData, contentType, 512);
            }
            try {
                var file = blob ? { data: blob, fileName: this.fileName } : null;
                await ContractsStore.upload(this.productYear, importForName, parseInt(this.companyId), file);

                let message = {
                    status: 200,
                    title: 'Processing file, you will receive an email once import is complete.',
                    errors: { '': [] }
                }
                this.response = JSON.stringify(message)
                this.isMessgeVisible = true
            } catch (e) {

            }
            this.isLoading = false;
        }
    }
   
}

class ImportAgentCertificatesValidator extends AbstractValidator<ImportAgentCertificatesViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.companyId)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Import for is required");
        this.validateIf(input => input.productYear)
            .isNotEmpty()
            .withFailureMessage("Product Year is required");
        this.validateIf(input => input.fileData)
            .isNotEmpty()
            .withFailureMessage("File is required");
    }
}