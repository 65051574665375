import { observable, action } from "mobx";
import { Permission } from "../../infrastructure/enum/Permission";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import ViewModel from "../../infrastructure/ViewModel";
import { routes } from "../../router";
import {  UnMatchedLeadDetailModel } from "../../services/LeadService";
import LeadStore from "../../store/LeadStore";

export class UnmatchedLeadsDetailViewModel implements ViewModel {
  @observable hasPermission: boolean = true;
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }


  @action Load = async (id: number) => {
    let delay = 0;
    if(UserContext.permissions.length <= 0)
        {
            delay = 2000;
        }
        setTimeout (async () => {
          this.leadId = id;
          await this.getLeadDetail(this.leadId);
          if(UserContext.permissions.length > 0 && !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)) {
            this.hasPermission = false;
        }
        else{
          this.hasPermission = true;
        }
        }, delay);
  
  };


  Route = async (): Promise<void> => { };
  @observable isMessgeVisible: boolean = false;
  @observable isLoading: boolean = true;
  @observable response: any;
  @observable leadId: number = 0;
  @observable leadDetail: UnMatchedLeadDetailModel | undefined;
  @action onBackClick() {
    Utils.setLeadsTab(3)
    routes.unmatchedLeads.push();
  }
  @action getLeadDetail = async (leadId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};

    try {
        // var result = await LeadStore.getLeadDetail(leadId);
      let result = await LeadStore.getUnmatchedLeadDetail(leadId)
        if (result != null) {
            if (result) {
                this.leadDetail = result;
            }
            else {
                this.leadDetail = undefined;
            }
        }
    } catch (e) {
        this.leadDetail = undefined;
        this.isMessgeVisible = true;
        this.response = e.response;
    }
    this.isLoading = false;
   
  };

}
