function pad(n:number, len:number) {
  return ('000' + n).slice(-len);
}
  
export function initialize() {
  Date.prototype.toJSON = function() {
  return (pad(this.getFullYear(), 4) + '-' + 
          pad(this.getMonth() + 1, 2) + '-' + 
          pad(this.getDate(), 2) + 'T' + 
          pad(this.getHours(), 2) + ':' + 
          pad(this.getMinutes(), 2) + ':' + 
          pad(this.getSeconds(), 2) + '.' +
          pad(this.getMilliseconds(), 3) + 'Z');
    }
    Date.prototype.toISOString = function () {
        return (pad(this.getFullYear(), 4) + '-' +
            pad(this.getMonth() + 1, 2) + '-' +
            pad(this.getDate(), 2) + 'T' +
            pad(this.getHours(), 2) + ':' +
            pad(this.getMinutes(), 2) + ':' +
            pad(this.getSeconds(), 2) + '.' +
            pad(this.getMilliseconds(), 3) + 'Z');
    }
}

