import React from "react";
import { observer } from "mobx-react";
import FullCalendar, {
  EventContentArg,
  EventClickArg,
  DateSelectArg,
  EventChangeArg,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { MarketingEventsViewModel } from "../../viewModels/home/MarketingEventsViewModel";
import { Button } from "primereact/button";
import { routes } from "../../router";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";

export const MarketingEventsView: React.FC<{
  vm: MarketingEventsViewModel;
}> = observer((props) => {
  const handleEventClick = (clickInfo: EventClickArg) => {
    let id = clickInfo.event.id ? Number(clickInfo.event.id) : 0;
    routes.marketingEventDetail.push({ marketingEventId: id });
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
  };

  const handleEventChange = (changeInfo: EventChangeArg) => {
    props.vm.changeEvent(changeInfo);
  };
  const renderEventContent = (event: EventContentArg) => {
    return (
      <>
        {event.event.title}
      </>
    );
  };
 
  return (
    // <Card>
    <div className="p-grid ">
      <div className="p-col-12">
        <h2>
          <b>Marketing Event Calendar</b>
        </h2>
      </div>
      <div className="p-col-12 p-md-6 p-lg-6 sm-order">
        <p>
          Want to add something to this Calendar? {` `}
          <a
            href={`mailto:${props.vm.sendToEmail}?subject=New Marketing Event Request (${props.vm.currentMonth})`}
          >
            Contact Marketing
          </a>
        </p>
      </div>
      {Utils.hasUserPermission(Permission.AddMarketingEvent) && (
        <div className="p-col-12 p-md-6 p-lg-6 sm-order text-right">
          <Button
            id="btnAdd"
            type="button"
            className="primary-button"
            label="Create Marketing Event"
            icon="fa fa-plus"
            onClick={() => routes.addMarketingEvent.push()}
          />
        </div>
      )}


      <div className="p-col-12">
        <div className="marketing-event">
          <div className="marketing-event-main" >
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              initialView={props.vm.initialView}
              height={1075}  
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={props.vm.weekendsVisible}
              /**
               * slice() is used to achieve MobX observability on eventStore.events
               * https://mobx.js.org/best/react.html#incorrect-use-an-observable-but-without-accessing-any-of-its-properties
               */
              events={props.vm.events.slice()} //
              select={handleDateSelect}
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              eventChange={handleEventChange}
            />
          </div>

        </div>
      </div>
    </div>
  );
});

