// mobx
import { observable, action } from "mobx";
// ViewModel
import ViewModel from "../../../../infrastructure/ViewModel";
// route
import RouteList from "../../../../infrastructure/RouteList";
// other
import Collection from "../../../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { Constants } from "../../../../infrastructure/enum/Constants";
import DocumentStore from "../../../../store/DocumentStore";
import Utils from "../../../../infrastructure/Utils";
import PageContext from "../../../../infrastructure/PageContext";
import { PageMode } from "../../../../infrastructure/enum/Common";

export class AddDocViewModel implements ViewModel {
  // ViewModel functions implementation
  get CanClose(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get CanRoute(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoaded(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoading(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  Close(): void {
    throw new Error(Constants.NoMethodImplementation);
  }

  @action Load = async (docId: number | undefined, mode: PageMode) => {
    this.docId = docId ? docId : 0;
    this.pageMode = mode;
    this.fileData = null;
    switch (mode) {
      case PageMode.Add:
        this.pageTitle = "Add Document";
        break;
      case PageMode.Edit:
        this.pageTitle = "Edit Document";
        break;
      case PageMode.Update:
        this.pageTitle = "Update Document";
        break;
      case PageMode.Clone:
        this.pageTitle = "Clone Document";
        break;
      default:
        this.pageTitle = "Add Document";
        break;
    }
    await this.loadDocument();
    await this.ResetValidate();
    this.showYearError = false;
    this.showPageIdError = false;
  };

  // declaration for route
  Route = async (currentRoute: RouteList): Promise<void> => {};

  // declaration for variables
  // route id's
  @observable docId: number = 0;
  @observable pageMode: PageMode = PageMode.Add;
  @observable pageTitle: string = "Add Document";

  // lists variables
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable agentPortalPageIdList = new Collection<{
    label: string;
    value: string;
  }>();

  // page input variables
  @observable selectedCompany: number = 0;
  @observable selectedType: number = 0;
  @observable selectedProduct: number = 0;
  @observable documentTitle: string = "";
  @observable description: string = "";
  @observable fileData: any = null;
  @observable fileName: string = "";
  @observable isLoading: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable showOnAgentPortal: boolean = false;
  @observable agentPortalPageId: number = 0;
  @observable agentPortalYear: string = new Date().getFullYear().toString();
  @observable showDependantsOnagentPortal: boolean = false;
  @observable showPageIdError: boolean = false;
  @observable showYearError: boolean = false;
  @observable response: any;

  // validation model
  @observable ErrorModel = new ErrorModel(new AgentDocumentValidator());

  // Functions implementation
  @action loadDocument = async () => {
    var defaultItem = { label: "Select", value: "0" };
    var noProductItem = { label: "No Specific Product", value: "0" };
    try {
      var result = await DocumentStore.documentDetails(this.docId);
      if (result != null) {
        this.companyList.values = [];
        this.documentTypeList.values = [];
        this.productList.values = [];
        // Company
        if (result.companyList != null) {
          result.companyList.forEach((obj: any) => {
            var data = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.companyList.values.push(data);
          });
        }
        this.companyList.values.splice(0, 0, defaultItem);
        this.selectedCompany = result.companyId ? result.companyId : 0;

        // Type
        if (result.typeList != null) {
          result.typeList.forEach((obj: any) => {
            var documentData = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.documentTypeList.values.push(documentData);
          });
        }
        this.documentTypeList.values.splice(0, 0, defaultItem);
        this.selectedType = result.typeId ? result.typeId : 0;

        // Type
        if (result.productList != null) {
          result.productList.forEach((obj: any) => {
            var documentData = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.productList.values.push(documentData);
          });
        }
        this.productList.values.splice(0, 0, noProductItem);
        this.selectedProduct = result.productId ? result.productId : 0;

        this.documentTitle = result.title ? result.title : "";
        this.description = result.description ? result.description : "";

        //AgentPortalPagesList
        if (result.agentPortalPagesList != null) {
          this.agentPortalPageIdList.values = [];
          result.agentPortalPagesList.forEach((obj: any) => {
            var data = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.agentPortalPageIdList.values.push(data);
          });
        }
        this.agentPortalPageId =
          this.docId !== 0
            ? result.agentPortalPageID
              ? result.agentPortalPageID
              : this.agentPortalPageIdList.values &&
                this.agentPortalPageIdList.values.length > 0
              ? Number(this.agentPortalPageIdList.values[0].value)
              : 0
            : this.agentPortalPageIdList.values &&
              this.agentPortalPageIdList.values.length > 0
            ? Number(this.agentPortalPageIdList.values[0].value)
            : 0;
        this.agentPortalYear =
          this.docId !== 0
            ? result.agentPortalYear
              ? result.agentPortalYear.toString()
              : new Date().getFullYear().toString()
            : new Date().getFullYear().toString();
        this.setShowOnAgentPortal(
          result.showOnAgentPortal ? result.showOnAgentPortal : false
        );
      }
    } catch (e) {
      this.documentTypeList.values = [];
      this.productList.values = [];
      this.companyList.values = [];
      this.companyList.values.splice(0, 0, defaultItem);
      this.documentTypeList.values.splice(0, 0, defaultItem);
      this.productList.values.splice(0, 0, noProductItem);
      this.agentPortalPageIdList.values = [];
    }
  };
  //  Events
  @action setSelectedType = (value: number) => {
    this.selectedType = value;
  };

  @action setSelectedCompany = async (value: number) => {
    this.selectedCompany = value;
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    var defaultItem = { label: "No Specific Product", value: "0" };
    try {
      var result = await DocumentStore.getLookUpByCompanyId(value, 0, 0);
      if (result != null) {
        // Product
        if (result.products != null) {
          this.productList.values = [];
          result.products.forEach((obj: any) => {
            var data = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.productList.values.push(data);
          });
        }
      }
    } catch (e) {
      this.productList.values = [];
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.productList.values.splice(0, 0, defaultItem);
    this.selectedProduct = 0;
    this.isLoading = false;
    await this.ResetValidate();
  };
  @action setShowOnAgentPortal = (value: boolean) => {
    this.showOnAgentPortal = value;
    if (value) this.showDependantsOnagentPortal = true;
    else this.showDependantsOnagentPortal = false;
  };
  @action setAgentPortalPageId = (value: number) => {
    this.agentPortalPageId = value;
  };
  @action setAgentPortalYear = (value: string) => {
    this.agentPortalYear = value;
  };
  @action setSelectedProduct = (value: number) => {
    this.selectedProduct = value;
  };
  @action setTitle = (value: string) => {
    this.documentTitle = value;
  };
  @action setDescription = (value: string) => {
    this.description = value;
  };
  @action goBack() { 
      this.ResetAllFeildsAfterSave();
      Utils.goBack();      
  }
  @action ResetAllFeildsAfterSave = () => {
        var defaultItem = { label: "Select", value: "0" };
        var noProductItem = { label: "No Specific Product", value: "0" };
        this.documentTypeList.values = [];
        this.documentTypeList.values.splice(0, 0, defaultItem);
        this.selectedType = 0;
        this.documentTitle = "";
        this.description = "";
        this.companyList.values = [];
        this.companyList.values.splice(0, 0, defaultItem);
        this.selectedCompany = 0;
        this.productList.values = [];
        this.productList.values.splice(0, 0, noProductItem);
        this.selectedProduct = 0;
        this.setShowOnAgentPortal(false);
    };
  onDrop = (value: any, fileName: string) => {
    this.fileData = value;
    this.fileName = fileName;
  };
  @action readURL(acceptedFiles: any) {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = (_event) => {
        this.fileData = reader.result;
      };
    }
  }
  @action Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action SaveDocument = async () => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    let docId = this.docId;
    if (this.pageMode === PageMode.Clone) {
      docId = 0;
    }
    try {
      // to add following attributes after creating nSwagger with updated JSON Object
      //this.showOnAgentPortal, this.agentPortalPageId, this.agentPortalYear
      await DocumentStore.CreateUpdateDMSDocument(
        docId,
        this.documentTitle,
        this.description,
        this.selectedType,
        this.selectedCompany,
        this.selectedProduct,
        this.fileName,
        this.fileData,
        this.pageMode,
        0,
        0,
        0,
        this.showOnAgentPortal,
        this.showOnAgentPortal ? this.agentPortalPageId : undefined,
        this.showOnAgentPortal ? Number(this.agentPortalYear.trim()) : undefined
      );

      this.isLoading = false;
      this.goBack();
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };  
  @action ValidatePortalPageIdAndYear = (): boolean => {
    if (!this.showOnAgentPortal) {
      return false;
    } else {
      this.showPageIdError = this.agentPortalPageId === 0 ? true : false;
      this.showYearError = this.agentPortalYear.trim() === "" ? true : false;
      if (!this.showYearError && !this.showPageIdError) {
        return false;
      } else {
        return true;
      }
    }
  };

  @action onSave = async () => {
    await this.ResetValidate();
    if (!(await this.Validate()) && !this.ValidatePortalPageIdAndYear()) {
      if (
        (this.fileData === "" ||
          this.fileData === undefined ||
          this.fileData === null) &&
        (this.docId === 0 ||
          isNaN(this.docId) ||
          this.docId === undefined ||
          this.docId === null)
      ) {
        var responseModel = {
          status: 400,
          title: Constants.NoFile,
          errors: { "": [] },
        };
        PageContext.setResponseMessage(JSON.stringify(responseModel));
        PageContext.setIsMessageVisible(true);
        return false;
      }
      //save document
      this.SaveDocument();
    }
  };
}

class AgentDocumentValidator extends AbstractValidator<AddDocViewModel> {
  public constructor() {
    super();
    this.validateIfNumber((input) => input.selectedType)
      .isNotEmpty()
      .isNotEqualTo(0)
      .withFailureMessage("Type is required");

    this.validateIf((input) => input.fileName)
      .isNotEmpty()
      .withFailureMessage("File is required")
      .when((input) => input.pageMode !== PageMode.Edit);

    this.validateIfNumber((input) => input.selectedCompany)
      .isNotEmpty()
      .isNotEqualTo(0)
      .withFailureMessage("Carrier is required");
    this.validateIf((input) => input.description)
      .isNotEmpty()
      .withFailureMessage("Description is required");
    this.validateIf((input) => input.documentTitle)
      .isNotEmpty()
      .withFailureMessage("Title is required");
    this.validateIfString((input) => input.agentPortalYear)
      .hasMaxLength(5)
      .whenNotEmpty()
      .withFailureMessage(
        "Agent Portal Year should not contain more than 4 digits"
      );
  }
}
