import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import { ReportsViewModel } from "./../standardReports/ReportsViewModel";

export class StandardReportsListViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable ReportsViewModel: ReportsViewModel = new ReportsViewModel();

  Route = async (): Promise<void> => undefined;

  @action Load = async () => {
    await this.ReportsViewModel.Load();
  };
}
