import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import Collection from "../../../infrastructure/CollectionHelper";
import ReportStore from "../../../store/ReportStore";
import Utils from "../../../infrastructure/Utils";
import UserContext from "../../../infrastructure/UserContext";
import { routes } from "../../../router";
import { googleTagManager } from "../../../infrastructure/tracking";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../views/components/controls/IMASTemplates";
import PageContext from "../../../infrastructure/PageContext";
import IMASLog from "../../../infrastructure/IMASLog";

export class CustomReportDetailsAddEditViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable reportName: string = "";
  @observable
  public ErrorModel = new ErrorModel(new CustomReportDetailsValidator());
  @observable selectedPrivacyStatus: string = "1";
  @observable privacyStatusList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable showCustomReportDetailsDialog: boolean = false;
  @observable reportDescription: string = "";
  @observable isException: boolean = false;
  @observable exceptionMsg: string = "";
  @observable timer: any;
  @observable isLoading: boolean = false;

  @action Load = async () => {
    await this.ResetValidate();
  };

  Route = async (): Promise<void> => undefined;

  @action cancel = () => {
    this.setShowCustomReportDetailsDialog(false);
  };

  listener: createCustomReportListener | undefined;
  setListener(listener: createCustomReportListener) {
    this.listener = listener;
  }

  @action hideValidationToaster = () => {
    toast.dismiss("validation-toaster");
};

  showErrorMessageinToaster(message: string) {
    toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: false,
        closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
        className: "validation-toaster website",
        draggable: false,
        toastId: "validation-toaster",
        autoClose: 7000,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
        this.hideValidationToaster();
    }, 7000);
}
  @action save = async () => {
    try {
      if (!(await this.Validate())) {
       googleTagManager.trackAction("custom_report_created", { feature: routes.addCustomReport.name, user_id: UserContext.userId, agent_id: 0 });
      if (this.listener) {
          this.listener.createCustomReport(
            this.reportName,
            this.selectedPrivacyStatus,
            this.reportDescription
          );
        }
      }
    } catch (e:any) {
      e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          this.showErrorMessageinToaster(`${_title}`);
      });
      this.isLoading = false;
      PageContext.setIsMessageVisible(false);
      IMASLog.log("exception: " + e);
    }
  };

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action setShowCustomReportDetailsDialog = async (value: boolean) => {
    await this.reset();
    await this.ResetValidate();
    this.showCustomReportDetailsDialog = value;
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action reset = async () => {
    this.selectedPrivacyStatus = "1";
    this.reportDescription = "";
    this.isException = true;
    this.exceptionMsg = "";
  };

  @action loadReportStatusDropdowns = async () => {
    const result = await ReportStore.getAllReportStatus();
    if (result) {
      Utils.mapListItemsToDropdown(result, this.privacyStatusList, "", "");
    }
  };

  @action onFieldChange = (e: any) => {
    if (e.target) {
      //Calendar Control
      (<any>this)[e.target.id] = e.target.value;
    } else {
      //All Input Fields
      (<any>this)[e.currentTarget.id] = e.currentTarget.value;
    }
  };
}

class CustomReportDetailsValidator extends AbstractValidator<CustomReportDetailsAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.reportName)
      .isNotEmpty()
      .withFailureMessage("Custom Report Name is required");
  }
}

export interface createCustomReportListener {
  createCustomReport(
    reportName: string,
    reportPrivacy: string,
    reportDescription: string
  ): void;
}
