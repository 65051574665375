import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import ContractsStore from "../../../../store/ContractsStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import { action, observable } from "mobx";
import Utils from "../../../../infrastructure/Utils";
import DocumentStore from "../../../../store/DocumentStore";
import ProductStore from "../../../../store/ProductStore";

export class EmailAndFaxDetailsListViewModel extends BaseListViewModel<any> {
    @observable docTypeList = new Collection<any>();
    @observable companyList = new Collection<any>();
    @observable productList = new Collection<any>();
    @observable docTypeId: number = 0;
    @observable companyId: number = 0;
    @observable productId: number = -1;
    constructor()
    {
        super("Manage Email and Fax details", "Email and Fax details", routes.addEmailFaxDetails, routes.editEmailFaxDetails);
        //this.loadItem(0);
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        return await ContractsStore.getEmailAndFaxDetailsList(this.companyId, this.docTypeId, this.productId, pageIndex, pageSize, sortColumn, sortOrder);
    }

    protected async deleteItem(id: number) {
        await ContractsStore.deleteEmailAndFaxDetails(id);
    }

    /*
    protected async loadItem(id: number): Promise<void> {
        var item = await ContractsStore.getEmailAndFaxDetailsById(id);
        if (item) {
            this.companyList.values = [{ label: "All", value: '0' }].concat(Utils.mapListItem(item.companies));
            this.docTypeList.values = [{ label: "All", value: '0' }].concat(Utils.mapListItem(item.docFileTypes));
            this.productList.values = [{ label: "All", value: '-1' }, { label: "No Specific Product", value: '0' }];
        }
    }
    */
    protected async loadLookups() {
        this.productList.values = [{ label: "All", value: '-1' }, { label: "No Specific Product", value: '0' }];

        var items = await ContractsStore.getAllCompanyLookup();
        Utils.mapListItemsToDropdown(items, this.companyList);

        var fileTypeItems = await DocumentStore.getDocumentFileTypesLookup();
        Utils.mapListItemsToDropdown(fileTypeItems, this.docTypeList);
    }

    @action async setSelectedCompany(companyId: number) {
        this.companyId = companyId;

        if(companyId < 1) {
            this.productList.values = [{ label: "All", value: '-1' }, { label: "No Specific Product", value: '0' }];
        } 
        else {
            var items = await ProductStore.getReportProductList(companyId);
            items.unshift({ text: "No Specific Product", value: '0' });
            Utils.mapListItemsToDropdown(items, this.productList, "All", "-1");
            //this.productList.values.unshift({ label: "All", value: "-1" });
        }
        this.productId = -1;
    }

   

    @action goSearch = async () => {
        this.setFirstPage();
        await this.loadItems();
        
    }

    @action resetFilters = async () => {
        this.resetPaging();
        this.docTypeId = 0;
        this.companyId = 0;
        this.productId = -1;
        await this.loadItems();
    }

    resetFiltersToDefault() {
        this.docTypeId = 0;
        this.companyId = 0;
        this.productId = -1;
    }
}