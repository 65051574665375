

import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { CreateUpdatePolicyNoteModel } from "../../../services/InsuredService";

class PolicyNotesValidator extends AbstractValidator<CreateUpdatePolicyNoteModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.note)
            .isNotEmpty()
            .withFailureMessage("Notes is required");
     
    }
}

export default new PolicyNotesValidator()