import { action, computed, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import {
    CarrierManagement,
    CarrierManagementTabs,
} from "../../../infrastructure/enum/Common";
import RouteList from "../../../infrastructure/RouteList";
import Utils from "../../../infrastructure/Utils";
import ViewModel from "../../../infrastructure/ViewModel";
import { routes } from "../../../router";
import ContractsStore from "../../../store/ContractsStore";
import { CarrierCMListViewModel } from "./carrier/CarrierCMListViewModel";
import { CarrierGroupCMListViewModel } from "./carriergroup/CarrierGroupCMListViewModel";
import { Permission } from "../../../infrastructure/enum/Permission";

export class CarrierManagementViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => {
        this.handleRoute(currentRoute);
    };

    @action Load = async () => {
        this.carrierGroupList.values = [];
        
        await this.loadCarrierGrouplookup();
    };

    @observable carrierGroupList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable cmSearchInputValue: string = "";
    @observable selectedStatus: string = "";
    @observable selectedCarrierGroup: any;
    @observable selectedTabIndex?: number = 0;
    @observable statusTypeList = [
        { label: "All", value: "" },
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
    ];
    @observable isRefreshEnableCarrier: boolean = false;
    @observable isRefreshEnableCarrierGroup: boolean = false;
    @observable selectedKey: string | undefined = "";
    @observable selectedPanel: string = CarrierManagementTabs.Carrier;
    @observable SelectedViewModel: keyof typeof CarrierManagementTabs =
        CarrierManagementTabs[CarrierManagementTabs.Carrier];
    @observable SelectedBaseViewModel: ViewModel | undefined;
    @observable CarrierCMListViewModel: CarrierCMListViewModel =
        new CarrierCMListViewModel();
    @observable CarrierGroupCMListViewModel: CarrierGroupCMListViewModel =
        new CarrierGroupCMListViewModel();
    @observable carrierManagementTabItems = [
        { label: "Carrier List", value: 0 },
        { label: "Carrier Group List", value: 1 },
    ];
    @action goToAdministration = () => {
        routes.administration.replace();
    };

    @action handleTabClick = (index: number | undefined, key: string): void => {
        this.selectedTabIndex = index;
        switch (key) {
            case CarrierManagement.Carrier:
                this.handleLinkClick(CarrierManagement.Carrier);
                break;
            case CarrierManagement.CarrierGroup:
                this.handleLinkClick(CarrierManagement.CarrierGroup);
                break;
        }
    };

    @action handleLinkClick = (item: string | undefined): void => {
        if (item) {
            this.CarrierCMListViewModel.isCarrierToast = false;
            this.CarrierGroupCMListViewModel.isCarrierGroupToast = false;
            this.selectedKey = item;
            switch (item) {
                case CarrierManagement.Carrier:
                    this.selectedPanel = CarrierManagement.Carrier.toString();
                    this.selectedKey = CarrierManagementTabs.Carrier;
                    routes.carrierCMList.push();
                    return;
                case CarrierManagement.CarrierGroup:
                    this.selectedPanel = CarrierManagement.CarrierGroup.toString();
                    this.selectedKey = CarrierManagementTabs.CarrierGroup;
                    routes.carrierGroupCMList.push();
                    return;
                default:
                    this.selectedPanel = CarrierManagement.Carrier.toString();
                    this.selectedKey = CarrierManagementTabs.Carrier;
                    return;
            }
        }
    };

    @computed get getTabIndex(): number {
        let tabIndex = 0;
        switch (this.selectedKey) {
            case CarrierManagementTabs.Carrier:
                tabIndex = 0;
                break;
            case CarrierManagementTabs.CarrierGroup:
                tabIndex = 1;
                break;
            default:
                tabIndex = 0;
                break;
        }
        return tabIndex;
    }

    @action handleRoute(currentRoute: RouteList) {
        let name = currentRoute.name;
        switch (name) {
            case routes.carrierCMList.name:
                this.selectedPanel = CarrierManagement.Carrier;
                this.selectedKey = CarrierManagementTabs.Carrier;
                this.SelectedViewModel = CarrierManagementTabs.Carrier;
                this.SelectedBaseViewModel = this.CarrierCMListViewModel;
                this.loadCarrierDefault("Carrier");
                this.CarrierCMListViewModel.Load();
                break;
            case routes.carrierManagement.name:
                if (!Utils.hasUserPermission(Permission.ViewCarrier)) {
                    this.selectedPanel = CarrierManagementTabs.CarrierGroup;
                    this.selectedKey = CarrierManagementTabs.CarrierGroup;
                    this.SelectedViewModel = CarrierManagementTabs.CarrierGroup;
                    this.SelectedBaseViewModel = this.CarrierGroupCMListViewModel;
                    this.loadCarrierDefault("CarrierGroup");
                    this.CarrierGroupCMListViewModel.Load();
                }
                else
                {
                this.selectedPanel = CarrierManagement.Carrier;
                this.selectedKey = CarrierManagementTabs.Carrier;
                this.SelectedViewModel = CarrierManagementTabs.Carrier;
                this.SelectedBaseViewModel = this.CarrierCMListViewModel;
                this.loadCarrierDefault("Carrier");
                this.CarrierCMListViewModel.Load();
                }
                break;
            case routes.carrierGroupCMList.name:
                this.selectedPanel = CarrierManagement.CarrierGroup;
                this.selectedKey = CarrierManagementTabs.CarrierGroup;
                this.SelectedViewModel = CarrierManagementTabs.CarrierGroup;
                this.SelectedBaseViewModel = this.CarrierGroupCMListViewModel;
                this.loadCarrierDefault("CarrierGroup");
                this.CarrierGroupCMListViewModel.Load();
                break;
        }
    }

    @action loadCarrierGrouplookup = async () => {
        var result = await ContractsStore.getAllCompanyGroupLookup();
        if (result) {
            Utils.mapListItemsToDropdown(result, this.carrierGroupList, "", "");
        }
    };

    @action onFieldChange = (e: any) => {
        if (e.target) {
            //Calendar Control
            (<any>this)[e.target.id] = e.target.value;
        } else {
            //All Input Fields
            (<any>this)[e.currentTarget.id] = e.currentTarget.value;
        }
        if (this.selectedPanel === CarrierManagement.Carrier) {
            this.CarrierCMListViewModel.cmSearchInputValue = this.cmSearchInputValue;
            if (this.cmSearchInputValue.length == 0 || this.cmSearchInputValue.length > 2) {
                this.onSession();
                this.CarrierCMListViewModel.loadItems();
            }
        } else {
            this.CarrierGroupCMListViewModel.cmSearchInputValue = this.cmSearchInputValue;
            if (this.cmSearchInputValue.length == 0 || this.cmSearchInputValue.length > 2) {
                this.onSession();
                this.CarrierGroupCMListViewModel.loadItems();
            }
        }
        this.refreshHandle();
    };

    @action setCarrierGroups = (value: string) => {
        this.selectedCarrierGroup = value;
        this.CarrierCMListViewModel.selectedCarrierGroup = value;
        this.CarrierCMListViewModel.loadItems();
        this.onSession();
        this.refreshHandle();
    };

    @action setStatusDropdown = (value: string) => {
        if (this.selectedPanel === CarrierManagement.Carrier) {
            this.selectedStatus = value;
            this.CarrierCMListViewModel.selectedStatus = value;
            this.onSession();
            this.refreshHandle();
            this.CarrierCMListViewModel.loadItems();
        } else {
            this.selectedStatus = value;
            this.CarrierGroupCMListViewModel.selectedGlobalStatus = value;
            this.onSession();
            this.refreshHandle();
            this.CarrierGroupCMListViewModel.loadItems();
        }

    };

    @action goSearch = () => {
        if (this.selectedPanel === CarrierManagement.Carrier) {
            this.CarrierCMListViewModel.loadItems();
        } else {
            this.CarrierGroupCMListViewModel.loadItems();
        }
        this.onSession();
        this.refreshHandle();
    };

    @action refresh = () => {
        if (this.selectedPanel === CarrierManagement.Carrier) {
            sessionStorage.removeItem("searchInputValue");
            sessionStorage.removeItem("selectedStatus");
            sessionStorage.removeItem("selectedGroup");
            this.isRefreshEnableCarrier = false;
        } else {
            sessionStorage.removeItem("selectedStatusGroup");
            sessionStorage.removeItem("searchInputValueGroup");
            this.isRefreshEnableCarrierGroup = false;
        }
        this.resetCarrierFilters();
        this.CarrierCMListViewModel.refresh();
        this.CarrierCMListViewModel.loadItems();
        this.CarrierGroupCMListViewModel.resetFilters();
        this.CarrierGroupCMListViewModel.loadItems();
    };

    @action resetCarrierFilters() {
        this.cmSearchInputValue = "";
        this.selectedStatus = "";
        this.selectedCarrierGroup = [];
    }

    @action loadCarrierDefault = async (type: string) => {
        this.CarrierCMListViewModel.isCarrierToast = false;
        this.CarrierGroupCMListViewModel.isCarrierGroupToast = false;
        if (type == "Carrier") {
            const carrierGroupIds = sessionStorage.getItem("selectedGroup") || null;
            const carrierSearchText =
                sessionStorage.getItem("searchInputValue") || "";
            const carrierStatus = sessionStorage.getItem("selectedStatus") || "";
            if (carrierSearchText != null) {
                this.cmSearchInputValue = String(carrierSearchText);
                this.CarrierCMListViewModel.cmSearchInputValue =
                    String(carrierSearchText);
            }
            this.selectedStatus = String(carrierStatus);
            this.CarrierCMListViewModel.selectedStatus = String(carrierStatus);
            if (carrierGroupIds != null && carrierGroupIds != "undefined") {
                var cgArray = carrierGroupIds.split(",").map((i: any) => i);
                this.selectedCarrierGroup = cgArray;
                this.CarrierCMListViewModel.selectedCarrierGroup = cgArray;
            } else {
                this.selectedCarrierGroup = undefined;
                this.CarrierCMListViewModel.selectedCarrierGroup = undefined;
            }
        } else {
            const cgSearchInput =
                sessionStorage.getItem("searchInputValueGroup") || "";
            const cgStatus = sessionStorage.getItem("selectedStatusGroup") || "";
            this.cmSearchInputValue = String(cgSearchInput);
            this.CarrierGroupCMListViewModel.cmSearchInputValue =
                String(cgSearchInput);
            this.selectedStatus = String(cgStatus);
            this.CarrierGroupCMListViewModel.selectedStatus = String(cgStatus);
        }
    };

    @action refreshHandle = async () => {
        if (this.selectedPanel === CarrierManagement.Carrier) {
            if (this.selectedStatus || this.cmSearchInputValue || sessionStorage.getItem("selectedGroup"))
                this.isRefreshEnableCarrier = true;
            else
                this.isRefreshEnableCarrier = false;
        } else if (this.selectedPanel === CarrierManagement.CarrierGroup) {
            if (this.selectedStatus || this.cmSearchInputValue)
                this.isRefreshEnableCarrierGroup = true;
            else
                this.isRefreshEnableCarrierGroup = false;
        }
    }

    @action onSession = async () => {
        if (this.selectedPanel === CarrierManagement.Carrier) {
            sessionStorage.setItem("selectedStatus", "" + this.selectedStatus);
            sessionStorage.setItem("searchInputValue", "" + this.cmSearchInputValue);
            if (this.selectedCarrierGroup)
                sessionStorage.setItem("selectedGroup", "" + this.selectedCarrierGroup);
        } else {
            sessionStorage.setItem("selectedStatusGroup", "" + this.selectedStatus);
            sessionStorage.setItem("searchInputValueGroup", "" + this.cmSearchInputValue);
        }
    };

    @action onClearSession = async () => {
        sessionStorage.removeItem("searchInputValue");
        sessionStorage.removeItem("selectedStatus");
        sessionStorage.removeItem("selectedGroup");
        sessionStorage.removeItem("selectedStatusGroup");
        sessionStorage.removeItem("searchInputValueGroup");
    };
}
