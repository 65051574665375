import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import Collection from "../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../store/ContractsStore";
import { ContractSummaryListView } from "../../../services/ContractService";
import Utils from "../../../infrastructure/Utils";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import ProducerStore from "../../../store/ProducerStore";
export class ContractSummaryViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async (agentId: number) => {
        this.agentId = agentId;
        if (this.agentId > 0) {
            var result = await ProducerStore.getAgentSummary(+this.agentId);
            if (result && result.name) {
                this.selectedAgent = "" + result.name;
            }
        }
        await this.loadAllLookupList(0, this.agentId);
        await this.loadSummaryDetails();
    };
    Route = async (currentRoute: RouteList): Promise<void> => { }    
    @observable isShown: boolean = false;
    @observable listViewActions: boolean = false;
    @observable isRefreshEnableCarrier: boolean = false;
    @observable isSortAscending: boolean = true;

    @observable isLoading: boolean = false;
    @observable agentId: number = 0;
    @observable rows: number = 5;
    @observable startIndex: number = 0;
    @observable totalRecords: number = 0;
    @observable first: number = 0;
    @observable sortColumn: string = 'contractId';
    @observable sortAscending: boolean = false;
    @observable response: any
    @observable isMessageVisible: boolean = false;
    @observable contractSummaryList = new Collection<ContractSummaryListView>()
    @observable selectedStatus: string = "0";
    @observable selectedCompany: any;
    @observable selectedAgent: any = "";  
    @observable
    status = new Collection<{
        key: number;
        label: string;
        value: string;
    }>();
    @observable companyList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable serachInputValue: string = "";
    @observable searchParam: string | undefined = "";
    @action setSortOrder() {
        this.sortAscending = !this.sortAscending;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }

    @action noRecordsFound() {
        this.totalRecords = 0;
        this.contractSummaryList.values = [];
    }
@action refresh = () => {
    this.serachInputValue = "";
    this.searchParam = "";
    this.selectedCompany = "0";
    this.selectedStatus = "1";
    this.isRefreshEnableCarrier = false;
    this.noRecordsFound();
    this.goSearch();    
    };
    @action isHideRefreshButton() {
        if (this.selectedStatus != "1" || this.serachInputValue || this.selectedCompany != "0") {
            this.isRefreshEnableCarrier = true;
        }
        else { this.isRefreshEnableCarrier = false; }
    }
    @action downloadContractSummaryReport = async (fileType: number) => {
        if (this.searchParam) {
            if (isNaN(Date.parse(this.searchParam))) {
                this.searchParam = this.searchParam.replace("/", "$");
            }
        }
        var res = await ContractsStore.downloadContractSummaryReport(this.agentId, fileType, this.selectedCompany, parseInt(this.selectedStatus), this.searchParam)
        if (res) {
            this.downloadReport(res)
        }
    }
    @action loadAllLookupList = async (contractId: number, agentId: number) => {
        var data = [
            { text: "Active & Pending", value: "1" },
            { text: "Terminated", value: "2" },
        ];
        this.loadstatusList(data);

        var items = await ContractsStore.getAllCompanyLookup();
        Utils.mapListItemsToDropdown(items, this.companyList, "All");
        this.selectedCompany = "0"

    };
    @action loadstatusList = async (data?: any[]) => {
        this.status.values = [];
        if (data != null) {
            let key = 1;
            data.forEach((obj) => {
                var dataO = {
                    key: key,
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value : "",
                };
                key++;
                this.status.values.push(dataO);
            });
        }
        this.status.values.splice(0, 0, { key: 0, label: "All", value: "0" });
        this.selectedStatus = "1";
    };
    @action goSearch = () => {
        this.startIndex = 0;
        this.first = 0;
        this.loadSummaryDetails();
    };
    @action setSelectedCompany = (value: string) => {
        this.selectedCompany = value;
        this.isHideRefreshButton();
        this.loadSummaryDetails(); 
    };
    @action searchIntervals = (text: string) => {
        this.isLoading = true;
        this.serachInputValue = "";
        this.searchParam = "";
        this.serachInputValue = text;
        this.isHideRefreshButton(); 
        if (text.length > 2) {
            this.searchParam = text;
            setTimeout(() => {
                this.loadSummaryDetails();
                this.isLoading = false;
            }, 1000);
              
        }
        if (text.length==0) {
            this.searchParam = "";
            this.startIndex = 0;
            this.first = 0;
            this.loadSummaryDetails();
            this.isLoading = false;
           
        } 
        
    };

    
    @action resetFilters = async () => {
        this.searchParam = "";
        this.serachInputValue = "";
        this.selectedStatus = "1";
        this.startIndex = 0;
        this.first = 0;
        this.selectedCompany = "0";
        await this.loadSummaryDetails()

    };
    @action downloadReport = async (result: any) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : "Report";
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };


    @action loadSummaryDetails = async () => {
        this.isLoading = true;
        this.isMessageVisible = false;
        this.response = "";
        this.searchParam = this.serachInputValue;
        if (this.searchParam) {
            if (isNaN(Date.parse(this.searchParam))) {
                this.searchParam = this.searchParam.replace("/", "$");
            }
        }
        try {            
            const result = await ContractsStore.getContractSummaryDetails(this.agentId, this.startIndex, this.rows, this.sortColumn, this.sortAscending, this.selectedCompany, parseInt(this.selectedStatus), this.searchParam
            )
            if (result) {
                if (result.recordCount) {
                    this.contractSummaryList.values = [];
                    this.totalRecords = result.recordCount
                    this.contractSummaryList.values = result.data ? result.data : []
                }
                else {
                    this.noRecordsFound();
                }

            }
            else {
                this.noRecordsFound();
            }
            this.isLoading = false;
        }
        catch (e) {
            this.response = e.response;
            this.isMessageVisible = true;
            this.isLoading = false;
        }
    }
    @action handleClick = (id: string) => { 
        let showState = this.isShown;
        const menulistDiv = document.getElementById(id);
        // code to hide all menu lists
        const menuListAllDivs = Array.from(
          document.getElementsByClassName(
            "custom-report-menu-list"
          ) as HTMLCollectionOf<HTMLElement>
        );
        if (menulistDiv !== null && menuListAllDivs !== null) {
          for (let i = 0; i < menuListAllDivs.length; i++) {
            if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
              menuListAllDivs[i].style.display = "none";
            }
          }
    
          // code to open menu list
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "block";
    
          // code to remove backgroud of svg
          for (let i = 0; i < menuListAllDivs.length; i++) {
            const svgSpan = document.getElementById(
              "custom-temp-" + menuListAllDivs[i].id
            ) as HTMLElement;
            if (svgSpan !== null && menuListAllDivs[i].id !== id) {
              svgSpan.className = "custom-report-menuspan";
            }
          }
          const svgDiv = document.getElementById(
            "custom-temp-" + id
          ) as HTMLElement;
          if (svgDiv !== null) {
            svgDiv.className =
              menulistDiv.style.display === "block"
                ? "custom-report-menu-active"
                : "custom-report-menuspan";
            menulistDiv.style.display === "block"
              ? (this.listViewActions = true)
              : (this.listViewActions = false);
          }
        }
    
        window.onclick = function (event) {
          const menuListAllDivs = Array.from(
            document.getElementsByClassName(
              "custom-report-menu-list"
            ) as HTMLCollectionOf<HTMLElement>
          );
          const svgDiv = document.getElementById(
            "custom-temp-" + id
          ) as HTMLElement;
          const menulistDiv = document.getElementById(id);
          if (showState && menulistDiv) {
            for (let i = 0; i < menuListAllDivs.length; i++) {
              menuListAllDivs[i].style.display = "none";
              menulistDiv.style.display =
                menulistDiv.style.display === "block" ? "none" : "none";
              svgDiv.className =
                menulistDiv.style.display === "block"
                  ? "custom-report-menuspan"
                  : "custom-report-menuspan";
              showState = false;
            }
    
            const activeClassName = document.querySelector(
              ".custom-report-menu-active"
            );
          } else {
            showState = true;
          }
        };
        this.isShown = showState;
        };
    
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadSummaryDetails();
    }   
}