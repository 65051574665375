import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import ProducerStore from "../../../store/ProducerStore";
import IMASLog from "../../../infrastructure/IMASLog";
import { CreateUpdateContactModel } from "../../../services/ProducerService";
import PageContext from "../../../infrastructure/PageContext";
import Collection from "../../../infrastructure/CollectionHelper";
import {
  ContactType,
  PhoneType,
  EmailType,
  AlternativePointOfContactType,
  PhoneTypeValue,
  EmailTypeValue,
  AlternativePointOfContactTypeValue,
} from "../../../infrastructure/enum/Common";
import Utils from "../../../infrastructure/Utils";
import ContractsStore from "../../../store/ContractsStore";
import { AxiosResponse } from "axios";
import { Permission } from "../../../infrastructure/enum/Permission";

export class AddEditContactViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {};
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable isMessgeVisible: boolean = false;
  @observable isEdit: boolean = false;
  @observable agentId: number = 0;
  @observable contactId: number = 0;
  @observable response: any;
  @observable isLoading: boolean = false;
  @observable isDefaultContact: boolean = false;
  @observable isCommissionContact: boolean = false;
  @observable isNotValidContact: boolean = false;
  @observable contactValueLabel: string = ContactType.Email;
  @observable
  public ErrorModelContact = new ErrorModel(new ContactValidatory());
  @action
  public ValidateContact = async () => {
    return this.ErrorModelContact.Validate(this);
  };
  @action
  public ResetValidateContact = async () => {
    return this.ErrorModelContact.ResetValidation(this);
  };
  listener: ContactListener | undefined;
  @observable commissionSelectedPreferenceDefaultInitial: any = [];
  @observable commissionSelectedPreferenceDefault: any = [];
  @observable commissionSelectedPreferenceEdit: boolean = false;
  @observable showDefaultOverrideMessage: boolean = false;

  @observable contactType: string = "";
  @observable contactValue: string = "";
  @observable contactDescription: string = "";
  @observable contactTypeList = new Collection<any>();
  @observable commissionPreference = [
    { label: "Default", value: "0" },
    { label: "Commission", value: "1" },
    { label: "Unset", value: "2" },
  ];
  @action setContactListener(listener: ContactListener) {
    this.listener = listener;
  }
  @action setContactVale(value: string) {
    this.isNotValidContact = false;
    this.contactValue = value;
  }

  @action setCommissionPreferenceType(e: any) {
    let tempArray = [...this.commissionSelectedPreferenceDefault];
    if (e.checked) tempArray.push(e.value);
    else tempArray.splice(tempArray.indexOf(e.value), 1);
    this.commissionSelectedPreferenceDefault = [];
    this.commissionSelectedPreferenceDefault = tempArray;
  }

  @action setContactType(value: string) {
    this.contactType = value;
    this.contactValue = "";
    this.commissionSelectedPreferenceDefault = [];
    this.commissionSelectedPreferenceDefault =
      this.commissionSelectedPreferenceDefaultInitial;
    this.commissionSelectedPreferenceEdit = (
      this.commissionSelectedPreferenceDefaultInitial || []
    ).includes("0");
    switch (value) {
      case "1":
        this.contactValueLabel = ContactType.Email;
        this.emailType = EmailTypeValue.Work;
        break;
      case "2":
        this.contactValueLabel = ContactType.TextMessage;
        break;
      case "3":
        this.contactValueLabel = ContactType.PhoneNumber;
        this.phoneType = PhoneTypeValue.Other;
        break;
      case "4":
        this.contactValueLabel = ContactType.Name;
        this.alternativePOCType = AlternativePointOfContactTypeValue.Primary;
        break;
    }
    this.isNotValidContact = false;
    this.ResetValidateContact();
  }
  @action setCommisionContact(value: boolean) {
    this.isCommissionContact = value;
  }
  @action setDefaultContact(value: boolean) {
    this.isDefaultContact = value;
  }
  @action setContactDescription(value: string) {
    this.contactDescription = value;
  }
  @action setContactId(value: number) {
    this.contactId = value;
  }
  @action setAgentId(value: number) {
    this.agentId = value;
  }
  @action cancel = async () => {
    if (this.listener) {
      this.listener.cancelAddEditContact();
    }
    this.enableSMS = false;
    this.commissionSelectedPreferenceDefault = [];
  };

  @action setSMS = async (value: boolean) => {
    this.enableSMS = value;
  };

  @action validateContactValue = async () => {
    this.isNotValidContact = false;
    this.contactValue = this.contactValue.trim();
    if (this.contactValue === "") {
      this.isNotValidContact = true;
    } else {
      this.isNotValidContact = false;
    }
    if (this.contactType === "1") {
      // eslint-disable-next-line
      var result = this.contactValue.match(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      );
      if (result) {
        if (!Utils.hasUserPermission(Permission.OverrideEmailValidation)) {
          let emailValidationResponse: AxiosResponse =
            await ProducerStore.validateEmail(this.contactValue);
          if (
            emailValidationResponse &&
            emailValidationResponse.data &&
            emailValidationResponse.data.isValid === true
          ) {
            this.isNotValidContact = false;
          } else {
            this.isNotValidContact = true;
          }
        } else {
          this.isNotValidContact = false;
        }
      } else {
        this.isNotValidContact = true;
      }
    }
    if (this.contactType === "3") {
      var phoneResult = this.contactValue.match(
        /^(((\d{3})(\d{3})(\d{4}))|((\(\d{3}\))[\s]?\d{3}[\-]\d{4})|(\d{3}[\s]\d{3}[\s]\d{4}))$/
      );
      if (phoneResult) {
        this.isNotValidContact = false;
      } else {
        this.isNotValidContact = true;
      }
    }
  };

  @observable phoneTypeList = new Collection<any>();
  @observable phoneType: number = PhoneTypeValue.Other;
  @observable enableSMS: boolean = false;
  @observable emailTypeList = new Collection<any>();
  @observable emailType: number = EmailTypeValue.Work;
  @observable alternativePOCTypeList = new Collection<any>();
  @observable alternativePOCType: number =
    AlternativePointOfContactTypeValue.Primary;

  @action setphoneType = async (value: string) => {
    this.phoneType = parseInt(value);
    if (this.phoneType !== PhoneTypeValue.Mobile) {
      this.setSMS(false);
    }
  };

  @action setEmailType = async (value: string) => {
    this.emailType = parseInt(value);
  };

  @action setAlternativePOCType = async (value: string) => {
    this.alternativePOCType = parseInt(value);
  };
  @observable isLookupsLoaded = false;
  @action async loadLookups() {
    if (!this.isLookupsLoaded) {
      let items = await ContractsStore.getContactLookups();
      Utils.mapListItemsToDropdown(
        items.emailType || [],
        this.emailTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.phoneType || [],
        this.phoneTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.contactType || [],
        this.contactTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.alternativePointOfContactType || [],
        this.alternativePOCTypeList,
        "",
        ""
      );
      this.isLookupsLoaded = true;
    }
  }

  @action addContact = async () => {
    this.isMessgeVisible = false;
    this.isNotValidContact = false;
    if (!(await this.ValidateContact())) {
      await this.validateContactValue();
      if (this.isNotValidContact) return true;
      this.isLoading = true;
      this.isMessgeVisible = false;
      try {
        var dto: CreateUpdateContactModel = {
          contactTypeId: +this.contactType,
          agentId: this.agentId,
          contactValue:
            this.contactType === "3"
              ? this.contactValue.replace(/[^\d]/g, "")
              : this.contactValue,
          description: this.contactDescription,
          isDefault: (this.commissionSelectedPreferenceDefault || []).includes(
            "0"
          ),
          isCommission: (
            this.commissionSelectedPreferenceDefault || []
          ).includes("1"),
          dateCreated: new Date(),
          enableSMS:
            this.contactType === "3" && this.phoneType === PhoneTypeValue.Mobile
              ? this.enableSMS
              : undefined,
          phoneTypeId: this.phoneType ? this.phoneType : undefined,
          emailTypeID: this.emailType ? this.emailType : undefined,
          alternativePointOfContactTypeID: this.alternativePOCType
            ? this.alternativePOCType
            : undefined,
        };

        if (!this.isEdit) {
          await ProducerStore.addContact(this.agentId, dto);
        } else {
          dto.id = this.contactId;
          await ProducerStore.editContact(this.agentId, dto);
        }
        PageContext.setIsMessageVisible(false);
        if (this.listener) {
          this.listener.cancelAddEditContact();
          this.listener.addEditContactListener();
        }
        this.isLoading = false;
        this.commissionSelectedPreferenceDefault = [];
      } catch (error: any) {
        PageContext.setIsMessageVisible(false);
        this.isLoading = false;
        error.text().then((_responseText: any) => {
          this.response = _responseText;
          this.isMessgeVisible = true;
        });
      }
    }
  };
}

export interface ContactListener {
  addEditContactListener(): void;
  cancelAddEditContact(): void;
  // updateLevelId(levelId: number): void;
}
class ContactValidatory extends AbstractValidator<AddEditContactViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.contactType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .isNotEqualTo("None")
      .isNotEqualTo("NONE")
      .withFailureMessage("Type is required");
    this.validateIfString((input) => input.contactValue)
      .isNotEmpty()
      .withFailureMessage("Value is required");
  }
}
