import { action, computed, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import { routes } from "../../../../router";
import Collection from "../../../../infrastructure/CollectionHelper";
import {
  AlternativePointOfContactType,
  AlternativePointOfContactTypeValue,
  CarrierManagement,
  ContactType,
  EmailType,
  EmailTypeValue,
  PhoneType,
  PhoneTypeValue,
} from "../../../../infrastructure/enum/Common";
import RouteList from "../../../../infrastructure/RouteList";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import UserContext from "../../../../infrastructure/UserContext";
import Utils from "../../../../infrastructure/Utils";
import ContractsStore from "../../../../store/ContractsStore";
import IMASLog from "../../../../infrastructure/IMASLog";
import { ContactModel } from "../../../../services/ContractService";
import { AbstractValidator } from "fluent-ts-validator";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import PageContext from "../../../../infrastructure/PageContext";

export class CarrierCMContactViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => undefined;

  @action Load = async () => {};

  @observable rows: number = 25;
  @observable totalContactRecords: number = 0;
  @observable companyId: number = 0;
  @observable isLoading: boolean = false;
  @observable exceptionMessage: any;
  @observable response: any = "";
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable isDeleteContactConfirm: boolean = false;
  @observable selectContactId: number = 0;
  @observable selectContactTypeId: number = 0;
  @observable title: string = "";
  @observable isContactDialogVisible: boolean = false;
  @observable contactId: number = 0;
  @observable isMessgeVisible: boolean = false;
  @observable isEdit: boolean = false;
  @observable isNotValidContact: boolean = false;
  @observable contactValueLabel: string = ContactType.Email;
  @observable contactPlaceHolder: string = "abc@mail.com";
  @observable contactErrMsg: string = "email address.";
  @observable contactDefault: any | undefined = undefined;
  @observable isLookupsLoaded: boolean = false;
  @observable addContactDto: any | undefined = [];
  @observable
  public ErrorModelContact = new ErrorModel(new ContactValidatory());
  @action
  public ValidateContact = async () => {
    return this.ErrorModelContact.Validate(this);
  };
  @action
  public ResetValidateContact = async () => {
    return this.ErrorModelContact.ResetValidation(this);
  };
  @observable contactType: string = "";
  @observable contactValue: string = "";
  @observable notes: string = "";
  @observable disableSave: boolean = true;
  @observable contactTypeList = new Collection<any>();
  @observable ContactList = new Collection<any>();
  @observable phoneTypeList = new Collection<any>();
  @observable emailTypeList = new Collection<any>();
  @observable alternativePOCTypeList = new Collection<any>();
  @observable phoneType: number = PhoneTypeValue.Mobile;
  @observable emailType: number = EmailTypeValue.Work;
  @observable alternativePOCType: number =
    AlternativePointOfContactTypeValue.Primary;
  @observable disbaleToolTipOnSave: boolean = false;
  @action async loadLookups() {
    this.disbaleToolTipOnSave = false;
    if (!this.isLookupsLoaded) {
      let items = await ContractsStore.getContactLookups();
      //replace Others with Other type
      var otherType =
        items.phoneType &&
        items.phoneType.filter((i) => i.text == PhoneType.Others);
      if (otherType && otherType.length > 0) {
        otherType[0].text = PhoneType.Other;
      }

      Utils.mapListItemsToDropdown(
        items.emailType || [],
        this.emailTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.phoneType || [],
        this.phoneTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.contactType || [],
        this.contactTypeList,
        "",
        ""
      );
      Utils.mapListItemsToDropdown(
        items.alternativePointOfContactType || [],
        this.alternativePOCTypeList,
        "",
        ""
      );
      this.isLookupsLoaded = true;
    }
  }

  @action loadContact(companyContacts: any) {
    if (companyContacts) {
      this.totalContactRecords =
        companyContacts.length > 0 ? companyContacts.length : 0;

      this.sortContacts(companyContacts || []);
    } else {
      this.noContactRecordsFound();
    }
  }

  @action loadContacts = async () => {
    try {
      this.ContactList.values = [];
      var customerContacts = await ContractsStore.getAllContact(this.companyId);
      if (customerContacts != null) {
        if (customerContacts.data) {
          this.totalContactRecords =
            customerContacts.data.length > 0 ? customerContacts.data.length : 0;
          this.sortContacts(customerContacts.data);
        }
      } else {
        this.noContactRecordsFound();
      }
    } catch (e: any) {
      IMASLog.log("exception from store: " + e.value);
      this.noContactRecordsFound();
    }
    this.isLoading = false;
  };
  @action onRowClick = async (data: any) => {
    this.contactDefault = data.default;
    this.selectContactId = data.id;
    this.selectContactTypeId = Utils.getContactTypeId("" + data.contactType);
  };
  @action cancel = async () => {
    this.isContactDialogVisible = false;
    this.resetContactForm();
  };

  @action addContact = async () => {
    this.isNotValidContact = false;
    if (!(await this.ValidateContact())) {
      await this.validateContactValue();
      if (this.isNotValidContact) return true;
      try {
        var dto: any = {
          id: this.contactId,
          companyId: this.companyId,
          contactTypeId: +this.contactType,
          contactValue:
            this.contactType === "3"
              ? this.contactValue.replace(/ /g, "")
              : this.contactValue,
          dateCreated: new Date(),
          phoneTypeId: this.phoneType,
          emailTypeId: this.emailType,
          alternativePointOfContactTypeId: this.alternativePOCType,
          createdByUserId: UserContext.getUserId(),
          notes: this.notes
        };

        if (this.companyId == 0) {
          await this.addEditContact(dto);
        } else {
          if (this.contactId === 0) {
            await ContractsStore.addContact(dto);
          } else {
            dto.id = this.contactId;
            await ContractsStore.editContact(dto);
          }
          await this.setContactDialogVisibility(false);
          await this.loadContacts();
        }
        PageContext.setIsMessageVisible(false);
        this.resetContactForm();       
        this.isLoading = false;
      } catch (error) {
        PageContext.setIsMessageVisible(false);
        this.isLoading = false;
        setTimeout(() => {
          PageContext.setIsMessageVisible(false);
          let message = JSON.parse(PageContext.responseMessage);
          if (message && message.status === 400) {
            this.response = PageContext.responseMessage;
            this.isMessgeVisible = true;
          }
        }, 100);
        IMASLog.log("exception: " + error);
      }
    }
  };

  private async addEditContact(dto: any) {
    var id = new Date().getTime();
    var isValid = false;
    if (this.contactType == "3") {
      isValid = true; //await ContractsStore.verifyContactPhoneNumber(dto);
    } else {
      isValid = true;
    }

    if (isValid) {
      if (this.contactId > 0) {
        var contact = this.addContactDto.find((item: any) => {
          return item.id === this.contactId;
        });
        if (contact) {
          contact.contactTypeId = +this.contactType;
          contact.contactValue = this.contactValue;
          contact.dateCreated = new Date();
          contact.phoneTypeId = this.phoneType;
          contact.emailTypeId = this.emailType;
          contact.alternativePointOfContactTypeId = this.alternativePOCType;
          contact.createdByUserId = UserContext.getUserId();
          contact.notes = this.notes;
        }

        var contactobj = this.ContactList.values.find((item: any) => {
          return item.id === this.contactId;
        });
        if (contactobj) {
          contactobj.contactTypeId = +this.contactType;
          contactobj.contactType =
            this.contactType === "4"
              ? "Alternative Point of Contact"
              : this.contactValueLabel;
          contactobj.contactValue = this.contactValue;
          contactobj.dateCreated = new Date();
          contactobj.phoneType = Utils.getLabelByValue(
            this.phoneTypeList.values,
            this.phoneType
          );
          contactobj.emailType = Utils.getLabelByValue(
            this.emailTypeList.values,
            this.emailType
          );
          contactobj.alternativePointOfContactType = Utils.getLabelByValue(
            this.alternativePOCTypeList.values,
            this.alternativePOCType
          );
          contactobj.phoneTypeId = this.phoneType;
          contactobj.emailTypeId = this.emailType;
          contactobj.alternativePointOfContactTypeId = this.alternativePOCType;
          contactobj.createdByUserId = UserContext.getUserId();
          contactobj.notes = this.notes;
        }
      } else {
        dto.id = id;
        this.addContactDto.push(dto);
        this.ContactList.values.push({
          id: id,
          default: false,
          commission: false,
          contactTypeId: +this.contactType,
          contactType:
            this.contactType === "4"
              ? "Alternative Point of Contact"
              : this.contactValueLabel,
          contactValue:
            this.contactType === "3"
              ? this.contactValue.replace(/ /g, "")
              : this.contactValue,
          dateCreated: new Date(),
          phoneType: Utils.getLabelByValue(
            this.phoneTypeList.values,
            this.phoneType
          ),
          emailType: Utils.getLabelByValue(
            this.emailTypeList.values,
            this.emailType
          ),
          alternativePointOfContactType: Utils.getLabelByValue(
            this.alternativePOCTypeList.values,
            this.alternativePOCType
          ),
          phoneTypeId: this.phoneType,
          emailTypeId: this.emailType,
          alternativePointOfContactTypeId: this.alternativePOCType,
          createdByUserId: UserContext.getUserId(),
          notes: this.notes
        });
      }
      await this.setContactDialogVisibility(false);
    }
  }

  @action setContactValue(value: string) {
    console.log(
      "this.contactValue -- 1  ",
      value,
      this.contactId,
      this.getPhoneNumber("" + value)
    );

    this.contactValue = this.getPhoneNumber("" + value);

    if(this.contactValue) this.disbaleToolTipOnSave = true;
    else this.disbaleToolTipOnSave = false;

    this.enableDisableSaveButton();
  }

    @action setNotes = async (value: string) => {
        this.notes = value;
    }

  @action setContactValueBlur = async (value: string) => {
    this.isNotValidContact = false;
    if (this.contactValue) this.disbaleToolTipOnSave = true;
    else this.disbaleToolTipOnSave = false;
    if (!(await this.ValidateContact()) && value !== null && value.length > 0) {
      await this.validateContactValue();
      await this.enableDisableSaveButton();
      if (this.isNotValidContact) {
        return true;
      }
      try {
      } catch (error) {
        PageContext.setIsMessageVisible(false);
        this.isLoading = false;
        setTimeout(() => {
          PageContext.setIsMessageVisible(false);
          let message = JSON.parse(PageContext.responseMessage);
          if (message && message.status === 400) {
            this.response = PageContext.responseMessage;
            this.isMessgeVisible = true;
            this.disableSave = true;
          }
        }, 100);
        IMASLog.log("exception: " + error);
      }
    }
  };
  @action getContactType = (contact: any) => {
    return "" + contact.contactType + "";
  };

  @action setContactType(value: string) {
    this.contactType = value;
    this.contactValue = "";
    this.disbaleToolTipOnSave = false;
    switch (value) {
      case "1":
        this.contactValueLabel = ContactType.Email;
        this.contactPlaceHolder = "abc@mail.com";
        this.contactErrMsg = "email address.";
        break;
      case "2":
        this.contactValueLabel = ContactType.TextMessage;
        this.contactPlaceHolder = "Enter value";
        break;
      case "3":
        this.contactValueLabel = ContactType.PhoneNumber;
        this.contactPlaceHolder = "000 000 0000";
        this.contactErrMsg = "phone number.";
        break;
      case "4":
        this.contactValueLabel = ContactType.Name;
        this.contactPlaceHolder = "Enter Contact Name";
        break;
    }
    this.isNotValidContact = false;
    this.ResetValidateContact();
  }

  @action getContactValue = (contact: any) => {
    if (contact.contactType === "Phone Number") {
      //3
      return this.getPhoneNumberUSformat("" + contact.contactValue);
    } else return "" + contact.contactValue;
  };

  @action getPhoneNumberUSformat = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumber;
  };

  @action getPhoneNumber = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "", match[2], " ", match[3], " ", match[4]].join("");
    }
    return phoneNumber;
  };

  @action getContactHref = (contact: any) => {
    if (contact.contactType === "Phone Number") {
      //3
      return "tel:" + contact.contactValue;
    } else if (contact.contactType === "Email") {
      return "mailto:" + contact.contactValue + "?Subject=Integrity%20Connect";
    } else return "" + contact.contactType;
  };

  @action getContactActionItems = (rowData: any) => {
    let deleteMenuItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeleteContactConfirm = true;
      },
    };
    var contactActionItems: MenuItem[] = [];
    contactActionItems.push(deleteMenuItem);
    return contactActionItems;
  };

  @action setSelectedContactId(
    value: number | undefined,
    contactType: string | undefined
  ) {
    this.selectContactId = value ? value : 0;
    this.selectContactTypeId = Utils.getContactTypeId("" + contactType);
  }

  @action setContactId(value: number) {
    this.contactId = value;
  }

  @action validateContactValue = async () => {
    this.isNotValidContact = false;
    this.contactValue = this.contactValue.trim();
    if (this.contactValue === "") {
      this.isNotValidContact = true;
    } else {
      this.isNotValidContact = false;
    }
    if (this.contactType === "1") {
      // eslint-disable-next-line
      var result = this.contactValue.match(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      );
      if (result) {
        this.isNotValidContact = false;
      } else {
        this.isNotValidContact = true;
      }
    }
    if (this.contactType === "3") {
      // eslint-disable-next-line
      var phoneResult = this.contactValue.length;
      if (phoneResult === 12) {
        this.isNotValidContact = false;
      } else {
        this.isNotValidContact = true;
      }
    }
  };

  @action setphoneType = async (value: string) => {
    this.phoneType = parseInt(value);
  };
  @action setEmailType = async (value: string) => {
    this.emailType = parseInt(value);
  };
  @action setAlternativePOCType = async (value: string) => {
    this.alternativePOCType = parseInt(value);
  };

  @action getPhoneTypeId = (phoneType: string) => {
    var pt = 0;
    if (this.phoneTypeList.values.length > 0) {
      var phone_type = this.phoneTypeList.values.filter(
        (i) => i.label === phoneType
      );
      if (phone_type && phone_type.length > 0) {
        pt = +phone_type[0].value;
      }
    }
    return pt;
  };
  @action getEmailTypeId = (emailType: string) => {
    var pt = 0;
    if (this.emailTypeList.values.length > 0) {
      var email_type = this.emailTypeList.values.filter(
        (i) => i.label === emailType
      );
      if (email_type && email_type.length > 0) {
        pt = +email_type[0].value;
      }
    }
    return pt;
  };

  @action getAlternativePOcTypeId = (apocType: string) => {
    var pt = 0;
    if (this.alternativePOCTypeList.values.length > 0) {
      var apoc_type = this.alternativePOCTypeList.values.filter(
        (i) => i.label === apocType
      );
      if (apoc_type && apoc_type.length > 0) {
        pt = +apoc_type[0].value;
      }
    }
    return pt;
  };

  @action sortContacts(data: ContactModel[]) {
    var sortData = [...data].sort(this.compareContact);
    this.ContactList.values = sortData;
  }

  compareContact = (a: ContactModel, b: ContactModel) => {
    const nameA = a ? "a" : "b";
    const nameB = b ? "a" : "b";

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  @action noContactRecordsFound() {
    this.totalContactRecords = 0;
    this.ContactList.values = [];
  }

  @action setDeleteContactConfirmation(value: boolean) {
    this.isDeleteContactConfirm = value;
  }

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false; 
  @action handleClick = (id: string) => { 
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
    };

  @action deleteContact = async () => {
    try {
      this.isException = false;
      if (this.companyId === 0) {
        this.addContactDto = this.addContactDto.filter((item: any) => {
          return item.id != this.selectContactId;
        });
        this.ContactList.values = this.ContactList.values.filter(
          (item: any) => {
            return item.id != this.selectContactId;
          }
        );
        this.isDeleteContactConfirm = false;
      } else {
        await ContractsStore.deleteContact(this.selectContactId);
        this.isDeleteContactConfirm = false;
        setTimeout(async () => {
          await this.loadContacts();
          this.isLoading = false;
        }, Utils.timeDelay_Delete());
      }
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };

  @action setContactDialogVisibility = async (value: boolean) => {
    this.ResetValidateContact();
    await this.resetContactForm();
    this.isContactDialogVisible = value;
    if (this.isContactDialogVisible) {
      await this.loadLookups();
    }

    if (this.companyId == 0 && value && this.contactId !== 0) {
      const contact = this.ContactList.values.find((item: any) => {
        return item.id == this.contactId;
      });
      if (contact) {
        this.contactPlaceHolder = "abc@mail.com";
        this.contactErrMsg = "email address.";
        this.setContactType("" + contact.contactTypeId);
        this.setContactValue(contact.contactValue ? contact.contactValue : "");
        this.setphoneType(contact.phoneTypeId);
        this.setEmailType(contact.emailTypeId);
        this.setAlternativePOCType(contact.alternativePointOfContactTypeId);
        this.setNotes(contact.notes);
      }
    } else if (value && this.contactId !== 0) {
      const result = await ContractsStore.getContactDetails(this.contactId);
      if (result) {
        this.setContactType(
          result.contactTypeId ? result.contactTypeId.toString() : "0"
        );
        this.setContactValue(result.contactValue ? result.contactValue : "");
        this.setphoneType(
          result.phoneTypeId ? result.phoneTypeId.toString() : "0"
        );
        this.setEmailType(
          result.emailTypeId ? result.emailTypeId.toString() : "0"
        );
        this.setAlternativePOCType(
          result.alternativePointOfContactTypeId
            ? result.alternativePointOfContactTypeId.toString()
            : "0"
          );
        this.setNotes(result.notes ? result.notes : "");
      }
    }
  };

  @action resetContactForm() {
    this.contactType = "1";
    this.phoneType = PhoneTypeValue.Mobile;
    this.emailType = EmailTypeValue.Work;
    this.alternativePOCType = AlternativePointOfContactTypeValue.Primary;
    this.contactValue = "";
    this.contactValueLabel = ContactType.Email;
    this.isMessgeVisible = false;
    this.disableSave = true;
    this.isNotValidContact = false;
    this.notes = "";
  }

  @action getContactNumber = (contact: ContactModel) => {
    window.open("rcapp://r/call?number=" + contact.contactValue, "_parent");
  };

  @action enableDisableSaveButton = async () => {
    if (!(await this.ValidateContact())) {
      if (!this.isNotValidContact) this.disableSave = false;
      else this.disableSave = true;
    } else {
      this.disableSave = true;
    }
  };
}

class ContactValidatory extends AbstractValidator<CarrierCMContactViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.contactType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .isNotEqualTo("None")
      .isNotEqualTo("NONE")
      .withFailureMessage("Type is required");
    this.validateIfString((input) => input.contactValue)
      .isNotEmpty()
      .withFailureMessage("Value is required");
  }
}
