import { action, observable } from "mobx";
import React from "react";
import Collection from "../../../infrastructure/CollectionHelper";
import IMASLog from "../../../infrastructure/IMASLog";
import UserContext from "../../../infrastructure/UserContext";
import Utils from "../../../infrastructure/Utils";
import ViewModel from "../../../infrastructure/ViewModel";
import { routes } from "../../../router";
import {
  ReportCloneRequest,
  ReportListModel,
  ReportPreviewRequest,
} from "../../../services/ReportService";
import ReportStore from "../../../store/ReportStore";
import {
  createCustomReportListener,
  CustomReportDetailsAddEditViewModel,
} from "./CustomReportDetailsAddEditViewModel";

export class CustomReportsListViewModel
  implements ViewModel, createCustomReportListener
{
  createCustomReport(
    reportName: string,
    reportPrivacy: string,
    reportDescription: string
  ): void {
    const dto: ReportCloneRequest = {
      reportName: reportName,
      reportStatusId: parseInt(reportPrivacy),
      description: reportDescription,
    };
    this.cloneCustomReport(dto);
  }
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (): Promise<void> => undefined;
  @observable isMessageVisible: boolean = false;
  @observable listViewActions: boolean = false;
  @observable response: any;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable searchInputValue: string = "";
  @observable selectedTableRecordId: string = "";
  @observable sort: boolean = false;
  @observable sortColumn: string = "createdDate";
  @observable customReportList = new Collection<ReportListModel>();
  @observable reportId: number = 0;
  @observable showGenerateToast: boolean = false;
  @observable showDeleteToast: boolean = false;
  @observable showCloneToast: boolean = false;
  @observable showGenerateToastFileName: string = "";
  @observable customMenuItemsRef = React.createRef();
  @observable menu = [
    { label: "New", icon: "pi pi-fw pi-plus" },
    { label: "Delete", icon: "pi pi-fw pi-trash" },
  ];
  @observable
  CustomReportDetailsAddEditViewModel: CustomReportDetailsAddEditViewModel =
    new CustomReportDetailsAddEditViewModel();
  @observable isdeleteConfirm: boolean = false;
  @observable noCustomRecords: boolean = false;
  @observable reportName: string = "";

  @action Load = async () => {
    this.resetToasters();
    this.showGenerateToastFileName = "";
    this.sortColumn = "createdDate";
    this.sort = false;
    this.isShown = false;
    this.isMessageVisible = false;
    this.searchInputValue = "";
    this.rows = 25;
    await this.loadCustomReports();
    await this.CustomReportDetailsAddEditViewModel.loadReportStatusDropdowns();
    this.CustomReportDetailsAddEditViewModel.setListener(this);
    if (localStorage.getItem("showDeleteToast") !== null) {
      this.reportName = localStorage.getItem("showDeleteToast") || "";
      this.showDeleteToast = true;
      localStorage.removeItem("showDeleteToast");
    }
  };
  @action loadCustomReports = async () => {
    try {
      this.isLoading = true;
      this.customReportList.values = [];

      const response = await ReportStore.getAllCustomReportList(
        "" + this.searchInputValue,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sort
      );

      if (response && response.data && response.data.length > 0) {
        this.noCustomRecords = false;
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.customReportList.values = response.data;
      } else {
        this.noCustomRecords = true;
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.isMessageVisible = true;
      this.response = e.response;
    }
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadCustomReports();
  }

  @action setReportId = (value: number) => {
    this.reportId = value;
  };
  @action goSearch = () => {
    this.startIndex = 0;
    this.first = 0;
    this.loadCustomReports();
  };
  @action searchIntervals = (text: string) => {
    this.searchInputValue = text;
    if (text) {
      this.searchInputValue = text;
      if (text && text.length > 2) {
        this.goSearch();
      }
    } else {
      this.searchInputValue = "";
      this.startIndex = 0;
      this.first = 0;
      this.loadCustomReports();
    }
  };
  @action resetFilters = async () => {
    this.searchInputValue = "";
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "createdDate";
    this.sort = false;
    await this.loadCustomReports();
    this.reportName = "";
  };

  @observable isShown: boolean = false;

  @action editCustomReport = () => {
    routes.editCustomReport.push({ id: this.reportId });
  };

  @action cloneCustomReport = async (dto: ReportCloneRequest) => {
    try {
      this.showCloneToast = false;
      await ReportStore.cloneReport(this.reportId, dto);
      await this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
        false
      );
      this.showCloneToast = true;
      await this.loadCustomReports();
    } catch (e: any) {}
  };

  @action generateCustomReport = async () => {
    this.showGenerateToast = false;
    const dto: ReportPreviewRequest = {
      reportId: this.reportId,
      reportName: "",
      entityName: "",
      createdByUserId: UserContext.getUserId(),
      reportColumnModel: undefined,
    };
    await ReportStore.downloadCustomReport(this.reportId, dto);
    this.showGenerateToast = true;
  };

  @action setDeleteConfirm = (value: boolean) => {
    this.isdeleteConfirm = value;
  };

  @action deleteCustomReport = async () => {
    try {
      this.showDeleteToast = false;
      await ReportStore.deleteReport(this.reportId);
      this.setDeleteConfirm(false);
      setTimeout(async () => {
        this.showDeleteToast = true;
        await this.loadCustomReports();
      }, Utils.timeDelay_Delete());
    } catch (error) {
      IMASLog.log("exception while deleting report.");
    }
  };

  @action cloneCustomReportDialog = async (value: boolean) => {
    this.CustomReportDetailsAddEditViewModel.reportName =
      this.reportName + " (Clone)";
    this.CustomReportDetailsAddEditViewModel.setShowCustomReportDetailsDialog(
      true
    );
  };

  @action handleClick = (id: string) => {
    this.resetToasters();
    let showState = this.isShown;
    this.selectedTableRecordId = id;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  @action resetToasters = () => {
    this.showGenerateToast = false;
    this.showDeleteToast = false;
    this.showCloneToast = false;
  };
}
