import { observable, action } from "mobx";
import { ErrorTypes } from "../infrastructure/enum/Common";
import ViewModel from "../infrastructure/ViewModel";


export class ErrorViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (type: ErrorTypes) => {
    if(type === ErrorTypes.NoAccess)
    {
      this.errorMessage = "You don't have permission to access this page";
    } else
    {
      this.errorMessage = "";
    }
  
  };

  Route = async (): Promise<void> => {};

  @observable errorMessage: string = "";
}
