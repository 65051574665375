/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import RouteList from "../../infrastructure/RouteList";
import IMASLog from "../../infrastructure/IMASLog";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import {
  CustomerSummaryListener,
  CustomerSummaryViewModel,
} from "./CustomerSummaryViewModel";
import { CustomerPoliciesViewModel } from "./CustomerPoliciesViewModel";
import { CustomerDocsViewModel } from "./CustomerDocsViewModel";
import { CustomerTasksViewModel } from "./CustomerTasksViewModel";
import { PolicyDetailsViewModel } from "./../agents/PolicyDetailsViewModel";
import { AddEditPolicyViewModel } from "./../agents/policies/AddEditPolicyViewModel";
import { AddEditCustomerTaskViewModel } from "./AddEditCustomerTaskViewModel";
import { AddCustomerDocViewModel } from "./AddCustomerDocViewModel";
import InsuredStore from "../../store/InsuredStore";
import { CreateUpdateCustomerModel } from "../../services/InsuredService";
import DocumentStore from "../../store/DocumentStore";
import Utils from "../../infrastructure/Utils";
import {
  CustomerTabs,
  CustomerTabViewModelTypes,
} from "../../infrastructure/enum/Customer";
import UserContext from "../../infrastructure/UserContext";
import { CustomerTabNotesViewModel } from "./CustomerTabNotesViewModel";

export class CustomerDetailsTabViewModel
  implements ViewModel, CustomerSummaryListener
{
  Route = async (currentRoute: RouteList): Promise<void> => {
    this.handleRoute(currentRoute);
  };

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @computed
  get firstName(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.fName ? this.customerSummary.fName : "")
      );
    return "";
  }

  @computed
  get lastName(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.lName ? this.customerSummary.lName : "")
      );
    return "";
  }

  updateCustomerInfo(fName: string, lName: string): void {
    this.customerName = this.getName(fName, lName);
  }

  @observable customerTabItems = [
    { label: "Summary", value: 0 },
    { label: "Policies", value: 1 },
    { label: "Docs", value: 2 },
    { label: "Tasks", value: 3 },
  ];

  @action Load = async (isLoadContacts:any) => {
    this.isLoading = false;
    this.isSuccess = false;
    this.isException = false;
    this.exceptionMessage = "";
    this.response = "";
    this.policyId = RouteParamDetails.policyId;
    this.taskId = RouteParamDetails.taskId;
    if (this.customerId !== RouteParamDetails.id) {
      this.CustomerSummaryViewModel.notesVisible = false;
      this.customerId = +RouteParamDetails.id;
      this.loadCustomerSummary();
      this.CustomerSummaryViewModel.setListener(this);
    } else {
      this.CustomerSummaryViewModel.notesVisible = true;
      isLoadContacts && this.CustomerSummaryViewModel.loadContacts();
    }
  };

  @action getName = (fName: string, lName: string) => {
    let name = "";
    if (fName && fName != null && lName && lName != null)
      name = fName + " " + lName;
    else if (!fName || fName === null) name = "" + lName;
    else name = "" + fName;
    return name;
  };

  @action loadCustomerSummary = async () => {
    try {
      if (this.customerId) {
        this.isLoading = true;
        this.customerSummary = await InsuredStore.getCustomerSummary(
          this.customerId
        );
        if (this.customerSummary) {
          this.customerName = this.getName(this.firstName, this.lastName);
          this.CustomerSummaryViewModel.setCustomerSummaryResponse(
            this.customerSummary
          );
        }
        if (this.customerSummary && this.customerSummary.bDay) {
          var age = Utils.getAge(this.customerSummary.bDay);
          RouteParamDetails.customerDob = this.customerSummary.bDay.toString();
          RouteParamDetails.customerAge = age ? "" + age : "";
        }
        this.CustomerSummaryViewModel.loadNotes();
        this.CustomerSummaryViewModel.loadContacts();
        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
      routes.customerSearch.replace();
      IMASLog.log("exception from store: " + e.value);
    }
  };

  @observable customerSummary: CreateUpdateCustomerModel | null = null;

  @action goBackToHome = () => {
    this.breadCrumbMenuItems.pop();
    routes.home.replace();
  };

  @action goBackToCustomers = () => {
    this.breadCrumbMenuItems.pop();
  };

  @action AddDoc = async () => {
    this.isLoading = true;
    this.response = {};
    try {
      await DocumentStore.loadAllDocList(this.customerId, 0, 0);
      this.isLoading = false;
      routes.addCustomerDoc.push({ customerId: "" + this.customerId });
    } catch (e) {
      this.response = e.response;
      this.isLoading = false;
    }
  };

  @action editTask = async () => {
    routes.editCustomerTask.push({
      customerId: "" + this.customerId,
      taskId: this.CustomerTasksViewModel.taskId,
    });
  };

  @action editCustomer = async () => {
    routes.editCustomer.push({ customerId: "" + this.customerId });
  };

  @action deleteCustomer = async () => {
    try {
      await InsuredStore.deleteCustomer(this.customerId);
      this.setDeleteCustomerConfirmation(false);
      this.isSuccess = true;
      setTimeout(async () => {
        routes.customerSearch.replace();
      }, Utils.timeDelay_Delete());
    } catch (error) {
      this.setDeleteCustomerConfirmation(false);
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception ..deleting note");
    }
  };

  @action setDeleteCustomerConfirmation(value: boolean) {
    this.isDeleteCustomerConfirm = value;
  }

  @action goToNotesTab = () => {
    routes.customerNotes.push({ customerId: "" + this.customerId });
  };

  @observable exceptionMessage: any;
  @observable response: any = "";
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable isLoading: boolean = false;
  @observable customerName: string = "";
  @observable customerId: number = 0;
  @observable selectedKey: string | undefined = "";
  @observable SelectedBaseViewModel: ViewModel | undefined;
  @observable selectedTabIndex?: number = 0;
  @observable SelectedViewModel: keyof typeof CustomerTabViewModelTypes =
    CustomerTabViewModelTypes[CustomerTabViewModelTypes.Summary];
  @observable CustomerSummaryViewModel: CustomerSummaryViewModel =
    new CustomerSummaryViewModel();
  @observable CustomerTabNotesViewModel: CustomerTabNotesViewModel =
    new CustomerTabNotesViewModel();
  @observable CustomerPoliciesViewModel: CustomerPoliciesViewModel =
    new CustomerPoliciesViewModel();
  @observable CustomerDocsViewModel: CustomerDocsViewModel =
    new CustomerDocsViewModel();
  @observable CustomerTasksViewModel: CustomerTasksViewModel =
    new CustomerTasksViewModel();
  @observable isDeleteCustomerConfirm: boolean = false;
  @observable PolicyDetailsViewModel: PolicyDetailsViewModel =
    new PolicyDetailsViewModel();
  @observable policyId: number = 0;
  @observable taskId: number = 0;
  @observable AddEditPolicyViewModel: AddEditPolicyViewModel =
    new AddEditPolicyViewModel();
  @observable AddEditCustomerTaskViewModel: AddEditCustomerTaskViewModel =
    new AddEditCustomerTaskViewModel();
  @observable AddCustomerDocViewModel: AddCustomerDocViewModel =
    new AddCustomerDocViewModel();
  @observable breadCrumbMenuItems: MenuItem[] = [
    {
      label: "Customers",
      command: () => {
        this.goBackToCustomers();
      },
    },
  ];

  @observable CustomerTabItems = [
    { label: "Summary", value: 0 },
    { label: "Policies", value: 1 },
    { label: "Scanned Documents", value: 2 },
    { label: "Tasks", value: 3 },
  ];

  @computed get getTabIndex(): number {
    var tabIndex = 0;
    switch (this.selectedKey) {
      case CustomerTabs.Summary:
        tabIndex = 0;
        break;
        case CustomerTabs.Notes:
        tabIndex = 1;
        break;
      case CustomerTabs.Policies:
        tabIndex = 2;
        break;
      case CustomerTabs.Docs:
        tabIndex = 3;
        break;
      case CustomerTabs.Tasks:
        tabIndex = 4;
        break;
      default:
        tabIndex = 0;
        break;
    }
    return tabIndex;
  }

  @action handleRoute(currentRoute: RouteList) {
    let name = currentRoute.name;
    switch (name) {
      case routes.customerDetails.name:
      case routes.customerSummary.name:
        this.selectedKey = CustomerTabs.Summary;
        this.SelectedViewModel = CustomerTabViewModelTypes.Summary;
        this.SelectedBaseViewModel = this.CustomerSummaryViewModel;
        if (this.customerId)
          this.CustomerSummaryViewModel.load(+this.customerId);
        break;
        case routes.customerTabNotes.name:
        this.selectedKey = CustomerTabs.Notes;
        this.SelectedViewModel = CustomerTabViewModelTypes.Notes;
        this.SelectedBaseViewModel = this.CustomerTabNotesViewModel;
        if (this.customerId)
          this.CustomerTabNotesViewModel.load(
            +this.customerId,
            this.customerName
          );
          break;
      case routes.customerPolicies.name:
        this.selectedKey = CustomerTabs.Policies;
        this.SelectedViewModel = CustomerTabViewModelTypes.Policies;
        this.SelectedBaseViewModel = this.CustomerPoliciesViewModel;
        if (this.customerId)
          this.CustomerPoliciesViewModel.load(
            +this.customerId,
            this.customerName
          );
        break;
      case routes.customerTasks.name:
        this.selectedKey = CustomerTabs.Tasks;
        this.SelectedViewModel = CustomerTabViewModelTypes.Tasks;
        this.SelectedBaseViewModel = this.CustomerTasksViewModel;
        if (this.customerId) this.CustomerTasksViewModel.load(+this.customerId);
        break;
      case routes.customerDocs.name:
        this.selectedKey = CustomerTabs.Docs;
        this.SelectedViewModel = CustomerTabViewModelTypes.Docs;
        this.SelectedBaseViewModel = this.CustomerDocsViewModel;
        if (this.customerId) this.CustomerDocsViewModel.Load(+this.customerId);
        break;
      case routes.customerpolicyDetails.name:
      case routes.customerpolicyRevenueHistoryDetails.name:
        var routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
        var tabIndex = routeParamObj.tabIndex;
        if (tabIndex == undefined) {
          tabIndex = 0;
        }
        this.SelectedViewModel = CustomerTabViewModelTypes.ViewPolicy;
        this.SelectedBaseViewModel = this.PolicyDetailsViewModel;
        this.PolicyDetailsViewModel.Load(this.policyId, tabIndex);
        break;
      case routes.addPolicy.name:
        this.selectedKey = CustomerTabs.Policies;
        RouteParamDetails.customerName = this.customerName;
        this.SelectedViewModel = CustomerTabViewModelTypes.AddEditPolicy;
        this.SelectedBaseViewModel = this.AddEditPolicyViewModel;
        this.AddEditPolicyViewModel.Load(
          +this.customerId,
          0,
          this.customerName
        );
        break;
      case routes.editCustomerPolicy.name:
        this.selectedKey = CustomerTabs.Policies;
        this.SelectedViewModel = CustomerTabViewModelTypes.AddEditPolicy;
        this.SelectedBaseViewModel = this.AddEditPolicyViewModel;
        if (this.customerId)
          this.AddEditPolicyViewModel.Load(+this.customerId, this.policyId, "");
        break;
      case routes.editCustomerTask.name:
        IMASLog.log("edit customer task..");
        this.selectedKey = CustomerTabs.Tasks;
        this.SelectedViewModel = CustomerTabViewModelTypes.EditTask;
        this.SelectedBaseViewModel = this.AddEditCustomerTaskViewModel;
        if (this.customerId)
          this.AddEditCustomerTaskViewModel.Load(+this.customerId, this.taskId);
        break;
      case routes.addCustomerDoc.name:
        this.selectedKey = CustomerTabs.Docs;
        this.SelectedViewModel = CustomerTabViewModelTypes.AddDoc;
        this.SelectedBaseViewModel = this.AddCustomerDocViewModel;
        if (this.customerId)
          this.AddCustomerDocViewModel.Load(+this.customerId);
        break;
    }
  }

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    switch (key) {
      case "Summary":
        this.handleLinkClick(CustomerTabs.Summary);
        break;
      case "Notes":
        this.handleLinkClick(CustomerTabs.Notes);
        break;
      case "Policies":
        this.handleLinkClick(CustomerTabs.Policies);
        break;
      case "Tasks":
        this.handleLinkClick(CustomerTabs.Tasks);
        break;
      case "Docs":
        this.handleLinkClick(CustomerTabs.Docs);
        break;
    }
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case CustomerTabs.Summary:
          routes.customerSummary.push({ customerId: "" + this.customerId });
          return;
          case CustomerTabs.Notes:
          routes.customerTabNotes.push({ customerId: "" + this.customerId });
          return;
        case CustomerTabs.Policies:
          routes.customerPolicies.push({ customerId: "" + this.customerId });
          return;
        case CustomerTabs.Tasks:
          routes.customerTasks.push({ customerId: "" + this.customerId });
          return;
        case CustomerTabs.Docs:
          routes.customerDocs.push({ customerId: "" + this.customerId });
          return;
        default:
          return;
      }
    }
  };

  @action viewTaskPolicyClick = () => {
    routes.customerpolicyDetails.push({
      customerId: "" + this.customerId,
      policyId: +this.CustomerDocsViewModel.selectedPolicyId,
    });
  };

  @action viewPolicyClick = (event: string) => {
    switch (event) {
      case "edit":
        routes.editCustomerPolicy.push({
          customerId: "" + this.customerId,
          policyId: this.CustomerPoliciesViewModel.policyId,
        });
        break;
      case "add":
        routes.addPolicy.push({ customerId: "" + this.customerId });
        break;
      default:
        routes.customerpolicyDetails.push({
          customerId: "" + this.customerId,
          policyId: +this.CustomerPoliciesViewModel.policyId,
        });
        break;
    }
  };
}
