import React from "react";
import { observer } from "mobx-react";
import "./CustomDialog.scss";

interface CustomDialogProps {
  isDialogShown: boolean;
  customClassName?: string;
  children: JSX.Element;
  onClose: () => void;
  title?: string;
}

export const CustomDialog = observer(
  ({ isDialogShown, children, onClose, title, customClassName = "" }: CustomDialogProps) => {
    return isDialogShown ? (
      <div className="custom-dialog-container">
        <div className={`${customClassName + "  custom-dialog"}`}       >
          <div className="content">
            <span className="title">{title}</span>
            <span className="close custom-focus" onClick={onClose} tabIndex={0}>
              &times;
            </span>
          </div>
          {children}
        </div>
      </div>
    ) : null;
  }
);
