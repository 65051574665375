import { observable, action } from "mobx";
import SupplyOrderStore from "../../../store/SupplyOrderStore";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import {
  SupplyOrderList,
  SupplyOrderDetailResponse,
  ReportFile,
} from "../../../services/SupplyOrderService";
import IMASLog from "../../../infrastructure/IMASLog";
import Utils from "../../../infrastructure/Utils";
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import { AgentListModel } from "../../../services/ProducerSearchService";
import PageContext from "../../../infrastructure/PageContext";
import UserContext from "../../../infrastructure/UserContext";

export class AgentOrdersViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (id: number, fromAgent: boolean) => {
    this.agentId = id;
    this.FromAgent = fromAgent;
    this.sortColumnOrder = "id";
    this.sortOrder = true;
    this.rows = 200;
    await this.loadOrders();
    this.userId = UserContext.getUserId();
    if (!fromAgent) {
      this.title = "Supply Orders";
      this.selectedType = "0";
      this.types = [
        { label: "All", value: "-1" },
        { label: "Shipped", value: "1" },
        { label: "Pending", value: "0" },
      ];
      // this.selectedAgent = ''
      // this.selectedType = '-1';  Have default option as "3", hence commenting this out
      await this.loadCreatedUsers();
    } else {
      this.selectedType = "0";
      this.types = [
        { label: "All", value: "-1" },
        { label: "Shipped", value: "1" },
        { label: "Pending", value: "0" },
        //{ label: 'Pending', value: '0' }
      ];
    }
  };

  Route = async (): Promise<void> => {
    // TODO: implement or remove function
  };

  @observable userId: number | undefined = 0;
  @observable rows: number = 200;
  @observable title: string = "";
  @observable totalRecords: number = 200;
  @observable first: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable orderId: number = 0;
  @observable ordersList = new Collection<SupplyOrderList>();
  @observable types: any;
  @observable startDate: Date | undefined = new Date();
  @observable endDate: Date | undefined = new Date();
  @observable searchOrderNumber: number | undefined;
  @observable CreatedByUserId: number | undefined;
  @observable sortOrder: boolean = true;
  @observable sortColumnOrder: string = "id";
  @observable isDeleteOrderConfirm: boolean = false;
  @observable selectedType: string = "0";
  @observable selectedAgent: string = "";
  @observable selectedAgentId: number | undefined;
  @observable FromAgent: boolean = false;
  @observable filteredAgentsList = new Collection<AgentListModel>();
  @observable supplyOrderDetailResponse: SupplyOrderDetailResponse | undefined;
  @observable isException: boolean = false;
  @observable createdUserList = [{ label: "All", value: "0" }];

  @action loadCreatedUsers = async () => {
    try {
      this.supplyOrderDetailResponse =
        await SupplyOrderStore.getSupplyOrderById(0);
      if (this.supplyOrderDetailResponse) {
        const users = this.supplyOrderDetailResponse.createdByUsers
          ? this.supplyOrderDetailResponse.createdByUsers
          : [];
        const sortList = [...users].sort(Utils.compareListItem);
        sortList.forEach((element: any) => {
          this.createdUserList.push({
            label: "" + element.text,
            value: "" + element.value,
          });
        });
      }
    } catch (e) {
      // TODO: implement or remove function
    }
  };

  @action loadOrders = async () => {
    try {
      setTimeout(() => {
        PageContext.setIsLoadingVisible(false);
      }, 500);
      this.isLoading = true;
      const orderId =
        !this.searchOrderNumber || isNaN(this.searchOrderNumber)
          ? undefined
          : this.searchOrderNumber;
      const createdbyid =
        this.CreatedByUserId && this.CreatedByUserId > 0
          ? this.CreatedByUserId
          : undefined;
      const agentOrders = await SupplyOrderStore.getSupplyOrders(
        this.agentId ? this.agentId : 1,
        this.FromAgent,
        parseInt(this.selectedType),
        this.selectedAgentId,
        createdbyid,
        orderId,
        this.startDate,
        this.endDate,
        this.startIndex,
        this.rows,
        this.sortColumnOrder,
        this.sortOrder
      );

      if (agentOrders && agentOrders.data) {
        this.totalRecords = agentOrders.recordCount
          ? agentOrders.recordCount
          : 0;
        this.ordersList.values = agentOrders.data;
        this.ordersList.values.forEach((element) => {
          element.bo = element.bo === 0 ? undefined : element.bo;
        });
      } else {
        this.ordersList.values = [];
      }
      this.isLoading = false;
    } catch (e) {
      if (e instanceof Error) IMASLog.log("exception: " + e.message);
      this.isLoading = false;
      this.ordersList.values = [];
    }
  };
  async setSortColumn(sortField: any) {
    this.sortOrder = !this.sortOrder;
    this.sortColumnOrder = sortField;
    await this.loadOrders();
  }

  @action async onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    await this.loadOrders();
  }
  @action setSelectedType = (value: string) => {
    this.selectedType = value;
    if (this.FromAgent) {
      this.goSearch();
    }
  };
  @action setSelectedCreatedBy(createdByUserId: number) {
    this.CreatedByUserId = createdByUserId;
  }
  @action setSelectedAgent(agentName: any, agentId: number) {
    if (agentId) {
      this.selectedAgent = agentName;
      this.selectedAgentId = agentId;
    } else {
      this.selectedAgent = "";
      this.selectedAgentId = undefined;
    }
  }
  @action setSelectedUser(value: any) {
    if (typeof value !== "object") {
      this.selectedAgent = value;
    }

    if (!value) {
      this.selectedAgentId = undefined;
    }
  }
  @action setSelectedOrder(orderId: number) {
    this.orderId = orderId;
  }
  @action setSelectedSearchOrder(orderNumber: number) {
    this.searchOrderNumber = orderNumber;
  }
  @action setSelectDate(value: any, type: string) {
    const d = isNaN(new Date(value).getMonth()) === false ? value : undefined;
    if (type === "startDate") {
      this.startDate = d;
    } else {
      this.endDate = d;
    }
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setagentlist(value: any) {
    this.filteredAgentsList.values = value;
  }
  @action fillterUsers = async (value: string) => {
    const results = await ProducerSearchStore.getAgentsLookup(
      Utils.getAgentSearchType(value),
      value,
      0,
      200,
      undefined,
      false
    );
    if (results !== null) {
      if (results.recordCount) {
        if (results.data) {
          this.filteredAgentsList.values = results.data;
        } else {
          this.filteredAgentsList.values = [{ id: 0 }];
        }
      } else {
        this.filteredAgentsList.values = [{ id: 0 }];
      }
    } else {
      this.filteredAgentsList.values = [{ id: 0 }];
    }
  };
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action setDeleteOrderConfirmation = (value: boolean) => {
    this.isDeleteOrderConfirm = value;
  };
  @action onDeleteOrderClick = async () => {
    this.isDeleteOrderConfirm = false;
    await SupplyOrderStore.deleteSupplyOrder(this.orderId);

    setTimeout(async () => {
      await this.loadOrders();
      PageContext.setIsMessageVisible(false);
    }, Utils.timeDelay_Delete());
  };
  @action goSearch = async () => {
    this.isException = false;
    this.startIndex = 0;
    this.first = 0;
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.setIsException(true);
    } else {
      if (!this.selectedAgentId) {
        this.selectedAgent = "";
      }
      await this.loadOrders();
      this.setIsException(false);
    }
  };
  @action async ordersDownload() {
    try {
      this.isLoading = true;
      const orderId =
        !this.searchOrderNumber || isNaN(this.searchOrderNumber)
          ? undefined
          : this.searchOrderNumber;
      const createdbyid =
        this.CreatedByUserId && this.CreatedByUserId > 0
          ? this.CreatedByUserId
          : undefined;
      const agentOrders = await SupplyOrderStore.downloadSupplyOrders(
        this.agentId ? this.agentId : 1,
        this.FromAgent,
        parseInt(this.selectedType),
        this.selectedAgentId,
        createdbyid,
        orderId,
        this.startDate,
        this.endDate
      );

      if (agentOrders) {
        this.downloadReport(agentOrders, "SupplyOrders");
      }
      this.isLoading = false;
    } catch (e) {
      if (e instanceof Error) IMASLog.log("exception: " + e.message);
      this.isLoading = false;
    }
  }
  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(myBlob);
          const n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @action resetFilters = async () => {
    this.selectedType = "0";
    this.searchOrderNumber = undefined;
    this.CreatedByUserId = undefined;
    this.selectedAgent = "";
    this.selectedAgentId = undefined;
    this.startDate = new Date();
    this.endDate = new Date();
    this.sortColumnOrder = "id";
    this.sortOrder = true;
    this.first = 0;
    this.startIndex = 0;
    this.rows = 200;
    this.setIsException(false);
    await this.loadOrders();
  };
}
