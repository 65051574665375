import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import { Constants } from "../../../../infrastructure/enum/Constants";
import PageContext from "../../../../infrastructure/PageContext";
import UserContext from "../../../../infrastructure/UserContext";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { UserModel } from "../../../../services/IdentityService";
import { AgentListModel } from "../../../../services/ProducerSearchService";
import IdentityStore from "../../../../store/IdentityStore";
import ProducerSearchStore from "../../../../store/ProducerSearchStore";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import ProspectiveCallListView from "../../../../views/autoDialers/ProspectiveCallListView";

export class UserAddEditViewModel extends BaseAddEditViewModel {
  @observable firstName: string = "";
  @observable lastName: string = "";
  @observable loginName: string = "";
  @observable extension: number | undefined = undefined;
  @observable email: string = "";
  @observable domainName: string = "";
  @observable isActive: boolean = true;
  @observable isADSyncable: boolean = true;
  @observable isSelectedDownlineAgencyRequired: boolean = false;

  @observable isDownlineAgencyUser: boolean | undefined = false;

  @observable teamList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable downlineAgencyList = new Collection<AgentListModel>();
  @observable selectedTeam: string = "0";
  @observable selectedDownlineAgency: string = "";
  @observable prevSelectedDownlineAgency: string = "";
  @observable newDownlineAgency: string = "";
  @observable newDownlineAgencyId: string | undefined = "0";
  @observable roleList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable tempRoleList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedRoles: any = [];
  @observable tempSelectedRoles: any = [];
  @observable marketerList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedMarketer: string = "0";
  @observable UpnList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedUpn: string = "";
  @observable selectedUpnId: string = "";
  @observable isExtenstionValid: boolean = false;
  @observable isDownlineAgencyChanged: boolean = false;
  @observable downlineAgencyId: string | undefined = "0";
  @observable prevDownlineAgencyId: string | undefined = "0";
  @observable isDownlineDialogVisible: boolean = false;
  @observable isSave: boolean = true;
  @observable isCancelChangesConfirmation: boolean = false;
  @observable titleName: string = "";

  constructor() {
    super("User", routes.listUser, new UserValidator());
  }

  protected resetModel() {
      this.reset();
     
  }

  protected toServiceModel() {
    var dto: UserModel = {
      id: this.selectedId,
      first: this.firstName,
      last: this.lastName,
      extension: this.extension || undefined,
      adguid: this.selectedUpnId,
      teamId: +this.selectedTeam,
      marketerId:
        this.selectedMarketer && this.selectedMarketer !== "0"
          ? +this.selectedMarketer
          : undefined,
      email: this.email,
      domainName: this.domainName,
      maxLeadCount: 0,
      active: this.isActive,
      newSystemOnly: false,
      clearBrowserCache: false,
      isADSyncable: this.isADSyncable,
      roleIds: this.selectedRoles ? this.selectedRoles.map(Number) : [],
      isDownlineAgency: this.isDownlineAgencyUser,
      downlineAgencyId: this.downlineAgencyId
        ? parseInt(this.downlineAgencyId)
        : undefined,
      agencyName: this.selectedDownlineAgency,
    };
    return dto;
  }

  protected async loadLookups(): Promise<void> {
    PageContext.setIsMessageVisible(false);
    await this.getUsersScreenLookup(this.selectedId);
  }
  @action setAgency = (value: string) => {
    this.selectedDownlineAgency = "";
    this.selectedDownlineAgency = value;
  };
  @action setAgencyId = (value: number) => {
    this.downlineAgencyId = "" + value;
  };
  @action fillterAgencies = async (value: string) => {
    try {
      let result = await ProducerSearchStore.getAgencyLookup(
        12,
        value,
        0,
        100,
        undefined,
        undefined
      );
      var values = null;
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            values = result.data;
          }
        }
      }
    } catch (e) {}

    if (values !== undefined && values !== null) {
      this.downlineAgencyList.values = values;
    } else {
      var noAgent: AgentListModel = { id: 0 };
      this.downlineAgencyList.values = [noAgent];
    }
  };
  @action getUsersScreenLookup = async (id: number) => {
    var result = await IdentityStore.getUsersScreenLookup(id || 0);
    if (result) {
      await this.loadTeams(result.teamLookup || []);
      await this.loadMarketers(result.marketerLookup || []);
      await this.loadUserRoles(result.roleLookup || []);
    }
    this.isLoading = false;
  };

  @action fillterUpn = async (value: string) => {
    try {
      if (value.length >= 3) {
        let result = await IdentityStore.getAllUPNs(
          value,
          0,
          100,
          "text",
          true
        );
        var values = null;
        if (result !== null) {
          if (result.recordCount) {
            if (result.data) {
              values = result.data;
            }
          }
        }
      }
    } catch (e) {}
    if (values !== undefined && values !== null) {
      this.UpnList.values = this.mapListItemAndSort(values, "upn");
    } else {
      this.UpnList.values = [];
    }
  };

  @action setIsDownlineAgencyChangeConfirm(value: boolean) {
    this.isDownlineDialogVisible = value;
  }
  @action closeDownlineAgencyChangeConfirm(value: boolean) {
    this.setIsDownlineAgencyChangeConfirm(value);
    if (!this.isDownlineAgencyChanged) {
      this.selectedDownlineAgency = this.prevSelectedDownlineAgency;
      this.downlineAgencyId = this.prevDownlineAgencyId;
    }
      this.isDownlineAgencyChanged = false;
      this.onchangeMandatory();
  }
  @action downlineAgencyChangeConfirm() {
    this.setAgency(this.newDownlineAgency);
    this.setAgencyId(
      this.newDownlineAgencyId ? parseInt(this.newDownlineAgencyId) : 0
    );
    this.setIsDownlineAgencyChangeConfirm(false);
      this.isDownlineAgencyChanged = true;

      this.onchangeMandatory();
    }

    @action onchangeMandatory = () => {
        this.isSave = true;
        if (this.lastName == "" || this.firstName == "" || this.email == "" || (this.isDownlineAgencyUser && this.downlineAgencyId == "0")) {
            this.isSave = false;
        }
    }
  @action reset = () => {
      this.firstName = "";
      this.isSave = true;
    this.lastName = "";
    this.selectedUpn = "";
    this.extension = undefined;
    this.email = "";
    this.selectedTeam = "";
    this.selectedUpnId = "";
    this.selectedMarketer = "";
    this.domainName = "";
    this.isActive = false;
    this.isADSyncable = false;
    this.selectedRoles = [];
    this.tempSelectedRoles = [];
    PageContext.setIsMessageVisible(false);
    this.isExtenstionValid = false;
    this.isDownlineAgencyUser = false;
    this.selectedDownlineAgency = "";
    this.prevSelectedDownlineAgency = "";
    this.newDownlineAgency = "";
    this.newDownlineAgencyId = "0";
    this.downlineAgencyId = "0";
    this.prevDownlineAgencyId = "0";
    this.isSelectedDownlineAgencyRequired = false;
    this.isDownlineAgencyChanged = false;
    this.selectedRoles = [];
    this.roleList.values = [];
    var roles = this.tempRoleList.values.filter(
      (x) => x.label !== Constants.UserDownlineAgencyRole
    );
    this.roleList.values = roles;
  };

  protected async loadItem(id: number): Promise<void> {
    var item = await IdentityStore.getUserById(id);
    if (item) {
      this.selectedId = item.id || 0;
      this.firstName = item.first || "";
      this.lastName = item.last || "";
      this.loginName = item.loginName || "";
      this.selectedUpn = item.upn ? item.upn : "";
      this.extension = item.extension || undefined;
      this.email = item.email || "";
      this.selectedTeam = "" + item.teamId || "";
      this.selectedUpnId = item.adguid || "";
      this.titleName = item.first + " " + this.lastName;
      var marketerIds = this.marketerList.values.map(function (marketerItem) {
        return marketerItem.value;
      });
      this.selectedMarketer =
        (item.marketerId && marketerIds.includes("" + item.marketerId)
          ? "" + item.marketerId
          : "0") || "0";
      this.domainName = item.domainName || "";
      this.isActive = item.active || false;
      this.isADSyncable = item.isADSyncable || false;
      this.isDownlineAgencyUser = item.isDownlineAgency;
      this.selectedDownlineAgency = item.agencyName ? item.agencyName : "";
      this.prevSelectedDownlineAgency = item.agencyName ? item.agencyName : "";
      this.downlineAgencyId = "" + item.downlineAgencyId;
      this.prevDownlineAgencyId = "" + item.downlineAgencyId;
      this.selectedRoles =
        item.roleIds && item.roleIds.length > 0 ? item.roleIds.map(String) : [];
      this.tempSelectedRoles =
        item.roleIds && item.roleIds.length > 0 ? item.roleIds.map(String) : [];
      if (item.roles && item.roles.length > 0) {
        this.roleList.values = [];
        this.roleList.values = this.mapListItemAndSort(item.roles, "role");
        }      
    }
  }

  @observable response: any;
  @action createUser = async () => {
    this.isMessgeVisible = false;
    try{
      var item = this.toServiceModel();
      if (item.adguid == "") {
          item.adguid = null;
      }
      let res  = await IdentityStore.addUser(item);
      this.isMessgeVisible = false;
      this.reset();
      routes.listUser.push();
    } catch (e:any) {      
      e.text().then((_responseText: any) => {
        this.isMessgeVisible = false;
            this.response = _responseText;
            this.isMessgeVisible = true;
        });
    //this.setAddEditGroupNameDialogVisible(false);
    }
};

@action updateUser = async () => {
  try{
  var item = this.toServiceModel();
    localStorage.setItem("noCacheload", "true");
    if (item.adguid == "") {
        item.adguid = null;
    }
  await IdentityStore.updateUser(item);
  await this.getUserPermission();
  this.reset();
  this.isMessgeVisible = false;
  routes.listUser.push();
}catch (e:any) {
  e.text().then((_responseText: any) => {
      let _title = JSON.parse(_responseText).title;
      let _status = JSON.parse(_responseText).status;
      var responseModels = {
        status: _status,
        title: "",
              errors: { "ValidationError": [_title] },
    };
        this.response = JSON.stringify(responseModels);
        this.isMessgeVisible = true;
    });
  //this.setAddEditGroupNameDialogVisible(false);
}

};  

  @action loadTeams = async (result: any) => {
    if (result) {
      this.teamList.values = [];
      this.teamList.values = this.mapListItemAndSort(result, "team");
      this.selectedTeam = this.teamList.values[0].value;
    }
    this.isLoading = false;
  };

  @action loadMarketers = async (result: any) => {
    if (result) {
      this.marketerList.values = [];
      this.marketerList.values = this.mapListItemAndSort(result, "marketer");
      this.selectedMarketer = this.marketerList.values[0].value;
    }
    this.isLoading = false;
  };

  @action getAdUserDetailsByUpn = async (upnId: string) => {
    var item = await IdentityStore.getAdUserDetailsByUpn(upnId);
    if (item) {
      this.firstName = item.firstName || "";
      this.lastName = item.lastName || "";
      this.extension = undefined; //+item.telephone || "";
      this.email = item.email || "";
      this.domainName = this.selectedUpn;
      this.isActive = true;
      this.isADSyncable = true;
    }
  };

  @action loadUserRoles = async (result: any) => {
    if (result) {
      this.tempRoleList.values = this.mapListItemAndSort(result, "role");
      this.roleList.values = [];
      var roles = this.tempRoleList.values.filter(
        (x) => x.label !== Constants.UserDownlineAgencyRole
      );
      this.roleList.values = roles;
      this.selectedRoles = [];
      this.tempSelectedRoles = [];
    }
    this.isLoading = false;
  };
  @action loadUserDownlineAgencyRoles = async () => {
    const roles = await IdentityStore.getUserRoleLookup(
      this.selectedId,
      this.isDownlineAgencyUser || false
    );

    if (roles && roles.length > 0) {
      this.roleList.values = [];
      this.roleList.values = this.mapListItemAndSort(roles, "role");
      this.selectedRoles = [];
    }
  };

  @action mapListItemAndSort(listObjects: any, actionV: string) {
    if (listObjects) {
      if (actionV === "marketer") {
        listObjects.splice(0, 0, { text: "None", value: "0", selected: false });
      }
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
    }

    @action setCancelChangesConfirmation(value: boolean) {
        this.isCancelChangesConfirmation = value;
    }

  @action getUserPermission = async () => {
    await IdentityStore.getUserPermissions("" + UserContext.getUserId()).then(
      (data: any) => {
        if (data) {
          UserContext.permissions = data.permissions;
          UserContext.roles = data.roles;
        }
      }
    );
  };
}

class UserValidator extends AbstractValidator<UserAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.firstName)
      .isNotEmpty()
      .withFailureMessage("First Name is required");
    this.validateIfString((input) => input.lastName)
      .isNotEmpty()
      .withFailureMessage("Last Name is required");
    this.validateIfString((input) => input.email)
      .isNotEmpty()
      .withFailureMessage("Email is required");
    this.validateIfString((input) => input.selectedTeam)
      .isNotEmpty()
      .withFailureMessage("Team is required");
  }
}
