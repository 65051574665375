import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import ViewModel from "../../../../infrastructure/ViewModel";
import { routes } from "../../../../router";
import { SubCategoryDetailsModel } from "../../../../services/ProductService";
import ProductStore from "../../../../store/ProductStore";

export class ProductSubCategoryDetailsViewModel  implements ViewModel  {
    
    get CanClose(): boolean {
      throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
      return true;
    }
    get IsLoaded(): boolean {
      throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
      throw new Error("Method not implemented.");
    }
    Close(): void {
      throw new Error("Method not implemented.");
    }

    @observable ProductList = new Collection<SubCategoryDetailsModel>();
    @observable sortOrder: boolean = true;
    @observable sortColumn: string = "prodNum"
    @observable id: number = 0;

    @action Load = async (id: number) => {
      this.id =  id;
      await this.loadProducts()
    };

    Route = async (): Promise<void> => { };

    @action loadProducts = async() => {
      var result = await ProductStore.getSubCategoryDetailsById(this.id, this.sortColumn, this.sortOrder);
      if (result) {
        this.ProductList.values = result.data ? result.data : []
      }
    }

    @action backToAdmin = async() => {
        routes.listProductSubCategory.replace();
    }

    @action setSortOrder() {
      this.sortOrder = !this.sortOrder;
    }
  
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }

}
