import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import { CreateUpdateSupplyOrderModel, 
        Client as SupplyOrderServiceClient, 
        CreateUpdateSuppliesRequest} from "../services/SupplyOrderService";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";


class SupplyOrderStore {

    getApiVersion = () => {
        return '1'
    }
    getTimezoneOffset = () => {
        return new Date().getTimezoneOffset()
    }
    getApiKey = async (): Promise<string> => {
        var token = await getToken();
        return token ? `Bearer ${token}` : "";
    }
    getClient = (): SupplyOrderServiceClient => {    
        return ServiceClient.getClient(ServiceType.SupplyOrder);
      }
    @action getSupplyOrders = async (agentId: number, fromAgent: boolean | undefined, status: number,
        searchAgentId: number | undefined, createdByUserId: number | undefined, orderId: number | undefined, startDate: Date | undefined, endDate: Date | undefined,
        startIndex: number, pageSize: number, sortColumn: string | undefined, sortAscending: boolean | undefined) => {
       
           
            var result = await this.getClient().getAllSupplyOrder(agentId, this.getApiVersion(), fromAgent, status,
                searchAgentId, createdByUserId, orderId, startDate, endDate, startIndex, pageSize, sortColumn,
                sortAscending, undefined, UserContext.getUserId());
            return result;
       
    }
    @action deleteSupplyOrder = async (orderId: number) => {        
            var result = await this.getClient().deleteSupplyOrder(orderId, this.getApiVersion(),UserContext.getUserId());
            return result;      
    }
    @action getSupplyOrderById = async (orderId: number) => {       
            var result = await this.getClient().getSupplyOrderDetailById(orderId, this.getApiVersion(), UserContext.getUserId());
            return result;       
    }
    @action getSupplyOrderOptions = async (agentId: number) => {       
            var result = await this.getClient().getAllSupplyOrderLookup(agentId, this.getApiVersion(),
                UserContext.getUserId());
            return result;        
    }
    @action SupplyOrderagain = async (orderId: number) => {       
            var result = await this.getClient().orderAgain(orderId, this.getApiVersion(),
                UserContext.getUserId());
            return result;
       
    }
    @action deleteSupplyItems = async (orderItemId: number) => {       
            var result = await this.getClient().deleteSupplyItem(orderItemId, this.getApiVersion(),
               UserContext.getUserId());
            return result;
    }
    @action getSupplyOrderCount = async (agentId: number) => {       
        var result = await this.getClient().getSupplyOrderCount(agentId, this.getApiVersion(),
        UserContext.getUserId());
        return result;
    }
    @action getSupplyItemList = async (stateId: number, companyId: number, typeId: number) => {        
            var result = await this.getClient().getAllSupplyItems(this.getApiVersion(), companyId, stateId, typeId,
               UserContext.getUserId());
            return result;       
    }
    @action getAppliableStates = async (supplyItemId: number) => {       
            var result = await this.getClient().getAllApplicableStates(supplyItemId, this.getApiVersion(), UserContext.getUserId());
            return result;        
    }
    @action SaveSupplyOrder = async (createUpdateSupplyOrderModel: CreateUpdateSupplyOrderModel) => {    
       
        var result = await this.getClient().createSupplyOrder(this.getApiVersion(),
            UserContext.getUserId(), undefined, createUpdateSupplyOrderModel);
        return result;
       
    }
    @action UpdateSupplyOrder = async (createUpdateSupplyOrderModel: CreateUpdateSupplyOrderModel) => {       
        var result = await this.getClient().updateSupplyOrder(this.getApiVersion(),
           UserContext.getUserId(), undefined, createUpdateSupplyOrderModel);
        return result;
       
    }
    @action downloadSupplyOrders = async (agentId: number, fromAgent: boolean | undefined, status: number,
        searchAgentId: number | undefined, createdByUserId: number | undefined, orderId: number | undefined, startDate: Date | undefined, endDate: Date | undefined) => {
       
            var result = await this.getClient().downloadReport(agentId, this.getApiVersion(), fromAgent, status,
                searchAgentId, createdByUserId, orderId, startDate, endDate, undefined, undefined, undefined,
                undefined, undefined, UserContext.getUserId());
            return result;       
    }

    /* Admin Supplies */
    @action getSupplies = async (carrierId: number | undefined, productTypeId: number | undefined, 
        stateId: number | undefined, statusId: number | undefined, item: string | undefined, description: string | undefined, startIndex: number, pageSize: number,
        sortColumn: string | undefined, sortAscending: boolean | undefined) => {
           
            var result = await this.getClient().getAllSupplies(this.getApiVersion(), carrierId, productTypeId,
                stateId, statusId, item, description, startIndex, pageSize, sortColumn, sortAscending, undefined, UserContext.getUserId());
            return result;
       
    }
    @action getSuppliesById = async (orderId: number) => {       
        var result = await this.getClient().getSuppliesById(orderId, this.getApiVersion(), UserContext.getUserId());
        return result;
    }
    @action deleteSupplies = async (orderId: number) => {        
            var result = await this.getClient().deleteSupplies(this.getApiVersion(), orderId.toString(), UserContext.getUserId(), undefined,orderId);
            return result;      
    }
    @action addSupplies = async (request: CreateUpdateSuppliesRequest) => {        
        var result = await this.getClient().addSupplies(this.getApiVersion(), UserContext.getUserId(), undefined, request);
        return result;      
    }
    @action updateSupplies = async (request: CreateUpdateSuppliesRequest) => {        
        var result = await this.getClient().updateSpplies(this.getApiVersion(), UserContext.getUserId(), undefined, request);
        return result;      
    }
   
    /* Admin Supplies */
}
export default new SupplyOrderStore();
