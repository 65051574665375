import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import WorkflowStore from "../../store/WorkflowStore";
import { LocationType } from "../../services/WorkflowService";
import Utils from "../../infrastructure/Utils";

export class AttachDocumentViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable isWorkflowApiCall: boolean = true;
  @observable isAPICall: boolean = true;

  @action Load = async () => {
    await this.ResetValidate();
    this.reset();
  };

  @action setComments(value: string) {
    this.comments = value;
  }

  @action setDialogVisibility(value: boolean) {
    this.isMessageVisible = false;
    this.isDialogVisible = value;
  }

  @action cancel = async () => {
    if (this.listener) {
      this.listener.cancelAttachReplaceDocument();
      this.reset();
    }
  };

  @action public save = async (docId: number, isReplace: boolean) => {
    if (this.isAPICall) {
      this.isAPICall = false;
      this.isMessageVisible = false;
      this.response = "";
      if (isReplace) {
        try {
          await this.ResetValidate();
          if (
            !(await this.Validate()) &&
            (this.fileData !== null ||
              this.fileData !== undefined ||
              this.fileData !== "")
          ) {
            this.newDocId = 0;
            let res = await this.replaceDoc(
              this.fileData,
              this.fileName,
              docId ? docId : 0,
              0,
              "Replaced with new PDF: " + this.comments.trim()
            );
            if (typeof res === "number") this.newDocId = res;
            setTimeout(() => {
              if (this.listener) {
                this.listener.attachReplaceDocumentListener();
                this.listener.cancelAttachReplaceDocument();
              }
              this.reset();
            }, 1000);
          }
          this.isWorkflowApiCall = true;
        } catch (e: any) {
          this.isMessageVisible = true;
          e && e.text
            ? e &&
              e.text().then((res: any) => {
                this.response = res;
              })
            : (this.response = e.response);

          this.isWorkflowApiCall = true;
          this.newDocId = 0;
        }
      } else {
        try {
          await this.ResetValidate();
          if (
            !(await this.Validate()) &&
            (this.fileData !== null ||
              this.fileData !== undefined ||
              this.fileData !== "")
          ) {
            this.newDocId = 0;
            let res = await this.attachDoc(
              this.fileData,
              this.fileName,
              docId ? docId : 0,
              this.selectedLocation === "1" ? 1 : 2,
              "Additional Page(s) added to existing doc: " +
                this.comments.trim()
            );
            if (typeof res === "number") this.newDocId = res;
            setTimeout(() => {
              if (this.listener) {
                this.listener.attachReplaceDocumentListener();
                this.listener.cancelAttachReplaceDocument();
              }
            }, 1000);
            this.isWorkflowApiCall = true;
          }
        } catch (e: any) {
          this.isMessageVisible = true;
          e && e.text
            ? e.text().then((res: any) => {
                this.response = res;
              })
            : (this.response = e.response);
          this.isWorkflowApiCall = true;
          this.newDocId = 0;
        }
      }
    }
    setTimeout(() => {
      this.isAPICall = true;
    }, 4000);
  };

  @action attachDoc = async (
    fileData: any,
    fileName: string,
    docId: number,
    location: LocationType,
    notes: string | undefined
  ) => {
    var authorization = await WorkflowStore.getApiKey();
    var blob = null;
    if (fileData !== null && fileData !== "" && fileData !== undefined) {
      var block = fileData ? fileData.split(";") : "";
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      blob = Utils.b64toBlob(realData, contentType, 512);
    }
    try {
      let result = WorkflowStore.postAttachData(
        WorkflowStore.getApiVersion(),
        blob,
        fileName,
        docId,
        location,
        notes,
        authorization,
        new Date().getTimezoneOffset()
      );
      return result;
    } catch (error: any) {
      error &&
        error.text().then((res: any) => {
          this.isMessageVisible = true;
          this.response = res;
        });
    }
  };

  @action replaceDoc = async (
    fileData: any,
    fileName: string,
    docId: number,
    location: LocationType,
    notes: string | undefined
  ) => {
    var authorization = await WorkflowStore.getApiKey();
    var blob = null;
    if (fileData !== null && fileData !== "" && fileData !== undefined) {
      var block = fileData ? fileData.split(";") : "";
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      blob = Utils.b64toBlob(realData, contentType, 512);
    }

    try {
      let result = WorkflowStore.postReplaceData(
        WorkflowStore.getApiVersion(),
        blob,
        fileName,
        docId,
        location,
        notes,
        authorization,
        new Date().getTimezoneOffset()
      );

      return result;
    } catch (error: any) {
      error &&
        error.text().then((res: any) => {
          this.isMessageVisible = true;
          this.response = res;
        });
    }
  };

  onDrop = (value: any, fileName: string) => {
    this.fileData = value;
    var fileNameExtension = fileName
      .substr(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    var fileNamewithoutExtension = fileName.split(".");
    var formattedFileName =
      fileNamewithoutExtension[0] + "." + fileNameExtension;
    this.fileName = formattedFileName;
  };

  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: DocumentListener | undefined;

  @observable locationList = [
    { label: "At the beginning", value: "1" },
    { label: "At the end", value: "2" },
  ];
  @observable attachTitle: string = "Select a PDF to attach to existing one:";
  @observable replaceTitle: string =
    "Select an updated PDF to replace the existing one:";
  @observable selectedLocation: string = "1";
  @observable isSuccess: boolean = false;
  @observable fileData: any = null;
  @observable comments: string = "";
  @observable isDialogVisible: boolean = false;
  @observable isUpload: boolean = false;
  @observable fileName: string = "";
  @observable response: any = null;
  @observable isMessageVisible: boolean = false;

  @observable newDocId: number = 0;

  @action setLocation = (value: string) => {
    this.selectedLocation = value;
  };
  @action reset = () => {
    this.selectedLocation = "1";
    this.isUpload = false;
    this.comments = "";
    this.fileData = null;
    this.fileName = "";
    this.isMessageVisible = false;
    this.response = "";
    this.ResetValidate();
  };

  setDocumentListener(listener: DocumentListener) {
    this.listener = listener;
  }

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @observable
  public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<AttachDocumentViewModel> {
  public constructor() {
    super();
    this.validateIfAny((input) => input.fileData)
      .isNotEmpty()
      .withFailureMessage("Please upload document");
    this.validateIfString((input) => input.comments)
      .hasMaxLength(750)
      .whenNotEmpty()
      .withFailureMessage("Note should not be more than 750 characters");
    this.validateIfString((input) => input.comments)
      .isNotEmpty()
      .withFailureMessage("Note is required");
  }
}

export interface DocumentListener {
  attachReplaceDocumentListener(): void;
  cancelAttachReplaceDocument(): void;
}
