import { AbstractValidator } from "fluent-ts-validator";
import { observable, action } from "mobx";
import TreeNode from "primereact/components/treenode/TreeNode";
import Collection from "../../infrastructure/CollectionHelper";
import { AutoDialerActionTypes, ResidentStateTypeContants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import ViewModel from "../../infrastructure/ViewModel";
import AutoDialerStore from "../../store/AutoDialerStore";

export class PropectiveCallListViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {
    //this.isException = false;
    // this.validAddressModel = undefined;
    this.resetFields();
  };
  Route = async (currentRoute: RouteList): Promise<void> => { }

  @observable residentState: any;
  @observable residentStateType: string = ResidentStateTypeContants.State;
  @observable residentZipCode: any = '';
  @observable residentCounty: { [key: string]: { checked?: boolean, partialChecked?: boolean }; } = {};
  @observable leadSource: any;
  @observable addZipCode: string = '';
  @observable addZipCodeDistance: number = 100;
  @observable addZipCodeKeepZipCodes: boolean = false;
  @observable residentStateList = new Collection<any>();
  @observable residentCountyList: TreeNode[] = [];
  @observable residentStateTypeList = [
    { label: ResidentStateTypeContants.State, value: ResidentStateTypeContants.State },
    { label: ResidentStateTypeContants.County, value: ResidentStateTypeContants.County },
    { label: ResidentStateTypeContants.ZipCode, value: ResidentStateTypeContants.ZipCode }
  ];
  @observable leadSourceList = new Collection<any>();
  @observable marketingEvent: number = 0;
  @observable marketingEventList = new Collection<any>();
  @observable callListName: string = '';

  @observable isLeadSourceLoaded: boolean = false;
  @observable isStatesLoaded: boolean = false;
  @observable isMarketerEventsLoaded: boolean = false;

  @action async getLeadSource(e: any) {
    if (!this.isLeadSourceLoaded) {
      var items = await AutoDialerStore.getLeadSource(false);
      if (items && items.length > 0) {
        this.leadSourceList.values = Utils.mapListItem(items);
        var list: any = [];
        items.forEach((element: any) => { list.push(element.value) });
        this.isLeadSourceLoaded = true;
        this.leadSource = list;
        let target = document.getElementById(e.props.id);
        target && target.click();
      }
    }
  }

  @action async getAllStatesAndCountys(e: any) {
    if (!this.isStatesLoaded) {
      var items = await AutoDialerStore.getAllStatesAndCountys();
      if (items.states && items.states.length > 0) {
        var list: any = [];
        var states: any = [];
        items.states.forEach((element: any) => {
          if (!element.isHeader) {
            list.push(element.stateId);
          }
          states.push({ label: element.stateName, value: element.stateId, abbreviation: element.abbreviation, isHeader : element.isHeader });
        });
        this.residentStateList.values = states;
        this.residentState = list;
        let target = e && document.getElementById(e.props.id);
        target && target.click();
      }
      this.isStatesLoaded = true;
      if (items.countiesByState) {
        this.residentCountyList  = [];
        for (const [key, value] of Object.entries(items.countiesByState)) {

          let childrennodes: TreeNode[] = [];
          let tree: any;
          value.forEach((element: any) => {
            tree = {
              key: key + '-' + element,
              label: element,
              data: true
            } as TreeNode;
            childrennodes.push(tree);
          });
          tree = {
            key: key,
            label: key,
            data: true,
            children: childrennodes
          } as TreeNode;
          this.residentCountyList.push(tree)
        }
      }
    }
  }
  @observable ErrorModel = new ErrorModel(new PropectiveCallListValidator());
  @observable validationType: string = AutoDialerActionTypes.PriviewResult;

  @action Validate = async () => {
    return await this.ErrorModel.Validate(this);
  }

  @action ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  }

  @action async getZipcodesByRange() {
    this.validationType = AutoDialerActionTypes.AddZipCode;
    await this.ResetValidate();
    if (!await this.Validate()) {
      this.addZipCode = this.addZipCode && this.addZipCode.split('-').length > 1 ? (this.addZipCode.split('-')[1] === "_____" ? this.addZipCode.split('-')[0] : this.addZipCode) : this.addZipCode;
      var items = await AutoDialerStore.getZipcodesByRange(this.addZipCode, this.addZipCodeDistance, this.addZipCodeKeepZipCodes);
      if (this.residentZipCode && this.residentZipCode.split(',').length > 0) {
        this.residentZipCode.split(',').forEach((element: any) => {
          items = items.filter((zip: any) => { return zip !== element; });
        });
        items = items.filter((zip: any) => { return zip !== ''; });
        this.residentZipCode += (items.length > 0 ? (this.residentZipCode.trim().endsWith(',') ? '' : ',') + items.join(',') : '');
      }
      else {
        if (this.residentZipCode) {
          this.residentZipCode += items.join(',')
        }
        else {
          this.residentZipCode = items.join(',');
        }
      }
    }
  }

  @action getMarketerEvents = async (e: any) => {
    // this.isLoading = true;
    if (!this.isMarketerEventsLoaded) {
      try {
        var items = await AutoDialerStore.getMarketerEvents();
        this.marketingEventList.values = []
        Utils.mapListItemsToDropdown(items, this.marketingEventList, "None", "0");
        let target = document.getElementById(e.props.id);
        target && target.click();
        this.isMarketerEventsLoaded = true;
      } catch (e) {
      }
    }
    // this.isLoading = false;
  }

  @action resetFields() {
    this.residentState = 0;
    this.residentStateType = ResidentStateTypeContants.State;
    this.leadSource = 0;
    this.residentStateList.values = [];
    this.leadSourceList.values = [];
    this.isLeadSourceLoaded = false;
    this.isStatesLoaded = false;
    this.marketingEvent = 0;
    this.callListName = "";
  }

}

class PropectiveCallListValidator extends AbstractValidator<PropectiveCallListViewModel> {
  public constructor() {
    super();

    this.validateIfString(input => input.addZipCode)
      .isNotEmpty()
      .withFailureMessage("Zip Code is required.")
      .when(input => (input.validationType === AutoDialerActionTypes.AddZipCode));

    this.validateIfNumber(input => input.addZipCodeDistance)
      .isNotEmpty()
      .withFailureMessage("Distance is required.")
      .when(input => (input.validationType === AutoDialerActionTypes.AddZipCode));

    this.validateIfNumber(input => input.addZipCodeDistance)
      .isNotEmpty()
      .isGreaterThan(0)
      .withFailureMessage("Distance should be greater then zero")
      .when(input => (input.validationType === AutoDialerActionTypes.AddZipCode));
    
      this.validateIfString(input => input.callListName)
      .isNotEmpty()
      .isNotEqualTo('')
      .withFailureMessage("Call list Name is required.")
      .when(input => input.validationType === AutoDialerActionTypes.GenerateCallList);
  }
}