import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import { routes } from "../../../../router";
import { ProductModel } from "../../../../services/ProductService";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";
import ProductStore from "../../../../store/ProductStore";
import ContractsStore from "../../../../store/ContractsStore";

export class ProductAddEditViewModel extends BaseAddEditViewModel {

    @observable carrierList = new Collection<{ label: string; value: string; }>();
    @observable categoryList = new Collection<{ label: string; value: string; }>();
    @observable subCategoryList = new Collection<{ label: string; value: string; }>();
    @observable forceLoad:any = Math.random();
    @observable tripCategoryList = new Collection<{ label: string; value: string; }>();
    @observable divisionList = new Collection<{ label: string; value: string; }>();
    @observable defaultModeList = new Collection<{ label: string; value: string; }>();
    @observable importDefaultModeList = new Collection<{ label: string; value: string; }>();
    @observable dbcategoryList = new Collection<any>();
    @observable marginFactor: string = "";
    @observable name: string = '';
    @observable categoryName: string = '';
    @observable carrierId: number = 0;
    @observable categoryId: number = 0;
    @observable subCategoryId: number = 0;
    @observable tripCategoryId: number = 0
    // @observable policyFee?: number = 0.00
    @observable policyFee: string = "0"
    // @observable agentFee?: number = 0.00
    @observable agentFee: string = "0"
    // @observable defaultPremium?: number = 0.00
    @observable defaultPremium: string = "0"
    @observable defaultMode: number = 8
    // @observable importDefaultPremium?: number = 0.00
    @observable importDefaultPremium: string = "0"
    @observable importDefaultMode: number = 0
    @observable age1?: number = 0
    @observable age2?: number = 0
    @observable age3?: number = 0
    @observable age4?: number = 0
    @observable age5?: number = 0
    @observable age6?: number = 0
    @observable age7?: number = 0
    @observable age8?: number = 0
    @observable age9?: number = 0
    @observable age10?: number = 0
    @observable level1?: number = 0
    @observable level2?: number = 0
    @observable level3?: number = 0
    @observable level4?: number = 0
    @observable level5?: number = 0
    @observable level6?: number = 0
    @observable level7?: number = 0
    @observable level8?: number = 0
    @observable level9?: number = 0
    @observable level10?: number = 0
    @observable rider: boolean = false
    @observable hidden: boolean = false
    @observable checkForCertification: boolean = false
    @observable checkForAppointment: boolean = false
    @observable isAppointmentAndCertificationRequired: boolean = false
    @observable isShowPremiumInCommissionReportStatement: boolean = false

    constructor() {
        super("Product", routes.listProduct, new ProductValidator());
    }

    protected toServiceModel() {
        var dto: ProductModel = {
            id: this.selectedId,
            prodNum: this.name,
            companyId: this.carrierId,
            product: undefined,
            //typeId?: this.ty;
            // age1: this.age1,
            // level1: this.level1,
            // age2: this.age2,
            // level2: this.level2,
            // age3: this.age3,
            // level3: this.level3,
            // age4: this.age4,
            // level4: this.level4,
            // age5: this.age5,
            // level5: this.level5,
            // age6: this.age6,
            // level6: this.level6,
            // age7: this.age7,
            // level7: this.level7,
            // age8: this.age8,
            // level8: this.level8,
            // age9: this.age9,
            // level9: this.level9,
            // age10: this.age10,
            // level10: this.level10,
            policyFee: this.policyFee ? Utils.formatNum(this.policyFee) : 0,
            agentFee: this.agentFee ? Utils.formatNum(this.agentFee) : 0,
            rider: this.rider,
            tripCategoryId: this.tripCategoryId,
            hidden: this.hidden,
            productCategoryId: this.categoryId,
            defaultPremium: this.defaultPremium ? Utils.formatNum(this.defaultPremium) : 0,
            defaultMode: this.defaultMode,
            importDefaultPremium: this.importDefaultPremium ? Utils.formatNum(this.importDefaultPremium) : 0,
            importDefaultMode: this.importDefaultMode,
            subCategoryId: this.subCategoryId,
            carrierProductName: this.name,
            //fraudWatch: this.fraud
            //divisionId: this.divisionId,
            checkForCertification: this.checkForCertification,
            checkForAppointment: this.checkForAppointment,
            //productClassId: this.clas
            //productCode: this.co
            isAppointmentAndCertificationRequired: this.isAppointmentAndCertificationRequired,
            isShowPremiumInCommissionReportStatement: this.isShowPremiumInCommissionReportStatement,
            //carriers?: ListItem[] | undefined;
            //subCategories?: ListItem[] | undefined;
            //tripCategories?: ListItem[] | undefined;
            //divisions?: ListItem[] | undefined;
            //modes?: ListItem[] | undefined;
            //defaultModes?: ListItem[] | undefined;

        }
        return dto;
    }

    @action reset = () => {
        this.name = "";
        this.policyFee = "0";
        this.agentFee = "0";
        this.defaultPremium = "0";
        this.defaultMode = 0;
        this.importDefaultPremium = "0";
        this.importDefaultMode = 0;
        this.rider = false;
        this.hidden = false;
        this.checkForCertification = false;
        this.checkForAppointment = false;
        this.isAppointmentAndCertificationRequired = false;
        this.isShowPremiumInCommissionReportStatement = false;
        this.tripCategoryId = 0;
        this.carrierId = 0;
        this.marginFactor ="";
        this.setSubCategoryId(0);
    }

    protected async loadLookups(): Promise<void> {
        await this.reset()
        await this.loadCarrierList();
        await this.loadSubCategoryList();
        await this.loadCategoryList();
    }
    @action loadcarriersOnAddProduct = async () => {
        await this.loadCarrierList();
        await this.loadSubCategoryList();

    }
    private async loadCarrierList() {
        var items = await ContractsStore.getAllCompanyLookup();
        Utils.mapListItemsToDropdown(items, this.carrierList, "Select", "0");
    }
    private async loadCategoryList() {
        var items = await ProductStore.getProductCategoryLookup();
        Utils.mapListItemsToDropdown(items, this.categoryList, "Select", "0");
    }
    private async loadSubCategoryList() {
        this.subCategoryList.values = [];
        var result = await ProductStore.getProductSubCategoriesLookup();
        this.dbcategoryList.values = result.subCategories ? result.subCategories : [];
        this.subCategoryList.values.push({ label: "Select", value: "0" });
        result.subCategories && result.subCategories.forEach((element: any) => {
            this.subCategoryList.values.push({ label: element.subCategoryText, value: element.subCategoryValue });
        });
        result.divisions && Utils.mapListItemsToDropdown(result.divisions, this.divisionList, "Select", "0");
        result.tripCategories && Utils.mapListItemsToDropdown(result.tripCategories, this.tripCategoryList, "");
        result.modes && Utils.mapListItemsToDropdown(result.modes, this.defaultModeList, "0", "0");
        result.modes && Utils.mapListItemsToDropdown(result.modes, this.importDefaultModeList, "0", "0");
        this.forceLoad = Math.random();
    }

    private async loadMarginFactorBySubCatId(id: number) {
        var result = await ProductStore.getMarginFactorBySubcategoryId(id);
        if (result != null) {
            this.marginFactor = (result != undefined && result >=0) ?''+result:  "";
        }
        
    }
    protected async loadItem(id: number): Promise<void> {
        var item = await ProductStore.getProductById(id.toString());
        if (item) {
            this.name = item.prodNum || "";
            this.age1 = item.age1;
            this.age2 = item.age2;
            this.age3 = item.age3;
            this.age4 = item.age4;
            this.age5 = item.age5;
            this.age6 = item.age6;
            this.age7 = item.age7;
            this.age8 = item.age8;
            this.age9 = item.age9;
            this.age10 = item.age10;
            this.level1 = item.level1;
            this.level2 = item.level2;
            this.level3 = item.level3;
            this.level4 = item.level4;
            this.level5 = item.level5;
            this.level6 = item.level6;
            this.level7 = item.level7;
            this.level8 = item.level8;
            this.level9 = item.level9;
            this.level10 = item.level10;
            this.marginFactor = (item.marginFactor != undefined && item.marginFactor >0) ?''+item.marginFactor:  "";
            this.policyFee = item.policyFee ? item.policyFee.toString() : "0";
            this.agentFee = item.agentFee ? item.agentFee.toString() : "0";
            this.defaultPremium = item.defaultPremium ? item.defaultPremium.toString() : "0";
            this.defaultMode = item.defaultMode || 0;
            this.importDefaultPremium = item.importDefaultPremium ? item.importDefaultPremium.toString() : "0";
            this.importDefaultMode = item.importDefaultMode || 0;
            this.rider = item.rider || false;
            this.hidden = item.hidden || false;
            this.checkForCertification = item.checkForCertification || false;
            this.checkForAppointment = item.checkForAppointment || false;
            this.isAppointmentAndCertificationRequired = item.isAppointmentAndCertificationRequired || false;
            this.isShowPremiumInCommissionReportStatement = item.isShowPremiumInCommissionReportStatement || false;
            this.tripCategoryId = item.tripCategoryId || 0;
            //this.divisionId = item.divisionId || 0;

            //this.setSubCategoryId(item.subCategoryId || 0);
            this.carrierId = item.companyId || 0;
            //this.setCategory(item.productCategoryId || 0);
            this.setSubCategoryId(item.subCategoryId || 0);
        }

    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await ProductStore.addProduct(item);
        this.reset();
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await ProductStore.updateProduct(item);
        this.reset();
    }

    @action setCarrierId = (value: number) => {
        this.carrierId = value;
    }
    @action setName = (value: string) => {
        this.name = value;
    }
    @action setSubCategoryId(value: number) {
        this.subCategoryId = value;
        let category = this.dbcategoryList.values.find(i => i.subCategoryValue === value.toString());
        this.categoryId = category ? category.categoryId : 0;
        this.categoryName = category ? category.categoryName : "";

    }
    @action async subCategoryChange(value: number) {
        this.setSubCategoryId(value);
        await this.loadMarginFactorBySubCatId(value);

    }

    @action async setCategory(value: number) {
        this.categoryId = value;
        await this.loadSubCategoryList();
    }
    @action setTripCategoryId(value: number) {
        this.tripCategoryId = value;
    }
    //@action setDivisionId(value: number) {
    //    this.divisionId = value;
    //}
    @action setPolicyFee(value: string) {
        this.policyFee = value;
    }
    @action setAgentFee(value: string) {
        this.agentFee = value;
    }
    @action setDefaultPremium(value: string) {
        this.defaultPremium = value;
    }
    @action setDefaultMode(value: number) {
        this.defaultMode = value;
    }
    @action setImportDefaultPremium(value: string) {
        this.importDefaultPremium = value;
    }
    @action setImpirtDefaultMode(value: number) {
        this.importDefaultMode = value;
    }
    @action setAge1(value: number) {
        this.age1 = value;
    }
    @action setAge2(value: number) {
        this.age2 = value;
    }
    @action setAge3(value: number) {
        this.age3 = value;
    }
    @action setAge4(value: number) {
        this.age4 = value;
    }
    @action setAge5(value: number) {
        this.age5 = value;
    }
    @action setAge6(value: number) {
        this.age6 = value;
    }
    @action setAge7(value: number) {
        this.age7 = value;
    }
    @action setAge8(value: number) {
        this.age8 = value;
    }
    @action setAge9(value: number) {
        this.age9 = value;
    }
    @action setAge10(value: number) {
        this.age10 = value;
    }
    @action setLevel1(value: number) {
        this.level1 = value;
    }
    @action setLevel2(value: number) {
        this.level2 = value;
    }
    @action setLevel3(value: number) {
        this.level3 = value;
    }
    @action setLevel4(value: number) {
        this.level4 = value;
    }
    @action setLevel5(value: number) {
        this.level5 = value;
    }
    @action setLevel6(value: number) {
        this.level6 = value;
    }
    @action setLevel7(value: number) {
        this.level7 = value;
    }
    @action setLevel8(value: number) {
        this.level8 = value;
    }
    @action setLevel9(value: number) {
        this.level9 = value;
    }
    @action setLevel10(value: number) {
        this.level10 = value;
    }
    @action setRider(value: boolean) {
        this.rider = value;
    }
    @action setHidden(value: boolean) {
        this.hidden = value;
    }
    @action setCheckforCertification(value: boolean) {
        this.checkForCertification = value;
    }
    @action setCheckforAppointment(value: boolean) {
        this.checkForAppointment = value;
    }
    @action setAppointmentAndCertificationRequired(value: boolean) {
        this.isAppointmentAndCertificationRequired = value;
    }
    @action setShowPremiumInCommissionReportStatementChange(value: boolean) {
        this.isShowPremiumInCommissionReportStatement = value;
    }
}

class ProductValidator extends AbstractValidator<ProductAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfNumber(input => input.carrierId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("Carrier is required");
        this.validateIfString(input => input.name)
            .isNotEmpty()
            .isNotEqualTo("0")
            .matches(/\S+/, "i")
            .withFailureMessage("Product Name is required");
        this.validateIfNumber(input => input.subCategoryId)
            .isGreaterThan(0)
            .withFailureMessage("SubCategory is required");
        //this.validateIfNumber(input => input.divisionId)
        //    .isGreaterThan(0)
        //    .withFailureMessage("Division is required");
        this.validateIfString(input => input.policyFee)
            .isNotEmpty()
            .withFailureMessage("Policy Fee is required");
        this.validateIfString(input => input.agentFee)
            .isNotEmpty()
            .withFailureMessage("Agent Fee is required");
        this.validateIfString(input => input.defaultPremium)
            .isNotEmpty()
            .withFailureMessage("Default Premium is required");
        this.validateIfString(input => input.importDefaultPremium)
            // .isGreaterThanOrEqual(0)
            .isNotEmpty()
            .withFailureMessage("Import Default Premium is required");
    }
}