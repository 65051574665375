import React from "react";
import { observer } from "mobx-react";
import { HomeViewModel } from "./../../viewModels/home/HomeViewModel";
import { TodaysTaskView } from "./TodaysTaskView";
import { AnnouncementsView } from "./AnnouncementsView";
import { Card } from "primereact/card";
import { routes } from "../../router";
import { Chart } from "react-google-charts";
import {
    AgentTripPointModel,
    Top5MarketingEventsModel,
} from "../../services/ProducerService";
import Utils from "../../infrastructure/Utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    homeTopKpiTitles,
    homeMidKpiTitles,
    homeBottomKpTitles,
    homeKpis,
} from "../../infrastructure/enum/Constants";
import { Permission } from "../../infrastructure/enum/Permission";

export const HomeView: React.FC<{ vm: HomeViewModel }> = observer((props) => {
    const handleTopKpiDragEnd = (result: any) => {
        if (!result.destination) return;
        const items = Array.from(topKpis);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        topKpis = items;
        let storageItems = items.map((i: any) => {
            return { title: i.title, kpiId: i.kpiId };
        });
        localStorage.setItem(homeKpis.homeTopKpis, JSON.stringify(storageItems));
    };

    const handleBottomKpiDragEnd = (result: any) => {
        if (!result.destination) return;
        const items = Array.from(bottomKpis);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        bottomKpis = items;
        let storageItems = items.map((i: any) => {
            return { title: i.title, kpiId: i.kpiId };
        });
        localStorage.setItem(homeKpis.homeBottomKpis, JSON.stringify(storageItems));
    };

    const handleMidKpiDragEnd = (result: any) => {
        if (!result.destination) return;
        const items = Array.from(midKpis);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        midKpis = items;
        let storageItems = items.map((i: any) => {
            return { title: i.title, kpiId: i.kpiId };
        });
        localStorage.setItem(homeKpis.homeMidKpis, JSON.stringify(storageItems));
    };

    let appCountData = () => {
        return props.vm.barChartValues ? (
            <>
                <Chart
                    className="home-custom-bar-chart"
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={
                        props.vm.barChartValues ? JSON.parse(props.vm.barChartValues) : [[]]
                    }
                    options={{
                        explorer: {
                            axis: "vertical",
                            maxZoomIn: 0,
                            maxZoomOut: 0,
                            zoomDelta: 0,
                        },
                        height: 320,
                        vAxis: {
                            // title: "App Counts",
                            format: "decimal",
                            gridlines: {
                                count: 3,
                                // color: "#FFF",
                                // minSpacing: 0,
                            },
                            // minorGridlines:{
                            //   count: 0,
                            // },
                        },
                        chartArea: {
                            width: "80%",
                        },
                        hAxis: {
                            // title: "Months",
                        },
                        bar: { groupWidth: "100%" },
                        legend: { position: "bottom" },
                        // tooltip: { trigger: "visible", isHTML: true },
                        // legend : {position: 'bottom', textStyle: {color: 'blue', fontSize: 16}}
                    }}
                />
                {props.vm.barChartValues === "[[]]" && (
                    <div className="custom-no-data-component bar-chart">
                        <p>No App Counts!</p>
                    </div>
                )}
            </>
        ) : (
            <div>Loading...</div>
        );
    };

    let myTasksData = () => {
        return <TodaysTaskView vm={props.vm.TodaysTaskViewModel} />;
    };

    let mrktgEventsData = () => {
        return (
            <div className="content-wrapper">
                {props.vm.mrktgEvents.length > 0 ? (
                    props.vm.mrktgEvents.map((i: Top5MarketingEventsModel) => {
                        return (
                            <div
                                className="content-box"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    routes.marketingEventDetail.push({
                                        marketingEventId: i.id ? i.id : 0,
                                    })
                                }
                            >
                                <span>
                                    <b>
                                        {props.vm.formatDate(
                                            i.displayStartDate
                                                ? new Date(i.displayStartDate)
                                                : new Date()
                                        )}
                                    </b>
                                </span>

                                <br />
                                {/* [${i.id ? i.id : ""}] -  */}
                                {`${Utils.getTimeInFormat(i.displayStartDate)}  ${i.marketingFormatName ? i.marketingFormatName : ""
                                    }
                      -
                      ${i.title ? i.title : ""} - ${i.ownerName ? i.ownerName : ""
                                    }`}
                            </div>
                        );
                    })
                ) : (
                    <>
                        <div
                            className="content-box"
                            style={{ backgroundColor: "transparent", paddingLeft: "0" }}
                        >
                            No Events to show!
                        </div>
                    </>
                )}
            </div>
        );
    };
    let announcementsData = () => {
        return <AnnouncementsView vm={props.vm.AnnouncementsViewModel} />;
    };

    let myTasksClick = () => {
        if (Utils.hasUserPermission(Permission.ViewTasks)) {
            return <span onClick={() => routes.tasks.push({})}>View All &gt;</span>;
        }
    };
    let calendarClick = () => {
        return (
            <span onClick={() => routes.listMarketingEvent.push({})}>
                View Calendar &gt;
            </span>
        );
    };
    let announcementsClick = () => {
        if (Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements) || Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements)) {
            return (
                <span onClick={() => routes.listAnnouncement.push({})}>
                    View All &gt;
                </span>
            );
        }
    };
    let carrierData = () => {
        return props.vm.carrierChartValues ? (
            <>
                <Chart
                    className="home-custom-pie-chart"
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={
                        props.vm.carrierChartValues
                            ? JSON.parse(props.vm.carrierChartValues)
                            : [[]]
                        // : [
                        //     [
                        //       "Carrier",
                        //       "App Count",
                        //       {
                        //         role: "tooltip",
                        //         type: "string",
                        //         p: { html: true },
                        //       },
                        //     ],
                        //   ]
                    }
                    options={{
                        title: "",
                        is3D: true,
                        pieSliceText: "none",
                        // legend: "none",
                        //  legend: {position: 'bottom', textStyle: { fontSize: 14}},
                        tooltip: { trigger: "visible", isHTML: true },
                        text: "both",
                        chartArea: {
                            width: "100%",
                        },
                    }}

                // rootProps={{ 'data-testid': '1' }}
                />
                {props.vm.carrierChartValues === "[[]]" && (
                    <div className="custom-no-data-component">
                        <p>No Carriers!</p>
                    </div>
                )}
            </>
        ) : (
            <div>Loading...</div>
        );
    };

    let agentsData = () => {
        return (
            <>
                <p className="sub-title">
                    <b>Name</b>{" "}
                    <span>
                        <b>Points</b>
                    </span>
                </p>
                <ul>
                    {props.vm.agentLists.length > 0 ? (
                        props.vm.agentLists.map((i: AgentTripPointModel) => {
                            return (
                                <li>
                                    {i.agentName ? i.agentName : ""}
                                    <span>
                                        {i.totalTripPoints ? i.totalTripPoints.toFixed(2) : ""}
                                    </span>
                                </li>
                            );
                        })
                    ) : (
                        <li>No agents!</li>
                    )}
                </ul>
            </>
        );
    };

    let productTypesData = () => {
        return props.vm.productTypesChartValues ? (
            <>
                <Chart
                    className="home-custom-pie-chart"
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={
                        props.vm.productTypesChartValues
                            ? JSON.parse(props.vm.productTypesChartValues)
                            : [[]]
                        // : [
                        //     [
                        //       "Product Types",
                        //       "App Count",

                        //     ],
                        //   ]
                    }
                    options={{
                        is3D: true,
                        pieSliceText: "none",
                        title: "",
                        // legend: "none",
                        // tooltip: { trigger: "none" },
                        chartArea: {
                            width: "100%",
                        },
                    }}
                />
                {props.vm.productTypesChartValues === "[[]]" && (
                    <div className="custom-no-data-component">
                        <p>No product types!</p>
                    </div>
                )}
            </>
        ) : (
            <div>Loading...</div>
        );
    };

    let homeTopKpiData = [
        {
            title: homeTopKpiTitles.ContractsInWorkflow,
            content: props.vm.totalContractsInWorkflow,
            kpiId: homeTopKpiTitles.topKpiOne,
        },
        {
            title: homeTopKpiTitles.AppsInWorkflow,
            content: props.vm.totalAppsInWorkflow,
            kpiId: homeTopKpiTitles.topKpiTwo,
        },
        {
            title: homeTopKpiTitles.LivesImpacted,
            content: props.vm.livesImpacted,
            kpiId: homeTopKpiTitles.topKpiThree,
        },
        {
            title: homeTopKpiTitles.ContractedAgents,
            content: props.vm.contractedAgents,
            kpiId: homeTopKpiTitles.topKpiFour,
        },
    ];

    let homeMidKpiData = [
        {
            title: homeMidKpiTitles.AppCountsByMonthYear,
            content: appCountData(),
            spanClick: "",
            kpiId: homeMidKpiTitles.midKpiOne,
        },
        {
            title: homeMidKpiTitles.MyTasks,
            content: myTasksData(),
            spanClick: myTasksClick(),
            kpiId: homeMidKpiTitles.midKpiTwo,
        },
        {
            title: homeMidKpiTitles.MarketingEvents,
            content: mrktgEventsData(),
            spanClick: calendarClick(),
            kpiId: homeMidKpiTitles.midKpiThree,
        },
        {
            title: homeMidKpiTitles.Announcements,
            content: announcementsData(),
            spanClick: announcementsClick(),
            kpiId: homeMidKpiTitles.midKpiFour,
        },
    ];

    let homeBottomKpiData = [
        {
            title: homeBottomKpTitles.Carriers,
            content: carrierData(),
            kpiId: homeBottomKpTitles.bottomKpiOne,
        },
        {
            title: homeBottomKpTitles.Agents,
            content: agentsData(),
            kpiId: homeBottomKpTitles.bottomKpiTwo,
        },
        {
            title: homeBottomKpTitles.ProductTypes,
            content: productTypesData(),
            kpiId: homeBottomKpTitles.bottomKpiThree,
        },
    ];

    let topKpis = [
        {
            id: 0,
            title:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[0].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[0].kpiId
                        )[0].title
                        : homeTopKpiData[0].title
                    : homeTopKpiTitles.ContractsInWorkflow,
            content:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[0].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[0].kpiId
                        )[0].content
                        : homeTopKpiData[0].content
                    : props.vm.totalContractsInWorkflow,
            kpiId:
                Utils.getHomeTopKpis().length > 0
                    ? Utils.getHomeTopKpis()[0].kpiId
                        ? Utils.getHomeTopKpis()[0].kpiId
                        : homeTopKpiTitles.topKpiOne
                    : homeTopKpiTitles.topKpiOne,
        },
        {
            id: 1,
            title:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[1].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[1].kpiId
                        )[0].title
                        : homeTopKpiData[1].title
                    : homeTopKpiTitles.AppsInWorkflow,
            content:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[1].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[1].kpiId
                        )[0].content
                        : homeTopKpiData[1].content
                    : props.vm.totalAppsInWorkflow,
            kpiId:
                Utils.getHomeTopKpis().length > 0
                    ? Utils.getHomeTopKpis()[1].kpiId
                        ? Utils.getHomeTopKpis()[1].kpiId
                        : homeTopKpiTitles.topKpiTwo
                    : homeTopKpiTitles.topKpiTwo,
        },
        {
            id: 2,
            title:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[2].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[2].kpiId
                        )[0].title
                        : homeTopKpiData[2].title
                    : homeTopKpiTitles.LivesImpacted,
            content:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[2].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[2].kpiId
                        )[0].content
                        : homeTopKpiData[2].content
                    : props.vm.livesImpacted,
            kpiId:
                Utils.getHomeTopKpis().length > 0
                    ? Utils.getHomeTopKpis()[2].kpiId
                        ? Utils.getHomeTopKpis()[2].kpiId
                        : homeTopKpiTitles.topKpiThree
                    : homeTopKpiTitles.topKpiThree,
        },
        {
            id: 3,
            title:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[3].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[3].kpiId
                        )[0].title
                        : homeTopKpiData[3].title
                    : homeTopKpiTitles.ContractedAgents,
            content:
                Utils.getHomeTopKpis().length > 0
                    ? homeTopKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeTopKpis()[3].kpiId
                    ).length > 0
                        ? homeTopKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeTopKpis()[3].kpiId
                        )[0].content
                        : homeTopKpiData[3].content
                    : props.vm.contractedAgents,
            kpiId:
                Utils.getHomeTopKpis().length > 0
                    ? Utils.getHomeTopKpis()[3].kpiId
                        ? Utils.getHomeTopKpis()[3].kpiId
                        : homeTopKpiTitles.topKpiFour
                    : homeTopKpiTitles.topKpiFour,
        },
    ];

    let midKpis = [
        {
            id: 0,
            title:
                // Utils.getHomeMidKpis().length > 0
                //   ? Utils.getHomeMidKpis()[0].title
                //   : homeMidKpiTitles.AppCountsByMonthYear,
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[0].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[0].kpiId
                        )[0].title
                        : homeMidKpiData[0].title
                    : homeMidKpiTitles.AppCountsByMonthYear,
            content:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[0].title
                //     )[0].content
                //   : appCountData(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[0].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[0].kpiId
                        )[0].content
                        : homeMidKpiData[0].content
                    : appCountData(),
            spanClick:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[0].title
                //     )[0].spanClick
                //   : "",
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[0].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[0].kpiId
                        )[0].spanClick
                        : homeMidKpiData[0].spanClick
                    : "",
            kpiId:
                Utils.getHomeMidKpis().length > 0
                    ? Utils.getHomeMidKpis()[0].kpiId
                        ? Utils.getHomeMidKpis()[0].kpiId
                        : homeMidKpiTitles.midKpiOne
                    : homeMidKpiTitles.midKpiOne,
        },
        {
            id: 1,
            title:
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[1].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[1].kpiId
                        )[0].title
                        : homeMidKpiData[1].title
                    : homeMidKpiTitles.MyTasks,
            content:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[1].title
                //     )[0].content
                //   : myTasksData(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[1].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[1].kpiId
                        )[0].content
                        : homeMidKpiData[1].content
                    : myTasksData(),
            spanClick:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[1].title
                //     )[0].spanClick
                //   : myTasksClick(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[1].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[1].kpiId
                        )[0].spanClick
                        : homeMidKpiData[1].spanClick
                    : myTasksClick(),
            kpiId:
                Utils.getHomeMidKpis().length > 0
                    ? Utils.getHomeMidKpis()[1].kpiId
                        ? Utils.getHomeMidKpis()[1].kpiId
                        : homeMidKpiTitles.midKpiTwo
                    : homeMidKpiTitles.midKpiTwo,
        },
        {
            id: 2,
            title:
                // Utils.getHomeMidKpis().length > 0
                //   ? Utils.getHomeMidKpis()[2].title
                //   : homeMidKpiTitles.MarketingEvents,
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[2].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[2].kpiId
                        )[0].title
                        : homeMidKpiData[2].title
                    : homeMidKpiTitles.MarketingEvents,
            content:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[2].title
                //     )[0].content
                //   : mrktgEventsData(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[2].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[2].kpiId
                        )[0].content
                        : homeMidKpiData[2].content
                    : mrktgEventsData(),
            spanClick:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[2].title
                //     )[0].spanClick
                //   : calendarClick(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[2].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[2].kpiId
                        )[0].spanClick
                        : homeMidKpiData[2].spanClick
                    : calendarClick(),
            kpiId:
                Utils.getHomeMidKpis().length > 0
                    ? Utils.getHomeMidKpis()[2].kpiId
                        ? Utils.getHomeMidKpis()[2].kpiId
                        : homeMidKpiTitles.midKpiThree
                    : homeMidKpiTitles.midKpiThree,
        },
        {
            id: 3,
            title:
                // Utils.getHomeMidKpis().length > 0
                //   ? Utils.getHomeMidKpis()[3].title
                //   : homeMidKpiTitles.Announcements,
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[3].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[3].kpiId
                        )[0].title
                        : homeMidKpiData[3].title
                    : homeMidKpiTitles.Announcements,
            content:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[3].title
                //     )[0].content
                //   : announcementsData(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[3].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[3].kpiId
                        )[0].content
                        : homeMidKpiData[3].content
                    : announcementsData(),
            spanClick:
                // Utils.getHomeMidKpis().length > 0
                //   ? homeMidKpiData.filter(
                //       (i) => i.title === Utils.getHomeMidKpis()[3].title
                //     )[0].spanClick
                //   : announcementsClick(),
                Utils.getHomeMidKpis().length > 0
                    ? homeMidKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeMidKpis()[3].kpiId
                    ).length > 0
                        ? homeMidKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeMidKpis()[3].kpiId
                        )[0].spanClick
                        : homeMidKpiData[3].spanClick
                    : announcementsClick(),
            kpiId:
                Utils.getHomeMidKpis().length > 0
                    ? Utils.getHomeMidKpis()[3].kpiId
                        ? Utils.getHomeMidKpis()[3].kpiId
                        : homeMidKpiTitles.midKpiFour
                    : homeMidKpiTitles.midKpiFour,
        },
    ];

    let bottomKpis = [
        {
            id: 0,
            title:
                Utils.getHomeBottomKpis().length > 0
                    ? homeBottomKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeBottomKpis()[0].kpiId
                    ).length > 0
                        ? homeBottomKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeBottomKpis()[0].kpiId
                        )[0].title
                        : homeBottomKpiData[0].title
                    : homeBottomKpTitles.Carriers,
            content:
                Utils.getHomeBottomKpis().length > 0
                    ? homeBottomKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeBottomKpis()[0].kpiId
                    ).length > 0
                        ? homeBottomKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeBottomKpis()[0].kpiId
                        )[0].content
                        : homeBottomKpiData[0].content
                    : carrierData(),
            kpiId:
                Utils.getHomeBottomKpis().length > 0
                    ? Utils.getHomeBottomKpis()[0].kpiId
                        ? Utils.getHomeBottomKpis()[0].kpiId
                        : homeBottomKpTitles.bottomKpiOne
                    : homeBottomKpTitles.bottomKpiOne,
        },
        {
            id: 1,
            title:
                Utils.getHomeBottomKpis().length > 0
                    ? homeBottomKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeBottomKpis()[1].kpiId
                    ).length > 0
                        ? homeBottomKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeBottomKpis()[1].kpiId
                        )[0].title
                        : homeBottomKpiData[1].title
                    : homeBottomKpTitles.Agents,
            content:
                Utils.getHomeBottomKpis().length > 0
                    ? homeBottomKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeBottomKpis()[1].kpiId
                    ).length > 0
                        ? homeBottomKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeBottomKpis()[1].kpiId
                        )[0].content
                        : homeBottomKpiData[1].content
                    : agentsData(),
            kpiId:
                Utils.getHomeBottomKpis().length > 0
                    ? Utils.getHomeBottomKpis()[1].kpiId
                        ? Utils.getHomeBottomKpis()[1].kpiId
                        : homeBottomKpTitles.bottomKpiTwo
                    : homeBottomKpTitles.bottomKpiTwo,
        },
        {
            id: 2,
            title:
                Utils.getHomeBottomKpis().length > 0
                    ? homeBottomKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeBottomKpis()[2].kpiId
                    ).length > 0
                        ? homeBottomKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeBottomKpis()[2].kpiId
                        )[0].title
                        : homeBottomKpiData[2].title
                    : homeBottomKpTitles.ProductTypes,
            content:
                Utils.getHomeBottomKpis().length > 0
                    ? homeBottomKpiData.filter(
                        (i) => i.kpiId === Utils.getHomeBottomKpis()[2].kpiId
                    ).length > 0
                        ? homeBottomKpiData.filter(
                            (i) => i.kpiId === Utils.getHomeBottomKpis()[2].kpiId
                        )[0].content
                        : homeBottomKpiData[2].content
                    : productTypesData(),
            kpiId:
                Utils.getHomeBottomKpis().length > 0
                    ? Utils.getHomeBottomKpis()[2].kpiId
                        ? Utils.getHomeBottomKpis()[2].kpiId
                        : homeBottomKpTitles.bottomKpiThree
                    : homeBottomKpTitles.bottomKpiThree,
        },
    ];

    return (
        <>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12">
                        <h1>
                            <b
                                style={{ color: "#334781" }}
                            >{`Welcome, ${localStorage.getItem('displayUserName') || props.vm.loggedInUser}!`}</b>
                        </h1>
                    </div>
                </div>
            </Card>
            <DragDropContext onDragEnd={handleTopKpiDragEnd}>
                <Droppable droppableId={"topKpis"} direction="horizontal">
                    {(provided: any) => (
                        <div
                            className="p-grid home-top-parent"
                            style={{ position: "relative", overflow: "auto" }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {topKpis.map(
                                (item: any, index: number) =>
                                    ((item.title === homeTopKpiTitles.ContractsInWorkflow &&
                                        Utils.hasUserPermission(
                                            Permission.ViewContractsInWorkflow
                                        )) ||
                                        (item.title === homeTopKpiTitles.AppsInWorkflow &&
                                            Utils.hasUserPermission(
                                                Permission.ViewAppsInWorkflow
                                            )) ||
                                        (item.title === homeTopKpiTitles.LivesImpacted &&
                                            Utils.hasUserPermission(Permission.ViewLivesImpacted)) ||
                                        (item.title === homeTopKpiTitles.ContractedAgents &&
                                            Utils.hasUserPermission(Permission.ViewContractedAgents))) && (
                                        <Draggable
                                            draggableId={`${item.id}`}
                                            key={item.id}
                                            index={index}
                                        >
                                            {(provided: any) => (
                                                <div
                                                    className="p-col-12 p-md-6 p-lg-3"
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <Card className="home-top-badge">
                                                        <h3
                                                            className="badge-title"
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {item.title}
                                                        </h3>
                                                        <p className="home-top-badge-content">
                                                            {item.content}
                                                        </p>
                                                    </Card>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                            )}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <DragDropContext onDragEnd={handleMidKpiDragEnd}>
                <Droppable droppableId={"midKpis"}>
                    {(provided: any) => (
                        <div
                            className="p-grid home-mid-badge-wrapper"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {midKpis.map((item: any, index: number) => (
                                (item.title === homeMidKpiTitles.AppCountsByMonthYear && Utils.hasUserPermission(Permission.ViewAppCountsByMonthYearOverYear)) ||
                                (item.title === homeMidKpiTitles.MyTasks && Utils.hasUserPermission(Permission.ViewMyTasks)) ||
                                (item.title === homeMidKpiTitles.MarketingEvents && Utils.hasUserPermission(Permission.ViewMarketingEvents)) ||
                                (item.title === homeMidKpiTitles.Announcements && Utils.hasUserPermission(Permission.ViewAnnouncements))) && (
                                    <Draggable
                                        draggableId={`${item.id}`}
                                        key={item.id}
                                        index={index}
                                    >
                                        {(provided: any) => (
                                            <div
                                                className="p-col-12 p-md-12 p-lg-6"
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                            >
                                                <Card className="home-mid-badge">
                                                    <h3 className="badge-title">
                                                        <b {...provided.dragHandleProps}>{item.title}</b>
                                                        {item.spanClick}
                                                    </h3>
                                                    {item.content}
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <DragDropContext onDragEnd={handleBottomKpiDragEnd}>
                <Droppable droppableId={"bottomKpis"} direction="horizontal">
                    {(provided: any) => (
                        <div
                            className="p-grid"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {bottomKpis.map((item: any, index: number) => (
                                (item.title === homeBottomKpTitles.Carriers && Utils.hasUserPermission(Permission.ViewTopFiveCarriers)) ||
                                (item.title === homeBottomKpTitles.Agents && Utils.hasUserPermission(Permission.ViewTopFiveAgents)) ||
                                (item.title === homeBottomKpTitles.ProductTypes && Utils.hasUserPermission(Permission.ViewTopFiveProductTypes))) && (
                                    <Draggable
                                        draggableId={`${item.id}`}
                                        key={item.id}
                                        index={index}
                                    >
                                        {(provided: any) => (
                                            <div
                                                className="p-col-12 p-md-6 p-lg-4"
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                            >
                                                <Card className="home-bottom-badge">
                                                    <h3
                                                        className="badge-title"
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <b>{item.title}</b>
                                                    </h3>
                                                    {item.content}
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
});

// export default HomeView;
