import React from "react";
import { observer } from "mobx-react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { SplitButton } from "primereact/splitbutton";
import IMASLog from "../../infrastructure/IMASLog";
import { ExistingCallListViewModel } from "../../viewModels/autoDialers/ExistingCallListViewModel";
import { Constants } from "../../infrastructure/enum/Constants";
import { IMASConfirmationAlert } from "../components/IMASConformationAlert";
import { IMASMessagesHandler } from "../components/controls/IMASMessagesHandler";
import Utils from "../../infrastructure/Utils";
import { Permission } from '../../infrastructure/enum/Permission';
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../infrastructure/AppInsights";

export const ExistingCallListView: React.FC<{ vm: ExistingCallListViewModel }> = observer((props) => {

    const warning = () => {
        let response = {
            status: 400,
            title: props.vm.exceptionMessage,
            errors: { "": [] },
        };
        return JSON.stringify(response);
    };
    const exception = () => {
        let response = {
            status: 500,
            title: props.vm.exceptionMessage,
            errors: { "": [] },
        };
        return JSON.stringify(response);
    };

    // variables
    let actionItems: MenuItem[] = [
        {
            label: "Remove",
            icon: "fa fa-trash",
            command: () => {
                setTimeout(() => {
                    props.vm.setRemoveConfirmation(true);
                }, 300);
            },
        },
    ];

    const createdDateTemplate = (rowData: any) => {
        return (
            <>
                {Utils.getDateInFormat(rowData.createdDate) +
                    ", " +
                    Utils.getTimeInFormat(rowData.createdDate)}
            </>
        );
    };
    const actionTemplate = (rowData: any) => {
        var obj = JSON.parse(JSON.stringify(rowData));
        let remaining = obj.remaning;
        let remainingCount = remaining != null && remaining !== "" && remaining !== undefined && remaining !== 0;
        return (
            <div className="text-center">
                {(
                    //Show StartCalling and DeleteCallList if User has AppSettings permission.
                    Utils.hasUserPermission(Permission.ViewOptionsListPanel) && Utils.hasUserPermission(Permission.DeleteCallList)) ? (
                        <>
                            {
                               // if remaining value is greater than Zero show StartCalling otherwise Remove.
                                remainingCount ?
                                    <SplitButton
                                        className="secondary-button text-left"
                                        icon="fa fa-phone"
                                        label="Start Calling"
                                        style={{ width: "auto" }}
                                        onClick={() => props.vm.startCalling(rowData)}
                                        model={actionItems}
                                    /> :
                                    <SplitButton
                                        icon="fa fa-trash"
                                        className="secondary-button text-left"
                                        label="Remove"
                                        onClick={() => props.vm.setRemoveConfirmation(true)}
                                        style={{ width: "auto" }}
                                    />
                             }
                        </>
                    ) : (
                        // No AppSetting permission for StartCalling.
                        !Utils.hasUserPermission(Permission.ViewOptionsListPanel) && Utils.hasUserPermission(Permission.DeleteCallList) ? (
                            <>
                                <SplitButton
                                    icon="fa fa-trash"
                                    className="secondary-button text-left"
                                    label="Remove"
                                    onClick={() => props.vm.setRemoveConfirmation(true)}
                                    style={{ width: "auto" }}
                                />
                            </>
                        ) : (
                                // No AppSetting permission for DeleteCallList. 
                                !Utils.hasUserPermission(Permission.DeleteCallList) && Utils.hasUserPermission(Permission.ViewOptionsListPanel) && remainingCount ? (
                                    <>
                                        <SplitButton
                                            icon="fa fa-trash"
                                            className="secondary-button text-left"
                                            label="Start Calling"
                                            onClick={() => props.vm.startCalling(rowData)}
                                            style={{ width: "auto" }}
                                        />
                                    </>
                                ) : (<> </>)                      
                           )
                    )}
            </div>
        );
    };
    const onColumnSort = (column: any) => {
        IMASLog.log("sort function " + JSON.stringify(column));
        var obj = JSON.parse(JSON.stringify(column));
        props.vm.setSortOrder();
        props.vm.setSortColumn(obj.sortField);
        props.vm.loadAllExistingCallList();
    };
    const onSelectionChange = (value: any, e: any) => {
        var obj = JSON.parse(JSON.stringify(value));
        props.vm.setSelectedCallList(parseInt(obj.id));
        props.vm.setSelectedRow(value);
        props.vm.loadAllExistingCallList();
    };
    const onPageHandle = (e: { first: number; rows: number }) => {
        props.vm.onPage(e.first, e.rows);
    };
    
    return (
        <>
            <div className="p-grid">
           
                <div className="p-col-12 sm-order-6 ">
                    {props.vm.isException && (
                        <IMASMessagesHandler response={exception()} > </IMASMessagesHandler>
                    )}
                    {props.vm.isWarning && (
                        <IMASMessagesHandler response={warning()}> </IMASMessagesHandler>
                    )}
             
                    <DataTable
                        className="header-align-left datatable-width-auto app-table"
                        id="docTable"
                        onSort={onColumnSort}
                        autoLayout={true}
                        paginator={true}
                        paginatorTemplate={
                            props.vm.existingCallList.values.length > 0
                                ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                : ""
                        }
                        rowsPerPageOptions={
                            props.vm.existingCallList.values.length > 0 ? [5, 10, 25] : []
                        }
                        resizableColumns={true}
                        columnResizeMode="expand"
                        responsive={true}
                        value={props.vm.existingCallList.values}
                        rows={props.vm.rows}
                        lazy={true}
                        totalRecords={props.vm.totalRecords}
                        currentPageReportTemplate={
                            props.vm.existingCallList.values.length > 0
                                ? "Showing {first} to {last} of {totalRecords} records"
                                : ""
                        }
                        first={props.vm.first}
                        onPage={onPageHandle}
                        onSelectionChange={(e) => onSelectionChange(e.value, e)}
                        emptyMessage={Constants.NoRecordFounds}
                        selectionMode="single"
                        sortField={props.vm.sortColumn} sortOrder={(props.vm.sortOrder ? 0 : -1)}
                    >
                        <Column field="callListName" header="Call List" sortField="callListName" sortFunction={onColumnSort} sortable={true} style={{ width: "30%" }} />
                        <Column body={createdDateTemplate} field="createdDate" header="Created Date" sortField="createdDate" sortFunction={onColumnSort} sortable={true} className="column-center"
                            style={{ width: "12%", overflow: "visible" }} />
                        <Column field="called" header="Called" className="column-center" style={{ width: "8%", overflow: "visible" }} sortField="called" sortFunction={onColumnSort} sortable={true}/>
                        <Column field="remaning" header="Remaining" className="column-center" style={{ width: "10%", overflow: "visible" }} sortField="remaning" sortFunction={onColumnSort} sortable={true}/>
                        <Column field="startingTotal" header="Starting Total" className="column-center" style={{ width: "11%", overflow: "visible" }} sortField="startingTotal" sortFunction={onColumnSort} sortable={true}/>
                        <Column body={actionTemplate} header="" style={{ overflow: "visible", textAlign: "center", width: "16%" }} />
                    </DataTable>

                    <IMASConfirmationAlert
                        value="Are you sure you want to remove this Call List?"
                        ok={() => props.vm.onRemoveClick(props.vm.selectedCallListRow)}
                        cancel={() => props.vm.setRemoveConfirmation(false)}
                        isVisible={props.vm.isRemoveConfirm}
                        onHide={() => props.vm.setRemoveConfirmation(false)}
                    />
                </div>
            </div>
        </>
    );
});

export default withAITracking(reactPlugin, ExistingCallListView, "ExistingCallList");
