import React from "react";
import { observer } from "mobx-react";
import "primeflex/primeflex.css";
import { GoalsViewModel } from "./GoalsViewModel";
import Utils from "../../infrastructure/Utils";

export const GoalsView: React.FC<{
  vm: GoalsViewModel;
}> = observer(() => {
  let env = Utils.getAppHostKey();
  let goalsUrl =
    `https://bonus-${env}.integrityconnect.com/app?tenantName=${Utils.getBUName()}`
      .replace("-stage", "-uat")
      .replace("-prod", "");

  return (
    <div className="goals-container">
      <iframe
        src={`${goalsUrl}`}
        id="goals-iframe"
        frameBorder="0"
        style={{ height: "calc(100vh - 70px)", width: "100%" }}
      />
    </div>
  );
});