import { action, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { NoteModel } from "../../../../services/ContractService";
import Collection from "../../../../infrastructure/CollectionHelper";
import { routes } from "../../../../router";
import AccountingStore from "../../../../store/AccountingStore";

export class PagedList<T> {
  public recordCount: number = 0;
  public data: T[] = [];
}
export class CarrierCMLevelsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable itemList = new Collection<NoteModel>();
  @observable cmSearchInputValue: string = "";
  @observable rowId: number = 0;

  @observable selectedRowData: any;
  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 50;
  @observable pageIndex: number = 0;
  @observable selectedStatus: string = "-1";
  @observable statusTypeList = [
    { label: "All", value: "-1" },
    { label: "Active", value: "0" },
    { label: "History", value: "1" },
  ];

  @observable sortColumn: string = "id";
  @observable isSortAscending: boolean = true;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 50;
  @observable carrierId: number = 0;

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;

  @observable sort: boolean = false;

  @observable isCmWebsitesAdd: boolean = true;
  @observable isCmWebsitesSave: boolean = false;
  @observable expandedLevelDetails: any = [];
  @observable selectedLevel: any;

  @action Load = async (id: number) => {
    this.carrierId = id;
    this.sortColumn = "id";
    this.sort = false;
    this.pageSize = 50;
    this.rows = 50;
    this.cmSearchInputValue = "";
    this.selectedStatus = "-1";
    this.expandedLevelDetails = [];
    this.selectedLevel = undefined;
    this.loadLevels();
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;
    this.loadLevels();
  }
  @action refresh = async () => {
    this.cmSearchInputValue = "";
    this.selectedStatus = "-1";
    this.loadLevels();
  };

  @action loadLevels = async () => {
    try {
      this.isLoading = true;
      this.itemList.values = [];

      const response = await AccountingStore.getAllPayoutLevelsByCarrierId(
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sort,
        this.carrierId,
        this.selectedStatus == "-1"
          ? undefined
          : this.selectedStatus == "0"
          ? false
          : true,
        "" + this.cmSearchInputValue
      );
      if (response && response.data && response.data.length > 0) {
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.itemList.values = response.data;
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action viewDocument = async (docId: number) => {
    window.open(
      window.location.origin + "/documentviewer/" + docId + "/" + 0,
      "_new"
    );
  };

  @action setStatusDropdown = (value: string) => {
    this.selectedStatus = value;
    this.loadLevels();
  };

  @action redirectLevel = () => {
    routes.payoutLevels.push();
  };

  @action openDocument = () => {
    routes.payoutLevels.push();
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.isSortAscending = this.defaultIsSortAscending;
  };

  @action onFieldChange = (e: any) => {
    e.persist();
    this.cmSearchInputValue = e.currentTarget.value;
    if (
      this.cmSearchInputValue.length >= 3 ||
      this.cmSearchInputValue.length === 0
    ) {
      this.loadLevels();
    }
  };

  readonly currentPageReportTemplate: string =
    "Showing {first} - {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }

  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [50, 100, 150, 200, 250] : [];
  }
}
