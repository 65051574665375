import { action } from "mobx";
import {
  CreateMarketerLeadModel,
  UpdateMarkerLeadModel,
  CreateSalesOpsLeadModel,
  UpdateSalesOpsLeadModel,
  FileType,
  UnMatchedLeadModel,
  ApproveEmailPhoneModel,
  CreateMarketerLeadNotesModel,
  AgentLeadDisabledCarrierModel,
  Client as LeadServiceClient
} from "../services/LeadService";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";

class LeadStore {
    @action
    public getDropdownValues = async (isContracted: boolean, isMarketer: boolean) => {
      return await this.getClient().getAllMarketerLeadsLookup(
        this.getApiVersion(),
          isContracted,
          isMarketer,
          UserContext.userId
      );

  };
  @action
  public getUnmatchedValues = async () => {
    return await this.getClient().getAllSalesOpsUnmatchedLookup(
      this.getApiVersion(),
      UserContext.userId
    );
  };

  @action getMarketerleadsAll = async (
    myreferral: boolean,
    fileType: FileType
  ) => {
    return await this.getClient().getAllMarketerLeadsExport(
      this.getApiVersion(),
      myreferral,
      fileType,
      UserContext.userId
    );
  };

  @action
  public getSalesopsDropdownValues = async () => {
    return await this.getClient().getAllSalesOpsLeadLookup(
      this.getApiVersion(),
      UserContext.userId
    );
  };
  @action
  public getLeadPhaseList = async () => {
    return await this.getProducerClient().getAgentLeadPhaseLookup(
      0,
      this.getApiVersion(),
      UserContext.userId
    );
  };
  @action
  public getLeadPhaseListByLeadPhaseId = async (leadPhaseId: number) => {
    return await this.getClient().getLeadPhase(
      leadPhaseId,
      this.getApiVersion(),
      UserContext.userId
    );
  };

  @action
  public getLeadPhaseDetailList = async (leadPhaseId: number) => {
    return await this.getProducerClient().getAgentLeadPhaseDetailsLookup(
      leadPhaseId,
      this.getApiVersion(),
      UserContext.userId
    );
  };

  @action
  public addAgentLead = async (dto: CreateMarketerLeadModel) => {
    return await this.getClient().createMarketerLead(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action
  public addSalesOpsAgentLead = async (dto: CreateSalesOpsLeadModel) => {
    return await this.getClient().createSalesOpsLead(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action
  public editSaleOpsLead = async (dto: UpdateSalesOpsLeadModel) => {
    return await this.getClient().updateSalesOpsLead(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action
  public editSaleOpsUnmatchedLead = async (
    impersonated_User?: any | undefined,
    body?: UnMatchedLeadModel[] | null | undefined
  ) => {
    return await this.getClient().updateSalesOpsUnmatchedLead(
      this.getApiVersion(),
      impersonated_User,
      undefined,
      body
    );
  };

  @action
  public editMarketerLead = async (dto: UpdateMarkerLeadModel) => {
    return await this.getClient().updateMarketerLead(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action
  public getMarketerLead = async (id: number, impersonated_userId: any) => {
    return await this.getClient().getMarketerLeadById(
      id,
      this.getApiVersion(),
      impersonated_userId
    );
  };
  @action public getSubAssigneeLookup = async (
    id: number,
    impersonated_userId: any
  ) => {
    return await this.getClient().getSubAssigneeLookup(
      id,
      this.getApiVersion(),
      impersonated_userId
    );
  };
  @action
  public getSaleOpsLead = async (id: number) => {
    return await this.getClient().getSalesOpsLeadById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  getClient = (): LeadServiceClient => {
    return ServiceClient.getClient(ServiceType.Lead);
  };
  getProducerClient = () => {
    return ServiceClient.getClient(ServiceType.Producer);
  };
  @action getMonthSummary = async (
    marketerUserId?: number | null | undefined,
    downline?: number | null | undefined,
    agentId?: number | null | undefined,
    impersonated_User?: any | undefined
  ) => {
    return await this.getClient().getLeadsMonthSummary(
      this.getApiVersion(),
      FileType._1,
      marketerUserId,
      downline,
      agentId,
      impersonated_User
    );
  };

  @action getMarketerLeads = async (
    isContracted?: boolean | undefined,
    agentLeadId?: number | null | undefined,
    agentId?: number | null | undefined,
    agentLastName?: string | null | undefined,
    assigneeId?: number | null | undefined,
    subAssigneeId?: number | null | undefined,
    agentLeadSourceId?: number | null | undefined,
    productLineId?: number | null | undefined,
    carrierId?: number | null | undefined,
    startDate?: Date | null | undefined,
    endDate?: Date | null | undefined,
    leadPhaseId?: number | null | undefined,
    leadPhaseDetailId?: number | null | undefined,
    status?: number | null | undefined,
    stage?: number | null | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    if (UserContext.userId && UserContext.userId > 0) {
      var result = await this.getClient().getAllMarketerLeads(
        this.getApiVersion(),
        isContracted,
        agentLeadId,
        agentId,
        agentLastName,
        assigneeId,
        subAssigneeId,
        agentLeadSourceId,
        productLineId,
        carrierId,
        startDate,
        endDate,
        leadPhaseId,
        leadPhaseDetailId,
        status,
        stage,
        pageIndex,
        pageSize,
        sortColumn,
        sortAscending,
        fullTextFilter,
        UserContext.userId
      );
      return result;
    }
    return undefined;
  };

  @action getSalesOpsLeads = async (
    agentLeadId?: number | null | undefined,
    agentId?: number | null | undefined,
    agentLastName?: string | null | undefined,
    assigneeId?: number | null | undefined,
    subAssigneeId?: number | null | undefined,
    agentLeadSourceId?: number | null | undefined,
    productLineId?: number | null | undefined,
    carrierId?: number | null | undefined,
    startDate?: Date | null | undefined,
    endDate?: Date | null | undefined,
    leadPhaseId?: number | null | undefined,
    leadPhaseDetailId?: number | null | undefined,
    status?: number | null | undefined,
    stage?: number | null | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = await this.getClient().getAllSalesOpsLeads(
      this.getApiVersion(),
      agentLeadId,
      agentId,
      agentLastName,
      assigneeId,
      subAssigneeId,
      agentLeadSourceId,
      productLineId,
      carrierId,
      startDate,
      endDate,
      leadPhaseId,
      leadPhaseDetailId,
      status,
      stage,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };
  @action generateActivatedReport = async (
    fileType?: FileType | undefined,
    marketerUserId?: number | null | undefined,
    downline?: number | null | undefined,
    agentId?: number | null | undefined,
    impersonated_User?: any | undefined
  ) => {
    var result = await this.getClient().getActivatedLeadsReport(
      this.getApiVersion(),
      fileType,
      marketerUserId,
      downline,
      agentId,
      impersonated_User
    );
    return result;
  };
  @action generateContractedReport = async (
    fileType?: FileType | undefined,
    marketerUserId?: number | null | undefined,
    downline?: number | null | undefined,
    agentId?: number | null | undefined,
    impersonated_User?: any | undefined
  ) => {
    var result = await this.getClient().getContractedLeadsReport(
      this.getApiVersion(),
      fileType,
      marketerUserId,
      downline,
      agentId,
      impersonated_User
    );
    return result;
  };
  @action getUnmatchedLeads = async (
    agentLeadId?: number | null | undefined,
    agentLeadSourceId?: number | null | undefined,
    startDate?: Date | null | undefined,
    endDate?: Date | null | undefined,
    leadPhaseId?: number | null | undefined,
    leadPhaseDetailId?: number | null | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = await this.getClient().getAllSalesOpsUnmatchedLeads(
      this.getApiVersion(),
      agentLeadId,
      startDate,
      endDate,
      leadPhaseId,
      leadPhaseDetailId,
      agentLeadSourceId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };

  getApiVersion = () => {
    return "1";
  };

  @action reportLeadSource = async () => {
    var result = await this.getClient().getReportLeadSourceLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getLeadDetail = async (leadId: number) => {
    var result = await this.getClient().getMarketerLeadDetailsById(
      leadId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getUnmatchedLeadDetail = async (leadId: number) => {
    return await this.getClient().getUnmatchedLeadDetailById(
      leadId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getLeadNotes = async (agentId: number) => {
    var result = await this.getClient().getMarketerLeadNotesById(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action approvePhoneEmail = async (body: ApproveEmailPhoneModel) => {
    var result = await this.getClient().approvePhoneEmail(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action addNote = async (dto: CreateMarketerLeadNotesModel) => {
    var result = await this.getClient().createMarketerLeadNotes(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action marketerLeadPayoutLevel = async (carrierId: number) => {
    var result = await this.getClient().getMarketerLeadPayoutLevelById(
      carrierId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action salesOpsLeadExport = async (fileType: FileType) => {
    let result = await this.getClient().getAllSalesOpsLeadsExport(
      this.getApiVersion(),
      fileType,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllAgentLeadDisabledCarriers = async (
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null,
    sortOrder?: boolean | undefined
    // fullTextFilter?: string | null | undefined,
    // impersonated_User?: any | undefined
  ) => {
    let result = await this.getClient().getAllAgentLeadDisabledCarriers(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action addAgentLeadDisabledCarrier = async (
    item: AgentLeadDisabledCarrierModel
  ) => {
    let result = await this.getClient().addAgentLeadDisabledCarrier(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
    return result;
  };
  @action deleteAgentLeadDisabledCarrier = async (id: number) => {
    let result = await this.getClient().deleteAgentLeadDisabledCarrier(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action isCarrierDisabledForAgentLead = async (id: number ) => {
    let result = await this.getClient().isCarrierDisabledForAgentLead(
      this.getApiVersion(),
      id,
      UserContext.getUserId()
    );
    return result;
    };  
    @action getLeadPhaseDetails = async (id: number) => {
        return await this.getClient().getLeadPhaseDetails(
            id,
            this.getApiVersion(),
            UserContext.getUserId()
        );
    };
}

export default new LeadStore();
