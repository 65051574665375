import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { action, observable } from "mobx";
import { RunCommissionsListModel } from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";
import UserContext from "../../../../infrastructure/UserContext";
import { googleTagManager } from "../../../../infrastructure/tracking";

export class RunCommissionsViewModel extends BaseListViewModel<RunCommissionsListModel> {

    @observable divisionList: any[] = [];
    @observable divisionId: string = "0";
    @observable payDate: Date | undefined = undefined;
    @observable selectedCarriers: any;

    @observable isNoPayDateDialogVisible: boolean = false;
    @observable isNoCarriersDialogVisible: boolean = false;
    @observable queued: boolean = false;
    @observable response: any;

    constructor() {
        super("Run Commissions", "", routes.processCommissions, routes.processCommissions,"","recordCount",true);
        this.pageSize = 1000;
        this.selectedCarriers = [];
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        this.selectedCarriers = [];
        return await CommissionStore.getRunCommissions(pageIndex, pageSize, sortColumn, sortOrder);
    }

    @action onQueueSelectedCarriers = async () => {        
        googleTagManager.trackAction("commissions_batch_processed", {
            feature: "ProcessCommissions",
            user_id: UserContext.userId,
        });
        
        if(this.payDate === undefined) {
            this.setNoPayDateDialogVisible(true);
            return;
        }

        if(!this.selectedCarriers || this.selectedCarriers.length <= 0) {
            this.setNoCarriersDialogVisible(true);
            return;
        }

        var items = new Array<number>();
        this.selectedCarriers.forEach((element: any) => {
            items.push(element.carrierId);
        });

        await CommissionStore.scheduleRunCommission(items, this.sanitizedDate(this.payDate));

        this.selectedCarriers = [];
        this.queued = true
         let response = {
            status: 200,
            title: "Queued!",
            errors: { "": [] },
          };
        this.response =  JSON.stringify(response);
        
        setTimeout(() => {
            this.queued = false;
            this.response = {};
        }, 2500);
        await this.loadItems();
    }

    @action onPrintStatements = async () => {
        routes.printCommissions.replace();
    }

    @action onUnmatchSelectedCarriers = async () => {
        if(!this.selectedCarriers || this.selectedCarriers.length <= 0) {
            this.setNoCarriersDialogVisible(true);
            return;
        }

        var items = new Array<number>();
        this.selectedCarriers.forEach((element: any) => {
            items.push(element.carrierId);
        });

        await CommissionStore.unmatchRunCommission(parseInt(this.divisionId), items);

        this.selectedCarriers = [];
        await this.loadItems();
    }

    @action setNoPayDateDialogVisible = (value: boolean) => {
        this.isNoPayDateDialogVisible = value;
    }
    @action setNoCarriersDialogVisible = (value: boolean) => {
        this.isNoCarriersDialogVisible = value;
    }

    private sanitizedDate(value: Date | undefined) : Date {
        var sanitized = value || new Date();
        sanitized.setHours(0, 0, 0, 0);
        return sanitized;
    }

    resetFiltersToDefault() {   
        this.pageSize = 1000;
        this.payDate = undefined;
      }


}