import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { UpdateCreditCardChargeModel } from "../../../../services/AccountingService";
import AccountStore from "../../../../store/AccountingStore";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";

export class CreditCardChargeRequestAddEditViewModel extends BaseAddEditViewModel {

    @observable depositDate: Date | undefined = undefined;
    @observable requestedDate: Date | undefined = undefined;
    @observable agentName: string = "";
    @observable depositAmount: string | undefined = undefined;
    @observable licensingFee: string = "0";
    @observable selectedStatusId: number = 0;
    @observable transactionId: string = "";
    @observable description: string = '';
    @observable isAmountDigits: boolean = false;
    @observable chargeStatusList = [
        { label: "Open", value: "1" },
        { label: "Closed - Cancel", value: "2" },
        { label: "Closed - Card Declined", value: "3" },
        { label: "Closed - Success", value: "4" },
    ];

    @action setDepositAmount = (value: string) => {
        this.depositAmount = value;
    }
    @action setChargeStatus = (value: number) => {
        this.selectedStatusId = value;
    }
    @action setTransactionId = (value: string) => {
        this.transactionId = value;
    }
    @action formatNum = (val: string): number => {
        let num;
        if (val) {
            val = val.toString().replace("$", "").split(",").join("");
            num = !isNaN(Number(val)) ? Number(val.split(",").join("")) : 0;
        } else num = 0;

        return num;
    };
    constructor()
    {
        super("Credit Card Charge Request", routes.listCreditCardChargeRequest, new CreditCardChargeRequestValidator());
    }

    protected toServiceModel() {
        var dto: UpdateCreditCardChargeModel = {
            id: this.selectedId,
            amount: this.depositAmount ? this.formatNum(this.depositAmount) : 0,
            creditCardChargeStatusId: this.selectedStatusId,
            transactionId: this.transactionId
        };
        
        return dto;
    }


    protected async loadItem(id: number): Promise<void> {
        this.isAmountDigits = false;
        if(id === 0) {
            this.resetModel();
        }
        else {
            var item = await AccountStore.getcreditCardChargeDetailDetail(id);
            if(item) {
                this.selectedId = item.id || 0;
                this.selectedStatusId = item.creditCardChargeStatusId || 1;
                this.depositDate = item.dateCreated;
                this.requestedDate = item.dateCreated;
                this.depositAmount = "" + item.amount || "0";
                this.agentName = item.agentName || "";
                this.description = item.description || "";
                this.transactionId = item.transactionId || "";
                this.licensingFee = (item.licensingFee ? "Yes" : "No");
            }
        }
    }

    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await AccountStore.editCreditCardCharge(item);
    }



    @action formatDate(date: Date | undefined) {
        return Utils.getDateInFormat(date);
    }
        
}

class CreditCardChargeRequestValidator extends AbstractValidator<CreditCardChargeRequestAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString((input) => input.depositAmount)
            .isNotEmpty()
            .withFailureMessage("Deposit Amount is required");
        this.validateIfString((input) => input.depositAmount)
            .hasMaxLength(12)
            .whenNotEmpty()
            .withFailureMessage("Deposit Amount must be less than or equal to 9 digits")
            .when((input) => input.depositAmount != undefined ? !input.depositAmount.includes(".") : false)
        this.validateIfString((input) => input.depositAmount)
            .hasMaxLength(15)
            .whenNotEmpty()
            .withFailureMessage("Deposit Amount must be less than or equal to 9 digits")
            .when((input) => input.depositAmount != undefined ? input.depositAmount.includes(".") : false)  
        this.validateIfNumber((input) => input.selectedStatusId)
            .isNotEmpty()
            .isGreaterThanOrEqual(1)
            .withFailureMessage("Charge status is required");

        this.validateIfString((input) => input.description)
            .hasMaxLength(500)
            .matches(/\S+/,"i")
            .whenNotEmpty()
            .withFailureMessage("Comment is more then 500 characters");
    }
}