import { action, observable } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import ContractsStore from "../../../store/ContractsStore";
import Collection from "../../../infrastructure/CollectionHelper";
import { LicenseStateListModel } from "../../../services/ContractService";
import { Permission } from "../../../infrastructure/enum/Permission";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import Utils from "../../../infrastructure/Utils";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";

export class ContractStatesViewModel implements ViewModel {
  @observable selectedPaymentPlanId: number = 0;
  @observable carrierName: string = "";
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (contractId: number, agentId: number) => {
    this.contractId = contractId;
    this.agentId = agentId;
    await this.loadLicenseStates();
    await this.loadStatesLookup();
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable contractId: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable isException: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @observable isStateDialog: boolean = false;
  @observable isLicenseStateEditMode: boolean = false;
  @observable isAppointmentEditMode: boolean = false;
  // LicenseState
  @observable licenseStateId: number = 0;
  @observable licenseId: number = 0;
  @observable sortColumnLicenseState: string = "apptDate";
  @observable sortOrderLicenseState: boolean = false;
  @observable pageIndexLicenseState: number = 0;
  @observable totalRecordsLicenseState: number = 0;
  @observable rowsLicenseState: number = 10;
  @observable firstLicenseState: number = 0;
  @observable statesData: any = [
    { label: "UnitedHealthCare", value: "UHC", abbreviation: "" },
  ];
  @observable LicenseStateList = new Collection<LicenseStateListModel>();

  @action async editStates() {
    this.licenseState = [];
    this.apptDate = undefined;
    this.termDate = undefined;
    this.setApptInstructions("");
    this.isStateDialog = true;
    this.isLicenseStateEditMode = false;
  }
  @observable apptDate: Date | undefined = undefined;
  @observable isValidationErrorVisible: boolean = false;
  @observable isValidationErrorMsg: string = "";
  @action setApptDate(value: Date | undefined) {
    this.apptDate = value;
  }
  @observable termDate: Date | undefined = undefined;
  @action setTermDate(value: Date | undefined) {
    this.termDate = value;
  }
  @action setEditStateDialog(value: boolean) {
    this.isStateDialog = value;
  }
  @observable apptInstructions: string = "";
  @action setApptInstructions(value: string) {
    this.apptInstructions = value;
  }
  @observable apptState: any = [];
  @observable licenseState: any;
  @action setLicenseState(value: any) {
    this.licenseState = value;
  }
  @action setApptState(value: string) {
    this.apptState = value;
  }

  @action loadLicenseStates = async () => {
    var result = await ContractsStore.getAllLicenseStates(
      this.contractId,
      this.pageIndexLicenseState,
      this.rowsLicenseState,
      this.sortColumnLicenseState,
      this.sortOrderLicenseState
    );
    if (result && result.data) {
      var res = result.data;
      this.totalRecordsLicenseState = result.recordCount || 0;
      this.LicenseStateList.values = res;
    }
  };
  @action async setSortColumnLicenseState(sortField: any) {
    this.sortColumnLicenseState = sortField;
    await this.loadLicenseStates();
  }
  @action onPageLicenseState(firstIndex: number, rows: number) {
    this.rowsLicenseState = rows;
    this.firstLicenseState = firstIndex;
    this.pageIndexLicenseState = firstIndex / this.rowsLicenseState;
    this.loadLicenseStates();
  }
  @action loadStatesLookup = async () => {
    var result = await ContractsStore.reportAgentHomeState();
    if (result != null) {
      this.statesData = [];
      if (result) {
        for (let item of result) {
          this.statesData.push({
            label: "" + item.stateName,
            value: "" + item.stateId,
            abbreviation: "" + item.abbreviation,
            isHeader: item.isHeader,
          });
        }
      }
    }
  };
  @action getLicenseStateActionItems = () => {
    let deletActionItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        setTimeout(() => {
          this.setDeleteLicenseStateConfirm(true);
        }, 300);
      },
    };
    let appointmentActionItems: MenuItem[] = [];
    if (Utils.hasUserPermission(Permission.DeleteStates)) {
      appointmentActionItems.push(deletActionItem);
    }
    return appointmentActionItems;
  };

  @action onEditLicenseStateClick = async (licenseStateId: number) => {
    this.isValidationErrorVisible = false;
    this.isStateDialog = true;
    this.isLicenseStateEditMode = true;
    this.licenseStateId = licenseStateId;
    var result = await ContractsStore.getLicenseStateById(licenseStateId);
    if (result) {
      this.licenseStateId = result.id ? result.id : 0;
      this.licenseId = result.licenseId ? result.licenseId : 0;
      if (result.stateId !== undefined) {
        this.licenseState = (result.stateId || 0).toString();
      }
      this.apptDate = result.issueDate ? new Date(result.issueDate) : undefined;
      this.termDate = result.expirationDate
        ? new Date(result.expirationDate)
        : undefined;
    }
    this.setEditStateDialog(true);
  };
  @observable isDeleteLicenseState: boolean = false;
  @action deleteLicenseState = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      await ContractsStore.deleteLlicenseState(this.licenseId);
      this.isLoading = false;
      this.isDeleteLicenseState = false;
      setTimeout(async () => {
        await this.loadLicenseStates();
      }, Utils.timeDelay_Delete());
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
      this.isDeleteLicenseState = false;
    }
  };
  @action setDeleteLicenseStateConfirm = (value: boolean) => {
    this.isDeleteLicenseState = value;
  };
  @action getStateById(id: string) {
    let stateObj = this.statesData.find(
      (i: any) => i.value.trim() === id.trim()
    );
    return stateObj ? stateObj.abbreviation : "";
  }
  @action setSortOrderLicenseState() {
    this.sortOrderLicenseState = !this.sortOrderLicenseState;
    this.pageIndexLicenseState = 0;
    this.firstLicenseState = 0;
  }
  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }
  @action saveLicenseState = async () => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    let selectedState: any = [];
    if (!this.isLicenseStateEditMode) {
      this.licenseState.forEach((element: any) => {
        selectedState.push(parseInt(element));
      });
    }
    try {
      var model: LicenseStateModel = {
        id: this.licenseStateId,
        licenseId: this.contractId,
        stateIds: !this.isLicenseStateEditMode ? selectedState : undefined,
        stateId: this.isLicenseStateEditMode
          ? parseInt(this.licenseState)
          : undefined,
        expirationDate: this.termDate,
        issueDate: this.apptDate,
      };
      let isValidDateRange = true;
      this.isValidationErrorMsg = "";
      this.isValidationErrorVisible = false;
      if (this.apptDate !== undefined && this.termDate !== undefined) {
        if (this.apptDate > this.termDate) {
          this.isValidationErrorMsg =
            "Termination Date must be greater or equal to Appointment Date";
          this.isValidationErrorVisible = true;
          isValidDateRange = false;
        }
      }
      if (
        this.LicenseStateList &&
        this.LicenseStateList.values.length > 0 &&
        selectedState &&
        selectedState.length > 0
      ) {
        let states = this.LicenseStateList.values.filter((item: any) => {
          return selectedState.indexOf(item.stateId) != -1;
        });
        if (states.length > 0) {
          let slist = states.map((item: any) => {
            return item.state;
          });
          this.isValidationErrorMsg =
            "State(s) " + slist.join(", ") + " already added";
          this.isValidationErrorVisible = true;
          return false;
        }
      }

      if (isValidDateRange) {
        let result: any;
        let isValid = true;
        if (!(await this.validateLicenseState())) {
          if (this.isLicenseStateEditMode) {
            result = await ContractsStore.editLicenseState(model);
          } else {
            result = await ContractsStore.addLicenseState(model);
          }
        } else {
          isValid = false;
          this.isLoading = false;
        }
        if (result !== null && result !== undefined) {
          isValid = true;
        }

        if (isValid) {
          this.setEditStateDialog(false);
          this.sortOrderLicenseState = false;
          this.sortColumnLicenseState = "apptDate";
          // this.isLicenseStateEditMode = false;
          this.isValidationErrorMsg = "";
          this.isValidationErrorVisible = false;
          this.loadLicenseStates();
        }
      }
    } catch (e) {
      this.isLoading = false;
      // retrun the excetion w.r.t. environment
      this.isMessgeVisible = true;
      this.setEditStateDialog(false);
      this.loadLicenseStates();
    }
  };
  public LicenseStateErrorModel = new ErrorModel(new LicenseStateValidator());

  @action
  public validateLicenseState = async () => {
    return this.LicenseStateErrorModel.Validate(this);
  };
}

class LicenseStateValidator extends AbstractValidator<ContractStatesViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.licenseState)
      .isNotEmpty()
      .withFailureMessage("State is required");
  }
}
