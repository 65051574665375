import { action, computed, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import { routes } from "../../../../router";
import Collection from "../../../../infrastructure/CollectionHelper";
import { CarrierManagement } from "../../../../infrastructure/enum/Common";
import RouteList from "../../../../infrastructure/RouteList";
import ContractsStore from "../../../../store/ContractsStore";
import AccountingStore from "../../../../store/AccountingStore";
import Utils from "../../../../infrastructure/Utils";

export class CarrierCMSummaryViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => undefined;
  @observable selectedID: number = 0;
  @observable CMNpnCheckedRadio: boolean = false;
  @observable CMCompanycheckedRadio: boolean = false;
  @observable CMRecruiterCheckedRadio: boolean = false;
  @observable CMPartyCheckedRadio: boolean = false;
  @observable CMAgencycheckedRadio: boolean = false;
  @observable CMcallCentercheckedRadio: boolean = false;
  @observable isAgencyChecked: boolean = false;
  @observable isOverlapChecked: boolean = false;
  @observable isEligibilityChecked: boolean = false;
  @observable isCompanyChecked: boolean = false;
  @observable isNpnChecked: boolean = false;
  @observable isPartyIdChecked: boolean = false;
  @observable isRecruiterChecked: boolean = false;
  @observable isCallCenterChecked: boolean = false;
  @observable selectedPaymentValue: any | undefined;
  @observable selectedPaymentValuePreview: string | undefined;
  @observable groupTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable writingNumberDefaults: any | [];
  @observable PaymentList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isRigidContractingChecked: boolean = false;

  @action Load = async (id: number) => {
    this.selectedID = id;
    window.scrollTo(0, 0);
  };
  @action handleToggle = (e: any) => {
    e.persist();
    (<any>this)[e.target.id] = e.target.checked;
    const i = this.writingNumberDefaults.findIndex(
      (item: any) => item.typeName === e.target.name
    );
    const optionsArr = this.writingNumberDefaults.map(
      (prevState: any, si: any) =>
        si === i
          ? { ...prevState, isWritableDefault: !prevState.isWritableDefault }
          : prevState
    );
    this.writingNumberDefaults = optionsArr;
  };

  @action handleOverlapCheckBoxChange = (e: any) => {
    (<any>this)[e.target.id] = !e.target.value;
  };
  @action handleCheckBoxChange = (e: any) => {
    const i = this.writingNumberDefaults.findIndex(
      (item: any) => item.typeName === e.target.name
    );
    const optionsArr = this.writingNumberDefaults.map(
      (prevState: any, si: any) =>
        si === i
          ? { ...prevState, isWritableSelected: !prevState.isWritableSelected }
          : prevState
    );
    this.writingNumberDefaults = optionsArr;
  };
  @action onFieldChange = (e: any) => {
    if (e.target) {
      (<any>this)[e.target.id] = e.target.value;
    } else {
      (<any>this)[e.currentTarget.id] = e.currentTarget.value;
    }
  };
}
