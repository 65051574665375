import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import { routes } from "../../router";
import LeadStore from "../../store/LeadStore";
import {
  MarketerLeadSearchReponse,
  MarkerterLeadListModel,
  MarketerLeadsMonthSummaryResponse,
  MonthCount,
  FileType,
} from "../../services/LeadService";
import { AgentListModel } from "../../services/ProducerSearchService";
import { ReportFile } from "../../services/AccountingService";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { LeadTabs, MainLeadTabs } from "../../infrastructure/enum/Leads";
import { Permission } from "../../infrastructure/enum/Permission";

export class MarketerLead {
  @observable
  public leadID: string = "";
  @observable
  public agentId: string = "";

  @observable
  public agentName: string = "";
  @observable
  public source: string = "";

  @observable
  public phase: string = "";
  @observable
  public phaseDetail: string = "";
  @observable
  public assignee: string = "";
  @observable
  public subAssignee: string = "";
  @observable
  public carrier: string = "";

  @observable
  public dateCreated: string = "";
  @observable
  public dateFollowUp: string = "";
  //#endregion
}
export class LeadListViewModel implements ViewModel {
  @observable companyList = [{ label: "ALL", value: "0" }];
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();
  @observable leadSourceList = [{ label: "ALL", value: "0" }];
  @observable assigneeList = [{ label: "ALL", value: "0" }];
  @observable subAssigneeList = [{ label: "ALL", value: "0" }];
  @observable tempSubAssigneeList = [{ label: "ALL", value: "0" }];
  @observable agentList = [{ label: "Select Agent", value: "0" }];
  @observable productLineList = [{ label: "ALL", value: "0" }];

  @observable phaseList = [{ label: "ALL", value: "0" }];
  @observable detailList = [{ label: "ALL", value: "0" }];
  @observable CarrierBankDepositID: number = 0;
  @observable selectedCompany: string = "0";
  @observable CselectedCompany: string = "0";
  @observable NCselectedCompany: string = "0";
  @observable selectedLeadSource: string = "0";
  @observable CselectedLeadSource: string = "0";
  @observable NCselectedLeadSource: string = "0";
  @observable selectedAssignee: string = "0";
  @observable CselectedAssignee: string = "0";
  @observable NCselectedAssignee: string = "0";
  @observable selectedSubAssignee: string = "0";
  @observable CselectedSubAssignee: string = "0";
  @observable NCselectedSubAssignee: string = "0";
  @observable selectedAgent: string = "0";
  @observable selectedProductLine: string = "0";
  @observable MarketerLeadSearchReponse: MarketerLeadSearchReponse | undefined =
    undefined;
  @observable MarkerterLeadListModel: MarkerterLeadListModel | undefined =
    undefined;
  @observable selectedLeadPhaseDetail: string = "0";
  @observable CselectedLeadPhaseDetail: string = "0";
  @observable NCselectedLeadPhaseDetail: string = "0";
  @observable selectedLeadPhase: string = "0";
  @observable CselectedLeadPhase: string = "3";
  @observable NCselectedLeadPhase: string = "0";
  @observable MarkerterLeadLists = new Collection<MarkerterLeadListModel>();
  @observable
  MarketerLeadsMonthSummaryResponse: MarketerLeadsMonthSummaryResponse | null =
    null;
  @observable ActivatedMonthCountSummary = new Collection<MonthCount>();
  @observable ContractedMonthCountSummary = new Collection<MonthCount>();
  @observable MyActivatedMonthCountSummary = new Collection<MonthCount>();
  @observable MyContractedMonthCountSummary = new Collection<MonthCount>();
  @observable leadId: number = 0;
  @observable hasPermission: boolean = true;
  @observable hasSalesOpsPermission: boolean = true;
  @action downloadMyContractedReport = async () => {
    let res = await LeadStore.generateContractedReport(
      1,
      UserContext.userId,
      0,
      0,
      UserContext.userId
    );
    res.fileName = "MyContracted.xlsx";
    this.downloadReport(res, "Agent_Debt");
  };
  @action downloadMyActivatedReport = async () => {
    let res = await LeadStore.generateActivatedReport(
      1,
      UserContext.userId,
      0,
      0,
      UserContext.userId
    );
    res.fileName = "MyActivated.xlsx";
    this.downloadReport(res, "Agent_Debt");
  };
  @action downloadTeamContractedReport = async () => {
    let res = await LeadStore.generateContractedReport(
      1,
      UserContext.userId,
      1,
      0,
      UserContext.userId
    );
    res.fileName = "TeamContracted.xlsx";
    this.downloadReport(res, "Agent_Debt");
  };
  @action downloadTeamActivatedReport = async () => {
    let res = await LeadStore.generateActivatedReport(
      1,
      UserContext.userId,
      1,
      0,
      UserContext.userId
    );
    res.fileName = "TeamActivated.xlsx";
    this.downloadReport(res, "Agent_Debt");
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @observable selectedKey: string | undefined = "";
  @observable selectedPanel: string = LeadTabs.NonContracted;
  @observable isCollapsed: boolean = true;
  @observable show: boolean = true;
  @observable showLine: boolean = true;
  @observable sortColumn: string = "dateCreated";
  @observable sortOrder: boolean = false;
  @observable companies = [{ label: "All", value: "0" }];
  @observable
  dataToExport: ReportFile = {};
  @observable
  data: string = "";
  @observable endDate: Date | undefined = undefined;
  @observable CendDate: Date | undefined = undefined;
  @observable NCendDate: Date | undefined = undefined;
  @observable startDate: Date | undefined = undefined;
  @observable CstartDate: Date | undefined = undefined;
  @observable NCstartDate: Date | undefined = undefined;
  @observable startViewDate: Date | undefined = undefined;
  @observable endViewDate: Date | undefined = undefined;

  @action updateLead = async (id: number) => {
    routes.editAgentLead.push({ AgentLeadId: id });
  };
  @action setSelectedLead = (leadId: number) => {
    this.leadId = leadId;
  };
  @action viewNonContractedDetail = async (leadId: number) => {
    routes.detailNonContarctedLeads.push({ AgentLeadId: leadId });
  };
  @action viewContractedDetail = async (leadId: number) => {
    routes.detailContarctedLeads.push({ AgentLeadId: leadId });
  };
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action exportReferrals = async (myreferral: boolean, fileType: FileType) => {
    var res = await LeadStore.getMarketerleadsAll(myreferral, fileType);
    if (res != null) {
      setTimeout(() => {
        var link = document.createElement("a");
        if (fileType === 0) {
          link.download = res.fileName ? res.fileName : "ReferralReport.pdf";
        } else {
          link.download = "ReferralReport.xlsx";
        }

        link.href = "data:" + res.contentType + ";base64," + res.data;
        link.textContent = "Download Report";
        link.click();
        document.body.appendChild(link);
      }, 0);
    }
  };

  sortTypeName = (a: any, b: any) => {
    let comparison = 0;
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }

    return comparison;
  };

  @action loadLeadPhaseDetail = async () => {
    var leadPhaseDetailResponse = await LeadStore.getLeadPhaseDetailList(
      parseInt(this.selectedLeadPhase)
    );
    if (this.detailList.length > 1) {
      this.detailList = this.detailList.slice(0, 1);
    }
    for (let item of leadPhaseDetailResponse) {
      this.detailList.push({ label: "" + item.text, value: "" + item.value });
    }
  };

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList = []; 
    this.showInactiveCarriers ? (this.companyList = this.carriersInitial.values) :
    this.companyList = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action loadDropdowns = async () => {
    this.phaseList = [];
    if (this.selectedPanel === LeadTabs.Contracted) {
      this.phaseList.push(
        { label: "Contracted", value: "3" },
        { label: "Closed/Activated", value: "5" },
        { label: "Closed/Not Activated", value: "9" }
      );
      // this.selectedLeadPhase = "3"
    } else {
      this.phaseList.push(
        { label: "ALL", value: "0" },
        { label: "Assigned", value: "1" },
        { label: "ContractingSent", value: "4" },
        { label: "Closed/Not Contracted", value: "8" }
      );
    }
    try {
      var response = await LeadStore.getDropdownValues(true, true);

      if (response && response.carriers && this.companyList.length === 1) {
        var carrierslist = [...response.carriers].sort(this.sortTypeName);
        for (let item of carrierslist) {
          this.companyList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
          this.selectedCompany = "0";
        }
        this.carriersInitial.values = this.companyList;
        this.companyList = Utils.carriersFilter(this.carriersInitial.values);
    
      }

      if (response && response.assignee) {
        this.assigneeList = [{ label: "ALL", value: "0" }];
        var assigneelist = [...response.assignee].sort(this.sortTypeName);
        for (let item of assigneelist) {
          this.assigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
      if (
        response &&
        response.subAssignee &&
        this.tempSubAssigneeList.length === 1
      ) {
        var subassigneelist = [...response.subAssignee].sort(this.sortTypeName);
        for (let item of subassigneelist) {
          this.tempSubAssigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        this.subAssigneeList = this.tempSubAssigneeList;
        this.selectedSubAssignee = "0";
      }
      if (
        response &&
        response.productLine &&
        this.productLineList.length === 1
      ) {
        for (let item of response.productLine) {
          this.productLineList.push({
            label: "" + item.productLineName,
            value: "" + item.id,
          });
        }
      }
      if (response && response.leadSource && this.leadSourceList.length === 1) {
        var leadsourcelist = [...response.leadSource].sort(this.sortTypeName);
        for (let item of leadsourcelist) {
          this.leadSourceList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
    } catch (e) {}
  };

  @observable agencyTabItems = [
    { label: LeadTabs.Contracted, value: 0 },
    { label: LeadTabs.NonContracted, value: 1 },
  ];
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action setIsCollapsed(value: boolean) {
    this.isCollapsed = value;
  }
  @action setSelectedPanel(value: string) {
    this.setFilterForm(value, true);
    this.selectedPanel = value;
    this.loadDropdowns();
    this.goSearch();
  }

  @action setFilterForm = (key: string, phaseload: boolean) => {
    switch (key) {
      case LeadTabs.Contracted:
        //set obervables for non-contract tab
        this.NCstartDate = this.startDate;
        this.NCendDate = this.endDate;
        this.NCselectedUser = this.selectedUser;
        this.NCuplineId = this.uplineId;
        this.NCselectedAssignee = this.selectedAssignee;
        this.NCselectedSubAssignee = this.selectedSubAssignee;
        this.NCselectedCompany = this.selectedCompany;
        this.NCselectedLeadPhase = this.selectedLeadPhase;
        this.NCselectedLeadPhaseDetail = this.selectedLeadPhaseDetail;
        this.NCselectedLeadSource = this.selectedLeadSource;
        this.NCLeadId = this.LeadId;
        //get obervables for contract tab
        this.startDate = this.CstartDate;
        this.endDate = this.CendDate;
        this.selectedUser = this.CselectedUser;
        this.uplineId = this.CuplineId;
        this.selectedAssignee = this.CselectedAssignee;
        this.selectedSubAssignee = this.CselectedSubAssignee;
        this.selectedCompany = this.CselectedCompany;
        this.selectedLeadPhase = this.CselectedLeadPhase;
        this.selectedLeadPhaseDetail = this.CselectedLeadPhaseDetail;
        this.selectedLeadSource = this.CselectedLeadSource;
        this.LeadId = this.CLeadId;
        return;
      case LeadTabs.NonContracted:
        //set obervables for contract tab
        this.CstartDate = this.startDate;
        this.CendDate = this.endDate;
        this.CselectedUser = this.selectedUser;
        this.CuplineId = this.uplineId;
        this.CselectedAssignee = this.selectedAssignee;
        this.CselectedSubAssignee = this.selectedSubAssignee;
        this.CselectedCompany = this.selectedCompany;
        this.CselectedLeadPhase =
          phaseload === true ? this.selectedLeadPhase : "3";
        this.CselectedLeadPhaseDetail = this.selectedLeadPhaseDetail;
        this.CselectedLeadSource = this.selectedLeadSource;
        this.CLeadId = this.LeadId;
        //get obervables for non-contract tab
        this.startDate = this.NCstartDate;
        this.endDate = this.NCendDate;
        this.selectedUser = this.NCselectedUser;
        this.uplineId = this.NCuplineId;
        this.selectedAssignee = this.NCselectedAssignee;
        this.selectedSubAssignee = this.NCselectedSubAssignee;
        this.selectedCompany = this.NCselectedCompany;
        this.selectedLeadPhase = this.NCselectedLeadPhase;
        this.selectedLeadPhaseDetail = this.NCselectedLeadPhaseDetail;
        this.selectedLeadSource = this.NCselectedLeadSource;
        this.LeadId = this.NCLeadId;
        return;
      default:
        return;
    }
  };
  @observable isException: boolean = false;

  @action setEndDate(value: Date | undefined) {
    this.endDate = value;
  }
  @action setStartDate(value: Date | undefined) {
    this.startDate = value;
  }
  @action setSelectDate(value: any, type: string) {
    var d = isNaN(new Date(value).getMonth()) === false ? value : undefined;
    if (type === "startDate") {
      this.startDate = d;
    } else {
      this.endDate = d;
    }
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }

  @action goSearch = async () => {
    this.isException = false;
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.setIsException(true);
    } else {
      await this.loadMarketerLeads();
      this.setIsException(false);
    }
  };

  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return false;
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case LeadTabs.Contracted:
          routes.contractedLeads.push();
          return;
        case LeadTabs.NonContracted:
          routes.leads.push();
          return;
        default:
          return;
      }
    }
  };
  @action handleMainLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case MainLeadTabs.MarketerLeads:
          routes.leads.push();
          return;
        case MainLeadTabs.SaleOpsLead:
          routes.salesLeads.push();
          return;
        default:
          return;
      }
    }
  };

  @action
  public Load = async (OnClose?: () => void): Promise<void> => {
    this.onClose = OnClose;
    this.getLeadsMainTabITem();
    this.getLeadsSubTabITem();
    let tabValue = Utils.getLeadsTab();
    if (tabValue !== "") {
      if (tabValue === "1") {
        this.selectedTabIndex = 1;
        this.selectedMainTabIndex = 0;
        this.selectedPanel = LeadTabs.Contracted.toString();
      } else {
        this.selectedTabIndex = 0;
        this.selectedMainTabIndex = 0;
        this.selectedPanel = LeadTabs.NonContracted.toString();
      }
    } else {
      this.selectedTabIndex = 0;
      this.selectedMainTabIndex = 0;
      this.selectedPanel = LeadTabs.NonContracted.toString();
    }

    let delay = 0;
    if (UserContext.permissions.length <= 0) {
      delay = 2000;
    }
    setTimeout(async () => {
      if (
        UserContext.permissions.length > 0 &&
        !Utils.hasUserPermission(Permission.ViewLeads)
      ) {
        this.hasPermission = false;
      } else {
        this.hasPermission = true;
      }

      if (
        UserContext.permissions.length > 0 &&
        !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
      ) {
        this.hasSalesOpsPermission = false;
      } else {
        this.hasSalesOpsPermission = true;
      }
      this.loadDropdowns();
      await this.goSearch();
    }, delay);
  };

  private paramIsContracted: boolean | undefined = false;

  private paramCarrierID: number | undefined;
  private paramLeadID: number | undefined;
  private paramAgentID: number | undefined;
  private paramAssignee: number | undefined;
  private paramPhase: number | undefined;
  private paramSubAssignee: number | undefined;
  private paramDetail: number | undefined;
  private paramLeadSource: number | undefined;
  private paramProductLine: number | undefined;

  @action resetFilters = () => {
    this.showInactiveCarriers = false;
    this.resetAll();
    this.startViewDate = new Date();
    this.endViewDate = new Date();
    this.loadDropdowns();
    this.goSearch();
    this.companyList = Utils.carriersFilter(this.carriersInitial.values);
  };

  @action resetAll = () => {
    this.setIsException(false);
    this.startDate = undefined;

    this.endDate = undefined;
    this.selectedAssignee = "0";
    this.selectedLeadPhase = "0";
    this.subAssigneeList = [{ label: "ALL", value: "0" }];
    this.tempSubAssigneeList = [{ label: "ALL", value: "0" }];
    this.detailList = [{ label: "ALL", value: "0" }];
    this.selectedSubAssignee = "0";
    this.uplineId = 0;
    this.selectedCompany = "0";
    this.selectedProductLine = "0";
    this.selectedLeadPhaseDetail = "0";
    this.selectedLeadSource = "0";
    this.LeadId = "";
    this.selectedUser = "";
    this.sortColumn = "dateCreated";
    this.sortOrder = false;
    this.rows = 10;
    this.startIndex = 0;
    this.first = 0;
  };

  @action loadMarketerLeads = async () => {
    if (this.selectedAssignee !== "0")
      this.paramAssignee = parseInt(this.selectedAssignee);
    else this.paramAssignee = undefined;

    if (this.selectedLeadPhase !== "0")
      this.paramPhase = parseInt(this.selectedLeadPhase);
    else this.paramPhase = undefined;

    if (this.selectedSubAssignee !== "0")
      this.paramSubAssignee = parseInt(this.selectedSubAssignee);
    else this.paramSubAssignee = undefined;

    if (this.selectedLeadPhaseDetail !== "0")
      this.paramDetail = parseInt(this.selectedLeadPhaseDetail);
    else this.paramDetail = undefined;

    if (this.selectedLeadSource !== "0")
      this.paramLeadSource = parseInt(this.selectedLeadSource);
    else this.paramLeadSource = undefined;

    //for contracted only

    if (this.selectedPanel === LeadTabs.Contracted) {
      this.paramIsContracted = true;

      if (this.selectedProductLine !== "0")
        this.paramProductLine = parseInt(this.selectedProductLine);
      else this.paramProductLine = undefined;
    } else {
      this.paramIsContracted = false;
    }

    if (this.uplineId !== 0) this.paramAgentID = this.uplineId;
    else this.paramAgentID = undefined;

    if (this.LeadId !== "") this.paramLeadID = parseInt(this.LeadId);
    else this.paramLeadID = undefined;

    if (this.selectedCompany !== "0")
      this.paramCarrierID = parseInt(this.selectedCompany);
    else this.paramCarrierID = undefined;
    this.MarketerLeadSearchReponse = await LeadStore.getMarketerLeads(
      this.paramIsContracted,
      this.paramLeadID,
      this.paramAgentID,
      undefined,
      this.paramAssignee,
      this.paramSubAssignee,
      this.paramLeadSource,
      this.paramProductLine,
      this.paramCarrierID,
      this.startDate,
      this.endDate,
      this.paramPhase,
      this.paramDetail,
      undefined,
      undefined,
      this.startIndex,
      this.rows,
      this.sortColumn,
      this.sortOrder
    );
    if (this.MarketerLeadSearchReponse && this.MarketerLeadSearchReponse.data) {
      this.MarkerterLeadLists.values = this.MarketerLeadSearchReponse.data;
      this.totalRecords = this.MarketerLeadSearchReponse.recordCount;
    }
    if (UserContext.userId && UserContext.userId > 0) {
      this.MarketerLeadsMonthSummaryResponse = await LeadStore.getMonthSummary(
        UserContext.userId,
        1,
        0,
        UserContext.userId
      );
      this.MyActivatedMonthCountSummary.values =
        this.MarketerLeadsMonthSummaryResponse &&
        this.MarketerLeadsMonthSummaryResponse.userMonthlyActivatedCount
          ? this.MarketerLeadsMonthSummaryResponse.userMonthlyActivatedCount
          : [];

      this.MyContractedMonthCountSummary.values =
        this.MarketerLeadsMonthSummaryResponse &&
        this.MarketerLeadsMonthSummaryResponse.userMonthlyContractedCount
          ? this.MarketerLeadsMonthSummaryResponse.userMonthlyContractedCount
          : [];

      this.ActivatedMonthCountSummary.values =
        this.MarketerLeadsMonthSummaryResponse &&
        this.MarketerLeadsMonthSummaryResponse.teamMonthlyActivatedCount
          ? this.MarketerLeadsMonthSummaryResponse.teamMonthlyActivatedCount
          : [];

      this.ContractedMonthCountSummary.values =
        this.MarketerLeadsMonthSummaryResponse &&
        this.MarketerLeadsMonthSummaryResponse.teamMonthlyContractedCount
          ? this.MarketerLeadsMonthSummaryResponse.teamMonthlyContractedCount
          : [];
    }
  };

  @observable SelectedViewModel: ViewModel | undefined;
  @observable rows: number = 10;
  Route = async (): Promise<void> => {};

  @action loaded = () => {};

  @action onRowClick = async (key: string) => {
    console.log("row click...." + JSON.stringify(key));
  };

  @action goToContracted = async () => {
    routes.contractedLeads.push();
  };
  @action addNewAgentLead = async () => {
    routes.addAgentLead.push();
  };

  @observable first: number = 0;
  @observable startIndex: number = 0;

  @observable LeadsMainTabItems: TabItems[] = [];
  @observable LeadsSubTabItems: SubTabItems[] = [];

  @action getLeadsSubTabITem = () => {
    this.LeadsSubTabItems = [];
    let a = { label: LeadTabs.NonContracted, value: 0 };
    let b = { label: LeadTabs.Contracted, value: 1 };
    this.LeadsSubTabItems.push(a);
    this.LeadsSubTabItems.push(b);
  };

  @action getLeadsMainTabITem = () => {
    if (
      UserContext.permissions.length > 0 &&
      !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
    ) {
      this.hasSalesOpsPermission = false;
    } else {
      this.hasSalesOpsPermission = true;
    }
    this.LeadsMainTabItems = [];
    let a = { label: MainLeadTabs.MarketerLeads, value: 0 };
    this.LeadsMainTabItems.push(a);
    if (this.hasSalesOpsPermission) {
      let b = { label: MainLeadTabs.SaleOpsLead, value: 1 };
      this.LeadsMainTabItems.push(b);
    }
  };

  @observable totalRecords: number | undefined = 0;
  private onClose?: () => void;

  @action onPage(firstIndex: number, rows: number) {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    this.goSearch();
  }
  @action handleTabClick = (index: number | undefined, key: string): void => {
    //this.resetAll();
    this.selectedTabIndex = index;
    if (index !== undefined) {
      Utils.setLeadsTab(index);
    }
    this.setSelectedPanel(key);
  };
  @action handleMainTabClick = (
    index: number | undefined,
    key: string
  ): void => {
    if (index === 0) {
      Utils.setLeadsTab(0);
      routes.leads.push();
    } else {
      Utils.setLeadsTab(2);
      routes.salesLeads.push();
    }
  };

  @observable selectedTabIndex?: number = 0;
  @observable selectedMainTabIndex?: number = 0;
  //Agent Section
  @observable isContractingSent: boolean = true;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable selectedUser: string = "";
  @observable NCselectedUser: string = "";
  @observable CselectedUser: string = "";
  @observable uplineId: number = 0;
  @observable NCuplineId: number = 0;
  @observable CuplineId: number = 0;
  @observable LeadId: string = "";
  @observable CLeadId: string = "";
  @observable NCLeadId: string = "";
  @observable isWarningDisplayed: boolean = false;
  @observable isUplineWarning: boolean = false;
  @observable selectedMarketingEvent: string = "Select Marketing Event";
  @observable agentId: number | undefined;
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
  }
  @action loadSubAssignee = async (assigneeId: number) => {
    if (assigneeId !== null && assigneeId > 0) {
      let result = await LeadStore.getSubAssigneeLookup(
        assigneeId,
        Utils.getUserId()
      );
      if (result) {
        this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
        for (let item of result) {
          this.subAssigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        for (let item of this.assigneeList) {
          if (item.value === this.selectedAssignee) {
            var existItem = this.subAssigneeList.find(
              (p) => p.value === this.selectedAssignee
            );
            if (existItem === undefined) {
              this.subAssigneeList.push({
                label: "" + item.label,
                value: "" + item.value,
              });
            }
            this.selectedSubAssignee = item.value;
          }
        }
      }
    } else {
      try {
        var response = await LeadStore.getDropdownValues(true, true);
        if (response && response.subAssignee) {
          this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
          var subassigneelist = [...response.subAssignee].sort(
            this.sortTypeName
          );
          for (let item of subassigneelist) {
            this.subAssigneeList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
          }
          this.selectedSubAssignee = "0";
        }
      } catch (e) {}
    }
  };
  @action setSelectedAssignee(value: string) {
    this.selectedAssignee = "" + value;
  }
  @action setUplineId = async (value: number) => {
    this.uplineId = value;
  };
  @action setLeadId = async (value: string) => {
    this.LeadId = value;
  };
  @action fillterUsers = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          this.AgentsList.values = result.data;
          this.FilteredAgentsList.values = result.data;
        }
      }
    }
  };
}

export interface TabItems {
  label: MainLeadTabs;
  value: number;
}
export interface SubTabItems {
  label: LeadTabs;
  value: number;
}
