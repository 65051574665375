import ViewModel from "../../infrastructure/ViewModel";
import { observable, action } from "mobx";
import RouteList from "../../infrastructure/RouteList";
import Collection from "../../infrastructure/CollectionHelper";
import {
  AgentReconcileProductionModel,
  AgentReconcileProductionResponse,
  TopCarriersModel,
  TopProductsModel,
} from "../../services/ProductionService";
import ProductionStore from "./../../store/ProductionStore";
import { months } from "../../infrastructure/enum/Constants";
import Utils from "../../infrastructure/Utils";
import ProducerSearchStore from "../../store/ProducerSearchStore";

export class ProductionViewModel implements ViewModel {
  @observable profilePicPartnerlogo: string | undefined =
    "assets/layout/images/ICONNECT.png";
  @observable isStartDate: boolean = false;
  @observable isEndDate: boolean = false;
  //Added
  @observable startDate: Date = new Date(new Date().getFullYear(), 0, 1);
  @observable startDateErr: boolean = false;
  @observable yearGapErr: boolean = false;
  @observable endDate: Date = new Date();
  @observable endDateErr: boolean = false;
  @observable loggedInUser: string = "";
  @observable isWorkflowApiCall: boolean = true;
  //Added
  @observable minDate: Date = new Date(2016, 12, 1);
  @observable maxDate: Date = new Date();
  @observable CarrierList = new Collection<TopCarriersModel>();
  @observable ProductList = new Collection<TopProductsModel>();
  @observable businessUnit: string | undefined = "";
  @observable totalCarriersAnnualizedPremium: string = "$0";
  @observable totalProductsAnnualizedPremium: string = "$0";
  @observable yearRange: string = "";
  @observable title: string | undefined = "";
  @observable isEDPApiCall: boolean = true;

  @observable startIndex: number = 0;
  @observable rows: number = 10;
  @observable first: number = 0;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 10;
  // @action maxRange = (num:number) => {
  //    if(this.totalRange > 31556952000)
  //     window.alert("Please put a range of max 1 years");
  // }
  @action resetPaging = () => {
    this.startIndex = 0;
    this.first = 0;
    this.rows = 10;
  };
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    // this.loadDebtList();
  }

  @action setStartDate = (value: Date) => {
    this.startDate = value;
  };

  @action validateDates = (val: string) => {
    this.yearGapErr = false;
    this.startDateErr = false;
    this.endDateErr = false;
    this.title = "";
    if (this.startDate > this.endDate) {
      if (val === "start") {
        this.startDateErr = true;
        this.endDateErr = false;
      }
      if (val === "end") {
        this.endDateErr = true;
        this.startDateErr = false;
      }
    } else {
      this.startDateErr = false;
      this.endDateErr = false;
      if (this.endDate.getTime() - this.startDate.getTime() > 31536000000) {
        this.yearGapErr = true;
      }
    }
  };

  @action setEndDate = (value: Date) => {
    this.endDate = value;
  };
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable sortColumnDebt: string = "id";
  @observable sortDebt: boolean = false;
  @action resetSort() {
    this.sortDebt = false;
    this.sortColumnDebt = "id";
  }
  @action setSortOrder() {
    this.sortDebt = !this.sortDebt;
  }
  @action setSortColumn(column: string) {
    this.sortColumnDebt = column;
  }
  @observable agentId: number | undefined;
  @observable agentName: string | undefined;
  @observable agentAWNs: string[] = [];
  @observable businessUnitFullName: string = "";
  @observable carriers: string[] = [];
  @observable lineAppCountChartValues: string = "";
  @observable lineAnnualizedPremiumChartValues: string = "";
  @observable prevYear: number = 0;
  @observable currYear: number = 0;
  @action Load = async (
    id: number,
    name: string | undefined,
    agentAWNs: string[]
  ) => {
    this.agentId = id;
    this.agentName = name;
    this.agentAWNs = agentAWNs;
    //await this.endpoint to grab data;
    this.startDate = new Date(new Date().getFullYear(), 0, 1);
    this.endDate = new Date();
    this.noRecordsFound();
    this.title = "";
    this.lineAppCountChartValues = "[[]]";
    this.lineAnnualizedPremiumChartValues = "[[]]";
    this.yearRange = 2017 + ":" + new Date().getFullYear();
    this.startDateErr = false;
    this.endDateErr = false;
    this.yearGapErr = false;
    this.title = "";

    this.checkAWNs();
    if (this.title != "") return;

    if (this.isEDPApiCall) {
      this.isEDPApiCall = false;
      this.loadData();
      this.isEDPApiCall = true;
    }
    this.resetPaging();
    this.sortColumnDebt = "id";
    this.sortDebt = false;
  };

  @action checkAWNs = async () => {
    if (this.agentAWNs.length == 0)
      this.title =
        "Unable to retrieve production data. Go to the Contracts tab for this agent and add any missing carrier Agent Writing Numbers (AWN).";
    else {
      for (let item of this.agentAWNs) {
        if (item != undefined && item.trim() == "") {
          this.title =
            "Unable to retrieve production data. Go to the Contracts tab and correct update any invalid Agent Writing Numbers (AWN).";
          break;
        }
      }
    }
  };

  @action goSearch = async () => {
    this.title = "";

    this.checkAWNs();
    if (this.title != "") return;

    if (this.isEDPApiCall) {
      this.isEDPApiCall = false;
      this.loadProductionData();
      this.isEDPApiCall = true;
    }
  };

  @action loadProductionData = async () => {
    try {
      let result = await ProductionStore.getProductionSearchData(
        this.startDate,
        this.endDate,
        this.agentAWNs,
        this.carriers
      );
      if (result !== null) {
        this.title = result.title;
        this.bindCarriersandProductsData(result.data);
      } else {
        this.noRecordsFound();
      }
    } catch (error) {
      this.noRecordsFound();
    }
  };

  @action noRecordsFound() {
    this.CarrierList.values = [];
    this.ProductList.values = [];
    this.totalCarriersAnnualizedPremium = "$0";
    this.totalProductsAnnualizedPremium = "$0";
  }

  @action loadData = async () => {
    try {
      let result = await ProductionStore.getProductionData(
        this.startDate,
        this.endDate,
        this.agentAWNs,
        this.carriers
      );
      if (result !== null) {
        this.title = result.title;
        this.bindCarriersandProductsData(result.data);
        this.bindChartData(result.data);
      } else {
        this.noRecordsFound();
      }
    } catch (error) {
      this.noRecordsFound();
    }
  };

  private bindCarriersandProductsData(
    result: AgentReconcileProductionModel | undefined
  ) {
    if (result != undefined) {
      this.CarrierList.values = result.topCarriers;
      this.ProductList.values = result.topProducts;
      this.businessUnit = result.businessUnit;
      this.totalCarriersAnnualizedPremium =
        result.carrierAnnualizedPremiumTotal;
      this.totalProductsAnnualizedPremium =
        result.productAnnualizedPremiumTotal;
    }
  }
  private bindChartData(result: AgentReconcileProductionModel | undefined) {
    try {
      if (result !== undefined) {
        let appCountsByMonthYearList = result.appCountYearOverYear
          ? result.appCountYearOverYear
          : [];
        let prevYear = new Date().getFullYear() - 1;
        let currYear = new Date().getFullYear();
        this.prevYear = prevYear;
        this.currYear = currYear;
        if (appCountsByMonthYearList.length > 0) {
          let tempList = [["", `${currYear}`, `${prevYear}`]];
          let list = appCountsByMonthYearList.map((i: any) => {
            return [
              i.month ? months[i.month - 1] : "",
              i.currentYear ? i.currentYear : 0,
              i.previousYear ? i.previousYear : 0,
            ];
          });
          let a = [...tempList, ...list];
          this.lineAppCountChartValues = JSON.stringify(a);
        } else {
          // let tempList = [["", `${currYear}`, `${prevYear}`]];
          let tempList = [[]];
          this.lineAppCountChartValues = JSON.stringify(tempList);
        }

        let annualizedPremiumsByMonthYearList =
          result.annualizedPremiumYearOverYear
            ? result.annualizedPremiumYearOverYear
            : [];
        if (annualizedPremiumsByMonthYearList.length > 0) {
          let tempList = [["", `${currYear}`, `${prevYear}`]];
          let list = annualizedPremiumsByMonthYearList.map((i: any) => {
            return [
              i.month ? months[i.month - 1] : "",
              i.currentYear ? i.currentYear : 0.0,
              i.previousYear ? i.previousYear : 0.0,
            ];
          });
          let a = [...tempList, ...list];
          this.lineAnnualizedPremiumChartValues = JSON.stringify(a);
        } else {
          // let tempList = [["", `${currYear}`, `${prevYear}`]];
          let tempList = [[]];
          this.lineAnnualizedPremiumChartValues = JSON.stringify(tempList);
        }
      } else {
        this.lineAppCountChartValues = "[[]]";
        this.lineAnnualizedPremiumChartValues = "[[]]";
      }
    } catch (error) {
      this.lineAppCountChartValues = "[[]]";
      this.lineAnnualizedPremiumChartValues = "[[]]";
    }
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action downloadProduction = async (request: any) => {
    try {
      var result = await ProducerSearchStore.downloadProductionData(request);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocument(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : "Production.xlsx",
              result.fileName !== null && result.fileName !== undefined
                  ? result.fileName
                  : "Production.xlsx"
          );
        }
      }
    } catch (e) {
      //IMASLog.log("exception while download/view the production.");
    }
    //this.isLoading = false;
  };
}
