import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import Utils from "../../../infrastructure/Utils";
import SupplyOrderStore from "../../../store/SupplyOrderStore";
import {
  SupplyOrderDetailResponse,
  OrderDetails,
} from "../../../services/SupplyOrderService";
import PageContext from "../../../infrastructure/PageContext";

export class OrderDetailsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (orderId: string, fromAgent: boolean) => {
    this.resetPage();
    this.isLoading = false;
    this.orderId = orderId;
    this.FromAgent = fromAgent;
    PageContext.setIsLoadingVisible(false);
    await this.loadOrderDetails();
    if (!fromAgent) {
      this.title = "Supply Orders";
    }
  };

  Route = async (): Promise<void> => {
    // TODO: implement or remove function
  };
  @observable isLoading: boolean = true;
  @observable FromAgent: boolean = true;
  @observable title: string = "";
  @observable orderId: string = "";
  @observable createdBy: string = "";
  @observable agent: string = "";
  @observable shippedBy: string = "";
  @observable orderDate: string = "";
  @observable comments: string = "";
  @observable shippedTime: string = "";
  @observable trackingNumber: string = "";
  @observable hyperLinkTrackingNumber: string = "";
  @observable weight: string = "";
  @observable fee: string = "";
  @observable packagedDate: string = "";
  @observable shippedTo: string = "";
  @observable orderStatus: string = "";
  @observable supplyOrderDetailResponse: SupplyOrderDetailResponse | undefined;
  @observable orderDetails: OrderDetails[] = [];

  @action loadOrderDetails = async () => {
    try {
      this.isLoading = true;
      this.supplyOrderDetailResponse =
        await SupplyOrderStore.getSupplyOrderById(parseInt(this.orderId));
      if (this.supplyOrderDetailResponse) {
        this.createdBy = this.supplyOrderDetailResponse.createdBy
          ? this.supplyOrderDetailResponse.createdBy
          : "";
        this.agent = this.supplyOrderDetailResponse.agentName
          ? this.supplyOrderDetailResponse.agentName
          : "";
        this.shippedBy = this.supplyOrderDetailResponse.shippingMethod
          ? this.supplyOrderDetailResponse.shippingMethod
          : "";
        this.orderDate = this.supplyOrderDetailResponse.orderDate
          ? Utils.getDateInFormat(this.supplyOrderDetailResponse.orderDate)
          : "";
        this.comments = this.supplyOrderDetailResponse.comments
          ? this.supplyOrderDetailResponse.comments
          : "";
        this.shippedTime = this.supplyOrderDetailResponse.completedDate
          ? Utils.getDateInFormat(this.supplyOrderDetailResponse.completedDate)
          : "";
        this.hyperLinkTrackingNumber = this.supplyOrderDetailResponse
          .hyperLinkTrackingNumber
          ? this.supplyOrderDetailResponse.hyperLinkTrackingNumber
          : "";
        this.trackingNumber = this.supplyOrderDetailResponse.orderTrackingNumber
          ? this.supplyOrderDetailResponse.orderTrackingNumber
          : "";
        this.weight = this.supplyOrderDetailResponse.weight
          ? this.supplyOrderDetailResponse.weight
          : "";
        this.fee = this.supplyOrderDetailResponse.fee
          ? "$" + this.supplyOrderDetailResponse.fee.toString()
          : "";
        this.packagedDate = this.supplyOrderDetailResponse.printedDate
          ? Utils.getDateInFormat(this.supplyOrderDetailResponse.printedDate)
          : "";
        this.shippedTo = this.supplyOrderDetailResponse.shippedTo
          ? this.supplyOrderDetailResponse.shippedTo
          : "";
        this.orderStatus = this.supplyOrderDetailResponse.comments
          ? this.supplyOrderDetailResponse.comments
          : "";
        if (this.supplyOrderDetailResponse.orderDetails) {
          this.orderDetails = this.supplyOrderDetailResponse.orderDetails;
        }
      }
      this.isLoading = false;
    } catch (e) {
    // TODO: implement or remove function
    }
  };

  // @observable policyDetails: AgentPolicySummaryDetailModel | null = null
  @observable NotesList = new Collection<{
    item: string;
    company: string;
    state: string;
    qty: string;
    sent: string;
    bo: string;
    comment: string;
  }>();
  @observable isDeleteOrderConfirm: boolean = false;
  @observable sourceFrom: string = "";
  @observable alertMessage: string = "";

  @action deleteRequest() {
    this.sourceFrom = "delete";
    this.alertMessage = "Are you sure you want to delete this order?";
    this.setDeleteOrderConfirmation(true);
  }
  @action ordeAgainRequest() {
    this.sourceFrom = "orderAgain";
    this.alertMessage = "Are you sure you want to Reorder this Order?";
    this.setDeleteOrderConfirmation(true);
  }

  @action onDeleteOrderClick = async () => {
    this.isDeleteOrderConfirm = false;
    if (this.sourceFrom === "delete") {
      await SupplyOrderStore.deleteSupplyOrder(parseInt(this.orderId));
    } else {
      await SupplyOrderStore.SupplyOrderagain(parseInt(this.orderId));
    }
    Utils.goBack();
  };

  @action setDeleteOrderConfirmation(value: boolean) {
    this.isDeleteOrderConfirm = value;
  }
  @action resetPage() {
    this.orderId = "";
    this.createdBy = "";
    this.agent = "";
    this.shippedBy = "";
    this.orderDate = "";
    this.comments = "";
    this.shippedTime = "";
    this.trackingNumber = "";
    this.weight = "";
    this.fee = "";
    this.packagedDate = "";
    this.shippedTo = "";
    this.orderStatus = "";
    this.title = "";
    this.orderDetails = [];
  }
}
