import { observable, action } from "mobx";
import ProducerStore from "../../store/ProducerStore";
import ContractsStore from "../../store/ContractsStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import {
  DocumentAttachment,
  NoteDetailModel,
  NoteModel,
} from "../../services/ProducerService";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { routes } from "../../router";
import IMASLog from "../../infrastructure/IMASLog";
import { Permission } from "../../infrastructure/enum/Permission";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { AgentListModel } from "../../services/ProducerSearchService";
import { AgentSummaryListener } from "./AgentContractsViewModel";
import DocumentStore from "../../store/DocumentStore";
import UserContext from "../../infrastructure/UserContext";
import { googleTagManager } from "../../infrastructure/tracking";

export class AddNoteViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable isEdit: boolean = false;
  @observable isClone: boolean = false;
  @observable noteId: number = 0;
  @observable isPinNote: boolean = false;
  @observable isPinNotes: boolean = false;
  @observable isPingNoted: boolean = false;
  @observable isPingNoteConfirm: boolean = false;
  @observable noteDetailModel: NoteDetailModel | undefined;
  @observable
  public ErrorModel = new ErrorModel(new AddNoteValidator());
  @action Load = async (id: number, noteId: number | undefined) => { 
    this.isLoadingInIt = true;
    this.isWarningDisplayed = false;
    this.agentId = id;
    let isClone = localStorage.getItem("isClone");
    this.isClone = isClone ? true : false;
    this.isInvalidMessage = false;
    this.resetAllFields();
    await this.getSaleTypeLookup();
    await this.getTypes();
    this.getSecLevelData();
    this.ResetValidate();    
    this.securityLevel = "Public";
    if (noteId) {
      this.noteId = noteId;
      this.isEdit = true;
      this.title = isClone ? "Clone Note" : "Edit Note";
      this.loadNoteDetails(this.noteId);
    } else {
      await this.loadNoteDetails(0);
      this.isPinNote = false;
      this.isPinNotes = false;
      this.message = "";
      this.selectedType = "3";
      this.noteId = 0;
      this.isEdit = false;
      this.title = "Add Note";
      this.loadCompanyLoopup();
    }
    this.isLoadingInIt = false;
  };

  @observable disableSave: boolean = false;

  @action setDisableSave = (value: boolean) => {
    this.disableSave = value;
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};
  static Symbol: symbol = Symbol("AddNoteViewModel");

  @observable isLoading: boolean = true;
  @observable isLoadingInIt: boolean = true;
  @observable isContractingSent: boolean = false;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable selectedUser: string = "";
  @observable uplineId: number = 0;
  @observable isWarningDisplayed: boolean = false;
  @observable isUplineWarning: boolean = false;
  @observable selectedMarketingEvent: string = "Select Marketing Event";
  @observable agentId: number | undefined;
  @observable agentNoteUserId: number = 0;
  @observable agentNoteUserTeamId: number = 0;
  @observable companies = new Collection<any>();

  @observable FilteredDocumentList = new Collection<any>();
  @observable documentList = new Collection<any>();
  @observable selectedDocument: string = "";
  @observable selectedDocumentId: number = 0;

  @observable marketingEvents = [
    { label: "Select Marketing Event", value: "Select Marketing Event" },
    {
      label:
        "5169-[5169]- Website Lead - Final Website - GWIC Website - Active - 03-8-2018 (Jot Form) - [03/08/2018]",
      value: "5169",
    },
    {
      label:
        "5168-[5168]- Website Lead - Final Website - GWIC Website - Active - 03-8-2018 (Jot Form) - [03/08/2018]",
      value: "5168",
    },
  ];

  @observable type = new Collection<any>();
  @observable levelData = [{ label: "NONE", value: "NONE" }];
  @observable salesTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable secLevelData: itemList[] = [];
  @action getSecLevelData = () => {
    this.secLevelData = [];
    let publ = { label: "Public", value: "Public" };
    let priv = { label: "Private", value: "Private" };
    Utils.hasUserPermission(Permission.AddPublicAgentNote) &&
      this.secLevelData.push(publ);
    Utils.hasUserPermission(Permission.AddPrivateAgentNote) &&
      this.secLevelData.push(priv);
  };
  @action onPinnedNoteClick = async () => {
    this.isPinNote = true;
    this.isPingNoteConfirm = false;
  };
  @action setPinnedNoteConfirmationCancel(value: boolean) {
    this.isPingNoteConfirm = value;
    this.isPinNote = value;
  }
  @action setPinnedNoteConfirmation(value: boolean) {
    this.isPingNoteConfirm = value;
  }
  @action checkIsPinned = () => {
    if (
      !this.isPinNotes &&
      this.isPinNote &&
      sessionStorage.getItem("isPinned") &&
      sessionStorage.getItem("isPinned") == "pin"
    )
      this.isPingNoteConfirm = true;
  };
  @action getTypes = async () => {
    this.type.values = [];
    var result = await ProducerStore.getNoteTypesLookup();
    if (result) {
      Utils.mapListItemsToDropdown(result, this.type, "", "");
    }
  };
  @observable isLead: boolean = false;
  @observable isRemoteAgency: boolean | undefined;
  @observable isAgency: number | undefined;
  @observable selectedCompany: string = "0";
  @observable selectedLevel: string = "0";
  @observable selectedType: string = "3";
  @observable selectedSalesType: string = "";
  @observable securityLevel: string = "Private";
  @observable message: string = "";
  @observable isInvalidMessage: boolean = false;
  @observable isException: boolean = false;
  @observable exceptionMessage: any;
  @observable isSuccess: boolean = false;
  @observable selecteDate: Date = new Date();
  @observable doNotCall: boolean = false;
  @observable doNotMarket: boolean = false;
  @observable doNotEmail: boolean = false;
  @observable doNotCallUpline: boolean = false;
  @observable doNotMarketUpline: boolean = false;
  @observable doNotEmailUpline: boolean = false;
  @observable fmoTranser: boolean = false;
  @observable title: string = "Add Note";
  @observable noteTitle: string = "";

  getSymbol(): symbol {
    return AddNoteViewModel.Symbol;
  }
  @action leftMessage() {
    var msg = this.message + " Left Message";
    IMASLog.log("left message: " + msg);
    this.setMessage(msg);
  }
  @action onChangePinNote = (e: any) => {
    (<any>this)[e.target.id] = !e.target.value;
  };
  @action getSaleTypeLookup = async () => {
    let res = await ProducerStore.getSaleTypeLookup();
    try {
      if (res && res.length > 0) {
        this.salesTypeList.values = [];
        res.forEach((obj) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value : "",
          };
          this.salesTypeList.values.push(data);
        });
      } else {
        this.salesTypeList.values = [];
      }
    } catch (e) {
      this.salesTypeList.values = [];
    }
  };

  @action loadNoteDetails = async (noteId: number) => {   
    this.isLoading = true;
    this.isException = false;
    try {
      this.noteDetailModel = await ProducerStore.getNoteDetails(
        noteId,
        this.agentId || 0
      );
    } catch (error) {
      IMASLog.log("error from note details " + error);
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
    if (this.noteDetailModel) {
      !this.isClone &&
        this.setMessage(
          this.noteDetailModel.note ? "" + this.noteDetailModel.note : ""
        );
      this.selectedCompany = "" + this.noteDetailModel.companyId;
      
      this.isAgency = this.noteDetailModel.isAgency;
      
      this.agentNoteUserId = this.noteDetailModel.userId || 0;
      this.agentNoteUserTeamId = this.noteDetailModel.userTeamId || 0;
      this.isRemoteAgency = this.noteDetailModel.remoteAgency;      
      this.isPinNote =
        this.noteDetailModel.pinned && !this.isClone
          ? this.noteDetailModel.pinned
          : false;
      this.isPinNotes =
        this.noteDetailModel.pinned && !this.isClone
          ? this.noteDetailModel.pinned
          : false;
      this.documentList.values = this.isClone
        ? []
        : this.noteDetailModel.documentAttachmentList || [];       
      if (this.noteId) {
        this.noteTitle = this.noteDetailModel.title || "";
        var secLevel = this.noteDetailModel.private ? "Private" : "Public";
        this.setSecurityLevel(secLevel);
      }

      if (this.isRemoteAgency) {
        this.isWarningDisplayed = this.isRemoteAgency || false;
        if (!this.noteId) this.setSecurityLevel("Private");
      }
      this.loadCompanies();
      if (this.noteDetailModel.hierarchyPremierId) {
        this.uplineId = this.noteDetailModel.hierarchyPremierId;
        var details = await ProducerStore.getAgentDetails(this.uplineId);
        this.selectedUser = details.firstName + ", " + details.lastName;
      } else {
        this.uplineId = 0;
        this.selectedUser = "";
      }
      this.type.values = [];
      var noteTypeList = this.noteDetailModel.agentNoteTypes;
      if (noteTypeList) {
        var temp: { label: string; value: string }[] = [];
        for (let noteType of noteTypeList) {
          temp.push({
            label: "" + noteType.text,
            value: "" + noteType.value,
          });
        }
        temp = temp.sort(this.sortTypeName);
        this.type.values = temp;
      }

      this.selectedSalesType = this.noteDetailModel.saleTypeId
        ? this.noteDetailModel.saleTypeId.toString()
        : "";
      this.doNotCall = this.getBooleanValue(
        this.noteDetailModel.defaultContactOptionsDoNotCall
      );
      this.doNotMarket = this.getBooleanValue(
        this.noteDetailModel.defaultContactOptionsDoNotMarket
      );
      this.doNotEmail = this.getBooleanValue(
        this.noteDetailModel.defaultContactOptionsDoNotEmail
      );
      this.fmoTranser = this.getBooleanValue(
        this.noteDetailModel.transferFromOtherFmo
      );
      this.setNoteType(
        this.noteDetailModel.agentNoteTypeId
          ? "" + this.noteDetailModel.agentNoteTypeId
          : "3"
      );
      this.isContractingSent = this.selectedType === "6";
      this.levelData = [];
      if (this.noteDetailModel.companyId) {
        await this.loadPayoutLevels("" + this.noteDetailModel.companyId);
        this.selectedLevel =
          "" +
          (this.noteDetailModel.payoutLevelId
            ? this.noteDetailModel.payoutLevelId
            : "0");
      } else {
        var marketingLevelList = this.noteDetailModel.markertingLevel;
        if (marketingLevelList) {
          for (let marketingLevel of marketingLevelList) {
            this.levelData.push({
              label: "" + marketingLevel.text,
              value: "" + marketingLevel.value,
            });
          }
        }
      }
    }
    this.isLoading = false;
  };
  getBooleanValue(value: boolean | undefined) {
    return value ? value : false;
  }
  @action resetAllFields() {
    this.isAgency = undefined;
    this.isRemoteAgency = false;
    this.isWarningDisplayed = false;
    this.isUplineWarning = false;
    this.noteTitle = "";
    this.message = "";
    this.selectedCompany = "0";
    this.selectedLevel = "0";
    this.selectedType = "3";
    this.securityLevel = "Private";
    this.selectedSalesType = "";
    this.uplineId = 0;
    this.isInvalidMessage = false;
    this.isException = false;
    this.isSuccess = false;
    this.selecteDate = new Date();
    this.doNotCall = false;
    this.doNotMarket = false;
    this.doNotEmail = false;
    this.doNotCallUpline = false;
    this.doNotMarketUpline = false;
    this.doNotEmailUpline = false;
    this.fmoTranser = false;
    this.marketingEvents = [];
    this.companies = new Collection<any>();
    this.setSelectedUser("");  
    this.isContractingSent = false;
    this.documentList.values = [];
    this.selectedDocument = "";
    this.selectedDocumentId = 0;
  }
  @action setIsContractingSent(value: boolean) {
    this.isContractingSent = value;
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setisUplineWarning(value: boolean) {
    this.isUplineWarning = value;
  }

  @action setisWarningDisplayed(value: boolean) {
    this.isWarningDisplayed = value;
  }

  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }

  @action setDoNotCall(value: boolean) {
    this.doNotCall = value;
  }
  @action setDoNotEmail(value: boolean) {
    this.doNotEmail = value;
  }
  @action setDoNotMarket(value: boolean) {
    this.doNotMarket = value;
  }
  @action setDoNotCallUpline(value: boolean) {
    this.doNotCallUpline = value;
  }
  @action setDoNotEmailUpline(value: boolean) {
    this.doNotEmailUpline = value;
  }
  @action setDoNotMarketUpline(value: boolean) {
    this.doNotMarketUpline = value;
  }
  @action setFmoTranser(value: boolean) {
    this.fmoTranser = value;
  }
  @action setSelectDate(value: Date) {
    this.selecteDate = value;
  }
  @action setMessage(value: string) {
    this.message = value;
  }

  @action setSecurityLevel(value: string) {
    this.securityLevel = value;
    if (value === "Private") {
      if (this.isRemoteAgency)
        this.isWarningDisplayed = this.isRemoteAgency || false;
    }
  }
  @action setNoteType(value: string) {
    IMASLog.log("note type : " + value);
    this.selectedType = value;
    this.isContractingSent = value === "6";
    if (this.selectedType === "6") {
      this.setCompany(this.selectedCompany);
    }
  }
  @action setLead(value: boolean) {
    this.isLead = value;
  }
  listener: AgentSummaryListener | undefined;
  setListener(listener: AgentSummaryListener) {
    this.listener = listener;
  }
  @action addNote = async () => {
    googleTagManager.trackAction("agent_note_created", { feature: routes.addNote.name, user_id: UserContext.userId, agent_id: this.agentId });
   await this.ResetValidate();
    var result = await this.Validate();
    if (result) {
      this.setDisableSave(false);
      return 0; 
    }
    if (this.message && this.message.trim().length <= 0) {
      this.isInvalidMessage = true;
      this.setDisableSave(false);
      return 0;
    }
    this.isInvalidMessage = false;
    IMASLog.log("validaion result: " + result);
    this.isException = false;
    this.isSuccess = false;
    this.isLoading = true;
      try {    
      IMASLog.log("selected type:" + this.selectedType);       
      var dto: NoteModel = {
        note: this.message ? this.message.trim() : "",
        agentNoteTypeId: +this.selectedType,
        private: this.securityLevel === "Private",
        dateTime: new Date(),
        companyId: 0,
        title: this.noteTitle,
        documentAttachmentList: this.documentList.values || undefined,
      };
      if (this.selectedCompany !== "0") dto.companyId = +this.selectedCompany;
      if (this.selectedLevel !== "0") dto.payoutLevelId = +this.selectedLevel;
      dto.defaultContactOptionsDoNotCall = this.doNotCallUpline;
      dto.defaultContactOptionsDoNotEmail = this.doNotEmailUpline;
      dto.defaultContactOptionsDoNotMarket = this.doNotMarketUpline;
      dto.transferFromOtherFmo = this.fmoTranser;
      dto.contactOptionsDoNotCall = this.doNotCall;
      dto.contactOptionsDoNotEmail = this.doNotEmail;
      dto.contactOptionsDoNotMarket = this.doNotMarket;
      dto.hierarchyPremierId = this.uplineId;
      dto.saleTypeId = this.selectedSalesType
        ? Number(this.selectedSalesType)
        : undefined;
      dto.pinned = this.isPinNote;
      if (this.agentId) {
        var noteId = 0;
        if (this.isEdit && !this.isClone) {
          if (this.noteDetailModel) dto.id = this.noteDetailModel.id;
          await ProducerStore.editNote(this.agentId, dto);
        } else {
          noteId = await ProducerStore.addNote(this.agentId, dto);          
          IMASLog.log("add note successfully .." + noteId);
        }
      }

      if (!this.isPinNote && this.isPinNotes)
        localStorage.setItem("AgentNotePinEdit", "true");
      else localStorage.setItem("AgentNotePinEdit", "false-Edit");

      if (this.isEdit) {
        if (!this.isPinNote && this.isPinNotes) {
          localStorage.setItem("AgentNotePinEdit", "false-Edit");
          localStorage.setItem("AgentNotePin", "unpin");
          sessionStorage.setItem("isPinned", "unpin");
        } else {
          localStorage.setItem("AgentNotePinEdit", "true");
          localStorage.setItem("AgentNotePin", "pin");
          sessionStorage.setItem("isPinned", "pin");
        }
      }

      if (!this.isEdit || this.isClone) {
        if (this.isPinNote || this.isPinNotes) {
          localStorage.setItem("AgentNotePinEdit", "true");
          localStorage.setItem("AgentNotePin", "pin");
          sessionStorage.setItem("isPinned", "pin");
        }
      }

      this.isLoading = false;
      if (this.listener) {
        this.listener.updateSummary();
      }
      this.setDisableSave(false);
      this.Cancel();
    } catch (error) {
      this.setDisableSave(false);
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };

  @action loadCompanyLoopup = async () => {
    var result = await ContractsStore.getAllCompanyLookup();
    if (result) {
      Utils.mapListItemsToDropdown(result, this.companies, "Select", "0");
    }
  };
  @action loadCompanies = async () => {
    this.isLoading = true;
    if (this.noteDetailModel) var companyList = this.noteDetailModel.companies;
    this.companies.values.push({ label: "Select", value: "0" });
    if (companyList) {
      companyList = companyList.sort(this.compareCarrierName);
      for (let company of companyList) {
        this.companies.values.push({
          label: "" + company.text,
          value: "" + company.value,
        });
        if (company.selected) this.selectedCompany = "" + company.value;
      }
    }
    this.isLoading = false;
  };

  compareCarrierName = (a: any, b: any) => {
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  sortTypeName = (a: any, b: any) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
    if (value === "") {
      this.uplineId = 0;
    }
  }
  @action setSelectedDocument(value: any) {
    this.selectedDocument = value;
  }

  @action deleteDocument(rowId: any, mappingId: any) {
    let document: any;
    if (rowId && rowId > 0 && mappingId == 0) {
      this.documentList.values = this.documentList.values.filter((item) => {
        return item.rowId != rowId;
      });
    } else if (mappingId && mappingId > 0) {
      document = this.documentList.values.filter((item) => {
        return item.mappingId == mappingId;
      });

      if (document && document.length > 0) {
        document[0].isDeleted = true;
      }
    }
  }

  @action setUplineId = async (value: number) => {
    this.uplineId = value;
    await this.getUplineContactOptions();
  };
  @action getUplineContactOptions = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      var result = await ContractsStore.getContractContactOptions(
        this.uplineId,
        +this.selectedCompany
      );
      this.setDoNotCallUpline(result.doNotCall ? result.doNotCall : false);
      this.setDoNotEmailUpline(result.doNotEmail ? result.doNotEmail : false);
      this.setDoNotMarketUpline(
        result.doNotMarket ? result.doNotMarket : false
      );
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };
  @action setCompany(value: string) {
    this.selectedCompany = value;
    if (value !== "NONE" && this.selectedType === "6" && value != "0") {
      this.loadPayoutLevels(value);
      this.selectedLevel = "0";
      this.isContractingSent = true;
    } else {
      this.levelData = [];
    }
  }
  @action loadPayoutLevels = async (value: string) => {
    if (this.agentId) {
      try {
        this.isException = false;
        this.isLoading = true;
        var result = await ProducerStore.getPayoutLevels(+value, this.agentId);
        var temp: { label: string; value: string }[] = [];
        temp.push({ label: "NONE", value: "0" });
        if (result.payoutLevel) {
          for (let marketingLevel of result.payoutLevel) {
            temp.push({
              label: "" + marketingLevel.text,
              value: "" + marketingLevel.value,
            });
          }
          this.levelData = [];
          this.levelData = temp;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isException = true;
        this.exceptionMessage = error.response;
      }
    }
  };
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action loaded = (agents: Collection<AgentListModel>) => {
    this.AgentsList = agents;
    this.isLoading = false;
  };
  @action
  public Cancel = async () => {
    localStorage.removeItem("isClone");
    this.message = "";
    routes.agentNotes.replace({ agentId: "" + RouteParamDetails.id });
  };
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    localStorage.removeItem("isClone");
    return this.ErrorModel.ResetValidation(this);
  };

  @action fillterUsers = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          this.AgentsList.values = result.data;
          this.FilteredAgentsList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              //element.scrollTo(0, 0);
            }
          }, 10);
        }
      }
    }
  };

  @action filterDocuments = async (value: string) => {
    let result = await DocumentStore.getAgentDocuments(
      this.agentId || 0,
      0,
      0,
      this.selectedDocument,      
      0,
      100,
      "id",
      false,
      Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments) ? null : false,
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result && result.recordCount && result.data) {
      let self = this;
      result.data =
        self.documentList.values &&
        result.data.filter((i) => {
          return (
            self.documentList.values.find(
              (j) =>
                j.documentName == i.documentName &&
                j.fileType == i.fileType &&
                !j.isDeleted
            ) == null
          );
        });
      result.recordCount = (result.data && result.data.length) || 0;
    }

    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          result.data.splice(0, 0, { id: -1 });
          this.FilteredDocumentList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              //element.scrollTo(0, 0);
            }
          }, 10);
        }
      } else {
        this.FilteredDocumentList.values = [noAgent];
        this.selectedDocument = "";
      }
    }
  };

  @action downloadDocumentLink = async (docId: number) => {
    this.isLoading = true;
    this.isException = false;
    this.exceptionMessage = {};
    try {
      var result = await DocumentStore.getDocumentLink(docId, 0);
      if (result != null) {
        if (result.pdfData) {
          Utils.downloadDocument(
            result.pdfData,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : "document.pdf",
            result.actualFileName ? result.actualFileName : ""
          );
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.exceptionMessage = JSON.stringify(responseModel);
            this.isException = true;
          }
        }
      }
    } catch (e) {
      this.isException = true;
      this.exceptionMessage = e.response;
    }
    this.isLoading = false;
  };
}

export interface itemList {
  label: string;
  value: string;
}
class AddNoteValidator extends AbstractValidator<AddNoteViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedCompany)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Carrier is required.")
      .when((input) => input.selectedType === "6");
    this.validateIfString((input) => input.message)
      .isNotEmpty()
      .withFailureMessage("Message is required.")
      .when((input) => input.selectedType != "6");
    this.validateIfString((input) => input.selectedUser)
      .isNotEmpty()
      .withFailureMessage("Upline is required.")
      .when(
        (input) =>
          input.selectedCompany !== "0" &&
          input.uplineId <= 0 &&
          input.selectedType === "6"
      );
  }
}
