import { action, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import IMASLog from "../../../../infrastructure/IMASLog";
import ContractsStore from "../../../../store/ContractsStore";
import JsBarcode from "jsbarcode";
import { routes } from "../../../../router";

export class PrintIMBMailShippingViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { }

    @observable mailShippingNumber: string = "";
    @observable companyLabel: string = "MXD DES MOINES IA";
    @observable stdLtrLabel: string = "STD LTR BC WKG";
    @observable agentAddressLabel: string = "Premier Senior Marketing Inc, Norfolk NE 901246241";
    @observable isLoading: boolean = false;

    @action backToAdmin = async () => {
        routes.administration.push();
    }

    @action Load = async () => {
        await this.getLastBulkMailShippingNumber();
    }
    @action async getLastBulkMailShippingNumber() {
        try {
            this.isLoading = true;
            var result = await ContractsStore.getLastBulkMailShippingNumber();
            if (result) {
                this.mailShippingNumber = ''+result.shippingNumber;
                setTimeout(() => {
                    JsBarcode("#barcode", this.mailShippingNumber.replace(/ /g, '').trim(), { width: 1.494, height: 70, displayValue: false });
                });
            }
            this.isLoading = false;
        } catch (e) {
            IMASLog.log("exception: " + e.message);
            this.isLoading = false;
        }
    }

    @action async updateShippingNumber() {
        try {
            this.isLoading = true;
            await ContractsStore.updateBulkMailShippingNumber();
            await this.getLastBulkMailShippingNumber();
            this.isLoading = false;
        } catch (e) {
            IMASLog.log("exception: " + e.message);
            this.isLoading = false;
        }
    }

    @action printDiv() {

        var divToPrint = document.getElementById('divBarcodeBlockPrint');
        var newWin = window.open('', 'Print-Window');
        if (newWin) {
            newWin.document.open();
            newWin.document.write('<html><body onload="window.print()">' + (divToPrint ? divToPrint.innerHTML : '') + '</body></html>');
            newWin.document.close();          
            newWin.close()
        }


    }



}