import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { AllColoringRulesModel } from "../../../../services/ContractService";
import AccountingStore from "../../../../store/AccountingStore";
import ContractsStore from "../../../../store/ContractsStore";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import { WritingNumberListViewModel } from "./WritingNumberListViewModel";
import { LicensePersistencyListViewModel } from "./LicensePersistencyListViewModel";
import { CompanyLicenseNumberDefaultsListViewModel } from "../../licensing/companyLicenseNumberDefaults/CompanyLicenseNumberDefaultsListViewModel";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { Carrier } from "../../../../infrastructure/enum/Settings";

export class CarrierAddEditViewModel extends BaseAddEditViewModel {

    @observable name: string = '';
    @observable abbreviation: string = '';
    @observable shortName: string | undefined = '';
    @observable carrierGroupName: string = '';
    @observable carrierGroupId: number = 0;
    @observable defaultPaymentSourceId: number = 0;
    @observable commissionProcessingDivisionId: number = 0;
    @observable isEligibilityRequired: boolean = false;
    @observable allowOverlappingDates: boolean = false;
    @observable hidden: boolean = false;
    @observable selectedColor: string = "000000"


    @observable writingNumbersModel: WritingNumberListViewModel = new WritingNumberListViewModel();
    @observable CompanyLicenseNumberDefaultsListViewModel: CompanyLicenseNumberDefaultsListViewModel = new CompanyLicenseNumberDefaultsListViewModel();
    @observable actionWNTitle: string = "";
    @observable isEditWNVisible: boolean = false;
    @observable selectedWNType: string = "1";
    @observable selectedWNDefault: any;
    @observable writingNumberTypeList = new Collection<{ label: string; value: string; }>();
    @observable selectedWNId: number = 0;
    @observable ViewColoringRule: boolean = false;
    @observable AddEditPTColor: boolean = false;
    @observable PTColorTitle: string = '';
    @observable startRange: string = "0.00"
    @observable stopRange: string = "0.00"
    @observable PTColorList = new Collection<AllColoringRulesModel>(); 
    @observable PTColorTotalrows: number = 0;
    @observable PTColorStartIndex: number = 0;
    @observable PTColorPageSize: number = 10;
    @observable PTColorSortColumn: string = "licensePersistencyTypeName";
    @observable PTColorSortOrder: boolean = true;
    @observable selectedPTColorId: number = 0;
    
    @action setWNEditorVisibility = async(value: boolean) => {
        this.isEditWNVisible = value;
    }
    @action onEditWNHide = () => {
        this.setWNEditorVisibility(false);
    }
    @action editWNItem = async (id: number) => {
        if(id === 0) {
            this.selectedWNId = 0
            this.actionWNTitle = Carrier.AddWRN;
            this.selectedWNType = "1";
            this.selectedWNDefault = false;
        } else {
            this.actionWNTitle = Carrier.EditWRN;
            var WN = this.writingNumbersModel.wrnList.values.filter((i)=> i.id === id)
            if(WN.length === 1){
                this.selectedWNId = id
                this.selectedWNType = this.writingNumberTypeList.values.filter((ind) => ind.label === WN[0].licenseNumberType)[0].value
                this.selectedWNDefault = WN[0] ? WN[0].isWritableDefault : false
            }
        }
        this.setWNEditorVisibility(true);
    };
    
    @action addWNItem = async () => {
        this.editWNItem(0);
    };

    @action
    public ValidatePT = async () => {
        return this.ErrorModelPT.Validate(this);
    }


    @action
    public ValidatePTColor = async () => {
        return this.ErrorModelPTColor.Validate(this);
    }
    @observable licensePersistencyModel: LicensePersistencyListViewModel = new LicensePersistencyListViewModel();
    @observable selectedLPTId: number = 0;
    @observable actionLPTTitle: string = "";
    @observable isEditLPTVisible: boolean = false;
    @observable selectedLPTTypeName: string = "";
    @observable selectedLPTSortOrder: string = "1";
    @observable selectedPTId: number = 0
    
    @action setLPTEditorVisibility = async(value: boolean) => {
        this.isEditLPTVisible = value;
    }
    @action onEditLPTHide = () => {
        this.setLPTEditorVisibility(false);
    }
    @action editLPTItem = async (id: number) => {
        if(id === 0) {
            this.actionLPTTitle = Carrier.AddPT;
            this.selectedLPTId = id;
            this.selectedPTId = 0;
            this.selectedLPTTypeName = "";
            this.selectedLPTSortOrder = "1";
        } else {
            this.selectedPTId = id
            this.actionLPTTitle = Carrier.EditPT;
            this.selectedLPTId = id;
            var PT = this.licensePersistencyModel.PTList.values.filter((i)=> i.id === id)
            if(PT.length === 1){
                this.selectedLPTTypeName = PT[0].licensePersistencyTypeName || ""
                this.selectedLPTSortOrder = PT[0] ? ''+PT[0].sortOrder : "1"
            }
        }
        this.ResetValidate();
        this.setLPTEditorVisibility(true);
    };
    
    @action addLPTItem = async () => {
        await this.editLPTItem(0);
    };




    constructor()
    {
        super("Carrier", routes.listCarrier, new CarrierValidator());
    }

    @action removeIds = (items: any, type: string) => {
        let results: any[] = []
        var temp = {}
        if(items.length > 0){
             items.forEach((i: any) => {
                if(type === "wrn"){
                    temp = {id: i.id.toString().includes("wrn") ? 0 : i.id, licenseNumberTypeId: +this.writingNumberTypeList.values.filter((ind) => ind.label ===  i.licenseNumberType)[0].value, isWritableDefault: i.isWritableDefault}
                } else if(type === "PT"){
                    temp = {licensePersistencyTypeId: i.id.toString().includes("PT") ? 0 : i.id, licensePersistencyTypeName: i.licensePersistencyTypeName, sortOrder: i.sortOrder}
                }
                results.push(temp) 
            }
            );
        }
        return results
    }

    protected toServiceModel() {
        return {
                    id: this.selectedId,
                    name: this.name,
                    abbreviation: this.abbreviation,
                    carrierShortName: this.shortName,
                    carrierGroupName: this.carrierGroupName,
                    carrierGroupId: this.carrierGroupId,
                    defaultPaymentSourceId: this.defaultPaymentSourceId,
                    checkEligibility: this.isEligibilityRequired,
                    hidden: this.hidden,
                    allowOverlappingDates: this.allowOverlappingDates,
                    companyLicenseNumberDefault: this.removeIds(this.writingNumbersModel.wrnList.values, "wrn"),
                    licensePersistencyTypes: this.removeIds(this.licensePersistencyModel.PTList.values, "PT")
               };

    }


    protected async loadItem(id: number): Promise<void> {
        if(id === 0) {
            this.resetModel();
        }
        else {
            var item = await ContractsStore.getCarrierById(id);
            if(item) {
                this.selectedId = item.id || 0;
                this.name = item.name || "";
                this.abbreviation = item.abbreviation || "";
                this.shortName = item.carrierShortName || "";
                this.carrierGroupName = item.carrierGroupName || "";
                this.carrierGroupId = item.carrierGroupId || 0;
                this.defaultPaymentSourceId = item.defaultPaymentSourceId || 0;
                this.isEligibilityRequired = item.checkEligibility || false;
                this.hidden = item.hidden || false;
                this.allowOverlappingDates = item.allowOverlappingDates || false;
                if(this.selectedId !== 0){
                    await this.writingNumbersModel.Load(item.id);
                    await this.licensePersistencyModel.Load(item.id);
                }
            }
        }
    }

    protected resetModel(): void {
        this.name = "";
        this.abbreviation = "";
        this.shortName = "";
        this.carrierGroupName = "";
        this.carrierGroupId = 0;
        this.defaultPaymentSourceId = 0;
        this.isEligibilityRequired = false;
        this.hidden = false;
        this.allowOverlappingDates = false;
        this.writingNumbersModel.reset();
        this.licensePersistencyModel.reset();
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await ContractsStore.addCarrier(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await ContractsStore.updateCarrier(item);
        
    }

    // Lookups and related
    protected async loadLookups(): Promise<void> {
        var items = await ContractsStore.getAllCarrierGroupLookup();
        Utils.mapListItemsToDropdown(items, this.carrierGroupList, "Select Carrier Group", "0");

        items = await AccountingStore.getPaymentSource(0);
        Utils.mapListItemsToDropdown(items, this.paymentSourceList, "Select Payment Source", "0");

        items = await ContractsStore.getAllLicenseNumbersLookup();
        Utils.mapListItemsToDropdown(items, this.writingNumberTypeList, "", ""); 
        this.selectedWNType = this.writingNumberTypeList.values[0].value
        
    }

    @observable carrierGroupList = new Collection<{ label: string; value: string; }>();
    @observable paymentSourceList = new Collection<{ label: string; value: string; }>();
    @observable divisionList  = new Collection<{ label: string; value: string; }>();
    @observable
    public ErrorModelPT = new ErrorModel(new PTValidator());
    @observable
    public ErrorModelPTColor = new ErrorModel(new PTColorValidator());

    @action
    public ResetValidate = async () => {
        return this.ErrorModelPT.ResetValidation(this);
    }

    @action ResetValidatePT = async () => {
        return this.ErrorModelPT.ResetValidation(this);
    }

    @action ResetValidatePTColor = async () => {
        return this.ErrorModelPTColor.ResetValidation(this);
    }

    @action saveSelectedWNItem = () => {
        var WN = this.writingNumbersModel.wrnList.values.filter((ind) => ind.id === this.selectedWNId)
        if (WN.length === 1){
            WN[0].licenseNumberType = this.writingNumberTypeList.values.filter((ind) => ind.value ===  this.selectedWNType.toString())[0].label
            WN[0].isWritableDefault = this.selectedWNDefault
        } else {
            this.writingNumbersModel.wrnList.values.push({id:  'wrn'+new Date().getMilliseconds(), licenseNumberType: this.writingNumberTypeList.values.filter((ind) => ind.value === this.selectedWNType.toString())[0].label, isWritableDefault: this.selectedWNDefault})
        }
            this.onEditWNHide()
    }

    @action deleteWN = (id: number) => {
        var WN = this.writingNumbersModel.wrnList.values.filter((ind) => ind.id === id)
        if(WN.length === 1){
            this.writingNumbersModel.wrnList.values =  this.writingNumbersModel.wrnList.values.filter(item => item.id !== id);
        }
    }

    @action deletePT =  (id: number) => {
        var PT = this.licensePersistencyModel.PTList.values.filter((ind) => ind.id === id)
        if(PT.length === 1){
            this.licensePersistencyModel.PTList.values =  this.licensePersistencyModel.PTList.values.filter(item => item.id !== id);
        }
    }

    @action saveSelectedLPTItem = async() => {
        if(await this.ValidatePT()){
            return 0;
        }
        var PT = this.licensePersistencyModel.PTList.values.filter((ind) => ind.id === this.selectedPTId)
        if (PT.length === 1){
            PT[0].licensePersistencyTypeName = this.selectedLPTTypeName
            PT[0].sortOrder = +this.selectedLPTSortOrder
        } else {
            this.licensePersistencyModel.PTList.values.push({id:  'PT'+new Date().getMilliseconds(), licensePersistencyTypeName: this.selectedLPTTypeName, sortOrder: +this.selectedLPTSortOrder})
        }
        this.onEditLPTHide()
    }

    @action ViewColoringRules = async(id: number) => {
        this.selectedPTId = id;
        await this.loadColoringRules()
        this.setViewColoringRules(true)
    }

    @action loadColoringRules = async() => {
        var result = await ContractsStore.getLicensePersistencyTypeColoringRule(this.selectedPTId, this.PTColorStartIndex, this.PTColorPageSize, this.PTColorSortColumn, this.PTColorSortOrder)
        if(result && result.data){
            this.PTColorTotalrows = result.recordCount || 0
            var res = result.data
            this.PTColorList.values = res
        }        
    }

    @action deletePTColor = async(id: number) => {
        await ContractsStore.deleteLicensePersistencyTypeColoringRule(id)
        setTimeout(async() => {
            await this.loadColoringRules()
        }, 500); 
    }

    @action setAddEditColoringRules = (value: boolean) => {
        this.AddEditPTColor = value
    }

    @action addPTColorItem = () => {
        this.isStopRangeValid = false;
        this.setAddEditColoringRules(true)
        this.PTColorTitle = Carrier.AddPTColor
        this.startRange = "0.00"
        this.stopRange = "0.00"
    }

    @action setViewColoringRules = (value: boolean) => {
        this.ViewColoringRule = value
    }

    @action ptoDto = (id: number) => {
        return {
            licensePersistencyTypeColoringRuleId: id,
            licensePersistencyTypeId: this.selectedPTId,
            hexColor: this.selectedColor.includes('#') ? this.selectedColor : '#'+this.selectedColor,
            startRange: +this.startRange,
            stopRange: +this.stopRange
            };
    }

    @observable validStopRangeError: string = "";
    @observable isStopRangeValid: boolean = false;

    @action onPTColorSave = async() => {
        if(await this.ValidatePTColor()){
            this.isStopRangeValid = true;
            this.validStopRangeError = "Stop Range is invalid";
            return 0;
        }
        var item = this.ptoDto(this.selectedPTColorId)
        if(this.PTColorTitle === Carrier.AddPTColor){
            await ContractsStore.createLicensePersistencyTypeColoringRule(item)
        } else {
            await ContractsStore.updateLicensePersistencyTypeColoringRule(item)
        }
        
        this.setAddEditColoringRules(false);
        setTimeout(async() => {
            await this.loadColoringRules()
        }, 500); 
    }

    @action editLPTColorItem = async(id: number) => {
        this.isStopRangeValid = false;
        var result = await ContractsStore.getLicensePersistencyTypeColoringRuleById(id)
        if(result){
            this.selectedPTColorId = id
            this.selectedColor = result.hexColor ? result.hexColor : "000"
            this.startRange = result.startRange ? ''+result.startRange  : '0.00'
            this.stopRange = result.stopRange ? ''+result.stopRange  : '0.00'
        }
        this.setAddEditColoringRules(true)
        this.PTColorTitle = Carrier.EditPTColor
    }

    @action onPTColorCancel = () => {
        this.setAddEditColoringRules(false)
    }
}

class CarrierValidator extends AbstractValidator<CarrierAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.name)
            .isNotEmpty()
            .matches(/\S+/,"i")
            .withFailureMessage("Carrier Name is required");
        this.validateIfString(input => input.shortName)
            .isNotEmpty()
            .matches(/\S+/,"i")
            .withFailureMessage("Carrier Short Name is required");
        this.validateIfNumber(input => input.carrierGroupId)
            .isGreaterThan(0)
            .withFailureMessage("Carrier Group is required");
        this.validateIfNumber(input => input.defaultPaymentSourceId)
            .isGreaterThan(0)
            .withFailureMessage("Payment Source is required");
    }
}

class PTValidator extends AbstractValidator<CarrierAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.selectedLPTTypeName)
            .isNotEmpty()
            .withFailureMessage("Type Name is required");
        this.validateIfString(input => input.selectedLPTSortOrder)
            .isNotEmpty()
            .withFailureMessage("Sort Order is required");
    }
}  

class PTColorValidator extends AbstractValidator<CarrierAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfNumber(input => +input.stopRange)
            .isNotEmpty()
            .isLessThanOrEqual(100)
            .withFailureMessage("Stop Range is invalid");
    }
}