import { observable, action, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList"; 

export class BookOfBusinessViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};
 
  @action Load = async () => { 
    window.onload = function() {
      var iframe1 = document.getElementById('bob-iframe') as HTMLIFrameElement; 
      
        setTimeout(() => {if(iframe1 && iframe1.contentWindow && iframe1.contentWindow !== null){
          iframe1.contentWindow.scrollTo(0,200);
        } 
          }, 9000);
  };
  };
 
}
