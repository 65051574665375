import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { getCurrentRoute, routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { AddSupplyOrderViewModel } from "./../agents/orders/AddSupplyOrderViewModel";
import { AgentOrdersViewModel } from "./../agents/orders/AgentOrdersViewModel";
import { OrderDetailsViewModel } from "./../agents/orders/OrderDetailsViewModel";

export class SuppliesDashboardViewModel implements ViewModel {
  @observable route = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable
  SuppliesViewModel: AgentOrdersViewModel = new AgentOrdersViewModel();
  @observable
  SuppliesOrderDetailsViewModel: OrderDetailsViewModel =
    new OrderDetailsViewModel();
  @observable
  SuppliesAddEditOrderViewModel: AddSupplyOrderViewModel =
    new AddSupplyOrderViewModel();

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
  };

  @action goToSupplyOrder = async (currentRoute: RouteList) => {
    if (!(this.SelectedViewModel instanceof AddSupplyOrderViewModel)) {
      this.SelectedViewModel = this.SuppliesAddEditOrderViewModel;
    }
    await this.SelectedViewModel.Load(
      RouteParamDetails.id,
      // TODO: Eslint error for line below. orderId doesn't exits in param
      // eslint-disable-next-line
      // @ts-ignore
      currentRoute.params.orderId,
      false
    );
    await this.SelectedViewModel.Route(currentRoute);
  };
  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    if (currentRoute.name === routes.supplies.name) {
      if (!(this.SelectedViewModel instanceof AgentOrdersViewModel)) {
        this.SelectedViewModel = this.SuppliesViewModel;
      }
      // TODO: Eslint error for line below. agentId doesn't exits in param
      // eslint-disable-next-line
      // @ts-ignore
      await this.SelectedViewModel.Load(currentRoute.params.agentId, false);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.suppliesOrderDetails.name) {
      if (!(this.SelectedViewModel instanceof OrderDetailsViewModel)) {
        this.SelectedViewModel = this.SuppliesOrderDetailsViewModel;
      }
      await this.SelectedViewModel.Load(
        currentRoute.params.orderId.toString(),
        false
      );
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.suppliesAddOrder.name) {
      this.goToSupplyOrder(currentRoute);
    } else if (currentRoute.name === routes.suppliesEditOrder.name) {
      this.goToSupplyOrder(currentRoute);
    }
  };
}
