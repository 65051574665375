import { AbstractValidator } from "fluent-ts-validator";
import { observable, action, computed } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import AccountStore from "../../../../store/AccountingStore";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { routes } from "../../../../router";
import Utils from "../../../../infrastructure/Utils";
import RouteParamDetails from "../../../../infrastructure/RouteParamDetails";

export class CreditCardChargeEditViewModel implements ViewModel {
  @observable ChargeID: number = 0;
  @observable title: string = "Add Credit Card Charge Request";
  @observable status = [{ label: "Select Status", value: "0" }];
  @observable Agent: string = "";
  @observable Description: string = "";
  @observable TransactionId: string = "";
  @observable LicenseFees: number = 0;
  @observable Amount: number = 0;
  @observable selectedStatus: string = "";
  @observable isLicenseFee: boolean = true;
  @observable isAmountDigits: boolean = false;
  @observable requestedDate: Date = new Date();
  @observable chargeStatus = [
    { label: "Open", value: "1" },
    { label: "Closed - Cancel", value: "2" },
    { label: "Closed - Card Declined", value: "3" },
    { label: "Closed - Success", value: "4" },
  ];
  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }

  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  @computed
  get IsLoaded(): boolean {
    return !this.loading;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  @action setSelectedChargeStatus(value: string) {
    this.selectedStatus = value;
  }

  @action updateIsLicenseFee(value: boolean) {
    this.isLicenseFee = value;
  }
  @action formatDate(date: Date) {
    return Utils.getDateInFormat(date);
  }

  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  public Load = async (
    CreditCardChargeID: number | undefined
  ): Promise<void> => {
    // if (ProducerID !== this.ProducerID) {
    this.ResetValidate();
    this.resetAddFields();
    this.ChargeID = CreditCardChargeID ? CreditCardChargeID : 0;
    this.loading = true;
    console.log("on close...." + CreditCardChargeID);

    if (CreditCardChargeID) {
      var creditCardCharge = await AccountStore.getcreditCardChargeDetailDetail(
        CreditCardChargeID
      );
      this.InputModel = new InputModel();
      this.setSelectStatus(
        (creditCardCharge.creditCardChargeStatusId || 0).toString()
      );

      this.title = "Credit Card Charge Requests";
      this.InputModel.Agent = creditCardCharge.agentName || "";
      this.InputModel.Description = creditCardCharge.description || "";
      this.InputModel.TransactionID = creditCardCharge.transactionId || "";
      this.InputModel.DepositAmount = creditCardCharge.amount
        ? creditCardCharge.amount.toString()
        : "0";
      if (creditCardCharge.licensingFee) {
        this.InputModel.LicensingFee = "Yes";
      } else {
        this.InputModel.LicensingFee = "No";
      }
    } else {
      this.title = "Credit Card Charge Requests";
      this.InputModel.Description = "";
      this.InputModel.DepositAmount = undefined;
    }
  };

  @action setSelectStatus(value: string) {
    this.selectedStatus = value;
  }

  @action formatNum = (val: string): number => {
    let num;
    if (val) {
      val = val.toString().replace("$", "").split(",").join("");
      num = !isNaN(Number(val)) ? Number(val.split(",").join("")) : 0;
    } else num = 0;

    return num;
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action addRequest = async () => {
    await this.ResetValidate();
    if (!(await this.Validate())) {
      let body = {
        id: 0,
        agentId: RouteParamDetails.id,
        amount: this.InputModel.DepositAmount
          ? this.formatNum(this.InputModel.DepositAmount)
          : 0,
        description: this.InputModel.Description,
        licenseFee: this.isLicenseFee,
      };
      try {
        await AccountStore.addCreditCardCharge(body);
        return true;
      } catch (e) {}
    } else {
      return false;
    }
  };

  @action
  public Submit = async () => {
    await AccountStore.editCreditCardCharge({
      id: this.ChargeID,
      amount: this.InputModel.DepositAmount
        ? this.formatNum(this.InputModel.DepositAmount)
        : 0,
      creditCardChargeStatusId: parseInt(this.selectedStatus),
      transactionId: this.InputModel.TransactionID,
    });

    if (this.onSave) {
    }
    routes.listCreditCardCharge.push();
  };
  @action
  public cancelChargeEdit = async () => {
    routes.listCreditCardCharge.push();
  };

  @action resetAddFields() {
    this.InputModel.DepositAmount = undefined;
    this.InputModel.Description = "";
    this.isLicenseFee = true;
    this.isAmountDigits = false;
  }
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this.InputModel);
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this.InputModel);
  };

  @observable
  public InputModel = new InputModel();
  @observable
  public ErrorModel = new ErrorModel(new Validator());
  public onSave?: (CarrierBankDepositID: number) => void;
  private onClose?: () => void;
  @observable
  private loading = true;
}

export class InputModel {
  @observable
  public TransactionID: string = "";
  @observable
  public Description: string = "";
  @observable
  public DepositAmount: string | undefined = undefined;
  @observable
  public LicensingFee: string = "";
  @observable
  public Agent: string = "";
  //#endregion
  @observable
  public ID: number | undefined;
  @observable
  public DepositDate: Date | undefined;

  @action public SetDepositAmount = (value: string) => {
    this.DepositAmount = value;
  };

  @action
  public SetDescription = (value: string) => {
    this.Description = value;
  };
  //#endregion
}

class Validator extends AbstractValidator<InputModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.DepositAmount)
      .isNotEmpty()
      .withFailureMessage("Deposit Amount is required");
    this.validateIfString((input) => input.DepositAmount)
      .hasMaxLength(12)
      .whenNotEmpty()
      .withFailureMessage(
        "Deposit Amount must be less than or equal to 9 digits"
      )
      .when((input) =>
        input.DepositAmount != undefined
          ? !input.DepositAmount.includes(".")
          : false
      );
    this.validateIfString((input) => input.DepositAmount)
      .hasMaxLength(15)
      .whenNotEmpty()
      .withFailureMessage(
        "Deposit Amount must be less than or equal to 9 digits"
      )
      .when((input) =>
        input.DepositAmount != undefined
          ? input.DepositAmount.includes(".")
          : false
      );
    this.validateIfString((input) => input.Description)
      .hasMaxLength(500)
      .whenNotEmpty()
      .withFailureMessage("Comment is more then 500 characters");
  }
}
