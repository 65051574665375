import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import { DocWorkflowViewModel } from "./DocWorkflowViewModel";
import { SearchWorkflowDocsViewModel } from "./SearchWorkflowDocsViewModel";
import { DocumentInfoViewModel } from "./DocumentInfoViewModel";
import {LicenseCommunicationViewModel} from "./LicenseCommunicationViewModel";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { MainWorklowTab, WorkflowStatusType, WorkflowTabModelViewTypes } from "../../infrastructure/enum/WorkflowDetails";
import Utils from "../../infrastructure/Utils";
import UserContext from "../../infrastructure/UserContext";
import { Permission } from "../../infrastructure/enum/Permission";

export class DocWorkflowDetailsTabViewModel implements ViewModel {

  @observable selectedTabIndex?: number = 0;
  @observable selectedMainTabIndex?: number = 0;
  @observable selectedKey: string | undefined = "";
  @observable documentId: number = 0;

  @observable
  DocWorkflowViewModel: DocWorkflowViewModel = new DocWorkflowViewModel();
  @observable
  SearchWorkflowDocsViewModel: SearchWorkflowDocsViewModel = new SearchWorkflowDocsViewModel();
  @observable
  DocumentInfoViewModel: DocumentInfoViewModel = new DocumentInfoViewModel();
  @observable LicenseCommunicationViewModel: LicenseCommunicationViewModel = new LicenseCommunicationViewModel();
  @observable SelectedViewModel: keyof typeof WorkflowTabModelViewTypes =
    WorkflowTabModelViewTypes[WorkflowTabModelViewTypes.Workflow];
  @observable SelectedBaseViewModel: ViewModel | undefined;

  Route = async (currentRoute: RouteList): Promise<void> => {
    this.handleRoute(currentRoute);
  };

  @observable isWorkflowLoaded: boolean = false

  @action handleRoute(currentRoute: RouteList) {
    let name = currentRoute.name;
    switch (name) {
      case routes.docWorkflow.name:
        this.selectedKey = MainWorklowTab.DocWorkflow;
        this.SelectedViewModel = WorkflowTabModelViewTypes.Workflow;
        this.SelectedBaseViewModel = this.DocWorkflowViewModel;
       
        if(!this.isWorkflowLoaded){
          this.isWorkflowLoaded = true
          this.DocWorkflowViewModel.Load();
        } else {
          var userId = UserContext.getUserId()
          var user = this.DocWorkflowViewModel.workflowAssignedToUsersList.values.filter((ind) => +ind.value === userId)
          if(!Utils.hasUserPermission(Permission.ShowOnUserDropdowns) &&  userId && this.DocWorkflowViewModel.workflowAssignedToUsersList.values.length > 0){
            localStorage.setItem("showUserName", user && user.length === 1 ? user[0].label : '')
            this.DocWorkflowViewModel.workflowAssignedToUsersList.values =  this.DocWorkflowViewModel.workflowAssignedToUsersList.values.filter(item => +item.value !== userId); 
          } else if(Utils.hasUserPermission(Permission.ShowOnUserDropdowns) && user.length === 0){
            this.DocWorkflowViewModel.workflowAssignedToUsersList.values.push({label: localStorage.getItem("showUserName") || '', value: ''+userId})
            this.DocWorkflowViewModel.workflowAssignedToUsersList.values =  this.DocWorkflowViewModel.workflowAssignedToUsersList.values.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : -1)
            }           
          this.DocWorkflowViewModel.startIndex = 0;
          this.DocWorkflowViewModel.rows = parseInt(localStorage.getItem('queueRows') || '50');          
          this.DocWorkflowViewModel.loadDocs();
        }
        break;
      case routes.searchWorkflowDocs.name:
        this.selectedKey = MainWorklowTab.SearchWorkflowDocs;
        this.SelectedViewModel = WorkflowTabModelViewTypes.SearchWorkflowDocs;
        this.SelectedBaseViewModel = this.SearchWorkflowDocsViewModel;
        this.SearchWorkflowDocsViewModel.Load();
        break;
      case routes.documentInfo.name:
        this.selectedKey = MainWorklowTab.DocWorkflow;
        if(RouteParamDetails.workflowStatus === 'contacts'){
            this.SelectedViewModel = WorkflowTabModelViewTypes.LicenseCommunication;
        this.SelectedBaseViewModel = this.LicenseCommunicationViewModel;
        this.LicenseCommunicationViewModel.Load(RouteParamDetails.documentId)
        }
        else{
            this.SelectedViewModel = WorkflowTabModelViewTypes.DocumentInfo;
            this.SelectedBaseViewModel = this.DocumentInfoViewModel;
            this.DocumentInfoViewModel.Load(
              RouteParamDetails.documentId,
                this.DocWorkflowViewModel.workFlowStatusType ? this.DocWorkflowViewModel.workFlowStatusType : ""
             // , RouteParamDetails.queueRows
            );
        }
       
        break;
        case routes.licenseCommunication.name:
            this.selectedKey = MainWorklowTab.DocWorkflow;
            this.SelectedViewModel = WorkflowTabModelViewTypes.LicenseCommunication;
            this.SelectedBaseViewModel = this.LicenseCommunicationViewModel
            this.LicenseCommunicationViewModel.Load(RouteParamDetails.documentId)
    }
  }

  @observable workFlowTabItems = [
    { label: "DocWorkflow", value: 0 },
    { label: "Search Workflow Docs", value: 1 },
  ];

  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case MainWorklowTab.DocWorkflow:
        tabIndex = 0;
        break;
      case MainWorklowTab.SearchWorkflowDocs:
        tabIndex = 1;
        break;
      default:
        tabIndex = 0;
        break;
    }
    return tabIndex;
  }
  @observable
  private loading = false;
  private onClose?: () => void;

  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  @action Load = async () => {

  };


  @action handleMainTabClick = (
    index: number | undefined,
    key: string
  ): void => {
    this.selectedTabIndex = index;
    switch (key) {
      case MainWorklowTab.DocWorkflow:
        this.handleLinkClick(MainWorklowTab.DocWorkflow);
        break;
      case MainWorklowTab.SearchWorkflowDocs:
        this.handleLinkClick(MainWorklowTab.SearchWorkflowDocs);
        break;
      default:
        this.handleLinkClick(MainWorklowTab.DocWorkflow);
        break;
    }
  };
  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case MainWorklowTab.DocWorkflow:
          routes.docWorkflow.push();
          return;
        case MainWorklowTab.SearchWorkflowDocs:
          routes.searchWorkflowDocs.push();
          return;
        default:
          return;
      }
    }
  };

}
