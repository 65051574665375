import { getToken } from "../infrastructure/Auth0Authenticator";
import { observable, action } from "mobx";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import { Client as ProductionClient } from "../services/ProductionService";

class ProductionStore {
    @observable productionScreenLoader: any = {
        productTypeChart: false,
        carrierChart: false,
        totalEnrollment: false,
        annualPremium: false,
        dataTable: false
    };
    @action setProductionScreenLoader(component: string, value: boolean)
    {
        this.productionScreenLoader[component] = value;
    }
    @action resetProductionScreenLoader()
    {
      this.productionScreenLoader = {  
        productTypeChart: true,
        carrierChart: true,
        totalEnrollment: true,
        annualPremium: true,
        dataTable: true
        };
    }
    getApiVersion = () => {
        return "1";
    };
    getApiKey = async (): Promise<string> => {
        var token = await getToken();
        return token ? `Bearer ${token}` : "";
    };
    getClient = (): ProductionClient => {
        return ServiceClient.getClient(ServiceType.Production);
    }
    
    @action getProductionData = async (
        startDate: Date,
        endDate: Date,
        agentOrAgencyAWNs: string[],
        carrierNames: string[],
    ) => {

        var result = await this.getClient().getAgentProductionData(
            this.getApiVersion(),
            startDate,
            endDate,
            agentOrAgencyAWNs,
            carrierNames,
            UserContext.userId
        );
        return result;
    };

    @action getProductionSearchData = async (
        startDate: Date,
        endDate: Date,
        agentOrAgencyAWNs: string[],
        carrierNames: string[],
    ) => {

        var result = await this.getClient().getProductionSearchData(
            this.getApiVersion(),
            startDate,
            endDate,
            agentOrAgencyAWNs,
            carrierNames,
            UserContext.userId
        );
        return result;
    };

    @action getAgentAWNsData = async (
        agentId: number
    ) => {
        
        var result = await this.getClient().getAgentAWNsData(
            this.getApiVersion(),
            agentId,
            UserContext.userId
        );
        return result;
    };

    @action getProductionReportData = async (
        startDate: Date,
        endDate: Date,
        agentOrAgencyAWNs: string[] | undefined,
        carrier: string | undefined,
        products: string[] | null | undefined,
        productCategories: string[] | null | undefined,
        productSubCategories: string[] | null | undefined,
        fileType: string
    ) => {

        var result = await this.getClient().getAgentProductionReportData(
            this.getApiVersion(),
            startDate,
            endDate,
            agentOrAgencyAWNs,
            carrier,
            products,
            productCategories,
            productSubCategories,
            fileType,
            UserContext.userId
        );
        return result;
    };

}
export default new ProductionStore();
