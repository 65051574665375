import { PageMode } from "./enum/Common";

class RouteParamDetails {
  firstName: string = "";
  agentName: string = '';
  id: number = 0;
  policyId: number = 0;
  contractId: number = 0;
  agentId: number = 0;
  noteId: number = 0;
  orderId: number = 0;
  docId: number = 0;
  taskId: number = 0;
  debtId: number = 0;
  paymentPlanId: number = 0;
  carrierName: string = "";
  customerName: string = "";
  customerDob: string = "";
  levelId: number = 0;
  carrierId: number | undefined = undefined;
  productId: number = 0;
  payoutLevelId: number  = 0;
  notesUserId: number = 0;
  agentLocation: string  = '';
  reportToLicenseId: number | undefined = undefined;  
  uplineAgentId: number | undefined = undefined;  
  documentId: number = 0;
  workflowStatus: string = "";
  customerState: string = "";
  marketerId: number = 0;
  pagemode: PageMode = 4;
  customerAge: string = "";
  customerIgnoreNameValidation: boolean = false;
  agentSearchInput: string = "";
  agentSearchType: string = "";
  marketingEventId: number = 0;
  roleName: string = "";
  productSubCategoryName: string = "";
  callListId: number = 0;
  customerSummaryCall: boolean = false;
  policyIdForAddRenewal: number = 0;
  tabId: number | undefined = undefined;
  reportType: string | undefined = undefined;
	tabIndex: number | undefined = undefined;
	isUpdated: boolean = false;
}

export default new RouteParamDetails();
