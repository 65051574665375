import { observable, action } from "mobx";
import Collection from "../../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../../infrastructure/RouteList";
import Utils from "../../../../../infrastructure/Utils";
import ViewModel from "../../../../../infrastructure/ViewModel";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
export class SubmissionMethodViewFileViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = "View Submisison Methods";
  @observable submisisonMethodTypeList = [
    {label: "All", value: "-1"},
    {label: "Agent", value: "0"},
    {label: "Insured", value: "1"}]
  @observable ItemList: any;
  @observable submisisonMethodTypeId: string = "-1"
  @observable documentTypeId: string = ""
  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable carrierId: string = "0"
  @observable carrierList = new Collection<{
    label: string;
    value: string;
  }>();  

  @observable productSubcategoryId: string = "0"
  @observable productSubcategoryList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable productId: string = "0"
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable stateId: string = "0"
  @observable stateList = new Collection<{
    label: string;
    value: string;
  }>();  

  @observable policyEffectiveDateYearId: string = "0"
  @observable policyEffectiveDateYearList = new Collection<{
    label: string;
    value: string;
  }>();
  @action Load = async () => {
    await this.lookup()
    await this.goSearch()
  };

  @action goSearch = async() => {
    var result = await BusinessUnitConfigStore.getSubmissionTypeSearch(+this.submisisonMethodTypeId, 
      +this.documentTypeId,
      +this.carrierId,
      +this.stateId,
      +this.productId,
      +this.productSubcategoryId,
      +this.policyEffectiveDateYearId )
      if(result){ 
        this.ItemList = result.data
      }
  };

  @action lookup = async() => {
    var result = await BusinessUnitConfigStore.getSubmissionMethodById(0, true)
    if(result){
      this.carrierList.values =  this.mapListItemAndSort(result.carriers);
      this.documentTypeList.values =  this.mapListItemAndSort(result.documentTypes);
      this.productList.values =  this.mapListItemAndSort(result.products);
      this.productSubcategoryList.values =  this.mapListItemAndSort(result.productSubcategories);
      this.stateList.values = this.mapStateListItemAndSort(result.states);  // this.mapListItemAndSort(result.states);
      this.policyEffectiveDateYearList.values =  this.mapListItemAndSort(result.policyEffectiveDateYears);
    }
  }

  @action resetFilters = async() => {
    this.carrierId = '0'
    this.stateId = '0'
    this.productId = '0'
    this.documentTypeId = '0'
    this.submisisonMethodTypeId = '-1'
    this.policyEffectiveDateYearId = '0'
    this.productSubcategoryId = '0'
    await this.goSearch();
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });          
      return sortList;
    }
    else {
      return [];
    }
  }

    @action mapStateListItemAndSort(listObjects: any) {
        if (listObjects) {
            var sortList = listObjects.sort(Utils.compareListItem);
            sortList.forEach((element: any) => {
                element.label = element.stateName;
                element.value = element.stateId;
            });
            return sortList;
        }
        else {
            return [];
        }
    }
}
