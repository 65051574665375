import React, { lazy, Suspense } from "react";
import { observer } from "mobx-react";
import { ScrollPanel } from "primereact/components/scrollpanel/ScrollPanel";
import classNames from "classnames";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./../layout/layout.css";
import "primeflex/primeflex.css";
import "./../css/all.min.css";
import "./../css/fontawesome-4.7.0.min.css";
import "./../index.css";
import "./../css/fontawesome.min.css";
import "./../App.css";
import "./../Connect.css";
import "../fonts/Lato-Black.woff";
import "./../views/footer/footer.css";
import {
  agentGroup,
  customerGroup,
  workflowGroup,
  administrationGroup,
  routes,
  carrierManagementGroup,
} from "../router";
import { AppViewModel } from "./../viewModels/AppViewModel";
import Bootstrapper from "../infrastructure/Bootstrapper";
import IMASLog from "../infrastructure/IMASLog";
import IMASErrorView from "../../src/views/components/IMASErrorView";
import { Card } from "primereact/card";
import PageContext from "../infrastructure/PageContext";
import { IMASMessagesHandler } from "./components/controls/IMASMessagesHandler";
import { IMASProgressBarHandler } from "./components/controls/IMASProgressBarHandler";
import { Loading } from "./components/Loading";
import Utils from "../infrastructure/Utils";
import { Permission } from "../infrastructure/enum/Permission";
import { IMASProgressSpinner } from "./components/controls/IMASProgressSpinner";
import WebChatView from "../infrastructure/WebChat/index";
import UserContext from "../infrastructure/UserContext";
import TermsofService from "../TOS/tos";
import ReactTooltip from "react-tooltip";
import { getFeatureFlag } from "../views/components/FeatureFlag/utils";
import { toast, ToastContainer } from "react-toastify";
import circleChecksvg from "../fonts/circlecheck.png";
import { BookOfBusinessView } from "./bookOfBusiness/BookOfBusinessView";
import { GoalsView } from "./goals/GoalsView";
import { Features } from "../infrastructure/ConfigurationManager";
import { UniversalUploadView } from "../modules/universalUpload/UniversalUploadView";

const AgentsDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "Producer" */ "./agents/AgentsDashboardView"
  ).then((module) => ({
    default: module.AgentsDashboardView,
  }))
);

const AdminDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "Administrator" */ "./administration/AdminDashboardView"
  ).then((module) => ({
    default: module.AdminDashboardView,
  }))
);
const TasksDashboardView = lazy(() =>
  import(/* webpackChunkName: "Tasks" */ "./tasks/TasksDashboardView").then(
    (module) => ({
      default: module.TasksDashboardView,
    })
  )
);

const CustomersDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "Insured" */ "./customers/CustomersDashboardView"
  ).then((module) => ({
    default: module.CustomersDashboardView,
  }))
);

const ReportsView = lazy(() =>
  import(
    /* webpackChunkName: "Reports" */ "./../modules/reports/standardReports/ReportsView"
  ).then((module) => ({
    default: module.ReportsView,
  }))
);

const SelfServiceReportDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "SelfServiceReportDashboard" */ "./../modules/reports/SelfServiceReportDashboardView"
  ).then((module) => ({
    default: module.SelfServiceReportDashboardView,
  }))
);
const CarrierCMDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "CarrierCMDashboardView" */ "./../modules/administration/carrierManagement/CarrierCMDashboardView"
  ).then((module) => ({
    default: module.CarrierCMDashboardView,
  }))
);

const bookOfBusiness = lazy(() =>
  import(
    /* webpackChunkName: "BookOfBusinessView" */ "./bookOfBusiness/BookOfBusinessView"
  ).then((module) => ({
    default: module.BookOfBusinessView,
  }))
);

const universalUpload = lazy(() =>
  import(
    /* webpackChunkName: "UniversalUploadView" */ "../modules/universalUpload/UniversalUploadView"
  ).then((module) => ({
    default: module.UniversalUploadView,
  }))
);
const RtsReview = lazy(() =>
  import(/* webpackChunkName: "RtsReview" */ "./rtsReview/RtsReview").then(
    (module) => ({
      default: module.RtsReview,
    })
  )
);

const DocWorkflowDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "Workflow" */ "./workflow/DocWorkflowDashboardView"
  ).then((module) => ({
    default: module.DocWorkflowDashboardView,
  }))
);

const LeadsDashboardView = lazy(() =>
  import(/* webpackChunkName: "Leads" */ "./leads/LeadsDashboardView").then(
    (module) => ({
      default: module.LeadsDashboardView,
    })
  )
);

const DocumentManagementView = lazy(() =>
  import(
    /* webpackChunkName: "DMS" */ "./documents/DocumentManagementView"
  ).then((module) => ({
    default: module.DocumentManagementView,
  }))
);

const NeedHelpView = lazy(() =>
  import(/* webpackChunkName: "NHP" */ "./footer/NeedHelpView").then(
    (module) => ({
      default: module.NeedHelpView,
    })
  )
);

const TermsofUseView = lazy(() =>
  import(/* webpackChunkName: "TOU" */ "./footer/TermsofUseView").then(
    (module) => ({
      default: module.TermsofUseView,
    })
  )
);

const SuppliesDashboardView = lazy(() =>
  import(
    /* webpackChunkName: "Supplies" */ "./supplies/SuppliesDashboardView"
  ).then((module) => ({
    default: module.SuppliesDashboardView,
  }))
);

const AppTopBarView = lazy(() =>
  import(/* webpackChunkName: "AppTopBarView" */ "./AppTopBarView").then(
    (module) => ({
      default: module.AppTopBarView,
    })
  )
);
const ContractSummaryView = lazy(() =>
  import(
    /* webpackChunkName: "ContractSummaryView" */ "./agents/contracts/ContractSummaryView"
  ).then((module) => ({
    default: module.ContractSummaryView,
  }))
);
const PdfViewer = lazy(() =>
  import(/* webpackChunkName: "PdfViewer" */ "./documents/PdfViewer").then(
    (module) => ({
      default: module.PdfViewer,
    })
  )
);
const AppMenu = lazy(() =>
  import(/* webpackChunkName: "AppMenu" */ "./../AppMenu").then((module) => ({
    default: module.AppMenu,
  }))
);

const checkFeatureFlag = async () => {
  try {
    await getFeatureFlag("");
  } catch (err) {
    console.error("Feature Flag: ", err);
  }
};

export const AppView: React.FC<{ vm: AppViewModel }> = observer((props) => {
  checkFeatureFlag();
  let wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": props.vm.layoutMode === "overlay",
    "layout-static": props.vm.layoutMode === "static",
    "layout-static-sidebar-inactive":
      props.vm.staticMenuInactive && props.vm.layoutMode === "static",
    "layout-overlay-sidebar-active":
      props.vm.overlayMenuActive && props.vm.layoutMode === "overlay",
    "layout-mobile-sidebar-active": props.vm.mobileMenuActive,
  });
  var isLeftMenuSlides = wrapperClass.includes("inactive");

  const onMenuItemClick = (event: any) => {
    if (event && event.item) {
      console.log("in app view " + event.index);
    }
  };

  const viewAgentCustomerDetails = (docId: number, type: number) => {
    var agentType = "agents";
    if (type == 2) agentType = "customers";

    sessionStorage.setItem("createlog", "true");
    window.open(
      window.location.origin + "/" + agentType + "/" + docId,
      "_blank"
    );
  };

  const recentProfilesCC = (str: any) => {
    return str
      .split(" ")
      .map(function (word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
      })
      .join("");
  };

  const recentProfiles = () => {
    let items =
      props.vm.recentProfilesDetails &&
      props.vm.recentProfilesDetails.map((item: any) => {
        return (
          <li>
            {item && item.id ? (
              <div className="layout-sidebar-scroll-content-recent-items">
                <div
                  className={
                    item && item.type == 0
                      ? "layout-sidebar-scroll-recent-agent-icon"
                      : item && item.type == 1
                      ? "layout-sidebar-scroll-recent-agency-icon"
                      : item && item.type == 2
                      ? "layout-sidebar-scroll-recent-insured-icon"
                      : ""
                  }
                ></div>
                <div className="layout-sidebar-scroll-content-recent-line-name">
                  <div>
                    <span
                      className="layout-sidebar-scroll-content-recent-hover"
                      onClick={() =>
                        viewAgentCustomerDetails(
                          item ? item.id : 0,
                          item ? item.type : 0
                        )
                      }
                      data-for={"rp" + item.id}
                      data-tip
                    >
                      {item ? recentProfilesCC(item.name) : ""}
                    </span>
                    <ReactTooltip
                      className={"recent-profile-tooltip-parent"}
                      id={"rp" + item.id}
                      place="top"
                      textColor="#fff"
                      backgroundColor="#434a51"
                    >
                      <span>
                        {item && item.type == 0
                          ? "Agent"
                          : item && item.type == 1
                          ? "Agency"
                          : "Insured"}
                      </span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </li>
        );
      });
    return items ? items : "";
  };
  const getCardClass = () => {
    let cardClass = "";
    switch (props.vm.route.name) {
      case routes.home.name:
      case routes.administration.name:
      case routes.reports.name:
      case routes.standardReports.name:
      case routes.customReports.name:
      case routes.reportsQueue.name:
      case routes.addCustomReport.name:
      case routes.editCustomReport.name:
      case routes.previewCustomReport.name:
      case routes.reportsQueueDownload.name:
      case routes.listTeam.name:
      case routes.termsofuse.name:
      case routes.needhelp.name:
      case routes.userAuditReport.name:
        cardClass = "custom-home-card";
        break;
      case routes.addCarrierManagement.name:
      case routes.previewCarrierManagement.name:
      case routes.editCarrierManagement.name:
      case routes.carrierManagement.name:
      case routes.carrierCMList.name:
      case routes.carrierGroupCMList.name:
        cardClass = "CMLayoutAddEditWrapper overflow-initial";
        break;
      case routes.listRole.name:
        cardClass = "role-layout-wrapper";
        break;
      case routes.listUser.name:
      case routes.editUser.name:
      case routes.listNotification.name:
        cardClass = "User-layout-wrapper";
        break;
      case routes.listAnnouncement.name:
        cardClass = "announcement-layout-wrapper";
        break;
      case routes.PreviewNotification.name:
        cardClass = "email-template-layout-wrapper";
        break;
      case routes.ManageAttributes.name:
      case routes.licenseManagement.name:
        cardClass = "manage-attribute-layout-wrapper";
        break;
      case routes.BulkUpload.name:
        cardClass = "contract-bulk-upload-layout-wrapper";
        break;
      case routes.rtsReview.name:
        cardClass = "rts-reveiw-wrapper-main";
        break;
      case routes.bookOfBusiness.name:
        cardClass = "bookOfBusiness-wrapper-main";
        break;
      case routes.goals.name:
        cardClass = "goals-wrapper-main";
        break;
      case routes.universalUpload.name:
        cardClass = "universalUpload-wrapper-main";
        break;
      default:
        cardClass = "homeCard";
        break;
    }
    return cardClass;
  };

  return (
    <>
      {props.vm.isContractSummary ? (
        <>
          <Suspense fallback={<Loading />}>
            <ContractSummaryView vm={props.vm.ContractSummaryViewModel} />
          </Suspense>
        </>
      ) : (
        <>
          {props.vm.isSingleView ? (
            <>
              <Suspense fallback={<> </>}>
                {props.vm.isPdfViewer && (
                  <>
                    <PdfViewer vm={props.vm.pdfViewerModel} />
                    <div className="footer-bottom">
                      {Utils.getCopyRightInfo()}
                    </div>
                  </>
                )}
              </Suspense>
            </>
          ) : (
            <>
              <Suspense fallback={<Loading />}>
                <div
                  className={wrapperClass}
                  onClick={() => {
                    props.vm.onWrapperClick();
                  }}
                >
                  {props.vm.route.name === routes.PreviewNotification.name ? (
                    <></>
                  ) : (
                    <AppTopBarView
                      model={props.vm.breadCrumbMenuItems}
                      profileViewModel={props.vm.AppInlineProfileViewModel}
                      TopHeaderSearchViewModel={
                        props.vm.TopHeaderSearchViewModel
                      }
                      impersonatingUserId={
                        props.vm.getselectedImpersonatingUserId
                      }
                      impersonatedUsers={props.vm.getImpersonatedUsers}
                      notification={props.vm.getNotifications}
                      onToggleMenu={(event: React.MouseEvent) => {
                        props.vm.onToggleMenu();
                        event.preventDefault();
                      }}
                      type={props.vm.getAgentSearchUserProperties}
                      smsListner={props.vm.AppInlineProfileViewModel.smsListner}
                    />
                  )}

                  {Utils.hasUserPermission(
                    Permission.ViewMainNavigationBar
                  ) && (
                    <div
                      className={`${
                        props.vm.route.name === routes.PreviewNotification.name
                          ? "layout-sidebar email-template-layout-wrapper-main"
                          : "layout-sidebar layout-sidebar-scroll-content-recent-down"
                      }`}
                      onClick={() => {}}
                    >
                      <ScrollPanel className="global-sidebar">
                        <div className="layout-sidebar-scroll-content">
                          <Suspense fallback={<Loading />}>
                            <AppMenu
                              model={props.vm.getMenu}
                              menuSlide={props.vm.staticMenuInactive}
                              index={props.vm.menuActiveIndex}
                              onMenuItemClick={onMenuItemClick}
                            />
                          </Suspense>
                          <div className="layout-sidebar-scroll-content-recent-line"></div>
                          <div
                            className="layout-sidebar-scroll-content-recent-main"
                            style={{
                              display:
                                isLeftMenuSlides == true ? "block" : "none",
                            }}
                          >
                            <span
                              className="layout-sidebar-scroll-content-recent"
                              style={{
                                display:
                                  isLeftMenuSlides == true ? "block" : "none",
                              }}
                            >
                              Recently Viewed Profiles
                            </span>
                          </div>
                          <div
                            style={{
                              display:
                                isLeftMenuSlides == true ? "block" : "none",
                            }}
                          >
                            {recentProfiles()}
                          </div>
                        </div>
                      </ScrollPanel>
                    </div>
                  )}
                  {UserContext.userId !== 0 ? <TermsofService /> : ""}
                  <ToastContainer
                    icon={<img height={25} width={25} src={circleChecksvg} />}
                    position={toast.POSITION.TOP_RIGHT}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    theme="light"
                    className={"CMToaster"}
                    autoClose={1000}
                  />
                  <div
                    className={`${
                      props.vm.route.name === routes.previewCustomReport.name ||
                      props.vm.route.name ===
                        routes.reportsQueueDownload.name ||
                      props.vm.route.name === routes.listTeam.name ||
                      props.vm.route.name === routes.userAuditReport.name
                        ? "layout-main"
                        : props.vm.route.name ===
                            routes.addCarrierManagement.name ||
                          props.vm.route.name ===
                            routes.previewCarrierManagement.name ||
                          props.vm.route.name ===
                            routes.editCarrierManagement.name ||
                          props.vm.route.name ===
                            routes.carrierManagement.name ||
                          props.vm.route.name === routes.carrierCMList.name ||
                          props.vm.route.name === routes.carrierGroupCMList.name
                        ? "layout-main CMLayoutAddEditWrapper overflow-x-initial"
                        : "layout-main"
                    }`}
                  >
                    <Card className={`${getCardClass()}`}>
                      {PageContext.isMessageVisible && (
                        <div className="p-grid">
                          <div
                            className={
                              props.vm.route.name === routes.reports.name ||
                              props.vm.route.name ===
                                routes.standardReports.name ||
                              props.vm.route.name ===
                                routes.customReports.name ||
                              props.vm.route.name ===
                                routes.addCustomReport.name ||
                              props.vm.route.name ===
                                routes.reportsQueue.name ||
                              props.vm.route.name ===
                                routes.reportsQueueDownload.name ||
                              props.vm.route.name ===
                                routes.editCustomReport.name ||
                              props.vm.route.name ===
                                routes.userAuditReport.name ||
                              props.vm.route.name ===
                                routes.previewCustomReport.name
                                ? "p-col-12 custom-report-warning"
                                : "p-col-12"
                            }
                          >
                            <IMASMessagesHandler
                              response={PageContext.responseMessage}
                            ></IMASMessagesHandler>
                          </div>
                        </div>
                      )}
                      {PageContext.isLoading && <IMASProgressSpinner />}
                      <IMASErrorView>
                        {Bootstrapper.resolve(props.vm.SelectedViewModel)}
                      </IMASErrorView>
                      {/* <IMASErrorView> */}
                      <React.Suspense fallback={<Loading />}>
                        {(props.vm.route.name === routes.mapsView.name ||
                          props.vm.route.name === routes.agentSearch.name ||
                          props.vm.route.name === routes.emailsView.name ||
                          props.vm.route.name === routes.addAgent.name ||
                          props.vm.route.name === routes.mergeAgents.name ||
                          agentGroup.has(props.vm.route) ||
                          props.vm.route.name ===
                            routes.agentGroupDetails.name ||
                          props.vm.route.name === routes.editAgent.name) && (
                          <AgentsDashboardView
                            vm={props.vm.AgentsDashboardViewModel}
                            loadRecentProfiles={props.vm.getRecentProfiles}
                          />
                        )}
                        {administrationGroup.has(props.vm.route) && (
                          <AdminDashboardView
                            vm={props.vm.AdminDashboardViewModel}
                            updateMenu={props.vm}
                          />
                        )}
                        {(props.vm.route.name === routes.tasks.name ||
                          props.vm.route.name === routes.addMyTask.name ||
                          props.vm.route.name === routes.editMyTask.name) && (
                          <TasksDashboardView
                            vm={props.vm.TasksDashboardViewModel}
                          ></TasksDashboardView>
                        )}
                        {(props.vm.route.name === routes.customerSearch.name ||
                          props.vm.route.name === routes.addCustomer.name ||
                          props.vm.route.name === routes.editCustomer.name ||
                          props.vm.route.name === routes.mergeCustomers.name ||
                          customerGroup.has(props.vm.route)) && (
                          <CustomersDashboardView
                            vm={props.vm.CustomersDashboardViewModel}
                            loadRecentProfiles={props.vm.getRecentProfiles}
                          ></CustomersDashboardView>
                        )}

                        {/* {props.vm.route.name === routes.reports.name && (
                              <ReportsView
                                vm={props.vm.ReportsViewModel}
                              ></ReportsView>
                            )} */}
                        {(props.vm.route.name === routes.reports.name ||
                          props.vm.route.name === routes.standardReports.name ||
                          props.vm.route.name === routes.customReports.name ||
                          props.vm.route.name === routes.reportsQueue.name ||
                          props.vm.route.name ===
                            routes.reportsQueueDownload.name ||
                          props.vm.route.name === routes.addCustomReport.name ||
                          props.vm.route.name ===
                            routes.editCustomReport.name ||
                          props.vm.route.name ===
                            routes.previewCustomReport.name) && (
                          <SelfServiceReportDashboardView
                            vm={props.vm.SelfServiceReportDashboardViewModel}
                          ></SelfServiceReportDashboardView>
                        )}

                        {carrierManagementGroup.has(props.vm.route) && (
                          <CarrierCMDashboardView
                            vm={props.vm.CarrierCMDashboardViewModel}
                          ></CarrierCMDashboardView>
                        )}
                        {props.vm.route.name === routes.rtsReview.name && (
                          <RtsReview vm={props.vm.RtsReviewModel}></RtsReview>
                        )}
                        {props.vm.route.name === routes.bookOfBusiness.name && (
                          <BookOfBusinessView
                            vm={props.vm.BookOfBusinessViewModel}
                          ></BookOfBusinessView>
                        )}
                        {props.vm.route.name === routes.universalUpload.name && (
                          <UniversalUploadView
                            vm={props.vm.UniversalUploadViewModel}
                          ></UniversalUploadView>
                        )}
                        {(props.vm.route.name ===
                          routes.searchWorkflowDocs.name ||
                          props.vm.route.name === routes.docWorkflow.name ||
                          workflowGroup.has(props.vm.route)) && (
                          <DocWorkflowDashboardView
                            vm={props.vm.DocWorkflowDashboardViewModel}
                          ></DocWorkflowDashboardView>
                        )}
                        {props.vm.route.name === routes.goals.name &&
                          Utils.isFeatureEnabled(Features.GOALS) &&
                          <GoalsView vm={props.vm.GoalsViewModel} />}
                        {(props.vm.route.name === routes.leads.name ||
                          props.vm.route.name === routes.addAgentLead.name ||
                          props.vm.route.name === routes.addSaleOpsLead.name ||
                          props.vm.route.name === routes.editSaleOpsLead.name ||
                          props.vm.route.name === routes.editAgentLead.name ||
                          props.vm.route.name ===
                            routes.detailNonContarctedLeads.name ||
                          props.vm.route.name ===
                            routes.detailContarctedLeads.name ||
                          props.vm.route.name ===
                            routes.detailSaleOpsLeads.name ||
                          props.vm.route.name ===
                            routes.detailUnmatchedLeads.name ||
                          props.vm.route.name === routes.salesLeads.name ||
                          props.vm.route.name ===
                            routes.unmatchedLeads.name) && (
                          <LeadsDashboardView
                            vm={props.vm.LeadsDashboardViewModel}
                          ></LeadsDashboardView>
                        )}
                        {props.vm.route.name === routes.documents.name && (
                          <DocumentManagementView
                            vm={props.vm.DocumentManagementViewModel}
                          ></DocumentManagementView>
                        )}

                        {props.vm.route.name === routes.needhelp.name && (
                          <NeedHelpView
                            vm={props.vm.NeedHelpViewModel}
                          ></NeedHelpView>
                        )}

                        {props.vm.route.name === routes.termsofuse.name && (
                          <TermsofUseView
                            vm={props.vm.TermsofUseViewModel}
                          ></TermsofUseView>
                        )}

                        {(props.vm.route.name === routes.supplies.name ||
                          props.vm.route.name ===
                            routes.suppliesOrderDetails.name ||
                          props.vm.route.name ===
                            routes.suppliesAddOrder.name ||
                          props.vm.route.name ===
                            routes.suppliesEditOrder.name) && (
                          <SuppliesDashboardView
                            vm={props.vm.SuppliesDashboardViewModel}
                          ></SuppliesDashboardView>
                        )}
                        {props.vm.route.name === false && (
                          <>
                            <div className="route-not-found">
                              <span>
                                <i className="fa fa-chain-broken fa-5"></i>
                              </span>
                              <h2>
                                <span> 4</span>
                                <span>0</span>
                                <span>4</span>
                              </h2>
                            </div>
                            <div className="view-not-found">
                              {"Page not found"}
                            </div>
                          </>
                        )}
                      </React.Suspense>
                      {/* </IMASErrorView> */}
                    </Card>
                  </div>
                  {props.vm.route.name !== routes.termsofuse.name && (
                    <div className="ic-footer">
                      <div>
                        <img alt="Logo" className={"footer-logo"} />
                      </div>

                      <div className="footer-link">
                        <ul>
                          <li>
                            <a
                              onClick={() => {
                                routes.needhelp.push();
                              }}
                            >
                              {" "}
                              Need Help?{" "}
                            </a>
                          </li>

                          <li>
                            {" "}
                            <a
                              onClick={() => {
                                routes.termsofuse.push();
                              }}
                            >
                              {" "}
                              Terms of Use{" "}
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="footer-copyright">
                        {Utils.getCopyRightInfo()}
                      </div>
                    </div>
                  )}
                </div>
                <div id="tasksSplit"></div>
                <div id="searchWorkflowDropdown"></div>
                <div id="workflowDropdown"></div>
                {props.vm.getNotifications.progressInfo.Total > 0 && (
                  <div id="progressbarcontainer">
                    <IMASProgressBarHandler
                      response={props.vm.getNotifications.progressInfo}
                    ></IMASProgressBarHandler>
                  </div>
                )}
              </Suspense>
            </>
          )}
        </>
      )}
      {props.vm.webChatConfig &&
        props.vm.enableChat &&
        Utils.hasUserPermission(Permission.ViewAskIntegrity) && (
          <div>
            <WebChatView data={props.vm.webChatConfig} />
          </div>
        )}
    </>
  );
});
