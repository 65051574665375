import { observer } from "mobx-react";
import React from "react";
import { Checkbox } from 'primereact/checkbox';


export const IMASCheckbox:
    React.FC<
        {
            label: string,
            value: boolean | undefined,
            id: string,
            disabled: boolean,
            onChange: (e: any) => void
            customLabel?: any
        }> = observer(props => {
            return (
                <>
                    <Checkbox
                        disabled={props.disabled}
                        inputId={props.id}
                        id={props.id}
                        value={props.id}
                        onChange={props.onChange} checked={props.value} />
                        {props.customLabel ? 
                        props.customLabel()
                        :
                    <label className="p-checkbox-label" htmlFor={props.id}>
                        {props.label}
                    </label>
        }

                </>
            )
        });