import { observable, action } from "mobx";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import Collection from "../../../../../infrastructure/CollectionHelper";
import { AddEditSubmissionMethod, SubmissionMethods, TypeConnector } from "../../../../../infrastructure/enum/Submissions";
import { routes } from "../../../../../router";
import { ErrorModel } from "../../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import Utils from "../../../../../infrastructure/Utils";
import { ConnectorType, CreateUpdateSubmissionMethodRequest } from "../../../../../services/BusinessUnitConfigService";
import UserContext from "../../../../../infrastructure/UserContext";

export class SubmissionMethodAddEditViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = AddEditSubmissionMethod.AddSubmission;
  @observable selectedType: string = SubmissionMethods.Agent;
  @observable selectedTypeConnector: string = TypeConnector.Email;
  @observable name: string = "";
  @observable priority: string = ""; 
  @observable priorityTypeConnector: string = "100"; 
  @observable fileName: string = "";
  @observable filePath: string = "";
  @observable selectedDocTypes: any = null;

  @observable docTypesList = new Collection<{
    label: string;
    value: string;
  }>(); 
  @observable carrierIds: any;
  @observable documentTypeIds: any;
  @observable productSubcategoryIds: any;
  @observable productIds: any;
  @observable stateIds: any;
  @observable policyEffectiveDateYearIds: any;
  @observable carriers = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productSubcategoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable stateList = new Collection<{
    label: string;
    value: string;
    isHeader?: boolean;
  }>();
  @observable policyEffectiveDateYearList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isDocTypesVisible: boolean = false;
  @observable isNameVisible: boolean = false;
  @observable isCarriersVisible: boolean = false;
  @observable isPriorityVisible: boolean = false;
  @observable isProdCatVisible: boolean = false;
  @observable isProductVisible: boolean = false;
  @observable isUserNameVisible: boolean = false;
  @observable isStatesVisible: boolean = false;
  @observable isPolicyEffDateVisible: boolean = false;
  @observable isDeleteConfirm: boolean = false;
  @observable public ErrorModel = new ErrorModel(
    new SubmissionMethodAddEditValidator()
  );  
  @observable public ErrorModelTypeConnector = new ErrorModel(
    new SubmissionMethodTypeConnectorAddEditValidator()
  );
  @observable sortColumn: string = "id";
  @observable sortOrder: boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable selectedId: number = 0
  @observable selectedConnectorId: number = 0
  @observable ItemsList =  new Collection<any>()
  @observable submisisonMethodType = new Collection<{
    label: string;
    value: string;
  }>();
  @observable exception: boolean = false
  @observable displayScreen: string = AddEditSubmissionMethod.SubmissionMethod;
  @observable translatedName: string = "";
  @observable value: string = "";
  @observable fileNameFormats: any;
  @observable agentSubmissionParameterizedList: any;
  @observable customerSubmissionParameterizedList: any;
  @observable isEmailAddressType: boolean = false;
  @observable isFromEmailAddress: boolean = false;
  @observable isFtpType: boolean = false;
  @observable isFileNameVisible: boolean = false;
  @observable isFilePathVisible: boolean = false;
  @observable isTranslatedName: boolean = false;
  @observable emailAddressType: string = "";
  @observable filterException: boolean = false;
  @observable emailAddressTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable fromFTPType: string = "";
  @observable fromFTPTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable typeConnectorList = new Collection<any>();
  @observable agentTypeConnectorList = new Collection<any>(); 
  @observable customerTypeConnectorList = new Collection<any>(); 
  @observable fromEmailAddress: string = "";
  @observable fromEmailAddressTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  @action setSelectedConnectorId(id: number){
    this.selectedConnectorId = id
  }

  @action Load = async (id: number) => {
    this.agentTypeConnectorList.values = []
    this.customerTypeConnectorList.values = []
    this.reset();
    if (id !== 0) {
      this.title =AddEditSubmissionMethod.EditSubmission
      this.selectedId = id;
    } else {
      this.title = AddEditSubmissionMethod.AddSubmission
    }
    await this.loadItem()
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });          
      return sortList;
    }
    else {
      return [];
    }
  }

  @action loadItem = async() => {
    var result = await BusinessUnitConfigStore.getSubmissionMethodById(this.selectedId, false)
    if(result){
      this.name = result.name || ''
      this.priority = ''+result.priority || ''     
      this.selectedType = result.type === 0 ?  SubmissionMethods.Agent : SubmissionMethods.Customer
      this.changeType(this.selectedType)       
      this.carriers.values =  this.mapListItemAndSort(result.carriers);
      this.carrierIds = result.companyIds ? result.companyIds.map(String) : []
      this.docTypesList.values =  this.mapListItemAndSort(result.documentTypes);
      this.documentTypeIds = result.documentTypeIds ? result.documentTypeIds.map(String) : []
      this.productSubcategoryList.values =  this.mapListItemAndSort(result.productSubcategories);
      this.productSubcategoryIds = result.productSubcategoryIds ? result.productSubcategoryIds.map(String) : []
      if(this.selectedType === SubmissionMethods.Customer && (this.carrierIds || this.productSubcategoryIds)){
        await this.loadProducts()
      } else {
        this.productList.values =  this.mapListItemAndSort(result.products);
      }
      this.productIds = result.productIds ? result.productIds.map(String) : []
      this.stateList.values =  this.mapListItemAndSort(result.states);
      if (result.states) {
        this.stateList.values = []
        for (let item of result.states) {
            this.stateList.values.push({
                label: "" + item.stateName,
                value: "" + item.stateId,
                isHeader: item.isHeader
            });
        }
      }
      this.stateIds = result.stateIds ? result.stateIds.map(String) : []
      this.policyEffectiveDateYearList.values =  this.mapListItemAndSort(result.policyEffectiveDateYears);
      this.policyEffectiveDateYearIds = result.policyEffectiveDateYearIds ? result.policyEffectiveDateYearIds.map(String) : [] 
      if(result.connectorTypeModel){
        this.agentSubmissionParameterizedList = result.connectorTypeModel.agentSubmissionParameterizedList || []
        this.customerSubmissionParameterizedList = result.connectorTypeModel.customerSubmissionParameterizedList || []
        this.emailAddressTypeList.values =  this.mapListItemAndSort(result.connectorTypeModel.emailTypes || [])
        this.fromFTPTypeList.values =  this.mapListItemAndSort(result.connectorTypeModel.ftpTypes || [])
        this.fromEmailAddressTypeList.values = this.mapListItemAndSort(result.connectorTypeModel.emailSenderTypes || [])
      }
        
      if(result.connectorTypeList && result.connectorTypeList.length > 0){
        var arr: any[] = []
        result.connectorTypeList.forEach((i: any) => {
          arr.push({
            id: ''+i.submissionRulesetConnectorId,
            type: i.submissionType === 0 ? TypeConnector.Email : TypeConnector.FTP,
            emailAddressType: i.submissionType === 0 ? ''+i.submissionTypeId : '0',
            fromEmailAddress: ''+i.submissionSenderTypeEmailId || '0',
            fromFTPType: i.submissionType === 1 ? ''+i.submissionTypeId : '0',
            filePath: i.filePath,
            fileName: i.fileName,
            priorityTypeConnector: i.priority,
            translatedFullPath: i.translatedFullPath,
            value: i.value,
          })
        });
        this.typeConnectorList.values = arr
      } else {
        this.typeConnectorList.values = []
      }
    }
  }

  @action reset = () => {
    this.filterException = false;
    this.isNameVisible = false;
    this.isDocTypesVisible = false;
    this.isCarriersVisible = false;
    this.isPriorityVisible = false;
    this.isProdCatVisible = false;
    this.isProductVisible = false;
    this.isStatesVisible = false;
    this.isPolicyEffDateVisible = false;
    this.selectedType = SubmissionMethods.Agent;
    this.name = "";
    this.priority = '';
    this.title = AddEditSubmissionMethod.AddSubmission;
    this.ResetValidate();
    this.selectedId = 0;
    this.selectedConnectorId = 0;
    this.displayScreen = AddEditSubmissionMethod.SubmissionMethod;
    this.setDeleteTypeConnectorConfirmation(false)
  };

  @action resetTypeConnector = () => {
    this.isEmailAddressType = false;
    this.isFromEmailAddress = false;
    this.isFtpType = false;
    this.isFileNameVisible = false;
    this.isFilePathVisible = false;
    this.isPriorityVisible = false;
    this.selectedTypeConnector = TypeConnector.Email;
    this.fileName = "";
    this.filePath = "";
    this.translatedName = "";
    this.isTranslatedName = false;
    this.ResetValidateTypeConnector();
    this.fromEmailAddress = ''
    this.fromFTPType = ''
    this.emailAddressType = ''
    this.priorityTypeConnector = '100'  
    this.exception = false 
  };

  @action loadFieldsTypeConnector = () => {
    this.ResetValidateTypeConnector();
    if (this.selectedTypeConnector === TypeConnector.Email) {
      this.isEmailAddressType = true;
      this.isFromEmailAddress = true;
      this.isFileNameVisible = true;
      this.isPriorityVisible = true;
      this.isFtpType = false;
      this.isFilePathVisible = false;
    } else {
      this.isEmailAddressType = false;
      this.isFromEmailAddress = false;
      this.isFileNameVisible = true;
      this.isPriorityVisible = true;
      this.isFtpType = true;
      this.isFilePathVisible = true;
    }
  };

  @action loadFields = () => {
    this.ResetValidate();
    if (this.selectedType === SubmissionMethods.Agent) {
      this.isNameVisible = true;
      this.isDocTypesVisible = true;
      this.isCarriersVisible = true;
      this.isPriorityVisible = true;
      this.isProdCatVisible = false;
      this.isProductVisible = false;
      this.isStatesVisible = false;
      this.isPolicyEffDateVisible = false;
    } else {
      this.isNameVisible = true;
      this.isDocTypesVisible = true;
      this.isCarriersVisible = true;
      this.isPriorityVisible = true;
      this.isProdCatVisible = true;
      this.isProductVisible = true;
      this.isStatesVisible = true;
      this.isPolicyEffDateVisible = true;
    }
  };

  @action setAgentCustomerTypeList = async() => {
    if(this.selectedType === SubmissionMethods.Agent){
      this.customerTypeConnectorList = this.typeConnectorList || []
      this.typeConnectorList = this.agentTypeConnectorList || []
    } else {
      this.agentTypeConnectorList = this.typeConnectorList || []
      this.typeConnectorList = this.customerTypeConnectorList || []
    }
  }

  @action setName = (value: string) => {
    this.name = value;
  };

  @action setPriority = (value: string) => {
    this.priority = value;
  };

  @action setPriorityTypeConnector = (value: string) => {
    this.priorityTypeConnector = value;
  };

  @action changeType = (value: string) => {
    this.selectedType = value;
    this.loadFields();
  };

  @action changeTypeConnector = (value: string) => {
    this.selectedTypeConnector = value;
    this.loadFieldsTypeConnector();
    this.translatedName = ""
    this.setIsTranslatedName(false)
  };

  @action onCancel = () => {
    this.typeConnectorList.values = []
    routes.submissionMethod.push();
  };

  @action onSave = async () => {
    try {
      this.filterException = false
      if (await this.Validate()) {
        return false;
      }
      if(this.selectedType === SubmissionMethods.Agent){
        if(this.carrierIds.length === 0 && this.documentTypeIds.length === 0){
          this.filterException = true
          return false
        }
      }
      else if(this.selectedType === SubmissionMethods.Customer){
        if(this.carrierIds.length === 0 && this.documentTypeIds.length === 0 && this.productSubcategoryIds.length === 0 && this.productIds.length === 0 && this.stateIds.length === 0 && this.policyEffectiveDateYearIds.length === 0){
          this.filterException = true
          return false
        } 
      }
      let dto = this.serviceModel();
      if(this.title === AddEditSubmissionMethod.AddSubmission){
        await BusinessUnitConfigStore.addSubmissionMethod(dto)
      } else {
        await BusinessUnitConfigStore.updateSubmissionMethod(dto)
      }
      this.onCancel()
    } catch (e) {}
  };
  protected serviceModel() {
    var dto: CreateUpdateSubmissionMethodRequest = {
      id: this.selectedId,
      name: this.name,
      type: this.selectedType === SubmissionMethods.Agent ? 0 : 1,
      companyIds: this.carrierIds ? this.carrierIds.map((i: any)=>Number(i)) : [],
      documentTypeIds:this.documentTypeIds ? this.documentTypeIds.map((i: any)=>Number(i)) : [],
      productIds: this.productIds && this.selectedType === SubmissionMethods.Customer ? this.productIds.map((i: any)=>Number(i)) : [],
      productSubcategoryIds: this.productSubcategoryIds && this.selectedType === SubmissionMethods.Customer ? this.productSubcategoryIds.map((i: any)=>Number(i)) : [],
      stateIds: this.stateIds && this.selectedType === SubmissionMethods.Customer ? this.stateIds.map((i: any)=>Number(i)) : [],
      policyEffectiveDateYearIds: this.policyEffectiveDateYearIds && this.selectedType === SubmissionMethods.Customer ? this.policyEffectiveDateYearIds.map((i: any)=>Number(i)) : [],
      createdModifiedByUserId: UserContext.getUserId(),
      priority: +this.priority || 0,
      connectorTypeList: this.getconnectorTypeList() || []
    }
    return dto;
  }

  @action getconnectorTypeList = () => {
    let results: any[] = []
    var dto_tc: ConnectorType = {}      
        if(this.typeConnectorList.values.length > 0){
          this.typeConnectorList.values.forEach((i: any) => {
              dto_tc = {
                submissionRulesetId: this.selectedId,
                submissionType: i.type === TypeConnector.Email ? 0 : 1,
                connectorTypeId: i.id.toString().includes("TC") ? 0 : +i.id,
                submissionTypeEmailId: +i.emailAddressType,
                submissionSenderTypeEmailId: +i.fromEmailAddress,
                submissionTypeFTPId: +i.fromFTPType,
                fileName: i.fileName,
                filePath: i.filePath,
                priority: +i.priorityTypeConnector,
                createdModifiedByUserId: UserContext.getUserId()
              } 
              results.push(dto_tc) 
            }
          );
        }
        return results
  }
 
  @action public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @action public ValidateTypeConnector = async () => {
    return this.ErrorModelTypeConnector.Validate(this);
  };

  @action public ResetValidateTypeConnector = async () => {
    return this.ErrorModelTypeConnector.ResetValidation(this);
  };

  @action addEditTypeConnector = (action: string) => {
    this.resetTypeConnector();
    this.displayScreen = AddEditSubmissionMethod.TypeConnector
    if(action === AddEditSubmissionMethod.add){
        this.setSelectedConnectorId(0)
        this.selectedTypeConnector = TypeConnector.Email;
        this.title = AddEditSubmissionMethod.AddTypeConnector
    } else {
      this.title = AddEditSubmissionMethod.EditTypeConnector
      this.loadConnectedTypeItem()
    }
    this.changeTypeConnector(this.selectedTypeConnector)
    this.fileNameFormats = this.selectedType === SubmissionMethods.Agent ? this.agentSubmissionParameterizedList : this.customerSubmissionParameterizedList
  }

  @action loadConnectedTypeItem = () => {
    var tc = this.typeConnectorList.values.filter((ind) => ind.id === this.selectedConnectorId)
    if (tc.length === 1){
      this.selectedTypeConnector = tc[0].type
      this.emailAddressType = tc[0].emailAddressType
      this.fromEmailAddress = tc[0].fromEmailAddress
      this.fromFTPType = tc[0].fromFTPType
      this.filePath = tc[0].filePath
      this.fileName = tc[0].fileName || ""
      this.priorityTypeConnector = tc[0].priorityTypeConnector
      this.translatedName =  tc[0].translatedFullPath
      this.value = tc[0].value
    }
  }

  @action onCancelTypeConnector = () => {
    this.title = this.selectedId === 0 ? AddEditSubmissionMethod.AddSubmission : AddEditSubmissionMethod.EditSubmission
    this.displayScreen = AddEditSubmissionMethod.SubmissionMethod
  } 

  @action onSaveTypeConnector = async() => {
    try {
      if (await this.ValidateTypeConnector()) {
        return false;
      }
      if(this.title === AddEditSubmissionMethod.AddTypeConnector){
        // Add type connector
        this.typeConnectorList.values.push({
          id: 'TC'+new Date().getMilliseconds(),
          type: this.selectedTypeConnector,
          emailAddressType: this.emailAddressType,
          fromEmailAddress: this.fromEmailAddress,
          fromFTPType: this.fromFTPType,
          filePath: this.filePath,
          fileName: this.fileName,
          priorityTypeConnector: this.priorityTypeConnector,
          translatedFullPath: this.translatedName,
          value: this.getValue(),
        })
      } else {
        // Update type connector
        var tc = this.typeConnectorList.values.filter((ind) => ind.id === this.selectedConnectorId)
        if (tc.length === 1){
          tc[0].type = this.selectedTypeConnector
          tc[0].emailAddressType =  this.emailAddressType 
          tc[0].fromEmailAddress = this.fromEmailAddress 
          tc[0].fromFTPType = this.fromFTPType
          tc[0].filePath = this.filePath
          tc[0].fileName = this.fileName
          tc[0].priorityTypeConnector =  this.priorityTypeConnector
          tc[0].translatedFullPath = this.translatedName 
          tc[0].value =  this.getValue()
        }        
      }
      this.onCancelTypeConnector()
      this.resetTypeConnector()
    } catch (e) {}
  }

  @action translateFile = async() => {
    if(this.fileName){
      this.exception = false
      var result = await BusinessUnitConfigStore.getTranslatedFullFilePath(this.selectedType === SubmissionMethods.Agent ? 0 : 1, this.fileName, this.filePath)
      if(result){
        this.translatedName = result.translatedText || ""
      }
    } else {
      this.exception = true
      this.setIsTranslatedName(false)
    }
  }

  @action getValue = () => {
    let temp = [];
    if(this.selectedTypeConnector === TypeConnector.Email){
      temp = this.emailAddressTypeList.values.filter((ind) => ind.value === this.emailAddressType)
    } else {
      temp = this.fromFTPTypeList.values.filter((ind) => ind.value === this.fromFTPType)
    }
    return temp.length > 0 ? (temp[0].label).split('|')[1] : ""
  }

  @action loadProducts = async() => {
    var result = await BusinessUnitConfigStore.getProductsByCarrierId(this.carrierIds ? this.carrierIds.map((i: any)=>Number(i)) :[], this.productSubcategoryIds ? this.productSubcategoryIds.map((i: any)=>Number(i)) : [])
    if(result){
      this.productList.values = this.mapListItemAndSort(result) || []
    }
  }

  @action deleteTypeConnector = () => {
    this.typeConnectorList.values = this.typeConnectorList.values.filter((ind) => ind.id !== this.selectedConnectorId)
    this.setDeleteTypeConnectorConfirmation(false)
  }

  @action setFileName = (value: string) => {
    this.fileName = value;
  };
  @action setFilePath = (value: string) => {
    this.filePath = value;
  };

  @action setSelectedDocTypes = (value: any) => {
    this.selectedDocTypes = value;
  };

  @action setIsTranslatedName = (value: boolean) => {
    this.isTranslatedName = value;
  };

  @action setDeleteTypeConnectorConfirmation = (value: boolean) => {
    this.isDeleteConfirm = value
  }
}

class SubmissionMethodAddEditValidator extends AbstractValidator<SubmissionMethodAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.name)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
    this.validateIfString((input) => input.priority)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Priority is required");
  }
}

class SubmissionMethodTypeConnectorAddEditValidator extends AbstractValidator<SubmissionMethodAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.priorityTypeConnector)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Priority is required");
    this.validateIfString((input) => input.fileName)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("File Name is required");
    this.validateIfString((input) => input.emailAddressType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("To Email Address is required").when((input) => input.selectedTypeConnector === TypeConnector.Email)
    this.validateIfString((input) => input.fromEmailAddress)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("From Email Address is required").when((input) => input.selectedTypeConnector === TypeConnector.Email)
    this.validateIfString((input) => input.fromFTPType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("FTP Type Address is required").when((input) => input.selectedTypeConnector === TypeConnector.FTP)
  }
}
