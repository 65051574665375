import { AbstractValidator } from "fluent-ts-validator";
import { observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import Utils from "../../../infrastructure/Utils";
import { getCurrentRoute, routes } from "../../../router";
import { CreateUpdateSuppliesRequest } from "../../../services/SupplyOrderService";
import SupplyOrderStore from "../../../store/SupplyOrderStore";

import { BaseAddEditViewModel } from "../../components/BaseAddEditViewModel";

export class SuppliesAddEditViewModel extends BaseAddEditViewModel {
  @observable defaultCarrier: string = "";
  @observable defaultCertDate: Date | undefined = undefined;
  @observable selectedProductType: string = "";
  @observable item: string = "";
  @observable itemDescription: string = "";
  @observable selectedCarrier: string = "";
  @observable selectedStatus: string = "";
  @observable selectedStates: any = null;
  @observable onOrderCount: number = 0;

  @observable productTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statusList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statesList = new Collection<{
    label: string;
    value: string;
    abbreviation?: string;
    isHeader?: boolean;
  }>();

  constructor() {    
    super("Supplies", routes.listAdminSupplies, new SuppliesAddEditValidator());
  }

  protected resetModel = async () => {
    this.companyList.values = [];
    this.productTypeList.values = [];
    this.statesList.values = [];
    this.statusList.values = [];
    this.selectedStates = [];
    var result = await SupplyOrderStore.getSuppliesById(this.selectedId);
    var defaultItem = { label: "Select", value: "0" };

    this.companyList.values = Utils.mapListItem(result.carriers);
    this.productTypeList.values = Utils.mapListItem(result.productTypes);
    if (result.states) {
      for (let item of result.states) {
          this.statesList.values.push({
              label: "" + item.stateName,
              value: "" + item.stateId,
              abbreviation: '' + item.abbreviation,
              isHeader: item.isHeader
          });
      }
  }
    // this.statesList.values = Utils.mapListItem(result.states);
    this.statusList.values = Utils.mapListItem(result.statusList);
    this.companyList.values.splice(0, 0, defaultItem);
    this.productTypeList.values.splice(0, 0, defaultItem);
    this.selectedCarrier = result.carrierId ? result.carrierId.toString() : "0";
    this.selectedProductType = result.productTypeId ? result.productTypeId.toString() : "0";
    this.selectedStatus = result.isActive ? "1" : "0";
    result.stateIds && result.stateIds.forEach((element:any) => {
      this.selectedStates.push(element.toString());
    });
    this.item = result.supplyItem ?  result.supplyItem : "";
    this.itemDescription = result.supplyDescription ? result.supplyDescription : "";
    this.onOrderCount = result.onOrderCount ? result.onOrderCount : 0;
  };

  protected toServiceModel() {
    var dto: CreateUpdateSuppliesRequest = {
      id: this.selectedId,
      itemNumber: this.item,
      itemDescription: this.itemDescription,
      productTypeId: isNaN(parseInt(this.selectedProductType)) ? 0 : parseInt(this.selectedProductType),
      companyId: isNaN(parseInt(this.selectedCarrier)) ? 0 : parseInt(this.selectedCarrier),
      active: this.selectedStatus === "1" ? true : false,
      stateIds: this.selectedStates,
      onOrderCount: this.onOrderCount
    };
    return dto;
  }

  protected async loadItem(id: number): Promise<void> {
    let route = getCurrentRoute();
    if(route.name === routes.cloneAdminSupplies.name)
    {
      this.pageTitle = 'Clone Supplies';
    }

    if (id === 0) {
      this.resetModel();
    }
  }

  protected async addItem(): Promise<void> {
    var item = this.toServiceModel();
    await SupplyOrderStore.addSupplies(item);
  }
  protected async updateItem(): Promise<void> {
    var item = this.toServiceModel();
    let route = getCurrentRoute();
    if(route.name === routes.cloneAdminSupplies.name) {
      item.id = 0;
      await SupplyOrderStore.addSupplies(item);
    } else {
      await SupplyOrderStore.updateSupplies(item);
    }
    
  }
}

class SuppliesAddEditValidator extends AbstractValidator<SuppliesAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedCarrier)
      .isNotEqualTo("0")
      .isNotEmpty()
      .withFailureMessage("Carrier is required");

    this.validateIfString((input) => input.selectedProductType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Product Type is required");

      this.validateIfString((input) => input.item)
      .isNotEmpty()
      .isNotEqualTo("")
      .withFailureMessage("Item is required");

      this.validateIfString((input) => input.item)
      .hasMaxLength(100)
      .withFailureMessage("Item should not be greater than 100 characters");

      this.validateIfString((input) => input.itemDescription)
      .isNotEmpty()
      .isNotEqualTo("")
      .withFailureMessage("Item description is required");

      //this.validateIfString((input) => input.itemDescription)
      //.hasMaxLength(500)
      //.withFailureMessage("Item description should not be greater than 500 characters")

      this.validateIf((input) => input.onOrderCount)
      .isNotEmpty()
      .withFailureMessage("On order is required");
  }
}
