import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";

import RouteList from "../../../infrastructure/RouteList";
import Collection from "../../../infrastructure/CollectionHelper";
import ReportStore from "../../../infrastructure/ReportModel"; 
import { ReportFile, FileType2 } from "../../../services/AccountingService"; 
import Utils from "../../../infrastructure/Utils";
import { Permission } from '../../../infrastructure/enum/Permission';
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import { AgentListModel } from "../../../services/ProducerSearchService";
import UserContext from "../../../infrastructure/UserContext";
import PageContext from "../../../infrastructure/PageContext";
import { AppMenuItems } from "../../../infrastructure/enum/Common";
import { ActionLogModel } from "../../../services/IdentityService";
import IdentityStore from "../../../store/IdentityStore";
import AccountingAdjustmentStore from "../../../store/AccountingAdjustmentStore"; 


export class TurninreportModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {   
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.setControlsBasedOnSelection()
    this.isLoading = true;   
    let delay = 0; 
    if(UserContext.permissions.length <= 0)
        {
            delay = 0;
        }
        // setTimeout(() => {
          if (Utils.hasUserPermission(Permission.ViewStandardReports)) {
            this.isReportNotVisible = false;
            this.selectedReportType = "";
            this.getReportTypes();
            this.visibleWhenReportType = false;
            this.resetValues();
            this.resetControlVisibility();
            Utils.hasUserPermission(Permission.ChangeMarketerParameter)
              ? (this.changeMarketerParameter = true)
              : (this.changeMarketerParameter = false);
          } else if (
            UserContext.permissions.length > 0 &&
            !Utils.hasUserPermission(Permission.ViewStandardReports)
          ) {
            this.isReportNotVisible = true;
          }
        // }, delay);
    this.isLoading = false;
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };

  @observable companies = [{ label: "All", value: "0" }];
  @observable isLoading: boolean = false; 
  @observable isExcel: boolean = true;
  @observable hidePrivateNotes: boolean = false;
  @observable hideOverRides: boolean = false;
  @observable title: string = "Report Viewer";
  @observable selectedFormat: string = "Excel"; 
  @observable UTCisoDateString: Date = new Date(); 
  @observable turnInstartDate: Date = new Date();
  @observable turnInendDate: Date = new Date();
  @observable visibleWhenReportType: boolean = false;
  //added
  @observable isAblReport:boolean = false;
  @observable FilteredUplineList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable startDateErr: boolean = false;
  @observable endDateErr: boolean = false;
  @observable agentErr: boolean = false;
  @observable formatList = [
    { label: "Excel", value: "0" },
    { label: "PDF", value: "1" },
  ];

  @observable showWarning: boolean = false;
  @observable isReportNotVisible: boolean = false;
  @observable changeMarketerParameter: boolean = false;
  @observable isMessageVisible: boolean = false;
  @observable response: any;
  @observable pageEventPrevention: boolean = true;
  @observable isReportAlertVisible: boolean = false;
  @observable reportAlertMessage: string = "";
  @observable showUserError: boolean = false;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }
   
  // ========drop-down value bindings==========
  @observable reportTypes = new Collection<{
    label: string;
    value: string;
  }>(); 
  @observable monthList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable yearList = new Collection<{
    label: string;
    value: string;
  }>();
    
  @observable selectedMonth: string = "0";
  @observable selectedYear: string = "0";
  @observable selectedReportType: string = "";
  @observable SelectedReportFromDropDown: string  = "";
  @observable selectedAgent: string = "";
  @observable selectedAgentId: number = 0;
  @observable isMonth: boolean  = false;
  @observable isYear: boolean  = false;
  @observable isAgent: boolean = false;

  @action resetControlVisibility = () => {
    this.isAgent = false;
    this.isMonth = false;
    this.isYear = false;
  };

  @action resetValues = () => {
    this.selectedFormat = "Excel";
    this.selectedAgentId = 0;
    this.selectedAgent = ""; 
    this.hidePrivateNotes = false;
    this.hideOverRides = false;
    this.startDateErr = false;
    this.endDateErr = false;
    this.FilteredAgentsList.values = [{ id: 0 }];
    this.FilteredAgentsList.values = [{ id: 0 }]; 
    this.showWarning = false;
    this.isReportNotVisible = false;
    this.changeMarketerParameter = false;
    this.isMessageVisible = false;
    this.response = "";
  };
 
  @action bindAllDropdowns = async () => {
    this.isLoading = true;
    this.getReportTypes(); 
    this.isLoading = false;
  };

  @action getReportTypes = async () => {
    let res = await ReportStore.getReportTypes(); 
    if (res) this.reportTypes.values = res;
    else this.reportTypes.values = [{ label: `No results`, value: "" }];
  };
   
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    }
    else {
      return [];
    }
  }
 
  @action fillterUsers = async (value: string) => {
    try {
      let result = await ProducerSearchStore.getAgentsLookup(1,
        value,
        0,
        100,
        undefined,
        undefined
      );
      var values = null;
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            values = result.data;
          }
        }
      }
    }
    catch (e) { }

    if (values !== undefined && values !== null) {
      this.FilteredUplineList.values = values;
    } else {
      var noAgent: AgentListModel = { id: 0 };
      this.FilteredUplineList.values = [noAgent];
    }
  };
 
  @action setAgent(value: string) {
    this.selectedAgent = value;
  }

  @action setAgentId(value: number) {
    this.selectedAgentId = value;
  }

  @action searchAgents = async (value: string) => {
    var values = null;
    try {

      let result = await ProducerSearchStore.getAgentsLookup(1,
        value,
        0,
        100,
        undefined,
        undefined
      );
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            values = result.data;
          }
        }
      }
    } catch (e) {

    }

    if (values !== undefined && values !== null) {
      this.FilteredAgentsList.values = values;
    } else {
      this.noPrincipalAgents();
    }
  };
  @action noPrincipalAgents() {
    var noAgent: AgentListModel = { id: 0 };
    this.FilteredAgentsList.values = [noAgent];
  }
 
  @action setSelectedFormat = (value: any) => {
    this.selectedFormat = value;
  };
  @action setReportType = (value: string) => { 
    this.selectedReportType = value;
  };
 
  @action setMonth(value: string) {
    this.selectedMonth = value;
  }
  
  @action setYear(value: string) {
    this.selectedYear = value;
    this.validateMonthMCS();
  }
  @action validateDates = (val: string) => {
    if (this.turnInstartDate > this.turnInendDate) {
      if (val === "start") {
        this.startDateErr = true;
        this.endDateErr = false;
      }
      if (val === "end") {
        this.endDateErr = true;
        this.startDateErr = false;
      }
    } else {
      this.startDateErr = false;
      this.endDateErr = false;
    }
  };

  @action setStartDate = (value: Date) => {
    this.turnInstartDate = value; 
  };
  @action setEndDate = (value: Date) => {
    this.turnInendDate = value; 
  };
  @action validateMonthMCS(){
        const d = new Date();
        let month = d.getMonth();
        let selectedMonthvalidation = (new Date()).getMonth()+2
        if(this.selectedYear == `${d.getFullYear()}`){ 
          let abc = this.monthList.values.filter(item1 => (parseInt(item1.value) < selectedMonthvalidation));
          this.monthList.values = abc;
          this.selectedMonth = `${month + 1}`;
        }else{
        //   this.getMonthList()
        }
  }  
  @action displayMessage(message: string) {
    this.reportAlertMessage = message;
    this.isReportAlertVisible = true;
  }
  @action hideMessage() {
    this.isReportAlertVisible = false;
  }

  @observable setControlsBasedOnSelection = async () => {
    this.isLoading = true;   
    this.resetControlVisibility();
    this.resetValues(); 
    this.hideMessage(); 
        const d = new Date();
        let month = d.getMonth();
        this.selectedMonth = `${month + 1}`;
        if(this.selectedMonth == '12'){
          this.selectedYear = `${d.getFullYear()-1}`
        }else{
          this.selectedYear = `${d.getFullYear()}`
        }
        this.validateMonthMCS()
        this.isMonth = true;
        this.isYear = true;
        this.isAgent = true; 
    this.isLoading = false;  
    this.visibleWhenReportType = true;
  };
 
  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @action hasAnySelection = (selectedList: any) => {
    return (selectedList && selectedList.length > 0);
  }

  @action showCreatingReport() {
    this.hideMessage();
    this.isLoading = true;
    this.isMessageVisible = false;
    this.response = "";
  }
  @action hideCreatingReport() {
    this.isLoading = false;
  }

  @action createReport = async () => {   
    
    var fileType: any;  
    this.showCreatingReport();
    try {
      
        const d = new Date();
        let hour = d.getHours();

        let UTCStartDate = new Date(this.turnInstartDate).setHours(hour)
        let UTCStartisoDateString = new Date(UTCStartDate).toISOString();
        var UTCStartDateFormatted = new Date(UTCStartisoDateString);

        let UTCEndDate = new Date(this.turnInendDate).setHours(hour)
        let UTCEndisoDateString = new Date(UTCEndDate).toISOString();
        var UTCEndDateFormatted = new Date(UTCEndisoDateString);

        if (this.selectedAgent === "") {
            this.showUserError = true;
            this.agentErr = true;
        }

        else {
            this.showUserError = false;
            this.agentErr = false;
        }

        if (!this.startDateErr && !this.endDateErr && !this.agentErr) {
              fileType = this.selectedFormat === "Excel" ? 1 : 0;
            let res = await AccountingAdjustmentStore.turnInReport(
                  this.selectedAgentId, UTCStartDateFormatted, UTCEndDateFormatted,
                  fileType
              );
              this.downloadReport(res, "TurnIn");
          }
    }
    catch (e) {
        console.error(e); // don't just swallow the inner exception
        console.log("error log",  e); // don't just swallow the inner exception
      // Handling exception locally instead of relying on Interceptor, interceptor messaging not working in Prod
      PageContext.setModuleName(AppMenuItems.Reports);
      PageContext.setResponseMessage(JSON.stringify(PageContext.getTimeOutResponse()), false, true);
      PageContext.setIsMessageVisible(true);  
    }

    this.hideCreatingReport();
  };

  @action actionLog = (key: any) => {
    var dto: ActionLogModel = {
        actionId: 42,
        entityId: 0,
        userId:  UserContext.getUserId(),
        notes: "Dropdown selected value "+ key
    }
   IdentityStore.createActionLog(dto)
  }
}