import { action } from "mobx";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  Client as ReportServiceClient,
  ReportCloneRequest,
  ReportModel,
  ReportPreviewRequest,
} from "../services/ReportService";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import UserContext from "../infrastructure/UserContext";
import Utils from "../infrastructure/Utils";

class ReportStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): ReportServiceClient => {
    return ServiceClient.getClient(ServiceType.Report);
  };
  getHostname = () => {
    return Utils.getServiceUrlByEnvironment();
  };

  @action getAllCustomReportList = async (
    fullTextFilter: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      this.getHostname()
    );
    return result;
  };
  @action getAllCustomReportQueueList = async (
    fullTextFilter: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllReportQueued(
      this.getApiVersion(),
      UserContext.getUserId(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      this.getHostname()
    );
    return result;
  };

  @action getAllEntityDefinition = async (entityId: number) => {
    var result = await this.getClient().getAllEntityDefinition(
      this.getApiVersion(),
      entityId,
      UserContext.getUserId(),
      this.getHostname()
    );
    return result;
  };

  @action entityTypeDropdownValues = async () => {
    var result = await this.getClient().entityTypeDropdownValues(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
    return result;
  };

  @action deleteReport = async (reportId: number) => {
    var result = await this.getClient().deleteReport(
      reportId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action cloneReport = async (reportId: number, dto: ReportCloneRequest) => {
    var result = await this.getClient().cloneReport(
      reportId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action previewReport = async (dto: ReportPreviewRequest) => {
    var result = await this.getClient().previewReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action createReport = async (dto: ReportModel) => {
    var result = await this.getClient().createReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action updateReport = async (dto: ReportModel) => {
    var result = await this.getClient().updateReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action getReportById = async (reportId: number) => {
    var result = await this.getClient().getReportById(
      reportId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  filterTimer: any;
  @action getFilterValues = async (
    entityColumnId?: number | undefined,
    filterValue?: string
  ) => {
    var result = await this.getClient().getFilterValues(
      this.getApiVersion(),
      entityColumnId,
      filterValue,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getFilterGridValues = async (
    entityColumnId?: number | undefined,
    filterValue?: string
  ) => {
    var result = await this.getClient().getFilterGridValues(
      this.getApiVersion(),
      entityColumnId,
      filterValue,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action downloadCustomReport = async (
    reportId: number,
    dto: ReportPreviewRequest
  ) => {
    var result = await this.getClient().downloadCustomReportWithBackGround(
      reportId,
      this.getApiVersion(),
      0,
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action downloadAttachment = async (downloadReportId: number) => {
    var result = await this.getClient().downloadReportAttachment(
      this.getApiVersion(),
      downloadReportId,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getAllReportStatus = async () => {
    var result = await this.getClient().getAllReportStatus(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
}

export default new ReportStore();
