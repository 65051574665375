import { action } from "mobx";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import {
  Client as ContractServiceClient,
  MarketerMailConfigModel,
} from "../services/ContractService";

class MarketerMailConfigurationStore {
  private loaded = false;

  getApiVersion = () => {
    return "1";
  };
  getClient = (): ContractServiceClient => {
    return ServiceClient.getClient(ServiceType.Contract);
  };

  @action
  public loadMarketerMailConfigurationList = async (
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | null | undefined
  ) => {
    var result = this.getClient().getAllMarketerMailConfigs(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.userId
    );

    return result;
  };

  @action
  public addMarketerMailConfiguration = async (
    dto: MarketerMailConfigModel
  ) => {
    var result = this.getClient().addMarketerMailConfig(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );

    return result;
  };

  @action
  public editMarketerMailConfiguration = async (
    dto: MarketerMailConfigModel
  ) => {
    var result = this.getClient().updateMarketerMailConfig(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };
}

export default new MarketerMailConfigurationStore();
