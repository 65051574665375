// mobx
import { observable, action } from "mobx";
// ViewModel
import ViewModel from "../../infrastructure/ViewModel";
// route
import RouteList from "../../infrastructure/RouteList";
import { Constants } from "../../infrastructure/enum/Constants";
import Collection from "../../infrastructure/CollectionHelper";
import { CreditCheckHistoryListModel } from "../../services/AccountingService";
import AccountingStore from "../../store/AccountingStore";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { routes } from "../../router";
export class CreditCheckHistoryViewModel implements ViewModel {
  // ViewModel functions implementation
  get CanClose(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get CanRoute(): boolean {
    return true
  }
  get IsLoaded(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoading(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  Close(): void {
    throw new Error(Constants.NoMethodImplementation);
  }
  @observable response: any;
  @observable agentName: string = '';
  @observable
  creditCheckHistoryList = new Collection<CreditCheckHistoryListModel>();
  @observable isMessageVisible: boolean = false;
  @observable pdfData: string = "";
  @observable agentId: number = 0;
  @action Load = async (agentId: number) => {
      this.agentName = 'Return to '+RouteParamDetails.firstName;
      this.agentId = agentId;
      await this.getCreditCheckHistory(agentId);
  };
  @action returnToAgent()
  {
    routes.agentDetails.replace({
        agentId: "" + this.agentId
      });
  }
  @action getCreditCheckHistory = async (agentId: number) => {
    try {
      this.isMessageVisible = false;
      var result = await AccountingStore.getCreditCheckHistory(
        agentId,
        0,
        1000
      );
      if (result.data) {
        this.creditCheckHistoryList.values = result.data;
      }
    } catch (e) {
      this.isMessageVisible = true;
    }
  };

  // declaration for route
  Route = async (currentRoute: RouteList): Promise<void> => {};
}
