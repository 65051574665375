import { observable, action } from "mobx";
import { routes } from "../../../../router";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import CommissionStore from "../../../../store/CommissionStore";
import Utils from "../../../../infrastructure/Utils";
import Collection from "../../../../infrastructure/CollectionHelper";
import { BackOutAllCommissionsRequest, BackOutSeletedCommissionsRequest, CommissionsForBackOutListModel } from "../../../../services/CommissionService";
import PageContext from "../../../../infrastructure/PageContext";
import { AgentListModel } from "../../../../services/ProducerSearchService";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import UserContext from "../../../../infrastructure/UserContext";
import { googleTagManager } from "../../../../infrastructure/tracking";

export class RenewalBackoutViewModel implements ViewModel  {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @observable payDate:  Date | undefined = undefined;
    @observable companyId: string = '';
    @observable companyList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable payToAgentID: string = '';
    @observable agentList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable policyNumber: string = '';
    @observable policyList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable sortColumn: string = 'id'
    @observable sortOrder: boolean = false;
    @observable pageIndex: number = 0;
    @observable pageSize: number = 500;
    @observable renewalBackoutList = new Collection<CommissionsForBackOutListModel>();
    @observable totalRecords: number = 100;
    @observable first: number = 0;
    @observable startIndex: number = 0;
    @observable sortColumnOrder: string = "id";
    @observable selectedRenewalBackout: any = '';
    @observable backoutConfirm: boolean = false;
    @observable selectedAgent: string = "";
    @observable FilteredAgentsList = new Collection<any>();
    @observable selectedPolicy: string = "0";
    @observable selectedPolicyName: string = ""
    @observable FilteredPoliciesList = new Collection<any>();
    @observable toolTipImage = "assets/layout/images/tooltip.png";

    @observable
    public ErrorModel = new ErrorModel(new RenewalBackoutValidator());

    @action setSelectedAgentId(id: any) {
        this.payToAgentID = id;
    }

    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }

    @action
    public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action setSelectedAgent(value: string) {
        this.selectedAgent = value;
        if(!value){
            this.payToAgentID = "0";
        }
    }

    @action searchAgents = async (value: string) => {
        if(value && value.toLowerCase() !== "all") {
            let result = await CommissionStore.getAgentLookup(
                value,
                +this.companyId,
                this.payDate || new Date()
            );
            var noAgent: AgentListModel = { id: 0 };
            if (result !== null) {
                if (result.length > 0) {
                    if (result) {
                        this.FilteredAgentsList.values = result;
                    }
                } else {
                    this.FilteredAgentsList.values = [noAgent];
                    this.selectedAgent = "";
                }
            } else {
                this.FilteredAgentsList.values = [noAgent];
                this.selectedAgent = "";
            }
        }
    };

    @action setSelectedPolicyId(id: any) {
        this.selectedPolicy = id;
    }

    @action setSelectedPolicy(value: string) {
        this.selectedPolicyName = value;
        if(!value){
            this.selectedPolicy = "0";
        }
    }

    @action reset = async() => {
        await this.ResetValidate()
        this.payDate = undefined
        this.companyList.values = []
        this.companyId = ""
        this.payToAgentID = "0"
        this.selectedAgent = ""
        this.selectedPolicy = "0"
        this.selectedPolicyName = ""
        this.renewalBackoutList.values = []
        this.pageSize = 500
    }
    @action resetBackOutFilterData = () => {
        if (!this.selectedAgent && !this.selectedPolicyName) {
            this.payToAgentID = "0"
            this.selectedAgent = ""
            this.selectedPolicy = "0"
            this.selectedPolicyName = ""
            this.renewalBackoutList.values = []
            this.pageSize = 500
        }
        if (+this.payToAgentID > 0 && !this.selectedPolicyName) {
            this.loadRenewalBackout();
        }
    }

    @action searchPolicies = async (value: string) => {
        if(value && value.toLowerCase() !== "all") {
            let result = await CommissionStore.getPolicyNumberLookup(
                value,
                +this.companyId,
                +this.payToAgentID,
                this.payDate || new Date()
            );
            var noAgent: AgentListModel = { id: 0 };
            if (result !== null) {
                if (result.length > 0) {
                    if (result) {
                        this.FilteredPoliciesList.values = result;
                    }
                } else {
                    this.FilteredPoliciesList.values = [noAgent];
                    this.selectedPolicy = "";
                }
            } else {
                this.FilteredPoliciesList.values = [noAgent];
                this.selectedPolicy = "";
            }
        }
    };

    @action setSortColumn = async(sortField: any) => {
        this.sortOrder = !this.sortOrder;
        this.sortColumnOrder = sortField;
        await this.loadRenewalBackout();
    }

    @action onPage = async(firstIndex: number, rows: number) => {
        this.pageSize = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.pageSize;
        await this.loadRenewalBackout();
    }

    @action Load = async () => {
        await this.ResetValidate();
        await this.resetAll()
    };

    Route = async (currentRoute: RouteList): Promise<void> => { };

    @action goToAdmin = () => {
        routes.administration.replace()
    }

    @action resetAll = async() => {
        this.policyList.values = [];
        this.agentList.values = [];
        this.payDate = undefined;
        this.renewalBackoutList.values = [];
        this.companyId = ""
        this.policyNumber = ""
        this.payToAgentID = ""
        this.backoutConfirm = false;
        this.pageSize = 500;
    }

    @action setRenewalBackoutSelection(selectedObj: any) {
        this.selectedRenewalBackout = selectedObj;
    }

    @action loadCompanies = async() =>{
        var result = await CommissionStore.getAllRenewalBackoutCarriers(this.payDate || new Date())
        if(result){
            this.companyList.values = []
            this.companyList.values = this.mapListItemAndSort(result, "company");
        }
    }

    @action mapListItemAndSort(listObjects: any, actionV: string) {
        if (listObjects) {
            if (actionV === "company"){
                listObjects.splice(0, 0, { text: "Select", value: "" , selected: false});
            } else {
                listObjects.splice(0, 0, { text: "All", value: "" , selected: false});
            }
            var sortList = listObjects.sort(Utils.compareListItem);
            sortList.forEach((element: any) => {
                element.label = element.text;
            });
            return sortList;
        }
        else {
            return [];
        }
    }

    @action loadRenewalBackout = async() => {
        if(!await this.Validate()){
            var result = await CommissionStore.getAllCommissionsForBackOut(this.selectedPolicyName.toLowerCase() !== "all" ? this.selectedPolicyName : "", +this.payToAgentID, +this.companyId, this.payDate || new Date(), this.startIndex,  this.pageSize,  this.sortColumn, this.sortOrder)
            if(result){
                this.renewalBackoutList.values = result.data || [];
                this.totalRecords = result.recordCount || 0;
            }
        }
    }

    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.pageIndex = 0;
    }

    @action backoutSelected = async() => {
        googleTagManager.trackAction("commission_revenue_backed_out", {
            feature: routes.renewalBackout.name,
            user_id: UserContext.userId
        });       

        PageContext.setIsMessageVisible(false)
        this.setbackoutConfirm(false)
        // var selected_ids = this.selectedRenewalBackout.map(function(item: any){
        //   return item.id
        // })
        // commented the above since multiple selection is now being switched to single
        var selected_ids = [this.selectedRenewalBackout.id]
        var dto: BackOutSeletedCommissionsRequest  = {
            renewalCommissionIds: selected_ids
        }
        await CommissionStore.backoutselectedcommissions(dto)
        await this.loadRenewalBackout()
    }

    @action backoutAll = async() => {
        googleTagManager.trackAction("commission_revenue_backed_out", {
            feature: routes.renewalBackout.name,
            user_id: UserContext.userId
        });
       
        var dto: BackOutAllCommissionsRequest  = {
            policyNumber: this.selectedPolicyName,
            payToAgentID: +this.payToAgentID,
            carrierID: +this.companyId,
            payDate: this.payDate
        }
        await CommissionStore.backOutAllCommissions(dto)
        await this.loadRenewalBackout()
    }

    @action setbackoutConfirm = (value: boolean) => {
        this.backoutConfirm = value;
    }

}

class RenewalBackoutValidator extends AbstractValidator<RenewalBackoutViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.companyId)
            .isNotEmpty()
            .withFailureMessage("Carrier is required");
        this.validateIfDate(input => input.payDate)
            .isNotEmpty()
            .withFailureMessage("Date is required");
    }
}