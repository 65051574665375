import { observable, action } from "mobx";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { routes } from "../../../../router";
import Utils from "../../../../infrastructure/Utils";
import ContractsStore from "../../../../store/ContractsStore";
import CommissionStore from "../../../../store/CommissionStore";
import { CommissionLevelModel, CommissionPlanModel, CommissionPlanUsageModel, RenewalAgeModel, RenewalRateModel } from "../../../../services/CommissionService";
import Collection from "../../../../infrastructure/CollectionHelper";
import { CommissionPlanHeader } from "../../../../infrastructure/enum/Settings";
import UserContext from "../../../../infrastructure/UserContext";

export class CommissionPlanListViewModel extends BaseListViewModel<any> {

  constructor() {
    super("Commission Plans", "Commission Plan", routes.addCommissionplan, routes.editCommissionplan, "id", "name");
    this.pageSize = 300
  }

  protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
    return CommissionStore.getCommissionPlans(this.selectedCompanyId, this.selectedStatusTypeId, +this.selectedPaymentMethod, +this.selectedPaymentSource, this.searchText, pageIndex, pageSize, sortColumn, sortOrder);
  }

  @observable selectedTabIndex?: number = 0;
  @observable selectedPanel: string = CommissionPlanHeader.Contracts;
  @observable selectedPlanId: number = 0
  @observable planUsageTabItems = [
    { label: CommissionPlanHeader.Contracts, value: 0 },
    { label: CommissionPlanHeader.Policies, value: 1 },
  ];
  @observable searchText: string = "";

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.selectedPanel = key;
    if(key === CommissionPlanHeader.Contracts){
      this.loadcontractUsage(this.selectedPlanId || 0)
    } else {
      this.loadpolicyUsage(this.selectedPlanId || 0)
    }
  };

  protected async deleteItem(id: number) {
    await CommissionStore.deleteCommissionPlan(id);
  }


  @action goToAdmin = () => {
    routes.administration.push()
  }

  @observable statusType: string = "1";
  @observable startIndex: number = 0;
  @observable selectedCompanyId: number = 0;
  @observable companyList: any;  
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedPaymentSource: string = "0";
  @observable paymentSourceList = new Collection<{ label: string; value: string; }>();
  @observable selectedPaymentMethod: string = "0";
  @observable paymentMethodList = new Collection<{ label: string; value: string; }>();
  @observable selectedStatusTypeId: number = 0;
  @observable statusTypeList: any;

  @observable levelList: CommissionLevelModel[] = [];
  @observable levelsDialogTitle: string = "Level";

  @observable planUsage: CommissionPlanUsageModel = {};
  @observable contractUsage: CommissionPlanUsageModel = {};
  @observable policyUsage: any;
  @observable planUsageDialogTitle: string = "Plan Usage";
  @observable planUsageVisible: boolean = false;
  @observable rateDialogTitle: string = '';
  @observable rangeList = new Collection<RenewalAgeModel>(); 
  @observable rateList = new Collection<RenewalRateModel>(); 
  @observable levellistvisible: boolean = false;
  @observable ratelistvisible: boolean = false;
  @observable selectedAgeRange: any;
  @observable selectedAgeRangeId: number | undefined = 0;

  protected async loadLookups(): Promise<void> {
    await this.loadCompanies();
    await this.loadCommissionPlanStatusTypes();
    await this.loadOtherDropdowns();
    // this.setInitialLookupValues();
  }

  @action loadOtherDropdowns = async() => {
    var result = await CommissionStore.getCommissionScreenLookup();
    if(result){
      this.paymentSourceList.values = Utils.mapListItemsAsList(result.paymentSource || [], "All", "0");
      this.paymentMethodList.values = Utils.mapListItemsAsList(result.paymentMethod || [], "All", "0");
    }
  }

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList = []; 
    this.showInactiveCarriers ? (this.companyList = this.carriersInitial.values) :
    this.companyList = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action loadCompanies = async () => {
    var items = await ContractsStore.GetActiveInactiveAllCarrierLookup();
    this.companyList = Utils.mapListItemsAsList(items, "All", "0");
    this.carriersInitial.values = this.companyList;
    this.companyList = Utils.carriersFilter(this.carriersInitial.values);
  }

  @action loadCommissionPlanStatusTypes = async () => {
    var items = await CommissionStore.getCommissionPlanStatusTypesLookup();
    this.statusTypeList = Utils.mapListItemsAsList(items, "All", "0");
  }

  @action setInitialLookupValues() {
    // this.selectedCompanyId = parseInt(this.companyList[0].value);
    // this.selectedStatusTypeId = 1;
  }

  @action setSelectedCarrier = async (value: number) => {
    this.selectedCompanyId = value;
  }

  @action setSelctedStatusType = async (value: number) => {
    this.selectedStatusTypeId = value;
  }

  @action loadLevels = async (plan: CommissionPlanModel) => {
    this.levelsDialogTitle = "Levels for " + plan.name + "";
    this.levelList = await CommissionStore.getCommissionLevelsByPlanId(plan.id || 0);
    this.setlevellistvisible(true);
  }

  @action loadPlanUsage = async (plan: CommissionPlanModel) => {
    this.planUsageDialogTitle = "Plan Usage for " + plan.name + "";
    this.selectedPanel = CommissionPlanHeader.Contracts;
    this.selectedTabIndex = 0
    this.selectedPlanId = plan.id || 0;
    this.loadcontractUsage(plan.id || 0)
    this.setPlanUsageVisible(true);
  }

  @action loadcontractUsage = async(id: number) => {
    this.contractUsage = await CommissionStore.getCommissionPlanUsageForContractByPlanId(id || 0);
  }

  @action loadpolicyUsage = async(id: number) => {
    this.policyUsage = await CommissionStore.getCommissionPlanUsageForPolicyByPlanId(id || 0);
  }

  @action cloneCommissionPlan = async() => {
    if(this.selectedPlan){
    await CommissionStore.cloneCommissionPlan(this.selectedPlan.id);
    this.hideCopyConfirmation()
    await this.loadItems();
    }
  }

  @observable isCopyConfirmationVisible: boolean = false
  @observable selectedPlan: any;

  @action showCopyConfirmation = (plan: any) => {
    this.isCopyConfirmationVisible = true;
    this.selectedPlan = plan;
  }

  @action hideCopyConfirmation = () => {
    this.isCopyConfirmationVisible = false;
  }

  @action loadRates = async (plan: CommissionPlanModel)  => {
    this.rateDialogTitle = "Revenue Rates for " + plan.name + "";
    var result_ageRange = await CommissionStore.getRenewalAgeByPlanId(plan.id || 0)
    this.rangeList.values = []
    this.selectedAgeRange = ""
    if(result_ageRange) {
      this.rangeList.values = result_ageRange.data || []
      if(this.rangeList.values.length > 0){
        var temp = this.rangeList.values[0]
        this.selectedAgeRange = temp
        this.selectedAgeRangeId = temp.id || 0
        this.loadDurationRates(this.selectedAgeRangeId)
      }
    }
    this.setratelistvisible(true)
  }

  @action setlevellistvisible = (value: boolean) => {
    this.levellistvisible = value
  }
  @action setPlanUsageVisible = (value: boolean) => {
    this.planUsageVisible = value;
    if(value === false){
    this.contractUsage.paymentPlans = []
    this.contractUsage.paymentPlanCount = 0
    this.contractUsage.policiesCount = 0
    this.policyUsage = []
    }
  }

  @action setratelistvisible = (value: boolean) => {
    this.ratelistvisible = value
  }

  @action onRowSelect = async (data: any) => {
    this.selectedAgeRange = data;
    this.selectedAgeRangeId = data.id;
    this.loadDurationRates(data.id)
  }

  @action loadDurationRates = async(id: number) => {
    this.rateList.values = []
    var result_rates  = await CommissionStore.GetRenewalRateByAgeId(id || 0)
    if(result_rates) {
      this.rateList.values = result_rates.data || []
    }
  }

  resetFiltersToDefault() {   
    this.pageSize = 300;
  }

  reset() {   
    this.pageSize = 300;
    this.searchText = "";
    this.selectedCompanyId = 0;
    this.selectedPaymentMethod = "0";
    this.selectedPaymentSource = "0";
    this.selectedStatusTypeId = 0;
    this.sortColumn ="name";
    this.isSortAscending = true;
    this.showInactiveCarriers = false;
    this.companyList = Utils.carriersFilter(this.carriersInitial.values);
    this.loadItems();
  }

}
