import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { routes } from "../../router";
import LeadStore from "../../store/LeadStore";
import { Constants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Utils from "../../infrastructure/Utils";
import {
  ApproveEmailPhoneModel,
  CreateMarketerLeadNotesModel,
  MarketerLeadDetailsResponse,
} from "../../services/LeadService";
import UserContext from "../../infrastructure/UserContext";
import { Permission } from "../../infrastructure/enum/Permission";


export class ContractedAgentLeadDetailViewModel implements ViewModel {
  @observable hasPermission: boolean = true;
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (id: number) => {

    let delay = 0;
    if(UserContext.permissions.length <= 0)
        {
            delay = 2000;
        }
        setTimeout (async () => {
          this.leadId = id;
    this.getLeadDetail(this.leadId);
    if (UserContext.permissions.length > 0 && !Utils.hasUserPermission(Permission.ViewLeads)) {
      this.hasPermission = false;
    }
    else{
      this.hasPermission = true;
    }
        }, delay);
   
  };

  Route = async (): Promise<void> => { };
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @observable notesVisibleResponse: any;
  @observable isNotesVisible: boolean = false;
  @observable leadId: number = 0;
  @observable leadDetail: MarketerLeadDetailsResponse = {};
  @observable leadLogs: any[] = [];
  @observable isAddNoteDialogVisible: boolean = false;
  @observable notes: string = "";
  @observable isPrivateNote: boolean = false;
  @observable isSendNoteAsEmail: boolean = false;
  public ErrorModel = new ErrorModel(new NoteValidator());
  @observable actionItems: MenuItem[] = [
    {
      label: "Call",
      icon: "fa fa-phone icon-fill-color",
      command: () => {
        this.callOnAgentPhoneNumber(
          this.leadDetail.agentPhone ? Number(this.leadDetail.agentPhone) : 0
        );
      },
    },
    {
      label: "Add Note",
      icon: "fa fa-plus icon-fill-color",
      command: () => {
        this.setAddNoteDialogVisible(true);
      },
    },
    {
      label: "Edit Lead",
      icon: "fa fa fa-edit icon-fill-color",
      command: () => {
        setTimeout(() => {
          this.editLead(this.leadId);
        }, 300);
      },
    },
  ];
  @action callOnAgentPhoneNumber = (phoneNumber: number) => {
    setTimeout(() => {
      var link = document.createElement("a");
      link.href = "tel:+" + phoneNumber;
      link.click();
    }, 0);
  };
  @action onBackClick() {
    Utils.setLeadsTab(1); 
    routes.leads.push();
  }
  @action editLead = async (id: number) => {
    routes.editAgentLead.push({ AgentLeadId: id });
  };

  @action setAddNoteDialogVisible = async (value: boolean) => {
    this.resetFormFields();
    this.isAddNoteDialogVisible = value;
  };
  @action setNotes(value: string) {
    this.notes = value;
  }
  @action setIsPrivateNote(value: boolean) {
    this.isPrivateNote = value;
  }
  @action setIsSendNoteAsEmail(value: boolean) {
    this.isSendNoteAsEmail = value;
  }
  @action getLeadDetail = async (leadId: number) => {
    try {
      var result = await LeadStore.getLeadDetail(leadId);
      if (result != null) {
        if (result) {
          this.leadDetail = result;
          if (this.leadDetail.agentId) {
            this.getLeadLogs(this.leadDetail.agentId);
          }
        } else {
          this.leadDetail = {};
        }
      }
    } catch (e) {
      this.leadDetail = {};
    }

  };

  @action getLeadLogs = async (agentId: number) => {
    try {
      this.notesVisibleResponse = {};
      this.isNotesVisible = false;
      var result = await LeadStore.getLeadNotes(agentId);
      if (result != null) {
        var output: any = [];
        output = result;
        this.leadLogs = output;
      } else {
        this.leadLogs = [];
      }

      if (this.leadLogs.length <= 0) {
        var responseModel = {
          status: 400,
          title: Constants.NoLogsAvailable,
          errors: { "": [] },
        };
        this.notesVisibleResponse = JSON.stringify(responseModel);
        this.isNotesVisible = true;
      }

    } catch (e) {
      this.leadLogs = [];
      this.isNotesVisible = false;
      this.notesVisibleResponse = {};
    }
  };



  @action approvePhone = async () => {
    this.isMessgeVisible = false;
    this.response = {};
    let body: ApproveEmailPhoneModel = {
      contactTypeId: 3, // 3 for phone & 1 for email
      contactValue: this.leadDetail.leadPhone,
      agentId: this.leadDetail.agentId ? this.leadDetail.agentId : 0,
    };
    await LeadStore.approvePhoneEmail(body);
    var successResponse = {
      status: 200,
      title: Constants.ContactAdded,
      errors: { "": [] },
    };
    this.isMessgeVisible = true;
      this.response = JSON.stringify(successResponse);
      this.leadDetail.canApprovePhone = false
   
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, Utils.timeDelay_Success());
  };

  @action approveEmail = async () => {
    this.isMessgeVisible = false;
    this.response = {};
    let body: ApproveEmailPhoneModel = {
      contactTypeId: 1, // 3 for phone & 1 for email
      contactValue: this.leadDetail.leadEmail,
      agentId: this.leadDetail.agentId ? this.leadDetail.agentId : 0,
    };

    await LeadStore.approvePhoneEmail(body);
    var successResponse = {
      status: 200,
      title: Constants.EmailAdded,
      errors: { "": [] },
    };
      this.isMessgeVisible = true;
      this.leadDetail.canApproveEmail = false;
    this.response = JSON.stringify(successResponse);
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, Utils.timeDelay_Success());
  };
  @action saveNotes = async () => {
    await this.ResetValidate();
    if (!(await this.Validate())) {
      var dto = this.createModel();
      this.addNote(dto);
    }
  };

  @action createModel() {
    var dto: CreateMarketerLeadNotesModel = {
      note: this.notes,
      contractingCompanyId: 0,
      payoutLevelId: 0,
      private: this.isPrivateNote,
      sendNoteEmail: this.isSendNoteAsEmail,
      agentLeadId: this.leadId,
      agentId: this.leadDetail.agentId
    };
    return dto;
  }

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action addNote = async (dto: CreateMarketerLeadNotesModel) => {
    this.isMessgeVisible = false;
    this.response = {};
    await LeadStore.addNote(dto);

    
    this.resetFormFields();
    this.isAddNoteDialogVisible = false;
    if (this.leadDetail.agentId) {
      this.getLeadLogs(this.leadDetail.agentId);
    }

    
  };
  @action resetFormFields() {
    this.isPrivateNote = false;
    this.isSendNoteAsEmail = false;
    this.notes = "";
    this.ResetValidate();
  }
}

class NoteValidator extends AbstractValidator<
  ContractedAgentLeadDetailViewModel
  > {
  public constructor() {
    super();
    this.validateIfString((input) => input.notes)
      .isNotEmpty()
      .isNotEqualTo("")
      .withFailureMessage("Note is required");
  }
}
