/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import IMASLog from "../../infrastructure/IMASLog";
import DocumentStore from "../../store/DocumentStore";
import { Constants } from "../../infrastructure/enum/Constants";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import PageContext from "../../infrastructure/PageContext";
import Utils from "../../infrastructure/Utils";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { Console } from "console";
import { RouteParameters } from "type-route";
import { NotFoundRoute, Action } from "type-route/lib/types";
import axios from "axios";

export class TermsofUseViewModel implements ViewModel {
    @action Load = async () => {
        this.getTOS();
        window.scrollTo(0,0);
    }

    Route = async (currentRoute: RouteList): Promise<void> => {
    }
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @observable customerId: number = 0;
    @observable customerName: string = '';
    @observable agreementText: any;
     @action reLoad = async () => { 
        window.scrollTo(0,0);
    }
    
    @action  getTOS = async (): Promise<any> => {
        const url = Utils.getServiceUrlByEnvironment();
        let authKey = await Utils.getApiKey();
        let host = Utils.getHost();
        return axios
          .get(`${url}/api/termsofservice/v1/termsofserviceagreement`, {
            headers: {
              HOSTNAME: host,
              authorization: authKey,
            },
          })
          .then((response:any) => {
            this.agreementText = response.data.termsofServiceAgreement;
            
          })
          .catch(console.error);
      };

    };
    