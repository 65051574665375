import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import ContractsStore from "../../../../store/ContractsStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import { action, observable } from "mobx";
import UserStore from "../../../../store/IdentityStore";
import { ActionLogModel, PasswordListResponse } from "../../../../services/IdentityService";
import IdentityStore from "../../../../store/IdentityStore";
import UserContext from "../../../../infrastructure/UserContext";

export class PasswordListViewModel extends BaseListViewModel<PasswordListResponse> {
    @observable companyList = new Collection<any>();
    @observable companyId: number = 0;
    @observable isPasswordConfirmationVisible: boolean = false;
    @observable paswordDisplayContent: any = "";
    @observable isOtherDetailsConfirmationVisible: boolean = false;
    @observable otherDetailsContent: any = "";
    constructor() {
        super("Password List", "Password List", routes.addPasswordList, routes.editPasswordList, 'id', 'company', true);
        this.pageSize = 50;

    }
    protected async loadLookups(): Promise<void> {
        var result = await ContractsStore.getCarriers(0, 1000);
        if (result && result.length > 0) {
            this.companyList.values = [];
            this.companyList.values.push({ label: "All", value: "0" });
            this.companyId = 0;
            result.forEach((element: any) => {
                this.companyList.values.push({ label: element.carrierName, value: element.carrierId.toString() });
            });
        }
    }
    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {

        return await UserStore.getPasswordList(this.companyId, pageIndex, pageSize, sortColumn, sortOrder);
    }

    protected async deleteItem(id: number) {
        await UserStore.deletePasswordList(id);
    }

    @action search = async () => {
        this.resetPaging();
        this.loadItems();
    }

    resetFiltersToDefault() {
        this.companyId = 0;
        this.pageSize = 50;
    }
    
    @action actionLog = async (company: any, companyId: any) => {
        var dto: ActionLogModel = {
            actionId: 16,
            entityId: +companyId,
            userId: UserContext.getUserId(),
            notes: "Viewed password for " + company
        }
        await IdentityStore.createActionLog(dto)
    }

    @action goSearch = async () => {
        await this.loadItems();

    }

    @action resetFilters = async () => {
        this.companyId = 0;
        await this.loadItems();
    }
}