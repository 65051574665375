import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';
import ReactRoundedImage from "react-rounded-image";
import WebChat from './assets/js/WebChat';
import helpIcon from './assets/images/help.png';
import botGifIcon from "./assets/images/ic-big.png";
// import exchangeIcon from './assets/images/exchange.png';
import './assets/css/fabric-icons-inline.css';
import './assets/css/MinimizableWebChat.css';
import launchIcon from './assets/images/ic-big.png';
import closeIcon from './assets/images/icon-Circle-close.svg';
import AgentCard from '../AgentCard';
import indicatorGif from './assets/images/indicator.gif';
import Utils from "../Utils";

const WebChatUI = (props) => {
  const [agent, setAgent] = useState(null);

  const botConfig = props.data.botConfig;
  const SERVER_URL = botConfig.SERVER_URL[props.data.host];

  const botTextInput = document.getElementsByClassName("webchat__send-box-text-box__input")
  
  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => next => async action => {
        if (action.type === 'WEB_CHAT/SET_SEND_BOX') {
          const user_entered_text = action.payload.text;
          // TODO: Handle 3 char validation for names
        }
    
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: {
                language: window.navigator.language,
                marketerName: props.data.marketerName,
                marketerId: props.data.marketerId,
                authorization: await Utils.getApiKey(),
                host: props.data.host,
                hostUrl: props.data.hostUrl,
                appId: botConfig.APP_ID
              }
            }
          });
        }
        else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (action.payload.activity.from.role === 'bot') {
            setNewMessage(true);
          }
          if (action.payload.activity.name === 'displayAgent') {
            setAgent({
              agentId: action.payload.activity.value.agentId,
              agentName: action.payload.activity.value.agentName
            });
          }
          if (action.payload.activity.name === 'hideAgent') {
            setAgent(null);
          }
          if (action.payload.activity.name === 'hideSendBox' ||
            (action.payload.activity.attachments &&
              ((action.payload.activity.attachments[0].content.id === 'carrierCard') ||
                (action.payload.activity.attachments[0].content.id === 'commissionButtons') ||
                (action.payload.activity.attachments[0].content.id === 'dropDownCard') ||
                (action.payload.activity.attachments[0].content.id === 'statusInfoCard') ||
                (action.payload.activity.attachments[0].content.id === 'anotherCarrierConfirmButton') ||
                (action.payload.activity.attachments[0].content.id === 'dropDownNoThanksCard')))) {
            setBotStyleOptions(styleOptionsHideSendBox);
          } else {
            setBotStyleOptions(styleOptions);
          }
        }
        else if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
          if (action.payload.activity.type === 'message') {
            let message = action.payload.activity.text ? action.payload.activity.text : action.payload.activity.value.dropDownInfo;
            console.log('Text: ', message);
            let data = [];
            if (message && message.startsWith('@#')) {
              let extractedStr = message.match("@#(.*)#@");
              let stringArray = extractedStr[1].split("==");
              stringArray.forEach((element) => {
                let keyValue = element.split(":");
                let key = keyValue[0].trim();
                let value = keyValue[1].trim();
                data.push({ [key]: value });
              });
              action.payload.activity.text = message.replace('@#' + extractedStr[1] + '#@', '');
              if (action.payload.activity.value && action.payload.activity.value.dropDownInfo) {
                action.payload.activity.value.dropDownInfo = message.replace('@#' + extractedStr[1] + '#@', '');
              }
            }
            let channelData = action.payload.activity.channelData;
            if (channelData) {
              channelData.authToken = await Utils.getApiKey();
              channelData.data = data
              action.payload.activity.channelData = channelData;
            } else {
              action.payload.activity.channelData = {
                authToken: await Utils.getApiKey(),
                data: data
              };
            }
          }
        }
        return next(action);
      }),
    []
  );

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: botConfig.BOT_CSS.CHAT_BACKGROUND_COLOR,
        bubbleBackground: botConfig.BOT_CSS.BUBBLE_BACKGROUND_COLOR,
        bubbleTextColor: botConfig.BOT_CSS.BUBBLE_TEXT_COLOR,
        bubbleFromUserBackground: botConfig.BOT_CSS.FROM_BUBBLE_BACKGROUND_COLOR,
        bubbleFromUserTextColor: botConfig.BOT_CSS.FROM_BUBBLE_TEXT_COLOR,
        bubbleBorderRadius: 5,
        bubbleFromUserBorderRadius: 5,
        typingAnimationBackgroundImage: `url('${indicatorGif}')`,
      }),
    []
  );

  const botImage = botConfig.APP_IMAGE;

  const styleOptions = {
    botAvatarImage: botConfig.BOT_IMAGE,
    botAvatarInitials: 'BF',
    emojiSet: true,
    hideUploadButton: true,
    userAvatarImage: botConfig.USER_IMAGE,
    userAvatarInitials: 'UI'
  }

  const styleOptionsHideSendBox = {
    hideSendBox: true,
    botAvatarImage: botConfig.BOT_IMAGE,
    botAvatarInitials: 'BF',
    emojiSet: true,
    hideUploadButton: true,
    userAvatarImage: botConfig.USER_IMAGE,
    userAvatarInitials: 'UI'
  }

  const [botStyleOptions, setBotStyleOptions] = useState(styleOptions);
  const [loaded, setLoaded] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [token, setToken] = useState();

  const handleFetchToken = useCallback(async () => {
    var params = JSON.stringify({
      marketerId: props.data.marketerId
    });

    if (!token) {
      const res = await fetch(SERVER_URL + '/directline/token', { method: 'POST', body: params });
      try {
        const { token } = await res.json();
        setToken(token);
      } catch {
        const token = null
        setToken(token);
      }
    }
  }, [setToken, token]);

  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const ImageStyle = {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  }

  const handleSendMessageToBot = (message) => {
    store.dispatch({
      type: 'WEB_CHAT/SEND_MESSAGE',
      payload: {
        text: message + ' is selected!'
      }
    });
  }

  return (
    <div className="minimizable-web-chat">
      {minimized && (
        <>
        <div className='maximize'>
        <p class="WebChat_chatIconText__xchxr" onClick={handleMaximizeButtonClick}>Ask Integrity</p>
        {/* <button className="" > */}
          <img id="bot" style={{ borderRadius: "50%" }} src={launchIcon} alt="Launch Icon" onClick={handleMaximizeButtonClick}/>
          {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
        {/* </button> */}
        </div>
        </>
      )}
      {loaded && (
        <div className={classNames('chat-box right', minimized ? 'hide' : '')}
          style={{
            borderTopColor: botConfig.BOT_CSS.BOT_HEADER_BACKGROUND,
            borderBottomColor: botConfig.BOT_CSS.CHAT_BACKGROUND_COLOR,
            borderLeft: '0px',
            borderRight: '0px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px'
          }}>
          <header
            style={{
              background: botConfig.BOT_CSS.BOT_HEADER_BACKGROUND,
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '10px'
            }}>
            <div style={{ marginLeft: '20px', marginTop: '17px' }}>
              <ReactRoundedImage
                image={botGifIcon}
                roundedColor={botConfig.BOT_CSS.BOT_HEADER_BACKGROUND}
                imageWidth="25"
                imageHeight="25"
                style={ImageStyle}
                roundedSize="3"
                borderRadius="90"
              />
            </div>
            <h4
              style={{
                color: 'white',
                paddingLeft: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // marginTop: 14,
                marginLeft: 20
              }}
            >
              {botConfig.APP_NAME}
            </h4 >
            <div className="filler" />
          </header>
          <WebChat
            className={"react-web-chat bot-search-icon"}
            onFetchToken={handleFetchToken}
            token={token}
            store={store}
            styleSet={styleSet}
            styleOptions={botStyleOptions}
          />
          {token && (
            <>
              
              <>
              <div className="chatBottom">
              {agent && <AgentCard handleSendMessageToBot={handleSendMessageToBot} agent={agent} />}
              <div className="bottomIcons">
                {/* <img class="bottomIconData" id="resetIcon" src={exchangeIcon} alt="reset" onClick={(e) => handleSendMessageToBot('Start Over')} /> */}
                <div class="bottomIconData" onClick={(e) => handleSendMessageToBot('Start Over')}>Start Over</div>
                <span class="bottomdivider"></span>
                <span id="helpIcon" src={helpIcon} alt="help" onClick={(e) => handleSendMessageToBot('Help')} >Help</span>
                {/* <img class="bottomIconData" id="helpIcon" src={helpIcon} alt="help" onClick={(e) => handleSendMessageToBot('Help')} /> */}
              </div>
              </div> 
              </>
            
            </>
          )}
        </div>
      )}
      {!minimized && (
        <button className="minimize"><img id="close" src={closeIcon} alt="close" onClick={handleMinimizeButtonClick} /></button>
      )}
    </div>
  );
};

export default WebChatUI;
