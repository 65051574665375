import { observable, action } from "mobx";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import Collection from "../../../../../infrastructure/CollectionHelper";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import {
  RequirementSubmissionMethodListModel,
  ReportFile,
} from "../../../../../services/BusinessUnitConfigService";
import DocumentStore from "../../../../../store/DocumentStore";
import ContractsStore from "../../../../../store/ContractsStore";
import Utils from "../../../../../infrastructure/Utils";

export class RequirementsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable sortColumn: string = "submissionRequirementRulesetId";
  @observable sortOrder: boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable ItemsList =
    new Collection<RequirementSubmissionMethodListModel>();
  @observable requirementTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable carrierList = new Collection<{
    label: string;
    value: string;
  }>();
   
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedId: number = 0;
  @observable selectedType: number = 0;
  @observable isdeleteConfirm: boolean = false;

  @observable selectedRequirementType: string = "-1";
  @observable selectedDocumentType: string = "0";
  @observable selectedCarrierId: string = "0";
  @observable isLoading: boolean = false;

  @action Load = async () => {
    await this.getDropdownValues();
    await this.loadRequirementsList();
  };

  @action setDeleteRequirementConfirmation = (value: boolean) => {
    this.isdeleteConfirm = value;
  };

  @action deleteRequirementType = async () => {
    this.setDeleteRequirementConfirmation(false);
    //selectedType values = 0 for agent, 1 for customer
    await BusinessUnitConfigStore.deleteRequirementSubmissionMethod(
      this.selectedId
    );
    await this.loadRequirementsList();
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(function (a: any, b: any) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      sortList.splice(0, 0, { label: "All ", value: "0" });

      return sortList;
    } else {
      return [];
    }
  }

  @action getDropdownValues = async () => {
    this.isLoading = true;
    await this.getCarrierList();
    await this.getDocumentTypeList();
    await this.getRequirementTypeList();
    this.isLoading = false;
  };

  @action getRequirementTypeList = async () => {
    let list = [
      { label: "All", value: "-1" },
      { label: "Agent", value: "0" },
      { label: "Insured", value: "1" },
    ];
    this.requirementTypeList.values = [];
    this.requirementTypeList.values = list;
    this.selectedRequirementType = this.requirementTypeList.values[0].value;
  };

  @action getDocumentTypeList = async () => {
    let result = await DocumentStore.getDocumentAllFileTypesLookup();
    if (result && result.length > 0) {
      this.documentTypeList.values = [];
      this.documentTypeList.values = this.mapListItemAndSort(result);
      this.selectedDocumentType = "0";
    }
  };

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.carrierList.values = []; 
    this.showInactiveCarriers ? (this.carrierList.values = this.carriersInitial.values) :
    this.carrierList.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action getCarrierList = async () => {
    let result = await ContractsStore.GetActiveInactiveAllCarrierLookup();
    if (result && result.length > 0) {
      this.carrierList.values = [];
      this.carrierList.values = this.mapListItemAndSort(result);
      this.carriersInitial.values = this.carrierList.values;
      this.carrierList.values = Utils.carriersFilter(this.carriersInitial.values);
      this.selectedCarrierId = "0";
    }
  };

  @action loadRequirementsList = async () => {
    let selectedType = Number(this.selectedRequirementType);
    let documentTypeId = Number(this.selectedDocumentType);
    let carrierID = Number(this.selectedCarrierId);

    try {
      this.isLoading = true;
      let result =
        await BusinessUnitConfigStore.getAllRequirementSubmissionMethods(
          selectedType,
          documentTypeId,
          carrierID,
          this.startIndex,
          this.rows,
          this.sortColumn,
          this.sortOrder
        );
      if (result && result.data) {
        this.totalRecords = result.recordCount || 0;
        this.ItemsList.values = result.data.length > 0 ? result.data : [];
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action goSearch = () => {
    this.loadRequirementsList();
  };

  @action resetFilters = async () => {
    this.rows = 25;
    this.startIndex = 0;
    this.sortColumn = "submissionRequirementRulesetId";
    this.sortOrder = false;
    this.isdeleteConfirm = false;
    this.selectedId = 0;
    this.selectedType = 0;
    this.selectedRequirementType = "-1";
    this.selectedDocumentType = "0";
    this.selectedCarrierId = "0";
    this.showInactiveCarriers = false;
    this.carrierList.values = Utils.carriersFilter(this.carriersInitial.values);
    await this.loadRequirementsList();
  };

  @action export = async () => {
    try {
      this.isLoading = true;
      let result =
        await BusinessUnitConfigStore.exportRequirementSubmissionRuleset(
          +this.selectedRequirementType,
          +this.selectedCarrierId,
          +this.selectedDocumentType
        );
      if (result) {
        this.downloadReport(result, "export");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action setSelectedId(id: number) {
    this.selectedId = id;
  }
  @action setSelectedType(id: number) {
    this.selectedType = id;
  }

  @action setSelectedRequirementType(value: string) {
    this.selectedRequirementType = value;
  }

  @action setSelectedDocumentType(value: string) {
    this.selectedDocumentType = value;
  }
  @action setSelectedCarrierId(value: string) {
    this.selectedCarrierId = value;
  }
}
