import { observable, action, computed } from "mobx";
import RouteList from "../../../../infrastructure/RouteList";
import ViewModel from "../../../../infrastructure/ViewModel";
import {
  SubmissionTabs,
  SubmissionTabsViewModelTypes,
} from "../../../../infrastructure/enum/Submissions";
import { routes } from "../../../../router";
import { SubmissionMethodViewModel } from "../submissionManagement/submissionMethod/SubmissionMethodViewModel";
import { SubmissionMethodViewFileViewModel } from "../submissionManagement/submissionMethod/SubmissionMethodViewFileViewModel";
import { AutoUnchecksViewModel } from "../submissionManagement/autoUnchecks/AutoUnchecksViewModel";
import { RequirementsViewModel } from "../submissionManagement/requirements/RequirementsViewModel";
import { RequirementsViewFileViewModel } from "../submissionManagement/requirements/RequirementsViewFileViewModel";
import { SubmissionTypesViewModel } from "../submissionManagement/submissionTypes/SubmissionTypesViewModel";
import { SubmissionMethodAddEditViewModel } from "../submissionManagement/submissionMethod/SubmissionMethodAddEditViewModel";
import { AutoUnchecksAddEditViewModel } from "../submissionManagement/autoUnchecks/AutoUnchecksAddEditViewModel";
import { RequirementsAddEditViewModel } from "../submissionManagement/requirements/RequirementsAddEditViewModel";
import { SubmissionTypesAddEditViewModel } from "../submissionManagement/submissionTypes/SubmissionTypesAddEditViewModel";
import { SenderSubmissionTypesViewModel } from "../submissionManagement/senderSubmissionTypes/SenderSubmissionTypesViewModel";
import { Constants } from "../../../../infrastructure/enum/Constants";

export class SubmissionManagementViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  @action Load = async () => {
    this.resetView();
  };
  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {
    this.handleRoute(currentRoute);
  };

  @observable editRequirementsId: number = 0;
  @observable editSubmissionTypeId: number = 0;
  @observable editSubmissionMethodId: number = 0;

  @observable SubmissionMethodViewModel: SubmissionMethodViewModel =
    new SubmissionMethodViewModel();
    @observable SubmissionMethodViewFileViewModel: SubmissionMethodViewFileViewModel =
    new SubmissionMethodViewFileViewModel();
    
  @observable AutoUnchecksViewModel: AutoUnchecksViewModel =
    new AutoUnchecksViewModel();
  @observable RequirementsViewModel: RequirementsViewModel =
    new RequirementsViewModel();
    
    @observable RequirementsViewFileViewModel: RequirementsViewFileViewModel =
    new RequirementsViewFileViewModel();
  @observable SubmissionTypesViewModel: SubmissionTypesViewModel =
    new SubmissionTypesViewModel();
  @observable SenderSubmissionTypesViewModel: SenderSubmissionTypesViewModel =
    new SenderSubmissionTypesViewModel();
  @observable
  SubmissionMethodAddEditViewModel: SubmissionMethodAddEditViewModel = new SubmissionMethodAddEditViewModel();
  @observable AutoUnchecksAddEditViewModel: AutoUnchecksAddEditViewModel =
    new AutoUnchecksAddEditViewModel();
  @observable RequirementsAddEditViewModel: RequirementsAddEditViewModel =
    new RequirementsAddEditViewModel();
  @observable SubmissionTypesAddEditViewModel: SubmissionTypesAddEditViewModel =
    new SubmissionTypesAddEditViewModel();

  @observable isLoading: boolean = false;
  @observable selectedKey: string | undefined = "";
  @observable selectedTabIndex?: number = 0;
  @observable SelectedBaseViewModel: ViewModel | undefined;
  @observable SelectedViewModel: keyof typeof SubmissionTabsViewModelTypes =
    SubmissionTabsViewModelTypes[SubmissionTabsViewModelTypes.SubmissionMethod];

  @observable tabItems = [
    { label: SubmissionTabs.SubmissionMethod, value: 0 },
    { label: SubmissionTabs.AutoUnchecks, value: 1 },
    { label: SubmissionTabs.Requirements, value: 2 },
    { label: SubmissionTabs.SubmissionTypes, value: 3 },
    { label: SubmissionTabs.SenderSubmissionTypes, value: 4 },
  ];

  @action backToAdmin = async () => {
    routes.administration.push();
  };

  @action resetView() {
    this.selectedKey = SubmissionTabs.SubmissionMethod;
    this.SelectedViewModel = SubmissionTabsViewModelTypes.SubmissionMethod;
  }

  @action goToSubmissionMethod() {
    this.selectedKey = SubmissionTabs.SubmissionMethod;
    this.SelectedViewModel = SubmissionTabsViewModelTypes.SubmissionMethod;
    this.SelectedBaseViewModel = this.SubmissionMethodViewModel;
      if (!this.isSubmissionMethodLoaded) {
          this.isSubmissionMethodLoaded = true
          this.SubmissionMethodViewModel.Load();
      }
      else {
          this.SubmissionMethodViewModel.goSearch();
      }
  }
  @action gotoAutoUnchecks() {
    this.selectedKey = SubmissionTabs.AutoUnchecks;
    this.SelectedViewModel = SubmissionTabsViewModelTypes.AutoUnchecks;
    this.SelectedBaseViewModel = this.AutoUnchecksViewModel;
      if (!this.isAutoUnchecksLoaded) {
          this.isAutoUnchecksLoaded = true
          this.AutoUnchecksViewModel.Load();
      }
      else {
          this.AutoUnchecksViewModel.goSearch();
      }
  }

  @action gotoRequirements() {
    this.selectedKey = SubmissionTabs.Requirements;
    this.SelectedViewModel = SubmissionTabsViewModelTypes.Requirements;
    this.SelectedBaseViewModel = this.RequirementsViewModel;
      if (!this.isRequirementsLoaded) {
          this.isRequirementsLoaded = true
          this.RequirementsViewModel.Load();
      }
      else {
          this.RequirementsViewModel.goSearch();
      }
  }

  @action gotoSubmissionTypes() {
    this.selectedKey = SubmissionTabs.SubmissionTypes;
    this.SelectedViewModel = SubmissionTabsViewModelTypes.SubmissionTypes;
    this.SelectedBaseViewModel = this.SubmissionTypesViewModel;
      if (!this.isSubmissionTypesLoaded) {
          this.isSubmissionTypesLoaded = true
          this.SubmissionTypesViewModel.Load();
      }
      else {
          this.SubmissionTypesViewModel.goSearch();
      }
  }

  @action gotoSenderSubmissionTypes() {
    this.selectedKey = SubmissionTabs.SenderSubmissionTypes;
    this.SelectedViewModel = SubmissionTabsViewModelTypes.SenderSubmissionTypes;
    this.SelectedBaseViewModel = this.SenderSubmissionTypesViewModel;
      if (!this.isSenderSubmissionTypesLoaded) {
          this.isSenderSubmissionTypesLoaded = true
          this.SenderSubmissionTypesViewModel.Load();
      }
      else {
          this.SenderSubmissionTypesViewModel.goSearch();
      }
  }

  @observable isSubmissionMethodLoaded: boolean = false
  @observable isAutoUnchecksLoaded: boolean = false
  @observable isRequirementsLoaded: boolean = false
  @observable isSubmissionTypesLoaded: boolean = false;
  @observable isSenderSubmissionTypesLoaded: boolean = false;

  @action handleRoute(currentRoute: RouteList) {
    let name = currentRoute.name;
    let routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    switch (name) {
      case routes.submissionManagement.name:
      case routes.submissionMethod.name:
        this.SubmissionMethodAddEditViewModel.typeConnectorList.values = []
        this.goToSubmissionMethod();        
        break;
      case routes.autoUnchecks.name:
        this.gotoAutoUnchecks();
        break;
      case routes.requirements.name:
        this.gotoRequirements();
        break;
      case routes.submissionTypes.name:
        this.gotoSubmissionTypes();
        break;
      case routes.addSubmissionMethod.name:
        this.selectedKey = SubmissionTabs.SubmissionMethod;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditSubmissionMethod;
        this.SelectedBaseViewModel = this.SubmissionMethodAddEditViewModel;
        this.SubmissionMethodAddEditViewModel.Load(0);
        break;
        case routes.viewSubmissionMethod.name:
          this.selectedKey = SubmissionTabs.SubmissionMethod;
          this.SelectedViewModel =
            SubmissionTabsViewModelTypes.ViewSubmissionMethod;
          this.SelectedBaseViewModel = this.SubmissionMethodViewFileViewModel;
          this.SubmissionMethodViewFileViewModel.Load();
          break;
      case routes.editSubmissionMethod.name:
        let submissionMethodId = routeParamObj && routeParamObj.Id;
        this.selectedKey = SubmissionTabs.SubmissionMethod;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditSubmissionMethod;
        this.SelectedBaseViewModel = this.SubmissionMethodAddEditViewModel;
        this.SubmissionMethodAddEditViewModel.Load(submissionMethodId);
        break;
      case routes.addAutoUnchecks.name:
        this.selectedKey = SubmissionTabs.AutoUnchecks;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditAutoUnchecks;
        this.SelectedBaseViewModel = this.AutoUnchecksAddEditViewModel;
        this.AutoUnchecksAddEditViewModel.Load(0);
        break;
      case routes.editAutoUnchecks.name:
        let autoUncheckId = routeParamObj && routeParamObj.Id;
        this.selectedKey = SubmissionTabs.AutoUnchecks;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditAutoUnchecks;
        this.SelectedBaseViewModel = this.AutoUnchecksAddEditViewModel;
        this.AutoUnchecksAddEditViewModel.Load(autoUncheckId);
        break;
        case routes.viewRequirement.name:
        this.selectedKey = SubmissionTabs.Requirements;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.ViewRequirements;
        this.SelectedBaseViewModel = this.RequirementsViewFileViewModel;
        this.RequirementsViewFileViewModel.Load();
        break;
      case routes.addRequirements.name:
        this.selectedKey = SubmissionTabs.Requirements;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditRequirements;
        this.SelectedBaseViewModel = this.RequirementsAddEditViewModel;
        this.RequirementsAddEditViewModel.Load(0);
        break;
      case routes.editRequirements.name:
        let requirementId = routeParamObj && routeParamObj.Id;
        this.selectedKey = SubmissionTabs.Requirements;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditRequirements;
        this.SelectedBaseViewModel = this.RequirementsAddEditViewModel;
        this.RequirementsAddEditViewModel.Load(requirementId);
        break;
      case routes.addSubmissionTypes.name:
        this.selectedKey = SubmissionTabs.SubmissionTypes;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditSubmissionTypes;
        this.SelectedBaseViewModel = this.SubmissionTypesAddEditViewModel;
        this.SubmissionTypesAddEditViewModel.Load(0);
        break;
      case routes.editSubmissionTypes.name:
        let submissionTypeid = routeParamObj && routeParamObj.Id
        let submissionType = routeParamObj && routeParamObj.type
        this.selectedKey = SubmissionTabs.SubmissionTypes;
        this.SelectedViewModel =
          SubmissionTabsViewModelTypes.AddEditSubmissionTypes;
        this.SelectedBaseViewModel = this.SubmissionTypesAddEditViewModel;
        this.SubmissionTypesAddEditViewModel.Load(submissionTypeid,submissionType ? submissionType : 0);
        break;
      case routes.senderSubmissionTypes.name:
        this.gotoSenderSubmissionTypes();
        break;
    }
  }

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    switch (key) {
      case SubmissionTabs.SubmissionMethod:
        this.handleLinkClick(SubmissionTabs.SubmissionMethod);
        break;
      case SubmissionTabs.AutoUnchecks:
        this.handleLinkClick(SubmissionTabs.AutoUnchecks);
        break;
      case SubmissionTabs.Requirements:
        this.handleLinkClick(SubmissionTabs.Requirements);
        break;
      case SubmissionTabs.SubmissionTypes:
        this.handleLinkClick(SubmissionTabs.SubmissionTypes);
        break;
      case SubmissionTabs.SenderSubmissionTypes:
        this.handleLinkClick(SubmissionTabs.SenderSubmissionTypes);
        break;
    }
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case SubmissionTabs.SubmissionMethod:
          routes.submissionMethod.push();
          return;
        case SubmissionTabs.AutoUnchecks:
          routes.autoUnchecks.push();
          return;
        case SubmissionTabs.Requirements:
          routes.requirements.push();
          return;
        case SubmissionTabs.SubmissionTypes:
          routes.submissionTypes.push();
          return;
        case SubmissionTabs.SenderSubmissionTypes:
          routes.senderSubmissionTypes.push();
          return;
        default:
          return;
      }
    }
  };

  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case SubmissionTabs.SubmissionMethod:
        tabIndex = 0;
        break;
      case SubmissionTabs.AutoUnchecks:
        tabIndex = 1;
        break;
      case SubmissionTabs.Requirements:
        tabIndex = 2;
        break;
      case SubmissionTabs.SubmissionTypes:
        tabIndex = 3;
        break;
      case SubmissionTabs.SenderSubmissionTypes:
        tabIndex = 4;
        break;
      default:
        tabIndex = 0;
        break;
    }
    return tabIndex;
  }

  @action addEditSubmissionMethod = (route: string, id: number) => {
    this.editSubmissionMethodId = id;
    switch (route) {
      case Constants.add:
        routes.addSubmissionMethod.push();
        break;
      case Constants.edit:
        routes.editSubmissionMethod.push({ Id: id });
        break;
      default:
        routes.addSubmissionMethod.push();
        break;
    }
  };  

  @action addEditSubmissionMethodTypeConnector = (route: string, id: number, editSubmissionMethodId: number) => {
    this.editSubmissionMethodId = editSubmissionMethodId 
    if(editSubmissionMethodId === 0){
    switch (route) {
      case Constants.add:
        routes.addSubmissionMethodConnector.push();
        break;
      case Constants.edit:
        routes.editSubmissionMethodConnector.push({ Id: id });
        break;
    }
  }
  else{
    switch (route) {
      case Constants.add:
        routes.addSubmissionMethodConnectorOnEdit.push({Id: editSubmissionMethodId});
        break;
      case Constants.edit:
        routes.editSubmissionMethodConnectorOnEdit.push({ Id: editSubmissionMethodId, connectorId: id });
        break;
      default:
        routes.addSubmissionMethodConnectorOnEdit.push({Id: editSubmissionMethodId});
        break;
    }
  }
  };

  @action addEditAutoUnchecks = (route: string, id: number) => {
    switch (route) {
      case Constants.add:
        routes.addAutoUnchecks.push();
        break;
      case Constants.edit:
        routes.editAutoUnchecks.push({ Id: id });
        break;
      default:
        routes.addAutoUnchecks.push();
        break;
    }
  };

  @action addEditRequirements = (route: string, id: number) => {
    this.editRequirementsId = id;
    switch (route) {
      case Constants.add:
        routes.addRequirements.push();
        break;
      case Constants.edit:
        routes.editRequirements.push({ Id: id});
        break;
      default:
        routes.addRequirements.push();
        break;
    }
  };

  @action addEditSubmisisonType = (route: string, id: number, type?: number) => {
    this.editSubmissionTypeId = id;
    switch (route) {
      case Constants.add:
        routes.addSubmissionTypes.push();
        break;
      case Constants.edit:
        routes.editSubmissionTypes.push({ Id: id, type:  type === 0 ? "0": "1" });
        break;
      default:
        routes.addSubmissionTypes.push();
        break;
    }
  };
}
