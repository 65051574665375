import React from "react";

//import "reflect-metadata";

import IMASLog from "./IMASLog";

type constructor<T> = {
  new(...args: any[]): T;
};

type viewProps<T extends object> = {
  vm: T;
};

class Bootstrapper {
  private maps = new Map<Function, React.FunctionComponent<viewProps<any>>>();

  public bind<T extends object>(
    viewModel: constructor<T>,
    view: React.FunctionComponent<viewProps<T>>
  ) {
    this.maps.set(viewModel, view);
  }

  public resolve(viewModel: object | undefined | null) {
    if (viewModel === undefined || viewModel === null) {
      IMASLog.log("Bootstrapper resolve..null.");
      return null;
    } else {
      IMASLog.log("Bootstrapper resolve...");
      var view = this.maps.get(viewModel.constructor);
      if (view === undefined) return null;
      var result = React.createElement(view, { vm: viewModel }, null);
      return result;
    }
  }

}

export default new Bootstrapper();
