import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import {
  Locations,
  Notes,
  PrinicpalAgents,
  WritingNumbers2,
  CommissionModel,
} from "../../../services/ContractService";
import Collection from "../../../infrastructure/CollectionHelper";
import { routes } from "../../../router";
import { ContractDetails } from "../../../infrastructure/enum/Contract";
import PageContext from "../../../infrastructure/PageContext";
import ContractsStore from "../../../store/ContractsStore";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import { CommunicationsViewModel } from "./CommunicationsViewModel";
import { ContractDocumentsViewModel } from "./ContractDocumentsViewModel";
import { ContractPaymentplansViewModel } from "./ContractPaymentplansViewModel";
import { ContractPersistencyViewModel } from "./ContractPersistencyViewModel";
import { ContractStatesViewModel } from "./ContractStatesViewModel";
import { AddEditPaymentPlanViewModel } from "../paymentplans/AddEditPaymentPlanViewModel";
import IdentityStore from "../../../store/IdentityStore";
import { ActionLogModel, UserPropertiesRequest } from "../../../services/IdentityService";
import UserContext, {
  UserProperties,
} from "../../../infrastructure/UserContext";
import { Permission } from "../../../infrastructure/enum/Permission";
import Utils from "../../../infrastructure/Utils";
import CustomAttributeStore from "../../../store/CustomAttributeStore";
import { ContractAddEditPaymentPlanViewModel } from "./ContractAddEditPaymentPlanViewModel";

export class ContractDetailsViewModel implements ViewModel {
  @observable selectedPaymentPlanId: number = 0;
    @observable includeTerminatedDownlines: boolean = false;
    @observable isLoadRecentProfiles: boolean = false;
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (contractId: number, agentId: number) => {
    this.replacedByLicenseId = "";
    this.replacedFromLicenseId = "";
    this.contractId = contractId;
    this.agentId = agentId;
    this.resetFields();
    this.isAgentHierarchyLoaded = false;
    this.productShowAll = false;
    this.notesShowAll = false;
    this.hierarchyFilter = "";
    this.agentHierarchyData = [];
    this.callDataLoadAPI = true;

    this.getContractSummary();
    this.loadContractLevels();

    if(
      Utils.hasUserPermission(Permission.PrivateCustomAttribute) 
      || 
      Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
        var attributes = await this.getCustomAttributesByScreen(4);
        if (attributes) {
          this.customAttributeList = attributes;
        }
      }
      if (
          sessionStorage.getItem("createlog") &&
          sessionStorage.getItem("createlog") === "true"
      ) {
          await this.actionLog();
      }
    };

    @action actionLog = async () => {
        var dto: ActionLogModel = {
            actionId: 1,
            entityId: this.agentId ? +this.agentId : undefined,
            userId: UserContext.getUserId(),
        };
        
        await IdentityStore.createActionLog(dto);
        sessionStorage.removeItem("createlog");
        this.isLoadRecentProfiles = true;
    };

    @action setLoadRecentProfiles = () => {
        this.isLoadRecentProfiles = false;
    };
  @observable onProductSort: string = "categoryName";
  @observable contractId: number = 0;
  @observable agentId: number = 0;
  @observable isShowProductNames: boolean = false;
  @observable sortProductColumn: string = "productName";
  @observable isSortProductAscending: boolean = true;
  @observable productMarketerId: number = 0;
  @observable productPayoutLevelId: number = 0;
  @observable productCategoryIds = [];
  @observable productSubCategoryIds = [];
  @observable productNotes: string = "";
  @observable paymentPlanTitle: string = "Add Payment plan";
  @observable productSortOrder?: number = undefined;

  @observable isLoading: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable isViewProductVisible: boolean = false;
  @observable isPaymentPlanVisible: boolean = false;
  @observable selectedTabIndex: number = 0;
  @observable selectedPanel: string = "";
  @observable response: string = "";
  @observable isCancelChangesConfirmation: boolean = false;

  @observable WritingNumbersList = new Collection<WritingNumbers2>();
  @observable ProductsList = new Collection<any>();
  @observable ProductsListTemp = new Collection<any>();
  @observable ViewProductsList = new Collection<CommissionModel>();
  @observable Locations = new Collection<Locations>();
  @observable PrincipalAgentsList = new Collection<PrinicpalAgents>();
  @observable Notes = new Collection<Notes>();

  @observable carrierId: number = 0;
  @observable status: string = "";
  @observable statusReason: string = "";
  @observable carrierName: string = "";
  @observable npn: string = "";
  @observable isAgency: boolean = false;
  @observable enteredDate: Date | undefined;
  @observable modifiedDate: Date | undefined;
  @observable createdByName: string | undefined = undefined;
  @observable modifiedByName: string | undefined = undefined;
  @observable startDate: Date | undefined;
  @observable endDate: Date | undefined;
  @observable submittedDate: Date | undefined;
  @observable advancedMonths: string = "0";
  @observable doNotCall: boolean = false;
  @observable doNotEmail: boolean = false;
  @observable doNotMarket: boolean = false;
  @observable doNotCallDownlineDefault: boolean = false;
  @observable doNotEmailDownlineDefault: boolean = false;
  @observable doNotMarketDownlineDefault: boolean = false;
  @observable immediateUpline: string = "";
  @observable processedByName: string = "";
  @observable contractReceiptDate: Date | undefined;
  @observable approvalDate: Date | undefined;
  @observable appointmentType: string = "";
  @observable appSubmittedWithin90Days: Date | undefined;
  @observable agentGroup: string = "";
  @observable saleType: string = "";
  @observable payee: string = "";
  @observable productCommissionCount: number = 0;
  @observable isSave: boolean = true;
  @observable CommunicationsViewModel = new CommunicationsViewModel();
  @observable ContractDocumentsViewModel = new ContractDocumentsViewModel();
  @observable ContractPaymentplansViewModel =
    new ContractPaymentplansViewModel();
  @observable ContractPersistencyViewModel = new ContractPersistencyViewModel();
  @observable ContractStatesViewModel = new ContractStatesViewModel();
  @observable AddEditPaymentPlanViewModel: AddEditPaymentPlanViewModel =
    new AddEditPaymentPlanViewModel();
  @observable ContractAddEditPaymentPlanViewModel =
    new ContractAddEditPaymentPlanViewModel();
  @observable isAddPaymentPlan: boolean = false;
  @observable agentHierarchyData: any = [];
  @observable isAgentHierarchyLoaded: boolean = false;
  @observable productShowAll: boolean = false;
  @observable notesShowAll: boolean = false;
  @observable productChangeDetector: number = 1;
  @observable noteChangeDetector: number = 1;
  @observable replacedFromLicenseId: string = "";
  @observable replacedByLicenseId: string = "";
  @observable isScrollVisible: boolean = false;
  @observable hierarchyFilter: string = "";
  @observable dataSource: any;
  @observable gridParams: any;
  @observable resultData: any;
  @observable timer: any;
  @observable dataChild: any;
  @observable searchFind: boolean = false;
  @observable payeeSearch: string = "This Agent/ Agency";

  @observable contractDetailsTabItems = [
    { label: ContractDetails.Info, value: 0 },
    { label: ContractDetails.Hierarchy, value: 1 },
    { label: ContractDetails.Communications, value: 2 },
    { label: ContractDetails.Documents, value: 3 },
    { label: ContractDetails.Principals, value: 4 },
    { label: ContractDetails.Locations, value: 5 },
    { label: ContractDetails.Appointments, value: 6 },
    { label: ContractDetails.PaymentPlans, value: 7 },
  ];

  @observable customAttributeList: any = [];

  @observable gridApiNoRows: string = "";
  @observable callDataLoadAPI: boolean = true;
  @observable searchSucess: boolean = true;

  @action setCancelChangesConfirmation(value: boolean) {
    this.isCancelChangesConfirmation = value;
  }

  @action onProductSorting = (sortColumn: any) => {
    //person.sort((a, b) => a.name.localeCompare(b.name));
    this.onProductSort = sortColumn.sortField || "categoryName";
  };

  @action onProductSortView = (sortColumn: any) => {
    this.sortProductColumn = sortColumn.sortField || "productName";
    this.isSortProductAscending = !this.isSortProductAscending;
    this.loadViewContractLevels(
      this.productMarketerId,
      this.productPayoutLevelId,
      this.productCategoryIds,
      this.productSubCategoryIds,
      this.contractId,
      this.productNotes,
      0,
      0,
      this.sortProductColumn,
      this.isSortProductAscending,
      "",
      this.productSortOrder
    );
  };

  @action productNamesChange = async () => {
    this.isShowProductNames = !this.isShowProductNames;
    await this.saveUserProperties(
      this.isShowProductNames,
      this.includeTerminatedDownlines
    );
  };

  @action includeTerminatedDownlinesChecked = async () => {
    await this.saveUserProperties(
      this.isShowProductNames,
      this.includeTerminatedDownlines
    );
  };

  @action saveUserProperties = async (
    isShowProductNames: boolean,
    includeTerminatedDownlines: boolean
  ) => {
    let userProperties: UserProperties = {
      isShowProductNames: isShowProductNames,
      includeTerminatedDownlines: includeTerminatedDownlines,
    };
    try {
      var dto: UserPropertiesRequest = {
        userId: UserContext.userId,
        userProperties: JSON.stringify(userProperties),
      };
      await IdentityStore.updateUserPropertiesById(dto);
    } catch (e: any) {
      this.response = e.response;
    }
  };

  @action getContractSummary = async () => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    try {
      var result = await ContractsStore.getContractDetailsById(this.contractId);
      if (result != null && result) {
        this.isAgency = result.isAgency ? result.isAgency : false;
        this.carrierId = result.companyId || 0;
        this.status = result.status || "";
        this.statusReason = result.statusReason || "";
        this.carrierName = result.company || "";
        localStorage.setItem("carrierName", this.carrierName);
        this.npn = result.npn || "";
        this.enteredDate = result.enteredDate;
        this.createdByName = result.createdBy || undefined;
        this.modifiedByName = result.modifiedBy || undefined;
        this.modifiedDate = result.modifiedOn;
        this.startDate = result.startDate;
        this.endDate = result.endDate;
        this.submittedDate = result.submittedDate;
        this.advancedMonths = result.advancedMonths || "";
        this.doNotCall = result.doNotCall || false;
        this.doNotEmail = result.doNotEmail || false;
        this.doNotMarket = result.doNotMarket || false;
        this.doNotCallDownlineDefault =
          result.doNotCallDownlineDefault || false;
        this.doNotEmailDownlineDefault =
          result.doNotEmailDownlineDefault || false;
        this.doNotMarketDownlineDefault =
          result.doNotMarketDownlineDefault || false;
        this.immediateUpline = result.term || "";
        this.processedByName = result.processedByName || "";
        this.contractReceiptDate = result.contractReceiptDate;
        this.approvalDate = result.approvalDate;
        this.appSubmittedWithin90Days = result.appSubmittedWithin90Days;
        this.saleType = result.saleType || "";
        this.agentGroup = result.agentGroupName || "-";
        this.payee = result.payeeName || "This Agent/ Agency";
        this.replacedByLicenseId = result.replacedByLicenseId
          ? result.replacedByLicenseId.toString()
          : "";
        this.replacedFromLicenseId = result.replacedFromLicenseId
          ? result.replacedFromLicenseId.toString()
          : "";

        this.appointmentType = result.appointmentType || "";
        this.WritingNumbersList.values = result.writingNumbers || [];
        if (result.notes && result.notes.length > 0) {
          result.notes.forEach((element: any, index) => {
            element.rowId = new Date().getTime() + index;
            this.Notes.values.push(element);
          });
        }
        this.Locations.values = result.locations || [];
        this.PrincipalAgentsList.values = result.prinicpalAgents || [];
        this.isShowProductNames = result.isShowProductNames || false;
        this.includeTerminatedDownlines =
          result.includeTerminatedDownlines || false;
        RouteParamDetails.reportToLicenseId = result.reportsToLicenseId;
        RouteParamDetails.carrierId = result.companyId;
        localStorage.setItem("contractCarrierId", "" + result.companyId);
        PageContext.setUpLineAgentId(0);
      }

      this.isLoading = false;
    } catch (e: any) {
      this.WritingNumbersList.values = [];
      this.Notes.values = [];
      this.Locations.values = [];
      this.PrincipalAgentsList.values = [];
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => {
    switch (currentRoute.name) {
      case routes.contractDetails.name:
        window.scrollTo(0, 0);
        this.selectedTabIndex = 0;
        this.selectedPanel = ContractDetails.Info;
        PageContext.setIsFromAddContract("Details");
        break;
      case routes.addPaymentPlan.name:
        localStorage.setItem("carrierName", this.carrierName);
        this.selectedTabIndex = 4;
        this.setIsAddPaymentPlan(true);
        this.selectedPanel = ContractDetails.PaymentPlans;
        this.AddEditPaymentPlanViewModel.Load(this.agentId, undefined);
        break;
      case routes.addPaymentPlanFromLevel.name:
        this.selectedTabIndex = 4;
        this.setIsAddPaymentPlan(true);
        this.selectedPanel = ContractDetails.PaymentPlans;
        this.AddEditPaymentPlanViewModel.Load(this.agentId, undefined, true);

        break;
      case routes.editPaymentPlan.name:
        this.selectedTabIndex = 4;
        this.setIsAddPaymentPlan(true);
        this.selectedPanel = ContractDetails.PaymentPlans;
        this.AddEditPaymentPlanViewModel.Load(
          this.agentId,
          RouteParamDetails.paymentPlanId
        );
        break;
      case routes.contractPaymentPlans.name:
        this.selectedTabIndex = 4;
        await this.getContractSummary();
        this.setIsAddPaymentPlan(false);
        this.selectedPanel = ContractDetails.PaymentPlans;
        if (localStorage.getItem("loadpaymentplans") === "true") {
          this.ContractPaymentplansViewModel.Load(
            this.contractId,
            this.agentId
          );
          localStorage.removeItem("loadpaymentplans");
        } else {
          this.ContractPaymentplansViewModel.Load(
            this.contractId,
            this.agentId
          );
        }
        break;
    }
  };
  @action setSelectedPanel = async (value: string) => {
    this.selectedPanel = value;
  };

  @action loadViewContractLevels = async (
    marketerId: number,
    payoutLevelId: number,
    categoryIds: any,
    subCategoryIds: any,
    licenseId: number,
    notes: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string,
    productSortOrder?: number
  ) => {
    this.isMessgeVisible = false;
    this.isLoading = true;
    try {
      var result = await ContractsStore.getProductPaidCommission(
        marketerId,
        payoutLevelId,
        categoryIds,
        subCategoryIds,
        licenseId,
        notes,
        pageIndex,
        pageSize,
        sortColumn,
        sortAscending,
        fullTextFilter,
        productSortOrder
      );
      if (result && result.data) {
        if (!Utils.hasUserPermission(Permission.ViewPaidPercentage)) {
          result.data = result.data.filter(
            (value, index, array) =>
              index ==
              array.findIndex((item) => item.productName == value.productName)
          );
        }
        this.productCommissionCount = result.data.length;
        this.ViewProductsList.values = result.data;
      } else {
        this.ViewProductsList.values = [];
      }
    } catch (e) {
      this.ViewProductsList.values = [];
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action loadContractLevels = async () => {
    this.isMessgeVisible = false;
    this.isLoading = true;
    try {
      if (this.contractId) {
        var result = await ContractsStore.getContractLevels(this.contractId);
        if (result && result.data) {
          if (Utils.hasUserPermission(Permission.ViewPaidPercentage)) {
          }
          let list: any = [];
          let i = 1;
          result.data.forEach((item: any) => {
            i++;
            item.rowId = new Date().getTime() + i;
            list.push(item);
          });
          this.ProductsList.values = list;
          this.ProductsListTemp.values = list;
        } else {
          this.noLevels();
        }
      }
    } catch (e) {
      this.noLevels();
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  @action noLevels() {
    this.ProductsList.values = [];
  }
  @action navigateToEditContract() {
    localStorage.setItem("isFromViewContract", "true");
    localStorage.setItem("isEditContract", "false");
    routes.editContract.push({
      agentId: "" + this.agentId,
      contractId: this.contractId,
    });
  }

  @action setIsAddPaymentPlan(value: boolean) {
    this.isAddPaymentPlan = value;
  }

  @action handleTabClick = async (index: number | undefined, key: string) => {
    this.selectedTabIndex = index || 0;
    this.isMessgeVisible = false;
    this.setSelectedPanel(key);
    switch (this.selectedPanel) {
      case ContractDetails.Info:
        routes.contractDetails.replace({
          agentId: "" + this.agentId,
          contractId: this.contractId ? this.contractId : 0,
        });
        break;
      case ContractDetails.Hierarchy:
        this.isAgentHierarchyLoaded = true;
        window.addEventListener("resize", this.listenScrollEvent);
        break;
      case ContractDetails.Documents:
        this.ContractDocumentsViewModel.Load(this.contractId, this.agentId);
        break;
      case ContractDetails.Communications:
        this.CommunicationsViewModel.Load(this.contractId, this.agentId);
        break;
      case ContractDetails.PaymentPlans:
        this.isAddPaymentPlan = false;
        routes.contractPaymentPlans.replace({
          agentId: "" + this.agentId,
          contractId: this.contractId ? this.contractId : 0,
        });
        break;
      case ContractDetails.Persistency:
        this.ContractPersistencyViewModel.Load(this.contractId, this.agentId);
        break;
      case ContractDetails.States:
        this.ContractStatesViewModel.Load(this.contractId, this.agentId);
        break;
    }
  };
  @action editPaymentplan = (paymentPlanId: number) => {
    this.selectedPaymentPlanId = paymentPlanId;
  };
  @action goToContracts = () => {
    this.carrierName = "";
    routes.agentContracts.replace({ agentId: "" + this.agentId });
  };
  @action setViewProduct = (value: boolean) => {
    this.isViewProductVisible = value;
  };
  @action setPaymentPlan = (value: boolean) => {
    this.isPaymentPlanVisible = value;
  };
  @action openDocumentLink = async (docId: number, typeId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    if (typeId === 2) {
      window.open(
        window.location.origin +
          "/agents/" +
          this.agentId +
          "/customerDocsViewer/" +
          docId +
          "/" +
          typeId,
        "_new"
      );
    } else {
      window.open(
        window.location.origin +
          "/agents/" +
          this.agentId +
          "/pdfViewer/" +
          docId +
          "/" +
          typeId,
        "_new"
      );
    }
    this.isLoading = false;
  };
  @action resetFields = () => {
    this.isAgency = false;
    this.carrierId = 0;
    this.status = "";
    this.statusReason = "";
    this.carrierName = "";
    this.npn = "";
    this.enteredDate = undefined;
    this.modifiedDate = undefined;
    this.createdByName = undefined;
    this.modifiedByName = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.submittedDate = undefined;
    this.advancedMonths = "";
    this.doNotCall = false;
    this.doNotEmail = false;
    this.doNotMarket = false;
    this.doNotCallDownlineDefault = false;
    this.doNotEmailDownlineDefault = false;
    this.doNotMarketDownlineDefault = false;
    this.immediateUpline = "";
    this.processedByName = "";
    this.contractReceiptDate = undefined;
    this.approvalDate = undefined;
    this.appSubmittedWithin90Days = undefined;
    this.saleType = "";
    this.agentGroup = "";
    this.payee = "";
    this.productCommissionCount = 0;

    this.appointmentType = "";
    this.WritingNumbersList.values = [];
    this.Notes.values = [];
    this.Locations.values = [];
    this.PrincipalAgentsList.values = [];
    this.isShowProductNames = false;
    this.includeTerminatedDownlines = false;
    this.isLoading = false;
    this.response = "";
    this.ProductsList.values = [];
    this.ProductsListTemp.values = [];
    this.ViewProductsList.values = [];
  };

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(
        screenId,
        this.contractId
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action listenScrollEvent = () => {
    this.attachEvent(document.getElementById("root"), "scroll", this.update());
    this.attachEvent(window, "resize", this.update());
    this.update();
  };

  @action visibleY = (el: any) => {
    if (el) {
      var rect = (el && el.getBoundingClientRect()) || null,
        top = rect.top,
        height = rect.height,
        el = el.parentNode;
      return top <= document.documentElement.clientHeight;
    } else {
      return false;
    }
  };

  @action attachEvent = (element: any, event: any, callbackFunction: any) => {
    if (element.addEventListener) {
      element.addEventListener(event, callbackFunction, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + event, callbackFunction);
    }
  };

  @action update = () => {
    this.isScrollVisible = this.visibleY(
      document.getElementById("pleaseignoreanddonotdeleteit2")
    );
  };

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  @action getHierarchy = async () => {
    this.agentHierarchyData = [];
    var result: any = await ContractsStore.getContractSummary(
      this.contractId,
      this.includeTerminatedDownlines
    );
    if (result.agentHierarchy) {
      this.agentHierarchyData.push(result.agentHierarchy);
    }
    window.addEventListener("scroll", this.listenScrollEvent);
    window.scrollTo(1, 1);
    return this.agentHierarchyData;
  };

  @observable timer: any;
  @action scrollLoader = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };
}
