// mobx
import { observable, action } from "mobx";
// ViewModel
import ViewModel from "../../../../infrastructure/ViewModel";
// route
import RouteList from "../../../../infrastructure/RouteList";
// other
import Collection from "../../../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { Constants } from "../../../../infrastructure/enum/Constants";
import DocumentStore from "../../../../store/DocumentStore";
import Utils from "../../../../infrastructure/Utils";
import { DocumentViewModel } from "./DocumentViewModel";

export class AddPacketViewModel implements ViewModel {
    // ViewModel functions implementation
    get CanClose(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    get CanRoute(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    get IsLoaded(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    get IsLoading(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    Close(): void {
        throw new Error(Constants.NoMethodImplementation);
    }

    @action Load = async (packetId: number | undefined) => {
        this.packetId = packetId ? packetId : 0;
        this.isAddDocumentsDialogVisible = false;
        this.ResetValidate();
        this.loadPacket(packetId ? packetId : 0);
    };

    // declaration for route
    Route = async (currentRoute: RouteList): Promise<void> => { }

    // declaration for variables
    // route id's
    @observable packetId: number = 0;

    // lists variables
    @observable documentTypeList = new Collection<{
        label: string, value: string
    }>()
    @observable companyList = new Collection<{
        label: string, value: string
    }>()
    @observable levelList = new Collection<{
        label: string, value: string
    }>()
    @observable sigPageDocList = new Collection<{
        label: string, value: string
    }>()

    // page input variables
    @observable selectedCompany: number = 0;
    @observable selectedType: number = 0;
    @observable selectedLevel: number = 0;
    @observable selectedsigDocPage: number = 0;
    @observable packetName: string = '';
    @observable description: string = '';
    @observable selectedProductIds: any = [];
    @observable productList: any = new Collection<any>();
    @observable selectedStateIds: any = [];
    @observable stateList: any = new Collection<any>();
    @observable packetDocumentList: any = new Collection<any>();
    @observable documentViewModel: any = new DocumentViewModel();
    @observable isAddDocumentsDialogVisible: boolean = false;
    @observable selectedDocument: any;
    @action moveup() {
        const selecteddoc = this.selectedDocument;
        const currentIndex = this.packetDocumentList.values.findIndex((x: any) => { return x.id === this.selectedDocument.id });
        if (currentIndex > 0) {
            const currentdoc = this.packetDocumentList.values[currentIndex];
            const previousdoc = this.packetDocumentList.values[currentIndex - 1];
            if ((currentdoc.sortOrder - 1) === previousdoc.sortOrder) {
                this.packetDocumentList.values[currentIndex].sortOrder--;
                this.packetDocumentList.values[currentIndex - 1].sortOrder++;
                this.move(this.packetDocumentList.values, currentIndex, currentIndex - 1);
            }
            else {
                this.packetDocumentList.values[currentIndex].sortOrder--;
                this.selectedDocument = selecteddoc;
                this.move(this.packetDocumentList.values, currentIndex, currentIndex);
            }
        }
    }
    @action movedown() {
        const selecteddoc = this.selectedDocument;
        const currentIndex = this.packetDocumentList.values.findIndex((x: any) => { return x.id === this.selectedDocument.id });
        if (currentIndex < (this.packetDocumentList.values.length - 1)) {
            const currentdoc = this.packetDocumentList.values[currentIndex];
            const nextdoc = this.packetDocumentList.values[currentIndex + 1];
            if ((currentdoc.sortOrder + 1) === nextdoc.sortOrder) {
                this.packetDocumentList.values[currentIndex].sortOrder++;
                this.packetDocumentList.values[currentIndex + 1].sortOrder--;
                this.move(this.packetDocumentList.values, currentIndex, currentIndex + 1);
            }
            else {
                this.packetDocumentList.values[currentIndex].sortOrder++;
                this.selectedDocument = selecteddoc;
                this.move(this.packetDocumentList.values, currentIndex, currentIndex);
            }
        }
    }
    move = (input: any, from: number, to: number) => {
        let numberOfDeletedElm = 1;
        const elm = input.splice(from, numberOfDeletedElm)[0];
        numberOfDeletedElm = 0;
        input.splice(to, numberOfDeletedElm, elm);
    }
    @observable selectedDocuments: any = [];

    // validation model
    @observable ErrorModel = new ErrorModel(new AgentDocumentValidator());

    @observable rows: number = 1000;
    @observable title: string = "";
    @observable totalRecords: number = 1000;
    @observable first: number = 0;
    @observable startIndex: number = 0;
    @observable sortOrder: boolean = false;
    @observable sortColumnOrder: string = "id";
    @observable isDeleteDocumentConfirm: boolean = false;
    @observable isDeleteMessage: string = "";
    @observable isAddProductVisible: boolean = false;
    @observable selectedProductCompany: number = 0;
    @observable productDescription: string = '';
    @observable isLoading: boolean = false;
    @observable isMessgeVisible: boolean = false;
    @observable response: any;

    // Functions implementation
    @action loadPacket = async (packetId: number) => {
        // this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "Select", value: "0" };
        try {
            var result = await DocumentStore.packetDetails(packetId);
            if (result != null) {
                // Company
                if (result.carrierList != null) {
                    this.companyList.values = [];
                    result.carrierList.forEach((obj: any) => {
                        var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.companyList.values.push(data);
                    });
                    this.companyList.values.splice(0, 0, defaultItem);
                    this.selectedCompany = result.companyId ? result.companyId : 0;
                }
                else {
                    this.companyList.values = [];
                    this.companyList.values.splice(0, 0, defaultItem);
                    this.selectedCompany = 0;
                }

                // Type
                if (result.documentTypeGroupList != null) {
                    this.documentTypeList.values = [];
                    result.documentTypeGroupList.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.documentTypeList.values.push(documentData);
                    });
                    this.documentTypeList.values.splice(0, 0, defaultItem)
                    this.selectedType = result.documentPacketGroupId ? result.documentPacketGroupId : 0;
                }
                else {
                    this.documentTypeList.values = [];
                }

                // Levels
                if (result.levelList != null) {
                    this.levelList.values = [];
                    result.levelList.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.levelList.values.push(documentData);
                    });
                    this.selectedLevel = result.payoutLevelId ? result.payoutLevelId : 0;
                }
                else {
                    this.levelList.values = [];
                }

                // Signature page docs
                if (result.signaturePageDocsList != null) {
                    this.sigPageDocList.values = [];
                    result.signaturePageDocsList.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.sigPageDocList.values.push(documentData);
                    });
                    this.selectedsigDocPage = result.signaturePageDocumentId ? result.signaturePageDocumentId : 0;
                }
                else {
                    this.sigPageDocList.values = [];
                }

                // States
                if (result.stateList != null) {
                    this.stateList.values = [];
                    result.stateList.forEach((obj: any) => {
                        var documentData = { label: obj.stateName ? obj.stateName : "", value: obj.stateId ? obj.stateId : "", isHeader: obj.isHeader };
                        this.stateList.values.push(documentData);
                    });
                }
                else {
                    this.stateList.values = [];
                }

                //Products
                this.productList.values = [];
                this.selectedProductIds = [];
                this.productDescription = "";
                if (result.selectedProducts && result.selectedProducts.length > 0) {
                    this.productDescription = result.selectedProducts.map((item: any) => { return item.text }).join(', ');
                    result.selectedProducts.forEach((item: any) => {
                        this.selectedProductIds.push(item.value);
                        var documentData = { label: item.text ? item.text : "", value: item.value ? item.value : "" };
                        this.productList.values.push(documentData);
                    });
                }
                this.selectedStateIds = [];
                if (result.selectedStates && result.selectedStates.length > 0) {                    
                    result.selectedStates.forEach((item: any) => { this.selectedStateIds.push(parseInt(item.value)) });
                }
                if (result.productList != null) {
                    result.productList.forEach((obj:any) => {
                        if (this.productList.values.filter((item: any) => { return item.value === obj.value }).length === 0) {
                            var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                            this.productList.values.push(documentData);
                        }
                    });
                }
                this.packetName = result.packetName ? result.packetName : "";
                this.description = result.description ? result.description : "";
                this.packetDocumentList.values = result.packetDocuments && result.packetDocuments.length ? result.packetDocuments : [];
            }

        } catch (e) {
            this.documentTypeList.values = [];
            this.companyList.values = [];
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = 0;
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.isLoading = false;
    }
    //  Events
    @action setDeleteDocumentConfirm = (value: boolean) => {
        if (this.selectedDocument && this.selectedDocument.id && this.selectedDocument.id > 0 && value) {
            this.isDeleteMessage = "Are you sure you want to remove this document from the Packet?"
        }
        else
        {
            this.isDeleteMessage = "Select document to remove from Packet";
        }        
        this.isDeleteDocumentConfirm = value;
    }
    @action setSelectedType = (value: number) => {
        this.selectedType = value;
    }
    @action setSelectedLevel = (value: number) => {
        this.selectedLevel = value;
    }
    @action setSelectedSigDocPage = (value: number) => {
        this.selectedsigDocPage = value;
    }
    @action setSelectedCompany = async (value: number) => {        
        this.selectedCompany = value;
        if (this.selectedType === 5) {
            // this.isLoading = true;
            this.isMessgeVisible = false;
            this.response = {};
            var defaultItem = { label: "Select", value: "0" };
            try {
                var result = await DocumentStore.getLookUpByCompany(value);
                if (result != null) {
                    // Levels
                    if (result.payoutLayouts != null) {
                        this.levelList.values = [];
                        result.payoutLayouts.forEach((obj: any) => {
                            var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                            this.levelList.values.push(data);
                        });
                        this.levelList.values.splice(0, 0, defaultItem);
                        this.selectedLevel = 0;
                    }
                    else {
                        this.levelList.values = [];
                        this.levelList.values.splice(0, 0, defaultItem);
                        this.selectedLevel = 0;
                    }

                    // Signature Page Docs
                    if (result.signaturePageDocs != null) {
                        this.sigPageDocList.values = [];
                        result.signaturePageDocs.forEach((obj: any) => {
                            var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                            this.sigPageDocList.values.push(data);
                        });
                        this.sigPageDocList.values.splice(0, 0, defaultItem);
                        this.selectedsigDocPage = 0;
                    }
                    else {
                        this.sigPageDocList.values = [];
                        this.sigPageDocList.values.splice(0, 0, defaultItem);
                        this.selectedsigDocPage = 0;
                    }

                }
            } catch (e) {
                this.productList.values = [];
                this.productList.values.splice(0, 0, defaultItem);
                this.selectedProductIds = [];
                this.isMessgeVisible = true;
                this.response = e.response;
            }
            this.isLoading = false;
        }
    }
    @action setSelectedStateIds = (value: any) => {
        this.selectedStateIds = value;
    }
    @action setSelectedProductIds = (value: any) => {
        this.selectedProductIds = value;
    }
    @action setPacketName = (value: string) => {
        this.packetName = value;
    }
    @action setDescription = (value: string) => {
        this.description = value;
    }
    @action setSelectedDocument = (value: any) => {
        this.selectedDocument = value;
    }
    @action setIsAddProductVisible = (value: boolean) => {
        this.isAddProductVisible = value;
        this.selectedProductCompany = this.selectedCompany;
        if (this.selectedCompany > 0) {
            this.setSelectedProductsCompany(this.selectedProductCompany);
        }
    }
    @action setSelectedProductsCompany = async (value: any) => {
        this.selectedProductCompany = value;
        try {
            // this.isLoading = true;
            var result = await DocumentStore.getProductByCompany(this.selectedProductCompany);
            if (result != null) {
                // Product
                let selectedProducts: any = [];
                var selectedItems = this.productList.values.filter((item: any) => { return this.selectedProductIds.indexOf(item.value) >= 0 });
                selectedItems.forEach((item: any) => { selectedProducts.push(item) });

                this.productList.values = [];
                result.forEach((obj:any) => {
                    var selecteditem = selectedProducts.filter((item: any) => { return item.value === obj.value });
                    if (!selecteditem || (selecteditem && selecteditem.length === 0)) {
                        var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        selectedProducts.push(data);
                    }
                });
                this.productList.values = selectedProducts;
                //this.companyList.values.splice(0, 0, defaultItem);
                //this.selectedProductIds = 0;
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }
    }

    @action goBack() {
        Utils.goBack();
    }
    @action deleteDocumentFromPacket() {
        if (this.selectedDocument && this.selectedDocument.id && this.selectedDocument.id > 0) {
            this.packetDocumentList.values = this.packetDocumentList.values.filter((item: any) => { return item.id !== this.selectedDocument.id });
            this.selectedDocument = undefined;
        }  
        
        let count: number = 1;
        this.packetDocumentList.values.forEach((element: any) => {
            element.sortOrder = count;
            count++;
        });

        this.isDeleteDocumentConfirm = false;
    }
    @action Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action addDocumentsToPackets(selectedDocuments: any) {
        if (selectedDocuments.length > 0) {
            let doc = selectedDocuments[0];
            let packetdocs = this.packetDocumentList.values.filter((item: any) => { return item.sortOrder < doc.sortOrder });
            let otherdocs = this.packetDocumentList.values.filter((item: any) => { return item.sortOrder >= doc.sortOrder });
            selectedDocuments.forEach((item: any) => {
                packetdocs.push(item);
            });
            let count = selectedDocuments.length;
            otherdocs.forEach((element: any) => {
                count++;
                element.sortOrder = (element.sortOrder > selectedDocuments.length) ? (element.sortOrder + 1) : count;
                packetdocs.push(element);
            });
            this.packetDocumentList.values = packetdocs;
            this.isAddDocumentsDialogVisible = false;
        }
    }

    @action async addDocument() {
        
        await this.documentViewModel.Load(true);
        this.documentViewModel.selectedCompany = this.selectedCompany;
        this.documentViewModel.  setSelectedCompany(this.selectedCompany);
        await this.documentViewModel.loadDocuments();
        this.documentViewModel.setPacketsSelectedDocs(this.packetDocumentList.values);
        this.documentViewModel.setDocumentsSortOrder(this.packetDocumentList.values.length + 1);
        this.isAddDocumentsDialogVisible = true;
    }
    @action closeAddDocument() {
        this.isAddDocumentsDialogVisible = false;
    }

    @action async onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        //await this.loadAllLookupList();
    }
    @action ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action savePacket = async () => {
        await this.ResetValidate();
        if (!await this.Validate()) {
            // this.isLoading = true;
            this.isMessgeVisible = false;
            this.response = {};
            try {
                let stateIds: any = [];
                let productIds: any = [];
                this.selectedStateIds && this.selectedStateIds.forEach((element: any) => {
                    stateIds.push(parseInt(element));
                });
                this.selectedProductIds.forEach((element: any) => {
                    productIds.push(parseInt(element));
                });

                let count: number = 1;
                this.packetDocumentList.values.forEach((element: any) => {
                    element.sortOrder = count;
                    count++;
                });

                await DocumentStore.savePacket(this.packetId, this.selectedType, this.packetName, this.description,
                    this.selectedCompany, this.selectedLevel, this.selectedsigDocPage, stateIds, productIds,
                    this.packetDocumentList.values);
               
                this.isLoading = false;
               
                this.goBack();
            } catch (e) {
                this.isLoading = false;
                this.isMessgeVisible = true;
                this.response = e.response;
            }
        }
    }
    @action saveAddProducts = async () => {
        this.productDescription = this.productList.values
            .filter((item: any) => { return this.selectedProductIds.indexOf(item.value) !== -1 })
            .map((item: any) => { return item.label }).join(', ');
        this.isAddProductVisible = false;
    }
}

class AgentDocumentValidator extends AbstractValidator<AddPacketViewModel>{
    public constructor() {
        super();
        this.validateIfNumber(input => input.selectedCompany)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("Carrier is required");
        this.validateIf(input => input.packetName)
            .isNotEmpty()
            .withFailureMessage("Packet Name is required");
        this.validateIf(input => input.description)
            .isNotEmpty()
            .withFailureMessage("Description is required");
    }
}