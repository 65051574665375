import { action } from "mobx";
import { Permission } from "../../../../infrastructure/enum/Permission";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { CommissionStatementListModel } from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";
import { BaseListViewModel } from "../../../../viewModels/components/BaseListViewModel";

export interface CommissionStatementListModelEx 
       extends CommissionStatementListModel {
  agentPdf: string;
  agentXls: string;
  agentPrint: string;
  marketerPdf: string;
  marketerXls: string;
  markAsPaid: string;  
}

export interface CommissionStatementSearchResponseEx {
  recordCount?: number;
  data: CommissionStatementListModelEx[];
}

export class PrintCommissionsViewModel extends BaseListViewModel<CommissionStatementListModel> {
    constructor()
    {
      super("Print Commissions", "Print Commissions", routes.printCommissions, routes.printCommissions,"id","payDate");
    }

    protected async loadLookups(): Promise<void> {
      if(!Utils.hasUserPermission(Permission.ViewAnyStatement)){
        routes.administration.replace()
      }
      this.pageSize = 25
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
      var items = await CommissionStore.getCommissionStatements(pageIndex, pageSize, sortColumn, sortOrder);
      if(!items || !items.data)
        return items;

      var modItems:CommissionStatementSearchResponseEx = {
        recordCount: items.recordCount,
        data: []
      };
      items.data.forEach(element => {
        modItems.data.push({
          agentPdf: "Pdf",
          agentPrint: "Print",
          agentXls: "Excel",
          marketerPdf: "Pdf",
          marketerXls: "Excel",
          markAsPaid: 'Mark Paid',
          divisionID: element.divisionID,
          divisionName: element.divisionName,
          isApproved: element.isApproved,
          isPaid: element.isPaid,
          payDate: element.payDate
        });
      });

      return modItems;
    }

    /*
    @action scheduleStatement = async (divisionId: number | undefined, payDate: Date | undefined, statementType: number, fileType: number) => {
      if(divisionId === undefined || payDate === undefined)
        return;

      var payDay = new Date(payDate);

      var result = await CommissionStore.generateCommissionStatement(divisionId, payDay, statementType, fileType);
      if(result === 1) {

      }
    }
    */
    @action scheduleStatement = async (model: CommissionStatementListModelEx, statementType: number, fileType: number) => {
      if(model.divisionID === undefined || model.payDate === undefined)
        return;

      var payDay = new Date(model.payDate);

      var result = await CommissionStore.generateCommissionStatement(model.divisionID, payDay, statementType, fileType);
      if(result === 1) {
        var type = (statementType * 10) + fileType;
        switch(type) {
          case 20: model.marketerPdf = "Queued"; break;
          case 21: model.marketerXls = "Queued"; break; 
          case 10: model.agentPdf = "Queued"; break;
          case 11: model.agentXls = "Queued"; break;
          case 31: model.agentPrint = "Queued"; break;        

        }
        
        var items = this.itemList.values;
        this.itemList.values = [];
        this.itemList.values = items;
      }
    }

    @action approveStatement = async (model: CommissionStatementListModelEx, divisionId: number | undefined, divisionName: string | undefined, payDate: Date | undefined) => {
      if(divisionId === undefined || payDate === undefined)
        return;

      divisionName = divisionName || "";
      var result = await CommissionStore.approveCommissionStatement(divisionId, divisionName, payDate);
     this.refreshItems(result);
    }
    @action unApproveStatement = async (model: CommissionStatementListModelEx, divisionId: number | undefined, divisionName: string | undefined, payDate: Date | undefined) => {
      if(divisionId === undefined || payDate === undefined)
        return;

      divisionName = divisionName || "";
      var result = await CommissionStore.unapproveCommissionStatement(divisionId, divisionName, payDate);
     this.refreshItems(result);
    }
    @action markAsPaid = async (model: CommissionStatementListModelEx, divisionId: number | undefined, divisionName: string | undefined, payDate: Date | undefined) => {
      if(divisionId === undefined || payDate === undefined)
        return;
      try{
      divisionName = divisionName || "";
      await CommissionStore.markCommissionAsPaid(divisionId, divisionName, payDate);
      model.markAsPaid = "Processing";
       var items = this.itemList.values;
        this.itemList.values = [];
        this.itemList.values = items;
     
      }catch(e) {}
    }

    @action refreshItems(result: any){
      if(result === 1){
        this.loadItems();
       }
    }

    resetFiltersToDefault() {   
      this.pageSize = 25;
    }
}
