export enum PolicyDetails {
    Summary = 'Summary',
    PaymentPlan = 'Payment Plan',
    Notes = 'Notes',
    Status = 'Status',
    Demo = 'Demo'
}

export enum PolicyTabs {
    Summary = "Summary",
    RenewalHistory = "Revenue History",
}