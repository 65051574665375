import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import Collection from "../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { Constants } from "../../infrastructure/enum/Constants";
import DocumentStore from "../../store/DocumentStore";
import {CreateUpdateCustomerModel} from  "../../services/InsuredService"
import {CustomerDocumentResponse, ScannedDocumentsDataEntryView} from "../../services/DocumentService"
import PageContext from "../../infrastructure/PageContext";
import Utils from "../../infrastructure/Utils";

export class AddCustomerDocViewModel implements ViewModel {

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => { }

    @action Load = async (customerId: any) => {
        this.customerId = customerId;
        this.customerName = RouteParamDetails.customerName;
        this.selectedWorkFlow = "0";
        this.selectedMarketer = '0';
        this.notes = '';
        this.fileData = '';
        this.isMessgeVisible = false;
        this.isWorkflowListEmpty = false;
        this.ResetValidate();
        this.selectedPolicy = "0"
        await this.loadAllLookupList();
        var defaultItem = { label: "Select", value: "0" };
        this.submissionList.values = [];
        this.submissionList.values.splice(0, 0, defaultItem);
        this.selectedSubmission = "0";
        if(this.selectedPolicy && this.selectedType){
            await this.loadAllLookupList()
        }
    }
    
    @observable isdocsAvailable: boolean = false;
    @observable isException: boolean = false;
    @observable customerId: number = 0;
    @observable customerName: string = '';
    @observable isSuccess: boolean = false;
    @observable isLoading: boolean = false;
    @observable
    public ErrorModel = new ErrorModel(new CustomerDocumentValidator());
    @observable selectedType: string = '';
    @observable selectedUpload: string = '';
    @observable selectedFax: string = '';
    @observable selectedWorkFlow: string = '';
    @observable selectedMarketer: string = '';
    @observable selectedSubmission: string = '';
    @observable notes: string = '';
    @observable fileData: string | undefined | "";
    @observable fileName: string = "";
    @observable selectedPolicy: string = '';
    @observable marketerList = new Collection<{
        label: string, value: string
    }>()
    @observable documentTypeList = new Collection<{
        label: string, value: string
    }>()
    @observable isMessgeVisible: boolean = false;
    @observable uploadOn: Date = new Date();
    @observable isCustomValidation: boolean = false;
    @observable customMessage: string = '';
    @observable workFlowList = new Collection<{
        label: string, value: string
    }>()
    @observable uploadTo: boolean = false;
    @observable faxTo: boolean = false;
    @observable response: any;
    @observable policyList = new Collection<{
        label: string, value: string
    }>()
    @observable uploadList = new Collection<{
        label: string, value: string
    }>()
    @observable FaxList = new Collection<{
        label: string, value: string
    }>()
    @observable submissionList = new Collection<{
        label: string, value: string
    }>()
    @observable faxDeliveryDate: Date = new Date();
    @observable notifyAgent: boolean = false;
    @observable uploadFaxInputVisible: boolean = true;
    @observable uploadToVisible: boolean = false;
    @observable faxToVisible: boolean = false;
    @observable customerDocLoad: CustomerDocumentResponse | null = null;
    @observable faxDeliveryDateVisible: boolean = false;
    @observable customerSummary: CreateUpdateCustomerModel | null = null;
    @observable isWorkflowListEmpty: boolean = false;
    @observable isAcceptFileSizeLimit: boolean = true;
    @observable isAcceptFileSize: boolean = false;

    @action checkUploadedFileSizeAccepted = async () => {
        if (this.isSubmissionChecked && !this.isAcceptFileSizeLimit && this.selectedSubmission && this.selectedSubmission != "0" && this.selectedSubmission.split('|').shift() == "0")
            this.isAcceptFileSize = true;
        else
            this.isAcceptFileSize = false;
    }

    @action setSelectedType = async (value: string) => {
        if (this.selectedType !== value){
            this.selectedType = value;
            await this.getSubmissionList();
            this.setNotifyAgent(value === "1")
        }
    }

    @action setSelectedPolicy = async (value: string) => {  
        if (this.selectedPolicy !== value){
            this.selectedPolicy = value;
          await this.getSubmissionList();
        }
    }
    @action setSelectedSubmission = (value: string) => {
        this.selectedSubmission = value;
    };
    @action setIsSubmissionChecked = (value: boolean) => {
        this.isSubmissionChecked = value;
        this.checkUploadedFileSizeAccepted();
    };
    @action setIsException(value: boolean) {
        this.isException = value;
    }

    @action setNotifyAgent(value: boolean) {
        this.notifyAgent = value;
    }

    @action setSelectedWorkFlow = (value: string) => {
        this.selectedWorkFlow = value;
    }

    @action setisCustomValidation(value: boolean) {
        this.isCustomValidation = value;
        this.customMessage = "";
    }

    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }

    @action setNotes(value: any) {
        this.notes = value;
    }

    showLongFileNameError() {
        this.isMessgeVisible = false;
        this.response = {};
        var responseModel = {
            status: 400,
            title: Constants.LongFileName,
            errors: { "": [] },
        };
        this.isMessgeVisible = true;
        PageContext.setIsMessageVisible(false);
        this.response = JSON.stringify(responseModel);
    }

    @action loadAllLookupList = async () => {
        var defaultItem = { label: "None", value: "0" };
        try {
            this.isMessgeVisible = false;
            this.customerDocLoad = await DocumentStore.loadAllDocList(this.customerId, +this.selectedPolicy, +this.selectedType)
                if (this.customerDocLoad) {
                    // Load policy dropdown
                    if (this.customerDocLoad.policyList != null && this.customerDocLoad.policyList.length > 0) {
                        this.policyList.values = []
                        this.customerDocLoad.policyList.forEach(obj => {
                            var data = { label: obj.name ? obj.name : "", value: obj.policyID ? ""+obj.policyID : "0" };
                            this.policyList.values.push(data);
                        });
                        // this.policyList.values.unshift({label:"Select",value:"9999999999999999"});
                        this.selectedPolicy = this.selectedPolicy && this.selectedPolicy !== "0" ? this.selectedPolicy : this.policyList.values[0].value
                    }
                    else {
                        this.policyList.values = [];
                        // this.policyList.values.splice(0, 0, defaultItem);
                        this.selectedPolicy = "";
                    }
                    // Load type dropdown
                    if (this.customerDocLoad.fileTypeList != null && this.customerDocLoad.fileTypeList.length > 0) {
                        this.documentTypeList.values = []
                        this.customerDocLoad.fileTypeList.forEach(obj => {
                            var documentData = { label: obj.name ? obj.name : "", value: obj.id ? ""+obj.id : "0" };
                            this.documentTypeList.values.push(documentData);
                        });
                        this.selectedType = this.customerDocLoad.documentTypeSelected ? ""+this.customerDocLoad.documentTypeSelected :"1" //setting defaults to application
                        this.notifyAgent = this.selectedType === "1" ? true : false;
                    }
                    else {
                        this.documentTypeList.values = [];
                        // this.documentTypeList.values.splice(0, 0, defaultItem);
                        this.selectedType = "";
                    }
                    if(this.selectedPolicy && this.selectedType){
                        await this.getSubmissionList()
                    }
                    // Load scanned docs dropdown
                    if (this.customerDocLoad.scannedDocumentsDataList != null) {
                        this.workFlowList.values = []
                        this.customerDocLoad.scannedDocumentsDataList.forEach(obj => {
                        var workFlowData = { label: obj.name ? obj.name : "", value: obj.id ? ''+obj.id : "0" };
                        this.workFlowList.values.push(workFlowData);
                        this.isWorkflowListEmpty = false;
                    });
                        this.selectedWorkFlow = this.customerDocLoad.scannedDocumentsDataList ? ""+this.customerDocLoad.scannedDocumentsDataList : "0"
                    }
                    else {
                        this.workFlowList.values = []
                    }
                
                }
            

        } catch (e) {
            this.documentTypeList.values = [];
            this.policyList.values = [];
            this.policyList.values.splice(0, 0, defaultItem);
            this.selectedPolicy = "";
            this.workFlowList.values = [];
        }
        this.isLoading = false;
    }

    @observable isSubmissionChecked: boolean = false;
    @observable isSubmissionEnabled: boolean = true;
    @action getSubmissionList = async () => {
        this.submissionList.values = [];
        if (this.selectedPolicy !== "0") {
            try {
                this.isLoading = true;
                this.isMessgeVisible = false;
                this.response = {};
                var defaultItem = { label: "Select", value: "0" };
                var result = await DocumentStore.getSubmissionTypesByCarrierId(
                    parseInt(""+this.selectedPolicy),
                    parseInt(""+this.selectedType),
                    this.customerId,
                );
                if (result != null) {
                    if (result != null) {
                        this.submissionList.values = [];
                        this.submissionList.values = this.mapListItemAndSort(result.submissionList || []);
                        this.selectedSubmission = "0";
                        if (result.submissionList && result.submissionList.length > 0) {
                            var selected = result.submissionList.filter((item: any) => { return item.selected === true });
                            if (selected && selected.length > 0) {
                                this.selectedSubmission = selected[0].value || "0";
                            }
                        }
                        this.isSubmissionChecked = result.isSubmissionChecked || false;
                        this.isSubmissionEnabled = result.isSubmissionEnabled ? true : false;
                    } else {
                        this.submissionList.values = [];
                        this.submissionList.values.splice(0, 0, defaultItem);
                        this.selectedSubmission = "0";
                    }
                    
                } else {
                    this.submissionList.values = [];
                    this.submissionList.values.splice(0, 0, defaultItem);
                    this.selectedSubmission = "0";
                }
            } catch (e:any) {
                this.submissionList.values = [];
                this.selectedSubmission = "0";
                this.isMessgeVisible = true;
                this.response = e.response;
            }
            this.isLoading = false;
        }
    };

    @action mapListItemAndSort(listObjects: any) {
        if (listObjects) {
         listObjects.splice(0, 0, { text: "Select", value: "0" , selected: false});
          var sortList = listObjects.sort(Utils.compareListItem);
          sortList.forEach((element: any) => {
            element.label = element.text;
          });          
          return sortList;
        }
        else {
          return [];
        }
    }

    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }

    @action
    public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action onDrop = (value: any, fileName: string) => {
        this.fileData = value;
        this.fileName = fileName;
    }

    @action getWorkflowList = async() =>{
        var result = await DocumentStore.getCustomerWorkflowList(this.selectedPolicy ? +this.selectedPolicy : 0);
        if (result && result.length > 0) {
            this.workFlowList.values = []
            result.forEach((obj: ScannedDocumentsDataEntryView) => {
                var workFlowData = { label: obj.name ? obj.name : "", value: obj.id ? ''+obj.id : "0" };
                this.workFlowList.values.push(workFlowData);
            });
            this.isWorkflowListEmpty = false;
        }
        else{
            this.isWorkflowListEmpty = true;
        }

    }

    @action CreateUpdateDocumentModel() {
        var selectedSubmissionObj = this.submissionList.values.filter((item:any)=> {return item.value === this.selectedSubmission });
        var dto: any = {
            customerId: this.customerId,
            typeId: parseInt(""+this.selectedType),
            policyId: parseInt(""+this.selectedPolicy),
            notes: this.notes,
            fileData: this.fileData,
            isSendAgentEmailChecked: this.notifyAgent,
            documentId: this.selectedWorkFlow ? parseInt(this.selectedWorkFlow) : 0,
            isSendSubmission: this.isSubmissionChecked,
            submissionTypeIds: this.selectedSubmission,
            submissionTypeText: selectedSubmissionObj && selectedSubmissionObj.length > 0 && selectedSubmissionObj[0].label,
            fileName: this.fileName
        }
        return dto;
    }

    @action
    public onSave = async () => {
        await this.ResetValidate();
        if (!await this.Validate()) {
            var dto = this.CreateUpdateDocumentModel();
            this.isMessgeVisible = false;
            this.response = {};
            // Custom validations
            var responseModel = {
                status: 400,
                title: Constants.NoFile,
                errors: { "": [] },
            };
            if ((dto.fileData === "" || dto.fileData === undefined || dto.fileData === null) &&
                (dto.documentId === 0 || dto.documentId === undefined ||  isNaN(dto.documentId)  || dto.documentId === null)) {
                this.isMessgeVisible = true;
               console.log("save method 222", )
                this.response = JSON.stringify(responseModel);
                PageContext.setIsMessageVisible(false);
                return false;
            }
            this.SaveDocument(dto);
        }
    }

    @action SaveDocument = async (dto: any) => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        try {
            await DocumentStore.CreateCustomerDocument(dto);
            this.goBack();
        } catch (e:any) {
            this.isLoading = false;
            this.isMessgeVisible = false;
            this.response = e.response;
        }
    }

    @action
    public goBack = async () => {        
            routes.customerDocs.replace({ customerId: "" + this.customerId});       
    }

}

class CustomerDocumentValidator extends AbstractValidator<AddCustomerDocViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.selectedType)
            .isNotEmpty()
            .isNotEqualTo('0')
            .withFailureMessage("Type is required");
        this.validateIfString(input => input.selectedType)
            .isNotEmpty()
            .isNotEqualTo('0')
            .withFailureMessage("Type is required");
        this.validateIfString((input) => input.selectedSubmission)
          .isNotEmpty()
          .isNotEqualTo("0")
          .withFailureMessage("Submit To is required")
          .when((input) => input.isSubmissionChecked);
        this.validateIfString((input) => input.selectedPolicy)
        .isNotEmpty()
        .isNotEqualTo("0")
        .withFailureMessage("Policy is required")
        .when((input) => input.selectedType == "1");
    }
}

