import { ICoordinator } from "./ICoordinator";

export abstract class Coordinator implements ICoordinator{
    childCoordinators: ICoordinator[] = new Array<ICoordinator>();
    abstract start(): void;

    store(coordinator: Coordinator)
    {
        this.childCoordinators.push(coordinator);
    }

    free(coordinator: Coordinator) 
    {
        this.childCoordinators = this.childCoordinators.filter((value) => value !== coordinator);
    }
}