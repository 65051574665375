export enum ContractDetails {
  Info = "Contract Details",
  Hierarchy = "Hierarchy",
  Communications = "Communications",
  Documents = "Documents",
  Principals = "Principals",
  Locations = "Locations",
  Appointments = "Appointments",
  PaymentPlans = "Payment Plans",
  AddEditPaymentPlan = "AddEditPaymentPlan",
  Persistency = "Persistency",
  States = "States",
}
export enum ContractStatus {
  Active = "Active",
  Terminated = "Terminated",
  Pending = "Pending",
}
export enum NotificationTemplateName {
  ContractUpdateTOH = "Contracting Update with TOH email ID",
  ContractUpdateLoginUser = "Contracting Update without TOH email ID",
}
 
