
export enum WorkflowStatusType {
    CompletedQueue = 'Complete',
    ReceivedQueue = 'Received',
    ScrubQueue = 'Scrub',
    MissingInfoQueue = 'Missing Info',
    DataEntryQueue = 'Data Entry',
    PendingQueue = 'Pending',
    AssignmentQueue = 'Assignment'
}

export enum DocumentType {
    ContractDocType = 'Contract',
    ContractChangeDocType = 'Contract Change',
    ContractInviteDocType = 'Contract Invite',
    ApplicationDocType = 'Application',
    MarketerChangeDocType = 'Marketer Change',  
    NonWorkflowDocType = 'Non-Workflow Direct-to-User',
    W9DocType ='W-9'
}

export enum MainWorklowTab {
    DocWorkflow = "Workflow",
    SearchWorkflowDocs = "Search Workflow Docs",
}

export enum WorkflowTabModelViewTypes {
    Workflow = "Workflow",
    SearchWorkflowDocs = "SearchWorkflowDocs",
    DocumentInfo = "DocumentInfo",
    LicenseCommunication = "LicenseCommunication"
}