import { action, toJS } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  CreateUpdatePaymentPlanModel,
  CreateUpdatePolicyNoteModel,
  CreateUpdateExcessPremiumModel,
  CreateUpdateSingleLifePremiumModel,
  CreateUpdateAnnuityPremiumModel,
  CreateUpdateCustomerModel,
  CreateUpdateCustomerNotesModel,
  CreateUpdatePolicyNumberModel,
  FileType,
  CreateUpdatePolicyModel,
  Client as InsuredServiceClient,
  PoliciesInfoRequest,
  PoliciesSummaryRequest,
  TripStandingsRequest,
  CreateUpdateNoteModel,
} from "../services/InsuredService";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import { routes } from "../router";
import { googleTagManager } from "../infrastructure/tracking";

class InsuredStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): InsuredServiceClient => {
    return ServiceClient.getClient(ServiceType.Insured);
  };
  @action getPolicies = async (
    agentId: number,
    companyId: number,
    policyStatusId: number,
    policyNumber: string,
    insuredName: string | undefined,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllPolicy(
      agentId,
      this.getApiVersion(),
      companyId,
      policyStatusId,
      policyNumber,
      insuredName,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getTripPoints = async (
    agentId: number,
    startDate: Date,
    endDate: Date,
    tripCategoryId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    var result = await this.getClient().getAllTripPointsByAgent(
      agentId,
      startDate,
      endDate,
      this.getApiVersion(),
      tripCategoryId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getPolicySummary = async (policyId: number, profileId: number) => {
    var result = await this.getClient().getSummaryByProfileId(
      policyId,
      this.getApiVersion(),
      profileId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPolicyNumber = async (searchType: number, searchText: string) => {
    var result = await this.getClient().getAllPolicyNumber(
      this.getApiVersion(),
      searchType,
      searchText,
      UserContext.getUserId()
    );
    return result;
  };

  @action getInsuredDetails = async (
    searchType: number,
    searchText: string
  ) => {
    var result = await this.getClient().getAllInsuredDetails(
      this.getApiVersion(),
      searchType,
      searchText,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPolicyDetails = async (policyId: number) => {
    var result = await this.getClient().getPolicyById(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getPolicySummaryById = async (policyId: number) => {
    var result = await this.getClient().getPolicySummaryById(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action addPolicySummary = async (dto: CreateUpdatePolicyModel) => {
    var result = await this.getClient().createPolicy(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editPolicySummary = async (dto: CreateUpdatePolicyModel) => {
    var result = await this.getClient().updatePolicy(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action getCommisionReceivedAlreadyExist = async (
    policyId: number | undefined,
    renewalId: number | undefined,
    commissionReceived: string | undefined,
    statementDate: Date | undefined
  ) => {
    return await this.getClient().getCommisionReceivedAlreadyExist(
      this.getApiVersion(),
      renewalId,
      policyId,
      commissionReceived,
      statementDate
    );
  };
  @action getPolicyPaymentPlan = async (policyId: number) => {
    var result = await this.getClient().getPaymentPlanByPolicyId(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action editPolicyPaymentPlan = async (dto: CreateUpdatePaymentPlanModel) => {
    var result = await this.getClient().updatePaymentPlanForPolicy(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action getPolicyStatusesLookup = async () => {
    var result = await this.getClient().getPolicyStatusesLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getPolicyStatus = async (policyId: number) => {
    var result = await this.getClient().getStatusById(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  //@action editPolicyStatus = async (dto: CreateUpdateStatusModel) => {
  //
  //    var result = await this.getClient().updateStatus(
  //      this.getApiVersion(),
  //      UserContext.getUserId(),
  //      undefined,
  //      dto
  //    );
  //    return result;
  //
  //};
  @action getPoliciyNotes = async (
    policyId: number,
    policyNoteTypeId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllNotesByPolicy(
      policyId,
      this.getApiVersion(),
      policyNoteTypeId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action addPolicyNotes = async (dto: CreateUpdatePolicyNoteModel) => {
    var result = await this.getClient().createNotes(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editPolicyNotes = async (dto: CreateUpdatePolicyNoteModel) => {
    var result = await this.getClient().updateNotes(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action getPolicySingleLifePremiums = async (policyId: number) => {
    var result = await this.getClient().getAllSingleLifePremium(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getSinglePremiumDetails = async (id: number) => {
    var result = await this.getClient().getSingleLifePremiumById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action deleteSingleLifePremium = async (id: number) => {
    var result = await this.getClient().deleteSingleLifePremium(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addSingleLifePremium = async (
    dto: CreateUpdateSingleLifePremiumModel
  ) => {
    var result = await this.getClient().createSingleLifePremium(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editSingleLifePremium = async (
    dto: CreateUpdateSingleLifePremiumModel
  ) => {
    var result = await this.getClient().updateSingleLifePremium(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action getPolicyExcessPremium = async (policyId: number) => {
    var result = await this.getClient().getAllExcessPremium(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getCommissionReceived = async (policyId: number) => {
    var result = await this.getClient().getAllCommissionReceivedByPolicy(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getExcessPremiumDetails = async (id: number) => {
    var result = await this.getClient().getExcessPremiumById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action deleteExcessPremium = async (id: number) => {
    var result = await this.getClient().deleteExcessPremium(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addExcessPremium = async (dto: CreateUpdateExcessPremiumModel) => {
    var result = await this.getClient().createExcessPremium(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editExcessPremium = async (dto: CreateUpdateExcessPremiumModel) => {
    var result = await this.getClient().updateExcessPremium(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action getPolicyAnnuityPremiums = async (policyId: number) => {
    var result = await this.getClient().getAllAnnuityPremium(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAnnuityPremiumDetails = async (id: number) => {
    var result = await this.getClient().getAnnuityPremiumById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action deleteAnnuityPremium = async (id: number) => {
    var result = await this.getClient().deleteAnnuityPremium(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addAnnuityPremium = async (dto: CreateUpdateAnnuityPremiumModel) => {
    var result = await this.getClient().createAnnuityPremium(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editAnnuitPremium = async (dto: CreateUpdateAnnuityPremiumModel) => {
    var result = await this.getClient().updateAnnuityPremium(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action getAgentsLookup = async (
    searchParam: string,
    status: string,
    searchType: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {  
      googleTagManager.trackAction("agent_search_conducted", {
          feature: routes.addPolicy.name,
          user_id: UserContext.userId,
          search_text: searchParam
      });

    var result = await this.getClient().getAgentsLookUp(
      this.getApiVersion(),
      searchParam,
      status,
      searchType,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  // @action getProductsByProfileId = async (profileId: number) => {
  //     var result = await this.getClient().getProductByProfile(
  //       profileId,
  //       this.getApiVersion(),
  //       UserContext.getUserId()
  //     );
  //     return result;
  // };

  @action getCustomerList = async (
    searchType: number,
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined,
    isSearchInsuredID: boolean | null | undefined = false,
  ) => {
    var result = await this.getClient().getAllCustomer(
      this.getApiVersion(),
      searchType,
      isSearchInsuredID != null ? isSearchInsuredID : false,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.getUserId()
    );
    return result;
  };
  @action getPolicyNumbers = async (policyId: number) => {
    var result = await this.getClient().getPolicyNumber(
      this.getApiVersion(),
      policyId,
      UserContext.getUserId()
    );
    return result;
  };
  @action checkPolicyNumber = async (policyNumber: string) => {
    var result = await this.getClient().checkPolicyNumberExists(
      this.getApiVersion(),
      policyNumber,
      UserContext.getUserId()
    );
    return result;
  };
  @action postPolicyNumber = async (dto: CreateUpdatePolicyNumberModel[]) => {
    var result = await this.getClient().updatePolicyNumbers(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action getRenewalHistory = async (policyId: number) => {
    var result = await this.getClient().getRenewalHistory(
      policyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action moveRenewal = async (fromPolicyId: number, toPolicyId: string) => {
    var result = await this.getClient().moveRenewal(
      this.getApiVersion(),
      fromPolicyId,
      toPolicyId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getCustomerDetails = async (customerId: number) => {
    var result = await this.getClient().getCustomerById(
      customerId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getAllInsuredNotes  = async (insuredId: number,status:any,pageIndex:number,pageSize:number,sortColumn:string,sortAscending: boolean,fullTextFilter:string) => {
    var result = await this.getClient().getAllInsuredNotes(
      this.getApiVersion(),
      insuredId,
      status,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };


  @action addInsuredNote  = async (body:CreateUpdateNoteModel) => {
    var result = await this.getClient().addInsuredNote(
      this.getApiVersion(), 
      UserContext.getUserId(),
      UserContext.getUserId(),
      body
    );
    return result;
  };

  @action updateInsuredNote  = async (body:CreateUpdateNoteModel) => {
    var result = await this.getClient().updateInsuredNote(
      this.getApiVersion(), 
      UserContext.getUserId(),
      UserContext.getUserId(),
      body
    );
    return result;
  };

  @action deleteInsuredNote  = async (id:number) => {
    var result = await this.getClient().deleteInsuredNote( 
      id,
      this.getApiVersion(), 
      UserContext.getUserId(),
      UserContext.getUserId(),
      
    );
    return result;
  };

  @action addCustomer = async (customerDto: CreateUpdateCustomerModel) => {
    var result = await this.getClient().createCustomer(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      customerDto
    );
    googleTagManager.trackAction("insured_created", {
        feature: "Insured",
        user_id: UserContext.userId,
        insured_id: result
    });
    return result;

     
  };

  @action editCustomer = async (customerDto: CreateUpdateCustomerModel) => {
    var result = await this.getClient().updateCustomer(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      customerDto
    );
    return result;
  };

  @action deleteCustomer = async (customerId: number) => {
    var result = await this.getClient().deleteCustomer(
      customerId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getCustomerSummary = async (customerId: number) => {
    var result = await this.getClient().getCustomerDetailById(
      customerId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getCustomerPolicies = async (
    customerId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllPoliciesByCustomer(
      customerId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };

  @action getCustomerNotes = async (
    customerId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllNotesByCustomer(
      customerId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };

  @action addCustomerNote = async (
    customerId: string,
    customerDto: CreateUpdateCustomerNotesModel
  ) => {
    var result = await this.getClient().createCustomerNotes(
      this.getApiVersion(),
      customerId,
      UserContext.userId,
      undefined,
      customerDto
    );
    return result;
  };

  @action downloadAgentPolicies = async (
    agentId: number,
    fileType: number,
    serachComapnyId: string,
    searchStatusId: string,
    searchPolicyNumber: string,
    insuredName: string | undefined
  ) => {
    var companyid = serachComapnyId ? parseInt(serachComapnyId) : 0;
    var statusId = searchStatusId ? parseInt(searchStatusId) : 0;

    var result = await this.getClient().getPolicyReport(
      this.getApiVersion(),
      agentId,
      fileType,
      companyid,
      statusId,
      searchPolicyNumber,
      insuredName,
      UserContext.userId
    );
    return result;
  };

  @action deletePolicy = async (policyId: number) => {
    var result = await this.getClient().deletePolicy(
      policyId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action askForCustomerMerge = async (body: any) => {
    var result = await this.getClient().mergeCustomerPreview(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };

  @action mergeCustomers = async (body: any) => {
    var result = await this.getClient().mergeCustomer(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };

  @action policiesInfoReport = async (
    agentId?: number | undefined,
    productId?: number | undefined,
    uplineId?: number | undefined,
    marketerIds?: number[] | undefined,
    companyId?: number | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    policyStatuses?: number[] | undefined,
    whichDate?: string | undefined,
    leadSource?: number | undefined,
    customerState?: number | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    hidePriviteNotes?: boolean | undefined,
    fileType?: FileType | undefined
  ) => {
    if (policyStatuses && policyStatuses.length > 0) {
      policyStatuses = policyStatuses.map((i) => Number(i));
    }
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    var request: PoliciesInfoRequest = {
      agentId: agentId,
      productId: productId,
      uplineId: uplineId,
      marketerIds: marketerIds ? marketerIds : [],
      companyId: companyId,
      startDate: startDate,
      stopDate: stopDate,
      policyStatuses: policyStatuses ? policyStatuses : [],
      whichDate: whichDate,
      leadSource: leadSource,
      customerState: customerState,
      licenseStates: licenseStates ? licenseStates : [],
      homeStates: homeStates ? homeStates : [],
      categories: categories ? categories : [],
      subCategories: subCategories ? subCategories : [],
      hidePriviteNotes: hidePriviteNotes,
      fileType: fileType,
    };
    var result = await this.getClient().getPoliciesInfoReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  @action policiesSummaryReport = async (
    agentId?: number | undefined,
    productId?: number | undefined,
    uplineId?: number | undefined,
    marketerIds?: number[] | undefined,
    companyId?: number | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    policyStatuses?: number[] | undefined,
    whichDate?: string | undefined,
    leadSource?: number | undefined,
    customerState?: number | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    fileType?: FileType | undefined
  ) => {
    if (policyStatuses && policyStatuses.length > 0) {
      policyStatuses = policyStatuses.map((i) => Number(i));
    }
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    var request: PoliciesSummaryRequest = {
      agentId: agentId,
      productId: productId,
      uplineId: uplineId,
      marketerIds: marketerIds ? marketerIds : [],
      companyId: companyId,
      startDate: startDate,
      stopDate: stopDate,
      policyStatuses: policyStatuses ? policyStatuses : [],
      whichDate: whichDate,
      leadSource: leadSource,
      customerState: customerState,
      licenseStates: licenseStates ? licenseStates : [],
      homeStates: homeStates ? homeStates : [],
      categories: categories ? categories : [],
      subCategories: subCategories ? subCategories : [],
      fileType: fileType,
    };
    var result = await this.getClient().getPoliciesSummaryReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action tripStandingsReport = async (
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    homeStates?: string[] | undefined,
    licenseStates?: number[] | undefined,
    uplineId?: number | undefined,
    marketerIds?: number[] | undefined,
    fileType?: FileType | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    var request: TripStandingsRequest = {
      startDate: startDate,
      stopDate: stopDate,
      homeStates: homeStates ? homeStates : [],
      licenseStates: licenseStates ? licenseStates : [],
      uplineId: uplineId,
      marketerIds: marketerIds ? marketerIds : [],
      fileType: fileType,
    };
    var result = await this.getClient().getTripStandingsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action getCalculatedCommission = async (
    productId: number,
    cashWithApp: number
  ) => {
    var result = await this.getClient().getCalculatedCommission(
      this.getApiVersion(),
      productId,
      cashWithApp,
      UserContext.userId
    );
    return result;
  };

  @action validateCustomerDetails = async (
    key: string,
    value: string,
    id: number,
    isCreateMode: boolean
  ) => {
    var result = await this.getClient().validateCustomerDetails(
      this.getApiVersion(),
      value,
      key,
      isCreateMode,
      id,
      UserContext.userId
    );
    return result;
  };

    @action getAllContact = async (
        customerId: number,
        startIndex: number,
        pageSize: number,
        sortColumn: string | undefined,
        sortAscending: boolean | undefined,
        fullTextFilter: string | undefined
    ) => {
    var result = await this.getClient().getAllInsuredContact(
      this.getApiVersion(),
      customerId,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action getContactTypes = async () => {
    return await this.getClient().getInsuredContactTypes(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action addContact = async (contactDto: any) => {
    var result = await this.getClient().createInsuredContact(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action editContact = async (contactDto: any) => {
    var result = await this.getClient().updateInsuredContact(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action getContactDetails = async (contactId: number) => {
    var result = await this.getClient().getInsuredContactById(
      contactId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getPolicyLocations = async (contactId: number) => {
    var result = await this.getClient().getPolicyLocationLookupByContract(
      contactId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };


  @action deleteContact = async (contactId: number) => {
    var result = await this.getClient().deleteInsuredContact(
      contactId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action defaultContact = async (
    contactId: number,
    customerId: number,
    contactTypeId: number,
    defaultContact: boolean
  ) => {
    var result = await this.getClient().insuredMakeDefault(
      customerId,
      contactId,
      contactTypeId,
      this.getApiVersion(),
      defaultContact,
      UserContext.getUserId()
    );
    return result;
  };
  @action verifyContactPhoneNumber = async (contactDto: any) => {
    var result = await this.getClient().validatePhoneNumber(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action getTripCategory = async () => {
    var result = await this.getClient().getTripCategory(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action pinUnpinNote = async (noteId: number, markAsRevenueNote: boolean, policyId: number) => {
      var result = await this.getClient().checkRevenueNote(
          noteId,
          markAsRevenueNote,
          policyId,
          this.getApiVersion(),
          UserContext.getUserId()
      );
      return result;
  };
}
export default new InsuredStore();
