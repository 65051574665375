import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { getCurrentRoute, routes } from "../../router";
import * as typeRoute from "type-route";
import { SelfServiceReportsListViewModel } from "./SelfServiceReportsListViewModel";
import { CustomReportAddEditViewModel } from "./customReports/CustomReportAddEditViewModel";

export class SelfServiceReportDashboardViewModel implements ViewModel {
  @observable route: typeRoute.Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable SelfServiceReportsListViewModel: SelfServiceReportsListViewModel =
    new SelfServiceReportsListViewModel();
  @observable CustomReportAddEditViewModel: CustomReportAddEditViewModel =
    new CustomReportAddEditViewModel();

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    const routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    if (
      currentRoute.name === routes.reports.name ||
      currentRoute.name === routes.customReports.name ||
      currentRoute.name === routes.standardReports.name ||
      currentRoute.name === routes.reportsQueue.name ||
      currentRoute.name === routes.reportsQueueDownload.name
    ) {
      if (
        !(this.SelectedViewModel instanceof SelfServiceReportsListViewModel)
      ) {
        this.SelectedViewModel = this.SelfServiceReportsListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addCustomReport.name ||
      currentRoute.name === routes.editCustomReport.name ||
      currentRoute.name === routes.previewCustomReport.name
    ) {
      if (!(this.SelectedViewModel instanceof CustomReportAddEditViewModel)) {
        this.SelectedViewModel = this.CustomReportAddEditViewModel;
      }
      if (currentRoute.name === routes.addCustomReport.name) {
        await this.SelectedViewModel.Load(0, false);
      } else if (currentRoute.name === routes.editCustomReport.name) {
        await this.SelectedViewModel.Load(routeParamObj.id, false);
      } else if (currentRoute.name === routes.previewCustomReport.name) {
        await this.SelectedViewModel.Load(routeParamObj.id, true);
      }
      await this.SelectedViewModel.Route(currentRoute);
    }
  };
}
