import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { DateSelectArg, EventChangeArg, EventInput } from "@fullcalendar/react";
import ProducerStore from "../../store/ProducerStore";
import { MarketingEventLookupRepsonse } from "../../services/ProducerService";
import Utils from "../../infrastructure/Utils";

export class MarketingEventsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @action Load = async () => {
    window.scrollTo(0, 0);
    this.Loaded();
    this.currentMonth = this.monthNames[this.currentDate.getMonth()];
    await this.getAllMarketingEvent();
  };
  @action Loaded = () => {};

  @observable monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  @observable currentDate = new Date();
  @observable initialView : string = "dayGridMonth";
  @observable currentMonth: string = "";
  @observable isLoading: boolean = false;
  @observable lookups: MarketingEventLookupRepsonse = {};
  @observable format: string | undefined = "";
  @observable owner: string | undefined = "";
  @observable sendToEmail: string | undefined = '';

  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable
  weekendsVisible = true;

  @observable events: EventInput[] = [];

  private eventGuid = 0;

  @action getAllLookups = async () => {
    this.isLoading = true;
    try {
      let result = await ProducerStore.getmarketingeventlookup();
      if (result) this.lookups = result;
    } catch (e) {}
  };

  @action getAllMarketingEvent = async () => {
    var calendarVisibleDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(), 
      new Date().getDate()
      )
    let result = await ProducerStore.getAllMarketingEvents(calendarVisibleDate);
    this.sendToEmail = result && result.marketingEmail ? result.marketingEmail : ''
    if (result.marketingEventsList && result.marketingEventsList.length > 0) {
      let temp: EventInput[] = [];
      let response :any = result.marketingEventsList
      response.forEach((i:any) => {
       let abc =  `${Utils.getTimeInFormat(i.displayStartDate)}  ${
          i.marketingFormatName ? i.marketingFormatName : ""
        }  - 
        ${i.title ? i.title : ""}`
          if (i.displayStartDate && i.dateTimeEnd && new Date(i.displayStartDate).getDate() == new Date(i.dateTimeEnd).getDate()) {
              if (abc.length > 48) { abc = abc.substring(0, 47) + "..."; }
          }
        let eventModel: EventInput = {
          id: i.id ? i.id.toString() : "",
          title: abc,
          start: i.displayStartDate ? i.displayStartDate : new Date(),
          end: i.dateTimeEnd ? i.dateTimeEnd : new Date(),
        };
        temp.push(eventModel);
      });
      this.events=[];
      this.events = temp;
      
      window.scrollTo(0,200);
     
      setTimeout(
        ()=>{
          var targetDiv  = Array.from(document.getElementsByClassName("fc-dayGridMonth-button") as HTMLCollectionOf<HTMLElement> );
          targetDiv && targetDiv[0] && targetDiv[0].click();
        },400
      )
     
    }
  };

  // @observable
  // events: EventInput[] = [
  //   {
  //     id: this.createEventId(),
  //     title: "All-day event",
  //     start: new Date(),
  //     allDay: true,
  //   },
  //   {
  //     id: this.createEventId(),
  //     title: "Timed event",
  //     start: new Date(),
  //     allDay: false,
  //   },
  // ];

  // getEvents(): EventInput[] {
  //   return this.events;
  // }

  private createEventId() {
    return String(this.eventGuid++);
  }

  @action
  addEvent(selectInfo: DateSelectArg, title: string | null) {
    this.events.push({
      id: this.createEventId(),
      title: title || "New Event",
      start: selectInfo.start,
      end: selectInfo.end,
      allDay: selectInfo.allDay,
    });
  }

  @action
  deleteEvent(id: string) {
    this.events.splice(
      this.events.findIndex((e: any) => e.id === id),
      1
    );
  }

  @action
  changeEvent(changeInfo: EventChangeArg) {
    const newEvent = changeInfo.event;
    const storedEvent = this.events.find(
      (e: any) => e.id === changeInfo.event.id
    );
    if (storedEvent) {
      storedEvent.title = newEvent.title;
      storedEvent.allDay = newEvent.allDay;
      storedEvent.start = newEvent.start || storedEvent.start;
      storedEvent.end = newEvent.end || storedEvent.end;
    }
  }

  @action
  toggleWeekends() {
    this.weekendsVisible = !this.weekendsVisible;
  }
}
