import { observable, action} from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import TaskStore from "../../store/TaskStore";
import { TaskListModel, ReportFile } from "../../services/TaskService";
import IMASLog from "../../infrastructure/IMASLog";
import Utils from "../../infrastructure/Utils";
import DocumentStore from "../../store/DocumentStore";

export class AgentTasksViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean { 
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @action Load = async () => {

    };
    Route = async (): Promise<void> => { }

    @observable SelectedTasks = [
    ]

    @observable selectedStatus: string = 'Open';
    @observable selectedStatusonSubmit: string = 'Open';
    @observable taskCheckboxDisabled: boolean = false;
    @observable taskDate: any = null;
    @observable isLoading: boolean = true;
    @observable startIndex: number = 0;
    @observable startSearchIndex: number = 0;
    @observable agentId: number = 0;
    @observable taskId: number = 0
    @observable rows: number = 25;
    @observable isDeleteTaskConfirm: boolean = false
    @observable selectedType: string = '0';
    @observable selectedUser: string = '0';
    @observable searchValue: string | undefined;
    @observable totalRecords: number = 0;
    @observable TaskList = new Collection<TaskListModel>();
    @observable searchInputValue: string = '';
    @observable first: number = 0;
    @observable sortColumn: string = 'id';
    @observable sortOrder: boolean = false;
    @observable isMultipleCompleteTaskConfirm: boolean = false;
    @observable taskDetailsChangeDetector: number = 1;
    @observable taskCompleteSelection: any = [];
    @observable priority = [
        { label: 'Low', value: '1' },
        { label: 'Medium', value: '2' },
        { label: 'High', value: '3' }
    ]    

    @observable statusData = [
        { label: 'All', value: 'All' },
        { label: 'Open', value: 'Open' },
        { label: 'Completed', value: 'Completed' }
    ]
        
    @action noRecordsFound() {
        this.totalRecords = 0
        this.TaskList.values = []
    }
    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows
        this.loadTasks()
    }

    @action load = async (id: number) => {
        this.agentId = id;
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'id';
        this.sortOrder = false;
        this.selectedStatus = "Open";        
        this.rows = 25;
        this.taskCompleteSelection = [];
       this.noRecordsFound();
        await this.loadTasks();
    };   

    @action loadTasks = async () => {
        this.isLoading = true;
        try {
            this.selectedStatusonSubmit = this.selectedStatus;
            let statusoption = this.selectedStatus === 'Completed' ? 1 : 0;
            let status = this.selectedStatus === 'All' ? -1 : statusoption;
           
            var agentTasks = await TaskStore.getAgentTasks(this.agentId, status,
                this.taskDate, this.searchValue, this.startIndex, this.rows, this.sortColumn, this.sortOrder)

            if (agentTasks != null) {
                if (agentTasks.data) {
                    this.totalRecords = (agentTasks.recordCount) ? agentTasks.recordCount : 0;
                    this.TaskList.values = agentTasks.data;
                }
                else {
                    this.noRecordsFound()
                }
            }
        } catch (e) {
            IMASLog.log('exception from store: ' + e.value)
            this.noRecordsFound()
        }
        this.isLoading = false;
    }
    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);                 
                    link.href = url;
                    link.download = name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
           });
        }
    };

    @action generateReport = async () => {
        try {
            this.isLoading = true;
            let statusoption = this.selectedStatus === 'Closed' ? 1 : 0;
            let status = this.selectedStatus === 'All' ? -1 : statusoption;
            let result = await TaskStore.downloadAgentTasks(this.agentId, status, this.taskDate, this.searchValue);
            this.downloadReport(result, "AgentTasks");
            this.isLoading = false;
        } catch(error) {
          IMASLog.log('exception while dowloading agent tasks.')
       }
    };

    @action setSelectedTask(taskId: number) {
        this.taskId = taskId
    }

    @observable isMessgeVisible: boolean = false;
    @observable response: any;

    @action onDeleteTaskClick = async () => {
        try {
            await TaskStore.deleteTask(this.taskId)
            this.setDeleteTaskConfirmation(false)
            setTimeout(async () => {
                await this.loadTasks()
            }, Utils.timeDelay_Delete());
            
        } catch (error) {
            IMASLog.log('exception while deleting task.')
        }
    }

    @action setDeleteTaskConfirmation(value: boolean) {
        this.isDeleteTaskConfirm = value
    }

    @action searchTask = async (key: string, text: string) => {
        if (key === 'Enter') {
            this.loadTasks();
        }
    }

    @action goSearch = () => {
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'id';
        this.sortOrder = false; 

        this.loadTasks();
    }

    @action resetAllFields() {
        this.selectedStatus = 'Open';
        this.searchInputValue = "";
        this.searchValue = "";
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'id';
        this.sortOrder = false;
    }  
    @action resetFilters = () => {
        this.selectedStatus = 'Open';
        this.searchInputValue = "";
        this.searchValue = "";
        this.startIndex = 0;
        this.first = 0;       
        this.sortColumn = 'id';
        this.sortOrder = false;
        this.taskCompleteSelection = [];
        this.loadTasks();
    }

    @action searchIntervals = (text: string) => {
        this.searchInputValue = text;
        if (text) {
            this.searchValue = text
        }
        else {
            this.searchValue = undefined;
            this.startIndex = 0;
            this.loadTasks();
        }
    }

    compare = (a: any, b: any) => {
        // Use toUpperCase() to ignore character casing
        const nameA = a.carrierName.toUpperCase();
        const nameB = b.carrierName.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
            comparison = 1;
        } else if (nameA < nameB) {
            comparison = -1;
        }
        return comparison;
    }

    @action statusChange(value: string) {
        this.selectedStatus = value
        // this.loadTasks()
    }

    @observable isCompleteTaskConfirm: boolean = false; 

    @action setCompleteTaskConfirmation(value: boolean) {
        this.isCompleteTaskConfirm = value;
    }

    @action onCompleteTaskClick= async () => {
        try {
            await TaskStore.completeTaskByTaskId({id: this.taskId, complete: true})
            setTimeout(async () => {
                this.setCompleteTaskConfirmation(false)
                await this.loadTasks()
            }, Utils.timeDelay_Delete());
        } catch (error) {
            IMASLog.log('exception while deleting task.')
        }
    }

    @action onMultipleCompleteTaskClick = async () => {
        try {
          let tasks: any = [];
          this.taskCompleteSelection && this.taskCompleteSelection.forEach((element: any) => {
            tasks.push(parseInt(element.id));
          });
          
          await TaskStore.completeTask({ id: tasks });
          setTimeout(async () => {
            this.setMultipleCompleteTaskConfirmation(false);  
            this.taskCompleteSelection=[];      
            await this.loadTasks();
           
          }, Utils.timeDelay_Delete());
        } catch (error) {
          IMASLog.log("exception while deleting task.");
        }
    };

    @action setMultipleCompleteTaskConfirmation(value: boolean) {
        this.isMultipleCompleteTaskConfirm = value;
    }
    
    @action setTaskCompleteSelection(taskList: any) {
        if (Array.isArray(taskList)) {
            let list = taskList.filter((item) => {
              return (!item.complete && !item.profileAssociation) ;
            });
         
            this.taskCompleteSelection =list;
        }
    }

    @action downloadDocumentLink = async (docId: number, filename: string) => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        try {
            var result = await DocumentStore.downloadFiles(docId, 0, true);
            if (result != null) {
                if (result.data) {
                    Utils.downloadDocumentFile(
                        result.data,
                        result.fileName !== null && result.fileName !== undefined
                            ? result.fileName
                            : filename,
                        result.headers ? result.headers["content-type"] : result.data.type
                    );
                }
            }
        } catch (e) {
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.isLoading = false;
    };
}