import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import {getCurrentRoute, routes } from "../../router";
import { Route } from "type-route";
import { TasksViewModel } from "./TasksViewModel";
import { AddTaskViewModel } from "../agents/AddTaskViewModel";
import Utils from "../../infrastructure/Utils";

export class TasksDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
   @observable TasksViewModel: TasksViewModel = new TasksViewModel();
   @observable AddTaskViewModel: AddTaskViewModel = new AddTaskViewModel();
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    var routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
     if (currentRoute.name === routes.tasks.name) {
      if (!(this.SelectedViewModel instanceof TasksViewModel)) {
        this.SelectedViewModel = this.TasksViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }
    else if (
      currentRoute.name === routes.addMyTask.name ||
      currentRoute.name === routes.editMyTask.name
    ) {

      if (!(this.SelectedViewModel instanceof AddTaskViewModel)) {
        this.SelectedViewModel = this.AddTaskViewModel;
      }
      await this.SelectedViewModel.Load(0, routeParamObj.taskId);
      await this.SelectedViewModel.Route(currentRoute);
    }

   
  }
}
