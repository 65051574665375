import { observable, action, computed } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import { AddSupplyItemViewModel } from "./AddSupplyItemViewModel";
import Utils from "../../../infrastructure/Utils";
import {
  AddEditAddressViewModel,
  AddressListener,
} from "../../components/AddEditAddressViewModel";
import ProducerStore from "../../../store/ProducerStore";
import IMASLog from "../../../infrastructure/IMASLog";
import {
  AddressListModel,
  CreateUpdateAddressModel,
} from "../../../services/ProducerService";
import SupplyOrderStore from "../../../store/SupplyOrderStore";
import { CreateUpdateSupplyOrderModel } from "../../../services/SupplyOrderService";
import { routes } from "../../../router";
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import { AgentListModel } from "../../../services/ProducerSearchService";
import { Permission } from "../../../infrastructure/enum/Permission";
import UserContext from "../../../infrastructure/UserContext";
import { googleTagManager } from "../../../infrastructure/tracking";

export class AddSupplyOrderViewModel implements ViewModel, AddressListener {
  async updateAddress() {
    await this.addEditAddress();
  }
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  Route = async (): Promise<void> => {
    // TODO: implement or remove function
  };

  @observable orderDate: Date | undefined = new Date();
  @observable comments: string = "";
  @observable isLoading: boolean = true;
  @observable FromAgent: boolean = true;
  @observable isContractingSent: boolean = true;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable selectedUser: string = "";
  @observable selectedAddress: AddressListModel | null = null;
  @observable title: string = "Add Supply Order";
  @observable isEdit: boolean = false;
  @observable orderId: number = 0;
  @observable AgentAddressList = new Collection<AddressListModel>();
  @observable SupplyOrderModel: CreateUpdateSupplyOrderModel | undefined;
  @observable SupplyItemModelList = new Collection<any>();
  @observable SaveSupplyItemModelList = new Collection<any>();
  @observable AddSupplyItemViewModel: AddSupplyItemViewModel =
    new AddSupplyItemViewModel();
  @observable isDeleteConfirmation = false;

  mockDivision = [{}];
  @observable AddEditAddressViewModel: AddEditAddressViewModel =
    new AddEditAddressViewModel();
  @observable addrDialogTitle: string = "";
  @observable isAddAddressSuccess: boolean = false;
  @observable AddressList = new Collection<AddressListModel>();
  @observable addressTotalRecords: number = 0;
  @observable rows: number = 50;
  @observable agentId: number = 0;
  @observable routeAgentId: number = 0;
  @observable selectedAddressId: number = 0;
  @observable selectedAddressRow: any;
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable selectedTabindex: number = 0;
  @observable isAddSupplyItemSuccess: boolean = false;
  @observable divisionList = new Collection<any>();
  @observable shippingMethodList = new Collection<any>();
  @observable divisionId: string = "0";
  @observable shippingMethodId: string = "0";
  @observable isCompleted: boolean = false;
  @observable saveToNotes: boolean = false;
  @observable selectedItemRowId: number = 0;
  @observable selectOrderDetailId: number = 0;
  @observable validationMessage: string = "";
  @observable isMessgeVisible: boolean = false;
  @observable agentNote: string = "";
  @observable response: any;
  @observable isOrderCompletedStatus: boolean = false;

  @observable totalRecords: number = 100;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable sortOrder: boolean = false;
  @observable sortColumnOrder: string = "id";
  async setSortColumn(sortField: any) {
    this.sortOrder = !this.sortOrder;
    this.sortColumnOrder = sortField;
    await this.loadOrderDetails();
  }
  @action async onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
  }

  @action Load = async (
    agentId: number,
    orderId: number | undefined,
    fromAgent: boolean
  ) => {

    if (orderId) {
      this.orderId = orderId;
      this.isEdit = true;
      this.title = "Edit Supply Order";
      this.SupplyItemModelList.values = [];
    } else {
      this.orderId = 0;
      this.isEdit = false;
      this.title = "Add Supply Order";
      this.agentId = 0;
      this.AddSupplyItemViewModel.setSelectedType(0);
      this.AddSupplyItemViewModel.setSelectedItem(0);
      this.AddSupplyItemViewModel.getItems();
      this.AddSupplyItemViewModel.setSelectedQuantity(1);
      this.AddSupplyItemViewModel.ResetValidate();
      this.noRecordsFound();
      this.selectedUser = "";
      this.agentNote = "";
      if (
        this.AddSupplyItemViewModel.states &&
        this.AddSupplyItemViewModel.states.length > 0
      ) {
        const state = this.AddSupplyItemViewModel.states[1].value;
        this.AddSupplyItemViewModel.setSelectedState(state);
      } else {
        this.AddSupplyItemViewModel.setSelectedState(0);
      }
      if (
        this.AddSupplyItemViewModel.companies &&
        this.AddSupplyItemViewModel.companies.length > 0
      ) {
        const carrier = this.AddSupplyItemViewModel.companies[0].value;
        this.AddSupplyItemViewModel.setSelectedCompany(
          isNaN(carrier) ? 0 : parseInt(carrier)
        );
      } else {
        this.AddSupplyItemViewModel.setSelectedCompany(0);
      }

      this.AddEditAddressViewModel.setAddressListener(this);

      if (fromAgent) {
        this.agentId = agentId;
        this.routeAgentId = agentId;
        await this.setSelectedAgentName();
      }
      this.FromAgent = fromAgent;
      this.isLoading = false;
      this.SupplyItemModelList = new Collection<any>();
      this.setIsException(false);
      this.setIsSuccess(false);
      this.selectedAddressId = 0;
      this.shippingMethodId = "0";
      this.selectedAddressRow = null;

      this.isMessgeVisible = false;
      this.isCompleted = false;
      this.isOrderCompletedStatus = false;
    }
    await this.loadOrderDetails();

  };
  @action setSelectedAgentName = async () => {
    try {
      this.isLoading = true;
      if (this.agentId != null && this.agentId != undefined) {
        var agentInfo = await ProducerStore.getAgentProfileInfoById(this.agentId);
        if (agentInfo && agentInfo.name) {

          let nameArray = agentInfo.name.split(" ");
          if (nameArray.length > 1) {
            this.selectedUser = nameArray[1] + ", " + nameArray[0];
          } else {
            this.selectedUser = nameArray[0];
          }
        }
      }
      this.isLoading = false;
    } catch (e) {

    }
  };

  @action loadOrderDetails = async () => {
    if (this.orderId > 0) {
      const supplyOrderDetailResponse =
        await SupplyOrderStore.getSupplyOrderById(this.orderId);
      if (supplyOrderDetailResponse) {
        if (supplyOrderDetailResponse.agentName) {
          let userName = "";
          const nameArray = supplyOrderDetailResponse.agentName.split(" ");
          if (nameArray.length > 1) {
            userName = nameArray[0] + " " + nameArray[1];
          } else {
            userName = nameArray[0];
          }
          this.selectedUser = userName;
        }
        this.orderDate = supplyOrderDetailResponse.orderDate
          ? new Date(supplyOrderDetailResponse.orderDate)
          : new Date();
        this.comments = supplyOrderDetailResponse.comments
          ? supplyOrderDetailResponse.comments
          : "";
        this.shippingMethodId = supplyOrderDetailResponse.shippingMethodId
          ? supplyOrderDetailResponse.shippingMethodId.toString()
          : "0";
        this.selectedAddressId = supplyOrderDetailResponse.addressId
          ? supplyOrderDetailResponse.addressId
          : 0;
        this.divisionId = supplyOrderDetailResponse.divisionId
          ? supplyOrderDetailResponse.divisionId.toString()
          : "0";
        this.agentId = supplyOrderDetailResponse.agentId
          ? supplyOrderDetailResponse.agentId
          : 0;
        this.isOrderCompletedStatus = supplyOrderDetailResponse.isCompleted
          ? supplyOrderDetailResponse.isCompleted
          : false;
        if (supplyOrderDetailResponse.orderDetails) {
          supplyOrderDetailResponse.orderDetails.forEach((element: any) => {
            this.SupplyItemModelList.values.push({
              orderDetailsId: element.id,
              orderId: element.orderId,
              quantity: element.quantity,
              bo: element.bo,
              supplyCompany: element.supplyCompany,
              supplyState: element.supplyState,
              quantitySent: element.quantitySent,
              item: element.supplyDescription,
              supplyItemId: element.supplyItemId,
              stateId: element.supplyStateId,
              carrierId: element.supplyCompanyId,
            });
          });
        }
        await this.loadAddresses(this.agentId);
        const address = this.AddressList.values.filter((item) => {
          return item.id === this.selectedAddressId;
        });
        this.selectedAddressRow = address && address.length > 0 && address[0];
      }
    } else {
      await this.loadAddresses(this.agentId);
      this.orderDate = new Date();
      this.comments = "";
    }
    const result = await SupplyOrderStore.getSupplyOrderOptions(
      this.agentId || 0
    );
    if (result) {
      if (this.agentId > 0) {
        this.agentNote = result.ordersCountNote ? result.ordersCountNote : "";
      }

      this.AddSupplyItemViewModel.states = [];
      if (result.states) {
        for (const item of result.states) {
          this.AddSupplyItemViewModel.states.push({
            label: "" + item.stateName,
            value: "" + item.stateId,
            isHeader: item.isHeader,
          });
        }
        this.AddSupplyItemViewModel.selectedState =
          result.states[1].stateId || 0;
      }

      this.AddSupplyItemViewModel.companies = this.mapListItem(
        result.carriers
      );
      this.AddSupplyItemViewModel.types = [{ label: "All", value: "0" }].concat(
        this.mapListItemAndSort(result.productTypes)
      );
      this.shippingMethodList.values = [{ label: "None", value: "0" }].concat(
        this.mapListItemAndSort(result.shippingMethods)
      );
    }
  };
  @action loadOrderSelections = async () => {
    const result = await SupplyOrderStore.getSupplyOrderOptions(0);
    if (result) {
      if (result.states) {
        for (const item of result.states) {
          this.AddSupplyItemViewModel.states.push({
            label: "" + item.stateName,
            value: "" + item.stateId,
            isHeader: item.isHeader,
          });
        }
        this.AddSupplyItemViewModel.selectedState =
          result.states[1].stateId || 0;
      }

      this.AddSupplyItemViewModel.companies = this.mapListItem(
        result.carriers
      );
      this.AddSupplyItemViewModel.types = [{ label: "All", value: "0" }].concat(
        this.mapListItemAndSort(result.productTypes)
      );
      this.shippingMethodList.values = [{ label: "None", value: "0" }].concat(
        this.mapListItemAndSort(result.shippingMethods)
      );
    }
  };
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      const sortList = [...listObjects].sort((a, b) =>
        a.text > b.text ? 1 : -1
      );
      sortList.forEach((element: any) => {
        element.label = element.text;
      });

      return sortList;
    } else {
      return [];
    }
  }
  @action mapListItem(listObjects: any) {
    if (listObjects) {

      listObjects.forEach((element: any) => {
        element.label = element.text;
      });

      return listObjects;
    } else {
      return [];
    }
  }
  @action setIsContractingSent(value: boolean) {
    this.isContractingSent = value;
  }
  @action getMapHref = (address: AddressListModel) => {
    return (
      "https://maps.google.com/maps?q=" +
      address.latitude +
      "," +
      address.longitude +
      "&ie=UTF-8"
    );
  };
  @computed
  get agentPlaceholder(): string {
    return RouteParamDetails.agentName;
  }

  @action setSelectDate(value: Date | undefined) {
    this.orderDate = value;
  }
  @action setSelectedAddress(value: any) {
    this.selectedAddressRow = value;
  }

  @action setAddressData(value: any) {
    this.AgentAddressList.values = value;
  }
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
  }
  @action async setSelectedAgent(agentName: any, agentId: number) {
    if (agentId) {
      this.selectedUser = agentName;
      this.agentId = agentId;
      this.loadAddresses(agentId);
    } else {
      this.selectedUser = "";
      this.agentId = 0;
    }
    this.selectedAddressId = 0;
    this.selectedAddressRow = null;
    if (this.agentId > 0) {
      const result = await SupplyOrderStore.getSupplyOrderOptions(this.agentId);
      if (result) {
        this.agentNote = result.ordersCountNote ? result.ordersCountNote : "";
      }
    }
  }
  @action setDivision(value: any) {
    this.divisionId = value;
  }
  @action setComments(value: any) {
    this.comments = value;
  }
  @action setShippingMethod(value: any) {
    this.shippingMethodId = value;
  }
  @action setIsCompleted(value: any) {
    this.isCompleted = value;
    this.saveToNotes = value === true ? false : this.saveToNotes;
  }
  @action setSaveNotes(value: any) {
    this.saveToNotes = value;
  }
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state)
      return " - " + agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return " - " + agent.city;
    else if (!agent.city && agent.state) return " - " + agent.state;
    else return "";
  };

  @action fillterUsers = async (value: string) => {
    const results = await ProducerSearchStore.getAgentsLookup(
      Utils.getAgentSearchType(value),
      value,
      0,
      200,
      undefined,
      false
    );
    if (results !== null) {
      if (results.recordCount) {
        if (results.data) {
          this.FilteredAgentsList.values = results.data;
        } else {
          this.FilteredAgentsList.values = [{ id: 0 }];
        }
      } else {
        this.FilteredAgentsList.values = [{ id: 0 }];
      }
    } else {
      this.FilteredAgentsList.values = [{ id: 0 }];
    }
  };
  @action setSupplyItem(
    value: string,
    type: string,
    orderDetailId: number,
    itemRowId: number
  ) {
    const supplyList = this.SupplyItemModelList;
    let rowData = [];
    if (orderDetailId) {
      rowData = supplyList.values.filter(
        (item) => item.orderDetailsId === orderDetailId
      );
    } else {
      rowData = supplyList.values.filter(
        (item) => item.itemRowId === itemRowId
      );
    }
    if (rowData && rowData.length > 0) {
      switch (type) {
        case "quantity":
          rowData[0].quantity = value ? parseInt(value) : null;
          rowData[0].quantitySent = value ? parseInt(value) : null;
          break;
        case "sent":
          if (value == '0') {
            rowData[0].quantitySent = 0;
          }
          else
            rowData[0].quantitySent = value ? parseInt(value) : null;
          rowData[0].bo = rowData[0].quantity - rowData[0].quantitySent;
          break;
        case "bo":
          rowData[0].bo = value ? parseInt(value) : null;
          break;
      }
    }
    this.SupplyItemModelList = new Collection<any>();
    this.SupplyItemModelList = supplyList;
  }

  @action loadAddresses = async (agentId: number) => {
    try {
      this.isLoading = true;
      if (this.agentId) {
        const result = await ProducerStore.getAddresses(agentId);
        if (result && result !== null) {
          if (result.recordCount) {
            this.addressTotalRecords = result.recordCount;
            if (result.data) this.AddressList.values = result.data;
          } else {
            this.noRecordsFound();
          }
        } else {
          this.noRecordsFound();
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = false;
    }
  };
  @action noRecordsFound() {
    this.addressTotalRecords = 0;
    this.AddressList.values = [];
  }
  @action getAddressDetails = async () => {
    try {
      return await ProducerStore.getAddressDetails(
        this.selectedAddressId,
        this.agentId
      );
    } catch (error) {
      IMASLog.log("exception: " + error);
    }
  };

  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setAddressDialogVisibility = async (
    value: boolean,
    isEdit: boolean
  ) => {
    await this.AddEditAddressViewModel.ResetValidate();
    this.AddEditAddressViewModel.isCustomValidation = false;
    this.setIsException(false);
    if (isEdit) {
      this.addrDialogTitle = "Edit Address";
      const result = await this.getAddressDetails();
      if (result) {
        this.AddEditAddressViewModel.setAddrssId(result.id ? result.id : 0);
        this.AddEditAddressViewModel.setAddress(
          result.addressLine1 ? result.addressLine1 : ""
        );
        if (result.addressType == 4)
          this.AddEditAddressViewModel.setAddressName(
            result.addressType ? "" + result.addressType : "",
            true,
            result.addressName ? result.addressName : ""
          );
        else
          this.AddEditAddressViewModel.setAddressName(
            result.addressType ? "" + result.addressType : "",
            true,
            ""
          );
        this.AddEditAddressViewModel.setBusinessName(
          result.bussinessName ? result.bussinessName : ""
        );
        this.AddEditAddressViewModel.setAttn(result.attn ? result.attn : "");
        this.AddEditAddressViewModel.setVisibleInDownline(
          result.visibleInDownline ? result.visibleInDownline : false
        );
        this.AddEditAddressViewModel.setDefault(
          result.isDefault ? result.isDefault : false
        );
        this.AddEditAddressViewModel.setCommission(
          result.isCommission ? result.isCommission : false
        );
        this.AddEditAddressViewModel.setResidential(
          result.isHome ? result.isHome : false
        );
      }
    } else {
      this.addrDialogTitle = "Add Address";
      this.AddEditAddressViewModel.setAddress("");
      this.AddEditAddressViewModel.setAddressName("1", false, "");
      this.AddEditAddressViewModel.setCustomAddrName("");
      this.AddEditAddressViewModel.setBusinessName("");
      this.AddEditAddressViewModel.setAttn("");
      this.AddEditAddressViewModel.setVisibleInDownline(false);
      this.AddEditAddressViewModel.setDefault(false);
      this.AddEditAddressViewModel.setCommission(false);
      this.AddEditAddressViewModel.setResidential(false);
      this.AddEditAddressViewModel.isValidAddress = false;
      this.AddEditAddressViewModel.showOverrideAddress = false;
    }
    this.AddEditAddressViewModel.setDialogVisibility(value);
  };

  @action setIsAddAddressSuccess(value: boolean) {
    this.isAddAddressSuccess = value;
  }

  @action addEditAddress = async () => {
    this.setIsAddAddressSuccess(false);
    this.setIsException(false);
    //this.AddEditAddressViewModel.isCustomValidation = (this.AddEditAddressViewModel.addressName === 'Custom' &&
    //    this.AddEditAddressViewModel.customAddrName.trim().length <= 0)

    if (!(await this.AddEditAddressViewModel.Validate())) { 
        this.isLoading = true;
        this.isException = false;
        const adrName =
          this.AddEditAddressViewModel.addressName === "Custom"
            ? this.AddEditAddressViewModel.customAddrName
            : this.AddEditAddressViewModel.addressName;
        const model: CreateUpdateAddressModel = {
          address: this.AddEditAddressViewModel.address,
          addressName: adrName,
          bussinessName: this.AddEditAddressViewModel.businessName,
          attn: this.AddEditAddressViewModel.attn,
          addressNote: "string",
          isVisibleInDownline: this.AddEditAddressViewModel.isVisibleInDownline,
          agentId: this.agentId,
          isHome: this.AddEditAddressViewModel.isResidential,
          isDefault: this.AddEditAddressViewModel.isDefault,
          isCommission: this.AddEditAddressViewModel.isCommission,
          addressType: +this.AddEditAddressViewModel.addressType,
        };
        const validAddressModel =
          this.AddEditAddressViewModel.validAddressModel;
        if (
          this.AddEditAddressViewModel.showOverrideAddress &&
          Utils.hasUserPermission(Permission.OverrideAddressValidation)
        ) {
          model.stateAbbreviation = this.AddEditAddressViewModel.state;
          model.zipCode = this.AddEditAddressViewModel.zip;
          model.city = this.AddEditAddressViewModel.city;
          model.addressLine1 = this.AddEditAddressViewModel.addressLine;
          model.isValid = false;
        } else if (validAddressModel) {
          model.countyName = validAddressModel.country;
          model.stateAbbreviation = validAddressModel.stateAbbreviation;
          model.addressLine1 = this.AddEditAddressViewModel.address;
          model.zipCode = validAddressModel.zipCode;
          model.lattitude = validAddressModel.latitude;
          model.longitude = validAddressModel.longitude;
          model.city = validAddressModel.city;
          model.addressComments = validAddressModel.addressComments;
          model.dpvFootnotes = validAddressModel.dpvFootnotes;
          model.dpvMatchCode = validAddressModel.dpvMatchCode;
          model.suitelinkMatch = validAddressModel.suitelinkMatch;
          model.lacslinkCode = validAddressModel.lacslinkCode;
          model.lacslinkIndicator = validAddressModel.lacslinkIndicator;
          model.footnotes = validAddressModel.footnotes;
          model.ewsMatch = validAddressModel.ewsMatch;
          model.active = validAddressModel.active;
          model.dpvVacant = validAddressModel.dpvVacant;
          model.dpvCmra = validAddressModel.dpvCmra;
          model.location = validAddressModel.location;
          model.precision = validAddressModel.precision;
          model.elotSort = validAddressModel.elotSort;
          model.elotSequence = validAddressModel.elotSequence;
          model.rdi = validAddressModel.rdi;
          model.buildingDefaultIndicator =
            validAddressModel.buildingDefaultIndicator;
          model.congressionalDistrict = validAddressModel.congressionalDistrict;
          model.carrierRoute = validAddressModel.carrierRoute;
          model.countyFips = validAddressModel.countyFips;
          model.zipType = validAddressModel.zipType;
          model.recordType = validAddressModel.recordType;
          model.deliveryPointBarcode = validAddressModel.deliveryPointBarcode;
          model.deliveryPoint = validAddressModel.deliveryPoint;
          model.deliveryPointCheckDigit =
            validAddressModel.deliveryPointCheckDigit;
          model.plusFourCode = validAddressModel.plusFourCode;
          model.defaultCityName = validAddressModel.defaultCityName;
          model.cityName = validAddressModel.cityName;
          model.secondaryDesignator = validAddressModel.secondaryDesignator;
          model.secondaryNumber = validAddressModel.secondaryNumber;
          model.streetSuffix = validAddressModel.streetSuffix;
          model.streetPostdirection = validAddressModel.streetPostdirection;
          model.streetPredirection = validAddressModel.streetPredirection;
          model.streetName = validAddressModel.streetName;
          model.primaryNumber = validAddressModel.primaryNumber;
          model.firmName = validAddressModel.firmName;
          model.isValid = true;
        }

        if (this.addrDialogTitle === "Add Address")
          await ProducerStore.addAddresses(this.agentId, model);
        else {
          model.id = this.AddEditAddressViewModel.id;
          await ProducerStore.editAddresses(this.agentId, model);
        }

        this.AddEditAddressViewModel.setDialogVisibility(false);
        await this.loadAddresses(this.agentId);
       
    }
  };

  @action setIsAddSupplyItemSuccess(value: boolean) {
    this.isAddSupplyItemSuccess = value;
  }
  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @computed
  get isSupplyItemDialogVisible(): boolean {
    return this.AddSupplyItemViewModel.isDialogVisible;
  }
  @computed
  get isAddressDialogVisible(): boolean {
    return this.AddEditAddressViewModel.isDialogVisible;
  }
  @action saveSupplyItem = async (supplyItemModel: any) => {
    if (!(await this.AddSupplyItemViewModel.Validate())) {
      const res = await SupplyOrderStore.getAppliableStates(
        supplyItemModel.supplyItemId
      );
      const supplyItem = {
        orderDetailsId: 0,
        orderId: this.orderId,
        supplyItemId: supplyItemModel.supplyItemId
          ? parseInt(supplyItemModel.supplyItemId)
          : 0,
        stateId: supplyItemModel.stateId
          ? parseInt(supplyItemModel.stateId)
          : 0,
        carrierId: supplyItemModel.carrierId
          ? parseInt(supplyItemModel.carrierId)
          : 0,
        productTypeId: supplyItemModel.productTypeId
          ? parseInt(supplyItemModel.productTypeId)
          : 0,
        supplyCompany: supplyItemModel.supplyCompany,
        supplyState: res && res.stateList,
        item: supplyItemModel.item,
        quantity: parseInt(supplyItemModel.quantity),
        quantitySent: parseInt(supplyItemModel.quantity),
        comments: "",
        itemRowId: new Date().getTime(),
      };
      const newValues: any[] = [];
      let isexisting: boolean = false;
      if (this.SupplyItemModelList) {
        this.SupplyItemModelList.values.forEach((obj) => {
          if (
            obj.supplyItemId === supplyItem.supplyItemId &&
            obj.carrierId === supplyItem.carrierId
          ) {
            isexisting = true;
            obj.quantity += supplyItem.quantity;
            if (this.isEdit) {
              obj.quantitySent += supplyItem.quantitySent;
            }
            newValues.push(obj);
          } else {
            newValues.push(obj);
          }
        });

        if (!isexisting) {
          newValues.push(supplyItem);
        }
      }
      this.SupplyItemModelList.values = newValues;
      this.AddSupplyItemViewModel.isDialogVisible = false;
      this.setIsAddSupplyItemSuccess(true);
      this.setIsException(false);
    }
  };
  listener: UpdateAgentOrderListener | undefined;
  setListener(listener: UpdateAgentOrderListener) {
    this.listener = listener;
  }

  @action async saveOrder() {

    googleTagManager.trackAction("supply_order_created", {
      feature: routes.supplies.name,
      user_id: UserContext.userId,
      agent_id: this.agentId
    });
    try {
      if (this.validateOrder()) {
        this.SaveSupplyItemModelList.values = [];
          this.SupplyItemModelList.values.forEach((element: any) => {
          this.SaveSupplyItemModelList.values.push({
            orderDetailsId: element.orderDetailsId || element.id,
            orderId: element.orderId,
            quantity: element.quantity,
            bo: element.bo,
            //supplyCompany: element.supplyCompany,
            supplyState: element.supplyState,
            quantitySent: element.quantitySent,
            //item: element.supplyDescription,
            supplyItemId: element.supplyItemId,
            stateId: element.supplyStateId,
            carrierId: element.carrierId || element.supplyCompanyId,
          });
        });

        const model: CreateUpdateSupplyOrderModel = {
          id: this.orderId ? this.orderId : 0,
          agentId: this.agentId,
          orderDate: this.orderDate ? this.orderDate : undefined,
          comments: this.comments,
          addressId: this.selectedAddressId,
          supplyItems: this.SaveSupplyItemModelList.values,
          shippingMethodId: this.shippingMethodId
            ? parseInt(this.shippingMethodId)
            : 0,
          divisionId:
            this.divisionId && parseInt(this.divisionId) > 0
              ? parseInt(this.divisionId)
              : 1,
          completed: this.isCompleted,
          saveToAgentNotes: this.saveToNotes,
          saveToAgentNotesLink: this.saveToNotes
            ? window.location.origin +
            "/agents/" +
            this.agentId +
            "/orders/details/" +
            this.orderId
            : "",
        };
          this.isLoading = true;
        if (this.orderId > 0) {
          await SupplyOrderStore.UpdateSupplyOrder(model);
          if (this.listener && this.isCompleted) {
            this.listener.updateAgenOrdertInfo("delete");
          }
        } else {
          await SupplyOrderStore.SaveSupplyOrder(model);
          if (this.listener) {
            this.listener.updateAgenOrdertInfo("add");
          }
        }
        this.SaveSupplyItemModelList.values = [];
        window.scrollTo(0, 0);
        this.isLoading = false;
        if (window.location.href.indexOf("agents") > 0 && this.FromAgent && this.agentId !== this.routeAgentId) {
          RouteParamDetails.id = this.agentId;
          routes.agentOrders.push({
            agentId: this.agentId.toString(),
          });
        } else {
          Utils.goBack();
        }
      }
    } catch (e: any) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  }

  @action cancelOrder() {
    this.comments = "";
    Utils.goBack();
  }

  @action validateOrder() {
    if (!this.agentId) {
      this.setIsException(true);
      window.scrollTo(0, 0);
      this.validationMessage = "Please select agent";
      return false;
    } else if (
      this.SupplyItemModelList &&
      this.SupplyItemModelList.values.length === 0 &&
      !this.isEdit
    ) {
      this.setIsException(true);
      this.validationMessage = "Please add supply item";
      window.scrollTo(0, 0);
      return false;
    } else if (!this.selectedAddressId) {
      this.setIsException(true);
      this.validationMessage = "Please select address";
      window.scrollTo(0, 0);
      return false;
    }

    this.setIsException(false);
    return true;
  }

  @action setDeleteConfirmation(value: boolean) {
    this.isDeleteConfirmation = value;
  }
  @action setdeleteSelectedId(selectOrderDetailId: number, itemRowId: number) {
    this.selectedItemRowId = itemRowId;
    this.selectOrderDetailId = selectOrderDetailId;
  }
  @action setSelectedAddressId(addressId: number) {
    this.selectedAddressId = addressId;
  }
  @action async deleteSupplyItem() {
    if (this.selectOrderDetailId > 0) {
      try {
        await SupplyOrderStore.deleteSupplyItems(this.selectOrderDetailId);
        this.SupplyItemModelList.values =
          this.SupplyItemModelList.values.filter((e) => {
            return e.orderDetailsId !== this.selectOrderDetailId;
          });
        this.isMessgeVisible = false;
      } catch (e: any) {
        this.isLoading = false;
        this.isMessgeVisible = true;
        this.response = e.response;
      }
    } else {
      this.SupplyItemModelList.values = this.SupplyItemModelList.values.filter(
        (e) => {
          return e.itemRowId !== this.selectedItemRowId;
        }
      );
    }
    this.isDeleteConfirmation = false;
  }
  @action addSupplyItem() {
    this.addrDialogTitle = "Add Supply Item";
    this.AddSupplyItemViewModel.ResetValidate();
    this.AddSupplyItemViewModel.setDialogVisibility(true);
  }
  @action setTabindex(value: number) {
    this.selectedTabindex = value;
  }
}

export interface UpdateAgentOrderListener {
  updateAgenOrdertInfo(action: string): void;
}
