import { observable } from "mobx";

export class Loadable<TType> {
    constructor (public value: TType){}
    @observable isLoaded: boolean = false;
}

export class Saveable<TType> {
    constructor (public value: TType) {}
    @observable isSaving: boolean = false;
}

export class LoadAndSaveable<TType> {
    constructor (public value: TType) {}
    @observable isLoading: boolean = true;
    @observable isSaving: boolean = false;
}