import { AbstractValidator } from "fluent-ts-validator";
import { action, observable, toJS } from "mobx"; 
import Collection from "../../../../infrastructure/CollectionHelper";
import { CommissionPlan } from "../../../../infrastructure/enum/Settings";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import PageContext from "../../../../infrastructure/PageContext";
import UserContext from "../../../../infrastructure/UserContext";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { CommisionDetailsModel, CommissionPlanModel, DeletedListModel, RenewalAgeDetailsModel, RenewalRateDetailsModel } from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";
import ContractsStore from "../../../../store/ContractsStore";
import { googleTagManager } from "../../../../infrastructure/tracking";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";

export class CommissionPlanAddEditViewModel extends BaseAddEditViewModel {
  @observable name: string = "";
  @observable carrierId: number = 0;
  @observable statusTypeId: number = 1;
  @observable paymentSourceId: number = 2;
  @observable paymentMethodId: number = 0;
  @observable alwaysShow: boolean = false;
  @observable levelList = new Collection< {
    id: string;
    commissionId: string;
    agent: string;
    agentId: string;
    a01: string;
    a02: string;
    a03: string;
    a04: string;
    a05: string;
    a06: string;
    a07: string;
    a08: string;
    a09: string;
    a10: string;
    p01: string;
    p02: string;
    p03: string;
    p04: string;
    p05: string;
    p06: string;
    p07: string;
    p08: string;
    p09: string;
    p10: string;
    age1: string;
    age2: string;
    }>();
  @observable ageRangeList = new Collection<{
    id: string;
    planId: string;
    ageFrom: string;
    ageTo: string;
    durationList: Collection<{
      id: string;
      ageRangeId: string;
      monthFrom: string;
      monthTo: string;
      rate: any;
      payTo: string;
      payFrom: string;
      payFromName: string;
      flatRate: string; 
      hidePremiumRate: boolean;
      showOnlyCommission: boolean;
      agentId: string;
      agent: string;}>
  }>();
  @observable durationList = new  Collection<{
    id: string;
    ageRangeId: string;
    monthFrom: string;
    monthTo: string;
    rate: string; 
    payTo: string;
    payFrom: string;
    payFromName: string;
    flatRate: string; 
    hidePremiumRate: boolean;
    showOnlyCommission: boolean;
    agentId: string;
    agent: string;
}>();
  @observable finallevelList = new Collection<CommisionDetailsModel>();
  @observable finalageRangeList = new Collection<RenewalAgeDetailsModel>();
  @observable finaldurationList = new  Collection<RenewalRateDetailsModel>();
  @observable deletedList = new Collection<DeletedListModel>();
  @observable selectedAgent: string = ''
  @observable selectedAgentId: string = ""
  @observable monthFrom: string = '0'
  @observable monthTo: string = '0'
  @observable rate: string = '0'
  @observable hidePremiumRate: boolean = false
  @observable showOnlyCommission: boolean = false
  @observable payTo: string = ""
  @observable flatRate: string = "No"
  @observable payFrom: string = "1"
  @observable flatRateList = [{label: 'No', value: 'No'}, {label: 'Yes', value: 'Yes'}]
  @observable agentList = new Collection<{
      label: string;
      value: string;
    }>();  
  @observable durationAgentList = new Collection<{
    label: string;
    value: string;
  }>();  
  @observable payToList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable payFromList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable durationAgent: string = ''
  @observable carrierList: any;
  @observable statusTypeList: any;
  @observable paymentSourceList: any;
  @observable paymentMethodList: any
  @observable actionTitle: string = ""
  @observable ageRangeDialog: boolean = false
  @observable ageFrom: string = '0'
  @observable ageTo: string = '0'
  @observable level: string = ''
  @observable selectedAgeRangeId: string = ""
  @observable selectedAgeRange: any;
  @observable selectedDuarionId: string = ''
  @observable durationRateDialog: boolean = false;
  @observable selectedlevelId: string = ''
  @observable a01: string=  ''
  @observable a02: string=  ''
  @observable a03: string=  ''
  @observable a04: string=  ''
  @observable a05: string=  ''
  @observable a06: string=  ''
  @observable a07: string=  ''
  @observable a08: string=  ''
  @observable a09: string=  ''
  @observable a10: string=  ''
  @observable p01: string=  ''
  @observable p02: string=  ''
  @observable p03: string=  ''
  @observable p04: string=  ''
  @observable p05: string=  ''
  @observable p06: string=  ''
  @observable p07: string=  ''
  @observable p08: string=  ''
  @observable p09: string=  ''
  @observable p10: string=  ''
  @observable LevelErrorModel = new ErrorModel(new CommissionPlanLevelValidator()); 
  @observable DurationErrorModel = new ErrorModel(new CommissionPlanDurationValidator());
  @observable deleteLevelId: string = ""
  @observable deleteAgeId: string = ""
  @observable deleteDurationRateId: string = ""
  @observable isDeleteLevelVisible: boolean = false
  @observable isDeleteAgeVisible: boolean = false
  @observable isDeleteDurationRateVisible: boolean = false

  @action setLevelDeleteConfirmation = (value: boolean) => {
    this.isDeleteLevelVisible = value
  }

  @action setAgeDeleteConfirmation = (value: boolean) => {
    this.isDeleteAgeVisible = value
  }

  @action setDurationRateDeleteConfirmation = (value: boolean) => {
    this.isDeleteDurationRateVisible = value
  }

  @action ValidateLevel = async () => {
    return await this.LevelErrorModel.Validate(this);
  }

  @action ResetValidateLevel = async () => {
      return await this.LevelErrorModel.ResetValidation(this);
  }

  @action ValidateDuration = async () => {
    return await this.DurationErrorModel.Validate(this);
  }

  @action ResetValidateDuration = async () => {
      return await this.DurationErrorModel.ResetValidation(this);
  }

  constructor()
  {
      super("Commission Plan", routes.listCommissionplan, new CommissionPlanValidator());
  }

  protected toServiceModel() {
    var dto: CommissionPlanModel = {
      id: this.selectedId,
      name: this.name,
      carrierId: this.carrierId,
      statusId: this.statusTypeId,
      paymentSourceId: this.paymentSourceId,
      paymentMethodId: this.paymentMethodId,
      alwaysShow: this.alwaysShow,
      commisionDetails: this.formatLevels(),
      renewalAge: this.formatAgeRange(),
      deletedList: this.selectedId === 0 ? [] : this.deletedList.values
    };
    return dto;
  }

  protected async loadItem(id: number): Promise<void> {
      if(this.selectedId === 0 ){
        await this.resetModel();
      }
      this.deletedList.values = []
      var item = await CommissionStore.getCommissionPlanById(id);
      if(item) {
          this.selectedId = item.id || 0;
          this.name = item.name || "";
          this.carrierId = item.carrierId || 0;
          this.statusTypeId = item.statusId || 1;
          this.paymentMethodId = item.paymentMethodId || 0;
          this.paymentSourceId = item.paymentSourceId || 1;
          this.alwaysShow = item.alwaysShow || false;
          this.loadLevels(item)
          this.loadAgeRangeDurations(item)
      }
  }

  protected async addItem(): Promise<void> {
    var item = this.toServiceModel();
    const agentIds = this.finallevelList.values.map((item) => { return item.agentId });
    var commaSeperatedUniqueAgentIds = this.finallevelList.values.filter(({ agentId }, index) => !agentIds.includes(agentId, index + 1)).map((item) => { return item.agentId }).join(',');
    googleTagManager.trackAction("commission_plan_created", { feature: routes.addCommissionplan.name, user_id: UserContext.userId, agent_id: commaSeperatedUniqueAgentIds || 0 });
    await CommissionStore.addCommissionPlan(item);
  }
  protected async updateItem(): Promise<void> {
    var item = this.toServiceModel();
    await CommissionStore.updateCommissionPlan(item);
  }

  @action loadLevels = (item: any) => {
    if(item.commisionDetails && item.commisionDetails.length > 0){
      this.levelList.values = []
      item.commisionDetails.forEach((i: any) => {
        var temp = {id: ''+i.id,
        commissionId: item.id,
        agent: ''+i.agentName,
        agentId: ''+i.agentId,
        a01: ''+i.a01,
        a02: ''+i.a02,
        a03: ''+i.a03,
        a04: ''+i.a04,
        a05: ''+i.a05,
        a06: ''+i.a06,
        a07: ''+i.a07,
        a08: ''+i.a08,
        a09: ''+i.a09,
        a10: ''+i.a10,
        p01: ''+i.p01,
        p02: ''+i.p02,
        p03: ''+i.p03,
        p04: ''+i.p04,
        p05: ''+i.p05,
        p06: ''+i.p06,
        p07: ''+i.p07,
        p08: ''+i.p08,
        p09: ''+i.p09,
        p10: ''+i.p10,
        age1: (i.a01 && i.p01 ? i.a01 + " - " +i.p01 : ''),
        age2: (i.a02 && i.p02 ? i.a02 + " - " +i.p02 : '') }
        this.levelList.values.push(temp)
      });
    }
  }

  @action loadAgeRangeDurations = (item: any) => {
    if(item.renewalAge && item.renewalAge.length > 0){
      this.ageRangeList.values = []
      item.renewalAge.forEach((i: any) => {
        var temp = {id: ''+i.id,
        planId: ''+this.selectedId,
        ageFrom: ''+i.age1,
        ageTo: ''+i.age2,
        durationList: {values: ((i.renewalRate && i.renewalRate.length > 0) ? this.loadDurationRates(i.renewalRate) : [])}
        }
        this.ageRangeList.values.push(temp)
      });
      // set duration list for first age range
      this.durationList.values = []
      this.selectedAgeRange = this.ageRangeList.values[0]
      this.selectedAgeRangeId = this.ageRangeList.values[0].id
      this.durationList.values = this.ageRangeList.values[0].durationList.values;
    }
  }

  @action loadDurationRates = (item: any) => {
    let results: any[] = []
    if(item && item.length > 0){
      item.forEach((i: any) => { 
        var temp_dur = {
          id: ''+i.id,
          ageRangeId: ''+i.ageId,
          monthFrom: ''+i.startMonth,
          monthTo: ''+i.stopMonth,
          rate: i.isFlatRate ? ''+i.flatRateAmount : ''+(i.rate),
          payTo: ''+i.payToAgentId,
          payFrom: ''+i.payFromID,
          payFromName: this.payFromList.values.filter((ind) => ind.value === ''+i.payFromID)[0].label,
          flatRate: i.isFlatRate ? 'Yes' : 'No', 
          hidePremiumRate: i.hidePremiumAndRate,
          showOnlyCommission: i.hideAllExceptAmount,
          agentId: ''+i.agentId,
          agent: i.agentName
        }
        results.push(temp_dur)
      });
    }
    return results
  }

  @action formatLevels =  () =>{
    this.finallevelList.values = []
    if(this.levelList.values.length > 0){
      this.levelList.values.forEach((i: any) => {
        var temp = {}
        temp = {id: i.id.toString().includes("l") ? 0 : +i.id, 
        commissionId: this.selectedId,
        agentName: i.agent,
        agentId: +i.agentId,
        payToId: +i.agentId,
        type: 'W',
        a01: +i.a01 || 0,
        a02: +i.a02 || 0,
        a03: +i.a03 || 0,
        a04: +i.a04 || 0,
        a05: +i.a05 || 0,
        a06: +i.a06 || 0,
        a07: +i.a07 || 0,
        a08: +i.a08 || 0,
        a09: +i.a09 || 0,
        a10: +i.a10 || 0,
        p01: +i.p01 || 0,
        p02: +i.p02 || 0,
        p03: +i.p03 || 0,
        p04: +i.p04 || 0,
        p05: +i.p05 || 0,
        p06: +i.p06 || 0,
        p07: +i.p07 || 0,
        p08: +i.p08 || 0,
        p09: +i.p09 || 0,
        p10: +i.p10 || 0,
        payFromProfit: true
      }
      this.finallevelList.values.push(temp)
     }
     );
    return this.finallevelList.values
    }
  }

  @action formatAgeRange = () =>{
    this.finalageRangeList.values = []
    this.ageRangeList.values.forEach((i: any) => {
      var temp = {}
      temp = {id: i.id.toString().includes('ar') ? 0 : +i.id,
      planId: this.selectedId,
      age1: +i.ageFrom,
      age2: +i.ageTo,
      renewalRate: this.formatDuration(i)
      }
      this.finalageRangeList.values.push(temp)
    });
    return this.finalageRangeList.values
  }

  @action formatDuration = (data: any) => {
    let results: any[] = []
    var duration_temp = {}
    if(data && data.durationList.values.length > 0){
      data.durationList.values.forEach((d: any) => {
        duration_temp = {id: d.id.toString().includes('dr') ? 0 : +d.id,
        ageId: data.id.toString().includes('ar') ? 0 : +data.id,
        agentId: +d.agentId,
        payToAgentId: +d.payTo,
        startMonth: +d.monthFrom,
        stopMonth: +d.monthTo,
        rate: d.flatRate === "No" ? +d.rate : 0,
        isFlatRate: d.flatRate === "Yes" ? true : false,
        flatRateAmount: d.flatRate === "Yes" ?  +d.rate : 0,
        payFromID: +d.payFrom,
        hidePremiumAndRate: d.hidePremiumRate,
        hideAllExceptAmount: d.showOnlyCommission 
      }
        results.push(duration_temp)
      });
      return results
    }
  }

  @observable levelDialogVisible: boolean = false;
  setLevelDialogVisible(value: boolean): void {
    this.levelDialogVisible = value;
  }

  updateAction(rowData: any): void {
    throw new Error("Method not implemented.");
  }

  protected async loadLookups(): Promise<void> {
    var items = await ContractsStore.getAllCompanyLookup();
    this.carrierList = Utils.mapListItemsAsList(items, "Select Carrier");

    var result = await CommissionStore.getCommissionScreenLookup();
    if(result){
      this.statusTypeList = Utils.mapListItemsAsList(result.planStatus || [], "Select Status");
      this.paymentSourceList = Utils.mapListItemsAsList(result.paymentSource || [], "Select Payment Source");
      this.paymentMethodList = Utils.mapListItemsAsList(result.paymentMethod || [], "Select Payment Method");
    }

    // set pay from lookup
    var payFromItem = await CommissionStore.getCommissionPayFromNameLookup();
    this.payFromList.values = Utils.mapListItemsAsList(payFromItem, "");
  }

  @action resetEntireForm = () => {
    this.resetAgeRange()
    this.resetDurationRates()
    this.resetLevel()
    this.resetModel()
    this.selectedId =  0;
    this.name =  "";
    this.carrierId =  0;
    this.statusTypeId = 1;
    this.paymentMethodId =  0;
    this.paymentSourceId = 2;
    this.alwaysShow =  false;
    this.levelList.values = []
    this.ageRangeList.values = []
    this.durationList.values = []
  }

  @action addEditAgeRange = (id: string) => {
    this.resetAgeRange();
    this.selectedAgeRangeId = id
    if(id === "0"){
      this.actionTitle = CommissionPlan.AddAgeRange
    }
    else {
      this.actionTitle = CommissionPlan.EditAgeRange
      var temp = this.ageRangeList.values.filter((ind) => ind.id === id)
      if(temp){
        this.ageFrom = temp[0].ageFrom
        this.ageTo = temp[0].ageTo
      }
    }
    this.setAgeRangeDialog(true)
  }

  @action setAgeRangeDialog(value: boolean){
    this.ageRangeDialog = value
  }

  @action onAgeRangeSave = () => {
    if(this.selectedAgeRangeId === "0"){
      this.ageRangeList.values.push({id: 'ar'+new Date().getMilliseconds(), planId: ''+this.selectedId, ageFrom: this.ageFrom, ageTo: this.ageTo, durationList:{ values: []}})
    } else {
      var temp = this.ageRangeList.values.filter((ind) => ind.id === this.selectedAgeRangeId)
      if(temp){
        temp[0].ageFrom = this.ageFrom
        temp[0].ageTo = this.ageTo
      }
    }
      this.setAgeRangeDialog(false)
  }

  @action deleteAgeRange = () => {
    this.selectedAgeRange = ""
    if(this.deleteAgeId && this.ageRangeList.values.length > 0){
      this.ageRangeList.values = this.ageRangeList.values.filter(item => item.id !== this.deleteAgeId)
      this.updateDeleteList(this.deleteAgeId, "Age") 
      this.setAgeDeleteConfirmation(false)  
      this.durationList.values = this.durationList.values.filter(item => item.ageRangeId !== this.deleteAgeId)  
    }
  }
 
  @action updateDeleteList = (id: string, name: string) => {
    if(parseInt(id)){
      this.deletedList.values.push({id: +id, name: name})
    }
  }

  @action onAgeRangeCancel = () => {
    this.setAgeRangeDialog(false)
    this.resetAgeRange();
  }

  @action resetAgeRange = async() => {
    this.ageFrom = '0'
    this.ageTo = '0'
    this.level = ''
    this.selectedAgeRange = ""
  }

  @action onRowSelect = async (data: any) => {
    this.selectedAgeRange = data;
    this.selectedAgeRangeId = data.id;
    if(this.selectedAgeRange){
      var temp = this.ageRangeList.values.filter(item => item.id === data.id)
      if(temp &&  temp[0] && temp[0].durationList.values.length > 0){
        this.durationList.values = temp[0].durationList.values
      } else {
        this.durationList.values = []
      }
    }
  }

  @action resetDurationRates = () => {
    this.monthFrom = '0'
    this.monthTo = '0'
    this.rate = '0'
    this.payTo = ''
    this.payFrom = '1'
    this.flatRate = ''
    this.hidePremiumRate = false
    this.showOnlyCommission = false
  }

  @action addEditDurationDialog = async(id: string) => {
    await this.ResetValidateDuration();
    if(this.selectedAgeRange && this.levelList.values.length > 0){
      this.selectedDuarionId = id
      if(id === "0"){
        this.resetDurationRates()
        this.actionTitle = CommissionPlan.AddDuration
        this.flatRate = "No"
      } else {
        this.actionTitle = CommissionPlan.EditDuration
        var temp = this.ageRangeList.values.filter((ind) => ind.id === this.selectedAgeRangeId)
        if(temp){
          var dur_temp = temp[0].durationList.values.filter((ind) => ind.id === id)
          if(dur_temp){
            this.monthFrom = dur_temp[0].monthFrom
            this.monthTo = dur_temp[0].monthTo
            this.rate = dur_temp[0].rate
            this.durationAgent = dur_temp[0].agentId
            this.payTo =  dur_temp[0].payTo
            this.payFrom =  dur_temp[0].payFrom
            this.flatRate = dur_temp[0].flatRate
            this.hidePremiumRate = dur_temp[0].hidePremiumRate
            this.showOnlyCommission = dur_temp[0].showOnlyCommission
          }
        }
      }
      this.durationAgentList.values = []
      this.payToList.values = []
      this.levelList.values.forEach((i: any) => {
        this.durationAgentList.values.push({label: i.agent, value: i.agentId})
        this.payToList.values.push({label: i.agent, value: i.agentId})
      });
      PageContext.setIsMessageVisible(false)
      this.setDurationRateDialog(true)
    } else {
      PageContext.setIsMessageVisible(true)
      let error = {
        status: 400,
        title: this.levelList.values.length === 0 ? "Please add level" : "Please select age range.",
        errors: { "": [] },
      };
      PageContext.setResponseMessage(JSON.stringify(error));
    }
  }

  @action setDurationRateDialog = (value: boolean) => {
    this.durationRateDialog = value
  }

  @action onDurationRateSave = async() => {
    await this.ResetValidateDuration();
    if (!await this.ValidateDuration()) {
      if(this.selectedAgeRangeId !== "0"){
        var temp = this.ageRangeList.values.filter((ind) => ind.id === this.selectedAgeRangeId)
        if(this.selectedDuarionId === "0"){
          temp[0].durationList.values.push({id: 'dr'+new Date().getMilliseconds(), 
          ageRangeId: this.selectedAgeRangeId,
          monthFrom: this.monthFrom, 
          monthTo: this.monthTo, 
          rate: this.rate ? parseFloat((Utils.formatNum(this.rate)).toFixed(5).toString()) : 0,
          payTo: this.payTo,
          agentId: this.durationAgent,
          flatRate: this.flatRate,
          hidePremiumRate: this.hidePremiumRate,
          showOnlyCommission: this.showOnlyCommission,
          payFrom: this.payFrom,
          payFromName: this.payFromList.values.filter((ind) => ind.value ===this.payFrom)[0].label,
          agent: (this.durationAgentList.values.filter((ind) => ind.value === this.durationAgent)[0].label)
        })
        } else {
          var dur_temp =  temp[0].durationList.values.filter((ind) => ind.id === this.selectedDuarionId)
          if(dur_temp){
            dur_temp[0].monthFrom = this.monthFrom
            dur_temp[0].monthTo = this.monthTo
            dur_temp[0].rate = this.rate ? (dur_temp[0].rate !== this.rate ?  parseFloat((Utils.formatNum(this.rate)).toFixed(5).toString()) : parseFloat(Utils.formatNum(this.rate).toString())) : 0
            dur_temp[0].payTo = this.payTo
            dur_temp[0].agentId = this.durationAgent
            dur_temp[0].flatRate = this.flatRate
            dur_temp[0].hidePremiumRate = this.hidePremiumRate
            dur_temp[0].showOnlyCommission = this.showOnlyCommission
            dur_temp[0].payFrom = this.payFrom
            dur_temp[0].payFromName = this.payFromList.values.filter((ind) => ind.value === ''+this.payFrom)[0].label
            dur_temp[0].agent = (this.durationAgentList.values.filter((ind) => ind.value === this.durationAgent)[0].label)
          }
        }
        this.durationList.values = temp[0].durationList.values;
        let tempArr = this.durationList.values;
        this.durationList.values =[]; 
        this.durationList.values =(tempArr || []).sort((a:any, b:any) =>   a.agent > b.agent || -(a.agent < b.agent)); 
        this.setDurationRateDialog(false)
      }
    }
  }

  @action onDurationRateCancel = () => {
    this.setDurationRateDialog(false)
    this.resetDurationRates();
  }

  @action deletedurationRate = () => {
    var temp = this.ageRangeList.values.filter((ind) => ind.id === this.selectedAgeRangeId)
    if(temp){
      var dur_temp = temp[0].durationList.values.filter((ind) => ind.id === this.deleteDurationRateId)
      if(dur_temp){
        this.durationList.values = this.durationList.values.filter(item => item.id !==  this.deleteDurationRateId)
        temp[0].durationList.values = temp[0].durationList.values.filter(item => item.id !==  this.deleteDurationRateId)
        this.updateDeleteList(this.deleteDurationRateId, "Rate") 
        this.setDurationRateDeleteConfirmation(false)
      }
    }
  }

  @action sethidePremiumRate(value: boolean) {
    this.hidePremiumRate = value;
  }

  @action setshowOnlyCommission(value: boolean) {
    this.showOnlyCommission = value;
  }

  @action validateAgent = () => {
    var valid = true
    if(!this.selectedAgentId || !this.selectedAgent){
      let error = {
        status: 400,
        title: 'Please select an agent',
        errors: { "": [] },
      };
      PageContext.setIsMessageVisible(true)
      PageContext.setResponseMessage(JSON.stringify(error))
      valid = false
    }
    return valid
  }

  @action saveLevel = async() => {
    await this.ResetValidateLevel();
    if (!await this.ValidateLevel() && this.validateAgent()) {
      if(this.selectedlevelId === '0'){
        this.levelList.values.push({id: 'l'+new Date().getMilliseconds(), 
        agent: this.selectedAgent,
        commissionId:''+this.selectedId,
        agentId: this.selectedAgentId,
        a01: this.a01,
        a02: this.a02,
        a03: this.a03,
        a04: this.a04,
        a05: this.a05,
        a06: this.a06,
        a07: this.a07,
        a08: this.a08,
        a09: this.a09,
        a10: this.a10,
        p01: this.p01,
        p02: this.p02,
        p03: this.p03,
        p04: this.p04,
        p05: this.p05,
        p06: this.p06,
        p07: this.p07,
        p08: this.p08,
        p09: this.p09,
        p10: this.p10,
        age1: this.a01 || this.p01 ? (this.a01 + " - " +this.p01) : "",
        age2: this.a02 || this.p02 ? (this.a02 + " - " +this.p02) : ""
      })
      } else {
        var temp = this.levelList.values.filter((ind) => ind.id === this.selectedlevelId)
        if(temp) {
          temp[0].agent = this.selectedAgent
          temp[0].agentId = this.selectedAgentId
          temp[0].a01 = this.a01
          temp[0].a02 = this.a02
          temp[0].a03= this.a03
          temp[0].a04= this.a04
          temp[0].a05= this.a05
          temp[0].a06= this.a06
          temp[0].a07= this.a07
          temp[0].a08= this.a08
          temp[0].a09= this.a09
          temp[0].a10= this.a10
          temp[0].p01= this.p01
          temp[0].p02= this.p02
          temp[0].p03= this.p03
          temp[0].p04= this.p04
          temp[0].p05= this.p05
          temp[0].p06= this.p06
          temp[0].p07= this.p07
          temp[0].p08= this.p08
          temp[0].p09= this.p09
          temp[0].p10= this.p10
          temp[0].age1 = this.a01 || this.p01 ? (this.a01 + " - " +this.p01) : ""
          temp[0].age2 = this.a02 || this.p02 ? (this.a02 + " - " +this.p02) : ""
        }
      }
      this.setLevelDialogVisible(false)
    }
  }

  @action resetLevel = () => {
    this.a01 = '0'
    this.a02 = '0'
    this.a03 = '0'
    this.a04 = '0'
    this.a05 = '0'
    this.a06 = '0'
    this.a07 = '0'
    this.a08 = '0'
    this.a09 = '0'
    this.a10 = '0'
    this.p01 = '0'
    this.p02 = '0'
    this.p03 = '0'
    this.p04 = '0'
    this.p05 = '0'
    this.p06 = '0'
    this.p07 = '0'
    this.p08 = '0'
    this.p09 = '0'
    this.p10 = '0'
    this.selectedAgent=''
    this.selectedAgentId = '0'
    PageContext.setIsMessageVisible(false)
  }

  @action addEditLevel = async(id: string)  => {
    this.resetLevel();
    await this.ResetValidateLevel()
    PageContext.setIsMessageVisible(false)
    this.selectedlevelId = id
    if(id === "0"){
      this.actionTitle = CommissionPlan.AddLevel
    } else {
      this.actionTitle = CommissionPlan.EditLevel
      var temp = this.levelList.values.filter((ind) => ind.id === this.selectedlevelId)
      if(temp){
       this.a01 = temp[0].a01
       this.a02 = temp[0].a02
       this.a03 = temp[0].a03
       this.a04 = temp[0].a04
       this.a05 = temp[0].a05
       this.a06 = temp[0].a06
       this.a07 = temp[0].a07
       this.a08 = temp[0].a08
       this.a09 = temp[0].a09
       this.a10 = temp[0].a10
       this.p01 = temp[0].p01
       this.p02 = temp[0].p02
       this.p03 = temp[0].p03
       this.p04 = temp[0].p04
       this.p05 = temp[0].p05
       this.p06 = temp[0].p06
       this.p07 = temp[0].p07
       this.p08 = temp[0].p08
       this.p09 = temp[0].p09
       this.p10 = temp[0].p10
       this.selectedAgent = temp[0].agent
       this.selectedAgentId = temp[0].agentId
      }
    }
    this.setLevelDialogVisible(true)
  }

  @action deletelevel = () => {
    if(this.deleteLevelId && this.levelList.values.length > 0){
      this.levelList.values = this.levelList.values.filter(item => item.id !== this.deleteLevelId)
      this.updateDeleteList(this.deleteLevelId, "Level") 
      this.setLevelDeleteConfirmation(false)
    }
  }

  @action loadAgents = async(searchValue: string) => {
    if (searchValue && searchValue.length >= 3){
      var results = await CommissionStore.getAgentsBySearchTermLookup(searchValue)
        var values = null
        if (results !== null) {
            values = results   
        }
        if (values !== undefined && values !== null) {
            this.agentList.values =  Utils.mapListItemsAsList(results, "");
        } else {
            this.agentList.values = [];
        }
      
    }
  }

}

class CommissionPlanValidator extends AbstractValidator<CommissionPlanAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString(input => input.name)
        .hasMaxLength(100)
        .isNotEmpty()
        .matches(/\S+/,"i")
        .withFailureMessage("Name is required");

    this.validateIfNumber((input) => input.carrierId)
        .isNotEmpty()
        .isGreaterThanOrEqual(1)
        .withFailureMessage("Carrier is required");

    this.validateIfNumber((input) => input.statusTypeId)
        .isNotEmpty()
        .isGreaterThanOrEqual(1)
        .withFailureMessage("Status is required");

    this.validateIfNumber((input) => input.paymentMethodId)
        .isNotEmpty()
        .isGreaterThanOrEqual(1)
        .withFailureMessage("Payment Method is required");

    this.validateIfNumber((input) => input.paymentSourceId)
        .isNotEmpty()
        .isGreaterThanOrEqual(1)
        .withFailureMessage("Payment Source is required");
  }
} 

class CommissionPlanLevelValidator extends AbstractValidator<CommissionPlanAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString(input => input.selectedAgent)
        .isNotEmpty()
        .withFailureMessage("Agent is required");

  }
}  

class CommissionPlanDurationValidator extends AbstractValidator<CommissionPlanAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString(input => input.durationAgent)
      .isNotEmpty()
      .withFailureMessage("Agent is required");
    this.validateIfString(input => input.payTo)
      .isNotEmpty()
      .withFailureMessage("Pay To is required");
  }
} 