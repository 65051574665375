import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Collection from "../../infrastructure/CollectionHelper";
import { EmailModel } from "../../services/MessagingService";
import IdentityStore from "../../store/IdentityStore";
import { routes } from "../../router";
import Utils from "../../infrastructure/Utils";

export class EmailViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable emailList = new Collection<EmailModel>();
  @observable sortColumn: string = "emailId";
  @observable sortOrder: boolean = false;
  @observable startIndex: number = 0;
  @observable first: number = 0;
  @observable isException: boolean = false;
  @observable rows: number = 50;
  @observable totalRecords: number = 0;
  @observable isLoading: boolean = false;
  @observable searchInputValue: string = "";
  @observable exceptionMessage: any;
  @observable searchValue: string = "";
  @observable attachment: string = "";
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @action Load = async () => {
    this.loadEmails();
  };
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action backToAdmin = async () => {
    routes.administration.push();
  };
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action setSelectedAttachment = (attachment: string) => {
    this.attachment = attachment;
  };
  @action searchIntervals = (text: string) => {
    this.searchValue = text;
    this.searchInputValue = text;
  };
  @action customerSearch = async (key: string, text: string) => {
    if (key === "Enter") {
      this.loadEmails();
    }
  };
  @action goSearch = async () => {
    this.sortColumn = "emailId";
    this.sortOrder = true;
    await this.loadEmails();
  };
  @action resetFilters = async () => {
    this.searchValue = "";
    this.searchInputValue = "";
    await this.resetPagingValues();
    await this.loadEmails();
  };
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadEmails();
  }
  @action resetPagingValues = async () => {
    this.rows = 50;
    this.totalRecords = 50;
    this.sortOrder = false;
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "emailId";
  };
  @action loadEmails = async () => {
    this.isException = this.isException ? false : false;
    try {
      let result = await IdentityStore.getAllEmail(
        this.searchValue,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (result !== null) {
        if (result.recordCount) {
          this.totalRecords = result.recordCount;
          if (result.data) this.emailList.values = result.data;
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.emailList.values = [];
  }
  @action downloadDocumentLink = async (filename: string) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      let result = await IdentityStore.downloadAttachment(filename);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : filename,
              result.data.type ? result.data.type : (result.headers ? result.headers["content-type"] : "")
          );
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action downloadDocument = async (base64: string, filename: string) => {
    setTimeout(() => {
      var link = document.createElement("a");
      link.download = `${filename}`;
      if (filename.indexOf(".xlsx") > 0) {
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          base64;
      }
      if (filename.indexOf(".pdf") > 0) {
        link.href = "data:application/pdf;base64," + base64;
      }
      if (filename.indexOf(".zip") > 0) {
        link.href = "data:application/zip;base64," + base64;
      }
      link.textContent = "Download";
      link.click();
      document.body.appendChild(link);
      link.remove();
    }, 0);
  };
}
