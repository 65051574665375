import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import IMASLog from "../../infrastructure/IMASLog";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AgentListModel } from "../../services/ProducerSearchService";
import WorkflowStore from "../../store/WorkflowStore";
import ContractsStore from "../../store/ContractsStore";
import { DownloadWorkflowDocumentSearchRequest, FileType, ReportFile, WorkflowDocumentListModel, WorkflowNotesResponse } from "../../services/WorkflowService";
import { Constants } from "../../infrastructure/enum/Constants";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import Utils from "../../infrastructure/Utils";
import { Permission } from '../../infrastructure/enum/Permission';
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { MainWorklowTab, WorkflowStatusType } from "../../infrastructure/enum/WorkflowDetails";


export class SearchWorkflowDocsViewModel implements ViewModel {

    Route = async (currentRoute: RouteList): Promise<void> => { }    

    @observable
    private loading = false;
    private onClose?: () => void;

    @computed
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    @computed
    get IsLoaded(): boolean {
        return true;
    }
    @computed
    get IsLoading(): boolean {
        return this.loading;
    }
    Close = (): void => {
        if (this.onClose)
            this.onClose();
    }
    @observable isLoading: boolean = false;
    @observable title: string = "";
    @observable agentId: number | undefined
    @observable companyId: number | undefined
    @observable docId: number = 0;
    @observable rows: number = 50; // rows is pagesize
    @observable totalRecords: number = 0;
    @observable first: number = 0; // first is pageindex
    @observable startIndex: number = 0;
    @observable selectedWorkflowStatusType: string = "All";
    @observable workflowStatusTypeList: any = [];

    @observable searchInputValue: string = ''
    @observable searchValue: string | undefined = undefined
    @observable selectedCompany: any
    @observable sortColumn: string = 'date'
    @observable sortOrder: boolean = false;
    @observable isException: boolean = false
    @observable isWarning: boolean = false
    @observable exceptionMessage: string = Constants.Error;
    @observable isWorkflowApiCall: boolean = true;
    @observable isSuccess: boolean = false
    @observable selectedTabIndex?: number = 0;
    @observable selectedMainTabIndex?: number = 1;
    @observable selectedKey: string | undefined = '';
    @observable selectedAgent: string = '';
    @observable selectedAgentId: number | undefined;


    @observable insuredLastName: string = '';
    @observable isReviveConfirm: boolean = false

    // Declare and define menuitem
    @observable conditionalactionItemsForWorkflow: MenuItem[] = []

    @action getConditionalactionItemsForWorkflow = () => {
        this.conditionalactionItemsForWorkflow = []
        let workflow = {
            label: 'Workflow',
            icon: "fa fa-sitemap",
            command: () => {
                setTimeout(() => {
                    this.showSidebarDocumentLog(this.selectedDocRow);
                }, 300);
            }
        }
        let download = {
            label: 'Download',
            icon: "fa fa-download",
            command: () => {
                setTimeout(() => {
                    this.downloadDocumentLink(this.docId);
                }, 300);
            }
        }
        let revive = {
            label: 'Revive',
            icon: "fa fa-share",
            command: () => {
                setTimeout(() => {
                    this.setReviveConfirmation(true);
                }, 300);
            }
        }
        this.conditionalactionItemsForWorkflow.push(workflow)
        this.conditionalactionItemsForWorkflow.push(download)
        Utils.hasUserPermission(Permission.ReviveDeletedDoc) && this.conditionalactionItemsForWorkflow.push(revive)
    }

    // Declare and define menuitem

    @action Load = async () => {        
        localStorage.removeItem("workFlowBackItem")
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'date';
        this.sortOrder = false;
        this.getConditionalactionItemsForWorkflow();        
        this.resetFilters();
        this.loadCompanies();
        this.valuesInWorkflowStatusList();
    };    

    @action setWorkflowStatusselectedType = (value: string) => {
        this.selectedWorkflowStatusType = value;
    };
    @action valuesInWorkflowStatusList = () => {
        this.workflowStatusTypeList = [];
        this.workflowStatusTypeList.push({
            label: "All",
            value: "All",
        });
        this.workflowStatusTypeList.push({
            label: "Received",
            value: "Received",
        });
        this.workflowStatusTypeList.push({ label: "Scrub", value: "Scrub" });
        this.workflowStatusTypeList.push({
            label: "Missing Info",
            value: "Missing Info",
        });
        this.workflowStatusTypeList.push({
            label: "Data Entry",
            value: "Data Entry",
        });

        this.workflowStatusTypeList.push({ label: "Pending", value: "Pending" });

        this.workflowStatusTypeList.push({ label: "Complete", value: "Complete" });

    };
    @action handleMainLinkClick = (item: string | undefined): void => {
        if (item) {
            this.selectedKey = item
            switch (item) {
                case MainWorklowTab.DocWorkflow:
                    routes.docWorkflow.push();
                    return
                case MainWorklowTab.SearchWorkflowDocs:
                    routes.searchWorkflowDocs.push();
                    return
                default:
                    return
            }
        }
    };
    @action handleMainTabClick = (index: number | undefined, key: string): void => {
        if (index === 0)
            routes.docWorkflow.push();
        else
            routes.searchWorkflowDocs.push();
    }

    @action setSelectedCompany = (value: string) => {
        this.selectedCompany = value;
    }
    @action setInsuredLastName(name: string) {
        this.insuredLastName = name;
    }
    @action insuredNameChange = (value: string) => {
        this.insuredName = value;
    }
    @action setSelectedAgent(agentName: any, agentId: number) {
        if (agentId) {
            this.selectedAgent = agentName;
            this.selectedAgentId = agentId;
        }
        else {
            this.selectedAgent = '';
            this.selectedAgentId = undefined;
        }
    }

    @observable agentsSearchList: number = 0
    @observable insuredSearchList: number = 0
    @observable insuredName: string = "";
    @observable filteredAgentsList = new Collection<AgentListModel>();
    @observable documentList = new Collection<WorkflowDocumentListModel>();
    @observable docWorkFlowHistoryList = new Collection<WorkflowNotesResponse>()
    @observable companyList = new Collection<{
        label: string, value: string
    }>()          
    @observable showInactiveCarriers: boolean = false;
    @observable carriersInitial = new Collection<{
      label: string;
      value: string;
    }>();


    @observable showStatus: string = 'Active Docs';
    @observable showData = [
        { label: 'Active Docs', value: 'Active Docs' },
        { label: 'Deleted/Replaced Docs', value: 'Deleted/Replaced Docs' },
        { label: 'ALL', value: 'ALL' }
    ]
    @observable selectedType: string = 'Application';
    @observable documentTypeList = [
        { label: 'Application', value: 'Application' },
        { label: 'Contract', value: 'Contract' },
        { label: 'Contract Invite', value: 'Contract Invite' },
        { label: 'Marketer Change', value: 'Marketer Change' }
    ]

    @action resetPagingValues() {
        this.rows = 50;
        this.totalRecords = 0;
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'date';
        this.sortOrder = false;
    }

    @action goSearch = async () => {        
        if (this.isWorkflowApiCall) {

            this.startIndex = 0;
            this.first = 0;
            this.isWorkflowApiCall = false;
            this.loadDocs();
        }
    }
    @action searchAgents = async (value: string) => {
        IMASLog.log('search agents ..')
        let result = await ProducerSearchStore.getAgentsLookup(1, value, 0, 20, undefined, undefined)
        var noAgent: AgentListModel = { id: 0 };
        if (result !== null) {
            if (result.recordCount) {
                this.agentsSearchList = result.recordCount
                if (result.data) {
                    this.filteredAgentsList.values = result.data;
                }
            }
            else {
                this.agentsSearchList = 0;
                this.filteredAgentsList.values = [noAgent];
                this.selectedAgent = '';
            }
        }
        else {
            this.agentsSearchList = 0;
            this.filteredAgentsList.values = [noAgent];
            this.selectedAgent = '';
        }
        setTimeout(() => {

        }, 500);
    }


    @observable AgentId: number = 0;
    @action handleInactiveCarriers = async (e: any) => {
          this.showInactiveCarriers = e.checked;
          this.companyList.values = []; 
          this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
          this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
        };
        
    @action loadCompanies = async () => {
        var defaultItem = { label: "ALL", value: "0" };
        try {
            var result = await ContractsStore.GetActiveInactiveAllCarrierLookup();
            if (result != null) {
                if (result != null) {
                    this.companyList.values = [];
                    result.forEach((obj: any) => {
                        var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.companyList.values.push(data);
                    });
                    this.carriersInitial.values = this.companyList.values;
                    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
                
                    this.companyList.values.splice(0, 0, defaultItem);
                    this.selectedCompany = "0";
                }
                else {
                    this.companyList.values = [];
                    this.companyList.values.splice(0, 0, defaultItem);
                    this.selectedCompany = "0";
                }
            }
        } catch (e) {
            this.companyList.values = [];
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";
        }
    }
    @action loadDocs = async () => {       
        this.isException = false;
        try {            
            var searchdto: any = {}
            searchdto.FileTypeId = this.selectedType === 'Application' ? 1 : this.selectedType === 'Contract' ? 9 : this.selectedType === 'Contract Invite' ? 42 : 43;
            searchdto.LastName = this.insuredLastName ? this.insuredLastName : undefined;
            searchdto.AgentId = this.selectedAgentId;
            searchdto.InsuredId = undefined;
            searchdto.InsuredName = this.insuredName ? this.insuredName : undefined;
            searchdto.CompanyId = this.selectedCompany && this.selectedCompany > 0 ? this.selectedCompany : undefined;
            searchdto.WorkFlowType = this.showStatus === 'ALL' ? 3 : this.showStatus === 'Deleted/Replaced Docs' ? 2 : 1;
            searchdto.WorkflowStatusId =
                this.selectedWorkflowStatusType === WorkflowStatusType.CompletedQueue
                    ? 0 :
                    this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue
                        ? 1
                        : this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue
                            ? 2
                            : this.selectedWorkflowStatusType ===
                                WorkflowStatusType.MissingInfoQueue
                                ? 3
                                : this.selectedWorkflowStatusType ===
                                    WorkflowStatusType.DataEntryQueue
                                    ? 4
                                    : this.selectedWorkflowStatusType === WorkflowStatusType.PendingQueue
                                        ? 100
                                        : this.selectedWorkflowStatusType ===
                                            "All"
                                            ? -1
                                            : 101;

            var result = await WorkflowStore.getWorkflowDocs(searchdto, this.startIndex, this.rows, this.sortColumn, this.sortOrder)
            if (result != null) {
                if (result.data) {
                    this.totalRecords = (result.recordCount) ? result.recordCount : 0;
                    this.documentList.values = result.data;
                }
                else {
                    this.documentList.values = [];
                }
            }
            this.isWorkflowApiCall = true;

        } catch (e) {
            IMASLog.log('exception from store: ' + e.value);
            this.documentList.values = [];
            this.isException = true;
            this.isWorkflowApiCall = true;
        }
    }
    @action goSearchDownloadFile = async () => {
        if (this.isWorkflowApiCall) {
            this.isWorkflowApiCall = false;
            await this.goSearchDownloadFileDetails();
         }
    }
    @action goSearchDownloadFileDetails = async () => {
        this.isException = false;  
        this.isLoading = true;
        try {
            var searchdto: DownloadWorkflowDocumentSearchRequest = {}
            searchdto.fileTypeId = this.selectedType === 'Application' ? 1 : this.selectedType === 'Contract' ? 9 : this.selectedType === 'Contract Invite' ? 42 : 43;
            searchdto.lastName = this.insuredLastName ? this.insuredLastName : undefined;
            searchdto.agentId = this.selectedAgentId;
            searchdto.insuredId = undefined;
            searchdto.insuredName = this.insuredName ? this.insuredName : undefined;
            searchdto.companyId = this.selectedCompany && this.selectedCompany > 0 ? this.selectedCompany : undefined;
            searchdto.workFlowType = this.showStatus === 'ALL' ? 3 : this.showStatus === 'Deleted/Replaced Docs' ? 2 : 1;
            searchdto.workflowStatusId =
                this.selectedWorkflowStatusType === WorkflowStatusType.CompletedQueue
                    ? 0 :
                    this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue
                        ? 1
                        : this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue
                            ? 2
                            : this.selectedWorkflowStatusType ===
                                WorkflowStatusType.MissingInfoQueue
                                ? 3
                                : this.selectedWorkflowStatusType ===
                                    WorkflowStatusType.DataEntryQueue
                                    ? 4
                                    : this.selectedWorkflowStatusType === WorkflowStatusType.PendingQueue
                                        ? 100
                                        : this.selectedWorkflowStatusType ===
                                            "All"
                                            ? -1
                                            : 101;
            searchdto.fileType = FileType._1; 
                                    
            var result = await WorkflowStore.downloadWorkflowDocs(searchdto);
            await this.downloadReport(result, "WorkflowDocument");
            this.isWorkflowApiCall = true; 
            this.isLoading = false;

        } catch (e) {
            IMASLog.log('exception from store: ' + e.value);
            this.documentList.values = [];
            this.isException = true;
            this.isWorkflowApiCall = true;
        }
    }

    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };

    @action loadDocumentWorkFlowHistory = async (documentId: number) => {
        try {
            var result = await WorkflowStore.getDocWorkFlowHistory(documentId);
            if (result) {
                var output: any = [];
                output = result;
                this.docWorkFlowHistoryList.values = output;
                this.setSidebarLogVisibility(true);
            }
            else {
                this.docWorkFlowHistoryList.values = [];
            }
        } catch (e) {
            IMASLog.log('exception from store: ' + e.value);
            this.docWorkFlowHistoryList.values = [];
            this.isException = true;
            this.setSidebarLogVisibility(false);
        }
    }
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadDocs();
    }
    @action agentSearchChange(value: string) {
        this.selectedAgent = value;
        if (!value) {
            this.selectedAgentId = undefined;
        }
    }

    @action setSelectedDoc = (docId: number) => {
        this.docId = docId;
    }
    @action setSelectedRow = (value: any) => {
        this.selectedDocRow = value;
    }
    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    @action setIsWarning(value: boolean) {
        this.isWarning = value;
    }

    @observable isSidebarLogVisible: boolean = false;
    @observable selectedDocRow: any = {};
    @action showSidebarDocumentLog(value: any) {
        this.selectedDocRow = value;
        var obj = JSON.parse(JSON.stringify(value))
        var id = obj.id;
        // to clear the validation error message      
        this.ResetValidate();
        this.loadDocumentWorkFlowHistory(id);
    }
    @action setSidebarLogVisibility(value: boolean) {
        this.isSidebarLogVisible = value
    }
    @action loadDocument = (base64: string, id?: number, filename?: string) => {
        let base = window.location.href
        let url = `${base}/pdfViewer/id/${id}`
        let win = window.open(url, "_blank");
        let html = "";
        html += "<html>";
        html += '<body style="margin:0!important">';
        html += '<div style="width: 100%;height: 100%; position: relative; ">';
        html +=
            '<div style="width: 100%;height: 100%; position: absolute;top: 0;left: 0; z-index: 10;">';
        html +=
            '<embed width="100%" height="100%" src="data:application/pdf;base64,' +
            base64 +
            '" type="application/pdf" />';
        html += "</div>";
        html += "</div>";
        html += "</body>";
        html += "</html>";
        setTimeout(() => {
            if (win !== null) {
                win.document.write(html);
                win.document.title = filename ? filename : 'PDF file'
            }
        }, 0);
    };
    @action downloadDocument = (result: any) => {
        if (result.pdfData) {
            var link = document.createElement("a");
            let n = result.filename ? result.filename.split(".")[0] : "";
            link.href = "data:application/pdf;base64," + result.pdfData;
            link.textContent = "Download PDF";
            link.download = n !== "" ? n : "document.pdf";
            link.click();
            document.body.appendChild(link);
        }
    }
    @action openDocumentLink = async (docId: number) => {
        this.isWarning = false;
        this.isException = false;
        try {
            if (docId !== undefined && docId !== null && docId > 0) {
                var result = await WorkflowStore.downloadWorkflowDocument(docId);
                if (result != null) {
                    if (result.pdfData && result.pdfData !== null) {
                        // this.loadDocument(result.pdfData);
                        this.loadDocument(result.pdfData, docId, result.filename);
                    }
                    else {
                        if (result.message && result.message !== null) {
                            this.exceptionMessage = result.message;
                            this.isWarning = true;
                            setTimeout(() => {
                                this.isWarning = false;
                                this.exceptionMessage = ''
                            }, 2500)
                        }
                        else {
                            this.isException = true;
                        }
                    }
                }
            } else {
                this.exceptionMessage = "Invalid document Id. PDF File not found!"
                this.isWarning = true;
                setTimeout(() => {
                    this.isWarning = false;
                    this.exceptionMessage = ''
                }, 2500)
            }

        } catch (e) {

            var response = e.response;
            if (response !== undefined && response !== null) {
                var obj = JSON.parse(response);
                IMASLog.log('exception from store: ' + obj.title);
                if (obj.status === 500 && obj.title.includes("PDF File for document")) {
                    this.exceptionMessage = "PDF File for this document not found!"
                    this.isWarning = true;
                    setTimeout(() => {
                        this.isWarning = false;
                        this.exceptionMessage = ''
                    }, 2500)
                }
                else {
                    this.isException = true;
                }
            }
            else {
                this.isException = true;
            }
        }
    }

    @action downloadDocumentLink = async (docId: number) => {
        this.isWarning = false;
        this.isException = false;
        try {
            var result = await WorkflowStore.downloadWorkflowDocument(docId);
            if (result != null) {
                if (result.pdfData) {
                    this.downloadDocument(result);
                }
                else {
                    this.exceptionMessage = "PDF File for this document not found!"
                    this.isWarning = true;
                    setTimeout(() => {
                        this.isWarning = false;
                        this.exceptionMessage = ''
                    }, 2500)
                }
            }
        } catch (e) {
            var response = e.response;
            if (response !== undefined && response !== null) {
                var obj = JSON.parse(response);
                IMASLog.log('exception from store: ' + obj.title);
                if (obj.status === 500 && obj.title.includes("PDF File for document")) {
                    this.exceptionMessage = "PDF File for this document not found!"
                    this.isWarning = true;
                    setTimeout(() => {
                        this.isWarning = false;
                        this.exceptionMessage = ''
                    }, 2500)
                }
                else {
                    this.isException = true;
                }
            }
            else {
                this.isException = true;
            }
        }
    }
    @action onReviveClick = async () => {
        try {
            if (this.isWorkflowApiCall) {

                this.isWorkflowApiCall = false;

                await WorkflowStore.updateWorkflowDocumentStatus(this.docId);
                this.setReviveConfirmation(false);
                await this.loadDocs();
            }
            this.isWorkflowApiCall = true;

        } catch (error) {
            IMASLog.log('exception ..document revive with document id : ' + this.docId);
            this.isException = true;
            this.setReviveConfirmation(false);
        }
    }
    @action setReviveConfirmation(value: boolean) {
        this.isReviveConfirm = value;
    }

    @action
    public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
    @action resetFilters = () => {
        this.showInactiveCarriers = false;
        this.insuredLastName = '';
        this.searchValue = '';
        this.selectedCompany = "0";
        this.selectedAgent = '';
        this.insuredName = '';
        this.selectedWorkflowStatusType = "All"
        this.selectedAgentId = undefined;
        this.selectedType = 'Application';
        this.showStatus = 'Active Docs';
        this.documentList.values = [];
        this.resetPagingValues(); 
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
    }
    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }
    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action setSelectedType = (value: string) => {
        this.selectedType = value
    }
    @action showChange(value: string) {
        this.showStatus = value
    }

    @observable
    public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<SearchWorkflowDocsViewModel>{
    public constructor() {
        super();
    }
}


