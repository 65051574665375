import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel"
import RouteList from "../../infrastructure/RouteList";
import Collection from "../../infrastructure/CollectionHelper";
import BusinessUnitConfigStore from '../../store/BusinessUnitConfigStore'

export class AgentsGroupSummaryViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { }
    @observable rows: number = 200;
    @observable totalRecords: number = 0;
    @observable id: number = 0;
    @observable sortAscending: boolean = false;
    @observable sortColumn: string = 'createdOn';
    @observable groupSummaryList = new Collection<any>()
    @observable agentId: number = 0;
    @observable isLoading: boolean = false;
    @observable isMessageVisible: boolean = false;
    @observable response: any;
    @observable startIndex: number = 0;
    @observable pageSize: number = 200;
    @observable first: number = 0;
    @action setSortOrder() {
        this.sortAscending = !this.sortAscending;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action noRecordsFound() {
        this.totalRecords = 0;
        this.groupSummaryList.values = [];
    }

    @action Load = async (agentid: number, id: number) => {
        this.id = id;
        this.agentId = agentid;
        this.rows = 200;    
        this.noRecordsFound();
        await this.loadAgentsByAgentGroup()
    };

    @action loadAgentsByAgentGroup = async () => {
        this.isMessageVisible = false;
        this.response = "";

        try {
            let result = await BusinessUnitConfigStore.getAllAgentsbyGroupId(this.id, this.startIndex, this.rows, this.sortColumn, this.sortAscending
            )

            if (result) {
                if (result.recordCount) {
                    this.totalRecords = result.recordCount
                    this.groupSummaryList.values = result.data ? result.data : []
                }
                else {
                    this.noRecordsFound();
                }

            }
            else {
                this.noRecordsFound();
            }
            this.isLoading = false;
        }
        catch (e) {

            this.isMessageVisible = true;
            this.isLoading = false;
        }
    }

    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadAgentsByAgentGroup();
    }

    @action downloadAgentReport = async(fileType: number) => {
        var res = await BusinessUnitConfigStore.downloadAllAgentByGroupReport(this.id, fileType)
        if(res){
            this.downloadReport(res)
        }
    }

    @action downloadReport = async (result: any) => {
        if (result) {
          await fetch(`data:${result.contentType};base64,${result.data}`)
            .then((response) => response.blob())
            .then(function (myBlob) {
              var link = document.createElement("a");
              var url = window.URL.createObjectURL(myBlob);
              let n = result.fileName ? result.fileName.split(".")[0] : "";
              link.href = url;
              link.download = n !== "" ? n : "Report";
              link.click();
              window.URL.revokeObjectURL(url);
              link.remove();
            });
        }
      };



}