import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import { Constants } from "../../../../infrastructure/enum/Constants";
import { routes } from "../../../../router";
import RouteList from "../../../../infrastructure/RouteList";
import IdentityStore from "../../../../store/IdentityStore";
import {AppSettingUpdateModel} from "../../../../services/IdentityService";
import UserContext from "../../../../infrastructure/UserContext";
import PageContext from "../../../../infrastructure/PageContext";
export class RolePermissionsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get CanRoute(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoaded(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoading(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  Close(): void {
    throw new Error(Constants.NoMethodImplementation);
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable selectedPermission: any;
  @observable rolePermissionsData: any;
  @observable roleId: number = 0;
  @observable mainGroups: any = {};

  @action setSelectedPermission(value: any) {
    this.selectedPermission = value;
  }

  @action onExpand(node: any) {
    for (let group in this.mainGroups) {
      if (
        this.mainGroups[group].length > 0 &&
        this.mainGroups[group].includes(node.key)
      ) {
        this.expandedNodes = {};
        this.expandedNodes[group] = true;
        this.mainGroups[group].forEach((e: string) => {
          var k = node.key;
          if (e === k) {
            this.expandedNodes[k] = true;
          }
        });
      }
    }
  }

  @action onCollapse(node: any) {}

  @observable expandedNodes: any;

  @action goToRoles = () => {
    routes.listRole.push();
  };

  @action Load = async (id: number) => {
    this.roleId = id;
    var result = await IdentityStore.appSettingByRoleId(id);
    if (result) {
      this.rolePermissionsData = result.appSettings;
      await this.expandTreeNode(this.rolePermissionsData);
      this.selectedPermission = result.selectedAppSetting;
    }
  };

  @action expandTreeNode = async (nodes: any) => {
    let _expandedKeys = {};
    for (let node of nodes) {
      await this.expandNode(node, _expandedKeys);
      if (node.className === "level1") {
        this.mainGroups[node.key] = node.children
          .filter(function (p: any) {
            return p.className === "level2";
          })
          .map(function (item: any) {
            return item.key;
          });
      }
    }
    this.expandedNodes = _expandedKeys;
  };

  @action expandNode = async (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        this.expandNode(child, _expandedKeys);
      }
    }
  };

  @action savePermissions = async (updateMenu: any) => {
    var dto: AppSettingUpdateModel = {
      roleId: this.roleId,
      bits: this.getCheckedPermissions(),
    };
    await IdentityStore.updateAppSetting(dto);
    window.scrollTo(0, 0);
    var responseModel = {
      status: 200,
      title: Constants.appsettingSuccessMessage,
      errors: { "": [] },
  };
    await this.getUserPermission();
    updateMenu.reloadMenus();
    this.Load(this.roleId);
    setTimeout(() => {
      PageContext.setResponseMessage(JSON.stringify(responseModel));
      PageContext.setIsMessageVisible(true);
    },300);
  };

  @action getCheckedPermissions = () => {
    var permissionkeys = [];
    for (const [key] of Object.entries(this.selectedPermission)) {
      if (Number(key)) {
        permissionkeys.push(parseInt(key));
      }
    }
    return permissionkeys;
  };
  @action getUserPermission = async () => {
    await IdentityStore.getUserPermissions("" + UserContext.getUserId()).then(
      (data: any) => {
        if (data) {
          UserContext.permissions = data.permissions;
          UserContext.roles = data.roles;
        }
      }
    );
  };
}
