import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import ProducerStore from "../../store/ProducerStore";
import Utils from "../../infrastructure/Utils";

export class AddEmailsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }

  get CanRoute(): boolean {
    return true;
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: EmailListListener | undefined;

  @observable eventListId: number | undefined = undefined;
  @observable title: string = "Add Emails to Marketing Event List";
  @observable emailList: string = "";
  @observable areEmailsValid: boolean = false;
  @observable fileData: any = null;
  @observable fileName: string = "";
  @observable emails: string[] = [];
  @observable IsEmptyForm: boolean = false;
  @observable isInvalidForm: boolean = false;
  @observable isInvalidFile: boolean = false;
  @observable fileNameExtension: string = "";
  @observable saveButtonDisabled: boolean = false;
  
  @action setEmailList = (value: string) => {
    this.emailList = value;
  };
  @action reset = () => {
    this.emailList = "";
    this.emails = [];
    this.areEmailsValid = false;
    this.IsEmptyForm = false;
    this.isInvalidForm = false;
    this.isInvalidFile = false;
    this.fileData = null;
    this.fileName = "";
    this.fileNameExtension = "";
     this.saveButtonDisabled = false;
  };

  @action validate(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).trim().toLowerCase());
  }

  @action emailValidator = () => {
    if(this.emailList.trim()!==null && this.emailList.trim()!==""&&this.emailList.trim()!==undefined)
    {
      this.emails = this.emailList.trim().split(",");
      if (this.emails.length > 1) {
        let list = this.emails.map((email) => this.validate(email));
        let checker = (arr: any) => arr.every((v: any) => v === true);
        this.areEmailsValid = checker(list);
      } else {
        this.areEmailsValid = false;
      }
    }
    else {
      this.areEmailsValid = false;
    }
  };

  setListListener(listener: EmailListListener) {
    this.listener = listener;
  }

  @action Load = async () => {  this.saveButtonDisabled = false;};

  @action cancel = async () => {
    if (this.listener) {
      this.listener.cancelEmailListListener();
      this.reset();
    }
  };

  @action save = async (eventListId?: number) => {
    this.eventListId = eventListId;
    // this.saveButtonDisabled = true;
    await this.submit();
  };
  onDrop = (value: any, fileName: string) => {
    this.fileData = value;  

   // observed old code used for extracting file name and file extension which is not right. so corrected the old code and below is the latest.  Refer Workflow ticket AMS-5025 for more details. 
    var fileNameExtension = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    var fileNamewithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    var formattedFileName = fileNamewithoutExtension + "." + fileNameExtension;

    this.fileName = formattedFileName;
    this.fileNameExtension = fileNameExtension;
  };
  @action submit = async () => {
    await this.ResetValidate();
    this.IsEmptyForm = false;
    this.isInvalidForm = false;
    this.isInvalidFile = false;
    this.areEmailsValid = false;
    try {
      if((this.fileData === null || this.fileData === "" || this.fileData === undefined)&&
      (this.emailList === null || this.emailList === "" || this.emailList === undefined))
      {
        this.IsEmptyForm = true;
      }

      else if((this.fileData !== null && this.fileData !== "" && this.fileData !== undefined)&&
      (this.emailList !== null && this.emailList !== "" && this.emailList !== undefined))
      {
        this.isInvalidForm  = true;
        
      }
      else if((this.fileData === null || this.fileData === "" || this.fileData === undefined)&&
      (this.emailList !== null && this.emailList !== "" && this.emailList !== undefined))
      {
        this.emailValidator();
      }

     if(!this.IsEmptyForm && !this.isInvalidForm && this.areEmailsValid)
     {
        this.saveButtonDisabled = true;
        let id = this.eventListId ? this.eventListId : 0;
        let temp = this.emailList ? this.emailList.trim().split(","): [];
        let emails = temp.map((i) => i.trim().toLowerCase()).join(" ");
        var authorization = await ProducerStore.getApiKey();
        var blob = null;
        if (this.fileData !== null && this.fileData !== "" && this.fileData !== undefined) {
          var block = this.fileData ? this.fileData.split(";") : "";
          var contentType = block[0].split(":")[1];
          var realData = block[1].split(",")[1];
          blob = Utils.b64toBlob(realData, contentType, 512);
        }

        await ProducerStore.addMarketingEventEmail(
          ProducerStore.getApiVersion(),
          undefined,
          undefined,
          id,
          emails,
          this.fileData !== null && this.fileData !== "" && this.fileData !== undefined ? true:false,
          blob,
          this.fileName,
          authorization,
          new Date().getTimezoneOffset()

        );

        setTimeout(() => {
          if (this.listener) {
            this.listener.addEmailListListener();
            this.listener.cancelEmailListListener();
          }
          this.reset();
        }, 1000);
      }
    } catch (e) {}
  };

  public ErrorModel = new ErrorModel(new AddEmailListValidator());
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
}

export interface EmailListListener {
  addEmailListListener(): void;
  cancelEmailListListener(): void;
}

class AddEmailListValidator extends AbstractValidator<AddEmailsViewModel> {
  public constructor() {
    super();
  }
}
