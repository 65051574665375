import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import RouteList from "../../../infrastructure/RouteList";
import { routes } from "../../../router";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import { ImportColumnDefinitionModel } from "../../../services/BusinessUnitConfigService";
import PageContext from "../../../infrastructure/PageContext";
import { Constants } from "../../../infrastructure/enum/Constants";

export class ColumnDefinitionListViewModel  implements ViewModel {
  get CanClose(): boolean {
      throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
      return true
  }
  get IsLoaded(): boolean {
      throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
      throw new Error("Method not implemented.");
  }
  Close(): void {
      throw new Error("Method not implemented.");
  }
  @action Load = async () => {
     await this.resetFiltersToDefault()
     await this.loadColumnDefinitions();
  };

  Route = async (currentRoute: RouteList): Promise<void> => { }
  static Symbol: symbol = Symbol("ColumnDefinitionListViewModel");

    @observable selectedId: string = ""
    @observable searchValue: string = '';
    @observable importTypes: any  = [
      { label: "Agent Debt", value: "3" },
      { label: "Agent RTS Info", value: "4" },
      { label: "Contract", value: "2" },
      { label: "Lead", value: "6" },
      { label: "Persistancy", value: "5" },
      { label: "Policy Dashboard", value: "0" },
      { label: "Revenue Dashboard", value: "1" },
    ];
    @observable selectedImportType: string = "0"
    @observable dataTypes: any  = [
      { label: "Object", value: '0' },
      { label: "DBNull", value: '1' },
      { label: "Boolean", value: '2' },
      { label: "Char", value: '3' },
      { label: "SByte", value: '4' },
      { label: "Byte", value: '5' },
      { label: "Int16", value: '6' },
      { label: "UInt16", value: '7' },
      { label: "Int32", value: '8' },
      { label: "UInt32", value: '9' },
      { label: "Int64", value: '10' },
      { label: "UInt64", value: '11' },
      { label: "Single", value: '12' },
      { label: "Double", value: '13' },
      { label: "Decimal", value: '14' },
      { label: "DateTime", value: '15' },
      { label: "String", value: '16' },
    ];
    @observable selecteddataType: number = 0
    @observable columnDefinitions= new Collection<any>();
    @observable isDelete: boolean = false;
    @observable importId: number = 0;
  
    @action goToAdmin = () => {
      routes.administration.push()
    }

    resetFiltersToDefault() {
      this.selectedImportType = '0'
      this.columnDefinitions.values = [];
      this.isDelete = false;
      this.importId = 0;
    }  
    
    @action addColumnDefinition = async() => {
      let newValues = this.columnDefinitions.values
      let newRow: ColumnDefinitionData =  {
        columnIndex: 'cd' + new Date().getMilliseconds(),
        columnName: "",
        dataType: 0,
        required: true,
        defaultValue: "",
        validColumnName: false,
      };
      newValues.push(newRow)
      this.columnDefinitions.values = [];
      this.columnDefinitions.values = newValues;
    }

    @action deleteColumnDefinition = async() => {
      this.columnDefinitions.values = this.columnDefinitions.values.filter(item => ''+item.columnIndex !== this.selectedId)
      this.showDeleteConfirmation(false)
    }

    @action loadColumnDefinitions = async() => {
      var result = await BusinessUnitConfigStore.getImportColumnDefinitionByType(+this.selectedImportType)
      this.columnDefinitions.values = []
      if(result){
          this.importId = result.id || 0;
          this.columnDefinitions.values = result.columns;
          let id = 1;
          this.columnDefinitions.values.forEach((obj) => {
              obj.id = 'cd' + id;
              obj.validColumnName = false;
              id++;
          })
      }
    } 

    @action columnNameChange = async(value: string, rowData: any) => {
      let newValues: ColumnDefinitionData[] = [];
      this.columnDefinitions.values.forEach((obj) => {

        if (obj.columnIndex === rowData.columnIndex) {
          obj.columnName = value;
        }

        newValues.push(obj);
      });
      this.columnDefinitions.values = newValues;
    }

    @action dataTypeChange = async(value: string, rowData: any) => {
      let newValues: ColumnDefinitionData[] = [];
      this.columnDefinitions.values.forEach((obj) => {

        if (obj.columnIndex === rowData.columnIndex) {
          obj.dataType = value;
        }

        newValues.push(obj);
        console.log(newValues);
      });
      this.columnDefinitions.values = newValues;
    }

    @action showDeleteConfirmation = async(value: boolean) => {
      this.isDelete = value
    }

    @action toServiceModel = async() =>  {
      var dto: ImportColumnDefinitionModel = {
        id: this.importId,
        importType: +this.selectedImportType,
        columns: await this.getColumnDate()
      };
      return dto;
    }

    @action getColumnDate = async() => {
      let columnData: any[] = []
      if(this.columnDefinitions.values.length > 0){
        let id = 0;
        this.columnDefinitions.values.forEach((obj) => {
          if(obj.columnName && obj.columnName !== Constants.OptionalField){
            let data = {
              columnIndex: id,
              columnName: obj.columnName,
              dataType: +obj.dataType,
              required: true,
              defaultValue: obj.defaultValue
            }
            columnData.push(data);
            id++;
          }
        });
      }
      return columnData;
    }

    @action save = async() => {
      // Validate for duplicate column Name
      let validInput = this.columnDefinitions.values.filter(item => item.validColumnName === true)
      let columnName = this.columnDefinitions.values.map(function (item) {return item.columnName});
      let hasDuplicate = columnName.some((val, i) =>columnName.indexOf(val) !== i);
        if(validInput.length !== 0 && hasDuplicate){
          var successResponse = {
            status: 400,
            title: "Duplicate Column Name exists",
            errors: { "": [] },
          };
          PageContext.setResponseMessage(JSON.stringify(successResponse));
          PageContext.setIsMessageVisible(true);
          window.scrollTo(0, 0);
        } else
        {
          var item = await this.toServiceModel();
          await BusinessUnitConfigStore.createOrUpdateImportColumnDefinition(item);
          await this.loadColumnDefinitions()
        }
      
    }

    @action valiadteColumnName = async(value: string, rowData: ColumnDefinitionData) => {
      if (value){
        let repeatedColumnNameCount = this.columnDefinitions.values.filter(item => item.columnName.toLowerCase() === value.toLowerCase())
        if(repeatedColumnNameCount && repeatedColumnNameCount.length > 1){
          rowData.validColumnName = true
        } else {
          rowData.validColumnName = false
        }
      }
    }

    @action checkForValidColumnInput = async() => {
      let validInput = this.columnDefinitions.values.filter(item => item.validColumnName === true)
      let hasDuplicate = this.columnDefinitions.values.some((val, i) => this.columnDefinitions.values.indexOf(val.columnName) !== i);
      return validInput.length !== 0 ? true : false
    }

}

export interface ColumnDefinitionData {
  columnIndex: string | number | undefined;
  columnName: string | undefined;
  dataType: number;
  required: boolean;
  defaultValue: string | undefined;
  validColumnName: boolean;
}