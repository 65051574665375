import React from "react";
import { observer } from "mobx-react";
import { TodaysTaskViewModel } from "../../viewModels/home/TodaysTaskViewModel";
import { Constants } from "../../infrastructure/enum/Constants";
import { Button } from "primereact/button";
import { routes } from "../../router";
import Utils from "../../infrastructure/Utils";
import { IMASCheckbox } from "../components/controls/IMASCheckbox";
import { TaskListModel } from "../../services/TaskService";

export const TodaysTaskView: React.FC<{ vm: TodaysTaskViewModel }> = observer(
  (props) => {
    const handleCheckedComplete = () => {
      if (props.vm.selectedTasks.length > 0) {
        props.vm.callCompleteTask();
      } else props.vm.isCompleteError = true;
    };

    const setSelectedTasks = (value: any, index: number) => {
      props.vm.setCheckedTask(index);
      props.vm.setTasksSelection(value, index);
    };

    const prorityTemplate = (rowData: any) => {
      let rowObj = Utils.getObject(rowData);
      var pillColor = "";
      var statusValue = "";
      switch (rowObj.priority) {
        case 1:
          pillColor = "green";
          statusValue = "LOW";
          break;
        case 2:
          pillColor = "yellow";
          statusValue = "MEDIUM";
          break;
        case 3:
          pillColor = "red";
          statusValue = "HIGH";
          break;
      }
      return <span className={"pill pill-" + pillColor}>{statusValue}</span>;
    };

    const dateTemplate = (rowData: any) => {
      return (
        <span className="font-color-black" style={{ width: "150px" }}>
          {new Intl.DateTimeFormat("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }).format(new Date(rowData.taskDate ? rowData.taskDate : "null"))}
        </span>
      );
    };

    return (
      <>
        {/* <Card className="home-mid-badge"> */}
          <div className="task-content-wrapper">
            {props.vm.taskList.values.length > 0 ? (
              props.vm.taskList.values.map(
                (i: TaskListModel, index: number) => {
                  return (
                    <div className="task-content-box">
                      <IMASCheckbox
                        label=""
                        id="checkForTask"
                        disabled={false}
                        value={props.vm.isTaskChecked[index]}
                        onChange={() => setSelectedTasks(i, index)}
                      />
                      <p>
                        <span className="task-title">{i.title}</span>
                        <span>{dateTemplate(i)}</span>
                        <span className="task-priority">
                          {prorityTemplate(i)}
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            routes.editMyTask.push({
                              taskId: i.id ? i.id : 0,
                            })
                          }
                        >
                          <i className="fa fa-pencil"></i>
                        </span>
                      </p>
                    </div>
                  );
                }
              )
            ) : (
              <div
                className="task-content-box"
                style={{
                  borderBottomColor: "transparent",
                  marginTop: "25px",
                }}
              >
                {Constants.NoTasksForToday}
              </div>
            )}

            {props.vm.isCompleteError && (
              <div
                className="invalid-feedback"
                style={{ marginTop: "15px" }}
              >{`Please check one or more tasks`}</div>
            )}

            {props.vm.taskList.values.length > 0 && (
              <Button
                label={`Set Checked Complete`}
                onClick={handleCheckedComplete}
                className="primary-button"
                style={{ marginTop: "30px" }}
              />
            )}
          </div>
        {/* </Card> */}
      </>
    );
  }
);
