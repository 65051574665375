import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';
import { observable, action } from "mobx";

export class ErrorModel<T> {
    @observable private errors = new Map<string, string[]>();

    constructor(private Validator: AbstractValidator<T>) {
    }

    public getError(key: keyof T & string) {
        return this.errors.get(key);
    }

    @action
    private setErrors(failures: ValidationFailure[]) {
        this.errors = new Map<string, string[]>();
        for (let failure of failures) {
            if (failure.message) {
                var array = this.errors.get(failure.propertyName);
                if (array === undefined) {
                    array = [];
                }
                array.push(failure.message);

                this.errors.set(failure.propertyName, array);
            }
        }
    }
     @action
     public ResetValidation = async(input: T) =>
     {
         this.setErrors([])
         return false
     }

    @action
    public Validate = async (input: T) => {
        var results = await this.Validator.validateAsync(input);
        this.setErrors(results.getFailures());
        return results.getFailures().length > 0
    }

    @action
    public IsFormValid = async (input: T) => {
        var results = await this.Validator.validateAsync(input);
        return results.getFailures().length > 0
    }
}