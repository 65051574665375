import { getToken } from "../infrastructure/Auth0Authenticator";
import { action } from "mobx";
import UserContext from "../infrastructure/UserContext";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import ServiceClient from "../infrastructure/client/ServiceClient";
import {Client as AddressServiceClient } from "../services/AddressService";
class AddressStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): AddressServiceClient => {    
    return ServiceClient.getClient(ServiceType.Address);
  }

  @action getRecommendations = async (addressLine: string) => {   
      var result = await this.getClient().getAllAddressRecommendations(this.getApiVersion(), addressLine,
        UserContext.getUserId()
      );
      return result;
   
  };
 
  @action validate = async (addressLine: string) => {      
      var result = await this.getClient().validate(this.getApiVersion(), addressLine,
        UserContext.getUserId()
      );
      return result;    
  };

  @action getAddressByZipCode = async (zip: string) => {
      
      var result = await this.getClient().getCityStateCountyByZipCode(this.getApiVersion(), zip,
        UserContext.getUserId()
      );
      return result;
   
  };
 

}
export default new AddressStore();
