import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { Constants } from "../../infrastructure/enum/Constants";
import PageContext from "../../infrastructure/PageContext";
import ProducerStore from "../../store/ProducerStore";
import { MergeAgentPreviewResponseModel } from "../../services/ProducerService";

class AddMergeAgentValidator extends AbstractValidator<MergeAgentsViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.fromAgentId)
      .isNotEmpty()
      .withFailureMessage("From Agent Id is required");

    this.validateIfString((input) => input.toAgentId)
      .isNotEmpty()
      .withFailureMessage("To Agent Id is required");
  }
}
export class MergeAgentsViewModel implements ViewModel {
  @observable AgentPreview: MergeAgentPreviewResponseModel | null = null;

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action Load = async () => {
    this.resetAll();
    this.resetInput();
    await this.ResetValidate();
    await this.ResetValidateAgent();
  };

  @action ResetValidateAgent = async () => {
    return await this.ErrorModelCustomer.ResetValidation(this);
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @observable
  public ErrorModelCustomer = new ErrorModel(new AddMergeAgentValidator());

  @action resetAll() {
    this.isCancelConfirm = false;
    this.isMergeConfirm = false;
    this.exceptionMessage = "";
    this.response = {};
    this.previewResults = false;
    this.isSuccess = false;
    this.isException = false;
  }

  @observable isLoading: boolean = false;
  @observable response: any;
  @observable fromAgentId: string = "";
  @observable toAgentId: string = "";
  @observable exceptionMessage: string = "";
  public ErrorModel = new ErrorModel(new AddMergeAgentValidator());
  @observable isCancelConfirm: boolean = false;
  @observable isMergeConfirm: boolean = false;
  @observable previewResults: boolean = false;
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable fromAgentVisible: boolean = false;
  @observable toAgentVisible: boolean = false;
  @observable isInvalidFromAgentId: boolean = false;
  @observable isInvalidToAgentId: boolean = false;

  @action setFromAgentVisible(value: boolean) {
    this.fromAgentVisible = value;
  }

  @action setToAgentVisible(value: boolean) {
    this.toAgentVisible = value;
  }

  @action setFromAgentIdChange(value: string) {
    this.fromAgentId = value;
  }

  @action setToAgentIdChange(value: string) {
    this.toAgentId = value;
  }

  @action setCancelConfirmation(value: boolean) {
    this.isCancelConfirm = value;
  }

  @action setMergeConfirmation(value: boolean) {
    this.isMergeConfirm = value;
  }

  @action
  public Cancel = async () => {
    this.isCancelConfirm = false;
    this.isMergeConfirm = false;
    this.resetInput();
    this.resetAll();
    this.previewResults = false;
    PageContext.setIsMessageVisible(false);
    await this.ResetValidate();
    await this.ResetValidateAgent();
    // routes.home.replace()
  };
  @action
  public resetInput = async () => {
    this.setFromAgentVisible(false);
    this.setToAgentVisible(false);
    this.fromAgentId = "";
    this.toAgentId = "";
  };

  @action onMergeAgents = async () => {
    try {
      this.isLoading = true;
      this.resetAll();
      var dto = { fromMailId: +this.fromAgentId, toMailId: +this.toAgentId };
      await ProducerStore.mergeAgents(dto);
      var responseModel = {
        status: 200,
        title: Constants.MergeSuccess,
        errors: { "": [] },
      };
      this.isSuccess = true;
      this.response = JSON.stringify(responseModel);
      PageContext.setResponseMessage(this.response);
      PageContext.setIsMessageVisible(true);
      this.resetInput();
      setTimeout(() => {
        PageContext.setIsMessageVisible(false);
      }, 1500);
    } catch (e) {
      this.exceptionMessage = e.response;
      this.isException = true;
    }
    this.isLoading = false;
  };

  @computed
  get fromStatus(): string {
    if (this.AgentPreview) return "" + this.AgentPreview.fromStatus;
    return "";
  }

  @computed
  get toStatus(): string {
    if (this.AgentPreview) return "" + this.AgentPreview.toStatus;
    return "";
  }

  @computed
  get fromCityStateZip(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.fromCityStateZip &&
        this.AgentPreview.fromCityStateZip != null
        ? this.AgentPreview.fromCityStateZip
        : "";
    return "";
  }

  @computed
  get toCityStateZip(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.toCityStateZip &&
        this.AgentPreview.toCityStateZip != null
        ? this.AgentPreview.toCityStateZip
        : "";
    return "";
  }

  @computed
  get fromNPN(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.fromNPN && this.AgentPreview.fromNPN != null
        ? this.AgentPreview.fromNPN
        : "";
    return "";
  }
  @computed
  get toNPN(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.toNPN && this.AgentPreview.toNPN != null
        ? this.AgentPreview.toNPN
        : "";
    return "";
  }
  @computed
  get fromFullName(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.fromFullName &&
        this.AgentPreview.fromFullName != null
        ? this.AgentPreview.fromFullName
        : "";
    return "";
  }
  @computed
  get toFullName(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.toFullName &&
        this.AgentPreview.toFullName != null
        ? this.AgentPreview.toFullName
        : "";
    return "";
  }
  @computed
  get fromPoliciesCount(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.fromPoliciesCount &&
        this.AgentPreview.fromPoliciesCount != null
        ? this.AgentPreview.fromPoliciesCount
        : "";
    return "";
  }
  @computed
  get toPoliciesCount(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.toPoliciesCount &&
        this.AgentPreview.toPoliciesCount != null
        ? this.AgentPreview.toPoliciesCount
        : "";
    return "";
  }

  @computed
  get fromAgentNoteCount(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.fromAgentNoteCount &&
        this.AgentPreview.fromAgentNoteCount != null
        ? this.AgentPreview.fromAgentNoteCount
        : "";
    return "";
  }

  @computed
  get toAgentNoteCount(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.toAgentNoteCount &&
        this.AgentPreview.toAgentNoteCount != null
        ? this.AgentPreview.toAgentNoteCount
        : "";
    return "";
  }

  @computed
  get fromDocCount(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.fromDocCount &&
        this.AgentPreview.fromDocCount != null
        ? this.AgentPreview.fromDocCount
        : "";
    return "";
  }

  @computed
  get toDocCount(): string {
    if (this.AgentPreview)
      return "" + this.AgentPreview.toDocCount &&
        this.AgentPreview.toDocCount != null
        ? this.AgentPreview.toDocCount
        : "";
    return "";
  }

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @action validateAgentIds = (): boolean => {
    let max = 2147483647;
    let fromAgentId = this.fromAgentId ? Number(this.fromAgentId) : 0;
    let toAgentId = this.toAgentId ? Number(this.toAgentId) : 0;
    this.isInvalidFromAgentId = fromAgentId >= max ? true : false;
    this.isInvalidToAgentId = toAgentId >= max ? true : false;
    if (!this.isInvalidFromAgentId && !this.isInvalidToAgentId) {
      return false;
    } else {
      return true;
    }
  };

  @action onPreview = async () => {
    try {
      this.isLoading = true;
      this.resetAll();
      var dto = { fromMailId: +this.fromAgentId, toMailId: +this.toAgentId };
      if (!(await this.Validate()) && !this.validateAgentIds()) {
        this.AgentPreview = await ProducerStore.askForAgenetMerge(dto);
        this.previewResults = true;
        if (this.AgentPreview && this.AgentPreview.message) {
          var responseModel = {
            status: 400,
            title: this.AgentPreview.message,
            errors: { "": [] },
          };

          this.isSuccess = true;
          this.response = JSON.stringify(responseModel);
          this.isLoading = false;
          this.setFromAgentVisible(true);
          this.setToAgentVisible(true);
          PageContext.setResponseMessage(this.response);
          PageContext.setIsMessageVisible(true);
        }
      }
    } catch (e) {
      this.isException = true;
      this.previewResults = false;
      this.exceptionMessage = e.response;
    }
    this.isLoading = false;
  };
}
