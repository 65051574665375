import { routes } from "../../../router";
import { observable, action } from "mobx";
import { StateLicensesListModel } from "../../../services/ContractService";
import Collection from "../../../infrastructure/CollectionHelper";
import Utils from "../../../infrastructure/Utils";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import AgentStateLicensesStore from "../../../store/AgentStateLicensesStore";
import UserContext from "../../../infrastructure/UserContext";
import DocumentStore from "../../../store/DocumentStore";

export class StateLicensesListViewModel implements ViewModel {
  @observable selectedId: number = 0;
  @observable totalRowsCount: number = 10;

  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 10;
  @observable isDeleteConfirmationVisible: boolean = false;
  @observable isSortAscending: boolean = true;

  @observable isMessgeVisible: boolean = false;

  @observable defaultIsSortAscending: boolean = true;
  get CanClose(): boolean {
    return true;
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    return true;
  }
  get IsLoading(): boolean {
    return false;
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (agentId: number) => {
    this.agentId = agentId;
    await this.loadItems();
  };
  @action addItem = async () => {
    routes.addAgentStateLicense.push({ agentId: this.agentId.toString() });
  };

  @action editItem = async (id: number) => {
    routes.editAgentStateLicense.push({
      agentId: this.agentId.toString(),
      Id: id,
    });
  };
  // Delete after confirmation
  @action showDeleteConfirmation = () => {
    this.isDeleteConfirmationVisible = true;
  };

  @action hideDeleteConfirmation = () => {
    this.isDeleteConfirmationVisible = false;
  };

  @action deleteSelectedItem = async () => {
    this.hideDeleteConfirmation();

    this.isLoading = true;
    this.isException = false;
    try {
      await this.deleteItem(this.selectedId);
      setTimeout(async () => {
        await this.loadItems();
      }, Utils.timeDelay_Delete());
      this.setSelectedId(0);
    } catch (error) {
      this.isException = true;
    }
    this.isLoading = false;
  };
  @action setSelectedId = (value: number) => {
    this.selectedId = value;
  };
  //  @observable ContractSummaryList = new Collection<{company: string, status: string , reason: string}>();
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable isLoading: boolean = false;
  @observable isException: boolean = false;
  @observable agentId: number = 0;
  @observable rows: number = 1000;
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable sortColumn: string = "id";
  @observable sortAscending: boolean = false;
  @observable response: any;
  @observable isMessageVisible: boolean = false;
  @observable stateLicenseList = new Collection<StateLicensesListModel>();
  @observable pageIndex: number = 0;
  @observable sortOrder: boolean = false;

  @action setSortOrder = async () => {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  };

  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.stateLicenseList.values = [];
  }

  @action loadItems = async () => {
    if (UserContext.userId && UserContext.userId > 0) {
      this.isLoading = true;
      this.isMessageVisible = false;
      this.response = "";
      try {
        let result = await AgentStateLicensesStore.loadStateLicenseList(
          this.agentId,
          this.pageIndex,
          this.pageSize,
          this.sortColumn,
          this.sortOrder
        );

        if (result) {
          if (result.recordCount) {
            this.totalRecords = result.recordCount;
            this.stateLicenseList.values = result.data ? result.data : [];
          } else {
            this.noRecordsFound();
          }
        } else {
          this.noRecordsFound();
        }
        this.isLoading = false;
      } catch (e) {
        this.response = e.response;
        this.isMessageVisible = true;
        this.isLoading = false;
      }
    }
  };
  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;

    this.loadItems();
  }
  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadItems();
  };

  readonly currentPageReportTemplate: string =
    "Showing {first} to {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.stateLicenseList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }
  rowsPerPageOptions(): number[] {
    return this.stateLicenseList.values.length > 0 ? [5, 10, 25, 50] : [];
  }
  @action async sortItems(
    sortColumn: string,
    isSortAscending: boolean = false
  ) {
    if (
      this.sortColumn !== sortColumn ||
      this.isSortAscending !== isSortAscending
    ) {
      this.sortColumn = sortColumn;
      this.isSortAscending = isSortAscending;
      await this.loadItems();
    }
  }
  onSort = async (sortColumn: any) => {
    var obj = JSON.parse(JSON.stringify(sortColumn));
    this.setSortOrder();
    this.setSortColumn(obj.sortField);
    await this.loadItems();
  };

  @action backToAdmin = async () => {
    routes.administration.push();
  };

  protected async deleteItem(id: number) {
    await AgentStateLicensesStore.deleteStateLicense(id);
  }

  @action downloadDocumentLink = async (docId: number, filename: string) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      var result = await DocumentStore.downloadFiles(docId, 0, true);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : filename,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
}
