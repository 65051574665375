import React from "react";
import { observer } from "mobx-react";

import { Button } from 'primereact/button';


export const IMASCancelSaveView: React.FC<{
  onSave: (e: any) => void;
  onCancel: (e: any) => void;
  isDelete?: boolean;
  disabled?: boolean;
}> = observer((props) => {
  return (
    <>
      <div className="p-grid">
        <div className="p-col text-right">
          <Button
            className="secondary-button"
            id="cancelButton"
            onClick={props.onCancel}
            type="button"
            label="Cancel"
            icon="fa fa-times"
          />
          &nbsp; &nbsp; &nbsp;
          <Button
            disabled={props.disabled}
            className="primary-button"
            id="saveButton"
            onClick={props.onSave}
            type="button"
            label={`${!props.isDelete ? "Save" : "Delete"}`}
            icon={`${!props.isDelete ? "fa fa-save" : "fa fa-trash"}`}
          />
        </div>
      </div>
    </>
  );
});