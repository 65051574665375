import { AbstractValidator } from "fluent-ts-validator";
import { observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import UserStore from "../../../../store/IdentityStore";
import Utils from "../../../../infrastructure/Utils";
import { PasswordListModel } from "../../../../services/IdentityService";

export class PasswordListAddEditViewModel extends BaseAddEditViewModel {
    @observable companyList = new Collection<any>();
    @observable companyId: number = 0;
    @observable webAddress: string = '';
    @observable userName: string = '';
    @observable password: string = '';
    @observable passwordHint: string = '';
    @observable siteAdministrator: string = '';
    @observable agentNumber: string = '';
    @observable adminPhone: string = '';
    @observable marketingPhone: string = '';

    constructor() {
        super("Password List", routes.listPasswordList, new PasswordListValidator());
       
    }
    protected async loadLookups(): Promise<void> {
        if (this.selectedId === 0) {
               this.loadItem(0);
           }
    }
    protected toServiceModel() {        
        return {
            id: this.selectedId || 0,
            companyId: this.companyId || 0,
            userName: this.userName || undefined,
            password: this.password || undefined,
            websiteURL: this.webAddress || '',
            hint: this.passwordHint || undefined,
            siteAdministrator: this.siteAdministrator || undefined,
            agentNumber: this.agentNumber || undefined,
            adminPhoneNumber: this.adminPhone || undefined,
            marketingPhoneNumber: this.marketingPhone || undefined
        } as PasswordListModel;
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await UserStore.getPasswordListById(id);
        if (item) {
            this.selectedId = item.id || 0;
            this.companyId = item.companyId || 0;
            this.userName = item.userName || "";
            this.password = item.password || "";
            this.webAddress = item.websiteURL || "";
            this.passwordHint =  item.hint || "";
            this.siteAdministrator =  item.siteAdministrator || "";
            this.agentNumber = item.agentNumber || "";
            this.adminPhone = item.adminPhoneNumber || "";
            this.marketingPhone = item.marketingPhoneNumber || "";
            this.companyList.values = [{ label: "Select", value: '0' }].concat(Utils.mapListItem(item.company));
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await UserStore.addPasswordList(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await UserStore.updatePasswordList(item);
    }
}

class PasswordListValidator extends AbstractValidator<PasswordListAddEditViewModel> {
    public constructor() {
        super();

            this.validateIfNumber(input => input.companyId)
            .isNotEqualTo(0)
            .withFailureMessage("Carrier is required.");
    }
}