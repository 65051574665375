import { action, computed, observable } from "mobx";
import RouteList from "../../../../infrastructure/RouteList";
import ViewModel from "../../../../infrastructure/ViewModel";
import { routes } from "../../../../router";
import { ReportFile } from "../../../../services/AccountingService";
import AccountingStore from "../../../../store/AccountingStore";
export class AccountingJournalReportsViewModel implements ViewModel {
  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = "Accounting Journal Reports";
  @observable isViewVisible: boolean = false;
  @observable loading: boolean = false;
  @observable selectedDiv: string = "";
  @observable selectedYear: string = "";
  @observable selectedAgentType: string = "";
  @observable selectedDate: string = "";
  @observable divisionList: any = [];
  @observable agentTypeList: any = [];
  @observable dateList: any = [];
  @observable isLoading: boolean = false;
    @observable startDate: Date | undefined = new Date();
    @observable endDate: Date | undefined = new Date();
  @observable startDateErr: boolean = false;
  @observable endDateErr: boolean = false;
  @observable yearErr: boolean = false;
  @observable agentValueTypeVisible: boolean = true;
  @observable dateVisible: boolean = true;
  @observable endDateVisible: boolean = false;
  @observable startDateVisible: boolean = false;
  @observable reportVisible: boolean = false;
  @observable defaultYear: string = new Date().getFullYear().toString();

  @action Load = async () => {
    this.reset()   
    await this.getDropDownValues();
    this.dropDownValuesChanged();
  };

  @action backToAdmin = async() => {
    routes.administration.push();
  }

  @action reset = () => {
    this.isLoading = false;
    this.isViewVisible = false
  }

  @action setSelectedDiv = (value: string) => {
    this.selectedDiv = value;
  };
  @action setSelectedYear = (value: string) => {
    this.selectedYear = value;
  };
  @action setSelectedAgentType = (value: string) => {
    this.selectedAgentType = value;
  };
  @action setSelectedDate = (value: string) => {
    this.selectedDate = value;
  };
    @action setStartDate = (value: Date | undefined) => {
    this.startDate = value;
  };
  @action setEndDate = (value: Date | undefined) => {
    this.endDate = value;
  };

  @action validateDates = (val: string) => {
    if ( this.startDate > this.endDate) {
      if (val === "start") {
        this.startDateErr = true;
        this.endDateErr = false;
      }
      if (val === "end") {
        
        this.endDateErr = true;
        this.startDateErr = false;
      }
    } else {
      this.startDateErr = false;
      this.endDateErr = false;
    }
  };

  @action dropDownValuesChanged = async () => {
    try {
      let divId = this.selectedDiv ? Number(this.selectedDiv) : 0;
      let year = this.selectedYear ? Number(this.selectedYear.trim()) : 0;
      let date = this.selectedDate ? new Date(this.selectedDate) : undefined;
      let agentTypeId = this.selectedAgentType
        ? Number(this.selectedAgentType)
        : 0;
      let startDate = undefined;
      let endDate = undefined;
      let result = await AccountingStore.dropDownValuesChanged(
        divId,
        year,
        agentTypeId,
        date,
        startDate,
        endDate
      );
      if (result) {
        this.startDateVisible = result.startDateVisible
          ? result.startDateVisible
          : false;
        this.endDateVisible = result.endDateVisible
          ? result.endDateVisible
          : false;
        this.dateVisible = result.dateVisible ? result.dateVisible : false;
        this.reportVisible = result.reportVisible
          ? result.reportVisible
          : false;
        this.agentValueTypeVisible = result.agentValueTypeVisible
          ? result.agentValueTypeVisible
          : false;
        var today = new Date();
        var weekago = new Date(today);
        weekago.setDate(today.getDate() - 7);
        this.startDate = weekago;
        this.endDate = today;
        let resultDate = result.date ? result.date : [];
        if (resultDate.length > 0) {
          let arrDate: any = [];
          resultDate.forEach((element: any) => {
            arrDate.push({
              label: "" + element.text,
              value: "" + element.value,
            });
          });
          this.dateList = arrDate;
          this.selectedDate = this.dateList[0].value;
        } 
      }
    } catch (e) {}
  };

  @action getDropDownValues = async () => {
    try {
      let result = await AccountingStore.journalReportDropDownValues();
      if (result) {
        let year = result.year ? result.year.toString() : "";
        this.selectedYear = year;

        let divList = result.division ? result.division : [];
        if (divList.length > 0) {
          let arrDiv: any = [];
          divList.forEach((element: any) => {
            arrDiv.push({
              label: "" + element.text,
              value: "" + element.value,
            });
          });
          this.divisionList = arrDiv;
          this.selectedDiv = this.divisionList[0].value;
        } else {
          this.divisionList = [];
        }

        let agentTypeList = result.agentValueType ? result.agentValueType : [];
        if (agentTypeList.length > 0) {
          let arrAgentType: any = [];
          agentTypeList.forEach((element: any) => {
            arrAgentType.push({
              label: "" + element.text,
              value: "" + element.value,
            });
          });
          this.agentTypeList = arrAgentType;
          this.selectedAgentType = this.agentTypeList[0].value;
        } else {
          this.agentTypeList = [];
        }
        let dateList = result.date ? result.date : [];
        if (dateList.length > 0) {
          let arrDate: any = [];
          dateList.forEach((element: any) => {
            arrDate.push({
              label: "" + element.text,
              value: "" + element.value,
            });
          });
          this.dateList = arrDate;
          this.selectedDate = this.dateList[0].value;
        } else {
          this.dateList = [];
        }
      }
    } catch (e) {}
  };

  @action getAccountingJournalReport = async () => {
    try {

      let divId = this.selectedDiv ? Number(this.selectedDiv) : undefined;
      let year = this.selectedYear ? Number(this.selectedYear) : undefined;
      let agentTypeId = this.selectedAgentType
        ? Number(this.selectedAgentType)
        : undefined;
      let date = this.selectedDate ? new Date(this.selectedDate) : undefined;
      let startDate = this.startDateVisible ? this.startDate : undefined;
      let endDate = this.endDateVisible ? this.endDate : undefined;

      let agentTypeText = this.dateVisible
        ? this.dateList.filter((i: any) => i.value === this.selectedDate)[0]
            .label
        : this.selectedAgentType
        ? this.agentTypeList.filter(
            (i: any) => i.value === this.selectedAgentType
          )[0].label
        : "";
      
        
      if (!this.startDateVisible || !this.startDateErr && !this.endDateErr && !this.yearErr) {
        this.isLoading = true;
        var result = await AccountingStore.downloadAllJournalReport(
          divId,
          year,
          agentTypeId,
          date,
          agentTypeText,
          startDate,
          endDate          
        );
         
        if (agentTypeId == 9 && result && result.fileName) {             
            result.fileName = result.fileName.replace(".xlsx", ".csv");
            result.contentType = "text/csv";
        }
         
        await this.downloadReport(result, "Accounting Journal Report");
      } else {
        console.log("did not call download");
      }
    } catch (e) {}
    this.isLoading = false;
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
      if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
}
