import { AbstractValidator } from "fluent-ts-validator";
import { observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import ProductStore from "../../../../store/ProductStore";

export class ProductLineAddEditViewModel extends BaseAddEditViewModel {

    @observable name: string = "";
    @observable isHierarchyBased: boolean = false;

    constructor()
    {
        super("Product Line", routes.listProductLines, new ProductLineValidator());
    }

    protected toServiceModel() {
        // return ProductLineModel2.fromJS({
        //             id: this.selectedId,
        //             productLineName: this.name,
        //             isMarketerHierarchyBased: this.isHierarchyBased
        //        });
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await ProductStore.getProductLineById(id);
        if(item) {
            this.selectedId = item.id || 0;
            this.name = item.productLineName || "";
            this.isHierarchyBased = item.isMarketerHierarchyBased || false;
        }
    }

    protected async addItem(): Promise<void> {
        this.toServiceModel();
        // await ProductStore.addProductLine(item);
    }
    protected async updateItem(): Promise<void> {
        this.toServiceModel();
        // await ProductStore.updateProductLine(item);
    }
}

class ProductLineValidator extends AbstractValidator<ProductLineAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.name)
            .isNotEmpty()
            .withFailureMessage("Name is required");
    }
}