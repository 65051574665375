import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import {
  CarrierGroupModel,
  CarrierGroupsListModel,
} from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";
import { action, observable } from "mobx";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Collection from "../../../../infrastructure/CollectionHelper";

export class CarrierGroupListViewModel extends BaseListViewModel<CarrierGroupsListModel> {
  @observable actionTitle: string = "";
  @observable isEditorVisible: boolean = false;
  @observable public ErrorModel = new ErrorModel(new CarrierGroupValidator());

  @observable selectedGroupName: string = "";
  @observable hidden: boolean = false;
  @observable disableSave: boolean = false;
  @observable activeCarriers: any = "";
  @observable searchInputValue: string = "";
  @observable rows: number = 50;
  @observable totalRecords: number = 0;
  @observable searchValue: string = "";
  @observable isHidden: boolean | undefined = false;
  @observable isNotHiddenChecked: boolean | undefined = true;
  @observable isHiddenChecked: boolean | undefined = undefined;
  @observable isHiddenAllChecked: boolean | undefined = undefined;
  @observable carrierGroupList = new Collection<CarrierGroupsListModel>();
  @action setEditorVisibility(isVisible: boolean) {
    this.isEditorVisible = isVisible;
  }
  @action onEditorHide() {
    this.setEditorVisibility(false);
  }

  @action async goSearch() {
    await this.loadCarrierGroups();
  }
  @action resetFilters = () => {
    this.searchValue = "";
    this.searchInputValue = "";
    this.isHidden = false;
    this.isNotHiddenChecked = true;
    this.isHiddenChecked = false;
    this.isHiddenAllChecked = false;
    this.sortColumn = "groupName";
    this.isSortAscending = true;
    this.resetPagingValues();
    this.loadCarrierGroups();
  };
  loadCarrierGroups = async () => {
    let res = await ContractsStore.getAllCarrierGroups(
      this.isHidden,
      0,
      this.pageSize,
      this.sortColumn,
      true,
      this.searchInputValue
    );
    if (res) {
      if (res.data) this.itemList.values = res.data;
      this.totalRecords = res.recordCount || 0;
      this.totalRowsCount = res.recordCount || 0;
    }
  };
  @action resetPagingValues() {
    this.rows = 10;
    this.totalRecords = 0;
  }
  @action editItem = async (id: number) => {
    if (id === 0) {
      this.actionTitle = "Add Carrier Group";
      this.selectedId = 0;
      this.selectedGroupName = "";
      this.hidden = false;
    } else {
      this.actionTitle = "Edit Carrier Group";
      var item = await ContractsStore.getCarrierGroupById(id);
      this.activeCarriers = "";
      this.disableSave = false;
      this.selectedId = item.id || 0;
      this.selectedGroupName = item.groupName || "";
      this.hidden = item.hidden || false;
    }
    this.ResetValidate();
    this.setEditorVisibility(true);
  };
  @action addItem = async () => {
    this.editItem(0);
  };

  protected toServiceModel() {
    var dto: CarrierGroupModel = {
      id: this.selectedId,
      groupName: this.selectedGroupName,
      hidden: this.hidden,
    };
    return dto;
  }
  @action public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action saveSelectedItem = async () => {
    try {
      if (!(await this.Validate())) {
        var item = this.toServiceModel();
        if (this.selectedId === 0) await ContractsStore.addCarrierGroup(item);
        else await ContractsStore.updateCarrierGroup(item);

        this.setEditorVisibility(false);
        //this.setSuccessMessage()
      }
      setTimeout(() => {
        this.Load();
      }, 10);
    } catch (e) {
      this.setEditorVisibility(false);
    }
  };
  @action checkActiveCarriers = async () => {
    this.disableSave = true;
    if (this.hidden) {
      await this.getCarrierGroupByCompanyId();
    } else {
      this.disableSave = false;
      this.activeCarriers = "";
    }
  };

  @action getCarrierGroupByCompanyId = async () => {
    try {
      this.isEditorVisible = false;
      let result = await ContractsStore.getCarrierGroupByCompanyId(
        this.selectedId
      );
      this.activeCarriers = [];
      let res: any = result;
      if (res) {
        if (res.length > 0) {
          this.activeCarriers = result;
          this.isEditorVisible = true;
        } else {
          this.isEditorVisible = false;
        }
      } else {
        this.isEditorVisible = false;
      }
    } catch (e) {
      this.isEditorVisible = false;
    }
  };

  constructor() {
    super(
      "Carrier Groups",
      "Carrier Group",
      routes.addCarrierGroups,
      routes.editCarrierGroups,
      "id",
      "groupName",
      true
    );
    this.pageSize = 200;
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    return await ContractsStore.getAllCarrierGroups(
      this.isHidden,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      this.searchInputValue
    );
  }

  protected async deleteItem(id: number) {
    await ContractsStore.deleteCarrierGroup(id);
  }

  resetFiltersToDefault() {
    this.pageSize = 200;
    this.resetFilters();
  }
}

class CarrierGroupValidator extends AbstractValidator<CarrierGroupListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedGroupName)
      .isNotEmpty()
      .isNotEqualTo("0")
      .matches(/\S+/, "i")
      .withFailureMessage("Name for Carrier Group is required");
  }
}
