import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { customerGroup, getCurrentRoute, routes } from "../../router";
import { Route } from "type-route";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";

import { CustomersViewModel } from "./CustomersViewModel";
import { CustomerDetailsTabViewModel } from "./CustomerDetailsTabViewModel";
import { AddEditCustomerViewModel } from "./AddEditCustomerViewModel";
import { MergeCustomersViewModel } from "./MergeCustomersViewModel";

export class CustomersDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable CustomersViewModel: CustomersViewModel = new CustomersViewModel();
  @observable
  AddEditCustomerViewModel: AddEditCustomerViewModel = new AddEditCustomerViewModel();
  @observable
  CustomerDetailsTabViewModel: CustomerDetailsTabViewModel = new CustomerDetailsTabViewModel();
  @observable
  MergeCustomersViewModel: MergeCustomersViewModel = new MergeCustomersViewModel();
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
    this.CustomerDetailsTabViewModel.CustomerSummaryViewModel.notesVisible = false;
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    var routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    var customerParamId: any | undefined;
    if (
      currentRoute.name === routes.customerSearch.name 
    ) {
      if (!(this.SelectedViewModel instanceof CustomersViewModel)) {
        this.SelectedViewModel = this.CustomersViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }

    if (
      currentRoute.name === routes.addCustomer.name ||
      currentRoute.name === routes.editCustomer.name
    ) {
      if (!(this.SelectedViewModel instanceof AddEditCustomerViewModel)) {
        this.SelectedViewModel = this.AddEditCustomerViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.customerId);
      await this.SelectedViewModel.Route(currentRoute);
    }
    else if (
          currentRoute.name === routes.mergeCustomers.name 
    ) {
      if (!(this.SelectedViewModel instanceof MergeCustomersViewModel)) {
        this.SelectedViewModel = this.MergeCustomersViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }
    // currentRoute.name === "customerDetails" ||
    // currentRoute.name === "customerDocs" ||
    // currentRoute.name === "customerPolicies" ||
    // currentRoute.name === "customerNotes" ||
    // currentRoute.name === "customerSummary" ||
    // currentRoute.name === "customerTasks" ||
    // currentRoute.name === "customerpolicyDetails" ||
    // currentRoute.name === "editCustomerPolicy" ||
    // currentRoute.name === "editCustomerTask" ||
    // currentRoute.name === "addCustomerDoc" ||
    // currentRoute.name === "addPolicy"
    else if (customerGroup.has(currentRoute)) {
      customerParamId = routeParamObj.customerId;

      RouteParamDetails.id = +customerParamId;
      RouteParamDetails.policyId = +routeParamObj.policyId;
      RouteParamDetails.taskId = +routeParamObj.taskId;

        if (!(this.SelectedViewModel instanceof CustomerDetailsTabViewModel)) {
            this.CustomerDetailsTabViewModel.customerId = RouteParamDetails.isUpdated ? 0 : routeParamObj.customerId;
        this.SelectedViewModel = this.CustomerDetailsTabViewModel;
      }
      // this.CustomerDetailsTabViewModel.resetView();
     
      await this.SelectedViewModel.Load(currentRoute.name === "customerSummary");
      await this.SelectedViewModel.Route(currentRoute);
     
    }
  };
}
