import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import IMASLog from "../../infrastructure/IMASLog";
import {
  SubSettings,
  GeneralSettings,
  CommisionsSettings,
  AccountingSettings,
  ManageImport,
  LicensingSettings,
  SalesSettings,
  Settings,
  NewBusinessSettings,
  Supplies,
} from "../../infrastructure/enum/Settings";
import { ReportFile } from "../../services/AccountingService";
import AccountStore from "../../store/AccountingStore";

export class SettingsViewModel implements ViewModel {
  @observable selectedPanel: string = SubSettings.Announcements;
  @observable selectedKey: string | null | undefined = Settings.General;

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {};

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    IMASLog.log("Settings " + currentRoute.name);
    var isSubSettingsSelected = true;

    // if (currentRoute.name === routes.listCreditCardCharge.name) {
    //   if (!(this.SelectedViewModel instanceof CreditCardChargeListViewModel)) {
    //     this.SelectedViewModel = new CreditCardChargeListViewModel();
    //   }
    //   await this.SelectedViewModel.Load();
    //   await this.SelectedViewModel.Route(currentRoute);
    // } else if (currentRoute.name === routes.editCreditCardCharge.name) {
    //   if (!(this.SelectedViewModel instanceof CreditCardChargeEditViewModel)) {
    //     this.SelectedViewModel = new CreditCardChargeEditViewModel();
    //   }
    //   await this.SelectedViewModel.Load(routeParams.CreditCardChargeID);
    //   await this.SelectedViewModel.Route(currentRoute);
    // }

    // else if (currentRoute.name === routes.listDocumentFileType.name) {
    //   if (!(this.SelectedViewModel instanceof ManageDocumentFileTypeViewModel)) {
    //     this.SelectedViewModel = new ManageDocumentFileTypeViewModel();
    //   }
    //   await this.SelectedViewModel.Load();
    //   await this.SelectedViewModel.Route(currentRoute);
    // }
    // else if (currentRoute.name === routes.listDeductible.name) {
    //   if (!(this.SelectedViewModel instanceof DeductibleListViewModel)) {
    //     this.SelectedViewModel = new DeductibleListViewModel();
    //   }
    //   await this.SelectedViewModel.Load(+routeParams.productId);
    //   await this.SelectedViewModel.Route(currentRoute);
    // } else if (
    //   currentRoute.name === routes.addDeductible.name ||
    //   currentRoute.name === routes.editDeductible.name
    // ) {
    //   if (!(this.SelectedViewModel instanceof AddEditDeductibleViewModel)) {
    //     this.SelectedViewModel = new AddEditDeductibleViewModel();
    //   }
    //   await this.SelectedViewModel.Load(
    //     +routeParams.productId,
    //     +routeParams.deductibleId
    //   );
    // await this.SelectedViewModel.Route(currentRoute);
    // }

    isSubSettingsSelected = false;

    this.isSubSettingsSelected = isSubSettingsSelected;
  };

  @action setSelectedPanel(mainMenu: string, value: string) {
    this.selectedPanel = value;
    this.selectedKey = mainMenu;
    this.changeRoute();
  }
  @action changeRoute() {
    switch (this.selectedPanel) {
      case SubSettings.RenewalImports:
        routes.listRenewalImports.push();
        break;
      case SubSettings.CreditCardChargeRequests:
        routes.listCreditCardChargeRequest.push();
        break;
      case SubSettings.CreditCardCharge:
        routes.listCreditCardCharge.push();
        break;
      case SubSettings.CarrierBankDeposit:
        routes.listCarrierBankDeposit.push();
        break;
      case SubSettings.Announcements:
        routes.listAnnouncement.push();
        break;
      case SubSettings.AgentGroups:
        routes.listagentGroup.push();
        break;
      case SubSettings.Carriers:
        routes.listCarrier.push();
        break;
      case SubSettings.Products:
        routes.listProduct.push();
        break;
      case SubSettings.ManageProductLines:
        routes.listProductLines.push();
        break;
      case SubSettings.ManageDocuments:
        routes.adminDocuments.push();
        break;
      case SubSettings.ManageEmailandFaxDetails:
        routes.listEmailFaxDetails.push();
        break;
      case SubSettings.ManageStatusReasons:
        routes.listLicenseStatusReasons.push();
        break;
    }
  }
  @observable SelectedViewModel: ViewModel | undefined;
  @observable isLoading: boolean = true;
  @observable isSubSettingsSelected: boolean = false;

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case GeneralSettings.Announcements:
          this.selectedPanel = GeneralSettings.Announcements;
          routes.listAnnouncement.push();
          break;
        case GeneralSettings.Carriers:
          this.selectedPanel = "";
          routes.listCarrier.push();
          break;
        case GeneralSettings.CarrierManagement:
          this.selectedPanel = "";
          routes.carrierManagement.push();
          break;
        case GeneralSettings.CarrierGroups:
          this.selectedPanel = "";
          routes.listCarrierGroups.push();
          break;
        case NewBusinessSettings.Certifications:
          this.selectedPanel = NewBusinessSettings.Certifications;
          routes.listCertifications.push();
          break;
        case GeneralSettings.ImportAgentCertificates:
          this.selectedPanel = GeneralSettings.ImportAgentCertificates;
          routes.importAgentCertificates.push();
          break;
        case GeneralSettings.ManageDocuments:
          this.selectedPanel = GeneralSettings.ManageDocuments;
          routes.adminDocuments.push();
          break;
        case GeneralSettings.ManageEmailandFaxDetails:
          return;

        case GeneralSettings.ManageProductLines:
          this.selectedPanel = GeneralSettings.ManageProductLines;
          routes.listProductLines.push();
          break;
        case GeneralSettings.PasswordList:
          this.selectedPanel = GeneralSettings.PasswordList;
          routes.listPasswordList.push();
          break;
        case GeneralSettings.PayoutLevels:
          this.selectedPanel = GeneralSettings.PayoutLevels;
          routes.payoutLevels.push();
          break;
        case GeneralSettings.PrintIMBMailShippingSlip:
          this.selectedPanel = GeneralSettings.PrintIMBMailShippingSlip;
          routes.printmailshipping.push();
          break;
        case GeneralSettings.Products:
          this.selectedPanel = "";
          routes.listProduct.push();
          break;
        case GeneralSettings.ProductSubCategories:
          this.selectedPanel = GeneralSettings.ProductSubCategories;
          routes.listProductSubCategory.push();
          break;
        case Supplies.Supplies:
          this.selectedPanel = Supplies.Supplies;
          routes.listAdminSupplies.push();
          break;
        case GeneralSettings.WebsiteLinks:
          this.selectedPanel = GeneralSettings.WebsiteLinks;
          routes.listWebLinks.push();
          break;
        case GeneralSettings.SubmissionManagement:
          this.selectedPanel = GeneralSettings.SubmissionManagement;
          routes.submissionManagement.push();
          break;
        case CommisionsSettings.EnterCommissions:
          return;
        case CommisionsSettings.MergeStatementDivision:
          return;
        case CommisionsSettings.PolicyDashboard:
          this.selectedPanel = CommisionsSettings.PolicyDashboard;
          routes.policyDashboard.push();
          break;
        case CommisionsSettings.PrintCommissions:
          this.selectedPanel = CommisionsSettings.PrintCommissions;
          routes.printCommissions.push();
          break;
        case CommisionsSettings.RenewalDashboard:
          this.selectedPanel = CommisionsSettings.RenewalDashboard;
          routes.renewalDashboard.push();
          break;
        case CommisionsSettings.RenewalBackout:
          this.selectedPanel = CommisionsSettings.RenewalBackout;
          routes.renewalBackout.push();
          break;
        case CommisionsSettings.ProcessCommissions:
          this.selectedPanel = CommisionsSettings.ProcessCommissions;
          routes.processCommissions.push();
          break;
        case AccountingSettings.AccountingJournalReports:
          this.selectedPanel = GeneralSettings.AccountingJournalReports;
          routes.accountingJournal.push();
          break;
        case AccountingSettings.CreditCardChargeRequests:
          this.selectedPanel = GeneralSettings.CreditCardChargeRequests;
          routes.listCreditCardChargeRequest.push();
          break;
        case AccountingSettings.ImportAgentDebt:
          this.selectedPanel = AccountingSettings.ImportAgentDebt;
          routes.importagentdebts.push();
          break;
        case AccountingSettings.ManageAgentDebtImports:
          this.selectedPanel = AccountingSettings.ManageAgentDebtImports;
          routes.listManageAgentDept.push();
          break;
        case AccountingSettings.PolicyAdjustments:
          this.selectedPanel = AccountingSettings.PolicyAdjustments;
          routes.policyAdjustments.push();
          break;

        case LicensingSettings.ImportLicensePersistencyTypes:
          this.selectedPanel = LicensingSettings.ImportLicensePersistencyTypes;
          routes.importlicensepersistencytypes.push();
          break;
        case AccountingSettings.ManageRevenue:
          this.selectedPanel = AccountingSettings.CarrierBankDeposits;
          routes.listCarrierBankDeposit.push();
          break;
        case LicensingSettings.LicenseDashboard:
          this.selectedPanel = LicensingSettings.LicenseDashboard;
          routes.licenseDashboard.push();
          return;
        case LicensingSettings.LicenseSplitter:
          this.selectedPanel = LicensingSettings.LicenseSplitter;
          break;
        case LicensingSettings.ManageCompanyLicenseNumberDefaults:
          this.selectedPanel =
            LicensingSettings.ManageCompanyLicenseNumberDefaults;
          routes.listCompanyLicenseNumberDefaults.push();
          break;
        case LicensingSettings.ManageDocumentFileTypes:
          this.selectedPanel = LicensingSettings.ManageDocumentFileTypes;
          routes.listDocumentFileType.push();
          break;
        case LicensingSettings.ManageMarketerEmailsforContractInvites:
          this.selectedPanel =
            LicensingSettings.ManageMarketerEmailsforContractInvites;
          routes.listMarketerMailConfiguration.push();
          break;
        case LicensingSettings.ManageStatusReasons:
          this.selectedPanel = LicensingSettings.ManageStatusReasons;
          routes.listLicenseStatusReasons.push();
          break;
        case SalesSettings.AgentLeadDisabledCarriers:
          this.selectedPanel = SalesSettings.AgentLeadDisabledCarriers;
          routes.listAgentLeadDisabledCarrier.push();
          break;
        case GeneralSettings.AgentGroups:
          this.selectedPanel = GeneralSettings.AgentGroups;
          routes.listagentGroup.push();
          break;
        case Settings.Roles:
          this.selectedPanel = Settings.Roles;
          routes.listRole.push();
          break;
        case Settings.Teams:
          this.selectedPanel = Settings.Teams;
          routes.listTeam.push();
          break;
        case Settings.Users:
          this.selectedPanel = Settings.Users;
          localStorage.setItem("selectedUserTab", "0");
          routes.listUser.push();
          break;
        case Settings.UserActivity:
          this.selectedPanel = Settings.UserActivity;
          routes.userActivity.push();
          break;
        case Settings.UserAuditTrail:
          this.selectedPanel = Settings.UserAuditTrail;
          routes.userAuditTrail.push();
          break;
        case Settings.UserAuditReport:
          this.selectedPanel = Settings.UserAuditReport;
          routes.userAuditReport.push();
          break;
        case CommisionsSettings.CommissionPlans:
          this.selectedPanel = CommisionsSettings.CommissionPlans;
          routes.listCommissionplan.push();
          break;
        case CommisionsSettings.BackoutRenewalImport:
          this.selectedPanel = CommisionsSettings.BackoutRenewalImport;
          routes.listRenewalImports.push();
          break;
        case ManageImport.ManageImports:
          this.selectedPanel = ManageImport.ManageImports;
          routes.listImports.push();
          break;
        case ManageImport.ManageColumnDefinitions:
          this.selectedPanel = ManageImport.ManageColumnDefinitions;
          routes.manageColumnDefinition.push();
          break;
        case GeneralSettings.Emails:
          this.selectedPanel = GeneralSettings.Emails;
          routes.emailsView.push();
          break;
        case GeneralSettings.Notifications:
          this.selectedPanel = GeneralSettings.Notifications;
          routes.notification.push();
          break;
        case GeneralSettings.ManageAttributes:
          this.selectedPanel = GeneralSettings.ManageAttributes;
          routes.ManageAttributes.push();
          break;
        case LicensingSettings.BulkUpload:
          this.selectedPanel = LicensingSettings.BulkUpload;
          routes.BulkUpload.push();
          break;
        case GeneralSettings.LicenseManagement:
          this.selectedPanel = GeneralSettings.LicenseManagement;
          routes.licenseManagement.push();
          break;
      }
      // this.changeRoute()
    }
  };

  @action generateCommissionReport = async () => {
    try {
      let res = await AccountStore.commissionReport();
      this.downloadReport(res, "commissionReport");
    } catch (e) {}
  };

  @action generateReceivingPaperStatementReport = async () => {
    try {
      let res = await AccountStore.receivingPaperStatementReport();
      console.log("service");
      console.log(res);
      this.downloadReport(res, "receivingPaperStatementReport");
    } catch (e) {}
  };
  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          console.log("response");
          console.log(myBlob);
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
}
