import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  NoteModel,
  CreateUpdateAddressModel,
  CreateUpdateContactModel,
  CreatePrincipalModel,
  UploadProfilePicModel,
  FileType,
  CreatePayeeModel,
  Client as ProducerServiceClient,
  MarketingEventModel,
  MarketingEventListModel,
  SendEmailMarketingEventRequestModel,
  SendTextMessageRequest,
  AgentListRequest,
  ContractedAgentListRequest,
  AgentWithoutNotesRequest,
  ProductionRequest,
  ProductionSummaryRequest,
  MarketerProductionRequest,
  DemographyAndContactsRequest,
  AgentStateLicenseRequest,
  UpdatePhonePreferenceModel,
  CreateAgentContactEmailUnsubscribeModel,
  CreateAgentModel,
} from "../services/ProducerService";

import {
  UpdateAgentModel,
  MarketingPreferencesModel,
  AgentReceivePaperStatementModel,
  AgentProfileLoginModel,
} from "../services/ProducerService";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import Utils from "../infrastructure/Utils";
import { number } from "prop-types";
import axios, { AxiosResponse } from "axios";

class ProducerStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): ProducerServiceClient => {
    return ServiceClient.getClient(ServiceType.Producer);
  };

  @action getAgentDetailsOnAdd = async () => {
    return await this.getClient().getAgentForCreate(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action getContactTypes = async () => {
    return await this.getClient().getContactTypes(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action getAgentDetails = async (agentId: number) => {
    var result = await this.getClient().getAgentByIdForUpdate(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgentW9Information = async (agentId: number) => {
    var result = await this.getClient().getAgentW9Information(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgencyReference = async (agentId: number) => {
    var result = await this.getClient().getAgencyReference(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action editAgent = async (agentDto: UpdateAgentModel) => {
    var result = await this.getClient().updateAgent(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      agentDto
    );
    return result;
  };
  @action updateAgentW9Information = async (reqData: any) => {
    var result = await this.getClient().updateAgentW9Information(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      reqData
    );
    return result;
  };

  @action addAgent = async (agentDto: CreateAgentModel) => {
    var result = await this.getClient().createAgent(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      agentDto
    );
    return result;
  };
  @action getAgentSummary = async (agentId: number) => {
    var result = await this.getClient().getSummaryById(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAgentMarketers = async (agentId: number) => {
    var result = await this.getClient().agentMarketersById(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAgentHierarchy = async (agentId: number) => {
    var result = await this.getClient().getAgentHierarchy(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action agentHierarchyLevelLookup = async () => {
    var result = await this.getClient().agentHierarchyLevelLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAgentProfileInfoById = async (agentId: number) => {
    var result = await this.getClient().getAgentProfileInfoById(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action checkDNXOption = async (agentId: number) => {
    var result = await this.getClient().checkDNXOption(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAgentNotes = async (
    agentId: number,
    userid: number,
    noteTypeId: number,
    noteSearch: string | undefined,
    selectedCompany: number | undefined,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllNote(
      agentId,
      this.getApiVersion(),
      userid,
      noteTypeId,
      noteSearch,
      selectedCompany,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getNoteDetails = async (noteId: number, agentId: number) => {
    var result = await this.getClient().getNoteBy(
      agentId,
      noteId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getPayoutLevels = async (companyId: number, agentId: number) => {
    var result = await this.getClient().getPayoutLevelByCompanyId(
      companyId,
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId()
    );
    return result;
  };
  @action addNote = async (agentId: number, noteModel: NoteModel) => {
    var result = await this.getClient().createNote(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      noteModel
    );
    return result;
  };
  @action editNote = async (agentId: number, noteModel: NoteModel) => {
    var result = await this.getClient().updateNote(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      noteModel
    );
    return result;
  };
  @action deleteNote = async (noteId: number) => {
    var result = await this.getClient().deleteNote(
      this.getApiVersion(),
      noteId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getNotesAllLookupList = async (agentId: number, noteId: number) => {
    var result = await this.getClient().getNoteBy(
      agentId,
      noteId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getNoteTypesLookup = async () => {
    var result = await this.getClient().getNoteTypesLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action pinUnpinNote = async (
    noteId: number,
    pinned: boolean,
    agentId: number
  ) => {
    var result = await this.getClient().pinUnpinNote(
      noteId,
      pinned,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action checkPinnedNote = async (
    noteId: number,
    pinned: boolean,
    agentId: number
  ) => {
    var result = await this.getClient().checkPinnedNote(
      noteId,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAddressTypesLookup = async () => {
    var result = await this.getClient().getAddressTypeLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action updateAgentImmediateUpline = async (
    agentId: number,
    parentId: number,
    selfUpline: boolean,
    hierarchyLevelId: number | null
  ) => {
    var result = await this.getClient().updateAgentImmediateUpline(
      this.getApiVersion(),
      agentId,
      parentId,
      selfUpline,
      hierarchyLevelId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgentReportingHierarchy = async (agentId: number) => {
    var result = await this.getClient().getAgentReportingHierarchy(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action agentHierarchyDetailsById = async (agentId: number) => {
    var result = await this.getClient().agentHierarchyDetailsById(
      agentId,
      this.getApiVersion(),
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAddresses = async (agentId: number) => {
    var result = await this.getClient().getAllAddresses(
      agentId,
      this.getApiVersion(),
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action editAddresses = async (
    agentId: number,
    addressDto: CreateUpdateAddressModel
  ) => {
    var result = await this.getClient().updateAddress(
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId(),
      undefined,
      addressDto
    );
    return result;
  };
  @action addAddresses = async (
    agentId: number,
    addressDto: CreateUpdateAddressModel
  ) => {
    var result = await this.getClient().createAddress(
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId(),
      undefined,
      addressDto
    );
    return result;
  };
  @action getAddressDetails = async (addressId: number, agentId: number) => {
    var result = await this.getClient().getAddressById(
      addressId,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action deleteAddress = async (addressId: number, agentId: number) => {
    var result = await this.getClient().deleteAddress(
      agentId,
      addressId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action default = async (
    addressId: number,
    addressType: number,
    agentId: number
  ) => {
    var result = await this.getClient().makeDefaultAddress(
      agentId,
      addressId,
      addressType,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAgentImmediateUpline = async (
    searchType: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined,
    fullTextFilter: string | undefined
  ) => {
    var result = await this.getClient().getAgentImmediateUpline(
      this.getApiVersion(),
      searchType,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action getContacts = async (
    agentId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined,
    fullTextFilter: string | undefined
  ) => {
    var result = await this.getClient().getAllContact(
      this.getApiVersion(),
      agentId,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action addContact = async (
    agentId: number,
    contactDto: CreateUpdateContactModel
  ) => {
    var result = await this.getClient().createContact(
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action editContact = async (
    agentId: number,
    contactDto: CreateUpdateContactModel
  ) => {
    var result = await this.getClient().updateContact(
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action validateEmail = (emailAddress: string): Promise<AxiosResponse> => {
    const headers = {
      Impersonated_User: `${
        UserContext.getUserId() != null ? UserContext.getUserId() : ""
      }`,
      HOSTNAME: "",
      Accept: "text/plain",
    };
    const apiUrl = `${Utils.getServiceUrlByEnvironment()}/email-validation/${emailAddress}`;
    return axios.get(apiUrl, { headers });
  };
  @action getContactDetails = async (contactId: number, agentId: number) => {
    var result = await this.getClient().getContactById(
      contactId,
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId()
    );
    return result;
  };
  @action deleteContact = async (contactId: number, agentId: number) => {
    var result = await this.getClient().deleteContact(
      contactId,
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId()
    );
    return result;
  };
  @action defaultContact = async (
    contactId: number,
    agentId: number,
    contactTypeId: number
  ) => {
    var result = await this.getClient().makeDefault(
      agentId,
      contactId,
      contactTypeId,
      this.getApiVersion(),
      false,
      UserContext.getUserId()
    );
    return result;
  };
  @action undefaultContact = async (
    contactId: number,
    agentId: number,
    contactTypeId: number
  ) => {
    var result = await this.getClient().makeDefault(
      agentId,
      contactId,
      contactTypeId,
      this.getApiVersion(),
      true,
      UserContext.getUserId()
    );
    return result;
  };
  @action commissionContact = async (
    contactId: number,
    agentId: number,
    contactTypeId: number
  ) => {
    var result = await this.getClient().setAsCommission(
      agentId,
      contactId,
      contactTypeId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action dnxNote = async (agentId: number, isDnxOverride: boolean) => {
    var result = await this.getClient().createDNxNote(
      agentId,
      isDnxOverride,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgencies = async (
    agentId: number,
    startIndex: number,
    pageSize: number
  ) => {
    var result = await this.getClient().getAgencyByAgentId(
      agentId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      undefined,
      undefined,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getPayees = async (
    agentId: number,
    startIndex: number,
    pageSize: number
  ) => {
    var result = await this.getClient().getAllPayee(
      agentId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      undefined,
      undefined,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action addPayee = async (agentId: number, payeeId: number) => {
    let dto: CreatePayeeModel = { agentId: agentId, payeeId: payeeId };
    var result = await this.getClient().createPayee(
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action deletePayee = async (agentId: number, payeeId: number) => {
    var result = await this.getClient().deletePayee(
      payeeId,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getPrincipalAgents = async (
    agentId: number,
    startIndex: number,
    pageSize: number
  ) => {
    var result = await this.getClient().getAllPrinciple(
      agentId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      "id",
      false,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action addPrincipalAgent = async (
    agentId: number,
    principalDto: CreatePrincipalModel
  ) => {
    var result = await this.getClient().createPrinciple(
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId(),
      undefined,
      principalDto
    );
    return result;
  };
  @action deletePrincipalAgent = async (
    principalAgentId: number,
    agentId: number
  ) => {
    var result = await this.getClient().deletePrinciple(
      principalAgentId,
      this.getApiVersion(),
      "" + agentId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAgentProfilePic = async (agentId: number) => {
    var result = await this.getClient().downloadProfilePic(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action updateProfilePic = async (dto: UploadProfilePicModel) => {
    var result = await this.getClient()._UploadProfilePic(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action checkAgentDuplicate = async (
    firstName: string,
    lastName: string,
    ssn: string | undefined,
    npn: string | undefined,
    isAgency: boolean,
    tin: string | undefined,
    agentId: number
  ) => {
    var result = await this.getClient().checkAgentDuplicate(
      firstName,
      lastName,
      this.getApiVersion(),
      ssn,
      undefined,
      npn,
      agentId,
      isAgency,
      tin,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPriniciplaAgentsLookup = async (name: string) => {
    var result = await this.getClient().getAllAgentPrincipalLookup(
      this.getApiVersion(),
      name,
      UserContext.getUserId()
    );
    return result;
  };

  @action reportAgentStatus = async () => {
    var result = await this.getClient().getReportAgentStatusLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action reportCountyList = async () => {
    var result = await this.getClient().getCountyWithStateLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action contractedAgentListReport = async (
    marketerIds?: number[] | undefined,
    uplineId?: number | undefined,
    licenseStatusId?: number | undefined,
    companyGroupId?: number | undefined,
    companyId?: number | undefined,
    leadSource?: number | undefined,
    agentStatusId?: number | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    fileType?: any
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    var request: ContractedAgentListRequest = {
      marketerIds: marketerIds ? marketerIds : [],
      uplineId: uplineId,
      licenseStatusId: licenseStatusId,
      companyGroupId: companyGroupId,
      companyId: companyId,
      homeStates: homeStates ? homeStates : [],
      licenseStates: licenseStates ? licenseStates : [],
      leadSource: leadSource,
      agentStatusId: agentStatusId,
      fileType: fileType,
    };
    var result = await this.getClient().getContractedAgentListReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  @action contractedAgentListReportBackground = async (
    marketerIds?: number[] | undefined,
    uplineId?: number | undefined,
    licenseStatusId?: number | undefined,
    companyGroupId?: number | undefined,
    companyId?: number | undefined,
    leadSource?: number | undefined,
    agentStatusId?: number | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    fileType?: any
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    var request: ContractedAgentListRequest = {
      marketerIds: marketerIds,
      uplineId: uplineId,
      licenseStatusId: licenseStatusId,
      companyGroupId: companyGroupId,
      companyId: companyId,
      homeStates: homeStates,
      licenseStates: licenseStates,
      leadSource: leadSource,
      agentStatusId: agentStatusId,
      fileType: fileType,
    };
    var result = await this.getClient().getContractedAgentListReport1(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action agentListReport = async (
    agencyId?: number | undefined,
    marketerIds?: number[] | undefined,
    agentStatusId?: number[] | undefined,
    fileType?: any,

    eoType?: any,
    leadSourceId?: number[] | undefined,
    agentGroupIds?: number[] | undefined,
    principalAgentType?: any,
    residentCountyList?: string[] | undefined,
    agentImmediateUplineId?: number | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (leadSourceId && leadSourceId.length > 0) {
      leadSourceId = leadSourceId.map((i) => Number(i));
    }
    if (agentStatusId && agentStatusId.length > 0) {
      agentStatusId = agentStatusId.map((i) => Number(i));
    }
    if (agentGroupIds && agentGroupIds.length > 0) {
      agentGroupIds = agentGroupIds.map((i) => Number(i));
    }
    var request: AgentListRequest = {
      agencyId: agencyId,
      marketerIds: marketerIds ? marketerIds : [],
      eoType: eoType,
      leadSourceIds: leadSourceId ? leadSourceId : [],
      agentStatusIds: agentStatusId ? agentStatusId : [],
      agentGroupIds: agentGroupIds ? agentGroupIds : [],
      fileType: fileType,
      principalAgentStatusType: principalAgentType,
      counties: residentCountyList,
      agentImmediateUplineId: agentImmediateUplineId,
    };
    var result = await this.getClient().getAgentDetailReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  @action productionReport = async (
    agentId?: number | undefined,
    productId?: number | undefined,
    uplineId?: number | undefined,
    marketerIds?: number[] | undefined,
    companyId?: number | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    policyStatuses?: number[] | undefined,
    whichDate?: string | undefined,
    leadSource?: number | undefined,
    customerState?: number | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    locationAgentId?: number | undefined,
    hideOverrides?: boolean | undefined,
    fileType?: FileType | undefined,
    agentGroups?: number[] | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (policyStatuses && policyStatuses.length > 0) {
      policyStatuses = policyStatuses.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    if (agentGroups && agentGroups.length > 0) {
      agentGroups = agentGroups.map((i) => Number(i));
    }
    var request: ProductionRequest = {
      agentId: agentId,
      productId: productId,
      uplineId: uplineId,
      marketerIds: marketerIds ? marketerIds : [],
      companyId: companyId,
      startDate: startDate,
      stopDate: stopDate,
      policyStatuses: policyStatuses ? policyStatuses : [],
      whichDate: whichDate,
      leadSource: leadSource,
      customerState: customerState,
      licenseStates: licenseStates ? licenseStates : [],
      homeStates: homeStates ? homeStates : [],
      categories: categories ? categories : [],
      subCategories: subCategories ? subCategories : [],
      hideOverrides: hideOverrides,
      fileType: fileType,
      agentGroups: agentGroups,
      locationAgentId: locationAgentId,
    };
    var result = await this.getClient().getProductionReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action productionSummaryReport = async (
    agentId?: number | undefined,
    productId?: number | undefined,
    uplineId?: number | undefined,
    marketerIds?: number[] | undefined,
    companyId?: number | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    policyStatuses?: number[] | undefined,
    whichDate?: string | undefined,
    leadSource?: number | undefined,
    customerState?: number | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    fileType?: FileType | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (policyStatuses && policyStatuses.length > 0) {
      policyStatuses = policyStatuses.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    var request: ProductionSummaryRequest = {
      agentId: agentId,
      productId: productId,
      uplineId: uplineId,
      marketerIds: marketerIds ? marketerIds : [],
      companyId: companyId,
      startDate: startDate,
      stopDate: stopDate,
      policyStatuses: policyStatuses ? policyStatuses : [],
      whichDate: whichDate,
      leadSource: leadSource,
      customerState: customerState,
      licenseStates: licenseStates ? licenseStates : [],
      homeStates: homeStates ? homeStates : [],
      categories: categories ? categories : [],
      subCategories: subCategories ? subCategories : [],
      fileType: fileType,
    };
    var result = await this.getClient().getProductionSummaryReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action agentWithoutNotesReport = async (
    marketerIds?: number[] | undefined,
    userId?: number,
    agentStatusId?: number | undefined,
    notesInXDays?: number | undefined,
    fileType?: FileType | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    var request: AgentWithoutNotesRequest = {
      marketerIds: marketerIds ? marketerIds : [],
      userId: userId,
      agentStatusId: agentStatusId,
      notesInXDays: notesInXDays,
      fileType: fileType,
    };
    var result = await this.getClient().getAgentWithoutNotesReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action getDemographyAndContactsReport = async (
    agentId?: number | undefined,
    agentStatusIds?: number[] | undefined,
    marketerIds?: number[] | undefined,
    addressTypeIds?: string[] | undefined,
    contactTypeIds?: number[] | undefined,
    smsEnabled?: string | undefined,
    fileType?: FileType | undefined,
    counties?: string[] | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (agentStatusIds && agentStatusIds.length > 0) {
      agentStatusIds = agentStatusIds.map((i) => Number(i));
    }
    if (contactTypeIds && contactTypeIds.length > 0) {
      contactTypeIds = contactTypeIds.map((i) => Number(i));
    }
    var request: DemographyAndContactsRequest = {
      agentId: agentId,
      agentStatusIds: agentStatusIds ? agentStatusIds : [],
      marketerIds: marketerIds ? marketerIds : [],
      addressTypeIds: addressTypeIds ? addressTypeIds : [],
      contactTypeIds: contactTypeIds ? contactTypeIds : [],
      smsEnabled: smsEnabled,
      fileType: fileType,
      counties: counties,
    };
    var result = await this.getClient().getDemographyAndContactsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action marketerProductionReport = async (
    whichDate?: string | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    uplineId?: number | undefined,
    marketerIds?: number[] | undefined,
    companyId?: number | undefined,
    agentId?: number | undefined,
    productId?: number | undefined,
    locationId?: number | undefined,
    policyStatuses?: number[] | undefined,
    customerState?: number | undefined,
    leadSource?: number | undefined,
    categories?: number[] | undefined,
    subCategories?: number[] | undefined,
    homeStates?: string[] | undefined,
    licenseStates?: number[] | undefined,
    hideOverrides?: boolean | undefined,
    fileType?: FileType | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (policyStatuses && policyStatuses.length > 0) {
      policyStatuses = policyStatuses.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (categories && categories.length > 0) {
      categories = categories.map((i) => Number(i));
    }
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    var request: MarketerProductionRequest = {
      whichDate: whichDate,
      startDate: startDate,
      stopDate: stopDate,
      uplineId: uplineId,
      marketerIds: marketerIds ? marketerIds : [],
      companyId: companyId,
      agentId: agentId,
      productId: productId,
      locationId: locationId,
      policyStatuses: policyStatuses ? policyStatuses : [],
      customerState: customerState,
      leadSource: leadSource,
      categories: categories ? categories : [],
      subCategories: subCategories ? subCategories : [],
      homeStates: homeStates ? homeStates : [],
      licenseStates: licenseStates ? licenseStates : [],
      hideOverrides: hideOverrides,
      fileType: fileType,
    };
    var result = await this.getClient().getMarketerProductionReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  // @action getAgentMarketers = async (agentId: number) => {
  //   var result = await this.getClient().agentsummarymarketers(agentId,
  //     this.getApiVersion(),
  //     UserContext.userId
  //   );
  //   return result;
  // };
  // @action getAgentHierarchies = async (agentId: number) => {
  //   var result = await this.getClient().agentsummaryhierarchy(agentId,
  //     this.getApiVersion(),
  //     UserContext.userId
  //   );
  //   return result;
  // };

  @action AgentPortalLogin = async (Dto: AgentProfileLoginModel) => {
    var result = await this.getClient().updateProfileLoginByAgentId(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      Dto
    );
    return result;
  };

  @action ReceivePaperStatement = async (
    Dto: AgentReceivePaperStatementModel
  ) => {
    var result = await this.getClient().updateReceivePaperStatementByAgentId(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      Dto
    );
    return result;
  };

  @action GetMarketerEmails = async (agentId: number) => {
    var result = await this.getClient().getEmailByAgentId(
      agentId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getMarketingPreferences = async (email: string) => {
    var result = await this.getClient().getMarketingPreferencesByEmail(
      this.getApiVersion(),
      email,
      UserContext.userId
    );
    return result;
  };

  @action savePreferences = async (dto: MarketingPreferencesModel) => {
    var result = await this.getClient().updateMarketingPreferencesByEmail(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };

  @action getmarketingeventlookup = async () => {
    let result = await this.getClient().getmarketingeventlookup(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getMarketingEventListById = async (id: number) => {
    let result = await this.getClient().getMarketingEventListById(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getAllMarketingEvents = async (calendarVisibleDate: Date) => {
    let result = await this.getClient().getAllMarketingEvents(
      this.getApiVersion(),
      calendarVisibleDate,
      UserContext.userId
    );
    return result;
  };
  @action getTop5MarketingEvents = async () => {
    let result = await this.getClient().getTop5MarketingEvents(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action addMarketingEvent = async (body: MarketingEventModel) => {
    let result = await this.getClient().addMarketingEvent(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };
  @action updateMarketingEvent = async (body: MarketingEventModel) => {
    let result = await this.getClient().updateMarketingEvent(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };
  @action getMarketingEventById = async (id: number) => {
    let result = await this.getClient().getMarketingEventById(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getMarketingEventListByEventId = async (
    id: number,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined
  ) => {
    let result = await this.getClient().getMarketingEventListByEventId(
      this.getApiVersion(),
      id,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };

  @action addMarketingEventList = async (body: MarketingEventListModel) => {
    let result = await this.getClient().addMarketingEventList(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };
  @action deleteMarketingEvent = async (id: number) => {
    let result = await this.getClient().deleteMarketingEvent(
      this.getApiVersion(),
      id,
      UserContext.userId
    );
    return result;
  };
  @action deletMarketingEventList = async (id: number) => {
    let result = await this.getClient().deletMarketingEventList(
      this.getApiVersion(),
      id,
      UserContext.userId
    );
    return result;
  };

  @action deleteMarketingEventEmail = async (id: number) => {
    let result = await this.getClient().deleteMarketingEventEmail(
      this.getApiVersion(),
      id,
      UserContext.userId
    );
    return result;
  };
  // @action addMarketingEventEmail = async (
  //   id: number,
  //   emails: string | undefined,
  //   isFile:boolean,
  //   file:File
  // ) => {
  //   let result = await this.getClient().addMarketingEventEmail(
  //     this.getApiVersion(),
  //     UserContext.userId,
  //     undefined,
  //     id,
  //     emails,
  //     isFile,
  //     file
  //   );
  //   return result;
  // };
  @action addMarketingEventEmail = async (
    v: string,
    impersonated_User?: any | undefined,
    hOSTNAME?: any | undefined,
    id?: number | undefined,
    newEmailsText?: string | null | undefined,
    isFile?: boolean | undefined,
    file?: any,
    fileName?: string,
    authorization?: any | undefined,
    accept_Timezone?: any | undefined
  ) => {
    const headersParametrs = {
      authorization:
        authorization !== undefined && authorization !== null
          ? "" + authorization
          : "",
      Accept_Timezone:
        accept_Timezone !== undefined && accept_Timezone !== null
          ? "" + accept_Timezone
          : "",
      Impersonated_User:
        impersonated_User !== undefined && impersonated_User !== null
          ? "" + impersonated_User
          : "",
      Accept: "application/json",
    };
    let baseUrl: string = "";
    baseUrl = Utils.getServiceUrlByEnvironment();
    let url_ = baseUrl + "/api/producer/v{v}/marketereventlist/addnewemails";
    if (v === undefined || v === null)
      throw new Error("The parameter 'v' must be defined.");
    url_ = url_.replace("{v}", encodeURIComponent("" + v));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = new FormData();
    if (id === null || id === undefined)
      throw new Error("The parameter 'id' cannot be null.");
    else content_.append("Id", id.toString());
    if (newEmailsText !== null && newEmailsText !== undefined)
      content_.append("NewEmailsText", newEmailsText.toString());
    if (isFile === null || isFile === undefined)
      throw new Error("The parameter 'isFile' cannot be null.");
    else content_.append("IsFile", isFile.toString());
    if (file !== null && file !== undefined)
      // content_.append("File", file.data, file.fileName ? file.fileName : "File");
      content_.append("File", file, fileName ? fileName : "file.xlsx");
    let options_ = {
      body: content_,
      method: "POST",
      headers: headersParametrs,
    } as RequestInit;

    return fetch(url_, options_).then((_response: Response) => {
      return this.processAddMarketingEventEmail(_response);
      // return this.getClient().processAddMarketingEventEmail(_response);
    });
  };
  @action processAddMarketingEventEmail(response: Response): Promise<number> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 201) {
      return response.text().then((_responseText) => {
        let result201: any = null;
        result201 =
          _responseText === ""
            ? null
            : <number>JSON.parse(_responseText, this.jsonParseReviver);
        return result201;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<number>(<any>null);
  }

  @action sendMarketingEventEmail = async (
    body: SendEmailMarketingEventRequestModel
  ) => {
    let result = await this.getClient().sendMarketingEventEmail(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };
  @action getStatisticsReport = async (id: number) => {
    let result = await this.getClient().getStatisticsReport(
      this.getApiVersion(),
      id,
      UserContext.userId
    );
    return result;
  };
  @action getResendListReport = async (id: number) => {
    let result = await this.getClient().getResendListReport(
      this.getApiVersion(),
      id,
      UserContext.userId
    );
    return result;
  };

  @action homeScreenKPILookup = async (startDate: Date, endDate: Date) => {
    let result = await this.getClient().homeScreenKPILookup(
      this.getApiVersion(),
      startDate,
      endDate,
      UserContext.userId
    );
    return result;
  };
  @action mergeAgents = async (body: any) => {
    var result = await this.getClient().mergeAgent(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };
  @action askForAgenetMerge = async (body: any) => {
    var result = await this.getClient().mergeAgentPreview(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };

  @action getAgentCurrentTime = async (id: number) => {
    var result = await this.getClient().getAgentCurrentTime(
      id,
      this.getApiVersion(),
      UserContext.userId,
      undefined
    );
    return result;
  };

  @action saveAgentMarketer = async (
    agentId: number,
    agentMarketerId: number
  ) => {
    var request = {
      agentId: agentId,
      agentMarketerId: agentMarketerId,
    };
    var result = await this.getClient().saveAgentMarketer(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      request
    );
    return result;
  };

  @action getAllEmailCommunication = async (
    agentId: number,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined,
    filterText?: string | undefined
  ) => {
    let result = await this.getClient().getAllEmailCommunication(
      agentId,
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      filterText,
      UserContext.getUserId()
    );
    return result;
  };

  @action downloadEmailCommunicationAttachment = async (
    attachmentFileGuid: string
  ) => {
    let result = await this.getClient().downloadEmailCommunicationAttachment(
      this.getApiVersion(),
      attachmentFileGuid,
      UserContext.getUserId()
    );
    return result;
  };

  @action getNoteScreenLookups = async () => {
    let result = await this.getClient().getNoteScreenLookups(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getSaleTypeLookup = async () => {
    let result = await this.getClient().getSaleTypeLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getMarketingEventWebViewerUrl = async (url: string) => {
    return this.getClient().getMarketingEventWebViewerUrl(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      { url }
    );
  };
  @action getAgentStatusById = async (agentId: number) => {
    return this.getClient().getAgentStatusById(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action generateExcelAgentsByGeolocation = async (
    zipcode: string,
    carrier: string,
    distance: string
  ) => {
    return this.getClient().generateExcelAgentsByGeolocation(
      zipcode,
      carrier,
      this.getApiVersion(),
      +distance,
      UserContext.getUserId()
    );
  };
  @action getAgentMarketerLookup = async (
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAgentMarketersLookup(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.userId
    );

    return result;
  };
  @action agentStateLicenseReport = async (
    agentOragencyId?: number | undefined,
    stateLicenseIds?: number[] | undefined,
    startDate?: Date | undefined,
    endDate?: Date | undefined,
    productLineId?: number | undefined,
    issueDate?: Date | undefined,
    expirationDate?: Date | undefined,
    stateLicenseStatusId?: string | undefined,
    fileType?: any
  ) => {
    if (stateLicenseIds && stateLicenseIds.length > 0) {
      stateLicenseIds = stateLicenseIds.map((i) => Number(i));
    }
    var request: AgentStateLicenseRequest = {
      agentOrAgencyId: agentOragencyId,
      stateLicenseIds: stateLicenseIds ? stateLicenseIds : [],
      startDate: startDate,
      endDate: endDate,
      lineOfAuthorityId: productLineId || [],
      issueDate: issueDate,
      expirationDate: expirationDate,
      fileType: fileType,
      stateLicenseStatusId: stateLicenseStatusId,
    };
    var result = await this.getClient().getStateLicensesReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  @action getContactSMS = async (agentId: number) => {
    var result = await this.getClient().getContactSMS(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getContactSMSBody = async (
    agentId: number,
    contactId: number,
    noOfMessages: number
  ) => {
    var result = await this.getClient().getContactSMSBody(
      this.getApiVersion(),
      agentId,
      contactId,
      noOfMessages,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPhoneTypes = async () => {
    var result = await this.getClient().getPhoneTypes(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getEmailTypes = async () => {
    var result = await this.getClient().getEmailType(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAlternativePOCTypes = async () => {
    var result = await this.getClient().getAlternativePOCType(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action setEnableSMS = async (id: number, enableSMS: boolean) => {
    var result = await this.getClient().setEnableSMS(
      id,
      enableSMS,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action createAlert = async (data: any) => {
    var result = await this.getClient().createAlert(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      data
    );
    return result;
  };
  @action updateAlert = async (data: any) => {
    var result = await this.getClient().updateAlert(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      data
    );
    return result;
  };
  @action getAlertByAgentId = async (agentID: any) => {
    var result = await this.getClient().getAlertByAgentId(
      agentID,
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId()
    );
    return result;
  };

  @action setIsMessageRead = async (id: number) => {
    var result = await this.getClient().setIsMessageRead(
      id,
      true,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action sendSms = async (dto: SendTextMessageRequest) => {
    return await this.getClient().sendSms(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action getAllPhonePreferences = async (agentId: number) => {
    return await this.getClient().getAllPhonePreferences(
      this.getApiVersion(),
      agentId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      UserContext.getUserId(),
      undefined
    );
  };
  @action updatePhonePreference = async (dto: UpdatePhonePreferenceModel) => {
    return await this.getClient().updatePhonePreference(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action getEmailPreferencesDetailByAgentId = async (agentId: number) => {
    return await this.getClient().getEmailPreferencesDetailByAgentId(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
  };
  @action createAgentContactEmailUnsubscribe = async (
    dto: CreateAgentContactEmailUnsubscribeModel[]
  ) => {
    return await this.getClient().createAgentContactEmailUnsubscribe(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;
}
export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}
function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) throw result;
  else throw new ApiException(message, status, response, headers, null);
}

export default new ProducerStore();
