export enum SubmissionTabs {
    SubmissionMethod = "Manage Submission Methods",
    AutoUnchecks = "Manage AutoUnchecks",
    Requirements = "Manage Requirements",
    SubmissionTypes = "Manage Submission Types",
    SenderSubmissionTypes = "Manage Sender Submission Types",
  }
  
  export enum SubmissionTabsViewModelTypes {
    SubmissionMethod = "SubmissionMethod",
    ViewSubmissionMethod = "ViewSubmissionMethod",
    AddEditSubmissionMethod = "AddEditSubmissionMethod",
    // AddEditSubmissionMethodTypeConnector = "AddEditSubmissionMethodTypeConnector",
    AutoUnchecks = "AutoUnchecks",
    AddEditAutoUnchecks = "AddEditAutoUnchecks",
    Requirements = "Requirements",
    ViewRequirements = "ViewRequirements",
    AddEditRequirements = "AddEditRequirements",
    SubmissionTypes = "SubmissionTypes",
    AddEditSubmissionTypes = "AddEditSubmissionTypes",
    SenderSubmissionTypes = "SenderSubmissionTypes",
  }

export enum SubmissionTypes{
    Email = "Email",
    FTP = "FTP",
  }

export enum SubmissionMethods{
  Agent = "Agent",
  Customer = "Insured"
}

export enum TypeConnector{
  Email = "Email",
  FTP = "FTP",
}

export enum AddEditSubmissionMethod {
  SubmissionMethod = 'SubmissionMethod',
  TypeConnector = 'TypeConnector',
  AddSubmission = "Add Submission Method",
  EditSubmission = "Edit Submission Method",
  AddTypeConnector = "Add Type Connector",
  EditTypeConnector= "Edit Type Connector",
  add = 'add',
  edit = 'edit',
}

  export interface AddEditClick {
    (...args: any[]): void;
  }
  