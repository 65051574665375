import { getToken } from "../infrastructure/Auth0Authenticator";
import { action, toJS } from "mobx";
import UserContext from "../infrastructure/UserContext";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { CreateCustomAttributeModel, CreateScreenCustomAttributeModel, Client as CustomAttributeServiceClient } from "../services/CustomAttributeService";
class CustomAttributeStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): CustomAttributeServiceClient => {
    return ServiceClient.getClient(ServiceType.CustomAttribute);
  }

  @action getAllScreens = async () => {
    var result = await this.getClient().getAllScreens(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllDataTypes = async () => {
    var result = await this.getClient().getAllDataTypes(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getScreenAttributes = async (screenId: number, entityId: number) => {   
    var result = await this.getClient().getScreenAttributes(
      screenId,
      this.getApiVersion(),
      entityId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getExistingAttributes = async () => {
    var result = await this.getClient().getAllAttributes(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  
  @action getAllAttributesByScreen = async (screenId: number, entityId?: number) => { 
    try
    {
      var result = await this.getClient().getScreenAttributes(
        screenId,
        this.getApiVersion(),
        entityId,
        UserContext.getUserId()
      );
      return result;
    }
    catch (e) { console.log(e); }
  };


  @action createCustomAttributes = async (body: CreateCustomAttributeModel) => {
    var result = await this.getClient().createCustomAttributes(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      body
    );
    return result;
  };
  @action reuseCustomAttributes = async (body: any) => {
    var result = await this.getClient().reuseCustomAttributes(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      body
    );
    return result;  
};
@action getAttributeById = async (
  id: number, isEdit:boolean) => {
  var result = await this.getClient().getAttributeById(
    id,
    isEdit,
    this.getApiVersion(), 
    UserContext.getUserId()
  );
  return result;  
};
@action getRecordCountById = async (
  id: number) => {
  var result = await this.getClient().getRecordCountById(
    id,
    this.getApiVersion(), 
    UserContext.getUserId()
  );
  return result;  
};

@action getAllManageCustomAttributes = async (
  searchFilterSelected:any | undefined,
  privacyStatus:any | undefined,
  pageIndex: number,
  pageSize: number,
  sortColumn: string | undefined,
  sortAscending: boolean | undefined,
  fullTextFilter:string | undefined) => {
  var result = await this.getClient().getAllManageCustomAttributes(
    this.getApiVersion(), 
    privacyStatus,
    searchFilterSelected,
    pageIndex,
    pageSize,
    sortColumn,
    sortAscending,
    fullTextFilter,
    UserContext.getUserId(),
    UserContext.getUserId()
  );
  return result;  
};
@action deleteCustomAttribute = async (id:any) => {
    var result = await this.getClient().deleteCustomAttribute(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId()
    );
    return result;  
};


@action updateCustomAttribute = async (body: any) => {
  var result = await this.getClient().updateCustomAttribute(
    this.getApiVersion(),
    UserContext.getUserId(),
    UserContext.getUserId(),
    body
  );
  return result;
};

@action saveCustomAttributes = async (body: any) => {
  var result = await this.getClient().saveCustomAttributes(
    this.getApiVersion(),
    UserContext.getUserId(),
    UserContext.getUserId(),
    body
  );
  return result;
};


}
export default new CustomAttributeStore();
