import AccountingStore from "../store/AccountingStore";
import ContractsStore from "../store/ContractsStore";
import DocumentStore from "../store/DocumentStore";
import IdentityStore from "../store/IdentityStore";
import LeadStore from "../store/LeadStore";
import ProducerStore from "../store/ProducerStore";
import ProductStore from "../store/ProductStore";
import ReportStore from "../store/ReportStore";
import TaskStore from "../store/TaskStore";
import WorkflowStore from "../store/WorkflowStore";
import PageContext from "./PageContext";
import UserContext from "./UserContext";

async function cacheDropdowns(): Promise<any> {
  PageContext.setIsOverlayEnabled(false);
  await WorkflowStore.getAllLookup();
  await ContractsStore.getAllMasterCarrierGroupLookup();
  await ContractsStore.getAllCompanyLookup();
  await ContractsStore.getMarketersLokup();
  // await UserStore.getImpersonateUserList();
  await ProductStore.getReportProductList(0);
  // await ContractsStore.getCarriers(0, 1000);
  await AccountingStore.getBankAccountLookup();
  await IdentityStore.getAllUsers("");
  await IdentityStore.getUserTeamList();
  await IdentityStore.getActionList();
  await TaskStore.getCreatedByUsersLookup();
  await DocumentStore.getAllDMSLookupList();
  await DocumentStore.getAllPacketLookup();
  // await ReportStore.previewReport({reportId: 0});
  await ContractsStore.getAllMasterCarrierLookup();

  //await ContractsStore.reportAgentHomeState();
  await ContractsStore.appointmentStates();
  await ProducerStore.getContactTypes();

  await LeadStore.getLeadPhaseList();
  if (UserContext.getUserId()) {
    await LeadStore.getDropdownValues(true, true);
  }
  await ProducerStore.reportAgentStatus();
  await ContractsStore.reportLicenseStatus();
  await LeadStore.reportLeadSource();
  await IdentityStore.getUserTeamList();
  //await InsuredStore.getPolicySummary(0, 0);
  await ProductStore.getPrdtCatList();

  //await InsuredStore.getPolicies(1, 0, 0,'',0, 25,'id',false);

  PageContext.setIsOverlayEnabled(true);
}

export function initialize() {
  setTimeout(async () => {
    try {
      await cacheDropdowns();
    } catch (e) {}
  }, 1000);

  // window.addEventListener('load', () => {
  //     setTimeout(async function () {
  //         try{
  //             await cacheDropdowns();
  //         } catch(e) {}
  //     }, 1000);
  // });
}
