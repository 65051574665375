import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import SupplyOrderStore from "../../../store/SupplyOrderStore";
import Utils from "../../../infrastructure/Utils";

export class AddSupplyItemViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  @action Load = async (OnClose?: () => void): Promise<void> => {
    this.onClose = OnClose;
    this.agentId = 316094;
  };
  private onClose?: () => void;
  Route = async (): Promise<void> => {
    // TODO: implement or remove function
  };
  @observable isLoading: boolean = true;

  @observable items: any;
  @observable states: any;
  @observable types: any;
  @observable companies: any;
  @observable quantity: number | undefined = 1;

  @observable agentId: number = 0;
  @observable selectedState: number = 0;
  @observable selectedType: number = 0;
  @observable selectedCompany: number = 0;
  @observable selectdItem: number = 0;
  @observable isDialogVisible: boolean = false;
  public ErrorModel = new ErrorModel(new SupplyItemValidator());

  @action load = async () => {
    this.ResetValidate();
    this.getItems();
  };

  @action noRecordsFound = () => {
    this.items = ["No Records Found"];
  };

  @action setSelectedState(value: number) {
    this.selectedState = value;
  }
  @action setSelectedCompany(value: number) {
    this.selectedCompany = isNaN(value) ? 0 : value;
  }
  @action setSelectedType(value: number) {
    this.selectedType = value;
  }
  @action setSelectedItem(value: number) {
    this.selectdItem = value;
  }
  @action setSelectedQuantity(value: any) {
    this.quantity = value ? parseInt(value) : undefined;
  }
  @action async getItems() {
    this.items = [];
    const result = await SupplyOrderStore.getSupplyItemList(
      this.selectedState,
      this.selectedCompany,
      this.selectedType
    );
    this.items = this.mapListItemAndSort(result);
    if (result && result.length <= 0) {
      this.selectdItem = 0;
      this.noRecordsFound();
    }
  }
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      const sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });

      return sortList;
    } else {
      this.selectdItem = 0;
      return [];
    }
  }
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  handleButtonClick = () => {
    // TODO: implement or remove function
  };

  @action setDialogVisibility(value: boolean) {
    this.isDialogVisible = value;
  }
}
class SupplyItemValidator extends AbstractValidator<AddSupplyItemViewModel> {
  public constructor() {
    super();
    this.validateIfNumber((input) => input.selectdItem)
      .isNotEqualTo(0)
      .isNotEmpty()
      .withFailureMessage("Item is required");
    this.validateIfNumber((input) => input.quantity)
      .isNotEmpty()
      .isGreaterThan(0)
      .isLessThan(1001)
      .withFailureMessage("Quantity must be between 1 and 1000.");
  }
}
