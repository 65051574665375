import { routes } from "../../../router";
import { BaseListViewModel } from "../../components/BaseListViewModel";
import LeadStore from "../../../store/LeadStore";
import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import ContractsStore from "../../../store/ContractsStore";

interface listViewModel {
  id?: number;
  companyName?: string;
}
export class AgentLeadDisabledCarrierViewModel extends BaseListViewModel<listViewModel> {
  constructor() {
    super(
      "Agent Lead Disabled Carriers",
      "Agent Lead Disabled Carriers",
      routes.addAgentLeadDisabledCarrier,
      routes.editAgentLeadDisabledCarrier
    );
  }

  @observable public ErrorModel = new ErrorModel(
    new AgentLeadDisabledCarrierViewValidator()
  );

  @observable selectedCarrier: string = "";
  @observable selectedCompany: string = "";

  @observable actionTitle: string = "";
  @observable isEditorVisible: boolean = false;

  @observable isDuplicateWarningVisible: boolean = false;
  @action hideDuplicateWarning = () => {
    this.isDuplicateWarningVisible = false;
  }

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @action public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    return await LeadStore.getAllAgentLeadDisabledCarriers(
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }

  protected async deleteItem(id: number) {
    await LeadStore.deleteAgentLeadDisabledCarrier(id);
  }

  protected toServiceModel() {
    var dto: any = {
      carrierId: Number(this.selectedCarrier),
      companyName: this.selectedCompany,
    };
    return dto;
  }

  @action setSelectedCompany = async (value: string) => {
    let name = this.companyList.values.filter((ind) => ind.value === value);
    this.selectedCompany = name[0].label;
  };
  @action setEditorVisibility(isVisible: boolean) {
    this.isEditorVisible = isVisible;
}
@action onEditorHide() {
    this.setEditorVisibility(false);
}

@action editItem = async (id: number) => {
  if(id === 0) {
      this.actionTitle = "Add Agent Lead Disabled Carriers";
      this.selectedCarrier = "";
      // this.selectedIsHeriarchyBased = false;
  } else {
      
  }
  this.ResetValidate();
  this.setEditorVisibility(true);
};
@action addItem = async () => {
  this.editItem(0);
};

  protected loadLookups = async () => {
    await this.loadCompanies();
  };

  @action saveSelectedItem = async () => {
    try
    {
      if (!await this.Validate()) {
        var item = this.toServiceModel();
        var isFound = await LeadStore.isCarrierDisabledForAgentLead(parseInt(item.carrierId));
        if(isFound) {
          this.isDuplicateWarningVisible = true;
          return;
        }
        else {
          await LeadStore.addAgentLeadDisabledCarrier(item);
        }
        
        this.setEditorVisibility(false)
        setTimeout(() => { this.Load(); }, 10);
      }
    }
    catch(e){
      console.log(e)
      this.setEditorVisibility(false)
    }
}

  @action private loadCompanies = async () => {
    this.isLoading = true;
    // var defaultItem = { label: "All", value: "0" };
    var result = await ContractsStore.getAllCompanyLookup();

    if (result !== null) {
      this.companyList.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companyList.values.push(data);
        });
      // this.companyList.values.splice(0, 0, defaultItem);
      this.selectedCarrier = "";
    } else {
      this.companyList.values = [];
      // this.companyList.values.splice(0, 0, defaultItem);
      this.selectedCarrier = "";
    }

    this.isLoading = false;
  };

  resetFiltersToDefault() {
    this.pageSize = 10;
  }
}

class AgentLeadDisabledCarrierViewValidator extends AbstractValidator<AgentLeadDisabledCarrierViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedCarrier)
      .isNotEmpty()
      .withFailureMessage("Carrier is required");
  }
}
