import { routes } from "../../../router";
import { BaseListViewModel } from "../../components/BaseListViewModel";
import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import { AgentListModel } from "../../../services/ProducerSearchService";
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import ContractsStore from "../../../store/ContractsStore";
import {
  CertificationsDropDownValuesResponse,
  CertificationsListModel,
} from "../../../services/ContractService";
import Utils from "../../../infrastructure/Utils";

interface listViewModel {
  id?: number;
  user?: string;
  company?: string;
  product?: string;
  certDate?: Date | string | undefined;
  year?: string;
  fullName?: string;
}
export class CertificationsListViewModel extends BaseListViewModel<listViewModel> {
  constructor() {
    super(
      "Certification Overrides",
      "Certification",
      routes.addCertifications,
      routes.editCertifications,
      "id",
      "id",
      false
    );
    this.pageSize = 50;
  }

  protected async loadLookups() {
    await this.certificationsDropDownValues();
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    let year = this.defaultSelectedYear ? Number(this.defaultSelectedYear) : 0;
    let companyId = this.defaultSelectedCompany
      ? Number(this.defaultSelectedCompany)
      : 0;
    let agentType = this.agentId ? Number(this.agentId) : 0;
    let searchText = this.agent ? this.agent : "";
    return await ContractsStore.getAllCertifications(
      year,
      companyId,
      agentType,
      searchText,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList.values = []; 
    this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
  protected certificationsDropDownValues = async () => {
    let result = await ContractsStore.certificationsDropDownValues();
    if (result) {
      let response: CertificationsDropDownValuesResponse = result;
      if (response.company) {
        this.companyList.values = [];
        response.company.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companyList.values.push(data);
        });
        this.companyList.values.splice(0, 0, { label: "All", value: "0" });
        this.carriersInitial.values = this.companyList.values;
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
        this.defaultSelectedCompany = "0";
      } else {
        this.companyList.values = [];
        this.companyList.values.splice(0, 0, { label: "All", value: "0" });
        this.defaultSelectedCompany = "0";
      }
      if (response.year) {
        response.year.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.yearList.values.push(data);
        });
        this.yearList.values.splice(0, 0, { label: "All", value: "0" });
      } else {
        this.yearList.values = [];
        this.yearList.values.splice(0, 0, { label: "All", value: "0" });
        this.defaultSelectedYear = "0";
      }
    }
  };

  protected async deleteItem(id: number) {
    await ContractsStore.deleteCertification(id);
  }

  @action getYearDropdownValues = () => {
    this.yearList.values = [];
    let currYear = new Date().getFullYear();
    let arr = [];
    arr.push(
      currYear.toString(),
      (currYear - 1).toString(),
      (currYear - 2).toString()
    );
    arr.unshift((currYear + 1).toString());
    arr.forEach((ind) => {
      var data = {
        label: ind ? ind : "",
        value: ind ? ind : "",
      };
      this.yearList.values.push(data);
    });
    this.defaultSelectedYear = "2021";
  };

  @observable FilteredAgenteList = new Collection<AgentListModel>();
  @observable deleteTemplate: any = "";
  @observable selectedModel: CertificationsListModel = {};

  @action setSelectedModel = (value: any) => {
    this.selectedModel = value;
  };

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
  
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable yearList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable defaultSelectedCompany: string = "0";
  @observable certificateId: number = 0;
  @observable
  defaultSelectedYear: string = (new Date().getFullYear() + 1).toString();

  @observable agent: string = "";
  @observable agentId: number = 0;

  @action setAgent = (value: string) => {
    this.agent = value;
  };
  @action setAgentId = (value: number) => {
    this.agentId = value;
  };

  @action setSelectedCertificate = (value: number) => {
    this.certificateId = value;
  };

  @action fillterUsers = async (value: string) => {
    try {
      let result = await ProducerSearchStore.getAgentsLookup(
        1,
        value,
        0,
        100,
        undefined,
        undefined
      );
      var values = null;
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            values = result.data;
          }
        }
      }
    } catch (e) {}

    if (values !== undefined && values !== null) {
      this.FilteredAgenteList.values = values;
    } else {
      var noAgent: AgentListModel = { id: 0 };
      this.FilteredAgenteList.values = [noAgent];
    }
  };

  @action search = async () => {
    await this.loadItems();
  };

  @action reset = async () => {
    
    this.showInactiveCarriers = false;
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
    this.defaultSelectedCompany = "0";
    this.defaultSelectedYear = (new Date().getFullYear() + 1).toString();
    this.agent = "";
    this.agentId = 0;
    this.certificateId = 0;
    // this.Load();
    await this.loadItems();
  };

  resetFiltersToDefault() {
    this.pageSize = 50;
  }

  @action private loadCompanies = async () => {
    this.isLoading = true;
    // var defaultItem = { label: "All", value: "0" };
    var result = await ContractsStore.getAllCompanyLookup();

    if (result !== null) {
      this.companyList.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companyList.values.push(data);
        });
      // this.companyList.values.splice(0, 0, defaultItem);
      this.defaultSelectedCompany = "";
    } else {
      this.companyList.values = [];
      // this.companyList.values.splice(0, 0, defaultItem);
      this.defaultSelectedCompany = "";
    }

    this.isLoading = false;
  };
}
