/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { AgentListModel } from "../../services/ProducerSearchService";
import {
  ProfileCheckbox,
  ProfileDetails,
} from "../../infrastructure/enum/Customer";

export class AgentProfileViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (id: number) => {
    this.agentId = id;
    this.loadData();
  };
  @action setSelectedPanel(value: string) {
    this.selectedPanel = value;
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable isMedicareChecked: boolean = true;
  @observable isHeathCbChecked: boolean = false;
  @observable isLongTermCbChecked: boolean = false;
  @observable isDisabiltiyCbChecked: boolean = false;
  @observable isFinalExpenseCbChecked: boolean = true;
  @observable isLifeCbChecked: boolean = false;
  @observable isAnnuityCbChecked: boolean = true;
  @observable isGroupCbChecked: boolean = false;
  @observable isPropertyCbChecked: boolean = true;
  @observable isSecuritiesCbChecked: boolean = false;

  @observable rows: number = 10;
  @observable totalRecords: number = 100;
  @observable first: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable selectedPanel: string = ProfileDetails.ProductProfile;
  @observable selectedMarketer: string = "";
  @observable endDate: Date = new Date();
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable referrerList = new Collection<{
    date: string;
    referrer: string;
    referredTo: string;
  }>();
  @action loadData = async () => {
    this.isLoading = true;
    this.referrerList.values = [
      {
        date: "03/24/2015",
        referrer: "Lundstrom, Steve",
        referredTo: "Kraft, Lisa",
      },
      {
        date: "04/15/2015",
        referrer: "Lundstrom, Steve",
        referredTo: "Otto, Tom",
      },
      {
        date: "04/15/2015",
        referrer: "Lundstrom, Steve",
        referredTo: "Otto, Tom",
      },
      {
        date: "04/15/2015",
        referrer: "Lundstrom, Steve",
        referredTo: "Otto, Tom",
      },
      {
        date: "0/15/2015",
        referrer: "Lundstrom, Steve",
        referredTo: "Hake, Brandi",
      },
      {
        date: "05/04/2016",
        referrer: "Pospisil, Adrienne",
        referredTo: "Prince, Steven",
      },
      {
        date: "11/04/2016",
        referrer: "Ritter, Travis",
        referredTo: "Ritter, Travis",
      },
    ];
    this.totalRecords = this.referrerList.values.length;
    this.isLoading = false;
  };
  @action setSelectDate(value: Date | Date[]) {
    this.endDate = value;
  }
  @action setSelectedMarketer(value: string) {
    this.selectedMarketer = value;
  }
  @action fillterUsers = async (value: string) => {
    // var results = await AgentsStore.getAgentList(value)
    // this.FilteredAgentsList.values = results.values;
  };
  @action updateChecbox(value: string, checked: boolean) {
    switch (value) {
      case ProfileCheckbox.Medicare:
        this.isMedicareChecked = checked;
        break;
      case ProfileCheckbox.Health:
        this.isHeathCbChecked = checked;
        break;
      case ProfileCheckbox.LongTermCare:
        this.isLongTermCbChecked = checked;
        break;
      case ProfileCheckbox.Disability:
        this.isDisabiltiyCbChecked = checked;
        break;
      case ProfileCheckbox.FinalExpense:
        this.isFinalExpenseCbChecked = checked;
        break;
      case ProfileCheckbox.Life:
        this.isLifeCbChecked = checked;
        break;
      case ProfileCheckbox.Annuity:
        this.isAnnuityCbChecked = checked;
        break;
      case ProfileCheckbox.Group:
        this.isGroupCbChecked = checked;
        break;
      case ProfileCheckbox.Property:
        this.isPropertyCbChecked = checked;
        break;
      case ProfileCheckbox.Securities:
        this.isSecuritiesCbChecked = checked;
        break;
    }
  }

  /**
   * Event handler for page change of Paginator
   *
   * @param {number} firstIndex : start idex of page
   * @memberof AgentProfileViewModel
   */
  @action onPage(firstIndex: number) {
    this.first = firstIndex;
  }
}
