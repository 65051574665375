import { routes } from "../../../../router";
import {
  ProductLineModel2,
  ProductLinesListModel,
} from "../../../../services/ProductService";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import ProductStore from "../../../../store/ProductStore";
import { action, observable } from "mobx";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import PageContext from "../../../../infrastructure/PageContext";

export class ProductLinesListViewModel extends BaseListViewModel<ProductLinesListModel> {
  @observable actionTitle: string = "";
  @observable isEditorVisible: boolean = false;
  @observable public ErrorModel = new ErrorModel(new ProductLineValidator());

  @observable selectedLineName: string = "";
  @observable selectedIsHeriarchyBased: boolean = false;

  @action setEditorVisibility(isVisible: boolean) {
    this.isMessgeVisible = false;
    this.isEditorVisible = isVisible;
  }
  @action onEditorHide() {
    this.isMessgeVisible = false;
    this.setEditorVisibility(false);
  }

  @action editItem = async (id: number) => {
    if (id === 0) {
      this.actionTitle = "Add Product Line";
      this.selectedId = 0;
      this.selectedLineName = "";
      this.selectedIsHeriarchyBased = false;
    } else {
      this.actionTitle = "Edit Product Line";
      var item = await ProductStore.getProductLineById(id);
      this.selectedId = item.id || 0;
      this.selectedLineName = item.productLineName || "";
      this.selectedIsHeriarchyBased = item.isMarketerHierarchyBased || false;
    }
    this.ResetValidate();
    this.setEditorVisibility(true);
  };
  @action addItem = async () => {
    this.editItem(0);
  };

  protected toServiceModel() {
    var dto: ProductLineModel2 = {
      id: this.selectedId,
      productLineName: this.selectedLineName,
      isMarketerHierarchyBased: this.selectedIsHeriarchyBased,
    };
    return dto;
  }
  @action public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @action saveSelectedItem = async () => {
    try {
      if (!(await this.Validate())) {
        var item = this.toServiceModel();
        if (this.selectedId === 0) await ProductStore.addProductLine(item);
        else await ProductStore.updateProductLine(item);

        this.setEditorVisibility(false);
        //this.setSuccessMessage()
      }
      setTimeout(() => {
        this.Load();
      }, 10);
      this.isMessgeVisible = false;
    } catch (e:any) { 
      e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          let _status = JSON.parse(_responseText).status;
          var responseModels = {
            status: _status,
            title: "",
                  errors: { "ValidationError": [_title] },
        };
            this.response = JSON.stringify(responseModels);
            this.isMessgeVisible = true;
        }); 
    }
  };

  constructor() {
    super(
      "Product Lines",
      "Product Line",
      routes.addProductLine,
      routes.editProductLine,
      "id",
      "productLineName"
    );
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    return await ProductStore.getProductLineList(
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }

  protected async deleteItem(id: number) {
    await ProductStore.deleteProductLine(id);
  }

  resetFiltersToDefault() {
    this.pageSize = 10;
  }
}

class ProductLineValidator extends AbstractValidator<ProductLinesListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedLineName)
      .isNotEmpty()
      .matches(/\S+/, "i")
      .isNotEqualTo("0")
      .withFailureMessage("Name for Product Line is required");
  }
}
