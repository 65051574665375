import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { routes } from "../../../../router";
import { RemoveFuneralHomeStatementsViewModel } from "./RemoveFuneralHomeStatementsViewModel";
import { RunCommissionsViewModel } from "./RunCommissionsViewModel";

export class ProcessCommissionsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
   
  @action Load = async () => {
    this.removeFuneralHome.Load();
    this.runCommission.Load();
  };

  Route = async (currentRoute: RouteList): Promise<void> => {     
  };

  @observable sortColumn: string = 'id'
  @observable sortOrder: boolean = false;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 25;
  @observable totalRecords: number = 100;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable sortColumnOrder: string = "id";
  @observable selectedProcessCommission: any;
  @observable selectedFuneralStatement: any;
  @observable removeFuneralHome: RemoveFuneralHomeStatementsViewModel = new RemoveFuneralHomeStatementsViewModel();
  @observable runCommission: RunCommissionsViewModel = new RunCommissionsViewModel();

  @action goToAdmin = () => {
    routes.administration.replace()
  }

  @action setSortColumn = async(sortField: any) => {
    this.sortOrder = !this.sortOrder;
    this.sortColumnOrder = sortField;
  }

  @action onPage = async(firstIndex: number, rows: number) => {
    this.pageSize = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.pageSize;
  }
 
}