import { action } from "mobx";
import { routes } from "../../../../router";
import { MarketerMailConfigsListModel } from "../../../../services/ContractService";
import MarketerMailConfigurationStore from "../../../../store/MarketerMailConfigurationStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";

export class MarketerMailConfigurationListViewModel extends BaseListViewModel<MarketerMailConfigsListModel> {
    constructor()
    {
        super("Contract Invite Emails", "Contract Invite Emails", routes.listMarketerMailConfiguration, routes.listMarketerMailConfiguration, "id", "agentId", true);
        this.pageSize = 500
        //setting pageSize to be random number to accomodate list items without pagination
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        return await MarketerMailConfigurationStore.loadMarketerMailConfigurationList(pageIndex, pageSize, sortColumn, sortOrder);
    }

    @action UpdateStatus = async (rowData: MarketerMailConfigsListModel, isEnabled: boolean) => {
        if (rowData.id === 0) {
            await MarketerMailConfigurationStore.addMarketerMailConfiguration({
                id:0,
                marketerAgentId: rowData.agentId,
                isEmailEnabled: isEnabled
            });
        }
        else {
            await MarketerMailConfigurationStore.editMarketerMailConfiguration({
                id: rowData.id,
                marketerAgentId: rowData.agentId,
                isEmailEnabled: isEnabled
            });
        }
        this.loadItems();
    }
}
