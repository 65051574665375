import { AbstractValidator } from "fluent-ts-validator";
import { observable } from "mobx";
import { routes } from "../../../../router";
import { WebLinkModel } from "../../../../services/BusinessUnitConfigService";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import BusinessUnitConfigStore from "../../../../store/BusinessUnitConfigStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";
import ContractsStore from "../../../../store/ContractsStore";

export class WebLinkAddEditViewModel extends BaseAddEditViewModel {

    @observable title: string = "";
    @observable url: string = "";
    @observable shortDescription: string = "";
    @observable startDate: Date | undefined = undefined;
    @observable endDate: Date | undefined = undefined;
    @observable enabled: boolean = false;
    @observable imageUrl: string = "";
    @observable linkTypeId: string = "0";
    @observable eventDate: Date | undefined = undefined;
    @observable companyId: string = "0";
    @observable enableOnlineRegistration: boolean = false;
    @observable webLinkTypes = new Collection<{
        label: string;
        value: string;
    }>();
    @observable carriers = new Collection<{
        label: string;
        value: string;
    }>();

    constructor() {
        super("Web Link", routes.listWebLinks, new WebLinkValidator());
    }

    protected toServiceModel() {
        var dto: WebLinkModel = {
            id: this.selectedId,
            title: this.title,
            url: this.url,
            shortDescription: this.shortDescription,
            startDate: this.startDate,
            endDate: this.endDate,
            enabled: this.enabled,
            imageUrl: this.imageUrl,
            linkTypeId: parseInt(this.linkTypeId),
            eventDate: this.eventDate,
            companyId: parseInt(this.companyId),
            enableOnlineRegistration: this.enableOnlineRegistration
        }
        return dto;
    }

    protected async loadLookups(): Promise<void> {
        var items = await ContractsStore.getAllCompanyLookup();
        Utils.mapListItemsToDropdown(items, this.carriers, "Select a Carrier");

        this.webLinkTypes.values = [
            { label: "Select a Type", value: "0" },
            { label: "Carrier Update", value: "1" },
            { label: "Industry News", value: "2" },
            { label: "Events", value: "3" },
        ];
    }

    protected resetModel() {
        this.title = "";
        this.url = "";
        this.shortDescription = "";
        this.startDate = new Date();
        this.endDate = new Date();
        this.eventDate = new Date();

        this.enabled = false;
        this.imageUrl = "";
        this.linkTypeId = "0";
        this.companyId = "0";
        this.enableOnlineRegistration = false;
    }


    protected async loadItem(id: number): Promise<void> {
        var item = await BusinessUnitConfigStore.getWebLinkById(id);
        if (item) {
            this.selectedId = item.id || 0;
            this.title = item.title || "";
            this.url = item.url || "";
            this.shortDescription = item.shortDescription || "";
            var startDate = Utils.getDateInFormat(item.startDate && id !== 0 ? item.startDate : undefined);
            this.startDate = new Date(startDate);

            var endDate = Utils.getDateInFormat(item.endDate && id !== 0 ? item.endDate : undefined);
            this.endDate = new Date(endDate);

            var eventDate = Utils.getDateInFormat(item.eventDate && id !== 0 ? item.eventDate : undefined);
            this.eventDate = new Date(eventDate);

            this.enabled = item.enabled || false;
            this.imageUrl = item.imageUrl || "";
            this.linkTypeId = String(item.linkTypeId) || "";
            this.companyId = String(item.companyId) || "";
            this.enableOnlineRegistration = item.enableOnlineRegistration || false;

        }

    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await BusinessUnitConfigStore.addWebLink(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await BusinessUnitConfigStore.updateWebLink(item);
    }

}

class WebLinkValidator extends AbstractValidator<WebLinkAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.linkTypeId)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Type is required");

        this.validateIfString(input => input.companyId)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Carrier is required");

        this.validateIfString(input => input.title)
            .isNotEmpty()
            .matches(/\S+/,"i")
            .withFailureMessage("Title is required");

        this.validateIfDate(input => input.startDate)
            .isNotEmpty()
            .withFailureMessage("Start Date is required");

        this.validateIfDate(input => input.endDate)
            .isNotEmpty()
            .withFailureMessage("End Date is required");



    }
}