import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import ProducerStore from "../../../store/ProducerStore";
import Collection from "../../../infrastructure/CollectionHelper";
import { EmailPreferencesDetailModel } from "../../../services/ProducerService";

export class EmailSubscriptionViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (id: number) => {
    this.agentId = id;
    await this.getEmailPreferencesDetailByAgentId();
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable emailData = new Collection<EmailPreferencesDetailModel>();
  @observable emailSubscriptions = new Collection<any>();
  @observable agentId: number = 0;

  @action getEmailPreferencesDetailByAgentId = async () => {
    const result = await ProducerStore.getEmailPreferencesDetailByAgentId(
      this.agentId
    );
    if (result) {
      this.emailData.values = result || [];
      this.formatEmailData();
    }
  };

  @observable dataTableHeaders: any = [];
  @observable dataTableData: any;

  @action formatEmailData = () => {
    this.dataTableHeaders = [];
    this.emailSubscriptions.values = [];
    if (this.emailData.values.length > 0) {
      this.emailData.values.forEach((obj, ind) => {
        if (ind === 0) {
          this.dataTableHeaders.push({
            header: "Email Address",
            field: "emailAddress",
          });
        }
        this.emailSubscriptions.values.push({
          emailAddress: obj.emailAddress,
        });
        if (
          obj.emailPreferencesProductLinesDetail &&
          obj.emailPreferencesProductLinesDetail.length > 0
        ) {
          obj.emailPreferencesProductLinesDetail.forEach((objd: any, indd) => {
            if (ind === 0) {
              this.dataTableHeaders.push({
                header: objd.productLineName,
                field: objd.productLineName,
              });
            }
            this.emailSubscriptions.values[ind][objd.productLineName] =
              objd.isChecked;
          });
        }
      });
    }
  };

  @action productLineNameChange = (
    value: boolean,
    rowData: any,
    column: any
  ) => {
    let newValues: any[] = [];
    this.emailSubscriptions.values.forEach((obj) => {
      if (obj.emailAddress === rowData.emailAddress) {
        rowData[column.field] = value;
      }
      newValues.push(obj);
      this.emailSubscriptions.values = [];
      this.emailSubscriptions.values = newValues;
    });
    let emailDataFinal = this.emailData.values;
    const email = emailDataFinal.filter(
      (i: any) => i.emailAddress === rowData.emailAddress
    );
    if (
      email.length === 1 &&
      email[0].emailPreferencesProductLinesDetail &&
      email[0].emailPreferencesProductLinesDetail.length > 0
    ) {
      let emailPreference = email[0].emailPreferencesProductLinesDetail.filter(
        (ep: any) => ep.productLineName === column.field
      );
      if (emailPreference && emailPreference.length === 1) {
        emailPreference[0].isChecked = value;
      }
    }
    this.emailData.values = [];
    this.emailData.values = emailDataFinal;
  };

  @action cancel = () => {
    this.resetEmailData();
  };
  @action save = async () => {
    await ProducerStore.createAgentContactEmailUnsubscribe(
      this.emailData.values
    );
  };
  @action resetEmailData = () => {
    this.emailSubscriptions.values = [];
    this.emailData.values = [];
  };
}
