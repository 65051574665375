import React from 'react';
import { IMASProgressSpinner } from './controls/IMASProgressSpinner';


export function Loading() {
    return (  
       <>
        <IMASProgressSpinner />
       </>
    );
}
