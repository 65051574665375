import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import ContractsStore from "../../../../store/ContractsStore";
import Collection from "../../../../infrastructure/CollectionHelper";

export class LicensePersistencyListViewModel implements ViewModel  {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable PTList = new Collection<any>();
  @observable PTrows: number = 10;
  @observable PTfirst: number = 0;
  @observable PTstartIndex: number = 0;
  @observable pageTitle: string = "Persistency Types";
  @observable sortColumn: string = 'licensePersistencyTypeName';
  @observable sortOrder: boolean = true;
  @observable PTtotalRecords: number | undefined = 0;

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.PTstartIndex = 0;
    this.PTfirst = 0;
  }

  @action Load = async (companyId: any) => {
    var result = await ContractsStore.getAllLicensePersistencyTypes(companyId, 0, 1000, this.sortColumn, this.sortOrder)
    if(result && result.data){
      this.PTtotalRecords = result.recordCount;
      this.PTList.values = result.data
    }
    };

    @action reset() {
        this.PTtotalRecords = 0;
        this.PTList = new Collection<any>();
    }

  Route = async (currentRoute: RouteList): Promise<void> => { };
}