import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import { routes } from "../../../router";
import ContractsStore from "../../../store/ContractsStore";
import LeadStore from "../../../store/LeadStore";

import { BaseAddEditViewModel } from "../../components/BaseAddEditViewModel";

export class AgentLeadDisabledCarrierAddEditViewModel extends BaseAddEditViewModel {
  @observable selectedCarrier: string = "";
  @observable selectedCompany: string = "";



  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();


  constructor() {
    super("Agent Lead Disabled Carrier", routes.listAgentLeadDisabledCarrier, new AgentLeadDisabledCarrierAddEditValidator());
  }

  protected resetModel = async () => {

    this.selectedCarrier = "";
    await this.loadCompanies();
  };

  protected toServiceModel() {
    var dto: any = {
      carrierId: Number(this.selectedCarrier),
      companyName: this.selectedCompany
    };
    return dto;
  }

  @action setSelectedCompany = async(value : string) => {
    let name = this.companyList.values.filter(ind => ind.value === value)
    this.selectedCompany = name[0].label
  }

  protected async loadItem(id: number): Promise<void> {
    if (id === 0) {
      this.resetModel();
    } else {
      let item: any = [];
      if (item) {
      }
    }
  }

  protected async addItem(): Promise<void> {
    var item = this.toServiceModel();
    await LeadStore.addAgentLeadDisabledCarrier(item);
  }
  protected async updateItem(): Promise<void> {
    // var item = this.toServiceModel();
    // await ContractsStore.updateCarrier(item);
  }



  @action private loadCompanies = async () => {
    this.isLoading = true;
    // var defaultItem = { label: "All", value: "0" };
    var result = await ContractsStore.getAllCompanyLookup();

    if (result !== null) {
      this.companyList.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companyList.values.push(data);
        });
      // this.companyList.values.splice(0, 0, defaultItem);
      this.selectedCarrier = "";
    } else {
      this.companyList.values = [];
      // this.companyList.values.splice(0, 0, defaultItem);
      this.selectedCarrier = "";
    }

    this.isLoading = false;
  };



}

class AgentLeadDisabledCarrierAddEditValidator extends AbstractValidator<AgentLeadDisabledCarrierAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedCarrier)
      .isNotEmpty()
      .withFailureMessage("Carrier is required");
  }
}
