class IMASLog {

    generateMessage(message: any, level: string) {
        //let color = "";
        // switch (level) {
        //     case 'trace':
        //         color = 'lightblue' ;
        //         break
        //     case 'info':
        //         color = 'blue';
        //         break
        //     case 'warn':
        //         color = 'orange';
        //         break
        //     case 'error':
        //         color = 'red';
        //         break
        //     case 'log':
        //         color = 'blue';
        //         break
        //     default:
        //         color = 'black';
        //         break;

        // }
        //console.log("%c" + message, "color:" + color + ";");
        //console.log("color:" + color + ";");

    }

    trace(message: string) {
        console.trace(message)
    }
    info(message: string) {
        this.generateMessage(message, 'info')
    }
    warn(message: string) {
        console.warn(message)
    }
    error(message: string) {
        console.error(message)
    }
    log(value: string) {
        this.generateMessage(value, 'log')
    }
    logObject(comment: string, value: any)
    {
        this.generateMessage(comment+' : '+JSON.stringify(value), 'log')
    }

}

export default new IMASLog()