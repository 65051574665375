import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { Reports, ReportTabs } from "../../infrastructure/ReportModel";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import UserContext from "../../infrastructure/UserContext";
import { routes } from "../../router";
import { CustomReportsListViewModel } from "./customReports/CustomReportsListViewModel";
import { StandardReportsListViewModel } from "./standardReports/StandardReportsListViewModel";
import { ReportsQueueListViewModel } from "./queues/ReportsQueueListViewModel";

export class SelfServiceReportsListViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {
    this.handleRoute(currentRoute);
  };

  @observable isReportNotVisible: boolean = false;
  @observable selectedTabIndex?: number = 0;
  @observable selectedKey: string | undefined = "";
  @observable isSSR: boolean = false;
  @observable breadCrumbTitle: string | undefined = "";
  @observable StandardReportsListViewModel: StandardReportsListViewModel =
    new StandardReportsListViewModel();
  @observable CustomReportsListViewModel: CustomReportsListViewModel =
    new CustomReportsListViewModel();
  @observable ReportsQueueListViewModel: ReportsQueueListViewModel =
    new ReportsQueueListViewModel();
  @observable SelectedViewModel: keyof typeof ReportTabs =
    ReportTabs[ReportTabs.StandardReports];
  @observable SelectedBaseViewModel: ViewModel | undefined;
  @observable reportTabItems = [
    { label: "Standard", value: 0 },
    { label: "Custom", value: 1 },
    { label: "Queue", value: 2 },
  ];

  @action Load = async () => {
    if (
      window.location.href.indexOf("customReports") != -1 ||
      window.location.href.indexOf("reportsqueue") != -1
    ) {
      this.isSSR = true;
    } else {
      this.isSSR = false;
    }

    this.isReportNotVisible = false;
      if (
          !Utils.hasUserPermission(Permission.ViewStandardReports) &&
          Utils.hasUserPermission(Permission.ViewCustomReports)
      ) {
          this.handleTabClick(1, "Custom");
      }
      else if (Utils.hasUserPermission(Permission.ViewStandardReports) &&
          !Utils.hasUserPermission(Permission.ViewCustomReports)) {
          this.handleTabClick(0, "Standard");
      }
      else if (!Utils.hasUserPermission(Permission.ViewStandardReports) &&
          !Utils.hasUserPermission(Permission.ViewCustomReports)) {
          this.isReportNotVisible = true;
      }
        

    // if (
    //   Utils.hasUserPermission(Permission.ViewStandardReports) ||
    //   Utils.hasUserPermission(Permission.ViewCustomReports)
    // ) {
    //   this.isReportNotVisible = false;
    //   Utils.hasUserPermission(Permission.ViewCustomReports) &&
    //     this.handleTabClick(1, "Custom");
    // } else {
    //   this.isReportNotVisible = true;
    // }
  };

  @action reset = () => {
    this.isReportNotVisible = false;
  };

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    switch (key) {
      case "Standard":
        this.handleLinkClick(Reports.StandardReports);
        break;
      case "Custom":
        this.handleLinkClick(Reports.CustomReports);
        break;
      case "Report Queue":
        this.handleLinkClick(Reports.Queue);
        break;
    }
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case Reports.StandardReports:
          this.isSSR = false;
          routes.standardReports.push();
          return;
        case Reports.CustomReports:
          this.isSSR = true;
          routes.customReports.push();
          return;
        case Reports.Queue:
          this.isSSR = true;
          routes.reportsQueue.push();
          return;
        default:
          this.isSSR = false;
          //routes.standardReports.push();
          return;
      }
    }
  };

  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case Reports.StandardReports:
        tabIndex = 0;
        break;
      case Reports.CustomReports:
        tabIndex = 1;
        break;
      case Reports.Queue:
        tabIndex = 2;
        break;
      default:
        tabIndex = 0;
        break;
    }

    return tabIndex;
  }

  @computed get getBreadCrumbTitle(): string {
    let title = "Standard Reports";
    switch (this.selectedKey) {
      case Reports.StandardReports:
        title = "Standard Reports";
        break;
      case Reports.CustomReports:
        title = "Custom Reports";
        break;
      case Reports.Queue:
        title = "Report Queue";
        break;
      default:
        title = "Standard Reports";
        break;
    }
    return title;
  }

  @action handleRoute(currentRoute: RouteList) {
    const name = currentRoute.name;
    const routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    switch (name) {
      case routes.reports.name:
        this.selectedKey = ReportTabs.StandardReports;
        this.SelectedViewModel = ReportTabs.StandardReports;
        this.SelectedBaseViewModel = this.StandardReportsListViewModel;
        this.StandardReportsListViewModel.Load();
        break;
      case routes.standardReports.name:
        this.selectedKey = ReportTabs.StandardReports;
        this.SelectedViewModel = ReportTabs.StandardReports;
        this.SelectedBaseViewModel = this.StandardReportsListViewModel;
        this.StandardReportsListViewModel.Load();
        break;
      case routes.customReports.name:
        this.selectedKey = ReportTabs.CustomReports;
        this.SelectedViewModel = ReportTabs.CustomReports;
        this.SelectedBaseViewModel = this.CustomReportsListViewModel;
        this.CustomReportsListViewModel.Load();
        break;
      case routes.reportsQueue.name:
        this.selectedKey = ReportTabs.Queue;
        this.SelectedViewModel = ReportTabs.Queue;
        this.SelectedBaseViewModel = this.ReportsQueueListViewModel;
        this.ReportsQueueListViewModel.Load(0);
        break;
      case routes.reportsQueueDownload.name:
        this.selectedKey = ReportTabs.Queue;
        this.SelectedViewModel = ReportTabs.Queue;
        this.SelectedBaseViewModel = this.ReportsQueueListViewModel;
        this.ReportsQueueListViewModel.Load(routeParamObj.id);
        break;
    }
  }

  @action goToStandardReport = () => {
    routes.reports.replace();
  };
}
