import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { AgentListModel } from "../../services/ProducerSearchService";

export class CustomerLookupViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {
  };

  Route = async (currentRoute: RouteList): Promise<void> => { }
  @observable isLoading: boolean = false;
  @observable isDialogVisible: boolean = false;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable SelecetedAgent: AgentListModel | null = null
  @observable serachInputValue: string = ''
  @observable first: number = 0
  @computed
  get isAnyAgentSelected(): boolean {
    if (this.SelecetedAgent)
      return false
    return true
  }
  @action searchIntervals = (text: string) => {
    if (text) {
      this.serachInputValue = text;
    }

  }

  @action loadAgents = async () => {
    this.isLoading = true
    //this.AgentsList = await AgentsStore.getAgentList(this.serachInputValue)
    this.isLoading = false;
  }

  @action updateSelectedAgent = (value: AgentListModel) => {
    this.SelecetedAgent = value
  }

  @action onPage(firstIndex: number) {
    this.first = firstIndex;
  }

  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state)
      return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state)
      return agent.city;
    else if (!agent.city && agent.state)
      return agent.state;
    else
      return "";
  }

  @action setDialogVisibility(value: boolean) {
    this.isDialogVisible = value;
  }

}