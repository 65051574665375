export enum LeadTabs {
    Contracted = "Contracted",
    NonContracted = "Non-Contracted",
}
export enum MainLeadTabs {
    MarketerLeads = "Marketer Leads",
    SaleOpsLead = "SalesOps Leads",
}
export enum SalesLeadTabs {
    NonContracted = "Non-Contracted",
    Contracted = "Contracted",
    SaleOpsLeads = "SalesOps Leads",
    UnmatchedLeads = "Unmatched Leads"
}
