import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import ProducerStore from "../../../store/ProducerStore";

export class GeolocationDownloadViewModel implements ViewModel {

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { };

    @action Load = async (zipcode: string, carrier: string, distance: string) => {
        if (zipcode && carrier && distance)
            await this.downloadAgents(zipcode, carrier, distance);
    };

    @observable response: any;

    @action downloadAgents = async (zipcode: string, carrier: string, distance: string) => {
        this.response = {};
        try {
            let result = await ProducerStore.generateExcelAgentsByGeolocation(zipcode, carrier, distance);
            if (result != null) {
                if (result.data) {
                    this.downloadDocument(result.data, "AllAgentsFound.xlsx");
                }
            }
        } catch (e) {
            // this.response = e.response;
        }
    };



    @action downloadDocument = (base64: string, filename: string) => {
        setTimeout(() => {
            var link = document.createElement("a");
            link.download = `${filename}`;
            if (filename.indexOf(".xlsx") > 0) {
                link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64;
            }
            if (filename.indexOf(".pdf") > 0) {
                link.href = "data:application/pdf;base64," + base64;
            }

            link.textContent = "Download";
            link.click();
            document.body.appendChild(link);
            link.remove();
        }, 0);
    };
}
