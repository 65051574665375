export enum CustomerTabs {
    Summary = "summary",
    Notes = "notes",
    Policies = "policies",
    Tasks = "tasks",
    Docs = "docs",
}

export enum CustomerTabViewModelTypes {
    Summary = "Summary",
    Notes = "Notes",
    Policies = "Policies",
    ViewPolicy = "ViewPolicy",
    AddEditPolicy = 'AddEditPolicy',
    Tasks = "Tasks",
    Docs = "Docs",
    EditTask = "EditTask",
    AddDoc = "AddDoc",
}
export enum CustomerSummary {
    Customer = "Insured",
    Notes = "Notes",
}
export enum MergeRecordsTabs {
    Agent = "Agent",
    Insured = "Insured",
}

export enum MergeTabViewModelTypes {
    Agent = "Agent",
    Insured = "Insured",
}
export enum ProfileDetails {
    ProductProfile = "Product Profile",
    Referrals = "Referrals"
}

export enum ProfileCheckbox {
    Medicare = "Product Profile",
    Life = "Referrals",
    Health = 'Health',
    LongTermCare = 'Long-Term Care',
    Disability = 'Disability',
    FinalExpense = 'Final Expense',
    Annuity = 'Annuity',
    Group = 'Group',
    Property = 'Propery/Casualty',
    Securities = 'Securities'
}

export enum CustomerSearch {
    LastName = "1",
    Policy = "2",
    Ssn = "3",
    Hicn = "4",
    Mbi = "5",
    InsuredId = "6",
    Payor = "7",
    Phone = "8",
    Dob= '9'
}

export enum CustomerTitle {
    Add = "Add Insured",
    Edit = "Edit Insured"
}

export enum SsnValidation {
    first = '000',
    second = '00',
    thrid = '0000'
}

export enum CustomerTask {
    agent = "agent",
    High = "High",
    Medium = "Medium" 
}