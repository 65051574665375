import React from 'react';


type TableRowProps = {
    header: string,
    headerClass?: boolean,
    headerClassName?: string,
    value: any,
    styleName: string,
    comment: string | undefined
}

const IMASTableRow = (cp: TableRowProps) => {
    var headerClass = "";
    if (cp.headerClassName) {
        headerClass = cp.headerClassName;
    }
    return (
        <tr>
            <td className={cp.headerClass ? `hdr-label row-right ${headerClass}` : "hdr-label"} >{cp.header}</td>
            <td className={cp.styleName} >{cp.value} <span >{cp.comment}</span>  </td>

        </tr>


    );
};

export default IMASTableRow;

