import React from "react";
import { observer } from "mobx-react";
import { CustomInputTextArea } from "../components/controls/IMASInputTextArea";
import { IMASCancelSaveView } from "../components/controls/IMASCancelSaveView";
import { AddEmailsViewModel } from "../../viewModels/home/AddEmailsViewModel";
import { IMASFilePicker } from "../components/controls/IMASFilePicker";
export const AddEmailsView: React.FC<{
  vm: AddEmailsViewModel;
  eventListId: number | undefined;
}> = observer((props) => {

  const setFileData = (value: any, name: any) => {
    props.vm.onDrop(value, name);
  }

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          {props.vm.IsEmptyForm && (
            <div className="invalid-feedback">{`Please select atleast one option`}</div>
          )}
          {props.vm.isInvalidForm && (
            <div className="invalid-feedback">{`Please select one option at a time`}</div>
          )}
          {props.vm.isInvalidFile && (
            <div className="invalid-feedback">{`Please upload a valid file`}</div>
          )}
        </div>
        <div className="p-col-12">
          <div className="form-group">
            <CustomInputTextArea
              errors={undefined}
              id={"email"}
              isrequired={false}
              maxLength={undefined}
              label={"Enter comma separated emails"}
              value={props.vm.emailList}
              placeHolder=""
              onBlur={() => { }}
              onChange={(value) => props.vm.setEmailList(value)}
              rows={4}
            />
            {!props.vm.areEmailsValid && props.vm.emails.length > 0 && <div className="invalid-feedback">{`Please enter two or more valid emails`}</div>}
          </div>
        </div>
        <div className="p-col-12">
        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-4">
          <div className="form-group" style={{ textAlign: "center" }}>
            <label><b>Or</b></label>

          </div>
          </div>
          <div className="p-col-4"></div>
        </div>
        </div>
        <div className="p-col-12">
          <div className="form-group">
            <label>Browse For File (comma delimited CSV, XLS, XLSX)</label>
            <IMASFilePicker
              onDrop={(value: string, name: string) => setFileData(value, name)}
              acceptFiles={['.xls','.xlsx','.csv']}
            />

          </div>
        </div>
        <div className="p-col-12">
          <IMASCancelSaveView
            onSave={() => props.vm.save(props.eventListId)}
            onCancel={() => props.vm.cancel()}
             disabled={props.vm.saveButtonDisabled}
          />
        </div>
      </div>
    </>
  );
});
