import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import ProducerStore from "../../store/ProducerStore";
import { ContactSMSBodyModel, EmailCommunicationListModel } from "../../services/ProducerService";
import { AgentSummary } from "../../infrastructure/enum/AgentDetails";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { AgentSMSListener, AppInlineProfileViewModel } from "../AppInlineProfileViewModel";
import UserContext from "../../infrastructure/UserContext";
import { googleTagManager } from "../../infrastructure/tracking";

export class AgentEmailViewModel implements ViewModel, AgentSMSListener {
    updateSmsHistorySearch(): void {
        var smsContactId = localStorage.getItem("smsContactId");
        if(smsContactId){
            this.setPhone(""+smsContactId);
            localStorage.removeItem("smsContactId");
        }
        this.loadSmsHistory(this.totalSmsCount + 100);
    }
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable emailList = new Collection<EmailCommunicationListModel>();
  @observable sortColumn: string = 'createdDate'
  @observable sortOrder: boolean = false;
  @observable startIndex: number = 0;
  @observable first: number = 0;
  @observable isException: boolean = false;
  @observable rows: number = 10;
  @observable totalRecords: number = 0;
  @observable isLoading: boolean = false;
  @observable searchInputValue: string = "";
  @observable exceptionMessage: any;
  @observable searchValue: string = '';
  @observable attachment: string = '';
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable agentId: number = 0
  @observable selectedPanel: string = AgentSummary.Email;
  @observable selectedTabIndex?: number = 0;
  @observable isCollapsed: boolean = true;
  @observable sms: string = "";
  @observable messageList = new Collection<ContactSMSBodyModel>();
  @observable phone: string = "";
  @observable AppInlineProfileViewModel: AppInlineProfileViewModel | undefined;
  @action setAppInlineProfileViewModel(viewModel: AppInlineProfileViewModel | undefined)
  {
    this.AppInlineProfileViewModel = viewModel;
  }
    @observable agentName: string = "";
    @action Load = async (id: number, name: string) => {
        if(this.AppInlineProfileViewModel){
            this.AppInlineProfileViewModel.setSMSListener(this);
        }
        if(name){
            var fullName = name.split(" "); 
            // this.agentName = fullName.length === 2 ? (fullName[1] ? (fullName[1]+", "+fullName[0]) : fullName[0]) : (fullName[2] ? (fullName[2]+", "+fullName[0]) : fullName[0]);
            this.agentName = name;
        }
        if (RouteParamDetails.tabId == undefined) {
            if (Utils.hasUserPermission(Permission.ViewAgentEmail)) {
                this.selectedPanel = AgentSummary.Email;
                this.selectedTabIndex = 0;
                this.agentId = id
                await this.loadEmails()
            }
            else {
                if (Utils.hasUserPermission(Permission.ViewSmsMessages) ||
                    Utils.hasUserPermission(Permission.SendSmsMessages)) {
                    this.selectedPanel = AgentSummary.SMSMessages;
                    this.selectedTabIndex = 1;
                    this.agentId = id;
                    await this.loadContactSms();
                }
            }
        }
        if (RouteParamDetails.tabId == 1) {
            this.selectedPanel = AgentSummary.SMSMessages;
            this.selectedTabIndex = 1;
            this.agentId = id;
            await this.loadContactSms();
        }
    };

    @observable agencyTabItems = [
        { label: AgentSummary.Email, value: 0 },
        { label: AgentSummary.SMSMessages, value: 1 },
    ];

    @observable phoneList = new Collection<{
        label: string;
        value: string;
      }>();

    @action loadContactSms = async() => {
        var result = await ProducerStore.getContactSMS(this.agentId);
        if (result){
            this.phoneList.values = [];
            Utils.mapListItemsToDropdown(result, this.phoneList, '', '')
            
            var smsContactId = localStorage.getItem("smsContactId");
            if(smsContactId){
                this.setPhone(""+smsContactId);
                localStorage.removeItem("smsContactId");
            } else {
                this.phone = this.phoneList.values.length > 0 ? this.phoneList.values[0].value : "";
            }
            await this.loadSmsHistory(this.totalSmsCount);
        } else {
            this.phoneList.values = [];
            this.messageList.values = [];
        }
    }

    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action setSelectedPanel(value: string) {
        this.selectedPanel = value;
    }
    @action setIsCollapsed(value: boolean) {
        this.isCollapsed = value;
    }
    @action handleTabClick = async(index: number | undefined, key: string) => {
        this.selectedTabIndex = index;
        this.isLoading = false;
        this.isMessgeVisible = false;
        await this.setSelectedPanel(key);
        this.isMessgeVisible = false;
    };
    @action loadTabsData = async (tab: string) => {
        switch (tab) {
            case AgentSummary.Email:
                await this.loadEmails();
                break;
            case AgentSummary.SMSMessages:
                await this.loadContactSms();
                break;
        }
    };

    @action searchChange(value: string) {
        this.searchInputValue = value;
        this.searchValue = value;
    }
    @action goSearch() {
        this.sortColumn = "createdDate"
        this.sortOrder = true
        this.loadEmails();
    }
    @action resetFilters = async() => {
        this.searchValue = "";
        this.searchInputValue = "";
        await this.resetPagingValues();
        await this.loadEmails();
        await this.setTotalSmsCount(100);
    }

    @action setTotalSmsCount = async(value: number) => {
        this.totalSmsCount = value;
    }
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadEmails();
    }
    @action resetPagingValues() {
        this.rows = 10;
        this.totalRecords = 0;
        this.sortOrder = false;
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = "createdDate";
    }
    @action loadEmails = async () => {
        this.isException = false;
        try {
            let filter = this.searchValue ? this.searchValue.trim(): ''
            let result = await ProducerStore.getAllEmailCommunication(this.agentId, this.startIndex, this.rows, this.sortColumn, this.sortOrder, filter);
            if (result !== null) {
                if (result.recordCount) {
                    this.totalRecords = result.recordCount;
                    if (result.data && result.data.length > 0) this.emailList.values = result.data;

                } else {
                    this.noRecordsFound();
                }
            } else {
                this.noRecordsFound();
            }
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
        }
    };

    @action noRecordsFound() {
        this.totalRecords = 0;
        this.emailList.values = [];
    }

    @action downloadDocumentLink = async (attachmentFileGuid:string, filename: string) => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        try {
            let result = await ProducerStore.downloadEmailCommunicationAttachment(attachmentFileGuid)
            if (result != null) {
                if (result.data) {
                    this.downloadDocument(result.data, filename ? filename : "");
                } else {
                    if (result.message && result.message !== null) {
                        var responseModel = {
                            status: 400,
                            title: `${filename} no longer exists`,
                            errors: { "": [] },
                        };
                        this.response = JSON.stringify(responseModel);
                        window.scrollTo(0,0);
                        this.isMessgeVisible = true;
                        setTimeout(() => {
                            this.isMessgeVisible = false
                            this.response = ''
                        },8000)
                    }
                }
            }
        } catch (e: any) {
            this.isMessgeVisible = true;
            this.response = e.response;
            window.scrollTo(0,0);
        }
        this.isLoading = false;
    };

    @action downloadDocument = (base64: string, filename: string) => {
        setTimeout(() => {
            var link = document.createElement("a");
            link.download = `${filename}`;
            if ((filename.indexOf(".jpg") > 0) || (filename.indexOf(".jpeg") > 0)) {
                link.href = "data:image/jpeg;base64;" + base64;
            }
            else if (filename.indexOf(".gif") > 0) {
                link.href = "data:image/gif;base64," + base64;
            }
            else if (filename.indexOf(".png") > 0) {
                link.href = "data:image/png;base64," + base64;
            }
            else if (filename.indexOf(".svg") > 0) {
                link.href = "data:image/svg+xml;base64," + base64;
            }
            else if(filename.indexOf(".ico") > 0){
                link.href = "data:image/x-icon;base64," + base64;
            }
            else if (filename.indexOf(".pdf") > 0) {
                link.href = "data:application/pdf;base64," + base64;
            }
            else if (filename.indexOf(".xlsx") > 0) {
                link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64;
            }
            else if (filename.indexOf(".docx") > 0) {
                link.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + base64;
            }
            else{
                link.href = "data:text/plain;base64," + base64;
            }

            link.textContent = "Download";
            link.click();
            document.body.appendChild(link);
            link.remove();
        }, 0);
    };

    @action setSMS = async(value: string) => {
        this.sms = value;
    }

    @action sendSMS = async() => {
        try{
            googleTagManager.trackAction("sms_sent",
                    {
                        feature: "Agent",
                        agentId: this.agentId,
                        user_id: UserContext.userId,
                        sms_number: this.phone
                    });
            var dto: any = {
                contactId: +this.phone,
                body: this.sms,
                agentId: this.agentId
            }
            let checkSmsSpace = this.sms ? this.sms.trim() : ""
            if(this.sms && this.phone && checkSmsSpace.length > 0){
                await ProducerStore.sendSms(dto);                
            this.sms = '';
            }
        } catch(e) {

        }
    }

    @observable totalSmsCount: number = 100;
    @observable showLoadMore: boolean = false;
    @observable smsNotifications: any[] = [];

    @action loadSmsHistory = async(noOfMessages: number) => {
        var result = await ProducerStore.getContactSMSBody(this.agentId, +this.phone, noOfMessages)
        if(result && result.contactSMSMessageGroupModels && result.contactSMSMessageGroupModels.length > 0){
            if (result.totalCount) {
                this.totalSmsCount = result.recordCount || 0;
                this.showLoadMore = result.totalCount > this.totalSmsCount
            }
            this.smsNotifications = result.contactSMSMessageGroupModels;
        } else {
            this.smsNotifications =  [];
        }
        let scroll_to_bottom = document.getElementById('sms-list-div');
        if(scroll_to_bottom){
            scroll_to_bottom.scroll(0, scroll_to_bottom.scrollHeight);
        }
    }

    @action setPhone = async(value: string) => {
        this.phone = value;
        this.showLoadMore = false;
        await this.setTotalSmsCount(100);
        await this.setSMS("");
        await this.loadSmsHistory(this.totalSmsCount);
    }

}