import { AbstractValidator } from 'fluent-ts-validator';
import { observable } from "mobx";
import { routes } from "../../../../router";
import BusinessUnitConfigStore from '../../../../store/BusinessUnitConfigStore';
import { BaseAddEditViewModel } from '../../../components/BaseAddEditViewModel';

export class AgentGroupAddEditViewModel extends BaseAddEditViewModel {

    @observable GroupName: string | undefined;
    @observable CreatedBy: number | 0 = 0;
    constructor() {
        super("AgentGroup", routes.listagentGroup, new AgentGroupValidator());
    }

    protected toServiceModel() {
        return {
            id: this.selectedId,
            groupName: this.GroupName,
            createdby: this.CreatedBy,
        };
    }

    protected resetModel() {
        this.GroupName = "";
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await BusinessUnitConfigStore.getAgentGroupDetail(id);
        if (item) {
            this.selectedId = item.id || 0;
            this.GroupName = item.groupName || "";
            this.CreatedBy = item.createdUserId || 0;
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await BusinessUnitConfigStore.addAgentGroup(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await BusinessUnitConfigStore.updateAgentGroup(item);
    }
}

class AgentGroupValidator extends AbstractValidator<AgentGroupAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.GroupName)
            .matches(/\S+/, "i")
            .hasMaxLength(255)
            .isNotEmpty()
            .withFailureMessage("Group Name is required and should be less than 255 characters");
    }
}
