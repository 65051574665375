import { routes } from "../../../../router";
import { action, computed, observable } from "mobx";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import ContractsStore from "../../../../store/ContractsStore"
import { ContractRequest, ContractRequestModel, ContractSummaryListView, SaveSplittLicenseRequest } from "../../../../services/ContractService";
import Collection from "../../../../infrastructure/CollectionHelper"
import Utils from "../../../../infrastructure/Utils";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import ProducerSearchStore from "../../../../store/ProducerSearchStore";
import { AgentListModel } from "../../../../services/ProducerSearchService";
import ProducerStore from "../../../../store/ProducerStore";
import { LicenseDashboardViewModel } from "../licenseDashboard/LicenseDashboardViewModel";

export class LicenseSplitterViewModel implements ViewModel {

    constructor(private OnSave?: (BankAccountID: number) => void) { }

    @observable isException: boolean = false;
    @observable isSuccess: boolean = false;
    @observable isWarningDisplayed: boolean = false;
    @observable isLoading: boolean = false;
    @observable operationList = [
        { label: 'Select Operation', value: '0' },
        { label: 'Move Contract', value: '1' },
        //{ label: 'Split License Based On AWN', value: '2' },
        { label: 'Move Contract Downlines', value: '3' },
        //{ label: 'Move IsLocation Data', value: '4' }
    ]
    
    @observable readonly pageTitle: string = "Contract Splitter";
    @observable rowsFrom: number = 200;
    @observable startIndexFrom: number = 0;
    @observable totalRecordsFrom: number = 0;
    @observable firstFrom: number = 0;
    @observable sortColumnFrom: string = "Carrier";
    @observable sortAscendingFrom: boolean = true;

    @observable rowsTo: number = 200;
    @observable startIndexTo: number = 0;
    @observable totalRecordsTo: number = 0;
    @observable firstTo: number = 0;
    @observable sortColumnTo: string = "Carrier";
    @observable sortAscendingTo: boolean = true;

    @observable response: any;
    @observable isMessageVisible: boolean = false;
    @observable contractListFrom = new Collection<ContractSummaryListView>()
    @observable contractListTo = new Collection<ContractSummaryListView>()

    //#region Properities
    @observable FromAgent: string = "";
    @observable FromAgentId: number = 0;
    @observable FromIsAgency: boolean = false;
    @observable ToAgent: string = "";
    @observable ToAgentId: number = 0;
    @observable ToIsAgency: boolean = false;

    @observable LoadedFromAgent: string = "";
    @observable LoadedFromAgentId: number = 0;
    @observable LoadedToAgent: string = "";
    @observable LoadedToAgentId: number = 0;

    @observable MoveLocationFromAgent: string = "";
    @observable MoveLocationFromAgentId: number = 0;
    @observable MoveLocationToAgent: string = "";
    @observable MoveLocationToAgentId: number = 0;
    @observable CloneAgent: string = "";
    @observable CloneAgentId: number = 0;
    @observable filteredFromAgentsList = new Collection<any>();
    @observable filteredToAgentsList = new Collection<any>();
    @observable filteredMapLocationFromAgentsList = new Collection<AgentListModel>();
    @observable filteredMapLocationToAgentsList = new Collection<AgentListModel>();
    @observable filteredCloneAgentsList = new Collection<AgentListModel>();
    @observable Operation: number | undefined;
    @observable selectedOperation: string = "";
    @observable searchParamFrom: string = "";
    @observable searchParamTo: string = "";
    @observable selectedFromLicenseId: number = 0;
    @observable selectedFromLicenseRow: any;
    @observable licenseDashboardViewModel = new LicenseDashboardViewModel();
    @observable selectedToLicenseId: number = 0;
    @observable selectedToLicenseRow: any;

    @action resetFields = () => {
        this.rowsFrom = 200;
        this.startIndexFrom = 0;
        this.totalRecordsFrom = 0;
        this.firstFrom = 0;
        this.sortColumnFrom = "Carrier";
        this.sortAscendingFrom = true;
        this.rowsTo = 200;
        this.startIndexTo = 0;
        this.totalRecordsTo = 0;
        this.firstTo = 0;
        this.sortColumnTo = "Carrier";
        this.sortAscendingTo = true;
        this.selectedOperation = "";
        this.searchParamFrom = "";
        this.searchParamTo = "";
        this.selectedFromLicenseId = 0;
        this.selectedFromLicenseRow = null;
        this.selectedToLicenseId = 0;
        this.selectedToLicenseRow = null;
        this.selectedFromStatus = "1";
        this.selectedToStatus = "1";
        this.serachFromInputValue = '';
        this.serachToInputValue = '';
    }

    @action onContractFromSelectionChange = (value: any, e: any) => {
        if (value) {
            this.selectedFromLicenseRow = value;
            var obj = JSON.parse(JSON.stringify(value));
            this.selectedFromLicenseId = parseInt(obj.id);
        }
    };

    @action onContractToSelectionChange = (value: any, e: any) => {
        if (value) {
            this.selectedToLicenseRow = value;
            var obj = JSON.parse(JSON.stringify(value));
            this.selectedToLicenseId = parseInt(obj.id);
        }
    };

    @action fillterUsers = async (value: string, type: any) => {
        var results = await ProducerSearchStore.getAgentsLookup(1, value, 0, 10, undefined, false);
        if (results !== null) {
            if (results.recordCount) {
                if (results.data) {
                    this.setFilteredAgentList(results.data, type);
                } else {
                    this.setFilteredAgentList([{ "name": "" }], type);
                }
            } else {
                this.setFilteredAgentList([{ "name": "" }], type);
            }
        } else {
            this.setFilteredAgentList([{ "name": "" }], type);
        }
    }
    @action setFilteredAgentList(value: any, type: any) {
        switch (type) {
            case LicenseSplitterTypeConstants.FromAgent:
                this.filteredFromAgentsList.values = value;
                break;
            case LicenseSplitterTypeConstants.ToAgent:
                this.filteredToAgentsList.values = value;
                break;
            case LicenseSplitterTypeConstants.MoveLocationFromAgent:
                this.filteredMapLocationFromAgentsList.values = value;
                break;
            case LicenseSplitterTypeConstants.MoveLocationToAgent:
                this.filteredMapLocationToAgentsList.values = value;
                break;
            case LicenseSplitterTypeConstants.CloneAgent:
                this.filteredCloneAgentsList.values = value;
                break;
        }
    }
    @action onSelectAgent = (value: string, type: any) => {
        let obj = Utils.getObject(value);
        if (obj.id) {
            this.setAgentOnSelect(type, obj.id, obj.name);
        } else {
            this.setAgentOnSelect(type, 0, '');
        }
    }
    @action setAgentOnSelect(type: any, id: number, name: string) {
        switch (type) {
            case LicenseSplitterTypeConstants.FromAgent:
                this.FromAgent = name;
                this.FromAgentId = name ? id : 0;
                if(this.FromAgentId==0){
                    this.LoadedFromAgent="";
                    this.LoadedFromAgentId=0;
                    this.FromIsAgency=false;
                    this.noRecordsFoundFrom();
                }
                break;
            case LicenseSplitterTypeConstants.ToAgent:
                this.ToAgentId = name ? id : 0;
                this.ToAgent = name;
                if(this.ToAgentId==0){
                    this.LoadedToAgent="";
                    this.LoadedToAgentId=0;
                    this.ToIsAgency=false;
                    this.noRecordsFoundTo();
                }
                break;
            case LicenseSplitterTypeConstants.MoveLocationFromAgent:
                this.MoveLocationFromAgentId = name ? id : 0;
                this.MoveLocationFromAgent = name;
                break;
            case LicenseSplitterTypeConstants.MoveLocationToAgent:
                this.MoveLocationToAgentId = name ? id : 0;
                this.MoveLocationToAgent = name;
                break;
            case LicenseSplitterTypeConstants.CloneAgent:
                this.CloneAgentId = name ? id : 0;
                this.CloneAgent = name;
                break;
        }
    }

    @computed
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    @computed
    get IsLoaded(): boolean {
        return !this.loading;
    }
    @computed
    get IsLoading(): boolean {
        return this.loading;
    }
    @action backToAdmin = async () => {
        routes.administration.push();
    }

    Close = (): void => {
        routes.listCompanyLicenseNumberDefaults.push();
    }
    public Load = async (): Promise<void> => {
        this.resetFields();
        this.loading = false;
        this.licenseDashboardViewModel.Load();
        this.loadAllLookupList();
    };
    @action noRecordsFoundFrom() {
        this.totalRecordsFrom = 0;
        this.contractListFrom.values = [];
    }
    @action noRecordsFoundTo() {
        this.totalRecordsTo = 0;
        this.contractListTo.values = [];
    }
    @action loadAgents = async () => {
        this.actionType = LicenseSplitterActionTypeConstants.LoadAgent;
        if(this.ToAgentId==0){
               this.LoadedToAgent="";
               this.LoadedToAgentId=0;
               this.ToIsAgency=false;
               this.noRecordsFoundTo();
           }
           if(this.FromAgentId==0){
                this.LoadedFromAgent="";
               this.LoadedFromAgentId=0;
               this.FromIsAgency=false;
                this.noRecordsFoundFrom();
           }
        await this.ResetValidate();
        if (!await this.Validate()) {
            this.resetFields();
            this.isLoading = true;
            this.isMessageVisible = false;
            this.response = "";
            if (this.FromAgentId > 0) {
                this.loadContracts('From');
                var fromResult = await ProducerStore.getAgentSummary(+this.FromAgentId);
                this.FromIsAgency = fromResult && fromResult.isAgency ? true : false;
            } else {
                this.contractListFrom.values = [];
            }

            if (this.ToAgentId > 0) {
                this.loadContracts('To');
                var toResult = await ProducerStore.getAgentSummary(+this.ToAgentId);
                this.ToIsAgency = toResult && toResult.isAgency ? true : false;
            } else {
                this.contractListTo.values = [];
            }
        }
    }

    @observable fromStatus = new Collection<{
        key: number;
        label: string;
        value: string;
    }>();

    @observable selectedFromStatus: string = "1";
    @observable toStatus = new Collection<{
        key: number;
        label: string;
        value: string;
    }>();

    @observable selectedToStatus: string = "1";
    @observable serachFromInputValue: string = '';
    @observable serachToInputValue: string = '';
    @observable actionType: string = LicenseSplitterActionTypeConstants.LoadAgent;

    @action loadAllLookupList = async () => {
        var data = [
            { text: "Active & Pending", value: "1" },
            { text: "Terminated", value: "2" },
            { text: "Pre-Contracting", value: "4" }
        ];
        this.loadstatusList(data);
    };

    @action loadstatusList = async (data?: any[]) => {
        this.fromStatus.values = [];
        this.toStatus.values = [];
        if (data != null) {
            let key = 1;
            data.forEach((obj) => {
                var dataO = {
                    key: key,
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value : "",
                };
                key++;
                this.fromStatus.values.push(dataO);
                this.toStatus.values.push(dataO);
            })
        }
        this.fromStatus.values.splice(0, 0, { key: 0, label: "All", value: "0" });
        this.toStatus.values.splice(0, 0, { key: 0, label: "All", value: "0" });
        this.selectedFromStatus = "1";
        this.selectedToStatus = "1";
    }

    @action searchFromIntervals = (text: string) => {
        this.serachFromInputValue = text;
        if (text) {
            this.searchParamFrom = text;
        } else {
            this.searchParamFrom = "";
            this.startIndexFrom = 0;
            this.firstFrom = 0;
            this.loadContracts('From');
        }
    };

    @action searchToIntervals = (text: string) => {
        this.serachToInputValue = text;
        if (text) {
            this.searchParamTo = text;
        } else {
            this.searchParamTo = "";
            this.startIndexTo = 0;
            this.firstTo = 0;
            this.loadContracts('To');
        }
    };

    @action loadContracts = async (type: any) => {
        try {
            this.isLoading = true;
            if (type === "From") {
                this.LoadedFromAgent = this.FromAgent;
                this.LoadedFromAgentId = this.FromAgentId;
                this.LoadedToAgent = this.ToAgent;
                this.LoadedToAgentId = this.ToAgentId;
                this.contractListFrom.values = [];
                var model: ContractRequestModel = {
                    agentId: this.FromAgentId,
                    policyStatusId: +this.selectedFromStatus,
                    searchParameter: "" + this.searchParamFrom,
                    marketerId: undefined,
                    divisionId: undefined,        
                    carrierIds: undefined,
                    pageIndex:  this.startIndexFrom,
                    pageSize: this.rowsFrom,
                    sortColumn: this.sortColumnFrom,
                    sortAscending: this.sortAscendingFrom,
                    isDownloadCall :false,
                    fullTextFilter: undefined
                  };

                var agentContracts = await ContractsStore.getContracts(model);
                if (agentContracts && agentContracts.data) {
                    this.totalRecordsFrom = agentContracts.recordCount
                        ? agentContracts.recordCount
                        : 0;
                    this.contractListFrom.values = agentContracts.data;
                }
            } else {
                this.contractListTo.values = [];
                var model: ContractRequestModel = {
                    agentId:  this.ToAgentId,
                    policyStatusId:  +this.selectedToStatus,
                    searchParameter: "" + this.searchParamTo,
                    marketerId: undefined,
                    divisionId: undefined,        
                    carrierIds: undefined,
                    pageIndex:  this.startIndexTo,
                    pageSize:  this.rowsTo,
                    sortColumn:this.sortColumnTo,
                    sortAscending: this.sortAscendingTo,
                    isDownloadCall :false,
                    fullTextFilter: undefined
                  };
                var agentContractsTo = await ContractsStore.getContracts(model) ;

                if (agentContractsTo && agentContractsTo.data) {
                    this.totalRecordsTo = agentContractsTo.recordCount
                        ? agentContractsTo.recordCount
                        : 0;
                    this.contractListTo.values = agentContractsTo.data;
                }
            }
            this.isLoading = false;
        } catch (e) {
            //IMASLog.log("exception: " + e.message);
            this.isLoading = false;
            this.isMessageVisible = true;
            this.response = e.response;
        }
    };
    @action resetFilters = async (type: any) => {
        if (type === "From") {
            this.searchParamFrom = "";
            this.startIndexFrom = 0;
            this.firstFrom = 0;
            this.selectedFromStatus = '1';
            this.serachFromInputValue = '';
            this.loadContracts(type);
        } else {
            this.searchParamTo = "";
            this.startIndexTo = 0;
            this.firstTo = 0;
            this.selectedToStatus = '1';
            this.serachToInputValue = '';
            this.loadContracts(type);
        }
    }
    @action setSortOrderFrom() {
        this.sortAscendingFrom = !this.sortAscendingFrom;
        this.startIndexFrom = 0;
        this.firstFrom = 0;
    }
    @action setSortColumnFrom(column: string) {
        this.sortColumnFrom = column;
    }
    @action setSortOrderTo() {
        this.sortAscendingTo = !this.sortAscendingTo;
        this.startIndexTo = 0;
        this.firstTo = 0;
    }
    @action setSortColumnTo(column: string) {
        this.sortColumnTo = column;
    }
    @action onPageFrom(firstIndex: number, rows: number) {
        this.rowsFrom = rows;
        this.firstFrom = firstIndex;
        this.startIndexFrom = firstIndex / this.rowsFrom;
        this.loadContracts('From');
    }
    @action onPageTo(firstIndex: number, rows: number) {
        this.rowsTo = rows;
        this.firstTo = firstIndex;
        this.startIndexTo = firstIndex / this.rowsTo;
        this.loadContracts('To');
    }

    @action loaded = () => {
        this.loading = false;
    };
    @action validateOperation() {
        if (this.selectedFromLicenseId > 0) {
            if (this.selectedOperation === "3" && this.selectedToLicenseId === 0) {
                var tResponse = {
                    status: 402,
                    title: '',
                    errors: { "": ['Please select To Agent contract'] },
                };
                this.response = JSON.stringify(tResponse);
                this.setIsSuccess(true);
                this.isLoading = false;
                window.scrollTo(0, 0);
                setTimeout(() => {
                    this.setIsSuccess(false);
                }, 3000);
                return false;
            } else {
                return true;
            }

        } else {
            var vResponse = {
                status: 402,
                title: '',
                errors: { "": ['Please select From Agent contract'] },
            };
            this.response = JSON.stringify(vResponse);
            this.setIsSuccess(true);
            this.isLoading = false;
            window.scrollTo(0, 0);
            setTimeout(() => {
                this.setIsSuccess(false);
            }, 3000);
            return false;
        }       
    }
    @action executeOperation = async () => {
        if (this.validateOperation()) {
            this.isException = false;
            this.isSuccess = false;
            this.isLoading = true;
            try {
                let request = {
                    fromLicenseID: this.selectedFromLicenseId,
                    toLicenseID: this.selectedToLicenseId,
                    toAgentID: this.LoadedToAgentId,
                    operationCommand: parseInt(this.selectedOperation)
                } as SaveSplittLicenseRequest;
                var response = await ContractsStore.moveLicense(request);
                if (response && response.succeded) {
                    this.loadContracts('From');
                    this.loadContracts('To');
                    var successResponse = {
                        status: 200,
                        title: response.errorMessage,
                        errors: { "": [] },
                    };
                    this.response = JSON.stringify(successResponse);
                } else {
                    var wResponse = {
                        status: 402,
                        title: '',
                        errors: { "": [response.errorMessage] },
                    };
                    this.response = JSON.stringify(wResponse);
                }
                this.selectedFromLicenseRow = null;
                this.selectedFromLicenseId = 0;
                this.selectedToLicenseRow = null;
                this.selectedToLicenseId = 0;
                this.setIsSuccess(true);
                this.isLoading = false;
                window.scrollTo(0, 0);
                setTimeout(() => {
                    this.setIsSuccess(false);
                }, 3000);
            } catch (error) {
                this.isLoading = false;
                this.isException = true;
            }
        }
    }

    @action moveLocation = async () => {
        // this.actionType = LicenseSplitterActionTypeConstants.MoveLocation;
        // await this.ResetValidate();
        // if (!await this.Validate()) {
        //     this.isException = false;
        //     this.isSuccess = false;
        //     this.isLoading = true;
        //     try {
        //         let request = {
        //             fromAgentId: this.MoveLocationFromAgentId,
        //             toAgentId: this.MoveLocationToAgentId
        //         } as MoveIsLocationDataRequest;
        //         var response = await ContractsStore.moveLocation(request);
        //         if (response && response.succeded) {
        //             this.loadContracts('From');
        //             this.loadContracts('To');
        //             var successResponse = {
        //                 status: 200,
        //                 title: response.message,
        //                 errors: { "": [] },
        //             };
        //             this.response = JSON.stringify(successResponse);
        //         } else {
        //             var wresponse = {
        //                 status: 402,
        //                 title: response.message,
        //                 errors: { "": [] },
        //             };
        //             this.response = JSON.stringify(wresponse);
        //         }
        //         this.setIsSuccess(true);
        //         this.isLoading = false;
        //         window.scrollTo(0, 0);
        //         setTimeout(() => {
        //             this.setIsSuccess(false);
        //             this.Cancel();
        //         }, 3000);
        //     } catch (error) {
        //         this.isLoading = false;
        //         this.isException = true;
        //     }
        // }
    }

    @action cloneAgentOperation = async () => {
        // this.actionType = LicenseSplitterActionTypeConstants.CloneAgent;
        // await this.ResetValidate();
        // if (!await this.Validate()) {
        //     this.isException = false;
        //     this.isSuccess = false;
        //     this.isLoading = true;
        //     try {
        //         var response = await ContractsStore.cloneAgent(this.CloneAgentId);
        //         if (response && response.succeded) {
        //             this.loadContracts('From');
        //             this.loadContracts('To');
        //             var successResponse = {
        //                 status: 200,
        //                 title: response.errorMessage,
        //                 errors: { "": [] },
        //             };
        //             window.scrollTo(0, 0);
        //             this.response = JSON.stringify(successResponse);
        //         } else {
        //             var wresponse = {
        //                 status: 402,
        //                 title: response && response.errorMessage,
        //                 errors: { "": [] },
        //             };
        //             this.response = JSON.stringify(wresponse);
        //         }
        //         this.setIsSuccess(true);
        //         this.isLoading = false;
        //         window.scrollTo(0, 0);
        //         setTimeout(() => {
        //             this.setIsSuccess(false);
        //             this.Cancel();
        //         }, 3000);
        //     } catch (error) {
        //         this.isLoading = false;
        //         this.isException = true;
        //     }
        // }
    }

    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
        this.isMessageVisible = value;
    }
    public Cancel = async () => {
        this.isSuccess = false;
    };
    Route = async (currentRoute: RouteList): Promise<void> => { }

    private onClose?: () => void;
    @observable
    private loading = true;

    @observable ErrorModel = new ErrorModel(new LicenSplitterValidator());

    @action Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
}

export const LicenseSplitterTypeConstants = {
    FromAgent: 'FromAgent',
    ToAgent: 'ToAgent',
    MoveLocationFromAgent: 'MoveLocationFromAgent',
    MoveLocationToAgent: 'MoveLocationToAgent',
    CloneAgent: 'CloneAgent'
}
export const LicenseSplitterActionTypeConstants = {
    LoadAgent: 'LoadAgent',
    MoveLocation: 'MoveLocation',
    CloneAgent: 'CloneAgent',
    OperationExecute: 'OperationExecute',
}

class LicenSplitterValidator extends AbstractValidator<LicenseSplitterViewModel>{
    public constructor() {
        super();

        this.validateIfNumber(input => input.MoveLocationFromAgentId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("From Agent is required")
            .when(input => input.actionType === LicenseSplitterActionTypeConstants.MoveLocation);

        this.validateIfNumber(input => input.MoveLocationToAgentId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("To Agent is required")
            .when(input => input.actionType === LicenseSplitterActionTypeConstants.MoveLocation);

        this.validateIfNumber(input => input.CloneAgentId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("Clone Agent is required")
            .when(input => input.actionType === LicenseSplitterActionTypeConstants.CloneAgent);

        this.validateIfNumber(input => input.FromAgentId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("From Agent is required")
            .when(input => input.actionType === LicenseSplitterActionTypeConstants.LoadAgent && input.FromAgentId === 0);

        this.validateIfNumber(input => input.ToAgentId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("To Agent is required")
            .when(input => input.actionType === LicenseSplitterActionTypeConstants.LoadAgent && input.ToAgentId === 0);

    }
}
