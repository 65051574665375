import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../infrastructure/RouteList";
import IMASLog from "../../../../infrastructure/IMASLog";
import { routes } from "../../../../router";
import DocumentStore from "../../../../store/DocumentStore";
import { Constants } from "../../../../infrastructure/enum/Constants";

export class PacketViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  private isLookupsLoaded: boolean = false;
  @action Load = async () => {
    this.sortColumnOrder = "id";
    this.sortOrder = false;
    this.first = 0;
    this.startIndex = 0;
    if (!this.isLookupsLoaded) {
      // We will load lookups only once, if there are any dependent lookups then sub-class has to handle that
      await this.loadLookup();
      this.isLookupsLoaded = true;
    }
    await this.loadPackets();
    this.title = "Document Packets";
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable rows: number = 200;
  @observable title: string = "";
  @observable totalRecords: number = 100;
  @observable first: number = 0;
  @observable isLoading: boolean = false;
  @observable startIndex: number = 0;
  @observable sortOrder: boolean = false;
  @observable sortColumnOrder: string = "id";
  @observable isDeletePacketConfirm: boolean = false;

  @observable packetsList = new Collection<any>();
  @observable packetDocumentsList = new Collection<any>();
  @observable types = new Collection<any>();
  @observable companyList = new Collection<any>();
  @observable selectedType: number = 0;
  @observable selectedCompany: number = 0;
  @observable documentTitle: string = "";
  @observable description: string = "";
  @observable isException: boolean = false;
  @observable packetId: number = 0;
  @observable isPacketDocuemntsVisible: boolean = false;
  @observable isDuplicatePacketDialogVisible: boolean = false;
  @observable duplicatePacketName: string = "";
  @observable isDuplicatePacketException: boolean = false;
  @observable duplicatePacketExceptionResponse: string = "";
  @observable isDuplicatePacketSuccess: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable packetDocumentResponse: any;
  @observable ispacketDocumentException: boolean = false;

  @action loadLookup = async () => {
    // this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    var defaultItem = { label: "All", value: "0" };
    try {
      var result = await DocumentStore.getAllPacketLookup();
      if (result != null) {
        this.types.values = [];
        this.companyList.values = [];

        // Company
        if (result.carriers != null) {
          result.carriers.forEach((obj: any) => {
            var data = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.companyList.values.push(data);
          });
        }
        this.companyList.values.splice(0, 0, defaultItem);
        this.selectedCompany = 0;

        // Type
        if (result.documentTypeGroups != null) {
          result.documentTypeGroups.forEach((obj: any) => {
            var documentData = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.types.values.push(documentData);
          });
        }
        this.types.values.splice(0, 0, defaultItem);
        this.selectedType = 0;
      }
    } catch (e) {
      this.types.values = [];
      this.companyList.values = [];
      this.companyList.values.splice(0, 0, defaultItem);
      this.types.values.splice(0, 0, defaultItem);
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action loadPackets = async () => {
    try {
      // this.isLoading = true;
      var packetResponse = await DocumentStore.searchAdminPackets(
        this.selectedType,
        this.selectedCompany,
        this.description,
        this.documentTitle,
        this.startIndex,
        this.rows,
        this.sortColumnOrder,
        this.sortOrder
      );

      if (packetResponse && packetResponse.data) {
        this.totalRecords = packetResponse.recordCount
          ? packetResponse.recordCount
          : 0;
        this.packetsList.values = packetResponse.data;
      }
      this.isLoading = false;
    } catch (e) {
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
    }
  };

  async setSortColumn(sortField: any) {
    this.sortOrder = !this.sortOrder;
    this.sortColumnOrder = sortField;
    await this.loadPackets();
  }
  @action addPacket() {
    routes.addPacket.push();
  }
  @action editPacket(id: number) {
    routes.editPacket.push({ packetId: id });
  }
  @action backtoDocuments() {
    routes.adminDocuments.push();
  }

  @action async onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    await this.loadPackets();
  }
  @action setSelectedType = (typeId: number) => {
    this.selectedType = typeId;
  };
  @action setSelectedCompany(companyId: number) {
    this.selectedCompany = companyId;
  }
  @action setSelectedTitle(title: string) {
    this.documentTitle = title;
  }
  @action setSelectedDescription(description: string) {
    this.description = description;
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }

  @action setDeletePacketConfirmation = (value: boolean, id: number) => {
    this.isDeletePacketConfirm = value;
    this.packetId = id;
  };
  @action setpacketDocumentsVisible = (value: boolean) => {
    this.isPacketDocuemntsVisible = value;
  };
  @action setDuplicatePacketDialogVisible = (value: boolean, id: number) => {
    this.isDuplicatePacketException = false;
    this.isDuplicatePacketSuccess = false;
    this.isDuplicatePacketDialogVisible = value;
    this.packetId = id;
    this.duplicatePacketName = "";
  };
  @action setDuplicatePacketName = (value: string) => {
    this.duplicatePacketName = value;
  };
  @action submitDuplicatePacket = async () => {
    if (this.duplicatePacketName && this.packetId > 0) {
      try {
        // this.isLoading = true;
        var packetResponse = await DocumentStore.duplicatePacket(
          this.packetId,
          this.duplicatePacketName
        );
        if (packetResponse) {
          this.isDuplicatePacketSuccess = true;
          this.isDuplicatePacketException = false;
          setTimeout(() => {
            this.setDuplicatePacketDialogVisible(false, 0);
          }, 500);
          this.loadPackets();
        }
        this.packetId = 0;
        this.isLoading = false;
      } catch (e: any) {
        IMASLog.log("exception: " + e.message);
        this.isLoading = false;
        e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          let _status = JSON.parse(_responseText).status;

          let response = {
            status: _status,
            title: _title,
            errors: { "": [] },
          };
          this.duplicatePacketExceptionResponse = JSON.stringify(response);
          this.isDuplicatePacketException = true;
        });
      }
    } else {
      let response = {
        status: 500,
        title: Constants.EnterPacketName,
        errors: { "": [] },
      };
      this.duplicatePacketExceptionResponse = JSON.stringify(response);
      this.isDuplicatePacketException = true;
    }
  };
  @action viewPacketDocument = async (packetId: number) => {
    this.isPacketDocuemntsVisible = true;
    this.ispacketDocumentException = false;
    this.packetDocumentsList.values = [];
    try {
      // this.isLoading = true;
      var packetDocumentResponse = await DocumentStore.viewPacketDocuments(
        packetId
      );

      if (packetDocumentResponse && packetDocumentResponse.data) {
        this.packetDocumentsList.values = packetDocumentResponse.data;
      }
      this.isLoading = false;
    } catch (e) {
      let response = {
        status: 500,
        title: e.message,
        errors: { "": [] },
      };
      this.ispacketDocumentException = true;
      this.packetDocumentResponse = JSON.stringify(response);
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
    }
  };

  @action viewDocument = async (docId: number) => {
    this.isLoading = true;
    this.ispacketDocumentException = false;
    this.response = {};
    try {
      this.isMessgeVisible = false;
      window.open(
        window.location.origin + "/documentviewer/" + docId + "/" + 0,
        "_new"
      );
    } catch (e) {
      this.ispacketDocumentException = true;
      var responseModels = {
        status: 400,
        title: "",
        errors: {
          ValidationError: ["Document with Id " + docId + " not found"],
        },
      };
      this.packetDocumentResponse = JSON.stringify(responseModels);
    }
  };
  @action onDeletePacketClick = async () => {
    this.isDeletePacketConfirm = false;
    await DocumentStore.deletePacket(this.packetId);
    await this.loadPackets();
    this.packetId = 0;
  };
  @action goSearch = async () => {
    this.startIndex = 0;
    this.first = 0;
    await this.loadPackets();
    this.setIsException(false);
  };

  @action resetFilters = async () => {
    this.selectedType = 0;
    this.selectedCompany = 0;
    this.documentTitle = "";
    this.description = "";
    this.sortColumnOrder = "id";
    this.first = 0;
    this.startIndex = 0;
    this.rows = 200;
    this.setIsException(false);
    await this.loadPackets();
  };
}
