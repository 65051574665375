import { action } from "mobx";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  Client as AccountingServiceClient
} from "../services/AccountingService";
import {
    Client as AutoDialerServiceClient, ContactListRequestModel, GenerateActiveCallListModel,
    GetActivePreviewRequestModel, GenerateProspectiveCallListModel, GetProspectivePreviewRequestModel, CreateNoteAndTaskModel, UpdateBadPhoneNumberModel, MarkAgentAsCalledModel
} from "../services/AutoDialerService";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import UserContext from "../infrastructure/UserContext";

class AutoDialerStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset(); 
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): AutoDialerServiceClient => {    
    return ServiceClient.getClient(ServiceType.AutoDialer);
  }
  getAccountingClient = (): AccountingServiceClient => {    
    return ServiceClient.getClient(ServiceType.Accounting);
  } 
  
  @action loadCallingList = async (
    agentId?: number | undefined,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,    
    sortAscending?: boolean,
    fullTextFilter?: string,
  ) => {
   
    var result = await this.getAccountingClient().getAllCreditCard(
      this.getApiVersion(),
      agentId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  }; 

  @action loadExistingCallingList = async(startIndex: number,
        pageSize: number,
        sortColumn: string | undefined,
        sortAscending: boolean | undefined,callListId?: number | undefined) => {
        var result = await this.getClient().getExistingCallList(
            this.getApiVersion(),
            callListId,
            startIndex,
            pageSize,
            sortColumn,
            sortAscending,
            undefined,            
            UserContext.getUserId()
        );
        return result;
    };

  @action removeCallingList = async (callListId: number) => {
        var result = await this.getClient().removeCallList(
            callListId,
            this.getApiVersion(),
            UserContext.getUserId()
        );
        return result;
    }; 

  @action getRandomAgentInfo = async (callListId: number | undefined) => {
        var result = await this.getClient().getRandomAgentInfo(
            this.getApiVersion(),
            callListId,
            undefined,
            UserContext.getUserId()
        );
        return result;
    };

  @action getAgentInfo = async (callListId: number | undefined, agentId: number | undefined ) => {
        var result = await this.getClient().getAgentInfoByAgentId(
            this.getApiVersion(),
            callListId,
            agentId,          
            UserContext.getUserId()
        );
        return result;
    };

   @action createNoteAndTask = async (createNoteAndTaskModel: CreateNoteAndTaskModel) => {
        var result = await this.getClient().createNoteAndTask(            
            this.getApiVersion(),  
            UserContext.getUserId(),
            undefined,
            createNoteAndTaskModel
        );
        return result;
    };
    @action createCustomCallList = async (
      callListName?: string | undefined, callListFor?: number | undefined, 
      marketerEventId?: number | undefined, callListAgentIdentifiers?: number[] | undefined,
       prospectiveFlag?: boolean | undefined, npnFlag?: boolean | undefined,
        file?: any | null | undefined,
        isUploadFile?: boolean | undefined) => {
      var result = await this.getClient().importCallList(            
          this.getApiVersion(),  
          UserContext.getUserId(),
          undefined,
          callListName,
          callListFor,
          marketerEventId,
          callListAgentIdentifiers,
          prospectiveFlag,
          npnFlag,                     
          file,
          isUploadFile
          
      );
      return result;
  };
//   @action createCustomCallList = async (importCallListModel: ImportCallListModel, file?: any | null | undefined) => {
//     var result = await this.getClient().importCallList(            
//         this.getApiVersion(),  
//         UserContext.getUserId(),
//         undefined,
//         importCallListModel,
//         file
        
//     );
//     return result;
// };

   @action getLast5CalledAgents = async (callListId?: number | undefined, agentcount?: number | undefined) => {
        var result = await this.getClient().getRecentCalledAgents(
            this.getApiVersion(),
            callListId,
            agentcount,           
            UserContext.getUserId()
        );
        return result;
    };

   @action updateBadPhoneNumber = async (updateBadPhoneNumberModel: UpdateBadPhoneNumberModel) => {
        var result = await this.getClient().updateBadPhoneNumber(
            this.getApiVersion(),
            UserContext.getUserId(),
            undefined,
            updateBadPhoneNumberModel
        );
        return result;
    };

   @action markAgentAsCalled = async (markAgentAsCalled: MarkAgentAsCalledModel) => {
        var result = await this.getClient().markAgentAsCalled(
            this.getApiVersion(),
            UserContext.getUserId(),
            undefined,
            markAgentAsCalled
        );
        return result;
    };

  @action deleteAgentDebt = async (id: number) => {  
    var result = await this.getAccountingClient().deleteDebt(
      id,
      this.getApiVersion(),   
      UserContext.getUserId()
    );
    return result;
  }; 
  @action getMarketers = async () =>  {  
    var result = await this.getClient().getMarketers(
      this.getApiVersion(),     
      UserContext.getUserId()
    );
    return result;
  }; 

  @action getCompanyAndProductSubCategory = async (marketerId: number) => {  
    var result = await this.getClient().getCompanyAndProductSubCategory(
      this.getApiVersion(),
      marketerId,
      UserContext.getUserId()
    );
    return result;
  };
  @action getLeadSource = async (isActive: boolean) => {  
    var result = await this.getClient().getLeadSource(
      this.getApiVersion(),
      isActive,
      UserContext.getUserId()
    );
    return result;
  }; 
  @action getAllStatesAndCountys = async () => {  
    var result = await this.getClient().getAllStatesAndCountys(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  
  @action getActivePreviewResults = async (request?: GetActivePreviewRequestModel | undefined) => {  
    var result = await this.getClient().getActivePreviewResults(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  
  @action getZipcodesByRange = async (zipCode?: string |  undefined, miles?: number | undefined, withInStateBoundary?: boolean |   undefined) => {  
    var result = await this.getClient().getZipcodesByRange(
      this.getApiVersion(),
      zipCode,
      miles,
      withInStateBoundary,
      UserContext.getUserId()      
    );
    return result;
  };
  
  @action getMarketerEvents = async () => {  
    var result = await this.getClient().getMarketerEvents(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action generateActiveCallList = async (request?: GenerateActiveCallListModel | undefined) => {  
    var result = await this.getClient().generateActiveCallList(
      this.getApiVersion(),
      undefined,
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  @action downloadIContactListResults = async (request?: ContactListRequestModel | undefined) => {  
    var result = await this.getClient().downloadIContactListResults(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action getProspectivePreviewResults = async (request?: GetProspectivePreviewRequestModel | undefined) => {  
    var result = await this.getClient().getProspectivePreviewResults(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action generateProspectiveCallList = async (request?: GenerateProspectiveCallListModel | undefined) => {  
    var result = await this.getClient().generateProspectiveCallList(
      this.getApiVersion(),
      undefined,
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action callLog = async (phoneNumber: string,  sourceName: string, agentId?: number) => {  
    var result = await this.getClient().callLog(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      phoneNumber,
      agentId,
      sourceName
    );
    return result;
  };

}

export default new AutoDialerStore();
