import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import UserContext from "../../../infrastructure/UserContext";
import Utils from "../../../infrastructure/Utils";
import { routes } from "../../../router";
import {
  CertificationModel,
  CertificationsDropDownValuesResponse  
} from "../../../services/ContractService";
import { AgentListModel } from "../../../services/ProducerSearchService";
import ContractsStore from "../../../store/ContractsStore";
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import ProductStore from "../../../store/ProductStore";
import { googleTagManager } from "../../../infrastructure/tracking";
import { BaseAddEditViewModel } from "../../components/BaseAddEditViewModel";

export class CertificationsAddEditViewModel extends BaseAddEditViewModel {
  @observable name: string = "";
  @observable abbreviation: string = "";
  @observable shortName: string | undefined = "";
  @observable carrierGroupName: string = "";
  @observable defaultPaymentSourceId: number = 0;
  @observable commissionProcessingDivisionId: number = 0;
  @observable isEligibilityRequired: boolean = false;
  @observable allowOverlappingDates: boolean = false;
  @observable hideItem: boolean = false;

  @observable defaultCertYear: string | undefined = (
    new Date().getFullYear()
  ).toString();
  @observable defaultCarrier: string = "";
  @observable agent: string = "";
  @observable agentId: number = 0;
  @observable note: string = "";
  @observable deleteAllOverrideCerts: boolean = false;
  @observable defaultCertDate: Date | undefined = undefined;
  @observable FilteredAgenteList = new Collection<AgentListModel>();
  @observable selectedProduct: any = null;
  @observable selectedSingleProduct: string = ''
  @observable isProductNotSelected: boolean = false;
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable id: number = 0;

  constructor() {
    super(
      "Certification",
      routes.listCertifications,
      new CertificationsAddEditValidator()
    );
  }

  protected async loadLookups() {
    await this.certificationsDropDownValues();
  }

  protected certificationsDropDownValues = async () => {
    let result = await ContractsStore.certificationsDropDownValues();
    if (result) {
      let response: CertificationsDropDownValuesResponse = result;
      if (response.company) {
        this.companyList.values = [];
        response.company.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companyList.values.push(data);
        });
       
      } else {
        this.companyList.values = [];        
      }
    }
  };

  protected toServiceModel() {
    let prdtIds = this.selectedId !== 0
      ? [Number(this.selectedSingleProduct)]
      : this.selectedProduct.map((i: any) => Number(i))
    var dto: CertificationModel = {
      id: this.selectedId !== 0 ? this.selectedId : 0,
      agentId: this.agentId ? this.agentId : 0,
      productId: this.selectedId ? Number(this.selectedSingleProduct) : 0,
      certificationDate: this.defaultCertDate
        ? this.defaultCertDate
        : new Date(),
      certificationYear: this.defaultCertYear
        ? Number(this.defaultCertYear)
        : 0,
      companyId: this.defaultCarrier ? Number(this.defaultCarrier) : 0,
      note: this.note ? this.note : "",
      override: this.deleteAllOverrideCerts,
      companyName: this.defaultCarrier
        ? this.companyList.values.filter(
            (i) => i.value === this.defaultCarrier
          )[0].label
        : "",
      agentName: this.agent ? this.agent : "",
      productIds: prdtIds,
    };
    return dto;
  }

  protected async loadItem(id: number): Promise<void> {
    if (id === 0) {
      this.resetModel();
    } else {
      var result = await ContractsStore.getCertificationById(id);
      if (result) {
        let item: CertificationModel = result;

        this.selectedId = item.id ? item.id : 0;
        this.agentId = item.agentId ? item.agentId : 0;
        this.agent = item.agentName ? item.agentName : "";

        if (item.companyName) {
          let carrierId = this.companyList.values.filter(
            (i) => i.label === item.companyName
          )[0].value;
          this.defaultCarrier = carrierId;
          await this.getProductList(Number(carrierId));
        } else {
          this.defaultCarrier = "0";
        }
        this.defaultCertDate = item.certificationDate
          ? new Date(`${item.certificationDate}`)
          : new Date();
        this.defaultCertYear = item.certificationYear
          ? item.certificationYear.toString()
          : "";
        this.note = item.note ? item.note : "";
        this.deleteAllOverrideCerts = item.override ? item.override : false;
 
        if (this.selectedId !== 0)
        { 
          this.selectedSingleProduct = item.productId
            ? item.productId.toString()
            : "";
        // }
        // else {
          if (item.productId) {
            let abc = item.productId ? item.productId.toString() : "";
            let b = [abc.toString()];
            this.changeProduct(b);
          } 
        }
          else this.selectedProduct = null;       
       }
    }
  }

  @action changeProduct = (value: any) => {
    this.selectedProduct = value;
  };

  protected async addItem(): Promise<void> {
    var item = this.toServiceModel();
    googleTagManager.trackAction("certification_created", { feature: routes.addCertifications.name, user_id: UserContext.userId, agent_id: item.agentId || 0 });
     await ContractsStore.addCertification(item);
  }
  protected async updateItem(): Promise<void> {
    var item = this.toServiceModel();
    await ContractsStore.updateCertification(item);
  }

  @action setAgent = (value: string) => {
    this.agent = value;
  };
  @action setAgentId = (value: number) => {
    this.agentId = value;
  };

  @action fillterUsers = async (value: string) => {
    try {
      let result = await ProducerSearchStore.getAgentsLookup(
        1,
        value,
        0,
        100,
        undefined,
        undefined
      );
      var values = null;
      if (result !== null) {
        if (result.recordCount) {
          if (result.data) {
            values = result.data;
          }
        }
      }
    } catch (e) {}

    if (values !== undefined && values !== null) {
      this.FilteredAgenteList.values = values;
    } else {
      var noAgent: AgentListModel = { id: 0 };
      this.FilteredAgenteList.values = [noAgent];
    }
  };

  @action getProductList = async (id: number) => {
    let res = await ProductStore.getProductLookup(id);
    if (res) {
      this.productList.values = [];
      this.productList.values = res.data && this.mapListItemAndSort(res.data);
    } else {
      this.productList.values = [];
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

    @action resetModel = () => {
        this.defaultCarrier = "";
        this.agent = "";
        this.agentId = 0;
        this.note = "";
        this.deleteAllOverrideCerts = false;
        this.defaultCertDate = undefined;
        this.FilteredAgenteList.values = [];
        this.selectedProduct = null;
        this.selectedSingleProduct = ''
        this.isProductNotSelected = false;
        this.defaultCertYear = new Date().getFullYear().toString()
    }
  // TO REMOVE
}

class CertificationsAddEditValidator extends AbstractValidator<CertificationsAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.defaultCarrier)
      // .isNotEqualTo("0")
      .isNotEmpty()
      .withFailureMessage("Carrier is required");
    this.validateIfString((input) => input.agent)
      .isNotEmpty()
      .matches(/\S+/,"i")
      .withFailureMessage("Agent is required");
    this.validateIfDate((input) => input.defaultCertDate)
      .isNotEmpty()
      .withFailureMessage("Certification is required");

      this.validateIfString((input) => input.selectedProduct) 
      .isNotNull()
      .withFailureMessage("Product is required");
  }
}
