import { routes } from "../../../router";
import { BaseListViewModel } from "../../components/BaseListViewModel";
import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import SupplyOrderStore from "../../../store/SupplyOrderStore";
import Utils from "../../../infrastructure/Utils";

interface listViewModel {
  id?: number;
  company?: string;
  status?: string;
  description?: string;
  item?: string;
}
export class SuppliesListViewModel extends BaseListViewModel<listViewModel> {
  constructor() {
    super(
      "Supplies",
      "Supply Item",
      routes.addAdminSupplies,
      routes.editAdminSupplies,
      "id",
      "item"
    );
    this.pageSize = 200
    //setting pageSize to 200 as per Amanda's request
  }
  
@action handleInactiveCarriers = async (e: any) => {
  this.showInactiveCarriers = e.checked;
  this.companyList.values = []; 
  this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
  this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
  this.defaultSelectedCompany = "0";
};
 
  protected async loadLookups(): Promise<void> {
    this.companyList.values = [];
    this.productTypeList.values = [];
    this.stateList.values = [];
    this.statusList.values = [];
    var result = await SupplyOrderStore.getSuppliesById(this.selectedId);
    var defaultItem = { label: "All", value: "0" };
    var selectItem = { label: "All", value: "-1" };

    this.companyList.values = Utils.mapListItem(result.carriers);
    this.productTypeList.values = Utils.mapListItem(result.productTypes);
    if (result.states) {
      this.stateList.values = [];
      var sortStateList = [...result.states].sort(Utils.compareListItem);
      for (let item of sortStateList) {
          this.stateList.values.push({ label: '' + item.stateName, value: '' + item.stateId, isHeader: item.isHeader});
      }
    }
    // this.stateList.values = Utils.mapListItem(result.states);
    this.statusList.values = Utils.mapListItem(result.statusList);
    
    this.companyList.values.splice(0, 0, defaultItem);
    this.carriersInitial.values = this.companyList.values;
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
    this.productTypeList.values.splice(0, 0, defaultItem);
    this.stateList.values.splice(0, 0, {label: "All",value: "0", abbreviation: ''});
    this.statusList.values.splice(0, 0, selectItem);
    this.defaultSelectedCompany = "0";
    this.defaultProductType = "0";
    this.defaultSelectedState = "0";
    this.defaultSelectedStatus = "1";
  }

  protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
    this.isLoading = true;
    let companyId = isNaN(parseInt(this.defaultSelectedCompany)) ? 0 : parseInt(this.defaultSelectedCompany);
    let productTypeId = isNaN(parseInt(this.defaultProductType)) ? 0 : parseInt(this.defaultProductType);
    let stateId = isNaN(parseInt(this.defaultSelectedState)) ? 0 : parseInt(this.defaultSelectedState);
    let statusId = isNaN(parseInt(this.defaultSelectedStatus)) ? 1 : parseInt(this.defaultSelectedStatus);
    
    return await SupplyOrderStore.getSupplies(companyId, productTypeId, stateId, statusId, this.item, this.description,
      pageIndex,  pageSize, sortColumn, sortOrder);
  }

  protected async deleteItem(id: number) {
      await SupplyOrderStore.deleteSupplies(id);
      this.loadItems();
  }
  @action editItem = async (id: number) => {
    routes.editAdminSupplies.push({ Id: (id) });
  };
  @action cloneItem = async (id: number) => {
    routes.cloneAdminSupplies.push({ Id: (id) });
  };

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
          
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable stateList = new Collection<{
    label: string;
    value: string;
    abbreviation?: string;
    isHeader?: boolean;
  }>();

  @observable productTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statusList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable defaultSelectedCompany: string = "0";
  @observable defaultSelectedState: string = "0";
  @observable defaultProductType: string = "0";
  @observable defaultSelectedStatus: string = "1";
  @observable item: string = "";
  @observable description: string = "";

  @action search = () => {
    this.loadItems();
  };

  resetFiltersToDefault() {
    this.pageSize = 200;
  }

  @action reset = () => {
    this.defaultSelectedCompany = "0";
    this.defaultSelectedState = "0";
    this.defaultProductType = "0";
    this.defaultSelectedStatus = "1";
    this.description = ''
    this.item = "";
    this.showInactiveCarriers = false;
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
    this.defaultSelectedCompany = "0";
    this.Load();
  };
}
