import React from "react";
import Dropzone from "react-dropzone";
import { Button } from "primereact/button";
import IMASLog from "../../../infrastructure/IMASLog";

export function IMASFilePicker(props: any) {
    let accept = props.acceptFiles ? props.acceptFiles : "application/pdf";
    if (props.isReset) {
        let resetBtn = document.getElementById("resetBtn");
        resetBtn && resetBtn.click();

    }
    const onDrop = (acceptedFiles: any) => {
        IMASLog.logObject("files", acceptedFiles);

        if (acceptedFiles.length > 0) {
            if ((parseFloat((acceptedFiles[0].size / 1048576).toFixed(2)) >= 10 && props.isSizeValidation) && props.isSizeValidation) {
                props.onDrop('', '');
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(acceptedFiles[0]);
                reader.onload = (_event) => {
                    var data = reader.result;
                    var name = acceptedFiles[0].name;
                    props.onDrop(data, name);
                };
            }
        }
    };

    const removeFile = (file: any) => () => {
        file.length = 0;
        file.splice(0, 0);
    };

    return (
        <div>
            <Dropzone onDrop={onDrop} multiple={false} accept={accept}>
                {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                    acceptedFiles,
                }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />

                        <div className="form-group">
                            <div className="dropzone needsclick dz-clickable">
                                <div className="dz-message">
                                    {!isDragReject && (
                                        <Button
                                            icon="pi pi-upload"
                                            label="Drop file here or click to upload"
                                            className="primary-button"
                                        />
                                    )}
                                    {isDragReject && (
                                        <Button
                                            icon="pi pi-upload"
                                            label="Drop file here or click to upload"
                                            className="p-button-danger danger-button"
                                        />
                                    )}
                                    <br />
                                    {acceptedFiles.length > 0 ? (
                                        <>
                                            {(parseFloat((acceptedFiles[0].size / 1048576).toFixed(2)) >= 10 && props.isSizeValidation) ?
                                                (<><span className="note validation-error size-err">{"The file you're trying to upload exceeds maximum allowed size 10 MB. Please reduce file size and try again."}</span></>) :
                                                <>
                                                    <span className="note">{acceptedFiles[0].name}</span>
                                                </>
                                            }
                                        </>
                                    ) :

                                        (
                                            props.isExistingFile == true ?
                                                (<>
                                                    <span className="note needsclick">
                                                        {props.ExistingFileName}
                                                    </span>
                                                </>) :

                                                (
                                                    Array(accept).join(',').search("pdf") > 0 ?
                                                        (<>
                                                            <span className="note needsclick">
                                                                {"Only PDF file is allowed" + (props.isSizeValidation ? " (Maximum allowed file size is 10MB)" : "")}
                                                            </span>
                                                        </>) : (<>
                                                            <span className="note needsclick">
                                                                {
                                                                    props.acceptMessage ? props.acceptMessage : "Only ('*'" + accept.join(', *') + ") file is allowed"
                                                                }
                                                            </span>
                                                        </>)
                                                )
                                        )
                                    }
                                </div>
                            </div>
                            {acceptedFiles.length > 0 && (
                                <div id="resetBtn" style={{ display: 'none' }} onClick={removeFile(acceptedFiles)}>Reset</div>
                            )}
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
}
