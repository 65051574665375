import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { getCurrentRoute, routes } from "../../router";
import { Route } from "type-route";

import { HomeViewModel } from "../home/HomeViewModel";
import { AddEditMarketingEventViewModel } from "../home/AddEditMarketingEventViewModel";
import { MarketingEventDetailViewModel } from "../home/MarketingEventDetailViewModel";

export class HomeDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable
  HomeViewModel: HomeViewModel = new HomeViewModel();
  @observable
  AddEditMarketingEventViewModel: AddEditMarketingEventViewModel = new AddEditMarketingEventViewModel();
  @observable
  MarketingEventDetailViewModel: MarketingEventDetailViewModel = new MarketingEventDetailViewModel();

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
    console.log(this.route.name)
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    console.log(currentRoute.name)
    console.log(this.route.name)
  };
}
