import { observer } from "mobx-react";
import React from "react";
import { Repeater } from "../Repeater";
import { MultiSelect } from "primereact/multiselect";
import classNames from "classnames";

export const IMASMultiSelect: React.FC<{
  errors: string[] | undefined;
  label: string;
  value: string;
  id: string;
  className: string;
  isrequired: boolean;
  placeHolder: string | undefined;
  optionLabel: string;
  options: Array<{ label: string; value: string; isHeader?: boolean }>;
  filter: boolean;
  itemTemplate: (data: any) => JSX.Element | undefined;
  onChange: (value: string) => void;
  onFocus?(e: Event): void;
  disable?: boolean;
  ref?: any;
  maxSelectedLabels?: number;
  isGroupedList?: boolean;
  selectedItemsLabel?: string;
}> = observer((props) => {
  var createRef = props.ref ? props.ref : React.createRef<any>();
  const onClick = (e: any) => {
    var dropdownDiv = document.getElementById(props.id) as HTMLElement;
    if (dropdownDiv) {
      var filterdropdown = dropdownDiv.querySelector(
        ".p-multiselect-filter-container >.p-inputtext"
      ) as HTMLInputElement;
      if (filterdropdown && !createRef.current.filtercleared) {
        let event: any = { query: "" };
        createRef.current.onFilter(event);
        createRef.current.filtercleared = true;
      }
    }
  };

  const getClassNameError = () => {
    if (props.errors)
      return classNames({
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    else return props.className ? classNames(props.className) : classNames("");
  };
  const onFocus = (e: Event) => {
    createRef.current.filtercleared = false;
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const onKeyDown = (e: any) => {
    var multiSelectDiv = document.getElementById(props.id);
    if (e.altKey && e.keyCode === 40) {
      // alt + Arrow Down
      // open the dropdown
      if (multiSelectDiv !== null) {
        multiSelectDiv.className = "p-multiselect p-component p-focus";
        var targetDiv = Array.from(
          multiSelectDiv.getElementsByClassName(
            "p-multiselect-panel"
          ) as HTMLCollectionOf<HTMLElement>
        );
        if (targetDiv !== null) {
          targetDiv[0].className =
            "p-multiselect-panel p-hidden p-input-overlay p-input-overlay-visible";
          targetDiv[0].style.display = "block";
          targetDiv[0].style.zIndex = "1009";
        }
      }
    }
    if (e.keyCode === 27) {
      // Esc
      // close the dropdown
      if (multiSelectDiv !== null) {
        multiSelectDiv.className = "p-multiselect p-component ";
        var targetDiv1 = Array.from(
          multiSelectDiv.getElementsByClassName(
            "p-multiselect-panel"
          ) as HTMLCollectionOf<HTMLElement>
        );
        if (targetDiv1 !== null) {
          targetDiv1[0].className =
            "p-multiselect-panel p-hidden p-input-overlay";
          targetDiv1[0].style.display = "none";
          targetDiv1[0].style.zIndex = "1002";
        }
      }
    }
  };

  const onChange = (e: any) => {
    if (
      props.isGroupedList &&
      e.originalEvent &&
      e.originalEvent.target &&
      e.originalEvent.target.type === "checkbox"
    ) {
      if (
        e.originalEvent &&
        e.originalEvent.originalEvent &&
        e.originalEvent.originalEvent.currentTarget
      ) {
        if (!e.originalEvent.originalEvent.currentTarget.altchecked) {
          e.value = props.options
            .filter((i: any) => {
              return i.value != "-1" && i.value != -1;
            })
            .map((i) => i.value);
          e.originalEvent.originalEvent.currentTarget.altchecked = true;
        } else {
          e.value = [];
          e.originalEvent.originalEvent.currentTarget.altchecked = false;
        }
      }
      props.onChange(e.value);
      handleheadercheckbox(e.value);
    } else if (
      props.isGroupedList &&
      e.value &&
      (e.value.indexOf(-1) >= 0 || e.value.indexOf("-1") >= 0)
    ) {
      let selectedoption = props.options.filter((i: any) => {
        return i.value == "-1" || i.value == -1;
      });
      if (
        selectedoption &&
        selectedoption.length > 0 &&
        selectedoption[0].isHeader
      ) {
        return false;
      } else {
        props.onChange(e.value);
      }
      handleheadercheckbox(e.value);
    } else {
      props.onChange(e.value);
    }
  };

  const handleheadercheckbox = (value: any) => {
    if (props.isGroupedList) {
      let container = document.getElementById("div" + props.id);
      if (container) {
        let header = container.querySelector(
          ".p-multiselect-header .p-checkbox-box"
        );
        let licheckbox = container.querySelector(
          ".p-multiselect-header .p-checkbox-box .p-checkbox-icon"
        );
        let headercheckbox: any = container.querySelector(
          ".p-multiselect-header .p-checkbox"
        );
        if (header && licheckbox && headercheckbox) {
          if (
            value &&
            value.length ===
              props.options.filter((i: any) => {
                return i.value != "-1" && i.value != -1;
              }).length
          ) {
            header.classList.remove("p-highlight");
            header.classList.add("p-highlight");
            licheckbox.classList.remove("pi");
            licheckbox.classList.remove("pi-check");
            licheckbox.classList.add("pi");
            licheckbox.classList.add("pi-check");
            headercheckbox.altchecked = true;
          } else {
            header.classList.remove("p-highlight");
            licheckbox.classList.remove("pi");
            licheckbox.classList.remove("pi-check");
            headercheckbox.altchecked = false;
          }
        }
      }
    }
  };

  if (props.isGroupedList) {
    setTimeout(() => {
      let container = document.getElementById("div" + props.id);
      if (container) {
        container
          .querySelectorAll(".list-item-header")
          .forEach((element: any) => {
            let liItem = element.closest("li");
            if (liItem && liItem.querySelector(".p-checkbox")) {
              liItem.classList.add("list-header-disabled");
              liItem.querySelector(".p-checkbox").style.display = "none";
            }
          });
        handleheadercheckbox(props.value);
      }
    }, 100);
  }

  return (
    <>
      <div id={"div" + props.id} onKeyDown={onKeyDown} onClick={onClick}>
        <label htmlFor={props.id}>
          {props.label}{" "}
          {props.isrequired && <span className="required">*</span>}{" "}
        </label>
        <MultiSelect
          style={{ minHeight: "36.4px" }}
          ref={createRef}
          id={props.id}
          className={getClassNameError()}
          value={props.value}
          itemTemplate={props.itemTemplate}
          options={props.options}
          optionLabel={props.optionLabel}
          placeholder={props.placeHolder}
          onChange={(e) => {
            onChange(e);
          }}
          onFocus={onFocus}
          filter={props.filter}
          disabled={props.disable ? props.disable : undefined}
          maxSelectedLabels={props.maxSelectedLabels}
          selectedItemsLabel={props.selectedItemsLabel}
        />
        <Repeater
          errors={props.errors}
          viewId={props.id}
          child={(error) => <div className="invalid-feedback">{error}</div>}
        />
      </div>
    </>
  );
});
