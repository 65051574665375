import { observable, action, toJS } from "mobx";
import ProducerStore from "../../store/ProducerStore";

import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import IMASLog from "../../infrastructure/IMASLog";
import { AgentSummaryModel, CreateAgentModel, UploadProfilePicModel, UpdateAgentModel, PrincipalModelList, PrinicpalAgentsModel, CreatePrincipalModel } from "../../services/ProducerService";

import { routes } from "../../router";

import Utils from "../../infrastructure/Utils";
import ProfilePicData from "../../infrastructure/ProfilePicData";
import { Constants } from "../../infrastructure/enum/Constants";
import { AgentDetails } from "../../infrastructure/enum/AgentDetails";
import PageContext from "../../infrastructure/PageContext";
import UserContext from "../../infrastructure/UserContext";
import Collection from "../../infrastructure/CollectionHelper";
import BusinessUnitConfigStore from "../../store/BusinessUnitConfigStore";
import { googleTagManager } from "../../infrastructure/tracking";
import { CustomAttributesComponentViewModel } from "../../views/customers/CustomAttributesComponentViewModel";
import { CustAttrToEntityModel } from "../../services/InsuredService";
import CustomAttributeStore from "../../store/CustomAttributeStore";
import { Permission } from "../../infrastructure/enum/Permission";

export class AddAgentViewModel implements ViewModel {
   
    @observable profilePic: string = 'assets/layout/images/profile-default.png'
    @observable profilePicDelete: boolean = false;
    @observable w9: Date | undefined;
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @observable agentSummary: AgentSummaryModel | null = null   
  
    @observable createdBy: number = 341
  
    @observable ffmId: string | undefined = ''
    @observable isCancelConfirm: boolean = false;
    @observable isException: boolean = false
    @observable isDuplicate: boolean = false
    @observable isDuplicateNPN: boolean = false
    @observable duplicateError: string = ''    
    @observable isSuccess: boolean = false    
    @observable exceptionMessage: any;
    @observable maxDate: Date = new Date();
    @observable yearRange: string = "";
    @observable smr: string = '1'
    @observable lhr: string = '1'
    @observable smrList = [{ label: 'N/A', value: '1' }]
    @observable lhrList = [{ label: 'N/A', value: '1' }]   
    @action Load = async (agentId: any) => {        
        PageContext.isCustomAttribute = false;
        var d: Date = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var startDate = new Date(year - 2, month, day);
        var endDate = new Date(year + 5, month, day);
        this.yearRange = startDate.getFullYear() + ":" + endDate.getFullYear();
        this.isCancelConfirm = false;
        this.isLoading = true
        this.isSuccess = false
        this.isException = false;
        PageContext.setIsOverlay(true);
            
        this.setIsDuplicate(false);
        this.duplicateError = "";
        this.setIsDuplicateNPN(false);       
        this.selectedKey = AgentDetails.Summary;
         this.maxDate = this.getmaxDate(new Date(),18);          
            this.agentId = 0
            this.title = 'Add Agent'        
        this.selectedKey = AgentDetails.Summary
        this.profilePic = 'assets/layout/images/profile-default.png'
        ProfilePicData.data = ''
        this.isDuplicate = false;
        this.isDuplicateNPN =false;
        this.duplicateAgentId = 0
        this.setIslastNameValid(false);
        this.setIsfirstNameValid(false)
        this.setIsDOBValid(false)
        this.setIsDbaValid(false)
        this.setIsmiddleNameValid(false)
        this.setIsnickNameValid(false)
        this.setIsagencyNameValid(false)
        this.setIsSsnValid(false)
        this.setIsNpnValid(false)
       // await this.loadProfilePic();
        await this.loadAgentDetails();
        this.profilePicDelete = false;
      
        PageContext.setIsOverlay(false);
        this.isLoading = false;
    
        if(Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute))
        {
            // get Custom attribute screen permission
            var res = await this.getCustomAttributePermissionByScreen();
            if (res) {
                const data = res.filter(x => x.path === Permission.AddAgentCustomAttribute && x.active === true).pop();
                this.isCustomAttrActive = data && Object.keys(data).length > 0 ? true : false;
                this.attributesScreenPath = 6;

                var attributes = await this.getCustomAttributesByScreen(data && data.id || 0);
                if (attributes) {
                    this.customAttributeList = attributes;
                }

                this.attributesScreenPathId = 6;

            }
        }

    };
    @action loadProfilePic = async () => {
        try{
            if(!this.agentId)return;
            setTimeout(() => {
                PageContext.setIsLoadingVisible(false); 
            }, 3000);    
            var result = await ProducerStore.getAgentProfilePic(this.agentId)
            if (result && result.imageData) {
                this.profilePic = 'data:image/jpeg;base64,' + result.imageData
            }  
            else
            {
            this.profilePic = 'assets/layout/images/profile-default.png';    
            }         
        }
        catch(e)
        {
            this.profilePic = 'assets/layout/images/profile-default.png';
            this.isException = true
            this.exceptionMessage = e.response;
           IMASLog.log('exception - download profile')
        }
    }
    @action loadAgentDetails = async () => {
        try {
            this.resetAll()
            this.isLoading = true
            this.isException = false
            await this.ResetValidate()
            await this.ResetValidateAgency()
            var result = await ProducerStore.getAgentDetailsOnAdd()
            if (result) {
                this.isAgency = result.isAgency ? result.isAgency : false
                this.isAgencyFromApi = result.isAgency ? result.isAgency : false
                this.firstName = result.firstName ? result.firstName : ''
                this.agencyName = result.lastName ? result.lastName : ''
                this.lastName = result.lastName ? result.lastName : ''
                this.middleName = result.middleName ? result.middleName : ''
                this.suffix = result.suffix ? '' +result.suffix : '0';
                this.alternativeLastName = result.alternativeLastName ? result.alternativeLastName : ''
                var date = Utils.getDateInFormat(result.dob ? result.dob : undefined)
               if(date)
               {
                this.setDOB(new Date(date));
               }
                this.dba = result.dba ? result.dba : ''
                this.nickName = result.nickName ? result.nickName : ''
                this.website = result.webSite ? result.webSite : ''
                this.npn = result.npn ? result.npn : ''
                this.ssn = result.ssn ? result.ssn : ''
                this.validateSSN = result.ssn ? result.ssn : ''
                this.tin = result.tin ? result.tin : ''               
                this.gender = result.gender ? result.gender : ''            
                
                this.loadStatusses(result);
                this.loadSmrs(result);
                this.loadLhrs(result);
                this.loadDebtLevels(result)              
                this.loadLeadSourceList(result);
                this.loadSuffixList(result);
                await this.loadAgentGroupNameList(result);
            }
            this.isLoading = false
        } catch (error) {
            this.isLoading = false
            this.isException = true
            this.exceptionMessage = error.response;
            IMASLog.log('exception..' + error)
        }
    }  
  

    private loadLeadSourceList(result: CreateAgentModel) {
        if (result.leadSources) {
            this.leadSourceList = [];
            this.leadSourceList.push({ label: 'Select', value: '0' });
            var sortLeadSourceList = [...result.leadSources].sort(Utils.compareListItem);
            for (let item of sortLeadSourceList) {               
                this.leadSourceList.push({ label: '' + item.text, value: '' + item.value });
            }
            this.leadSource = result.leadSource ? '' + result.leadSource : '0';
        }
    }
    private loadSuffixList(result: CreateAgentModel) {
        if (result.suffixes) {
            this.suffixList.values = [];
            this.suffixList.values.push({ label: 'Select', value: '0' });
            //var sortLeadSourceList = [...result.leadSources].sort(Utils.compareListItem);
            for (let item of result.suffixes) {
                this.suffixList.values.push({ label: '' + item.text, value: '' + item.value });
            }
            this.suffix = result.suffix ? '' + result.suffix : '0';
        }
    }

    private loadStatusses(result: CreateAgentModel) {
        if (result.statuses) {
            this.statusList = [];
            var sortStatusList = [...result.statuses].sort(Utils.compareListItem);
            for (let item of sortStatusList) {
                this.statusList.push({ label: '' + item.text, value: '' + item.value });
            }
            this.status = result.status ? '' + result.status : '6';
        }
    }

    private loadSmrs(result: UpdateAgentModel) {
        if (result.smrs) {
            this.smrList = [];
            var sortSmrList = [...result.smrs].sort(Utils.compareListItem);
            for (let item of sortSmrList) {
                this.smrList.push({ label: '' + item.text, value: '' + item.value });
            }
            this.smr = result.smr ? '' + result.smr : '1';
        }
    }

    private loadLhrs(result: UpdateAgentModel) {
        if (result.lhrs) {
            this.lhrList = [];
            var sortLhrList = [...result.lhrs].sort(Utils.compareListItem);
            for (let item of sortLhrList) {
                this.lhrList.push({ label: '' + item.text, value: '' + item.value });
            }
            this.lhr = result.lhr ? '' + result.lhr : '1';
        }
    }
    private loadDebtLevels(result: CreateAgentModel) {}
   
    @observable title: string = ''   
    
    @observable
    public ErrorModel = new ErrorModel(new AddAgentValidator());
   
    @observable
    public ErrorModelAgency = new ErrorModel(new AddAgencyValidator());

    Route = async (currentRoute: RouteList): Promise<void> => { }
    
    @observable isLoading: boolean = true;
    @observable response: any = '';  
    @observable agentId: number = 0
    @observable duplicateAgentId: number = 0  
    @observable isAgency: boolean = false
    @observable isAgencyFromApi: boolean = false  
    @observable npn: string | undefined = ''
    @observable agencyName: string = ''
    @observable dba: string | undefined = ''
    @observable nickName: string | undefined = ''
    @observable website: string | undefined = ''
    @observable ssn: string | undefined = ''
    @observable validateSSN: string = ''
    @observable alternativeLastName: string | undefined = ''
    @observable tin: string = ''
    @observable agency: string | undefined = ''
    @observable selectedKey: string = AgentDetails.Summary
    @observable dateOfBirth: Date | undefined
    @observable status: string = '6'
    @observable selectedGroups: any = []; 
    @observable groups = new Collection<{ label: string; value: string }>();
    @observable lastName: string = ''
    @observable firstName: string | undefined = ''
    @observable middleName: string | undefined = ''
    @observable leadSource: string = 'None'      
    @observable isDuplicateApiHit: boolean = false;

    @observable isfirstNameValid: boolean = false;
    @observable validfirstNameError: string = '';
    @observable principalAgent: string = ''
    @observable principalAgentId: number = 0
    @observable selectedPrincipalAgentId: number = 0
    @observable FilteredAgentsList = new Collection<PrincipalModelList>();
    @observable isDeletePrincipalAgentConfirm: boolean = false
    @observable addPrincipalAgentDto: any | undefined = [];
    @observable principalAgentsList = new Collection<PrinicpalAgentsModel>();


    
  //custom attribute states
  @observable addAttributesModal: boolean = false;
  @observable CustomAttributesComponentViewModel: CustomAttributesComponentViewModel = new CustomAttributesComponentViewModel();
  @observable isCustomAttrActive: boolean = false;
  @observable attributesScreenPath: number = 6;
  @observable customAttributeList: any = [];
  @observable isValid = true;
  @observable attributesScreenPathId: number = 0;
  @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();

    @action setIsfirstNameValid(value: boolean) {
        this.isfirstNameValid = value;
    }

    @action setValidfirstNameError(value: string) {
        this.validfirstNameError = value;
    }

    @observable islastNameValid: boolean = false;
    @observable validlastNameError: string = '';
    @action setIslastNameValid(value: boolean) {
        this.islastNameValid = value;
    }

    @action setValidlastNameError(value: string) {
        this.validlastNameError = value;
    }
    @action setSelectedGroups = (value: any) => {
        this.selectedGroups = value;
      };
    @observable ismiddleNameValid: boolean = false;
    @observable validmiddleNameError: string = '';
    @action setIsmiddleNameValid(value: boolean) {
        this.ismiddleNameValid = value;
    }

    @action setValidmiddleNameError(value: string) {
        this.validmiddleNameError = value;
    }

    @observable isnickNameValid: boolean = false;
    @observable validnickNameError: string = '';
    @action setIsnickNameValid(value: boolean) {
        this.isnickNameValid = value;
    }

    @action setValidnickNameError(value: string) {
        this.validnickNameError = value;
    }

    @observable isagencyNameValid: boolean = false;
    @observable validagencyNameError: string = '';
    @action setIsagencyNameValid(value: boolean) {
        this.isagencyNameValid = value;
    }

    @action setValidagencyNameError(value: string) {
        this.validagencyNameError = value;
    }

    @observable isNpnValid: boolean = false;
    @observable validNpnError: string = '';
    @action setIsNpnValid(value: boolean) {
        this.isNpnValid = value;
    }
    @action loadAgentGroupNameList = async (result: UpdateAgentModel) => {
        var items = await BusinessUnitConfigStore.getAllAgentGroupNameLookup();
        Utils.mapListItemsToDropdown(items, this.groups, "");
      };
    @action setValidNpnError(value: string) {
        this.validNpnError = value;
    }

    @observable isFfmValid: boolean = false;
    @observable validFfmError: string = '';
    @action setIsFfmValid(value: boolean) {
        this.isFfmValid = value;
    }

    @action setValidFfmError(value: string) {
        this.validFfmError = value;
    }

    @observable isSsnValid: boolean = false;
    @observable validSsnError: string = '';
    @action setIsSsnValid(value: boolean) {
        this.isSsnValid = value;
    }

    @action setValidSsnError(value: string) {
        this.validSsnError = value;
    }

    @observable isDbaValid: boolean = false;
    @observable validDbaError: string = '';
    @action setIsDbaValid(value: boolean) {
        this.isDbaValid = value;
    }

    @action setValidDbaError(value: string) {
        this.validDbaError = value;
    }

    @observable isDOBValid: boolean = false;
    @observable validDOBError: string = '';
    @action setIsDOBValid(value: boolean) {
        this.isDOBValid = value;
    }

    @action setValidDOBError(value: string) {
        this.validDOBError = value;
    }

    //Id - 0  value - none, Id - 1 Value - Reduce 2%, Id - 2 Value - No Renewals, Id - 3 - Leave Renewals
    @observable renewalOptionList =
        [
            { label: 'None', value: '0' },
            { label: 'Reduce 2%', value: '1' },
            { label: 'No Renewals', value: '2' },
            { label: 'Leave Renewals', value: '3' },


        ]
   

    @observable agentRatingList =
        [
            { label: 'None', value: '' },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' },
            { label: 'D', value: 'D' },
            { label: 'E', value: 'E' },
            { label: 'F', value: 'F' }
        ]
       
   
    @observable gender: string = ''
    @observable genderList = [
        { label: 'Not Selected', value: '' },
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' }
    ]

    @observable suffix: string = '0'
    @observable suffixList = new Collection<{
        label: string, value: string
    }>()

    @observable debtLevel: string = '0'
    @observable delbtLevelList = [
        { label: 'None', value: '0' }

    ]
    @observable leadSourceList = [
        { label: 'None', value: '0' }
    ]


    @action setLeadSource(value: string) {
        this.leadSource = value;
    } 
  
    @observable statusList = [
        { label: 'Prospective', value: '6' }
    ]
    @action setFirstName(value: string) {
        this.firstName = value

    }
    @action setMiddleName(value: string) {
        this.middleName = value
    }
    @action setLastName(value: string) {
        this.lastName = value
    }
    @action setStatus(value: string) {
        this.status = value;
    }
   
    @action setProfilPic(value: string) {
        this.profilePicDelete = false;
        this.profilePic = value
        if(value === '1,1')
        {
            this.profilePic= 'assets/layout/images/profile-default.png'
            this.profilePicDelete = true;
            ProfilePicData.data = undefined;
        }
    }
   
    @action setGender(value: string) {
        this.gender = value;
    }
    @action setSuffix(value: string) {
        this.suffix = value;
    }
    @action setCancelConfirmation(value: boolean) {
        this.isCancelConfirm = value;
    }
    @action updateIsAgencySelected = (value: boolean) => {
        this.isAgency = value
    }
    @action setNPN(value: string) {
        this.npn = value;
        if(value === ''){
            this.checkDuplicateAgent();
        }
    }
    @action setAgencyName(value: string) {
        this.agencyName = value;

    }
    @action setTin(value: string) {
        this.tin = value;
        this.isDuplicate = false;
    }
    @action setAgency(value: string) {
        this.agency = value;
    }
  
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    @action setIsDuplicate(value: boolean) {
        this.isDuplicate = value;
    }
    @action setIsDuplicateNPN(value: boolean) {
        this.isDuplicateNPN = value;
    }
    @action setDuplicateError(value: string) {
        this.duplicateError = value;
    }
   
    
    @action setIsSuccess(value: boolean) {
        if(value)
        {
            window.scrollTo(0,0);
        }
        this.isSuccess = value;
    }   
  
    @action setExceptionMessage(value: string) {
        this.exceptionMessage = value;
    }
    @action setDba(value: string) {
        this.dba = value;
    }
    @action setFFMId(value: string) {
        this.ffmId = value;
    }
    @action setWebsite(value: string) {
        this.website = value;
    }
    @action async setSSN(value: string) {
        this.ssn = value;
        this.validateSSN = value.replaceAll('-', '').replaceAll('_', '');
        await this.Validate();
        if (value === '' || this.validateSSN.length == 9){
            this.checkDuplicateAgent();
        }
    }
    @action setAlternativeLastName(value: string) {
        this.alternativeLastName = value;
    }
    @action setNickName(value: string) {
        this.nickName = value
    }
    @action setDOB(value: Date | undefined) {
        this.dateOfBirth = value;
    }
    @action setSmr(value: string) {
        this.smr = value;
    }
    @action setLhr(value: string) {
        this.lhr = value;
    }
    @action setPrincipalAgent(value: string) {
        this.principalAgent = value
    }
    @action setPrincipalAgentId(value: number) {
        this.principalAgentId = value
    }
    @action setSelectedPrincipalAgentId = (value: number) => {
        this.selectedPrincipalAgentId = value
    };
    @action setDeletePrincipalAgentConfirmation(value: boolean) {
        this.isDeletePrincipalAgentConfirm = value
    }
    @action
    public Validate = async () => {
        return this.ErrorModel.Validate(this);
    }
    @action
    public ResetValidate = async () => {
        return this.ErrorModel.ResetValidation(this);
    }
  
    @action
    public ValidateAgency = async () => {
        return this.ErrorModelAgency.Validate(this);
    }
    @action
    public ResetValidateAgency = async () => {
        return this.ErrorModelAgency.ResetValidation(this);
    }
    @action
    public Cancel = async () => {       
            routes.agentSearch.replace()
        }
@action validateMiddleName() {
    if(!this.isAgency){
        //validation for middleName
        var middleNameValue = ''
        var MName: any[] = []
        if (this.middleName) {
            middleNameValue = this.middleName.toLowerCase().trim()
            MName = middleNameValue.split('');
        
            if (middleNameValue && MName && (["dba", "llc", "agency", "jr", "sr", "||", "inc"].indexOf(middleNameValue) !== -1 || middleNameValue.length < 2 || ["'", "-"].includes(MName[0]) || ["'", "-"].includes(MName[MName.length - 1]) || (middleNameValue.split(" ").length-1) > 1))
        {
            this.setIsmiddleNameValid(true);
            this.setValidmiddleNameError('Middle Name is Invalid');
        } 
        else {
            this.setIsmiddleNameValid(false);
            this.setValidmiddleNameError('');
        }
        } else {
            this.setIsmiddleNameValid(false);
            this.setValidmiddleNameError('');
        }
    }
}
@action validateLastName() {
    if(!this.isAgency){
        //validation for lastName
        var lastNameValue = ''
        var LName: any[] = []
        if (this.lastName) {
            lastNameValue = this.lastName.toLowerCase().trim()
            LName = lastNameValue.split('');
        
            if (lastNameValue && LName && (["dba", "llc", "agency", "jr", "sr", "||"].indexOf(lastNameValue) !== -1 ||
               ["'", "-"].includes(LName[0]) || ["'", "-"].includes(LName[LName.length - 1])))
        {
            this.setIslastNameValid(true);
            this.setValidlastNameError('Last Name is Invalid');
        } else {
            this.setIslastNameValid(false);
            this.setValidlastNameError('');
        }
        } else {
            this.setIslastNameValid(false);
            this.setValidlastNameError('');
        }
    }
}
@action validateFirstName()
{
    if(!this.isAgency){
        //validation for firstName
        var firstNameValue = ''
        var FName: any[] = []
        if (this.firstName) {
            firstNameValue = this.firstName.toLowerCase().trim()
            FName = firstNameValue.split('');
        
        if(firstNameValue && FName && ( firstNameValue.length < 2 || ["'", "-"].includes(FName[0]) || ["'", "-"].includes(FName[FName.length - 1])  || (firstNameValue.split(" ").length-1) > 1))
        {
            this.setIsfirstNameValid(true);
            this.setValidfirstNameError('First Name is Invalid');
        } else {
            this.setIsfirstNameValid(false);
            this.setValidfirstNameError('');
        }
        } else {
            this.setIsfirstNameValid(false);
            this.setValidfirstNameError('');
        }
    }
}
    @action addEditAgent = async () => {
        
        await this.ResetValidateAgency()
        await this.ResetValidate()

        PageContext.isMessageVisible = false;
        
        try {
         // Code commented due to AMS-3098
            //     if (this.isDuplicate) {
            //     if (this.isAgency) {
            //       if (this.tin || this.npn) return 0;
            //     } else {
            //       if (this.ssn || this.npn) return 0;
            //     }
            //   } 
            if( this.isDuplicateNPN )  
            {
                return 0;
            }        
          
            this.isLoading = true
            this.isException = false
            this.setIsSuccess(false)
                      
            this.validateFirstName();
            this.validateLastName();

            //validation for nickName
            var nickNameValue = ''
            var NName: any[] = []
            if (this.nickName) {
                nickNameValue = this.nickName.toLowerCase().trim()
                NName = nickNameValue.split('');
            
            if(nickNameValue && NName && (nickNameValue.length < 2 || ["'", "-"].includes(NName[0]) || ["'", "-"].includes(NName[NName.length - 1])))
            {
                this.setIsnickNameValid(true);
                this.setValidnickNameError('Nick Name is Invalid');
            } else {
                this.setIsnickNameValid(false);
                this.setValidnickNameError('');
            }
            } else {
                this.setIsnickNameValid(false);
                this.setValidnickNameError('');
            }
            if (this.isAgency) {
                //validation for agency
                var agencyNameValue = ''
                var AName: any[] = []
                if (this.agencyName) {
                    agencyNameValue = this.agencyName.toLowerCase().trim()
                    AName = agencyNameValue.split('');
                
                if(agencyNameValue && AName && (["dba"].filter(s => s.includes(agencyNameValue)).length !== 0 || agencyNameValue.length < 2 || ["'", "-", "&", ","].includes(AName[0]) || ["'", "-", "&", ","].includes(AName[AName.length - 1])))
                {
                    this.setIsagencyNameValid(true);
                    this.setValidagencyNameError('Agency Name is Invalid');
                } else {
                    this.setIsagencyNameValid(false);
                    this.setValidagencyNameError('');
                }
                } else {
                    this.setIsagencyNameValid(false);
                    this.setValidagencyNameError('');
                }
            }

            //validation for Npn
            var NpnValue = ''
            var NpnName: any[] = []
            if (this.npn) {
                NpnValue = this.npn
                NpnName = NpnValue.split('');
                if(NpnValue && NpnName && NpnName[0] === '0'){
                    this.setIsNpnValid(true);
                    this.setValidNpnError('NPN is Invalid');
                } else {
                    this.setIsNpnValid(false);
                    this.setValidNpnError('');
                }
            } else {
                this.setIsNpnValid(false);
                this.setValidNpnError('');
            }

             //validation for SSN
             if(!this.isAgency){
                var SSNValue = ''
                var SSNName: any[] = []
                if (this.ssn) {                  
                    SSNValue = this.ssn.includes("-") ? this.ssn : this.ssn.substring(0, 3)+'-'+this.ssn.substring(3, 5)+'-'+this.ssn.substring(5, 9)
                    SSNName = SSNValue.split('-');
                    if(SSNValue && SSNName && (SSNName[0] === '000' || SSNName[1] === "00" ||  SSNName[2] === "0000")){
                        this.setIsSsnValid(true);
                        this.setValidSsnError('SSN is Invalid');
                    } else {
                        this.setIsSsnValid(false);
                        this.setValidSsnError('');
                    }
                } else {
                    this.setIsSsnValid(false);
                    this.setValidSsnError('');
                }
            }
             //validation for DBA
            var DbaValue = ''
            var DName: any[] = []
            if (this.dba) {
                DbaValue = this.dba.toLowerCase().trim()
                DName = DbaValue.split('');
            
            if(DbaValue && DName && (["dba", this.agency, this.firstName ? this.firstName.toLowerCase() : '', this.lastName ? this.lastName.toLowerCase() : '', (this.firstName && this.lastName ? (this.lastName+", "+this.firstName).toLowerCase() : ''),(this.firstName && this.lastName ? (this.lastName+','+this.firstName).toLowerCase() : ''),(this.firstName && this.lastName ? (this.firstName+" "+this.lastName).toLowerCase() : '')].filter(s => s && s.includes(DbaValue)).length !== 0 || 
                 DbaValue.length < 2 || ["'", "-", "&", ","].includes(DName[0]) || ["'", "-", "&", ","].includes(DName[DName.length - 1]) ))
            {
                this.setIsDbaValid(true);
                this.setValidDbaError('DBA is Invalid');
            } 
            else {
                this.setIsDbaValid(false);
                this.setValidDbaError('');
            }
            } else {
                this.setIsDbaValid(false);
                this.setValidDbaError('');
            }

            //validation for calender date for age 18
            if (this.dateOfBirth && !this.isAgency) {
                if(this.dateOfBirth && Utils.validateDOB(this.dateOfBirth)){
                    this.setIsDOBValid(true);
                    this.setValidDOBError(Constants.dobValidation);
                }
                else{
                    this.setIsDOBValid(false);
                    this.setValidDOBError('');
                }

            } else {
                this.setIsDOBValid(false);
                this.setValidDOBError('');
            }

            if(this.isfirstNameValid || this.islastNameValid || this.isnickNameValid || this.isDOBValid || this.isDbaValid || this.isagencyNameValid || this.isSsnValid || this.isNpnValid){
                return 0;
            }
        
            if (!await this.ValidateAgency() && this.isAgency) {

                var agencyDto: CreateAgentModel = { lastName: '' }
                var tinValue = ''
                if (this.tin) {
                    tinValue = this.tin.replace(/-/g, '').trim()
                }
                let agencyName = this.agencyName ? this.agencyName.trim() : ''
                
                agencyDto.lastName = Utils.capitalize(agencyName)
                agencyDto.isAgency = this.isAgency
                agencyDto.npn = this.npn
                agencyDto.status = +this.status
                agencyDto.dba = this.dba ? this.dba.trim() : '';
                agencyDto.nickName = this.nickName ? this.nickName.trim() : ''
                agencyDto.leadSource = +this.leadSource
                agencyDto.tin = tinValue
                agencyDto.webSite = this.website
                // agencyDto.federallyFacilitatedMarketplaceUserId = this.ffmId
                agencyDto.w9 = this.w9
                agencyDto.smr = +this.smr
                agencyDto.lhr = +this.lhr
                agencyDto.principalAgentList = this.addPrincipalAgentDto;
                agencyDto.agentGroup = this.selectedGroups;
                try {
                    var agentResponse = await ProducerStore.addAgent(agencyDto)
                    if (agentResponse.error && agentResponse.error.length > 0) {
                        this.setIsDuplicate(true)
                        this.duplicateAgentId = agentResponse.existingAgentId ? agentResponse.existingAgentId : 0
                        this.duplicateError = '' + agentResponse.error
                    }
                    else {
                        this.agentId = agentResponse.agentId ? agentResponse.agentId : 0;
                        googleTagManager.trackAction("agent_created", { feature: "AddAgency", user_id: UserContext.userId, agent_id: this.agentId });
                        IMASLog.log('agency created successfully :' + this.agentId)
                        this.isLoading = false
                        this.isAgencyFromApi = true
                        await this.ResetValidateAgency()
                        this.gotoDetails()
                    }
                }
                catch (error) {
                    this.isLoading = false;
                    this.isException = true;
                    this.exceptionMessage = error.response;
                    return 0

                }
            }

            
            this.validateCustomAttributes();
            if (!this.isAgency && ! await this.Validate() && this.isValid) {
                var ssnValue = ''
                if (this.ssn) {
                    ssnValue = this.ssn.replace(/-/g, '').trim()
                }

                var attrData = localStorage.getItem("CustomAttribute");
                var result = [];
                if (attrData) {
                  result = JSON.parse(attrData);
                  let custAttrToEntityData: any = [];
                  this.custAttrToEntityList.values =[];
                  result.forEach((result: any) => {
                    var data = {
                      customAttributeScreenId: result.custAttrScrnId,
                      entityId: 6,
                      value: result.attrValue+''
                    };
                    if (result.attrValue != null && result.attrValue != "" && result.attrValue != undefined) {
                      custAttrToEntityData.push(data);
                    }
            
                  });
                  this.custAttrToEntityList.values = custAttrToEntityData;
                }

                let firstName = this.firstName ? this.firstName.trim() : ''
                let lastName = this.lastName ? this.lastName.trim() : ''

                let customAttribute: any = [];

                if (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
                    Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
                    customAttribute = await this.createUpdateAttributes();
                } 

                var updateDto: any = {
                    firstName: Utils.capitalize(firstName),
                    lastName: Utils.capitalize(lastName),
                    isAgency: this.isAgency,
                    middleName: this.middleName ? this.middleName : '',
                    status: +this.status,
                    gender: this.gender,
                    suffix: this.suffix !== '0' ? this.suffix : undefined,
                    tin: this.tin,
                    dba: this.dba ? this.dba.trim() : '',
                    dob: this.dateOfBirth,
                    npn: this.npn,
                    nickName: this.nickName ? this.nickName.trim() : '',
                    webSite: this.website,
                    leadSource: +this.leadSource,
                    ssn: ssnValue,
                    federallyFacilitatedMarketplaceUserId: this.ffmId,
                    w9: this.w9,
                    smr: +this.smr,
                    lhr: +this.lhr,
                    agentGroup : this.selectedGroups,
                    customAttribute : customAttribute
                }
                try {                                       
                    var agentResponse = await ProducerStore.addAgent(updateDto)
                    if (agentResponse.error && agentResponse.error.length > 0) {
                        this.setIsDuplicate(true)
                        this.duplicateAgentId = agentResponse.existingAgentId ? agentResponse.existingAgentId : 0
                        this.duplicateError = '' + agentResponse.error
                    }   
                    
                    this.agentId = agentResponse.agentId ? agentResponse.agentId : 0;
                    
                    googleTagManager.trackAction("agent_created", { feature: routes.addAgent.name, user_id: UserContext.userId, agent_id: this.agentId });                       
                    
                    this.isLoading = false;
                    await this.ResetValidate();                        
                    this.setIsSuccess(true);
                    
                    this.gotoDetails();
                } catch (error) {
                    this.isLoading = false;
                    this.isException = true;
                    this.exceptionMessage = error.response;
                    return 0
                }

            }
            this.isLoading = false
        } catch (error) {
            this.isLoading = false;
            this.isException = true;
            this.exceptionMessage = error.response;
            return 0
        }

    }
    
  @action createUpdateAttributes = async () => {
    let data: any = [];
    var attributes = await this.getCustomAttributesByScreen(6);
    (this.customAttributeList || []).map((item: any) => {
        var attribute = (attributes || []).find(item6 => { return item6.attrId === item.attrId; });
        item.attrValue = item.dataTypeId == 6 ? (item.attrValue ? Utils.getDateInFormat(new Date(item.attrValue)) : "") : item.attrValue;
        data.push({
            customAttributeScreenId: attribute && attribute.scrnCustAttrId || 0,
            value: item.attrValue + "",
        });
    });

    return data;
  };
    @action gotoDetails = async () => {        

            try {
                this.isException = false;
                this.isLoading = true;
                let profilePicDTO: UploadProfilePicModel = {};
                if (ProfilePicData.data) {
                  var imageData = ProfilePicData.data.split(",");
                  profilePicDTO.agentId = this.agentId;
                  profilePicDTO.image = imageData[1];
                  await ProducerStore.updateProfilePic(profilePicDTO);
                }            
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;             
            }

        setTimeout(() => {
            routes.agentDetails.replace({ agentId: "" + this.agentId });            
        }, 100);
    }
    @action goToDuplicateAgent()
    {
        
        routes.agentDetails.replace({ agentId: "" + this.duplicateAgentId});
        
    }
    
    private createAgentDto(updateDto: UpdateAgentModel) {
        updateDto.debtLevelId = +this.debtLevel;
        updateDto.id = this.agentId;
        updateDto.gender = this.gender     
       
    }

    private createAgencyDto(agencyDto: UpdateAgentModel) {
        agencyDto.debtLevelId = +this.debtLevel;
        agencyDto.id = this.agentId;
       
    }
    @action searchPrincipalAgents = async (value: string) => {

        try {
            var element = document.querySelector(".p-autocomplete-panel");
            let result = await ProducerStore.getPriniciplaAgentsLookup(value);
            var noPrincipalAgent: PrincipalModelList = { id: 0 };
            if (result) {
                if (result.principalModelLists && result.principalModelLists.length > 0) {
                    result.principalModelLists.splice(0, 0, { id: -1 });
                    this.FilteredAgentsList.values = result.principalModelLists;
                    setTimeout(() => {
                        if (element) {
                            element.scrollTo(0, 0);
                        }
                    }, 10)
                } else {
                    this.noPrincipalAgents();
                }
            } else {
                this.noPrincipalAgents();
            }

        } catch (e) {
            this.noPrincipalAgents()
            this.isLoading = false;
            this.isException = true;
            this.exceptionMessage = e.response;

        }

    }
    @action noPrincipalAgents() {
        var noAgent: PrincipalModelList = { id: 0 };
        this.FilteredAgentsList.values = [noAgent];
    }
    @action createPrincipalAgentModel() {
        var dto: CreatePrincipalModel = {
            principalAgentId: this.principalAgentId,
        };
        return dto;
    }
    @action addPrincipalAgentToAgent = async () => {
        await this.addPrincipalAgent();
    };
    @action singlePrincipalAgentSearchLookUp = async () => {
        if (this.FilteredAgentsList.values.length == 2) { 
            let obj = Utils.getObject(this.FilteredAgentsList.values[1])
            if (obj.id) {
                this.setPrincipalAgent(obj.lastName + ', ' + obj.firstName)
                this.setPrincipalAgentId(obj.id)
            }
            else {
                this.setPrincipalAgent('')
                this.setPrincipalAgentId(0)
                }
            }
    };
    private async addPrincipalAgent() {
        this.singlePrincipalAgentSearchLookUp();
        if (this.principalAgentId > 0) {
            if (this.addPrincipalAgentDto) {
                var principalAgent = this.addPrincipalAgentDto.find((item: any) => {
                    return item.principalAgentId === this.principalAgentId;
                });
                if (!principalAgent) {
                    var dto = this.createPrincipalAgentModel();
                    this.addPrincipalAgentDto.push(dto);
                }
                var principalobj = this.principalAgentsList.values.find((item: any) => {
                    return item.id === this.principalAgentId;
                });
                if (!principalobj) {
                    this.principalAgentsList.values.push({
                        id: this.principalAgentId,
                        principalAgentName: this.principalAgent,
                    });
                }
            }
            else {
                var dto = this.createPrincipalAgentModel();
                this.addPrincipalAgentDto.push(dto);
                this.principalAgentsList.values.push({
                    id: this.principalAgentId,
                    principalAgentName: this.principalAgent,
                });
            }
            this.principalAgentId = 0;
            this.principalAgent = "";
        }
    }
    @action deletePrincipalAgent = async () => {
        this.addPrincipalAgentDto = this.addPrincipalAgentDto.filter((item: any) => {
            return item.principalAgentId != this.selectedPrincipalAgentId;
        });
        this.principalAgentsList.values = this.principalAgentsList.values.filter((item: any) => {
            return item.id != this.selectedPrincipalAgentId;
        });
        this.isDeletePrincipalAgentConfirm = false;
    };
    
    @action resetAll() {
        this.firstName = ''
        this.lastName = ''
        this.middleName = ''
        this.nickName = ''
        this.dba = ''
        this.agencyName = ''
        this.website = ''
        this.ffmId = ''
        this.ssn = ''
        this.validateSSN = ''
        this.npn = ''
        this.leadSource = '0'
        this.gender = ''
        this.dateOfBirth = new Date();
        setTimeout(() => {
            this.dateOfBirth = undefined;              
        }, 100);   
        this.leadSourceList = []
        this.isDuplicateApiHit = false
        this.w9 = undefined;
        this.principalAgent = '';
        this.principalAgentId = 0;
        this.addPrincipalAgentDto = [];
        this.principalAgentsList.values = [];
      this.selectedGroups = [];
    }


    @action checkDuplicateAgent = async () => {           
        if(this.isAgency)
        {
            await this.checkDuplicateAgency()
            return
        }
        this.firstName = this.firstName ? this.firstName.trim() : this.firstName
        this.lastName = this.lastName.trim()
        if (this.firstName && this.lastName) {
            try {
                var ssn = ''
                if(this.ssn)
                {
                ssn = this.ssn.replace(/-/g, '').trim();
                }
                this.isLoading = true
                this.isException = false
                this.setIsDuplicate(false)
                this.setIsDuplicateNPN(false)
                this.firstName = this.firstName.trim()
                this.lastName = this.lastName.trim()
                var result = await ProducerStore.checkAgentDuplicate('' + this.firstName, '' + this.lastName, ssn, 
                this.npn,this.isAgency, this.tin, 0)
                this.isLoading = false
                if (result.isDuplicate) {
                    this.setIsDuplicate(true)
                   this.duplicateAgentId = result.existingAgentId ? result.existingAgentId : 0
                    this.duplicateError = '' + result.error;
                    if(result.isDuplicateNPN)
                    {
                        this.setIsDuplicateNPN(true);
                    }
                }
                else {
                    this.duplicateError = '' + result.error;
                    this.duplicateAgentId = result.existingAgentId ? result.existingAgentId : 0;
                    this.setIsDuplicate(false);
                    this.setIsDuplicateNPN(false);
                }

            }
            catch (e) {
                this.isLoading = false;
                        this.isException = true;
                        this.exceptionMessage = e.response;
                        this.isDuplicateApiHit = false
            }
        }
    }
    @action checkDuplicateAgency = async () => {  
        this.agencyName = this.agencyName.trim()     
        if (this.agencyName) {
            try {
                this.isLoading = true
                var tin = ''
                if(this.tin)
                {
                  tin =  this.tin.replace(/-/g, '').trim()
                }
                this.isException = false
                this.setIsDuplicate(false)
                var result = await ProducerStore.checkAgentDuplicate('firsname', '' + this.agencyName, this.ssn, 
                this.npn,this.isAgency, tin, 0)
                this.isLoading = false;
                if (result.isDuplicate) {
                    this.setIsDuplicate(true)
                 this.duplicateAgentId = result.existingAgentId ? result.existingAgentId : 0
                    this.duplicateError = '' + result.error
                }
                else {
                    this.setIsDuplicate(false)
                    this.duplicateAgentId = result.existingAgentId ? result.existingAgentId : 0
                    this.duplicateError = '' + result.error;
                }

            }
            catch (e) {
                this.isLoading = false;
                        this.isException = true;
                        this.exceptionMessage = e.response;
            }
        }
    }

    @action getmaxDate = (dt: Date, n: number) => {
      return new Date();
    }

    
  // custom attribute methods
  @action handleAttributesModal = (value: boolean) => {
    this.CustomAttributesComponentViewModel.disableRadioInitial = true;
    this.addAttributesModal = value; 
    setTimeout( async() => {
      value && this.CustomAttributesComponentViewModel.loadExistingAttributes(5);
      !value && this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900); 
  }

  @action AttributeSave =async (type:number) =>{
    if(type == 2){     
        Utils.showSuccessToaster(`The custom attribute has been applied successfully.`, 7000, 500);
        let reqData:any=[];
        // Map custom Attribute to Add Agent Screen
        this.CustomAttributesComponentViewModel.selectedExistingAttributesList.map(itm=>{
            reqData.push({
            pathId: 5,
            attrId:itm,
            visible: true
            } )
        })          
        let res = await CustomAttributeStore.reuseCustomAttributes(reqData);               
        this.CustomAttributesComponentViewModel.selectedExistingAttributesList = [];
        reqData=[];
    } else {
        let data;
        let tempData = (this.CustomAttributesComponentViewModel.defaultValue.values || []).map((Items: any) => {
            Items.value = 0;
            delete Items.duplicateValue;
            delete Items.isDuplicate;
            return Items;
        });

        data = {
            screenId: this.attributesScreenPath,
            name: this.CustomAttributesComponentViewModel.newAttributeName,
            dataTypeId: this.CustomAttributesComponentViewModel.attributeDataType,
            active: true,
            // values: this.newAttributeValues,
            values: tempData || '',
            description: this.CustomAttributesComponentViewModel.newAttributeDescription,
            isPrivate: this.CustomAttributesComponentViewModel.selectedAttributeSecurityType != 1,
            isMandatory: this.CustomAttributesComponentViewModel.selectedAttributeMandatoryType == 1,
            visible: true
        };
        var resSave = await CustomAttributeStore.createCustomAttributes(data);
        if (resSave && resSave.attrId) {
            Utils.showSuccessToaster(`The custom attribute '${this.CustomAttributesComponentViewModel.newAttributeNameTmp}' has been added successfully.`, 7000, 500);
        }
        let reqData: any = [];
        reqData.push({
            pathId: 5,
            attrId: resSave.attrId,
            visible: true
        });
        let res = await CustomAttributeStore.reuseCustomAttributes(reqData);
    }
    this.addAttributesModal = false;
    setTimeout( async() => {
    var attributes = await this.getCustomAttributesByScreen(5);
    if (attributes) { 
        this.customAttributeList = attributes;
    } 
    }, 900); 
    this.CustomAttributesComponentViewModel.resetAttributes(); 
  }

  @action async getCustomAttributePermissionByScreen() {
    try {
      var res = await CustomAttributeStore.getAllScreens();
      return res;
    }
    catch (e) {
      throw e;
    }

  }

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(screenId);
      return res;
    }
    catch (e) {
      throw e;
    }

  }
  @action public validateCustomAttributes = async () => {
    PageContext.isCustomAttribute = false;
    this.response = "";
    var attrData = localStorage.getItem("CustomAttribute");
    var result = [];
    this.isValid = true;
    if (attrData) {
      result = JSON.parse(attrData);
      var mandatoryCustomAttributeData = result.filter((x: any) => x.isMandatory == true && (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined));
      if (mandatoryCustomAttributeData != null && mandatoryCustomAttributeData.length > 0 && (Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute))) {
        PageContext.isCustomAttribute = true;
        this.isValid = false;
        window.scrollTo(0, 0);
        var responseModel = {
          status: 400,
          title: Constants.CustomAttributeValidationMessage,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
      }
    }
  }

   
}

class AddAgentValidator extends AbstractValidator<AddAgentViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.firstName)
            .isNotEmpty()
            .withFailureMessage("First Name is required");
        this.validateIfString(input => input.lastName)
            .isNotEmpty()
            .withFailureMessage("Last Name is required");
        this.validateIfString(input => input.leadSource)
            .isNotEmpty()
            .isNotEqualTo('0')
            .withFailureMessage("Lead Source is required");

        this.validateIfString(input => input.validateSSN)
            .hasMinLength(9).whenNotEmpty()
            .withFailureMessage("Please enter 9 numerals for SSN");
    }
}

class AddAgencyValidator extends AbstractValidator<AddAgentViewModel>{
    public constructor() {
        super();

        this.validateIfString(input => input.agencyName)
            .isNotEmpty()
            .withFailureMessage("Agency Name is required");
    }
}
