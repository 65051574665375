// mobx
import { observable, action } from "mobx";
// store
import WorkflowStore from "../../store/WorkflowStore";
// ViewModel
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
// route
import RouteList from "../../infrastructure/RouteList";
// models
import { WorkflowDocumentListModel, WorkflowNotesResponse, AddDocumentNoteModel } from "../../services/WorkflowService"; 
// other
import IMASLog from "../../infrastructure/IMASLog";
import { Constants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { Permission } from "../../infrastructure/enum/Permission";
import Utils from "../../infrastructure/Utils";


export class WorkflowViewModel implements ViewModel {
    // ViewModel functions implementation
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async (id: number) => {
        this.agentId = id;
        this.resetPagingValues();
        this.title = "";
        this.rows = 25;
        if(Utils.hasUserPermission(Permission.ViewWorkflowTabOnAgentScreen))
        {
         await this.loadDocs();
        }
    };

    // declaration for route
    Route = async (currentRoute: RouteList): Promise<void> => { }
    // declaration for variables
    // route id's
    @observable agentId: number = 0;
    @observable docId: number = 0;

    // paging 
    @observable rows: number = 25; // rows is pagesize
    @observable totalRecords: number = 0;
    @observable first: number = 0; // first is pageindex
    @observable startIndex: number = 0;

    // loader
    @observable isLoading: boolean = true;

    // page input variables
    @observable searchInputValue: string = ''
    @observable searchValue: string | undefined = undefined   
    // @observable sortColumn: string = 'id'
    @observable sortColumn: string = 'date'; //as per sheet provided in 1281
    @observable sortOrder: boolean = false;

    // others
    @observable isException: boolean = false
    @observable isWarning: boolean = false
    @observable title: string = '';
    @observable exceptionMessage: string = Constants.Error;
   // @observable isException: boolean = false
    @observable isSuccess: boolean = false
  //  @observable isLoading: boolean = false;

    // lists variables
    @observable documentList = new Collection<WorkflowDocumentListModel>();
    @observable docWorkFlowHistoryList = new Collection<WorkflowNotesResponse>()
    @observable companyList = new Collection<{
        label: string, value: string
    }>()
    @observable documentTypeList = new Collection<{
        label: string, value: string
    }>()
    @observable docNotes: string = ''; 

    // Workflow side panel variables
    @observable isSidebarLogVisible: boolean = false;
    @observable selectedDocRow: any = {};

    // Functions implementation
    @action resetPagingValues() {
        this.rows = 25;
        this.totalRecords = 0;
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'id';
        this.sortColumn = 'date'; //as per sheet provided in 1281
        this.sortOrder = false;
    }
    @action loadDocs = async () => {
        this.isException = false;
        try {            
            var result = await WorkflowStore.getAgentsWorkflow(this.agentId, this.searchValue, this.startIndex, this.rows, this.sortColumn, this.sortOrder)
            if (result != null) {
                if (result.data) {
                    this.totalRecords = (result.recordCount) ? result.recordCount : 0;

                    this.documentList.values = result.data;
                }
                else {
                    this.documentList.values = [];
                    this.totalRecords = 0;
                }
            }
        } catch (e) {

            IMASLog.log('exception from store: ' + e.value);
            this.documentList.values = [];
            this.totalRecords = 0;
            this.isException = true;
        }
        this.isLoading = false;

    }
    // show document workflow history
    @action loadDocumentWorkFlowHistory = async (documentId: number) => {
        try {
            var result = await WorkflowStore.getDocWorkFlowHistory(documentId);
            if (result) {
                var output: any = [];
                output = result;
                this.docWorkFlowHistoryList.values = output;

                this.setSidebarLogVisibility(true);
            }
            else {
                this.docWorkFlowHistoryList.values = [];
            }

        } catch (e) {

            IMASLog.log('exception from store: ' + e.value);
            this.docWorkFlowHistoryList.values = [];
            this.isException = true;
            this.setSidebarLogVisibility(false);
        }
        this.isLoading = false;
    }
    // Events
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadDocs();
    }   
    @action setSelectedDoc = (docId: number) => {

        this.docId = docId;
    }
    @action setSelectedRow = (value: any) => {

        this.selectedDocRow = value;
    }
    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    @action setIsWarning(value: boolean) {
        this.isWarning = value;
    }
    // events for workflow
    @action showSidebarDocumentLog(value: any) {
        this.selectedDocRow = value;
        var obj = JSON.parse(JSON.stringify(value))
        var id = obj.id;
        // to clear the validation error message
        this.docNotes = "";
        this.ResetValidate();
        this.loadDocumentWorkFlowHistory(id);
    }
    @action setSidebarLogVisibility(value: boolean) {
        this.isSidebarLogVisible = value
    }
    @action loadDocument = (base64: string) => {
        let win = window.open("", "_blank");
        let html = '';
        html += '<html>';
        html += '<body style="margin:0!important">';
        html += '<div style="width: 100%;height: 100%; position: relative; ">';
        html += '<div style="width: 100%;height: 100%; position: absolute;top: 0;left: 0; z-index: 10;">';
        html += '<embed width="100%" height="100%" src="data:application/pdf;base64,' + base64 + '" type="application/pdf" />';
        html += '</div>';
        html += '</div>';
        html += '</body>';
        html += '</html>';
        setTimeout(() => {
            if (win !== null)
                win.document.write(html);

        }, 0);
    }
    @action downloadDocument = (result: any) => {
        if (result.pdfData) {
            var link = document.createElement("a");
            let n = result.filename ? result.filename.split(".")[0] : "";
            link.href = "data:application/pdf;base64," + result.pdfData;
            link.textContent = "Download PDF";
            link.download = n !== "" ? n : "document.pdf";
            link.click();
            document.body.appendChild(link); 
        }
    }
    @action openDocumentLink = async (docId: number) => {
        this.isWarning = false;
        this.isException = false;
        try {
            window.open(window.location.origin + "/workflowviewer/"+docId, '_new');
        } catch (e) {
            var response = e.response;
            if (response !== undefined && response !== null) {
                var obj = JSON.parse(response);
                IMASLog.log('exception from store: ' + obj.title);
                if (obj.status === 500 && obj.title.includes("PDF File for document")) {
                    this.exceptionMessage = "PDF File for this document not found!"
                    this.isWarning = true;
                    setTimeout(() => {
                        this.isWarning = false;
                        this.exceptionMessage = ''
                    }, 2500)
                }
                else {
                    this.isException = true;
                }
            }
            else {
                this.isException = true;
            }
        }
    }
    @action downloadDocumentLink = async (docId: number) => {       
        this.isWarning = false;
        this.isException = false;
        try {
            var result = await WorkflowStore.downloadWorkflowDocument(docId);
            if (result != null) {
                if (result.pdfData) {
                    this.downloadDocument(result);
                }
                else {
                    this.exceptionMessage = "PDF File for this document not found!"
                    this.isWarning = true;
                    setTimeout(() => {
                        this.isWarning = false;
                        this.exceptionMessage = ''
                    }, 2500)
                }
             }
          } catch (e) {
            var response = e.response;
            if (response !== undefined && response !== null) {
                var obj = JSON.parse(response);
                IMASLog.log('exception from store: ' + obj.title);
                if (obj.status === 500 && obj.title.includes("PDF File for document")) {
                    this.exceptionMessage = "PDF File for this document not found!"
                    this.isWarning = true;
                    setTimeout(() => {
                        this.isWarning = false;
                        this.exceptionMessage = ''
                    }, 2500)
                }
                else {
                    this.isException = true;
                }
            }
            else {
                this.isException = true;
            }
        }    
    }
     
    // event for reset
    @action
    public ResetValidate = async () => {
        return this.ErrorModel.ResetValidation(this);
    }
    @action resetFilters = () => {       
        this.resetPagingValues();
        this.loadDocs();
    } 
    @action setDocNotes(value: string) {
        this.docNotes = value;
    }
    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }
    @action
    public Validate = async () => {
        return this.ErrorModel.Validate(this);
    }
    @action
    public Submit = async () => {
        if (!await this.Validate()) {
            this.isLoading = true
            try {
                var notesdto: AddDocumentNoteModel = {}
                notesdto.notes = this.docNotes;                             
                await WorkflowStore.addWorkflowDocumentNotes(this.docId, notesdto)
                IMASLog.log('add notes is successfull..')
                this.setIsSuccess(true);                         
                this.isLoading = false;
                setTimeout(() => {
                   
                }, 500);
                this.loadDocumentWorkFlowHistory(this.docId);
                this.docNotes = "";

            } catch (error) {
                this.isLoading = false;
                this.isException = true;
                IMASLog.log('exception: ' + error)
            }
        }
    }
    @observable
    public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<WorkflowViewModel>{
    public constructor() {
        super();      
        this.validateIfString(input => input.docNotes)
            .isNotEmpty()
            .isNotEqualTo('NONE')
            .withFailureMessage("Notes is required."); 
    }
}