import { AbstractValidator } from "fluent-ts-validator";
import { observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import { CarrierGroupModel } from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";

export class CarrierGroupAddEditViewModel extends BaseAddEditViewModel {

    @observable name: string = "";

    constructor()
    {
        super("Carrier Group", routes.listCarrierGroups, new CarrierGroupValidator());
    }

    protected toServiceModel(): CarrierGroupModel {
        return {
            id: this.selectedId,
            groupName: this.name
        };
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await ContractsStore.getCarrierGroupById(id);
        if(item) {
            this.selectedId = item.id || 0;
            this.name = item.groupName || "";
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await ContractsStore.addCarrierGroup(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await ContractsStore.updateCarrierGroup(item);
    }
}

class CarrierGroupValidator extends AbstractValidator<CarrierGroupAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.name)
            .isNotEmpty()
            .withFailureMessage("Name is required");
    }
}