import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import { BankDepositSearchResponse } from "../../../../services/AccountingService";
import AccountingStore from "../../../../store/AccountingStore";
import ContractsStore from "../../../../store/ContractsStore";
import Utils from "../../../../infrastructure/Utils";
import UserContext from "../../../../infrastructure/UserContext";
import PageContext from "../../../../infrastructure/PageContext";

export class CarrierBankDepositListViewModel extends BaseListViewModel<BankDepositSearchResponse> {
  @observable linkTypeId: string = "0";
  @observable CarrierBankDepositTypes = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedCompany: number = 0;
  @observable selectedBank: number = 0;
  @observable toDate: Date | undefined = undefined;
  @observable fromDate: Date | undefined = undefined;
  @observable companyList = new Collection<{ label: string; value: string }>();
  @observable bankAccountList = new Collection<{
    label: string;
    value: string;
  }>();

  @action setToDate(value: Date | undefined) {
    this.toDate = value;
  }
  @action setFromDate(value: Date | undefined) {
    this.fromDate = value;
  }

  constructor() {
    super(
      "Revenue",
      "Revenue",
      routes.addCarrierBankDeposit,
      routes.editCarrierBankDeposit,
      "id",
      "statementDate",
      false
    );
    this.pageSize = 50;
  }

  protected async loadLookups() {
    var items = await ContractsStore.getAllCompanyLookup();
    Utils.mapListItemsToDropdown(items, this.companyList, "Select Carrier");

    items = await AccountingStore.getBankAccountLookup();
    items = items.filter(function (e) {
      return e.text !== "ARC" && e.text !== "IMDirect";
    });
    Utils.mapListItemsToDropdown(
      items,
      this.bankAccountList,
      "Select Paid Entity"
    );
  }
  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    PageContext.setIsLoadingVisible(false);
    return await AccountingStore.getCarrierBankDeposits(
      this.selectedCompany > 0 ? this.selectedCompany : undefined,
      this.fromDate,
      this.toDate,
      this.selectedBank > 0 ? this.selectedBank : undefined,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }

  protected async deleteItem(id: number) {
    PageContext.setIsLoadingVisible(true);
    await AccountingStore.deleteCarrierBankDepositDetail(id);
  }

  @action search = async () => {
    this.resetPaging();
    this.loadItems();
  };
  @action reset = async () => {
    this.resetPaging();
    this.resetFiltersToDefault();
    this.loadItems();
  };

  resetFiltersToDefault() {
    this.selectedCompany = 0;
    this.selectedBank = 0;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.pageSize = 50;
  }

  @action downloadExcelReport = async () => {
    if (UserContext.userId && UserContext.userId > 0) {
      let res = await AccountingStore.getCarrierBankDepositsReport(
        this.selectedCompany > 0 ? this.selectedCompany : undefined,
        this.fromDate,
        this.toDate,
        this.selectedBank > 0 ? this.selectedBank : undefined
      );
      res.fileName = "Revenue_Report.xlsx";
      Utils.saveDownloadedReportFile(res, "Revenue_Report");
    }
  };
}
