import { getDateMeta } from "@fullcalendar/react";
import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import PageContext from "../../../../infrastructure/PageContext";
import RouteParamDetails from "../../../../infrastructure/RouteParamDetails";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { RenewalModel } from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";
import InsuredStore from "../../../../store/InsuredStore";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";

export class RenewalAddEditViewModel extends BaseAddEditViewModel {
    @observable name: string = "";
    @observable policyNumber: string = "";
    @observable firstName: string = "";
    @observable lastName: string = "";
    @observable product: string = "";
    @observable carrier: string = "";
    @observable mode: string = "";
    @observable flatRateFactor: string = "";
    @observable issueDate: string = "";
    @observable flatRatePolicyEffectiveDate: Date | undefined;
    @observable flatRatePolicyProductId: number | undefined = 0;
    @observable flatRateMonth: number | undefined;
    @observable useFlatRateMonth: boolean | undefined = false;
    @observable premium: string = "0";
    @observable premiumOverride: number = 0;
    @observable duration: string = "";
    @observable issueAge: string = "";
    @observable carrierId: number | undefined;
    @observable commissionPlanId: string = "";
    @observable statementDate: Date | undefined;
    @observable commissionReceived: string = "";
    @observable commissionPayable: string = "";
    @observable policyPremium: string = "";
    @observable payeesOnPlan: string = "";
    @observable commissionPlanPayees: string = "";
    @observable noteMessage: string = "";
    @observable commissionPlanList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable paymentSourceList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable isFlatRateFactorEditable: boolean = false;
    @observable flatRateFactorCapttion: string = "";
    @observable isProceedConfirmationVisible: boolean = false;
    @observable isBasisFocus: boolean = true;
    @observable isProRateFocus: boolean = true;
    @observable isSaveDisabled: boolean = false;
    constructor() {
        super("Revenue", routes.renewalDashboard, new RenewalValidator());
    }

    protected toServiceModel() {
        var dto: RenewalModel = {
            id: this.selectedId,
            duration: +this.duration,
            renewalCommissionPlanId: +this.commissionPlanId,
            flatRateFactor: +this.flatRateFactor,
            renewalIssueAge: +this.issueAge,
            policyId: RouteParamDetails.policyIdForAddRenewal,
            renewalPremium: this.premium ? Number(this.premium) : 0,
            premiumOverride: this.premiumOverride ? this.premiumOverride : 0,
            statementDate: this.statementDate,
            commissionReceived: this.commissionReceived,
            commissionPayable: +this.commissionPayable,
            payeesOnPlan: +this.payeesOnPlan,
            commissionPlanPayees: this.commissionPlanPayees,
        };
        return dto;
    }
    @action hideProceedConfirmation = () => {
        this.isProceedConfirmationVisible = false;
    };
    @action setBasicFocus = (value: boolean) => {
        this.isBasisFocus = value;
    };
    @action setProRateFocus = (value: boolean) => {
        this.isProRateFocus = value;
    };
    @action proceedWithSameCommReceived = async () => {
        this.hideProceedConfirmation();
        this.Save();
    };
    @action
    public Proceed = async () => {        
        if (!this.isSaveDisabled){
            this.isSaveDisabled = true;
        
        this.isProceedConfirmationVisible = false;
        var isProceed: boolean | undefined = false;
        var policyId = this.policyId;
        try {
            let result = await InsuredStore.getCommisionReceivedAlreadyExist(
                policyId,
                this.selectedId,
                this.commissionReceived,
                this.statementDate ? this.statementDate : new Date()
            );
            if (result) {
                isProceed = result.iscommissionReceivedExist;
            }
        } catch {
            isProceed = false;
        }

        if (!isProceed) {
            this.Save();           
        } else {
            this.isProceedConfirmationVisible = true;
        }
    }
    this.isSaveDisabled = false;
    };
    @action setStatementDate = (value: Date) => {
        this.statementDate = value;
    };
    @action setCommissionReceived = (value: string) => {
        this.commissionReceived = value;
    };

    @action getPolicyIdFromStorage() {
        let policyId = 0;
        var policyIdFromStorage = localStorage.getItem("renewalPolicyId");
        if (policyIdFromStorage) {
            policyId = +policyIdFromStorage;
        }
        return policyId;
    }

    @observable policyId: number = 0;
    protected resetModel = async () => {
        this.resetFields();
        if (RouteParamDetails.policyIdForAddRenewal != 0) {
            await this.getRenewalDetails(
                RouteParamDetails.policyIdForAddRenewal,
                undefined
            );
        }
    };
    @action getRenewalDetails = async (
        policyId: number | undefined,
        renewalId: number | undefined
    ) => {
        this.commissionPlanList.values = [];
        let result = await CommissionStore.getRenewalEditor(policyId, renewalId);
        this.policyId = result.renewalPolicyID || 0;
        this.policyNumber = result.policyNumber ? result.policyNumber : "";
        this.firstName = result.customerFirstName ? result.customerFirstName : "";
        this.lastName = result.customerLastName ? result.customerLastName : "";
        this.product = result.productName ? result.productName : "";
        this.flatRatePolicyProductId = result.flatRatePolicyProductID;
        this.carrier = result.carrierName ? result.carrierName : "";
        this.mode = result.modeName ? result.modeName : "";
        this.flatRateFactor = result.renewalFlatRateFactor
            ? "" + result.renewalFlatRateFactor
            : "";
        this.flatRateMonth = result.flatRateFlatRateMonth;
        this.useFlatRateMonth = result.flatRateUseFlatRateMonth;
        this.issueDate =
            "" + Utils.getDateFormat(result.flatRatePolicyEffectiveDate);
        this.flatRatePolicyEffectiveDate = result.flatRatePolicyEffectiveDate;
        this.premium = result.renewalPremium ? result.renewalPremium + "" : "0";
        this.premiumOverride = result.renewalPremiumOverride
            ? result.renewalPremiumOverride
            : 0;
        this.duration = "" + result.flatRateDuration;
        this.issueAge = "" + result.flatRateRenewalIssueAge;
        this.carrierId = result.carrierId;
        this.commissionPlanId = "" + result.renewalCommissionPlanID;
        this.statementDate = result.statementDate
            ? new Date(result.statementDate)
            : undefined; // || undefined;
        this.commissionReceived = result.commissionReceived
            ? "" + result.commissionReceived
            : "";
        this.commissionPayable = result.commissionPayable
            ? "" + result.commissionPayable
            : "0";
        this.payeesOnPlan = result.payeesOnPlan ? "" + result.payeesOnPlan : "0";
        this.commissionPlanPayees = result.commissionPlanPayees
            ? "" + result.commissionPlanPayees
            : "";
        this.policyPremium = result.policyPremium ? "" + result.policyPremium : "0";
        this.commissionPlanList.values = Utils.mapListItem(result.commissionPlans);
        RouteParamDetails.policyIdForAddRenewal = result.renewalPolicyID
            ? result.renewalPolicyID
            : 0;
        localStorage.setItem("renewalPolicyId", "" + result.renewalPolicyID);
        this.noteMessage = result.revenueNote ? "" + result.revenueNote : "";
    };
    protected async loadItem(id: number): Promise<void> {
        this.flatRateFactorCapttion = "";
        this.isFlatRateFactorEditable = false;
        if (id !== 0 && RouteParamDetails.policyIdForAddRenewal == 0) {
            await this.getRenewalDetails(0, id);
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        PageContext.isAddRenewal = false;
        await CommissionStore.addRenewal(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await CommissionStore.updateRenewal(item);
    }

    @action setFlatRateFactor(value: string) {
        this.flatRateFactor = value;
        this.calCommissionByProRate();
    }

    @action setPremium(value: string) {
        this.premium = value;
    }

    @action setIssueAge(value: string) {
        this.issueAge = value;
    }

    @action setDuration(value: string) {
        this.duration = value;
    }

    @action setPremiumOverride(value: string) {
        this.premiumOverride = Number(value);
    }

    @action calCommissionByProRate() {
        if (this.flatRateFactor == "" || this.flatRateFactor == null)
            this.flatRateFactor = "0";

        if (
            this.flatRateFactor &&
                Number(this.flatRateFactor) >= 0 &&
                Number(this.flatRateFactor) <= 100
                ? true
                : false
        ) {
            if (
                this.isFlatRateFactorEditable &&
                (this.flatRateFactorCapttion == "Pro-rated using Flat Rate Month" ||
                    this.flatRateFactorCapttion == "Pro-rated")
            ) {
                this.getCalculatedCommissionPayable();
            }
        } else {
            if (this.flatRateFactor)
                this.flatRateFactor = this.flatRateFactor.substring(
                    0,
                    this.flatRateFactor.length - 1
                );
        }
    }

    @action getCalculatedCommissionPayable = async () => {
        var result = await CommissionStore.getCalculatedCommissionPayable(
            +this.commissionPlanId,
            this.policyId,
            Number(this.premium),
            this.premiumOverride,
            +this.issueAge,
            +this.duration || 0,
            +this.flatRateFactor
        );
        if (result) {
            this.commissionPayable = "" + result.commissionPayable || "";
            this.payeesOnPlan = "" + result.payeesOnPlan || "";
            this.commissionPlanPayees = "" + result.payees || "";
        }
    };

    @action calculateProRate = async () => {
        var result = await CommissionStore.getRenewalProCalculationRate(
            +this.commissionPlanId,
            +this.issueAge,
            this.flatRatePolicyProductId,
            this.flatRatePolicyEffectiveDate,
            this.flatRateMonth,
            this.useFlatRateMonth,
            +this.duration
        );
        if (result) {
            this.flatRateFactorCapttion = result.resultCaption
                ? result.resultCaption
                : "";
            this.flatRateFactor = result.flatRate ? "" + result.flatRate : "";
            this.isFlatRateFactorEditable = result.isEditable
                ? result.isEditable
                : false;

            if (
                this.isFlatRateFactorEditable &&
                (this.flatRateFactorCapttion == "Pro-rated using Flat Rate Month" ||
                    this.flatRateFactorCapttion == "Pro-rated") &&
                (this.flatRateFactor &&
                    Number(this.flatRateFactor) >= 0 &&
                    Number(this.flatRateFactor) <= 100
                    ? true
                    : false)
            ) {
               await this.getCalculatedCommissionPayable();
            } 
            const ele = document.getElementById("flatRateFactor");
                if (ele) ele.focus();
        }
    };

    @action noteWarning = () => {
        let response = {
            status: 201,
            title: "NOTE: " + this.noteMessage.toString(),
            errors: { "": [] },
        };
        return JSON.stringify(response);
    };
    @action resetFields() {
        this.name = "";
        this.policyNumber = "";
        this.firstName = "";
        this.lastName = "";
        this.product = "";
        this.carrier = "";
        this.mode = "";
        this.flatRateFactor = "";
        this.issueDate = "";
        this.flatRatePolicyEffectiveDate = undefined;
        this.flatRatePolicyProductId = 0;
        this.flatRateMonth = undefined;
        this.useFlatRateMonth = undefined;
        this.premium = "0";
        this.premiumOverride = 0;
        this.duration = "";
        this.issueAge = "";
        this.carrierId = undefined;
        this.commissionPlanId = "";
        this.statementDate = undefined;
        this.commissionReceived = "";
        this.commissionPayable = "";
        this.policyPremium = "";
        this.payeesOnPlan = "";
        this.isFlatRateFactorEditable = false;
        this.flatRateFactorCapttion = "";
        this.noteMessage = "";
        this.isSaveDisabled = false;
    }
}

class RenewalValidator extends AbstractValidator<RenewalAddEditViewModel> {
    public constructor() {
        super();       

        this.validateIfString((input) => input.issueAge)
        .isNotEmpty()
        .withFailureMessage("Issue Age is required");
 
        this.validateIfString((input) => input.commissionPlanId)
            .isNotEmpty()
            .withFailureMessage("Commission Plan is required");
    }
}
