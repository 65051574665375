import { AbstractValidator } from "fluent-ts-validator";
import { observable, action } from "mobx";
import TreeNode from "primereact/components/treenode/TreeNode";
import Collection from "../../infrastructure/CollectionHelper";
import { AutoDialerActionTypes, ResidentStateTypeContants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import Utils from "../../infrastructure/Utils";
import { AgentListModel } from "../../services/ProducerSearchService";
import AutoDialerStore from "../../store/AutoDialerStore";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import ContractsStore from "../../store/ContractsStore";

export class ActiveCallListViewModel {
  @observable marketer: number = 0;
  @observable residentState: any;
  @observable residentStateType: string = ResidentStateTypeContants.State;
  @observable residentZipCode: any = '';
  @observable residentCounty: { [key: string]: { checked?: boolean, partialChecked?: boolean }; } = {};
  @observable licensedState: any;
  @observable leadSource: any;
  @observable licenseStatus: any;
  @observable licensedCompany: any;
  @observable licensedNotwithCompany: any;
  @observable hierarchy: number = -1;
  @observable agentsdownline: number = 0;
  @observable agentsdownlineAgentId: number = 0;
  @observable agentsdownlineAgentName: string = "";
  @observable productSalesHaveNot: boolean = false;
  @observable productSalesFrom: Date = new Date(new Date().getFullYear(), 0, 1);
  @observable productSalesTo: Date = new Date();
  @observable productSalesProductType: any;
  @observable productSalesCompany: any;
  @observable lastSpokenTo: number = 0;
  @observable addZipCode: string = '';
  @observable addZipCodeDistance: number = 100;
  @observable addZipCodeKeepZipCodes: boolean = false;

  @observable marketerList = new Collection<any>();
  @observable residentStateList = new Collection<any>();
  @observable residentCountyList: TreeNode[] = [];
  @observable residentStateTypeList = [
    { label: ResidentStateTypeContants.State, value: ResidentStateTypeContants.State },
    { label: ResidentStateTypeContants.County, value: ResidentStateTypeContants.County },
    { label: ResidentStateTypeContants.ZipCode, value: ResidentStateTypeContants.ZipCode }
  ];
  @observable licensedStateList = new Collection<any>();
  
  @observable stateDropdownList: any = [
    { label: "No Records", value: "UHC", abbreviation: "" },
  ];
  @observable leadSourceList = new Collection<any>();
  @observable licenseStatusList = [
    { label: "Active", value: "1" },
    { label: "Pending", value: "0" }
  ];
  @observable licensedCompanyList = new Collection<any>();
  @observable licensedNotwithCompanyList = new Collection<any>();
  @observable hierarchyList = [
    { label: "Does Not Matter", value: "-1" },
    { label: "Yes", value: "1" },
    { label: "No", value: "0" }
  ];
  @observable productSalesList = new Collection<any>();
  @observable productSalesProductTypeList = new Collection<any>();
  @observable productSalesCompanyList = new Collection<any>();

  @observable marketingEvent: number = 0;
  @observable marketingEventList = new Collection<any>();
  @observable callListName: string = '';

  @action async Load() {
    this.loadAppointmentStates();
    this.resetFields();
    this.getMarketers();
    await this.ResetValidate();
    this.getAllStatesAndCountys(undefined);
  }
  @action async getMarketers() {
    var items = await AutoDialerStore.getMarketers();
    Utils.mapListItemsToDropdown(items, this.marketerList, "Select", "0");
  }
  @observable isLeadSourceLoaded: boolean = false;
  @observable isCompanyLoaded: boolean = false;
  @observable isStatesLoaded: boolean = false;
  @observable isMarketerEventsLoaded: boolean = false;

  @action async getLeadSource(e: any) {
    if (!this.isLeadSourceLoaded) {
      var items = await AutoDialerStore.getLeadSource(true);
      if (items && items.length > 0) {
        this.leadSourceList.values = Utils.mapListItem(items);
        var list: any = [];
        items.forEach((element: any) => { list.push(element.value) });
        this.isLeadSourceLoaded = true;
        this.leadSource = list;
        let target = document.getElementById(e.props.id);
        target && target.click();
      }
    }
  }

  @action loadAppointmentStates = async () => {
    var result = await ContractsStore.appointmentStates();
    if (result != null) {
      if (result) {
        this.stateDropdownList = [];
        this.stateDropdownList.push({
          label: "None",
          value: "",
          abbreviation: "",
        });
        var sortStateList = [...result].sort(Utils.compareListItem);
        for (let item of sortStateList) {
          this.stateDropdownList.push({
            label: "" + item.stateName,
            value: "" + item.abbreviation,
            abbreviation: item.stateName,
            isHeader: item.isHeader,
          });
        }
      }
    }
  };
  @action async getAllStatesAndCountys(e: any) {
    if (!this.isStatesLoaded) {
      var items = await AutoDialerStore.getAllStatesAndCountys();
      if (items.states && items.states.length > 0) {
        var list: any = [];
        var states: any = [];

        items.states.forEach((element: any) => {
          if (!element.isHeader) {
            list.push(element.stateId);
          }
          states.push({ label: element.stateName, value: element.stateId, abbreviation: element.abbreviation, isHeader: element.isHeader });
        });
        this.licensedStateList.values = states;
        this.residentStateList.values = states;
        this.licensedState = list;
        this.residentState = list;
        let target = e && document.getElementById(e.props.id);
        target && target.click();
      }
      this.isStatesLoaded = true;
      if (items.countiesByState) {
        this.residentCountyList  = [];
        for (const [key, value] of Object.entries(items.countiesByState)) {

          let childrennodes: TreeNode[] = [];
          let tree: any;
          value.forEach((element: any) => {
            tree = {
              key: key + '-' + element,
              label: element,
              data: true
            } as TreeNode;
            childrennodes.push(tree);
          });
          tree = {
            key: key,
            label: key,
            data: true,
            children: childrennodes
          } as TreeNode;
          this.residentCountyList.push(tree)
        }
      }
    }
  }
  @action async getCompanies(e: any) {
    await this.ResetValidate();
    if (!this.isCompanyLoaded && !await this.Validate()) {
      var items = await AutoDialerStore.getCompanyAndProductSubCategory(this.marketer);
      if (items.companies && items.companies.length > 0) {
        var companies: any = [];
        var list: any = [];
        items.companies.forEach((element: any) => {
          companies.push({ label: element.text, value: element.value });
          list.push(element.value);
        });
        this.licensedNotwithCompanyList.values = companies;
        this.licensedCompanyList.values = companies;
        this.productSalesCompanyList.values = companies;
        //this.licensedNotwithCompany = list;
        this.licensedCompany = list;
        this.productSalesCompany = list;
      }
      if (items.subCategories && items.subCategories.length > 0) {
        var productTypes: any = [];
        var plist: any = [];
        items.subCategories.forEach((element: any) => {
          productTypes.push({ label: element.text, value: element.value });
          plist.push(element.value);
        });
        this.productSalesProductTypeList.values = productTypes;
        this.productSalesProductType = plist;
      }
      this.isCompanyLoaded = true;
      let target = document.getElementById(e.props.id);
      target && target.click();
    }
    else {
      await this.Validate() && window.scrollTo(0, 0);
    }
  }

  @action resetFields() {
    this.marketer = 0;
    this.residentState = 0;
    this.residentStateType = ResidentStateTypeContants.State;
    this.licensedState = 0;
    this.leadSource = 0;
    this.licenseStatus = ["1", "0"];
    this.licensedCompany = 0;
    this.licensedNotwithCompany = 0;
    this.hierarchy = -1;
    this.lastSpokenTo = 0;
    this.agentsdownline = 0;
    this.agentsdownlineAgentId = 0;
    this.agentsdownlineAgentName = "";
    this.productSalesHaveNot = false;
    this.productSalesFrom = new Date(new Date().getFullYear(), 0, 1);
    this.productSalesTo = new Date();
    this.productSalesProductType = 0;
    this.productSalesCompany = 0;
    this.residentStateList.values = [];
    this.licensedStateList.values = [];
    this.leadSourceList.values = [];
    this.licensedCompanyList.values = [];
    this.licensedNotwithCompanyList.values = [];
    this.productSalesCompanyList.values = [];
    this.productSalesProductTypeList.values = [];
    this.marketingEvent = 0;
    this.callListName = "";
    this.isLeadSourceLoaded = false;
    this.isStatesLoaded = false;
    this.isCompanyLoaded = false;
  }
  @action resetByMarketer() {
    this.licensedCompany = 0;
    this.licensedNotwithCompany = 0;
    this.productSalesProductType = 0;
    this.productSalesCompany = 0;
    this.licensedCompanyList.values = [];
    this.licensedNotwithCompanyList.values = [];
    this.productSalesCompanyList.values = [];
    this.productSalesProductTypeList.values = [];
    this.isCompanyLoaded = false;
  }
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable licensedCompanyTargetPlaceHolder = "All";
  @action fillterUsers = async (value: string) => {
    var results = await ProducerSearchStore.getAgentsLookup(Utils.getAgentSearchType(value), value, 0, 200, undefined, false);
    if (results !== null) {
      if (results.recordCount) {
        if (results.data) {
          this.FilteredAgentsList.values = results.data;
        } else {
          this.FilteredAgentsList.values = [{ id: 0 }];
        }
      } else {
        this.FilteredAgentsList.values = [{ id: 0 }];
      }
    } else {
      this.FilteredAgentsList.values = [{ id: 0 }];
    }
  }
  @action async setSelectedAgent(agentName: any, agentId: number) {
    if (agentId) {
      this.agentsdownlineAgentName = agentName;
      this.agentsdownlineAgentId = agentId;
    }
    else {
      this.agentsdownlineAgentName = '';
      this.agentsdownlineAgentId = 0;
    }
  }
  @action setSelectedAgentDownline(value: string) {
    this.agentsdownlineAgentName = value;
    this.agentsdownlineAgentId = 0;
  }

  @observable ErrorModel = new ErrorModel(new ActiveCallListValidator());
  @observable validationType: string = AutoDialerActionTypes.PriviewResult;

  @action Validate = async () => {
    return await this.ErrorModel.Validate(this);
  }
  @action ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  }
  @action async getZipcodesByRange() {
    this.validationType = AutoDialerActionTypes.AddZipCode;
    await this.ResetValidate();
    if (!await this.Validate()) {
      this.addZipCode = this.addZipCode && this.addZipCode.split('-').length > 1 ? (this.addZipCode.split('-')[1] === "_____" ? this.addZipCode.split('-')[0] : this.addZipCode) : this.addZipCode;
      var items = await AutoDialerStore.getZipcodesByRange(this.addZipCode, this.addZipCodeDistance, this.addZipCodeKeepZipCodes);
      if (this.residentZipCode && this.residentZipCode.split(',').length > 0) {
        this.residentZipCode.split(',').forEach((element: any) => {
          items = items.filter((zip: any) => { return zip !== element; });
        });
        items = items.filter((zip: any) => { return zip !== ''; });
        this.residentZipCode += (items.length > 0 ? (this.residentZipCode.trim().endsWith(',') ? '' : ',') + items.join(',') : '');
      }
      else {
        if (this.residentZipCode) {
          this.residentZipCode += items.join(',')
        }
        else {
          this.residentZipCode = items.join(',');
        }
        
      }
    }
  }

  @action getMarketerEvents = async (e: any) => {
    // this.isLoading = true;
    if (!this.isMarketerEventsLoaded) {
      try {
        var items = await AutoDialerStore.getMarketerEvents();
        this.marketingEventList.values = []
        Utils.mapListItemsToDropdown(items, this.marketingEventList, "None", "0");
        let target = document.getElementById(e.props.id);
        target && target.click();
        this.isMarketerEventsLoaded = true;
      } catch (e) {
      }
    }
    // this.isLoading = false;
  }
}

class ActiveCallListValidator extends AbstractValidator<ActiveCallListViewModel> {
  public constructor() {
    super();

    this.validateIfNumber(input => input.marketer)
      .isNotEqualTo(0)
      .withFailureMessage("Marketer is required.")
      .when(input => (input.validationType === AutoDialerActionTypes.PriviewResult || input.validationType === AutoDialerActionTypes.GenerateCallList || input.validationType === AutoDialerActionTypes.GenerateIContactList));

    this.validateIfString(input => input.callListName)
      .isNotEmpty()
      .isNotEqualTo('')
      .withFailureMessage("Call list Name is required.")
      .when(input => input.validationType === AutoDialerActionTypes.GenerateCallList);

    this.validateIfString(input => input.addZipCode)
      .isNotEmpty()
      .withFailureMessage("Zip Code is required.")
      .when(input => (input.validationType === AutoDialerActionTypes.AddZipCode));

    this.validateIfNumber(input => input.addZipCodeDistance)
      .isNotEmpty()
      .withFailureMessage("Distance is required.")
      .when(input => (input.validationType === AutoDialerActionTypes.AddZipCode));

      this.validateIfNumber(input => input.addZipCodeDistance)
      .isNotEmpty()
      .isGreaterThan(0)
      .withFailureMessage("Distance should be greater then zero")
      .when(input => (input.validationType === AutoDialerActionTypes.AddZipCode));

  }
}