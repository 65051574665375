import React from 'react';
import { observer } from 'mobx-react';
import { ActiveCallListViewModel } from '../../viewModels/autoDialers/ActiveCallListViewModel';
import { CustomDropdown } from '../components/controls/IMASDropdown';
import { Card } from 'primereact/card';
import { ResidentStateTypeContants } from '../../infrastructure/enum/Constants';
import { CustomInputDate } from '../components/controls/CustomInputDate';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { IMASAutoComplete } from '../components/controls/IMASAutoComplete';
import { agentItemTemplate, groupMultiselectItemsTemplate } from '../components/controls/IMASTemplates';
import Utils from '../../infrastructure/Utils';
import { IMASMultiSelect } from '../components/controls/IMASMultiSelect';
import { CustomInputTextArea } from '../components/controls/IMASInputTextArea';
import { IMASCheckbox } from '../components/controls/IMASCheckbox';
import { Button } from 'primereact/button';
import { IMASTreeNode } from '../components/controls/IMASTreeNode';
import { InputNumber } from 'primereact/inputnumber';
import { Repeater } from '../components/Repeater';
import { IMASFormattedInput } from '../components/controls/IMASFormattedInput';

export const ActiveCallListView: React.FC<{ vm: ActiveCallListViewModel }> = observer(props => {
    const onMarketerChange = (value: string) => {
        props.vm.marketer = parseInt(value);
        props.vm.isCompanyLoaded = false;
        props.vm.resetByMarketer();
    }
    const onResidentStateTypeChange = async (value: string) => {
        if (value === ResidentStateTypeContants.County) {
            await props.vm.getAllStatesAndCountys(undefined);
            props.vm.residentStateType = value;
        } else {
            props.vm.residentStateType = value;
        }

        if ( props.vm.residentStateType !== ResidentStateTypeContants.State) {
            props.vm.residentState = [];
        } 
        if (props.vm.residentStateType !== ResidentStateTypeContants.County) {
            props.vm.residentCounty = {};
        } 
        if (props.vm.residentStateType !== ResidentStateTypeContants.ZipCode) {
            props.vm.residentZipCode = undefined;
            props.vm.addZipCode = "";            
        }
    }
    const onResidentStateChange = (value: any) => {
        props.vm.residentState = value;
    }
    const onResidentCountyChange = (value: any) => {
        props.vm.residentCounty = value;
    }
    const onResidentZipCodeChange = (value: any) => {
        props.vm.residentZipCode = value;
    }
    const onLicenseStateChange = (value: any) => {
        props.vm.licensedState = value;
    }
    const onLeadSourceChange = (value: any) => {
        props.vm.leadSource = value;
    }
    const onLicenseStatusChange = (value: any) => {
        props.vm.licenseStatus = value;
    }
    const onLicensedCompanyChange = (value: any) => {
        props.vm.licensedCompany = value;
        let licenseNotWithCompanyList = props.vm.licensedCompanyList.values.filter((element: any) => { return props.vm.licensedCompany.indexOf(element.value) === -1 });
        props.vm.licensedNotwithCompany = [];
        licenseNotWithCompanyList.forEach((element) => {
            props.vm.licensedNotwithCompany.push(element.value);
        });
    }
    const onLicensedNotwithCompanyChange = (value: any) => {
        props.vm.licensedNotwithCompany = value;
        let licenseCompanyList = props.vm.licensedNotwithCompanyList.values.filter((element: any) => { return props.vm.licensedNotwithCompany.indexOf(element.value) === -1 });
        props.vm.licensedCompany = [];
        licenseCompanyList.forEach((element) => {
            props.vm.licensedCompany.push(element.value);
        });
        props.vm.licensedCompanyTargetPlaceHolder = props.vm.licensedCompany.length === 0 ? "None" : "All";
    }
    const onAddZipCodeChange = (value: string) => {
        props.vm.addZipCode = value;
    }
    const onAddZipCodeDistanceChange = (value: number) => {
        props.vm.addZipCodeDistance = value;
    }
    const onAddZipCodeKeepZipCodeChange = (value: boolean) => {
        props.vm.addZipCodeKeepZipCodes = value;
    }
    const onHierarchyChange = (value: string) => {
        props.vm.hierarchy = parseInt(value);
    }
    const onLastSpokenToChange = (value: number) => {
        props.vm.lastSpokenTo = value;
    }
    const onAgentsdownlineChange = (value: number) => {
        props.vm.agentsdownline = value;
    }
    const onProductSalesHaveNotChange = (value: boolean) => {
        props.vm.productSalesHaveNot = value;
    }
    const onProductSalesFromChange = (value: any) => {
        props.vm.productSalesFrom = value;
    }
    const onProductSalesToChange = (value: any) => {
        props.vm.productSalesTo = value;
    }
    const onProductSalesProductTypeChange = (value: any) => {
        props.vm.productSalesProductType = value;
    }
    const onProductSalesCompanyChange = (value: any) => {
        props.vm.productSalesCompany = value;
    }
    const onSelectAgent = (value: any) => {
        let obj = Utils.getObject(value);
        if (obj.id) {
            props.vm.setSelectedAgent(
                '' + obj.name,
                obj.id
            );
        } else {
            props.vm.setSelectedAgent(undefined, 0);
        }
    };
    const filterUsers = (query: any) => {
        setTimeout(() => {
            props.vm.fillterUsers(query);
        }, 300);
    };
    const itemsTemplate = (option: any) => {
        if (option) {
            return <div className="list-item">{option.label}</div>;
        }
        return <div className="list-item">No Records.</div>;
    };

    // refs all multi-select
    let leadSourceTarget = React.createRef<any>();
    let licensedCompanyTarget = React.createRef<any>();
    let licensedNotWithCompanyTarget = React.createRef<any>();
    let licenseStateTarget = React.createRef<any>();
    let residenseStateTarget = React.createRef<any>();
    let productTypesTarget = React.createRef<any>();
    let companyTarget = React.createRef<any>();

    return (
        <div>
            <div className="p-grid">
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <CustomDropdown
                            errors={props.vm.ErrorModel.getError("marketer")}
                            label="Marketer"
                            placeHolder=""
                            disabled={false}
                            id="marketerDropdown"
                            isrequired={true}
                            onBlur={() => { }}
                            value={props.vm.marketer.toString()}
                            options={props.vm.marketerList.values}
                            onChange={(value) => {
                                onMarketerChange(value);
                            }}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <IMASMultiSelect
                            errors={undefined}
                            label="Lead Source"
                            placeHolder="All"
                            id="leadSourceDropdown"
                            isrequired={false}
                            value={props.vm.leadSource}
                            options={props.vm.leadSourceList.values}
                            itemTemplate={itemsTemplate}
                            onChange={(value) => {
                                onLeadSourceChange(value);
                            }}
                            ref={leadSourceTarget}
                            onFocus={(e) => { props.vm.getLeadSource(leadSourceTarget.current) }}
                            optionLabel={"label"}
                            filter={true}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <IMASMultiSelect
                            errors={undefined}
                            label="Contract Status"
                            placeHolder="All"
                            id="licenseStatusDropdown"
                            isrequired={false}
                            value={props.vm.licenseStatus}
                            options={props.vm.licenseStatusList}
                            itemTemplate={itemsTemplate}
                            onChange={(value) => {
                                onLicenseStatusChange(value);
                            }}
                            optionLabel={"label"}
                            filter={true}
                        />
                    </div>
                </div>


            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <IMASMultiSelect
                            errors={undefined}
                            label="Contracted Carrier"
                            placeHolder={props.vm.licensedCompanyTargetPlaceHolder}
                            id="licensedCompanyDropdown"
                            isrequired={false}
                            value={props.vm.licensedCompany}
                            options={props.vm.licensedCompanyList.values}
                            itemTemplate={itemsTemplate}
                            onChange={(value) => {
                                onLicensedCompanyChange(value);
                            }}
                            ref={licensedCompanyTarget}
                            onFocus={(e) => { props.vm.getCompanies(licensedCompanyTarget.current) }}
                            optionLabel={"label"}
                            filter={true}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <IMASMultiSelect
                            errors={undefined}
                            label="NOT Contracted with these Carriers"
                            placeHolder="None"
                            id="licensedNotwithCompanyDropdown"
                            isrequired={false}
                            value={props.vm.licensedNotwithCompany}
                            options={props.vm.licensedNotwithCompanyList.values}
                            itemTemplate={itemsTemplate}
                            onChange={(value) => {
                                onLicensedNotwithCompanyChange(value);
                            }}
                            ref={licensedNotWithCompanyTarget}
                            onFocus={(e) => { props.vm.getCompanies(licensedNotWithCompanyTarget.current) }}
                            optionLabel={"label"}
                            filter={true}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <IMASMultiSelect
                            errors={undefined}
                            label="Contracted State"
                            placeHolder="All"
                            id="licensedStateDropdown"
                            isrequired={false}
                            value={props.vm.licensedState}
                            options={props.vm.licensedStateList.values}
                            itemTemplate={groupMultiselectItemsTemplate}
                            onChange={(value) => {
                                onLicenseStateChange(value);
                            }}
                            ref={licenseStateTarget}
                            onFocus={(e) => { props.vm.getAllStatesAndCountys(licenseStateTarget.current) }}
                            optionLabel={"label"}
                            filter={true}
                            isGroupedList={true}
                        />
                    </div>
                </div>

            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-12 p-lg-12">
                    <label>Resident Location</label>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4 p-lg-4">
                                <div className="form-group">
                                    <CustomDropdown
                                        errors={undefined}
                                        label="Filter By"
                                        placeHolder=""
                                        disabled={false}
                                        id="residentTypeDropdown"
                                        isrequired={false}
                                        onBlur={() => { }}
                                        value={props.vm.residentStateType.toString()}
                                        options={props.vm.residentStateTypeList}
                                        onChange={(value) => {
                                            onResidentStateTypeChange(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={props.vm.residentStateType === ResidentStateTypeContants.County ? "p-col-12 p-md-8 p-lg-8" : "p-col-12 p-md-4 p-lg-4"} >
                                <div className="form-group">
                                    {props.vm.residentStateType === ResidentStateTypeContants.State &&
                                        <IMASMultiSelect
                                            errors={undefined}
                                            label="State"
                                            placeHolder="All"
                                            id="residentStateDropdown"
                                            isrequired={false}
                                            value={props.vm.residentState}
                                            options={props.vm.residentStateList.values}
                                            itemTemplate={groupMultiselectItemsTemplate}
                                            onChange={(value) => {
                                                onResidentStateChange(value);
                                            }}
                                            ref={residenseStateTarget}
                                            onFocus={(e) => { props.vm.getAllStatesAndCountys(licenseStateTarget.current) }}
                                            optionLabel={"label"}
                                            filter={true}
                                            isGroupedList={true}
                                        />}

                                    {props.vm.residentStateType === ResidentStateTypeContants.County &&
                                        <div style={{ maxHeight: "270px", overflow: "overlay", paddingRight: "20px" }}>
                                            <IMASTreeNode
                                                value={props.vm.residentCountyList}
                                                selectionMode="checkbox"
                                                selectionKeys={props.vm.residentCounty}
                                                onSelectionChange={e => onResidentCountyChange(e)}
                                                filter={true}
                                                expandedKeys={[]}
                                                id="residentCountyDropdown"
                                                filterPlaceholder={"Search"}
                                                className="appsetting-tree"
                                                contentClassName="county-tree"
                                                onExpand={e => { }}
                                                onCollapse={e => { }}
                                            />
                                        </div>}

                                    {props.vm.residentStateType === ResidentStateTypeContants.ZipCode &&
                                        <CustomInputTextArea
                                            errors={undefined}
                                            label="Search Via ZipCodes. Ex: 68710,68700-68709,68800-68899"
                                            placeHolder=""
                                            id="ZipCodeDropdown"
                                            isrequired={false}
                                            rows={10}
                                            maxLength={undefined}
                                            onBlur={() => { }}
                                            value={props.vm.residentZipCode}
                                            onChange={(value) => {
                                                onResidentZipCodeChange(value)
                                            }}
                                        />}
                                </div>
                            </div>
                            <div className={props.vm.residentStateType === ResidentStateTypeContants.County ? "hide" : "p-col-12 p-md-4 p-lg-4"}>
                                {props.vm.residentStateType === ResidentStateTypeContants.ZipCode &&
                                    <div>
                                        <div className="form-group">
                                        <label>Add Zip codes that are within</label>
                                            <InputNumber value={props.vm.addZipCodeDistance} style={{ width: '100%' }} id="distanceTextbox"
                                                required={true} onChange={(e) => onAddZipCodeDistanceChange(e.value)} />
                                            <Repeater viewId='addZipCodeDistance' errors={props.vm.ErrorModel.getError("addZipCodeDistance")} child={(error) => <div className="invalid-feedback">{error}</div>} />
                                        </div>
                                        <div className="form-group">
                                            <IMASFormattedInput
                                                errors={props.vm.ErrorModel.getError("addZipCode")}
                                                format="#####-#####"
                                                mask="_"
                                                label="Miles of"
                                                isrequired={false}
                                                id='zipCodeTextbox'
                                                onBlur={() => ""}
                                                placeHolder=""
                                                value={props.vm.addZipCode}
                                                onChange={(value) => { onAddZipCodeChange(value); }}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <IMASCheckbox
                                                label="Limit Zip Codes to State Boundaries"
                                                id="keepZipCodes"
                                                onChange={(e) => {
                                                    onAddZipCodeKeepZipCodeChange(e.checked)
                                                }}
                                                value={props.vm.addZipCodeKeepZipCodes}
                                                disabled={false}
                                            />
                                        </div>
                                        <Button id="btnZipCode" className='primary-button margin-left-10' icon="fa fa-plus" label="Add Zip Codes" onClick={() => props.vm.getZipcodesByRange()} />
                                    </div>}
                            </div>
                        </div>
                    </Card>
                </div>

            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-4 p-lg-4">
                    <label>Hierarchy</label>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="form-group">
                                    <CustomDropdown
                                        errors={undefined}
                                        label=""
                                        placeHolder=""
                                        disabled={false}
                                        id="hierarchyDropdown"
                                        isrequired={false}
                                        onBlur={() => { }}
                                        value={props.vm.hierarchy.toString()}
                                        options={props.vm.hierarchyList}
                                        onChange={(value) => {
                                            onHierarchyChange(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <RadioButton
                                    name="agentsdownline"
                                    inputId="rb1"
                                    value="0"
                                    checked={props.vm.agentsdownline === 0}
                                    onChange={(value) => {
                                        onAgentsdownlineChange(0);
                                    }} ></RadioButton>
                                <label htmlFor="rb1" className="p-checkbox-label">Agents in this Agent's Downline</label> &nbsp; &nbsp;&nbsp;&nbsp;

                        </div>
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <RadioButton
                                    name="agentsdownline"
                                    inputId="rb2"
                                    value="1"
                                    checked={props.vm.agentsdownline === 1}
                                    onChange={(value) => {
                                        onAgentsdownlineChange(1);
                                    }} >
                                </RadioButton>
                                <label htmlFor="rb2" className="p-checkbox-label">Agents NOT in this Agent's Downline</label>
                            </div>
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="form-group">
                                    <IMASAutoComplete
                                        errors={undefined}
                                        label=""
                                        placeHolder=""
                                        value={props.vm.agentsdownlineAgentName}
                                        onChange={(value: any) => props.vm.setSelectedAgentDownline(value)}
                                        onSelect={onSelectAgent}
                                        itemTemplate={agentItemTemplate}
                                        onComplete={filterUsers}
                                        suggestions={props.vm.FilteredAgentsList.values}
                                        isrequired={false}
                                        disabled={false}
                                        id="agentDownlineDropdown"
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="p-col-12 p-md-8 p-lg-8">
                    <label>Product Sales</label>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div >
                                    <Checkbox
                                        id="chkHaveNot"
                                        checked={props.vm.productSalesHaveNot}
                                        onChange={(e) => {
                                            onProductSalesHaveNotChange(e.checked);
                                        }}
                                    ></Checkbox>{" "}
                                    <label className="p-checkbox-label">Have not sold these carrier products</label>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="form-group">
                                    <CustomInputDate
                                        dateFormat="mm/dd/yy"
                                        label="Sold From"
                                        isrequired={false}
                                        id="soldFromDate"
                                        errors={undefined}
                                        disabled={!props.vm.productSalesHaveNot}
                                        value={props.vm.productSalesFrom}
                                        onChange={(value) => { onProductSalesFromChange(value) }}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="form-group">
                                    <CustomInputDate
                                        dateFormat="mm/dd/yy"
                                        label="Sold To"
                                        isrequired={false}
                                        id="soldToDate"
                                        errors={undefined}
                                        value={props.vm.productSalesTo}
                                        onChange={(value) => { onProductSalesToChange(value) }
                                        }
                                        disabled={!props.vm.productSalesHaveNot}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="form-group">
                                    <IMASMultiSelect
                                        errors={undefined}
                                        label="Product Type"
                                        placeHolder="All"
                                        id="productSalesTypeDropdown"
                                        isrequired={false}
                                        value={props.vm.productSalesProductType}
                                        options={props.vm.productSalesProductTypeList.values}
                                        itemTemplate={itemsTemplate}
                                        onChange={(value) => {
                                            onProductSalesProductTypeChange(value);
                                        }}
                                        ref={productTypesTarget}
                                        onFocus={(e) => { props.vm.getCompanies(productTypesTarget.current) }}
                                        optionLabel={"label"}
                                        filter={true}
                                        disable={!props.vm.productSalesHaveNot}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="form-group">
                                    <IMASMultiSelect
                                        errors={undefined}
                                        label="Product Carrier"
                                        placeHolder="All"
                                        id="productSalesCompanyDropdown"
                                        isrequired={false}
                                        value={props.vm.productSalesCompany}
                                        options={props.vm.productSalesCompanyList.values}
                                        itemTemplate={itemsTemplate}
                                        onChange={(value) => {
                                            onProductSalesCompanyChange(value);
                                        }}
                                        ref={companyTarget}
                                        onFocus={(e) => { props.vm.getCompanies(companyTarget.current) }}
                                        optionLabel={"label"}
                                        filter={true}
                                        disable={!props.vm.productSalesHaveNot}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="p-grid" style={{ marginTop: "-15px" }}>                
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="form-group">
                        <label>Last Spoken To</label>
                        <InputNumber value={props.vm.lastSpokenTo} style={{ width: '100%' }} id="lastSpokenToDropdown"
                            required={true} onChange={(e) => onLastSpokenToChange(e.value)} />
                    </div>
                </div>
            </div>
        </div >
    );
});

export default ActiveCallListView;