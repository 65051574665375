import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  CreateUpdateTaskModel,
  Client as TaskServiceClient,
  CompleteTaskModel,
  CompleteTaskByTaskIdModel,
} from "../services/TaskService";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import { googleTagManager } from "../infrastructure/tracking";

class TaskStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): TaskServiceClient => {
    return ServiceClient.getClient(ServiceType.Task);
  };
  @action getAgentTasks = async (
    agentId: number,
    status: number,
    taskDate: Date,
    taskSearch: string | undefined,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var taskDate1 = taskDate ? new Date(taskDate) : null;
    var result = await this.getClient().getAllTaskByAgentId(
      agentId,
      this.getApiVersion(),
      status,
      taskDate1,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      taskSearch,
      UserContext.getUserId()
    );
    return result;
  };
  @action getUserTasks = async (
    userId: number,
    status: boolean | undefined,
    taskDate: Date,
    taskSearch: string | undefined,
    selectedTeamMenbers: [],
    selectedCreatedByUsers: [],
    selectedCarriers: [],
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var taskDate1 = taskDate ? new Date(taskDate) : null;
    var result = await this.getClient().getAllByUserByUserId(
      userId,
      this.getApiVersion(),
      status,
      taskDate1,
      selectedTeamMenbers.join(","),
      selectedCreatedByUsers.join(","),
      selectedCarriers.join(","),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      taskSearch,
      UserContext.getUserId()
    );
    return result;
  };
  @action getTaskDetails = async (taskId: number) => {
    var result = await this.getClient().getTaskById(
      taskId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action addTask = async (
    agentId: number,
    createUpdateTaskModel: CreateUpdateTaskModel
  ) => {
    var result = await this.getClient().createTask(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      createUpdateTaskModel
    );
    return result;
  };
  @action editTask = async (
    agentId: number,
    createUpdateTaskModel: CreateUpdateTaskModel
  ) => {
    var result = await this.getClient().updateTask(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      createUpdateTaskModel
    );
    return result;
  };

  @action deleteTask = async (taskId: number) => {
    var result = await this.getClient().deleteTask(
      taskId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action downloadUserTasks = async (
    userId: number,
    status: boolean | undefined,
    taskDate: Date,
    taskSearch: string | undefined,
    selectedMembers: [],
    selectedCreatedByUsers: [],
    selectedCarriers: []
  ) => {
    var taskDate1 = taskDate ? new Date(taskDate) : null;
    var result = await this.getClient().downloadAllUserTaskReport(
      userId,
      this.getApiVersion(),
      status,
      taskDate1,
      selectedMembers.join(","),
      selectedCreatedByUsers.join(","),
      selectedCarriers.join(","),
      undefined,
      undefined,
      undefined,
      undefined,
      taskSearch,
      UserContext.getUserId()
    );
    return result;
  };

  @action downloadAgentTasks = async (
    agentId: number,
    status: number,
    taskDate: Date,
    taskSearch: string | undefined
  ) => {
    var taskDate1 = taskDate ? new Date(taskDate) : null;
    var result = await this.getClient().downloadAllAgentTaskReport(
      agentId,
      this.getApiVersion(),
      status,
      taskDate1,
      undefined,
      undefined,
      undefined,
      undefined,
      taskSearch,
      UserContext.getUserId()
    );
    return result;
  };

  @action getCustomerTasks = async (
    customerId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllTaskByCustomerId(
      customerId,
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };
  @action getMyTasks = async (
    status?: number,
    taskDate?: Date | null | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | null | undefined,
    sortAscending?: boolean | undefined
  ) => {
    var taskDate1 = taskDate ? new Date(taskDate) : null;
    let id = UserContext.userId ? UserContext.userId : 0;
    let result = await this.getClient().getMyTasks(
      id,
      this.getApiVersion(),
      status,
      taskDate1,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };

  @action completeTask = async (body: CompleteTaskModel) => {
    googleTagManager.trackAction("task_completed", {
      feature: "Task",
      user_id: UserContext.userId,
      task_id: body.id,
    });
    let result = await this.getClient().completeTask(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action completeTaskByTaskId = async (body: CompleteTaskByTaskIdModel) => {
    googleTagManager.trackAction("task_completed", {
      feature: "Task",
      user_id: UserContext.userId,
      task_id: body.id,
    });
    let result = await this.getClient().completeTaskByTaskId(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action
  public getTeamUsersLookup = async (teamId: number) => {
    var result = this.getClient().getAllActiveTeamUsersLookup(
      teamId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action
  public getCreatedByUsersLookup = async () => {
    var result = this.getClient().getAllUsersLookup(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action
  public getAllTaskLookups = async (teamId: number, includeInactiveUser : boolean) => {
    var result = this.getClient().getAllTaskLookups(
      teamId, includeInactiveUser,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
}
export default new TaskStore();
