import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import { Constants } from "../../../infrastructure/enum/Constants";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import PageContext from "../../../infrastructure/PageContext";
import Utils from "../../../infrastructure/Utils";
import { routes } from "../../../router";
import { ColumnMapModel, ConversionModel, ImportDefinitionModel } from "../../../services/BusinessUnitConfigService";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import ContractsStore from "../../../store/ContractsStore";
import InsuredStore from "../../../store/InsuredStore";
import ProductStore from "../../../store/ProductStore";
import { BaseAddEditViewModel } from "../../components/BaseAddEditViewModel";


export class ImportAddEditViewModel extends BaseAddEditViewModel {

  constructor()
  {
    super("Import", routes.listImports, new ImportValidator());
  }

  @observable headerStartingPoint: string = "0"
  @observable hasMultipleHeader: boolean = false;

  @action toServiceModel = async() =>  {
    var dto: ImportDefinitionModel = {
      id: this.selectedId,
      importType: +this.selectedImportType,
      carrierId: +this.selectedCarrierId,
      importName: this.importName,
      description: this.description,
      fileType: +this.selectedImportFileType,
      delimiter: this.delimeter,
      hasHeader: this.hasHeader,
      headerStartingPoint: +this.headerStartingPoint,
      hasMultipleHeader: this.hasMultipleHeader,
      runAsBackgroundTask: this.hasBackgroundTask,
      columnMaps: await this.getColumnMaps(),
      productConversions: await this.getProductConversions(),
      modeConversions: await this.getModeConversions(),
      durationConversions: await this.getDurationConversions(),
      policyStatusConversions:  await this.getPolicyStatusConversions(),
    };
    return dto;
  }

  @observable importList: any;
  @observable ruleDialog: boolean = false;
  @observable copyDialog: boolean = false;
  @observable rule: any;
  @observable selectedIndex: number = 0;
  @observable importTypes: any  = [
    { label: "Agent Debt", value: "3" },
    { label: "Agent RTS Info", value: "4" },
    { label: "Contract", value: "2" },
    { label: "Lead", value: "6" },
    { label: "Persistancy", value: "5" },
    { label: "Policy Dashboard", value: "0" },
    { label: "Revenue Dashboard", value: "1" },
  ];
  @observable dataTypes: any[]  = [
    { label: "Object", value: "0" },
    { label: "DBNull", value: "1" },
    { label: "Boolean", value: "2" },
    { label: "Char", value: '3' },
    { label: "SByte", value: '4' },
    { label: "Byte", value: '5' },
    { label: "Int16", value: '6' },
    { label: "UInt16", value: '7' },
    { label: "Int32", value: '8' },
    { label: "UInt32", value: '9' },
    { label: "Int64", value: '10' },
    { label: "UInt64", value: '11' },
    { label: "Single", value: '12' },
    { label: "Double", value: '13' },
    { label: "Decimal", value: '14' },
    { label: "DateTime", value: '15' },
    { label: "String",  value: '16' },
  ];
  @observable selectedImportType: string = "0"
  @observable importName: string = "";
  @observable newImportName: string = "";
  @observable selectedCarrierId: number = 0;
  @observable carrierList: any;
  @observable description: string= '';
  @observable importFileTypeList: any  = [
    { label: "Xls", value: "0" },
    { label: "Xlsx", value: "1" },
    { label: "Delimited", value: "2" },
  ];
  @observable delimeterLabel = "Sheet Name";
  @observable selectedImportFileType: string = "0"
  @observable delimeter: string = '';
  @observable hasHeader: boolean = false;
  @observable hasBackgroundTask: boolean = false;
  @observable columnMapList = new Collection<any>();;
  @observable sourceList = new Collection<any>();
  @observable manageConversationView: boolean = false;
  @observable modeList: any  = [
    { label: "Annually", value: "1" },
    { label: "Semi Annually", value: "2" },
    { label: "Quarterly", value: "3" },
    { label: "60 Day Direct", value: "4" },
    { label: "60 Day Auto Withdrawal", value: "5" },
    { label: "Monthly", value: "8" },
    { label: "Two Monthly", value: "9" },
  ];

  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();  

  @observable policyStatusList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable modeConversionList = new Collection<any>();
  @observable productConversionList = new Collection<any>();
  @observable durationConversionList = new Collection<any>();
  @observable policyStatusConversionList = new Collection<any>();
  @observable isModeDelete: boolean = false;
  @observable selectedModeId: string = "";
  @observable selectedProductId: string = "";
  @observable isProductDelete: boolean = false;
  @observable selectedDurationId: string = "";
  @observable isDurationDelete: boolean = false;
  @observable selectedPolicyStatusId: string = "";
  @observable isPolicyStatusDelete: boolean = false;

  protected async loadLookups(): Promise<void> {
    this.manageConversationView = false;
    await this.reset();
    await this.loadCarriers()
    await this.loadSourceColumns();
    await this.loadPolicyStatuses()
  }


  @action loadColumnMapsForAdd = async () => {
    var res = await BusinessUnitConfigStore.getImportColumnDefinitionByType(+this.selectedImportType)
    if(res && res.columns){
      this.columnMapList.values = []
      let newValues: any[] = res.columns;
      newValues.forEach((obj) => {
        obj.source = '-1'
      })
      this.columnMapList.values = newValues;
    }
  }

  @action loadCarriers = async () => {
    var items = await ContractsStore.getAllCompanyLookup();
    this.carrierList = Utils.mapListItemsAsList(items, "");
    this.selectedCarrierId = this.carrierList.length > 0 ? this.carrierList[0].value : ""
    await this.loadProducts();
  }

  @action loadPolicyStatuses = async () => {
    var items = await InsuredStore.getPolicyStatusesLookup();
    this.policyStatusList.values = Utils.mapListItemsAsList(items, "");
  }
  
  @action setRule = () => {
    setTimeout(async() => {
      this.ruleDialog = true;
      let selectedColumn = this.columnMapList.values.filter(obj => obj.columnIndex === this.selectedIndex)
      if(selectedColumn && selectedColumn.length > 0){
        this.rule = selectedColumn[0].rule
      }
    }, 200);
  }

  @action okRule = async() => {
    this.response = ""
    let selectedColumn = this.columnMapList.values.filter(obj => obj.columnIndex === this.selectedIndex)
    if(selectedColumn && selectedColumn.length > 0 && selectedColumn[0].rule){
    var result = await BusinessUnitConfigStore.compileBusinessRule(selectedColumn[0].rule)
      if(result){
        selectedColumn[0].ruleValid = result[0] === "Success" ? true : false;
        if(result[0] === "Success"){
          this.rule = selectedColumn[0].rule
          this.ruleDialog = false;
        } else {
          let response = {
            status: 500,
            title: result[0],
            errors: { "": [] },
          };
          this.response = JSON.stringify(response);
        }
      }
    } else {
      this.ruleDialog = false;
    }
  } 

  @action closeRule = () => {
    let selectedColumn = this.columnMapList.values.filter(obj => obj.columnIndex === this.selectedIndex)
    this.ruleDialog = false;
    if(selectedColumn.length > 0 && !selectedColumn[0].ruleValid ){
      if(this.selectedId === 0){
        selectedColumn[0].rule = ""
      } else {
        let fetchColumn = this.importData.columnMaps.filter((obj: any) => obj.columnIndex === this.selectedIndex)
        if(fetchColumn.length > 0){
         selectedColumn[0].rule = fetchColumn[0].businessRule;
        }
      }
    }
  }

  @action setCopy = async(value: boolean) => {
    this.newImportName = "";
    this.copyDialog = value;
    await this.ResetCopyValidation()
  }

  @action reset = async() => {
    this.headerStartingPoint = "0";
    this.hasMultipleHeader = false;
    this.delimeterLabel = "Sheet Name";
    this.manageConversationView = false
    PageContext.setIsMessageVisible(false);
    this.selectedImportType = "0";
    this.importName = "";
    this.selectedImportFileType = "0";
    this.delimeter = "";
    this.hasHeader = false;
    this.hasBackgroundTask = false;
    this.description = "";
    this.productConversionList.values =[];
    this.modeConversionList.values = [];
    this.durationConversionList.values = [];
    this.policyStatusConversionList.values = [];
    await this.loadCarriers();
    this.columnMapList.values= [];
    if(this.selectedId > 0){
      this.columnMapList.values=[];
    } else {
      await this.loadColumnMapsForAdd();
    }
    this.productDup = false;
    this.modeDup = false;
    this.durationDup = false;
    this.policyStatusDup = false;
    this.help = false;
    this.ruleDialog = false;
  } 

  protected async loadItem(id: number): Promise<void> {
    this.manageConversationView = false;
    setTimeout(async() => {
      if(this.selectedId === 0){
        await this.loadColumnMapsForAdd();
      } else {
        let importType = localStorage.getItem("selectedImportType")
        if(importType)
        await this.loadImports(+importType || 0, id)
      }
    }, 200); 
  }

  @observable importData: any;

  @action loadImports = async(importType: number | undefined, id: number) => {
    var result = await BusinessUnitConfigStore.getImportDefinitionByType(importType, id)
    if(result) {
      this.importData = result;
      this.selectedImportType = ''+result.importType;
      this.selectedCarrierId = result.carrierId || 0;
      await this.loadProducts();
      this.importName = result.importName || '';
      this.description  = result.description || '';
      this.selectedImportFileType = ''+result.fileType;
      this.delimeterLabel = this.selectedImportFileType === "2" ? "Delimiter" : "Sheet Name";
      this.delimeter = result.delimiter || '';
      this.hasHeader = result.hasHeader || false;
      this.hasBackgroundTask = result.runAsBackgroundTask || false;
      this.hasMultipleHeader = result.hasMultipleHeader || false;
      this.headerStartingPoint = ''+result.headerStartingPoint || "0";
      this.columnMapList.values = await this.fetchColumnMaps(result.columnMaps);
      await this.loadConversions();
    }
  }

  @action loadConversions = async() => {
    if(this.selectedId === 0){
      if(this.productDup || this.modeDup || this.durationDup || this.policyStatusDup){
        this.productConversionList.values = [];
        this.modeConversionList.values = [];
        this.durationConversionList.values = [];
        this.policyStatusConversionList.values = [];
      }
    } else {
      this.productConversionList.values = await this.fetchProductConversions(this.importData && this.importData.productConversions ? this.importData.productConversions : []);
      this.modeConversionList.values = await this.fetchModeConversions(this.importData && this.importData.modeConversions ? this.importData.modeConversions : []);
      this.durationConversionList.values = await this.fetchDurationConversions(this.importData && this.importData.durationConversions ? this.importData.durationConversions : []);
      this.policyStatusConversionList.values = await this.fetchPolicyStatusConversions(this.importData && this.importData.policyStatusConversions ? this.importData.policyStatusConversions : []);
    }
  }

  protected async addItem(): Promise<void> {
    var item = await this.toServiceModel();
    await BusinessUnitConfigStore.createOrUpdateImportDefinition(item);
  }

  protected async updateItem(): Promise<void> {
    var item = await this.toServiceModel();
    await BusinessUnitConfigStore.createOrUpdateImportDefinition(item);
  }

  @action loadSourceColumns = () => {
      this.sourceList.values = [];
      this.sourceList.values.push({ label: 'Not Applicable', value: '-1' });
      this.sourceList.values.push({ label: 'Column A (0)', value: '0' });
      this.sourceList.values.push({ label: 'Column B (1)', value: '1' });
      this.sourceList.values.push({ label: 'Column C (2)', value: '2' });
      this.sourceList.values.push({ label: 'Column D (3)', value: '3' });
      this.sourceList.values.push({ label: 'Column E (4)', value: '4' });
      this.sourceList.values.push({ label: 'Column F (5)', value: '5' });
      this.sourceList.values.push({ label: 'Column G (6)', value: '6' });
      this.sourceList.values.push({ label: 'Column H (7)', value: '7' });
      this.sourceList.values.push({ label: 'Column I (8)', value: '8' });
      this.sourceList.values.push({ label: 'Column J (9)', value: '9' });
      this.sourceList.values.push({ label: 'Column K (10)', value: '10' });
      this.sourceList.values.push({ label: 'Column L (11)', value: '11' });
      this.sourceList.values.push({ label: 'Column M (12)', value: '12' });
      this.sourceList.values.push({ label: 'Column N (13)', value: '13' });
      this.sourceList.values.push({ label: 'Column O (14)', value: '14' });
      this.sourceList.values.push({ label: 'Column P (15)', value: '15' });
      this.sourceList.values.push({ label: 'Column Q (16)', value: '16' });
      this.sourceList.values.push({ label: 'Column R (17)', value: '17' });
      this.sourceList.values.push({ label: 'Column S (18)', value: '18' });
      this.sourceList.values.push({ label: 'Column T (19)', value: '19' });
      this.sourceList.values.push({ label: 'Column U (20)', value: '20' });
      this.sourceList.values.push({ label: 'Column V (21)', value: '21' });
      this.sourceList.values.push({ label: 'Column W (22)', value: '22' });
      this.sourceList.values.push({ label: 'Column X (23)', value: '23' });
      this.sourceList.values.push({ label: 'Column Y (24)', value: '24' });
      this.sourceList.values.push({ label: 'Column Z (25)', value: '25' });
      this.sourceList.values.push({ label: 'Column AA (26)', value: '26' });
      this.sourceList.values.push({ label: 'Column AB (27)', value: '27' });
      this.sourceList.values.push({ label: 'Column AC (28)', value: '28' });
      this.sourceList.values.push({ label: 'Column AD (29)', value: '29' });
      this.sourceList.values.push({ label: 'Column AE (30)', value: '30' });
      this.sourceList.values.push({ label: 'Column AF (31)', value: '31' });
      this.sourceList.values.push({ label: 'Column AG (32)', value: '32' });
      this.sourceList.values.push({ label: 'Column AH (33)', value: '33' });
      this.sourceList.values.push({ label: 'Column AI (34)', value: '34' });
      this.sourceList.values.push({ label: 'Column AJ (35)', value: '35' });
      this.sourceList.values.push({ label: 'Column AK (36)', value: '36' });
      this.sourceList.values.push({ label: 'Column AL (37)', value: '37' });
      this.sourceList.values.push({ label: 'Column AM (38)', value: '38' });
      this.sourceList.values.push({ label: 'Column AN (39)', value: '39' });
      this.sourceList.values.push({ label: 'Column AO (40)', value: '40' });
      this.sourceList.values.push({ label: 'Column AP (41)', value: '41' });
      this.sourceList.values.push({ label: 'Column AQ (42)', value: '42' });
      this.sourceList.values.push({ label: 'Column AR (43)', value: '43' });
      this.sourceList.values.push({ label: 'Column AS (44)', value: '44' });
      this.sourceList.values.push({ label: 'Column AT (45)', value: '45' });
      this.sourceList.values.push({ label: 'Column AU (46)', value: '46' });
      this.sourceList.values.push({ label: 'Column AV (47)', value: '47' });
      this.sourceList.values.push({ label: 'Column AW (48)', value: '48' });
      this.sourceList.values.push({ label: 'Column AX (49)', value: '49' });
      this.sourceList.values.push({ label: 'Column AY (50)', value: '50' });
      this.sourceList.values.push({ label: 'Column AZ (51)', value: '51' });
      this.sourceList.values.push({ label: 'Column BA (52)', value: '52' });
      this.sourceList.values.push({ label: 'Column BB (53)', value: '53' });
      this.sourceList.values.push({ label: 'Column BC (54)', value: '54' });
      this.sourceList.values.push({ label: 'Column BD (55)', value: '55' });
      this.sourceList.values.push({ label: 'Column BE (56)', value: '56' });
      this.sourceList.values.push({ label: 'Column BF (57)', value: '57' });
      this.sourceList.values.push({ label: 'Column BG (58)', value: '58' });
      this.sourceList.values.push({ label: 'Column BH (59)', value: '59' });
      this.sourceList.values.push({ label: 'Column BI (60)', value: '60' });
      this.sourceList.values.push({ label: 'Column BJ (61)', value: '61' });
      this.sourceList.values.push({ label: 'Column BK (62)', value: '62' });
      this.sourceList.values.push({ label: 'Column BL (63)', value: '63' });
      this.sourceList.values.push({ label: 'Column BM (64)', value: '64' });
      this.sourceList.values.push({ label: 'Column BN (65)', value: '65' });
      this.sourceList.values.push({ label: 'Column BO (66)', value: '66' });
      this.sourceList.values.push({ label: 'Column BP (67)', value: '67' });
      this.sourceList.values.push({ label: 'Column BQ (68)', value: '68' });
      this.sourceList.values.push({ label: 'Column BR (69)', value: '69' });
      this.sourceList.values.push({ label: 'Column BS (70)', value: '70' });
      this.sourceList.values.push({ label: 'Column BT (71)', value: '71' });
      this.sourceList.values.push({ label: 'Column BU (72)', value: '72' });
      this.sourceList.values.push({ label: 'Column BV (73)', value: '73' });
      this.sourceList.values.push({ label: 'Column BW (74)', value: '74' });
      this.sourceList.values.push({ label: 'Column BX (75)', value: '75' });
      this.sourceList.values.push({ label: 'Column BY (76)', value: '76' });
      this.sourceList.values.push({ label: 'Column BZ (77)', value: '77' });
      this.sourceList.values.push({ label: 'Column CA (78)', value: '78' });
      this.sourceList.values.push({ label: 'Column CB (79)', value: '79' });
      this.sourceList.values.push({ label: 'Column CC (80)', value: '80' });
      this.sourceList.values.push({ label: 'Column CD (81)', value: '81' });
      this.sourceList.values.push({ label: 'Column CE (82)', value: '82' });
      this.sourceList.values.push({ label: 'Column CF (83)', value: '83' });
      this.sourceList.values.push({ label: 'Column CG (84)', value: '84' });
      this.sourceList.values.push({ label: 'Column CH (85)', value: '85' });
      this.sourceList.values.push({ label: 'Column CI (86)', value: '86' });
      this.sourceList.values.push({ label: 'Column CJ (87)', value: '87' });
      this.sourceList.values.push({ label: 'Column CK (88)', value: '88' });
      this.sourceList.values.push({ label: 'Column CL (89)', value: '89' });
      this.sourceList.values.push({ label: 'Column CM (90)', value: '90' });
      this.sourceList.values.push({ label: 'Column CN (91)', value: '91' });
      this.sourceList.values.push({ label: 'Column CO (92)', value: '92' });
      this.sourceList.values.push({ label: 'Column CP (93)', value: '93' });
      this.sourceList.values.push({ label: 'Column CQ (94)', value: '94' });
      this.sourceList.values.push({ label: 'Column CR (95)', value: '95' });
      this.sourceList.values.push({ label: 'Column CS (96)', value: '96' });
      this.sourceList.values.push({ label: 'Column CT (97)', value: '97' });
      this.sourceList.values.push({ label: 'Column CU (98)', value: '98' });
      this.sourceList.values.push({ label: 'Column CV (99)', value: '99' });
      this.sourceList.values.push({ label: 'Column CW (100)', value: '100' });
      this.sourceList.values.push({ label: 'Column CX (101)', value: '101' });
      this.sourceList.values.push({ label: 'Column CY (102)', value: '102' });
      this.sourceList.values.push({ label: 'Column CZ (103)', value: '103' });
      this.sourceList.values.push({ label: 'Column DA (104)', value: '104' });
      this.sourceList.values.push({ label: 'Column DB (105)', value: '105' });
      this.sourceList.values.push({ label: 'Column DC (106)', value: '106' });
      this.sourceList.values.push({ label: 'Column DD (107)', value: '107' });
      this.sourceList.values.push({ label: 'Column DE (108)', value: '108' });
      this.sourceList.values.push({ label: 'Column DF (109)', value: '109' });
      this.sourceList.values.push({ label: 'Column DG (110)', value: '110' });
  }  
  @observable toolTipImage = "assets/layout/images/tooltip.png";
  
  @action setManageConversationView = (value: boolean) => {
    this.manageConversationView = value
    let title = ""
    if(this.carrierList.length > 0){
      let carrierName = this.carrierList.filter((item: any) => ''+item.value === ''+this.selectedCarrierId)
      title = carrierName.length > 0 ? carrierName[0].label : ""
    }
    this.pageTitle = value === true ? "Manage Conversions for "+title : (this.selectedId === 0 ? "Add " + this.itemType : "Edit "+ this.itemType);
  }

  @action onRowSelect = (data: any) => {
    this.selectedIndex = data.columnIndex;
  }

  @action sourceChange = async(value: string, rowData: any) => {
    let newValues: any[] = [];
    this.columnMapList.values.forEach((obj) => {
      if (obj.columnIndex === rowData.columnIndex) {
        obj.source = value;
      }
      newValues.push(obj);
    });
    this.columnMapList.values = newValues;
  }

  @action fetchDataType = async(dataType: number) => {
    let dataTypeSet = this.dataTypes.filter((item: any) => ''+item.value === ''+dataType)
    if (dataTypeSet && dataTypeSet.length > 0){
      return dataTypeSet[0].label
    } else {
      return ''
    }
  }
  
  @action requiredChange = async(value: boolean, rowData: any) => {
    let newValues: any[] = [];
    this.columnMapList.values.forEach((obj) => {
      if (obj.columnIndex === rowData.columnIndex) {
        obj.required = value;
      }
      newValues.push(obj);
    });
    this.columnMapList.values = newValues;
  }

  @action defaultValueChange = async(value: string, rowData: any) => {
    let newValues: any[] = [];
    this.columnMapList.values.forEach((obj) => {
      if (obj.columnIndex === rowData.columnIndex) {
        obj.defaultValue = value;
      }
      newValues.push(obj);
    });
    this.columnMapList.values = newValues;
  }

  @action changeRule = async(value: any) => {
    let selectedColumn = this.columnMapList.values.filter((obj) => obj.columnIndex === this.selectedIndex)
    if(selectedColumn && selectedColumn.length > 0){
      selectedColumn[0].rule = value
    }
  }

  @observable response: any;

  @action testRule = async() => {
    this.response = ""
    let selectedColumn = this.columnMapList.values.filter((obj) => obj.columnIndex === this.selectedIndex)
    if(selectedColumn && selectedColumn.length > 0 && selectedColumn[0].rule){
      var result = await BusinessUnitConfigStore.compileBusinessRule(selectedColumn[0].rule)
      if(result){
        selectedColumn[0].ruleValid = result[0] === "Success" ? true : false;
        let response = {
          status: result[0] === "Success" ? 200 : 500,
          title: result[0],
          errors: { "": [] },
        };
        this.response = JSON.stringify(response);
      }
    }
  }

  @action loadProducts = async() => {
    var defaultItem = { label: "All", value: "0" };
    let res = await ProductStore.getReportProductList(this.selectedCarrierId || 0);
    if (res) {
      this.productList.values = [];
      this.productList.values = Utils.mapListItemsAsList(res, "");
    } else {
      this.productList.values = [];
      this.productList.values.splice(0, 0, defaultItem);
    }
  }

  @action onModeRowSelect = async(rowData: modeConversionData, e: any) => {
    this.selectedModeId = rowData.id
  }

  @action onProductRowSelect = async(rowData: productConversionData, e: any) => {
    this.selectedProductId = rowData.id
  }

  @action addModeConversion = async() => {
    let newValues = this.modeConversionList.values
    let newRow: modeConversionData =  {
      id: 'm' + new Date().getMilliseconds(),
      mode: "",
      modeConFactor: "",
      error: false
    };
    newValues.push(newRow)
    this.modeChange("1", newRow)
    this.modeConversionList.values = [];
    this.modeConversionList.values = newValues;
  }

  @observable modeDup: boolean = false;
 
  @action modeChange = async(value: string, rowData: modeConversionData) => {
    let newValues: modeConversionData[] = [];
    this.modeConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        let dupCheck = this.modeConversionList.values.filter((obj) => obj.mode === value)
        if(dupCheck && dupCheck.length > 0){
          obj.error = true;
        } else {
          obj.error = false;
        }
        obj.mode = value;
      }
      newValues.push(obj);
    });
    this.modeConversionList.values = newValues;
  }  

  @action modeConFactorChange = async(value: string, rowData: modeConversionData) => {
    let newValues: modeConversionData[] = [];
    this.modeConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        obj.modeConFactor = value;
      }
      newValues.push(obj);
    });
    this.modeConversionList.values = newValues;
  }

  @action showModeDeleteConfirmation = async(value: boolean) => {
    this.isModeDelete = value
  }

  @action deletMode = async() => {
    this.modeConversionList.values = this.modeConversionList.values.filter(item => item.id !== this.selectedModeId)
    this.showModeDeleteConfirmation(false)
  }

  @action addProductConversion = async() => {
    let newValues = this.productConversionList.values
    let newRow: productConversionData =  {
      id: 'p' + new Date().getMilliseconds(),
      product: "",
      productConFactor: "",
      error: false
    };
    newValues.push(newRow)
    await this.productChange(this.productList.values.length > 0 ? this.productList.values[0].value : "", newRow)
    this.productConversionList.values = [];
    this.productConversionList.values = newValues;
  }

  @observable productDup: boolean = false;
 
  @action productChange = async(value: string, rowData: productConversionData) => {
    let newValues: productConversionData[] = [];
    this.productConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        let dupCheck = this.productConversionList.values.filter((obj) => obj.product === value)
        if(dupCheck && dupCheck.length > 0){
          obj.error = true;
        } else {
          obj.error = false;
        }
        obj.product = value;
      }
      newValues.push(obj);
    });
    this.productConversionList.values = newValues;
  }  

  @action productConFactorChange = async(value: string, rowData: productConversionData) => {
    let newValues: productConversionData[] = [];
    this.productConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        obj.productConFactor = value;
      }
      newValues.push(obj);
    });
    this.productConversionList.values = newValues;
  }

  @action showProductDeleteConfirmation = async(value: boolean) => {
    this.isProductDelete = value
  }

  @action deleteProduct = async() => {
    this.productConversionList.values = this.productConversionList.values.filter(item => item.id !== this.selectedProductId)
    this.showProductDeleteConfirmation(false)
  }

  @action addDurationConversion = async() => {
    let newValues = this.durationConversionList.values
    let newRow: durationConversionData =  {
      id: 'd' + new Date().getMilliseconds(),
      duration: "",
      durationConFactor: "",
      error: false
    };
    newValues.push(newRow)
    this.durationChange("", newRow)
    this.durationConversionList.values = [];
    this.durationConversionList.values = newValues;
  }

  @observable durationDup: boolean = false;
 
  @action durationChange = async(value: string, rowData: durationConversionData) => {
    let newValues: durationConversionData[] = [];
    this.durationConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        let dupCheck = this.durationConversionList.values.filter((obj) => obj.duration === value)
        if(dupCheck && dupCheck.length > 0){
          obj.error = true;
        } else {
          obj.error = false;
        }
        obj.duration = value;
      }
      newValues.push(obj);
    });
    this.durationConversionList.values = newValues;
  }  

  @action durationConFactorChange = async(value: string, rowData: durationConversionData) => {
    let newValues: durationConversionData[] = [];
    this.durationConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        obj.durationConFactor = value;
      }
      newValues.push(obj);
    });
    this.durationConversionList.values = newValues;
  }

  @action showDurationDeleteConfirmation = async(value: boolean) => {
    this.isDurationDelete = value
  }

  @action deleteDuration = async() => {
    this.durationConversionList.values = this.durationConversionList.values.filter(item => item.id !== this.selectedDurationId)
    this.showDurationDeleteConfirmation(false)
  }

  @action onDurationRowSelect = async(rowData: productConversionData, e: any) => {
    this.selectedDurationId = rowData.id
  }

  @action addPolicyStatusConversion = async() => {
    let newValues = this.policyStatusConversionList.values
    let newRow: policyConversionData =  {
      id: 'ps' + new Date().getMilliseconds(),
      policyStatus: "",
      policyStatusConFactor: "",
      error: false
    };
    newValues.push(newRow)
    this.policyStatusChange(this.policyStatusList.values.length > 0 ? this.policyStatusList.values[0].value : "", newRow)
    this.policyStatusConversionList.values = [];
    this.policyStatusConversionList.values = newValues;
  }

  @observable policyStatusDup: boolean = false;
 
  @action policyStatusChange = async(value: string, rowData: policyConversionData) => {
    let newValues: policyConversionData[] = [];
    this.policyStatusConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        let dupCheck = this.policyStatusConversionList.values.filter((obj) => obj.policyStatus === value)
        if(dupCheck && dupCheck.length > 0){
          obj.error = true;
        } else {
          obj.error = false;
        }
        obj.policyStatus = value;
      }
      newValues.push(obj);
    });
    this.policyStatusConversionList.values = newValues;
  }  

  @action policyStatusConFactorChange = async(value: string, rowData: policyConversionData) => {
    let newValues: policyConversionData[] = [];
    this.policyStatusConversionList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        obj.policyStatusConFactor = value;
      }
      newValues.push(obj);
    });
    this.policyStatusConversionList.values = newValues;
  }

  @action showPolicyStatusDeleteConfirmation = async(value: boolean) => {
    this.isPolicyStatusDelete = value
  }

  @action deletePolicyStatus = async() => {
    this.policyStatusConversionList.values = this.policyStatusConversionList.values.filter(item => item.id !== this.selectedPolicyStatusId)
    this.showPolicyStatusDeleteConfirmation(false)
  }

  @action onPolicyStatusRowSelect = async(rowData: policyConversionData, e: any) => {
    this.selectedPolicyStatusId = rowData.id
  }

  @action getProductConversions = async() => {
    //conversionType is 0 for product conversions
    let productConversions:  ConversionModel[] =  []
    if(this.productConversionList.values.length > 0){
      this.productConversionList.values.forEach((obj) => {
        if(obj.productConFactor){
            let data = {
            conversion: obj.product,
            factor: obj.productConFactor,
            conversionType: 0,
            }
            productConversions.push(data);
        }
      });
    }
    return productConversions;
  }

  @action getModeConversions = async() => {
    //conversionType is 1 for Mode conversions
    let modeConversions:  ConversionModel[] =  []
    if(this.modeConversionList.values.length > 0){
      this.modeConversionList.values.forEach((obj) => {
        if(obj.modeConFactor){
          let data = {
           conversion: obj.mode,
           factor: obj.modeConFactor,
           conversionType: 1,
          }
          modeConversions.push(data);
        }
      });
    }
    return modeConversions;
  }

  @action getDurationConversions = async() => {
    //conversionType is 3 for Duration conversions
    let durationConversions:  ConversionModel[] =  []
    if(this.durationConversionList.values.length > 0){
      this.durationConversionList.values.forEach((obj) => {
        if(obj.durationConFactor){
          let data = {
           conversion: ''+obj.duration,
           factor: obj.durationConFactor,
           conversionType: 3,
          }
          durationConversions.push(data);
        }
      });
    }
    return durationConversions;
  }

  @action getPolicyStatusConversions = async() => {
    //conversionType is 2 for Policy conversions
    let policyStatusConversions:  ConversionModel[] =  []
    if(this.policyStatusConversionList.values.length > 0){
      this.policyStatusConversionList.values.forEach((obj) => {
        if(obj.policyStatusConFactor){
          let data = {
          conversion: obj.policyStatus,
          factor: obj.policyStatusConFactor,
          conversionType: 2,
          }
          policyStatusConversions.push(data);
        }
      });
    }
    return policyStatusConversions;
  }

  @action getColumnMaps = async() => {
    let columnMaps: ColumnMapModel[] = []
    if(this.columnMapList.values.length > 0){
      this.columnMapList.values.forEach((obj) => {
        if(obj.columnName !== Constants.OptionalField){
          let data = {
            sourceColumn: {
              columnIndex: obj.columnIndex,
              columnName: obj.source,
              dataType: +obj.dataType,
              required: obj.required,
              defaultValue: obj.defaultValue,
              displayOrder: obj.displayOrder || 0,
            },
            targetColumn: {
              columnIndex: obj.columnIndex,
              columnName: obj.columnName,
              dataType: +obj.dataType,
              required: obj.required,
              defaultValue: obj.defaultValue,
              displayOrder: obj.displayOrder || 0,
            },
            businessRule: obj.ruleValid ? obj.rule : ""
          }
          columnMaps.push(data);
        }
      });
    }
    return columnMaps;
  }

  @action fetchProductConversions = async(pc: any) => {
    let newValues: any[] = [];
    let id=0;
    pc.forEach((obj: any) => {
      let data = {
        id: 'p' + id,
        product: obj.conversion,
        productConFactor: obj.factor,
        error: false
      }
      newValues.push(data);
      id++;
    });
    return this.productConversionList.values = newValues;
  }

  @action fetchModeConversions = async(pc: any) => {
    let newValues: any[] = [];
    let id=0;
    pc.forEach((obj: any) => {
      let data = {
        id: 'm' + id,
        mode: obj.conversion,
        modeConFactor: obj.factor,
        error: false
      }
      newValues.push(data);
      id++;
    });
    return this.modeConversionList.values = newValues;
  }

  @action fetchDurationConversions = async(pc: any) => {
    let newValues: any[] = [];
    let id= 0;
    pc.forEach((obj: any) => {
      let data = {
        id: 'd' + id,
        duration: obj.conversion,
        durationConFactor: obj.factor,
        error: false
      }
      newValues.push(data);
      id++;
    });
    return this.durationConversionList.values = newValues;
  }

  @action fetchPolicyStatusConversions = async(pc: any) => {
    let newValues: any[] = [];
    let id=0;
    pc.forEach((obj: any) => {
      let data = {
        id: 'ps' + id,
        policyStatus: obj.conversion,
        policyStatusConFactor: obj.factor,
        error: false
      }
      newValues.push(data);
      id++;
    });
   return this.policyStatusConversionList.values = newValues;
  }

  @action fetchColumnMaps = async(pc: any) => {
    let newValues: any[] = [];
    pc.forEach((obj: any) => {
      let data = {
        columnIndex: obj.targetColumn.columnIndex,
        source: obj.sourceColumn ? obj.sourceColumn.columnName : '-1',
        columnName: obj.targetColumn.columnName,
        dataType: ''+obj.targetColumn.dataType,
        required: obj.targetColumn.required,
        defaultValue: obj.targetColumn.defaultValue,
        rule: obj.businessRule,
        ruleValid: true,
        displayOrder: obj.targetColumn.displayOrder,
      }
      newValues.push(data);
    });
   return this.columnMapList.values = newValues;
  }

  @observable
  public ErrorModelCopy = new ErrorModel(new CopyImportValidator());


  @action public ResetCopyValidation = async () => {
    return await this.ErrorModelCopy.ResetValidation(this);
  }

  @action checkCopyValid = async () => {
    var hasErrors = await this.ErrorModelCopy.Validate(this)
    return !hasErrors;
  }

  @action copyImport = async () => {
    await this.ResetCopyValidation();
    if (!await this.checkCopyValid()) {
      return false;
    }
    var item = await this.toServiceModel();
    item.importName = this.newImportName;
    item.id = 0;
    await BusinessUnitConfigStore.copyImportDefinition(item)
    this.setCopy(false);
  }

  @observable validateDup: any;

  @action hasProductDupsObjects = async() =>  {
    let duplicates: any[] = [];
    this.productConversionList.values.forEach((el: any, i: any) => {
      this.productConversionList.values.forEach((element: any, index: any) => {
          if (i === index) return null;
            if (element.product === el.product && element.productConFactor === el.productConFactor) {
              if (!duplicates.includes(el)) duplicates.push(el);
            }
        });
      });
    this.productDup =  duplicates.length > 0
  }

  @action hasModeDupsObjects = async() =>  {
    let duplicates: any[] = [];
    this.modeConversionList.values.forEach((el: any, i: any) => {
      this.modeConversionList.values.forEach((element: any, index: any) => {
          if (i === index) return null;
            if (element.mode === el.mode && element.modeConFactor === el.modeConFactor) {
              if (!duplicates.includes(el)) duplicates.push(el);
            }
        });
      });
    this.modeDup =  duplicates.length > 0
  }

  @action hasDurationDupsObjects = async() =>  {
    let duplicates: any[] = [];
    this.durationConversionList.values.forEach((el: any, i: any) => {
      this.durationConversionList.values.forEach((element: any, index: any) => {
          if (i === index) return null;
            if (element.duration === el.duration && element.durationConFactor === el.durationConFactor) {
              if (!duplicates.includes(el)) duplicates.push(el);
            }
        });
      });
    this.durationDup =  duplicates.length > 0
  }

  @action hasPolicyStatusDupsObjects = async() =>  {
    let duplicates: any[] = [];
    this.policyStatusConversionList.values.forEach((el: any, i: any) => {
      this.policyStatusConversionList.values.forEach((element: any, index: any) => {
          if (i === index) return null;
            if (element.policyStatus === el.policyStatus && element.policyStatusConFactor === el.policyStatusConFactor) {
              if (!duplicates.includes(el)) duplicates.push(el);
            }
        });
      });
    this.policyStatusDup =  duplicates.length > 0
  }

  @action validateConversions = async () => {
    await this.hasProductDupsObjects();
    await this.hasModeDupsObjects();
    await this.hasDurationDupsObjects();
    await this.hasPolicyStatusDupsObjects();
    if(this.productDup || this.modeDup || this.durationDup || this.policyStatusDup){
      let response = {
        status: 400,
        title: "Duplicate record(s) exists",
        errors: { "": [] },
      };
      this.validateDup = JSON.stringify(response);
      return false
    } else {
      this.validateDup = "";
    }
  }

  @observable help: boolean = false;

  @action helpDialog = (value: boolean) => {
    this.help = value;
  }

}

class ImportValidator extends AbstractValidator<ImportAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString(input => input.importName)
      .isNotEmpty()
      .withFailureMessage("Import Name is required");
  }
}

class CopyImportValidator extends AbstractValidator<ImportAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString(input => input.newImportName)
      .isNotEmpty()
      .withFailureMessage("Import Name is required");
  }
}

export interface ImportTypeData {
  columnIndex: string | number | undefined;
  source: string;
  columnName: string;
  dataType: number;
  required: boolean;
  defaultValue: string | undefined;
  rule: string;
  ruleValid: boolean;
  displayOrder: number;
}

export interface modeConversionData {
  id: string;
  mode: string;
  modeConFactor: string;
  error: boolean;
}
export interface productConversionData {
  id: string;
  product: string;
  productConFactor: string;
  error: boolean;
}

export interface durationConversionData {
  id: string;
  duration: string;
  durationConFactor: string;
  error: boolean;
}

export interface policyConversionData {
  id: string;
  policyStatus: string;
  policyStatusConFactor: string;
  error: boolean;
}
