import moment from "moment";
import { setFeatureFlagApi } from "./api";

const featureFlagKey = "feature-flags";
const featureFlagDateKey = "feature-flags-date";
const today = new Date();

export const checkIfFeatureIsPresent = (
  featureName: string,
  features: string[]
) => {
  const index = features.findIndex((feat: string) => feat === featureName);
  return index >= 0;
};

const getFeatureFlagSessionStorage = () =>
  sessionStorage.getItem(featureFlagKey);

const getFeatureFlagSessionStorageDueDate = () =>
  sessionStorage.getItem(featureFlagDateKey);

const eraseFeatureFlagSessionStorage = () => {
  sessionStorage.setItem(featureFlagKey, "");
  sessionStorage.setItem(featureFlagDateKey, "");
};

export const setFeatureFlagInSessionStorage = (
  data: string,
  minutesToWait = 10
) => {
  // Set a date and check if the due date passed
  // Prevent multiple calls to API in a short period of time
  sessionStorage.setItem(featureFlagKey, data);
  const futureDate = moment(today).add(minutesToWait, "minutes");
  sessionStorage.setItem(featureFlagDateKey, futureDate.toString());
};

const isFeatureFlagDueDatePast = (minutesToWait = 10) => {
  const date = getFeatureFlagSessionStorageDueDate();
  const features = getFeatureFlagSessionStorage();

  if (!date && !features) return true;

  const timePast = moment(date).diff(today, "minutes");
  // check if the due date has passed
  if (timePast > minutesToWait) {
    eraseFeatureFlagSessionStorage();
    return true;
  }

  return false;
};

export const getFeatureFlagFromSessionStorage = (featureName: string) => {
  const featureFlagsSessionStorage = getFeatureFlagSessionStorage();
    if (featureFlagsSessionStorage) {
        try {
            const features = JSON.parse(featureFlagsSessionStorage);
            const isFeaturePresent = checkIfFeatureIsPresent(featureName, features);
            return isFeaturePresent;
        } catch (e) {
            return false;
        }
  }
  return false
};
export const getFeatureFlag = async (
  featureName: string,
  minutesToWaitForNextApiCall = 5
) => {
  // check if time passed
  const isTimePast = isFeatureFlagDueDatePast(minutesToWaitForNextApiCall);
  if (isTimePast) {
    return await setFeatureFlagApi(featureName);
  } else {
    return getFeatureFlagFromSessionStorage(featureName);
  }
};
