import { observable, action, computed } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../../infrastructure/IMASLog";
import ContractsStore from "../../../store/ContractsStore";
import Utils from "../../../infrastructure/Utils";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import Collection from "../../../infrastructure/CollectionHelper";
import {
  CreateUpdateLevelRequest,
  LevelDetailModel,
  LevelMarkterDetailModel,
} from "../../../services/ContractService";
import { routes } from "../../../router";
import PageContext from "../../../infrastructure/PageContext";

export class AddEditLevelViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure you want to leave this page?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable
  public ErrorModel = new ErrorModel(new LevelValidator());
  @observable isMessgeVisible: boolean = false;
  @observable isEdit: boolean = false;
  @observable title: string = "";
  @observable response: any;
  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: AddEditLevelListener | undefined;
  @observable isLevelNotesAvailable: boolean = false;
  @observable isLoading: boolean = false;
  @observable levelId: number | undefined;
  @observable payoutLevelId: number | undefined;
  @observable productId: number | undefined;
  @observable createdBy: string = "";
  @observable contractingSentFor: string = "";
  @observable payoutLevel: string = "";
  @observable immediateUplineAgent: string = "";
  @observable instructions: string = "";
  @observable dateCreated: string = "";
  @observable productSubCategoryId: number = 0;
  @observable isFromAddEditContract: boolean = false;
  @observable levelNotes: string | undefined;
  @observable isCloneAvailable: boolean = false;
  @observable percentageTemp: number = 0;
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
  @action Load = async (fromAddEditContract?: boolean) => {
    this.isMessgeVisible = false;
    this.isFromAddEditContract = fromAddEditContract
      ? fromAddEditContract
      : false;
    this.isLevelNotesAvailable = false;
    this.isLoading = true;
    await this.ResetValidate();
    this.selectedCloningLevel = "0";
    this.selectedLevl = "0";
    this.selectedProduct = "0";
    this.specialInstructions = "";
    var uplineAgentId =
      RouteParamDetails.uplineAgentId ||
      parseInt(localStorage.getItem("contractUplineAgentId") || "0");
    this.isCloneAvailable = RouteParamDetails.id !== uplineAgentId;
    if (this.levelId) {
      this.isEdit = true;
      this.title = "Edit Product Details";
      await this.getLevelDetails();
    } else {
      this.title = "Add Product Details";
      this.isEdit = false;
      this.MarketersList.values = [];
      var data: MarketerContractData = {
        id: 1,
        name: "",
        value: 100,
        action: "add",
        invalidValue: false,
        invalidName: false,
      };
      this.MarketersList.values.push(data);
      await this.getMarketersLookup();
      await this.getLevelLookup();
    }
    //  await this.getLevelNotes();
    this.isLoading = false;
  };
  @computed
  get isCloneEnabled(): boolean {
    if (this.selectedCloningLevel && this.selectedCloningLevel !== "0") {
      return true;
    } else {
      return false;
    }
  }

  @action getMarketersLookup = async () => {
    try {
      this.isMessgeVisible = false;
      this.marketersData = [];
      var result = await ContractsStore.getMarketersLokup();
      if (result) {
        this.marketersData = [];
        var filterList = result.filter(
          (v: any, i: any, a: any) =>
            a.findIndex((t: any) => t.value === v.value) === i
        );
        var sortList = [...filterList].sort(Utils.compareListItem);

        for (let item of sortList) {
          this.marketersData.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.marketersData = [];
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
  @action getLevelNotes = async () => {
    try {
      this.isLevelNotesAvailable = false;
      var result = await ContractsStore.getLevelNotes(
        RouteParamDetails.contractId
      );
      if (result) {
        this.isLevelNotesAvailable = true;
      }
    } catch (e) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
  @action getLevelLookup = async (
    isEdit?: boolean,
    payoutLevelId?: number,
    productId?: number
  ) => {
    try {
      this.isMessgeVisible = false;
      this.levelsList = [];
      this.cloningLevels = [];
      this.productsList = [];
      this.levelsList.push({ label: "None", value: "0" });
      this.cloningLevels.push({ label: "None", value: "0" });
      let result = null;
      var carrierId =
        RouteParamDetails.carrierId ||
        parseInt(localStorage.getItem("contractCarrierId") || "0");
      if (isEdit) {
        result = await ContractsStore.getLevelsLookup(
          carrierId,
          payoutLevelId,
          productId,
          RouteParamDetails.contractId
        );
      } else {
        result = await ContractsStore.getLevelsLookup(
          carrierId,
          undefined,
          undefined,
          RouteParamDetails.contractId
        );
      }
      if (result) {
        if (result.payoutLevels) {
          var sortList = [...result.payoutLevels].sort(Utils.compareListItem);
          for (let item of sortList) {
            this.levelsList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
            this.cloningLevels.push({
              label: "" + item.text,
              value: "" + item.value,
            });
          }
        }
        if (result.products) {
          var sortListProducts = [...result.products].sort(
            Utils.compareListItem
          );
          for (let item of sortListProducts) {
            this.productsList.push({
              label: "" + item.text,
              value: "" + item.value,
              subCategory: "" + item.subCategoryId,
            });
          }
          if (this.productsList && this.productsList.length > 0) {
            this.selectedProduct = this.productsList[0].value;
          }
        }
        if (result.note && result.note.note) {
          this.isLevelNotesAvailable = true;
          this.levelNotes = result.note.note;
          this.createdBy = result.note.createdByUser
            ? result.note.createdByUser
            : "";
          if (result.note.dateTime) {
            this.dateCreated =
              Utils.getDateInFormat(result.note.dateTime) +
              " at " +
              Utils.getTimeInFormat(result.note.dateTime);
          }
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.levelsList = [];
      this.cloningLevels = [];
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
  @action getLevelDetails = async () => {
    try {
      this.isMessgeVisible = false;
      this.isLoading = true;
      if (this.levelId) {
        var result = await ContractsStore.getLevelDetails(this.levelId);
        if (result) {
          await this.getMarketersLookup();
          await this.getLevelLookup();
          let levelId = this.levelsList.find(
            (i) => i.label === result.description
          );
          let prodId = this.productsList.find(
            (i) => i.label === result.productName
          );
          this.payoutLevelId = result.payoutLevelId;
          this.productId = result.productId;
          this.productSubCategoryId = result.productSubCategoryId
            ? result.productSubCategoryId
            : 0;
          this.setCloningLevel(levelId ? levelId.value : "");
          this.setProduct(prodId ? prodId.value : "");
          this.setLevel(levelId ? levelId.value : "");
          this.setSpecialInstructions(
            result.specialInstructions ? result.specialInstructions : ""
          );
          this.setMarketerData(result);
          this.contractingSentFor = result.contractingSentFor
            ? result.contractingSentFor
            : "";
          this.instructions = "";
          this.payoutLevel = result.payoutLevel ? result.payoutLevel : "";
        }
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
  @action setMarketerData(result: LevelDetailModel) {
    this.MarketersList.values = [];
    let newValues: MarketerContractData[] = [];
    var data1: MarketerContractData = {
      id: 2,
      name: "",
      value: 0,
      action: "add",
      invalidValue: false,
      invalidName: false,
    };
    if (result.marketerList) {
      result.marketerList.forEach((obj) => {
        var data: MarketerContractData = {
          id: obj.marketerId,
          name: "" + obj.marketerId,
          value: obj.marketerSplit ? obj.marketerSplit * 100 : 0,
          action: "delete",
          invalidName: false,
          invalidValue: false,
        };
        newValues.push(data);
      });
    }
    newValues.push(data1);
    this.MarketersList.values = newValues;
  }
  setListener(listener: AddEditLevelListener) {
    this.listener = listener;
  }
  @action getUpdatedMarketerValues() {
    this.marketerNewValues = [];
    this.MarketersList.values.forEach((obj) => {
      if (obj.action === "delete" || (obj.name && obj.action === "add"))
        this.addMarketer(obj);
    });
  }
  @action checkSplitPercentage() {
    let percentage = 0;
    this.marketerNewValues.forEach((obj) => {
      if (obj.split) {
        percentage += obj.split * 100;
      }
    });
    return percentage === 100;
  }
  @action showExceptionMessage(msg: string) {
    this.isLoading = false;
    var exceptionResponse = {
      status: 500,
      title: msg,
      errors: { "": [] },
    };
    this.response = JSON.stringify(exceptionResponse);
    this.isMessgeVisible = true;
  }
  @action addContractLevel = async (isFromBuildAll?: boolean) => {
    try {
      if (!(await this.Validate())) {
        this.isLoading = true;
        this.getUpdatedMarketerValues();
        if (this.marketerNewValues.length === 0) {
          this.showExceptionMessage("Please select a marketer");
          window.scrollTo(0, 0);
          return 0;
        }
        if (!this.checkSplitPercentage()) {
          this.showExceptionMessage("Production Credit must equal 100%");
          window.scrollTo(0, 0);
          return 0;
        }
        var dto: CreateUpdateLevelRequest = {
          contractId: RouteParamDetails.contractId,
          payoutLevelId: +this.selectedLevl,
          levelMarkterDetailModelList: this.marketerNewValues,
          paidCommission: this.paidCommission ? +this.paidCommission : 0,
          productId: +this.selectedProduct,
          specialInstructions: this.specialInstructions
            ? this.specialInstructions.replace(/ +/g, " ")
            : "",
        };
        let result = null;
        if (this.isEdit) {
          dto.id = this.levelId;
          result = await ContractsStore.editLevel(dto);
          localStorage.setItem("keepContractDetails", "true");
        } else {
          result = await ContractsStore.addLevel(dto);
        }
        if (!isFromBuildAll) {
          window.scrollTo(0, 0);
          this.isLoading = false;

          this.goToParentView();
        } else {
          if (!this.isEdit) {
            if (result) {
              this.levelId = result;
            }
          }
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };

  @action cancel = async () => {
    this.goToParentView();
  };
  @action showCloneDetails = async () => {
    if (PageContext.isFromAddContract === "Edit") {
      routes.editContract.replace({
        agentId: "" + RouteParamDetails.id,
        contractId: RouteParamDetails.contractId
          ? RouteParamDetails.contractId
          : 0,
      });
    } else {
      routes.contractDetails.replace({
        agentId: "" + RouteParamDetails.id,
        contractId: RouteParamDetails.contractId
          ? RouteParamDetails.contractId
          : 0,
      });
    }
  };

  @action goToParentView() {
    if (PageContext.isFromAddContract === "Add") {
      routes.agentContracts.replace({
        agentId: "" + RouteParamDetails.id,
        // contractId: RouteParamDetails.contractId,
      });
    } else if (PageContext.isFromAddContract === "Edit") {
      localStorage.setItem("keepContractDetails", "true");
      routes.editContract.replace({
        agentId: "" + RouteParamDetails.id,
        contractId: RouteParamDetails.contractId,
      });
    } else {
      routes.contractDetails.replace({
        agentId: "" + RouteParamDetails.id,
        contractId: RouteParamDetails.contractId,
      });
    }
  }

  @observable cloningLevels = [
    { label: "None", value: "0" },
    { label: "LOA", value: "1" },
    { label: "Premier", value: "2" },
  ];
  @observable selectedCloningLevel: string = "0";
  @action setCloningLevel(value: string) {
    this.selectedCloningLevel = value;
  }

  @observable levelsList = [{ label: "None", value: "0" }];
  @observable selectedLevl: string = "0";
  @action setLevel(value: string) {
    this.selectedLevl = value;
  }

  @observable productsList = [
    { label: "Final Expense (Immediate)", value: "0", subCategory: "0" },
    { label: "Final Expense (Modified)", value: "1", subCategory: "0" },
    { label: "Medicare Suppliment", value: "2", subCategory: "0" },
  ];
  @observable selectedProduct: string = "0";
  @action setProduct(value: string) {
    this.selectedProduct = value;
  }
  @observable productTypeList = [{ label: "None", value: "0" }];
  @observable selectedProductType: string = "0";
  @action setProductType(value: string) {
    this.selectedProductType = value;
  }
  @observable subCategoryList = [{ label: "None", value: "0" }];
  @observable selectedSubCategory: string = "0";
  @action setSubCategory(value: string) {
    this.selectedSubCategory = value;
  }
  @observable specialInstructions: string = "";
  @action setSpecialInstructions(value: string) {
    this.specialInstructions = value;
  }
  @observable marketersData = [
    { label: "marketer2", value: "marketer2" },
    { label: "marketer1", value: "marketer1" },
  ];
  @observable marketer: string = "marketer2";
  @action setMarketer(value: string) {
    this.marketer = value;
  }
  @action updateMarketer(value: string, rowData: MarketerContractData) {
    let newValues: MarketerContractData[] = [];
    this.MarketersList.values.forEach((obj) => {
      let selectedMarketers = newValues.map(function (el) {
        return el.name;
      });

      if (obj.id === rowData.id && !selectedMarketers.includes(value)) {
        obj.name = value;
        rowData.invalidName = false;
      }

      console.log("obj is pushed");
      newValues.push(obj);
    });
    this.MarketersList.values = newValues;
  }
  @action updateAction(rowData: MarketerContractData) {
    let newValues: MarketerContractData[] = [];
    this.marketerNewValues = [];
    if (rowData.action == "delete") {
      this.MarketersList.values.forEach((obj) => {
        if (obj.id !== rowData.id) {
          obj.invalidName = rowData.name ? false : true;
          obj.invalidValue = rowData.value ? false : true;
          newValues.push(obj);
        }
        console.log("obj is removed");
      });
      this.MarketersList.values = newValues;
      return;
    } else {
      if (!rowData.name || !rowData.value) {
        this.MarketersList.values.forEach((obj) => {
          if (obj.id === rowData.id) {
            obj.invalidName = rowData.name ? false : true;
            obj.invalidValue = rowData.value ? false : true;
            newValues.push(obj);
          } else {
            newValues.push(obj);
          }
          console.log("obj is pushed");
        });
        this.MarketersList.values = newValues;
        return;
      }
    }

    this.MarketersList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        if (obj.action === "add") {
          obj.action = "delete";
          obj.invalidName = false;
          obj.invalidValue = false;
          newValues.push(obj);
          var newObj: MarketerContractData = {
            id:
              obj && obj.id ? obj.id + 1 : this.MarketersList.values.length + 1,
            name: "",
            value: this.getRemainingPercent(),
            action: "add",
            invalidName: false,
            invalidValue: false,
          };
          newValues.push(newObj);
        }
      } else {
        obj.invalidValue = false;
        obj.invalidName = false;
        newValues.push(obj);
      }
      console.log("obj is pushed");
    });
    this.MarketersList.values = newValues;
  }
  private addMarketer(obj: MarketerContractData) {
    let marketerName = this.marketersData.find((i) => i.value === obj.name);
    console.log("marketerName : " + JSON.stringify(marketerName));
    if (marketerName) {
      let id = 0;
      let splitPer = obj.value ? obj.value / 100 : 0;
      id = +marketerName.value;
      var marketer: LevelMarkterDetailModel = {
        split: splitPer,
        levelMarkterId: +id,
      };
      this.marketerNewValues.push(marketer);
    }
  }

  getRemainingPercent() {
    let percentage: number = 0;
    this.MarketersList.values.forEach((obj) => {
      if (obj.value) {
        percentage += obj.value;
      }
    });
    let remainingPercent = 100 - percentage;
    return remainingPercent > 0 ? remainingPercent : 0;
  }
  @action updateMarketerValue(value: string, rowData: MarketerContractData) {
    let newValues: MarketerContractData[] = [];
    this.MarketersList.values.forEach((obj) => {
      if (obj.id === rowData.id) {
        obj.value = +value;
        rowData.invalidValue = false;
      }
      console.log("obj is pushed");
      newValues.push(obj);
    });
    this.MarketersList.values = newValues;
  }
  @observable MarketersList = new Collection<MarketerContractData>();
  @observable marketerNewValues: LevelMarkterDetailModel[] = [];
  @observable isInvalidMarketer: boolean = false;
  @observable paidCommission: string = "120";
  @action setPaidCommission(value: string) {
    this.paidCommission = value;
  }
  @action setLevelId(value: number) {
    this.levelId = value;
    IMASLog.log("level id : " + value);
  }

  @action buildAll = async () => {
    try {
      if (this.levelId) {
        this.isMessgeVisible = false;
        this.isLoading = true;

        await ContractsStore.buildAll(this.levelId, 0);
        this.isLoading = false;
        this.cancel();
      } else {
        this.isMessgeVisible = false;

        await this.addContractLevel(true);
        if (this.levelId) {
          this.isLoading = true;
          await ContractsStore.buildAll(this.levelId, 0);
          this.isLoading = false;
          this.cancel();
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
  @action cloneProducts = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      await ContractsStore.cloneProductDetails(
        RouteParamDetails.reportToLicenseId,
        RouteParamDetails.contractId,
        +this.selectedCloningLevel
      );
      this.isLoading = false;
      this.showCloneDetails();
    } catch (e) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
  @action buildAllBySubcategory = async () => {
    try {
      let product = this.productsList.find(
        (i) => i.value === this.selectedProduct
      );
      if (product) {
        this.productSubCategoryId = +product.subCategory;
      }
      if (this.levelId) {
        this.isLoading = true;
        this.isMessgeVisible = false;

        await ContractsStore.buildAll(this.levelId, this.productSubCategoryId);
        this.isLoading = false;
        this.cancel();
      } else {
        this.isMessgeVisible = false;

        await this.addContractLevel(true);
        if (this.levelId) {
          this.isLoading = true;
          await ContractsStore.buildAll(
            this.levelId,
            this.productSubCategoryId
          );
          this.isLoading = false;
          this.cancel();
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };
}

export interface MarketerContractData {
  name: string | undefined;
  value: number | undefined;
  action: string;
  id: number | undefined;
  invalidName: boolean;
  invalidValue: boolean;
}

export interface AddEditLevelListener {
  contractLevelListener(): void;
  canelAddEditLevel(): void;
}

class LevelValidator extends AbstractValidator<AddEditLevelViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedProduct)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Product is required");
    this.validateIfString((input) => input.selectedLevl)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Level is required");
  }
}
