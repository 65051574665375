import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { workflowGroup, getCurrentRoute, routes } from "../../router";
import { Route } from "type-route";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import Utils from "../../infrastructure/Utils";

import { DocWorkflowDetailsTabViewModel } from "./DocWorkflowDetailsTabViewModel";

export class DocWorkflowDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable
  DocWorkflowDetailsTabViewModel: DocWorkflowDetailsTabViewModel = new DocWorkflowDetailsTabViewModel();

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

    @action Load = async () => {
        this.route = getCurrentRoute();
    };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    if (
      currentRoute.name === routes.searchWorkflowDocs.name ||
      currentRoute.name === routes.docWorkflow.name
    ) {
      if (!(this.SelectedViewModel instanceof DocWorkflowDetailsTabViewModel)) {
        this.SelectedViewModel = this.DocWorkflowDetailsTabViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (workflowGroup.has(currentRoute)) {
      var routePamParamObj = JSON.parse(JSON.stringify(currentRoute.params));

      RouteParamDetails.documentId = routePamParamObj.documentId;
      RouteParamDetails.workflowStatus = routePamParamObj.workflowStatus;

      if (!(this.SelectedViewModel instanceof DocWorkflowDetailsTabViewModel)) {
        this.SelectedViewModel = this.DocWorkflowDetailsTabViewModel;
      }
      await this.SelectedViewModel.Load(
        RouteParamDetails.documentId,
        RouteParamDetails.workflowStatus
      );
      await this.SelectedViewModel.Route(currentRoute);
    }
  };
}
