import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import AddressStore from "./../../store/AddressStore";
import IMASLog from "../../infrastructure/IMASLog";

import Collection from "../../infrastructure/CollectionHelper";
import { CassAddress } from "../../services/AddressService";
import ProducerStore from "../../store/ProducerStore";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";

export class AddEditAddressViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {
      this.isException = false;
      await this.ResetValidate();
      await this.getAddressTypes();
  };
  @observable
  public ErrorModel = new ErrorModel(new AddressValidator());
  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: AddressListener | undefined;
  @observable isLoading: boolean = false;
  @observable isException: boolean = false;
  @observable validAddressModel: CassAddress | undefined;
  @observable exceptionMsg: any = "Invalid Address";
  @observable city: string = "";
  @observable county: string = "";
  @observable addressLine: string = "";
  @observable state: string = "";
  @observable zip: string = "";
  @observable id: number = 0;
  @observable isDialogVisible: boolean = false;
  @observable agentId: number = 0;
  @observable businessName: string = "";
  @observable attn: string = "";
  @observable addressNotes: string = "";
  @observable address: string = "";
  @observable isDefault: boolean = false;
  @observable isCommission: boolean = false;
  @observable isResidential: boolean = false;
  @observable isVisibleInDownline: boolean = false;
  @observable isValid: boolean = true;
  @observable customAddrName: string = "";
  @observable isCustomValidation: boolean = false;
  @observable AddressListSuggestions = new Collection<string>();
  @observable addressType: string = "1";
  @observable addressTypeId: string = "0";
  @observable isAddressEditMode: boolean = false;
  @observable existingCustomAddrName: string = "";
  @observable isValidAddress: boolean = false;
  @observable showOverrideAddress: boolean = false;
  setAddressListener(listener: AddressListener) {
    this.listener = listener;
  }
  @action setAddrssId(value: number) {
    this.id = value;
  }

  @action setAddressType(value: number) {
    
    this.addressTypeId =''+ value;
  }
  @observable addressName: string = "";
  @observable addressNameList = new Collection<any>();
 
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setCustomAddrName(value: string) {
    this.customAddrName = value;
  }
  @action setAddress(value: string) {
    this.isValid = false;
    this.address = value;
  }
  @action setBusinessName(value: string) {
    this.businessName = value;
  }
  @action setAttn(value: string) {
    this.attn = value;
  }
  @action setAgentId(value: number) {
    this.agentId = value;
  }
  @action setDialogVisibility = async(value: boolean) => {
    this.isDialogVisible = value;
    if(value === true){
      this.Load()
    } else {
      setTimeout(async() => {
        this.showOverrideAddress = false;
        await this.resetOverrideFields();
      }, 500);
    }
    this.isMessgeVisible = false;
  }
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @action setAddressName(value: string, isEditMode: boolean, addrName:string  ) {
    
    this.isAddressEditMode=isEditMode;
   
    if(this.isAddressEditMode)
    { 
        this.existingCustomAddrName =addrName;  
    }

    this.isCustomValidation = false;
    this.addressType = value;
    if(this.isAddressEditMode)
    {          
          this.customAddrName = this.existingCustomAddrName ; 
    }
    else{
          this.customAddrName = ""  ;  
    }
    if (value == "4")
    {   
        this.addressName = "Custom";
     }
    else
    {     
      let addrType= this.addressNameList.values.find(p=>p.value==value);
      if(addrType!=null)
      { 
          this.addressName = addrType.label; 
       } 
    } 
}

  @action searchAddress = async (value: string) => {
    try {
      var result = await AddressStore.getRecommendations(value);
      IMASLog.log("address suggestions " + result);
      if (result && result.length > 0)
        this.AddressListSuggestions.values = result;
      else this.AddressListSuggestions.values = ["No Addresses found."];
    } catch (e) {
      this.AddressListSuggestions.values = ["No Addresses found."];
    }
  };

  @action getAddressTypes = async() => {
    
    this.addressNameList.values = [];
    var result = await ProducerStore.getAddressTypesLookup()
     
    if(result){
      Utils.mapListItemsToDropdown(result, this.addressNameList, "", "");
      if(this.addressTypeId !== '0')
      {
          this.addressType = this.addressTypeId;
          if (this.addressType == "4")
              this.setAddressName(this.addressType ? "" + this.addressType : '', true, this.customAddrName ? this.customAddrName : '')
          else
              this.setAddressName(this.addressType ? "" + this.addressType : '', true, '')
      }
      else{
        this.addressType = this.addressNameList.values.length > 0 ? this.addressNameList.values[0].value : ""
        this.addressName = this.addressNameList.values.length > 0 ? this.addressNameList.values[0].label : ""
  
      }
      
    }
  }

  @action validateAddress = async () => {    
    if (this.AddressListSuggestions.values.length > 0 && this.address) {
      await this.checkAddressValidation();    
    }
  };
  @action checkAddressValidation = async () => {
    this.validAddressModel = undefined;
    this.isValidAddress = false;
    this.showOverrideAddress = false;
      let address = await AddressStore.validate(this.address);
      if (address) {
        this.validAddressModel = address;
        this.isValidAddress = true;
        var successResponse = {
          status: 200,
          title: "Address is valid",
          errors: { "": [] },
        };
        this.exceptionMsg = JSON.stringify(successResponse);
        this.isException = true;
      } else {
        this.validAddressModel = undefined
        this.isLoading = false;
        this.isValidAddress = false;
        await this.resetOverrideFields();
        if(Utils.hasUserPermission(Permission.OverrideAddressValidation)){
          this.showOverrideAddress = true;
          await this.ResetValidate();
          this.isValidAddress = true;
          await this.setOverrideValidationExceptionMessage();
        } else {
          this.showOverrideAddress = false;
          await this.setExceptionMessage();
        }
        return 0
      }

  }

  @action resetOverrideFields() {
    this.city = "";
    this.county = "";
    this.state = "";
    this.zip = "";
    this.addressLine = "";
  }

  @action setAddressLine = (value: string) =>{
    this.addressLine = value;
  }

  @action setCity = (value: string) =>{
    this.city = value;
  }

  @action setCounty = (value: string) => {
    this.county = value;
  }

  @action setState = (value: string) =>{
    this.state = value;
  }

  @action setZip = (value: string) =>{
    this.zip = value;
  }
  
  @action setDefault(value: boolean) {
    this.isDefault = value;
  }
  @action setCommission(value: boolean) {
    this.isCommission = value;
  }
  @action setResidential(value: boolean) {
    this.isResidential = value;
  }
  @action setVisibleInDownline(value: boolean) {
    this.isVisibleInDownline = value;
  }
  @action setExceptionMessage() {
    var inValidResponse = {
      status: 500,
      title: "Invalid Address",
      errors: { "": [] },
    };
    this.exceptionMsg = JSON.stringify(inValidResponse);
    this.isException = true;
  }

  @action setOverrideValidationExceptionMessage(){
    var inValidResponse = {
      status: 400,
      title: "The address you have entered cannot be validated. Please review before saving.",
      errors: { "": [] },
    };
    this.exceptionMsg = JSON.stringify(inValidResponse);
    this.isException = true;
  }
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @action
  public Submit = async () => {
    this.isCustomValidation =
      this.addressName === "Custom" && this.customAddrName.trim().length <= 0;
          try {
            if (!(await this.Validate())) {     
              //this.isException = false;
              this.isLoading = true;
              if (this.isCustomValidation) {
                this.isLoading = false;
                return 0;
              } else {
                this.isCustomValidation = false;
              }
              if (this.validAddressModel || (this.showOverrideAddress && Utils.hasUserPermission(Permission.OverrideAddressValidation))) {
                    if (this.listener) await this.listener.updateAddress();
                  this.isLoading = false;
                  this.isException = false;
                  this.setDialogVisibility(false);                
                  // setTimeout(() => {
                      if (this.listener) await this.listener.updateDefaultStateOnHeader(); 
                  // }, 3000);
              } else {
                this.isLoading = false;
                this.setExceptionMessage();
              }
            }
            this.isMessgeVisible = false;
          } catch (e:any) {
            e.text().then((_responseText: any) => {
                  this.response =  _responseText;
                  this.isMessgeVisible = true;
              }); 
          }
  };
}

export interface AddressListener {
  updateAddress(): void;
  updateDefaultStateOnHeader(): void;
}

class AddressValidator extends AbstractValidator<AddEditAddressViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.addressType)
      .isNotEmpty()
      .withFailureMessage("Address Name is required");

    this.validateIfString((input) => input.address)
      .isNotEmpty()
      .isNotEqualTo("No Addresses found.")
      .withFailureMessage("Address is required")
      .when((input) => !input.showOverrideAddress);
    this.validateIfString((input) => input.city)
      .isNotEmpty()
      .withFailureMessage("City is required")
      .when((input) => input.showOverrideAddress);
    this.validateIfString((input) => input.county)
      .isNotEmpty()
      .withFailureMessage("County is required")
      .when((input) => input.showOverrideAddress);
    this.validateIfString((input) => input.state)
      .isNotEmpty()
      .withFailureMessage("State is required")
      .when((input) => input.showOverrideAddress);
    this.validateIfString((input) => input.zip)
      .isNotEmpty()
      .withFailureMessage("Zip is required")
      .when((input) => input.showOverrideAddress);
    this.validateIfString((input) => input.addressLine)
      .isNotEmpty()
      .withFailureMessage("Address Line is required")
      .when((input) => input.showOverrideAddress);
  }
}
