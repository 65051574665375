import { action, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../infrastructure/RouteList";
import ContractsStore from "../../../../store/ContractsStore";
import {
  AddressListModel,
  CreateUpdateAddressModel,
} from "../../../../services/ContractService";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import Utils from "../../../../infrastructure/Utils";
import UserContext from "../../../../infrastructure/UserContext";
import PageContext from "../../../../infrastructure/PageContext";
import IMASLog from "../../../../infrastructure/IMASLog";
export class CarrierCMAddressViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => undefined;
  public ErrorModel = new ErrorModel(new AddressValidator());
  @observable isLoading: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable addressList = new Collection<AddressListModel>();
  @observable selectedCarrierId: number = 0;
  @observable addrDialogTitle: string = "";
  @observable isAddressDialogVisible: boolean = false;
  @observable isException: boolean = false;
  @observable exceptionMsg: any = "";
  @observable addressType: string = "2";
  @observable addressTypeList = new Collection<any>();
  @observable stateList = new Collection<any>();
  @observable addressName: string = "";
  @observable customAddrName: string = "";
  @observable isCustomValidation: boolean = false;
  @observable addressLine: string = "";
  @observable city: string = "";
  @observable county: string = "";
  @observable state: string = "";
  @observable zip: string = "";
  @observable addressId: number = 0;
  @observable isDeleteAddressConfirm: boolean = false;
  @observable disableSave: boolean = false;
  @observable addAddressDto: any | undefined = [];
  @observable validZipError: string = "";
  @action Load = async () => {};

  @action getAllAddresses = async (carrierId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};

    try {
      var result = await ContractsStore.getAllAddresses(carrierId);
      if (result != null) {
        if (result.data) {
          this.addressList.values = result.data;
        } else {
          this.addressList.values = [];
        }
      }
    } catch (e: any) {
      this.addressList.values = [];
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  @action getAddressDetail = async () => {
    if (this.selectedCarrierId == 0) {
      //save carrier mode

      await this.getAddressDetailByIdFromTemp(this.addressId);
      // get detail by temp storage to bind the detail
    } else {
      // edit carrier mode
      await this.getAddressDetailById(this.addressId);
    }
  };
  @action getAddressDetailById = async (addressId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};

    try {
      var result = await ContractsStore.getAddressDetails(
        addressId,
        "" + this.selectedCarrierId
      );
      if (result != null) {
        this.addressType = "" + result.addressType;
        this.addressLine = result.addressLine1 || "";
        this.city = result.cityName || "";
        this.county = result.countyName || "";
        this.state = result.stateAbbreviation || "";
        this.zip = result.zipCode || "";
      }
    } catch (e: any) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false; 
  @action handleClick = (id: string) => { 
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
    };

  @action getAddressDetailByIdFromTemp = async (addressId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};

    try {
      var result = this.addAddressDto.filter((item: any) => {
        return item.id == addressId;
      });
      if (result != null) {
        this.addressType = "" + result[0].addressType;
        this.addressLine = result[0].addressLine1;
        this.city = result[0].cityName;
        this.county = result[0].countyName;
        this.state = result[0].stateAbbreviation;
        this.zip = result[0].zipCode;
      }
    } catch (e: any) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  @action setType(value: string) {
    this.addressType = value;
    this.enableDisableSaveButton();
  }

  @action setAddressDialogVisibility = async (value: boolean) => {
    this.isAddressDialogVisible = value;
  };
  @action setAddressLine = async (value: string) => {
    this.addressLine = value;
    this.enableDisableSaveButton();
  };
  @action setCity = (value: string) => {
    this.city = value.trim();
    this.enableDisableSaveButton();
  };

  @action setCounty = (value: string) => {
    this.county = value;
    this.enableDisableSaveButton();
  };

  @action setState = (value: string) => {
    this.state = value.trim();
    this.enableDisableSaveButton();
  };
  @action setZip = (value: string, number: string) => {
    this.zip = value.trim();
    if (number == "1") {
      if (value && this.zip.length < 5) {
        this.validZipError = "Please enter a valid zip code.";
        return;
      } else {
        this.validZipError = "";
      }
    }
    this.enableDisableSaveButton();
  };
  @action enableDisableSaveButton = async () => {
    if (!(await this.IsFormValid())) {
      if (this.zip.length == 5) this.disableSave = false;
      else this.disableSave = true;
    } else {
      this.disableSave = true;
    }
  };

  @action cancel = async () => {
    this.isAddressDialogVisible = false;
    this.resetAddressForm();
  };
  @action setDeleteAddressConfirmation(value: boolean) {
    this.isDeleteAddressConfirm = value;
  }
  @action deleteAddress = async () => {
    try {
      this.isException = false;
      if (this.selectedCarrierId === 0) {
        this.addAddressDto = this.addAddressDto.filter((item: any) => {
          return item.id != this.addressId;
        });
        this.addressList.values = this.addressList.values.filter(
          (item: any) => {
            return item.id != this.addressId;
          }
        );
        this.isDeleteAddressConfirm = false;
      } else {
        await ContractsStore.deleteAddress(
          this.addressId,
          "" + this.selectedCarrierId
        );
        this.isDeleteAddressConfirm = false;
        setTimeout(async () => {
          await this.getAllAddresses(this.selectedCarrierId);
          this.isLoading = false;
        }, Utils.timeDelay_Delete());
      }
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMsg = error.response;
      IMASLog.log("exception: " + error);
    }
  };
  @action loadAddressLookups = async () => {
    await this.getCarrierAddressTypes();
    await this.getCarrierAddressStates();
    if (this.addressId > 0) await this.getAddressDetail();
  };
  @action getCarrierAddressTypes = async () => {
    this.addressTypeList.values = [];
    try {
      let response = await ContractsStore.getAddressTypeLookupForCarrier();
      if (response) {
        Utils.mapListItemsToDropdown(response, this.addressTypeList, "", "");
        this.addressType = "2";
      }
    } catch (e) {
      this.addressTypeList.values = [];
    }
  };
  @action getCarrierAddressStates = async () => {
    this.stateList.values = [];
    try {
      let response = await ContractsStore.appointmentStates();
      if (response) {
        this.loadStates(response);
      }
    } catch (e) {
      this.stateList.values = [];
    }
  };

  @action addAddress = async () => {
    await this.ResetValidate();
    if (!(await this.Validate())) {
      try {
        this.isLoading = true;
        var dto = this.CreateUpdateAddressModel();
        if (this.selectedCarrierId == 0) {
          await this.addEditAddress(dto);
        } else {
          // edit mode
          if (this.addressId === 0) {
            await ContractsStore.addAddress(dto, "" + this.selectedCarrierId);
          } else {
            dto.id = this.addressId;
            await ContractsStore.editAddress(dto, "" + this.selectedCarrierId);
          }
          await this.setAddressDialogVisibility(false);
          await this.getAllAddresses(this.selectedCarrierId);
        }
        PageContext.setIsMessageVisible(false);
        this.resetAddressForm();
        this.ResetValidate();
        this.isLoading = false;
      } catch (error) {
        PageContext.setIsMessageVisible(false);
        this.isLoading = false;
        setTimeout(() => {
          PageContext.setIsMessageVisible(false);
          let message = JSON.parse(PageContext.responseMessage);
          if (message && message.status === 400) {
            this.exceptionMsg = PageContext.responseMessage;
            this.isException = true;
          }
        }, 100);
        IMASLog.log("exception: " + error);
      }
    }
  };
  private async addEditAddress(dto: any) {
    var dateTimeAsId = new Date().getTime();

    if (this.addressId > 0) {
      var address = this.addAddressDto.find((item: any) => {
        return item.id === this.addressId;
      });
      if (address) {
        (address.addressType = parseInt(this.addressType)),
          (address.address = this.addressLine),
          (address.city = this.city),
          (address.state = this.state),
          (address.county = this.county),
          (address.zip = this.zip),
          (address.dateCreated = new Date());
        address.userId = UserContext.getUserId();
      }
      var addressobj = this.addressList.values.find((item: any) => {
        return item.id === this.addressId;
      });
      if (addressobj) {
        (addressobj.addressType = this.getaddressType(this.addressType)),
          (addressobj.address = this.addressLine),
          (addressobj.city = this.city),
          (addressobj.state = this.state),
          (addressobj.county = this.county),
          (addressobj.zip = this.zip);
      }
    } else {
      dto.id = dateTimeAsId;
      this.addAddressDto.push(dto);
      this.addressList.values.push({
        id: dateTimeAsId,
        addressType: this.getaddressType(this.addressType),
        address: this.addressLine,
        city: this.city,
        state: this.state,
        county: this.county,
        zip: this.zip,
      });
    }
    await this.setAddressDialogVisibility(false);
  }
  @action getaddressType = (addressType: any) => {
    var addressTypes = null;
    var Address_type = this.addressTypeList.values.filter((item: any) => {
      return item.value == addressType;
    });
    if (Address_type && Address_type.length > 0) {
      addressTypes = Address_type[0].label;
    }
    return addressTypes;
  };

  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public IsFormValid = async () => {
    return this.ErrorModel.IsFormValid(this);
  };

  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
  @action CreateUpdateAddressModel() {
    var dto: CreateUpdateAddressModel = {
      id: this.addressId,
      companyId: this.selectedCarrierId,
      addressName: "",
      addressLine1: this.addressLine,
      addressLine2: "",
      zipCode: this.zip,
      cityName: this.city,
      stateAbbreviation: this.state,
      countyName: this.county,
      addressType: parseInt(this.addressType),
      dateCreated: new Date(),
      userId: UserContext.getUserId(),
    };
    return dto;
  }

  @action resetAddressForm() {
    this.addressType = "2";
    this.addressLine = "";
    this.city = "";
    this.county = "";
    this.state = "";
    this.zip = "";
    this.isException = false;
    this.validZipError = "";
    this.exceptionMsg = {};
  }

  @action loadStates(data: any) {
    data.forEach((obj: any) => {
      if (obj.stateId !== -1) {
        var data = {
          label: obj.abbreviation ? obj.abbreviation : "",
          value: obj.abbreviation ? obj.abbreviation : "",
        };
        this.stateList.values.push(data);
      }
    });
  }
}

class AddressValidator extends AbstractValidator<CarrierCMAddressViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.addressType)
      .isNotEmpty()
      .withFailureMessage("Address Type is required");

    this.validateIfString((input) => input.addressLine)
      .isNotEmpty()
      .withFailureMessage("Address Line is required");

    this.validateIfString((input) => input.city)
      .isNotEmpty()
      .withFailureMessage("City is required");

    this.validateIfString((input) => input.county)
      .isNotEmpty()
      .withFailureMessage("County is required");

    this.validateIfString((input) => input.state)
      .isNotEmpty()
      .withFailureMessage("State is required");

    this.validateIfString((input) => input.zip)
      .isNotEmpty()
      .withFailureMessage("Zip is required");

    this.validateIfString((input) => input.zip)
      .hasMinLength(5)
      .whenNotEmpty()
      .withFailureMessage("Please enter a valid zip code.");
  }
}
