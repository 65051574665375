import { AbstractValidator } from "fluent-ts-validator";
import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { routes } from "../../router";
import LeadStore from "../../store/LeadStore";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
type NewType = undefined;

export class AgentLeadEditViewModel implements ViewModel {
  constructor(private OnSave?: (BankAccountID: number) => void) {}
  @observable selecteDate: Date = new Date();
  @observable selectedTime: string = "";
  @observable companyList = [{ label: "Select Carrier", value: "0" }];
  @observable leadSourceList = [{ label: "Select Lead source", value: "0" }];
  @observable assigneeList = [{ label: "Select Assignee", value: "0" }];
  @observable subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
  @observable leadPhaseDetailList = [
    { label: "Select Lead Phase Detail", value: "0" },
  ];
  @observable leadPhaseList = [{ label: "Select Lead Phase", value: "0" }];
  @observable hasPermission: boolean = true;
  @observable isLoading: boolean = true;
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable isWarningDisplayed: boolean = false;
  @observable isUplineWarning: boolean = false;

  @observable CarrierBankDepositID: number = 0;
  @observable selectedCompany: string = "";
  @observable selectedLeadSource: string = "";
  @observable selectedAssignee: string = "";
  @observable selectedSubAssignee: string = "";
  @observable selectedLeadPhaseDetail: string = "";
  @observable selectedDateFollowUp?: Date | NewType = new Date();
  @observable selectedLeadPhase: string = "";
  @observable AgentLeadId: number = 0;
  @observable  isMessageVisible: boolean = false;
  @observable response:any;

  @observable selectedBankAccount: string = "";

  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return !this.loading;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }

  Close = (): void => {
    routes.leads.push();
  };

  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setisUplineWarning(value: boolean) {
    this.isUplineWarning = value;
  }

  @action setisWarningDisplayed(value: boolean) {
    this.isWarningDisplayed = value;
  }

  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action setDateFollowUp(value: Date | undefined) {
    this.selectedDateFollowUp = value;
  }
  @action setTime(value: string) {
    this.selectedTime = value;
  }
  @action setSelectedDate(value: string) {
    this.selecteDate = new Date(value);
  }
  public Load = async (AgentLeadId: number): Promise<void> => {

    let delay = 0;
    if(UserContext.permissions.length <= 0)
        {
            delay = 2000;
        }
        setTimeout (async () => {
          if(UserContext.permissions.length > 0 && !Utils.hasUserPermission(Permission.ViewLeads)) {
            this.hasPermission = false;
        }
        else{
          this.hasPermission = true;
        }
          this.AgentLeadId = AgentLeadId;
          this.loading = true;
          this.isMessageVisible = false;
          this.response = ''
          try {
            let userId = UserContext.getUserId();
            var model = await LeadStore.getMarketerLead(
              this.AgentLeadId,
              userId
            );
      
      
            if (model.leadPhase && this.leadPhaseList.length === 1) {
              for (let item of model.leadPhase) {
                this.leadPhaseList.push({
                  label: "" + item.text,
                  value: "" + item.value,
                });
      
      
              }
            }
            this.selectedLeadPhase = "" + model.leadPhaseId;
            if (model.leadPhaseDetail && this.leadPhaseDetailList.length === 1) {
              for (let item of model.leadPhaseDetail) {
                this.leadPhaseDetailList.push({
                  label: "" + item.text,
                  value: "" + item.value,
                });
      
              }
            }
            this.selectedLeadPhaseDetail = "" + model.leadPhaseDetailsId;
      
            if (model.subAssingee && this.subAssigneeList.length === 1) {
              for (let item of model.subAssingee) {
                this.subAssigneeList.push({
                  label: "" + item.text,
                  value: "" + item.value,
                });
              }
            }
            this.selectedSubAssignee = "0";
      
            if(model.assigneeId){
              let assigneeId = model.assigneeId
              let result  = await LeadStore.getSubAssigneeLookup(assigneeId, Utils.getUserId())
              if(result){
                this.subAssigneeList = []
                for (let item of result) {
                  this.subAssigneeList.push({
                    label: "" + item.text,
                    value: "" + item.value,
                  });
                }
                this.selectedSubAssignee = model.subAssigneeId ?model.subAssigneeId.toString(): "0";
              }
            }
      
            for (let item of this.subAssigneeList) {
              if (item.value === "" + model.subAssigneeId) {
                this.selectedSubAssignee = item.value || "0";
              }
            }
      
            if (model.dateFollowUp) {
              this.InputModel = new InputModel();
      
              this.InputModel.DateFollowUp = new Date(model.dateFollowUp.toString());
              this.selectedDateFollowUp = new Date(
                model.dateFollowUp.toLocaleString()
              );
      
            }
      
          } catch (e) {
              this.isMessageVisible = true
              this.response = e.response
          }
          this.isLoading = false;
        }, delay);
    
  };

  public Cancel = async () => {
    this.isSuccess = false;
    routes.leads.push();
  };

    @action async onLeadPhaseChange(value: string) {
        var model = await LeadStore.getLeadPhaseDetails(
            parseInt(value)
        );
        if (model && model.length > 0) {
            this.leadPhaseDetailList = [
                { label: "Select Lead Phase Detail", value: "0" },
            ];
            for (let item of model) {
                this.leadPhaseDetailList.push({
                    label: "" + item.text,
                    value: "" + item.value,
                });
            }
        }
        this.selectedLeadPhaseDetail = "0";
    }
  @action goToContracted = async () => {
    routes.listLeads.push();
  };
  @action goToNonContracted = async () => {
    routes.leads.push();
  };
  @action addNewAgentLead = async () => {
    routes.addAgentLead.push();
  };

  @action
  private loaded = () => {
    this.loading = false;
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action
  public Submit = async () => {
    this.isException = false;
    this.isSuccess = false;
    this.isLoading = true;
    try {
      await LeadStore.editMarketerLead({
        agentLeadId: this.AgentLeadId,
        subAssigneeId: parseInt(this.selectedSubAssignee),
        dateFollowUp: this.selectedDateFollowUp,
        leadPhaseId: parseInt(this.selectedLeadPhase),
        leadPhaseDetailId: parseInt(this.selectedLeadPhaseDetail),
        isClosed: true,
        isContracted: true,
        isContractingSent: true,
      });
     
      this.isLoading = false;
        this.Cancel();
     
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
    }
  };

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this.InputModel);
  };

  @observable
  public InputModel = new InputModel();
  @observable
  public ErrorModel = new ErrorModel(new Validator());

  public onSave?: (CarrierBankDepositID: number) => void;
  @observable
  private loading = true;
}

export class InputModel {
  //#region Properities
  @observable
  public subAssignee: string = "";
  @observable
  public DateFollowUp: Date | undefined;
  @observable
  public LeadPhase: string = "";
  @observable
  public DepositAmount: string = "";
  @observable
  public Note: string = "";
  @observable
  public PaidDivisionID: number | undefined;
  @observable
  public CompanyID: number | undefined;
  //#endregion

  //#region State Management
  @action
  public SetSubAssignee = (value: string) => {
    this.subAssignee = value;
  };

  @action
  public SetDateFollowUp = (value: Date | undefined) => {
    this.DateFollowUp = value;
  };

  @action
  public SetLeadPhase = (value: string) => {
    this.LeadPhase = value;
  };

  @action
  public SetDepositAmount = (value: string) => {
    this.DepositAmount = value;
  };

  @action
  public SetNote = (value: string) => {
    this.Note = value;
  };
  //#endregion
}

class Validator extends AbstractValidator<InputModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.LeadPhase)
      .isNotEmpty()
      .withFailureMessage("Lead Phase is required");
  }
}
