import { routes } from "../../../../router";
import { WebLinkListModel } from "../../../../services/BusinessUnitConfigService";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import BusinessUnitConfigStore from "../../../../store/BusinessUnitConfigStore";
import { observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";

export class WebLinksListViewModel extends BaseListViewModel<WebLinkListModel> {
    @observable linkTypeId: string = "0";
    @observable webLinkTypes = new Collection<{
        label: string;
        value: string;
    }>();

    constructor() {
        super("Web Links", "Web Link", routes.addWebLink, routes.editWebLink, "id", "eventDate", false);
    }

    protected async loadLookups() {
        this.webLinkTypes.values = [
            { label: "All", value: "0" },
            { label: "Carrier Update", value: "1" },
            { label: "Events", value: "3" },
            { label: "Industry News", value: "2" },
        ];
    }
    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        return await BusinessUnitConfigStore.getWebLinkList(parseInt(this.linkTypeId), pageIndex, pageSize, sortColumn, sortOrder);
    }

    protected async deleteItem(id: number) {
        await BusinessUnitConfigStore.deleteWebLink(id);
    }

    protected resetFiltersToDefault() {
        this.linkTypeId = "0";
        this.pageSize = 10;
    }
}