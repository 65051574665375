import { observable, action, computed} from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import InsuredStore from "../../store/InsuredStore";
import {MergeCustomerPreviewResponseModel} from "../../services/InsuredService"
import { Constants } from "../../infrastructure/enum/Constants";
import PageContext from "../../infrastructure/PageContext";

class AddCustomerValidator extends AbstractValidator<MergeCustomersViewModel>{
    public constructor() {
        super();
        this.validateIfString(input => input.fromCustomerId)
            .isNotEmpty()
            .withFailureMessage("From Insured Id is required");
        this.validateIfString(input => input.toCustomerId)
            .isNotEmpty()
            .withFailureMessage("To Insured Id is required");
    }
}

export class MergeCustomersViewModel implements ViewModel {

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => { }

    @action Load = async () => {
         this.resetAll();
         this.resetInput();
        await this.ResetValidate()
        await this.ResetValidateCustomer()
    }

    @observable isSuccess: boolean = false;
    @observable isException: boolean = false;
    @observable fromCustomerId: string = "";
    @observable toCustomerId: string = "";
    public ErrorModel = new ErrorModel(new AddCustomerValidator());
    @observable isLoading: boolean = false;
    @observable response: any;
    @observable isCancelConfirm: boolean = false;
    @observable isMergeCustomerConfirm: boolean = false;
    @observable exceptionMessage: boolean = false;
    @observable previewResults: boolean = false;
    @observable fromCustomerVisible: boolean = false;
    @observable toCustomerVisible: boolean = false;

    @action ResetValidateCustomer = async () => {
        return await this.ErrorModelCustomer.ResetValidation(this);
    }

    @action
    public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }

    @action resetAll() {
        this.isCancelConfirm = false;
        this.isMergeCustomerConfirm = false;
        this.exceptionMessage = false;
        this.previewResults = false;
        this.response = {}
        this.isSuccess = false;
        this.isException = false;
    }

    @computed
    get fromAddress(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromAddress && this.CustomerPreview.fromAddress != null ? this.CustomerPreview.fromAddress : "";
        return "";
    }

    @computed
    get toAddress(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toAddress &&  this.CustomerPreview.toAddress != null ?  this.CustomerPreview.toAddress : "";
        return "";
    }

    @computed
    get fromCityStateZip(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromCityStateZip &&  this.CustomerPreview.fromCityStateZip != null ?  this.CustomerPreview.fromCityStateZip : "";
        return "";
    }

    @computed
    get toCityStateZip(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toCityStateZip && this.CustomerPreview.toCityStateZip != null ? this.CustomerPreview.toCityStateZip : "";
        return "";
    }

    @computed
    get fromHICN(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromHICN && this.CustomerPreview.fromHICN != null ? this.CustomerPreview.fromHICN : "";
        return "";
    }
    @computed
    get toHICN(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toHICN;
        return "";
    }
    @computed
    get fromFullName(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromFullName;
        return "";
    }
    @computed
    get toFullName(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toFullName;
        return "";
    }
    @computed
    get fromPoliciesCount(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromPoliciesCount && this.CustomerPreview.fromPoliciesCount != null ? this.CustomerPreview.fromPoliciesCount : "";
        return "";
    }
    @computed
    get toPoliciesCount(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toPoliciesCount &&  this.CustomerPreview.toPoliciesCount != null ?  this.CustomerPreview.toPoliciesCount : "";
        return "";
    }

    @computed
    get fromDocCount(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromDocCount && this.CustomerPreview.fromDocCount != null ? this.CustomerPreview.fromDocCount : "";
        return "";
    }

    @computed
    get toDocCount(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toDocCount && this.CustomerPreview.toDocCount != null ? this.CustomerPreview.toDocCount : "";
        return "";
    }

    @computed
    get fromSSN(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromSSN && this.CustomerPreview.fromSSN != null ?
            (this.CustomerPreview.fromSSN.length > 5 ? this.CustomerPreview.fromSSN.slice(0, 5) + ('xxx-xx-'.concat('', this.CustomerPreview.fromSSN.slice(10))) : this.CustomerPreview.fromSSN): "";
        return "";
    }

    @computed
    get fromMBI(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.fromMBI && this.CustomerPreview.fromMBI != null ? this.CustomerPreview.fromMBI : "";
        return "";
    }

    @computed
    get toSSN(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toSSN && this.CustomerPreview.toSSN != null ? 
            (this.CustomerPreview.toSSN.length > 5 ? this.CustomerPreview.toSSN.slice(0, 5) + ('xxx-xx-'.concat('', this.CustomerPreview.toSSN.slice(10))) : this.CustomerPreview.toSSN) : "";
        return "";
    }

    @computed
    get toMBI(): string {
        if (this.CustomerPreview) return "" + this.CustomerPreview.toMBI && this.CustomerPreview.toMBI != null ? this.CustomerPreview.toMBI : "";
        return "";
    }

    @observable CustomerPreview: MergeCustomerPreviewResponseModel | null = null;

    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }

    @observable
    public ErrorModelCustomer= new ErrorModel(new AddCustomerValidator());
    
    @action setCancelConfirmation(value: boolean) {
        this.isCancelConfirm = value;
    }

    @action setMergeCustomerConfirmation(value: boolean) {
        this.isMergeCustomerConfirm = value;
    }

    @action setFromCustomerChange(value: string) {
        this.fromCustomerId = value;
    }

    @action setToCustomerChange(value: string){
        this.toCustomerId = value;
    }

    @action setFromCustomerVisible(value: boolean) {
        this.fromCustomerVisible = value;
    }

    @action setToCustomerVisible(value: boolean) {
        this.toCustomerVisible = value;
    }

    @action
    public Cancel = async () => {
        this.isCancelConfirm = false;
        this.resetInput();
        this.resetAll();
        PageContext.setIsMessageVisible(false);       
       await this.ResetValidate()
       await this.ResetValidateCustomer()
       // routes.customerSearch.replace()
    }

    @action
    public resetInput = async () => {
        this.fromCustomerId = "";
        this.toCustomerId = "";
        this.setFromCustomerVisible(false);
        this.setToCustomerVisible(false);
    }

    @action onMergeCustomers = async () => {
        try {
            this.isLoading = true;
            this.resetAll();
            var dto = {fromCustomerId: +this.fromCustomerId, toCustomerId: +this.toCustomerId}
            await InsuredStore.mergeCustomers(dto);
            this.isSuccess = true;
            var responseModel = {
                status: 200,
                title: Constants.MergeSuccess,
                errors: { "": [] },
            };
            this.response = JSON.stringify(responseModel);
            PageContext.setResponseMessage(this.response);
            PageContext.setIsMessageVisible(true);
            this.resetInput();
            setTimeout(() => { PageContext.setIsMessageVisible(false) }, 1500);
        } catch (e) {
            this.isException = true;
            this.exceptionMessage = e.response;
        }
        this.isLoading = false;
    }

    @action onPreview = async() => {
        try {
            this.isLoading = true;
            this.resetAll();
            var dto = {fromCustomerId: +this.fromCustomerId, toCustomerId: +this.toCustomerId}
            if(! await this.Validate()){
                this.CustomerPreview = await InsuredStore.askForCustomerMerge(dto);
                this.previewResults = true
                if (this.CustomerPreview && this.CustomerPreview.message){
                    this.exceptionMessage = true;
                    var responseModel = {
                        status: 400,
                        title: this.CustomerPreview.message,
                        errors: { "": [] },
                    };
                    this.isSuccess = true;
                    this.response = JSON.stringify(responseModel);
                    this.isLoading = false;
                    this.setFromCustomerVisible(true);
                    this.setToCustomerVisible(true);
                    PageContext.setResponseMessage(this.response);
                    PageContext.setIsMessageVisible(true);
                }
            }
        } catch (e) {
            this.isException = true
            this.previewResults = false;
            this.exceptionMessage =  e.response;
        }
        this.isLoading = false; 
    }

}