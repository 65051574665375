import React from "react";
import { observer } from "mobx-react";
import "primeflex/primeflex.css";
import { UniversalUploadViewModel } from "./UniversalUploadViewModel";  
import { Permission } from "../../infrastructure/enum/Permission";
import Utils from "../../infrastructure/Utils";
import { Card } from "primereact/card";
import { IMASMessagesHandler } from "../../views/components/controls/IMASMessagesHandler"; 
import "./UniversalUploadView.css";

export const UniversalUploadView: React.FC<{
  vm: UniversalUploadViewModel;
}> = observer((props) => { 
  
  let env = Utils.getAppHostKey(); 
  let documentUploaderUrl =
    `https://documentuploader-${env}.integrityconnect.com/app?tenantName=${Utils.getBUName()}`
      .replace("-stage", "-uat")
      .replace("-prod", "");
      
  return (
    <>
      {Utils.hasUserPermission(Permission.ViewUniversalUpload) ? (
        <div className="bob-container">
          <iframe src={`${documentUploaderUrl}`} id="bob-iframe" frameBorder="0" />
        </div>
      ) : (
        <Card>
          <IMASMessagesHandler response={Utils.errorMsg()} />
        </Card>
      )}
    </> 
  );
});
