import { observable, action } from "mobx";
import { Loadable } from "./UtilitiesHelper";

class DashboardModel {
    @observable private InputCount = new Loadable<number>(0);
    @action getInputCount = async (forceReload: boolean = false) => {
        if (!this.InputCount.isLoaded || forceReload) {
            this.InputCount.isLoaded = false;
            this.inputCountLoaded(2);
        }
        return this.InputCount;
    };

    @action private inputCountLoaded = (inputCount: number) => {
        this.InputCount.isLoaded = true;
        this.InputCount.value = inputCount;
    };

    @observable private FeedCount = new Loadable<number>(0);
    @action getFeedCount = async (forceReload: boolean = false) => {
        if (!this.FeedCount.isLoaded || forceReload) {
            this.FeedCount.isLoaded = false;
            this.feedCountLoaded(6);
        }
        return this.FeedCount;
    };

    @action private feedCountLoaded = (FeedCount: number) => {
        this.FeedCount.isLoaded = true;
        this.FeedCount.value = FeedCount;
    };

    @observable private OutputCount = new Loadable<number>(0);
    @action getOutputCount = async (forceReload: boolean = false) => {
        if (!this.OutputCount.isLoaded || forceReload) {
            this.OutputCount.isLoaded = false;
            this.outputCountLoaded(5);
        }
        return this.OutputCount;
    };

    @action private outputCountLoaded = (outputCount: number) => {
        this.OutputCount.isLoaded = true;
        this.OutputCount.value = outputCount;
    };

    @observable private WeekInputCount = new Loadable<number>(0);
    @action getWeekInputCount = async (forceReload: boolean = false) => {
        if (!this.WeekInputCount.isLoaded || forceReload) {
            this.WeekInputCount.isLoaded = false;
            this.WeekInputCountLoaded(4);
        }
        return this.WeekInputCount;
    };

    @action private WeekInputCountLoaded = (WeekInputCount: number) => {
        this.WeekInputCount.isLoaded = true;
        this.WeekInputCount.value = WeekInputCount;
    };

    @observable private TodayInputCount = new Loadable<number>(0);
    @action getTodayInputCount = async (forceReload: boolean = false) => {
        if (!this.TodayInputCount.isLoaded || forceReload) {
            this.TodayInputCount.isLoaded = false;
            this.TodayInputCountLoaded(1);
        }
        return this.TodayInputCount;
    };

    @action private TodayInputCountLoaded = (TodayInputCount: number) => {
        this.TodayInputCount.isLoaded = true;
        this.TodayInputCount.value = TodayInputCount;
    };

    @observable private WeekOutputCount = new Loadable<number>(0);
    @action getWeekOuputCount = async (forceReload: boolean = false) => {
        if (!this.WeekOutputCount.isLoaded || forceReload) {
            this.WeekOutputCount.isLoaded = false;
            this.WeekOuputCountLoaded(5);
        }
        return this.WeekOutputCount;
    };

    @action private WeekOuputCountLoaded = (WeekOuputCount: number) => {
        this.WeekOutputCount.isLoaded = true;
        this.WeekOutputCount.value = WeekOuputCount;
    };

    @observable private TodayOutputCount = new Loadable<number>(0);
    @action getTodayOutputCount = async (forceReload: boolean = false) => {
        if (!this.TodayOutputCount.isLoaded || forceReload) {
            this.TodayOutputCount.isLoaded = false;
            this.TodayOutputCountLoaded(7);
        }
        return this.TodayOutputCount;
    };

    @action private TodayOutputCountLoaded = (TodayOutputCount: number) => {
        this.TodayOutputCount.isLoaded = true;
        this.TodayOutputCount.value = TodayOutputCount;
    };
}

export default new DashboardModel();