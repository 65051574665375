import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../../viewModels/components/BaseListViewModel";
import { CreditCardChargeModel as CreditCardChargeRequestListModel } from "../../../../services/AccountingService";
import AccountStore from "../../../../store/AccountingStore";
import { observable } from "mobx";

export class CreditCardChargeRequestListViewModel extends BaseListViewModel<CreditCardChargeRequestListModel> {
    
    @observable viewOnly: boolean = true;
    @observable agentId: number | undefined = undefined;

    constructor()
    {
        super("Credit Card Charge Requests", 
              "Credit Card Charge Request", 
              routes.listCreditCardChargeRequest, 
              routes.editCreditCardChargeRequest, 'id', 'id', false);
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        return await AccountStore.loadCreditCardChargeList(this.agentId, pageIndex, pageSize, sortColumn, sortOrder);
    }
}
