import React from "react";
import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Constants } from "../../../infrastructure/enum/Constants";
import { Alert } from "../../../infrastructure/enum/Common";
import IMASLog from "../../../infrastructure/IMASLog";
import Utils from "../../../infrastructure/Utils";
import PageContext from "../../../infrastructure/PageContext";

export const IMASMessagesHandler: React.FC<{
  response?: any;
  isCardRequired?: boolean;
  isCloseButtonNotRequired?: boolean;
}> = observer((props) => {
  var statusCode: number = 0;
  var message: string = "";
  var innerError: string = "";
  try {
    let isStringResponse = typeof props.response === "string";
    var obj = isStringResponse ? JSON.parse(props.response) : props.response;
    statusCode = parseInt(obj.status);
    message = obj.title;
    var innerErrorObject: any = Object.values(
      JSON.parse(JSON.stringify(obj.errors))
    )[0];
    innerError = innerErrorObject[0];
    IMASLog.log("title: " + message);
    IMASLog.log("inner error: " + innerError);
    if (message === innerError) {
      innerError = "";
    }
  } catch {
    statusCode = 0;
    message = Constants.Error;
    innerError = "";
  }
  // Update variables according to inputs
  var commonMessage: string = message;
  var alertType: Alert;

  if (statusCode === 200 || statusCode === 204) {
    alertType = Alert.success;
  } else if (statusCode === 0) {
    // validation message
    alertType = Alert.danger;
  } else if (statusCode === 401) {
    alertType = Alert.danger;
  } else if (statusCode === 400) {
    alertType = Alert.warning;
  } else {
    alertType = statusCode === 500 ? Alert.danger : Alert.warning;
  }

  var x = document.getElementById("divAlert");
  if (x !== null) {
    if (x.style.display === "none") {
      x.style.display = "block";
    }
  }

  const onClose = () => {
    var alertDiv = document.getElementById("divAlert");
    var y = document.getElementById("alertcard");
    if (alertDiv !== null) {
      if (alertDiv.style.display === "none") {
        alertDiv.style.display = "block";
      } else {
        alertDiv.style.display = "none";
        PageContext.isCustomAttribute = false;
      }
    }
    if (y) {
      y.className = "";
    }
  };
  return (
    <div id="alertcard" className={props.isCardRequired ? "card" : ""}>
      {(commonMessage || innerError) && (
        <div
          id="divAlert"
          className={"alert alert-" + String(alertType)}
          role="alert"
        >
          <div className="display-flex-column">
            {/* {statusCode > 0 && statusCode !== 200 &&
                                <div>Status Code: {statusCode}</div>
                            } */}
            {/* <div> {commonMessage}</div> */}
            <div>{Utils.getSafeHtml(commonMessage)}</div>
            <div>{innerError}</div>
          </div>
          {statusCode !== 401 && !props.isCloseButtonNotRequired && (
            <Button icon="fa fa-times" onClick={onClose} />
          )}
        </div>
      )}
    </div>
  );
});
