import { observable, action } from "mobx";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import Collection from "../../../../../infrastructure/CollectionHelper";
import { SubmissionTypes } from "../../../../../infrastructure/enum/Submissions";
import { routes } from "../../../../../router";
import { ErrorModel } from "../../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import {
  SubmissionTypeEmailModel,
  SubmissionTypeFtpModel,
  SubmissionTypeModel,
} from "../../../../../services/BusinessUnitConfigService";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import Utils from "../../../../../infrastructure/Utils";

export class SubmissionTypesAddEditViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = "Add Submission Type";
  @observable selectedType: string = SubmissionTypes.Email;
  @observable name: string = "";
  @observable email: string = "";
  @observable faxDomain: string = "N/A";
  @observable isSecureEmail: boolean = false;
  @observable isDisabled: boolean = false;
  @observable isLoading: boolean = false;
  @observable selectedId: number = 0;
  @observable type: string = "";
  @observable server: string = "";
  @observable userName: string = "";
  @observable userPassword: string = "";
  @observable port: string = "22";
  @observable selectedFtpType: string = "";
  @observable ftpTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  //flags for fields based on type
  @observable isFaxDomainVisible: boolean = false;
  @observable isEmailVisible: boolean = false;
  @observable isNameVisible: boolean = false;
  @observable isSecureEmailVisible: boolean = false;
  @observable isDisabledVisible: boolean = false;
  @observable isServerVisible: boolean = false;
  @observable isUserNameVisible: boolean = false;
  @observable isUserPasswordVisible: boolean = false;
  @observable isPortVisible: boolean = false;
  @observable isSelectedFtpTypeVisible: boolean = false;
  //flags for fields based on type

  @observable public EmailErrorModel = new ErrorModel(
    new EmailAddEditValidator()
  );
  @observable public FTPErrorModel = new ErrorModel(new FTPAddEditValidator());

  @action Load = async (id: number, type?: string) => {
    this.reset();
    let list = [
      { label: "SFTP", value: "1" },
      { label: "FTPS", value: "2" },
    ];
    this.ftpTypeList.values = list;
    if (id !== 0) {
      this.title = "Edit Submission Type";
      this.selectedId = id;
      this.type = type ? type : "";
      let argType = this.type !== "" ? Number(this.type) : 0;
      if (argType === 0) {
        this.changeType(SubmissionTypes.Email);
      } else {
        this.changeType(SubmissionTypes.FTP);
      }
      this.getSubmissionTypeById(argType);
    } else {
      this.title = "Add Submission Type";
      this.changeType(SubmissionTypes.Email);
    }

    //move to add section while developing as edit screen would have a type already
  };

  @action getSubmissionTypeById = async (argType: number) => {
    try {
      this.isLoading = true;
      let response = await BusinessUnitConfigStore.getSubmissionTypeById(
        this.selectedId,
        argType
      );
      if (response) {
        let details: SubmissionTypeModel = response;
        this.loadDetails(details);
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action loadDetails = (dto: SubmissionTypeModel) => {
    if (dto.submissionType === 1) {
      let model = dto.submissionTypeFtpModel;
      if (model) {
        this.selectedFtpType = model.ftpType ? model.ftpType.toString() : "";
        this.isDisabled = model.isDisabled ? true : false;
        this.userPassword = model.password ? model.password : "";
        this.userName = model.userName ? model.userName : "";
        this.server = model.server ? model.server : "";
        this.port = model.port ? model.port.toString() : "";
        this.name = model.submissionTypeFTPName
          ? model.submissionTypeFTPName
          : "";
      }
    } else {
      let model = dto.submissionTypeEmailModel;
      if (model) {
        this.name = model.submissionTypeEmailName
          ? model.submissionTypeEmailName
          : "";
        this.email = model.toEmailAddress ? model.toEmailAddress : "";
        this.isSecureEmail = model.sendAsSecureEmail ? true : false;
        this.isDisabled = model.isDisabled ? true : false;
      }
    }
  };

  @action reset = () => {
    this.isFaxDomainVisible = false;
    this.isNameVisible = false;
    this.isEmailVisible = false;
    this.isSecureEmailVisible = false;
    this.isDisabledVisible = false;
    this.isServerVisible = false;
    this.isUserNameVisible = false;
    this.isUserPasswordVisible = false;
    this.isPortVisible = false;
    this.isSelectedFtpTypeVisible = false;
    this.isLoading = false;
    this.selectedType = SubmissionTypes.Email;
    this.email = "";
    this.name = "";
    this.server = "";
    this.userName = "";
    this.userPassword = "";
    this.port = "22";
    this.selectedFtpType = "";
    this.isSecureEmail = false;
    this.isDisabled = false;
    this.selectedId = 0;
    this.title = "Add Submission Type";
    this.ResetValidateEmail();
    this.ResetValidateFTP();
  };

  @action loadFields = () => {
    this.ResetValidateEmail();
    this.ResetValidateFTP();
    this.name = "";
    if (this.selectedType === SubmissionTypes.Email) {
      this.isFaxDomainVisible = true;
      this.isNameVisible = true;
      this.isEmailVisible = true;
      this.isServerVisible = false;
      this.isUserNameVisible = false;
      this.isUserPasswordVisible = false;
      this.isPortVisible = false;
      this.isSelectedFtpTypeVisible = false;
      this.isSecureEmailVisible = true;
      this.isDisabledVisible = true;
    } else {
      this.isFaxDomainVisible = false;
      this.isNameVisible = true;
      this.isEmailVisible = false;
      this.isServerVisible = true;
      this.isUserNameVisible = true;
      this.isUserPasswordVisible = true;
      this.isPortVisible = true;
      this.isSelectedFtpTypeVisible = true;
      this.isSecureEmailVisible = false;
      this.isDisabledVisible = true;
    }
  };

  @action setName = (value: string) => {
    this.name = value;
  };

  @action setEmail = (value: string) => {
    this.email = value;
  };

  @action setServer = (value: string) => {
    this.server = value;
  };

  @action setUserName = (value: string) => {
    this.userName = value;
  };

  @action setUserPassowrd = (value: string) => {
    this.userPassword = value;
  };

  @action setPort = (value: string) => {
    this.port = value;
  };

  @action setSelectedFtpType = (value: string) => {
    this.selectedFtpType = value;
  };

  @action setIsSecureEmail = (value: boolean) => {
    this.isSecureEmail = value;
  };

  @action setIsDisabled = (value: boolean) => {
    this.isDisabled = value;
  };

  @action changeType = (value: string) => {
    this.selectedType = value;
    this.loadFields();
  };

  @action onCancel = () => {
    routes.submissionTypes.push();
  };

  @action onSave = async () => {
    try {
      if (
        ((await this.ValidateEmail()) &&
          this.selectedType === SubmissionTypes.Email) ||
        ((await this.ValidateFTP()) &&
          this.selectedType === SubmissionTypes.FTP)
      ) {
        return false;
      }
      this.isLoading = true;
      if (this.selectedType === SubmissionTypes.Email) {
        let dto: SubmissionTypeEmailModel = this.emailModel();
        if (this.selectedId !== 0) {
          await BusinessUnitConfigStore.updateEmailSubmissionType(dto);
        } else {
          await BusinessUnitConfigStore.addEmailSubmissionType(dto);
        }
      } else {
        let dto: SubmissionTypeFtpModel = this.ftpModel();
        if (this.selectedId !== 0) {
          await BusinessUnitConfigStore.updateFTPSubmissionType(dto);
        } else {
          await BusinessUnitConfigStore.addFTPSubmissionType(dto);
        }
      }
      this.isLoading = false;
      Utils.goBack();
    } catch (e) {
      this.isLoading = false;
    }
  };
  protected emailModel() {
    let submissionTypeEmailId = this.selectedId !== 0 ? this.selectedId : 0;
    let submissionTypeEmailName = this.name.trim();
    let toEmailAddress = this.email.trim();
    let sendAsSecureEmail = this.isSecureEmail;
    let isDisabled = this.isDisabled;
    return {
      submissionTypeEmailId,
      submissionTypeEmailName,
      toEmailAddress,
      sendAsSecureEmail,
      isDisabled,
    };
  }
  protected ftpModel() {
    let submissionTypeFTPId = this.selectedId !== 0 ? this.selectedId : 0;
    let submissionTypeFTPName = this.name.trim();
    let server = this.server.trim();
    let userName = this.userName.trim();
    let password = this.userPassword.trim();
    let port = this.port ? Number(this.port.trim()) : 0;
    let ftpType = this.selectedFtpType !== '' ? Number(this.selectedFtpType):undefined;
    let isDisabled = this.isDisabled;
    return {
      submissionTypeFTPId,
      submissionTypeFTPName,
      server,
      userName,
      password,
      port,
      ftpType,
      isDisabled,
    };
  }

  @action public ValidateEmail = async () => {
    return this.EmailErrorModel.Validate(this);
  };

  @action public ResetValidateEmail = async () => {
    return this.EmailErrorModel.ResetValidation(this);
  };

  @action public ValidateFTP = async () => {
    return this.FTPErrorModel.Validate(this);
  };

  @action public ResetValidateFTP = async () => {
    return this.FTPErrorModel.ResetValidation(this);
  };
}

class EmailAddEditValidator extends AbstractValidator<SubmissionTypesAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.name)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
    this.validateIfString((input) => input.email)
      .isNotEmpty()
      .withFailureMessage("Email is required.");
    this.validateIfString((input) => input.email)
      .isEmail()
      .whenNotEmpty()
      .withFailureMessage("Email is invalid.");
  }
}

class FTPAddEditValidator extends AbstractValidator<SubmissionTypesAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.name)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
    this.validateIfString((input) => input.server)
      .isNotEmpty()
      .withFailureMessage("Server is required.");
    this.validateIfString((input) => input.userName)
      .isNotEmpty()
      .withFailureMessage("UserName is required.");
    this.validateIfString((input) => input.userPassword)
      .isNotEmpty()
      .withFailureMessage("Password is required.");
    this.validateIfString((input) => input.port)
      .isNotEmpty()
      .withFailureMessage("Port is required.");
  }
}
