import { routes } from "../../../../router";
import BusinessUnitConfigStore from "../../../../store/BusinessUnitConfigStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { AnnouncementListModel } from "../../../../services/BusinessUnitConfigService";
import { action, observable } from "mobx";
import { AbstractValidator } from "fluent-ts-validator";
import Utils from "../../../../infrastructure/Utils";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { Permission } from "../../../../infrastructure/enum/Permission";
import Collection from "../../../../infrastructure/CollectionHelper";
import { toast } from "react-toastify";
import PageContext from "../../../../infrastructure/PageContext";
import { closeToasterButtonTemplate } from "../../../../views/components/controls/IMASTemplates";
export class AnnouncementListViewModel extends BaseListViewModel<AnnouncementListModel> {
  constructor() {
    super(
      "Announcements",
      "Announcement",
      routes.addAnnouncement,
      routes.editAnnouncement,
      "id",
      "id",
      false
    );
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    this.addEditAnnounce = false;
    let securityLevel;
    if (
      Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      securityLevel = null;
    } else if (
      Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements)
    ) {
      securityLevel = true;
    } else {
      securityLevel = false;
    }
    return await BusinessUnitConfigStore.loadAnnouncementList(
      securityLevel,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }

  @observable ErrorModel: ErrorModel<any> | undefined;
  @observable addEditAnnounce: boolean = false;
  @observable StartDate: Date | undefined;
  @observable EndDate: Date | undefined;
  @observable AnnouncementMessage: string = "";
  @observable endMinDate: Date | undefined;
  @observable addToasterConfirm: boolean = false;
  @observable updateToasterConfirm: boolean = false;
  @observable deleteToasterConfirm: boolean = false;
  @observable timer: any;
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @observable selectedSecurity: any | undefined;
  @observable SecurityList = new Collection<any>();

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  resetFiltersToDefault() {
    this.pageSize = 50;
  }

  protected toServiceModel() {
    return {
      id: this.selectedId,
      dateStart: Utils.getValidDate(this.StartDate),
      dateEnd: Utils.getValidDate(this.EndDate),
      message: this.AnnouncementMessage,
      private: this.selectedSecurity == 1,
    };
  }

  @action resetModel = () => {
    this.StartDate = undefined;
    this.EndDate = undefined;
    this.AnnouncementMessage = "";
    this.endMinDate = undefined;
    this.addEditAnnounce = false;
    this.selectedId = 0;
    this.response =[];
    this.isMessgeVisible = false;
  };

  @action handleModalVisibility = (id: any) => {
    this.SecurityList.values = [];
    if (
      Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      this.SecurityList.values = [
        { label: "Private", value: "1", selected: false },
        { label: "Public", value: "0", selected: true },
      ];
    } else if (
      !Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      this.SecurityList.values = [
        { label: "Public", value: "0", selected: true },
      ];
    } else if (
      Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      !Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      this.SecurityList.values = [
        { label: "Private", value: "1", selected: false },
      ];
    } else {
      this.SecurityList.values = [];
    }
    this.addEditAnnounce = true;
    this.loadItem(id);
  };

  @action loadItem = async (id: number) => {
    this.response =[];
    this.isMessgeVisible = false;
    var item = await BusinessUnitConfigStore.getAnnouncementDetail(id);
    if (item) {
      this.selectedId = item.id || 0;
      this.selectedSecurity = item.private ? "1" : "0" || "0";
      var startDate = Utils.getDateInFormat(
        item.dateStart && id !== 0 ? item.dateStart : undefined
      );
      this.StartDate = new Date(startDate);
      var endDate = Utils.getDateInFormat(
        item.dateEnd && id !== 0 ? item.dateEnd : undefined
      );
      this.EndDate = new Date(endDate);
      this.endMinDate = new Date(startDate);
      this.AnnouncementMessage = item.message || "";
    }
  };

  @action openAddModal = () => {
    this.SecurityList.values = [];
    localStorage.removeItem("roleCloned");
    this.addToasterConfirm = false;
    this.updateToasterConfirm = false;
    this.deleteToasterConfirm = false;
    this.addEditAnnounce = true;
    this.response =[];
    this.isMessgeVisible = false;
    if (
      Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      this.SecurityList.values = [
        { label: "Private", value: "1", selected: false },
        { label: "Public", value: "0", selected: true },
      ];

      this.selectedSecurity = "0";
    } else if (
      !Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      this.SecurityList.values = [
        { label: "Public", value: "0", selected: true },
      ];

      this.selectedSecurity = "0";
    } else if (
      Utils.hasUserPermission(Permission.ViewAndManagePrivateAnnouncements) &&
      !Utils.hasUserPermission(Permission.ViewAndManagePublicAnnouncements)
    ) {
      this.SecurityList.values = [
        { label: "Private", value: "1", selected: false },
      ];

      this.selectedSecurity = "1";
    } else {
      this.SecurityList.values = [];
      this.selectedSecurity = "";
    }
  };
  @action closeToasters = () => {
    this.response =[];
    this.isMessgeVisible = false;
    this.isLoading = false;
    this.addToasterConfirm = false;
    this.updateToasterConfirm = false;
    this.deleteToasterConfirm = false;
    localStorage.removeItem("roleCloned");
  };
  @action loadGrid = () => {
    setTimeout(() => {
      // this.getItems(0,this.pageSize,'id',false);
      this.loadItems();
      this.resetModel();
    }, 500);
  };

  @action addAnnounce = async () => {
    try
    { this.isLoading = true;
      localStorage.setItem("roleCloned", "cloned");
      var item = this.toServiceModel();
      let res;
      this.selectedId > 0
        ? (res = await BusinessUnitConfigStore.updateAnnouncement(item))
        : (res = await BusinessUnitConfigStore.addAnnouncement(item));
      if (this.selectedId > 0) {
        Utils.showSuccessToaster("Announcement updated.", 5000, 500);
      } else {
        Utils.showSuccessToaster("Announcement created.", 5000, 500);
      }
      this.loadGrid();}
    catch(e:any) {
      this.isLoading = false;
      e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          this.showErrorMessageinToaster(`${_title}`);
      });
      this.isLoading = false;
      PageContext.setIsMessageVisible(false); 
  }
   
  };

  @action hideValidationToaster = () => {
    toast.dismiss("validation-toaster");
};

showErrorMessageinToaster(message: string) {
    toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: false,
        closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
        className: "validation-toaster website",
        draggable: false,
        toastId: "validation-toaster",
        autoClose: 7000,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
        this.hideValidationToaster();
    }, 7000);
}

  protected async deleteItem(id: number) {
    this.isLoading = true;
    localStorage.removeItem("roleCloned");
    await BusinessUnitConfigStore.deleteAnnouncement(id);
    Utils.showSuccessToaster("Announcement deleted.", 5000, 500);
    this.loadGrid();
  }
}

class AnnouncementValidator extends AbstractValidator<AnnouncementListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.AnnouncementMessage)
      .matches(/\S+/, "i")
      .hasMaxLength(255)
      .isNotEmpty()
      .withFailureMessage(
        "Message is required and should be less than 255 characters"
      );
  }
}
