import ViewModel from "../infrastructure/ViewModel";
import RouteList from "../infrastructure/RouteList";

export class TestViewModel implements ViewModel
{
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    Load(...args: any[]): Promise<void> {
        throw new Error("Method not implemented.");
    }
    Route(currentRoute: RouteList): Promise<void> {
        throw new Error("Method not implemented.");
    }   
}