// mobx
import { observable, action } from "mobx";
// store
import DocumentStore from "../../../store/DocumentStore";
// ViewModel
import ViewModel from "../../../infrastructure/ViewModel";
// route
import RouteList from "../../../infrastructure/RouteList";
import { routes } from "../../../router";
// other
import Collection from "../../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { Constants } from "../../../infrastructure/enum/Constants";
import PageContext from "../../../infrastructure/PageContext";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import Utils from "../../../infrastructure/Utils";
import { constants } from "buffer";
import { googleTagManager } from "../../../infrastructure/tracking";
import UserContext from "../../../infrastructure/UserContext";
import { Permission } from "../../../infrastructure/enum/Permission";

export class AddDocViewModel implements ViewModel {
    // ViewModel functions implementation
    get CanClose(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    get CanRoute(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    get IsLoaded(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    get IsLoading(): boolean {
        throw new Error(Constants.NoMethodImplementation);
    }
    Close(): void {
        throw new Error(Constants.NoMethodImplementation);
    }

    @action Load = async (id: number, docId: number | undefined) => {
        this.agentId = id;
        this.docId = docId ? docId : 0;
        this.selectedWorkFlow = "0";
        this.selectedMarketer = "0";
        this.selectedSubmission = "0";
        this.securityLevel = "0";
        this.notes = "";
        this.documentOption = "";
        this.fileData = null;
        this.workFlowList = [];
        await this.ResetValidate();
        this.validatePermissions();
        await this.loadAllLookupList();
        this.autoFocus = true;
        var defaultItem = { label: "Select", value: "0" };
        this.submissionList.values = [];
        this.submissionList.values.splice(0, 0, defaultItem);
        this.selectedSubmission = "0";
    };

    // declaration for route
    Route = async (currentRoute: RouteList): Promise<void> => {};

    // declaration for variables

    // route id's
    @observable agentId: number = 0;
    @observable docId: number = 0;

    // lists variables
    @observable companyList = new Collection<{
        label: string;
        value: string;
    }>();

    @observable documentTypeList = new Collection<{
        label: string;
        value: string;
    }>();

    @observable workFlowList: any = [];

    @observable marketerList = new Collection<{
        label: string;
        value: string;
    }>();

    @observable submissionList = new Collection<{
        label: string;
        value: string;
    }>();

    // page input variables
    @observable selectedCompany: string = "";
    @observable selectedType: string = "";
    @observable selectedTypeText: string = "";
    @observable selectedWorkFlow: string = "0";
    @observable selectedMarketer: string = "";
    @observable selectedSubmission: string = "";
    @observable notes: string = "";
    @observable documentOption: string = "";
    @observable fileData: any = null;
    @observable fileName: string = "";
    @observable autoFocus: boolean = false;
    @observable isEnterIntoWorkflowVisible: boolean = true;
    @observable uploadedFileName: string = "";
    @observable isAcceptFileSizeLimit: boolean = true;
    @observable isAcceptFileSize: boolean = false;
    @observable securityLevel: string = "0";
    // validation model
    @observable
    public ErrorModel = new ErrorModel(new AgentDocumentValidator());

    // loader
    @observable isLoading: boolean = false;

    @observable isMessgeVisible: boolean = false;
    @observable response: any;
    listener: AddDocumentListener | undefined;
    setListener(listener: AddDocumentListener) {
        this.listener = listener;
    }   
    @observable secLevelData = [
            { label: "Public", value: "0" },
            { label: "Private", value: "1" }
        ];
    @action setSecurityLevel(value: string) {
        this.securityLevel = value;
    }
  
    @action validatePermissions = () => {
        if (!Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments)) {
            this.removeTypesFromList("Private");
        }       
    }
    @action removeTypesFromList = (labelValue: string) => {
        var res = this.secLevelData.find(x => x.label.toLowerCase() === labelValue.toLowerCase());
        if (res) {
            this.secLevelData.forEach((item, index) => {
                if (item === res) this.secLevelData.splice(index, 1);
            });
        }
    }
    // Functions implementation
    @action checkUploadedFileSizeAccepted = async () => {
        if (this.documentOption && this.documentOption == "Submit To" && !this.isAcceptFileSizeLimit && this.selectedSubmission && this.selectedSubmission != "0" && this.selectedSubmission.split('|').shift() == "0")
            this.isAcceptFileSize = true;
        else
            this.isAcceptFileSize = false;
    }

    @action enterIntoWorkflowVisible = async (filename: string | undefined) => {
        if (filename && filename.split('.').pop().toLowerCase() != "pdf" && (
            this.selectedType === "9" ||
            this.selectedType === "42" ||
            this.selectedType === "43" ||
            this.selectedType === "14" ||            
            this.selectedType === "31" ||
            this.selectedTypeText === "W-9")) {
            this.isEnterIntoWorkflowVisible = false;
        }
        else {
            this.isEnterIntoWorkflowVisible = true;
        }
    }

    @action loadAllLookupList = async () => {
        this.workFlowList = [];
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "Select", value: "0" };
        try {
            var result = await DocumentStore.getAllLookupList(this.agentId, 0);

            if (result != null) {
                // Company
                if (result.companyList != null) {
                    this.companyList.values = [];
                    result.companyList.forEach((obj: any) => {
                        var data = {
                            label: obj.text ? obj.text : "",
                            value: obj.value ? obj.value : "",
                        };
                        this.companyList.values.push(data);
                    });
                    this.companyList.values.splice(0, 0, defaultItem);
                    this.selectedCompany = "0";
                } else {
                    this.companyList.values = [];
                    this.companyList.values.splice(0, 0, defaultItem);
                    this.selectedCompany = "0";
                }

                // Type
                if (result.documentTypeList != null) {
                    this.documentTypeList.values = [];
                    this.selectedType = "";
                    this.selectedTypeText = "";
                    result.documentTypeList.forEach((obj: any) => {
                        var documentData = {
                            label: obj.text ? obj.text : "",
                            value: obj.value ? obj.value : "",
                        };
                        this.documentTypeList.values.push(documentData);
                    });

                    this.selectedType = this.documentTypeList.values[0].value;
                    this.selectedTypeText = this.documentTypeList.values[0].label;
                } else {
                    this.documentTypeList.values = [];
                    this.selectedType = "";
                    this.selectedTypeText = "";
                }
            }
        } catch (e) {
            this.documentTypeList.values = [];

            this.companyList.values = [];
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";

            this.workFlowList = [];

            this.marketerList.values = [];
            this.marketerList.values.splice(0, 0, defaultItem);
            this.selectedMarketer = "0";

            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.isLoading = false;
    }

    @action getWorkFlowList = async (
        agentId: number,
        documentTypeId: number,
        companyId: number
    ) => {
        this.workFlowList = [];
        if (
            this.selectedCompany !== "0" &&
            this.selectedType !== "0" &&
            (this.selectedType === "9" ||
                this.selectedType === "42" ||
                this.selectedType === "43" ||
                this.selectedType === "14" ||
                this.selectedType === "31" ||
                this.selectedTypeText === "W-9")
        ) {
            try {
                this.isLoading = true;
                this.isMessgeVisible = false;
                this.response = {};
                var result = await DocumentStore.getWorkFlowList(
                    agentId,
                    documentTypeId,
                    companyId
                );
                if (result != null) {
                    result.forEach((obj: any) => {
                        var data = {
                            label: obj.name ? obj.name : "",
                            value: obj.id ? obj.id : "",
                        };
                        this.workFlowList.push(data);
                    });
                    this.selectedWorkFlow = "0";
                } else {
                    this.workFlowList = [];
                    this.selectedWorkFlow = "0";
                }
            } catch (e) {
                this.workFlowList = [];
                this.selectedWorkFlow = "0";
                this.isMessgeVisible = true;
                this.response = e.response;
            }
            this.isLoading = false;
        }
    };

    @action getSubmissionList = async (
        agentId: number,
        documentTypeId: number,
        companyId: number
    ) => {
        this.submissionList.values = [];
        if (
            this.selectedCompany !== "0" &&
            this.selectedType !== "0" &&
            (this.selectedType === "9" ||
                this.selectedType === "42" ||
                this.selectedType === "43" ||
                this.selectedType === "14" ||
                this.selectedType === "31" ||
                this.selectedTypeText === "W-9")
        ) {
            try {
                this.isLoading = true;
                this.isMessgeVisible = false;
                this.response = {};
                var defaultItem = { label: "Select", value: "0" };

                var result = await BusinessUnitConfigStore.getSubmissionTypesByCarrierId(
                    agentId,
                    documentTypeId,
                    companyId
                );
                if (result != null) {
                    if (result != null) {
                        this.submissionList.values = [];
                        this.submissionList.values = this.mapListItemAndSort(result || []);
                        this.selectedSubmission = "0";
                        if (result && result.length > 0) {
                            var selected = result.filter((item: any) => { return item.selected === true });
                            if (selected && selected.length > 0) {
                                this.selectedSubmission = selected[0].value || "0";
                            }
                        }
                    } else {
                        this.submissionList.values = [];
                        this.submissionList.values.splice(0, 0, defaultItem);
                        this.selectedSubmission = "0";
                    }
                } else {
                    this.submissionList.values = [];
                    this.submissionList.values.splice(0, 0, defaultItem);
                    this.selectedSubmission = "0";
                }
                this.checkUploadedFileSizeAccepted();
            } catch (e) {
                this.submissionList.values = [];
                this.selectedSubmission = "0";
                this.isMessgeVisible = true;
                this.response = e.response;
            }
            this.isLoading = false;
        }
    };

    @action mapListItemAndSort(listObjects: any) {
        if (listObjects) {
            listObjects.splice(0, 0, { text: "Select", value: "0" , selected: false});
            var sortList = listObjects.sort(Utils.compareListItem);
            sortList.forEach((element: any) => {
                element.label = element.text;
            });
            return sortList;
        }
        else {
            return [];
        }
    }

    //  Events

    @action setSelectedType = (value: string) => {
        this.selectedType = value;
        var typeselected = this.documentTypeList.values.filter((item: any) => { return item.value == value });
        if (typeselected && typeselected.length > 0) {
            this.selectedTypeText = typeselected[0].label;
        }
        this.getWorkFlowList(
            this.agentId,
            parseInt(this.selectedType),
            parseInt(this.selectedCompany)
        );
        this.getSubmissionList(
            this.agentId,
            parseInt(this.selectedType),
            parseInt(this.selectedCompany)
        );
        this.enterIntoWorkflowVisible(this.uploadedFileName);
    };

    @action setSelectedCompany = (value: string) => {
        this.selectedCompany = value;
        this.getWorkFlowList(
            this.agentId,
            parseInt(this.selectedType),
            parseInt(this.selectedCompany)
        );
        this.getSubmissionList(
            this.agentId,
            parseInt(this.selectedType),
            parseInt(this.selectedCompany)
        );
    };

    @action setSelectedMarketer = (value: string) => {
        this.selectedMarketer = value;
    };

    @action setSelectedSubmission = (value: string) => {
        this.selectedSubmission = value;
    };

    @action setSelectedWorkFlow = (value: string) => {
        this.selectedWorkFlow = value;
    };
    @action setNotes = (value: string) => {
        this.notes = value;
    };
    @action setDocumentOption = (value: string) => {
        this.documentOption = value;
    };
    @action goBack() {
        this.isAcceptFileSize = false;
        this.isAcceptFileSizeLimit = true;
        routes.agentDocs.replace({ agentId: "" + this.agentId });
    }

    onDrop = (value: any, fileName: string) => {
        this.fileData = value;
        this.fileName = fileName;
    };
    @action readURL(acceptedFiles: any) {
        if (acceptedFiles.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = (_event) => {
                this.fileData = reader.result;
            };
        }
    }
    @action
    public Validate = async () => {
        return this.ErrorModel.Validate(this);
    };

    @action
    public ResetValidate = async () => {
        return this.ErrorModel.ResetValidation(this);
    };

    @action CreateUpdateDocumentModel() {

        var dto: any = {
            id: this.selectedWorkFlow ? parseInt(this.selectedWorkFlow) : 0,
            fName: "",
            lName: "",
            agentId: this.agentId,
            typeId: parseInt(this.selectedType),
            companyId: parseInt(this.selectedCompany),
            notes: this.notes,
            isPrivate: this.securityLevel =="0" ? false : true,
            createdDate: new Date(),
            fileData: this.fileData,
            licenseStatusId: 0,
            isWorkflowChecked: this.documentOption === "Enter Into Workflow (Scrub)" ? true : false,
            isSendSubmission: this.documentOption === "Submit To" ? true : false,
            isDontSend: this.documentOption === "Don't Send, Just attach" ? true : false,
            ignoreForAgeCalculation: false,
            submissionTypeIds: this.selectedSubmission,
            fileName: this.fileName            
        };
        return dto;
    }
    @action SaveDocument = async (dto: any) => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        try {           
            if (dto.isSendSubmission && dto.id > 0) {
                googleTagManager.trackAction("workflow_doc_finalized_and_sent", {
                    feature: "AgentDocs",
                    user_id: UserContext.userId,
                    carrier_id: dto.companyId,
                    document_id: dto.id,
                    transmission_method: "",
                });
            }
             // API call

            await DocumentStore.CreateUpdateDocument(dto);

            this.isLoading = false;
            this.isMessgeVisible = false;

            if (dto.isWorkflowChecked) {
                if (this.listener) {
                    this.listener.documentAddedToWorkflow();
                }
            }           
            this.goBack();
        } catch (e) {
            this.isLoading = false;
            this.isMessgeVisible = false;
            this.response = e.response;
        }
    };

    showLongFileNameError(){
        this.isMessgeVisible = false;
        this.response = {};
        var responseModel = {
            status: 400,
            title: Constants.LongFileName,
            errors: { "": [] },
        };
        this.isMessgeVisible = true;
        PageContext.setIsMessageVisible(false);
        this.response = JSON.stringify(responseModel);
    }
    @action
    public onSave = async () => {
        await this.ResetValidate();
        if (!(await this.Validate())) {
            var dto = this.CreateUpdateDocumentModel();
            this.isMessgeVisible = false;
            this.response = {};
            var responseModel = {
                status: 400,
                title: Constants.NoFile,
                errors: { "": [] },
            };
            // Custom validations
            //If both #1 and #2 conditions failed then show error message "Please select a file."
            if (
                (dto.fileData === "" ||
                    dto.fileData === undefined ||
                    dto.fileData === null) &&
                (dto.id === 0 ||
                    isNaN(dto.id) ||
                    dto.id === undefined ||
                    dto.id === null)
            ) {
                this.isMessgeVisible = true;
                PageContext.setIsMessageVisible(false);
                this.response = JSON.stringify(responseModel);
                return false;
            }

            // If Enter Into Workflow (Scrub)  radio button is checked -
            // then show this message ""You can't submit a file into Workflow if it's already in Workflow."
            
            if (
                dto.id !== 0 &&
                dto.id !== undefined &&
                this.documentOption !== "" &&
                this.documentOption !== null &&
                this.documentOption !== undefined &&
                this.documentOption === "Enter Into Workflow (Scrub)"
            ) {
                responseModel = {
                    status: 400,
                    title: Constants.AlreadyExistInWorkFLow,
                    errors: { "": [] },
                };
                this.response = JSON.stringify(responseModel);
                this.isMessgeVisible = true;
                PageContext.setIsMessageVisible(false);
                return false;
            }

            // isSendDocViaEmail is true and dropdoen have no value selevted
            if (
                dto.isSendDocViaEmail === true &&
                (this.selectedMarketer === null ||
                    this.selectedMarketer === "" ||
                    this.selectedMarketer === "0" ||
                    this.selectedMarketer === undefined)
            ) {
                responseModel = {
                    status: 400,
                    title: Constants.NoFaxEmailSelection,
                    errors: { "": [] },
                };
                this.response = JSON.stringify(responseModel);
                this.isMessgeVisible = true;
                PageContext.setIsMessageVisible(false);
                return false;
            }
            // save document
            this.SaveDocument(dto);
        }
    };
}

export interface AddDocumentListener {
    documentAddedToWorkflow(): void;
}

class AgentDocumentValidator extends AbstractValidator<AddDocViewModel> {
    public constructor() {
        super();
        this.validateIfString((input) => input.selectedType)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Type is required");

        this.validateIfString((input) => input.selectedSubmission)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Submit To is required")
            .when((input) => input.documentOption === "Submit To");
    }
}
