import { observable, action, computed } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import {
  ManageSubscription,
  ManageSubscriptionIndex,
} from "../../../infrastructure/enum/Common";
import { PhoneSubscriptionViewModel } from "./PhoneSubscriptionViewModel";
import { EmailSubscriptionViewModel } from "./EmailSubscriptionViewModel";
import Utils from "../../../infrastructure/Utils";
import { Permission } from "../../../infrastructure/enum/Permission";

export class ManageSubscriptionViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (
    id: number,
    showPhoneTab: boolean,
    showEmailTab: boolean
  ) => {
    this.agentId = id;
    this.PhoneSubscriptionViewModel.resetPhoneData();
    this.EmailSubscriptionViewModel.resetEmailData();
    this.setTabWithPermission(showPhoneTab, showEmailTab);
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable selectedKey: string | undefined = "Phone";
  @observable agentId: number = 0;
  @observable PhoneSubscriptionViewModel: PhoneSubscriptionViewModel =
    new PhoneSubscriptionViewModel();
  @observable EmailSubscriptionViewModel: EmailSubscriptionViewModel =
    new EmailSubscriptionViewModel();

  @action setTabWithPermission = (
    showPhoneTab: boolean,
    showEmailTab: boolean
  ) => {
    if (Utils.hasUserPermission(Permission.UnsubscribePhone) && showPhoneTab) {
      this.selectedKey = ManageSubscription.Phone;
    } else if (
      Utils.hasUserPermission(Permission.UnsubscribeEmail) &&
      showEmailTab
    ) {
      this.selectedKey = ManageSubscription.Email;
    }
    this.handleLinkClick(this.selectedKey);
  };
  @action handleTabClick = (index: number | undefined, key: string): void => {
    switch (index) {
      case ManageSubscriptionIndex.Phone:
        this.handleLinkClick(ManageSubscription.Phone);
        break;
      case ManageSubscriptionIndex.Email:
        this.handleLinkClick(ManageSubscription.Email);
        break;
    }
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case ManageSubscription.Phone:
          this.PhoneSubscriptionViewModel.resetPhoneData();
          this.PhoneSubscriptionViewModel.Load(this.agentId);
          return;
        case ManageSubscription.Email:
          this.EmailSubscriptionViewModel.emailSubscriptions.values = [];
          this.EmailSubscriptionViewModel.emailData.values = [];
          this.EmailSubscriptionViewModel.Load(this.agentId);
          return;
        default:
          return;
      }
    }
  };

  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case ManageSubscription.Phone:
        tabIndex = 0;
        break;
      case ManageSubscription.Email:
        tabIndex = 1;
        break;
      default:
        if (Utils.hasUserPermission(Permission.UnsubscribePhone)) {
          this.selectedKey = ManageSubscription.Phone;
          tabIndex = ManageSubscriptionIndex.Phone;
        } else if (Utils.hasUserPermission(Permission.UnsubscribeEmail)) {
          this.selectedKey = ManageSubscription.Email;
          tabIndex = ManageSubscriptionIndex.Email;
        } else {
          tabIndex = 0;
        }
        break;
    }
    return tabIndex;
  }
}
