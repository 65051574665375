/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import InsuredStore from "../../store/InsuredStore";
import { CustomerSearch } from "../../infrastructure/enum/Customer";
import Utils from "../../infrastructure/Utils";
import UserContext from "../../infrastructure/UserContext";
import { googleTagManager } from "../../infrastructure/tracking";

export interface CustomerListModel {
  id?: number;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  city?: string;
  zip?: string;
  state?: string;
  dob?: Date;
  countyName?: string;
  mbi?: string;
  hicn?: string;
  ssn?: string;
  payor?: string;
  suffix?: string;
}

export class CustomersViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {};

  @action resetSearchFilters = async () => {
    this.sortColumn = "lastName";
    this.sortOrder = true;
  };

  @action validateCustomerId = (id: string | undefined) => {
    if (id) return id;
    else return "";
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable selectedCompany: string = "0";
  @observable rows: number = 200;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable isLoading: boolean = false;
  @observable searchInputValue: string = "";
  @observable customerName: string = "";
  @observable startIndex: number = 0;
  @observable isException: boolean = false;
  @observable CustomersList = new Collection<CustomerListModel>();
  @observable searchValue: any;
  @observable serachInputValue: string = "";
  @observable sortColumn: string = "lastName";
  @observable sortOrder: boolean = true;
  @observable companies = [{ label: "All", value: "0" }];
  @observable exceptionMessage: any;
  @observable searchType: string = "1";
  @observable searchTypeList = [
    { label: "Last Name", value: CustomerSearch.LastName },
    { label: "Policy #", value: CustomerSearch.Policy },
    { label: "SSN", value: CustomerSearch.Ssn },
    { label: "HICN", value: CustomerSearch.Hicn },
    { label: "MBI", value: CustomerSearch.Mbi },
    { label: "Insured Id", value: CustomerSearch.InsuredId },
    { label: "Payor/Owner", value: CustomerSearch.Payor },
    { label: "Phone Number", value: CustomerSearch.Phone },
    { label: "Date of Birth", value: CustomerSearch.Dob },
  ];
  @observable DOB: Date | undefined = undefined;
  @observable validSsnError: string = "";

  @action setIsException(value: boolean) {
    this.isException = value;
  }

  @action setDOB = (value: any) => {
    this.DOB = value;
  };
  @action setSearchType(value: string) {
    this.DOB = undefined;
    this.searchType = "";
    this.searchInputValue = "";
    if (value !== "3") {
      this.validSsnError = "";
    }
    this.searchType = value;
  }

  @action setCustomerName(value: string) {
    this.customerName = value;
  }

  @action addCustomer = async () => {
    routes.addCustomer.push();
  };

  @action onPage(firstIndex: number, rows: number) {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    this.loadCustomers();
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action mergeCustomer = async () => {
    routes.mergeCustomers.push();
  };

  @action customerSearch = async (text: string) => {
    this.loadCustomers();
  };

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.CustomersList.values = [];
  }

  @action goSearch() {
    this.sortColumn = "lastName";
    this.sortOrder = true;
    googleTagManager.trackAction("insured_search_conducted", {
      feature: routes.customerSearch.name,
      user_id: UserContext.userId,
      search_text:
        this.searchType != CustomerSearch.Dob
          ? this.searchInputValue
          : this.DOB || "",
    });
    this.loadCustomers();
  }

  @action searchIntervals = (text: string) => {
    this.searchValue = text;
    this.searchInputValue = text;
    if (!text) {
      this.noRecordsFound();
    }
  };

  @action loadCustomers = async () => {
    this.isException = false;
    try {
      this.searchValue =
        this.searchType === CustomerSearch.Ssn ||
        this.searchType === CustomerSearch.Hicn ||
        this.searchType === CustomerSearch.Mbi ||
        this.searchType === CustomerSearch.Phone
          ? this.searchValue
              .replace(/-/g, "")
              .replace(/\s/g, "")
              .replace(/\(/g, "")
              .replace(/\)/g, "")
              .trim()
          : this.searchType === CustomerSearch.Dob
          ? Utils.getDateInFormat(this.DOB)
          : this.searchValue;
      if (
        this.searchType === CustomerSearch.Ssn &&
        this.searchValue.length < 3
      ) {
        this.validSsnError = "Please enter minimum 3 digits";
        this.noRecordsFound();
        return;
      } else {
        this.validSsnError = "";
      }
      let result = await InsuredStore.getCustomerList(
        +this.searchType,
        this.searchValue,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder,
        this.searchType === CustomerSearch.InsuredId ? true : false
      );
      if (result !== null) {
        if (result.recordCount) {
          this.totalRecords = result.recordCount;
          if (result.data) this.CustomersList.values = result.data;
          if (result.data !== undefined && result.recordCount === 1) {
            routes.customerDetails.push({
              customerId: "" + result.data[0].id,
            });
          }
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };
}

export interface CustomerInformationListModel {
  customerInformationDetailModels?: CustomerInformationModel[];
}

export interface CustomerInformationModel {
  id?: number;
  lastName?: string;
  firstName?: string;
  city?: string;
  state?: string;
  defaultAddressID?: number;
  residentialAddressID?: number;
  dob?: string;
  countyName?: string;
}
