import { observable, action, computed } from "mobx";
import Collection from "../../infrastructure/CollectionHelper";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import { routes } from "../../router";

export class PagedList<T> {
  public recordCount: number = 0;
  public data: T[] = [];
}

export class BaseListViewModel<T> implements ViewModel {
  @observable readonly pageTitle: string = "";
  @observable readonly itemType: string = "";

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;

  @observable isDeleteConfirmationVisible: boolean = false;
  @observable selectedId: number = 0;
  @observable totalRowsCount: number = 10;
  @observable itemList = new Collection<T>();

  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 10;
  @observable sortColumn: string;
  @observable isSortAscending: boolean = true;
  @observable defaultSortColumn: string;
  @observable defaultIsSortAscending: boolean = true;

  private addRoute: any;
  private editRoute: any;
  private idColumnName: string;
  private isLookupsLoaded: boolean = false;

  @computed
  get CanClose(): boolean {
    return true;
  }

  @computed
  get CanRoute(): boolean {
    return true;
  }

  @computed
  get IsLoaded(): boolean {
    var isloaded = !this.isLoading;
    throw isloaded;
  }

  @computed
  get IsLoading(): boolean {
    var isloading = this.isLoading;
    throw isloading;
  }

  Route = async (_currentRoute: RouteList): Promise<void> => {};
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  constructor(
    pageTitle: string,
    itemType: string,
    addRoute: any,
    editRoute: any,
    idColumnName: string = "id",
    defaultSortColumn: string = "id",
    defaultSortAsc: boolean = true
  ) {
    this.pageTitle = pageTitle;
    this.itemType = itemType;
    this.addRoute = addRoute;
    this.editRoute = editRoute;
    this.idColumnName = idColumnName;
    this.sortColumn = defaultSortColumn;
    this.defaultSortColumn = defaultSortColumn;
    this.isSortAscending = defaultSortAsc;
    this.defaultIsSortAscending = defaultSortAsc;
  }

  @action Load = async () => {
    if (!this.isLookupsLoaded) {
      // We will load lookups only once, if there are any dependent lookups then sub-class has to handle that
      await this.loadLookups();
      this.isLookupsLoaded = true;
    }
    this.itemList.values = [];
    this.resetPaging();
    this.resetFiltersToDefault();
    await this.loadItems();
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;

    this.loadItems();
  }

  @action backToAdmin = async () => {
    routes.administration.push();
  };
  @action addItem = async () => {
    this.addRoute.push();
  };

  @action editItem = async (id: number) => {
    this.editRoute.push({ Id: id });
  };

  @action setSelectedId = (value: number) => {
    this.selectedId = value;
  };

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.sortColumn = this.defaultSortColumn;
    this.isSortAscending = this.defaultIsSortAscending;
  };
  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };

  // Delete after confirmation
  @action showDeleteConfirmation = () => {
    this.isDeleteConfirmationVisible = true;
  };

  @action hideDeleteConfirmation = () => {
    this.isDeleteConfirmationVisible = false;
  };

  @action deleteSelectedItem = async () => {
    this.hideDeleteConfirmation();

    this.isLoading = true;
    this.isException = false;
    try {
      await this.deleteItem(this.selectedId);
      setTimeout(async () => {
        await this.loadItems();
      }, Utils.timeDelay_Delete_5s());
      this.setSelectedId(0);
    } catch (error) {
      this.isException = true;
    }
    this.isLoading = false;
  };

  // Misc grid configurations exposed to avoid duplicate code
  readonly currentPageReportTemplate: string =
    "Showing {first} to {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }
  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [5, 10, 25, 50] : [];
  }

  onSort = (sortColumn: any) => {
    var sortOrder: boolean;
    if (sortColumn.sortField === this.sortColumn)
      sortOrder = !this.isSortAscending;
    else sortOrder = true;

    this.sortItems(sortColumn.sortField, sortOrder);
  };

  onPage = (pageInfo: any) => {
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action loadItems = async () => {
    this.isLoading = true;
    this.isException = false;
    try {
      let result = await this.getItems(
        this.currentPage,
        this.pageSize,
        this.sortColumn,
        this.isSortAscending
      );
      if (result !== null) {
        if (result.recordCount) this.totalRowsCount = result.recordCount;
        if (result.data) this.itemList.values = result.data;
        else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      console.log("Error while loading");
      console.log(error);
    }
  };

  @action noRecordsFound() {
    this.totalRowsCount = 0;
    this.itemList.values = [];
  }

  @action async sortItems(
    sortColumn: string,
    isSortAscending: boolean = false
  ) {
    if (
      this.sortColumn !== sortColumn ||
      this.isSortAscending !== isSortAscending
    ) {
      this.sortColumn = sortColumn;
      this.isSortAscending = isSortAscending;
      await this.loadItems();
    }
  }

  // Protected that needs overriding by subclasses
  protected onClose?: () => void;
  protected async loadLookups(): Promise<void> {}

  protected resetFiltersToDefault() {}

  // CRUD
  protected async getItems(
    _pageIndex: number,
    _pageSize: number,
    _sortColumn: string,
    _sortOrder: boolean
  ): Promise<any> {
    return new PagedList<T>();
  }

  protected async deleteItem(_id: number): Promise<any> {
    return 0;
  }
}
