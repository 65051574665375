import { AbstractValidator } from "fluent-ts-validator";
import { observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import { LicenseStatusReasonModel } from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";
import Collection from "../../../../infrastructure/CollectionHelper";

export class LicenseStatusReasonAddEditViewModel extends BaseAddEditViewModel {

    @observable licenseStatus: string = "";
    @observable reason: string = "";
    @observable statusId: number = 0;
    @observable statusList = new Collection<any>();

    constructor() {
        super("Manage Status Reasons", routes.listLicenseStatusReasons, new LicenseStatusReasonValidator());
    }

    protected toServiceModel(): LicenseStatusReasonModel {
        return {
            id: this.selectedId,
            licenseStatus: this.licenseStatus,
            reason: this.reason,
            statusId: this.statusId
        };
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await ContractsStore.getLicenseStatusReasonById(id);
        if (item) {
            this.selectedId = item.id || 0;
            this.licenseStatus = item.licenseStatus || "";
            this.reason = item.reason || "";
            this.statusId = item.statusId || 0;
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await ContractsStore.addLicenseStatusReason(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await ContractsStore.updateLicenseStatusReason(item);
    }
}

class LicenseStatusReasonValidator extends AbstractValidator<LicenseStatusReasonAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.reason)
            .isNotEmpty()
            .withFailureMessage("Reason is required");
    }
}