import { observable, action } from "mobx";
import Collection from "../../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import { SubmissionAutoUncheckRulesetListModel } from "../../../../../services/BusinessUnitConfigService";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import ContractsStore from "../../../../../store/ContractsStore";
import DocumentStore from "../../../../../store/DocumentStore";
import Utils from "../../../../../infrastructure/Utils";


export class AutoUnchecksViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

 

  @observable sortColumn: string = "submissionAutoUncheckRulesetId";
  @observable sortOrder: boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable selectedId: number = 0;
  @observable ItemsList =
    new Collection<SubmissionAutoUncheckRulesetListModel>();

  @observable autoUncheckTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable carrierList = new Collection<{
    label: string;
    value: string;
  }>();     
                  
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedType: number = 0;
  @observable isdeleteConfirm: boolean = false;

  @observable selectedAutoUncheckType: string = "-1";
  @observable selectedDocumentType: string = "0";
  @observable selectedCarrierId: string = "0";

  @action Load = async () => {
    await this.getDropdownValues();
    await this.loadAutoUnchecksList();
  };

  @action setDeleteAutoUncheckConfirmation = (value: boolean) => {
    this.isdeleteConfirm = value;
  };

  @action deleteAutoUncheckType = async () => {
    this.setDeleteAutoUncheckConfirmation(false);
    //selectedType values = 0 for agent, 1 for customer
    await BusinessUnitConfigStore.deleteSubmissionAutoUncheckRuleset(
      this.selectedId
    );
    await this.loadAutoUnchecksList();
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(function (a: any, b: any) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      sortList.splice(0, 0, { label: "All ", value: "0"});
      return sortList;
    } else {
      return [];
    }
  }

  @action getDropdownValues = async () => {
    await this.getCarrierList();
    await this.getDocumentTypeList();
    await this.getRequirementTypeList();
  };

  @action getRequirementTypeList = async () => {
    let list = [
      { label: "All", value: "-1" },
      { label: "Agent", value: "0" },
      { label: "Insured", value: "1" },
    ];
    this.autoUncheckTypeList.values = [];
    this.autoUncheckTypeList.values = list;
    this.selectedAutoUncheckType = this.autoUncheckTypeList.values[0].value;
  };

  @action getDocumentTypeList = async () => {
    let result = await DocumentStore.getDocumentAllFileTypesLookup();
    if (result && result.length > 0) {
      this.documentTypeList.values = [];
      this.documentTypeList.values = this.mapListItemAndSort(result);
      this.selectedDocumentType = "0";
    }
  };

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.carrierList.values = []; 
    this.showInactiveCarriers ? (this.carrierList.values = this.carriersInitial.values) :
    this.carrierList.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action getCarrierList = async () => {
    let result = await ContractsStore.GetActiveInactiveAllCarrierLookup();
    if (result && result.length > 0) {
      this.carrierList.values = [];
      this.carrierList.values = this.mapListItemAndSort(result);
      this.carriersInitial.values = this.carrierList.values;
      this.carrierList.values = Utils.carriersFilter(this.carriersInitial.values);
      this.selectedCarrierId = "0";
    }
  };

  @action loadAutoUnchecksList = async () => {
    let selectedType = Number(this.selectedAutoUncheckType);
    let documentTypeId = Number(this.selectedDocumentType);
    let carrierID = Number(this.selectedCarrierId);
    let result =
      await BusinessUnitConfigStore.getAllSubmissionAutoUncheckRulesets(
        selectedType,
        documentTypeId,
        carrierID,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
    if (result && result.data) {
      this.totalRecords = result.recordCount || 0;
      this.ItemsList.values = result.data.length > 0 ? result.data : [];
    }
  };

  @action goSearch = () => {
    this.loadAutoUnchecksList();
  };

  @action resetFilters = async () => {
    this.rows = 25;
    this.startIndex = 0;
    this.sortColumn = "submissionAutoUncheckRulesetId";
    this.sortOrder = false;
    this.isdeleteConfirm = false;
    this.selectedId = 0;
    this.selectedType = 0;
    this.selectedAutoUncheckType = "-1";
    this.selectedDocumentType = "0";
    this.selectedCarrierId = "0";
    this.showInactiveCarriers = false;
    this.carrierList.values = Utils.carriersFilter(this.carriersInitial.values);
    await this.loadAutoUnchecksList();
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action setSelectedId(id: number) {
    this.selectedId = id;
  }
  @action setSelectedType(id: number) {
    this.selectedType = id;
  }

  @action setSelectedAutoUncheckType(value: string) {
    this.selectedAutoUncheckType = value;
  }

  @action setSelectedDocumentType(value: string) {
    this.selectedDocumentType = value;
  }
  @action setSelectedCarrierId(value: string) {
    this.selectedCarrierId = value;
  }
}
