import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import WorkflowStore from "../../store/WorkflowStore";
import { LicenseCommunicationItem } from "../../services/WorkflowService";
import Collection from "../../infrastructure/CollectionHelper";

export class LicenseCommunicationViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }

  get CanRoute(): boolean {
    return true;
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable documentId: number = 0;
  @observable response: any;
  @observable isMessageVisible: boolean = false;
  @observable company: string = "";
  @observable fileType: string = "";
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 50;
  @observable startIndex: number = 0;
  @observable sortColumn: string = "id";
  @observable sortAscending: boolean = false;
  @observable communicationList = new Collection<LicenseCommunicationItem>();


  @action reset = () => {
    this.sortAscending = false;
    this.sortColumn = "id";
    this.isMessageVisible = false;  
    this.response = '';
    this.company = '';
    this.fileType = '';
    this.documentId = 0;
    this.communicationList.values = []
    this.rows = 50;
    this.first = 0;
    this.startIndex = 0;
    this.totalRecords = 0;
  }

  @action Load = async (docId: number) => {
    this.documentId = docId;
    await this.getLicenseComm();
  };
  @action setSortOrder() {
    this.sortAscending = !this.sortAscending;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.communicationList.values = [];
  }

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    //call load communication method
  }

  @action getLicenseComm = async () => {
    this.isMessageVisible = false;
    this.response = '';
    try {
      let result = await WorkflowStore.licenseCommunication(this.documentId);
      if (result) {
        this.company = result.company ? result.company : "";
        this.fileType = result.fileType ? result.fileType : "";
        this.communicationList.values =
          result.data && result.data.length > 0 ? result.data : [];
        this.totalRecords = result.data ? result.data.length : 0;
      } else {
        this.noRecordsFound();
      }    
    } catch (e) {
      console.log(e);
      this.isMessageVisible = true;
      this.response = e.response;   
    }
  };
}
