/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action, } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import IMASLog from "../../infrastructure/IMASLog";
import { TaskListModel } from "../../services/TaskService";
import TaskStore from "../../store/TaskStore"
import Utils from "../../infrastructure/Utils";

export class CustomerTasksViewModel implements ViewModel {

    @action Load = async () => {
        this.isLoading = false;
        this.customerId = +RouteParamDetails.id;
    }

    @observable isLoading: boolean = false;
    @observable customerId: number = 0;
    @observable isCompleteTaskConfirm: boolean = false;
    
    Route = async (currentRoute: RouteList): Promise<void> => {
    }
    
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @observable taskId: number = 0
    @observable rows: number = 25;
    @observable isDeleteTaskConfirm: boolean = false
    @observable totalRecords: number = 0;
    @observable TaskList = new Collection<TaskListModel>();
    @observable first: number = 0;
    @observable sortColumn: string = 'id';
    @observable sortOrder: boolean = false;
    @observable startIndex: number = 0;
    @observable SelectedTasks = []

    @action load = async (id: number) => {
        this.customerId = id;
        this.loadTasks();
    };

    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }

    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows
        this.loadTasks()
    }

    @action loadTasks = async () => {
        //this.isLoading = true;
        try {
            var customerTasks = await TaskStore.getCustomerTasks(this.customerId, this.startIndex, this.rows, this.sortColumn, this.sortOrder)
            if (customerTasks != null) {
                if (customerTasks.data) {
                    this.totalRecords = (customerTasks.recordCount) ? customerTasks.recordCount : 0
                    this.TaskList.values = customerTasks.data
                }
                else {
                    this.noRecordsFound()
                }
            }
        } catch (e) {
            IMASLog.log('exception from store: ' + e.value)
            this.noRecordsFound()
        }
        this.isLoading = false;
    }

    @action noRecordsFound() {
        this.totalRecords = 0
        this.TaskList.values = []
    }

    @action setSelectedTask(taskId: number) {
        this.taskId = taskId
    }

    @action setDeleteTaskConfirmation(value: boolean) {
        this.isDeleteTaskConfirm = value
    }

    @action setCompleteTaskConfirmation(value: boolean) {
        this.isCompleteTaskConfirm = value;
    }

    @action onCompleteTaskClick= async () => {
        try {
            await TaskStore.completeTaskByTaskId({id: this.taskId, complete: true})
            setTimeout(async () => {
                this.setCompleteTaskConfirmation(false)
                await this.loadTasks()
            }, Utils.timeDelay_Delete());
        } catch (error) {
            IMASLog.log('exception while deleting task.')
        }
    }

}