import React from "react";
import { observer } from "mobx-react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import utils from "../../infrastructure/Utils";
import IMASTableRow from "../components/controls/IMASTableRow";
import { CustomInputDate } from '../components/controls/CustomInputDate'
import { CustomInputTextArea } from "../components/controls/IMASInputTextArea";
import { IMASCheckbox } from '../components/controls/IMASCheckbox';
import { StartCallingViewModel } from "../../viewModels/autoDialers/StartCallingViewModel";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../infrastructure/AppInsights";
import { AgentSummaryMarketer, AgentSummaryHierarchy } from "../../services/AutoDialerService";
import Utils from "../../infrastructure/Utils";
import { Permission } from '../../infrastructure/enum/Permission';
import { Constants } from "../../infrastructure/enum/Constants";

export const StartCallingView: React.FC<{ vm: StartCallingViewModel; }> = observer((props) => {
    const dateTemplate = (value: any) => {
        return (
            <span>
                {utils.getDateInFormat(value) + " " + utils.getTimeInFormat(value)}
            </span>
        );
    };

    const followupTaskChange = (value: boolean) => {
        props.vm.followupTask = value;
    }

    const makeNotePrivateChange = (value: boolean) => {
        props.vm.makeNotePrivate = value;
    }


    const getCityStateZipcode = () => {
        return `${props.vm.city ? props.vm.city + ", " : ''} ${props.vm.state  ? props.vm.state : ''} ${props.vm.zip ? props.vm.zip : ''}`;
    }

    const taskDateChange = (value: Date | undefined) => {
        if (value)
            props.vm.setTaskDate(value)
    }

    const phoneNumberFormat = (phoneNumber: any) => {
        var cleaned = ('' + phoneNumber).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return phoneNumber;
    }
    const phoneNumberClick = () => {
        props.vm.callPhoneNumber(phoneNumberFormat(props.vm.phonenumber), 'AutoDialer')
        //window.open("rcmobile://call?number="+ props.vm.phonenumber, "_new");
    }
    const onClickEvent = (e: any, param: string) => {
        var obj = JSON.parse(JSON.stringify(e));
        let id = ''
        let divisionId: number | undefined;
        switch (param) {
            case 'note':
                id = '' + obj.userId;
                break;
            case 'hierarchies':
                id = '' + obj.agentId;
                break;
            case 'marketers':
                id = '' + obj.marketerId;
                divisionId = obj.divisionId;
                break;
        }
        props.vm.goToNotesTab(id, param, divisionId)
    }

    const heirarchy = (res: AgentSummaryHierarchy) => {
        if (res.firstName && res.lastName)
            return res.firstName + " " + res.lastName;
        else if (res.lastName)
            return res.lastName;
        else if (res.firstName)
            return res.firstName
    }

    const marketer = (res: AgentSummaryMarketer) => {
        let name = '';
        if (res.firstName && res.firstName != null) {
            name = res.firstName + " " + res.lastName;
        }
        else {
            name = '' + res.lastName;
        }
        if (res.abbreviation) {
            name = name + " [" + res.abbreviation + "]";
        }
        return name;
    }

    return (
        <>
            <div className={"card"}>
                <div className="summary">
                    <div className="p-grid screen-title">
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="back-icon t-cell">
                                <i className="fa fa-bx fa-arrow-circle-o-left cursor-pointer icon-fill-color"
                                    onClick={() => { props.vm.goBack(); }} aria-hidden="true"></i>
                            </div>
                            <div className="title-text t-cell">Dialer</div>
                        </div>
                        <div className="p-col-6 text-right">
                            {(props.vm.remaining !== 0) && (
                                <>
                                    <Button
                                        label="Mark This Agent As Called"
                                        id="markAgentButton"
                                        className="primary-button"
                                        onClick={() => props.vm.markAgentAsCalled(props.vm.agentId, props.vm.callListId)}
                                        icon="fa fa-check-square-o"
                                        style={{ width: "auto" }}
                                    ></Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                               <Button
                                        label="Find Another Agent"
                                        id="findAgentButton"
                                        className="primary-button"
                                        onClick={() => props.vm.findAnotherAgent(props.vm.callListId, props.vm.agentId, props.vm.agentName)}
                                        icon="fa fa-search"
                                        style={{ width: "auto" }}
                                    ></Button>
                               &nbsp;&nbsp;&nbsp;&nbsp;
                             </>
                            )}
                        </div>
                    </div>
                    <div className="p-grid editor-host gray-background mt-5">
                        <div className="p-col-4 p-md-4 p-lg-4" >
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="form-group">
                                            <div className="section-title  secondry-title">List Details</div>
                                            <div className="auto-dialer-calldata-section">
                                                <table className="order-details">
                                                    <IMASTableRow
                                                        styleName="row-value"
                                                        header="List Name:"
                                                        value={props.vm.callListName}
                                                        comment=""
                                                    />
                                                    <IMASTableRow
                                                        styleName="row-value"
                                                        header="Called:"
                                                        value={props.vm.called}
                                                        comment=""
                                                    />
                                                    <IMASTableRow
                                                        styleName="row-value"
                                                        header="Remaining:"
                                                        value={props.vm.remaining}
                                                        comment=""
                                                    />
                                                    <IMASTableRow
                                                        styleName="row-value"
                                                        header="Total List Count:"
                                                        value={props.vm.startingTotal}
                                                        comment=""
                                                    />
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="form-group">
                                            <div className="section-title  secondry-title">Last 5 Called Agents</div>
                                            <div className="auto-dialer-calldata-section">
                                                {
                                                    (props.vm.lastCalledAgentsList.values && props.vm.lastCalledAgentsList.values.length > 0) ? props.vm.lastCalledAgentsList.values.map((agent: any) => {
                                                        // eslint-disable-next-line no-script-url
                                                        return <div > <span className="text-black cursor-pointer" onClick={() => props.vm.getAgentInfo(agent)}> <i className="fa fa-users"></i> {agent.agentId} {Utils.textCapitalize(agent.agentName)}</span></div>
                                                    }) : <> <div className="">No agents have been called from this list.</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div> <div>&nbsp;</div> </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-4" >
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="form-group">
                                            <div className="section-title  secondry-title">Last 5 Skipped Agents</div>
                                            <div className="auto-dialer-calldata-section">
                                                {
                                                    (props.vm.last5SkippedAgentsList && props.vm.last5SkippedAgentsList.length > 0) ? props.vm.last5SkippedAgentsList.map((agent: any) => {
                                                        return <div > <span className="text-black cursor-pointer" onClick={() => props.vm.getAgentInfo(agent)}> <i className="fa fa-users"></i> {agent.agentId} {Utils.textCapitalize(agent.agentName)}</span></div>
                                                    }) : <> <div className="">No agents have been skipped on this list.</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div> <div>&nbsp;</div> </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-12" style={{ marginTop: "-1em" }} >
                            <Card>
                                <div className="section-title  secondry-title">Agent Information</div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3 p-lg-3 padding-bottom-0">
                                        <div className="form-group padding-bottom-0">
                                            <table className="order-details">
                                                <IMASTableRow
                                                    styleName="row-value"
                                                    header="Date Pulled:"
                                                    value={dateTemplate(props.vm.currentDate)}
                                                    comment=""
                                                />
                                                <IMASTableRow
                                                    styleName="row-value"
                                                    header="Time Zone:"
                                                    value={props.vm.timezone}
                                                    comment=""
                                                />
                                                <IMASTableRow
                                                    styleName="row-value"
                                                    header="City / State:"
                                                    value={getCityStateZipcode()}
                                                    comment=""
                                                />
                                            </table>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-lg-3 padding-bottom-0">
                                        <div className="form-group padding-bottom-0">
                                            <table className="order-details">
                                                <tr>
                                                    <td className="hdr-label">Agent Id:</td>
                                                    <td className="pl-5">
                                                        <span onClick={(e: any) => { props.vm.goToNotesTab(props.vm.agentId ? props.vm.agentId.toString() : '', 'hierarchies', undefined) }} className='link margin-right-10' >{props.vm.agentId}</span>
                                                        {Utils.hasUserPermission(Permission.ViewLeads) && Utils.hasUserPermission(Permission.CreateLead, Constants.autoDialerCreateLead) && (<span onClick={(e: any) => { props.vm.addNewAgentLead(); }} className='link margin-right-10 row-fontcolor' >Create Lead</span>)}
                                                    </td>
                                                </tr>
                                                <IMASTableRow
                                                    styleName="row-value"
                                                    header="Agent Name:"
                                                    value={Utils.textCapitalize(props.vm.agentName)}
                                                    comment=""
                                                />
                                                <IMASTableRow
                                                    styleName="row-value"
                                                    header="Status:"
                                                    value={props.vm.statustext}
                                                    comment=""
                                                />
                                            </table>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-lg-6 padding-bottom-0">
                                        <div className="form-group padding-bottom-0">
                                            <table className="order-details">
                                                {Utils.hasUserPermission(Permission.Email) ? (
                                                    <>
                                                        <tr>
                                                            <td className="hdr-label">Email:</td>
                                                            <td >
                                                                <a className="link text-trans-lowercase" href={"mailto:" + props.vm.email}>{props.vm.email} </a>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : !Utils.hasUserPermission(Permission.Email) && (
                                                    <>
                                                        <IMASTableRow
                                                            styleName="text-trans-lowercase"
                                                            header="Email:"
                                                            value={props.vm.email}
                                                            comment=""
                                                        />
                                                    </>
                                                )}
                                                <tr>
                                                    <td className="hdr-label">Phone:</td>
                                                    <td >
                                                        <span className="link cursor-pointer" onClick={phoneNumberClick} >{phoneNumberFormat(props.vm.phonenumber)}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="p-col-12 p-md-7 p-lg-7 row-fontcolor padding-bottom-0">
                                            {props.vm.updatedPhoneNumberMessage}
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-12 p-lg-12 auto-dialer-marketers-heirerchy-section">

                                        <table className="order-details">
                                            <tr>
                                                <td className="hdr-label">Marketers:</td>
                                                <td className="pl-5">
                                                    {props.vm.marketers.values && props.vm.marketers.values.length > 0 ?
                                                    props.vm.marketers.values.map(data => {
                                                        return (
                                                            <span onClick={(e: any) => onClickEvent(data, 'marketers')} className='link margin-right-10' >{marketer(data)}</span>

                                                        );
                                                    }): "None"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="hdr-label">Hierarchy:</td>
                                                <td className="pl-5">
                                                {props.vm.hierarchies.values && props.vm.hierarchies.values.length > 0 ?
                                                    props.vm.hierarchies.values.map(data => {
                                                        return (
                                                            <span onClick={(e: any) => onClickEvent(data, 'hierarchies')} className='link margin-right-10' >{heirarchy(data)}</span>

                                                        );
                                                    }): "None"}
                                                </td>
                                            </tr>
                                        </table>
                                        {Utils.hasUserPermission(Permission.DeletePhoneNumber) && (
                                            <div className="p-col-12 text-right">
                                                <Button
                                                    id="badPhoneNumberButton"
                                                    label="Bad Phone #"
                                                    icon="fa fa-save"
                                                    onClick={() => props.vm.UpdatePhoneNumber(props.vm.agentId, props.vm.contactId)}
                                                    className="primary-button"
                                                    style={{ width: "auto" }}
                                                />
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </Card>
                        </div>
                        <div className="p-col-6 p-md-4 p-lg-6 " style={{ marginTop: "-1em" }}>
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="section-title  secondry-title">Add Note</div>
                                        <div className="form-group">
                                            <CustomInputTextArea
                                                id="txtcomments"
                                                errors={props.vm.ErrorModel.getError("addComments")}
                                                label="Add a Note to this Agent"
                                                rows={4}
                                                isrequired={true}
                                                maxLength={5000}
                                                onBlur={(value: string) =>
                                                    props.vm.setAddComments(value)
                                                }
                                                placeHolder=""
                                                value={props.vm.addComments}
                                                onChange={(value: string) =>
                                                    props.vm.setAddComments(value)
                                                }
                                            />
                                        </div>
                                        <div >
                                            <IMASCheckbox
                                                label="Create follow-up task for me on"
                                                id="chkFollowupTask"
                                                onChange={(e) => {
                                                    followupTaskChange(e.checked)
                                                }}
                                                value={props.vm.followupTask}
                                                disabled={false}
                                            />
                                            <CustomInputDate
                                                id='taskDate'
                                                dateFormat="mm/dd/yy"
                                                errors={undefined}
                                                label=""
                                                isrequired={false}
                                                value={props.vm.taskDate}
                                                onChange={taskDateChange}
                                            />
                                        </div>
                                        <div >
                                            <IMASCheckbox
                                                label="Make this note PRIVATE (outside Agencies can't see it)"
                                                id="chkPrivateNote"
                                                onChange={(e) => {
                                                    makeNotePrivateChange(e.checked)
                                                }}
                                                value={props.vm.makeNotePrivate}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-6 p-lg-6 pt-30 row-fontcolor">
                                                {props.vm.noteAndTaskMessage}
                                            </div>
                                            <div className="p-col-12 p-md-6 p-lg-6 text-right">
                                                <Button
                                                    id="saveNoteOrTaskButton"
                                                    label="Save Note / Task"
                                                    icon="fa fa-save"
                                                    onClick={() => props.vm.saveNoteAndTask(props.vm.callListId)}
                                                    className="primary-button"
                                                    style={{ width: "auto" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="p-col-6 p-md-4 p-lg-6" style={{ marginTop: "-1em" }}>
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="section-title secondry-title">Notes History</div>
                                        <div className="auto-dialer-notes-section">
                                            {(props.vm.agentNotesModel.values && props.vm.agentNotesModel.values.length > 0) ? props.vm.agentNotesModel.values.map((notesModel) => {
                                                return (<div className="display-flex-column margin-10 p-b10" >
                                                    <div className="p-grid auto-dialer-notes-header" >
                                                        <span className="p-col-6" >
                                                            {" "}
                                                            {dateTemplate(notesModel.dateCreated)}
                                                        </span>
                                                        <div className="p-col-6">
                                                            <div className="add-note f-right padding-right-10">
                                                                <span  >
                                                                    {" "}
                                                                    {notesModel.name}
                                                                </span>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span> {notesModel.comments} </span>
                                                </div>)
                                            }) : <div className="">There are no Notes for this Agent.</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
});

export default withAITracking(reactPlugin, StartCallingView, "StartCalling");
