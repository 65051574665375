import { AbstractValidator } from "fluent-ts-validator";
import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { routes } from "../../router";
import Collection from "../../infrastructure/CollectionHelper";
import LeadStore from "../../store/LeadStore";
import IMASLog from "../../infrastructure/IMASLog";
import { AgentListModel } from "../../services/ProducerSearchService";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import ProductStore from "../../store/ProductStore";
import Utils from "../../infrastructure/Utils";
import UserContext from "../../infrastructure/UserContext";
import { Permission } from "../../infrastructure/enum/Permission";
import { Constants } from "../../infrastructure/enum/Constants";
import LeadsTabContext from "../../infrastructure/LeadsTabContext";
import ProducerStore from "../../store/ProducerStore";

export class AgentLeadAddViewModel implements ViewModel {
  constructor(private OnSave?: (BankAccountID: number) => void) { }
  @observable isLoading: boolean = true;
  @observable companyList = [{ label: "Select Carrier", value: "0" }];
  @observable leadSourceList = [{ label: "Select Lead source", value: "0" }];
  @observable assigneeList = [{ label: "Select Assignee", value: "0" }];
  @observable tempSubAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
  @observable subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
  @observable agentList = [{ label: "Select Agent", value: "0" }];
  @observable productLineList = [
    { label: "Select Line of Product", value: "0" },
  ];
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable isProductLineAlreadyReffered: boolean = false;
  @observable isProductLineActive: boolean = false;
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable CarrierBankDepositID: number = 0;
  @observable selectedCompany: string = "";
  @observable selectedLeadSource: string = "";
  @observable selectedAssignee: string = "";
  @observable selectedSubAssignee: string = "";
  @observable selectedAgent: string = "";
  @observable selectedProductLine: string = "";
  @observable note: string = "";
  @observable hasPermission: boolean = true;
  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return !this.loading;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }

  Close = (): void => {
    routes.leads.push();
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setisUplineWarning(value: boolean) {
    this.isUplineWarning = value;
  }

  @action setisWarningDisplayed(value: boolean) {
    this.isWarningDisplayed = value;
  }

  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action checkAgentProductLineAlreadyReffered = async () => {
    if (this.uplineId !== 0 && this.selectedProductLine !== "" && this.selectedProductLine !== "0") {
      var productLine = await ProductStore.getAgentProductLineByProduct(this.uplineId, parseInt(this.selectedProductLine));
      if (productLine !== null) {
        var isProductLineAlreadyReffered = productLine.refferred;
        var isProductLineActive = productLine.refferred;
        this.isProductLineActive= isProductLineActive ? true : false;
        this.isProductLineAlreadyReffered = isProductLineAlreadyReffered ? true : false;
      }
      else {
        this.isProductLineAlreadyReffered = false;
        this.isProductLineActive = false;
      }

      this.isMessgeVisible = false;
      this.response = {};
      if (this.isProductLineActive  ) {
       var responseModel = {
          status: 400,
          title: Constants.LineAlreadyActive,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
        this.isMessgeVisible = true;

      }

    }
  }
  @action loadSubAssignee = async (assigneeId: number) => {
    if(assigneeId !== null  && assigneeId > 0)
    {
      let result = await LeadStore.getSubAssigneeLookup(assigneeId, Utils.getUserId())
    if (result) {
      this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
      for (let item of result) {
        this.subAssigneeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      for (let item of this.assigneeList) {
        if (item.value === this.selectedAssignee) {
          var existItem = this.subAssigneeList.find(p => p.value === this.selectedAssignee);
          if (existItem === undefined) {
            this.subAssigneeList.push({
              label: "" + item.label,
              value: "" + item.value,
            });
          }
          this.selectedSubAssignee = item.value;
        }
      }
    }
    }
    else{
        var response = await LeadStore.getDropdownValues(true, true);
      if (response) {
        this.subAssigneeList=[{ label: "Select Sub-Assignee", value: "0" }];
       // var subassigneelist = [...response.subAssignee].sort(this.sortTypeName);
        // for (let item of subassigneelist) {
        //   this.subAssigneeList.push({
        //     label: "" + item.text,
        //     value: "" + item.value,
        //   });
        // }
        this.selectedSubAssignee = "0";
      }
    }
    


  };
  @action setSelectedAssignee(value: string) {
    this.selectedAssignee = "" + value;
  }
  @action leadSourceChange(value: string) {
    this.selectedLeadSource = "" + value;
    if (value === "2") {
      this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
      this.selectedAssignee = "0";
      this.selectedSubAssignee = "0";
    }
  }

  public Load = async (): Promise<void> => {

    let delay = 0;
    if(UserContext.permissions.length <= 0)
        {
            delay = 2000;
        }
        setTimeout (async () => {
          this.isMessgeVisible = false;
          this.response = {};
          this.loading = true;
          this.InputModel.DepositDate = new Date();
          this.InputModel = new InputModel();
          await this.loadDropdowns();
          this.isLoading = false;
      
          if (
            UserContext.permissions.length > 0 &&
            !Utils.hasUserPermission(Permission.CreateLeadViaAgentProfileScreen)
          ) {
            this.hasPermission = false;
          } else {
            this.hasPermission = true;
          }
          if (
            UserContext.permissions.length > 0 &&
            !Utils.hasUserPermission(Permission.ViewLeads)
          ) {
            this.hasPermission = false;
          } else {
            this.hasPermission = true;
          }
          let isFromAgent = LeadsTabContext.getIsGotoLeadFromAgent()
          if(isFromAgent){
            let agentId = Number(localStorage.getItem(Constants.currentAgentId))
            var agentInfo = await ProducerStore.getAgentProfileInfoById(agentId);
            if(agentInfo){
              this.uplineId = Number(agentId)
              this.selectedUser = (agentInfo.name ?  
                (agentInfo.dba && agentInfo.dba.split(',').length > 1 ? agentInfo.dba.split(',')[1].trim() + ", " + agentInfo.dba.split(',')[0 ] : agentInfo.name)
                + (agentInfo.nickName ? " (" +agentInfo.nickName+")" : "") : "");
            }
            LeadsTabContext.setIsGotoLeadFromAgent(false)
          }
          if (localStorage.getItem("agentId") && this.uplineId === 0) {
             let localagentId = localStorage.getItem("agentId");
             let localagentName = localStorage.getItem("agentName");
            if (localagentId && !isNaN(parseInt(localagentId)) &&  parseInt(localagentId) > 0) {
              this.uplineId = parseInt(localagentId);
              this.selectedUser = localagentName ? localagentName : '';
              localStorage.removeItem("agentId");
              localStorage.removeItem("agentName");
            }
          }
        }, delay);
    
  };
  sortTypeName = (a: any, b: any) => {
    let comparison = 0;
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }

    return comparison;
  };
  @action loadDropdowns = async () => {
    this.loading = true;
      var response = await LeadStore.getDropdownValues(true, true);

    if (response) {
      if (response.carriers && this.companyList.length === 1) {
        var carrierlist = [...response.carriers].sort(this.sortTypeName);
        for (let item of carrierlist) {
          this.companyList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }

      if (response.assignee && this.assigneeList.length === 1) {
        var assigneelist = [...response.assignee].sort(this.sortTypeName);
        for (let item of assigneelist) {
          this.assigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }

      if (response.subAssignee && this.tempSubAssigneeList.length === 1) {
        var subassigneelist = [...response.subAssignee].sort(this.sortTypeName);
        for (let item of subassigneelist) {
          this.tempSubAssigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        this.subAssigneeList = this.tempSubAssigneeList;
        this.selectedSubAssignee = "0";
      }

      if (response.productLine && this.productLineList.length === 1) {
        for (let item of response.productLine) {
          this.productLineList.push({
            label: "" + item.productLineName,
            value: "" + item.id,
          });
        }
      }

      if (response.leadSource && this.leadSourceList.length === 1) {
        var leadsourcelist = [...response.leadSource].sort(this.sortTypeName);
        for (let item of leadsourcelist) {
          this.leadSourceList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
    }
    this.resetPage();
    this.loading = false;
  };

  @action resetPage() {
    this.selectedCompany = "0";
    this.selectedAssignee = "0";
    this.selectedSubAssignee = "0";
    this.selectedProductLine = "0";
    this.selectedLeadSource = "0";
    this.selectedUser = "";
    this.uplineId = 0;
    this.notes = "";
    this.ResetValidate();
  }
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action setNote(value: string) {
    this.notes = value;
  }

  @action goToContracted = async () => {
    routes.listLeads.push();
  };
  @action goToNonContracted = async () => {
    routes.leads.push();
  };
  @action addNewAgentLead = async () => {
    routes.addAgentLead.push();
  };

  @action
  private loaded = () => {
    this.loading = false;
  };
  public Cancel = async () => {
    this.isSuccess = false;
    routes.leads.push();
  };
  Route = async (currentRoute: RouteList): Promise<void> => { };

  @action
  public Submit = async () => {
    if (!(await this.Validate())) {
      this.isException = false;
      this.isSuccess = false;
      this.isLoading = true;
      this.isMessgeVisible = false;
      this.response = {};
      if (this.isProductLineAlreadyReffered && parseInt(this.selectedLeadSource) === 2 ) { // referral
       var responseModel = {
          status: 500,
          title: Constants.LineAlreadyReferred,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
        this.isMessgeVisible = true;

      }
      else{
      try {
        await LeadStore.addAgentLead({
          subAssigneeId: this.selectedSubAssignee === "0" ? undefined : parseInt(this.selectedSubAssignee),
          productLineId: parseInt(this.selectedProductLine),
          createdUserId: 1,
          carrierId: parseInt(this.selectedCompany),
          assigneeId: this.selectedAssignee === "0" ? undefined : parseInt(this.selectedAssignee),
          agentId: this.uplineId,
          leadSourceId: parseInt(this.selectedLeadSource),
          leadNote: this.notes,
          productLineActivated: false,
          assignerId: undefined,
          dateCreated: new Date(),
          dateAssigned: new Date(),
          recruiterName: "",
        });

      
        this.isLoading = false;
        
          this.Cancel();
        
      } catch (error) {
        this.isLoading = false;
        this.isException = true;
        IMASLog.log("exception: " + error);
      }
    }
    }
  };

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @observable
  public InputModel = new InputModel();
  @observable
  public ErrorModel = new ErrorModel(new Validator());
  public onSave?: (CarrierBankDepositID: number) => void;
  private onClose?: () => void;
  @observable
  private loading = true;

  @observable isContractingSent: boolean = true;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable selectedUser: string = "";
  @observable uplineId: number = 0;
  @observable notes: string = "";
  @observable isWarningDisplayed: boolean = false;
  @observable isUplineWarning: boolean = false;
  @observable selectedMarketingEvent: string = "Select Marketing Event";
  @observable agentId: number | undefined;
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
  }
  @action setUplineId = async (value: number) => {
    this.uplineId = value;
    this.checkAgentProductLineAlreadyReffered();
  };
  @action fillterUsers = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          this.AgentsList.values = result.data;
          this.FilteredAgentsList.values = result.data;
        }
      }
    }
  };
}

export class InputModel {
  //#region Properities
  @observable
  public LeadSourceID: number | undefined;
  @observable
  public DepositDate: Date | undefined;
  @observable
  public CarrierID: number | undefined;
  @observable
  public DepositAmount: string = "";

  @observable
  public PaidDivisionID: number | undefined;
  @observable
  public CompanyID: number | undefined;
  //#endregion

  //#region State Management
  @action
  public SetLeadSourceID = (value: number) => {
    this.LeadSourceID = value;
  };

  @action
  public SetDepositDate = (value: Date | undefined) => {
    this.DepositDate = value;
  };

  @action
  public SetCarrierID = (value: number) => {
    this.CarrierID = value;
  };

  @action
  public SetDepositAmount = (value: string) => {
    this.DepositAmount = value;
  };

  //#endregion
}

class Validator extends AbstractValidator<AgentLeadAddViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.selectedLeadSource)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Lead Source is required");

    this.validateIfNumber((input) => input.uplineId)
      .isDefined()
      .isNotEqualTo(0)
      .withFailureMessage("Agent is required");

    this.validateIfString((input) => input.selectedProductLine)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Product Line is required");
  }
}
