
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import RouteList from "../../../infrastructure/RouteList";

import InsuredStore from "../../../store/InsuredStore";
import { action, observable } from "mobx";
import { RenewalHistoryDetailModel } from "../../../services/InsuredService";

export class RenewalHistoryViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (id: number) => {
    this.policyId = id;
    this.loadRenewalHistory();
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };

  @observable rows: number = 10;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable policyId: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 10;
  @observable renewalHistoryList = new Collection<RenewalHistoryDetailModel>();


  @observable totalPoints: number = 0;

  @observable sortTrips: boolean = true;
  @observable sortColumnTrips: string = "commissionID";

  @action setSortOrder() {
    this.sortTrips = !this.sortTrips;
  }
  @action setSortColumn(column: string) {
    this.sortColumnTrips = column;
  }

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.renewalHistoryList.values = [];
  }

  @action loadRenewalHistory = async () => {
    this.isLoading = true;
    try {
      var renewalHistory = await InsuredStore.getRenewalHistory(this.policyId);
      if (renewalHistory) {
        if (renewalHistory.records && renewalHistory.records.length > 0) {
          this.totalRecords = renewalHistory.records.length;
          this.totalPoints = renewalHistory.totalCommissionPaid ? renewalHistory.totalCommissionPaid : 0;
          if (renewalHistory !== null) {
            this.renewalHistoryList.values = renewalHistory.records;
          }
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
    this.isLoading = false;
  };
  @action onPage(firstIndex: number) {
    this.first = firstIndex;
  }
}
