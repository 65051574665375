import { observable, action } from "mobx";
import DashboardModel from "../infrastructure/DashboardModel";
import { Loadable } from "../infrastructure/UtilitiesHelper";
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import ViewModel from "../infrastructure/ViewModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../infrastructure/ErrorModel";
import { Constants } from "../infrastructure/enum/Constants";
import IdentityStore from "../store/IdentityStore";
import { MessageType, ProgressInfo } from "../services/MessagingService";
export class DashboardViewModel implements ViewModel {
  @observable initialDeposit: boolean = false;
  @observable message: string = '';
  @observable ErrorModel = new ErrorModel(new NotificationValidator());
  @action setInitialDeposit(value: boolean) {
    this.initialDeposit = value
  }
  Route = async (): Promise<void> => { }
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {

  };
  @action setMessage = (value: string) => {
    this.message = value;
  }
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action sendMessage = async () => {
    this.isMessgeVisible = false;
    this.response = {};

    await this.ResetValidate();
    var result = await this.Validate();
    if (result) return 0;

    try {
      var dto: ProgressInfo = {
        total: 0,
        progress:0,
        messageType: MessageType._2,
        message: this.message,
        adUserId:''
      };

    var res =  await IdentityStore.sendPushNotification(dto);
  
        var responseModel = {
          status: res === true ? 200 : 500,
          title: res === true ? Constants.NotificationSuccess : Constants.NotificationError,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
        this.isMessgeVisible = true;
        this.message="";

    } catch (e) {
      this.isMessgeVisible = false;
      this.response = e.response;
    }
  };
  @observable actionItems: MenuItem[] = [
    {
      label: 'Create Lead',
      command: () => {
      }
    },
    {
      label: 'Marketing Preferences',
      command: () => {

      }
    },
    {
      label: 'Disable Agent Profile',
      command: () => {
      }
    },
    {
      label: 'Copy Defalut Eamil',
      command: () => {
      }
    },

  ]
  @observable AgentsList = [{ id: '1234', type: 'trype', address: 'address', name: 'name' },
  { id: '1234', type: 'trype', address: 'address', name: 'name' },
  { id: '1234', type: 'trype', address: 'address', name: 'name' },
  { id: '1234', type: 'trype', address: 'address', name: 'name' }];
  @observable paymentPlansList = [
    { status: 'Active', prodId: 'MA', agentNo: '', commPlan: 'Direct Pay', contract: 'Pending', company: 'Aetna - MA' },
    { status: 'Hyistory', prodId: 'Mod MS Basic Plan', agentNo: '', commPlan: '20 Direct Pay', contract: 'Active', company: 'Blue Cross Blue Shield - Mod MS Basic Plan' },
    { status: 'Active', prodId: 'ASSURANCE PLUS', agentNo: '', commPlan: 'Direct Pay', contract: 'Active', company: 'Great Western- PPL - ASSURANCE PLUS' },
    { status: 'Active', prodId: 'Extra Packages', agentNo: '', commPlan: 'Fake', contract: 'Active', company: 'Blue Cross - Extra Packages' }
  ]
  @observable InputCounts = new Loadable<number>(0);
  @observable OutputCounts = new Loadable<number>(0);
  @observable FeedCounts = new Loadable<number>(0);

  @observable FilesReceivedToday = new Loadable<number>(0);
  @observable FilesDistributedToday = new Loadable<number>(0);
  @observable FilesReceivedLastSevenDays = new Loadable<number>(0);
  @observable FileDistributedLastSevenDays = new Loadable<number>(0);

  @observable Latest50Files = {};

  @observable OwnerFiles = {};

  @observable ReceivedFileByInputsByDay = {};

  @observable DistributedFileByOutputsByDay = {};  

  @action load = async () => {    
    this.InputCounts = await DashboardModel.getInputCount();
    this.OutputCounts = await DashboardModel.getOutputCount();
    this.FeedCounts = await DashboardModel.getFeedCount();
    this.FilesReceivedToday = await DashboardModel.getTodayInputCount();
    this.FilesDistributedToday = await DashboardModel.getTodayOutputCount();
    this.FilesReceivedLastSevenDays = await DashboardModel.getWeekInputCount();
    this.FileDistributedLastSevenDays = await DashboardModel.getWeekOuputCount();    
  };



  @action loadDataset = async () => {
    this.InputCounts = await DashboardModel.getInputCount(true);
    this.OutputCounts = await DashboardModel.getOutputCount(true);
    this.FeedCounts = await DashboardModel.getFeedCount(true);
    this.FilesReceivedToday = await DashboardModel.getTodayInputCount(true);
    this.FilesDistributedToday = await DashboardModel.getTodayOutputCount(true);
    this.FilesReceivedLastSevenDays = await DashboardModel.getWeekInputCount(true);
    this.FileDistributedLastSevenDays = await DashboardModel.getWeekOuputCount(true);
  };
}
class NotificationValidator extends AbstractValidator<DashboardViewModel>{
  public constructor() {
    super();

    this.validateIf(input => input.message)
      .isNotEmpty()
      .withFailureMessage("Message is required");
  }
}