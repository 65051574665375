import { AbstractValidator } from "fluent-ts-validator";
import { action, observable } from "mobx";
import { routes } from "../../../../router";
import { BaseAddEditViewModel } from "../../../components/BaseAddEditViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";
import AccountingStore from "../../../../store/AccountingStore";
import { CreateManageAgentDeptRequest } from "../../../../services/AccountingService";

export class ManageImportAgentDeptAddEditViewModel extends BaseAddEditViewModel {

    @observable excelColumnList = new Collection<any>();
    @observable companyList = new Collection<any>();
    @observable name:string = '';
    @observable companyId = 0;
    @observable writingNumberColumnIndex = 0;
    @observable advancedColumnIndex = 0;
    @observable unsecuredColumnIndex = 0;
    @observable reserveColumnIndex = 0;
    @observable sideColumnIndex = 0;
    @observable rollupColumnIndex = 0;
    @observable producerWeekColumnIndex = 0;
    @observable uplineWeekColumnIndex = 0;

    constructor() {
        super("Agent Debt Import", routes.listManageAgentDept, new ManageImportAgentDeptValidator());
        var self = this;
        setTimeout(function(){ 
            if (self.selectedId === 0) {
                self.loadItem(0);
            }
        });
    }

    protected async loadLookups() {        
        this.getExcelColumnList();
    }


    protected toServiceModel() {
        return {
            id: this.selectedId || 0,
            agentDebtImportName: this.name,
            companyId: this.companyId,
            writingNumberColumnIndex: this.writingNumberColumnIndex,
            advancedColumnIndex: this.advancedColumnIndex,
            unsecuredColumnIndex: this.unsecuredColumnIndex,
            reserveColumnIndex: this.reserveColumnIndex,
            sideColumnIndex: this.sideColumnIndex,
            rollupColumnIndex: this.rollupColumnIndex,
            producerWeekColumnIndex: this.producerWeekColumnIndex,
            uplineWeekColumnIndex: this.uplineWeekColumnIndex
        } as CreateManageAgentDeptRequest;
    }

    protected resetModel() {
        this.name = '';
        this.companyId = 0;
        this.writingNumberColumnIndex = 0;
        this.advancedColumnIndex = 0;
        this.unsecuredColumnIndex = 0;
        this.reserveColumnIndex = 0;
        this.sideColumnIndex = 0;
        this.rollupColumnIndex = 0;
        this.producerWeekColumnIndex = 0;
        this.uplineWeekColumnIndex = 0;
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await AccountingStore.getAgentDeptImportById(id);
        if (item) {
            this.selectedId = id || 0;
            this.name = item.name || '';
            this.companyId = item.companyId || 0;
            this.writingNumberColumnIndex = item.writingNumberColumnIndex || 0;
            this.advancedColumnIndex = item.advancedColumnIndex || 0;
            this.unsecuredColumnIndex = item.unsecuredColumnIndex || 0;
            this.reserveColumnIndex = item.reserveColumnIndex || 0;
            this.sideColumnIndex = item.sideColumnIndex || 0;
            this.rollupColumnIndex = item.rollupColumnIndex || 0;
            this.producerWeekColumnIndex = item.producerWeekColumnIndex || 0;
            this.uplineWeekColumnIndex = item.uplineWeekColumnIndex || 0;
            Utils.mapListItemsToDropdown(item.carriers || [], this.companyList, "Select", "0");
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        console.log(item);
        await AccountingStore.addAgentDeptImport(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        console.log(item);
        await AccountingStore.updateAgentDeptImport(item);
    }

    @action getExcelColumnList = () => {
        this.excelColumnList.values = [];
        this.excelColumnList.values.push({ label: 'Column A (0)', value: '0' });
        this.excelColumnList.values.push({ label: 'Column B (1)', value: '1' });
        this.excelColumnList.values.push({ label: 'Column C (2)', value: '2' });
        this.excelColumnList.values.push({ label: 'Column D (3)', value: '3' });
        this.excelColumnList.values.push({ label: 'Column E (4)', value: '4' });
        this.excelColumnList.values.push({ label: 'Column F (5)', value: '5' });
        this.excelColumnList.values.push({ label: 'Column G (6)', value: '6' });
        this.excelColumnList.values.push({ label: 'Column H (7)', value: '7' });
        this.excelColumnList.values.push({ label: 'Column I (8)', value: '8' });
        this.excelColumnList.values.push({ label: 'Column J (9)', value: '9' });
        this.excelColumnList.values.push({ label: 'Column K (10)', value: '10' });
        this.excelColumnList.values.push({ label: 'Column L (11)', value: '11' });
        this.excelColumnList.values.push({ label: 'Column M (12)', value: '12' });
        this.excelColumnList.values.push({ label: 'Column N (13)', value: '13' });
        this.excelColumnList.values.push({ label: 'Column O (14)', value: '14' });
        this.excelColumnList.values.push({ label: 'Column P (15)', value: '15' });
        this.excelColumnList.values.push({ label: 'Column Q (16)', value: '16' });
        this.excelColumnList.values.push({ label: 'Column R (17)', value: '17' });
        this.excelColumnList.values.push({ label: 'Column S (18)', value: '18' });
        this.excelColumnList.values.push({ label: 'Column T (19)', value: '19' });
        this.excelColumnList.values.push({ label: 'Column U (20)', value: '20' });
        this.excelColumnList.values.push({ label: 'Column V (21)', value: '21' });
        this.excelColumnList.values.push({ label: 'Column W (22)', value: '22' });
        this.excelColumnList.values.push({ label: 'Column X (23)', value: '23' });
        this.excelColumnList.values.push({ label: 'Column Y (24)', value: '24' });
        this.excelColumnList.values.push({ label: 'Column Z (25)', value: '25' });
        this.excelColumnList.values.push({ label: 'Column AA (26)', value: '26' });
        this.excelColumnList.values.push({ label: 'Column AB (27)', value: '27' });
        this.excelColumnList.values.push({ label: 'Column AC (28)', value: '28' });
        this.excelColumnList.values.push({ label: 'Column AD (29)', value: '29' });
        this.excelColumnList.values.push({ label: 'Column AE (30)', value: '30' });
        this.excelColumnList.values.push({ label: 'Column AF (31)', value: '31' });
        this.excelColumnList.values.push({ label: 'Column AG (32)', value: '32' });
        this.excelColumnList.values.push({ label: 'Column AH (33)', value: '33' });
        this.excelColumnList.values.push({ label: 'Column AI (34)', value: '34' });
        this.excelColumnList.values.push({ label: 'Column AJ (35)', value: '35' });
        this.excelColumnList.values.push({ label: 'Column AK (36)', value: '36' });
        this.excelColumnList.values.push({ label: 'Column AL (37)', value: '37' });
        this.excelColumnList.values.push({ label: 'Column AM (38)', value: '38' });
        this.excelColumnList.values.push({ label: 'Column AN (39)', value: '39' });
        this.excelColumnList.values.push({ label: 'Column AO (40)', value: '40' });
        this.excelColumnList.values.push({ label: 'Column AP (41)', value: '41' });
        this.excelColumnList.values.push({ label: 'Column AQ (42)', value: '42' });
        this.excelColumnList.values.push({ label: 'Column AR (43)', value: '43' });
        this.excelColumnList.values.push({ label: 'Column AS (44)', value: '44' });
        this.excelColumnList.values.push({ label: 'Column AT (45)', value: '45' });
        this.excelColumnList.values.push({ label: 'Column AU (46)', value: '46' });
        this.excelColumnList.values.push({ label: 'Column AV (47)', value: '47' });
        this.excelColumnList.values.push({ label: 'Column AW (48)', value: '48' });
        this.excelColumnList.values.push({ label: 'Column AX (49)', value: '49' });
        this.excelColumnList.values.push({ label: 'Column AY (50)', value: '50' });
        this.excelColumnList.values.push({ label: 'Column AZ (51)', value: '51' });
        this.excelColumnList.values.push({ label: 'Column BA (52)', value: '52' });
        this.excelColumnList.values.push({ label: 'Column BB (53)', value: '53' });
        this.excelColumnList.values.push({ label: 'Column BC (54)', value: '54' });
        this.excelColumnList.values.push({ label: 'Column BD (55)', value: '55' });
        this.excelColumnList.values.push({ label: 'Column BE (56)', value: '56' });
        this.excelColumnList.values.push({ label: 'Column BF (57)', value: '57' });
        this.excelColumnList.values.push({ label: 'Column BG (58)', value: '58' });
        this.excelColumnList.values.push({ label: 'Column BH (59)', value: '59' });
        this.excelColumnList.values.push({ label: 'Column BI (60)', value: '60' });
        this.excelColumnList.values.push({ label: 'Column BJ (61)', value: '61' });
        this.excelColumnList.values.push({ label: 'Column BK (62)', value: '62' });
        this.excelColumnList.values.push({ label: 'Column BL (63)', value: '63' });
        this.excelColumnList.values.push({ label: 'Column BM (64)', value: '64' });
        this.excelColumnList.values.push({ label: 'Column BN (65)', value: '65' });
        this.excelColumnList.values.push({ label: 'Column BO (66)', value: '66' });
        this.excelColumnList.values.push({ label: 'Column BP (67)', value: '67' });
        this.excelColumnList.values.push({ label: 'Column BQ (68)', value: '68' });
        this.excelColumnList.values.push({ label: 'Column BR (69)', value: '69' });
        this.excelColumnList.values.push({ label: 'Column BS (70)', value: '70' });
        this.excelColumnList.values.push({ label: 'Column BT (71)', value: '71' });
        this.excelColumnList.values.push({ label: 'Column BU (72)', value: '72' });
        this.excelColumnList.values.push({ label: 'Column BV (73)', value: '73' });
        this.excelColumnList.values.push({ label: 'Column BW (74)', value: '74' });
        this.excelColumnList.values.push({ label: 'Column BX (75)', value: '75' });
        this.excelColumnList.values.push({ label: 'Column BY (76)', value: '76' });
        this.excelColumnList.values.push({ label: 'Column BZ (77)', value: '77' });
        this.excelColumnList.values.push({ label: 'Column CA (78)', value: '78' });
        this.excelColumnList.values.push({ label: 'Column CB (79)', value: '79' });
        this.excelColumnList.values.push({ label: 'Column CC (80)', value: '80' });
        this.excelColumnList.values.push({ label: 'Column CD (81)', value: '81' });
        this.excelColumnList.values.push({ label: 'Column CE (82)', value: '82' });
        this.excelColumnList.values.push({ label: 'Column CF (83)', value: '83' });
        this.excelColumnList.values.push({ label: 'Column CG (84)', value: '84' });
        this.excelColumnList.values.push({ label: 'Column CH (85)', value: '85' });
        this.excelColumnList.values.push({ label: 'Column CI (86)', value: '86' });
        this.excelColumnList.values.push({ label: 'Column CJ (87)', value: '87' });
        this.excelColumnList.values.push({ label: 'Column CK (88)', value: '88' });
        this.excelColumnList.values.push({ label: 'Column CL (89)', value: '89' });
        this.excelColumnList.values.push({ label: 'Column CM (90)', value: '90' });
        this.excelColumnList.values.push({ label: 'Column CN (91)', value: '91' });
        this.excelColumnList.values.push({ label: 'Column CO (92)', value: '92' });
        this.excelColumnList.values.push({ label: 'Column CP (93)', value: '93' });
        this.excelColumnList.values.push({ label: 'Column CQ (94)', value: '94' });
        this.excelColumnList.values.push({ label: 'Column CR (95)', value: '95' });
        this.excelColumnList.values.push({ label: 'Column CS (96)', value: '96' });
        this.excelColumnList.values.push({ label: 'Column CT (97)', value: '97' });
        this.excelColumnList.values.push({ label: 'Column CU (98)', value: '98' });
        this.excelColumnList.values.push({ label: 'Column CV (99)', value: '99' });
        this.excelColumnList.values.push({ label: 'Column CW (100)', value: '100' });
        this.excelColumnList.values.push({ label: 'Column CX (101)', value: '101' });
        this.excelColumnList.values.push({ label: 'Column CY (102)', value: '102' });
        this.excelColumnList.values.push({ label: 'Column CZ (103)', value: '103' });
        this.excelColumnList.values.push({ label: 'Column DA (104)', value: '104' });
        this.excelColumnList.values.push({ label: 'Column DB (105)', value: '105' });
        this.excelColumnList.values.push({ label: 'Column DC (106)', value: '106' });
        this.excelColumnList.values.push({ label: 'Column DD (107)', value: '107' });
        this.excelColumnList.values.push({ label: 'Column DE (108)', value: '108' });
        this.excelColumnList.values.push({ label: 'Column DF (109)', value: '109' });
        this.excelColumnList.values.push({ label: 'Column DG (110)', value: '110' });
    }
}

class ManageImportAgentDeptValidator extends AbstractValidator<ManageImportAgentDeptAddEditViewModel> {
    public constructor() {
        super();
          this.validateIfString(input => input.name)
            .isNotEmpty()
            .withFailureMessage("Import Name is required");

          this.validateIfNumber(input => input.companyId)
            .isNotEmpty()
            .isNotEqualTo(0)
            .withFailureMessage("Carrier is required");
    }
}