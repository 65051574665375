import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import IMASLog from "../infrastructure/IMASLog";
import {
  AddDocumentNoteModel,
  CreateUpdateWorkflowDocumentModel,
  AddSendToCommentsModel,
  DuplicateWorkflowDocumentModel,
  FileParameter,
  LocationType,
  DeleteWorkflowDocumentModel,
  Client as WorkFlowServiceClient
} from "../services/WorkflowService";
import Utils from "../infrastructure/Utils";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import UserContext from "../infrastructure/UserContext";
import { WorkflowStatusType } from "../infrastructure/enum/WorkflowDetails";

class WorkflowStore {
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };

  getClient = (): WorkFlowServiceClient => {
    return ServiceClient.getClient(ServiceType.Workflow);
  }

  @action getAgentsWorkflow = async (
    agentId: number,
    search: string | undefined,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    try {
      var result = await this.getClient().getWorkflowDocumentsByAgentId(
        agentId,
        this.getApiVersion(),
        startIndex,
        pageSize,
        sortColumn,
        sortAscending,
        search,
        UserContext.getUserId()
      );
      return result;
    } catch (e) {
      IMASLog.log("exception: " + e.message);
    }
  };

  @action getAgentWorkflowDocumentCount = async (
    agentId: number
  ) => {
    try {
      var result = await this.getClient().getWorkflowDocumentCountByAgentId(
        agentId,
        this.getApiVersion(),
        UserContext.getUserId()
      );
      return result;
    } catch (e) {
      IMASLog.log("exception: " + e.message);
    }
  };

  @action getAllTypeLookup = async (

  ) => {
    var result = await this.getClient().getAllTypeLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };



  @action getDocWorkFlowHistory = async (documentId: number) => {
    var result = await this.getClient().getAllWorkflowNotes(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action downloadWorkflowDocument = async (documentId: number) => {
    var result = await this.getClient().downloadWorkflowDocument(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action addWorkflowDocumentNotes = async (
    documentId: number,
    addWorkflowDocumentNotesModel: AddDocumentNoteModel
  ) => {
    var result = await this.getClient().createWorkflowDocumentNotes(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      addWorkflowDocumentNotesModel
    );
    return result;
  };

  @action deleteWorkflowDocument = async (docId: number, deleteWorkflowDocumentModel: DeleteWorkflowDocumentModel) => {
    let result = await this.getClient().deleteWorkflowDocument(
      docId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      deleteWorkflowDocumentModel
    );
    return result;
  };
  @action getWorkflowDocs = async (
    searchRequest: any,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().getAllWorkflowDocuments(
      this.getApiVersion(),
      searchRequest.FileTypeId,
      searchRequest.LastName,
      searchRequest.AgentId,
      searchRequest.InsuredId,
      searchRequest.CompanyId,
      searchRequest.WorkFlowType,
      searchRequest.InsuredName,
      searchRequest.WorkflowStatusId,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getWorkflowDocsByQueue = async (
    queueRequest: any,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var result = await this.getClient().searchWorkflowDocumentByQueue(
      this.getApiVersion(),
      queueRequest.CompanyId,
      queueRequest.ProductId,
      //queueRequest.DivisionId,
      queueRequest.FileTypeId,
      queueRequest.AssignedTo,
      queueRequest.AssignedToUserTeam,
      queueRequest.WorkFlowStatus,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      queueRequest.Search,
      UserContext.getUserId(),
    );
    return result;
  };

  @action updateWorkflowDocumentStatus = async (documentId: number) => {
    var result = await this.getClient().updateWorkflowStatusToRevive(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllLookup = async () => {
    var result = await this.getClient().getAllWorkflowLookUps(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action uploadFileTEST = async (fileData: any) => {
    var result = await this.getClient().uploadWorkflowDocument(
      this.getApiVersion(),
      UserContext.getUserId(),
      fileData
    );
    return result;
  };

  @action createUpdateWorkflowDocument = async (
    createUpdateWorkflowDocumentModel: CreateUpdateWorkflowDocumentModel
  ) => {
    var result = await this.getClient().createWorkflowDocument(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      createUpdateWorkflowDocumentModel
    );
    return result;
  };
    @action replaceReceivedandUpdateDocument = async (
        createUpdateWorkflowDocumentModel: CreateUpdateWorkflowDocumentModel,
        id?: number | undefined, fName?: string | null | undefined, lName?: string | null | undefined,
        agentId?: number | null | undefined, typeId?: number | undefined, companyId?: number | null | undefined,
        marketerAgentId?: number | null | undefined, uplineAgentId?: number | null | undefined,
        assignedToUserId?: number | null | undefined, assignedToTeamId?: number | null | undefined,
        productId?: number | null | undefined, notes?: string | null | undefined,
        appDate?: Date | null | undefined, flag?: string | null | undefined,
        fileName?: string | null | undefined, file?: FileParameter | null | undefined,
        existingFileName?: string | null | undefined
    ) => {
        var result = await this.getClient().replaceReceivedandUpdateDocument(
            this.getApiVersion(),
            id , fName , lName,
            agentId , typeId , companyId ,
            marketerAgentId , uplineAgentId ,
            assignedToUserId , assignedToTeamId,
            productId , notes,
            appDate, flag,          
            fileName, 
            UserContext.getUserId(),
            undefined, file,
            existingFileName );
        return result;
    };
    @action downloadWorkflowDocs = async (
        downloadWorkflowDocumentSearchModel: any
    ) => {
      
      var result = await this.getClient().downloadWorkflowDocs(
        this.getApiVersion(),
        downloadWorkflowDocumentSearchModel.fileTypeId,         
        downloadWorkflowDocumentSearchModel.lastName,         
        downloadWorkflowDocumentSearchModel.agentId,         
        downloadWorkflowDocumentSearchModel.insuredId,         
        downloadWorkflowDocumentSearchModel.companyId,         
        downloadWorkflowDocumentSearchModel.workFlowType,         
        downloadWorkflowDocumentSearchModel.insuredName,         
        downloadWorkflowDocumentSearchModel.workflowStatusId,         
        downloadWorkflowDocumentSearchModel.fileType,
        UserContext.getUserId(), undefined)
         return result;
            };  
  @action downloadFile = async (fileName: string) => {
    var result = await this.getClient().downloadFileInReceivedQueue(
      this.getApiVersion(),
      fileName,
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteFile = async (fileName: string) => {

    var result = await this.getClient().deleteFileInReceivedQueue(
      this.getApiVersion(),
      fileName,
      UserContext.getUserId()
    );

    return result;

  };

  @action updateFile = async (fileName: string, isSteal: boolean) => {
    var result = await this.getClient().updateFileInReceivedQueue(
      this.getApiVersion(),
      fileName,
      isSteal,
      UserContext.getUserId()
    );
    return result;
  };

  @action cancelFile = async (fileName: string) => {
    var result = await this.getClient().cancelFileInReceivedQueue(
      this.getApiVersion(),
      fileName,
      UserContext.getUserId()
    );
    return result;
  };

  @action stealDocument = async (documentId: number) => {

    var result = await this.getClient().stealDocument(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;

  };

  @action assign = async (documentId: number) => {
    var result = await this.getClient().assignDocument(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action unAssign = async (documentId: number) => {
    var result = await this.getClient().unAssignDocument(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getWorkflowDocInfo = async (documentId: number) => {
    var result = await this.getClient().getWorkflowDocumentById(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
  ): any {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
  }

  @action sendTo = async (
    documentId: number,
    addSendToCommentsModel: AddSendToCommentsModel
  ) => {

    var result = await this.getClient().sendToComments(
      documentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      addSendToCommentsModel
    );
    
    return result;

  };



  @action dataEntry = async (documentId: number, workflowStatusId: number) => {
    var result = await this.getClient().documentDataEntry(documentId,
      this.getApiVersion(),
      workflowStatusId,
      UserContext.getUserId()
    );
    return result;
  }

  @action assignment = async (documentId: number) => {
    var result = await this.getClient().documentSendToAssignment(documentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  }

  @action duplicate = async (documentId: number, duplicateWorkflowDocumentModel: DuplicateWorkflowDocumentModel) => {
    var result = await this.getClient().duplicateWorkflowDocument(documentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      duplicateWorkflowDocumentModel);
    return result;
  }

  @action pendingCertifications = async (documentId: number) => {
    var result = await this.getClient().pendingCertifications(documentId,
      this.getApiVersion(),
      UserContext.getUserId());
    return result;
  }

  @action receipt = async (documentId: number) => {
    var result = await this.getClient().documentReceipt(documentId,
      this.getApiVersion(),
      UserContext.getUserId());
    return result;
  }

  @action changeForm = async (documentId: number, workflowStatusId: number, isChangeForm: boolean) => {
    var result = await this.getClient().changeForm(documentId,
      this.getApiVersion(),
      workflowStatusId,
      isChangeForm,
      UserContext.getUserId());
    return result;
  }

  @action agentContactEmail = async (documentId: number) => {
    let result = await this.getClient().getAgentContactEmails(documentId,
      this.getApiVersion(),
      UserContext.getUserId())
    return result
  }

  @action postFormData(
    v: string,
    file: any,
    fileName: string,
    impersonated_User?: any | undefined,
    authorization?: any | undefined,
    accept_Timezone?: any | undefined
  ) {
    const formData = new FormData(); // Currently empty

    if (file != null) {
      formData.append("file", file, fileName ? fileName : "file.pdf");
    }

    const headersParametrs = {
      authorization:
        authorization !== undefined && authorization !== null
          ? "" + authorization
          : "",
      Accept_Timezone:
        accept_Timezone !== undefined && accept_Timezone !== null
          ? "" + accept_Timezone
          : "",
      Impersonated_User:
        impersonated_User !== undefined && impersonated_User !== null
          ? "" + impersonated_User
          : "",
      Accept: "application/json",
    };
    let baseUrl: string = "";
    baseUrl = Utils.getServiceUrlByEnvironment();
    let url_ = baseUrl + "/api/workflow/v1/documents/upload";

    let options_ = {
      body: formData,
      method: "POST",
      headers: headersParametrs,
    } as RequestInit;

    return fetch(url_, options_).then((_response: Response) => {
      return this.getClient().processUploadWorkflowDocument(_response);
    });
  }

  @action replaceWorkflowDocument(
    file: any,
    fileName: string,
    existingfileName: string
  ) {

    var result = this.getClient().replaceReceivedDocument(
      this.getApiVersion(), 
      UserContext.getUserId(), 
      null, 
      { 
        data: file, 
        fileName: fileName ? fileName : "file.pdf" 
      }, 
      existingfileName
    );
    
    return result;

  }



  @action postAttachData(
    v: string,
    file: any,
    fileName: string,
    documentId: number,
    location: LocationType,
    notes: string | undefined,
    authorization?: any | undefined,
    accept_Timezone?: any | undefined
  ) {
    const headersParametrs = {
      authorization:
        authorization !== undefined && authorization !== null
          ? "" + authorization
          : "",
      Accept_Timezone:
        accept_Timezone !== undefined && accept_Timezone !== null
          ? "" + accept_Timezone
          : "",
      Impersonated_User: UserContext.getUserId() ? UserContext.getUserId() : "", 
      Accept: "application/json",
    };

    let baseUrl: string = "";
    baseUrl = Utils.getServiceUrlByEnvironment();
    let url_ = baseUrl + "/api/workflow/v{v}/documents/{documentId}/attach";
    if (documentId === undefined || documentId === null)
      throw new Error("The parameter 'documentId' must be defined.");
    url_ = url_.replace("{documentId}", encodeURIComponent("" + documentId));
    if (v === undefined || v === null)
      throw new Error("The parameter 'v' must be defined.");
    url_ = url_.replace("{v}", encodeURIComponent("" + v));
    url_ = url_.replace(/[?&]$/, "");
    const formData = new FormData();
    if (file !== null && file !== undefined) {
      formData.append("File", file, fileName ? fileName : "file.pdf");
    }
    if (location === null || location === undefined)
      throw new Error("The parameter 'location' cannot be null.");
    else formData.append("Location", location.toString());
    if (notes !== null && notes !== undefined)
      formData.append("Notes", notes.toString());
    let options_ = {
      body: formData,
      method: "POST",
      headers: headersParametrs,
    } as RequestInit;

    return fetch(url_, options_).then((_response: Response) => {
      // return this.processAttach(_response);
      return this.getClient().processAttachWorkflowDocument(_response);
    });
  }

  @action postReplaceData(
    v: string,
    file: any,
    fileName: string,
    documentId: number,
    location: LocationType,
    notes: string | undefined,
    authorization?: any | undefined,
    accept_Timezone?: any | undefined
  ) {
    const headersParametrs = {
      authorization:
        authorization !== undefined && authorization !== null
          ? "" + authorization
          : "",
      Accept_Timezone:
        accept_Timezone !== undefined && accept_Timezone !== null
          ? "" + accept_Timezone
          : "",
      Impersonated_User: UserContext.getUserId() ? UserContext.getUserId() : "",
      Accept: "application/json",
    };

    let baseUrl: string = "";
    baseUrl = Utils.getServiceUrlByEnvironment();
    let url_ =
      baseUrl + "/api/workflow/v{v}/documents/{documentId}/replace";
    if (documentId === undefined || documentId === null)
      throw new Error("The parameter 'documentId' must be defined.");
    url_ = url_.replace("{documentId}", encodeURIComponent("" + documentId));
    if (v === undefined || v === null)
      throw new Error("The parameter 'v' must be defined.");
    url_ = url_.replace("{v}", encodeURIComponent("" + v));
    url_ = url_.replace(/[?&]$/, "");
    const formData = new FormData();
    if (file !== null && file !== undefined) {
      formData.append("File", file, fileName ? fileName : "file.pdf");
    }
    if (location === null || location === undefined)
      throw new Error("The parameter 'location' cannot be null.");
    else formData.append("Location", location.toString());
    if (notes !== null && notes !== undefined)
      formData.append("Notes", notes.toString());
    let options_ = {
      body: formData,
      method: "POST",
      headers: headersParametrs,
    } as RequestInit;

    return fetch(url_, options_).then((_response: Response) => {
      // return this.processAttach(_response);
      return this.getClient().processReplaceWorkflowDocument(_response);
      //  return this.getClient().replaceWorkflowDocument(_response);
    });
  }

  @action processAttach(response: Response): Promise<number> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : Number(JSON.parse(_responseText, this.jsonParseReviver));
        return result200;
      });
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        let result404: any = null;
        result404 =
          _responseText === ""
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
        return this.throwException(
          "Not Found",
          status,
          _responseText,
          _headers,
          result404
        );
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        let result401: any = null;
        result401 =
          _responseText === ""
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
        return this.throwException(
          "Unauthorized",
          status,
          _responseText,
          _headers,
          result401
        );
      });
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return this.throwException(
          "Server Error",
          status,
          _responseText,
          _headers
        );
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return this.throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<number>(null as any);
  }

  @action documentCount = async () => {
    let result = await this.getClient().getWorkflowDocumentCount(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action licenseCommunication = async (docId: number) => {
    let result = await this.getClient().getLicenseCommunications(
      docId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action attachDocTemp = async (
    docId: number,
    file: FileParameter,
    location: LocationType,
    notes: string
  ) => {
    let result = await this.getClient().attachWorkflowDocument(
      docId,
      this.getApiVersion(),
      UserContext.getUserId(),
      file,
      location,
      notes
    );
    return result;
  };
}
export default new WorkflowStore();

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
}
