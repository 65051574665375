export enum AgentDetails {
  Summary = "Summary",
  Addresses = "Addresses",
  ContactOptions = "Contact Options",
  CreditChecks = "Credit Checks",
  Agencies = "Agencies",
  Payees = "Payees",
  PrinicipaAgents = "Principal Agents",
  W9 = "W9 Forms",
}

export enum CustomerDetails {
  Summary = "Summary",
  ContactOptions = "Contact Options",
}

export enum AgentSummary {
  Agent = "Agent Info",
  ContactOptions = "Contact Options",
  Addresses = "Addresses",
  QuickNotes = "Quick Notes",
  ProductLines = "Product Lines",
  PrincipalAgents = "Principal Agents",
  Email = "Email",
  SMSMessages = "SMS Messages",
  Messaging = "Messsaging",
  ReportingHierarchy = "Reporting Hierarchy",
  MedicareCenter = "MedicareCENTER",
}

export enum AccountSubTabs {
  credit = "credit",
  debt = "debt",
}
export enum ContractSubTabs {
  Contracts = "Contracts",
  PaymentPlans = "PaymentPlans",
}

export enum AgentTabViewModelTypes {
  Summary = "Summary",
  Notes = "Notes",
  //Email = "Email",
  Messaging = "Messaging",
  AddNote = "AddNote",
  EditAgentNote = "EditAgentNote",
  Policies = "Policies",
  ViewPolicy = "ViewPolicy",
  AddEditPolicy = "AddEditPolicy",
  StateLicenses = "StateLicenses",
  AddStateLicense = "AddStateLicense",
  EditStateLicense = "EditStateLicense",
  AddEditPaymentPlan = "AddEditPaymentPlan",
  Contracts = "Contracts",
  //added
  Production = "Production",
  // ContractSummary = "ContractSummary",
  ContractDetails = "ContractDetails",
  AddContract = "AddContract",
  EditContract = "EditContract",
  ReplaceContract = "ReplaceContract",
  Tasks = "Tasks",
  AddTask = "AddTask",
  EditTask = "EditTask",
  Documents = "Documents",
  AddDoc = "AddDoc",
  EditDoc = "EditDoc",
  TripPoints = "TripPoints",
  Orders = "Orders",
  OrderDetails = "OrderDetails",
  AddSupplyOrder = "AddSupplyOrder",
  EditSupplyOrder = "EditSupplyOrder",
  AddSupplyItem = "AddSupplyItem",
  Workflow = "Workflow",
  Account = "Account",
  Debt = "Debt",
  Profile = "Profile",
  AddAgentDebt = "AddAgentDebt",
  EditAgentDebt = "EditAgentDebt",
  AddEditProductDetails = "AddEditProductDetails",
  Appointments = "Appointments",
  AutoAppointments = "AutoAppointments",
  ManageRTS = "ManageRTS",
}

export enum AgentTabs {
  Summary = "summary",
  Notes = "notes",
  //Email = "email",
  Policies = "policies",
  Production = "production",
  PaymentPlans = "paymentplans",
  StateLicenses = "statelicenses",
  Contracts = "contracts",
  Appointments = "appointments",
  Orders = "orders",
  Tasks = "tasks",
  Documents = "Documents",
  TripPoints = "points",
  Profile = "profile",
  Account = "account",
  Debt = "debt",
  Workflow = "workflow",
  Messaging = "messaging",
  ManageRTS = "manageRTS",
}
export enum AccountDetails {
  CreditCardChargeReq = "Credit Card Charge Request",
  DebtHistory = "Debt History",
}
export enum AgentContractDetails {
  Contracts = "Contracts",
  PaymentPlans = "Payment Plans",
}
export enum AgentDocumentOptionValues {
    SubmitTo = 1,
    DontSendJustAttach = 2,
    EnterIntoWorkflowScrub = 3
}
export enum AgentDocumentOptions {
    SubmitTo = "Submit To",
    DontSendJustAttach = "Don't Send, Just attach",
    EnterIntoWorkflowScrub = "Enter Into Workflow (Scrub)"
}
export enum AgentApptCertDetails {
    RTS = "RTS",
    AutoRTS = "Auto RTS",
}
