import { AbstractValidator } from "fluent-ts-validator";
import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import Collection from "../../infrastructure/CollectionHelper";
import { routes } from "../../router";
import { AgentListModel } from "../../services/ProducerSearchService";
import LeadStore from "../../store/LeadStore";
import ProducerStore from "../../store/ProducerStore";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import Utils from "../../infrastructure/Utils";
import UserContext from "../../infrastructure/UserContext";
import { Permission } from "../../infrastructure/enum/Permission";
import IMASLog from "../../infrastructure/IMASLog";
type NewType = undefined;
export class SaleOpsAgentLeadEditViewModel implements ViewModel {
  @observable selecteDate: Date = new Date();
  @observable selectedTime: string = "";
  @observable leadEmail: string = "";
  @observable leadNPN: string = "";
  @observable leadPhone: string = "";
  @observable companyList = [{ label: "Select Company", value: "0" }];
  @observable leadSourceList = [{ label: "Select Lead source", value: "0" }];
  @observable assigneeList = [{ label: "Select Assignee", value: "0" }];
  @observable subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
  @observable productLineList = [{ label: "select Line", value: "0" }];
  @observable tempSubAssigneeList = [
    { label: "Select Sub-Assignee", value: "0" },
  ];
  @observable leadPhaseDetailList = [
    { label: "Select Lead Phase Detail", value: "0" },
  ];
  @observable leadNPNList = [{ label: "Select Lead NPN", value: "0" }];
  @observable leadEmailList = [{ label: "Select Lead Email", value: "0" }];
  @action setLeadEmail = async (value: string) => {
    this.selectedLeadEmail = value;
  };
  @action setLeadPhone = async (value: string) => {
    this.leadPhone = value;
  };
  @action setLeadNPN = async (value: string) => {
    var npnValue = "";
    npnValue = value.replace(/_/g, "").trim();
    this.selectedLeadNPN = npnValue;
  };
  @observable leadNameList = [{ label: "Select Lead Name", value: "0" }];
  @observable leadPhaseList = [{ label: "Select Lead Phase", value: "0" }];
  @observable phaseList = [{ label: "ALL", value: "0" }];

  @observable isLoading: boolean = true;
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable isWarningDisplayed: boolean = false;
  @observable isUplineWarning: boolean = false;
  @observable isContractingSent: boolean = true;
  @observable selectedUser: string = "";
  @observable uplineId: number = 0;
  @observable assignerId: number = 0;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable CarrierBankDepositID: number = 0;
  @observable selectedCompany: string = "";
  @observable selectedLeadSource: string = "";
  @observable selectedAssignee: string = "";
  @observable notes: string = "";
  @observable invalidForActivation: boolean = false;
  @observable invalidReferral: boolean = false;
  @observable selectedSubAssignee: string = "";
  @observable selectedProductLine: string = "0";
  @observable selectedLeadPhaseDetail: string = "";
  @observable selectedLeadNPN: string = "";
  @observable selectedLeadEmail: string = "";
  @observable selectedDateFollowUp?: Date | NewType = new Date();
  @observable selectedDateAssign?: Date | NewType = new Date();
  @observable selectedLeadPhase: string = "";
  @observable selectedLeadName: string = "";
  @observable AgentLeadId: number = 0;
  @observable agentId: number | undefined;
  @observable selectedBankAccount: string = "";
  @observable isPhoneValid: boolean = false;
  @observable validPhoneError: string = "";
  @observable isNPNValid: boolean = true;
  @observable validNPNError: string = "";
  @observable hasPermission: boolean = true;
  @observable isPreContracted: boolean = false;

  @action loadLeadPhaseDetail = async () => {
    var leadPhaseDetailResponse = await LeadStore.getLeadPhaseDetailList(
      parseInt(this.selectedLeadPhase)
    );
    if (this.leadPhaseDetailList.length > 1) {
      this.leadPhaseDetailList = this.leadPhaseDetailList.slice(0, 1);
    }
    for (let item of leadPhaseDetailResponse) {
      this.leadPhaseDetailList.push({
        label: "" + item.text,
        value: "" + item.value,
      });
    }
  };

  @action phaseChange = async () => {
    await this.Validate();
    await this.loadLeadPhaseDetail();
    this.selectedLeadPhaseDetail = this.leadPhaseDetailList[1].value;
  };
  @action resetFilters = () => {
    this.isLoading = true;
    this.isException = false;
    this.isSuccess = false;
    this.isPhoneValid = false;
    this.validPhoneError = "";
    this.isNPNValid = true;
    this.validNPNError = "";
    this.hasPermission = true;
    this.isWarningDisplayed = false;
    this.isUplineWarning = false;
    this.selectedUser = "";
    this.selectedAssignee = "";
    this.selectedSubAssignee = "";
    this.selectedProductLine = "0";
    this.selectedCompany = "";
    this.selectedLeadSource = "";
    this.selectedLeadName = "";
    this.selectedLeadPhase = "";
    this.selectedLeadPhaseDetail = "";
    this.selectedLeadNPN = "";
    this.selectedLeadEmail = "";
    this.leadPhone = "";
    this.selectedDateFollowUp = undefined;
    this.selectedDateAssign = undefined;
    this.invalidReferral = false;
    this.invalidForActivation = false;
    this.notes = "";
    this.assignerId = 0;
    this.uplineId = 0;
    this.AgentLeadId = 0;
    this.agentId = 0;
  };

  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return !this.loading;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }

  Close = (): void => {
    routes.salesLeads.push();
  };

  @action setIsPhoneValid(value: boolean) {
    this.isPhoneValid = value;
  }
  @action setValidPhoneError(value: string) {
    this.validPhoneError = value;
  }
  @action setIsNPNValid(value: boolean) {
    this.isNPNValid = value;
  }
  @action setValidNPNError(value: string) {
    this.validNPNError = value;
  }
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setisUplineWarning(value: boolean) {
    this.isUplineWarning = value;
  }

  @action setisWarningDisplayed(value: boolean) {
    this.isWarningDisplayed = value;
  }

  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action setUplineId = async (value: number) => {
    this.uplineId = value;
  };
  @action setInvalidForActivation(value: boolean) {
    this.invalidForActivation = value;
  }
  @action setInvalidReferral(value: boolean) {
    this.invalidReferral = value;
  }
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action fillterUsers = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          this.AgentsList.values = result.data;
          this.FilteredAgentsList.values = result.data;
        }
      }
    }
  };
  sortTypeName = (a: any, b: any) => {
    let comparison = 0;
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }

    return comparison;
  };
  @action loadLeadPhaseDropdown = async (leadPhaseId: number) => {
    var leadPhaseResponse = await LeadStore.getLeadPhaseListByLeadPhaseId(
      leadPhaseId
    );
    this.leadPhaseList = [{ label: "Select Lead Phase", value: "0" }];
    for (let item of leadPhaseResponse) {
      this.leadPhaseList.push({
        label: "" + item.text,
        value: "" + item.value,
      });
    }
  };
  @action loadDropdowns = async () => {
    this.loading = true;
    var response = await LeadStore.getDropdownValues(true, false);

    if (response && response.carriers) {
      var carrierslist = [...response.carriers].sort(this.sortTypeName);
      for (let item of carrierslist) {
        this.companyList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
        this.selectedCompany = "0";
      }
    }

    if (response && response.assignee && this.assigneeList.length === 1) {
      var assigneelist = [...response.assignee].sort(this.sortTypeName);
      for (let item of assigneelist) {
        this.assigneeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }
    if (response && response.subAssignee) {
      this.subAssigneeList = [];
      this.tempSubAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
      var subassigneelist = [...response.subAssignee].sort(this.sortTypeName);

      for (let item of subassigneelist) {
        this.tempSubAssigneeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
      this.subAssigneeList = this.tempSubAssigneeList;
    }
    if (response && response.productLine) {
      for (let item of response.productLine) {
        this.productLineList.push({
          label: "" + item.productLineName,
          value: "" + item.id,
        });
      }
    }
    if (response && response.leadSource) {
      var leadsourcelist = [...response.leadSource].sort(this.sortTypeName);
      for (let item of leadsourcelist) {
        this.leadSourceList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }
    this.loading = false;
  };
  @action setDateFollowUp(value: Date | undefined) {
    this.selectedDateFollowUp = value;
  }

  @action setTime(value: string) {
    this.selectedTime = value;
  }
  @action setSelectedDate(value: string) {
    this.selecteDate = new Date(value);
  }
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
  }
  public Load = async (AgentLeadId: number): Promise<void> => {
    let delay = 0;
    if (UserContext.permissions.length <= 0) {
      delay = 2000;
    }
    setTimeout(async () => {
      if (
        UserContext.permissions.length > 0 &&
        !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
      ) {
        this.hasPermission = false;
      } else {
        this.hasPermission = true;
      }
      await this.ResetValidate();
      this.resetFilters();
      this.AgentLeadId = AgentLeadId;
      this.loading = true;
      await this.loadDropdowns();
      var model = await LeadStore.getSaleOpsLead(this.AgentLeadId);
      this.assignerId = model.assignerId || 0;
      this.selectedAssignee = model.assigneeId ? "" + model.assigneeId : "0";
      if (model.assigneeId) {
        await this.loadSubAssignee(model.assigneeId);
      }
      this.selectedSubAssignee = model.subAssigneeId
        ? "" + model.subAssigneeId
        : "0";
      this.selectedProductLine = model.productLineId
        ? "" + model.productLineId
        : "0";
      this.selectedLeadSource = model.agentLeadSourceId
        ? "" + model.agentLeadSourceId
        : "";
      this.selectedCompany = model.carrierId ? "" + model.carrierId : "";
      this.selectedLeadName = model.leadName ? "" + model.leadName : "";

      this.selectedLeadNPN = model.leadNPN ? "" + model.leadNPN : "";
      this.selectedLeadEmail =
        model.leadEmail && model.leadEmail.length > 0
          ? "" + model.leadEmail[0]
          : "";
      this.selectedLeadPhaseDetail = "" + model.leadPhaseDetailId;

      if (model.dateFollowUp) {
        this.InputModel = new InputModel();

        this.InputModel.DateFollowUp = new Date(model.dateFollowUp.toString());
        this.selectedDateFollowUp = new Date(
          model.dateFollowUp.toLocaleString()
        );
      }

      this.isPreContracted = model.isPreContracted
        ? model.isPreContracted
        : false;
      this.leadPhone =
        model.leadPhone && model.leadPhone.length > 0
          ? "" + model.leadPhone[0]
          : "";
      this.notes = model.leadNote ? "" + model.leadNote : "";
      this.selectedLeadName = model.leadName ? "" + model.leadName : "";

      this.uplineId = model.agentId || 0;
      if (model.leadPhaseId) {
        await this.loadLeadPhaseDropdown(model.leadPhaseId);
      }

      this.selectedLeadPhase = model.leadPhaseId ? "" + model.leadPhaseId : "";
      this.loadLeadPhaseDetail();

      if (model.dateFollowUp) {
        this.InputModel = new InputModel();

        this.InputModel.DateFollowUp = new Date(model.dateFollowUp.toString());
      }
      if (this.uplineId !== null && this.uplineId > 0) {
        this.loadAgentDetail();
      }
      this.isLoading = false;
    }, delay);
  };

  @action loadAgentDetail = async () => {
    var result = await ProducerStore.getAgentDetails(this.uplineId);
    if (result != null) {
      if (
        result.firstName !== "" &&
        result.firstName !== null &&
        result.firstName !== undefined
      ) {
        this.selectedUser = result.lastName + " " + result.firstName;
      } else {
        this.selectedUser = result.lastName;
      }
    }
  };

  public Cancel = async () => {
    this.isSuccess = false;
    routes.salesLeads.push();
  };

  @action goToContracted = async () => {
    routes.listLeads.push();
  };
  @action goToNonContracted = async () => {
    routes.leads.push();
  };
  @action addNewAgentLead = async () => {
    routes.addAgentLead.push();
  };
  @action setNote(value: string) {
    this.notes = value;
  }

  Route = async (): Promise<void> => {};
  @action loadSubAssignee = async (assigneeId: number) => {
    if (assigneeId !== null && assigneeId > 0) {
      try {
        let result = await LeadStore.getSubAssigneeLookup(
          assigneeId,
          Utils.getUserId()
        );
        if (result) {
          this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
          for (let item of result) {
            this.subAssigneeList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
          }
          for (let item of this.assigneeList) {
            if (item.value === this.selectedAssignee) {
              var existItem = this.subAssigneeList.find(
                (p) => p.value === this.selectedAssignee
              );
              if (existItem === undefined) {
                this.subAssigneeList.push({
                  label: "" + item.label,
                  value: "" + item.value,
                });
              }
              this.selectedSubAssignee = item.value;
            }
          }
        }
      } catch (ex) {
        IMASLog.log(ex.toString());
      }
    } else {
      var response = await LeadStore.getDropdownValues(true, false);
      if (response) {
        this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
        if (response.subAssignee) {
          var subassigneelist = [...response.subAssignee].sort(
            this.sortTypeName
          );
          for (let item of subassigneelist) {
            this.subAssigneeList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
          }
        }
        this.selectedSubAssignee = "0";
      }
    }
  };
  @action setSelectedAssignee(value: string) {
    this.selectedAssignee = "" + value;
  }
  @action
  public Submit = async () => {
    await this.ResetValidate();
    this.isPhoneValid = false;
    var phoneValue = "";
    if (this.leadPhone) {
      phoneValue = this.leadPhone
        .replace(/_/g, "")
        .replace(/-/g, "")
        .replace(/\s/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .trim();
    }
    if (phoneValue && phoneValue.length > 0 && phoneValue.length < 10) {
      this.setIsPhoneValid(true);
      this.setValidPhoneError("Phone is Invalid");
    }
    this.isNPNValid = true;

    if (this.selectedLeadNPN && this.selectedLeadNPN.length > 0) {
      if (this.selectedLeadNPN.substring(0, 1) === "0") {
        this.setIsNPNValid(false);
        this.setValidNPNError("NPN can't start with 0");
      }
    }

    if ((await this.Validate()) || this.isPhoneValid || !this.isNPNValid)
      return;

    if (!(await this.Validate())) {
      this.isException = false;
      this.isSuccess = false;
      this.isLoading = true;
      let emails: string[] = [];
      emails.push(this.selectedLeadEmail);

      let phones: string[] = [];
      phones.push(phoneValue);

      await LeadStore.editSaleOpsLead({
        agentLeadId: this.AgentLeadId,
        subAssigneeId: parseInt(this.selectedSubAssignee),
        dateFollowUp: this.selectedDateFollowUp,
        leadPhaseId: parseInt(this.selectedLeadPhase),
        leadPhaseDetailId: parseInt(this.selectedLeadPhaseDetail),
        isClosed: true,
        productLineId: parseInt(this.selectedProductLine),
        assigneeId: parseInt(this.selectedAssignee),
        agentId: this.uplineId,
        carrierId: parseInt(this.selectedCompany),
        leadNPN: this.selectedLeadNPN,
        leadNote: this.notes,
        assignerId: this.assignerId,
        leadEmails: emails,
        leadPhoneNumbers: phones,

        isPreContracted: this.isPreContracted,
        invalidReferral: true,
        invalidForActivation: this.invalidForActivation,
      });

      this.isLoading = false;

      this.Cancel();
    }
  };

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @observable
  public InputModel = new InputModel();
  @observable
  public ErrorModel = new ErrorModel(new Validator());
  public onSave?: (CarrierBankDepositID: number) => void;
  private onClose?: () => void;
  @observable
  private loading = true;
}

export class InputModel {
  //#region Properities
  @observable
  public subAssignee: string = "";
  @observable
  public DateFollowUp: Date | undefined;

  @observable
  public LeadPhase: string = "";
  @observable
  public Assignee: string = "";
  @observable
  public DepositAmount: string = "";
  @observable
  public Note: string = "";
  @observable
  public PaidDivisionID: number | undefined;
  @observable
  public CompanyID: number | undefined;
  //#endregion

  //#region State Management
  @action
  public SetSubAssignee = (value: string) => {
    this.subAssignee = value;
  };

  @action
  public SetDateFollowUp = (value: Date | undefined) => {
    this.DateFollowUp = value;
  };

  @action
  public SetLeadPhase = (value: string) => {
    this.LeadPhase = value;
  };

  @action
  public SetDepositAmount = (value: string) => {
    this.DepositAmount = value;
  };

  @action
  public SetNote = (value: string) => {
    this.Note = value;
  };
  //#endregion
}

class Validator extends AbstractValidator<SaleOpsAgentLeadEditViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.selectedLeadPhase)
      .isNotEmpty()
      .withFailureMessage("Lead Phase is required");

    this.validateIfString((input) => input.selectedLeadSource)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Lead Source is required");

    this.validateIfNumber((input) => input.uplineId)
      .isDefined()
      .isNotEqualTo(0)
      .withFailureMessage("Agent is required");

    this.validateIfString((input) => input.selectedProductLine)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Product Line is required");

    this.validateIfString((input) => input.selectedLeadEmail)
      .isEmail()
      .whenNotEmpty()
      .withFailureMessage("Please enter valid email");
  }
}
