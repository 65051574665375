import { observer } from "mobx-react";
import React from "react";
import { InputText } from "primereact/inputtext";
import { Repeater } from "../Repeater";
import classNames from "classnames";

export const CustomInputText: React.FC<{
  errors: string[] | undefined;
  label: string;
  value: string | undefined;
  id: string;
  isrequired: boolean;
  isdisabled?: boolean;
  maxLength: number | undefined;
  placeHolder: string | undefined;
  readonly?: boolean;
  keyfilter?: string;
  minLength?: number;
  className?: string;
  autoFocus?: boolean;
  onBlur?: (value: string) => void;
  onChange: (value: string) => void;
  keyEventHandle?: (key: string, value: string) => void;
}> = observer((props) => {
  const getClassNameError = () => {
    if (props.errors)
      return classNames({
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    else return classNames("");
  };
  setTimeout(() => {
    let ele = document.getElementById("policyNumber");
    if (ele) {
      ele.focus();
    }
  }, 50);
  return (
    <>
      {props.label && (
        <label htmlFor={props.id}>
          {props.label}{" "}
          {props.isrequired && <span className="required">*</span>}{" "}
        </label>
      )}
      {props.readonly || props.isdisabled ? (
        <>
          <div
            className="p-inputtext p-component p-autocomplete-input p-disabled p-filled"
            style={{ minHeight: "36.6px" }}
          >
            {props.value}
          </div>
        </>
      ) : (
        <>
          <InputText
            placeholder={props.placeHolder}
            readOnly={props.readonly ? props.readonly : false}
            id={props.id}
            className={props.className ? props.className : getClassNameError()}
            maxLength={props.maxLength}
            minLength={props.minLength}
            value={props.value}
            disabled={props.isdisabled}
            keyfilter={props.keyfilter}
            onKeyUp={(e) => {
              if (props.keyEventHandle)
                props.keyEventHandle(e.key, e.currentTarget.value);
            }}
            onBlur={(event) =>
              props.onBlur ? props.onBlur(event.currentTarget.value) : null
            }
            onChange={(event) => props.onChange(event.currentTarget.value)}
            autoFocus={props.autoFocus}
          />
        </>
      )}

      <Repeater
        errors={props.errors}
        viewId={props.id}
        child={(error) => <div className="invalid-feedback">{error}</div>}
      />
    </>
  );
});
