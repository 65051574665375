import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import IdentityStore from "../../store/IdentityStore";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { reportNamesConstants } from "../../infrastructure/enum/Constants";
import Utils from "../../infrastructure/Utils";

export class EmailDownloadViewModel implements ViewModel {
	get CanRoute(): boolean {
		return true;
	}

	get CanClose(): boolean {
		throw new Error("Method not implemented.");
	}
	get IsLoaded(): boolean {
		throw new Error("Method not implemented.");
	}
	get IsLoading(): boolean {
		throw new Error("Method not implemented.");
	}
	Close(): void {
		throw new Error("Method not implemented.");
	}
	Route = async (currentRoute: RouteList): Promise<void> => { };

	@action Load = async (fileName: string, reportType: any) => {

		if (fileName)
			await this.downloadEmail(fileName, reportType);
	};



	@observable response: any;

    @action downloadEmail = async (filename: string, reportType?: string | undefined) => {
        this.response = {};
        try {
            let result = await IdentityStore.downloadAttachment(filename);
            if (result != null) {
                if (result.data) {
                    Utils.downloadDocumentFile(
                        result.data,
                        result.fileName !== null && result.fileName !== undefined
                            ? result.fileName
                            : filename,
                        result.data.type ? result.data.type : (result.headers ? result.headers["content-type"] : "")
                    );
                    //await this.downloadDocument(result.data, result.fileName ? result.fileName : "", reportType);
                    if (reportType !== null && reportType !== undefined && reportType !== '0') {
                        RouteParamDetails.reportType = reportType;
                        routes.reports.push();
                    }
                }
            }
        } catch (e) {
            this.response = e.response;
        }
    };

	@action downloadDocument = async (base64: string, filename: string, reportType: string | undefined) => {
		//setTimeout(() => {
		var link = document.createElement("a");

		if (reportType !== null && reportType !== undefined && reportType !== '0') {
			var downloadedFileName = this.setFileNameOnReportSelection(filename, reportType);
			link.download = `${downloadedFileName}`;
		}
		else {
			link.download = `${filename}`;
		}


		if (filename.indexOf(".xlsx") > 0) {
			link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64;
		}
		if (filename.indexOf(".pdf") > 0) {
			link.href = "data:application/pdf;base64," + base64;
		}

		link.textContent = "Download";
		link.click();
		document.body.appendChild(link);
		link.remove();
		//}, 0);
	};

	setFileNameOnReportSelection = (filename: string, reportType: string) => {

		var downloadedFileName = "";
		var fileExtension = ""

		if (filename.indexOf(".xlsx") > 0) {
			fileExtension = ".xlsx";
		}
		if (filename.indexOf(".pdf") > 0) {
			fileExtension = ".pdf";
		}
		switch (reportType) {
			case "22":
				downloadedFileName = reportNamesConstants.AgentAgencyDetails;
				break;
			case "26":
				downloadedFileName = reportNamesConstants.CommissionBreakDown;
				break;
			case "1":
				downloadedFileName = reportNamesConstants.ContractedAgentList;
				break;
			case "24":
				downloadedFileName = reportNamesConstants.ContractDetails;
				break;
			case "8":
				downloadedFileName = reportNamesConstants.ContractsByMarketer;
				break;
			case "27":
				downloadedFileName = reportNamesConstants.AgentAgencyContactandDemographics;
				break;
			case "14":
				downloadedFileName = reportNamesConstants.MarketerProduction;
				break;
			case "17":
				downloadedFileName = reportNamesConstants.PolicyInfo;
				break;
			case "18":
				downloadedFileName = reportNamesConstants.PolicySummary;
				break;
			case "19":
				downloadedFileName = reportNamesConstants.Production;
				break;
			case "20":
				downloadedFileName = reportNamesConstants.ProductionSummary;
				break;
			default:
				downloadedFileName = reportNamesConstants.Report;
				break;
		}
		return downloadedFileName + "_Report" + fileExtension;
	}

}
