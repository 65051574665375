import React from 'react'
import { DashboardViewModel } from "./../viewModels/DashboardViewModel";
import { observer } from "mobx-react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { RadioButton } from 'primereact/radiobutton';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from 'primereact/splitbutton';
import 'primeflex/primeflex.css';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { CustomInputText } from './components/controls/IMASInputText';
import { IMASMessagesHandler } from './components/controls/IMASMessagesHandler';

const DashboardView: React.FC<{ vm: DashboardViewModel }> = observer(props => {
   
    const editTemplate = (rowData: any, column: any) => {

        return (
            <div >
                <SplitButton className='imas-split-button' icon="fa fa-edit" label="Edit"
                    style={{ width: 'auto' }} model={actionItems} />

            </div>
        );
    };
    const actionItems: MenuItem[] = [
        {
            label: 'Delete',
            icon: 'fa fa-trash',
            command: () => {
            }
        },
        {
            label: 'Replace',
            icon: 'fa fa-exchange',
            command: () => {

            }
        },
        {
            label: 'View',
            icon: 'fa fa-eye',
            command: () => {
            }
        },
    ]
    return (
        <div className="p-grid">
            <div className="p-col-12 gray-background" >
                <Card>
                    <div className="section-title"> Card </div>
                </Card>
                <Card>
                    <div className="section-title"> Grid 3 Columns </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4 p-lg-4">Column 1</div>
                        <div className="p-col-12 p-md-4 p-lg-4">Column 2</div>
                        <div className="p-col-12 p-md-4 p-lg-4">Column 3</div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Grid 2 Columns </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6 p-lg-6">Column 1</div>
                        <div className="p-col-12 p-md-6 p-lg-6">Column 2</div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Mobile View Order Change - To check resize to mobile view</div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4 p-lg-4 sm-order-2">Column 1</div>
                        <div className="p-col-12 p-md-4 p-lg-4 sm-order-3">Column 2</div>
                        <div className="p-col-12 p-md-4 p-lg-4 sm-order-1">Column 3</div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8 p-lg-8">
                            <div className="section-title"> Form Inputs</div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4 text-right">
                            <Button className='primary-button' type="button" label="Add" icon="fa fa-plus" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Input Box</label>
                                <InputText placeholder='Input Box' />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Input Box Readonly</label>
                                <InputText readOnly placeholder='Input Box Readonly' value="Input Box Readonly" />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Input Box Disabled</label>
                                <InputText disabled placeholder='Input Box Disabled' value='Input Box Disabled' />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Textarea</label>
                                <InputTextarea rows={3} />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Textarea Readonly</label>
                                <InputTextarea rows={3} readOnly />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Textarea Disabled</label>
                                <InputTextarea rows={3} disabled />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Dropdown Single</label>
                                <Dropdown value={citySelectItems} options={citySelectItems} placeholder="Select" />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Dropdown Filter</label>
                                <Dropdown value={citySelectItems} options={citySelectItems} filter={true} filterPlaceholder="Search" filterBy="label,value" placeholder="Select" />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Dropdown Disabled</label>
                                <Dropdown disabled value={citySelectItems} options={citySelectItems} placeholder="Select" />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col">
                            <div className="button-group text-right">
                                <Button className='secondary-button margin-right-10' type="button" label="Cancel" icon="fa fa-times" />
                                <Button className="primary-button" type="button" label="Save" icon="fa fa-save" />
                            </div>
                        </div>
                    </div>



                </Card>

                <Card>
                    <div className="section-title"> Form Inputs 2 Columns </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="form-group">
                                <label>Input Box</label>
                                <InputText placeholder='Input Box' />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="form-group">
                                <label>Input Box Readonly</label>
                                <InputText readOnly placeholder='Input Box Readonly' value="Input Box Readonly" />
                            </div>
                        </div>

                    </div>

                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <Card>
                            <div className="section-title"> 2 Columns Card Grid 1</div>
                            <div className="form-group">
                                <label>Input Box</label>
                                <InputText placeholder='Input Box' />
                            </div>
                        </Card>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <Card>
                            <div className="section-title"> 2 Columns Card Grid 2 </div>
                            <div className="form-group">
                                <label>Input Box Readonly</label>
                                <InputText readOnly placeholder='Input Box Readonly' value="Input Box Readonly" />
                            </div>
                        </Card>
                    </div>
                </div>
                <Card>
                    <div className="section-title"> Valid State Input </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Input Box Error <span className="required">*</span></label>
                                <InputText placeholder='Input Box' className="p-error" />
                                <div className="invalid-feedback">Required</div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Dropdown Error <span className="required">*</span></label>
                                <Dropdown className="p-error" value={citySelectItems} options={citySelectItems} placeholder="Select" />
                                <div className="invalid-feedback">Required</div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">
                                <label>Textarea Error <span className="required">*</span></label>
                                <InputTextarea rows={3} className="p-error" />
                                <div className="invalid-feedback">Required</div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Alerts </div>
                    <div className="alert alert-success" role="alert">
                        Success alert—check it out!
                       <Button icon="fa fa-times" />
                    </div>
                    <div className="alert alert-danger" role="alert">
                        Danger alert—check it out!
                       <Button icon="fa fa-times" />
                    </div>
                    <div className="alert alert-warning" role="alert">
                        Warning alert—check it out!
                        <Button icon="fa fa-times" />
                    </div>
                    <div className="alert alert-info" role="alert">
                        Info alert—check it out!
                        <Button icon="fa fa-times" />
                    </div>
                </Card>
                <Card>

                    <div className="section-title"> Search Box and Datepicker </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="form-group search-form-group">
                                <label>Search Box</label>
                                <InputText
                                    type="search"
                                    id='serachInputValue'
                                    placeholder="Search"
                                    tooltip="Search" tooltipOptions={{ position: 'bottom', hideDelay: 300 }}
                                    icon="fa fa-search"
                                />
                                <Button id='searchButton' className="primary-button" icon="fa fa-search" />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="form-group">
                                <label>Datepicker</label>
                                <Calendar monthNavigator={true} yearNavigator={true} yearRange="2020:2030" showIcon={true} />
                            </div>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Buttons </div>
                    <div className="p-grid">
                        <div className="p-col">
                            <div className="section-title secondry-title"> Primary Buttons </div>
                            <div className="form-group">
                                <Button className="primary-button margin-right-10" icon="fa fa-search" label="Search" />
                                <Button className="primary-button margin-right-10" icon="fa fa-plus" label="Add" />
                                <Button className="primary-button margin-right-10" icon="fa fa-edit" label="Edit" />
                            </div>

                            <div className="section-title secondry-title"> Secondary Buttons </div>
                            <div className="form-group">
                                <Button className='secondary-button margin-right-10' type="button" label="Reset" icon="fa fa-redo" />
                                <Button className='secondary-button margin-right-10' type="button" label="add" icon="fa fa-plus" />
                                <Button className='secondary-button margin-right-10' type="button" label="Cancel" icon="fa fa-times" />
                                <SplitButton className='secondary-button' label="Edit" icon="fa fa fa-edit" ></SplitButton>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className='section-title'>DataTable</div>
                    <div className='p-grid'>
                        <div className="p-col-12" >
                            <div className="section-title secondry-title"> Prime react table </div>
                            <div className="form-group">
                                <DataTable
                                    resizableColumns={true} columnResizeMode="expand"
                                    responsive={true} value={props.vm.AgentsList} paginator={true}
                                    rows={10} lazy={true} totalRecords={10}
                                    emptyMessage='No Addresses available'
                                    first={0} >

                                    <Column field="type" header="Type" />
                                    <Column field="id" header="ID" />
                                    <Column header="address" field="addressName" />
                                    <Column header="name" field="Name" />
                                    <Column className="grid-split-btn" header="" body={editTemplate} />

                                </DataTable>
                            </div>
                            <div className="section-title secondry-title"> Customized table </div>
                            <DataTable
                                resizableColumns={true} columnResizeMode="expand" className="app-table"
                                responsive={true} value={props.vm.AgentsList} paginator={true}
                                rows={10} lazy={true} totalRecords={10}
                                emptyMessage='No Addresses available'
                                first={0} >

                                <Column field="type" header="Type" />
                                <Column field="id" header="ID" />
                                <Column header="address" field="addressName" />
                                <Column header="name" field="Name" />
                                <Column className="secondary-button grid-split-btn" header="" body={editTemplate} />

                            </DataTable>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Tab View </div>

                    <div className="app-tab tab-opt2 margin-top-10 clear-both">
                        <div className="form-group enable-mobile">
                            <Dropdown value={citySelectItems} options={citySelectItems} placeholder="Select" />
                        </div>
                        <TabView>
                            <TabPanel header="Header I">

                            </TabPanel>
                            <TabPanel header="Header II">
                                Content II
                            </TabPanel>
                            <TabPanel header="Header III">
                                Content III
                            </TabPanel>
                        </TabView>
                        <div className="p-tabview-panels">
                            <div className="app-tab secondary-tab">
                                <TabView>
                                    <TabPanel header="Header I">
                                        Content I
                                    </TabPanel>
                                    <TabPanel header="Header II">
                                        Content II
                                    </TabPanel>
                                    <TabPanel header="Header III">
                                        Content III
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Vertical Checkbox </div>
                    <div className="p-col-12">
                        <Checkbox inputId="cb1" value="New York"></Checkbox>
                        <label htmlFor="cb1" className="p-checkbox-label">New York</label>
                    </div>
                    <div className="p-col-12">
                        <Checkbox inputId="cb2" value="San Francisco" ></Checkbox>
                        <label htmlFor="cb2" className="p-checkbox-label">San Francisco</label>
                    </div>
                    <div className="p-col-12 form-group">
                        <Checkbox inputId="cb3" value="Los Angeles" ></Checkbox>
                        <label htmlFor="cb3" className="p-checkbox-label">Los Angeles</label>
                    </div>

                    <div className="section-title"> Horizontal Checkbox </div>
                    <div className="p-grid">
                        <div className="p-col-3">
                            <Checkbox inputId="cb1" value="New York"></Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label">New York</label>
                        </div>
                        <div className="p-col-3">
                            <Checkbox inputId="cb2" value="San Francisco" ></Checkbox>
                            <label htmlFor="cb2" className="p-checkbox-label">San Francisco</label>
                        </div>
                        <div className="p-col-3">
                            <Checkbox inputId="cb3" value="Los Angeles" ></Checkbox>
                            <label htmlFor="cb3" className="p-checkbox-label">Los Angeles</label>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Vertical Radio button </div>
                    <div className="p-col-12">
                        <RadioButton inputId="rb1" value="New York"></RadioButton>
                        <label htmlFor="rb1" className="p-checkbox-label">New York</label>
                    </div>
                    <div className="p-col-12">
                        <RadioButton inputId="rb2" value="San Francisco" ></RadioButton>
                        <label htmlFor="rb2" className="p-checkbox-label">San Francisco</label>
                    </div>
                    <div className="p-col-12 form-group">
                        <RadioButton inputId="rb3" value="Los Angeles" ></RadioButton>
                        <label htmlFor="rb3" className="p-checkbox-label">Los Angeles</label>
                    </div>
                    <div className="section-title"> Horizontal Radio button </div>
                    <div className="p-grid">
                        <div className="p-col-3">
                            <RadioButton inputId="r3b1" value="New York"></RadioButton>
                            <label htmlFor="r3b1" className="p-checkbox-label">New York</label>
                        </div>
                        <div className="p-col-3">
                            <RadioButton inputId="r2b2" value="San Francisco" ></RadioButton>
                            <label htmlFor="r2b2" className="p-checkbox-label">San Francisco</label>
                        </div>
                        <div className="p-col-3">
                            <RadioButton inputId="r3b3" value="Los Angeles" ></RadioButton>
                            <label htmlFor="r3b3" className="p-checkbox-label">Los Angeles</label>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="section-title"> Spinner </div>
                    <div className="spinner text-center">
                        <ProgressSpinner />
                    </div>
                </Card>

                <Card>
                    <div className="section-title"> Colored label</div>
                    <div className="p-grid">
                        <div className="p-col-3">
                            <i className="box light-green"></i>
                            <label htmlFor="r3b1" className="p-checkbox-label">New York</label>
                        </div>
                        <div className="p-col-3">
                            <i className="box light-orange"></i>
                            <label htmlFor="r2b2" className="p-checkbox-label">San Francisco</label>
                        </div>
                        <div className="p-col-3">
                            <i className="box light-blue"></i>
                            <label htmlFor="r3b3" className="p-checkbox-label">Los Angeles</label>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8 p-lg-8">
                            <div className="section-title"> Signal R Notification By Current Ad User</div>
                        </div>

                    </div>

                    <div className="p-grid">
                    <div className="p-col-9">
                        {props.vm.isMessgeVisible && (
                            <IMASMessagesHandler
                                response={props.vm.response}
                            ></IMASMessagesHandler>
                        )}
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group">

                                <CustomInputText
                                    errors={props.vm.ErrorModel.getError("message")}
                                    label="Message"
                                    id="txtxmessage"
                                    isrequired={true}
                                    onBlur={() => { }}
                                    maxLength={undefined}
                                    placeHolder=""
                                    value={props.vm.message}
                                    onChange={(value: string) => {
                                        props.vm.setMessage(value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="form-group" style={{ paddingTop: "24px" }}>
                                <label>&nbsp;</label>
                                <Button  className="primary-button"  onClick={() => props.vm.sendMessage()} type="button" label="Send" icon="fa fa-save" />
                            </div>
                        </div>

                    </div>





                </Card>

            </div>

        </div>
    );
});
const citySelectItems = [
    { label: 'New York', value: 'NY' },
    { label: 'Rome', value: 'RM' },
    { label: 'London', value: 'LDN' },
    { label: 'Istanbul', value: 'IST' },
    { label: 'Paris', value: 'PRS' }
];
export default DashboardView;
