// mobx
import { observable, action, toJS } from "mobx";
// store
import DocumentStore from "../../../store/DocumentStore";
// ViewModel
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
// route
import RouteList from "../../../infrastructure/RouteList";
// models
import { DocumentListModel } from "../../../services/DocumentService";
// other
import { Constants } from "../../../infrastructure/enum/Constants";
import Utils from "../../../infrastructure/Utils";
import { Permission } from "../../../infrastructure/enum/Permission";
import { AddAgentDocViewModel } from "../../../views/agents/documents/AddAgentDocViewModel";
import ProspectiveCallListView from "../../../views/autoDialers/ProspectiveCallListView";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import {
  AgentDocumentOptionValues,
  AgentDocumentOptions,
} from "../../../infrastructure/enum/AgentDetails";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import { routes } from "../../../router";
import { toast } from "react-toastify";
import { googleTagManager } from "../../../infrastructure/tracking";
import UserContext from "../../../infrastructure/UserContext";
import { closeToasterButtonTemplate } from "../../../views/components/controls/IMASTemplates";
import PageContext from "../../../infrastructure/PageContext";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";

export class AgentDocsViewModel implements ViewModel {
  // ViewModel functions implementation
  get CanClose(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get CanRoute(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoaded(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoading(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  Close(): void {
    throw new Error(Constants.NoMethodImplementation);
  }
  @action Load = async (id: number, docId:number) => {
    this.showInactiveCarriers = false;
    this.documentList.values = [];
    this.agentId = id;
    this.selectedTypeSearch = 0;
    this.selectedCompanySearch = 0;
    this.resetPagingValues();
    this.loadDocTypesAndCompanies();
    if(docId > 0){
      this.handleAddModalVisibility(true,docId);
    }
    this.rows = 25;
    this.searchValue = "";
    this.searchInputValue = "";
    await this.loadDocs(); 
  };

  // declaration for route
  Route = async (currentRoute: RouteList): Promise<void> => {};
  // route id's
  @observable agentId: number = 0;
  @observable docId: number = 0;

  // paging
  @observable rows: number = 25; // rows is pagesize
  @observable totalRecords: number = 0;
  @observable first: number = 0; // first is pageindex
  @observable startIndex: number = 0;

  // loader
  @observable isLoading: boolean = true;

  // page input variables
  @observable searchInputValue: string = "";
  @observable searchValue: string | undefined = undefined;
  @observable selectedTypeSearch: any;
  @observable selectedCompanySearch: any;
  @observable sortColumn: string = "createdDate";
  @observable sortOrder: boolean = false;

  // others
  @observable isDeleteConfirm: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @observable exceptionMessage: string = Constants.Error;

  // lists variables
  @observable documentList = new Collection<DocumentListModel>();
  @observable docWorkFlowHistoryList = new Collection<any>();
  @observable companyListSearch = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable documentTypeListSearch = new Collection<{
    label: string;
    value: string;
  }>();
  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  // Workflow side panel variables
  @observable isSidebarLogVisible: boolean = false;
  @observable selectedDocRow: any = {};
  @observable viewFiles: string[] = ["pdf", "png", "jpg", "jpeg"];
  @observable fileDocumentType: any;
  @observable deleteAlterMsg: string = "";
  @observable isRefreshEnable: boolean = false;
  // Functions implementation

  // function for company and doc type binding
  @observable AddAgentDocViewModel: AddAgentDocViewModel =
    new AddAgentDocViewModel();
  @observable workflowModalVisibility: boolean = false;
  @observable deleteModalVisibility: boolean = false;
  @observable deleteDocumentContext: any =
    "Are you sure you want to delete this document?";
    @observable showInactiveCarriers: boolean = false;
    @observable carriersInitial = new Collection<{
      label: string;
      value: string;
    }>();


  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyListSearch.values = []; 
    this.showInactiveCarriers ? (this.companyListSearch.values = this.carriersInitial.values) :
    this.companyListSearch.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action loadDocTypesAndCompanies = async () => {
    var result = await DocumentStore.getAgentDocScreenLookups();
    if (result) {
      Utils.mapListItemsToDropdown(
        result.carriers || [],
        this.companyListSearch,
        "All Carriers"
      );
      Utils.mapListItemsToDropdown(
        result.fileTypes || [],
        this.documentTypeListSearch,
        "All"
      );
      this.selectedTypeSearch = "0";
      this.selectedCompanySearch = "0";
      this.carriersInitial.values = this.companyListSearch.values;
      this.companyListSearch.values = Utils.carriersFilter(this.carriersInitial.values);
    }
  };

  // function for all documents binding
  @action resetPagingValues() {
    this.rows = 25;
    this.totalRecords = 0;
    this.sortOrder = false;
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "createdDate";
  }
  @action loadDocs = async (retainFilters?:boolean) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    if(!retainFilters){
    this.isRefreshEnable = false;}
    this.response = {};
    try {
      var result = await DocumentStore.getAgentDocuments(
        this.agentId,
        this.selectedTypeSearch ? parseInt(this.selectedTypeSearch) : 0,
        this.selectedCompanySearch ? parseInt(this.selectedCompanySearch) : 0,
        this.searchValue ? this.searchValue : "",
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder,
        Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments)
          ? null
          : false
      );
      if (result != null) {
        if (result.data) {
          this.totalRecords = result.recordCount ? result.recordCount : 0;

          this.documentList.values = result.data;
        } else {
          this.documentList.values = [];
        }
      }
    } catch (e) {
      this.documentList.values = [];
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  // show document workflow history
  @action loadDocumentWorkFlowHistory = async (documentId: number) => {
    this.isLoading = true;
    this.response = {};
    try {
      var result = await DocumentStore.getAgentDocWorkFlowHistory(documentId);
      if (result) {
        var output: any = [];
        output = result;
        this.docWorkFlowHistoryList.values = output;

        this.setSidebarLogVisibility(true);
      } else {
        this.docWorkFlowHistoryList.values = [];
      }
      if (this.docWorkFlowHistoryList.values.length <= 0) {
        var responseModel = {
          status: 400,
          title: Constants.NoHistoryAvailable,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
      }
    } catch (e) {
      this.docWorkFlowHistoryList.values = [];
      this.isMessgeVisible = true;
      this.response = e.response;
      this.setSidebarLogVisibility(false);
    }
    this.isLoading = false;
  };
  //  Events

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadDocs();
  }
  @action setSelectedCompanySearch = (value: string) => {
    this.selectedCompanySearch = value;
  };
  @action setSelectedTypeSearch = (value: string) => {
    this.selectedTypeSearch = value;
  };
  @action setSelectedDoc = (docId: number) => {
    this.docId = docId;
  };
  @action setSelectedRow = (value: any) => {
    this.selectedDocRow = value;
  };

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action setWorkflowModalVisibility = (value: boolean) => {
    this.workflowModalVisibility = value;
  };

  @action setDeleteModalVisibility = (value: boolean) => {
    this.deleteModalVisibility = value;
  };

  @action onDeleteClick = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      this.response = {};
      await DocumentStore.deleteDocument(this.docId);
      Utils.showSuccessToaster("Document Deleted.", 3000);
      setTimeout(async () => {
        this.setDeleteModalVisibility(false);
        this.isLoading = false;
        await this.loadDocs(true);
      }, Utils.timeDelay_Delete());
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
      this.setDeleteConfirmation(false);
    }
  };

  @action onDeleteClickMsg = async (docId: number) => {
    try {
      var result = await DocumentStore.getDocumentAssocaitionsByDocumentId(
        docId
      );
      if (result && result.message) {
        this.deleteAlterMsg = result.message;
      }
      this.setDeleteModalVisibility(true);
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
      this.setDeleteModalVisibility(false);
    }
  };

  // events for workflow
  @action showSidebarDocumentLog(value: any) {
    this.selectedDocRow = value;
    var obj = JSON.parse(JSON.stringify(value));
    var id = obj.id;
    this.loadDocumentWorkFlowHistory(id);
  }
  @action setSidebarLogVisibility(value: boolean) {
    this.isSidebarLogVisible = value;
  }

  // events for search
  @action searchIntervals = async (text: string) => {
    this.searchInputValue = text;
    if (text) {
      this.searchValue = text;
    } else {
      this.searchValue = undefined;
      this.startIndex = 0;
    }
    await this.refreshEnable();
  };

  @action refreshEnable = async () => {
    await this.goSearch();
    if (
      (this.selectedTypeSearch == 0 || this.selectedTypeSearch == "") &&
      (this.selectedCompanySearch == 0 || this.selectedCompanySearch == "") &&
      (this.searchInputValue == "" || this.searchInputValue.length <= 2)
    ) {
      this.isRefreshEnable = false;
    } else {
      this.isRefreshEnable = true;
    }
  };
  @action loadDocument = (base64: string) => {
    let win = window.open("DocumentViewer", "_blank");
    let html = "";
    html += "<html>";
    html += '<body style="margin:0!important">';
    html += '<div style="width: 100%;height: 100%; position: relative; ">';
    html +=
      '<div style="width: 100%;height: 100%; position: absolute;top: 0;left: 0; z-index: 10;">';
    html +=
      '<embed width="100%" height="100%" src="data:application/pdf;base64,' +
      base64 +
      '" type="application/pdf" />';
    html += "</div>";
    html += "</div>";
    html += "</body>";
    html += "</html>";

    setTimeout(() => {
      if (win !== null) win.document.write(html);
    }, 0);
  };

  @action downloadDocumentLink = async (docId: number, docFileName: any) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      var result = await DocumentStore.downloadFiles(docId, 0, true);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : docFileName,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        } else {
          if (result.data && result.data !== null) {
            var responseModel = {
              status: 400,
              title: result.data,
              errors: { "": [] },
            };
            this.response = JSON.stringify(responseModel);
            this.isMessgeVisible = true;
          }
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action searchDocs = async (key: string, text: string) => {
    if (key === "Enter") {
      this.loadDocs();
    }
  };
  @action goSearch = async () => {
    this.first = 0;
    this.loadDocs();
  };
  // event for reset
  @action resetFilters = () => {
    this.selectedTypeSearch = "0";
    this.selectedCompanySearch = "0";
    this.searchValue = "";
    this.searchInputValue = "";
    this.resetPagingValues();
    this.loadDocs();
  };

  @action setDeleteConfirmation(value: boolean) {
    this.isDeleteConfirm = value;
  }

  @observable isloadGrid: boolean = false;
  @action handleAddModalVisibility = (value: boolean, docId: number) => {
    this.docId = docId;
    this.addModalVisibility(value);
    !value && localStorage.removeItem("SubmitFromWorkFlowDocId");
  };

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "action-report-menu-list-wrap"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "actions-ellipsis-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "actions-ellipsis-menu-active"
            : "actions-ellipsis-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "action-report-menu-list-wrap"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "actions-ellipsis-menuspan"
              : "actions-ellipsis-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".actions-ellipsis-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };
  //

  // add doc method start

  @observable disableUpload: boolean = true;
  @observable disableFooter: boolean = false;
  @observable disableWorkFlowRadio: boolean = false;
  @observable isAddModal: boolean = false;
  @observable workFlowList: any = [];
  @observable lookupDocApiData: any;

  @observable marketerList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable submissionList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedCompany: string = "";
  @observable selectedType: string = "";
  @observable selectedTypeText: string = "";
  @observable selectedWorkFlow: string = "0";
  @observable selectedMarketer: string = "";
  @observable selectedSubmission: string = "";
  @observable notes: string = "";
  @observable documentOption: string = "";
  @observable fileData: any = null;
  @observable fileName: string = "";
  @observable autoFocus: boolean = false;
  @observable isEnterIntoWorkflowVisible: boolean = true;
  @observable uploadedFileName: string = "";
  @observable isAcceptFileSizeLimit: boolean = true;
  @observable isAcceptFileSize: boolean = false;
  @observable securityLevel: string = "0";

  @observable isAgentDocFromWorkFlow: Boolean = false;
  @observable uploadedFileSize: number = 0;
  @observable public ErrorModel = new ErrorModel(new AgentDocumentValidator());

  @observable isExistingFile: Boolean = false;
  @observable ExistingFileName: any = null;
  @observable ExistingfileData: any = null;

  listener: AddDocumentListener | undefined;
  setListener(listener: AddDocumentListener) {
    this.listener = listener;
  }
  @observable secLevelData = [
    { label: "Public", value: "0" },
    { label: "Private", value: "1" },
  ];
  @action setSecurityLevel(value: string) {
    this.securityLevel = value;
  }

  @action validatePermissions = () => {
    if (!Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments)) {
      this.removeTypesFromList("Private");
    }
  };
  @action removeTypesFromList = (labelValue: string) => {
    var res = this.secLevelData.find(
      (x) => x.label.toLowerCase() === labelValue.toLowerCase()
    );
    if (res) {
      this.secLevelData.forEach((item, index) => {
        if (item === res) this.secLevelData.splice(index, 1);
      });
    }
  };

  @action checkUploadedFileSizeAccepted = async () => {
    if (
      this.documentOption &&
      this.documentOption == "Submit To" &&
      !this.isAcceptFileSizeLimit &&
      this.selectedSubmission &&
      this.selectedSubmission != "0" &&
      this.selectedSubmission.split("|").shift() == "0"
    )
      this.isAcceptFileSize = true;
    else this.isAcceptFileSize = false;
  };

  @action enterIntoWorkflowVisible = async (filename: string | undefined) => {
    if (
      filename &&
      filename.split(".").pop().toLowerCase() != "pdf" &&
      (this.selectedType === "9" ||
        this.selectedType === "42" ||
        this.selectedType === "43" ||
        this.selectedType === "14" ||
        this.selectedType === "31" ||
        this.selectedTypeText === "W-9")
    ) {
      this.isEnterIntoWorkflowVisible = false;
      this.documentOption = "";
    } else {
      this.isEnterIntoWorkflowVisible = true;
    }
  };

  @action loadAllLookupList = async () => {
    this.workFlowList = [];
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    var defaultItem = { label: "Select", value: "0" };
    try {
      var result;
      if (this.lookupDocApiData == "" || this.lookupDocApiData == null) {
        result = await DocumentStore.getAllLookupList(this.agentId, 0);
        this.lookupDocApiData = result;
      } else {
        result = this.lookupDocApiData;
      }
      if (result != null) {
        if (result.companyList != null) {
          this.companyList.values = [];
          result.companyList.forEach((obj: any) => {
            var data = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.companyList.values.push(data);
          });
          this.companyList.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
        } else {
          this.companyList.values = [];
          this.companyList.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
        }

        if (result.documentTypeList != null) {
          this.documentTypeList.values = [];
          this.selectedType = "";
          this.selectedTypeText = "";
          result.documentTypeList.forEach((obj: any) => {
            var documentData = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.documentTypeList.values.push(documentData);
          });

          this.selectedType = this.documentTypeList.values[0].value;
          this.selectedTypeText = this.documentTypeList.values[0].label;
        } else {
          this.documentTypeList.values = [];
          this.selectedType = "";
          this.selectedTypeText = "";
        }
        if (this.docId > 0) {
          var editResult = await DocumentStore.editDocument(this.docId);
          if (editResult && editResult != null && editResult.fileBytes) {
            // Company
            if (editResult.companyList != null) {
              this.companyList.values = [];
              editResult.companyList.forEach((obj: any) => {
                var data = {
                  label: obj.text ? obj.text : "",
                  value: obj.value ? obj.value : "",
                };
                this.companyList.values.push(data);
              });
              this.companyList.values.splice(0, 0, defaultItem);
              this.selectedCompany = this.companyList.values[0].value;
            } else {
              this.companyList.values = [];
              this.companyList.values.splice(0, 0, defaultItem);
              this.selectedCompany = "0";
            }

            // Type
            if (editResult.documentTypeList != null) {
              this.documentTypeList.values = [];
              this.selectedType = "";
              this.selectedTypeText = "";
              editResult.documentTypeList.forEach((obj: any) => {
                var documentData = {
                  label: obj.text ? obj.text : "",
                  value: obj.value ? obj.value : "",
                };
                this.documentTypeList.values.push(documentData);
              });

              this.selectedType = this.documentTypeList.values[0].value;
              this.selectedTypeText = this.documentTypeList.values[0].label;
            } else {
              this.documentTypeList.values = [];
              this.selectedType = "";
              this.selectedTypeText = "";
            }

            if (editResult && editResult.typeId && editResult.typeId > 0) {
              let doctype = this.documentTypeList.values.filter(i => { return i.value == editResult.typeId.toString() });
              this.selectedTypeText = doctype && doctype.length > 0 ? doctype[0].label : this.selectedTypeText;
              this.isEnterIntoWorkflowVisible = true;
            }

            this.selectedCompany =
              editResult.companyId !== null &&
              editResult.companyId !== undefined
                ? editResult.companyId.toString()
                : "0";
            this.selectedType =
              editResult.typeId !== null && editResult.typeId !== undefined
                ? editResult.typeId.toString()
                : "0";
            this.notes =
              editResult.notes !== null && editResult.notes !== undefined
                ? editResult.notes.toString()
                : " ";
            this.docId =
              editResult.id !== null && editResult.id !== undefined
                ? parseInt(editResult.id.toString())
                : 0;
            this.isExistingFile = true;
            this.ExistingFileName = editResult.fileName;
            this.ExistingfileData = `data:application/pdf;base64,${editResult.fileBytes}`;
            this.fileData = this.ExistingfileData;
            this.fileName = this.ExistingFileName;
            this.securityLevel = editResult.private ? "1" : "0";
            this.disableUpload = this.ExistingFileName ? false : true;
            this.getWorkFlowList(
              this.agentId,
              parseInt(this.selectedType),
              parseInt(this.selectedCompany)
            );
            this.getSubmissionList(
              this.agentId,
              parseInt(this.selectedType),
              parseInt(this.selectedCompany)
            );
            // workflow
            if (editResult.workflowList != null) {
              this.workFlowList = [];
              this.selectedWorkFlow = "0";
              if (
                this.selectedCompany !== "0" &&
                this.selectedType !== "0" &&
                (this.selectedType === "9" ||
                  this.selectedType === "42" ||
                  this.selectedType === "43" ||
                  this.selectedType === "14" ||
                  this.selectedType === "31" ||
                  this.selectedTypeText === "W-9")
              ) {
                editResult.workflowList.forEach((obj: any) => {
                  var data = {
                    label: obj.name ? obj.name : "",
                    value: obj.id ? obj.id : "",
                  };
                  this.workFlowList.push(data);
                });
                this.selectedWorkFlow = "0";
                var workFlowDocs = this.workFlowList.filter((obj: any) => {
                  return obj.value === this.docId;
                });
                if (workFlowDocs.length > 0 && this.isAgentDocFromWorkFlow) {
                  this.selectedWorkFlow = workFlowDocs[0].value || "0";
                  this.isEnterIntoWorkflowVisible = false;
                  this.documentOption = "";
                }
              }
            } else {
              this.workFlowList = [];
              this.selectedWorkFlow = "0";
            }

            // Submission List
            if (
              editResult.submissionTypes &&
              editResult.submissionTypes.length > 0
            ) {
              this.submissionList.values = [];
              if (
                this.selectedCompany !== "0" &&
                this.selectedType !== "0" &&
                (this.selectedType === "9" ||
                  this.selectedType === "42" ||
                  this.selectedType === "43" ||
                  this.selectedType === "14" ||
                  this.selectedType === "31" ||
                  this.selectedTypeText === "W-9")
              ) {
                this.submissionList.values = this.mapListItemAndSort(
                  editResult.submissionTypes || []
                );
                this.selectedSubmission = "0";
                if (
                  editResult.submissionTypes &&
                  editResult.submissionTypes.length > 0
                ) {
                  var selected = editResult.submissionTypes.filter(
                    (item: any) => {
                      return item.selected === true;
                    }
                  );
                  if (selected && selected.length > 0) {
                    this.selectedSubmission = selected[0].value || "0";
                  }
                }
                this.checkUploadedFileSizeAccepted();
              } else {
                this.submissionList.values = [];
                this.submissionList.values.splice(0, 0, defaultItem);
                this.selectedSubmission = "0";
              }
            }

            if (editResult.fileBytes && editResult.fileBytes.length > 0) {
              this.uploadedFileSize = parseFloat(
                (editResult.fileBytes.length / 1400240).toFixed(2)
              );
              if (this.uploadedFileSize >= 31 && editResult.fileType == 0) {
              }
            }
            if (this.fileName && this.fileName.split(".").pop()) {
              this.enterIntoWorkflowVisible(this.fileName);
            }
            let documentOptions =
              editResult.submitToOption !== null &&
              editResult.submitToOption !== undefined
                ? parseInt(editResult.submitToOption.toString())
                : 0;
            this.documentOption =
              documentOptions === AgentDocumentOptionValues.SubmitTo
                ? AgentDocumentOptions.SubmitTo
                : documentOptions ===
                  AgentDocumentOptionValues.DontSendJustAttach
                ? AgentDocumentOptions.DontSendJustAttach
                : documentOptions ===
                  AgentDocumentOptionValues.EnterIntoWorkflowScrub
                ? AgentDocumentOptions.EnterIntoWorkflowScrub
                : "";
            let documentOptionValue =
              editResult.submitToValue !== null &&
              editResult.submitToValue !== undefined
                ? editResult.submitToValue.toString()
                : "";
            //setTimeout(() => {
            if (
              documentOptionValue != "" &&
              this.submissionList.values &&
              this.submissionList.values.length > 0
            ) {
              var selected = this.submissionList.values.filter((item: any) => {
                return item.value === documentOptionValue;
              });
              if (selected && selected.length > 0) {
                this.selectedSubmission = selected[0].value || "0";
              } else {
                this.selectedSubmission = "0";
              }
            } else {
              this.selectedSubmission = "0";
            }
            //}, 1500);
          }
        }
      }
    } catch (e) {
      this.documentTypeList.values = [];

      this.companyList.values = [];
      this.companyList.values.splice(0, 0, defaultItem);
      this.selectedCompany = "0";

      this.workFlowList = [];

      this.marketerList.values = [];
      this.marketerList.values.splice(0, 0, defaultItem);
      this.selectedMarketer = "0";

      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action getWorkFlowList = async (
    agentId: number,
    documentTypeId: number,
    companyId: number
  ) => {
    if (
      this.selectedCompany !== "0" &&
      this.selectedType !== "0" &&
      (this.selectedType === "9" ||
        this.selectedType === "42" ||
        this.selectedType === "43" ||
        this.selectedType === "14" ||
        this.selectedType === "31" ||
        this.selectedTypeText === "W-9")
    ) {
      try {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var result = await DocumentStore.getWorkFlowList(
          agentId,
          documentTypeId,
          companyId
        );
        if (result != null) {
          this.workFlowList = [];
          result.forEach((obj: any) => {
            var data = {
              label: obj.name ? obj.name : "",
              value: obj.id ? obj.id : "",
            };
            this.workFlowList.push(data);
          });
          

          this.selectedWorkFlow = "0";
          var workFlowDocs = this.workFlowList.filter((obj: any) => {
            return obj.value === this.docId;
          });
          if (workFlowDocs.length > 0 && this.isAgentDocFromWorkFlow) {
            this.selectedWorkFlow = workFlowDocs[0].value || "0";
            
            this.isEnterIntoWorkflowVisible = false;
            this.documentOption = "";
          }
        } else {
          this.workFlowList = [];
          this.selectedWorkFlow = "0";
        }
      } catch (e) {
        this.workFlowList = [];
        this.selectedWorkFlow = "0";
        this.isMessgeVisible = true;
        this.response = e.response;
      }
      this.isLoading = false;
    }
  };

  @action getSubmissionList = async (
    agentId: number,
    documentTypeId: number,
    companyId: number
  ) => {
    this.submissionList.values = [];
    if (
      this.selectedCompany !== "0" &&
      this.selectedType !== "0" &&
      (this.selectedType === "9" ||
        this.selectedType === "42" ||
        this.selectedType === "43" ||
        this.selectedType === "14" ||
        this.selectedType === "31" ||
        this.selectedTypeText === "W-9")
    ) {
      try {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "Select", value: "0" };

        var result =
          await BusinessUnitConfigStore.getSubmissionTypesByCarrierId(
            agentId,
            documentTypeId,
            companyId
          );
        if (result != null) {
          if (result != null) {
            this.submissionList.values = [];
            this.submissionList.values = this.mapListItemAndSort(result || []);
            this.selectedSubmission = "0";
            if (result && result.length > 0) {
              var selected = result.filter((item: any) => {
                return item.selected === true;
              });
              if (selected && selected.length > 0) {
                this.selectedSubmission = selected[0].value || "0";
              }
            }
          } else {
            this.submissionList.values = [];
            this.submissionList.values.splice(0, 0, defaultItem);
            this.selectedSubmission = "0";
          }
        } else {
          this.submissionList.values = [];
          this.submissionList.values.splice(0, 0, defaultItem);
          this.selectedSubmission = "0";
        }
        this.checkUploadedFileSizeAccepted();
      } catch (e) {
        this.submissionList.values = [];
        this.selectedSubmission = "0";
        this.isMessgeVisible = true;
        this.response = e.response;
      }
      this.isLoading = false;
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      listObjects.splice(0, 0, { text: "Select", value: "0", selected: false });
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action setSelectedType = (value: string) => {
    this.selectedType = value;
    var typeselected = this.documentTypeList.values.filter((item: any) => {
      return item.value == value;
    });
    if (typeselected && typeselected.length > 0) {
      this.selectedTypeText = typeselected[0].label;
    }
    this.getWorkFlowList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
    this.getSubmissionList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
    this.enterIntoWorkflowVisible(this.uploadedFileName);
  };

  @action setSelectedCompany = (value: string) => {
    this.selectedCompany = value;
    this.getWorkFlowList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
    this.getSubmissionList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
  };

  @action setSelectedMarketer = (value: string) => {
    this.selectedMarketer = value;
  };

  @action setSelectedSubmission = (value: string) => {
    this.selectedSubmission = value;
  };

  @action setSelectedWorkFlow = (value: string) => {
    this.selectedWorkFlow = value;
    
    if (value == "0" && this.fileName == "") {
      this.disableUpload = true;
      this.isEnterIntoWorkflowVisible = true;
    } else if (value == "0" && (this.fileName != "" || this.fileName != null)) {
      this.disableUpload = false;
      if (this.fileName && this.fileName.split(".").pop()) {
        this.enterIntoWorkflowVisible(this.fileName);
      }
    } else if (value != "0" && (this.fileName != "" || this.fileName != null)) {
      this.isEnterIntoWorkflowVisible = false;
      this.disableUpload = false;
    } else if (value != "0" && (this.fileName == "" || this.fileName == null)) {
      this.isEnterIntoWorkflowVisible = false;
      this.disableUpload = false;
    }
  };
  @action setNotes = (value: string) => {
    this.notes = value;
    this.disableFooter = false;
  };
  @action setDocumentOption = (value: string) => {
    this.documentOption = value;
  };
  @action goBack() {
    this.isAcceptFileSize = false;
    this.isAcceptFileSizeLimit = true;
    routes.agentDocs.replace({ agentId: "" + this.agentId });
  }

  onDrop = (value: any, fileName: string) => {
    this.fileData = value;
    this.fileName = fileName;
    this.disableWorkFlowRadio = false;
  };
  @action readURL(acceptedFiles: any) {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = (_event) => {
        this.fileData = reader.result;
      };
    }
  }
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @action CreateUpdateDocumentModel() {
    var dto: any = {
      id: this.docId ? this.docId : this.selectedWorkFlow ? parseInt(this.selectedWorkFlow) : 0,
      fName: "",
      lName: "",
      agentId: this.agentId,
      typeId: parseInt(this.selectedType),
      companyId: parseInt(this.selectedCompany),
      notes: this.notes,
      isPrivate: this.securityLevel == "0" ? false : true,
      createdDate: new Date(),
      fileData: this.fileData,
      licenseStatusId: 0,
      isWorkflowChecked:
        this.documentOption === "Enter Into Workflow (Scrub)" ? true : false,
      isSendSubmission: this.documentOption === "Submit To" ? true : false,
      isDontSend:
        this.documentOption === "Don't Send, Just attach" ? true : false,
      ignoreForAgeCalculation: false,
      submissionTypeIds: this.selectedSubmission,
      fileName: this.fileName,
    };
    return dto;
  }

  @action hideValidationToaster = () => {
    toast.dismiss("validation-toaster");
  };

  @observable timer: any;
  @action SaveDocument = async (dto: any) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      if (dto.isSendSubmission && dto.id > 0) {
        googleTagManager.trackAction("workflow_doc_finalized_and_sent", {
          feature: "AgentDocs",
          user_id: UserContext.userId,
          carrier_id: dto.companyId,
          document_id: dto.id,
          transmission_method: "",
        });
      }
      this.docId > 0
        ? await DocumentStore.UpdateDocument(dto)
        : await DocumentStore.CreateUpdateDocument(dto);
      
      this.isMessgeVisible = false;
      localStorage.removeItem("SubmitFromWorkFlowDocId");

      if (dto.isWorkflowChecked) {
        if (this.listener) {
          this.listener.documentAddedToWorkflow();
        }
      }
      Utils.showSuccessToaster(
        `Document ${this.docId > 0 ? "updated" : "added"} successfully.`,
        7000,
        500
      );
      this.disableFooter = false;
      this.isLoading = false;
      this.loadDocs(true);
      this.addModalVisibility(false);
    } catch (e: any) {      
      e.text().then((_responseText: any) => {
        let _title = JSON.parse(_responseText).title;
        this.showErrorMessageinToaster(`${_title}`);
      });
      this.disableFooter = false;
      this.isLoading = false;
      PageContext.setIsMessageVisible(false);
    }
  };

  showErrorMessageinToaster(message: string) {
    toast.warning(message, {
      position: toast.POSITION.TOP_RIGHT,
      closeOnClick: false,
      closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
      className: "validation-toaster contract",
      draggable: false,
      toastId: "validation-toaster",
      autoClose: 7000,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.hideValidationToaster();
    }, 7000);
  }

  showLongFileNameError() {
    this.isMessgeVisible = false;
    this.response = {};
    var responseModel = {
      status: 400,
      title: Constants.LongFileName,
      errors: { "": [] },
    };
    this.isMessgeVisible = true;
    PageContext.setIsMessageVisible(false);
    this.response = JSON.stringify(responseModel);
  }
  @action
  public onSave = async () => {
    this.disableFooter = true;
    localStorage.removeItem("SubmitFromWorkFlowDocId");
    await this.ResetValidate();
    if (!(await this.Validate())) {
      var dto = this.CreateUpdateDocumentModel();
      this.isMessgeVisible = false;
      this.response = {};
      // var responseModel = {
      //     status: 400,
      //     title: Constants.NoFile,
      //     errors: { "": [] },
      // };

      if (
        (dto.fileData === "" ||
          dto.fileData === undefined ||
          dto.fileData === null) &&
        (dto.id === 0 ||
          isNaN(dto.id) ||
          dto.id === undefined ||
          dto.id === null)
      ) {
        //this.isMessgeVisible = false;
        PageContext.setIsMessageVisible(false);
        // this.response = JSON.stringify(responseModel);
        this.showErrorMessageinToaster(`${Constants.NoFile}`);
        return false;
      }

      if (
        this.selectedWorkFlow !="0" &&
        dto.id !== 0 &&
        dto.id !== undefined &&
        this.documentOption !== "" &&
        this.documentOption !== null &&
        this.documentOption !== undefined &&
        this.documentOption === "Enter Into Workflow (Scrub)"
      ) {
        
        this.showErrorMessageinToaster(`${Constants.AlreadyExistInWorkFLow}`);
        PageContext.setIsMessageVisible(false);
        return false;
      }

      if (
        dto.isSendDocViaEmail === true &&
        (this.selectedMarketer === null ||
          this.selectedMarketer === "" ||
          this.selectedMarketer === "0" ||
          this.selectedMarketer === undefined)
      ) {
        // responseModel = {
        //     status: 400,
        //     title: Constants.NoFaxEmailSelection,
        //     errors: { "": [] },
        // };
        // this.response = JSON.stringify(responseModel);
        //this.isMessgeVisible = false;
        this.showErrorMessageinToaster(`${Constants.NoFaxEmailSelection}`);
        PageContext.setIsMessageVisible(false);
        return false;
      }

      this.SaveDocument(dto);
    }
  };

  @action addModalVisibility = async (value: boolean) => {
    !value && localStorage.removeItem("SubmitFromWorkFlowDocId");
    this.isAddModal = value;
    this.isExistingFile = false;
    if (value && this.docId > 0) {
      this.isAgentDocFromWorkFlow =
        localStorage.getItem("SumitFromWorkFlow") !== null &&
        localStorage.getItem("SumitFromWorkFlow") === "Sumit"
          ? true
          : false;
      if (this.isAgentDocFromWorkFlow) {
        this.documentOption = "Submit To";
      }
    } else {
      this.disableUpload = true;
    }
    if (value) {
      this.selectedWorkFlow = "0";
      this.selectedMarketer = "0";
      this.selectedSubmission = "0";
      this.securityLevel = "0";
      this.notes = "";
      this.documentOption = "";
      this.fileData = null;
      this.workFlowList = [];
      await this.ResetValidate();
      this.validatePermissions();
      await this.loadAllLookupList();
      this.autoFocus = true;
      var defaultItem = { label: "Select", value: "0" };
      this.submissionList.values = [];
      this.submissionList.values.splice(0, 0, defaultItem);
      this.selectedSubmission = "0";
    } else {
      this.docId = 0;
      this.fileData = null;
      this.fileName = "";
      this.ExistingFileName = "";
      this.disableWorkFlowRadio = false;
      this.disableFooter = false;
      localStorage.removeItem("SumitFromWorkFlow");
    }
  };

  //
  // add doc method end

  //
}

export interface AddDocumentListener {
  documentAddedToWorkflow(): void;
}

class AgentDocumentValidator extends AbstractValidator<AgentDocsViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Type is required");

    this.validateIfString((input) => input.selectedSubmission)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Submit To is required")
      .when((input) => input.documentOption === "Submit To");
  }
}
