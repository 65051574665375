import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import Collection from "../../../infrastructure/CollectionHelper";
import ProducerStore from "../../../store/ProducerStore";
import {
  PhonePreferenceModel,
  UpdatePhonePreferenceModel,
} from "../../../services/ProducerService";

export class PhoneSubscriptionViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable agentId: number = 0;
  @action Load = async (id: number) => {
    this.agentId = id;
    await this.resetPhoneData();
    await this.getAllPhonePreferences();
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable phoneNumber: string = "";
  @observable phoneDetails = new Collection<any>();
  @observable phoneData = new Collection<PhonePreferenceModel>();
  @observable enableSave: boolean = false;
  @observable associatedProfileCount: number = 0;
  @observable showPhoneDetails: boolean = false;
  @observable phoneSubscribeStatus = new Collection<any>();
  @observable phoneSubscribeStatusChanged = new Collection<any>();

  @action resetPhoneData = async () => {
    this.phoneNumber = "";
    this.phoneData.values = [];
    this.phoneDetails.values = [];
    this.phoneSubscribeStatus.values = [];
    this.phoneSubscribeStatusChanged.values = [];
    this.showPhoneDetails = false;
    this.setEnableSave(false);
  };

  @action clearAll = async () => {
    this.phoneNumber = "";
    this.phoneDetails.values = [];
    this.phoneSubscribeStatus.values = [];
    this.phoneSubscribeStatusChanged.values = [];
    this.showPhoneDetails = false;
    this.setEnableSave(false);
  };

  @action activeChange = (value: boolean, rowData: PhonePreferenceModel) => {
    let newValues: any[] = [];
    this.phoneData.values.forEach((obj) => {
      if (obj.phoneNumber === rowData.phoneNumber) {
        obj.isPhoneUnsubscribed = !value;
      }
      newValues.push(obj);
    });
    this.phoneSubscribeStatusChanged.values = [];
    this.phoneData.values.filter((i) => {
      if (i.associatedProfileCount && i.associatedProfileCount > 0) {
        this.phoneSubscribeStatusChanged.values.push(i.isPhoneUnsubscribed);
      }
    });
    this.phoneData.values = [];
    if (rowData.associatedProfileCount && rowData.associatedProfileCount > 0) {
      this.setEnableSave(
        JSON.stringify(this.phoneSubscribeStatus.values) !=
          JSON.stringify(this.phoneSubscribeStatusChanged.values)
      );
    }
    this.phoneData.values = newValues;
  };

  @action cancel = () => {
    this.resetPhoneData();
  };

  @action save = async () => {
    const dto = await this.serviceModel();
    await ProducerStore.updatePhonePreference(dto);
    await this.cancel();
  };

  @action serviceModel = async () => {
    let sm: UpdatePhonePreferenceModel = {
      id: 0,
      phoneUnsubscribeModel: await this.getPhoneUnsubscribeModel(),
    };
    return sm;
  };

  @action getPhoneUnsubscribeModel = async () => {
    let model: any = [];
    if (this.phoneData.values.length > 0) {
      this.phoneData.values.forEach((obj: any) => {
        model.push({
          phoneNumber: obj.phoneNumber,
          isPhoneUnsubscribed: obj.isPhoneUnsubscribed,
        });
      });
    }
    return model;
  };

  @action setEnableSave = (value: boolean) => {
    this.enableSave = value;
  };

  @action getAllPhonePreferences = async () => {
    const result = await ProducerStore.getAllPhonePreferences(this.agentId);
    if (result && result.data) {
      this.phoneData.values = result.data;
      this.phoneSubscribeStatus.values = [];
      this.phoneData.values.filter((i) => {
        if (i.associatedProfileCount && i.associatedProfileCount > 0) {
          this.phoneSubscribeStatus.values.push(i.isPhoneUnsubscribed);
        }
      });
    } else {
      this.phoneData.values = [];
    }
  };

  @action getPhoneNumberUSformat = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumber;
  };
}
