import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import { routes } from "../../router";
import LeadStore from "../../store/LeadStore";
import {
  SalesOpsUnmatchedLeadResponse,
  UnMatchedLeadDetailModel,
  UnMatchedLeadModel,
} from "../../services/LeadService";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import { MainLeadTabs, SalesLeadTabs } from "../../infrastructure/enum/Leads";
import { Permission } from "../../infrastructure/enum/Permission";
import { Constants } from "../../infrastructure/enum/Constants";

export interface UnMatchLeadModel {
  agentLeadId?: number;
  agentId?: string;
  leadAgent?: string;
  assigneeId?: string;
  assignee?: string;
  subAssigneeId?: string;
  subAssignee?: string;
  agentLeadSourceId?: string;
  agentLeadSource?: string;
  assignerId?: string;
  productLineId?: string;
  productLine?: string;
  carrierId?: string;
  carrier?: string;
  leadPhaseId?: string;
  leadPhase?: string;
  leadPhaseDetailId?: string;
  leadPhaseDetail?: string;
  dateCreated?: Date;
  dateFollowUp?: Date;
  leadPhone?: string;
  leadEmail?: string;
  leadNote?: string;
  leadNPN?: string;
  leadName?: string;
  targetCarrier?: string;
  unverifiedLeadPhone?: string;
  unverifiedLeadEmail?: string;
  stage?: string;
  isRecordUpdated?: boolean;
  isValid?: boolean;
}
export class UnmatchedLeadListViewModel implements ViewModel {
  @observable leadSourceList = [{ label: "ALL", value: "0" }];
  @observable assigneeList = [{ label: "", value: "0" }];
  @observable subAssigneeList = [{ label: "", value: "0" }];

  @observable productLineList = [{ label: "", value: "0" }];
  @observable phaseList = [{ label: "", value: "0" }];
  @observable detailList = [{ label: "", value: "0" }];
  @observable UnMatchedLeadsLists = new Collection<UnMatchLeadModel>();
  @observable isValid?: boolean = true;
  @observable selectedTabIndex?: number = 1;
  @observable selectedMainTabIndex?: number = 1;
  @observable selectedLeadSource: string = "0";
  @observable UnMatchedLeadSearchReponse:
    | SalesOpsUnmatchedLeadResponse
    | undefined = undefined;
  @observable isUpdateRecord: boolean = false;
  @observable hasPermission: boolean = true;
  @observable selectedPanel: string = SalesLeadTabs.SaleOpsLeads;
  @observable isCollapsed: boolean = true;
  @observable sortColumn: string = "AgentLeadId";
  @observable sortOrder: boolean = false;
  @observable endDate: Date | undefined = undefined;
  @observable startDate: Date | undefined = undefined;
  @observable startViewDate: Date | undefined = undefined;
  @observable endViewDate: Date | undefined = undefined;
  @observable LeadsMainTabItems: TabItems[] = [];
  @observable LeadsSubTabItems: SubTabItems[] = [];
  @observable isLoading: boolean = false;
  private paramLeadSource: number | undefined;
  @observable rows: number = 10;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable private loading = false;
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @observable isLeadsDetailDialogVisible: boolean = false;
  @observable selectedLeadId: number = 0;
  @observable leadDetail: UnMatchedLeadDetailModel | undefined;
  private onClose?: () => void;
  Route = async (): Promise<void> => {};
  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  @action public Load = async (OnClose?: () => void): Promise<void> => {
    this.selectedTabIndex = 1;
    this.selectedMainTabIndex = 1;
    let delay = 0;
    this.getLeadsMainTabITem();
    this.getLeadsSubTabITem();
    if (UserContext.permissions.length <= 0) {
      delay = 2000;
    }
    setTimeout(async () => {
      this.isLoading = true;
      this.onClose = OnClose;
      await this.loadDropdowns();
      await this.goSearch();
      this.isLoading = false;

      if (
        UserContext.permissions.length > 0 &&
        !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
      ) {
        this.hasPermission = false;
      } else {
        this.hasPermission = true;
      }
    }, delay);
  };
  @action loadDropdowns = async () => {
    this.loading = true;

    this.phaseList = [];
    this.detailList = [];
    this.phaseList.push(
      { label: "Unmatched", value: "7" },
      { label: "Closed/Unmatched", value: "10" }
    );
    this.detailList.push(
      { label: "No Action", value: "7" },
      { label: "Duplicate", value: "39" },
      { label: "Invalid Lead", value: "38" },
      { label: "Unsubscribe", value: "37" }
    );
    var response = await LeadStore.getUnmatchedValues();

    if (response && response.assignee && this.assigneeList.length === 1) {
      var assigneelist = [...response.assignee].sort(this.sortTypeName);
      for (let item of assigneelist) {
        this.assigneeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }
    if (response && response.subAssignee && this.subAssigneeList.length === 1) {
      var subassigneelist = [...response.subAssignee].sort(this.sortTypeName);

      for (let item of subassigneelist) {
        this.subAssigneeList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }
    if (response && response.productLine && this.productLineList.length === 1) {
      for (let item of response.productLine) {
        this.productLineList.push({
          label: "" + item.productLineName,
          value: "" + item.id,
        });
      }
    }

    if (response && response.leadSource && this.leadSourceList.length === 1) {
      var leadSourceList = [...response.leadSource].sort(this.sortTypeName);
      for (let item of leadSourceList) {
        this.leadSourceList.push({
          label: "" + item.text,
          value: "" + item.value,
        });
      }
    }

    this.loading = false;
  };
  @action loadMarketerLeads = async () => {
    if (this.selectedLeadSource !== "0")
      this.paramLeadSource = parseInt(this.selectedLeadSource);
    else this.paramLeadSource = undefined;
    await this.loadUnmatchLeads();
  };
  private async loadUnmatchLeads() {
    this.isLoading = true;
    this.UnMatchedLeadSearchReponse = await LeadStore.getUnmatchedLeads(
      undefined,
      this.paramLeadSource,
      this.startDate,
      this.endDate,
      undefined,
      undefined,
      this.startIndex,
      this.rows,
      this.sortColumn,
      this.sortOrder
    );
    this.UnMatchedLeadsLists.values = [];
    if (
      this.UnMatchedLeadSearchReponse &&
      this.UnMatchedLeadSearchReponse.data
    ) {
      let newValues: UnMatchLeadModel[] = [];
      this.UnMatchedLeadSearchReponse.data.forEach((obj) => {
        let item: UnMatchLeadModel = {
          agentId: obj.agentId ? "" + obj.agentId : undefined,
          agentLeadId: obj.agentLeadId ? obj.agentLeadId : undefined,
          leadAgent: obj.leadAgent ? "" + obj.leadAgent : undefined,
          assigneeId: obj.assigneeId ? "" + obj.assigneeId : undefined,
          assignee: obj.assignee ? "" + obj.assignee : undefined,
          subAssigneeId: obj.subAssigneeId ? "" + obj.subAssigneeId : undefined,
          subAssignee: obj.subAssignee ? "" + obj.subAssignee : undefined,
          agentLeadSourceId: obj.agentLeadSourceId
            ? "" + obj.agentLeadSourceId
            : undefined,
          agentLeadSource: obj.agentLeadSource
            ? "" + obj.agentLeadSource
            : undefined,
          assignerId: obj.assignerId ? "" + obj.assignerId : undefined,
          productLineId: obj.productLineId ? "" + obj.productLineId : undefined,
          productLine: obj.productLine ? "" + obj.productLine : undefined,
          carrierId: obj.carrierId ? "" + obj.carrierId : undefined,
          carrier: obj.carrier ? "" + obj.carrier : undefined,
          leadPhaseId: obj.leadPhaseId ? "" + obj.leadPhaseId : undefined,
          leadPhase: obj.leadPhase ? "" + obj.leadPhase : undefined,
          leadPhaseDetailId: obj.leadPhaseDetailId
            ? "" + obj.leadPhaseDetailId
            : undefined,
          leadPhaseDetail: obj.leadPhaseDetail
            ? "" + obj.leadPhaseDetail
            : undefined,
          dateCreated: obj.dateCreated,
          dateFollowUp: obj.dateFollowUp,
          leadPhone: obj.leadPhone ? "" + obj.leadPhone : undefined,
          leadEmail: obj.leadEmail ? "" + obj.leadEmail : undefined,
          leadNote: obj.leadNote ? "" + obj.leadNote : undefined,
          leadNPN: obj.leadNPN ? "" + obj.leadNPN : undefined,
          leadName: obj.leadName ? "" + obj.leadName : undefined,
          targetCarrier: obj.targetCarrier ? "" + obj.targetCarrier : undefined,
          unverifiedLeadPhone: obj.unverifiedLeadPhone
            ? "" + obj.unverifiedLeadPhone
            : undefined,
          unverifiedLeadEmail: obj.unverifiedLeadEmail
            ? "" + obj.unverifiedLeadEmail
            : undefined,
          stage: obj.stage ? "" + obj.stage : undefined,
          isRecordUpdated: false,
          isValid: true,
        };
        newValues.push(item);
      });
      this.UnMatchedLeadsLists.values = newValues;
    }
    this.isLoading = false;
  }

  @action getLeadDetail = async (leadId: number) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    this.actionTitle = "";
    try {
      let result = await LeadStore.getUnmatchedLeadDetail(leadId);
      if (result != null) {
        if (result) {
          this.leadDetail = result;
          this.actionTitle =
            this.leadDetail.leadName + "( " + this.selectedLeadId + " )";
        } else {
          this.leadDetail = undefined;
          this.actionTitle = "Details";
        }
      }
    } catch (e) {
      this.leadDetail = undefined;
      this.actionTitle = "Details";
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action getLeadsMainTabITem = () => {
    if (
      UserContext.permissions.length > 0 &&
      !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
    ) {
      this.hasPermission = false;
    } else {
      this.hasPermission = true;
    }
    this.LeadsMainTabItems = [];
    let a = { label: MainLeadTabs.MarketerLeads, value: 0 };
    this.LeadsMainTabItems.push(a);
    if (this.hasPermission) {
      let b = { label: MainLeadTabs.SaleOpsLead, value: 1 };
      this.LeadsMainTabItems.push(b);
    }
  };
  @action getLeadsSubTabITem = () => {
    this.LeadsSubTabItems = [];
    let a = { label: SalesLeadTabs.SaleOpsLeads, value: 0 };
    let b = { label: SalesLeadTabs.UnmatchedLeads, value: 1 };
    this.LeadsSubTabItems.push(a);
    this.LeadsSubTabItems.push(b);
  };
  @action handleTabClick = (index: number | undefined): void => {
    this.selectedTabIndex = index;

    if (index === 0) {
      Utils.setLeadsTab(2);
      routes.salesLeads.push();
    } else {
      Utils.setLeadsTab(3);
    }
  };
  @action handleMainTabClick = (index: number | undefined): void => {
    if (index === 0) {
      Utils.setLeadsTab(0);
      routes.leads.push();
    } else {
      Utils.setLeadsTab(2);
      routes.salesLeads.push();
    }
  };
  @action setSelectedPanel(value: string) {
    this.selectedPanel = value;
  }
  @action setIsCollapsed(value: boolean) {
    this.isCollapsed = value;
  }
  @observable actionTitle: string = "";
  @action onLeadItemClick(value: number) {
    this.selectedLeadId = value;
    this.isLeadsDetailDialogVisible = true;
    this.leadDetail = undefined;
    this.getLeadDetail(this.selectedLeadId);
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  sortTypeName = (a: any, b: any) => {
    let comparison = 0;
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }

    return comparison;
  };
  @action setEndDate(value: Date | undefined) {
    this.endDate = value;
  }
  @action setStartDate(value: Date | undefined) {
    this.startDate = value;
  }

  @action updateAgentIdValue(value: string, rowData: UnMatchLeadModel) {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.agentId = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  }
  @action updateLeadNPNValue(value: string, rowData: UnMatchLeadModel) {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.leadNPN = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  }
  @action updateLeadEmailValue(value: string, rowData: UnMatchLeadModel) {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.leadEmail = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  }
  @action updateLeadPhoneValue(value: string, rowData: UnMatchLeadModel) {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        var phoneValue = "";
        phoneValue = value
          .replace(/_/g, "")
          .replace(/-/g, "")
          .replace(/\s/g, "")
          .replace(/\(/g, "")
          .replace(/\)/g, "")
          .trim();
        if (this.isNumber(phoneValue) || phoneValue === "") {
          obj.leadPhone = phoneValue;
          obj.isRecordUpdated = true;
        }
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  }
  @action updateLeadNoteValue(value: string, rowData: UnMatchLeadModel) {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.leadNote = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  }
  @action updateLeadAssigneeValue = async (
    value: string,
    rowData: UnMatchLeadModel
  ) => {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.assigneeId = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  };
  @action updateLeadSubAssigneeValue = async (
    value: string,
    rowData: UnMatchLeadModel
  ) => {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.subAssigneeId = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  };
  @action updateProductLineValue = async (
    value: string,
    rowData: UnMatchLeadModel
  ) => {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.productLineId = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  };
  @action updatePhaseValue = async (
    value: string,
    rowData: UnMatchLeadModel
  ) => {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.leadPhaseId = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  };
  @action updatePhaseDetailValue = async (
    value: string,
    rowData: UnMatchLeadModel
  ) => {
    let newValues: UnMatchLeadModel[] = [];
    this.UnMatchedLeadsLists.values.forEach((obj) => {
      if (obj.agentLeadId === rowData.agentLeadId) {
        obj.leadPhaseDetailId = value;
        obj.isRecordUpdated = true;
      }
      newValues.push(obj);
    });
    this.UnMatchedLeadsLists.values = [];
    this.UnMatchedLeadsLists.values = newValues;
  };

  @action onLeadsDetailDialogHide = async () => {
    this.isLeadsDetailDialogVisible = false;
  };

  @action onUpdateUnmatchLeads = async () => {
    this.isValid = true;
    let updatedValues: UnMatchLeadModel[] = [];
    let updatedRecords: UnMatchLeadModel[] = [];

    updatedRecords = this.UnMatchedLeadsLists.values.filter(
      (x) => x.isRecordUpdated === true
    );
    if (updatedRecords.length > 0) {
      this.UnMatchedLeadsLists.values.forEach((obj) => {
        var dto = obj;
        let agentLeadId = dto.agentLeadId;
        // NPN
        let inputLeadNPNId = "inputLeadNPN" + agentLeadId;
        let divLeadNPNId = "divLeadNPN" + agentLeadId;
        let inputLeadNPN = document.getElementById(inputLeadNPNId);
        let divLeadNPN = document.getElementById(divLeadNPNId);
        var isValidNPN = true;

        if (dto.leadNPN && dto.leadNPN.length > 0) {
          if (dto.leadNPN.substring(0, 1) === "0") {
            isValidNPN = false;
          }
        }
        if (isValidNPN) {
          if (inputLeadNPN && divLeadNPN) {
            divLeadNPN.style.display = "none";
            inputLeadNPN.className = "p-inputtext p-component";
          }
        } else {
          if (inputLeadNPN && divLeadNPN) {
            divLeadNPN.style.display = "block";
            inputLeadNPN.className = "p-inputtext p-component p-error";
          }
        }
        // Phone Number

        let inputLeadPhoneId = "inputLeadPhone" + agentLeadId;
        let divLeadPhoneId = "divLeadPhone" + agentLeadId;
        let inputLeadPhone = document.getElementById(inputLeadPhoneId);
        let divLeadPhone = document.getElementById(divLeadPhoneId);
        var isValidPhone = true;
        var phoneValue = "";
        if (dto.leadPhone) {
          phoneValue = dto.leadPhone
            .replace(/_/g, "")
            .replace(/-/g, "")
            .replace(/\s/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .trim();
        }
        if (phoneValue && phoneValue.length > 0 && phoneValue.length < 10) {
          isValidPhone = false;
        }
        if (isValidPhone) {
          if (inputLeadPhone && divLeadPhone) {
            divLeadPhone.style.display = "none";
            inputLeadPhone.className = "p-inputtext p-component";
          }
        } else {
          if (inputLeadPhone && divLeadPhone) {
            divLeadPhone.style.display = "block";
            inputLeadPhone.className = "p-inputtext p-component p-error";
          }
        }

        // Email

        let inputLeadEmailId = "inputLeadEmail" + agentLeadId;
        let divLeadEmailId = "divLeadEmail" + agentLeadId;
        let inputLeadEmail = document.getElementById(inputLeadEmailId);
        let divLeadEmail = document.getElementById(divLeadEmailId);
        var isValidEmail = true;
        var emailValue = dto.leadEmail;
        if (emailValue) {
          const regx =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          isValidEmail = regx.test(emailValue);
        }
        if (isValidEmail) {
          if (inputLeadEmail && divLeadEmail) {
            divLeadEmail.style.display = "none";
            inputLeadEmail.className = "p-inputtext p-component";
          }
        } else {
          if (inputLeadEmail && divLeadEmail) {
            divLeadEmail.style.display = "block";
            inputLeadEmail.className = "p-inputtext p-component p-error";
          }
        }
        if (isValidEmail && isValidPhone && isValidNPN) {
          obj.isValid = true;
        } else {
          obj.isValid = false;
        }

        updatedValues.push(obj);
      });
      this.UnMatchedLeadsLists.values = [];
      this.UnMatchedLeadsLists.values = updatedValues;

      let inValidRecords: UnMatchLeadModel[] = [];
      inValidRecords = this.UnMatchedLeadsLists.values.filter(
        (x) => x.isValid === false
      );
      if (inValidRecords.length > 0) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }

      if (this.isValid) {
        this.openModal(true);
      }
    } else {
      var responseModel = {
        status: 400,
        title: Constants.NoUpdatedRecords,
        errors: { "": [] },
      };
      this.response = JSON.stringify(responseModel);
      window.scrollTo(0, 0);
      this.isMessgeVisible = true;
      setTimeout(() => {
        this.isMessgeVisible = false;
        this.response = "";
      }, 5000);
    }
  };
  @action openModal = (value: boolean) => {
    this.isUpdateRecord = value;
  };
  @action updateLeads = async () => {
    try {
      if (UserContext.getUserId()) {
        let updatedValues: UnMatchLeadModel[] = [];

        updatedValues = this.UnMatchedLeadsLists.values.filter(
          (x) => x.isRecordUpdated === true
        );
        if (updatedValues.length > 0) {
          var unMatchedLeadModel: UnMatchedLeadModel[] = [];
          let newValues: UnMatchedLeadModel[] = [];
          updatedValues.forEach((obj) => {
            let emails: string[] = [];
            let phones: string[] = [];
            emails.push(obj.leadEmail || "");
            phones.push(obj.leadPhone || "");
            let item: UnMatchedLeadModel = {
              agentLeadId: obj.agentLeadId ? obj.agentLeadId : undefined,
              productLineId: obj.productLineId
                ? parseInt(obj.productLineId)
                : undefined,
              assigneeId: obj.assignerId ? parseInt(obj.assignerId) : undefined,
              subAssigneeId: obj.subAssignee
                ? parseInt(obj.subAssignee)
                : undefined,
              agentId: obj.agentId ? parseInt(obj.agentId) : undefined,
              dateAssigned: obj.dateCreated,
              dateFollowUp: obj.dateFollowUp,
              leadPhaseId: obj.leadPhaseId
                ? parseInt(obj.leadPhaseId)
                : undefined,
              leadPhaseDetailId: obj.leadPhaseDetailId
                ? parseInt(obj.leadPhaseDetailId)
                : undefined,
              leadEmails: emails,
              leadPhoneNumbers: phones,
              leadNPN: obj.leadNPN ? "" + obj.leadNPN : undefined,
              leadNote: obj.leadNote ? "" + obj.leadNote : undefined,
              isClosed: true,
            };
            newValues.push(item);
          });
          unMatchedLeadModel = newValues;
          await LeadStore.editSaleOpsUnmatchedLead(
            UserContext.getUserId(),
            unMatchedLeadModel
          );

          this.openModal(false);
          var response = {
            status: 200,
            title:
              unMatchedLeadModel.length > 1
                ? Constants.UpdateRecordsSuccess
                : Constants.UpdateSuccess,
            errors: { "": [] },
          };
          this.response = JSON.stringify(response);
          window.scrollTo(0, 0);
          this.isMessgeVisible = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 5000);
          this.loadUnmatchLeads();
        } else {
          var responseModel = {
            status: 400,
            title: Constants.NoUpdatedRecords,
            errors: { "": [] },
          };
          this.response = JSON.stringify(responseModel);
          window.scrollTo(0, 0);
          this.isMessgeVisible = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 5000);
        }
      }
    } catch (ex) {
      this.openModal(false);
    }
  };
  @action setSelectDate(value: any, type: string) {
    var d = isNaN(new Date(value).getMonth()) === false ? value : undefined;
    if (type === "startDate") {
      this.startDate = d;
    } else {
      this.endDate = d;
    }
  }
  @observable isException: boolean = false;
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action goSearch = async () => {
    this.isException = false;
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.setIsException(true);
    } else {
      await this.loadMarketerLeads();
      this.setIsException(false);
    }
  };

  @action resetAll = () => {
    this.setIsException(false);
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedLeadSource = "0";
    this.rows = 10;
    this.startIndex = 0;
    this.first = 0;
    this.startViewDate = new Date();
    this.endViewDate = new Date();
  };
  @action resetFilters = () => {
    this.resetAll();
    this.goSearch();
  };
  @action goToContracted = async () => {
    routes.contractedLeads.push();
  };
  isNumber = (n: string) => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  };
}
export interface TabItems {
  label: MainLeadTabs;
  value: number;
}
export interface SubTabItems {
  label: SalesLeadTabs;
  value: number;
}
