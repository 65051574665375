import Utils from "../../../infrastructure/Utils";
import { getToken } from "../../../infrastructure/Auth0Authenticator";
import {
  checkIfFeatureIsPresent,
  setFeatureFlagInSessionStorage,
} from "./utils";

const url = Utils.getServiceUrlByEnvironment();
const host = Utils.getHost();

export const getFeatureFlagApi = async () => {
  const myHeaders = new Headers();
  const token = await getToken();
  const bearerToken = token ? "Bearer " + token : "";
  myHeaders.append("Authorization", bearerToken);
  myHeaders.append("hostname", host);

  return await fetch(
    url + "/api/businessunitconfig/v1/GetEnabledFeatures/EnabledFeatures",
    {
      method: "GET",
      headers: myHeaders,
    }
  )
    .then((response) => response.text())
    .then((result) => result)
    .catch(() => new Error("Unable to get feature flag"));
};

export const setFeatureFlagApi = async (
  featureName = "",
  minutesToWaitForNextApiCall = 5
) => {
  const data = await getFeatureFlagApi();
  if (data && typeof data === "string") {
    // prevent api call from executing multiple times a second
    setFeatureFlagInSessionStorage(data, minutesToWaitForNextApiCall);
    const features = JSON.parse(data);
    const isFeaturePresent = checkIfFeatureIsPresent(featureName, features);
    return isFeaturePresent;
  } else {
    const waitOneMinuteToNextApiCall = 1;
    setFeatureFlagInSessionStorage("Feature not available", waitOneMinuteToNextApiCall);
    throw new Error("Feature not available");
  }
};
