import { action, observable } from "mobx";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { CarrierListModel2 } from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";
import { BaseListViewModel } from "../../../../viewModels/components/BaseListViewModel";

export class CarrierCMListViewModel extends BaseListViewModel<CarrierListModel2> {
  @observable cmSearchInputValue: string = "";
  @observable rows: number = 50;
  @observable totalRecords: number = 0;
  @observable selectedStatus: string | null = "";
  @observable selectedCarrierGroup: any;
  @observable isCarrierToast: boolean = false;
  @observable toasterIcon: string | null = "";
  @observable toasterMessage: string | null = "";
  @observable timer: any;
  constructor() {
    super(
      "Carriers",
      "Carrier",
      routes.addCarrierManagement,
      routes.editCarrierManagement,
      "carrierId",
      "createdDate",
      false
    );
    this.pageSize = 150;
  }

  @action async goSearch() {
    await this.loadItems();
  }
  @action resetFilters = () => {
    this.resetPagingValues();
  };

  @action resetPagingValues() {
    this.totalRecords = 0;
    this.pageSize = 150;
    this.firstItem = 0;
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    this.isCarrierToast = false;
    return await ContractsStore.getAllCarrierss(
      this.selectedStatus ? this.selectedStatus === "0" : null,
      pageIndex,
      this.selectedCarrierGroup,
      pageSize,
      sortColumn,
      sortOrder,
      this.cmSearchInputValue
    );
  }

  @action downloadCarriers = async () => {
    this.isCarrierToast = false;
    let res = await ContractsStore.getCarrierssAsExcel(
      this.selectedStatus ? this.selectedStatus === "0" : null,
      this.selectedCarrierGroup,
      this.firstItem,
      this.pageSize,
      this.sortColumn,
      this.isSortAscending,
      this.cmSearchInputValue || undefined
    );
    Utils.saveDownloadedReportFile(res, "Carriers");
    Utils.showSuccessToaster("Carrier List downloaded.", 7000);
    // setTimeout(() => {
    //   this.toasterIcon = "download";
    //   this.toasterMessage = "Carrier List downloaded.";
    //   this.isCarrierToast = true;
    // }, 300);

    // clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    //   this.isCarrierToast = false;
    // }, 9000);
  };

  resetFiltersToDefault() {
    this.resetFilters();
  }

  @action refresh = () => {
    this.cmSearchInputValue = "";
    this.selectedStatus = "";
    this.sortColumn = "createdDate";
    this.isSortAscending = false;
    this.selectedCarrierGroup = "";
    this.resetFiltersToDefault();
  };
}
