import { observable, action } from "mobx";
import { AccountSubTabs } from "./enum/AgentDetails";

class ACcountTabContext {
  @observable selectedTab: string | undefined = "";

  @action getSelectedTab = () => {
    return this.selectedTab;
  };

  @action setSelectedTab = (value: number) => {
      if(value === 0)this.selectedTab = AccountSubTabs.credit;
    else this.selectedTab = AccountSubTabs.debt
  };
}

export default new ACcountTabContext();

