import { observable, action } from "mobx";
import DocumentStore from "../../../store/DocumentStore";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import { routes } from "../../../router";
import Collection from "../../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../../infrastructure/ErrorModel";
import { Constants } from "../../../infrastructure/enum/Constants";
import PageContext from "../../../infrastructure/PageContext";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import Utils from "../../../infrastructure/Utils";
import {
  AgentDocumentOptions,
  AgentDocumentOptionValues,
} from "../../../infrastructure/enum/AgentDetails";
import { Permission } from "../../../infrastructure/enum/Permission";

export class EditDocViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get CanRoute(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoaded(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoading(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  Close(): void {
    throw new Error(Constants.NoMethodImplementation);
  }

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable workFlowList: any = [];

  @observable marketerList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable submissionList = new Collection<{
    label: string;
    value: string;
  }>();
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable agentId: number = 0;
  @observable docId: number = 0;
  @observable selectedCompany: string = "";
  @observable selectedType: string = "";
  @observable selectedTypeText: string = "";
  @observable selectedWorkFlow: string = "";
  @observable selectedMarketer: string = "";
  @observable selectedSubmission: string = "";
  @observable notes: string = "";
  @observable documentOption: string = "";
  @observable fileData: any = null;
  @observable fileName: string = "";
  @observable autoFocus: boolean = false;
  @observable isLoading: boolean = false;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable public ErrorModel = new ErrorModel(new AgentDocumentValidator());
  listener: AddDocumentListener | undefined;
  setListener(listener: AddDocumentListener) {
    this.listener = listener;
  }
  @observable filBytes: any = null;
  @observable isExistingFile: Boolean = false;
  @observable ExistingFileName: any = null;
  @observable ExistingfileData: any = null;
  @observable isEnterIntoWorkflowVisible: boolean = true;
  @observable uploadedFileName: string = "";
  @observable isAcceptFileSizeLimit: boolean = true;
  @observable isAcceptFileSize: boolean = false;
  @observable uploadedFileSize: number = 0;
  @observable isAgentDocFromWorkFlow: Boolean = false;
  @observable securityLevel: string ="";
  @action Load = async (agentId: number, docId: number | undefined) => {
    this.agentId = agentId;
    this.docId = docId ? docId : 0;
    this.selectedWorkFlow = "0";
    this.selectedMarketer = "0";
    this.selectedSubmission = "0";
    this.securityLevel = "";
    this.notes = "";
    this.documentOption = "";
    this.fileData = null;
    this.isExistingFile = false;
    this.ExistingFileName = null;
    this.isAgentDocFromWorkFlow =
      localStorage.getItem("SumitFromWorkFlow") !== null &&
      localStorage.getItem("SumitFromWorkFlow") === "Sumit"
        ? true
        : false;
    this.workFlowList = [];
    await this.ResetValidate();
    this.validatePermissions();
    await this.loadAllLookupList();
    this.autoFocus = true;
    if (this.docId == 0) this.selectedSubmission = "0";
    if (this.isAgentDocFromWorkFlow) {
      this.documentOption = "Submit To";
      localStorage.removeItem("SumitFromWorkFlow");
    }
  };
  @action loadAllLookupList = async () => {
    this.workFlowList = [];
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    var defaultItem = { label: "Select", value: "0" };
    try {
      // this.docId = 5223435;// need remove this line
      if (this.docId > 0) {
        var editResult = await DocumentStore.editDocument(this.docId);
        if (editResult && editResult != null && editResult.fileBytes) {
          // Company
          if (editResult.companyList != null) {
            this.companyList.values = [];
            editResult.companyList.forEach((obj: any) => {
              var data = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.companyList.values.push(data);
            });
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = this.companyList.values[0].value;
          } else {
            this.companyList.values = [];
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";
          }

          // Type
          if (editResult.documentTypeList != null) {
            this.documentTypeList.values = [];
            this.selectedType = "";
            this.selectedTypeText = "";
            editResult.documentTypeList.forEach((obj: any) => {
              var documentData = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.documentTypeList.values.push(documentData);
            });

            this.selectedType = this.documentTypeList.values[0].value;
            this.selectedTypeText = this.documentTypeList.values[0].label;
          } else {
            this.documentTypeList.values = [];
            this.selectedType = "";
            this.selectedTypeText = "";
          }

          if (editResult && editResult.typeId  && editResult.typeId > 0) {
            let doctype = this.documentTypeList.values.filter(i => { return i.value == editResult.typeId.toString() });
             this.selectedTypeText = doctype && doctype.length > 0 ? doctype[0].label : this.selectedTypeText;
         }

          this.selectedCompany =
            editResult.companyId !== null && editResult.companyId !== undefined
              ? editResult.companyId.toString()
              : "0";
          this.selectedType =
            editResult.typeId !== null && editResult.typeId !== undefined
              ? editResult.typeId.toString()
              : "0";
          this.notes =
            editResult.notes !== null && editResult.notes !== undefined
              ? editResult.notes.toString()
              : " ";
          this.docId =
            editResult.id !== null && editResult.id !== undefined
              ? parseInt(editResult.id.toString())
              : 0;
          this.isExistingFile = true;
          this.ExistingFileName = editResult.fileName;
          this.ExistingfileData = `data:application/pdf;base64,${editResult.fileBytes}`;
          this.fileData = this.ExistingfileData;
          this.fileName = this.ExistingFileName;
          this.securityLevel = editResult.private ? "1":"0";

          // workflow
          if (editResult.workflowList != null) {
            this.workFlowList = [];
            this.selectedWorkFlow = "0";
            if (
              this.selectedCompany !== "0" &&
              this.selectedType !== "0" &&
              (this.selectedType === "9" ||
                this.selectedType === "42" ||
                this.selectedType === "43" ||
                this.selectedType === "14" ||
                this.selectedType === "31" ||
                this.selectedTypeText === "W-9")
            ) {
              editResult.workflowList.forEach((obj: any) => {
                var data = {
                  label: obj.name ? obj.name : "",
                  value: obj.id ? obj.id : "",
                };
                this.workFlowList.push(data);
              });
              this.selectedWorkFlow = "0";
              var workFlowDocs = this.workFlowList.filter((obj: any) => {
                return obj.value === this.docId;
              });
              if (workFlowDocs.length > 0 && this.isAgentDocFromWorkFlow) {
                this.selectedWorkFlow = workFlowDocs[0].value || "0";
              }
            }
          } else {
            this.workFlowList = [];
            this.selectedWorkFlow = "0";
          }

          // Submission List
          if (
            editResult.submissionTypes &&
            editResult.submissionTypes.length > 0
          ) {
            this.submissionList.values = [];
            if (
              this.selectedCompany !== "0" &&
              this.selectedType !== "0" &&
              (this.selectedType === "9" ||
                this.selectedType === "42" ||
                this.selectedType === "43" ||
                this.selectedType === "14" ||
                this.selectedType === "31" ||
                this.selectedTypeText === "W-9")
            ) {
              this.submissionList.values = this.mapListItemAndSort(
                editResult.submissionTypes || []
              );
              this.selectedSubmission = "0";
              if (
                editResult.submissionTypes &&
                editResult.submissionTypes.length > 0
              ) {
                var selected = editResult.submissionTypes.filter(
                  (item: any) => {
                    return item.selected === true;
                  }
                );
                if (selected && selected.length > 0) {
                  this.selectedSubmission = selected[0].value || "0";
                }
              }
              this.checkUploadedFileSizeAccepted();
            } else {
              this.submissionList.values = [];
              this.submissionList.values.splice(0, 0, defaultItem);
              this.selectedSubmission = "0";
            }
          }

          if (editResult.fileBytes && editResult.fileBytes.length > 0) {
            this.uploadedFileSize = parseFloat(
              (editResult.fileBytes.length / 1400240).toFixed(2)
            );
            if (this.uploadedFileSize >= 31 && editResult.fileType == 0) {
            }
          }
          if (this.fileName && this.fileName.split(".").pop()) {
            this.enterIntoWorkflowVisible(this.fileName);
          }
          let documentOptions =
            editResult.submitToOption !== null &&
            editResult.submitToOption !== undefined
              ? parseInt(editResult.submitToOption.toString())
              : 0;
          this.documentOption =
            documentOptions === AgentDocumentOptionValues.SubmitTo
              ? AgentDocumentOptions.SubmitTo
              : documentOptions === AgentDocumentOptionValues.DontSendJustAttach
              ? AgentDocumentOptions.DontSendJustAttach
              : documentOptions ===
                AgentDocumentOptionValues.EnterIntoWorkflowScrub
              ? AgentDocumentOptions.EnterIntoWorkflowScrub
              : "";
          let documentOptionValue =
            editResult.submitToValue !== null &&
            editResult.submitToValue !== undefined
              ? editResult.submitToValue.toString()
              : "";
          //setTimeout(() => {
          if (
            documentOptionValue != "" &&
            this.submissionList.values &&
            this.submissionList.values.length > 0
          ) {
            var selected = this.submissionList.values.filter((item: any) => {
              return item.value === documentOptionValue;
            });
            if (selected && selected.length > 0) {
              this.selectedSubmission = selected[0].value || "0";
            } else {
              this.selectedSubmission = "0";
            }
          } else {
            this.selectedSubmission = "0";
          }
          //}, 1500);
        }
      }
    } catch (e) {
      this.documentTypeList.values = [];

      this.companyList.values = [];
      this.companyList.values.splice(0, 0, defaultItem);
      this.selectedCompany = "0";

      this.workFlowList = [];

      this.marketerList.values = [];
      this.marketerList.values.splice(0, 0, defaultItem);
      this.selectedMarketer = "0";

      //this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
    };
    @observable secLevelData = [
        { label: "Public", value: "0" },
        { label: "Private", value: "1" }
    ];
  @action validatePermissions = () => {
      if (!Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments)) {
          this.removeTypesFromList("Private");
      }
  }
  @action removeTypesFromList = (labelValue: string) => {
      var res = this.secLevelData.find(x => x.label.toLowerCase() === labelValue.toLowerCase());
      if (res) {
          this.secLevelData.forEach((item, index) => {
              if (item === res) this.secLevelData.splice(index, 1);
          });
      }
  }
  @action setSecurityLevel(value: string) {
      this.securityLevel = value;
  }
  @action setSelectedType = (value: string) => {
    this.selectedType = value;
    var typeselected = this.documentTypeList.values.filter((item: any) => {
      return item.value == value;
    });
    if (typeselected && typeselected.length > 0) {
      this.selectedTypeText = typeselected[0].label;
    }
    this.getWorkFlowList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
    this.getSubmissionList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
    this.enterIntoWorkflowVisible(
      this.uploadedFileName ? this.uploadedFileName : this.ExistingFileName
    );
  };

  @action checkUploadedFileSizeAccepted = async () => {
    if (
      this.documentOption &&
      this.documentOption == "Submit To" &&
      (!this.isAcceptFileSizeLimit || this.uploadedFileSize >= 31) &&
      this.selectedSubmission &&
      this.selectedSubmission != "0" &&
      this.selectedSubmission.split("|").shift() == "0"
    )
      this.isAcceptFileSize = true;
    else this.isAcceptFileSize = false;
  };

  @action enterIntoWorkflowVisible = async (filename: string | undefined) => {
    if (
      filename &&
      filename.split(".").pop().toLowerCase() != "pdf" &&
      (this.selectedType === "9" ||
        this.selectedType === "42" ||
        this.selectedType === "43" ||
        this.selectedType === "14" ||
        this.selectedType === "31" ||
        this.selectedTypeText === "W-9")
    ) {
      this.isEnterIntoWorkflowVisible = false;
    } else {
      this.isEnterIntoWorkflowVisible = true;
    }
  };
  @action setSelectedCompany = (value: string) => {
    this.selectedCompany = value;
    this.getWorkFlowList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
    this.getSubmissionList(
      this.agentId,
      parseInt(this.selectedType),
      parseInt(this.selectedCompany)
    );
  };
  @action setSelectedMarketer = (value: string) => {
    this.selectedMarketer = value;
  };
  @action setSelectedSubmission = (value: string) => {
    this.selectedSubmission = value;
  };
  @action setSelectedWorkFlow = (value: string) => {
    this.selectedWorkFlow = value;
  };
  @action setNotes = (value: string) => {
    this.notes = value;
  };
  @action setDocumentOption = (value: string) => {
    this.documentOption = value;
  };
  @action goBack() {
    this.isAcceptFileSize = false;
    this.isAcceptFileSizeLimit = true;
    routes.agentDocs.replace({ agentId: "" + this.agentId });
  }

  onDrop = (value: any, fileName: string) => {
    if (value != null) {
      this.fileData = value;
      this.fileName = fileName;
      this.isExistingFile = false;
    } else if (this.isExistingFile) {
      this.fileData = this.ExistingfileData;
      this.fileName = this.ExistingFileName;
    } else {
      this.fileData = value;
      this.fileName = fileName;
    }
  };
  showLongFileNameError() {
    this.isMessgeVisible = false;
    this.response = {};
    var responseModel = {
      status: 400,
      title: Constants.LongFileName,
      errors: { "": [] },
    };
    this.isMessgeVisible = true;
    PageContext.setIsMessageVisible(false);
    this.response = JSON.stringify(responseModel);
  }
  @action readURL(acceptedFiles: any) {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = (_event) => {
        this.fileData = reader.result;
      };
    }
  }
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @action CreateUpdateDocumentModel() {
    var dto: any = {
      // id: this.selectedWorkFlow ? parseInt(this.selectedWorkFlow) : 0,
      id: this.docId,
      fName: "",
      lName: "",
      agentId: this.agentId,
      typeId: parseInt(this.selectedType),
      companyId: parseInt(this.selectedCompany),
      notes: this.notes,
      isPrivate: this.securityLevel == "0" ? false : true,
      createdDate: new Date(),
      fileData: this.fileData,
      licenseStatusId: 0,
      isWorkflowChecked:
        this.documentOption === "Enter Into Workflow (Scrub)" ? true : false,
      isSendSubmission: this.documentOption === "Submit To" ? true : false,
      isDontSend:
        this.documentOption === "Don't Send, Just attach" ? true : false,
      ignoreForAgeCalculation: false,
      submissionTypeIds: this.selectedSubmission,
      fileName: this.fileName,
    };
    return dto;
  }
  @action SaveDocument = async (dto: any) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      await DocumentStore.UpdateDocument(dto);

      this.isLoading = false;
      this.isMessgeVisible = false;

      if (dto.isWorkflowChecked) {
        if (this.listener) {
          this.listener.documentAddedToWorkflow();
        }
      }
      this.goBack();
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = false;
      this.response = e.response;
    }
  };

  @action
  public onSave = async () => {
    await this.ResetValidate();
    if (!(await this.Validate())) {
      var dto = this.CreateUpdateDocumentModel();
      this.isMessgeVisible = false;
      this.response = {};
      var responseModel = {
        status: 400,
        title: Constants.NoFile,
        errors: { "": [] },
      };
      // Custom validations
      //If both #1 and #2 conditions failed then show error message "Please select a file."
      if (
        (dto.fileData === "" ||
          dto.fileData === undefined ||
          dto.fileData === null) &&
        (dto.id === 0 ||
          isNaN(dto.id) ||
          dto.id === undefined ||
          dto.id === null)
      ) {
        this.isMessgeVisible = true;
        PageContext.setIsMessageVisible(false);
        this.response = JSON.stringify(responseModel);
        return false;
      }

      // If Enter Into Workflow (Scrub)  radio button is checked -
      // then show this message ""You can't submit a file into Workflow if it's already in Workflow."

      //if (
      //    dto.id !== 0 &&
      //    dto.id !== undefined &&
      //    this.documentOption !== "" &&
      //    this.documentOption !== null &&
      //    this.documentOption !== undefined &&
      //    this.documentOption === "Enter Into Workflow (Scrub)"
      //) {
      //    responseModel = {
      //        status: 400,
      //        title: Constants.AlreadyExistInWorkFLow,
      //        errors: { "": [] },
      //    };
      //    this.response = JSON.stringify(responseModel);
      //    this.isMessgeVisible = true;
      //    PageContext.setIsMessageVisible(false);
      //    return false;
      //}

      // isSendDocViaEmail is true and dropdoen have no value selevted
      if (
        dto.isSendDocViaEmail === true &&
        (this.selectedMarketer === null ||
          this.selectedMarketer === "" ||
          this.selectedMarketer === "0" ||
          this.selectedMarketer === undefined)
      ) {
        responseModel = {
          status: 400,
          title: Constants.NoFaxEmailSelection,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
        this.isMessgeVisible = true;
        PageContext.setIsMessageVisible(false);
        return false;
      }
      // save document
      this.SaveDocument(dto);
    }
  };

  @action getWorkFlowList = async (
    agentId: number,
    documentTypeId: number,
    companyId: number
  ) => {
    this.workFlowList = [];
    if (
      this.selectedCompany !== "0" &&
      this.selectedType !== "0" &&
      (this.selectedType === "9" ||
        this.selectedType === "42" ||
        this.selectedType === "43" ||
        this.selectedType === "14" ||
        this.selectedType === "31" ||
        this.selectedTypeText === "W-9")
    ) {
      try {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var result = await DocumentStore.getWorkFlowList(
          agentId,
          documentTypeId,
          companyId
        );
        if (result != null) {
          result.forEach((obj: any) => {
            var data = {
              label: obj.name ? obj.name : "",
              value: obj.id ? obj.id : "",
            };
            this.workFlowList.push(data);
          });
          this.selectedWorkFlow = "0";
          var workFlowDocs = this.workFlowList.filter((obj: any) => {
            return obj.value === this.docId;
          });
          if (workFlowDocs.length > 0 && this.isAgentDocFromWorkFlow) {
            this.selectedWorkFlow = workFlowDocs[0].value || "0";
          }
        } else {
          this.workFlowList = [];
          this.selectedWorkFlow = "0";
        }
      } catch (e) {
        this.workFlowList = [];
        this.selectedWorkFlow = "0";
        this.isMessgeVisible = true;
        this.response = e.response;
      }
      this.isLoading = false;
    }
  };

  @action getSubmissionList = async (
    agentId: number,
    documentTypeId: number,
    companyId: number
  ) => {
    this.submissionList.values = [];
    if (
      this.selectedCompany !== "0" &&
      this.selectedType !== "0" &&
      (this.selectedType === "9" ||
        this.selectedType === "42" ||
        this.selectedType === "43" ||
        this.selectedType === "14" ||
        this.selectedType === "31" ||
        this.selectedTypeText === "W-9")
    ) {
      try {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "Select", value: "0" };

        var result =
          await BusinessUnitConfigStore.getSubmissionTypesByCarrierId(
            agentId,
            documentTypeId,
            companyId
          );
        if (result != null) {
          if (result != null) {
            this.submissionList.values = [];
            this.submissionList.values = this.mapListItemAndSort(result || []);
            this.selectedSubmission = "0";
            if (result && result.length > 0) {
              var selected = result.filter((item: any) => {
                return item.selected === true;
              });
              if (selected && selected.length > 0) {
                this.selectedSubmission = selected[0].value || "0";
              }
            }
          } else {
            this.submissionList.values = [];
            this.submissionList.values.splice(0, 0, defaultItem);
            this.selectedSubmission = "0";
          }
        } else {
          this.submissionList.values = [];
          this.submissionList.values.splice(0, 0, defaultItem);
          this.selectedSubmission = "0";
        }
        this.checkUploadedFileSizeAccepted();
      } catch (e) {
        this.submissionList.values = [];
        this.selectedSubmission = "0";
        this.isMessgeVisible = true;
        this.response = e.response;
      }
      this.isLoading = false;
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      listObjects.splice(0, 0, { text: "Select", value: "0", selected: false });
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }
}
class AgentDocumentValidator extends AbstractValidator<EditDocViewModel> {
  public constructor() {
    super();
    //this.validateIfString((input) => input.selectedCompany)
    //    .isNotEmpty()
    //    .isNotEqualTo("0")
    //    .withFailureMessage("Carrier is required");
    this.validateIfString((input) => input.selectedType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Type is required");

    this.validateIfString((input) => input.selectedSubmission)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Submit To is required")
      .when((input) => input.documentOption === "Submit To");
  }
}
export interface AddDocumentListener {
  documentAddedToWorkflow(): void;
}
