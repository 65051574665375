// mobx
import { observable, action } from "mobx";
// store
import DocumentStore from "../../../store/DocumentStore";
// ViewModel
import ViewModel from "../../../infrastructure/ViewModel";
// route
import RouteList from "../../../infrastructure/RouteList";
import { Constants } from "../../../infrastructure/enum/Constants";
import { DocType } from "../../../infrastructure/enum/Common";
import WorkflowStore from "../../../store/WorkflowStore";

export class PdfViewerViewModel implements ViewModel {
  // ViewModel functions implementation
  get CanClose(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get CanRoute(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoaded(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  get IsLoading(): boolean {
    throw new Error(Constants.NoMethodImplementation);
  }
  Close(): void {
    throw new Error(Constants.NoMethodImplementation);
  }
  @observable response: any;
  @observable pdfData: string = "";
  @observable blobData: any;
  @observable blobUrl: string = "";
  @observable isException: boolean = false;
  @observable documentType: any = "";
  @observable fileName: any;
  @observable isLoading: boolean = false;
  @action Load = async (docId: number, typeId: number, docType: DocType) => {
    this.isException = false;
    this.pdfData = "";
    this.blobUrl = "";
    this.blobData = undefined;
    switch (docType) {
      case DocType.Customer:
        await this.openCusomerDocument(docId, typeId);
        break;
      case DocType.DMS:
        await this.openDMSDocumentLink(docId, typeId);
        break;
      case DocType.Document:
        await this.openDocumentLink(docId, typeId);
        break;
      case DocType.WorkFlow:
        await this.openWorkflowDocument(docId);
        break;

      default:
        await this.openDocumentLink(docId, typeId);
        break;
    }
  };

  @action getFileTypeByName = async (fileName: string) => {
    var fileExtension = fileName.split(".").pop()
      ? fileName.split(".").pop().toLowerCase()
      : "";
    this.isException = false;
    switch (fileExtension) {
      case "pdf":
        return "application/pdf";
        break;
      case "jpg":
        return "image/jpg";
        break;
      case "jpeg":
        return "image/jpeg";
        break;
      case "png":
        return "image/png";
        break;
      case "txt":
        return "text/plain";
        break;

      default:
        return "";
        break;
    }
  };

  @action openCusomerDocument = async (docId: number, typeId: number) => {
    try {
      var result = await DocumentStore.downloadFiles(docId, typeId, false);
      if (result != null) {
        if (result.data && result.data !== null) {
          this.documentType = result.data.type;
          this.blobData = new Blob([result.data], { type: result.data.type });
          this.blobUrl = URL.createObjectURL(this.blobData);
        } else {
          let msg = "File not found.";
          var responseModel = {
            status: 400,
            title: msg,
            errors: { "": [] },
          };
          this.response = JSON.stringify(responseModel);

          this.isException = true;
        }
      }
    } catch (e) {
      var response = e.response;
      let exceptionMsg = "File not found.";
      if (response !== undefined && response != null) {
        var obj = JSON.parse(response);
        exceptionMsg = obj[0].Message;
      }
      var errorModel = {
        status: 400,
        title: exceptionMsg,
        errors: { "": [] },
      };
      this.response = JSON.stringify(errorModel);
      this.isException = true;
    }
  };

  @action openDocumentLink = async (docId: number, typeId: number) => {
    try {
      this.isLoading = true
      var result = await DocumentStore.downloadFiles(docId, typeId, true);
      if (result != null) {
        if (result.data && result.data !== null) {
          this.documentType = result.data.type;
          this.blobData = new Blob([result.data], { type: result.data.type });
          this.blobUrl = URL.createObjectURL(this.blobData);
          this.isException = false;
        }
        }
      else {
          let msg = "File not found.";
          var responseModel = {
              status: 400,
              title: msg,
              errors: { "": [] },
          };
          this.response = JSON.stringify(responseModel);

          this.isException = true;
      }
    } catch (e) {
      this.isException = true;
    } finally {
      this.isLoading = false
    }
  };

  @action openDMSDocumentLink = async (docId: number, typeId: number) => {
    try {
      var result = await DocumentStore.viewDocument(docId);
      if (result != null) {
        if (result.pdfData && result.pdfData !== null) {
          this.pdfData = result.pdfData;
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.response = JSON.stringify(responseModel);
            this.isException = true;
          }
        }
      }
    } catch (e) {}
  };

  @action openWorkflowDocument = async (docId: number) => {
    try {
      var result = await WorkflowStore.downloadWorkflowDocument(docId);
      if (result != null) {
        if (result.pdfData && result.pdfData !== null) {
          this.pdfData = result.pdfData;
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.response = JSON.stringify(responseModel);
            this.isException = true;
          }
        }
      }
    } catch (e) {}
  };

  @action loadDocument = (base64: string) => {
    this.pdfData = base64;
  };

  // declaration for route
  Route = async (currentRoute: RouteList): Promise<void> => {};
}
