import ViewModel from "../infrastructure/ViewModel";
import RouteList from "../infrastructure/RouteList";
import { observable, action } from "mobx";
import { AgentListModel } from "../services/ProducerSearchService";
import ProducerSearchStore from "../store/ProducerSearchStore";
import Collection from "../infrastructure/CollectionHelper";
import { routes } from "../router";
import RouteParamDetails from "../infrastructure/RouteParamDetails";
import { AgentSearch } from "../infrastructure/enum/Common";
import Utils from "../infrastructure/Utils";
import { Permission } from "../infrastructure/enum/Permission";
import UserContext, { AgentSearchUserProperties } from "../infrastructure/UserContext";

export class TopHeaderSearchViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }


  @action Load = async () => {
    if ((!Utils.hasUserPermission(Permission.ViewAgentSSN) && !Utils.hasUserPermission(Permission.EditAgentSSN))) {
      var res = this.searchTypeList.find(x => x.label === "SSN");
      if (res) {
        this.searchTypeList.forEach((item, index) => {
          if (item === res) this.searchTypeList.splice(index, 1);
        });
      }
      
    }

    if (!Utils.hasUserPermission(Permission.ViewAgentTIN) && !Utils.hasUserPermission(Permission.EditAgentTIN)) {
      var res = this.searchTypeList.find(x => x.label === "TIN");
      if (res) {
        this.searchTypeList.forEach((item, index) => {
          if (item === res) this.searchTypeList.splice(index, 1);
        });
      }
    }
    this.getAgentSearchUserProperties();
  };
  listener: AgentSearchListener | undefined;
  setListener(listener: AgentSearchListener) {
    this.listener = listener;
  }
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable agentsSearchList: number = 0;
  @observable selectedAgent: string = "";
  @observable type: string = "2";
  @observable isLoading: boolean = false;
  @observable agentId: number = 0;
  @observable isType: boolean = true;
  @observable searchType: string = "6";
    @observable searchTypeList = [
    { label: "NPN", value: AgentSearch.Npn },    
    { label: "Last Name", value: AgentSearch.LastName },
    { label: "First Name", value: AgentSearch.FirstName },
    { label: "Writing Number #", value: AgentSearch.Agent },
    { label: "Agent ID", value: AgentSearch.AgentId },
    { label: "Contact Options", value: AgentSearch.ContactOptions },
    { label: "DBA/Agency Name", value: AgentSearch.Dba },
    { label: "Email", value: AgentSearch.Email },    
    { label: "Phone", value: AgentSearch.Phone },   
    { label: "SSN", value: AgentSearch.Ssn }, 
    { label: "TIN", value: AgentSearch.Tin }
  ];
  @observable validSsnError: string = '';

  @action setSearchType(value: string) {
    this.isType = false;
    if (value !== "3") {
      this.validSsnError = '';
      this.selectedAgent = ''
    }

    this.searchType = value;
    this.validSsnError = "";

    this.setAgentSearchUserProperties(value || 6, "");
  }

  @action setSelectedAgent(value: string) {
    this.selectedAgent = value;
    if (value === "") {
      this.agentId = 0;
    }
  }
  @action noRecordsFound() {
    this.agentsSearchList = 0;
    this.FilteredAgentsList.values = [];

  }

  @action setSelectedAgentId(id: any, contractId: number | undefined) {
    this.agentId = id;
    if (this.agentId && this.agentId !== 0) {
      localStorage.setItem('createlog', 'createLog'); 
      if (this.searchType === AgentSearch.Agent) {
        routes.contractDetails.replace({ agentId: "" + this.agentId, contractId: contractId ? contractId : 0 });
      }
      else {
        routes.agentDetails.replace({ agentId: "" + this.agentId });
      }
    }
  }

  removeDash = (input: string) => {
    return input.replace(/-/g, '')
  }
  @action setAgentSearchUserProperties = async (type: number, text: string) => {
    var searchHeaderType = await this.getHeaderSearchType();
    let host = Utils.getHost();
    var userId= UserContext.getUserId();
    let userProperties: AgentSearchUserProperties = {
      buName: host,
      userId: userId,
      searchType: searchHeaderType,
      searchHeaderType: '' + type,
    };
    var data = JSON.stringify(userProperties);
    localStorage.setItem('agentSearchUserProperties', data);
  };

  @action getHeaderSearchType = async () => {
    var result = "";
    const agentSearchUserProperties = localStorage.getItem("agentSearchUserProperties");
    if (agentSearchUserProperties != undefined && agentSearchUserProperties != null) {
        const userprop: AgentSearchUserProperties = JSON.parse(agentSearchUserProperties);
        result = userprop && userprop.searchType ? userprop.searchType : "6";
    }
    return result;
  };

  @action getAgentSearchUserProperties = async () => {
    this.isType = false;
    let host = Utils.getHost();
    var userId= UserContext.getUserId();
    const agentSearchUserProperties = localStorage.getItem("agentSearchUserProperties");
    if (agentSearchUserProperties != undefined && agentSearchUserProperties != null) {
      const userprop: AgentSearchUserProperties = JSON.parse(agentSearchUserProperties);
      if (host == userprop.buName && userprop.userId == userId) {       
        this.searchType = userprop.searchHeaderType || "6";
      }
      else{
        this.searchType =  "6";
        this.selectedAgent = "";      
      }
    }
  };

  @action searchAgents = async (value: string, actionV: string) => {
    this.setAgentSearchUserProperties(this.searchType || 6, "");
    var tempValue = ''
    if (this.selectedAgent === '' && localStorage.getItem("searchValue")) {
      value = localStorage.getItem("searchValue") || ""
    } else {
      localStorage.removeItem("searchValue")
    }
    if (this.searchType === AgentSearch.Phone) {
      tempValue = value;
      value = this.formatPhoneNumber(value);
      value = this.removeDash(value);
    }
    if (this.searchType === AgentSearch.Ssn || this.searchType === AgentSearch.Tin) {
      value = value.replace(/-/g, '').trim()
      if (value.length < 3) {
        this.validSsnError = "Please enter minimum 3 digits";
        this.noRecordsFound();
       return;
      }
      else {
        this.validSsnError = ''

      }
    }

      var searchTypeParam = this.searchType;
      if (this.searchType === AgentSearch.LastName) {
        searchTypeParam = Utils.getAgentSearchType(value).toString();
      }
  
      let result = await ProducerSearchStore.getAgentList(
        +searchTypeParam,
        value,
        0,
        100,
        "lastname",
        true
      );
      this.isLoading = false;
      var noAgent: AgentListModel = { id: 0 };
  
      if (result !== null) {
        if (result.recordCount) {
          this.agentsSearchList = result.recordCount;
          if (result.data) {         
            if (actionV === "auto" && result && result.data.length > 0) {
              result.data.splice(0, 0, { id: -1 });
            }
            this.FilteredAgentsList.values = result.data;
          }
          if (actionV === "submit") {
            RouteParamDetails.agentSearchInput = this.searchType === AgentSearch.Phone ? tempValue : value;
            RouteParamDetails.agentSearchType = this.searchType;
            this.setAgentSearchUserProperties(+RouteParamDetails.agentSearchType ,  RouteParamDetails.agentSearchInput);
            var local_url = window.location;
            var index = local_url.href.split("/");
            if (local_url.href.includes('agents') && index.length ==4) {
              if (this.listener) {
                this.listener.updateAgentSearch();
              }
            } else {
              routes.agentSearch.replace()
            }
  
            this.selectedAgent = "";
          }
  
        } else {
          this.agentsSearchList = 0;
          this.FilteredAgentsList.values = [noAgent];
          // this.selectedAgent = "";
        }
      } else {
        this.agentsSearchList = 0;
        this.FilteredAgentsList.values = [noAgent];
        // this.selectedAgent = "";
      }
    
   
  };

  Route(currentRoute: RouteList): Promise<void> {
    throw new Error("Method not implemented.");
  }
  formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return match[0];

    }
    return phoneNumberString;
  }
}

export interface AgentSearchListener {
  updateAgentSearch(): void;
}