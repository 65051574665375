import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { CustomerLookupViewModel } from "../components/CustomerLookupViewModel";
import { AgentLookupViewModel } from "../components/AgentLookupViewModel";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../infrastructure/IMASLog";
import loggedInUser from "../../infrastructure/Auth0Authenticator";
import { CreateUpdateTaskModel, TaskModel } from "../../services/TaskService";
import TaskStore from "../../store/TaskStore";
import Utils from "../../infrastructure/Utils";
import ContractsStore from "../../store/ContractsStore";
import InsuredStore from "../../store/InsuredStore";
import UserStore from "../../store/IdentityStore";
import { UserLookupViewModel } from "../components/UserLookupViewModel";
import { UserLookupListModel } from "../../services/IdentityService";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import UserContext from "../../infrastructure/UserContext";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { AgentListModel } from "../../services/ProducerSearchService";
import { CustomerTask } from "../../infrastructure/enum/Customer";
import IdentityStore from "../../store/IdentityStore";

export class AddEditCustomerTaskViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => { };

  @action Load = async (id: number, taskId: number | undefined) => {
    if (taskId) {
      this.customerId = id;
      this.taskId = taskId;
      this.isEdit = true;
      this.title = "Edit Task";
    }
    this.selectedUserId = UserContext.userId
      ? UserContext.userId.toString()
      : "0";
    this.ResetValidate();
    this.resetAllFields();
    this.loadTaskDetails();
    this.setIsSuccess(false);
    this.canCompleteTask = false;
  };

  @observable taskId: number = 0;
  @observable agentId: number | undefined;
  @observable userId: number | undefined;
  @observable customerId: number | undefined;
  @observable orderId?: number;
  @observable companyId?: number;
  @observable taskTitle: string = "";
  @observable taskDate: Date | undefined = new Date();
  @observable description: string = "";
  @observable isEdit: boolean = false;
  @observable message: string = "";
  @observable selectedCompany: string = "";
  @observable selectedUser: string = "";
  @observable selectedUserId: string = "";
  @observable createdDate: Date | undefined = new Date();
  @observable completeDate: Date | undefined = new Date();
  @observable isComplete: boolean = false;
  @observable isSaveNotes: boolean = false;
  @observable agentInput: string | null = null;
  @observable customerInput: string | null = null;
  @observable
  CustomerLookupViewModel: CustomerLookupViewModel = new CustomerLookupViewModel();
  @observable
  AgentLookupViewModel: AgentLookupViewModel = new AgentLookupViewModel();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable FilteredCustomersList = new Collection<CustomerLookupListModel>();
  @observable
  UserLookupViewModel: UserLookupViewModel = new UserLookupViewModel();
  @observable FilteredUsersList = new Collection<UserLookupListModel>();

  @observable title: string = "Add Task";
  @observable taskNotes: string = "";
  @observable selectedAgent: string = "";
  @observable selectedCustomer: string = "";
  @observable selectedCustomerId: string = "";
  @observable assignTo: string = loggedInUser.displayName;
  @observable taskDetailModel: TaskModel | undefined;
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable isLoading: boolean = false;
  @observable startIndex: number = 0;
  @observable rows: number = 25;
  @observable agentsSearchList: number = 0;
  @observable customersSearchList: number = 0;
  @observable usersSearchList: number = 0;
  @observable canCompleteTask: boolean = false;

  @observable priority: string = "Low";
  @observable priorityList: { label: string; value: string }[] = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  @observable companyList = [{ label: "NONE", value: "NONE" }];
  @computed
  get isDialogVisible(): boolean {
    return this.CustomerLookupViewModel.isDialogVisible;
  }

  @action loadCompanies = async () => {   
    this.isLoading = true;
    var carriers = await ContractsStore.getCarriers(this.startIndex, 1000);   
    if (carriers) {
      var unSortList: { label: string; text: string; value: string }[] = [];    
      carriers.forEach((element: any) => {       
        unSortList.push({
          text: "" + element.carrierName,
          label: "" + element.carrierName,
          value: "" + element.id,
        });
      });     
      this.companyList = [...unSortList].sort(Utils.compareListItem);    
      if (this.isEdit && this.taskDetailModel)
        await this.setCompanyName(
          this.taskDetailModel.companyId
            ? "" + this.taskDetailModel.companyId
            : ""
        );
    }    
    this.isLoading = false;
  };

  @action resetAllFields() {
    this.taskTitle = "";
    this.taskDate = new Date();
    this.priority = "Low";
    this.selectedAgent = "";
    this.selectedCustomer = "";
    this.selectedUser = "";
    this.selectedCompany = "";
    this.companyList = [];
    this.description = "";
    this.isComplete = false;
    this.isSaveNotes = false;
  }
  getBooleanValue(value: boolean | undefined) {
    return value ? value : false;
  }

  @computed
  get isAgentSearchDialogVisible(): boolean {
    return this.AgentLookupViewModel.isDialogVisible;
  }
  @action setSelectDate(value: Date) {
    this.createdDate = value;
  }
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action setSelectedUser(userData: any) {
    this.selectedUser = userData;
  }
  @action setSelectedUserId(id: any) {
    this.selectedUserId = id;
  }
  @action setSelectedCustomerId(id: any) {
    this.selectedCustomerId = id;
  }
  @action setSelectedAgentId(id: any) {
    this.agentId = id;
  }
  @action setSelectedAgent(value: string) {
    this.selectedAgent = value;
    if (value === "" && this.taskDetailModel) {
      this.agentId = 0;
      this.taskDetailModel.agentId = 0;
    }
  }
  @action setSelectedCusomer(value: string) {
    this.selectedCustomer = value;
    if (value === "" && this.taskDetailModel) {
      this.selectedCustomerId = "";
      this.taskDetailModel.customerId = 0;
    }
  }
  @action setTitle(value: string) {
    this.taskTitle = value;
  }
  @action setAssignTo(value: string) {
    this.assignTo = value;
  }
  @action setTaskDate(value: Date | undefined) {
    this.taskDate = value;
  }
  @action setPriority(value: string) {
    this.priority = value;
  }
  @action setTaskNotes(value: string) {
    this.description = value;
  }
  @action setCreateDate(value: Date | undefined) {
    this.createdDate = value;
  }
  @action setDialogVisibility(value: boolean) {
    this.CustomerLookupViewModel.isDialogVisible = value;
  }
  @action setAgentSearchDialogVisibility(value: boolean) {
    this.AgentLookupViewModel.isDialogVisible = value;
  }
  @action setIsComplete(value: boolean) {
    this.isComplete = value;
  }
  @action setSaveNotes(value: boolean) {
    this.isSaveNotes = value;
  }
  @action setCompanyName = async (value: string) => {
    this.selectedCompany = value;
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }

  @action searchIntervals = (text: string, value: string) => {
    if (value === CustomerTask.agent) {
      if (!text) {
        this.AgentLookupViewModel.SelecetedAgent = null;
        this.agentInput = null;
      } else this.agentInput = text;
    } else {
      if (!text) {
        this.CustomerLookupViewModel.SelecetedAgent = null;
        this.customerInput = null;
      } else this.customerInput = text;
    }
  };
  @action searchAgent = () => {
    this.AgentLookupViewModel.isDialogVisible = true;
  };
  @action searchCustomer = () => {
    this.CustomerLookupViewModel.isDialogVisible = true;
  };
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action goBack() {
  
      Utils.goBack();
   
  }
  @action loadTaskDetails = async () => {
    this.isLoading = true;
    this.isException = false;
    if (this.isEdit) {
      try {
        this.taskDetailModel = await TaskStore.getTaskDetails(this.taskId);
      } catch (error) {
        IMASLog.log("error while fetching task details " + error);
        this.isException = true;
        this.isLoading = false;
      }
      if (this.taskDetailModel) {
        this.taskTitle = "" + this.taskDetailModel.task;
        var date = Utils.getDateInFormat(
          this.taskDetailModel.taskDate
            ? this.taskDetailModel.taskDate
            : new Date()
        );
        this.setTaskDate(new Date(date));
        this.description = "" + this.taskDetailModel.description;
        this.isComplete = this.getBooleanValue(this.taskDetailModel.complete);
        this.setPriority(
          this.taskDetailModel.priority === 3
            ? "High"
            : this.taskDetailModel.priority === 2
              ? "Medium"
              : "Low"
        );
        this.loadCompanies();       
          this.selectedCompany = "" + this.taskDetailModel.companyId;        
        this.selectedUser = "" + this.taskDetailModel.userName;
        this.selectedCustomer =
          this.taskDetailModel.customerName === null
            ? ""
            : "" + this.taskDetailModel.customerName;
        this.selectedAgent =
          this.taskDetailModel.agentName === null
            ? ""
            : "" + this.taskDetailModel.agentName;
      } 
    } else {
      if (this.agentId !== 0) {
        var agentFullName = RouteParamDetails.firstName.split(" ");
        this.selectedAgent =
          "" + agentFullName[agentFullName.length - 1] + "," + agentFullName[0];
      }
      var loginuserName = loggedInUser.displayName.split(" ");
      this.selectedUser =
        "" + loginuserName[loginuserName.length - 1] + "," + loginuserName[0];
        
        //call api to get userid by aduserid
        var result = await IdentityStore.getUserByAdGuid(loggedInUser.adUserId);
        if (result) {
          this.selectedUserId = result.id ? result.id.toString() : "0";
        }
       
      this.loadCompanies();
    }
    this.isLoading = false;
  };

  @action
  public Submit = async () => {
    if (!(await this.Validate())) {
      if (this.isComplete) {
        this.canCompleteTask = true;
      } else {
        await this.saveTask();
      }
    }
  };
  private async saveTask() {
    this.isLoading = true;
    try {
      var taskdto: CreateUpdateTaskModel = {};
      taskdto.id = this.taskId;
      taskdto.userId = Number(this.selectedUserId);
      taskdto.agentId = this.agentId ? this.agentId : 0;
      taskdto.customerId = Number(this.selectedCustomerId);
      taskdto.orderId = this.orderId = 0;
      taskdto.taskDate = this.taskDate;
      taskdto.task = this.taskTitle;
      taskdto.description = this.description;
      taskdto.complete = this.isComplete;
      taskdto.priority =
        this.priority === CustomerTask.High ? 3 : this.priority === CustomerTask.Medium? 2 : 1;
      taskdto.dateCreated = this.createdDate;
      if (this.selectedCompany !== "") {
        taskdto.companyId = this.selectedCompany
          ? Number(this.selectedCompany)
          : taskdto.companyId;
      } else {
        taskdto.companyId = undefined;
      }
      taskdto.dateCompleted = this.completeDate;
      taskdto.saveNotes = this.isSaveNotes;
      if (this.isEdit) {
        if (this.taskDetailModel) taskdto.id = this.taskDetailModel.id;
        taskdto.userId = taskdto.userId
          ? taskdto.userId
          : this.taskDetailModel
            ? Number(this.taskDetailModel.userId)
            : 0;
        if (this.selectedAgent !== "")
          taskdto.agentId = taskdto.agentId
            ? taskdto.agentId
            : this.taskDetailModel
              ? Number(this.taskDetailModel.agentId)
              : 0;
        if (this.selectedCustomer !== "")
          taskdto.customerId = taskdto.customerId
            ? taskdto.customerId
            : this.taskDetailModel
              ? Number(this.taskDetailModel.customerId)
              : 0;
        await TaskStore.editTask(taskdto.agentId, taskdto);
        
      } else {
        await TaskStore.addTask(taskdto.agentId, taskdto);
       
       
      }
    
      this.isLoading = false;
     
        Utils.goBack();
    
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      IMASLog.log("exception: " + error);
    }
  }

  @observable response: any;

  @action setSuccessResponse() {
    window.scrollTo(0, 0);
  }

  @action async completeTask() {
    await this.saveTask();
    this.setCompleteTaskConfirm(false);
  }
  @action setCompleteTaskConfirm(value: boolean) {
    this.canCompleteTask = value;
  }
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action searchAgents = async (value: string) => {
    IMASLog.log("search agents ..");
    let result = await ProducerSearchStore.getAgentsLookup(1,
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.agentsSearchList = result.recordCount;
        if (result.data) {
          this.FilteredAgentsList.values = result.data;
        }
      } else {
        this.agentsSearchList = 0;
        this.FilteredAgentsList.values = [noAgent];
        this.selectedAgent = "";
      }
    } else {
      this.agentsSearchList = 0;
      this.FilteredAgentsList.values = [noAgent];
      this.selectedAgent = "";
    }
    this.isLoading = false;
  };
  @action searchCustomers = async (value: string) => {
    IMASLog.log("search customers ..");
    var noCustomer: CustomerLookupListModel = { id: 0 };
      var searchTypeVal = 1;
      let isnum = /^\d+$/.test(value);
      if (isnum) { searchTypeVal = 6 };

      let result = await InsuredStore.getCustomerList(searchTypeVal,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        this.customersSearchList = result.recordCount;
        if (result.data) {
          this.FilteredCustomersList.values = result.data;
        }
      } else {
        this.customersSearchList = 0;
        this.FilteredCustomersList.values = [noCustomer];
        this.selectedCustomer = "";
      }
    } else {
      this.customersSearchList = 0;
      this.FilteredCustomersList.values = [noCustomer];
      this.selectedCustomer = "";
    }
    this.isLoading = false;    
  };
  @action searchUsers = async (value: string) => {
    IMASLog.log("search users ..");
    var noUser: UserLookupListModel = { id: 0 };
    let result = await UserStore.getUserList(value, 0, 20);

    if (result !== null) {
      if (result.recordCount) {
        this.usersSearchList = result.recordCount;
        if (result.data) {
          this.FilteredUsersList.values = result.data;
        }
      } else {
        this.usersSearchList = 0;
        this.FilteredUsersList.values = [noUser];
        this.selectedUser = "";
      }
    } else {
      this.usersSearchList = 0;
      this.FilteredUsersList.values = [noUser];
      this.selectedUser = "";
    }
    this.isLoading = false;    
  };

  @observable
  public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<AddEditCustomerTaskViewModel> {
  public constructor() {
    super();
    this.validateIfDate((input) => input.taskDate)
      .isNotEmpty()
      .withFailureMessage("Task Date is required");
    this.validateIfString((input) => input.taskTitle)
      .isNotEmpty()
      .isNotEqualTo("NONE")
      .withFailureMessage("Title is required.");
    this.validateIfString(input => input.selectedUser)
      .isNotEmpty()
      .withFailureMessage("Assigned To is required");
  }

}

export interface CustomerLookupListModel {
  id?: number;
  firstName?: string;
  lastName?: string;
  dob?: Date;
  city?: string;
  state?: string;
  zip?: string;
}


