import { observable, action } from "mobx";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import Collection from "../../../../../infrastructure/CollectionHelper";
import { SubmissionMethods } from "../../../../../infrastructure/enum/Submissions";
import { routes } from "../../../../../router";
import { ErrorModel } from "../../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import {
  RequirementSubmissionMethodModel,
  RequirementSubmissionMethodRequest,
} from "../../../../../services/BusinessUnitConfigService";
import Utils from "../../../../../infrastructure/Utils";

export class RequirementsAddEditViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable title: string = "Add Requirement";
  @observable selectedType: string = '';
  @observable name: string = "";
  @observable selectedId: number = 0;
  @observable isLoading: boolean = false;
  @observable selectedDocTypes: any = null;
  @observable selectedCarrierIds: any = null;
  @observable selectedPrdtSubCat: any = null;
  @observable selectedPrdts: any = null;
  @observable selectedStates: any = null;
  @observable selectedPolicyEffectiveDateYear: any = null;

  @observable docTypesList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable carrierList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productSubCatList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statesList = new Collection<{
    label: string;
    value: string;
    isHeader?:boolean;
  }>();
  @observable policyEffectiveDateYearList = new Collection<{
    label: string;
    value: string;
  }>();

  //flags for fields based on type
  @observable isDocTypesVisible: boolean = false;
  @observable isNameVisible: boolean = false;
  @observable isCarriersVisible: boolean = false;
  @observable isProdCatVisible: boolean = false;
  @observable isProductVisible: boolean = false;
  @observable isUserNameVisible: boolean = false;
  @observable isStatesVisible: boolean = false;
  @observable isPolicyEffDateVisible: boolean = false;

  //flags for fields based on type

  @observable public AgentErrorModel = new ErrorModel(
    new AgentAddEditValidator()
  );

  @observable public CustomerErrorModel = new ErrorModel(
    new CustomerAddEditValidator()
  );

  @action Load = async (id: number) => {
    this.reset();
    if (id !== 0) {
      this.title = "Edit Requirement";
      this.selectedId = id;
      await this.getRequirementById();
    } else {
      this.title = "Add Requirement";
      this.changeType(SubmissionMethods.Agent);
      await this.getRequirementById();
    }
  };

  @action getRequirementById = async () => {
    try {
      this.isLoading = true;
      let result =
        await BusinessUnitConfigStore.getRequirementSubmissionMethodById(
          this.selectedId
        );

      if (result) {
        let details: RequirementSubmissionMethodModel = result;
        if(details.submissionRulesetType === 1){
          this.changeType(SubmissionMethods.Customer)
        }else{
          this.changeType(SubmissionMethods.Agent);
          // this.loadAgentDetails(details);
        }
        this.loadCustomerDetails(details)
      }
    } catch (e) {
      this.isLoading = false;
      this.name = "";
      this.selectedDocTypes = null;
      this.selectedCarrierIds = null;
      this.selectedPrdtSubCat = null;
      this.selectedPrdts = null;
      this.selectedStates = null;
      this.selectedPolicyEffectiveDateYear = null;

      this.docTypesList.values = [];
      this.carrierList.values = [];
      this.productSubCatList.values = [];
      this.productList.values = [];
      this.statesList.values = [];
      this.policyEffectiveDateYearList.values = [];
    }
  };

 

  @action loadAgentDetails = (dto: RequirementSubmissionMethodModel) => {
    this.name = dto.submissionRequirementRulesetName
      ? dto.submissionRequirementRulesetName
      : "";
    this.docTypesList.values = dto.documentTypes
      ? Utils.mapListItem(dto.documentTypes)
      : [];
    this.selectedDocTypes = dto.documentTypeIds
      ? dto.documentTypeIds.map((i: any) => i.toString())
      : null;

    this.carrierList.values = dto.companies
      ? Utils.mapListItem(dto.companies)
      : [];
    this.selectedCarrierIds = dto.companyIds
      ? dto.companyIds.map((i: any) => i.toString())
      : null;
  };

  @action loadCustomerDetails = (dto: RequirementSubmissionMethodModel) => {
    
    this.name = dto.submissionRequirementRulesetName
      ? dto.submissionRequirementRulesetName
      : "";
    this.docTypesList.values = dto.documentTypes
      ? Utils.mapListItem(dto.documentTypes)
      : [];
    this.selectedDocTypes = dto.documentTypeIds
      ? dto.documentTypeIds.map((i: any) => i.toString())
      : null;

    this.carrierList.values = dto.companies
      ? Utils.mapListItem(dto.companies)
      : [];
    this.selectedCarrierIds = dto.companyIds
      ? dto.companyIds.map((i: any) => i.toString())
      : null;

    this.productSubCatList.values = dto.productSubcategories
      ? Utils.mapListItem(dto.productSubcategories)
      : [];
    this.selectedPrdtSubCat = dto.productSubcategoryIds
      ? dto.productSubcategoryIds.map((i: any) => i.toString())
      : null;

    this.productList.values = dto.products
      ? Utils.mapListItem(dto.products)
      : [];
    this.selectedPrdts = dto.productIds
      ? dto.productIds.map((i: any) => i.toString())
      : null;
      if (dto.states) {
          for (let item of dto.states) {
              this.statesList.values.push({
                  label: "" + item.stateName,
                  value: "" + item.stateId,
                  isHeader: item.isHeader
              });
          }
      }
    this.selectedStates = dto.stateIds
      ? dto.stateIds.map((i: any) => i.toString())
      : null;
    this.policyEffectiveDateYearList.values = dto.policyEffectiveDateYearsList
      ? Utils.mapListItem(dto.policyEffectiveDateYearsList)
      : [];
    this.selectedPolicyEffectiveDateYear = dto.policyEffectiveDateYears
      ? dto.policyEffectiveDateYears.map((i: any) => i.toString())
      : null;
  };

  @action reset = () => {
    this.isNameVisible = false;
    this.isDocTypesVisible = false;
    this.isCarriersVisible = false;
    this.isProdCatVisible = false;
    this.isProductVisible = false;
    this.isStatesVisible = false;
    this.isPolicyEffDateVisible = false;
    this.selectedType = '';
    this.name = "";
    this.title = "Add Requirement";
    this.selectedId = 0;
    this.selectedDocTypes = null;
    this.selectedCarrierIds = null;
    this.selectedPrdtSubCat = null;
    this.selectedPrdts = null;
    this.selectedStates = null;
    this.selectedPolicyEffectiveDateYear = null;
    this.docTypesList.values = [];
    this.carrierList.values = [];
    this.productSubCatList.values = [];
    this.productList.values = [];
    this.statesList.values = [];
    this.policyEffectiveDateYearList.values = [];
    this.AgentResetValidate();
    this.CustomerResetValidate();
  };

  @action loadFields = () => {
    this.AgentResetValidate();
    this.CustomerResetValidate();
    if (this.selectedType === SubmissionMethods.Agent) {
      this.isNameVisible = true;
      this.isDocTypesVisible = true;
      this.isCarriersVisible = true;
      this.isProdCatVisible = false;
      this.isProductVisible = false;
      this.isStatesVisible = false;
      this.isPolicyEffDateVisible = false;
    } else {
      this.isNameVisible = true;
      this.isDocTypesVisible = true;
      this.isCarriersVisible = true;
      this.isProdCatVisible = true;
      this.isProductVisible = true;
      this.isStatesVisible = true;
      this.isPolicyEffDateVisible = true;
    }
  };

  @action setName = (value: string) => {
    this.name = value;
  };

  @action setSelectedDocTypes = (value: any) => {
    this.selectedDocTypes = value;
  };

  @action setSelectedCarrierIds = (value: any) => {
    this.selectedCarrierIds = value;
  };
  @action setSelectedPrdtSubCat = (value: any) => {
    this.selectedPrdtSubCat = value;
  };

  @action setSelectedPrdts = (value: any) => {
    this.selectedPrdts = value;
  };

  @action setSelectedStates = (value: any) => {
    this.selectedStates = value;
  };

  @action setSelectedPolicyEffectiveDateYear = (value: any) => {
    this.selectedPolicyEffectiveDateYear = value;
  };

  @action changeType = (value: string) => {
    this.selectedType = value;
    this.loadFields();
  };

  @action onCancel = () => {
    routes.requirements.push();
  };

  @action onSave = async () => {
    try {
      this.isLoading = true;
      if ((await this.AgentValidate()) && (await this.CustomerValidate())) {
        return false;
      }
      let dto: RequirementSubmissionMethodRequest = this.agentCustomerModel();
      if (this.selectedId !== 0) {
        await BusinessUnitConfigStore.updateRequirementSubmissionMethod(dto);
      } else {
        await BusinessUnitConfigStore.addRequirementSubmissionMethod(dto);
      }
      this.isLoading = false;
      Utils.goBack();
    } catch (e) {
      this.isLoading = false;
    }
  };
  protected agentCustomerModel() {
    let submissionRequirementRulesetId = this.selectedId
      ? Number(this.selectedId)
      : 0;
    let submissionRulesetType =
      this.selectedType === SubmissionMethods.Agent ? 0 : 1;
    let submissionRequirementRulesetName = this.name ? this.name.trim() : "";
    let companyIds = this.selectedCarrierIds
      ? this.selectedCarrierIds.map((i: any) => Number(i))
      : undefined;
    let documentTypeIds = this.selectedDocTypes
      ? this.selectedDocTypes.map((i: any) => Number(i))
      : undefined;
    let productIds = this.selectedPrdts
      ? this.selectedPrdts.map((i: any) => Number(i))
      : undefined;
    let productSubcategoryIds = this.selectedPrdtSubCat
      ? this.selectedPrdtSubCat.map((i: any) => Number(i))
      : undefined;
    let stateIds = this.selectedStates
      ? this.selectedStates.map((i: any) => Number(i))
      : undefined;
    let policyEffectiveDateYears = this.selectedPolicyEffectiveDateYear
      ? this.selectedPolicyEffectiveDateYear.map((i: any) => Number(i))
      : undefined;
    return {
      submissionRequirementRulesetId,
      submissionRulesetType,
      submissionRequirementRulesetName,
      companyIds,
      documentTypeIds,
      productIds,
      productSubcategoryIds,
      stateIds,
      policyEffectiveDateYears,
    };
  }

  @action public AgentValidate = async () => {
    return this.AgentErrorModel.Validate(this);
  };

  @action public AgentResetValidate = async () => {
    return this.AgentErrorModel.ResetValidation(this);
  };

  @action public CustomerValidate = async () => {
    return this.CustomerErrorModel.Validate(this);
  };

  @action public CustomerResetValidate = async () => {
    return this.CustomerErrorModel.ResetValidation(this);
  };
}

class AgentAddEditValidator extends AbstractValidator<RequirementsAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.name)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
  }
}

class CustomerAddEditValidator extends AbstractValidator<RequirementsAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.name)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
  }
}
