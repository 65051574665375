import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { action, observable } from "mobx";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import ContractsStore from "../../../../store/ContractsStore"
import { CompanyLicenseNumberDefaultModel, CompanyLicenseNumberDefaultsListModel } from "../../../../services/ContractService";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";

export class CompanyLicenseNumberDefaultsListViewModel extends BaseListViewModel<CompanyLicenseNumberDefaultsListModel> {

    @observable actionTitle: string = "";
    @observable isEditorVisible: boolean = false;
    @observable public ErrorModel = new ErrorModel(new CompanyLicenseNumberDefaultValidator());
    
    @observable companies = new Collection<{ label: string; value: string; }>();
    @observable editCompanies = new Collection<{ label: string; value: string; }>();
    @observable filterCompanyId: number = 0;
    @action setFilterCompany = async (value: number) => {
        this.filterCompanyId = value;
        await this.loadItems();
    }

    @observable licenseNumberTypeList = new Collection<{ label: string; value: string; }>();

    @observable companyId: number = 0;
    @observable licenseNumberTypeId: number = 0;
    @observable isWritableDefault: boolean = true;

    @action setEditCompany(value: number) {
        this.companyId = value;
    }
    @action setEditNumberType(value: number) {
        this.licenseNumberTypeId = value;
    }
    @action setEditWritable(value: boolean) {
        this.isWritableDefault = value;
    }

    @action setEditorVisibility(isVisible: boolean) {
        this.isEditorVisible = isVisible;
    }
    @action onEditorHide() {
        this.setEditorVisibility(false);
    }

    protected async loadLookups() {
        var items = await ContractsStore.getAllCompanyLookup();
        Utils.mapListItemsToDropdown(items, this.companies);
        Utils.mapListItemsToDropdown(items, this.editCompanies, "Select a Carrier");

        this.licenseNumberTypeList.values = [
                { label: "Select a Number Type", value: "0" },
                { label: "Agency", value: "2" },
                { label: "Agent", value: "1" },
                { label: "Call Center", value: "4" },
                { label: "Comp", value: "3" },
                { label: "NPN", value: "8" },            
                { label: "PartyID", value: "6" },
                { label: "Recruiter", value: "5" },
            ];
    }

    @action editItem = async (id: number) => {
        if(id === 0) {
            this.actionTitle = "Add License Number Default";
            this.selectedId = 0;
            this.companyId = 0;
            this.licenseNumberTypeId = 0;
            this.isWritableDefault = true;
        } else {
            this.actionTitle = "Edit License Number Default";
            var item = await ContractsStore.getCompanyLicenseNumberDefaultById(id);
            this.selectedId = item.id || 0;
            this.companyId = item.companyId || 0;
            this.licenseNumberTypeId = item.licenseNumberTypeId || 0;
            this.isWritableDefault = item.isWritableDefault || false;
        }
        
        this.ResetValidate();
        this.setEditorVisibility(true);
    };
    @action addItem = async () => {
        this.editItem(0);
    };
  
    protected toServiceModel() {
        var dto: CompanyLicenseNumberDefaultModel = {
            id: this.selectedId,
            companyId: this.companyId,
            licenseNumberTypeId: this.licenseNumberTypeId,
            isWritableDefault: this.isWritableDefault
        }
        return dto;
    }
    @action public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
   

    @action saveSelectedItem = async () => {
        try
        {
          if (!await this.Validate()){
            var item = this.toServiceModel();
            if(this.selectedId === 0) 
                await ContractsStore.addCompanyLicenseNumberDefaults(item);
            else
                await ContractsStore.updateCompanyLicenseNumberDefaults(item);
            
            this.setEditorVisibility(false)
          }
          setTimeout(() => { this.Load(); }, 10);
        }
        catch(e){
          this.setEditorVisibility(false)
        }
    }
    


    constructor()
    {
        super("Carrier License Number Defaults", "Carrier License Number Default", routes.addCompanyLicenseNumberDefaults, routes.editCompanyLicenseNumberDefaults, "id", "id", false);
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        return await ContractsStore.getAllCompanyLicenseNumberDefaults(this.filterCompanyId, pageIndex, pageSize, sortColumn, sortOrder);
    }

    protected async deleteItem(id: number) {
        await ContractsStore.deleteCompanyLicenseNumberDefaults(id);
    }
}

class CompanyLicenseNumberDefaultValidator extends AbstractValidator<CompanyLicenseNumberDefaultsListViewModel> {
    public constructor() {
      super();

      this.validateIfNumber(input => input.licenseNumberTypeId)
          .isPositive()
          .isNotEmpty()
          .withFailureMessage("Contract Number Type is required");

      this.validateIfNumber(input => input.companyId)
          .isPositive()
          .isNotEmpty()
          .withFailureMessage("Carrier is required");
    }
}

