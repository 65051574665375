import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import LeadStore from "../../store/LeadStore";
import {
  MarkerterLeadListModel,
  SalesOpsLeadSearchReponse,
  MonthCount,
  MarketerLeadsMonthSummaryResponse,
  LeadModel,
} from "../../services/LeadService";
import { AgentListModel } from "../../services/ProducerSearchService";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import UserContext from "../../infrastructure/UserContext";
import { ReportFile } from "../../services/AccountingService";
import Utils from "../../infrastructure/Utils";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { SalesLeadTabs, MainLeadTabs } from "../../infrastructure/enum/Leads";
import { Permission } from "../../infrastructure/enum/Permission";

export class MarketerLead {
  @observable
  public leadID: string = "";
  @observable
  public agentId: string = "";

  @observable
  public agentName: string = "";
  @observable
  public source: string = "";

  @observable
  public phase: string = "";
  @observable
  public phaseDetail: string = "";
  @observable
  public assignee: string = "";
  @observable
  public subAssignee: string = "";
  @observable
  public carrier: string = "";

  @observable
  public dateCreated: string = "";
  @observable
  public dateFollowUp: string = "";

  //#endregion
}

export class SalesLeadListViewModel implements ViewModel {
  @observable companyList = [{ label: "ALL", value: "0" }];         
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable leadSourceList = [{ label: "ALL", value: "0" }];
  @observable assigneeList = [{ label: "ALL", value: "0" }];
  @observable subAssigneeList = [{ label: "ALL", value: "0" }];
  @observable agentList = [{ label: "Select Agent", value: "0" }];
  @observable productLineList = [{ label: "ALL", value: "0" }];

  @observable phaseList = [{ label: "ALL", value: "0" }];
  @observable detailList = [{ label: "ALL", value: "0" }];

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.setSelectedPanel(key);
    if (index === 1) {
      Utils.setLeadsTab(3);
      routes.unmatchedLeads.push();
    } else {
      Utils.setLeadsTab(2);
    }
  };
  @action handleMainTabClick = (
    index: number | undefined,
    key: string
  ): void => {
    if (index === 0) {
      Utils.setLeadsTab(0);
      routes.leads.push();
    } else {
      Utils.setLeadsTab(2);
      routes.salesLeads.push();
    }
  };
  @observable selectedTabIndex?: number = 0;
  @observable selectedMainTabIndex?: number = 1;
  @observable CarrierBankDepositID: number = 0;
  @observable selectedCompany: string = "0";
  @observable selectedLeadSource: string = "0";
  @observable selectedAssignee: string = "0";
  @observable selectedSubAssignee: string = "0";
  @observable selectedAgent: string = "0";
  @observable selectedProductLine: string = "0";
  @observable SalesOpsLeadSearchReponse: SalesOpsLeadSearchReponse | undefined =
    undefined;
  @observable SalesOpsLeadListModel: LeadModel | undefined = undefined;
  @observable selectedLeadPhaseDetail: string = "0";
  @observable selectedLeadPhase: string = "2";
  @observable SalesOpsLeadLists = new Collection<LeadModel>();
  @observable MarkerterLeadLists = new Collection<MarkerterLeadListModel>();

  @observable ActivatedMonthCountSummary = new Collection<MonthCount>();
  @observable ContractedMonthCountSummary = new Collection<MonthCount>();
  @observable
  MarketerLeadsMonthSummaryResponse: MarketerLeadsMonthSummaryResponse | null =
    null;
  @observable leadId: number = 0;
  @observable hasPermission: boolean = true;
  @observable actionItems: MenuItem[] = [
    {
      label: "Delete",
      command: () => {},
    },
  ];
  @observable selectedKey: string | undefined = "";
  @observable selectedPanel: string = SalesLeadTabs.SaleOpsLeads;
  @observable isCollapsed: boolean = true;
  @observable show: boolean = true;
  @observable showLine: boolean = true;
  @observable sortColumn: string = "dateCreated";
  @observable sortOrder: boolean = false;
  @observable companies = [{ label: "All", value: "0" }];

  @observable tempSubAssigneeList = [{ label: "ALL", value: "0" }];

  @observable LeadsMainTabItems: TabItems[] = [];
  @observable LeadsSubTabItems: SubTabItems[] = [];

  @action getLeadsMainTabITem = () => {
    if (
      UserContext.permissions.length > 0 &&
      !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
    ) {
      this.hasPermission = false;
    } else {
      this.hasPermission = true;
    }
    this.LeadsMainTabItems = [];
    let a = { label: MainLeadTabs.MarketerLeads, value: 0 };
    this.LeadsMainTabItems.push(a);
    if (this.hasPermission) {
      let b = { label: MainLeadTabs.SaleOpsLead, value: 1 };
      this.LeadsMainTabItems.push(b);
    }
  };

  @action getLeadsSubTabITem = () => {
    this.LeadsSubTabItems = [];
    let a = { label: SalesLeadTabs.SaleOpsLeads, value: 0 };
    let b = { label: SalesLeadTabs.UnmatchedLeads, value: 1 };
    this.LeadsSubTabItems.push(a);
    this.LeadsSubTabItems.push(b);
  };
  @action updateLead = async (id: number) => {
    routes.editSaleOpsLead.push({ AgentLeadId: id });
  };
  @action setSelectedLead = (leadId: number) => {
    this.leadId = leadId;
  };

  @action viewDetail = async (leadId: number) => {
    routes.detailSaleOpsLeads.push({ AgentLeadId: leadId });
  };
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action exportReferrals = async () => {
    try {
      let fileType = 1;
      let result = await LeadStore.salesOpsLeadExport(fileType);
      this.downloadReport(result, "export");
    } catch (e) {
      console.log(e);
    }
  };

  @action downloadActivatedReport = async () => {
    let res = await LeadStore.generateActivatedReport(
      1,
      0,
      0,
      0,
      UserContext.getUserId()
    );
    res.fileName = "Activated.xlsx";
    this.downloadReport(res, "Agent_Debt");
  };

  @action downloadContractedReport = async () => {
    let res = await LeadStore.generateContractedReport(
      1,
      0,
      0,
      0,
      UserContext.getUserId()
    );
    res.fileName = "Contracted.xlsx";
    this.downloadReport(res, "Agent_Debt");
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @action loadLeadPhaseDetail = async () => {
    var leadPhaseDetailResponse = await LeadStore.getLeadPhaseDetailList(
      parseInt(this.selectedLeadPhase)
    );
    if (this.detailList.length > 1) {
      this.detailList = this.detailList.slice(0, 1);
    }
    for (let item of leadPhaseDetailResponse) {
      this.detailList.push({ label: "" + item.text, value: "" + item.value });
    }
  };

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList = []; 
    this.showInactiveCarriers ? (this.companyList = this.carriersInitial.values) :
    this.companyList = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action loadDropdowns = async () => {
    this.loading = true;
    var leadPhaseResponse = await LeadStore.getLeadPhaseList();
    this.phaseList = [{ label: "ALL", value: "0" }];
    if (this.phaseList.length === 1) {
      var leadphaselist = [...leadPhaseResponse].sort(this.sortTypeName);
      for (let item of leadphaselist) {
        this.phaseList.push({ label: "" + item.text, value: "" + item.value });
      }
      this.selectedLeadPhase = "2"; // unassigned
    }
    try {
      var response = await LeadStore.getDropdownValues(true, false);

      if (response && response.carriers && this.companyList.length === 1) {
        var carrierlist = [...response.carriers].sort(this.sortTypeName);
        for (let item of carrierlist) {
          this.companyList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
          this.selectedCompany = "0";
        }
        this.carriersInitial.values = this.companyList;
        this.companyList = Utils.carriersFilter(this.carriersInitial.values);
      }

      if (response && response.assignee && this.assigneeList.length === 1) {
        var assigneelist = [...response.assignee].sort(this.sortTypeName);
        for (let item of assigneelist) {
          this.assigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
      if (
        response &&
        response.subAssignee &&
        this.subAssigneeList.length === 1
      ) {
        for (let item of response.subAssignee) {
          this.tempSubAssigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        this.subAssigneeList = this.tempSubAssigneeList;
      }
      if (
        response &&
        response.productLine &&
        this.productLineList.length === 1
      ) {
        for (let item of response.productLine) {
          this.productLineList.push({
            label: "" + item.productLineName,
            value: "" + item.id,
          });
        }
      }
      if (response && response.leadSource && this.leadSourceList.length === 1) {
        var leadsourcelist = [...response.leadSource].sort(this.sortTypeName);
        for (let item of leadsourcelist) {
          this.leadSourceList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
    } catch (e) {}
    this.loading = false;
  };
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action setIsCollapsed(value: boolean) {
    this.isCollapsed = value;
  }
  @action setSelectedPanel(value: string) {
    this.selectedPanel = value;
  }
  sortTypeName = (a: any, b: any) => {
    let comparison = 0;
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }

    return comparison;
  };
  @action loadSubAssignee = async (assigneeId: number) => {
    if (assigneeId !== null && assigneeId > 0) {
      let result = await LeadStore.getSubAssigneeLookup(
        assigneeId,
        Utils.getUserId()
      );
      if (result) {
        this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
        for (let item of result) {
          this.subAssigneeList.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
        for (let item of this.assigneeList) {
          if (item.value === this.selectedAssignee) {
            var existItem = this.subAssigneeList.find(
              (p) => p.value === this.selectedAssignee
            );
            if (existItem === undefined) {
              this.subAssigneeList.push({
                label: "" + item.label,
                value: "" + item.value,
              });
            }
            this.selectedSubAssignee = item.value;
          }
        }
      }
    } else {
      var response = await LeadStore.getDropdownValues(true, false);
      if (response) {
        this.subAssigneeList = [{ label: "Select Sub-Assignee", value: "0" }];
        if (response.subAssignee) {
          var subassigneelist = [...response.subAssignee].sort(
            this.sortTypeName
          );
          for (let item of subassigneelist) {
            this.subAssigneeList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
          }
        }
        this.selectedSubAssignee = "0";
      }
    }
  };
  @action setSelectedAssignee(value: string) {
    this.selectedAssignee = "" + value;
  }
  @observable endDate: Date | undefined = undefined;
  @observable startDate: Date | undefined = undefined;
  @observable endViewDate: Date | undefined = undefined;
  @observable startViewDate: Date | undefined = undefined;
  @action setEndDate(value: Date | undefined) {
    this.endDate = value;
  }
  @action setStartDate(value: Date | undefined) {
    this.startDate = value;
  }
  @action setSelectDate(value: any, type: string) {
    var d = isNaN(new Date(value).getMonth()) === false ? value : undefined;
    if (type === "startDate") {
      this.startDate = d;
    } else {
      this.endDate = d;
    }
  }
  @observable isException: boolean = false;
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action goSearch = async () => {
    this.isException = false;
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.setIsException(true);
    } else {
      await this.loadMarketerLeads();
      this.setIsException(false);
    }
  };
  @observable isLoading: boolean = false;
  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case SalesLeadTabs.SaleOpsLeads:
          routes.salesLeads.push();
          return;
        case SalesLeadTabs.UnmatchedLeads:
          routes.salesLeads.push();
          return;
        default:
          return;
      }
    }
  };
  @action handleMainLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case MainLeadTabs.MarketerLeads:
          routes.leads.push();
          return;
        case MainLeadTabs.SaleOpsLead:
          routes.salesLeads.push();
          return;

        default:
          return;
      }
    }
  };

  @action
  public Load = async (OnClose?: () => void): Promise<void> => {
    this.selectedTabIndex = 0;
    this.selectedMainTabIndex = 1;
    let delay = 0;
    this.getLeadsMainTabITem();
    this.getLeadsSubTabITem();
    if (UserContext.permissions.length <= 0) {
      delay = 2000;
    }
    setTimeout(async () => {
      this.isLoading = true;
      this.onClose = OnClose;
      this.loadDropdowns();
      await this.goSearch();
      this.isLoading = false;
      if (
        UserContext.permissions.length > 0 &&
        !Utils.hasUserPermission(Permission.ViewSalesOpsAgentLeads)
      ) {
        this.hasPermission = false;
      } else {
        this.hasPermission = true;
      }
    }, delay);
  };

  private paramCarrierID: number | undefined;
  private paramLeadID: number | undefined;
  private paramAgentID: number | undefined;
  private paramAssignee: number | undefined;
  private paramPhase: number | undefined;
  private paramSubAssignee: number | undefined;
  private paramDetail: number | undefined;
  private paramLeadSource: number | undefined;
  private paramProductLine: number | undefined;

  @action resetFilters = () => {
    this.showInactiveCarriers = false;
    this.resetAll();
    this.startViewDate = new Date();
    this.endViewDate = new Date();
    this.loadDropdowns();
    this.companyList = Utils.carriersFilter(this.carriersInitial.values);
    this.goSearch();
  };
  @action resetAll = () => {
    this.setIsException(false);
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedAssignee = "0";
    this.selectedLeadPhase = "2";
    this.selectedSubAssignee = "0";
    this.uplineId = 0;
    this.selectedCompany = "0";
    this.selectedProductLine = "0";
    this.selectedLeadPhaseDetail = "0";
    this.selectedLeadSource = "0";
    this.LeadId = "";
    this.selectedUser = "";
    this.sortColumn = "dateCreated";
    this.sortOrder = false;
    this.rows = 10;
    this.startIndex = 0;
    this.first = 0;
    this.subAssigneeList = [{ label: "ALL", value: "0" }];
    this.tempSubAssigneeList = [{ label: "ALL", value: "0" }];
  };
  @action loadMarketerLeads = async () => {
    if (this.selectedAssignee !== "0")
      this.paramAssignee = parseInt(this.selectedAssignee);
    else this.paramAssignee = undefined;

    if (this.selectedLeadPhase !== "0")
      this.paramPhase = parseInt(this.selectedLeadPhase);
    else this.paramPhase = undefined;

    if (this.selectedSubAssignee !== "0")
      this.paramSubAssignee = parseInt(this.selectedSubAssignee);
    else this.paramSubAssignee = undefined;

    if (this.selectedLeadPhaseDetail !== "0")
      this.paramDetail = parseInt(this.selectedLeadPhaseDetail);
    else this.paramDetail = undefined;

    if (this.selectedLeadSource !== "0")
      this.paramLeadSource = parseInt(this.selectedLeadSource);
    else this.paramLeadSource = undefined;

    if (this.selectedPanel === SalesLeadTabs.SaleOpsLeads) {
      if (this.selectedProductLine !== "0")
        this.paramProductLine = parseInt(this.selectedProductLine);
      else this.paramProductLine = undefined;
    } else {
    }

    if (this.uplineId !== 0) this.paramAgentID = this.uplineId;
    else this.paramAgentID = undefined;

    if (this.LeadId !== "") this.paramLeadID = parseInt(this.LeadId);
    else this.paramLeadID = undefined;

    if (this.selectedCompany !== "0")
      this.paramCarrierID = parseInt(this.selectedCompany);
    else this.paramCarrierID = undefined;

    this.isLoading = true;

    this.SalesOpsLeadSearchReponse = await LeadStore.getSalesOpsLeads(
      this.paramLeadID,
      this.paramAgentID,
      undefined,
      this.paramAssignee,
      this.paramSubAssignee,
      this.paramLeadSource,
      this.paramProductLine,
      this.paramCarrierID,
      this.startDate,
      this.endDate,
      this.paramPhase,
      this.paramDetail,
      undefined,
      undefined,
      this.startIndex,
      this.rows,
      this.sortColumn,
      this.sortOrder
    );

    if (this.SalesOpsLeadSearchReponse && this.SalesOpsLeadSearchReponse.data) {
      this.SalesOpsLeadLists.values = this.SalesOpsLeadSearchReponse.data;
      this.totalRecords = this.SalesOpsLeadSearchReponse.recordCount;
    }
    if (UserContext.getUserId()) {
      this.MarketerLeadsMonthSummaryResponse = await LeadStore.getMonthSummary(
        0,
        0,
        0,
        UserContext.getUserId()
      );
      this.ActivatedMonthCountSummary.values =
        this.MarketerLeadsMonthSummaryResponse &&
        this.MarketerLeadsMonthSummaryResponse.teamMonthlyActivatedCount
          ? this.MarketerLeadsMonthSummaryResponse.teamMonthlyActivatedCount
          : [];
      this.ContractedMonthCountSummary.values =
        this.MarketerLeadsMonthSummaryResponse &&
        this.MarketerLeadsMonthSummaryResponse.teamMonthlyContractedCount
          ? this.MarketerLeadsMonthSummaryResponse.teamMonthlyContractedCount
          : [];
      this.isLoading = false;
    }
  };

  @observable SelectedViewModel: ViewModel | undefined;
  @observable rows: number = 10;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action onRowClick = async (key: string) => {
    console.log("row click...." + JSON.stringify(key));
  };

  @action goToContracted = async () => {
    console.log("going to contracted");
    routes.contractedLeads.push();
  };
  @action addNewSaleOpsAgentLead = async () => {
    routes.addSaleOpsLead.push();
  };

  @observable first: number = 0;
  @observable startIndex: number = 0;

  @observable totalRecords: number | undefined = 0;
  private onClose?: () => void;
  @observable
  private loading = false;

  @action onPage(firstIndex: number, rows: number) {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    this.goSearch();
  }

  @observable isContractingSent: boolean = true;
  @observable AgentsList = new Collection<AgentListModel>();
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable selectedUser: string = "";
  @observable uplineId: number = 0;
  @observable LeadId: string = "";
  @observable isWarningDisplayed: boolean = false;
  @observable isUplineWarning: boolean = false;
  @observable selectedMarketingEvent: string = "Select Marketing Event";
  @observable agentId: number | undefined;
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  public ErrorModel = new ErrorModel(new LeadListValidator());
  @action setSelectedUser(value: string) {
    this.selectedUser = value;
  }
  @action setUplineId = async (value: number) => {
    this.uplineId = value;
  };
  @action setLeadId = async (value: string) => {
    this.LeadId = value;
  };
  @action fillterUsers = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          this.AgentsList.values = result.data;
          this.FilteredAgentsList.values = result.data;
        }
      }
    }
  };
}

class LeadListValidator extends AbstractValidator<SalesLeadListViewModel> {
  public constructor() {
    super();
  }
}

export interface TabItems {
  label: MainLeadTabs;
  value: number;
}
export interface SubTabItems {
  label: SalesLeadTabs;
  value: number;
}
