import { observable, action } from "mobx";

class LeadsTabContext {
  @observable selectedTab: string | undefined = "";
  @observable isGotoLeadFromAgent: boolean = false;

  @action getSelectedTab = () => {
    return this.selectedTab;
  };

  @action setSelectedTab = (value: number) => {
    this.selectedTab = value.toString();
  };
  @action getIsGotoLeadFromAgent = () => {
    return this.isGotoLeadFromAgent;
  };
  @action setIsGotoLeadFromAgent = (value: boolean) => {
    this.isGotoLeadFromAgent = value;
  };
}

export default new LeadsTabContext();
