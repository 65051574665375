import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";

import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import Collection from "../../../infrastructure/CollectionHelper";
import {
  LevelListModel,
  ListItem,
  ProductModel,
  SubCategoryModel,
} from "../../../services/ContractService";
import ContractsStore from "../../../store/ContractsStore";
import Utils from "../../../infrastructure/Utils";
import AccountingStore from "../../../store/AccountingStore";
import {
  CreateUpdatePaymentPlanModel,
  CreateUpdatePaymentPlanRequest,
} from "../../../services/AccountingService";

export class ContractAddEditPaymentPlanViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable agentId: number = 0;
  @observable contractId: number = 0;
  @observable companyId: number = 0;
  @observable paymentPlanId: number = 0;
  @observable specialInstruction: string = "";
  @action Load = async (agentId: number) => {
    this.agentId = agentId;
    this.contractId = RouteParamDetails.contractId;
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable isLoading: boolean = false;
  @observable isEdit: boolean = false;

  @observable isShowAll: boolean = false;
  @observable title: string = "Add Payment Plan";

  @observable commPlan: string = "0";
  @observable paymentSource: string = "1";
  @observable paymentPlanStatus: string = "A";
  @observable commPlanStatus: string = "1";

  @observable companyName: string = "";
  @observable marketer: string = "0";
  @observable marketerName: string = "";
  @observable levelName: string = "";
  @observable levelPayoutLevelId: number = 0;
  @observable paymentTo: string = "0";

  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable exceptionMsg: string = "Something went wrong";
  @observable commPlanChange: boolean = false;
  @observable isShowallChange: boolean = false;
  @observable profileId: number = 0;
  @observable profileCommPlanId: number = 0;

  @observable paymentSourceList = [
    { label: "Premium", value: "1" },
    { label: "Commission", value: "2" },
  ];

  @observable paymentToList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable paymentPlanStatusList = [
    { label: "Active", value: "A" },
    { label: "History", value: "H" },
  ];
  @observable commPlanStatusList = [
    { label: "Active", value: "1" },
    { label: "History", value: "2" },
  ];
  @observable commPlanList = [{ label: "Direct Pay", value: "0" }];

  @observable tempProductNameList = new Collection<ListItem>();
  @observable subCategoryIds: any;
  @observable productIds: any;
  @observable productLevelIds: any;
  @observable productIdList: any;

  @observable subcategoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productNameList = new Collection<{
    label: string;
    value: string;
  }>();

  @action async addEditPaymentPlan(
    contractId: number,
    agentId: number,
    requestPayload: LevelListModel,
    companyName: string,
    companyId: number,
    isEdit: boolean
  ) {
    this.resetFields();
    this.isEdit = isEdit;

    this.agentId = agentId;
    this.contractId = contractId;
    this.companyName = companyName;
    this.companyId = companyId;
    this.levelName = requestPayload.description || "";
    let levels =
      requestPayload.productIds &&
      requestPayload.productIds.map((i: any) => i.levelId);
    let levelId = levels && levels.length > 0 ? levels[0] : 0;

    this.levelPayoutLevelId = levelId || 0;
    this.marketerName = requestPayload.marketer || "";
    this.marketer = requestPayload.marketerId + "" || "0";
    this.specialInstruction = requestPayload.specialInstructions || "";
    this.profileId = requestPayload.profileId || 0;
    this.profileCommPlanId = requestPayload.profileCommPlanId || 0;
    let selectedProductIds: any = [];
    let selectedSubcategoryIds: any = [];

    if (this.isEdit) {
      selectedProductIds =
        requestPayload.productIds &&
        requestPayload.productIds.map((i) => i.productId + "");

      selectedSubcategoryIds =
        requestPayload.productIds &&
        requestPayload.productIds.map((i) => i.subCategoryId + "");
    } else {
      selectedProductIds =
        requestPayload.productIds &&
        requestPayload.productIds
          .filter((i) => {
            return !i.hidden;
          })
          .map((i) => i.productId + "");

      selectedSubcategoryIds =
        requestPayload.productIds &&
        requestPayload.productIds
          .filter((i) => {
            return !i.hidden;
          })
          .map((i) => i.subCategoryId + "");

      selectedSubcategoryIds = [
        ...new Set(selectedSubcategoryIds.map((i) => i)),
      ];
    }

    this.productLevelIds =
      requestPayload.productIds &&
      requestPayload.productIds.map((i) => i.levelId || 0);

    this.productIdList = requestPayload.productIds;
    await this.loadLookups(requestPayload);
    this.productIds = selectedProductIds;
    this.subCategoryIds = selectedSubcategoryIds;

    if (this.isEdit) {
      let subData = [
        {
          label: requestPayload.subCategoryName + "",
          value: requestPayload.subCategoryId + "",
        },
      ];
      let productData = [
        {
          label: requestPayload.productName + "",
          value: requestPayload.productId + "",
        },
      ];
      this.productNameList.values = productData;
      this.subcategoryList.values = subData;
      this.paymentTo = requestPayload.profilePayToId + "";
      await this.setCommisionPlan(requestPayload.profileCommPlanId + "");
      this.setCommPlanStatus("1");
      this.setPaymentPlanStatus(requestPayload.profileStatus + "");
      this.setPaymentSource(requestPayload.profilePaymentSourceId + "");
      await this.loadPay1stYearTo();
      this.setPaymentTo(requestPayload.profilePayToId + "");
    }
    this.enableSave();
  }

  @action resetFields = () => {
    this.isEdit = false;
    this.isShowAll = false;
    this.title = "Add Payment Plan";
    this.commPlan = "0";
    this.paymentSource = "1";
    this.paymentPlanStatus = "A";
    this.commPlanStatus = "1";
    this.companyName = "";
    this.marketer = "0";
    this.marketerName = "";
    this.levelName = "";
    this.levelPayoutLevelId = 0;
    this.paymentTo = "0";
    this.isException = false;
    this.isSuccess = false;
    this.commPlanChange = false;
    this.isShowallChange = false;
    this.productIds = [];
    this.subCategoryIds = [];
    this.productNameList.values = [];
    this.subcategoryList.values = [];
    this.profileId = 0;
  };

  @action loadCommPlan = async (showAll: boolean) => {
    try {
      //this.setIsLoading(true);
      //this.setIsException(false);
      var result = null;
      var commPlanIdP = this.profileCommPlanId;
      if (this.companyId) {
        result = await AccountingStore.getCommPlan(
          +commPlanIdP,
          this.companyId,
          this.levelPayoutLevelId,
          +this.commPlanStatus,
          showAll
        );
        if (result) {
          this.commPlanList = [];
          var sortList = [...result].sort(Utils.compareListItem);
          var sortListUnique = sortList.filter(
            (v, i, a) => a.findIndex((t) => t.value === v.value) === i
          );
          let defaultValue = "0";
          for (let item of sortListUnique) {
            this.commPlanList.push({
              label: "" + item.text,
              value: "" + item.value,
            });
            if (item.text === "Direct Pay") {
              defaultValue = "" + item.value;
            }
          }
          if (this.isEdit) {
            this.setCommisionPlan(commPlanIdP ? "" + commPlanIdP : "0");
          } else {
            if (this.commPlanList.length > 1) {
              defaultValue = "0";
            }
            this.setCommisionPlan(defaultValue);
          }
          console.log(defaultValue);
          //this.setIsLoading(false);
        }
      }
    } catch (e) {
      //this.setIsLoading(false);
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action loadLookups = async (request: LevelListModel) => {
    try {
      //this.setIsLoading(true);
      //this.setIsException(false);
      let levelId =
        this.productLevelIds && this.productLevelIds.length > 0
          ? this.productLevelIds[0]
          : 0;

           var result = await AccountingStore.getPaymentPlanLookupDetails(
        this.agentId,
        this.contractId,
        this.companyId,
        this.productLevelIds,
        request.marketerId,
        levelId,
        request.profileCommPlanId || 0
      );
      if (result) {
        this.tempProductNameList.values = result.productList || [];
        let productNameList = JSON.parse(JSON.stringify(result.productList));
        let subcategoryList = JSON.parse(
          JSON.stringify(result.subcategoryList)
        );

        this.subcategoryList.values = this.mapListItemsAsList(
          subcategoryList || [],
          "",
          ""
        );
        this.productNameList.values = this.mapListItemsAsList(
          productNameList || [],
          "",
          ""
        );
        var sortList = [...(result.commissionPlanList || [])].sort(
          Utils.compareListItem
        );
        var sortListUnique = sortList.filter(
          (v, i, a) => a.findIndex((t) => t.value === v.value) === i
        );

        this.commPlanList = Utils.mapListItemsAsList(
          sortListUnique || [],
          "",
          ""
        );
        let directPayValue = this.commPlanList.find((i) => {
          return i.label == "Direct Pay";
        });
        if (this.commPlanList.length == 1) {
          this.commPlan = directPayValue ? directPayValue.value : "0";
          this.commPlanChange = true;
        }

        this.paymentSourceList = Utils.mapListItemsAsList(
          result.paymentSourceList || [],
          "",
          ""
          );

          var selectedPaymentSource = result.paymentSourceList && result.paymentSourceList.filter((item: any) => {
              return item.selected == true;
          });
          if (selectedPaymentSource != null) {
              this.paymentSource = "" + selectedPaymentSource[0].value;
          }

        Utils.mapListItemsToDropdown(
          result.payYearCommissionList || [],
          this.paymentToList,
          "",
          ""
        );
      }
    } catch {}
  };

  @action mapListItemsAsList(
    listItems: any[],
    defaultName: string = "All",
    defaultValue: string = "0"
  ) {
    var assign = [];
    if (defaultName !== "") {
      var defaultItem = { label: defaultName, value: defaultValue };
      assign.push(defaultItem);
    }
    if (listItems != null) {
      listItems.forEach((item) => {
        var data = {
          label: item.text ? item.text : "",
          value: item.value ? item.value : "",
          disabled: item.disabled ? item.disabled : false,
          hidden: item.hidden ? item.hidden : false,
        };
        assign.push(data);
      });
    }
    return assign;
  }

  @action loadPaymentSource = async (check: boolean | undefined) => {
    try {
      //this.setIsLoading(true);
      //this.setIsException(false);
      var result = await AccountingStore.getPaymentSource(+this.commPlan);
      this.paymentSourceList = [];
      if (result) {
        this.paymentSourceList = this.mapListItemAndSort(result);
        var selectedPaymentSource = result.filter((item: any) => {
          return item.selected == true;
        });
        var flag = true;
        if (
          (!this.commPlanChange && this.isShowallChange) ||
          (this.isEdit && this.isShowallChange)
        ) {
          for (let item of this.paymentSourceList) {
            if (this.paymentSource == item.value) flag = false;
          }
        }

        if (flag && selectedPaymentSource != null) {
          this.paymentSource = "" + selectedPaymentSource[0].value;
        }


        //this.setIsLoading(false);
      }
    } catch (e) {
      //this.setIsLoading(false);
    }
    this.commPlanChange = false;
    this.isShowallChange = false;
  };
  @action loadPay1stYearTo = async () => {
    let levelId =
      this.productLevelIds && this.productLevelIds.length
        ? this.productLevelIds[0]
        : 0;
    this.paymentToList.values = [];
    var result = await AccountingStore.getPayFirstYearTo(
      this.agentId,
      +levelId,
      +levelId,
      +this.paymentTo,
      +this.marketer
    );

    if (result.firstToYearList) {
      Utils.mapListItemsToDropdown(
        result.firstToYearList || [],
        this.paymentToList,
        ""
      );
      if (this.isEdit) {
        this.setPaymentTo("" + this.paymentTo || "0");
      } else {
        this.setPaymentTo("0");
      }
    }
  };
  @action setCommisionPlan = async (value: string) => {
    this.commPlanChange = true;
    this.commPlan = value;
    if (value && value !== "0") {
      await this.loadPaymentSource(true);
    }
    this.enableSave();
  };
  @action setProduct(value: any) {
    this.productIds = value;
  }
  @action setSubcategory(value: any) {
    this.subCategoryIds = value;
  }
  @action setPaymentPlanStatus = async (value: string) => {
    this.paymentPlanStatus = value;
  };
  @action setCommPlanStatus = async (value: string) => {
    this.commPlanStatus = value;
  };
  @action setPaymentSource = async (value: string) => {
    this.paymentSource = value;
  };
  @action setPaymentTo(value: string) {
    this.paymentTo = value;
  }

  @action setShowAll = async (value: boolean) => {
    this.isShowAll = value;
  };

  @observable isSave: boolean = false;
  @action enableSave = () => {
    this.isSave = false;
    if (
      this.subCategoryIds &&
      this.subCategoryIds.length > 0 &&
      this.productIds &&
      this.productIds.length > 0 &&
      this.paymentTo &&
      this.paymentTo != "0" &&
      this.commPlan &&
      this.commPlan != "0"
    )
      this.isSave = true;
  };

  @action Submit = async () => {
    if (this.isSave) {
      if (this.isEdit) {
        let level =
          this.productIdList && this.productIdList.length > 0
            ? this.productIdList[0]
            : {};
        var dto: CreateUpdatePaymentPlanModel = {
          commPlanId: +this.commPlan,
          paymentSourceId: +this.paymentSource,
          companyId: +this.companyId,
          levelId: level ? level.levelId : 0,
          payToId: +this.paymentTo,
          productId: level ? level.productId : 0,
          status: this.paymentPlanStatus,
          mgrId: +this.marketer,
          isActive: false,
          dateCreated: new Date(),
          id: this.profileId,
        };
        await AccountingStore.editPaymentPlan(dto);
        Utils.showSuccessToaster("Payment plan updated.", 2400);
      } else {
        let modelList: any = [];
        if (this.productIds && this.productIds.length > 0) {
          this.productIds.forEach((productId: any) => {
            let level =
              this.productIdList &&
              this.productIdList.find((i: any) => {
                return i.productId == productId;
              });

            var dto: CreateUpdatePaymentPlanModel = {
              commPlanId: +this.commPlan,
              paymentSourceId: +this.paymentSource,
              companyId: +this.companyId,
              levelId: level ? level.levelId : 0,
              payToId: +this.paymentTo,
              productId: productId,
              status: this.paymentPlanStatus,
              mgrId: +this.marketer || 0,
              isActive: false,
              dateCreated: new Date(),
              id: 0,
            };
            modelList.push(dto);
          });
        }
        let dtoList = {
          model: modelList,
        } as CreateUpdatePaymentPlanRequest;
        await AccountingStore.createUpdatePaymentPlan(dtoList);
        Utils.showSuccessToaster("Payment plan created.", 2400);
      }
    }
  };
}
