import { observable, action, computed } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import {
  CreditCardChargeModel,
  CreditCardChargeResponseModel,
} from "../../../../services/AccountingService";
import { routes } from "../../../../router";
import { CreditCardChargeEditViewModel } from "./CreditCardChargeEditViewModel";
import AccountStore from "../../../../store/AccountingStore";

export class CreditCardChargeListViewModel implements ViewModel {
  @observable
  CreditCardChargeListModel: CreditCardChargeResponseModel | null = null;
  @observable CreditCardCharges = new Collection<CreditCardChargeModel>();
  @observable CreditCardChargeListViewModel:
    | CreditCardChargeListViewModel
    | undefined = undefined;
  @observable CreditCardChargeEditViewModel:
    | CreditCardChargeEditViewModel
    | undefined = undefined;  
    @observable title: string = "Credit Card Charge Requests";
  @observable sortCreditCardCharges: boolean = false;
  @observable sortColumnCreditCardCharges: string = "id";

  @action setSortOrder() {
    this.sortCreditCardCharges = !this.sortCreditCardCharges;
  }
  @action setSortColumn(column: string) {
    this.sortColumnCreditCardCharges = column;
  }

  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  @computed
  get totalRecords(): number {
    if (
      this.CreditCardChargeListModel &&
      this.CreditCardChargeListModel.recordCount
    ) {
      return this.CreditCardChargeListModel.recordCount;
    } else {
      return 0;
    }
  }

  @action
  public Load = async (
    OnSave?: (CreditCardChargeID: number) => void,
    OnClose?: () => void,
    agent?: number | undefined,
    isRecordUpdated?: boolean
  ): Promise<void> => {    
    this.onClose = OnClose;
    this.agentId = agent;    
    this.sortCreditCardCharges = false;
    this.sortColumnCreditCardCharges = "id";
    this.CreditCardCharges.values = [];
    if (isRecordUpdated) this.resetPaging();   
    this.loadList();
  };

  @observable SelectedViewModel: ViewModel | undefined;
  @observable rows: number = 10;
  @observable agentId: number | undefined = undefined; 
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @action updateCharge = async (id?: number) => {
    routes.editCreditCardCharge.push({ CreditCardChargeID: id || 0 });
  };
  @action deleteDeposit = async (model: CreditCardChargeModel) => {
    // if (window.confirm("Are you sure?")) {
    // }
  };

  @action resetPaging = () => {
    this.first = 0;
    this.rows = 10;
    this.startIndex = 0;
  };

  @action noRecordsFound() {
    this.CreditCardCharges.values = [];
  }

  @action loadList = async () => {    
    try {
      this.CreditCardChargeListModel = await AccountStore.loadCreditCardChargeList(
        this.agentId,
        this.startIndex,
        this.rows,
        this.sortColumnCreditCardCharges,
        this.sortCreditCardCharges
      );

      if (this.CreditCardChargeListModel && this.CreditCardChargeListModel.data)
        this.CreditCardCharges.values = this.CreditCardChargeListModel.data;
      else {
        this.noRecordsFound();
      }

     
    } catch (e) {     
      this.noRecordsFound();
    }    
  };

  @action loaded = (charges: Collection<CreditCardChargeModel>) => {
    this.CreditCardCharges = charges;   
  };

  @action onRowClick = async (key: string) => {
    console.log("row click...." + JSON.stringify(key));
  }; 

  @observable first: number = 0;
  @observable startIndex: number = 0;
  private onClose?: () => void;
  @observable
  private loading = false;

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;   
    this.loadList();
  }
}
