import { observable, action } from "mobx";
import AutoDialerStore from "../../store/AutoDialerStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { ExistingCallListResponse } from "../../services/AutoDialerService";
import IMASLog from "../../infrastructure/IMASLog";
import { routes } from "../../router";
import { Constants } from "../../infrastructure/enum/Constants";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { StartCallingViewModel } from '../../viewModels/autoDialers/StartCallingViewModel';


export class ExistingCallListViewModel implements ViewModel {
    // ViewModel functions implementation
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => {
        this.resetPagingValues();
        await this.loadAllExistingCallList();        
    };

    // declaration for route
    Route = async (currentRoute: RouteList): Promise<void> => { }

    // declaration for variables
    @observable callListId: number = 0;
    // paging 
    @observable rows: number = 25; // rows is pagesize
    @observable totalRecords: number = 0;
    @observable first: number = 0; // first is pageindex
    @observable startIndex: number = 0;
    // loader
    @observable isLoading: boolean = true;
    // page input variables
    @observable searchInputValue: string = ''
    @observable searchValue: string | undefined = undefined
    @observable sortColumn: string = 'callListName'
    @observable sortOrder: boolean = true;
    // others
    @observable isException: boolean = false
    @observable isWarning: boolean = false
    @observable title: string = '';
    @observable exceptionMessage: string = Constants.Error;
    // @observable isException: boolean = false
    @observable isSuccess: boolean = false
    // @observable isLoading: boolean = false;

    // lists variables
    @observable existingCallList = new Collection<ExistingCallListResponse>()
    @observable StartCallingViewModel: StartCallingViewModel = new StartCallingViewModel();
    @observable selectedCallListRow: any = {};
    // Functions implementation
    @action resetPagingValues() {
        this.rows = 25;
        this.totalRecords = 0;
        this.startIndex = 0;
        this.first = 0;
        this.sortColumn = 'callListName';
        this.sortOrder = true;
    }
    @action loadAllExistingCallList = async () => {
        //this.isLoading = true;   
        try {
            var result = await AutoDialerStore.loadExistingCallingList( this.startIndex,
                this.rows,
                this.sortColumn,
                this.sortOrder,                
                0 );
            if (result.data) {
                this.totalRecords = (result.recordCount) ? result.recordCount : 0;
                var output: any = [];
                output = result.data;
                this.existingCallList.values = output;
            }
            else {
                this.existingCallList.values = [];
                this.totalRecords = 0;
            }
        } catch (e) {
            IMASLog.log('exception from store: ' + e.value);
            this.existingCallList.values = [];
            this.totalRecords = 0;
            this.isException = true;
        }
        // this.isLoading = false;
    }
    @observable isRemoveConfirm: boolean = false;
    @action setRemoveConfirmation(value: boolean) {
        this.isRemoveConfirm = value;
    }
    @action onRemoveClick = async (value: any) => {
        try {
            this.selectedCallListRow = value;
            var obj = JSON.parse(JSON.stringify(value));
            await AutoDialerStore.removeCallingList(obj.callListId);
            this.setRemoveConfirmation(false);
            await this.loadAllExistingCallList();
        } catch (error) {
            IMASLog.log("exception while removing the Calllist.");
            this.isException = true;
            this.setRemoveConfirmation(false);
            await this.loadAllExistingCallList();
        }
    };

    @action startCalling(rowData: any) {
        this.callListId = rowData.callListId;
        routes.startCalling.push({
            callListId: this.callListId ? this.callListId : 0,
        });
    }

    // Events
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        this.loadAllExistingCallList();
    }
    @action setSelectedCallList = (callListId: number) => {
        this.callListId = callListId;
    }
    @action setSelectedRow = (value: any) => {
        this.selectedCallListRow = value;
    }
    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    @action setIsWarning(value: boolean) {
        this.isWarning = value;
    }

    // event for reset
    @action
    public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
    @action resetFilters = () => {
        this.resetPagingValues();
        this.loadAllExistingCallList();
    }

    @action setIsSuccess(value: boolean) {
        this.isSuccess = value;
    }
    @action
    public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }

    @observable
    public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<ExistingCallListViewModel>{
    public constructor() {
        super();

    }
}