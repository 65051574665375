import React from "react";
import { observer } from "mobx-react";
import { AnnouncementsViewModel } from "./../../viewModels/home/AnnouncementsViewModel";

export const AnnouncementsView: React.FC<{
  vm: AnnouncementsViewModel;
}> = observer((props) => {
  return (
    <>      
        <div className="content-wrapper">
          {props.vm.Announcements.values.length > 0 ? (
            props.vm.Announcements.values.map((ind) => {
              return (
                <div className="content-box">
                  {ind.message ? ind.message : ""}
                </div>
              );
            })
          ) : (
            <div
              className="content-box"
              style={{ backgroundColor: 'transparent',paddingLeft: "0" }}
            >
              No Announcements for today!
            </div>
          )}
        </div>    
    </>
  );
});
