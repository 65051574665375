import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator"; //"../infrastructure/AdalAuthenticator";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import {
  RoleModel,
  UserModel,
  Client as IdentityServiceClient,
  AppSettingUpdateModel,
  PasswordListModel,
  ActionLogModel,
  AddUsersToRoleRequest,
  UserPropertiesRequest,
  DownloadAuditTrailModel,
} from "../services/IdentityService";
import {
  Client as MessagingServiceClient,
  ProgressInfo,
  UserConnection,
} from "../services/MessagingService";
import { Client as InviteService } from "../services/InviteService";

class UserStore {
  isDownlineAgencyPreviousValue: any = false;
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): IdentityServiceClient => {
    return ServiceClient.getClient(ServiceType.Identity);
  };
  getMessagingClient = (): MessagingServiceClient => {
    return ServiceClient.getClient(ServiceType.Messaging);
  };
  getInviteClient = (): InviteService => {
    return ServiceClient.getClient(ServiceType.InviteService);
  };

  @action getUserList = async (
    searchKey: string,
    startIndex: number,
    pageSize: number
  ) => {
    return await this.getClient().getAllUserLookup(
      this.getApiVersion(),
      startIndex,
      pageSize,
      undefined,
      undefined,
      searchKey,
      UserContext.getUserId()
    );
  };

  @action getActionList = async () => {
    return await this.getClient().getAllUserActionLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllUsers = async (searchText: string) => {
    return await this.getClient().getUserLookup(
      this.getApiVersion(),
      0,
      1000,
      undefined,
      undefined,
      searchText,
      UserContext.getUserId()
    );
  };

  @action getAuditTrailUserLookup = async (searchText: string) => {
    return await this.getClient().getAuditTrailUserLookup(
      this.getApiVersion(),
      0,
      1000,
      undefined,
      undefined,
      searchText,
      UserContext.getUserId()
    );
  };
  @action loadChangedValuesByRow = async (auditId: number, entityName: string) => {
    return await this.getClient().getAuditReportChangeSetDetail(
      this.getApiVersion(),
      auditId,
      entityName,    
      UserContext.getUserId()
    );

  };

  @action getUserPermissions = async (
    impersonatedUserId: string | undefined
  ) => {
    return await this.getClient().getPermissions(
      this.getApiVersion(),
      impersonatedUserId
    );
  };

  @action getImpersonateUserList = async () => {
    var auth = await this.getApiKey();
    return await this.getClient().getImpersonatedUsers(
      this.getApiVersion(),
      UserContext.getUserId(),
      auth
    );
  };

  @action getUserTeamList = async () => {
    return await this.getClient().getAllUserTeamLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getUsersScreenLookup = async (id: number) => {
    return await this.getClient().getUsersScreenLookup(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action updateAppSetting = async (dto: AppSettingUpdateModel) => {
    return await this.getClient().updateAppSetting(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action updateUserPropertiesById = async (dto: UserPropertiesRequest) => {
    return await this.getClient().updateUserPropertiesById(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action getUserActivities = async (
    userId: number,
    actionId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    return await this.getClient().getAllUserActivity(
      this.getApiVersion(),
      userId,
      actionId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
  };

  @action auditTrail = async (
    userGuId: string,
    searchText: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    return await this.getClient().auditTrail(
      this.getApiVersion(),
      0,
      userGuId && userGuId === "0" ? "" : userGuId,
      null,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      searchText,
      UserContext.getUserId()
    );
  };

  @action auditReport = async (
    userGuId: string,
    action: string,
    entity: string,
    fromDate: Date | undefined,
    toDate: Date | undefined,
    agentId: number | undefined,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {

    return await this.getClient().auditReport(
      this.getApiVersion(),
      undefined,
      userGuId && userGuId === "0" ? "" : userGuId,
      action,
      entity,
      fromDate,
      toDate,
      agentId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId(),
      undefined
    );
  };

  @action getAllRole = async (
    isDownlineRole: number | null,
    startIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean,
    searchKey: string
  ) => {
    return await this.getClient().getAllRole(
      this.getApiVersion(),
      isDownlineRole,
      startIndex,
      pageSize,
      sortColumn,
      sortOrder,
      searchKey,
      UserContext.getUserId()
    );
  };

  @action getDownlineAgencyUserLookup = async (
    agencyIds: number[],
    searchText: string,
    isDownline: boolean
  ) => {
    return await this.getClient().getDownlineAgencyUserLookup(
      this.getApiVersion(),
      agencyIds,
      isDownline,
      undefined,
      undefined,
      undefined,
      undefined,
      searchText,
      UserContext.getUserId()
    );
  };
  @action updateRole = async (item: RoleModel) => {
    return await this.getClient().updateRole(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action cloneRole = async (item: RoleModel) => {
    return await this.getClient().cloneRole(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action checkRoleExists = async (
    id: number,
    roleName: string,
    isCreateMode: boolean
  ) => {
    return await this.getClient().checkRoleExists(
      id,
      roleName,
      isCreateMode,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
  };

  @action addRole = async (item: RoleModel) => {
    return await this.getClient().createRole(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action getRoleById = async (id: number) => {
    return await this.getClient().getRoleById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getUsersByRoleId = async (roleId: number) => {
    return await this.getClient().getUsersByRoleId(
      roleId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllTeam = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean,
    searchKey: string
  ) => {
    return await this.getClient().getAllTeam(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortOrder,
      searchKey,
      UserContext.getUserId()
    );
  };
  @action updateTeam = async (item: TeamModel) => {
    return await this.getClient().updateTeam(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };
  @action addTeam = async (item: TeamModel) => {
    return await this.getClient().createTeam(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };
  @action getTeamById = async (id: number) => {
    return await this.getClient().getTeamById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action checkTeamExists = async (
    id: number | undefined,
    teamName: string | undefined,
    isCreateMode: boolean
  ) => {
    return await this.getClient().checkTeamExists(
      this.getApiVersion(),
      id,
      teamName,
      isCreateMode,
      UserContext.getUserId()
    );
  };

  @action deleteTeam = async (id: number) => {
    return await this.getClient().deleteTeam(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllUser = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean,
    searchType: number,
    searchKey: string,
    statusType: number
  ) => {
    return await this.getClient().getAllUser(
      this.getApiVersion(),
      statusType,
      searchType,
      startIndex,
      pageSize,
      sortColumn,
      sortOrder,
      searchKey,
      UserContext.getUserId()
    );
  };

  @action getUserById = async (id: number): Promise<UserModel> => {
    return await this.getClient().getUserById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action deleteRole = async (id: number) => {
    return await this.getClient().deleteRole(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllTeams = async () => {
    return await this.getClient().getAllUserTeam(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllMarketers = async () => {
    return await this.getClient().getAllMarketerLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllUPNs = async (
    searchValue: string,
    pageIndex: number,
    pagesize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    return await this.getClient().getAllUpnLookup(
      this.getApiVersion(),
      pageIndex,
      pagesize,
      sortColumn,
      sortOrder,
      searchValue,
      UserContext.getUserId()
    );
  };

  @action getAllUserRoles = async (userId: number) => {
    return await this.getClient().rolesByUserId(
      userId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action updateUser = async (item: UserModel) => {
    return await this.getClient().updateUser(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action addUser = async (item: UserModel) => {
    return await this.getClient().createUser(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action getAdUserDetailsByUpn = async (upnId: string) => {
    return await this.getClient().getAdUserDetailsByUpn(
      this.getApiVersion(),
      upnId,
      UserContext.getUserId()
    );
  };

  @action appSettingByRoleId = async (roleId: number) => {
    return await this.getClient().appSettingByRoleId(
      roleId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  // Password List CRUD
  @action
  getPasswordList = async (
    companyId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string = "Id",
    isSortAscending: boolean = true,
    cmSearchInputValue: string
  ) => {
    return await this.getClient().getAllPasswordList(
      this.getApiVersion(),
      companyId,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      cmSearchInputValue,
      UserContext.getUserId()
    );
  };

  @action
  getPasswordListById = async (id: number): Promise<PasswordListModel> => {
    return await this.getClient().getPasswordListById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action
  updatePasswordList = async (item: PasswordListModel) => {
    return await this.getClient().updatePasswordList(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  addPasswordList = async (item: PasswordListModel) => {
    return await this.getClient().createPasswordList(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  deletePasswordList = async (id: number) => {
    await this.getClient().deletePasswordList(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action createActionLog = async (dto: ActionLogModel) => {
    return await this.getClient().createActionLog(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };

  @action getNotesUsersLookup = async () => {
    return await this.getClient().getNotesUsersLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action getAllWorkflowUsersLookup = async () => {
    return await this.getClient().getAllWorkflowUsersLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action sendPushNotification = async (model: ProgressInfo) => {
    return await this.getMessagingClient().sendPushNotification(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
  };

  @action revokeInvite = async (id: any) => {
    return await this.getInviteClient().revokeInvite(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
  };

  @action createUserConnectionPushNotification = async (
    model: UserConnection
  ) => {
    return await this.getMessagingClient().createUserConnectionPushNotification(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
  };

  @action getAllEmail = async (
    searchKey: string,
    startIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    return await this.getMessagingClient().getAllEmail(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      searchKey,
      UserContext.getUserId()
    );
  };
  @action downloadAttachment = async (filename: string) => {
    let result = await this.getClient().downloadAttachment(
      this.getApiVersion(),
      filename,
      UserContext.getUserId()
    );
    return result;
  };

  @action getUserLookupByRoleId = async (roleId: number) => {
    let result = await this.getClient().getUserLookupByRoleId(
      roleId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllExistingUsersByRoleId = async (roleId: number) => {
    let result = await this.getClient().getAllExistingUsersByRoleId(
      roleId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  //addUsersToRole
  @action addUsersToRole = async (body: AddUsersToRoleRequest) => {
    let result = await this.getClient().addUsersToRole(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  //deleteUserFromRole
  @action deleteUserFromRole = async (roleId: number) => {
    let result = await this.getClient().deleteUserFromRole(
      roleId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  //deleteUserFromRole
  @action getUserByAdGuid = async (adGuid: string) => {
    let result = await this.getClient().getUserByAdGuid(
      this.getApiVersion(),
      adGuid,
      UserContext.getUserId()
    );
    return result;
  };

  @action getUserRoleLookup = async (
    roleId: number,
    isDownlineAgency: boolean
  ) => {
    let result = await this.getClient().getUsersScreenRoleLookup(
      roleId,
      isDownlineAgency,
      this.getApiVersion(),
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getUserRecentActivities = async (userId: number) => {
    let result = await this.getClient().userRecentActivities(
      this.getApiVersion(),
      userId,
      UserContext.getUserId()
    );
    return result;
  };

  @action getUsersAsExcel = async (
    statusType: number,
    searchType: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string,
    isSortAscending: boolean = true,
    cmSearchInputValue: string
  ) => {
    return await this.getClient().getUsersAsExcel(
      this.getApiVersion(),
      statusType,
      searchType,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      cmSearchInputValue,
      UserContext.getUserId()
    );
  };
  @action userAuditReport = async (
    userId: number,
    userGuid: string,
    actionName: string,
    moduleId: number | undefined,
    subModuleName: string,
    fromDate: Date | undefined,
    toDate: Date | undefined,
    agentId: number | undefined,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean | undefined,
    fullTextFilter: string
  ) => {
    return await this.getClient().auditReport(
      this.getApiVersion(),
      userId,
      userGuid,
      actionName,
      fromDate,
      toDate,
      agentId,
      moduleId,
      subModuleName,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      undefined
    );
  };
  @action getAuditModules = async () => {
    return await this.getClient().getAllAuditTrailModuleLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action getAuditSubModules = async (moduleId: number) => {
    return await this.getClient().getAllAuditTrailSubModuleLookup(
      moduleId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };
  @action downloadAuditTrail = async (model: DownloadAuditTrailModel) => {
    return await this.getClient().downloadAuditTrail(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    )
  }
}

export default new UserStore();
