
import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import Utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";
import { AutoDialerActionTypes, AutoDialerConstants, ResidentStateTypeContants } from "../../infrastructure/enum/Constants";
import { PropectiveCallListViewModel } from "./ProspectiveCallListViewModel";
import { ActiveCallListViewModel } from "./ActiveCallListViewModel";
import { ExistingCallListViewModel } from "./ExistingCallListViewModel";
import { StartCallingViewModel } from "./StartCallingViewModel";
import { ContactListRequestModel, GenerateActiveCallListModel, GenerateProspectiveCallListModel, GetActivePreviewRequestModel, GetProspectivePreviewRequestModel, ReportFile, ResidentState4 } from "../../services/AutoDialerService";
import AutoDialerStore from "../../store/AutoDialerStore";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { Permission } from "../../infrastructure/enum/Permission";
import { routes } from "../../router";
import { CreateCustomCallListViewModel } from "./CreateCustomCallListViewModel";

export class AutoDialerViewModel implements ViewModel {
    @observable callingTypes = [
        { label: 'Choose a Call List', value: '0' },
        { label: AutoDialerConstants.Prospective, value: AutoDialerConstants.Prospective },
        { label: AutoDialerConstants.Active, value: AutoDialerConstants.Active },
        { label: AutoDialerConstants.Existing, value: AutoDialerConstants.Existing }
       
    ]

    @observable selectedCallingType: string = "0";
    @observable CallingListModel: any | null = null
    @observable CallingListList = new Collection<any>();
    @observable isMessgeVisible: boolean = false;
    @observable response: any = {};
    @observable marketingEvent: number = 0;
    @observable marketingEventList = new Collection<any>();
    @observable callListName: string = '';
    @observable callListId: number = 0;

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async (_isLoadPage: boolean) => {
        for(let i=0; i<this.callingTypes.length; i++)
        {
                if(this.callingTypes[i].label== AutoDialerConstants.CreateCustom)
                    this.callingTypes.splice( i,1);
        }  
        
        if(Utils.hasUserPermission(Permission.CreateCustomCallList))
        { 
            this.callingTypes.push( 
            { label: AutoDialerConstants.CreateCustom, value: AutoDialerConstants.CreateCustom }); 
        }
        if(Utils.hasUserPermission(Permission.ViewAutoDialer))
        {
        if (_isLoadPage) {
            this.selectedCallingType = "0";
            this.startIndex = 0;
            this.first = 0;
            this.sortColumn = 'agentId';
            this.sortOrder = false;
            let user = Utils.getUserId() || 0;
            this.userId = user;
            this.resetFilters();
            this.noRecordsFound();
            if (Utils.hasUserPermission(Permission.OnlyViewLockedDownAutoDialer)) {
                this.existingCallListViewModel.loadAllExistingCallList();
            }
        }
        else if (this.selectedCallingType === AutoDialerConstants.Existing) {
            this.existingCallListViewModel.loadAllExistingCallList();
        }
    }
    };

    Route = async (): Promise<void> => { }

    @observable endDate: Date = new Date();
    @observable isLoading: boolean = true;
    @observable isContractingSent: boolean = true;
    @observable startIndex: number = 0;
    @observable startSearchIndex: number = 0;
    @observable agentId: number = 0;
    @observable userId: number = 0;
    @observable taskId: number = 0
    @observable rows: number = 25;
    @observable first: number = 0;
    @observable propectiveCallListViewModel = new PropectiveCallListViewModel();
    @observable activeCallListViewModel = new ActiveCallListViewModel();
    @observable existingCallListViewModel = new ExistingCallListViewModel();
    @observable startCallingViewModel = new StartCallingViewModel();
    @observable createCustomCallListViewModel=new  CreateCustomCallListViewModel();
    @observable totalRecords: number = 0;
    @observable isDeleteTaskConfirm: boolean = false;
    @observable selectedType: string = '0';
    @observable selectedUser: string = '0';
    @observable sortColumn: string = 'agentId';
    @observable sortOrder: boolean = false;
    @observable isPreviewResultView: boolean = false;
    @observable isGenerateCallListResultView: boolean = false;

    @observable actionItems: MenuItem[] = [
        {
            label: 'Edit',
            command: () => {
                // this.growl.show({severity:'success', summary:'Updated', detail:'Data Updated'});
            }
        }
    ]
    @action resetFilters = async () => {
        this.selectedCallingType = "0";
        this.startIndex = 0;
        this.first = 0;
        this.endDate = new Date();
        this.sortOrder = false;
        this.sortColumn = 'agentId';
        this.isPreviewResultView = false;
        this.isGenerateCallListResultView = false;
    }

    @action noRecordsFound() {
        this.totalRecords = 0
        this.CallingListList.values = []
    }
    @action setSortOrder() {
        this.sortOrder = !this.sortOrder;
        this.startIndex = 0;
        this.first = 0;
    }
    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    @action onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows
        this.previewResult();
    }
    @action getMarketerEvents = async () => {
        try {
            var items = await AutoDialerStore.getMarketerEvents();
            this.marketingEventList.values = []
            Utils.mapListItemsToDropdown(items, this.marketingEventList, "None", "0");
        } catch (e) {
        }   
    }

    @action generatePreviewResult=async()=>{

        this.startIndex = 0;
        this.first = 0;
        this.previewResult();
    }
    @action previewResult = async () => {

        if (this.selectedCallingType === AutoDialerConstants.Active) {
            this.activeCallListViewModel.validationType = AutoDialerActionTypes.PriviewResult;
            await this.activeCallListViewModel.ResetValidate();
            if (!await this.activeCallListViewModel.Validate()) {
                this.isLoading = true;
                try {
                    let requestmodel = this.toPreviewResultServiceModel();
                    var result = await AutoDialerStore.getActivePreviewResults(requestmodel);
                    if (result != null) {
                        if (result.data) {
                            this.totalRecords = (result.recordCount) ? result.recordCount : 0
                            this.CallingListList.values = result.data;
                            this.isPreviewResultView = true;
                        }
                        else {
                            this.noRecordsFound()
                        }
                    }
                } catch (e) {
                    console.log(e)
                    IMASLog.log('exception from store: ' + e.value)
                    this.noRecordsFound()
                }
                this.isLoading = false;
            }
            else {
                window.scrollTo(0, 0);
            }

        } else if (this.selectedCallingType === AutoDialerConstants.Prospective) {
            this.isLoading = true;
            try {
                let requestmodel = this.toProspectivePreviewResultServiceModel();
                var presult = await AutoDialerStore.getProspectivePreviewResults(requestmodel);
                if (presult != null) {
                    if (presult.data) {
                        this.totalRecords = (presult.recordCount) ? presult.recordCount : 0
                        this.CallingListList.values = presult.data;
                        this.isPreviewResultView = true;
                    }
                    else {
                        this.noRecordsFound()
                    }
                }
            } catch (e) {
                console.log(e)
                IMASLog.log('exception from store: ' + e.value)
                this.noRecordsFound()
            }
            this.isLoading = false;
        }

    }

    @action ganerateCallList = async () => {
        if (Utils.hasUserPermission(Permission.AssignMarketingEventToCallList)) {
            this.getMarketerEvents();
        }        
        this.isGenerateCallListResultView = true;
        this.isPreviewResultView = false;
        this.marketingEvent = 0;
        this.callListName = '';
    }
    @action cancelCallList = async () => {
        this.isGenerateCallListResultView = false;
        this.isPreviewResultView = false;
    }

    @action startCalling(callListId: any) {
        this.callListId = callListId;
        routes.startCalling.push({
            callListId: this.callListId ? this.callListId : 0,
        });
    }

    @action saveCallList = async () => {
        if (this.selectedCallingType === AutoDialerConstants.Active) {
            this.activeCallListViewModel.validationType = AutoDialerActionTypes.GenerateCallList;
            await this.ResetValidate();
            if (!await this.Validate()) {
                this.isLoading = true;
                try {
                    let requestmodel = this.toGenerateCallListServiceModel();
                    var result = await AutoDialerStore.generateActiveCallList(requestmodel);
                    if (result) {
                        this.isPreviewResultView = false;
                        this.isGenerateCallListResultView = false;
                        window.scrollTo(0, 0);
                        setTimeout(() => {
                            this.isMessgeVisible = false;
                          // this.selectedCallingType = AutoDialerConstants.Existing;
                          // this.existingCallListViewModel.Load();
                            this.startCalling(result);
                        }, Utils.timeDelay_Success())
                    }
                    else {
                        this.noRecordsFound()
                    }
                } catch (e) {
                    console.log(e)
                    IMASLog.log('exception from store: ' + e.value)
                    this.noRecordsFound()
                }
                this.isLoading = false;
            }
            else {
                window.scrollTo(0, 0);
                setTimeout(() => {
                    return this.ErrorModel.ResetValidation(this);
                }, 2500)
            }
        } else if (this.selectedCallingType === AutoDialerConstants.Prospective) {
            this.propectiveCallListViewModel.validationType = AutoDialerActionTypes.GenerateCallList;
            await this.ResetValidate();
            if (!await this.Validate()) {
                this.isLoading = true;
                try {
                    let requestmodel = this.toProspectiveGenerateCallListServiceModel();
                    var cresult = await AutoDialerStore.generateProspectiveCallList(requestmodel);
                    if (cresult) {
                        this.isPreviewResultView = false;
                        this.isGenerateCallListResultView = false;
                        window.scrollTo(0, 0);
                        setTimeout(() => {
                            this.isMessgeVisible = false;
                            // this.selectedCallingType = AutoDialerConstants.Existing;
                            // this.existingCallListViewModel.Load();
                            this.startCalling(cresult);
                        }, Utils.timeDelay_Success())
                    }
                    else {
                        this.noRecordsFound()
                    }
                } catch (e) {
                    console.log(e)
                    IMASLog.log('exception from store: ' + e.value)
                    this.noRecordsFound()
                }
                this.isLoading = false;
            }
            else {
                setTimeout(() => {
                    return this.ErrorModel.ResetValidation(this);
                }, 2500)
            }
        }

    }

    @action generateIContactList = async () => {
        this.activeCallListViewModel.validationType = AutoDialerActionTypes.GenerateIContactList;
        await this.activeCallListViewModel.ResetValidate();
        if (!await this.activeCallListViewModel.Validate()) {
            this.isLoading = true;
            try {
                let requestmodel = this.toDownloadIContactListServiceModel();
                var result = await AutoDialerStore.downloadIContactListResults(requestmodel);
                if (result) {
                    this.downloadReport(result, "IContactList");
                }
                else {
                    this.noRecordsFound()
                }
            } catch (e) {
                console.log(e)
                IMASLog.log('exception from store: ' + e.value)
                this.noRecordsFound()
            }
            this.isLoading = false;
        }
        else {
            window.scrollTo(0, 0);
        }
    }

    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };

    @action toPreviewResultServiceModel = () => {
        let { residentStateModel, leadSourceList, licenseCompanyList, licenseNotWithCompanyList,
            licenStatusList, productTypesList, productSalesCompanyList }: {
                residentStateModel: ResidentState4; leadSourceList: any;
                licenseCompanyList: any; licenseNotWithCompanyList: any; licenStatusList: any; productTypesList: any; productSalesCompanyList: any;
            } = this.mapToServiceModel();

        //get label by value for license state
        let licensedStatelabels = [];
        if (this.activeCallListViewModel.licensedState !== 0) {
            licensedStatelabels = this.activeCallListViewModel.licensedStateList.values.filter(item => { 
                return (this.activeCallListViewModel.licensedState.indexOf(item.value) >= 0 && item.isHeader != -1);
            }).map(i => i.abbreviation);
        }

        return {
            pageIndex: this.startIndex,
            pageSize: this.rows,
            sortColumn: this.sortColumn,
            sortAscending: this.sortOrder,
            fullTextFilter: undefined,
            marketer: this.activeCallListViewModel.marketer,
            residentState: residentStateModel,
            licensedInStates: this.activeCallListViewModel.licensedState === 0 ? [] : licensedStatelabels,
            leadSource: leadSourceList,
            licensedInCompany: licenseCompanyList,
            licensedNotInCompany: licenseNotWithCompanyList,
            licenseStatus: licenStatusList,
            agentInAHierachy: this.activeCallListViewModel.hierarchy,
            agentDownline: this.activeCallListViewModel.agentsdownlineAgentId,
            agentInThisDownLine: (this.activeCallListViewModel.agentsdownline === 1),
            lastSpokenDays: this.activeCallListViewModel.lastSpokenTo,
            haveNotSold: this.activeCallListViewModel.productSalesHaveNot,
            soldProductStartingDate: this.activeCallListViewModel.productSalesHaveNot ? this.activeCallListViewModel.productSalesFrom : null,
            soldProductEndingDate: this.activeCallListViewModel.productSalesHaveNot ? this.activeCallListViewModel.productSalesTo : null ,
            soldProductTypes: productTypesList,
            soldProductWithCompany: productSalesCompanyList,
            requireEmail: true
        } as GetActivePreviewRequestModel;
    }
    @action toProspectivePreviewResultServiceModel = () => {
        let residentStateModel: ResidentState4 = this.mapToProspectiveServiceModel();
        let leadSourceList: any = [];
        if (this.propectiveCallListViewModel.leadSource === 0) {
            leadSourceList = [];
        } else {
            this.propectiveCallListViewModel.leadSource.forEach((element: any) => {
                leadSourceList.push(parseInt(element));
            });
        }
        return {
            pageIndex: this.startIndex,
            pageSize: this.rows,
            sortColumn: this.sortColumn,
            sortAscending: this.sortOrder,
            fullTextFilter: undefined,
            residentState: residentStateModel,
            leadSource: leadSourceList
        } as GetProspectivePreviewRequestModel;
    }
    @action toGenerateCallListServiceModel = () => {
        let { residentStateModel, leadSourceList, licenseCompanyList, licenseNotWithCompanyList,
            licenStatusList, productTypesList, productSalesCompanyList }: {
                residentStateModel: ResidentState4; leadSourceList: any;
                licenseCompanyList: any; licenseNotWithCompanyList: any; licenStatusList: any; productTypesList: any; productSalesCompanyList: any;
            } = this.mapToServiceModel();

        //get label by value for license state
        let licensedStatelabels = [];
        if (this.activeCallListViewModel.licensedState !== 0) {
            licensedStatelabels = this.activeCallListViewModel.licensedStateList.values.filter(item => { 
                return (this.activeCallListViewModel.licensedState.indexOf(item.value) >= 0 && item.isHeader != -1);
            }).map(i => i.abbreviation);
        }

        return {
            marketer: this.activeCallListViewModel.marketer,
            residentState: residentStateModel,
            licensedInStates: this.activeCallListViewModel.licensedState === 0 ? [] : licensedStatelabels,
            leadSource: leadSourceList,
            licensedInCompany: licenseCompanyList,
            licensedNotInCompany: licenseNotWithCompanyList,
            licenseStatus: licenStatusList,
            agentInAHierachy: this.activeCallListViewModel.hierarchy,
            agentDownline: this.activeCallListViewModel.agentsdownlineAgentId,
            agentInThisDownLine: (this.activeCallListViewModel.agentsdownline === 1),
            lastSpokenDays: this.activeCallListViewModel.lastSpokenTo,
            haveNotSold: this.activeCallListViewModel.productSalesHaveNot,
            soldProductStartingDate: this.activeCallListViewModel.productSalesHaveNot ? this.activeCallListViewModel.productSalesFrom : null,
            soldProductEndingDate: this.activeCallListViewModel.productSalesHaveNot ? this.activeCallListViewModel.productSalesTo : null,
            soldProductTypes: productTypesList,
            soldProductWithCompany: productSalesCompanyList,
            requireEmail: false,
            callListName: this.callListName,
            marketerEventId: this.marketingEvent ? this.marketingEvent : null
        } as GenerateActiveCallListModel;
    }

    @action toProspectiveGenerateCallListServiceModel = () => {
        let residentStateModel: ResidentState4 = this.mapToProspectiveServiceModel();
        let leadSourceList: any = [];
        if (this.propectiveCallListViewModel.leadSource === 0) {
            leadSourceList = [];
        } else {
            this.propectiveCallListViewModel.leadSource.forEach((element: any) => {
                leadSourceList.push(parseInt(element));
            });
        }

        return {
            residentState: residentStateModel,
            leadSource: leadSourceList,
            callListName: this.callListName,
            marketerEventId: this.marketingEvent ? this.marketingEvent : null
        } as GenerateProspectiveCallListModel;
    }

    @action toDownloadIContactListServiceModel = () => {
        let { residentStateModel, leadSourceList, licenseCompanyList, licenseNotWithCompanyList,
            licenStatusList, productTypesList, productSalesCompanyList }: {
                residentStateModel: ResidentState4; leadSourceList: any;
                licenseCompanyList: any; licenseNotWithCompanyList: any; licenStatusList: any; productTypesList: any; productSalesCompanyList: any;
            } = this.mapToServiceModel();
        
        //get label by value for license state
        let licensedStatelabels = [];
        if (this.activeCallListViewModel.licensedState !== 0) {
            licensedStatelabels = this.activeCallListViewModel.licensedStateList.values.filter(item => { 
                return (this.activeCallListViewModel.licensedState.indexOf(item.value) >= 0 && item.isHeader != -1);
            }).map(i => i.abbreviation);
        }

        return {
            marketer: this.activeCallListViewModel.marketer,
            residentState: residentStateModel,
            licensedInStates: this.activeCallListViewModel.licensedState === 0 ? [] : licensedStatelabels,
            leadSource: leadSourceList,
            licensedInCompany: licenseCompanyList,
            licensedNotInCompany: licenseNotWithCompanyList,
            licenseStatus: licenStatusList,
            agentInAHierachy: this.activeCallListViewModel.hierarchy,
            agentDownline: this.activeCallListViewModel.agentsdownlineAgentId,
            agentInThisDownLine: (this.activeCallListViewModel.agentsdownline === 1),
            lastSpokenDays: this.activeCallListViewModel.lastSpokenTo,
            haveNotSold: this.activeCallListViewModel.productSalesHaveNot,
            soldProductStartingDate: this.activeCallListViewModel.productSalesHaveNot ? this.activeCallListViewModel.productSalesFrom : null,
            soldProductEndingDate: this.activeCallListViewModel.productSalesHaveNot ? this.activeCallListViewModel.productSalesTo : null,
            soldProductTypes: productTypesList,
            soldProductWithCompany: productSalesCompanyList,
            requireEmail: true
        } as ContactListRequestModel;
    }
    private mapToServiceModel() {
        let residentStatelabels= [];
        if (this.activeCallListViewModel.residentState !== 0) {
            residentStatelabels = this.activeCallListViewModel.residentStateList.values.filter(item => { 
                return (this.activeCallListViewModel.residentState.indexOf(item.value) >= 0 && item.isHeader != -1);
            }).map(i => i.abbreviation);
        }
        
        if (this.activeCallListViewModel.residentStateType === ResidentStateTypeContants.State) {
            this.activeCallListViewModel.residentCounty = {};
            this.activeCallListViewModel.residentZipCode = undefined;
        } else if (this.activeCallListViewModel.residentStateType === ResidentStateTypeContants.County) {
            this.activeCallListViewModel.residentState = [];
            this.activeCallListViewModel.residentZipCode = undefined;
        } else if (this.activeCallListViewModel.residentStateType === ResidentStateTypeContants.ZipCode) {
            this.activeCallListViewModel.residentCounty = {};
            this.activeCallListViewModel.residentState = 0;
        }

        let residentCountyList: any = [];
        for (const [key, value] of Object.entries(this.activeCallListViewModel.residentCounty)) {
            if (value && value.checked) {
                if (key.split('-').length > 1) {
                    residentCountyList.push(key.split('-')[1]);
                } 
                else {
                    this.activeCallListViewModel.residentState.push(key);
                }
            }
            else if (value && value.partialChecked) {
                this.activeCallListViewModel.residentState.push(key);
            }
        }
        let residentStateModel = {
            states: this.activeCallListViewModel.residentState === 0 ? undefined : residentStatelabels,
            counties: residentCountyList,
            zipCodes: this.activeCallListViewModel.residentZipCode ? this.activeCallListViewModel.residentZipCode.split(",") : undefined
        } as ResidentState4;
        let licenStatusList: any = [];
        if (this.activeCallListViewModel.licenseStatus === 0) {
            licenStatusList = undefined;
        } else {
            this.activeCallListViewModel.licenseStatus.forEach((element: any) => {
                licenStatusList.push(parseInt(element));
            });
        }
        let licenseCompanyList: any = [];
        if (this.activeCallListViewModel.licensedCompany === 0) {
            licenseCompanyList = [];
        } else {
            this.activeCallListViewModel.licensedCompany.forEach((element: any) => {
                licenseCompanyList.push(parseInt(element));
            });
        }
        let licenseNotWithCompanyList: any = [];
        if (this.activeCallListViewModel.licensedNotwithCompany === 0) {
            licenseNotWithCompanyList = [];
        } else {
            this.activeCallListViewModel.licensedNotwithCompany.forEach((element: any) => {
                licenseNotWithCompanyList.push(parseInt(element));
            });
        }
        let leadSourceList: any = [];
        if (this.activeCallListViewModel.leadSource === 0) {
            leadSourceList = [];
        } else {
            this.activeCallListViewModel.leadSource.forEach((element: any) => {
                leadSourceList.push(parseInt(element));
            });
        }
        let productTypesList: any = [];
        if (this.activeCallListViewModel.productSalesProductType === 0) {
            productTypesList = [];
        } else {
            this.activeCallListViewModel.productSalesProductType.forEach((element: any) => {
                productTypesList.push(parseInt(element));
            });
        }
        let productSalesCompanyList: any = [];
        if (this.activeCallListViewModel.productSalesCompany === 0) {
            productSalesCompanyList = [];
        } else {
            this.activeCallListViewModel.productSalesCompany.forEach((element: any) => {
                productSalesCompanyList.push(parseInt(element));
            });
        }
        return { residentStateModel, leadSourceList, licenseCompanyList, licenseNotWithCompanyList, licenStatusList, productTypesList, productSalesCompanyList };
    }
    private mapToProspectiveServiceModel() {
        let residentStatelabels = [];
        if (this.propectiveCallListViewModel.residentState !== 0) {
            residentStatelabels = this.propectiveCallListViewModel.residentStateList.values.filter(item => { 
                return (this.propectiveCallListViewModel.residentState.indexOf(item.value) >= 0 && item.isHeader != -1);
            }).map(i => i.abbreviation);
        }

        if (this.propectiveCallListViewModel.residentStateType === ResidentStateTypeContants.State) {
            this.propectiveCallListViewModel.residentCounty = {};
            this.propectiveCallListViewModel.residentZipCode = undefined;
        } else if (this.propectiveCallListViewModel.residentStateType === ResidentStateTypeContants.County) {
            this.propectiveCallListViewModel.residentState = [];
            this.propectiveCallListViewModel.residentZipCode = undefined;
        } else if (this.propectiveCallListViewModel.residentStateType === ResidentStateTypeContants.ZipCode) {
            this.propectiveCallListViewModel.residentCounty = {};
            this.propectiveCallListViewModel.residentState = 0;
        }

        let residentCountyList: any = [];
        for (const [key, value] of Object.entries(this.propectiveCallListViewModel.residentCounty)) {
            if (value && value.checked) {
                if (key.split('-').length > 1) {
                    residentCountyList.push(key.split('-')[1]);
                } else {
                    this.propectiveCallListViewModel.residentState.push(key);
                }
            }
            else if (value && value.partialChecked) {
                this.propectiveCallListViewModel.residentState.push(key);
            }
        }
        let residentStateModel = {
            states: this.propectiveCallListViewModel.residentState === 0 ? undefined : residentStatelabels,
            counties: residentCountyList,
            zipCodes: this.propectiveCallListViewModel.residentZipCode ? this.propectiveCallListViewModel.residentZipCode.split(",") : undefined
        } as ResidentState4;

        return residentStateModel;
    }
    @observable ErrorModel = new ErrorModel(new AutoDialerValidator());
    @observable validationType: string = AutoDialerActionTypes.PriviewResult;

    @action Validate = async () => {
        return await this.ErrorModel.Validate(this);
    }
    @action ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
}

class AutoDialerValidator extends AbstractValidator<AutoDialerViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.callListName)
            .isNotEmpty()
            .isNotEqualTo('')
            .withFailureMessage("Call list Name is required.")

    }
}