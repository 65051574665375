import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import utils from "../../infrastructure/Utils";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../infrastructure/IMASLog";
import Collection from "../../infrastructure/CollectionHelper";
import { AgentListModel } from "../../services/ProducerSearchService";
import productStore from "../../store/ProductStore";
import WorkflowStore from "../../store/WorkflowStore";
import { CreateUpdateWorkflowDocumentModel } from "../../services/WorkflowService";
import {
  WorkflowStatusType,
  DocumentType,
} from "../../infrastructure/enum/WorkflowDetails";
import PageContext from "../../infrastructure/PageContext";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import Utils from "../../infrastructure/Utils";
import UserContext from "../../infrastructure/UserContext";
import { routes } from "../../router";
import { googleTagManager } from "../../infrastructure/tracking";
import ContractsStore from "../../store/ContractsStore";

export class AddEditDocumentViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (
    isEdit?: boolean,
    company?: number,
    product?: number,
    companyList?: any
  ) => {
    this.isMessageVisible = false;
    this.selectedCompany = "0";
    if (isEdit) {
      this.selectedUplineAgent = "";
      this.loadLookups(companyList);
      this.selectedCompany = company;
      await this.loadProducts();
      this.selectedProduct = product;
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};
  listener: DocumentListener | undefined;
  @observable isException: boolean = false;
  @observable exceptionMsg: string = "";
  @observable isWorkflowApiCall: boolean = true;

  @observable isMessageVisible: boolean = false;
  @observable response: any;

  @observable id: number = 0;
  @observable isDialogVisible: boolean = false;
  @observable agentId: any;
  @observable lastName: string = "";
  @observable firstName: string = "";
  @observable comments: string = "";

  @observable address: string = "";
  @observable isValid: boolean = true;
  @observable isCustomValidation: boolean = false;

  @observable assignedToUsers: any;
  @observable assignedToUserTeam: any;
  @observable carriers: any;
  @observable noteErrorMessage: string = "200 characters max";
  @observable selectedType: string = "Application";
  @observable replaceConfirmErrorMessage: string =
    "You are about to delete and replace the current document.";
  @observable documentTypeList = [
    { label: "Application", value: "Application" },
    { label: "Contract", value: "Contract" },
    { label: "Contract Change", value: "Contract Change" },
    { label: "Contract Invite", value: "Contract Invite" },
    { label: "Marketer Change", value: "Marketer Change" },
    {
      label: "Non-Workflow Direct-to-User",
      value: "Non-Workflow Direct-to-User",
    },
  ];

  @action setSelectedType = (value: string) => {
    this.selectedUplineAgent = "";
    this.selectedUplineAgentId = undefined;
    this.selectedType = value;
    if (this.selectedType === DocumentType.ApplicationDocType) {
      if (
        localStorage.getItem("isLoadUpline") == "true" &&
        this.selectedAgentId != undefined &&
        this.selectedType == "Application" &&
        this.selectedAgentId > 0 &&
        this.selectedCompany > 0
      ) {
        this.getContractUplineDetail();
      }
    }
    if (this.selectedType === DocumentType.NonWorkflowDocType) {
      this.selectedAssignedToUserTeam = "0";
    }
  };

  @observable selectedFlag: string = "-1";
  @observable flagList = [
    { label: "ALL", value: "-1" },
    { label: "Waiting on $$$", value: "$" },
    { label: "Change Form", value: "X" },
    { label: "Copy", value: "C" },
    { label: "Duplicate", value: "D" },
    { label: "UHC Ftp", value: "F" },
    { label: "Partial", value: "P" },
    { label: "Vue", value: "V" },
    { label: "Received In The Mail", value: "R" },
  ];
  @action setSelectedFlag = (value: string) => {
    this.selectedFlag = value;
  };

  @observable appDate: Date | undefined = new Date();
  @action setAppDate(value: Date | undefined) {
    this.appDate = value;
  }

  @observable selectedFileName: any;
  @action setSelectedFile = (value: string) => {
    this.selectedFileName = value;
  };
  @observable receivedQueueSelectedFileName: string | undefined = "";
  @observable selectedAgent: string = "";
  @observable selectedAgentId: number | undefined;
  @observable agentsSearchList: number = 0;
  @observable filteredAgentsList = new Collection<AgentListModel>();
  @action agentSearchChange(value: string) {
    this.selectedAgent = value;
    if (!value) {
      this.selectedAgentId = undefined;
    }
  }
  @action setSelectedAgent(agentName: any, agentId: number) {
    if (agentId) {
      this.selectedAgent = agentName;
      this.selectedAgentId = agentId;
    } else {
      this.selectedAgent = "";
      this.selectedAgentId = undefined;
    }

    // call API to get upline agent if type is Application

    if (
      localStorage.getItem("isLoadUpline") == "true" &&
      this.selectedAgentId != undefined &&
      this.selectedType == "Application" &&
      this.selectedAgentId > 0 &&
      this.selectedCompany > 0
    ) {
      this.getContractUplineDetail();
    }
  }
  @action searchAgents = async (value: string) => {
    IMASLog.log("search agents ..");
    let result = await ProducerSearchStore.getAgentsLookup(
      utils.getAgentSearchType(value),
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.agentsSearchList = result.recordCount;
        if (result.data) {
          this.filteredAgentsList.values = result.data;
        }
      } else {
        this.agentsSearchList = 0;
        this.filteredAgentsList.values = [noAgent];
      }
    } else {
      this.agentsSearchList = 0;
      this.filteredAgentsList.values = [noAgent];
    }
    setTimeout(() => {}, 500);
  };

  @observable selectedUplineAgent: string = "";
  @observable selectedUplineAgentId: number | undefined;
  @observable uplineAgentsSearchList: number = 0;
  @observable filteredUplineAgentsList = new Collection<AgentListModel>();
  @action uplineAgentSearchChange(value: string) {
    this.selectedUplineAgent = value;
    if (!value) {
      this.selectedUplineAgentId = undefined;
    }
  }
  @action setSelectedUplineAgent(agentName: any, agentId: number) {
    if (agentId) {
      this.selectedUplineAgent = agentName;
      this.selectedUplineAgentId = agentId;
    } else {
      this.selectedUplineAgent = "";
      this.selectedUplineAgentId = undefined;
    }
  }
  @action searchUplineAgents = async (value: string) => {
    IMASLog.log("search upline agents ..");
    let result = await ProducerSearchStore.getAgentsLookup(
      utils.getAgentSearchType(value),
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.uplineAgentsSearchList = result.recordCount;
        if (result.data) {
          this.filteredUplineAgentsList.values = result.data;
        }
      } else {
        this.uplineAgentsSearchList = 0;
        this.filteredUplineAgentsList.values = [noAgent];
      }
    } else {
      this.uplineAgentsSearchList = 0;
      this.filteredUplineAgentsList.values = [noAgent];
    }
  };

  /* 
      Added
       */
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @action loadLookups = async (companyList: any) => {
    this.companyList.values = companyList;
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      listObjects.splice(0, 0, { text: "All ", value: "0", selected: false });
      var sortList = listObjects.sort(utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @observable selectedMarketerAgent: string = "";
  @observable selectedMarketerAgentId: number | undefined;
  @observable marketerAgentsSearchList: number = 0;
  @observable filteredMarketerAgentsList = new Collection<AgentListModel>();
  @action marketerAgentSearchChange(value: string) {
    this.selectedMarketerAgent = value;
    if (!value) {
      this.selectedMarketerAgentId = undefined;
    }
  }
  @action setSelectedMarketerAgent(agentName: any, agentId: number) {
    if (agentId) {
      this.selectedMarketerAgent = agentName;
      this.selectedMarketerAgentId = agentId;
    } else {
      this.selectedMarketerAgent = "";
      this.selectedMarketerAgentId = undefined;
    }
  }
  @action searchMarketerAgents = async (value: string) => {
    IMASLog.log("search marketer agents ..");
    let result = await ProducerSearchStore.getAllMarketersLookup(
      utils.getAgentSearchType(value),
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.marketerAgentsSearchList = result.recordCount;
        if (result.data) {
          this.filteredMarketerAgentsList.values = result.data;
        }
      } else {
        this.marketerAgentsSearchList = 0;
        this.filteredMarketerAgentsList.values = [noAgent];
      }
    } else {
      this.marketerAgentsSearchList = 0;
      this.filteredMarketerAgentsList.values = [noAgent];
    }
    setTimeout(() => {}, 500);
  };

  @observable selectedCompany: any;
  @action setSelectedCompany = (value: number) => {
    this.selectedCompany = value;
    this.loadProducts();
    // call API to get upline agent if type is Application
    if (
      localStorage.getItem("isLoadUpline") == "true" &&
      this.selectedAgentId != undefined &&
      this.selectedType == "Application" &&
      this.selectedAgentId > 0 &&
      this.selectedCompany > 0
    ) {
      this.getContractUplineDetail();
    }
  };

  @observable selectedDocumentId: any;
  @action setSelectedDocumentId = (value: number) => {
    this.selectedDocumentId = value;
  };

  @observable workFlowStatusType: any;
  @action setWorkFlowStatusType(workFlowStatusType: any) {
    this.workFlowStatusType = workFlowStatusType;
  }
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedProduct: any;
  @action setSelectedProduct = (value: number) => {
    this.selectedProduct = value;
  };

  sortTypeName = (a: any, b: any) => {
    let comparison = 0;
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }

    return comparison;
  };

  @action loadProducts = async () => {
    var defaultItem = { label: "None", value: "0" };
    try {
      if (this.selectedCompany && this.selectedCompany > 0) {
        var result = await productStore.getProductLookup(this.selectedCompany);
        if (result != null) {
          if (result.data != null) {
            this.productList.values = [];
            var list = [...result.data].sort(this.sortTypeName);
            list.forEach((obj: any) => {
              var objdata = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.productList.values.push(objdata);
            });
            this.productList.values.splice(0, 0, defaultItem);
          } else {
            this.productList.values = [];
            this.productList.values.splice(0, 0, defaultItem);
            this.selectedProduct = "0";
          }
        }
      } else {
        this.productList.values = [];
        this.productList.values.splice(0, 0, defaultItem);
        this.selectedProduct = "0";
      }
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
      this.productList.values = [];
      this.productList.values.splice(0, 0, defaultItem);
      this.selectedProduct = "0";
    }
  };

  @observable selectedAssignedToUser: any;
  @observable selectedAssignedToUserTeam: any;
  @action setSelectedAssignedToUser = (value: string) => {
    this.selectedAssignedToUser = value;
    if (
      this.selectedAssignedToUser !== "ALL" &&
      this.selectedAssignedToUser !== ""
    ) {
      this.selectedAssignedToUserTeam = "0";
    } else {
      this.selectedAssignedToUser = "0";
    }
  };
  @action setSelectedAssignedToUserTeam = (value: string) => {
    this.selectedAssignedToUserTeam = value;
    if (
      this.selectedAssignedToUserTeam !== "ALL" &&
      this.selectedAssignedToUserTeam !== ""
    ) {
      this.selectedAssignedToUser = "0";
    } else {
      this.selectedAssignedToUserTeam = "0";
    }
  };

  setDocumentListener(listener: DocumentListener) {
    this.listener = listener;
  }

  @action setIsException(value: boolean) {
    this.isException = value;
  }

  @action setLastName(value: string) {
    this.lastName = value;
  }
  @action setFirstName(value: string) {
    this.firstName = value;
  }
  @action setComments(value: string) {
    this.comments = value;
  }
  @action setAgentId(value: number) {
    this.agentId = value;
  }
  @action setDialogVisibility(value: boolean) {
    this.isDialogVisible = value;
  }
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  @action cancel = async () => {
    this.selectedUplineAgent = "";
    if (this.listener) {
      this.listener.cancelAddEditDocument();
    }
  };

  @action reset = () => {
    this.isMessageVisible = false;
    this.response = "";
    this.selectedUplineAgent = "";
    this.selectedUplineAgentId = undefined;
  };

  @action setDialogAutoScrollTop() {
    var dialogelement = document.querySelector(".p-dialog-content");
    if (dialogelement) {
      dialogelement.scrollTo(0, 0);
    }
  }
  @action downloadReceivedQueueDocument = async () => {
    if (this.listener) {
      this.listener.downloadFileListener(this.selectedFileName);
    }
  };
  @action
  public Submit = async () => {
    try {
      if (!(await this.Validate())) {
        this.isException = false;
        this.isMessageVisible = false;
        this.response = "";
        var file = this.selectedFileName;

        if (this.isWorkflowApiCall) {
          var createUpdatedto: CreateUpdateWorkflowDocumentModel = {};
          createUpdatedto.fName = this.firstName ? this.firstName : "";
          createUpdatedto.lName = this.lastName ? this.lastName : "";
          createUpdatedto.agentId =
            this.selectedAgentId && this.selectedAgentId > 0
              ? Number(this.selectedAgentId)
              : 0;
          var date = utils.getDateInFormat(
            this.appDate ? this.appDate : new Date()
          );
          this.setAppDate(new Date(date));
          createUpdatedto.appDate = this.appDate;
          createUpdatedto.typeId =
            this.selectedType.trim() === DocumentType.ApplicationDocType
              ? 1
              : this.selectedType.trim() === DocumentType.ContractDocType
              ? 9
              : this.selectedType.trim() === DocumentType.ContractChangeDocType
              ? 31
              : this.selectedType.trim() === DocumentType.ContractInviteDocType
              ? 42
              : this.selectedType.trim() === DocumentType.MarketerChangeDocType
              ? 43
              : 26;
          if (this.workFlowStatusType === WorkflowStatusType.ReceivedQueue) {
            createUpdatedto.id = 0;
            createUpdatedto.fileName = file ? file : "";
            createUpdatedto.assignedToUserId =
              this.selectedAssignedToUser && this.selectedAssignedToUser > 0
                ? Number(this.selectedAssignedToUser)
                : 0;
            if (
              createUpdatedto.assignedToUserId === 0 &&
              createUpdatedto.typeId === 26
            ) {
              let exceptionMsg = {
                status: 500,
                title: "Select a User if Type = Non-Workflow Direct-to-User",
                errors: { "": [] },
              };
              //auto scroll dialog to top
              this.setDialogAutoScrollTop();

              this.response = JSON.stringify(exceptionMsg);
              this.isMessageVisible = true;
              setTimeout(() => {
                this.isMessageVisible = false;
                this.response = "";
              }, 3000);
              this.isException = true;
              return 0;
            } else {
              this.exceptionMsg = "";
              this.isException = false;
              this.isMessageVisible = false;
              this.response = "";
            }
            createUpdatedto.assignedToTeamId =
              this.selectedAssignedToUserTeam &&
              this.selectedAssignedToUserTeam > 0
                ? Number(this.selectedAssignedToUserTeam)
                : 0;
            createUpdatedto.uplineAgentId = 0;
            createUpdatedto.marketerAgentId = 0;
          } else {
            createUpdatedto.id = this.selectedDocumentId;
            createUpdatedto.fileName = undefined;
            //  createUpdatedto.fileName = this.selectedFileName;
            createUpdatedto.uplineAgentId =
              this.selectedUplineAgentId && this.selectedUplineAgentId > 0
                ? Number(this.selectedUplineAgentId)
                : 0;
            createUpdatedto.marketerAgentId =
              this.selectedMarketerAgentId && this.selectedMarketerAgentId > 0
                ? Number(this.selectedMarketerAgentId)
                : 0;
          }
          createUpdatedto.companyId =
            this.selectedCompany && this.selectedCompany > 0
              ? this.selectedCompany
              : 0;
          if (
            createUpdatedto.companyId === 0 &&
            createUpdatedto.typeId !== 26
          ) {
            let exceptionMsg = {
              status: 500,
              title: "Carrier is required",
              errors: { "": [] },
            };
            //auto scroll dialog to top
            this.setDialogAutoScrollTop();

            this.response = JSON.stringify(exceptionMsg);
            this.isMessageVisible = true;
            setTimeout(() => {
              this.isMessageVisible = false;
              this.response = "";
            }, 3000);
            this.isException = true;
            return 0;
          } else {
            this.exceptionMsg = "";
            this.isException = false;
            this.isMessageVisible = false;
            this.response = "";
          }
          this.response = "";
          createUpdatedto.productId =
            this.selectedProduct && this.selectedProduct > 0
              ? Number(this.selectedProduct)
              : 0;
          createUpdatedto.notes = this.comments ? this.comments : "";
          createUpdatedto.flag =
            this.selectedFlag === "-1" ? "" : this.selectedFlag;

          this.isWorkflowApiCall = false;
          await WorkflowStore.createUpdateWorkflowDocument(createUpdatedto);

          IMASLog.log("document is saved successfully");
          this.isWorkflowApiCall = true;

          if (this.listener) {
            this.listener.addEditDocumentListener();
            this.listener.closeDialogAfterSaveDocument();
          }
          this.setDialogVisibility(false);
        }
      }
    } catch (e: any) {
      this.isWorkflowApiCall = true;
      e && e.text
        ? e.text().then((res: any) => {
            this.response = res;
          })
        : (this.response = e.response);
      this.isMessageVisible = true;
    }
  };

  @observable isReplaceDeleteFileName: boolean = false;
  @observable hideReplaceFileName: boolean = false;
  @observable isDocUpload: boolean = false;
  @observable replaceFileName: string = "";
  @observable replaceFileData: string = "";

  onDropToReplaceDoc = async (value: any, fileName: string) => {
    if (value && fileName) {
      this.isReplaceDeleteFileName = true;
    } else {
      this.isReplaceDeleteFileName = false;
    }
    this.replaceFileData = value;
    this.replaceFileName = fileName;
  };
  @action
  public SubmitForReceivedQueue = async () => {
    try {
      if (!(await this.Validate())) {
        PageContext.setIsLoadingVisible(true);
        this.isException = false;
        this.isMessageVisible = false;
        this.response = "";

        if (this.isWorkflowApiCall) {
          var blob = null;
          var file = this.selectedFileName;
          var formattedFileName = "";

          var createUpdatedto: CreateUpdateWorkflowDocumentModel = {};
          createUpdatedto.fName = this.firstName ? this.firstName : "";
          createUpdatedto.lName = this.lastName ? this.lastName : "";
          createUpdatedto.agentId =
            this.selectedAgentId && this.selectedAgentId > 0
              ? Number(this.selectedAgentId)
              : 0;

          var date = utils.getDateInFormat(
            this.appDate ? this.appDate : new Date()
          );
          this.setAppDate(new Date(date));
          createUpdatedto.appDate = this.appDate;

          createUpdatedto.typeId =
            this.selectedType.trim() === DocumentType.ApplicationDocType
              ? 1
              : this.selectedType.trim() === DocumentType.ContractDocType
              ? 9
              : this.selectedType.trim() === DocumentType.ContractChangeDocType
              ? 31
              : this.selectedType.trim() === DocumentType.ContractInviteDocType
              ? 42
              : this.selectedType.trim() === DocumentType.MarketerChangeDocType
              ? 43
              : 26;

          /*if (this.workFlowStatusType === WorkflowStatusType.ReceivedQueue) {*/
          createUpdatedto.id = 0;
          // createUpdatedto.fileName = file ? file : "";

          createUpdatedto.assignedToUserId =
            this.selectedAssignedToUser && this.selectedAssignedToUser > 0
              ? Number(this.selectedAssignedToUser)
              : 0;
          if (
            createUpdatedto.assignedToUserId === 0 &&
            createUpdatedto.typeId === 26
          ) {
            let exceptionMsg = {
              status: 500,
              title: "Select a User if Type = Non-Workflow Direct-to-User",
              errors: { "": [] },
            };
            //auto scroll dialog to top
            this.setDialogAutoScrollTop();

            this.response = JSON.stringify(exceptionMsg);
            this.isMessageVisible = true;
            setTimeout(() => {
              this.isMessageVisible = false;
              this.response = "";
            }, 3000);
            this.isException = true;
            return 0;
          } else {
            this.exceptionMsg = "";
            this.isException = false;
            this.isMessageVisible = false;
            this.response = "";
          }

          createUpdatedto.assignedToTeamId =
            this.selectedAssignedToUserTeam &&
            this.selectedAssignedToUserTeam > 0
              ? Number(this.selectedAssignedToUserTeam)
              : 0;
          createUpdatedto.uplineAgentId =
            this.selectedUplineAgentId && this.selectedUplineAgentId > 0
              ? Number(this.selectedUplineAgentId)
              : 0;
          createUpdatedto.marketerAgentId = 0;
          /* }  */

          createUpdatedto.companyId =
            this.selectedCompany && this.selectedCompany > 0
              ? this.selectedCompany
              : 0;
          if (
            createUpdatedto.companyId === 0 &&
            createUpdatedto.typeId !== 26
          ) {
            let exceptionMsg = {
              status: 500,
              title: "Carrier is required",
              errors: { "": [] },
            };
            //auto scroll dialog to top
            this.setDialogAutoScrollTop();

            this.response = JSON.stringify(exceptionMsg);
            this.isMessageVisible = true;
            setTimeout(() => {
              this.isMessageVisible = false;
              this.response = "";
            }, 3000);
            this.isException = true;
            return 0;
          } else {
            this.exceptionMsg = "";
            this.isException = false;
            this.isMessageVisible = false;
            this.response = "";
          }

          createUpdatedto.productId =
            this.selectedProduct && this.selectedProduct > 0
              ? Number(this.selectedProduct)
              : 0;
          createUpdatedto.notes = this.comments ? this.comments : "";
          createUpdatedto.flag =
            this.selectedFlag === "-1" ? "" : this.selectedFlag;

          if (
            this.replaceFileData != null &&
            this.replaceFileData !== "" &&
            this.replaceFileData !== undefined
          ) {
            var fileNameExtension = this.replaceFileName
              .substring(this.replaceFileName.lastIndexOf(".") + 1)
              .toLowerCase();
            var fileNamewithoutExtension = this.replaceFileName.substring(
              0,
              this.replaceFileName.lastIndexOf(".")
            );
            formattedFileName =
              fileNamewithoutExtension + "." + fileNameExtension;
            this.receivedQueueSelectedFileName = formattedFileName.trim();
            var block = this.replaceFileData
              ? this.replaceFileData.split(";")
              : "";
            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            blob = Utils.b64toBlob(realData, contentType, 512);
            createUpdatedto.fileName = formattedFileName.trim();
            this.isDocUpload = true;
          } else {
            createUpdatedto.fileName = file ? file : "";
            this.isDocUpload = false;
          }

          this.isWorkflowApiCall = false;

          PageContext.isMessageVisible = false;
          if (this.isDocUpload) {
            var result = await WorkflowStore.replaceReceivedandUpdateDocument(
              createUpdatedto,
              createUpdatedto.id,
              createUpdatedto.fName,
              createUpdatedto.lName,
              createUpdatedto.agentId,
              createUpdatedto.typeId,
              createUpdatedto.companyId,
              createUpdatedto.marketerAgentId,
              createUpdatedto.uplineAgentId,
              createUpdatedto.assignedToUserId,
              createUpdatedto.assignedToTeamId,
              createUpdatedto.productId,
              createUpdatedto.notes,
              createUpdatedto.appDate,
              createUpdatedto.flag,
              createUpdatedto.fileName,
              {
                data: blob,
                fileName: formattedFileName.trim()
                  ? formattedFileName.trim()
                  : "file.pdf",
              },
              this.selectedFileName.trim()
            );
          } else {
            await WorkflowStore.createUpdateWorkflowDocument(createUpdatedto);
          }
          googleTagManager.trackAction("workflow_doc_moved", {
            feature: "Workflow",
            user_id: UserContext.userId,
            old_queue: WorkflowStatusType.ReceivedQueue,
            new_queue: WorkflowStatusType.ScrubQueue,
          });
          IMASLog.log("document is saved successfully");

          setTimeout(() => {
            this.isWorkflowApiCall = true;
            this.replaceFileData = "";
            this.selectedUplineAgent = "";
            this.hideReplaceFileName = false;
            this.setDialogVisibility(false);
            if (this.listener) {
              this.listener.closeDialogAfterSaveDocument();
              this.listener.addEditDocumentListener();
            }
            PageContext.setIsLoadingVisible(false);
          }, 100);
        }
      }
    } catch (e:any) {
      PageContext.setIsLoadingVisible(false);
      PageContext.isBackgroundMessageDisable = true;
      setTimeout(() => (PageContext.isMessageVisible = false), 100);
      setTimeout(() => {
        PageContext.isMessageVisible = false;
        this.hideReplaceFileName = false;
        this.setDialogVisibility(true);
        //this.response = PageContext.responseMessage;
        this.isWorkflowApiCall = true;
        e && e.text
        ? e.text().then((res: any) => {
            this.response = res;
          })
        : (this.response = e.response); 
        this.isMessageVisible = true;
        this.replaceFileData = "";
        PageContext.isBackgroundMessageDisable = false;
        var dialogelement = document.querySelector(".p-dialog-content");
        if (dialogelement) {
          dialogelement.scrollTo(0, 0);
        }
      }, 400);
      setTimeout(() => (this.isMessageVisible = false), 100000);
    }
  };

  @action getContractUplineDetail = async () => {
    try {
      var res = await ContractsStore.getContractUplineDetail(
        this.selectedCompany,
        this.selectedAgentId || 0
      );
      if (res != null) {
        if (res.uplineId != null) {
          this.selectedUplineAgent =
            res.uplineName != undefined ? res.uplineName : "";
          this.selectedUplineAgentId = res.uplineId;
        } else {
          this.selectedUplineAgent = "";
          this.selectedUplineAgentId = undefined;
        }
      }
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
    }
  };
  @observable
  public ErrorModel = new ErrorModel(new AddressValidator());
}

export interface DocumentListener {
  addEditDocumentListener(): void;
  cancelAddEditDocument(): void;
  closeDialogAfterSaveDocument(): void;
  downloadFileListener(value: any): void;
}

class AddressValidator extends AbstractValidator<AddEditDocumentViewModel> {
  public constructor() {
    super();
    this.validateIfDate((input) => input.appDate)
      .isNotEmpty()
      .withFailureMessage("App Date is required");
    // this.validateIfNumber(input => input.selectedCompany)
    //     .isNotEqualTo(0)
    //     .isNotEmpty()
    //     .withFailureMessage("Carrier is required");
    this.validateIfString((input) => input.firstName)
      .isNotEmpty()
      .when(
        (input) =>
          input.selectedType !== "Contract" &&
          input.selectedType !== "Contract Invite"
      )
      .withFailureMessage("First Name is required");
    this.validateIfString((input) => input.lastName)
      .isNotEmpty()
      .withFailureMessage("Last Name is required");
  }
}
