import { routes } from "../../../../router";
import AccountingStore from "../../../../store/AccountingStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";

export class ManageImportAgentDeptListViewModel extends BaseListViewModel<any> {
    constructor()
    {
        super("Agent Debt Import", "Agent Debt Import", routes.addManageAgentDept, routes.editManageAgentDept);
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
       return await AccountingStore.getAllAgentDeptImports(pageIndex, pageSize, sortColumn, sortOrder);
    }

    protected async deleteItem(id: number) {
        await AccountingStore.deleteAgentDeptImport(id);
    }

    resetFiltersToDefault() {
        this.pageSize = 10;
    }

}