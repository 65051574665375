import { observable, action } from "mobx";
import { routes } from "../../../../router";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import CommissionStore from "../../../../store/CommissionStore";
import Utils from "../../../../infrastructure/Utils";
import Collection from "../../../../infrastructure/CollectionHelper";
import { CreatePolicyModel, ExistingPoliciesListModel, NonActivatedPoliciesListModel, PaymentPlansConfigurationListModel, PolicyDashboardConfigurationListModel, ReportFile } from "../../../../services/CommissionService";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import PageContext from "../../../../infrastructure/PageContext";
import BusinessUnitConfigStore from "../../../../store/BusinessUnitConfigStore";

export class PolicyDashboardViewModel implements ViewModel  {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {
    await this.loadCompanyLoopup()
    await this.loadCommissionTypeLookup()
    await this.loadCompanyImports()
  };
  Route = async (currentRoute: RouteList): Promise<void> => { };

  @observable planList = new Collection<{ label: string; value: string; }>();
  @observable importVisible: boolean = false
  @observable carrierList = new Collection<{ label: string; value: string; }>();
  @observable carrierImportList = new Collection<{ label: string; value: string; }>();
  @observable selectedCarrier: string = '0'
  @observable selectedCarrierImport: string = '0'
  @observable selectedPlan: string = '1'
  @observable WRNList = new Collection<{ label: string; value: string; }>();
  @observable selectedWRN: string = '0'
  @observable selectedStatus: string = "0"
  @observable showAll: boolean = true
  @observable statusList = [
  {label: "Not Processed Yet", value: "0"},
  {label: "Updated Policy", value: "1"},
  {label: "Added Policy", value: "2"},
  {label: "Skipped", value: "3"}]
  @observable importList = new Collection<PolicyDashboardConfigurationListModel>();
  @observable selectedRow: any;
  @observable selectedPolicyId: number = 0
  @observable selectedProductId: string = '' 
  @observable paymentPlanList = new Collection<PaymentPlansConfigurationListModel>();  
  @observable unpaidPolicyList = new Collection<NonActivatedPoliciesListModel>();
  @observable exsistingPolicyList = new Collection<ExistingPoliciesListModel>();
  @observable fileData: any;
  @observable fileName: string = "";
  @observable ErrorModel = new ErrorModel(new ImportValidator());
  @observable isSortAscending: boolean = true;
  @observable sortColumn: string ="customerLastName";
  @observable totalRecords: number = 0;
  @action Validate = async () => {
    return await this.ErrorModel.Validate(this);
  }

  @action ResetValidate = async () => {
      return await this.ErrorModel.ResetValidation(this);
  }

  @action setImportVisible = (value: boolean) => {
    this.importVisible = value;
    this.disableUpload = true;
  }

  @action goToAdmin = () => {
    routes.administration.replace()
  }

  @action loadCompanyLoopup = async() => {
    var result = await CommissionStore.getPolicyImportCompaniesLookup()
    if(result){
      Utils.mapListItemsToDropdown(result, this.carrierList, "Select Carrier", "0");
    }
  }  

  @action loadCommissionTypeLookup = async() => {
    var result = await CommissionStore.getPolicyCommissionTypesLookup()
    if(result){
      Utils.mapListItemsToDropdown(result, this.planList, "Select Commission Type", "0");
      this.selectedPlan = "1"
    }
  }

  @action loadWRN = async() => {
    var result = await CommissionStore.getPolicyImportAgentWritingNumbers(+this.selectedCarrier, +this.selectedStatus, this.showAll)
    if(result){
      let WRNLists:any = [];
      result.forEach((item) => {
        var data = {
            label: item ? item : "",
            value: item ? item : "",
          };
          WRNLists.push(data);
      });
      this.WRNList.values = WRNLists;
    }
  }
  @action setSortOrder() {
    this.isSortAscending = !this.isSortAscending;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action reset = () =>{
    this.selectedCarrier = "0"
    this.selectedWRN = "0"
    this.showAll = true
    this.selectedPlan = "1"
    this.selectedStatus = "0"
    this.importList.values = []
    this.paymentPlanList.values = []
    this.unpaidPolicyList.values = []
    this.exsistingPolicyList.values = []
    this.selectedPolicyNumber = "0";
    this.selectedpolicyImportId = "0";
    this.SelectedNonActivePolicyID = "0";
    this.selectedActivepolicyImportId = "0";
  }

  @action resetData = () => {
    this.importList.values = []
    this.paymentPlanList.values = []
    this.unpaidPolicyList.values = []
    this.exsistingPolicyList.values = []
    this.selectedRow = ''
    this.SelectedNonActivePolicyID = "0";
    this.selectedActivepolicyImportId = "0";
  }

    @action searchImport = async (policyImportId: number, policyId: number) => {
      this.selectedRow = ''
        if (policyImportId > 0 && policyId > 0 && this.selectedStatus == "0") {
          this.importList.values = this.importList.values.filter(item => item.policyImportID !== (policyImportId))
          this.totalRecords = this.totalRecords > 0 ? this.totalRecords - 1 : 0;
      }          
      else {
          var result = await CommissionStore.getPolicyDashboardConfigurations(+this.selectedCarrier, +this.selectedStatus, this.selectedWRN,0,1000, this.sortColumn, this.isSortAscending)
          if (result) {
              this.totalRecords = result.recordCount ? result.recordCount : 0;
              this.importList.values = result.data ? result.data : []
          }
      }    
  }

  @observable selectedPolicyNumber: string = "0";
  @observable selectedpolicyImportId: string = "0";
  @observable selectedActivepolicyImportId: string = "0";
  @observable SelectedNonActivePolicyID: string | undefined = "0";
  @action onRowSelect = async (data: any) => {
    this.SelectedNonActivePolicyID = "0";
    this.selectedActivepolicyImportId = "0";
    this.selectedRow = data;
    this.selectedPolicyId = data.id; 
    this.selectedPolicyNumber = data.policyNumber;
    this.selectedProductId = data.productID;
    this.selectedpolicyImportId = data.policyImportID;
    var SelectedName = (data.customerLastName + ", " + data.customerFirstName).toLowerCase();
    this.unpaidPolicyList.values.filter((item: any) => {
       if (item.customerName.toLowerCase() == SelectedName) {
         this.SelectedNonActivePolicyID = item.id;
         this.selectedActivepolicyImportId = data.policyImportID;
       }
    }); 
      await this.loadPaymentPlans();   
      await this.loadUnpaidPolicy();
      await this.loadExsistingPolicy(); 
  }

  @action loadCompanyImports = async() => {
    var result = await CommissionStore.getPolicyImportNamesLookup()
    if(result){
      Utils.mapListItemsToDropdown(result, this.carrierImportList, "Select an Option", "0");
    }
  }

  @action skip = async(policyImportID: number) => {
    await CommissionStore.skipPolicyImport(policyImportID)
    await this.searchImport(policyImportID || 0, 0)
  }

  @action loadPaymentPlans = async() => {
    var result = await CommissionStore.getPaymentPlansConfiguration(this.selectedWRN, +this.selectedProductId, +this.selectedCarrier)
    if(result){
      this.paymentPlanList.values = result.data ||  []
    }
  }

  @action loadUnpaidPolicy = async() => {
    var result = await CommissionStore.getNonActivatedPolicies(+this.selectedCarrier, this.selectedWRN)
    if(result){
      this.unpaidPolicyList.values = result.data || []
    }
  }

  @action loadExsistingPolicy = async() => {
    var result = await CommissionStore.getExistingPolicies(+this.selectedCarrier, this.selectedPolicyNumber, this.selectedWRN)
    if(result){
      this.exsistingPolicyList.values = result.data || []
    }
  }

  @action activate = async(id: number) => {
    await CommissionStore.activatePolicyImport(+this.selectedpolicyImportId, id)
    this.unpaidPolicyList.values = this.unpaidPolicyList.values.filter(item => item.id !== id)  
  }


  @action terminate = async(id: number) => {
    await CommissionStore.terminatePolicyImport(+this.selectedpolicyImportId, id)
    this.exsistingPolicyList.values = this.exsistingPolicyList.values.filter(item => item.id !== id)  
  }

  @action view = async(id: number, agentId: number) => {
    window.open("/agents/"+agentId+"/policies/edit/"+id, "_blank");
  }

  @action create = async(paymentPlanID: number) => {
    var dto: CreatePolicyModel = {
      policyImportId: +this.selectedpolicyImportId,
      paymentPlanId: paymentPlanID,
      commissionTypeId: +this.selectedPlan,
      licenseNumber: this.selectedWRN
    }
    var policyId = await CommissionStore.createPolicy(dto)
    await CommissionStore.addPolicyAnnuitySinglePremium(+this.selectedpolicyImportId, policyId);
    await this.searchImport(+this.selectedpolicyImportId || 0, policyId || 0);
  }

  @action onDrop = (value: any, fileName: string) => {
    this.fileData = value;
    this.fileName = fileName;
    this.disableUpload = false
  }
  @observable disableUpload: boolean = true;

  @action async process() {
    await this.ResetValidate();
    if (!await this.Validate()) {
      this.disableUpload = true
      var blob = null;
      if (this.fileData != null && this.fileData !== "" && this.fileData !== undefined) {
          // Split the base64 string in data and contentType
          var block = this.fileData ? this.fileData.split(";") : "";
          // Get the content type of the image
          var contentType = block[0].split(":")[1];// In this case "image/gif"
          // get the real base64 content of the file
          var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
          // Convert it to a blob to upload
          blob = Utils.b64toBlob(realData, contentType, 512);
      }
      try {
          var file = blob ? { data: blob, fileName: this.fileName } : null;
          let carrierName: any[] = this.carrierImportList.values.length > 0 ? this.carrierImportList.values.filter(obj => obj.value === this.selectedCarrierImport) : []
          var result = await CommissionStore.importPolicy(file, carrierName && carrierName.length > 0 ? carrierName[0].label : "", this.selectedCarrierImport);
          
          PageContext.setIsMessageVisible(true)
          let msg = {
            status: 200,
            title: "File imported successfully.",
            errors: { "": [] },
          };
          PageContext.setResponseMessage(JSON.stringify(msg));
          setTimeout(() => {
            PageContext.setIsMessageVisible(false)
          }, 800);

          await this.delay(800);

          if (result) {
              this.downloadReport(result, "Policy");
              localStorage.setItem('fromImport', 'true');
              //this.loadItems()
          } else {
          }
      } catch (e) {
        this.disableUpload = true
      }
      this.cancelInport()
    }
    }

    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };

    @action delay(milliseconds: number) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

  @action cancelInport = () =>{
    this.disableUpload = true
    this.setImportVisible(false)
    this.resetImport()
  }

  @action resetImport = () => {
    this.fileData = null
    this.fileName= ""
    this.selectedCarrierImport = "0"
    this.disableUpload = true
  }
}

class ImportValidator extends AbstractValidator<PolicyDashboardViewModel>{
  public constructor() {
      super();
      this.validateIfString(input => input.selectedCarrierImport)
          .isNotEmpty()
          .isNotEqualTo("0")
          .withFailureMessage("Import is required");
      this.validateIf(input => input.fileData)
          .isNotEmpty()
          .withFailureMessage("File is required");
  }
}
