import { action, observable, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel"; 
import RouteList from "../../infrastructure/RouteList"; 
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel"; 
import CustomAttributeStore from "../../store/CustomAttributeStore";
import Collection from "../../infrastructure/CollectionHelper";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import { ManageAttributesViewModel } from "./ManageAttributesViewModel";
import { CustomAttributeValues } from "../../services/ContractService";
export class CustomAttributesComponentViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => undefined;
  public ErrorModel = new ErrorModel(new AddressValidator());
  @observable isLoading: boolean = true;
  @observable isMessgeVisible: boolean = false; 
  @observable isException: boolean = false;
  @observable exceptionMsg: any = ""; 


  @observable attributeType = [
  { label: "Reuse Existing Attribute", value: "1" },
  { label: "Create New Attribute", value: "2" } 
  ] ;
  @observable attributeSecurityType = [
  { label: "Public", value: "1" },
  { label: "Private", value: "2" } 
  ] ;

  @observable attributeMandatoryType = [
    { label: "Mandatory", value: "1" },
    { label: "Optional", value: "2" } 
    ] ;
    @observable attributeDataTypeList = [{
      label: '',
      value: ''
    }];
  
  @observable selectedAttributeType:number = 1;
  @observable selectedAttributeSecurityType:number|undefined = 1;
  @observable selectedAttributeMandatoryType:number|undefined = 2;
  @observable newAttributeName:string = '';
  @observable newAttributeNameTmp:string = '';
  @observable disableAttributeSave:boolean = false;
  @observable showCreateToast:boolean = false;
  @observable showAppliedToast:boolean = false;
  @observable checkFlag:boolean = true;
  @observable disableRadioInitial:boolean = true;
  @observable isModalActive:boolean|undefined = false;
  @observable timer: any;
  @observable isMultiValued:boolean = false;
  @observable newAttributeDescription:string = '';
  @observable attributeDataType:any;
  @observable dataTypeRes:any;
  @observable screenPath:number = 0;
  @observable existingAttributesListIntact:any=[];
  @observable existingAttributesList:any=[];
  @observable selectedExistingAttributesList:any=[];
  @observable existingAttributesListTemp:any=[];
  @observable SelectedExistingAttributeList:any;
  @observable SelectedExistingAttribute:any;
  @observable isLoadExistingList:boolean = true;
  @observable SearchInputValue:any;
  @observable DefaultListValueList:any;
  @observable isEdit:boolean = false;
  @observable attributeStatusType = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "2" } 
    ] ;
  @observable selectedStatus:any = 1;
  @observable editDataDetails:any;
  // @observable ManageAttributesViewModel:ManageAttributesViewModel = new ManageAttributesViewModel();
  @observable valueChangeDetector: number = 1;
  @observable defaultValue = new Collection<CustomAttributeValues>();;
  @observable isSave:boolean = false;
  @observable enableAddValue:Boolean = true;


  @action Load = async () => { 
  };
 
  @action public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action public IsFormValid = async () => {
    return this.ErrorModel.IsFormValid(this);
  };

  @action public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  }; 

   
  @action setSelectedExistingAttribute = (value:any,i:number) =>{  
    this.resetToasters(true);
    this.existingAttributesList[i].isChecked = !this.existingAttributesList[i].isChecked;
    this.SelectedExistingAttributeList = this.existingAttributesList.filter((x:any,i:any) => { return x.isChecked; }).length;
    if(value){
      let arr = this.selectedExistingAttributesList;
          if(arr.includes(value)){
              arr.splice(arr.indexOf(value), 1);
              return;
          }
          arr.push(value);
      this.selectedExistingAttributesList.push(); 
      this.handleTempState(i); 
    }
  }
@action handleTempState=(i:any)=>{
  this.existingAttributesListTemp  = this.existingAttributesList;
}


  @action loadLookUps =async (fromEdit:boolean,attrDetails:any) =>{ 
    this.attributeDataTypeList = []; 
    var res = await CustomAttributeStore.getAllDataTypes();
    if(res){
      this.dataTypeRes = res;
      const localStorageItems:any = [];
      localStorage.setItem('custAttrDataType', JSON.stringify([...localStorageItems, this.dataTypeRes]));
      (res || []).map((itm:any)=>{ 
         itm.active&& this.attributeDataTypeList.push({label:itm.name,value:itm.id}); 
      });
      let value = res[0].id ||0; 
      this.isEdit = fromEdit;
      fromEdit && this.mapEditData(attrDetails);
    }
  }

  @action loadExistingAttributes =async (path:number) =>{
    await this.loadLookUps(false,undefined);
    this.existingAttributesList=[]; 
    this.existingAttributesListTemp=[]; 
      var res = await CustomAttributeStore.getExistingAttributes();
      if(res){
        let tmp:any;
        let tmpRes:any;
        if(!Utils.hasUserPermission(Permission.PublicCustomAttribute)&& Utils.hasUserPermission(Permission.PrivateCustomAttribute)){
          tmp=  res.filter(function( obj ) {return obj.isPrivate == true;});  
        }else if(Utils.hasUserPermission(Permission.PublicCustomAttribute)&& !Utils.hasUserPermission(Permission.PrivateCustomAttribute)){
          tmp = res.filter(function( obj ) {return obj.isPrivate == false;}); 
        }else{
          tmp=res;
        }
        tmpRes = (tmp||[]).map((itm:any)=> (
          {"isPreChecked": itm.screens.includes(path) , "isChecked": itm.screens.includes(path), ...itm}
        )); 
        this.existingAttributesListIntact = JSON.parse(JSON.stringify(tmpRes.sort((a, b) => a.name.localeCompare(b.name))));
        this.existingAttributesList = tmpRes.sort((a, b) => a.name.localeCompare(b.name));
          this.existingAttributesListTemp = this.existingAttributesList;
          this.isLoadExistingList = this.existingAttributesList.length >0;
          this.SelectedExistingAttributeList = this.existingAttributesList.filter((x:any,i:any) => { return x.isPreChecked; }).length;
          this.disableRadioInitial = false;
      } 
  }
 

  // @action handleLoadLookUp = ()=>{ 
  //   let dataTypeCacheTmp:any  = localStorage.getItem("custAttrDataType"); 
  //       let dataTypeCache   = JSON.parse(dataTypeCacheTmp); 
      
  //     // if(dataTypeCache && dataTypeCache == null){
  //     //   this.loadLookUps(); 
  //     // }else 
  //     if(this.checkFlag && (dataTypeCache && dataTypeCache != null)){
  //       this.checkFlag= false;
  //         dataTypeCache   = JSON.parse(dataTypeCacheTmp);
  //         this.attributeDataTypeList =[];
  //         (dataTypeCache[0]).map((itm:any)=>{ 
  //           this.attributeDataTypeList.push({label:itm.name,value:itm.id}); 
  //         });
  //         this.dataTypeRes= dataTypeCache[0];
  //       let value = dataTypeCache[0][0].id ||'0'; 
  //     }else{
  //        this.loadLookUps(); 
  //     } 
  // }
  @action setAttributeType = async (value: string,type:string) => {
    if(this.isModalActive && type == 'attribute-type'){
      value == '2' &&  this.resetExistingAttributes(); 
    } 
    
    type == 'attribute-type' && (this.selectedAttributeType = parseInt(value));
    type == 'security' && (this.selectedAttributeSecurityType = parseInt(value));      
    type == 'mandatory' && (this.selectedAttributeMandatoryType = parseInt(value));      
    type == 'status' && (this.selectedStatus = parseInt(value));   
    type == 'attribute-type' && this.resetSlectedAttributesCount();         
  };
  
  @action attributeNameChange =(value:string) =>{
    this.newAttributeNameTmp = '';
    this.resetToasters(true);
    if (value.length == 1 && value == " ") {
      this.newAttributeNameTmp = '';
      this.newAttributeName = '';
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        this.newAttributeName = '';
        this.newAttributeNameTmp = '';
        return false;
      } else { 
        this.newAttributeName = value; 
        this.newAttributeNameTmp = value; 
        return true;
      }
    }  
  }
   

  @action attributeDescriptionChange =(value:string) =>{
    this.newAttributeDescription = value;
  }
  
  @action attributeDataTypeChange =(value:string) =>{ 
    this.defaultValue.values = [];
    this.checkIsMultiValued(value);
    this.attributeDataType = value;
    if (this.isMultiValued && (value == "3" || value == "4" || value == "5"))
      this.isSave = false;
  }

  @action checkIsMultiValued = (value:any)=>{ 
    let tempVar = (this.dataTypeRes||[]).filter((itm:any)=>{return itm.id == value}); 
    this.isMultiValued = tempVar && tempVar[0].multiValued;
  }

  
  @action  saveAttribute = async(type:number) =>{
    this.resetToasters(false);
    let data;
    let tempData = (this.defaultValue.values||[]).map((Items:any)=>{
      Items.value = 0;
      delete Items.duplicateValue;
      delete Items.isDuplicate; 
      return Items;
    }); 
    if(type== 1 ){       
      data={
        screenId: this.screenPath,
        name: this.newAttributeName,
        dataTypeId: this.attributeDataType,
        active: true,
        // values: this.newAttributeValues,
        values: tempData||'',
        description: this.newAttributeDescription,
        isPrivate: this.selectedAttributeSecurityType != 1,
        isMandatory: this.selectedAttributeMandatoryType ==1,
        visible: true
      } ; 
        let res = await CustomAttributeStore.createCustomAttributes(data);
        if (res && res.attrId) {
            Utils.showSuccessToaster(`The custom attribute '${this.newAttributeNameTmp}' has been added successfully.`, 7000, 500);
        }
    }else if(type== 2 ){
      Utils.showSuccessToaster(`The custom attribute has been applied successfully.`, 7000, 500);
      let reqData:any=[];
       this.selectedExistingAttributesList.map(itm=>{
        reqData.push({
          pathId: this.screenPath,
          attrId:itm,
          visible: true
        } )
       }) 
      let res = await CustomAttributeStore.reuseCustomAttributes(reqData);
      this.selectedExistingAttributesList = [];
      reqData=[];
    } 
    setTimeout( async() => {  
      this.resetAttributes();
    }, 900);
    return data;
  }
  @action updateAttribute =  async()=>{

    // updateCustomAttribute 
  }
  @action  getData = async() =>{
    let data={
        screenId: this.screenPath,
        name: this.newAttributeName,
        dataTypeId: this.attributeDataType,
        active: true,
        values: this.defaultValue,
        description: this.newAttributeDescription,
        isPrivate: this.selectedAttributeSecurityType == 1,
        isMandatory: this.selectedAttributeMandatoryType ==1,
        visible: true
    }
    return data;
  }
  
  @action resetToasters =(value:any) =>{ 
    if(value){
      this.showCreateToast = false; 
      this.showAppliedToast = false;
      localStorage.removeItem("CMNotesEdit");
    }else{
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {  
        this.showCreateToast = false; 
        this.showAppliedToast = false;
        localStorage.removeItem("CMNotesEdit");
      },  8000);
    }
  }  
  @action resetAttributes =() =>{ 
    this.isMultiValued = false;
    this.attributeDataType = '';
    this.newAttributeName = '';
    this.defaultValue.values = [];
    this.newAttributeDescription = '';
    this.selectedAttributeSecurityType = 1;
    this.selectedAttributeMandatoryType = 2;
    this.selectedAttributeType = 1;  
    this.SearchInputValue = '';
    this.resetToasters(false);
    this.isEdit = false;
  }  
  @action resetExistingAttributes =() =>{
    this.existingAttributesList= [];
    this.SearchInputValue = '';
    this.selectedExistingAttributesList = [];
    this.SelectedExistingAttributeList = 0;
    this.resetAttributes();
    this.existingAttributesList = this.existingAttributesListTemp; 
    
  }
  @action resetSlectedAttributesCount = ()=>{
    this.SelectedExistingAttributeList = 0;
    this.existingAttributesList = [];
    this.existingAttributesList = JSON.parse(JSON.stringify(this.existingAttributesListIntact)) ;
    this.SelectedExistingAttributeList = this.existingAttributesListIntact.filter((x:any,i:any) => { return x.isPreChecked; }).length;
  }
  @action searchAttribute = (e:any,isSearch:boolean) =>{
    let tmp3 = JSON.parse(JSON.stringify(this.existingAttributesList));
    let tmp2 = this.existingAttributesList.slice(0);
    if(isSearch){
      this.existingAttributesList = tmp2.filter(entry => Object.values(entry).some(val =>  typeof val === "string" && entry.name.toLowerCase().includes(this.SearchInputValue.toLowerCase()))).sort((a, b) => a.name.localeCompare(b.name));
    }else{
      this.existingAttributesList = [];
      this.existingAttributesList = this.existingAttributesListTemp;
    }
  }
  
  @action onFieldChange = (e: any) => {
    this.SearchInputValue  = e;
    if(this.SearchInputValue.length >2){
    this.searchAttribute(e,true);}else {
      this.searchAttribute(e,false);
    }
  }
    @action goSearch = () => {  
    };

    @action mapEditData=(data:any)=>{
      if(data){
        this.editDataDetails = data; 
        this.isMultiValued = false;
        // this.attributeDataType = data.attributeDataType;
        this.newAttributeName = data.name; 
        this.defaultValue.values = data.values;
        this.newAttributeDescription = data.description;
        this.selectedAttributeSecurityType = data.isPrivate ? 2: 1;
        this.selectedAttributeMandatoryType =  data.isMandatory ? 1: 2;
        this.attributeDataType =  data.dataTypeId;
        this.isMultiValued = data.values.length > 0;
        this.selectedStatus = data.active ? 1 : 2;
      }
    }
    
  rowId: number = 1;
  @action addValueRow() { 
    let checkFlag = true;
    let checkIsEmptyValue = (this.defaultValue.values || []).map((item:any)=>{
      if ('text' in item){
        checkFlag= item.text.length>0;
        this.isSave =  item.text.length>0;
      }else {
        this.isSave = false;
        checkFlag =  false;
      }
    });
    
    if(checkFlag ){ 
      let rowData = {
        count: 0,
        value: 'Test'+new Date().getTime() + this.rowId++
      } as CustomAttributeValues;
      this.defaultValue.values.push(rowData);
      this.isSave = false;
    }
    // this.enableAddValue = checkIsEmptyValue;
  }
  @action  isWhitespaceString = (str:string) => !str.replace(/\s/g, '').length;
  @action checkForEmptyValue = () => {
    let checkIsEmptyValue = (this.defaultValue.values || []).map((item:any)=>{
      if ('text' in item){ 
        this.isSave =  item.text.length>0; 
      }else {
      this.isSave = false; }

    if(this.isWhitespaceString(item.text)){
      this.isSave = false;
      item.text = '';
    }
    });

      if (checkIsEmptyValue.length == 0) {
          this.isSave = false;
      }
  }
  @action validateDuplicateValue = () => {
    this.defaultValue.values.forEach((item: any) => {
      let duplicateList: any = this.defaultValue.values.filter(
        (item2: any) => {
          return (
            item.text && 
            item.text.toLowerCase()   === item2.text.toLowerCase()  
          );
        }
      );
      item.isDuplicate =
        duplicateList &&
        duplicateList.length > 1 &&
        (item.value
          ? item.value == duplicateList[1].value
          : item.id == duplicateList[1].id);
      item.duplicateValue = "";
      if (item.isDuplicate) {
        this.isSave = false;
        item.duplicateValue = item.text;
      }
    });
    this.valueChangeDetector++;
    // this.enableSave();
  };
  
  @action deleteItem = (rowData:any) => {  
        if (rowData.id && rowData.id > 0) {
          this.defaultValue.values = this.defaultValue.values.filter(
            (i: any) => {
              return i.id != rowData.id;
            }
          );
        } else {
          this.defaultValue.values = this.defaultValue.values.filter(
            (i: any) => {
              return i.value != rowData.value;
            }
          );
        } 
  };


}

class AddressValidator extends AbstractValidator<CustomAttributesComponentViewModel> {
  public constructor() {
    super();
    // this.validateIfString((input) => input.addressType)
    //   .isNotEmpty()
    //   .withFailureMessage("Field is required");
  }
}
