/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action } from "mobx";

import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";

import RouteList from "../../infrastructure/RouteList";
import utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { CreditCardChargeListViewModel } from "../administration/accounting/creditCardCharge/CreditCardChargeListViewModel";
import { CreditCardChargeEditViewModel } from "../administration/accounting/creditCardCharge/CreditCardChargeEditViewModel";
import ContractsStore from "../../store/ContractsStore";
import AccountStore from "../../store/AccountingStore";
import { Permission } from '../../infrastructure/enum/Permission';
import { AccountDetails, AccountSubTabs } from "../../infrastructure/enum/AgentDetails";
//import { routes } from "../../router";
//import { AddAgentDebtViewModel } from "../../viewModels/agents/AddAgentDebtViewModel";
export class AgentAccountViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (id: number, name: string | undefined) => {
    this.agentId = id;
    this.agentName = name;
    this.isException = false;
    this.isMessageVisible = false;
    this.sortDebt = false;
    this.sortColumnDebt = "id";
    this.isAddCreditCardReq = false;
    this.isAddDebtCardReq= false;
    this.getAccountDetailsTabItems();
      this.resetPaging();
      utils.setAccountSubTab(0);
    let openTab = utils.getAccountSubTab();
    if (
      utils.hasUserPermission(Permission.CreditCardChargeRequests) &&
      (openTab === AccountSubTabs.credit || openTab === "")
    ) {
      this.selectedTabIndex = 0;
      this.selectedPanel = AccountDetails.CreditCardChargeReq;
      this.CreditCardListViewModel.Load(undefined, undefined, this.agentId);
    } else {
      this.selectedTabIndex = 1;
      this.selectedPanel = AccountDetails.DebtHistory;
      await this.loadCompanies();
      this.loadDebtList();
    }


  };
  @action resetPaging = () => {
    this.startIndex = 0;
    this.first = 0;
    this.rows = 10;
  };
  @action resetSort() {
    this.sortDebt = false;
    this.sortColumnDebt = "id";
  }
  @action setSelectedPanel = async (value: string) => {
    console.log(value);
    this.selectedPanel = value;
    if (value === AccountDetails.DebtHistory) {
      utils.setAccountSubTab(1);
      await this.loadCompanies();
      this.rows = 10;
      this.isAddDebtCardReq= false;
      this.loadDebtList();
    } else {
      utils.setAccountSubTab(0);
      this.CreditCardListViewModel.Load(undefined, undefined, this.agentId);
    }
  }
  @action formatName(name: string): string {
    return name ? name.split(" ").reverse().join(`, `) : "";
  }
  @action generatePaymentUrl = () => {
    let url = `https://gateway.helcim.com/hosted/?merchantId=4501452221&token=b32b231h10794id69c&allowZeroAmount=1&customerId=${this.agentId
      }&billingName=${this.formatName(
        this.agentName ? this.agentName : ""
      )}#Policies`;
    window.open(url, "_new");
  };
  Route = async (currentRoute: RouteList): Promise<void> => { };
  @observable selectedCompany: string = "0";
  @observable rows: number = 10;
  @observable totalRecords: number = 100;
  @observable totalRecordsForDebtList: number = 0;
  @observable first: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable debtId: number = 0;
  @observable selectedPanel: string = AccountDetails.CreditCardChargeReq;
  @observable selectedTabIndex?: number = 0;
  // @observable companies = [{ label: "All", value: "0" }];
  @observable agentName: string | undefined = "";
  @observable isAddCreditCardReq: boolean = false;
  @observable isAddDebtCardReq: boolean = false;
  @observable isDeleteDebtConfirm: boolean = false;
  @observable isEditDebtAccess: boolean = false;
  @observable isException: boolean = false;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 10;
  @observable sortColumnDebt: string = "id";
  @observable sortDebt: boolean = false;
  @observable isMessageVisible: boolean = false;
  @observable response: any;
  @observable
  CreditCardListViewModel: CreditCardChargeListViewModel = new CreditCardChargeListViewModel();
  @observable
  CreditCardChargeEditViewModel: CreditCardChargeEditViewModel = new CreditCardChargeEditViewModel();
  // @observable
  // AddAgentDebtViewModel: AddAgentDebtViewModel = new AddAgentDebtViewModel();  

  //Add Debt View details
  @observable writingNumber: string = "";
  @observable unSecured: string = "0";
  @observable reserve: string = "0";
  @observable sideDebt: string = "0";
  @observable rollUp: string = "0";
  @observable producerWeek: string = "0";
  @observable uplineWeek: string = "0";
  @observable advanced: string = "0";  
  @observable total: number = 0;
  @observable title: string = "Add Agent Debt";  
  @observable isZeroedOut: boolean = false;
  @observable advance: string = "0";
  @observable debtcompanies = new Collection<{
    label: string;
    value: string;
  }>();

  @action writingNumberChange = (value: string) => {
    this.writingNumber = value;
  };
  @action setUpline = (value: string) => {
    this.uplineWeek = value;
  };
  @action setProducer = (value: string) => {
    this.producerWeek = value;
  };
  @action setUnsecured = (value: string) => {
    this.unSecured = value;
  };
  @action setReserve = (value: string) => {
    this.reserve = value;
  };
  @action setSideDebt = (value: string) => {
    this.sideDebt = value;
  };
  @action setRollup = (value: string) => {
    this.rollUp = value;
  };
  @action setAdvanced = (value: string) => {
    this.advanced = value;
  };

  @action setAdvance = (value: string) => {
    this.advance = value;
  };
  @observable selecteddebtCompany: string = "";

  public ErrorModel = new ErrorModel(new AddDebtValidator());
  @action AddDebtLoad = async (id: number, debtId: number | undefined) => {
    this.agentId = id;

    this.resetAddFields();
    await this.ResetValidate();
    await this.loadDebtCompanies();

    if (debtId && utils.hasUserPermission(Permission.EditAgentDebt)) {
      this.title = "Edit Agent Debt";
      this.debtId = debtId;
      this.loadDebtValues();
    } else {
      this.title = "Add Agent Debt";
      this.debtId = 0;
    }
  };


  @action resetAddFields() {
    this.unSecured = "0";
    this.uplineWeek = "0";
    this.writingNumber = "";
    this.sideDebt = "0";
    this.rollUp = "0";
    this.reserve = "0";
    this.producerWeek = "0";
    this.advanced = "0";   
    this.endDate = new Date();
    this.selecteddebtCompany = "";
    this.debtId = 0;
    this.isZeroedOut = false;
  }
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @observable endDate: Date = new Date();
  @action setSelectDate(value: Date) {
    this.endDate = value;
  }
  @action setSelectedDebtCompany(value: string) {
    this.selecteddebtCompany = value;
  }
  @action loadDebtValues = async () => {
    
    try {
      let result = await AccountStore.getDebtById(this.debtId);
      if (result) {
        this.fillValues(result);
      }      
    } catch (e) {      
    }
  };
  @action fillValues(response: any) {
    this.advanced = response.advanced;
    this.advance = response.advanced;
    let temp = response.carrierId.toString();
    this.selecteddebtCompany = temp;
    this.debtId = response.id;
    this.producerWeek = response.producer;
    this.endDate = new Date(response.reportDate);
    this.reserve = response.reserve;
    this.rollUp = response.rollUp;
    this.sideDebt = response.side;
    this.total = response.total;
    this.unSecured = response.unSecured;
    this.uplineWeek = response.upline;
    this.writingNumber = response.writingNumber;
  }
  @action
  loadDebtCompanies = async () => {
   
    var result = await ContractsStore.getAllCompanyLookup();
    if (result !== null) {
      this.debtcompanies.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };

          this.debtcompanies.values.push(data);
        });
      this.selecteddebtCompany = this.debtcompanies.values[0].value.toString();
    } else {
      this.debtcompanies.values = [];
      this.selecteddebtCompany = "";
    }
  };

  @action formatNum = (val: string): number => {
    let num;
    if (val) {
      val = val.toString().replace("$", "").split(",").join("");
      num = !isNaN(Number(val)) ? Number(val.split(",").join("")) : 0;
    } else num = 0;

    return num;
  };
  @action onDebtSubmit = async () => {
   
    await this.ResetValidate();
    if (!(await this.Validate())) {
      let body = {
        id: this.debtId !== 0 ? this.debtId : 0,
        agentId: this.agentId,
        carrierId: Number(this.selecteddebtCompany),
        writingNumber: this.writingNumber,
        reportDate: utils.getDateinyymmdd(this.endDate),
        advanced: this.formatNum(this.advanced),
        unSecured: this.formatNum(this.unSecured),
        reserve: this.formatNum(this.reserve),
        side: this.formatNum(this.sideDebt),
        total: this.total,
        rollUp: this.formatNum(this.rollUp),
        producer: this.formatNum(this.producerWeek),
        upline: this.formatNum(this.uplineWeek),
      };
      try {
        if (this.debtId && this.debtId !== 0) {
          await AccountStore.editAgentDebt(body);
        } else {
          await AccountStore.addAgentDebt(body);
        }
        this.selectedCompany = "0";
        this.toggleDebtCardReq(true);          
      
      } catch (e) {
        
      }
    } 
  };
  @action onDebtCancel = () => {    
    // routes.agentAccount.replace({
    //   agentId: "" + RouteParamDetails.id      
    // });
    // Change to details screen 
    //this.isAddDebtCardReq=false;
    //this.toggleDebtCardReq(true);
    this.isAddDebtCardReq=false;
  };


  //Credit card & Account details
  @observable creditCardChargeReqList = new Collection<{
    status: string;
    requestor: string;
    requested: string;
    amount: number;
    description: string;
  }>();
  // @observable CreditCardChargeListModel: CreditCardChargeData | null = null;
  @observable companies = new Collection<{
    label: string;
    value: string;
  }>();

  @observable agentDebtList = new Collection<{
    id?: number;
    carrier?: string;
    carrierId?: number;
    awn?: string;
    reportDate?: Date;
    displayReportDate?: Date;
    advanced?: number;
    unSecured?: number;
    reserve?: number;
    side?: number;
    total?: number;
    rollUp?: number;
    producer?: number;
    upline?: number;
    isZeroedOut?: boolean;
  }>();

  @action setSortOrder() {
    this.sortDebt = !this.sortDebt;
  }
  @action setSortColumn(column: string) {
    this.sortColumnDebt = column;
  }
  @action noRecordsFound() {
    this.totalRecordsForDebtList = 0;
    this.agentDebtList.values = [];
    }
    @action onEditDebt(debtId: any) { 
      this.isAddDebtCardReq=true;
        this.AddDebtLoad(this.agentId, debtId || 0);
    }
  @action onDeleteDebtClick = async () => {
    try {
      await AccountStore.deleteAgentDebt(this.debtId);
      setTimeout(async () => {
        this.setDeleteDebtConfirmation(false);
        this.selectedCompany = "0";
        await this.loadDebtList();
      }, utils.timeDelay_Delete());

    } catch (error) {
      IMASLog.log("exception ..deleting debt");
    }
  };
  @action setDeleteDebtConfirmation(value: boolean) {
    this.isDeleteDebtConfirm = value;
  }

  @action setIsEditDebtAccess(value: boolean) {
    this.isEditDebtAccess = value;
  }

  @action setSelectedDebt = (debtId: number) => {
    this.debtId = debtId;
  };

  @action toggleCreditCardReq = (bool: boolean) => {
    this.isAddCreditCardReq = !this.isAddCreditCardReq;
    if (!this.isAddCreditCardReq && bool) {
      this.CreditCardListViewModel.Load(
        undefined,
        undefined,
        this.agentId,
        true
      );
      return;
    }
    if (!this.isAddCreditCardReq)
      this.CreditCardListViewModel.loadList();
    else {
      this.CreditCardChargeEditViewModel.Load(undefined);
    }
  };

  @action toggleDebtCardReq = (bool: boolean) => { 
    this.isAddDebtCardReq = !this.isAddDebtCardReq;
    if (!this.isAddDebtCardReq && bool) {
      this.loadDebtList();
      return;
    }
    if (this.isAddDebtCardReq)
    {
      this.AddDebtLoad(this.agentId,0);
      //this.AddAgentDebtViewModel.Load(this.agentId,this.debtId);
    }    
  };  

  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.setSelectedPanel(key);
  };
  @action loadDebtList = async () => {
    this.isLoading = true;
    this.isMessageVisible = false;
    this.response = "";
    try {
      var agentDebtList = await AccountStore.debtListBasedOnAgent(
        this.agentId,
        this.selectedCompany !== "0" ? Number(this.selectedCompany) : undefined,
        this.startIndex,
        this.rows,
        this.sortColumnDebt,
        this.sortDebt
      );
      if (agentDebtList) {
        if (agentDebtList.recordCount) {
          this.totalRecordsForDebtList = agentDebtList.recordCount;
          if (agentDebtList.data)
            this.agentDebtList.values = agentDebtList.data;
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
    } catch (e) {
      this.response =e;
      this.isMessageVisible = true;
      this.isLoading = false;
    }

    this.isLoading = false;
  };
  @action getFormatValue = (rowData: any, header: string) => {
    let rowObj = utils.getObject(rowData);
    switch (header) {
      case "Advanced":
        return utils.getCurrencyFormat(rowObj.advanced);
      case "Unsecured":
        return utils.getCurrencyFormat(rowObj.unSecured);
      case "Reserve":
        return utils.getCurrencyFormat(rowObj.reserve);
      case "Side":
        return utils.getCurrencyFormat(rowObj.side);
      case "Total":
        return utils.getCurrencyFormat(rowObj.total);
      case "Rollup":
        return utils.getCurrencyFormat(rowObj.rollUp);

      default:
        return "";
    }
  };
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadDebtList();
  }
  @action loadCompanies = async () => {
    this.isLoading = true;
    var defaultItem = { label: "All", value: "0" };
    var result = await ContractsStore.getAllCompanyLookup();

    if (result !== null) {
      this.companies.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companies.values.push(data);
        });
      this.companies.values.splice(0, 0, defaultItem);
      this.selectedCompany = "0";
    } else {
      this.companies.values = [];
      this.companies.values.splice(0, 0, defaultItem);
      this.selectedCompany = "0";
    }

    this.isLoading = false;
  };
  @observable AccountDetailsTabItems: TabItems[] = [];

  @action getAccountDetailsTabItems = () => {
    this.AccountDetailsTabItems = [];
    let a = { label: AccountDetails.CreditCardChargeReq, value: 0 };
    let b = { label: AccountDetails.DebtHistory, value: 1 };
    utils.hasUserPermission(Permission.CreditCardChargeRequests) &&
      this.AccountDetailsTabItems.push(a);
    utils.hasUserPermission(Permission.ViewAgentDebt) &&
      this.AccountDetailsTabItems.push(b);
  };
}



export interface TabItems {
  label: AccountDetails;
  value: number;
}

class AddDebtValidator extends AbstractValidator<AgentAccountViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.selecteddebtCompany)
      .isNotEmpty()
      .withFailureMessage("Carrier is required");

    this.validateIfString((input) => input.writingNumber)
      .isNotEmpty()
      .withFailureMessage("Writing number is required");

    this.validateIfString((input) => input.advanced)
      .isNotEmpty()
      .withFailureMessage("Advanced is required");

    this.validateIfString((input) => input.unSecured)
      .isNotEmpty()
      .withFailureMessage("Unsecured is required");

    this.validateIfString((input) => input.reserve)
      .isNotEmpty()
      .withFailureMessage("Reserve is required");

    this.validateIfString((input) => input.sideDebt)
      .isNotEmpty()
      .withFailureMessage("Side Debt is required");

    this.validateIfString((input) => input.rollUp)
      .isNotEmpty()
      .withFailureMessage("Rollup is required");

    this.validateIfString((input) => input.producerWeek)
      .isNotEmpty()
      .withFailureMessage("Producer weeks is required");

    this.validateIfString((input) => input.uplineWeek)
      .isNotEmpty()
      .withFailureMessage("Upline weeks is required");
  }
}
