import { action, computed, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import { routes } from "../../../../router";
import Collection from "../../../../infrastructure/CollectionHelper";
import {
  CarrierCM,
  CarrierCMTabIndex,
} from "../../../../infrastructure/enum/Common";
import RouteList from "../../../../infrastructure/RouteList";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import ContractsStore from "../../../../store/ContractsStore";
import Utils from "../../../../infrastructure/Utils";
import { CarrierCMSummaryViewModel } from "./CarrierCMSummaryViewModel";
import { CarrierCMNotesViewModel } from "./CarrierCMNotesViewModel";
import { CarrierCMWebsitesViewModel } from "./CarrierCMWebsitesViewModel";

import AccountingStore from "../../../../store/AccountingStore";
import { CarrierCMContactViewModel } from "./CarrierCMContactViewModel";
import { CarrierCMAddressViewModel } from "./CarrierCMAddressViewModel";
import {
  CarrierListModel2,
  CreateUpdateCarrierModel,
  NotesSearchResponse,
} from "../../../../services/ContractService";
import UserContext from "../../../../infrastructure/UserContext";
import { ReportListModel } from "../../../../services/ReportService";
import { CarrierCMProductsViewModel } from "./CarrierCMProductsViewModel";
import { CarrierCMLevelsViewModel } from "./CarrierCMLevelsViewModel";
import { CarrierCMDocumentMainViewModel } from "./CarrierCMDocumentMainViewModel";

export class PagedList<T> {
  public recordCount: number = 0;
  public data: T[] = [];
}
export class CarrierCMAddEditViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable CarrierCMSummaryViewModel: CarrierCMSummaryViewModel =
    new CarrierCMSummaryViewModel();
  @observable CarrierCMNotesViewModel: CarrierCMNotesViewModel =
    new CarrierCMNotesViewModel();
  @observable CarrierCMWebsitesViewModel: CarrierCMWebsitesViewModel =
    new CarrierCMWebsitesViewModel();
  @observable CarrierCMProductsViewModel: CarrierCMProductsViewModel =
    new CarrierCMProductsViewModel();
  @observable CarrierCMLevelsViewModel: CarrierCMLevelsViewModel =
    new CarrierCMLevelsViewModel();
  @observable CarrierCMDocumentMainViewModel: CarrierCMDocumentMainViewModel =
    new CarrierCMDocumentMainViewModel();
  @observable CarrierCMContactViewModel: CarrierCMContactViewModel =
    new CarrierCMContactViewModel();
  @observable CarrierCMAddressViewModel: CarrierCMAddressViewModel =
    new CarrierCMAddressViewModel();
  @observable
  public ErrorModel = new ErrorModel(new CarrierManagementValidator());
  @observable carrierName: string | undefined;
  @observable CMchecked: boolean | undefined = true;
  @observable CMcheckedPreview: any = undefined;
  @observable isSave: boolean = false;
  @observable isCmEditMode: boolean = false;
  @observable shortName: string | undefined = "";
  @observable selectedGroup: any | undefined;
  @observable selectedCarrier: string = "";
  @observable selectedGroupPreview: any | undefined;
  @observable selectedID: number = 0;
  @observable selectedPreviewCarrier: string = "";
  @observable timer: any;
  @observable groupTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable PaymentList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isShowCreateToast: boolean = false;
  @observable isPreview: boolean = false;
  @observable isDetailsTab: boolean = true;
  @observable isNotesTab: boolean = false;
  @observable isWebsitesTab: boolean = false;
  @observable isProductsTab: boolean = false;
  @observable isLevelsTab: boolean = false;
  @observable isDocumentsTab: boolean = false;
  @observable isCancelChangesConfirmation: boolean = false;
  @observable isShowEditToast: boolean = false;
  @observable carrierNamePreview: string = "Carrier";
  @observable selectedKey: string | undefined = "Details";
  @observable cmTabItems = [
    { label: "Details", value: 0 },
    { label: "Notes", value: 1 },
    { label: "Products", value: 2 },
    { label: "Levels", value: 3 },
    { label: "Websites", value: 4 },
  ];

  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 10;
  @observable sortColumn: string = "createdDate";
  @observable isSortAscending: boolean = true;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 50;

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;
  @observable exceptionResponse: any = "";
  @observable sort: boolean = false;
  @observable itemList = new Collection<ReportListModel>();
  @observable searchInputValue: string = "";
  @observable productCount: number = 0;
  @observable levelCount: number = 0;
  @observable carrierShortName: string = "";
  @observable carrierAliasName: string = "";
  @observable carrierAliasNames: string = "";
  @observable selectedGroupName: string = "";
  @observable selectedGroupId: number = 0;
  @observable masterCarrierName: string = "";

  @action Load = async (id: number) => {
    window.scrollTo(0, 0);
    this.isException=false;
    this.CarrierCMAddressViewModel.addAddressDto = [];
    this.CarrierCMContactViewModel.addContactDto = [];
    this.carrierName = "";
    this.isCancelChangesConfirmation = false;
    this.shortName = "";
    this.carrierAliasName = "";
    this.carrierAliasNames = "";
    this.selectedGroupName = "";
    this.selectedID = id || 0;
    this.isSave = false;
    this.isDetailsTab = true;
    this.isNotesTab = false;
    this.isWebsitesTab = false;
    this.isProductsTab = false;
    this.isLevelsTab = false;
    this.isDocumentsTab = false;
    this.selectedKey = "Details";
    this.selectedCarrier = "0";
    if (this.companyTypeList.values.length <= 0) {
      await this.loadCarrierLookups();
    }
    this.loadLookups(id || 0);
    if (id === 0 || id == undefined) {
      this.isCmEditMode = false;
      this.CarrierCMSummaryViewModel.isRigidContractingChecked = true;
    } else {
      this.isCmEditMode = true;
    }
    if (
      localStorage.getItem("isCMPreview") &&
      localStorage.getItem("ssrCreateToast")
    ) {
      this.carrierNamePreview =
        localStorage.getItem("isCMPreview") || "Carrier";
      this.isShowCreateToast = false;
      setTimeout(() => {
        this.isShowCreateToast = true;
        localStorage.removeItem("isCMPreview");
      }, 300);
    } else if (
      this.selectedID! == 0 &&
      localStorage.getItem("isCMPreview") &&
      localStorage.getItem("ssrCreateToast")
    ) {
      this.carrierNamePreview =
        localStorage.getItem("isCMPreview") || "Carrier";
      this.isShowEditToast = false;
      setTimeout(() => {
        this.isShowEditToast = true;
        localStorage.removeItem("isCMPreview");
      }, 300);
    }

    // Navigate product tab
    if (
      localStorage.getItem("ProductRedirectionCarrierId") ==
      this.selectedID.toString()
    ) {
      this.handleLinkClick(CarrierCM.Products);
    }
    localStorage.setItem("ProductRedirectionCarrierId", "");
  };

  @action setCancelChangesConfirmation(value: boolean) {
    this.isCancelChangesConfirmation = value;
  }

  @action setcarrierAliasName(value: string) {
    this.carrierAliasName = value;

    //if (this.carrierAliasName.trim() == "")
    //    this.carrierAliasName = this.carrierAliasNames;
  }

  @action handleToggle = () => {
    this.CMchecked = !this.CMchecked;
  };

  @action handleCheckBoxChange = (value: boolean) => {};

  @action onFieldChange = (e: any) => {
    var regex = new RegExp(/\s{2}/, "g");
    if (regex.test(e.currentTarget.value)) {
      return false;
    } else {
      (<any>this)[e.currentTarget.id] = e.currentTarget.value;
      if (
        this.shortName &&
        this.shortName.length > 0 &&
        this.carrierName &&
        this.carrierName.length > 0 &&
        this.shortName.trim().split(/ +/).join(" ").length &&
        this.carrierName.trim().split(/ +/).join(" ").length
      ) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
      return true;
    }
  };

  @action saveCarrier = async () => {    
    this.CarrierCMContactViewModel.addContactDto.map((itm: any) => {
      itm.id = 0;
    });
    this.CarrierCMAddressViewModel.addAddressDto.map((itm: any) => {
      itm.id = 0;
    });
    let companyLicenseNumberDefault: any[] = [];
    if (this.CarrierCMSummaryViewModel.writingNumberDefaults) {
      this.CarrierCMSummaryViewModel.writingNumberDefaults.map(
        (item: any, idx: any) => {
          if (item.isWritableDefault || item.isWritableSelected) {
            companyLicenseNumberDefault.push({
              id: item.id,
              companyId: item.companyId,
              licenseNumberTypeId: item.licenseNumberTypeId,
              isWritableDefault: item.isWritableSelected
                ? item.isWritableDefault
                : false,
              isWritableSelected: item.isWritableSelected,
              typeName: item.typeName,
            });
          }
        }
      );
    }

    let reqData: CreateUpdateCarrierModel = {
      id: this.selectedID,
      name: this.carrierAliasName.trim()
        ? this.carrierAliasName.trim()
        : this.selectedGroupName,
      carrierShortName: this.carrierShortName,
      carrierGroupId: this.selectedGroupId ? this.selectedGroupId : 0,
      defaultPaymentSourceId:
        this.CarrierCMSummaryViewModel.selectedPaymentValue == "null" ||
        this.CarrierCMSummaryViewModel.selectedPaymentValue ==
          "Not Applicable" ||
        this.CarrierCMSummaryViewModel.selectedPaymentValue == "0"
          ? 0
          : this.CarrierCMSummaryViewModel.selectedPaymentValue,
      commissionProcessingDivisionId: 0,
      checkEligibility: this.CarrierCMSummaryViewModel.isEligibilityChecked,
      strictContracting:
        this.CarrierCMSummaryViewModel.isRigidContractingChecked,
      status: this.CMchecked,
      allowOverlappingDates: this.CarrierCMSummaryViewModel.isOverlapChecked,
      createdBy: UserContext.getUserId(),
      modifiedBy: UserContext.getUserId(),
      modifiedOn: 0,
      companyLicenseNumberDefault: companyLicenseNumberDefault,
      contactList: this.CarrierCMContactViewModel.addContactDto,
      addressList: this.CarrierCMAddressViewModel.addAddressDto,
      masterCarrierId: this.selectedCarrier,
    };
    let result;
    this.isShowCreateToast = false;
    this.isShowEditToast = false;
    if (true) {
    }
    this.isException = false;
        this.exceptionResponse ="";
    if (this.selectedID === 0) {   
      try{   
      result = await ContractsStore.addCarriers(reqData);
      Utils.showSuccessToaster(
        reqData.name + " successfully created.",
        3000,
        500
      );
      result && routes.previewCarrierManagement.push({ Id: result });
    }
    catch (e: any) {
      this.isException = false;
      e.text().then((_responseText: any) => {
        let _title = JSON.parse(_responseText).title;
        let _status = JSON.parse(_responseText).status;
        var responseModels = {
          status: _status,
          title: "",
          errors: { ValidationError: [_title] },
        };      
        this.exceptionResponse = JSON.stringify(responseModels);
        this.isException = true;
      });
    }
    } else {
      try{        
      result = await ContractsStore.updateCarriers(reqData);
      Utils.showSuccessToaster(reqData.name + " updated.", 3000, 500);
      routes.previewCarrierManagement.push({ Id: this.selectedID });
      }
      catch (e: any) {
        this.isException = false;
        e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          let _status = JSON.parse(_responseText).status;
          var responseModels = {
            status: _status,
            title: "",
            errors: { ValidationError: [_title] },
          };      
          this.exceptionResponse = JSON.stringify(responseModels);
          this.isException = true;
        });
      }
    }
  
  };

  @action loadLookups = async (carrierId: number) => {
    let items = await ContractsStore.getCarriersById(carrierId);
    items.paymentSource &&
      Utils.mapListItemsToDropdown(
        items.paymentSource,
        this.CarrierCMSummaryViewModel.PaymentList,
        "Not Applicable",
        "0"
      );
    items.carrierGroups &&
      Utils.mapListItemsToDropdown(
        items.carrierGroups,
        this.groupTypeList,
        "Select",
        "0"
      );

    let result = this.groupTypeList.values.map((itm: any, idx: any) => {
      if (itm.value == items.carrierGroupId) {
        return itm;
      }
    });
    let paymentTempVar = this.CarrierCMSummaryViewModel.PaymentList.values.map(
      (itm: any, idx: any) => {
        if (itm.value == items.defaultPaymentSourceId) {
          return itm;
        }
      }
    );
    let selectedDrptemp = result.filter((key) => key);
    let paymentTemp = paymentTempVar.filter((key) => key);
    if (this.selectedID !== 0) {
      this.isSave = true;
    }

    this.productCount = items.productCount || 0;
    this.levelCount = items.levelCount || 0;
    this.selectedID = (items && items.id && items.id) || 0;
    this.CMchecked =
      this.selectedID == 0 ? true : items && items.status && items.status;
    this.CMcheckedPreview = items.status;
    this.CarrierCMSummaryViewModel.isOverlapChecked =
      items.allowOverlappingDates || false;
    this.CarrierCMSummaryViewModel.isEligibilityChecked =
      items.checkEligibility || false;
    this.CarrierCMSummaryViewModel.isRigidContractingChecked =
      carrierId == 0 ? true : items.strictContracting || false;
    this.carrierName = items.name || "";
    this.shortName = items.carrierShortName || "";
    this.CarrierCMSummaryViewModel.selectedPaymentValuePreview =
      (paymentTemp && paymentTemp[0] && paymentTemp[0].label) ||
      items.defaultPaymentSourceId;
    this.CarrierCMSummaryViewModel.selectedPaymentValue =
      items.defaultPaymentSourceId == 0 ||
      (items.defaultPaymentSourceId == null && this.isCmEditMode)
        ? items.paymentSource &&
          "" + this.CarrierCMSummaryViewModel.PaymentList.values[0].value
        : (!this.isCmEditMode &&
            items.paymentSource &&
            "" + items.paymentSource[1].value) ||
          "" + items.defaultPaymentSourceId;
    this.selectedGroup =
      items.carrierGroupId != null &&
      items.carrierGroupId != undefined &&
      items.carrierGroupId > 0
        ? "" + items.carrierGroupId
        : "0";
    this.selectedGroupPreview =
      (selectedDrptemp && selectedDrptemp[0] && selectedDrptemp[0].label) || "";
    this.CarrierCMContactViewModel.loadContact(
      (items.carrierContacts && items.carrierContacts.data) || []
    );
    this.CarrierCMContactViewModel.companyId = this.selectedID;

    // Address related lookups
    if (items.carrierAddresses != null && items.carrierAddresses.data != null) {
      this.CarrierCMAddressViewModel.addressList.values =
        items.carrierAddresses.data;
    } else {
      this.CarrierCMAddressViewModel.addressList.values = [];
    }
    this.CarrierCMAddressViewModel.selectedCarrierId = this.selectedID;
    this.carrierAliasName = items.name || "";
    this.carrierAliasNames = items.masterCarrierName || "";
    this.carrierShortName = items.carrierShortName || "";
    this.selectedCarrier = items.masterCarrierId
      ? items.masterCarrierId.toUpperCase()
      : "0";
    this.selectedPreviewCarrier = items.masterCarrierName || "";
    this.selectedGroupName = items.carrierGroupName || "";
    this.selectedGroupId = items.carrierGroupId || 0;
    if (items.writingNumberDefaults) {
      this.CarrierCMSummaryViewModel.writingNumberDefaults =
        items.writingNumberDefaults;

      items && clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.isShowCreateToast = false;
      }, 9000);
    }
    //if (
    //  this.shortName &&
    //  this.shortName.length > 0 &&
    //  this.carrierName &&
    //  this.carrierName.length > 0 &&
    //  this.shortName.trim().split(/ +/).join(" ").length &&
    //  this.carrierName.trim().split(/ +/).join(" ").length
    //) {
    //  this.isSave = true;
    //} else {
    //  this.isSave = false;
    //}
  };

  @action loadCarrierLookups = async () => {
    let items = await ContractsStore.getAllMasterCarrierLookup();
    items && Utils.mapListItemsToDropdown(items, this.companyTypeList, "", "");
    this.selectedCarrier = "";
  };

  @action loadCarrierShortNameAndGroup = async () => {
    let items = await ContractsStore.getMasterCarrierById(
      this.selectedCarrier || "0"
    );
    if (items) {
      this.carrierShortName = items.shortName || "";
      this.carrierAliasName = items.name || "";
      this.selectedGroupName = items.carrierGroupName || "";
      this.selectedGroupId = items.groupId || 0;
      this.carrierAliasNames = items.name || "";
    }
  };

  @action onChangeCarrier = async (value: string) => {
    this.selectedCarrier = value;

    if (value && value != "0") {
      this.loadCarrierShortNameAndGroup();
      this.isSave = true;
    } else {
      this.selectedGroupName = "";
      this.carrierAliasName = "";
      this.carrierShortName = "";
      this.carrierAliasNames = "";
      this.isSave = false;
    }
  };

  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case CarrierCM.Details:
        tabIndex = 0;
        break;
      case CarrierCM.Notes:
        tabIndex = 1;
        break;
      case CarrierCM.Products:
        tabIndex = 2;
        break;
      case CarrierCM.Levels:
        tabIndex = 3;
        break;
      case CarrierCM.Websites:
        tabIndex = 4;
        break;
      case CarrierCM.Documents:
        tabIndex = 5;
        break;

      default:
        tabIndex = 0;
        break;
    }

    return tabIndex;
  }

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      this.isDetailsTab = false;
      this.isNotesTab = false;
      this.isWebsitesTab = false;
      this.isProductsTab = false;
      this.isLevelsTab = false;
      this.isDocumentsTab = false;
      switch (item) {
        case CarrierCM.Details:
          this.isDetailsTab = true;
          return;
        case CarrierCM.Notes:
          this.CarrierCMNotesViewModel.Load(this.selectedID);
          this.isNotesTab = true;
          return;
        case CarrierCM.Websites:
          this.CarrierCMWebsitesViewModel.Load(this.selectedID);
          this.isWebsitesTab = true;
          return;
        case CarrierCM.Products:
          this.isProductsTab = true;
          this.CarrierCMProductsViewModel.Load(this.selectedID);
          return;
        case CarrierCM.Levels:
          this.isLevelsTab = true;
          this.CarrierCMLevelsViewModel.Load(this.selectedID);
          return;

        case CarrierCM.Documents:
          this.isDocumentsTab = true;
          this.CarrierCMDocumentMainViewModel.Load(this.selectedID);
          return;
        default:
          return;
      }
    }
  };

  @action handleTabClick = (index: number | undefined, key: string): void => {
    switch (index) {
      case CarrierCMTabIndex.Details:
        this.handleLinkClick(CarrierCM.Details);
        break;
      case CarrierCMTabIndex.Notes:
        this.handleLinkClick(CarrierCM.Notes);
        break;
      case CarrierCMTabIndex.Products:
        this.handleLinkClick(CarrierCM.Products);
        break;
      case CarrierCMTabIndex.Levels:
        this.handleLinkClick(CarrierCM.Levels);
        break;
      case CarrierCMTabIndex.Websites:
        this.handleLinkClick(CarrierCM.Websites);
        break;

      case CarrierCMTabIndex.Documents:
        this.handleLinkClick(CarrierCM.Documents);
        break;
    }
  };
  readonly currentPageReportTemplate: string =
    "Showing {first} to {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }
  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [50, 100, 150, 100] : [];
  }

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;
  }

  @action noRecordsFound() {
    this.itemList.values = [];
  }

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
  }
}
class CarrierManagementValidator extends AbstractValidator<CarrierCMAddEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.carrierName)
      .isNotEmpty()
      .withFailureMessage("Carrier Name is required");
  }
}
