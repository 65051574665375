import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Collection from "../../infrastructure/CollectionHelper";
import utils from "../../infrastructure/Utils";
import { routes } from "../../router";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import IMASLog from "../../infrastructure/IMASLog";
import WorkflowStore from "../../store/WorkflowStore";
import {
  WorkflowNotesResponse,
  AddSendToCommentsModel,
  AddDocumentNoteModel,
  DuplicateWorkflowDocumentModel,
} from "../../services/WorkflowService";
import {
  AttachDocumentViewModel,
  DocumentListener,
} from "./AttachDocumentViewModel";
import {
  AddDocumentCommentViewModel,
  NoteDeleteListener,
} from "./AddDocumentCommentViewModel";
import { SendToViewModel, SendToListener } from "./SendToViewModel";
import { WorkflowStatusType } from "../../infrastructure/enum/WorkflowDetails";
import Utils from "../../infrastructure/Utils";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { Permission } from "../../infrastructure/enum/Permission";
import UserContext from "../../infrastructure/UserContext";
import { Constants } from "../../infrastructure/enum/Constants";
import PageContext from "../../infrastructure/PageContext";
import {
  AddEditDocumentViewModel,
  DocumentListener as DocumentListeners,
} from "../workflow/AddEditDocumentViewModel";
import { WorkflowDocumentListModel } from "../../services/WorkflowService";
import { googleTagManager } from "../../infrastructure/tracking";

export class DocumentInfoViewModel
  implements
    ViewModel,
    DocumentListener,
    DocumentListeners,
    NoteDeleteListener,
    SendToListener
{
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }

  get CanRoute(): boolean {
    return true;
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  listener: DocumentInformationListener | undefined;

  @observable
  AttachDocumentViewModel: AttachDocumentViewModel =
    new AttachDocumentViewModel();
  @observable
  AddDocumentCommentViewModel: AddDocumentCommentViewModel =
    new AddDocumentCommentViewModel();
  @observable SendToViewModel: SendToViewModel = new SendToViewModel();
  @observable documentDialogTitle: string = "";
  @observable isAttachDocumentDialogVisible: boolean = false;
  @observable noteDeleteDialogTitle: string = "";
  @observable isNoteDeleteDocumentDialogVisible: boolean = false;
  @observable isSendToDialogVisible: boolean = false;
  @observable
  public ErrorModel = new ErrorModel(new DocumentInfoValidator());
  @observable isMessgeVisible: boolean = false;
  @observable isEdit: boolean = false;

  @observable isException: boolean = false;
  @observable exceptionMsg: string = "";
  @observable title: string = "";
  @observable response: any;
  @observable isReplace: boolean = false;
  @observable isDeleteDocument: boolean = false;
  @observable documentId: number = 0;

  @observable agentId: number | undefined;
  @observable uplineAgentId: number | undefined;
  @observable marketerAgentId: number | undefined;
  @observable workflowStatusId: number | undefined;
  @observable fileName: string = "";
  @observable lastName: string = "";
  @observable firstName: string = "";
  @observable agent: string = "";
  @observable uplineAgent: string = "";
  @observable marketerAgent: string = "";
  @observable carrier: string = "";
  @observable product: string = "";
  @observable docType: string = "";
  @observable queue: string = "";
  @observable notes: string = "";
  @observable flag: string = "";
  @observable pendingCertificates: boolean | undefined;
  @observable submissionReceived: boolean | undefined;
  @observable appDate: Date | undefined;
  @observable receivedDate: Date | undefined;
  @observable assignedToUsers: any;
  @observable assignedToUserTeam: any;
  @observable isCompleteConfirm: boolean = false;
  @observable isDataEntryConfirm: boolean = false;
  @observable isAssignmentConfirm: boolean = false;
  @observable isDuplicateConfirm: boolean = false;
  @observable isPendingCertificationsConfirm: boolean = false;
  @observable isReceiptConfirm: boolean = false;
  @observable changeFormTitle: string = "Change Form";
  @observable isChangeFormDialogVisible: boolean = false;
  @observable isChangeForm: boolean | undefined;
  @observable docWorkFlowHistoryList = new Collection<WorkflowNotesResponse>();
  @observable workFlowStatusType: any;
  @observable selectedAssignedToUser: any;
  @observable selectedAssignedToUserTeam: any;
  @observable workflowAssignedToUsersList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable workflowAssignedToUserTeamList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedChangeFormFlag: string = "No";
  @observable changeFormFlagList = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  @action setChangeFormFlag = (value: string) => {
    this.selectedChangeFormFlag = value;
  };

  // MenuItems declared and defined

  @observable actionItemsForAssignmentQueue: MenuItem[] = [];
  @observable
  actionItemsWithNoSubmissionReceivedAndPendingCertifications: MenuItem[] = [];
  @observable
  actionItemsWithSubmissionReceivedAndNoPendingCertifications: MenuItem[] = [];
  @observable
  actionItemsWithNoSubmissionReceivedAndNoPendingCertifications: MenuItem[] =
    [];
  @observable
  actionItemsWithSubmissionReceivedAndPendingCertifications: MenuItem[] = [];
  @observable actionItemsForMissingInfoQueueWithContract: MenuItem[] = [];
  @observable actionItemsForMissingInfoQueueWithOutContract: MenuItem[] = [];
  @observable actionItemsForQueuesWithContract: MenuItem[] = [];
  @observable actionItemsForQueuesWithOutContract: MenuItem[] = [];
  @observable selectedWorkflowStatusType: string = "Scrub";
  @observable insuredName: string = "";

  // applicable to Queues: Scrub, Missing Info, Pending with DocumentType !== 'Contract'.
  @action getActionItemsForQueuesWithOutContract = () => {
    this.actionItemsForQueuesWithOutContract = [];
    let dataEntry = {
      label: "Data Entry",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDataEntryConfirmation(true);
        }, 300);
      },
    };
    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let replace = {
      label: "Replace",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            true,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };

    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
      this.actionItemsForQueuesWithOutContract.push(dataEntry);
    // this.actionItemsForQueuesWithOutContract.push(note);
    this.actionItemsForQueuesWithOutContract.push(contacts);
    this.actionItemsForQueuesWithOutContract.push(attach);
    this.actionItemsForQueuesWithOutContract.push(replace);
    let arr = Utils.getDeleteDocument();
    let hasDelete =
      arr &&
      arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
    UserContext.permissions.length > 0 &&
      Utils.hasUserPermission(Permission.DeleteDocument) &&
      hasDelete &&
      hasDelete.length > 0 &&
      this.actionItemsForQueuesWithOutContract.push(del);
    // this.actionItemsForQueuesWithOutContract.push(sendToAssignment);
  };
  // applicable to Queues: Scrub, Missing Info, Pending with DocumentType === 'Contract'.
  @action getActionItemsForQueuesWithContract = () => {
    this.actionItemsForQueuesWithContract = [];
    let dataEntry = {
      label: "Data Entry",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDataEntryConfirmation(true);
        }, 300);
      },
    };
    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let replace = {
      label: "Replace",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            true,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let duplicate = {
      label: "Duplicate",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDuplicateConfirmation(true);
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };

    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    setTimeout(() => {
      Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
        this.actionItemsForQueuesWithContract.push(dataEntry);
      // this.actionItemsForQueuesWithContract.push(note);
      this.actionItemsForQueuesWithContract.push(contacts);
      this.actionItemsForQueuesWithContract.push(attach);
      this.actionItemsForQueuesWithContract.push(replace);
      this.actionItemsForQueuesWithContract.push(duplicate);
      let arr = Utils.getDeleteDocument();
      let hasDelete =
        arr &&
        arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
      UserContext.permissions.length > 0 &&
        Utils.hasUserPermission(Permission.DeleteDocument) &&
        hasDelete &&
        hasDelete.length > 0 &&
        this.actionItemsForQueuesWithContract.push(del);
      // this.actionItemsForQueuesWithContract.push(sendToAssignment);
    }, 300);
  };
  // applicable to Queues: Missing Info with DocumentType !== 'Contract' and Carrier/Company === 'UnitedHealthCare' and Product !== 'Medicare Supplement (AARP)'.
  @action getActionItemsForMissingInfoQueueWithOutContract = () => {
    this.actionItemsForMissingInfoQueueWithOutContract = [];
    // let dataEntry = {
    //   label: "Data Entry",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setChangeFormDialogVisible(true);
    //     }, 300);
    //   },
    // };
    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let SendToMissing = {
      label: "Send To Missing",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setSendToDialogVisibilty(true);
        }, 300);
      },
    };

    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };

    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    setTimeout(() => {
      // Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
      //   this.actionItemsForMissingInfoQueueWithOutContract.push(dataEntry);
      // this.actionItemsForMissingInfoQueueWithOutContract.push(note);
      this.actionItemsForMissingInfoQueueWithOutContract.push(SendToMissing);
      this.actionItemsForMissingInfoQueueWithOutContract.push(contacts);
      this.actionItemsForMissingInfoQueueWithOutContract.push(attach);
      let arr = Utils.getDeleteDocument();
      let hasDelete =
        arr &&
        arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
      UserContext.permissions.length > 0 &&
        Utils.hasUserPermission(Permission.DeleteDocument) &&
        hasDelete &&
        hasDelete.length > 0 &&
        this.actionItemsForMissingInfoQueueWithOutContract.push(del);
      // this.actionItemsForMissingInfoQueueWithOutContract.push(sendToAssignment);
    }, 300);
  };
  // applicable to Queues: Missing Info with DocumentType === 'Contract' and Carrier/Company === 'UnitedHealthCare' and Product !== 'Medicare Supplement (AARP)'.
  @action getActionItemsForMissingInfoQueueWithContract = () => {
    this.actionItemsForMissingInfoQueueWithContract = [];

    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let SendToMissing = {
      label: "Send To Missing",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setSendToDialogVisibilty(true);
        }, 300);
      },
    };
    let duplicate = {
      label: "Duplicate",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDuplicateConfirmation(true);
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };

    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    // this.actionItemsForMissingInfoQueueWithContract.push(note);
    this.actionItemsForMissingInfoQueueWithContract.push(SendToMissing);
    this.actionItemsForMissingInfoQueueWithContract.push(contacts);
    this.actionItemsForMissingInfoQueueWithContract.push(attach);
    this.actionItemsForMissingInfoQueueWithContract.push(duplicate);
    let arr = Utils.getDeleteDocument();
    let hasDelete =
      arr &&
      arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
    UserContext.permissions.length > 0 &&
      Utils.hasUserPermission(Permission.DeleteDocument) &&
      hasDelete &&
      hasDelete.length > 0 &&
      this.actionItemsForMissingInfoQueueWithContract.push(del);
    // this.actionItemsForMissingInfoQueueWithContract.push(sendToAssignment);
  };
  // pendingCertificates === true && submissionReceived === true
  @action getActionItemsWithSubmissionReceivedAndPendingCertifications = () => {
    this.actionItemsWithSubmissionReceivedAndPendingCertifications = [];
    let dataEntry = {
      label: "Data Entry",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDataEntryConfirmation(true);
        }, 300);
      },
    };
    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let replace = {
      label: "Replace",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            true,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let duplicate = {
      label: "Duplicate",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDuplicateConfirmation(true);
        }, 300);
      },
    };
    let noPendingCertification = {
      label: "No Pending Certifications",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setPendingCertificationsConfirmation(true);
        }, 300);
      },
    };
    let receiptNotConfirmed = {
      label: "Receipt Confirmed",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setReceiptConfirmation(true);
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };
    let complete = {
      label: "Complete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setCompleteConfirmation(true);
        }, 300);
      },
    };
    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
      this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
        dataEntry
      );
    // this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(note);
    this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
      contacts
    );
    this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(attach);
    this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
      replace
    );
    this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
      duplicate
    );
    Utils.hasUserPermission(
      Permission.MarkContractsAsPendingCertificationsinWorkflow
    ) &&
      this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
        noPendingCertification
      );
    Utils.hasUserPermission(Permission.MarkContractsAsReceivedinWorkflow) &&
      this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
        receiptNotConfirmed
      );
    let arr = Utils.getDeleteDocument();
    let hasDelete =
      arr &&
      arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
    UserContext.permissions.length > 0 &&
      Utils.hasUserPermission(Permission.DeleteDocument) &&
      hasDelete &&
      hasDelete.length > 0 &&
      this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(del);
    Utils.hasUserPermission(Permission.CompleteWorkflowDocumentsInPending) &&
      this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
        complete
      );
    // this.actionItemsWithSubmissionReceivedAndPendingCertifications.push(
    //   sendToAssignment
    // );
  };
  // pendingCertificates === false && submissionReceived === false
  @action
  getActionItemsWithNoSubmissionReceivedAndNoPendingCertifications = () => {
    this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications = [];
    let dataEntry = {
      label: "Data Entry",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDataEntryConfirmation(true);
        }, 300);
      },
    };
    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let replace = {
      label: "Send To Missing",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setSendToDialogVisibilty(true);
        }, 300);
      },
    };
    let duplicate = {
      label: "Duplicate",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDuplicateConfirmation(true);
        }, 300);
      },
    };
    let pendingCertification = {
      label: "Pending Certifications",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setPendingCertificationsConfirmation(true);
        }, 300);
      },
    };
    let receiptConfirmed = {
      label: "Receipt Confirmed",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setReceiptConfirmation(true);
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };
    let complete = {
      label: "Complete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setCompleteConfirmation(true);
        }, 300);
      },
    };
    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
      this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
        dataEntry
      );
    // this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
    //   note
    // );
    this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
      contacts
    );
    this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
      attach
    );
    this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
      replace
    );
    this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
      duplicate
    );
    Utils.hasUserPermission(
      Permission.MarkContractsAsPendingCertificationsinWorkflow
    ) &&
      this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
        pendingCertification
      );
    Utils.hasUserPermission(Permission.MarkContractsAsReceivedinWorkflow) &&
      this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
        receiptConfirmed
      );
    let arr = Utils.getDeleteDocument();
    let hasDelete =
      arr &&
      arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
    UserContext.permissions.length > 0 &&
      Utils.hasUserPermission(Permission.DeleteDocument) &&
      hasDelete &&
      hasDelete.length > 0 &&
      this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
        del
      );
    Utils.hasUserPermission(Permission.CompleteWorkflowDocumentsInPending) &&
      this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
        complete
      );
    // this.actionItemsWithNoSubmissionReceivedAndNoPendingCertifications.push(
    //   sendToAssignment
    // );
  };
  // applicable to Queues: Assignment
  @action getActionItemsForAssignmentQueue = () => {
    this.actionItemsForAssignmentQueue = [];
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };

    this.actionItemsForAssignmentQueue.push(attach);
  };
  // pendingCertificates === true && submissionReceived === false
  @action
  getActionItemsWithNoSubmissionReceivedAndPendingCertifications = () => {
    this.actionItemsWithNoSubmissionReceivedAndPendingCertifications = [];
    let dataEntry = {
      label: "Data Entry",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDataEntryConfirmation(true);
        }, 300);
      },
    };
    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };
    let replace = {
      label: "Send To Missing",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setSendToDialogVisibilty(true);
        }, 300);
      },
    };
    let duplicate = {
      label: "Duplicate",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDuplicateConfirmation(true);
        }, 300);
      },
    };
    let noPendingCertification = {
      label: "No Pending Certifications",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setPendingCertificationsConfirmation(true);
        }, 300);
      },
    };
    let receiptConfirmed = {
      label: "Receipt Confirmed",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setReceiptConfirmation(true);
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };
    let complete = {
      label: "Complete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setCompleteConfirmation(true);
        }, 300);
      },
    };
    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
      this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
        dataEntry
      );
    // this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(note);
    this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
      contacts
    );
    this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
      attach
    );
    this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
      replace
    );
    this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
      duplicate
    );
    Utils.hasUserPermission(
      Permission.MarkContractsAsPendingCertificationsinWorkflow
    ) &&
      this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
        noPendingCertification
      );
    Utils.hasUserPermission(Permission.MarkContractsAsReceivedinWorkflow) &&
      this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
        receiptConfirmed
      );
    let arr = Utils.getDeleteDocument();
    let hasDelete =
      arr &&
      arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
    UserContext.permissions.length > 0 &&
      Utils.hasUserPermission(Permission.DeleteDocument) &&
      hasDelete &&
      hasDelete.length > 0 &&
      this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
        del
      );
    Utils.hasUserPermission(Permission.CompleteWorkflowDocumentsInPending) &&
      this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
        complete
      );
    // this.actionItemsWithNoSubmissionReceivedAndPendingCertifications.push(
    //   sendToAssignment
    // );
  };
  // pendingCertificates === false && submissionReceived === true
  @action
  getActionItemsWithSubmissionReceivedAndNoPendingCertifications = () => {
    this.actionItemsWithSubmissionReceivedAndNoPendingCertifications = [];
    let dataEntry = {
      label: "Data Entry",
      icon: "fa fa-file-pdf-o",

      command: () => {
        setTimeout(() => {
          this.setDataEntryConfirmation(true);
        }, 300);
      },
    };
    let replace = {
      label: "Send To Missing",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setSendToDialogVisibilty(true);
        }, 300);
      },
    };

    // let note = {
    //   label: "Note",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setNoteDeleteDocumentDialogVisibility(true, false);
    //     }, 300);
    //   },
    // };
    let contacts = {
      label: "Contacts",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.gotoContacts();
        }, 300);
      },
    };
    let attach = {
      label: "Attach",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setAttachDocumentDialogVisibility(
            true,
            false,
            this.workFlowStatusType
          );
        }, 300);
      },
    };

    let duplicate = {
      label: "Duplicate",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setDuplicateConfirmation(true);
        }, 300);
      },
    };
    let pendingCertification = {
      label: "Pending Certifications",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setPendingCertificationsConfirmation(true);
        }, 300);
      },
    };
    let receiptNotConfirmed = {
      label: "Receipt Confirmed",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setReceiptConfirmation(true);
        }, 300);
      },
    };
    let del = {
      label: "Delete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setNoteDeleteDocumentDialogVisibility(true, true);
        }, 300);
      },
    };
    let complete = {
      label: "Complete",
      icon: "fa fa-file-pdf-o",
      command: () => {
        setTimeout(() => {
          this.setCompleteConfirmation(true);
        }, 300);
      },
    };
    // let sendToAssignment = {
    //   label: "Send To Assignment",
    //   icon: "fa fa-file-pdf-o",
    //   command: () => {
    //     setTimeout(() => {
    //       this.setAssignmentConfirmation(true);
    //     }, 300);
    //   },
    // };
    Utils.hasUserPermission(Permission.MoveDocumentToDataEntry) &&
      this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
        dataEntry
      );
    // this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(note);
    this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
      contacts
    );
    this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
      attach
    );
    this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
      replace
    );
    this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
      duplicate
    );
    Utils.hasUserPermission(
      Permission.MarkContractsAsPendingCertificationsinWorkflow
    ) &&
      this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
        pendingCertification
      );
    Utils.hasUserPermission(Permission.MarkContractsAsReceivedinWorkflow) &&
      this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
        receiptNotConfirmed
      );
    let arr = Utils.getDeleteDocument();
    let hasDelete =
      arr &&
      arr.filter((ind: any) => ind.id === Constants.workflowDeleteDocument);
    UserContext.permissions.length > 0 &&
      Utils.hasUserPermission(Permission.DeleteDocument) &&
      hasDelete &&
      hasDelete.length > 0 &&
      this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
        del
      );
    Utils.hasUserPermission(Permission.CompleteWorkflowDocumentsInPending) &&
      this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
        complete
      );
    // this.actionItemsWithSubmissionReceivedAndNoPendingCertifications.push(
    //   sendToAssignment
    // );
  };

  //Adding the View Functionality on Click of a Filename: in DocumentInfoView
  //1st for Viewing Document
  @observable docId: number = 0;
  @observable isWarning: boolean = false;
  @action openDocumentLink = async (docId: number, id?: number) => {
    this.isWarning = false;
    this.isException = false;
    this.isMessgeVisible = false;
    this.response = "";
    try {
      if (id !== undefined && id !== null && id > 0) {
        var result = await WorkflowStore.downloadWorkflowDocument(id);
        if (result != null) {
          if (result.pdfData && result.pdfData !== null) {
            this.loadDocument(result.pdfData, id, result.filename);
          } else {
            if (result.message && result.message !== null) {
              // this.response = result.message;
              // this.isMessgeVisible = true;
              // setTimeout(() => {
              //  this.isMessgeVisible = false;
              //  this.response = ''
              // }, 1500)
              let successResponse = {
                status: 400,
                title: result.message,
                errors: { "": [] },
              };
              this.response = JSON.stringify(successResponse);
              this.isMessgeVisible = true;
              setTimeout(() => {
                this.isMessgeVisible = false;
                this.response = "";
              }, 2500);
            } else {
              this.isException = true;
            }
          }
        }
      } else {
        this.response = "Invalid document Id. PDF File not found!";
        this.isMessgeVisible = true;
        setTimeout(() => {
          this.isMessgeVisible = false;
          this.response = "";
        }, 2500);
      }
    } catch (e) {
      var response = e.response;
      this.response = e.response;
      this.isMessgeVisible = true;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.response = "PDF File for this document not found!";
          this.response = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 2500);
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  //2nd Change: onClick View
  @action loadDocument = (base64: string, id?: number, filename?: string) => {
    let base = window.location.href;
    let url = `${base}/pdfViewer/id/${id}`;
    let win = window.open(url, "_blank");
    let html = "";
    html += "<html>";
    html += '<body style="margin:0!important">';
    html += '<div style="width: 100%;height: 100%; position: relative; ">';
    html +=
      '<div style="width: 100%;height: 100%; position: absolute;top: 0;left: 0; z-index: 10;">';
    html +=
      '<embed width="100%" height="100%" src="data:application/pdf;base64,' +
      base64 +
      '" type="application/pdf" />';
    html += "</div>";
    html += "</div>";
    html += "</body>";
    html += "</html>";
    setTimeout(() => {
      if (win !== null) {
        win.document.write(html);
        win.document.title = filename ? filename : "PDF file";
      }
    }, 0);
  };

  //3rd Change to add onClick === "View" Functionality
  @observable exceptionMessage: string = Constants.Error;
  @observable selectedFile: any;

  @action openFile = async (value: any, id?: number) => {
    this.isWarning = false;
    this.isException = false;
    try {
      var fileName = value;
      var result = await WorkflowStore.downloadFile(fileName);
      if (result != null) {
        if (result.pdfData && result.pdfData !== null) {
          this.loadDocument(result.pdfData, id, result.filename);
        } else {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        }
      }
    } catch (e) {
      var response = e.response;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  @observable selectedCompany: any;
  @observable selectedType: any;
  @observable selectedProduct: any;
  @observable selectedDivision: any;
  @observable fileData: any = null;
  @observable searchValue: string = "";
  @observable isNotReceivedQueue: boolean = false;
  @observable selectedDocumentList: any;
  @action setStates = () => {
    let selectedAssignedToUser = this.selectedAssignedToUser;
    this.selectedAssignedToUser = selectedAssignedToUser;
  };
  @action viewDocumentInfo(rowData: any) {
    this.documentId = rowData.id;
    this.workFlowStatusType = rowData.workFlowStatus;
    if (this.documentId != null) {
      // Assigning the User on selecting the Document.
      this.assign();
    }
    let storeValuesObj = {
      selectedAssignedToUser: this.selectedAssignedToUser,
      selectedAssignedToUserTeam: this.selectedAssignedToUserTeam,
      selectedType: this.selectedType,
      selectedCompany: this.selectedCompany,
      selectedProduct: this.selectedProduct,
      selectedDivision: this.selectedDivision,
      searchValue: this.searchValue,
      isNotReceivedQueue: this.isNotReceivedQueue,
      selectedDocumentList: parseInt(localStorage.getItem("queueRows") || "50"),
    };
    Utils.setDocInfo(true, storeValuesObj);
    this.setStates();
    routes.documentInfo.push({
      documentId: this.documentId ? this.documentId : 0,
      workflowStatus: this.workFlowStatusType ? this.workFlowStatusType : "",
    });
  }

  @action downloadFile = async (value: any) => {
    this.isWarning = false;
    this.isException = false;
    try {
      var fileName = value;
      var result = await WorkflowStore.downloadFile(fileName);
      if (result != null) {
        if (result.pdfData) {
          this.downloadPDFFile(result);
        } else {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        }
      }
    } catch (e) {
      var response = e.response;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  @action downloadPDFFile = (result: any) => {
    if (result.pdfData) {
      setTimeout(() => {
        var link = document.createElement("a");
        let n = result.filename ? result.filename.split(".")[0] : "";
        link.href = "data:application/pdf;base64," + result.pdfData;
        link.textContent = "Download PDF";
        link.download = n !== "" ? n : "document.pdf";
        link.click();
        document.body.appendChild(link);
        link.remove();
      }, 0);
    }
  };

  @action assign = async () => {
    try {
      await WorkflowStore.assign(Number(this.documentId));
    } catch (error) {
      IMASLog.log("exception while assign the document.");
      this.isException = true;
    }
  };

  @observable
  AddEditDocumentViewModel: AddEditDocumentViewModel =
    new AddEditDocumentViewModel();
  @observable isAddDocumentDialogVisible: boolean = false;
  @observable selectedDocRow: any = {};
  @action setAddDocumentDialogVisibility = async (
    value: boolean,
    isEdit: boolean | undefined,
    fileName: any,
    workFlowStatusType: any,
    isCancel: boolean
  ) => {
    this.workFlowStatusType = workFlowStatusType;
    this.AddEditDocumentViewModel.setWorkFlowStatusType(
      this.workFlowStatusType
    );

    if (
      value === false &&
      isCancel &&
      this.workFlowStatusType === WorkflowStatusType.ScrubQueue
    ) {
      //clear the user metadata on cancel so that other users can work on the Received Queue file.
      if (fileName !== null && fileName !== undefined && fileName !== "") {
        await WorkflowStore.cancelFile(fileName);
        this.loadDocs();
      }
    }
    if (value) {
      this.AddEditDocumentViewModel.ResetValidate();
      this.AddEditDocumentViewModel.reset();
      if (isEdit) {
        this.documentDialogTitle = "Edit Document Information";
        var docId = this.documentId;
        var result = await WorkflowStore.getWorkflowDocInfo(docId);
        if (result) {
          //Comany Id, Company Name, product Id
          this.AddEditDocumentViewModel.Load(
            true,
            result.companyId ? result.companyId : 0,
            result.productId ? result.productId : 0,
            this.companyList.values
          );
          this.AddEditDocumentViewModel.setSelectedDocumentId(
            result.id ? result.id : 0
          );

          this.AddEditDocumentViewModel.setSelectedFile(
            result.filename ? result.filename : ""
          );
          this.AddEditDocumentViewModel.setFirstName(
            result.fName ? result.fName : ""
          );
          this.AddEditDocumentViewModel.setLastName(
            result.lName ? result.lName : ""
          );
          var date = utils.getDateInFormat(
            result.appDate ? result.appDate : new Date()
          );

          this.AddEditDocumentViewModel.setAppDate(new Date(date));
          this.AddEditDocumentViewModel.setSelectedType(
            result.typeName ? result.typeName.trim() : ""
          );
          this.AddEditDocumentViewModel.setSelectedAgent(
            result.agentName,
            result.agentId ? result.agentId : 0
          );
          this.AddEditDocumentViewModel.setSelectedUplineAgent(
            result.uplineAgentName,
            result.uplineAgentId ? result.uplineAgentId : 0
          );

          this.AddEditDocumentViewModel.setSelectedMarketerAgent(
            result.marketerAgentName,
            result.marketerAgentId ? result.marketerAgentId : 0
          );

          this.AddEditDocumentViewModel.setSelectedCompany(
            result.companyId ? result.companyId : 0
          );

          // this.AddEditDocumentViewModel.setSelectedCompanyName(
          //   result.companyName ? result.companyName : ""
          // );
          this.AddEditDocumentViewModel.setSelectedFlag(
            result.flag ? result.flag.trim() : ""
          );
          this.AddEditDocumentViewModel.setComments(
            result.notes ? result.notes : ""
          );
          // this.AddEditDocumentViewModel.setSelectedProduct(
          // result.productId ? result.productId : 0
          // );
        }
      } else {
        // set the user metadata to notify other users when the file is stealed.
        this.resetAddDocumentDialog();
        this.documentDialogTitle = "Add Document to Workflow";
        this.AddEditDocumentViewModel.setSelectedAssignedToUser("");
        this.AddEditDocumentViewModel.setSelectedAssignedToUserTeam("");
        this.AddEditDocumentViewModel.setSelectedCompany(0);
        this.AddEditDocumentViewModel.setSelectedProduct(0);
        this.AddEditDocumentViewModel.setSelectedFile(fileName);
      }
      this.AddEditDocumentViewModel.setDialogVisibility(value);
    }
    this.isAddDocumentDialogVisible = value;
    // PageContext.setIsOverlay(!this.isAddDocumentDialogVisible)
  };
  cancelAddEditDocument(): void {
    this.setAddDocumentDialogVisibility(
      false,
      false,
      this.selectedDocRow.insuredName,
      this.workFlowStatusType,
      true
    );
  }

  closeDialogAfterSaveDocument(): void {
    this.setAddDocumentDialogVisibility(
      false,
      false,
      undefined,
      this.workFlowStatusType,
      false
    );
    this.getWorkflowDocInfo();
    setTimeout(() => this.loadDocumentWorkflowHistory(), 300);
  }

  addEditDocumentListener(): void {
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, 2000);
    this.loadDocs();
  }

  documentInfoListener(): void {
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, 2000);
    this.loadDocs();
  }

  @observable rows: number = 50; // rows is pagesize
  @observable totalRecords: number = 0;
  @observable first: number = 0; // first is pageindex
  @observable startIndex: number = 0;
  @observable sortColumn: string = "appDate";
  @observable sortOrder: boolean = false;
  @observable isWorkflowApiCall: boolean = true;
  @observable documentList = new Collection<WorkflowDocumentListModel>();

  @action loadDocs = async () => {
    this.isException = false;
    try {
      var searchdto: any = {};
      searchdto.WorkFlowStatus =
        this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue &&
        Utils.hasUserPermission(Permission.AccessReceivedQueue)
          ? 1
          : this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue &&
            Utils.hasUserPermission(Permission.AccessScrubQueue)
          ? 2
          : this.selectedWorkflowStatusType ===
              WorkflowStatusType.MissingInfoQueue &&
            Utils.hasUserPermission(Permission.AccessMissingInfoQueue)
          ? 3
          : this.selectedWorkflowStatusType ===
              WorkflowStatusType.DataEntryQueue &&
            Utils.hasUserPermission(Permission.AccessDataEntryQueue)
          ? 4
          : this.selectedWorkflowStatusType ===
              WorkflowStatusType.PendingQueue &&
            Utils.hasUserPermission(Permission.AccessPendingQueue)
          ? 100
          : 102;
      searchdto.AssignedTo =
        this.selectedAssignedToUser && this.selectedAssignedToUser > 0
          ? this.selectedAssignedToUser
          : undefined;
      searchdto.AssignedToUserTeam =
        this.selectedAssignedToUserTeam && this.selectedAssignedToUserTeam > 0
          ? this.selectedAssignedToUserTeam
          : undefined;
      searchdto.FileTypeId =
        this.selectedType && this.selectedType > 0
          ? this.selectedType
          : undefined;
      searchdto.CompanyId =
        this.selectedCompany && this.selectedCompany > 0
          ? this.selectedCompany
          : undefined;
      searchdto.ProductId =
        this.selectedProduct && this.selectedProduct > 0
          ? this.selectedProduct
          : undefined;
      searchdto.DivisionId =
        this.selectedDivision && this.selectedDivision > 0
          ? this.selectedDivision
          : undefined;
      searchdto.Search = this.searchValue ? this.searchValue : undefined;

      var result = await WorkflowStore.getWorkflowDocsByQueue(
        searchdto,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (result != null) {
        if (result.data) {
          this.totalRecords = result.recordCount ? result.recordCount : 0;
          this.documentList.values = result.data;
        } else {
          this.documentList.values = [];
        }
      }
      this.isWorkflowApiCall = true;
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
      this.documentList.values = [];
      this.isException = true;
      this.isWorkflowApiCall = true;
    }
  };

  @action resetAddDocumentDialog() {
    this.AddEditDocumentViewModel.exceptionMsg = "";
    this.AddEditDocumentViewModel.isException = false;
    this.AddEditDocumentViewModel.selectedAgent = "";
    this.AddEditDocumentViewModel.firstName = "";
    this.AddEditDocumentViewModel.lastName = "";
    this.AddEditDocumentViewModel.selectedType = "Application";
    this.AddEditDocumentViewModel.selectedFlag = "-1";
    this.AddEditDocumentViewModel.comments = "";
  }

  @observable editButtonOnScrubQueue: MenuItem[] = [];
  @action getEditButtonOnScrubQueue = () => {
    this.editButtonOnScrubQueue = [];
    let download = {
      label: "Download",
      icon: "fa fa-download",
      command: () => {
        setTimeout(() => {
          this.downloadDocumentLink(this.docId);
        }, 300);
      },
    };
    this.editButtonOnScrubQueue.push(download);
  };

  @action downloadDocumentLink = async (docId: number) => {
    this.isWarning = false;
    this.isException = false;
    this.isMessgeVisible = false;
    this.response = "";
    try {
      var result = await WorkflowStore.downloadWorkflowDocument(docId);
      if (result != null) {
        if (result.pdfData) {
          this.downloadPDFFile(result);
        } else {
          // this.response = "PDF File for this document not found!";
          //   this.isMessgeVisible = true;
          //   setTimeout(() => {
          //  this.isMessgeVisible = false;
          //  this.response = ''
          //   }, 1500)
          let successResponse = {
            status: 400,
            title: result.message,
            errors: { "": [] },
          };
          this.response = JSON.stringify(successResponse);
          this.isMessgeVisible = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 2500);
        }
      }
    } catch (e) {
      var response = this.response;
      this.response = this.response;
      this.isMessgeVisible = true;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.response = "PDF File for this document not found!";
          this.isMessgeVisible = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 2500);
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  @action setSelectedDoc = (docId: number) => {
    this.docId = docId;
  };
  @action setSelectedRow = (value: any) => {
    this.selectedDocRow = value;
  };
  @action setSelectedFile = (file: string) => {
    this.selectedFile = file;
  };

  @observable isStealConfirm: boolean = false;
  @action setStealConfirmation(value: boolean) {
    this.isStealConfirm = value;
  }

  @observable actionItemsForDocTypeContractInvite: MenuItem[] = [];
  @action getActionItemsForDocTypeContractInvite = () => {
    this.actionItemsForDocTypeContractInvite = [];
    let edit = {
      label: "Edit",
      icon: "fa fa-edit",
      command: () => {
        setTimeout(() => {
          this.setAddDocumentDialogVisibility(
            true,
            true,
            this.selectedDocRow.insuredName,
            this.selectedWorkflowStatusType,
            false
          );
        }, 300);
      },
    };
    let download = {
      label: "Download",
      icon: "fa fa-download",
      command: () => {
        setTimeout(() => {
          this.downloadDocumentLink(this.docId);
        }, 300);
      },
    };
    if (UserContext.permissions.length > 0) {
      Utils.hasUserPermission(Permission.EditDocumentMetadata) &&
        this.actionItemsForDocTypeContractInvite.push(edit);
    }
    this.actionItemsForDocTypeContractInvite.push(download);
  };
  @action setSelectedCompany = (value: string) => {
    this.selectedCompany = value;
  };

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  // MenuItems declared and defined
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };

  setDocumentInfoListener(listener: DocumentInformationListener) {
    this.listener = listener;
  }

  @computed
  get formatAppDate(): string {
    if (this.appDate) return utils.getDateInFormat(this.appDate);
    return "";
  }

  @computed
  get formatReceivedDate(): string {
    if (this.receivedDate) return utils.getDateInFormat(this.receivedDate);
    return "";
  }

  @action setNoteDeleteDocumentDialogVisibility = (
    visibilty: boolean,
    isDeleteDocument: boolean | undefined
  ) => {
    if (isDeleteDocument) {
      this.isDeleteDocument = true;
      this.noteDeleteDialogTitle = `Delete document`;
    } else {
      this.isDeleteDocument = false;
      this.noteDeleteDialogTitle = `Add Note`;
    }
    this.isNoteDeleteDocumentDialogVisible = visibilty;
    if (!visibilty) this.resetNoteDelete();
  };

  @action setAttachDocumentDialogVisibility = (
    visibilty: boolean,
    isReplace: boolean | undefined,
    workFlowStatusType: any
  ) => {
    if (isReplace) {
      this.isReplace = true;
      this.documentDialogTitle = `Replacing document for ${this.firstName} ${this.lastName}`;
    } else {
      this.isReplace = false;
      this.documentDialogTitle = "Attach Document";
    }
    this.isAttachDocumentDialogVisible = visibilty;
    if (!visibilty) this.resetAttachReplace();
  };

  @action setSendToDialogVisibilty = (visibilty: boolean) => {
    this.isSendToDialogVisible = visibilty;
    if (visibilty) {
      let loggedInUser = Utils.getUserId() || 0;
      this.SendToViewModel.selectedAssignedToUser = loggedInUser.toString();
      this.SendToViewModel.sendtoEmailUserList = [{ label: "Me", value: "1" }];
    }
    if (!visibilty) this.resetSendToUser();
  };

  @action setSelectedAssignedToUser = (value: string) => {
    this.selectedAssignedToUser = value;
    if (
      this.selectedAssignedToUser !== "ALL" &&
      this.selectedAssignedToUser !== ""
    ) {
      this.selectedAssignedToUserTeam = "0";
    } else {
      this.selectedAssignedToUser = "0";
    }
  };

  @action setSelectedAssignedToUserTeam = (value: string) => {
    this.selectedAssignedToUserTeam = value;
    if (
      this.selectedAssignedToUserTeam !== "ALL" &&
      this.selectedAssignedToUserTeam !== ""
    ) {
      this.selectedAssignedToUser = "0";
    } else {
      this.selectedAssignedToUserTeam = "0";
    }
  };

  @action setIsException(value: boolean) {
    this.isException = value;
  }

  @action goToAgentWorkflowTab(value: number | undefined, isAgent: boolean) {
    if (isAgent) {
      this.agentId = value;
      window.open(
        window.location.origin + "/agents/" + this.agentId + "/summary",
        "_blank"
      );
    } else {
      this.uplineAgentId = value;
      window.open(
        window.location.origin + "/agents/" + this.uplineAgentId + "/summary",
        "_blank"
      );
    }
  }

  @observable missingInfoComments: string = "";
  @action setMissingInfoComments(value: string) {
    this.missingInfoComments = value;
  }

  @observable addComments: string = "";
  @action setAddComments(value: string) {
    this.addComments = value;
  }

  @action attachReplaceDocumentListener(): void {
    let docId = this.AttachDocumentViewModel.newDocId;
    routes.documentInfo.push({
      documentId: docId,
      workflowStatus: this.getLastItem,
    });
  }
  @action cancelAttachReplaceDocument(): void {
    this.setAttachDocumentDialogVisibility(
      false,
      false,
      this.workFlowStatusType
    );
  }

  @action noteDeleteDocumentListener(): void {
    this.loadDocumentWorkflowHistory();
  }

  @action cancelNoteDeleteDocument(): void {
    this.setNoteDeleteDocumentDialogVisibility(false, false);
  }

  @action sendToUserListener(): void {}

  @action cancelSendToUser(): void {
    this.setSendToDialogVisibilty(false);
  }

  @action resetSendToUser() {
    this.SendToViewModel.comments = "";
    this.SendToViewModel.selectedAssignedToUserTeam = "0";
    this.SendToViewModel.selectedAssignedToUser = "0";
    this.SendToViewModel.moveToMissingInfo = true;
    this.SendToViewModel.sendEmailTo = false;
    this.SendToViewModel.isMessageVisible = false;
    this.SendToViewModel.response = "";
    this.SendToViewModel.ResetValidate();
  }

  @action resetAttachReplace() {
    this.AttachDocumentViewModel.selectedLocation = "1";
    this.AttachDocumentViewModel.isUpload = false;
    this.AttachDocumentViewModel.comments = "";
    this.AttachDocumentViewModel.fileData = null;
    this.AttachDocumentViewModel.fileName = "";
    this.AttachDocumentViewModel.isMessageVisible = false;
    this.AttachDocumentViewModel.response = "";
    this.AttachDocumentViewModel.ResetValidate();
  }

  @action resetNoteDelete() {
    this.AddDocumentCommentViewModel.isUpload = false;
    this.AddDocumentCommentViewModel.comments = "";
    this.AddDocumentCommentViewModel.fileName = "";
    this.AddDocumentCommentViewModel.isMessageVisible = false;
    this.AddDocumentCommentViewModel.response = "";
    this.AddDocumentCommentViewModel.ResetValidate();
  }

  @action Load = async (docId: number, workflowStatus: string) => {
    localStorage.setItem("workFlowBackItem", "true");
    this.AddEditDocumentViewModel.setDocumentListener(this);
    this.documentId = docId;
    this.workFlowStatusType = workflowStatus
      ? workflowStatus
      : this.getLastItem;
    this.getActionItemsForAssignmentQueue();
    this.getActionItemsWithNoSubmissionReceivedAndPendingCertifications();
    this.getActionItemsWithSubmissionReceivedAndNoPendingCertifications();
    this.getActionItemsWithNoSubmissionReceivedAndNoPendingCertifications();
    this.getActionItemsWithSubmissionReceivedAndPendingCertifications();
    this.getActionItemsForMissingInfoQueueWithContract();
    this.getActionItemsForMissingInfoQueueWithOutContract();
    this.getActionItemsForQueuesWithContract();
    this.getActionItemsForQueuesWithOutContract();
    this.AttachDocumentViewModel.setDocumentListener(this);
    this.AddDocumentCommentViewModel.setDocumentListener(this);
    this.SendToViewModel.setDocumentListener(this);
    this.resetDocumentInfo();
    await this.getWorkflowDocInfo();
    await this.loadDocumentWorkflowHistory();
    await this.loadLookups();
    this.SendToViewModel.documentId = this.documentId;
    this.SendToViewModel.emailList = "";
    if (this.workFlowStatusType === WorkflowStatusType.ScrubQueue) {
      localStorage.setItem("isLoadUpline", "true");
    } else {
      localStorage.setItem("isLoadUpline", "false");
    }
    if (
      this.workFlowStatusType === WorkflowStatusType.PendingQueue ||
      this.workFlowStatusType === WorkflowStatusType.ScrubQueue ||
      this.workFlowStatusType === WorkflowStatusType.MissingInfoQueue
    )
      await this.getEmailsForSendTo();
  };

  @action getEmailsForSendTo = async () => {
    this.SendToViewModel.emailList = "";
    try {
      let result = await WorkflowStore.agentContactEmail(this.documentId);
      if (result.data) {
        this.SendToViewModel.emailList = result.data.map((index: any) => index);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  @computed
  get getLastItem() {
    let url = decodeURIComponent(window.location.href);
    return url.substring(url.lastIndexOf("/") + 1);
  }

  @action loadLookups = async () => {
    var defaultItem = { label: "ALL", value: "0" };
    try {
      var result = await WorkflowStore.getAllLookup();
      if (result != null) {
        if (result.workflowAssignedToUsersList != null) {
          this.workflowAssignedToUsersList.values = [];
          result.workflowAssignedToUsersList.forEach((obj: any) => {
            var workflowAssignedToUsersLookup = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.workflowAssignedToUsersList.values.push(
              workflowAssignedToUsersLookup
            );
          });

          this.SendToViewModel.assignedToUsers = [
            { label: "ALL", value: "0" },
          ].concat(this.mapListItemAndSort(result.workflowAssignedToUsersList));

          this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
          this.selectedAssignedToUser = "0";
          this.SendToViewModel.selectedAssignedToUserTeam = "0";
        } else {
          this.workflowAssignedToUsersList.values = [];
          this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
          this.selectedAssignedToUser = "0";
        }

        if (result.workflowAssignedToUserTeamList != null) {
          this.workflowAssignedToUserTeamList.values = [];
          result.workflowAssignedToUserTeamList.forEach((obj: any) => {
            var workflowAssignedToUserTeamLookup = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.workflowAssignedToUserTeamList.values.push(
              workflowAssignedToUserTeamLookup
            );
          });

          this.SendToViewModel.assignedToUserTeam = [
            { label: "ALL", value: "0" },
          ].concat(
            this.mapListItemAndSort(result.workflowAssignedToUserTeamList)
          );

          this.workflowAssignedToUserTeamList.values.splice(0, 0, defaultItem);
          this.selectedAssignedToUserTeam = "0";
        } else {
          this.workflowAssignedToUserTeamList.values = [];
          this.workflowAssignedToUserTeamList.values.splice(0, 0, defaultItem);
          this.selectedAssignedToUserTeam = "0";
        }
        if (result.companyList != null) {
          this.companyList.values = [];
          result.companyList.forEach((obj: any) => {
            var data = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value : "",
            };
            this.companyList.values.push(data);
          });
          this.AddEditDocumentViewModel.carriers = [
            { label: "ALL", value: "0" },
          ].concat(this.mapListItemAndSort(result.companyList));
          this.companyList.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
        } else {
          this.companyList.values = [];
          this.companyList.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
        }
      }
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);

      this.workflowAssignedToUsersList.values = [];
      this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
      this.selectedAssignedToUser = "0";

      this.workflowAssignedToUserTeamList.values = [];
      this.workflowAssignedToUserTeamList.values.splice(0, 0, defaultItem);
      this.selectedAssignedToUserTeam = "0";
    }
  };

  @action goBackToDocWorkflow() {
    // return window.history.back();
    routes.docWorkflow.replace();
  }

  @action gotoContacts = () => {
    routes.licenseCommunication.push({
      documentId: this.documentId ? this.documentId : 0,
    });
  };

  @action onCompleteClick = async (value: any) => {
    try {
      await WorkflowStore.updateWorkflowDocumentStatus(this.documentId);

      googleTagManager.trackAction("workflow_doc_moved", {
        feature: "Workflow",
        user_id: UserContext.userId,
        old_queue: this.workFlowStatusType,
        new_queue: WorkflowStatusType.CompletedQueue,
      });
      this.setCompleteConfirmation(false);
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log(
        "exception while processing complete action: " + this.documentId
      );
      this.isException = true;
      this.setCompleteConfirmation(false);
    }
  };

  @action setCompleteConfirmation(value: boolean) {
    this.isCompleteConfirm = value;
  }

  @action onDataEntryClick = async (value: any) => {
    try {
      this.workflowStatusId =
        this.workFlowStatusType === WorkflowStatusType.ScrubQueue
          ? 2
          : this.workFlowStatusType === WorkflowStatusType.MissingInfoQueue
          ? 3
          : this.workFlowStatusType === WorkflowStatusType.DataEntryQueue
          ? 4
          : this.workFlowStatusType === WorkflowStatusType.PendingQueue
          ? 100
          : 102;
      googleTagManager.trackAction("workflow_doc_moved", {
        feature: "Workflow",
        user_id: UserContext.userId,
        old_queue: this.workFlowStatusType,
        new_queue: WorkflowStatusType.DataEntryQueue,
      });
      await WorkflowStore.dataEntry(this.documentId, this.workflowStatusId);
      this.setDataEntryConfirmation(false);
      PageContext.setIsMovedToDataEntry(true);
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log(
        "exception while processing data entry action: " + this.documentId
      );
      this.isException = true;
      this.setDataEntryConfirmation(false);
    }
  };

  @action setDataEntryConfirmation(value: boolean) {
    this.isDataEntryConfirm = value;
  }

  @action onAssignmentClick = async (value: any) => {
    try {
      await WorkflowStore.assignment(this.documentId);
      this.setAssignmentConfirmation(false);
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log(
        "exception while processing Send To Assignment action: " +
          this.documentId
      );
      this.isException = true;
      this.setAssignmentConfirmation(false);
    }
  };

  @action setAssignmentConfirmation(value: boolean) {
    this.isAssignmentConfirm = value;
  }

  @action onDuplicateClick = async (value: any) => {
    try {
      var duplicateModeldto: DuplicateWorkflowDocumentModel = {};
      duplicateModeldto.notes = " ";
      await WorkflowStore.duplicate(this.documentId, duplicateModeldto);
      this.setDuplicateConfirmation(false);
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log(
        "exception while processing Duplicate action: " + this.documentId
      );
      this.isException = true;
      this.setDuplicateConfirmation(false);
    }
  };

  @action setDuplicateConfirmation(value: boolean) {
    this.isDuplicateConfirm = value;
  }

  @action onPendingCertificationsClick = async (value: any) => {
    try {
      await WorkflowStore.pendingCertifications(this.documentId);
      this.setPendingCertificationsConfirmation(false);
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log(
        "exception while processing Pending Certifications: " + this.documentId
      );
      this.isException = true;
      this.setPendingCertificationsConfirmation(false);
    }
  };

  @action setPendingCertificationsConfirmation(value: boolean) {
    this.isPendingCertificationsConfirm = value;
  }

  @action onReceiptClick = async (value: any) => {
    try {
      await WorkflowStore.receipt(this.documentId);
      this.setReceiptConfirmation(false);
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log("exception while processing Receipt: " + this.documentId);
      this.isException = true;
      this.setReceiptConfirmation(false);
    }
  };

  @action setReceiptConfirmation(value: boolean) {
    this.isReceiptConfirm = value;
  }

  @action setChangeFormDialogVisible = async (value: boolean) => {
    this.isChangeFormDialogVisible = value;
  };

  @action sendChangeForm = async () => {
    try {
      this.workflowStatusId = 3;
      this.isChangeForm = this.selectedChangeFormFlag === "Yes" ? true : false;
      await WorkflowStore.changeForm(
        this.documentId,
        this.workflowStatusId,
        this.isChangeForm
      );
      this.isChangeFormDialogVisible = false;
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log("exception while sending Change Form: " + this.documentId);
      this.isException = true;
      this.isChangeFormDialogVisible = false;
    }
  };

  @action save = async () => {
    try {
      var addCommentsdto: AddSendToCommentsModel = {};
      addCommentsdto.workflowStatusId =
        this.workFlowStatusType === WorkflowStatusType.DataEntryQueue ? 4 : 102;
      addCommentsdto.assignedToUserId =
        this.selectedAssignedToUser && this.selectedAssignedToUser > 0
          ? Number(this.selectedAssignedToUser)
          : 0;
      addCommentsdto.assignedToTeamId =
        this.selectedAssignedToUserTeam && this.selectedAssignedToUserTeam > 0
          ? Number(this.selectedAssignedToUserTeam)
          : 0;
      addCommentsdto.chkSendEmail = false;
      addCommentsdto.chkMoveToMissingInfo = false;
      addCommentsdto.notes = this.missingInfoComments
        ? this.missingInfoComments
        : "";

      // var notesdto: AddDocumentNoteModel = {};
      // notesdto.id = this.documentId;
      // notesdto.userId = 0;
      // notesdto.notes = this.addComments ? this.addComments : "";
      // notesdto.dateCreated = new Date();

      if (
        addCommentsdto.assignedToUserId === 0 &&
        addCommentsdto.assignedToTeamId === 0
        // &&
        // (notesdto.notes === "" || notesdto.notes.trim().length <= 0)
      ) {
        setTimeout(() => {
          this.exceptionMsg = "Select an individual or user team";
        }, 250);

        this.isException = true;
        setTimeout(() => (this.isException = false), 3000);
        return 0;
      } else {
        this.exceptionMsg = "";
        this.isException = false;
      }

      if (
        addCommentsdto.assignedToUserId !== 0 ||
        addCommentsdto.assignedToTeamId !== 0
      ) {
        try {
          await WorkflowStore.sendTo(this.documentId, addCommentsdto);
        } catch (e: any) {
          IMASLog.log(
            "exception while Send the document back to Missing Info."
          );
          this.isMessgeVisible = true;
          e && e.text
            ? e &&
              e.text().then((res: any) => {
                this.response = res;
              })
            : (this.response = e.response);
          return false;
        }
      }

      // if (notesdto.notes !== "" && notesdto.notes.trim().length > 0) {
      //   try {
      //     this.isException = false
      //     await WorkflowStore.addWorkflowDocumentNotes(notesdto.id, notesdto);
      //   } catch (error) {
      //     IMASLog.log("exception while adding Document notes.");
      //     this.isException = true;
      //   }
      // }

      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (e) {
      this.isException = true;
    }
  };

  @action saveComments = async () => {
    let notesdto: AddDocumentNoteModel = {};
    //notesdto.id = this.documentId;
    //notesdto.userId = 0;
    notesdto.notes = this.addComments ? this.addComments : "";
    //notesdto.dateCreated = new Date();
    if (notesdto.notes !== "" && notesdto.notes.trim().length > 0) {
      try {
        this.isException = false;
        await WorkflowStore.addWorkflowDocumentNotes(this.documentId, notesdto);
        this.addComments = "";
        setTimeout(() => this.loadDocumentWorkflowHistory(), 300);
        // await this.loadDocumentWorkflowHistory();
      } catch (e:any) {    
        e.text().then((_responseText: any) => {
          this.isMessgeVisible = false;
              this.response = _responseText;
              this.isMessgeVisible = true;
          });
      }
    }
  };

  @action getWorkflowDocInfo = async () => {
    try {
      this.isMessgeVisible = false;
      if (this.documentId > 0) {
        var result = await WorkflowStore.getWorkflowDocInfo(
          Number(this.documentId)
        );
        if (result) {
          this.documentId = result.id ? result.id : 0;
          this.fileName = result.filename ? result.filename : "";
          this.firstName = result.fName ? result.fName : "";
          this.lastName = result.lName ? result.lName : "";
          this.appDate = result.appDate ? result.appDate : new Date();
          this.receivedDate = result.date ? result.date : new Date();
          this.agentId = result.agentId ? result.agentId : 0;
          //this.agent = this.firstName || this.lastName ? (this.firstName + " " +  this.lastName) : "No Agent Assigned";
          this.agent = result.agentName
            ? result.agentName
            : "No Agent Assigned";
          this.uplineAgentId = result.uplineAgentId ? result.uplineAgentId : 0;
          this.uplineAgent = result.uplineAgentName
            ? result.uplineAgentName
            : "No Agent Assigned";
          this.marketerAgentId = result.marketerAgentId
            ? result.marketerAgentId
            : 0;
          this.marketerAgent = result.marketerAgentName
            ? result.marketerAgentName
            : "No Marketer Assigned";
          this.docType = result.typeName ? result.typeName : "";
          this.carrier = result.companyName ? result.companyName : "";
          this.product = result.productName ? result.productName : "";
          this.flag = result.flag ? result.flag : "";
          this.queue = result.workflowStatusType
            ? result.workflowStatusType
            : "";
          this.notes = result.notes ? result.notes : "";
          this.pendingCertificates = result.pendingCertificates
            ? result.pendingCertificates
            : false;
          this.submissionReceived = result.submissionReceived
            ? result.submissionReceived
            : false;
        }
      }
    } catch (e) {
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action unAssign = async () => {
    try {
      await WorkflowStore.unAssign(Number(this.documentId));
      if (this.listener) {
        this.listener.documentInfoListener();
      }
      this.goBackToDocWorkflow();
    } catch (error) {
      IMASLog.log("exception ..unassign the document.");
      this.isException = true;
    }
  };

  @action loadDocumentWorkflowHistory = async () => {
    try {
      var result = await WorkflowStore.getDocWorkFlowHistory(
        Number(this.documentId)
      );
      if (result) {
        var output: any = [];
        output = result;
        this.docWorkFlowHistoryList.values = output;
      } else {
        this.docWorkFlowHistoryList.values = [];
      }
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
      this.docWorkFlowHistoryList.values = [];
      this.isException = true;
    }
  };

  @action resetDocumentInfo() {
    this.selectedAssignedToUser = "ALL";
    this.selectedAssignedToUserTeam = "ALL";
    this.exceptionMsg = "";
    this.isException = false;
    this.missingInfoComments = "";
    this.addComments = "";

    this.isReplace = false;
    this.isDeleteDocument = false;
    this.isAttachDocumentDialogVisible = false;
    this.isNoteDeleteDocumentDialogVisible = false;
    this.documentDialogTitle = "";
    this.noteDeleteDialogTitle = "";
  }
}

export interface DocumentInformationListener {
  documentInfoListener(): void;
}

class DocumentInfoValidator extends AbstractValidator<DocumentInfoViewModel> {
  public constructor() {
    super();
  }
}
