import React, { useReducer } from "react";
import { Observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Constants } from "../../infrastructure/enum/Constants";
import Utils from "../../infrastructure/Utils";
import { IMASMessagesHandler } from "../components/controls/IMASMessagesHandler";
import { TitleNav, EmailSearch, EmailBodyModal } from "./components";
import { EmailViewModel } from "../../viewModels/emails/EmailViewModel";
import "./emailView.css";
import IMASCustomTooltip from "../components/controls/IMASCustomTooltip";
import infoCircle from "../../fonts/info-circle.svg";

interface Props {
  vm: EmailViewModel;
}

const initialState = { isOpen: false, body: "", subject: "" };
interface ModalState {
  isOpen?: boolean;
  body?: string;
  subject?: string;
}
const reducer = (
  state: ModalState,
  action: { type: string; payload?: ModalState }
) => {
  switch (action.type) {
    case "close":
      return { ...initialState };
    case "open":
      return {
        subject: action.payload ? action.payload.subject : "",
        body: action.payload ? action.payload.body : "",
        isOpen: true,
      };
    default:
      throw new Error("Not a valid useReducer option: " + action.type);
  }
};

export const EmailView = (props: Props) => {
  const [modal, dispatch] = useReducer(reducer, initialState);

  const closeModal = () => {
    dispatch({ type: "close" });
  };

  const onSubjectClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    subject: string,
    body: string
  ) => {
    e.preventDefault();
    dispatch({ type: "open", payload: { subject, body } });
  };

  const onColumnSort = (column: any) => {
    const obj = JSON.parse(JSON.stringify(column));
    props.vm.setSortOrder();
    props.vm.setSortColumn(obj.sortField);
    props.vm.loadEmails();
  };

  const onPageHandle = (e: { first: number; rows: number }) => {
    props.vm.onPage(e.first, e.rows);
  };

  const createdDateTemplate = (rowData: any) => {
    return <span>{Utils.getDateInFormat(rowData.createdDate)}</span>;
  };
  const statusTemplate = (rowData: any) => {
    let rowId = "email-notification-tooltip" + rowData.emailId;
    return rowData.errorMessage ? (
      <span>
        Failure
        <IMASCustomTooltip
          parent={
            <img
              data-tip
              data-for={rowId}
              src={infoCircle}
              style={{ marginLeft: "5px", verticalAlign: "sub", width: "17px" }}
            />
          }
          id={rowId}
          children={rowData.errorMessage}
          childrenClassName=""
          className={""}
        ></IMASCustomTooltip>
      </span>
    ) : (
      <span>Success</span>
    );
  };

  const subjectTemplate = (rowData: any) => {
    const { subject, body } = rowData;
    return (
      <a onClick={(e) => onSubjectClick(e, subject, body)} href={`#${subject}`}>
        {Utils.getSafeHtml(subject)}
      </a>
    );
  };

  const attachmentTemplate = (rowData: any) => {
    return rowData.attachment.trim() !== "" ? (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => downloadFile(rowData.attachment)}
      >
        <i className="fas fa-download"></i>
      </span>
    ) : (
      ""
    );
  };

  const downloadFile = (filename: string) => {
    props.vm.downloadDocumentLink(filename);
  };

  return (
    <Observer>
      {() => (
        <div className="p-fluid">
          <EmailBodyModal
            isOpen={modal.isOpen}
            body={modal.body}
            subject={modal.subject}
            onClose={closeModal}
          />
          <EmailSearch
            onChange={props.vm.searchIntervals}
            onKeyUp={props.vm.customerSearch}
            onSearch={props.vm.goSearch}
            onReset={props.vm.resetFilters}
            searchValue={props.vm.searchInputValue}
          />
          <div className="p-grid">
            <div className="p-col-12 sm-order-3">
              {props.vm.isMessgeVisible && (
                <IMASMessagesHandler response={props.vm.response} />
              )}
              <DataTable
                className="header-align-left datatable-width-auto app-table"
                id="emailTable"
                onSort={onColumnSort}
                paginator={true}
                paginatorTemplate={
                  props.vm.emailList.values.length > 0
                    ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    : ""
                }
                rowsPerPageOptions={
                  props.vm.emailList.values.length > 0
                    ? [50, 100, 200, 300]
                    : []
                }
                resizableColumns={true}
                columnResizeMode="expand"
                responsive={true}
                value={props.vm.emailList.values}
                rows={props.vm.rows}
                lazy={true}
                totalRecords={props.vm.totalRecords}
                currentPageReportTemplate={
                  props.vm.emailList.values.length > 0
                    ? "Showing {first} to {last} of {totalRecords} records"
                    : ""
                }
                first={props.vm.first}
                onPage={onPageHandle}
                emptyMessage={Constants.NoRecordFounds}
                selectionMode="single"
                sortField={props.vm.sortColumn}
                sortOrder={props.vm.sortOrder ? 0 : -1}
              >
                <Column
                  field="from"
                  header="From"
                  sortable={true}
                  style={{ width: "20%" }}
                />
                <Column
                  field="to"
                  header="To"
                  sortable={true}
                  style={{ width: "25%" }}
                />
                <Column
                  field="subject"
                  header="Subject"
                  body={subjectTemplate}
                  sortable={false}
                />
                <Column
                  className="email_view_created_date"
                  field="createdDate"
                  header="Created Date"
                  body={createdDateTemplate}
                  sortable={true}
                  style={{ width: "10%" }}
                />
                <Column
                  header="Status"
                  body={statusTemplate}
                  sortable={false}
                  style={{ width: "10%" }}
                />
                <Column
                  className="email_view_column"
                  field="attachment"
                  body={attachmentTemplate}
                  header=""
                />
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};
