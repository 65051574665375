import { AbstractValidator} from 'fluent-ts-validator';
import { observable } from "mobx";
import { routes } from "../../../../router";
import BusinessUnitConfigStore from '../../../../store/BusinessUnitConfigStore';
import { BaseAddEditViewModel } from '../../../components/BaseAddEditViewModel';
import Utils from '../../../../infrastructure/Utils';


export class AnnouncementAddEditViewModel extends BaseAddEditViewModel {

    @observable StartDate: Date | undefined;
    @observable EndDate: Date | undefined;
    @observable AnnouncementMessage: string | undefined;
    @observable endMinDate: Date | undefined;

    constructor()
    {
        super("Announcement", routes.listAnnouncement, new AnnouncementValidator());
    }

    protected toServiceModel() {
        return {
            id: this.selectedId,
            dateStart: Utils.getValidDate(this.StartDate),
            dateEnd: Utils.getValidDate(this.EndDate),
            message: this.AnnouncementMessage
        };
    }

    protected resetModel() {
        this.StartDate = undefined;
        this.EndDate = undefined;
        this.AnnouncementMessage = "";
        this.endMinDate =undefined;
    }

    protected async loadItem(id: number): Promise<void> {
        var item = await BusinessUnitConfigStore.getAnnouncementDetail(id);
        if(item) {
            this.selectedId = item.id || 0;
            var startDate = Utils.getDateInFormat(item.dateStart && id !== 0 ? item.dateStart : undefined);
            this.StartDate = new Date(startDate);
            var endDate = Utils.getDateInFormat(item.dateEnd && id !== 0 ? item.dateEnd : undefined);
            this.EndDate = new Date(endDate);
            this.endMinDate = new Date(startDate);
            this.AnnouncementMessage = item.message || "";
        }
    }

    protected async addItem(): Promise<void> {
        var item = this.toServiceModel();
        await BusinessUnitConfigStore.addAnnouncement(item);
    }
    protected async updateItem(): Promise<void> {
        var item = this.toServiceModel();
        await BusinessUnitConfigStore.updateAnnouncement(item);
    }
}

class AnnouncementValidator extends AbstractValidator<AnnouncementAddEditViewModel> {
    public constructor() {
        super();
        this.validateIfString(input => input.AnnouncementMessage)
            .matches(/\S+/,"i")
            .hasMaxLength(255)
            .isNotEmpty()
            .withFailureMessage("Message is required and should be less than 255 characters");
    }
}
