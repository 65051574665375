import React from "react";
import { observer } from "mobx-react";
import { SyncLoader } from "react-spinners";
import { css } from '@emotion/react'
import PageContext from "../../../infrastructure/PageContext";

export const IMASProgressSpinner:
    React.FC<
        {
      
        }> =  observer(() => { 

          const overlay = css({
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            zIndex: 2,
            cursor: 'pointer'
          })
         
        return (   
              <div className='app-spinner'>
                  <SyncLoader size={20} color={'#334781'} loading={true} css={PageContext.isOverlayRequired ?  overlay : undefined}/>
              </div>
         
        )});