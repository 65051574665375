import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import {
  AppointmentSearchListModel,
  CarrierSearchResponse,
  CreateUpdateAppointmentModel,
} from "../../services/ContractService";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import PageContext from "../../infrastructure/PageContext";
import { AgentPolicyListModel } from "../../services/InsuredService";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { Permission } from "../../infrastructure/enum/Permission";
import ContractsStore from "../../store/ContractsStore";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { AgentCertificationsViewModel } from "./AgentCertificationsViewModel";
import { AgentApptCertDetails } from "../../infrastructure/enum/AgentDetails";
import { routes } from "../../router";
import { AgentAutoAppointmentsViewModel } from "./AgentAutoAppointmentsViewModel";
import { AppointmentManagementTabs } from "../../infrastructure/enum/Common";
import UserContext from "../../infrastructure/UserContext";
import { userInfo } from "os";
import { googleTagManager } from "../../infrastructure/tracking";

export class AgentAppointmentsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {};

  Route = async (currentRoute: RouteList): Promise<void> => {
    this.handleRoute(currentRoute);
  };
  static Symbol: symbol = Symbol("AgentPoliciesViewModel");
  //New Code
  @observable contractId: number = 0;
  @observable carriersData = [{ label: "Select Carrier", value: "0" }];
  @observable appointmentId: number = 0;
  @observable isAddEditAppointmentDialog: boolean = false;
  @observable isException: boolean = false;
  @observable isAppointmentEditMode: boolean = false;
  //  @observable sortColumnAppointments: string = "effectiveDate";
  @observable sortColumnAppointments: string = "id"; //as per sheet provided in 1281
  @observable sortOrderAppointments: boolean = false;
  @observable pageIndexAppointments: number = 0;
  @observable totalRecordsAppointments: number = 0;
  @observable rowsAppointments: number = 5;
  @observable firstAppointments: number = 0;
  @observable AppointmentList = new Collection<AppointmentSearchListModel>();
  @observable apptInstructions: string = "";
  @observable carrierAppointment: string = "0";
  @observable SelectedBaseViewModel: ViewModel | undefined;
  @observable AgentCertificationsViewModel: AgentCertificationsViewModel =
    new AgentCertificationsViewModel();
  @observable AgentAutoAppointmentsViewModel: AgentAutoAppointmentsViewModel =
    new AgentAutoAppointmentsViewModel();
  @observable selectedPanel: string = AgentApptCertDetails.RTS;
  @observable SelectedViewModel: keyof typeof AppointmentManagementTabs =
    AppointmentManagementTabs[AppointmentManagementTabs.RTS];
  @action setCarrierAppointment(value: string) {
    this.carrierAppointment = value;
  }
  @observable carrierAppointmentData = [{ label: "All", value: "All" }];
  @observable selectedTabIndex?: number = 0;
  @observable selectedKey: string | undefined = "";
  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    switch (key) {
      case AgentApptCertDetails.RTS:
        this.handleLinkClick(AgentApptCertDetails.RTS);
        break;
      case AgentApptCertDetails.AutoRTS:
        this.handleLinkClick(AgentApptCertDetails.AutoRTS);
        this.AgentAutoAppointmentsViewModel.load(this.agentId);
        break;
      default:
        this.handleLinkClick(AgentApptCertDetails.RTS);
        return;
    }
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case AgentApptCertDetails.RTS:
          this.selectedPanel = AgentApptCertDetails.RTS.toString();
          this.selectedKey = AppointmentManagementTabs.RTS;
          return;
        case AgentApptCertDetails.AutoRTS:
          this.selectedPanel = AgentApptCertDetails.AutoRTS.toString();
          this.selectedKey = AppointmentManagementTabs.AutoRTS;
          return;
        default:
          this.selectedPanel = AgentApptCertDetails.RTS.toString();
          this.selectedKey = AppointmentManagementTabs.RTS;
          return;
      }
    }
  };

  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case AppointmentManagementTabs.RTS:
        tabIndex = 0;
        break;
      case AppointmentManagementTabs.AutoRTS:
        tabIndex = 1;
        break;
      default:
        tabIndex = 0;
        break;
    }
    return tabIndex;
  }

  @action handleRoute(currentRoute: RouteList) {
    let name = currentRoute.name;
    switch (name) {
      case routes.agentAppointments.name:
        this.selectedPanel = AgentApptCertDetails.RTS;
        this.selectedKey = AppointmentManagementTabs.RTS;
        this.SelectedViewModel = AppointmentManagementTabs.RTS;
        //this.SelectedBaseViewModel = this.AgentAppointmentsViewModel;
        this.Load();
        break;
      case routes.agentAutoAppointments.name:
        this.selectedPanel = AgentApptCertDetails.AutoRTS;
        this.selectedKey = AppointmentManagementTabs.AutoRTS;
        this.SelectedViewModel = AppointmentManagementTabs.AutoRTS;
        this.SelectedBaseViewModel = this.AgentAutoAppointmentsViewModel;
        this.AgentAutoAppointmentsViewModel.Load();
        break;
      case routes.carrierGroupCMList.name:
        this.selectedPanel = AgentApptCertDetails.RTS;
        this.selectedKey = AppointmentManagementTabs.RTS;
        this.SelectedViewModel = AppointmentManagementTabs.RTS;
        this.Load();
        break;
    }
  }

  @action setApptInstructions(value: string) {
    this.apptInstructions = value;
  }
  @action setAddEditAppointmentDialog(value: boolean) {
    this.isAddEditAppointmentDialog = value;
    this.isMessgeVisible = false;
  }
  @observable apptDate: Date | undefined = undefined;
  @action setApptDate(value: Date | undefined) {
    this.apptDate = value;
  }

  @observable apptState: any = [];
  @observable appointmentState: string = "";
  @action setAppointmentState(value: string) {
    this.appointmentState = value;
  }
  @action setApptState(value: string) {
    this.apptState = value;
  }
  @action setAppointmentId(appointmentId: number) {
    this.appointmentId = appointmentId;
  }
  @action saveContractAppointment = async () => {    
    this.isMessgeVisible = false;
    let stateList = [];
    if (this.isAppointmentEditMode) {
      this.apptState = [this.appointmentState];
      stateList = this.statesData.filter((s: any) => {
        return this.appointmentState === s.value;
      });
    } else {
      stateList = this.statesData.filter((s: any) => {
        return this.apptState.find((e: any) => {
          return e === s.value;
        })
          ? true
          : false;
      });
    }

    let selectedState: any = [];
    stateList.forEach((element: any) => {
      selectedState.push(element.abbreviation);
    });
    try {
      var model: CreateUpdateAppointmentModel = {
        agentId: this.agentId,
        id: this.appointmentId,
        companyId: parseInt(this.carrierAppointment),
        effectiveDate: this.apptDate,
        appointmentNote: this.apptInstructions,
        states: selectedState,
      };
      let result: any;
      let isValid = true;

      if (!(await this.validateAppointment())) {
        if (this.isAppointmentEditMode) {
          result = await ContractsStore.editAppointment(model);
        } else {
            googleTagManager.trackAction("appointment_created", {
                feature: routes.agentAppointments.name,
                user_id: UserContext.userId,
                agent_id:this.agentId
            });
                  
          result = await ContractsStore.addAppointment(model);
        }
      } else {
        isValid = false;
        this.isLoading = false;
      }

      if (result !== null && result !== undefined) {
        isValid = true;
      }

      if (isValid) {
        this.setAddEditAppointmentDialog(false);
        this.sortOrderAppointments = false;
        this.sortColumnAppointments = "id"; //as per sheet provided in 1281
        await this.loadContractAppointments();
        this.loadCompanies();
      }
      this.isMessgeVisible = false;
    } catch (e:any) {
      e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          let _status = JSON.parse(_responseText).status;
          var responseModels = {
            status: _status,
            title: "",
                  errors: { "ValidationError": [_title] },
        };
            this.response = JSON.stringify(responseModels);
            this.isMessgeVisible = true;
        }); 
      // this.setAddEditAppointmentDialog(false);
    }
  };
  @observable statesData: any = [
    { label: "UnitedHealthCare", value: "UHC", abbreviation: "" },
  ];
  @action onPageAppointments(firstIndex: number, rows: number) {
    this.rowsAppointments = rows;
    this.firstAppointments = firstIndex;
    this.pageIndexAppointments = firstIndex / rows;
    this.loadContractAppointments();
  }
  public AppointmentErrorModel = new ErrorModel(new AppointmentValidator());

  @action
  public validateAppointment = async () => {
    return this.AppointmentErrorModel.Validate(this);
  };

  @action getAppointmentActionItems = () => {
    let deletActionItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        setTimeout(() => {
          this.setDeleteAppointmentConfirm(true);
        }, 300);
      },
    };
    let appointmentActionItems: MenuItem[] = [];
    if (Utils.hasUserPermission(Permission.DeleteAppointment)) {
      appointmentActionItems.push(deletActionItem);
    }
    return appointmentActionItems;
  };

  @action onEditAppointmentClick = async (appointmentId: number) => {
    this.isAppointmentEditMode = true;
    this.appointmentId = appointmentId;
    var result = await ContractsStore.getAppointmentDetails(
      appointmentId,
      this.agentId
    );
    if (result) {
      this.carrierAppointment = result.companyId
        ? result.companyId.toString()
        : "";
      this.apptState = result.state
        ? this.statesData.filter((s: any) => {
            return s.abbreviation === result.state;
          })
        : [];
      this.apptDate = result.effectiveDate
        ? new Date(result.effectiveDate)
        : new Date();
      this.apptInstructions = result.appointmentNote
        ? result.appointmentNote.toString()
        : "";
      if (this.apptState && this.apptState.length > 0) {
        this.appointmentState = this.apptState[0].value;
      }
    }
    this.setAddEditAppointmentDialog(true);
  };
  @action loadAppointmentCompanies = async () => {
    var result = await ContractsStore.appointmentCompanies(this.contractId);
    if (result != null) {
      this.carriersData = [];
      this.carriersData.push({ label: "All", value: "0" });
    }
  };
  @action loadAppointmentStates = async () => {
    setTimeout(() => {
      PageContext.setIsLoadingVisible(false);
    }, 3000);
    var result = await ContractsStore.appointmentStates();
    if (result != null) {
      this.statesData = [];
      if (result) {
        for (let item of result) {
          this.statesData.push({
            label: "" + item.stateName,
            value: "" + item.stateId,
            abbreviation: "" + item.abbreviation,
            isHeader: item.isHeader,
          });
        }
      }
    }
  };

  @observable isDeleteAppointment: boolean = false;
  @observable isLoading: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable agent: string = "";
  @observable npn: string = "";
  @observable response: any;
  @action deleteAppointment = async () => {
    try {
      this.isMessgeVisible = false;
      await ContractsStore.deleteAppointment(this.appointmentId);
      this.isLoading = false;
      this.isDeleteAppointment = false;
      setTimeout(async () => {
        await this.loadContractAppointments();
        await this.loadCompanies();
      }, Utils.timeDelay_Delete());
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
      this.isDeleteAppointment = false;
    }
  };
  @action loadContractAppointments = async () => {
    setTimeout(() => {
      PageContext.setIsLoadingVisible(false);
    }, 3000);
    this.isMessgeVisible = false;
    try {
      var result = await ContractsStore.loadContractAppointments(
        this.agentId,
        parseInt(this.selectedCompany),
        this.pageIndexAppointments,
        this.rowsAppointments,
        this.sortColumnAppointments,
        this.sortOrderAppointments,
        ""
      );
      if (result) {
        this.agent =
          (result.agentName ? result.agentName : "") +
          " (" +
          this.agentId +
          ")";
        this.npn = result.agentNPN ? result.agentNPN : "";
      }
      if (result && result.data) {
        this.AppointmentList.values = result.data;
        this.totalRecordsAppointments = result.recordCount
          ? result.recordCount
          : 0;
      } else {
        this.AppointmentList.values = [];
        this.totalRecordsAppointments = 0;
      }
      this.isMessgeVisible = false;
    } catch (e) {
      this.AppointmentList.values = [];
      this.totalRecordsAppointments = 0;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  @action setDeleteAppointmentConfirm = (value: boolean) => {
    this.isDeleteAppointment = value;
  };
  @action async addAppointment() {
    this.carrierAppointment = "0";
    this.apptState = [];

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.apptDate = today; // AMS-2279
    this.setApptInstructions("");
    this.isAddEditAppointmentDialog = true;
    this.isAppointmentEditMode = false;
    this.AppointmentErrorModel.ResetValidation(this);
  }
  //End New Code

  @observable isContractingSent: boolean = true;
  @observable PoliciesList = new Collection<AgentPolicyListModel>();
  @observable startIndex: number = 0;
  @observable startSearchIndex: number = 0;
  @observable agentId: number = 0;
  //   @observable companies = [{ label: "All", value: "0" }];
  @observable carrierResponse = new Collection<CarrierSearchResponse>();
  @observable companies = new Collection<{
    label: string;
    value: string;
  }>();

  @observable companiesList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedCompany: string = "0";
  @observable selectedType: string = "0";
  @observable serachComapnyId: string = "0";
  @observable searchStatusId: string = "0";
  @observable searchPolicyNumber: string = "";
  @observable serachInputValue: string = "";
  @observable first: number = 0;
  @observable isInvalidMessage: boolean = false;
  @observable sortOrder: boolean = false;
  @observable sortColumnOrder: string = "id";
  @observable rows: number = 25;

  getSymbol(): symbol {
    return AgentAppointmentsViewModel.Symbol;
  }

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
  }

  @action load = async (id: number) => {
    this.selectedPanel = AgentApptCertDetails.RTS;
    this.selectedKey = AppointmentManagementTabs.RTS;
    this.SelectedViewModel = AppointmentManagementTabs.RTS;
    this.noPolicies();
    this.agentId = id;
    this.searchPolicyNumber = "";
    this.rows = 25;
    PageContext.setIsLoadingVisible(false);
    this.loadCompanies();
    this.serachComapnyId = "0";
    this.sortColumnAppointments = "id"; //as per sheet provided in 1281
    this.AppointmentList.values = [];
    await this.loadContractAppointments();
    await this.AgentCertificationsViewModel.Load(this.agentId);
    await this.loadAppointmentStates();
    this.loadAllCompanies();
  };

  @action loadAllCompanies = async () => {
    try {
      PageContext.setIsMessageVisible(false);
      var items = await ContractsStore.GetActiveInactiveAllCarrierLookup();
      
      Utils.mapListItemsToDropdown(items, this.companiesList, "");
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      PageContext.setResponseMessage(e.response);
      PageContext.setIsMessageVisible(true);
    }
  };
  @action loadCompanies = async () => {
    try {
      PageContext.setIsMessageVisible(false);
      var defaultItem = { label: "All", value: "0" };
      var result =
        await ContractsStore.getCompanyLookupBasedOnAgentAppointments(
          this.agentId.toString()
        );

      if (result !== null) {
        this.companies.values = [];

        if (result.data !== null && result.data) {
          var sortList = [...result.data].sort(Utils.compareCarrierName);
          sortList.forEach((obj: any) => {
            var data = {
              label: obj.carrierName ? obj.carrierName : "",
              value: obj.carrierId ? obj.carrierId.toString() : "",
            };
            this.companies.values.push(data);
          });
          this.companies.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
          this.serachComapnyId = "0";
        } else {
          this.companies.values = [];
          this.companies.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
          this.serachComapnyId = "0";
        }
      }

      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      PageContext.setResponseMessage(e.response);
      PageContext.setIsMessageVisible(true);
    }
  };

  compare = (a: any, b: any) => {
    const nameA = a.carrierName.toUpperCase();
    const nameB = b.carrierName.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
    this.serachComapnyId = value;
    this.startIndex = 0;
    this.loadContractAppointments();
  }
  @action async setSortColumn(sortField: any) {
    this.sortColumnAppointments = sortField;
  }
  @action setSortOrder() {
    this.sortOrderAppointments = !this.sortOrderAppointments;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSelectedStatus(value: string) {
    this.selectedType = value;
    this.searchStatusId = value;
    this.startIndex = 0;
  }

  @action searchIntervals = (text: string) => {
    this.serachInputValue = text;
    if (text) {
      this.searchPolicyNumber = text;
    } else {
      this.searchPolicyNumber = "";
      this.startIndex = 0;
    }
  };
  @action updatePolicyNumber = async (text: string) => {
    this.startIndex = 0;
    this.searchPolicyNumber = text;
  };

  @action goSearch = () => {
    this.startIndex = 0;
    this.first = 0;
  };
  @action noPolicies() {
    this.PoliciesList.values = [];
  }

  @action resetFilters = () => {
    this.startIndex = 0;
    this.serachInputValue = "";
    this.selectedType = "0";
    this.selectedCompany = "0";
    this.searchPolicyNumber = "";
    this.searchStatusId = "0";
    this.serachComapnyId = "0";
    this.first = 0;
  };
}
class AppointmentValidator extends AbstractValidator<AgentAppointmentsViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.carrierAppointment)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Carrier is required");

    this.validateIfString((input) => input.apptState)
      .isNotEmpty()
      .withFailureMessage("State is required");

    this.validateIfDate((input) => input.apptDate)
      .isNotEmpty()
      .withFailureMessage("ApptDate  is required");
  }
}
