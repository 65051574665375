import { action, observable } from "mobx";
import { Route } from "type-route";
import { PageMode } from "../../infrastructure/enum/Common";
import RouteList from "../../infrastructure/RouteList";
import ViewModel from "../../infrastructure/ViewModel";
import { CarrierManagementViewModel } from "../../modules/administration/carrierManagement/CarrierManagementViewModel";
import { CarrierCMAddEditViewModel } from "../../modules/administration/carrierManagement/carrier/CarrierCMAddEditViewModel";
import {
  carrierManagementGroup,
  getCurrentRoute,
  routes,
  submissionGroup,
} from "../../router";
import { AccountingJournalReportsViewModel } from "./accounting/accountingJournalReports/AccountingJournalReportsViewModel";
import { CarrierBankDepositAddViewModel } from "./accounting/carrierbankdeposit/CarrierBankDepositAddViewModel";
import { CarrierBankDepositEditViewModel } from "./accounting/carrierbankdeposit/CarrierBankDepositEditViewModel";
import { CarrierBankDepositListViewModel } from "./accounting/carrierbankdeposit/CarrierBankDepositListViewModel";
import { CreditCardChargeRequestAddEditViewModel } from "./accounting/creditcardchargerequests/CreditCardChargeRequestEditViewModel";
import { CreditCardChargeRequestListViewModel } from "./accounting/creditcardchargerequests/CreditCardChargeRequestListViewModel";
import { ImportAgentDeptsViewModel } from "./accounting/importAgentDepts/ImportAgentDeptsViewModel";
import { ManageImportAgentDeptAddEditViewModel } from "./accounting/manageImportAgentDept/ManageImportAgentDeptAddEditViewModel";
import { ManageImportAgentDeptListViewModel } from "./accounting/manageImportAgentDept/ManageImportAgentDeptListViewModel";
import { RoleListViewModel } from "./appSettings/roles/RoleListViewModel";
import { RolePermissionsViewModel } from "./appSettings/roles/RolePermissionsViewModel";
import { TeamListViewModel } from "./appSettings/teams/TeamListViewModel";
import { UserActivityViewModel } from "./appSettings/UserActivityViewModel";
import { UserAuditReportViewModel } from "./appSettings/UserAuditReportViewModel";
import { UserAuditTrailViewModel } from "./appSettings/UserAuditTrailViewModel";
import { UserAddEditViewModel } from "./appSettings/users/UserAddEditViewModel";
import { UserListViewModel } from "./appSettings/users/UserListViewModel";
import { PolicyAdjustmentsViewModel } from "../administration/accounting/policyAdjustments/PolicyAdjustmentsViewModel";
import { BackoutRenewalImportListViewModel } from "./commission/backoutrenewalimport/BackoutRenewalImportListViewModel";
import { CommissionPlanAddEditViewModel } from "./commission/commissionplans/CommissionPlanAddEditViewModel";
import { CommissionPlanListViewModel } from "./commission/commissionplans/CommissionPlanListViewModel";
import { PolicyDashboardViewModel } from "./commission/policydashboard/PolicyDashboardViewModel";
import { PrintCommissionsViewModel } from "./commission/printcommissions/PrintCommissionsViewModel";
import { ProcessCommissionsViewModel } from "./commission/processcesscommissions/ProcessCommissionsViewModel";
import { RenewalBackoutViewModel } from "./commission/renewalbackout/RenewalBackoutViewModel";
import { RenewalAddEditViewModel } from "./commission/renewaldashboard/RenewalAddEditViewModel";
import { RenewalDashboardViewModel } from "./commission/renewaldashboard/RenewalDashboardViewModel";
import { CarrierGroupAddEditViewModel } from "./contract/carriergroups/CarrierGroupAddEditViewModel";
import { CarrierGroupListViewModel } from "./contract/carriergroups/CarrierGroupListViewModel";
import { CarrierAddEditViewModel } from "./contract/carriers/CarrierAddEditViewModel";
import { CarrierListViewModel } from "./contract/carriers/CarrierListViewModel";
import { EmailAndFaxDetailsAddEditViewModel } from "./contract/emailAndFaxDetails/EmailAndFaxDetailsAddEditViewModel";
import { EmailAndFaxDetailsListViewModel } from "./contract/emailAndFaxDetails/EmailAndFaxDetailsListViewModel";
import { ImportAgentCertificateDownloadFileViewModel } from "./contract/importAgentCertificates/ImportAgentCertificateDownloadFileViewModel";
import { ImportAgentCertificatesViewModel } from "./contract/importAgentCertificates/ImportAgentCertificatesViewModel";
import { ImportLicensePersistencyTypesViewModel } from "./contract/importLicensePersistencyType/ImportLicensePersistencyTypeViewModel";
import { PrintIMBMailShippingViewModel } from "./contract/printIMBMailShipping/PrintIMBMailShippingViewModel";
import { LicenseStatusReasonsListViewModel } from "./contract/statusreasons/LicenseStatusReasonsListViewModel";
import { AgentGroupAddEditViewModel } from "./general/agentGroup/AgentGroupAddEditViewModel";
import { AgentGroupListViewModel } from "./general/agentGroup/AgentGroupListViewModel";
import { AnnouncementAddEditViewModel } from "./general/announcement/AnnouncementAddViewModel";
import { AnnouncementListViewModel } from "./general/announcement/AnnouncementListViewModel";
import { AddDocViewModel } from "./general/documents/AddDocViewModel";
import { AddPacketViewModel } from "./general/documents/AddPacketViewModel";
import { DocumentViewModel } from "./general/documents/DocumentViewModel";
import { PacketViewModel } from "./general/documents/PacketViewModel";
import { ProductAddEditViewModel } from "./general/products/AddEditProductViewModel";
import { ProductListViewModel } from "./general/products/ProductListViewModel";
import { SubmissionManagementViewModel } from "./general/submissionManagement/SubmissionManagementViewModel";
import { WebLinkAddEditViewModel } from "./general/weblinks/WebLinkAddEditViewModel";
import { WebLinksListViewModel } from "./general/weblinks/WebLinksListViewModel";
import { PasswordListAddEditViewModel } from "./identity/passwordList/PasswordListAddEditViewModel";
import { PasswordListViewModel } from "./identity/passwordList/PasswordListViewModel";
import { ColumnDefinitionListViewModel } from "./imports/ColumnDefinitionListViewModel";
import { ImportAddEditViewModel } from "./imports/ImportAddEditViewModel";
import { ImportListViewModel } from "./imports/ImportListViewModel";
import { LicenseSplitterViewModel } from "./licensing/licenseSplitter/LicenseSplitterViewModel";
import { LicenseStatusReasonAddEditViewModel } from "./licensing/licenseStatusReasons/LicenseStatusReasonAddEditViewModel";
import { MarketerMailConfigurationListViewModel } from "./licensing/MarketerMailConfiguration/MarketerMailConfigurationListViewModel";
import { PayoutLevelListViewModel } from "./licensing/payoutlevels/PayoutLevelListViewModel";
import { CertificationsAddEditViewModel } from "./newBusiness/CertificationsAddEditViewModel";
import { CertificationsListViewModel } from "./newBusiness/CertificationsListViewModel";
import { ProductLineAddEditViewModel } from "./product/productlines/ProductLinesAddEditViewModel";
import { ProductLinesListViewModel } from "./product/productlines/ProductLinesListViewModel";
import { ProductSubCategoryAddEditViewModel } from "./product/productsubcategory/ProductSubCategoryAddEditViewModel";
import { ProductSubCategoryDetailsViewModel } from "./product/productsubcategory/ProductSubCategoryDetailsViewModel";
import { ProductSubCategoryListViewModel } from "./product/productsubcategory/ProductSubCategoryListViewModel";
import { AgentLeadDisabledCarrierAddEditViewModel } from "./sales/AgentLeadDisabledCarrierAddEditViewModel";
import { AgentLeadDisabledCarrierViewModel } from "./sales/AgentLeadDisabledCarrierViewModel";
import { SettingsViewModel } from "./SettingsViewModel";
import { SuppliesAddEditViewModel } from "./supplies/SuppliesAddEditViewModel";
import { SuppliesListViewModel } from "./supplies/SuppliesListViewModel";
import { NotificationViewModel } from "./appSettings/notifications/NotificationViewModel";
import { ManageAttributesViewModel } from "../../views/customers/ManageAttributesViewModel";
import {ContractBulkUploadViewModel} from "../../views/agents/contracts/ContractBulkUploadViewModel";
import { LicenseManagementViewModel } from "../../views/administration/general/licenseManagement/LicenseManagementViewModel";

export class AdminDashboardViewModel implements ViewModel {
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable SettingsViewModel: SettingsViewModel = new SettingsViewModel();
  @observable CarrierListViewModel: CarrierListViewModel =
    new CarrierListViewModel();
  @observable CarrierManagementViewModel: CarrierManagementViewModel =
    new CarrierManagementViewModel();
  @observable CarrierCMAddEditViewModel: CarrierCMAddEditViewModel =
    new CarrierCMAddEditViewModel();
  @observable CarrierAddEditViewModel: CarrierAddEditViewModel =
    new CarrierAddEditViewModel();
  @observable AnnouncementListViewModel: AnnouncementListViewModel =
    new AnnouncementListViewModel();
  @observable AnnouncementAddEditViewModel: AnnouncementAddEditViewModel =
    new AnnouncementAddEditViewModel();
  @observable CarrierGroupListViewModel: CarrierGroupListViewModel =
    new CarrierGroupListViewModel();
  @observable CarrierGroupAddEditViewModel: CarrierGroupAddEditViewModel =
    new CarrierGroupAddEditViewModel();
  @observable DocumentViewModel: DocumentViewModel = new DocumentViewModel();
  @observable AddDocViewModel: AddDocViewModel = new AddDocViewModel();
  @observable PacketViewModel: PacketViewModel = new PacketViewModel();
  @observable AgentGroupListViewModel: AgentGroupListViewModel =
    new AgentGroupListViewModel();
  @observable AgentGroupAddEditViewModel: AgentGroupAddEditViewModel =
    new AgentGroupAddEditViewModel();
  @observable AddPacketViewModel: AddPacketViewModel = new AddPacketViewModel();
  @observable EmailAndFaxDetailsListViewModel: EmailAndFaxDetailsListViewModel =
    new EmailAndFaxDetailsListViewModel();
  @observable
  EmailAndFaxDetailsAddEditViewModel: EmailAndFaxDetailsAddEditViewModel =
    new EmailAndFaxDetailsAddEditViewModel();
  @observable ProductLineAddEditViewModel: ProductLineAddEditViewModel =
    new ProductLineAddEditViewModel();
  @observable ProductLinesListViewModel: ProductLinesListViewModel =
    new ProductLinesListViewModel();
  @observable ProductAddEditViewModel: ProductAddEditViewModel =
    new ProductAddEditViewModel();
  @observable ProductListViewModel: ProductListViewModel =
    new ProductListViewModel();
  @observable ProductSubCategoryListViewModel: ProductSubCategoryListViewModel =
    new ProductSubCategoryListViewModel();
  @observable
  ProductSubCategoryAddEditViewModel: ProductSubCategoryAddEditViewModel =
    new ProductSubCategoryAddEditViewModel();
  @observable
  ProductSubCategoryDetailsViewModel: ProductSubCategoryDetailsViewModel =
    new ProductSubCategoryDetailsViewModel();
  @observable WebLinksListViewModel: WebLinksListViewModel =
    new WebLinksListViewModel();
  @observable WebLinkAddEditViewModel: WebLinkAddEditViewModel =
    new WebLinkAddEditViewModel();
  @observable SubmissionManagementViewModel: SubmissionManagementViewModel =
    new SubmissionManagementViewModel();
  @observable
  AgentLeadDisabledCarrierViewModel: AgentLeadDisabledCarrierViewModel =
    new AgentLeadDisabledCarrierViewModel();
  @observable
  AgentLeadDisabledCarrierAddEditViewModel: AgentLeadDisabledCarrierAddEditViewModel =
    new AgentLeadDisabledCarrierAddEditViewModel();
  @observable LicenseSplitterViewModel: LicenseSplitterViewModel =
    new LicenseSplitterViewModel();
  @observable
  MarketerMailConfigurationListViewModel: MarketerMailConfigurationListViewModel =
    new MarketerMailConfigurationListViewModel();
  @observable
  LicenseStatusReasonsListViewModel: LicenseStatusReasonsListViewModel =
    new LicenseStatusReasonsListViewModel();
  @observable
  LicenseStatusReasonAddEditViewModel: LicenseStatusReasonAddEditViewModel =
    new LicenseStatusReasonAddEditViewModel();
  @observable PayoutLevelListViewModel: PayoutLevelListViewModel =
    new PayoutLevelListViewModel();
  @observable
  ImportLicensePersistencyTypesViewModel: ImportLicensePersistencyTypesViewModel =
    new ImportLicensePersistencyTypesViewModel();
  @observable PasswordListViewModel: PasswordListViewModel =
    new PasswordListViewModel();
  @observable PasswordListAddEditViewModel: PasswordListAddEditViewModel =
    new PasswordListAddEditViewModel();
  @observable
  BackoutRenewalImportListViewModel: BackoutRenewalImportListViewModel =
    new BackoutRenewalImportListViewModel();
  @observable CommissionPlanListViewModel: CommissionPlanListViewModel =
    new CommissionPlanListViewModel();
  @observable CommissionPlanAddEditViewModel: CommissionPlanAddEditViewModel =
    new CommissionPlanAddEditViewModel();
  @observable PolicyDashboardViewModel: PolicyDashboardViewModel =
    new PolicyDashboardViewModel();
  @observable PrintCommissionsViewModel: PrintCommissionsViewModel =
    new PrintCommissionsViewModel();
  @observable RenewalDashboardViewModel: RenewalDashboardViewModel =
    new RenewalDashboardViewModel();
  @observable RenewalAddEditViewModel: RenewalAddEditViewModel =
    new RenewalAddEditViewModel();
  @observable RenewalBackoutViewModel: RenewalBackoutViewModel =
    new RenewalBackoutViewModel();
  @observable ProcessCommissionsViewModel: ProcessCommissionsViewModel =
    new ProcessCommissionsViewModel();
  @observable CertificationsListViewModel: CertificationsListViewModel =
    new CertificationsListViewModel();
  @observable CertificationsAddEditViewModel: CertificationsAddEditViewModel =
    new CertificationsAddEditViewModel();
  @observable
  ImportAgentCertificatesViewModel: ImportAgentCertificatesViewModel =
    new ImportAgentCertificatesViewModel();
  @observable
  ImportAgentCertificateDownloadFileViewModel: ImportAgentCertificateDownloadFileViewModel =
    new ImportAgentCertificateDownloadFileViewModel();
  @observable
  AccountingJournalReportsViewModel: AccountingJournalReportsViewModel =
    new AccountingJournalReportsViewModel();
  @observable
  CreditCardChargeRequestListViewModel: CreditCardChargeRequestListViewModel =
    new CreditCardChargeRequestListViewModel();
  @observable ImportAgentDeptsViewModel: ImportAgentDeptsViewModel =
    new ImportAgentDeptsViewModel();
  @observable
  ManageImportAgentDeptListViewModel: ManageImportAgentDeptListViewModel =
    new ManageImportAgentDeptListViewModel();
  @observable
  ManageImportAgentDeptAddEditViewModel: ManageImportAgentDeptAddEditViewModel =
    new ManageImportAgentDeptAddEditViewModel();
  @observable CarrierBankDepositListViewModel: CarrierBankDepositListViewModel =
    new CarrierBankDepositListViewModel();
  @observable CarrierBankDepositAddViewModel: CarrierBankDepositAddViewModel =
    new CarrierBankDepositAddViewModel();
  @observable CarrierBankDepositEditViewModel: CarrierBankDepositEditViewModel =
    new CarrierBankDepositEditViewModel();
  @observable SuppliesListViewModel: SuppliesListViewModel =
    new SuppliesListViewModel();
  @observable SuppliesAddEditViewModel: SuppliesAddEditViewModel =
    new SuppliesAddEditViewModel();
  @observable PrintIMBMailShippingViewModel: PrintIMBMailShippingViewModel =
    new PrintIMBMailShippingViewModel();
  @observable RoleListViewModel: RoleListViewModel = new RoleListViewModel();
  @observable RolePermissionsViewModel: RolePermissionsViewModel =
    new RolePermissionsViewModel();
  @observable TeamListViewModel: TeamListViewModel = new TeamListViewModel();
  @observable UserListViewModel: UserListViewModel = new UserListViewModel();
  @observable UserAddEditViewModel: UserAddEditViewModel =
    new UserAddEditViewModel();
  @observable UserActivityViewModel: UserActivityViewModel =
    new UserActivityViewModel();
  @observable UserAuditTrailViewModel: UserAuditTrailViewModel =
    new UserAuditTrailViewModel();
  @observable UserAuditReportViewModel: UserAuditReportViewModel =
    new UserAuditReportViewModel();
  @observable PolicyAdjustmentsViewModel: PolicyAdjustmentsViewModel =
    new PolicyAdjustmentsViewModel();
  @observable
  CreditCardChargeRequestAddEditViewModel: CreditCardChargeRequestAddEditViewModel =
    new CreditCardChargeRequestAddEditViewModel();
  @observable ImportListViewModel: ImportListViewModel =
    new ImportListViewModel();
  @observable ColumnDefinitionListViewModel: ColumnDefinitionListViewModel =
    new ColumnDefinitionListViewModel();
  @observable ImportAddEditViewModel: ImportAddEditViewModel =
    new ImportAddEditViewModel();
  @observable NotificationViewModel: NotificationViewModel =
    new NotificationViewModel();
  @observable ManageAttributesViewModel: ManageAttributesViewModel =
    new ManageAttributesViewModel();
  @observable ContractBulkUploadViewModel: ContractBulkUploadViewModel = new ContractBulkUploadViewModel();
  @observable LicenseManagementViewModel: LicenseManagementViewModel = new LicenseManagementViewModel();

    

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async () => {
    this.route = getCurrentRoute();
  };

  @action Route = async (currentRoute: RouteList): Promise<void> => {
    this.route = currentRoute;
    var routeParams = JSON.parse(JSON.stringify(currentRoute.params));
    if (currentRoute.name === routes.administration.name) {
      if (!(this.SelectedViewModel instanceof SettingsViewModel)) {
        this.SelectedViewModel = this.SettingsViewModel;
      }
      await this.SelectedViewModel.Load(currentRoute.params);
    } else if (currentRoute.name === routes.listAnnouncement.name) {
      if (!(this.SelectedViewModel instanceof AnnouncementListViewModel)) {
        this.SelectedViewModel = this.AnnouncementListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addAnnouncement.name ||
      currentRoute.name === routes.editAnnouncement.name
    ) {
      if (!(this.SelectedViewModel instanceof AnnouncementAddEditViewModel)) {
        this.SelectedViewModel = this.AnnouncementAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listagentGroup.name) {
      if (!(this.SelectedViewModel instanceof AgentGroupListViewModel)) {
        this.SelectedViewModel = this.AgentGroupListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addagentGroup.name ||
      currentRoute.name === routes.editagentGroup.name
    ) {
      if (!(this.SelectedViewModel instanceof AgentGroupAddEditViewModel)) {
        this.SelectedViewModel = this.AgentGroupAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listCarrier.name) {
      if (!(this.SelectedViewModel instanceof CarrierListViewModel)) {
        this.SelectedViewModel = this.CarrierListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (carrierManagementGroup.has(currentRoute)) {
      if (!(this.SelectedViewModel instanceof CarrierManagementViewModel)) {
        this.SelectedViewModel = this.CarrierManagementViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } 
    // else if (
    //   currentRoute.name === routes.addCarrierManagement.name ||
    //   currentRoute.name === routes.editCarrierManagement.name ||
    //   currentRoute.name === routes.previewCarrierManagement.name
    // ) {
    //   if (!(this.SelectedViewModel instanceof CarrierCMAddEditViewModel)) {
    //     this.SelectedViewModel = this.CarrierCMAddEditViewModel;
    //   }
    //   await this.SelectedViewModel.Load(routeParams.Id);
    //   await this.SelectedViewModel.Route(currentRoute);
    // }
     else if (
      currentRoute.name === routes.addCarrier.name ||
      currentRoute.name === routes.editCarrier.name
    ) {
      if (!(this.SelectedViewModel instanceof CarrierAddEditViewModel)) {
        this.SelectedViewModel = this.CarrierAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listCarrierGroups.name) {
      if (!(this.SelectedViewModel instanceof CarrierGroupListViewModel)) {
        this.SelectedViewModel = this.CarrierGroupListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addCarrierGroups.name ||
      currentRoute.name === routes.editCarrierGroups.name
    ) {
      if (!(this.SelectedViewModel instanceof CarrierGroupAddEditViewModel)) {
        this.SelectedViewModel = this.CarrierGroupAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.adminDocuments.name) {
      if (!(this.SelectedViewModel instanceof DocumentViewModel)) {
        this.SelectedViewModel = this.DocumentViewModel;
      }
      await this.SelectedViewModel.Load(false);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.addDocument.name) {
      if (!(this.SelectedViewModel instanceof AddDocViewModel)) {
        this.SelectedViewModel = this.AddDocViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.documentId, PageMode.Add);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.editDocument.name) {
      if (!(this.SelectedViewModel instanceof AddDocViewModel)) {
        this.SelectedViewModel = this.AddDocViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.documentId, PageMode.Edit);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.updateDocument.name) {
      if (!(this.SelectedViewModel instanceof AddDocViewModel)) {
        this.SelectedViewModel = this.AddDocViewModel;
      }
      await this.SelectedViewModel.Load(
        routeParams.documentId,
        PageMode.Update
      );
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.cloneDocument.name) {
      if (!(this.SelectedViewModel instanceof AddDocViewModel)) {
        this.SelectedViewModel = this.AddDocViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.documentId, PageMode.Clone);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.packets.name) {
      if (!(this.SelectedViewModel instanceof PacketViewModel)) {
        this.SelectedViewModel = this.PacketViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addPacket.name ||
      currentRoute.name === routes.editPacket.name
    ) {
      if (!(this.SelectedViewModel instanceof AddPacketViewModel)) {
        this.SelectedViewModel = this.AddPacketViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.packetId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listEmailFaxDetails.name) {
      if (
        !(this.SelectedViewModel instanceof EmailAndFaxDetailsListViewModel)
      ) {
        this.SelectedViewModel = this.EmailAndFaxDetailsListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addEmailFaxDetails.name ||
      currentRoute.name === routes.editEmailFaxDetails.name
    ) {
      if (
        !(this.SelectedViewModel instanceof EmailAndFaxDetailsAddEditViewModel)
      ) {
        this.SelectedViewModel = this.EmailAndFaxDetailsAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listProductLines.name) {
      if (!(this.SelectedViewModel instanceof ProductLinesListViewModel)) {
        this.SelectedViewModel = this.ProductLinesListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addProductLine.name ||
      currentRoute.name === routes.editProductLine.name
    ) {
      if (!(this.SelectedViewModel instanceof ProductLineAddEditViewModel)) {
        this.SelectedViewModel = this.ProductLineAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listPasswordList.name) {
      if (!(this.SelectedViewModel instanceof PasswordListViewModel)) {
        this.SelectedViewModel = this.PasswordListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addPasswordList.name ||
      currentRoute.name === routes.editPasswordList.name
    ) {
      if (!(this.SelectedViewModel instanceof PasswordListAddEditViewModel)) {
        this.SelectedViewModel = this.PasswordListAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listProduct.name) {
      if (!(this.SelectedViewModel instanceof ProductListViewModel)) {
        this.SelectedViewModel = this.ProductListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addProduct.name ||
      currentRoute.name === routes.editProduct.name
    ) {
      if (!(this.SelectedViewModel instanceof ProductAddEditViewModel)) {
        this.ProductAddEditViewModel.reset();
        this.SelectedViewModel = this.ProductAddEditViewModel;
        this.ProductAddEditViewModel.loadcarriersOnAddProduct(); 
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listProductSubCategory.name) {
      if (
        !(this.SelectedViewModel instanceof ProductSubCategoryListViewModel)
      ) {
        this.SelectedViewModel = this.ProductSubCategoryListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addProductSubCategory.name ||
      currentRoute.name === routes.editProductSubCategory.name
    ) {
      if (
        !(this.SelectedViewModel instanceof ProductSubCategoryAddEditViewModel)
      ) {
        this.SelectedViewModel = this.ProductSubCategoryAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.productDetails.name) {
      if (
        !(this.SelectedViewModel instanceof ProductSubCategoryDetailsViewModel)
      ) {
        this.SelectedViewModel = this.ProductSubCategoryDetailsViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listWebLinks.name) {
      if (!(this.SelectedViewModel instanceof WebLinksListViewModel)) {
        this.SelectedViewModel = this.WebLinksListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addWebLink.name ||
      currentRoute.name === routes.editWebLink.name
    ) {
      if (!(this.SelectedViewModel instanceof WebLinkAddEditViewModel)) {
        this.SelectedViewModel = this.WebLinkAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    }
    // else if(currentRoute.name === routes.submissionManagement.name){
    else if (submissionGroup.has(currentRoute)) {
      if (!(this.SelectedViewModel instanceof SubmissionManagementViewModel)) {
        this.SelectedViewModel = this.SubmissionManagementViewModel;
      }
      this.SubmissionManagementViewModel.resetView();
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listAgentLeadDisabledCarrier.name) {
      if (
        !(this.SelectedViewModel instanceof AgentLeadDisabledCarrierViewModel)
      ) {
        this.SelectedViewModel = this.AgentLeadDisabledCarrierViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addAgentLeadDisabledCarrier.name ||
      currentRoute.name === routes.editAgentLeadDisabledCarrier.name
    ) {
      if (
        !(
          this.SelectedViewModel instanceof
          AgentLeadDisabledCarrierAddEditViewModel
        )
      ) {
        this.SelectedViewModel = this.AgentLeadDisabledCarrierAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.licenseDashboard.name) {
      if (!(this.SelectedViewModel instanceof LicenseSplitterViewModel)) {
        this.SelectedViewModel = this.LicenseSplitterViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.listMarketerMailConfiguration.name
    ) {
      if (
        !(
          this.SelectedViewModel instanceof
          MarketerMailConfigurationListViewModel
        )
      ) {
        this.SelectedViewModel = this.MarketerMailConfigurationListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listLicenseStatusReasons.name) {
      if (
        !(this.SelectedViewModel instanceof LicenseStatusReasonsListViewModel)
      ) {
        this.SelectedViewModel = this.LicenseStatusReasonsListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addLicenseStatusReasons.name ||
      currentRoute.name === routes.editLicenseStatusReasons.name
    ) {
      if (
        !(this.SelectedViewModel instanceof LicenseStatusReasonAddEditViewModel)
      ) {
        this.SelectedViewModel = this.LicenseStatusReasonAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.payoutLevels.name) {
      if (!(this.SelectedViewModel instanceof PayoutLevelListViewModel)) {
        this.SelectedViewModel = this.PayoutLevelListViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.importlicensepersistencytypes.name
    ) {
      if (
        !(
          this.SelectedViewModel instanceof
          ImportLicensePersistencyTypesViewModel
        )
      ) {
        this.SelectedViewModel = this.ImportLicensePersistencyTypesViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listRenewalImports.name) {
      if (
        !(this.SelectedViewModel instanceof BackoutRenewalImportListViewModel)
      ) {
        this.SelectedViewModel = this.BackoutRenewalImportListViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listCommissionplan.name) {
      if (!(this.SelectedViewModel instanceof CommissionPlanListViewModel)) {
        this.SelectedViewModel = this.CommissionPlanListViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addCommissionplan.name ||
      currentRoute.name === routes.editCommissionplan.name
    ) {
      if (!(this.SelectedViewModel instanceof CommissionPlanAddEditViewModel)) {
        this.SelectedViewModel = this.CommissionPlanAddEditViewModel;
      }
      await this.CommissionPlanAddEditViewModel.resetEntireForm();
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.policyDashboard.name) {
      if (!(this.SelectedViewModel instanceof PolicyDashboardViewModel)) {
        this.SelectedViewModel = this.PolicyDashboardViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.printCommissions.name) {
      if (!(this.SelectedViewModel instanceof PrintCommissionsViewModel)) {
        this.SelectedViewModel = this.PrintCommissionsViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.renewalDashboard.name) {
      if (!(this.SelectedViewModel instanceof RenewalDashboardViewModel)) {
        this.SelectedViewModel = this.RenewalDashboardViewModel;
        this.RenewalDashboardViewModel.customLoad();
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addRenewal.name ||
      currentRoute.name === routes.editRenewal.name
    ) {
      if (!(this.SelectedViewModel instanceof RenewalAddEditViewModel)) {
        this.SelectedViewModel = this.RenewalAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.renewalBackout.name) {
      if (!(this.SelectedViewModel instanceof RenewalBackoutViewModel)) {
        this.SelectedViewModel = this.RenewalBackoutViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.processCommissions.name) {
      if (!(this.SelectedViewModel instanceof ProcessCommissionsViewModel)) {
        this.SelectedViewModel = this.ProcessCommissionsViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listCertifications.name) {
      if (!(this.SelectedViewModel instanceof CertificationsListViewModel)) {
        this.SelectedViewModel = this.CertificationsListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addCertifications.name ||
      currentRoute.name === routes.editCertifications.name
    ) {
      if (!(this.SelectedViewModel instanceof CertificationsAddEditViewModel)) {
        this.SelectedViewModel = this.CertificationsAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.importAgentCertificates.name) {
      if (
        !(this.SelectedViewModel instanceof ImportAgentCertificatesViewModel)
      ) {
        this.SelectedViewModel = this.ImportAgentCertificatesViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.importAgentCertificatesDownloadFile.name
    ) {
      if (
        !(
          this.SelectedViewModel instanceof
          ImportAgentCertificateDownloadFileViewModel
        )
      ) {
        this.SelectedViewModel =
          this.ImportAgentCertificateDownloadFileViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.accountingJournal.name) {
      if (
        !(this.SelectedViewModel instanceof AccountingJournalReportsViewModel)
      ) {
        this.SelectedViewModel = this.AccountingJournalReportsViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listCreditCardChargeRequest.name) {
      if (
        !(
          this.SelectedViewModel instanceof CreditCardChargeRequestListViewModel
        )
      ) {
        this.SelectedViewModel = this.CreditCardChargeRequestListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.editCreditCardChargeRequest.name) {
      if (
        !(
          this.SelectedViewModel instanceof
          CreditCardChargeRequestAddEditViewModel
        )
      ) {
        this.SelectedViewModel = this.CreditCardChargeRequestAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.importagentdebts.name) {
      if (!(this.SelectedViewModel instanceof ImportAgentDeptsViewModel)) {
        this.SelectedViewModel = this.ImportAgentDeptsViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listManageAgentDept.name) {
      if (
        !(this.SelectedViewModel instanceof ManageImportAgentDeptListViewModel)
      ) {
        this.SelectedViewModel = this.ManageImportAgentDeptListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addManageAgentDept.name ||
      currentRoute.name === routes.editManageAgentDept.name
    ) {
      if (
        !(
          this.SelectedViewModel instanceof
          ManageImportAgentDeptAddEditViewModel
        )
      ) {
        this.SelectedViewModel = this.ManageImportAgentDeptAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listCarrierBankDeposit.name) {
      if (
        !(this.SelectedViewModel instanceof CarrierBankDepositListViewModel)
      ) {
        this.SelectedViewModel = this.CarrierBankDepositListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.addCarrierBankDeposit.name) {
      if (!(this.SelectedViewModel instanceof CarrierBankDepositAddViewModel)) {
        this.SelectedViewModel = this.CarrierBankDepositAddViewModel;
      }
      await this.SelectedViewModel.Load();
    } else if (currentRoute.name === routes.editCarrierBankDeposit.name) {
      if (
        !(this.SelectedViewModel instanceof CarrierBankDepositEditViewModel)
      ) {
        this.SelectedViewModel = this.CarrierBankDepositEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listAdminSupplies.name) {
      if (!(this.SelectedViewModel instanceof SuppliesListViewModel)) {
        this.SelectedViewModel = this.SuppliesListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addAdminSupplies.name ||
      currentRoute.name === routes.editAdminSupplies.name ||
      currentRoute.name === routes.cloneAdminSupplies.name
    ) {
      if (!(this.SelectedViewModel instanceof SuppliesAddEditViewModel)) {
        this.SelectedViewModel = this.SuppliesAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.printmailshipping.name) {
      if (!(this.SelectedViewModel instanceof PrintIMBMailShippingViewModel)) {
        this.SelectedViewModel = this.PrintIMBMailShippingViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listRole.name) {
      if (!(this.SelectedViewModel instanceof RoleListViewModel)) {
        this.SelectedViewModel = this.RoleListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.rolePermissions.name) {
      if (!(this.SelectedViewModel instanceof RolePermissionsViewModel)) {
        this.SelectedViewModel = this.RolePermissionsViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listTeam.name) {
      if (!(this.SelectedViewModel instanceof TeamListViewModel)) {
        this.SelectedViewModel = this.TeamListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listUser.name) {
      if (!(this.SelectedViewModel instanceof UserListViewModel)) {
        this.SelectedViewModel = this.UserListViewModel;
         this.UserListViewModel.selectedTabIndex = 0;
         this.UserListViewModel.resetInvitePagination();
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addUser.name ||
      currentRoute.name === routes.editUser.name
    ) {
      if (!(this.SelectedViewModel instanceof UserAddEditViewModel)) {
        this.SelectedViewModel = this.UserAddEditViewModel;
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.userActivity.name) {
      if (!(this.SelectedViewModel instanceof UserActivityViewModel)) {
        this.SelectedViewModel = this.UserActivityViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.userAuditTrail.name) {
      if (!(this.SelectedViewModel instanceof UserAuditTrailViewModel)) {
        this.SelectedViewModel = this.UserAuditTrailViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.userAuditReport.name) {
      if (!(this.SelectedViewModel instanceof UserAuditReportViewModel)) {
        this.SelectedViewModel = this.UserAuditReportViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.manageColumnDefinition.name) {
      if (!(this.SelectedViewModel instanceof ColumnDefinitionListViewModel)) {
        this.SelectedViewModel = this.ColumnDefinitionListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listImports.name) {
      if (!(this.SelectedViewModel instanceof ImportListViewModel)) {
        this.SelectedViewModel = this.ImportListViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addImports.name ||
      currentRoute.name === routes.editImports.name
    ) {
      if (!(this.SelectedViewModel instanceof ImportAddEditViewModel)) {
        this.SelectedViewModel = this.ImportAddEditViewModel;
        this.ImportAddEditViewModel.reset();
      }
      await this.SelectedViewModel.Load(routeParams.Id);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.policyAdjustments.name) {
      if (!(this.SelectedViewModel instanceof PolicyAdjustmentsViewModel)) {
        this.SelectedViewModel = this.PolicyAdjustmentsViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.notification.name) {
      if (!(this.SelectedViewModel instanceof NotificationViewModel)) {
        this.SelectedViewModel = this.NotificationViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.PreviewNotification.name) {
      if (!(this.SelectedViewModel instanceof NotificationViewModel)) {
        this.SelectedViewModel = this.NotificationViewModel;
      }
      
      await this.SelectedViewModel.LoadPreview(routeParams.Id); 
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.ManageAttributes.name) {
      if (!(this.SelectedViewModel instanceof ManageAttributesViewModel)) {
        this.SelectedViewModel = this.ManageAttributesViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }else if (currentRoute.name === routes.BulkUpload.name) {
      if (!(this.SelectedViewModel instanceof ContractBulkUploadViewModel)) {
        this.SelectedViewModel = this.ContractBulkUploadViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }else if (currentRoute.name === routes.licenseManagement.name) {
      if (!(this.SelectedViewModel instanceof LicenseManagementViewModel)) {
        this.SelectedViewModel = this.LicenseManagementViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }
  };
}
