import { observable, action } from "mobx";
class DocWorkflowContext {
  @observable isDocInfo: boolean = false
  @observable selectedAssignedToUser: any = "0"
  @observable selectedAssignedToUserTeam: any = "0"
  @observable selectedType:any = "0"
  @observable selectedCompany: any = "0"
  @observable selectedProduct: any = "0"
  @observable selectedDivision: any = "0"
  @observable searchValue:string = ""
  @observable isNotReceivedQueue: boolean = false
  @observable selectedDocumentList: any = "0";

  @action getIsDocInfo = () => {
    let storeValuesObj = {
      isDocInfo : this.isDocInfo,
      selectedAssignedToUser : this.selectedAssignedToUser,
      selectedAssignedToUserTeam: this.selectedAssignedToUserTeam,
      selectedType: this.selectedType,
      selectedCompany: this.selectedCompany,
      selectedProduct: this.selectedProduct,
      selectedDivision: this.selectedDivision,
      searchValue: this.searchValue,
      isNotReceivedQueue: this.isNotReceivedQueue,
      selectedDocumentList: parseInt(localStorage.getItem('queueRows') || '50')
    }
    return storeValuesObj
  };

  @action settIsDocInfo = (value: boolean, storeValuesObj: any) => {
      this.isDocInfo = value
      this.selectedAssignedToUser = storeValuesObj.selectedAssignedToUser
      this.selectedAssignedToUserTeam = storeValuesObj.selectedAssignedToUserTeam
      this.selectedType= storeValuesObj.selectedType
      this.selectedCompany = storeValuesObj.selectedCompany
      this.selectedProduct = storeValuesObj.selectedProduct
      this.selectedDivision = storeValuesObj.selectedDivision
      this.searchValue = storeValuesObj.searchValue
      this.isNotReceivedQueue = storeValuesObj.isNotReceivedQueue
      this.selectedDocumentList = parseInt(localStorage.getItem('queueRows') || '50')
  };
}

export default new DocWorkflowContext();

