import { routes } from "../../../../router";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { action, observable } from "mobx";
import { FuneralHomeStatementListModel, RemoveFuneralHomeStatement } from "../../../../services/CommissionService";
import CommissionStore from "../../../../store/CommissionStore";

export class RemoveFuneralHomeStatementsViewModel extends BaseListViewModel<FuneralHomeStatementListModel> {

    @observable fromDate: Date | undefined = new Date();
    @observable toDate: Date | undefined = new Date();
    @observable selectedFuneralStatement: any;

    constructor() {
        super("Funeral Home Statement", "", routes.processCommissions, routes.processCommissions);
        this.resetSearch();
    }

    protected async getItems(pageIndex: number, pageSize: number, sortColumn: string, sortOrder: boolean) {
        return await CommissionStore.getFuneralHomeStatements(this.fromDate, this.toDate, pageIndex, pageSize, sortColumn, sortOrder);
    }

    @action onSearch = async () => {
        await this.loadItems();
    }
    @action onReset = async () => {
        this.resetSearch();
        await this.loadItems();
    }
    @action onRemoveSelectedItems = async () => {
        if(this.selectedFuneralStatement.length > 0) {
            var deleteItems = new Array<RemoveFuneralHomeStatement>();
            this.selectedFuneralStatement.forEach((element: any) => {
                deleteItems.push({ payToId: element.payToId, payDate: element.payDate  });
            });

            await CommissionStore.removeFuneralHomeStatements(deleteItems);
            await this.loadItems();
        }
    }

    @action setFromDate = (value: Date | undefined) => {
        if (value != undefined)
            this.fromDate = this.sanitizedDate(value);
        else
            this.fromDate = undefined

    }
    @action setToDate = (value: Date | undefined) => {
        
        if (value != undefined)
            this.toDate = this.sanitizedDate(value);
        else
            this.toDate = undefined
    }

    private resetSearch() {
        this.toDate = this.sanitizedDate(new Date());
        this.fromDate = this.sanitizedDate(new Date());
        this.fromDate.setDate(this.fromDate.getDate() - 7);
        this.selectedFuneralStatement = [];
    }

    private sanitizedDate(value: Date | undefined): Date {
            var sanitized = value || new Date();
            sanitized.setHours(0, 0, 0, 0);
            return sanitized;
    }

    resetFiltersToDefault() {   
        this.resetSearch();
    }


}