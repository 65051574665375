import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import { CarrierSearchResponse } from "../../services/ContractService";
import RouteList from "../../infrastructure/RouteList";
import IMASLog from "../../infrastructure/IMASLog";
import InsuredStore from "../../store/InsuredStore";
import ContractStore from "../../store/ContractsStore";
import Utils from "../../infrastructure/Utils";
import PageContext from "../../infrastructure/PageContext";
import {
  AgentPolicyListModel,
  AllPoliciesByAgentResponse,
} from "../../services/InsuredService";

export class AgentPoliciesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => { };

  Route = async (currentRoute: RouteList): Promise<void> => { };
  static Symbol: symbol = Symbol("AgentPoliciesViewModel");

  @observable isLoading: boolean = true;
  @observable isContractingSent: boolean = true;
  @observable PoliciesList = new Collection<AgentPolicyListModel>();
  @observable startIndex: number = 0;
  @observable startSearchIndex: number = 0;
  @observable agentId: number = 0;
  //   @observable companies = [{ label: "All", value: "0" }];
  @observable carrierResponse = new Collection<CarrierSearchResponse>();
  @observable companies = new Collection<{
    label: string;
    value: string;
  }>();
  @observable type = new Collection<{
    label: string;
    value: string;
  }>();
  @observable policyId: number = 0;

  @observable dataTypes = [
    { label: "Excel", value: "Excel" },
    { label: "PDF", value: "PDF" },
  ];
  @observable selectedCompany: string = "0";
  @observable selectedDataType: string = "Excel";
  @observable selectedType: string = "0";
  @observable serachComapnyId: string = "0";
  @observable searchStatusId: string = "0";
  @observable searchPolicyNumber: string = "";
  @observable serachInputValue: string = "";
  @observable policiesListModel: AllPoliciesByAgentResponse | null = null;
  @observable first: number = 0;
  @observable sortOrder: boolean = false;
  // @observable sortColumnOrder: string = "dateIssued";
  @observable sortColumnOrder: string = "id"; //as per sheet provided in 1281
  @observable insuredName: string | undefined = "";
  @computed
  get totalRecords(): number {
    if (this.policiesListModel && this.policiesListModel.recordCount)
      return this.policiesListModel.recordCount;
    else return 0;
  }
  @computed
  get isExcelData(): boolean {
    return this.selectedDataType === "Excel";
  }
  @computed
  get isPdfData(): boolean {
    return this.selectedDataType === "PDF";
  }
  @observable rows: number = 50;

  getSymbol(): symbol {
    return AgentPoliciesViewModel.Symbol;
  }

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadPolicies();
  }

  @action load = async (id: number) => {
    this.noPolicies();
    this.agentId = id;
    this.searchPolicyNumber = "";
    this.rows = 50;
    this.resetFilters();
    this.loadCompanies();
    await this.loadPolicies();
  };

  @action loadCompanies = async () => {
    try {
      this.isLoading = true;
      PageContext.setIsMessageVisible(false);
      var defaultItem = { label: "All", value: "0" };
      var result = await ContractStore.getCompanyLookupBasedOnAgent(
        this.agentId
      );

      if (result !== null) {
        this.companies.values = [];

        if (result !== null && result) {
          var sortList = [...result.carriers].sort(Utils.compareCarrierName);
          sortList.forEach((obj: any) => {
            var data = {
              label: obj.carrierName ? obj.carrierName : "",
              value: obj.carrierId ? obj.carrierId.toString() : "",
            };
            this.companies.values.push(data);
          });
          this.companies.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
          this.type.values = this.mapListItemAndSort(result.status);
          this.selectedType = "0";
        } else {
          this.companies.values = [];
          this.companies.values.splice(0, 0, defaultItem);
          this.selectedCompany = "0";
          this.type.values = [];
          this.type.values.splice(0, 0, defaultItem);
          this.selectedType = "0";
        }
      }

      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      PageContext.setResponseMessage(e.response);
      PageContext.setIsMessageVisible(true);
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      listObjects.splice(0, 0, { text: "All", value: "0", selected: false });
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }
  @action downloadPolicies = async (fileType: number) => {
    try {
      this.isLoading = true;
      PageContext.setIsMessageVisible(false);
      var result = await InsuredStore.downloadAgentPolicies(
        this.agentId,
        fileType,
        this.serachComapnyId,
        this.searchStatusId,
        this.searchPolicyNumber,
        this.insuredName && this.insuredName.length > 1
          ? this.insuredName
          : undefined
      );
      await this.downloadReport(result);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      PageContext.setResponseMessage(e.response);
      PageContext.setIsMessageVisible(true);
    }
  };
  @action downloadReport = async (result: any) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = "PolicyList";
          link.href = url;
          link.download = n !== "" ? n : "agent-policies";
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
  @action setSelectedPolicy = (value: number) => {
    this.policyId = value;
  };
  compare = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.carrierName.toUpperCase();
    const nameB = b.carrierName.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
    this.serachComapnyId = value;
    this.startIndex = 0;
  }
  @action async setSortColumn(sortField: any) {
    this.sortColumnOrder = sortField;
    await this.loadPolicies();
  }
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSelectedStatus(value: string) {
    this.selectedType = value;
    this.searchStatusId = value;
    this.startIndex = 0;
  }
  @action loadPolicies = async () => {
    try {
      this.isLoading = true;

      PageContext.setIsMessageVisible(false);
      this.policiesListModel = await InsuredStore.getPolicies(
        this.agentId,
        +this.serachComapnyId,
        +this.searchStatusId,
        this.searchPolicyNumber,
        this.insuredName && this.insuredName.length > 1
          ? this.insuredName
          : undefined,
        this.startIndex,
        this.rows,
        this.sortColumnOrder,
        this.sortOrder
      );
      if (this.policiesListModel && this.policiesListModel.data)
        this.PoliciesList.values = this.policiesListModel.data;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      PageContext.setResponseMessage(e.response);
      PageContext.setIsMessageVisible(true);
    }
  };

  @action searchIntervals = (text: string) => {
    this.serachInputValue = text;
    if (text) {
      this.searchPolicyNumber = text;
    } else {
      this.searchPolicyNumber = "";
      this.startIndex = 0;
      this.loadPolicies();
    }
  };
  @action updatePolicyNumber = async (text: string) => {
    this.startIndex = 0;
    this.searchPolicyNumber = text;
    //this.loadPolicies()
  };

  @action goSearch = () => {
    this.startIndex = 0;
    this.first = 0;
    this.loadPolicies();
  };
  @action noPolicies() {
    this.PoliciesList.values = [];
  }

  @action resetFilters = () => {
    this.startIndex = 0;
    this.serachInputValue = "";
    this.selectedType = "0";
    this.selectedCompany = "0";
    this.searchPolicyNumber = "";
    this.searchStatusId = "0";
    this.serachComapnyId = "0";
    this.first = 0;
    this.insuredName = "";
  };
  @action reset = async () => {
    this.resetFilters();
    await this.loadPolicies();
  };

  @action setInsuredName(value: string) {
    this.insuredName = value;
  }
}
