import { observable, action } from "mobx";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import Collection from "../../../../../infrastructure/CollectionHelper";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import {
  SubmissionTypeListModel,
  ReportFile,
} from "../../../../../services/BusinessUnitConfigService";

export class SubmissionTypesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @action Load = async () => {
    this.getSubmissionTypeList()
    await this.loadSubmissionTypeList();
  };

  @observable sortColumn: string = "submissionTypeId";
  @observable sortOrder: boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable ItemsList = new Collection<SubmissionTypeListModel>();
  @observable submisisonMethodType = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedId: number = 0;
  @observable selectedType: number = 0;
  @observable searchValue: string = "";
  @observable selectedSubmissionType: string = "-1";
  @observable isdeleteConfirm: boolean = false;
  @observable isLoading: boolean = false;


  @action getSubmissionTypeList = async () => {
    let list = [
      { label: "All", value: "-1" },
      { label: "Email", value: "0" },
      { label: "FTP", value: "1" },
    ];
    this.submisisonMethodType.values = [];
    this.submisisonMethodType.values = list;
    this.selectedSubmissionType = this.submisisonMethodType.values[0].value;
  };

  @action setDeleteSubmissionTypeConfirmation = (value: boolean) => {
    this.isdeleteConfirm = value;
  };

  @action deleteSubmissionType = async () => {
    this.setDeleteSubmissionTypeConfirmation(false);
    //selectedType values = 0 for email, 1 for FTP
    await BusinessUnitConfigStore.deleteSubmissionType(
      this.selectedId,
      this.selectedType
    );
    await this.loadSubmissionTypeList();
  };

  @action goSearch = async () => {
    await this.loadSubmissionTypeList();
  };

  @action resetFilters = async () => {
    this.searchValue = "";
    this.rows = 25;
    this.startIndex = 0;
    this.sortColumn = "submissionTypeId";
    this.sortOrder = false;
    this.isdeleteConfirm = false;
    this.selectedId = 0;
    this.selectedType = 0;
    this.selectedSubmissionType = "-1";

    await this.loadSubmissionTypeList();
  };

  @action loadSubmissionTypeList = async () => {
    try {
      this.isLoading = true;
      let result = await BusinessUnitConfigStore.getAllSubmissionTypes(
        Number(this.selectedSubmissionType),
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder,
        this.searchValue
      );
      if (result && result.data) {
        this.totalRecords = result.recordCount || 0;
        this.ItemsList.values = result.data.length > 0 ? result.data : [];
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action exportEmail = async () => {
    try {
      this.isLoading = true;
      let result = await BusinessUnitConfigStore.exportEmailSubmissionType();
      if (result) {
        this.downloadReport(result, "export");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action exportFTP = async () => {
    try {
      this.isLoading = true;
      let result = await BusinessUnitConfigStore.exportFtpSubmissionType();
      if (result) {
        this.downloadReport(result, "export");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadSubmissionTypeList();
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  
  @action setSelectedSubmissionType(value: string) {
    this.selectedSubmissionType = value;
  }

  @action setSelectedId(id: number) {
    this.selectedId = id;
  }
  @action setSelectedType(id: number) {
    this.selectedType = id;
  }
}
