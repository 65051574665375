import { action, computed, observable, toJS } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import IdentityStore from "../../../../store/IdentityStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import UserContext from "../../../../infrastructure/UserContext";
import IMASLog from "../../../../infrastructure/IMASLog";
import PageContext from "../../../../infrastructure/PageContext";
import DocumentStore from "../../../../store/DocumentStore";

export class PagedList<T> {
    public recordCount: number = 0;
    public data: T[] = [];
}
export class CarrierCMDocumentViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => { };
    @observable itemList = new Collection<any>();
    @observable cmSearchInputValue: string = "";
    @observable rowId: number = 0;
    @observable response: any = "";
    @observable isMessgeVisible: boolean = false;

    @observable selectedRowData: any;
    @observable firstItem: number = 0;
    @observable currentPage: number = 0;
    @observable pageSize: number = 50;
    @observable pageIndex: number = 0;

    @observable sortColumn: string = "id";
    @observable isSortAscending: boolean = true;
    @observable defaultSortColumn: string | undefined;
    @observable defaultIsSortAscending: boolean = true;
    @observable startIndex: number = 0;
    @observable totalRecords: number = 0;
    @observable first: number = 0;
    @observable rows: number = 50;
    @observable sort: boolean = false;

    @observable isLoading: boolean = false;
    @observable isException: boolean = false;
    @observable disableSave: boolean = true;
    @observable selectedRowId: number = 0;

    @observable title: string = "";
    @observable carrierID: number = 0;
    @observable isRefreshEnable: boolean = false;
    @action setSelectedDocuments(value: any) {
        this.selectedDocuments = value;
    }

    @observable selectedDocuments: any;
    @observable selectedType: number = 0;
    @observable types = new Collection<any>();
    @observable packetsList = new Collection<any>();  

    @action loadtypesLookupList = async () => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "All Types", value: "0" };
        try {
            var result = await DocumentStore.getAllDocumentLookup();
            if (result != null) {
                if (result.documentTypes != null) {
                    this.types.values = [];
                    result.documentTypes.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.types.values.push(documentData);
                    });
                }
            }
        } catch (e) {
            this.types.values.splice(0, 0, defaultItem);
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.types.values.splice(0, 0, defaultItem);
        this.isLoading = false;
    }

    @action redirectDocumnets = () => {
        routes.adminDocuments.push();
    };
    @action refresh = () => {
        this.cmSearchInputValue = "";
        this.selectedType = 0;
        this.isRefreshEnable = false;
        this.loadPackets();
    };

    @action Load = async (id: number) => {
        this.firstItem = 0;
        this.currentPage = 0;
        this.pageSize = 50;
        this.pageIndex = 0;
        this.carrierID = id;
        this.cmSearchInputValue = "";
        this.isRefreshEnable = false;
        this.defaultSortColumn = undefined;
        this.sortColumn = "Id";
        this.isSortAscending = true;
        this.defaultIsSortAscending = true;        
        this.loadPackets();
        this.loadtypesLookupList();
    };

    @action loadPage(pageIndex: number, pageSize: number) {
        this.currentPage = pageIndex;
        this.pageSize = pageSize;
        this.firstItem = pageIndex * pageSize;
        this.loadPackets();
    }
    @action loadPackets = async () => {
        try {
            this.isLoading = true;
            const packetResponse = await DocumentStore.getAllDocumentSearchByTypes(this.carrierID, this.selectedType, this.pageIndex, this.pageSize, this.sortColumn, this.sort, this.cmSearchInputValue);
            if (packetResponse && packetResponse.data && packetResponse.data.length > 0) {               
                this.packetsList.values = [];
                this.totalRecords = packetResponse.recordCount
                    ? packetResponse.recordCount
                    : 0;
                this.packetsList.values = packetResponse.data;
            }
            else {
                this.totalRecords = 0;
                this.packetsList.values = [];
                this.selectedRowData = [];
            }
            this.isLoading = false;
        } catch (e) {
            IMASLog.log("exception: " + e.message);
            this.isLoading = false;
        }
    }   
    @action setselectedType(categoryId: number) {
        this.selectedType = categoryId;
        this.isRefreshEnable = true;
        if (this.cmSearchInputValue.length === 0 && this.selectedType == 0) this.isRefreshEnable = false;
        this.loadPackets();
    }
    @action selectedRow = async (data: any) => {
        this.selectedRowId = data.id;
    };
  
    @action setSortOrder() {
        this.sort = !this.sort;
        this.startIndex = 0;
        this.first = 0;
    }

    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }

     onPage = (pageInfo: any) => {
        this.firstItem = pageInfo.first;
        this.pageSize = pageInfo.rows;
        this.pageIndex = pageInfo.first / pageInfo.rows;
        this.loadPage(pageInfo.page, pageInfo.rows);
    };  
    @observable packetDocumentsList = new Collection<any>();
    @action downloadDocument = (base64: string) => {
        setTimeout(() => {
            var link = document.createElement("a");
            link.download = "document.pdf";
            link.href = "data:application/pdf;base64," + base64;
            link.textContent = "Download PDF";
            link.click();
            // document.body.appendChild(link);
        }, 0);
    };

    @action downloadDocumentLink = async (docId: number, typeId: number) => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        try {
            var result = await DocumentStore.viewDocument(docId);
            if (result != null) {
                if (result.pdfData) {
                    this.downloadDocument(result.pdfData);
                } else {
                    if (result.message && result.message !== null) {
                        var responseModel = {
                            status: 400,
                            title: result.message,
                            errors: { "": [] },
                        };
                        this.response = JSON.stringify(responseModel);
                        this.isMessgeVisible = true;
                        PageContext.setResponseMessage(this.response);
                        PageContext.setIsMessageVisible(true);
                    }
                }
            }
        } catch (e) {
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.isLoading = false;
    };
    @action viewPacketDocument = async (packetId: number) => {
        try {
            this.isLoading = true;
            var packetDocumentResponse = await DocumentStore.viewPacketDocuments(packetId);
            this.packetDocumentsList.values = [];
            if (packetDocumentResponse && packetDocumentResponse.data) {
                this.packetDocumentsList.values = packetDocumentResponse.data;
            }
            this.isLoading = false;
        } catch (e) {
            let response = {
                status: 500,
                title: e.message,
                errors: { "": [] },
            };
            IMASLog.log("exception: " + e.message);
            this.isLoading = false;
        }
    }
    @action viewDocument = async (docId: number) => {
        this.isLoading = true;
        this.response = {};
        try {
            this.isMessgeVisible = false;
            window.open(window.location.origin + "/documentviewer/" + docId + "/" + 0, '_new');
        } catch (e) {
            var responseModels = {
                status: 400,
                title: "",
                errors: { "ValidationError": ["Document with Id " + docId + " not found"] },
            };
        }
    }
    @action setFirstPage = () => {
        this.firstItem = 0;
        this.currentPage = 0;
    };

    @action resetPaging = () => {
        this.firstItem = 0;
        this.currentPage = 0;
        this.isSortAscending = this.defaultIsSortAscending;
    };

    @action onFieldChange = (e: any) => {
        e.persist();
        this.cmSearchInputValue = e.currentTarget.value;

        this.isRefreshEnable = true;
        if (this.cmSearchInputValue.length === 0 && this.selectedType == 0) this.isRefreshEnable = false;

        if (
            this.cmSearchInputValue.length >= 3 ||
            this.cmSearchInputValue.length === 0
        ) {
            this.loadPackets();
        }
    };

    readonly currentPageReportTemplate: string =
        "Showing {first} - {last} of {totalRecords} records";
    paginatorTemplate(): string {
        return this.packetsList.values.length > 0
            ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            : "";
    }
    rowsPerPageOptions(): number[] {
        return this.packetsList.values.length > 0 ? [50, 100, 150, 200] : [];
    }
}

