import React from "react";
import { Tree } from "primereact/tree";
import { observer } from "mobx-react";
import TreeNode from "primereact/components/treenode/TreeNode";
import { StringifyOptions } from "querystring";

export const IMASTreeNode: React.FC<{
  value: TreeNode[];
  selectionMode?: string;
  selectionKeys: any;
  expandedKeys: TreeNode[];
  filter?: boolean;
  filterPlaceholder?: string;
  className?: string;
  onSelectionChange: (value: string) => void;
  disable?: boolean;
  id?: string;
  nodeTemplate?: any;
  contentClassName?: string;
  onExpand?: (node: any) => void;
  onCollapse?: (node: any) => void;
  metaKeySelection?: boolean;
  filterMode?: string;
}> = observer((props) => {
  return (
    <>
      <Tree
        id={props.id}
        value={props.value}
        selectionMode={props.selectionMode ? props.selectionMode : "checkbox"}
        selectionKeys={props.selectionKeys}
        onSelectionChange={(e) => {
          props.onSelectionChange(e.value);
        }}
        filter={props.filter ? props.filter : false}
        filterPlaceholder={
          props.filterPlaceholder ? props.filterPlaceholder : ""
        }
        expandedKeys={props.expandedKeys}
        className={props.className ? props.className : ""}
        contentClassName={props.contentClassName}
        nodeTemplate={props.nodeTemplate}
        onExpand={(e) => {
          props.onExpand(e.node);
        }}
        onCollapse={(e) => {
          props.onCollapse(e.node);
              }}
        metaKeySelection={props.metaKeySelection}
        filterMode={props.filterMode || "lenient"}
      />
    </>
  );
});
