import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import TaskStore from "../../store/TaskStore";
import Collection from "../../infrastructure/CollectionHelper";
import { CompleteTaskModel, TaskListModel } from "../../services/TaskService";

export class TodaysTaskViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @action Load = async () => {
    this.selectedTasks = [];
    this.isCompleteError = false
    this.Loaded();
    await this.getMyTasks();
    this.setCheckMarksForTasks();
  };
  @action Loaded = () => {};

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable isLoading: boolean = false;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable rows: number = 5;
  @observable sortColumn: string = "id";
  @observable sortAscending: boolean = false;
  @observable status: boolean = false;
  @observable taskDate: Date = new Date();
  @observable totalRecords: number | undefined = 0;
  @observable taskList = new Collection<TaskListModel>();
  @observable selectedTasks: any = [];
  @observable isTaskChecked: any = [];
  @observable isCompleteError:boolean = false

  @action setTasksSelection(selectedObj: any, index: number) {
    if (this.selectedTasks.length === 0) {
      if (this.isTaskChecked[index]) this.selectedTasks.push(selectedObj.id);
      return;
    } else if (this.selectedTasks.length > 0) {
      let count = this.selectedTasks.filter(
        (item: number) => item === selectedObj.id
      );
      if (count.length > 0) {
        let a = this.selectedTasks.filter((i: number) => i !== selectedObj.id);
        this.selectedTasks = a;
      } else {
        if (this.isTaskChecked[index]) this.selectedTasks.push(selectedObj.id);
      }
      // return;
    }
  }

  @action callCompleteTask = async () => {
    try {
      let ids = this.selectedTasks ? this.selectedTasks : [];
      let dto: CompleteTaskModel = {
        id: ids,
      };
      this.isCompleteError = false
      await TaskStore.completeTask(dto);
      await this.Load()
    } catch (e) {}
  };

  @action setCheckedTask(value: number) {
    this.isTaskChecked[value] = !this.isTaskChecked[value];
  }
  // @action setTasksSelection(value: number) {
  //   if (this.selectedTasks.length === 0){
  //     console.log('first item')
  //      this.selectedTasks.push(value);
  //      return
  //   }
  //   else if(this.selectedTasks.length > 0){
  //     console.log('other items')
  //     let count = this.selectedTasks.filter(
  //       (item: any) => item.id === value
  //     );
  //     if (count.length > 0) return;
  //     else this.selectedTasks.push(value);
  //     return
  //   }
  //   console.log(this.selectedTasks)
  // }

  @action setSortOrder() {
    this.sortAscending = !this.sortAscending;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action onPage = async (firstIndex: number, rows: number) => {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    await this.getMyTasks();
  };

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.taskList.values = [];
  }

  @action setCheckMarksForTasks = () => {
    let length = this.taskList.values.length;
    if (length) {
      let a = this.taskList.values.map((i) => i.complete);
      this.isTaskChecked = a;
    } else {
      this.isTaskChecked = [];
    }
  };

  @action getMyTasks = async () => {
    this.isLoading = true;
      try {
          let status = this.status  ? 1 : 0;
      let result = await TaskStore.getMyTasks(
          status,
        this.taskDate,
        this.first,
        this.rows,
        this.sortColumn,
        this.sortAscending
      );
      if (result.recordCount) {
        this.totalRecords = result.recordCount;
        if (result.data) {
          this.taskList.values = result.data;
        } else this.taskList.values = [];
      } else {
        this.noRecordsFound();
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };
}
