import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import {
  BackOutAllCommissionsRequest,
  BackOutSeletedCommissionsRequest,
  Client as CommissionServiceClient,
  CommissionPlanModel,
  CreatePolicyModel,
  FileParameter,
  FileType,
  RemoveFuneralHomeStatement,
  RenewalModel,
  StatementFileType,
  StatementType,
} from "../services/CommissionService";
import { number } from "prop-types";
// import { request } from "http";-

class CommissionStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): CommissionServiceClient => {
    return ServiceClient.getClient(ServiceType.Commission);
  };
  getHostname = () => {
    return null;
  };

  @action getAllRenewalBackoutCarriers = async (payDate: Date) => {
    return await this.getClient().getAllRenewalBackoutCarriers(
      this.getApiVersion(),
      payDate,
      UserContext.getUserId(),
      this.getHostname()
    );
  };

  @action getAllCommissionsForBackOut = async (
    policyNumber: string,
    payToAgentID: number,
    companyId: number,
    payDate: Date,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    return await this.getClient().getAllCommissionsForBackOut(
      this.getApiVersion(),
      policyNumber,
      payToAgentID,
      companyId,
      payDate,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId(),
      this.getHostname()
    );
   
  };

  @action backoutselectedcommissions = async (
    dto: BackOutSeletedCommissionsRequest
  ) => {
   return await this.getClient().backOutSelectedCommissions(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      dto
    );
    
  };

  @action backOutAllCommissions = async (dto: BackOutAllCommissionsRequest) => {
    return await this.getClient().backOutAllCommissions(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      dto
    );
   
  };

  // Commission Related
  @action getCommissionPlans = async (
    carrierId: number,
    planStatusId: number,
    paymentMethodId: number,
    paymentSourceId: number,
    searchText: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    return await this.getClient().getAllCommissionPlans(
      this.getApiVersion(),
      carrierId,
      planStatusId,
      paymentMethodId,
      paymentSourceId,
      searchText,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId(),
      this.getHostname()
    );

   
  };

  @action getCommissionPlanById = async (id: number) => {
    return await this.getClient().getCommissionPlanById(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
   
  };

  @action getCommissionPayFromNameLookup = async () => {
    return await this.getClient().getCommissionPayFromNameLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
   
  };
  @action getCommissionLevelsByPlanId = async (id: number) => {
    return await this.getClient().getCommissionLevelsByPlanId(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
   
  };

  @action getCommissionPlanUsageForContractByPlanId = async (id: number) => {
   return await this.getClient().getCommissionPlanUsageForContractByPlanId(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
    
  };

  @action getCommissionPlanUsageForPolicyByPlanId = async (id: number) => {
    return await this.getClient().getCommissionPlanUsageForPolicyByPlanId(
       id,
       this.getApiVersion(),
       UserContext.getUserId(),
       this.getHostname()
     );
     
   };

  @action addCommissionPlan = async (item: CommissionPlanModel) => {
    return await this.getClient().addCommissionPlan(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      item
    );    
  };

  @action updateCommissionPlan = async (item: CommissionPlanModel) => {
    return await this.getClient().updateCommissionPlan(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      item
    );
    
    };

  @action deleteCommissionPlan = async (id: number) => {
   return await this.getClient().deleteCommissionPlan(
      this.getApiVersion(),
      "" + id,
      UserContext.getUserId(),
      this.getHostname(),
      id
    );
  };

  // Print Commissions
  @action getCommissionStatements = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    return await this.getClient().getCommissionStatements(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      !sortAscending,
      undefined,
      UserContext.getUserId(),
      this.getHostname()
    );
  
  };

  @action generateCommissionStatement = async (
    divisionId: number,
    payDate: Date,
    statementType: StatementType,
    fileType: StatementFileType
  ) => {
    return await this.getClient().generateCommissionStatement(
      this.getApiVersion(),
      divisionId,
      payDate,
      statementType,
      fileType,
      UserContext.getUserId(),
      this.getHostname()
    );

   
  };

  @action approveCommissionStatement = async (
    divisionId: number,
    divisionName: string,
    payDate: Date
  ) => {
    return await this.getClient().approveCommissionStatement(
      this.getApiVersion(),
      divisionId,
      divisionName,
      payDate ? new Date(payDate) : undefined,
      UserContext.getUserId(),
      this.getHostname()
    );
   
  };

  @action unapproveCommissionStatement = async (
    divisionId: number,
    divisionName: string,
    payDate: Date
  ) => {
    return await this.getClient().unapproveCommissionStatement(
      this.getApiVersion(),
      divisionId,
      divisionName,
      payDate ? new Date(payDate) : undefined,
      UserContext.getUserId(),
      this.getHostname()
    );    
  };

  @action markCommissionAsPaid = async (
    divisionId: number,
    divisionName: string,
    payDate: Date
  ) => {
    return await this.getClient().markCommissionAsPaid(
      this.getApiVersion(),
      divisionId,
      divisionName,
      payDate ? new Date(payDate) : undefined,
      UserContext.getUserId(),
      this.getHostname()
    );   
  };

  // Process commission related
  // Funeral Home Removal
  @action getFuneralHomeStatements = async (
      fromDate: Date | undefined,
      toDate: Date | undefined,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
   return await this.getClient().getFuneralHomeStatements(
      this.getApiVersion(),
      fromDate,
      toDate,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
       undefined,
      UserContext.getUserId(),
      this.getHostname()
    );   
  };

  @action removeFuneralHomeStatements = async (
    statements: RemoveFuneralHomeStatement[]
  ) => {
   return await this.getClient().removeFuneralHomeStatements(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      {
        statements: statements,
      }
    );
  };

  // Run Commissions
  @action getRunCommissions = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    return await this.getClient().getRunCommissions(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
        undefined,
      UserContext.getUserId(),
      this.getHostname()
    );    
  };

  @action scheduleRunCommission = async (
    companyIds: number[],
    payDay: Date
  ) => {
    return await this.getClient().scheduleRunCommission(
      this.getApiVersion(),
      companyIds,
      payDay,
      UserContext.getUserId(),
      this.getHostname()
    );
    
  };

  @action unmatchRunCommission = async (
    divisionId: number,
    companyIds: number[]
  ) => {
    return await this.getClient().unmatchRunCommission(
      this.getApiVersion(),
      companyIds,
      UserContext.getUserId(),
      this.getHostname()
    );
    
  };

  // Backout Renewal Import
  @action getBackoutRenewalImports = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    return await this.getClient().getBackoutRenewalImports(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
  };

  @action backoutRenewalImport = async (
    carrierId: number,
    importDate: string,
  ) => {
    return await this.getClient().backoutRenewalImport(
      this.getApiVersion(),
      carrierId,
      importDate,
      UserContext.getUserId(),
      this.getHostname()
    );
   
  };

  // Lookups
  @action getCommissionPlanStatusTypesLookup = async () => {
    return await this.getClient().getCommissionPlanStatussesLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
     
  }  

  @action getPolicyImportNamesLookup = async () => {
  return await this.getClient().getPolicyImportNamesLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
    
  }  

  @action importPolicy = async (
    file?: any | null | undefined,
    importCarrierName?: string | undefined,
    importId?: string,
  ) => {
    return await this.getClient().importPolicy(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      file,
      importCarrierName,
      importId,
    );    
  };

  @action getPolicyImportCompaniesLookup = async() =>{
    return await this.getClient().getPolicyImportCompaniesLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
   
  }

  @action getAgentsBySearchTermLookup= async(searchvalue: string) =>{
    return await this.getClient().getAgentsBySearchTermLookup(
      searchvalue,
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );    
  }

  @action getPolicyCommissionTypesLookup = async() =>{
   return await this.getClient().getPolicyCommissionTypesLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname()
    );
   
  }

  @action getPolicyImportAgentWritingNumbers = async(companyId: number, statusId: number, showAll: boolean) =>{
    return await this.getClient().getPolicyImportAgentWritingNumbers(
      this.getApiVersion(),
      companyId,
      statusId,
      showAll,
      UserContext.getUserId(),
      this.getHostname(),
    );   
  }

  @action getPolicyDashboardConfigurations = async(companyId: number, statusId: number, wrn: string,  pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined) =>{
    return await this.getClient().getPolicyDashboardConfigurations(
      this.getApiVersion(),
      companyId,
      statusId,
      wrn,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      "",
      UserContext.getUserId(),
      this.getHostname(),
    );
    
  }  

  @action getPaymentPlansConfiguration = async(wrn: string, productId: number, companyId: number, ) =>{
    return await this.getClient().getPaymentPlansConfiguration(
      this.getApiVersion(),
      wrn, 
      productId,
      companyId,
      UserContext.getUserId(),
      this.getHostname(),
    );   
  }  

  @action getNonActivatedPolicies = async(companyId: number, wrn: string, ) =>{
    return await this.getClient().getNonActivatedPolicies(
      this.getApiVersion(),
      companyId,
      wrn, 
      UserContext.getUserId(),
      this.getHostname(),
    );   
  }
  @action getRenewalDashboardSearch = async (
    isDurationSort: boolean,
    agentID: number | undefined,
    carrierID: number,
    policyNumber: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    return await this.getClient().getRenewalDashboardSearch(
      this.getApiVersion(),
      isDurationSort,
      agentID,
      carrierID,
      policyNumber,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
        undefined,
      UserContext.getUserId(),
      this.getHostname()
    );
    
  };

  @action getExistingPolicies = async(companyId: number, policyNumber: string, wrn: string, ) =>{
    return await this.getClient().getExistingPolicies(
      this.getApiVersion(),
      companyId,
      policyNumber,
      wrn,
      UserContext.getUserId(),
      this.getHostname(),
    );
    
  }

  @action skipPolicyImport = async(policyImportId: number) =>{
   return await this.getClient().skipPolicyImport(
      this.getApiVersion(),
      policyImportId,
      UserContext.getUserId(),
      this.getHostname(),
    );    
  }

  @action activatePolicyImport = async(policyImportId: number, policyId: number) =>{
   return await this.getClient().activateImportedPolicy(
      this.getApiVersion(),
      policyImportId,
      policyId,
      UserContext.getUserId(),
      this.getHostname(),
    );
   
  }

  @action terminatePolicyImport = async(policyImportId: number, policyId: number) =>{
   return await this.getClient().terminateImportedPolicy(
      this.getApiVersion(),
      policyImportId,
      policyId,
      UserContext.getUserId(),
      this.getHostname(),
    );   
  }

  @action createPolicy = async(dto: CreatePolicyModel) =>{
    var result = await this.getClient().createPolicyFromAdmin(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      dto
      );  
    return result;
  }

    @action addPolicyAnnuitySinglePremium = async (policyImportId: number, policyId: number) => {
         await this.getClient().addPolicyAnnuitySinglePremium(
            this.getApiVersion(),
            policyImportId,
            policyId,
            UserContext.getUserId(),
            this.getHostname(),
        );
    }

  @action getPoliciesBySearchTerm = async (policyNumber: string, isDefaultSort: boolean, agentId: number | undefined, carrierId: number | undefined) => {
   return await this.getClient().getPoliciesBySearchTerm(this.getApiVersion(), isDefaultSort,
          agentId, carrierId, policyNumber, UserContext.getUserId());        
  }

  @action getRenewalRecordsCount = async (pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined) => {
   return await this.getClient().getRenewalRecordCounts(this.getApiVersion(), pageIndex,
   pageSize,
   sortColumn,
   sortAscending,
   "", UserContext.getUserId());    
  }
  @action getPolicyCommissionTypes = async () => {
    return await this.getClient().getPolicyCommissionTypes(this.getApiVersion(), UserContext.getUserId());    
  }

  @action getRenewalPendingCarrierLookup = async () => {
   return await this.getClient().getRenewalPendingCarrierLookup(this.getApiVersion(), UserContext.getUserId());
    
  }

  @action getRenewalAgentsOfCarrierLookup = async (carrierId: number | undefined) => {
   return await this.getClient().getRenewalAgentsOfCarrierLookup(this.getApiVersion(), carrierId, UserContext.getUserId());
    
  }
  @action getRenewalDashboardExcel = async(agentId: number | undefined, carrierId: number | undefined, policyNumber: string | undefined,
        pageIndex: number | undefined, pageSize: number | undefined, sortColumn: string | undefined, sortOrder: boolean,fullTextFilter: string | undefined) => {
   return await this.getClient().getRenewalDashboardAsExcel(this.getApiVersion(),true,agentId, carrierId, policyNumber, pageIndex,
      pageSize, sortColumn, sortOrder,fullTextFilter,UserContext.getUserId());
     
  }

  @action getRenewalReviewLog = async (pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined) => {
    return await this.getClient().getRenewalReviewLog(this.getApiVersion(), pageIndex,
    pageSize,
    sortColumn,
    sortAscending,
    "", UserContext.getUserId());
   
  }

  @action getRenewalEditor = async (policyId: number | undefined, renewalId: number | undefined) =>
  {
    return await this.getClient().getRenewalEditor(this.getApiVersion(), policyId, renewalId);    
  }

 
  @action getRenewalPaymentSourceLookup = async () => {
    return await this.getClient().getRenewalPaymentSourceLookup(this.getApiVersion(), UserContext.getUserId());
  
  }  

  @action getPoliciesByPolicyNumber = async (policyNumber: string | undefined) => {
   return await this.getClient().getPoliciesByPolicyNumber(this.getApiVersion(), policyNumber, UserContext.getUserId());
    
  }
  
  @action addRenewal = async(dto: RenewalModel) => {
    return await this.getClient().addRenewal(this.getApiVersion(), UserContext.getUserId(), undefined, dto);
    
  }

  @action updateRenewal = async(dto: RenewalModel) => {
    return await this.getClient().updateRenewal(this.getApiVersion(),UserContext.getUserId(), undefined, dto);
    
  }

  @action getRenewalById = async (id: number) => 
  {
    return await this.getClient().getRenewalById(id,this.getApiVersion(), UserContext.getUserId());    
  }

  @action getRenewalProCalculationRate = async (commissionPlanId: number | undefined, issueAge: number | undefined,
     policyProductId: number | undefined, policyEffectiveDate: Date | undefined, flatRateMonth: number | undefined,
     userFlatRateMonth: boolean | undefined, duration: number | undefined) => {     
  return await this.getClient().getRenewalFlatRateCalculationFromAdmin(this.getApiVersion(), commissionPlanId,issueAge, policyProductId, policyEffectiveDate ? new Date(policyEffectiveDate) : undefined,
      flatRateMonth, userFlatRateMonth, duration, UserContext.getUserId());     
  }

  @action cloneCommissionPlan = async(id: number) =>{
    return await this.getClient().cloneCommissionPlan(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      id
    );    
  }

  @action getRenewalAgeByPlanId = async(id: number) =>{
   return await this.getClient().getRenewalAgeByPlanId(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
    );   
  }

  @action GetRenewalRateByAgeId = async(id: number) =>{
    return await this.getClient()._GetRenewalRateByAgeId(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
    );
    
  }

  @action deleteRenewal = async (id: number) => {
   return await this.getClient().deleteRenewal(this.getApiVersion(), ''+id,id, UserContext.getUserId());
    
  }

  @action getRenewalImportsLookup = async () => {
   return await this.getClient().getRenewalImportNamesLookup(this.getApiVersion(), UserContext.getUserId());
   
  }

  @action importRenewal = async (
    file : FileParameter | undefined, 
    importName: string | undefined,
    fileFormatExport: FileType | undefined,
    importId: string) => {
    return await this.getClient().importRenewals(this.getApiVersion(), 
    UserContext.getUserId(),
    undefined, 
    file,
    importName,
    fileFormatExport,
    importId);
  } 

  @action getCommissionScreenLookup = async () => {
    return await this.getClient().getCommissionScreenLookup(
      this.getApiVersion(), 
      UserContext.getUserId());
  }  

  @action getAgentLookup = async (
    searchText: string,
    carrierId: number,
    payDate: Date
  ) => {
    return await this.getClient().getAgentLookup(
      this.getApiVersion(),
      searchText,
      carrierId,
      payDate, 
      UserContext.getUserId());
  }

  @action getPolicyNumberLookup = async (
    searchText: string,
    carrierId: number,
    agentId: number,
    payDate: Date
  ) => {
    return await this.getClient().getPolicyNumberLookup(
      this.getApiVersion(),
      searchText,
      carrierId,
      agentId,
      payDate, 
      UserContext.getUserId());
    }

    
    @action getPayToAgentforCommission = async (searchType: number,
        searchText: string) => {
        return await this.getClient().getPayToAgentForCommission(this.getApiVersion(),
            searchType,
            searchText,            
            UserContext.getUserId(),
            this.getHostname()
        );

    } 

    @action getCalculatedCommissionPayable = async (
      commissionPlanId: number,
      policyId: number, 
      basis: number, 
      basisOverride: number, 
      issueAge: number,
      duration: number, 
      flatRateFactor: number
      ) => {
      return await this.getClient().getCalculatedCommissionPayable(this.getApiVersion(),
        commissionPlanId,
        policyId,
        basis,
        basisOverride,
        issueAge,
        duration,
        flatRateFactor,
        UserContext.getUserId(),
        this.getHostname()
      );
    }
}

export default new CommissionStore();
