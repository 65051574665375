import { BaseListViewModel } from "../../../components/BaseListViewModel";
import {
    TeamListModel,
    TeamModel,
    UserDetails,
    UserLookupListModel,
} from "../../../../services/IdentityService";
import { routes } from "../../../../router";
import IdentityStore from "../../../../store/IdentityStore";
import { action, observable } from "mobx";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import PageContext from "../../../../infrastructure/PageContext";
import { Team } from "../../../../infrastructure/enum/Common";
import Collection from "../../../../infrastructure/CollectionHelper";
import UserContext from "../../../../infrastructure/UserContext";

export class TeamListViewModel extends BaseListViewModel<TeamListModel> {
    constructor() {
        super("Teams", "Team", null, null, "teamId", "id", false);
        this.pageSize = 50;
    }

    @observable viewTeamPermissions: boolean = false;
    @observable addEditTeam: boolean = false;
    @observable actionTitle: string = "";
    @observable teamName: string = "";
    @observable selectedPermission = {
        "0-2-2": { checked: true, partialChecked: false },
        "0-2-1": { checked: true, partialChecked: false },
        "0-2": { checked: false, partialChecked: true },
    };
    @observable expandedNodes: any;
    @observable searchValue: string = "";
    @observable public ErrorModel = new ErrorModel(new TeamValidator());
    @observable isDeleteConfirm: boolean = false;
    @observable userList = new Collection<UserLookupListModel>();
    @observable selectedUser: string = "";
    @observable selectedUserForAdd: string = "";
    @observable selectedrow: any;
    @observable showWarning: boolean = false;
    @observable existingUserList = new Collection<UserDetails>();
    @observable isConfirmationAlert: boolean = false;
    @observable confirmationTitle: string = "";
    @observable confirmationValue: string = "";
    @observable isConfirmationForDelete: boolean = false;
    @observable showUserError: boolean = false;
    @observable userIdToDelete: number = 0;
    @observable teamNameEmpty: boolean = false;
    @observable teamNameExists: boolean = false;
    @observable title: string = "";
    @observable isShowCreateToast: boolean = false;
    @observable selectedUserId: string = "";
    @observable existingTeam: string = "";
    @observable status: string = "";

    @action showConfirmationAlert = (bool: boolean, id?: string) => {
        this.isConfirmationAlert = true;
        if (bool) {
            this.confirmationTitle = "Confirm Delete";
            this.confirmationValue = "Are you sure you want to delete this user";
            this.userIdToDelete = id ? Number(id) : 0;
            this.isConfirmationForDelete = true;
        } else {
            this.confirmationTitle = "Confirm Add";
            this.confirmationValue = "Are you sure you want to add user/users";
            this.isConfirmationForDelete = false;
        }
    };

    @action hideConfirmationAlert = () => {
        this.isConfirmationAlert = false;
    };

    @action addBtnAction = async () => {
        if (this.selectedUserId != "") {
            this.showUserError = false;
            this.addUsers();
            this.selectedUser = "";
            this.selectedUserForAdd = "";
            this.selectedUserId = "";
            this.existingTeam = "";
            this.status = "";
            this.showWarning = true;
        } else {
            //show error
            this.showUserError = true;
        }
    };

    @action clearBtnAction = () => {
        this.showUserError = false;
        this.selectedUser = "";
        this.selectedUserForAdd = "";
        this.selectedUserId = "";
        this.existingTeam = "";
        this.status = "";
    };

    @action onClose = () => {
        this.showWarning = false;
    }

    @action addUsers = async () => {
        if ((this.actionTitle === Team.AddTitle) || (this.actionTitle === Team.EditTitle)) {
            var userDetail: UserDetails = {
                id: Number(this.selectedUserId),
                userName: this.selectedUserForAdd,
                teamName: this.existingTeam,
                status: this.status,
                isNewlyAdded: true
            }
            this.existingUserList.values.splice(0, 0, userDetail);
        }
    };

    @action deleteUser = async (user: UserDetails) => {
        this.userIdToDelete = user ? Number(user.id) : 0;
        if (this.userIdToDelete !== 0) {
            let id = this.userIdToDelete;
            this.existingUserList.values = this.existingUserList.values.filter(
                (i) => i.id !== id);
            var items = this.existingUserList.values.filter(i => i.teamName != null);
            if (items.length === 0) {
                this.showWarning = false;
            }
        }
    };
    @observable isShown: boolean = false;
    @observable listViewActions: boolean = false;
    @action handleClick = (id: string) => { 
      let showState = this.isShown;
      const menulistDiv = document.getElementById(id);
      // code to hide all menu lists
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      if (menulistDiv !== null && menuListAllDivs !== null) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
            menuListAllDivs[i].style.display = "none";
          }
        }
  
        // code to open menu list
        menulistDiv.style.display =
          menulistDiv.style.display === "block" ? "none" : "block";
  
        // code to remove backgroud of svg
        for (let i = 0; i < menuListAllDivs.length; i++) {
          const svgSpan = document.getElementById(
            "custom-temp-" + menuListAllDivs[i].id
          ) as HTMLElement;
          if (svgSpan !== null && menuListAllDivs[i].id !== id) {
            svgSpan.className = "custom-report-menuspan";
          }
        }
        const svgDiv = document.getElementById(
          "custom-temp-" + id
        ) as HTMLElement;
        if (svgDiv !== null) {
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menu-active"
              : "custom-report-menuspan";
          menulistDiv.style.display === "block"
            ? (this.listViewActions = true)
            : (this.listViewActions = false);
        }
      }
  
      window.onclick = function (event) {
        const menuListAllDivs = Array.from(
          document.getElementsByClassName(
            "custom-report-menu-list"
          ) as HTMLCollectionOf<HTMLElement>
        );
        const svgDiv = document.getElementById(
          "custom-temp-" + id
        ) as HTMLElement;
        const menulistDiv = document.getElementById(id);
        if (showState && menulistDiv) {
          for (let i = 0; i < menuListAllDivs.length; i++) {
            menuListAllDivs[i].style.display = "none";
            menulistDiv.style.display =
              menulistDiv.style.display === "block" ? "none" : "none";
            svgDiv.className =
              menulistDiv.style.display === "block"
                ? "custom-report-menuspan"
                : "custom-report-menuspan";
            showState = false;
          }
  
          const activeClassName = document.querySelector(
            ".custom-report-menu-active"
          );
        } else {
          showState = true;
        }
      };
      this.isShown = showState;
      };
  
    @action loadUsers = async () => {
        let result = await IdentityStore.getUserList(this.selectedUser, 0, 100);
        if (result !== undefined && result !== null && result.data != null && result.data != undefined && result.data.length > 0) {
            result.data.splice(0, 0, { id: -1 });
            this.userList.values = result.data;

        } else {
            var noUser: UserLookupListModel = { id: 0 };
            this.userList.values = [noUser];
        }
    }

    protected async getItems(
        pageIndex: number,
        pageSize: number,
        sortColumn: string,
        sortOrder: boolean
    ) {
        this.isShowCreateToast = false;
        return await IdentityStore.getAllTeam(
            pageIndex,
            pageSize,
            sortColumn,
            sortOrder,
            this.searchValue
        );
    }

    @action public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    };

    @action public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    };

    protected toServiceModel() {
        let users: number[] =
            // this.actionTitle === Team.AddTitle ?
            this.existingUserList.values.length > 0
                ? this.existingUserList.values.map((i: any) => Number(i.id))
                : []
        // : [];
        var dto: TeamModel = {
            id: this.selectedId,
            teamName: this.teamName,
            userIds: users,
        };
        return dto;
    }

    @action AddEditTeamItem = async () => {
        try {
            this.teamNameEmpty = false;
            this.teamNameExists = false;
            if (this.teamName === "") {
                this.teamNameEmpty = true;
                return false;
            }
            var item = this.toServiceModel();
            var nameExists = await IdentityStore.checkTeamExists(item.id, item.teamName, this.actionTitle === Team.AddTitle)
            if (nameExists) {
                this.teamNameExists = true;
                return false;
            }
            if (this.actionTitle === Team.AddTitle)
                localStorage.setItem("ssrCreateToast", 'create')
            else
                localStorage.setItem("ssrCreateToast", 'modified')
            var result =
                this.actionTitle === Team.AddTitle
                    ? await IdentityStore.addTeam(item)
                    : this.actionTitle === Team.EditTitle
                        ? await IdentityStore.updateTeam(item)
                        : await IdentityStore.updateTeam(item);
            this.setAddEditTeamDialogVisible(false);
            this.selectedId = +result;
            setTimeout(() => {
                this.isShowCreateToast = true;
            }, 60);
            this.title = "Team " + "'" + this.teamName + "'" + " user list";
            this.Load();
        } catch (e) {
            this.setAddEditTeamDialogVisible(false);
        }
    };

    @action setAddEditTeamDialogVisible(value: boolean) {
        this.addEditTeam = value;
    }

    @action onHide = async (value: string) => {
        this.setAddEditTeamDialogVisible(false);
    };

    @action AddEditTeam = async (id: number, action_item: string) => {
        this.teamName = "";
        this.showWarning = false;
        this.teamNameEmpty = false;
        this.teamNameExists = false;
        this.selectedUserId = "";
        this.selectedUser = "";
        this.selectedUserForAdd = "";
        this.status = "";
        this.existingTeam = "";
        this.isShowCreateToast = false;
        this.existingUserList = new Collection<UserDetails>();
        if (action_item === Team.Add) {
            this.actionTitle = Team.AddTitle;
            this.selectedId = 0;
            this.showUserError = false;
        } else if (action_item === Team.Edit) {
            this.actionTitle = Team.EditTitle;
            var result = await IdentityStore.getTeamById(id);
            if (result) {
                this.teamName = result.teamName ? result.teamName : "";
                this.selectedId = id;
                this.showUserError = false;
                this.existingUserList.values = result.users;
            }
        }
        this.ResetValidate();
        PageContext.setIsMessageVisible(false);
        this.setAddEditTeamDialogVisible(true);
    };


    resetFiltersToDefault() {
        this.searchValue = "";
        this.pageSize = 50;
    }

    @action deleteTeam = async () => {
        this.setDeleteConfirm(false);
        this.userList.values = [];
        await IdentityStore.deleteTeam(this.selectedrow.id);
        var items = this.itemList.values;
        var index = items.indexOf(this.selectedrow);
        items.splice(index, 1);
        this.itemList.values = items;
        this.selectedrow = "";
    };

    @action deleteConfirm = async (model: any) => {
        // var result = await IdentityStore.getUsersByTeamId(model.id);
        this.selectedrow = model;
        // if (result) {
        this.setDeleteConfirm(true);
        //   this.userList =
        //     result.length !== 0 ? "The team is assigned to " + result + ". " : "";
        // }
    };

    @action setDeleteConfirm = (value: boolean) => {
        this.isDeleteConfirm = value;
    };

    @action goToAdmin = () => {
        routes.administration.replace();
    };

    @action goToTeams = () => {
        routes.listTeam.replace();
    };
}

class TeamValidator extends AbstractValidator<TeamListViewModel> {
    public constructor() {
        super();
        this.validateIfString((input) => input.teamName)
            .isNotEmpty()
            .isNotEqualTo("0")
            .withFailureMessage("Team Name is required");
    }
}
