import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import { ReportQueuedListModel } from "../../../services/ReportService";
import ReportStore from "../../../store/ReportStore";

export class ReportsQueueListViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (): Promise<void> => undefined;
  @observable isMessageVisible: boolean = false;
  @observable response: any;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable searchInputValue: string = "";
  @observable selectedTableRecordId: string = "";
  @observable sort: boolean = false;
  @observable sortColumn: string = "createdDate";
  @observable customReportQueueList = new Collection<ReportQueuedListModel>();
  @observable reportId: number = 0;

  @observable noCustomRecords: boolean = false;
  @observable reportName: string = "";
  @observable showGenerateToast: boolean = false;
  @observable showGenerateToastFileName: any;

  @action Load = async (id: number) => {
    this.isMessageVisible = false;

    this.showGenerateToast = false;
    this.showGenerateToastFileName = "";
    this.sortColumn = "createdDate";
    this.sort = false;
    this.searchInputValue = "";
    this.rows = 25;
    await this.loadCustomReportsQueue();
    if (id > 0) {
      var res = this.customReportQueueList.values.filter(x => x.downloadReportId == id);
      if (res) {
        var reportName = res[0].reportName ? res[0].reportName : "";
        await this.downloadAttachment(id, reportName, 0);
      }

    }
  };
  @action loadCustomReportsQueue = async () => {
    try {
      this.isLoading = true;
      this.customReportQueueList.values = [];

      const response = await ReportStore.getAllCustomReportQueueList(
        "" + this.searchInputValue,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sort
      );

      if (response && response.data && response.data.length > 0) {
        this.noCustomRecords = false;
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.customReportQueueList.values = response.data;
      } else {
        this.noCustomRecords = true;
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.isMessageVisible = true;
      this.response = e.response;
    }
  };



  @action downloadAttachment = async (
    downloadReportId: number,
    reportName: string,
    id: number
  ) => {
    try {
      this.showGenerateToast = false;
      this.isLoading = true;
      this.isMessageVisible = false;
      const result = await ReportStore.downloadAttachment(downloadReportId);
      if (result) {
        if (result.message && result.message !== null) {
          var responseModel = {
            status: 400,
            title: result.message,
            errors: { "": [] },
          };
          this.response = JSON.stringify(responseModel);
          this.isMessageVisible = true;
          window.scrollTo(0, 0);
        }
        else {
          await this.downloadReport(result, result.fileName || "");
          this.showGenerateToastFileName = (reportName == "Untitled Report") ? reportName : reportName + " Report";
          this.showGenerateToast = true;
          setTimeout(() => {
            this.showGenerateToast = false;
          }, 2000);
        }
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.isMessageVisible = true;
      this.response = e.response;
    }
  };
  @action downloadReport = async (result: any, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(myBlob);
          const n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }

  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadCustomReportsQueue();
  }

  @action setReportId = (value: number) => {
    this.reportId = value;
  };
}
