import { observable, action } from "mobx";
import RouteList from "../../../../../infrastructure/RouteList";
import ViewModel from "../../../../../infrastructure/ViewModel";
import Collection from "../../../../../infrastructure/CollectionHelper";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../../../../infrastructure/ErrorModel";
import BusinessUnitConfigStore from "../../../../../store/BusinessUnitConfigStore";
import { SubmissionSenderEmailTypeListModel } from "../../../../../services/BusinessUnitConfigService";


export class SenderSubmissionTypesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return window.confirm("Are you sure?");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };
  @action Load = async () => {
    await this.loadSenderSubmissionTypes()
  };
  private onClose?: () => void;
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable sortColumn: string = "submissionSenderTypeEmailId";
  @observable sortOrder: boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable dialogTitle: string = "Add Sender Email Type";
  @observable addEditEmail: boolean = false;
  @observable senderName: string = "";
  @observable senderEmail: string = "";
  @observable public ErrorModel = new ErrorModel(
    new SenderSubmissionValidator()
  );
  @observable isdeleteConfirm: boolean = false;
  @observable ItemsList = new Collection<SubmissionSenderEmailTypeListModel>();
  @observable submisisonMethodType = new Collection<{
    label: string;
    value: string;
  }>();
  @observable selectedId: number = 0;
  @observable searchValue: string = '';
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @action loadSenderSubmissionTypes = async() => {
    var result = await BusinessUnitConfigStore.getAllSubmissionSenderEmailTypes(this.startIndex, this.rows, this.sortColumn, this.sortOrder, this.searchValue)
    if (result && result.data){
      this.totalRecords = result.recordCount || 0
      this.ItemsList.values = result.data ? result.data : []
    }
  }

  @action public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @action deleteSenderSubmissionType = async() => {
    this.setDeleteSenderSubmissionTypeConfirmation(false)
    await BusinessUnitConfigStore.deleteSubmissionSenderEmailType(this.selectedId)
    await this.loadSenderSubmissionTypes()
  }

  @action onRowClick = async (data: any) => {
    this.selectedId = data.submissionSenderTypeEmailId
  }

  @action setSearchValue = (value: string) => {
    this.searchValue = value
  }

  @action onSave = async () => {
    try {
      if (await this.Validate()) {
        return false;
      }
      let dto = this.toServiceModel();
      if(this.selectedId === 0){
        await BusinessUnitConfigStore.addSubmissionSenderEmailType(dto)
      } else {
        await BusinessUnitConfigStore.updateSubmissionSenderEmailType(dto)
      }
      this.setAddEditEmailDialogVisible(false);
      this.loadSenderSubmissionTypes()
    }
    catch(_e:any){
      _e.text().then((_responseText: any) => {
        let _title = JSON.parse(_responseText).title;
        let _status = JSON.parse(_responseText).status;
        var responseModels = {
          status: _status,
          title: "",
                errors: { "ValidationError": [_title] },
      };
          this.response = JSON.stringify(responseModels);
          this.isMessgeVisible = true;
      });
    }
  };

  protected toServiceModel() {
    let name = this.senderName.trim()
    let email = this.senderEmail.trim()
    let id =  this.selectedId || 0
    return{
      submissionSenderTypeEmailId: id, 
      submissionSenderTypeEmailName: name,
      fromEmailAddress: email
    }
  }

  @action resetAddEditFilters = () => {
    this.selectedId = 0;
    this.senderName = '';
    this.senderEmail = '';
    this.response =[];
    this.isMessgeVisible = false;
    this.ResetValidate();
  };

  @action setAddEditEmailDialogVisible = (value: boolean) => {
    this.addEditEmail = value;
    this.response =[];
    this.isMessgeVisible = false;
    !value && this.resetAddEditFilters()
  };

  @action addOrEditEmail = async(id: number) => {
    this.selectedId = id
    if(this.selectedId !== 0){
      this.dialogTitle = "Edit Sender Email Type"
      await this.loadItem(id)
    }
    else{
      this.dialogTitle = "Add Sender Email Type"
    }
    this.setAddEditEmailDialogVisible(true)
    this.response =[];
    this.isMessgeVisible = false;
  }

  @action loadItem = async(id: number) => {
    var result = await BusinessUnitConfigStore.getSubmissionSenderEmailTypeById(id)
    if(result){
      this.senderName = result.submissionSenderTypeEmailName || ''
      this.senderEmail = result.fromEmailAddress ||''
    }
  }

  @action setSenderName = (value: string) => {
    this.senderName = value;
  };

  @action setSenderEmail = (value: string) => {
    this.senderEmail = value;
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadSenderSubmissionTypes()
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }

  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action goSearch = () => {
    this.loadSenderSubmissionTypes()
  };

  @action setDeleteSenderSubmissionTypeConfirmation = (value: boolean) => {
    this.isdeleteConfirm = value
  }
  
  @action resetFilters = () => {
    this.searchValue = ''
    this.rows = 25
    this.startIndex = 0
    this.sortColumn = 'submissionSenderTypeEmailId'
    this.sortOrder = false
    this.isdeleteConfirm = false
    this.response =[];
    this.isMessgeVisible = false;
    this.loadSenderSubmissionTypes()
  }
}

class SenderSubmissionValidator extends AbstractValidator<SenderSubmissionTypesViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.senderName)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Name is required");
      this.validateIfString((input) => input.senderEmail)
      .isNotEmpty()
      .withFailureMessage("Email is required.");
    this.validateIfString((input) => input.senderEmail)
      .isEmail()
      .whenNotEmpty()
      .withFailureMessage("Email is invalid.");
  }
}
